import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { WbTicketDuc } from 'core-app/modules/WbTicket/duc'
import { AppDuc } from 'core-app/modules/App/duc'

import { MainRouteDuc } from 'core-app/routes/duc'
import { Title } from 'core-app/shared/components/Title'
import { Button, ButtonIconWrapper } from 'ui-lib/components/Button'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import { Icon } from 'ui-lib/icons/components/Icon'
import { getDateByFormat } from 'core-app/utils/date'
import { Spacer } from 'ui-lib/utils/Spacer'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { isEmptyObject } from 'core-app/utils/helpers'

import { BoxShadowWrapper } from 'ui-lib/components/Card'
import { Label, P, H3 } from 'ui-lib/components/Typography'
import styled from 'styled-components'
import { applyMediaQueries } from 'ui-lib/utils/helpers'

import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Box } from 'ui-lib/utils/Box'

import { getIn } from 'timm'

const FileItem = styled.a`
	font-size: 0.75em;
	line-height: 1.2;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: fit-content;
	text-decoration: underline;
	align-items: center;
	justify-content: center;
	padding: 4px 12px;
	color: #4a4a4a;
	background: #ebf8fe;
	border: 1px solid #5d7480;
	border-radius: 10px;
`
const BlockWrapper = styled(Box)(p => ({
	cursor: p.plain ? 'initial' : 'pointer',
	border: '1.5px solid transparent',
	borderRadius: 4,
	'&:hover': p.plain
		? {}
		: {
				border: `1.5px solid ${p.theme.color.accent}`,
		  },
}))

const SummaryWrapper = styled(Box)({
	paddingRight: 40,
	'&:last-child': {
		paddingRight: 'initial',
	},
	...applyMediaQueries(['mobile'], {
		paddingTop: 4,
		paddingBottom: 4,
	}),
})

const SummaryBlock = ({ name, detail }) => (
	<SummaryWrapper>
		<Label color="#7680A7" small>
			{name}
		</Label>
		<Box height={35} center>
			{typeof detail === 'string' ? (
				<P small bold>
					{detail}
				</P>
			) : (
				detail
			)}
		</Box>
	</SummaryWrapper>
)

export const DocumentView = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { isMobile } = useSelector(AppDuc.selectors.detection)

	const documentInformation = useSelector(
		WbTicketDuc.selectors.documentInformation
	)
	const partnerList = useSelector(AuthDuc.selectors.getTargetPartnersOfUser)
	const { data = {}, file = {} } = documentInformation
	const smallholderID = getIn(data, ['meta', 'smallholderID'])
	const smallHolder = partnerList.filter(
		partner => partner.id === smallholderID
	)
	const smallHolderName = getIn(smallHolder, [0, 'name']) || '---'

	const netWeight = getIn(data, ['ffbDetails', 'weight']) || '---'
	const netWeightUOM = getIn(data, ['ffbDetails', 'weightUOM']) || '---'

	const getBreadCrumbsList = useCallback(
		() => [
			{
				label: t('breadcrumb.home'),
				name: 'home',
				isActive: true,
			},
			{
				label: t('breadcrumb.weighBridge'),
				name: 'weighBridge',
				isActive: true,
			},
			{
				label: t('breadcrumb.viewWb'),
				name: 'weighBridge',
				isActive: false,
			},
		],
		[t]
	)
	const title = getIn(document, ['status']) || '---'
	const breadCrumbs = useMemo(() => getBreadCrumbsList(title), [
		title,
		getBreadCrumbsList,
	])

	/** Handlers */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'weighBridge') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.WBTICKET)
			)
		}
	}

	return (
		<Box padding={8} width="100%" height="100%">
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Box row justifyContent="space-between" alignItems="baseline">
				<Title title={t('weighbridge.viewTicket')} />
				<Box row justifyContent="flex-end">
					<Box style={{ padding: 2 }} width="200px">
						<Button
							label={t('common.back')}
							rounded
							customIcon={
								<ButtonIconWrapper lightBG>
									<Icon glyph={LeftArrowIcon} />
								</ButtonIconWrapper>
							}
							onClick={() =>
								dispatch(
									MainRouteDuc.creators.switchPage(
										MainRouteDuc.types.WBTICKET
									)
								)
							}
						/>
					</Box>
				</Box>
			</Box>
			<Box overflow>
				<BoxShadowWrapper>
					<BlockWrapper
						padding={16}
						overflow="auto"
						width="100%"
						row
						justifyContent={isMobile ? 'flex-end' : 'space-between'}
						wrap
					>
						<Box
							row={!isMobile}
							alignItems="flex-start"
							width={isMobile ? '100%' : ''}
						>
							<Box row wrap>
								<SummaryBlock
									name={t('weighbridge.smallholder')}
									detail={smallHolderName}
								/>
								<SummaryBlock
									name={t('weighbridge.createdAt')}
									detail={getDateByFormat(data.createdAt)}
								/>

								<SummaryBlock
									name={t('weighbridge.netWeight')}
									detail={`${netWeight} ${netWeightUOM} (${netWeight *
										1000} Kg)`}
								/>
							</Box>
						</Box>
					</BlockWrapper>
				</BoxShadowWrapper>
				<Spacer size={40} />
				<BoxShadowWrapper>
					<Box
						row
						style={{
							padding: '16px',
							justifyContent: 'space-between',
						}}
					>
						<H3>{t('weighbridge.attachedPicture')} </H3>
						{!isEmptyObject(file) && (
							<FileItem
								href={file.fullURL}
								target="_blank"
								download
							>
								{file.name}
							</FileItem>
						)}
					</Box>
					<BlockWrapper
						padding={16}
						style={{ display: 'block', textAlign: 'center' }}
						overflow="auto"
						width="100%"
						plain
						wrap
					>
						{!isEmptyObject(file) ? (
							// eslint-disable-next-line react/jsx-no-target-blank
							<a href={file.fullURL} target="_blank">
								<img
									alt="uploaded-ticket"
									src={file.fullURL}
									style={{
										maxHeight: '320px',
										height: 'auto',
									}}
								/>
							</a>
						) : (
							<P>No File Attached</P>
						)}
					</BlockWrapper>
				</BoxShadowWrapper>
			</Box>
		</Box>
	)
}
