/* @flow */
import styled from 'styled-components'

const H1 = styled.h1(p => ({
	...p.theme.fonts.bold,
	color:
		p.color || (p.primary ? p.theme.color.primary : p.theme.color.black1),
	lineHeight: p.theme.lineHeight.xxl,
	fontSize: p.theme.fontSize.xxxl,
	userSelect: 'text',
}))

const H2 = styled.h2(p => ({
	...p.theme.fonts.medium,
	color:
		p.color || (p.primary ? p.theme.color.primary : p.theme.color.accent2),
	lineHeight: p.theme.lineHeight.xl,
	fontSize: p.theme.fontSize.xxl,
	userSelect: 'text',
}))

const H3 = styled.h3(p => ({
	...(p.bold ? p.theme.fonts.bold : p.theme.fonts.medium),
	color:
		p.color || (p.primary ? p.theme.color.primary : p.theme.color.black1),
	lineHeight: p.theme.lineHeight.l,
	fontSize: p.theme.fontSize.xl,
	userSelect: 'text',
}))

const H4 = styled.h4(p => ({
	...(p.bold ? p.theme.fonts.bold : p.theme.fonts.medium),
	color: p.color || (p.primary ? p.theme.color.primary : p.theme.color.grey8),
	lineHeight: p.theme.lineHeight.l,
	fontSize: p.theme.fontSize.l,
	userSelect: 'text',
}))

const P = styled.p(p => ({
	...(p.bold ? p.theme.fonts.medium : p.theme.fonts.regular),
	color:
		p.color || (p.primary ? p.theme.color.primary : p.theme.color.black1),
	lineHeight: p.theme.lineHeight.l,
	fontSize: p.small ? p.theme.fontSize.s : p.theme.fontSize.m,
	userSelect: 'text',
	...(p.large && { fontSize: p.theme.fontSize.l }),
}))

const Label = styled.label(p => ({
	color:
		p.color || (p.primary ? p.theme.color.primary : p.theme.color.accent2),
	lineHeight: p.theme.lineHeight.l,
	fontSize: p.small ? p.theme.fontSize.s : p.theme.fontSize.m,
	userSelect: 'text',
	cursor: p.link ? 'pointer' : 'initial',
	...(p.bold ? p.theme.fonts.medium : p.theme.fonts.regular),
}))

const Text = styled.span(p => ({
	...(p.bold ? p.theme.fonts.medium : p.theme.fonts.regular),
	color:
		p.color || (p.primary ? p.theme.color.primary : p.theme.color.black1),
	lineHeight: p.theme.lineHeight.m,
	fontSize: p.theme.fontSize.s,
	userSelect: 'text',
	cursor: p.link ? 'pointer' : 'initial',
	...(p.link && { textDecoration: 'underline' }),
}))

const SmallText = styled.span(p => ({
	color:
		p.color || (p.primary ? p.theme.color.primary : p.theme.color.black1),
	lineHeight: p.theme.lineHeight.l,
	fontSize: p.fontSize ? p.fontSize : p.theme.fontSize.xs,
	userSelect: 'text',
	cursor: p.link ? 'pointer' : 'initial',
	...(p.bold ? p.theme.fonts.medium : p.theme.fonts.regular),
}))

const TableTitle = styled.label(p => ({
	color: p.color || '#3F56C4',
	lineHeight: p.theme.lineHeight.l,
	fontSize: p.small ? p.theme.fontSize.s : p.theme.fontSize.m,
	userSelect: 'text',
	fontWeight: '700',
}))

const CircleBox = styled.div(p => ({
	color: p.color || p.theme.color.white,
	fontSize: p.fontSize ? p.fontSize : p.theme.fontSize.m,
	fontWeight: p.fontWeight ? p.fontWeight : '700',
	width: p.width ? p.width : '20px',
	height: p.height ? p.height : '20px',
	borderRadius: '50%',
	backgroundColor: p.backgroundColor
		? p.backgroundColor
		: p.theme.color.iconGreen,
	textAlign: 'center',
	alignContent: 'center',
}))

export { H1, Label, H2, H3, H4, P, Text, SmallText, TableTitle, CircleBox }
