function nestedJSONValue(obj, keys) {
	let src = obj
	let val = null

	for (const key of keys) {
		val = src[key]
		if (val === undefined || val === null) {
			return undefined
		}
		src = val
	}

	return val
}

export const IAMAuth = payload => {
	const availableDataFilters = {}
	// compareWithWildCard returns if 'sub' feature is a sub-set of 'main' feature.
	function compareWithWildCard(main, sub) {
		if (!main || !sub || !main.split || !sub.split) {
			return false
		}

		if (main === sub) {
			return true
		}
		const mainParts = main.split('.')
		const subParts = sub.split('.')

		return !(
			mainParts.length !== 4 ||
			subParts.length !== 4 ||
			(mainParts[0] !== '*' && mainParts[0] !== subParts[0]) ||
			(mainParts[1] !== '*' && mainParts[1] !== subParts[1]) ||
			(mainParts[2] !== '*' && mainParts[2] !== subParts[2]) ||
			(mainParts[3] !== '*' && mainParts[3] !== subParts[3])
		)
	}

	function compareList(haystack, needle) {
		for (const item of haystack) {
			if (compareWithWildCard(item, needle)) {
				return true
			}
		}

		return false
	}

	function isAuthorized(featureName) {
		if (!featureName || !featureName.trim) {
			return false
		}

		const { allowedFeatures, deniedFeatures } = payload
		if (!allowedFeatures) {
			return false
		}

		const feature = featureName.trim()
		// Denied has higher precedence than allowed
		if (
			deniedFeatures[feature] ||
			compareList(Object.keys(deniedFeatures), feature)
		) {
			return false
		}

		return !!(
			allowedFeatures[feature] ||
			compareList(Object.keys(allowedFeatures), feature)
		)
	}

	function getAvailableDataFilters(feature) {
		let list = availableDataFilters[feature] || null
		if (list) {
			return list
		}

		if (!payload) {
			return []
		}

		const { deniedFeatures, allowedFeatures } = payload
		if (
			deniedFeatures &&
			(deniedFeatures[feature] ||
				compareList(Object.keys(deniedFeatures), feature))
		) {
			availableDataFilters[feature] = []

			return []
		}

		if (!allowedFeatures) {
			availableDataFilters[feature] = []

			return []
		}

		list = []
		Object.keys(allowedFeatures).forEach(availableFeature => {
			if (!compareWithWildCard(availableFeature, feature)) {
				return
			}
			const dflist = allowedFeatures[availableFeature].dataFilter || []
			if (!Array.isArray(dflist) || !dflist.length) {
				return
			}
			list.push(...dflist)
		})

		availableDataFilters[feature] = list

		return list
	}

	return {
		isAuthorized,
		dataFilters: (featureName, ...keys) => {
			if (!featureName || !featureName.trim) {
				return []
			}

			const dfList = getAvailableDataFilters(featureName.trim())

			const dfVals = []
			dfList.forEach(df => {
				const val = nestedJSONValue(df, keys)
				if (val !== undefined) {
					dfVals.push(val)
				}
			})

			return dfVals
		},
	}
}
