/* @flow */
import React, { useEffect, useState } from 'react'
import { Close } from 'grommet-icons'
import { Box as GrommBox, Layer, TextArea } from 'grommet'
import styled from 'styled-components'
import type { Element } from 'react'
import { Box } from '../../utils/Box'
import { H2, SmallText, P, Text } from '../Typography'
import {
	Button,
	RoundedFeedbackButton,
	ButtonFeedbackIconWrapper,
} from '../Button/index'
import { Icon, IconWrapper } from '../../icons/components/Icon'
import { Spacer } from '../../utils/Spacer'
import theme from '../../utils/base-theme'
import SendIcon from '../../icons/send-feedback.svg'
import CloseIcon from '../../icons/close-feedback.svg'
import { applyMediaQueries } from '../../utils/helpers'

type Props = {
	heading: string,
	subHeading?: string,
	closelabel?: string,
	skiplabel?: string,
	confirmlabel?: string,
	noPrimaryButton?: Boolean,
	secondaryButtonLabel?: string,
	body?: Element<any> | string,
	closeable?: boolean,
	show?: boolean,
	onConfirm?: () => void,
	onClose?: () => void,
	onSkip?: () => void,
	secondaryButtonClick?: () => void,
	hideButtons?: boolean,
	isMobile?: boolean,
	isFeedback?: boolean,
	forceCloseviaButton?: boolean,
	forceSkipviaButton?: boolean,
	isLoading?: boolean,
	isDisabled?: boolean,
	noPadding?: boolean,
	size?: string | number,
	height?: number,
	icon?: string,
	boxModal?: boolean,
	sideNote?: string,
	margin?: string,
	overflow?: string,
	data?: Array<string | { id: string, message: string, date: string }>,
	sideNote?: string,
	inputHeading?: String,
	customIcon?: String,
	closeIcon?: String,
	sendIcon?: String,
	bgColor?: String,
	onSubmit?: () => void,
	value?: string,
	returnKeyValue?: boolean,
	uniqueRef?: Boolean,
	onChange?: (value: SyntheticInputEvent<> | { [string]: string }) => void,
	extendStyles?: {},
	extendStylesBody?: {},
}

const Modal = ({
	full = false,
	bgColor,
	heading,
	subHeading,
	body,
	closeable,
	show,
	closelabel = 'Close',
	skiplabel = 'Skip',
	secondaryButtonLabel,
	noPrimaryButton = false,
	confirmlabel,
	onConfirm: onConfirmProp = () => {},
	onConfirmWarning,
	onClose: onCloseProp = () => {},
	onSkip: onSkipProp = () => {},
	secondaryButtonClick: secondaryButtonClickProp = () => {},
	hideButtons,
	isMobile,
	forceCloseviaButton,
	forceSkipviaButton,
	isLoading,
	isDisabled,
	noPadding,
	icon,
	size = 'large',
	overflow,
	margin,
	boxModal,
	sideNote,
	extendStyles,
	extendStylesBody,
}: Props) => {
	const [open, setOpen] = React.useState(show)

	useEffect(() => {
		setOpen(show)
	}, [show])

	const onClose = () => {
		if (closeable) {
			const shouldPreventClose = onCloseProp()
			if (!shouldPreventClose) setOpen(false)
		}
	}

	const onButtonClose = () => {
		if (forceCloseviaButton || closeable) {
			setOpen(false)
			onCloseProp()
		}
	}

	const onConfirm = () => {
		if (!onConfirmProp()) {
			// if they return boolean true, prevent close
			setOpen(false)
		}
	}

	const onSkip = () => {
		if (!onSkipProp()) {
			// if they return boolean true, prevent close
			setOpen(false)
		}
	}

	const secondaryButtonClick = () => {
		if (!secondaryButtonClickProp()) {
			// if they return boolean true, prevent close
			setOpen(true)
		}
	}

	return (
		<>
			{open && (
				<Layer
					full={full}
					modal
					responsive={false}
					animation="fadeIn"
					closeable={closeable}
					onClickOutside={onClose}
					onEsc={onClose}
					position={isMobile ? 'bottom' : 'center'}
					margin={margin}
				>
					<GrommBox
						pad={noPadding ? 'none' : '32px 48px'}
						gap={noPadding ? 'none' : 'small'}
						width={size}
						overflow={overflow}
						background={bgColor || theme.color.white}
						style={{
							// $FlowFixMe
							...extendStyles,
						}}
					>
						<Box
							row
							justifyContent="space-between"
							alignItems="center"
						>
							<Box row alignItems="center">
								{icon && (
									<>
										<IconWrapper width={100} height={60}>
											<Icon glyph={icon} />
										</IconWrapper>
										<Spacer size={24} horizontal />
									</>
								)}
								{heading && <H2>{heading}</H2>}
							</Box>

							{closeable && (
								<Button
									extendStyles={
										!heading
											? {
													position: 'absolute',
													right: 0,
													top: 0,
													zIndex: 1,
													padding: 8,
											  }
											: {}
									}
									plain
									customIcon={<Close />}
									onClick={onClose}
								/>
							)}
						</Box>
						{subHeading && (
							<Text
								style={{
									color: theme.color.grey6,
								}}
							>
								{subHeading}
							</Text>
						)}
						<Spacer size={4} />

						{boxModal ? (
							<Box
								style={{
									flex: 1,
									overflow: 'auto',
									background: '#f8f8f8',
									border: '1px solid #000000',
									...extendStylesBody,
								}}
								padding={16}
								height={400}
							>
								{body}
							</Box>
						) : (
							<Box
								style={{
									overflow: 'auto',
									...extendStylesBody,
								}}
							>
								{body}
							</Box>
						)}
						<Spacer size={4} />
						{!hideButtons && (
							<Box
								row
								justifyContent={
									sideNote ? 'space-between' : 'flex-end'
								}
								alignItems="center"
							>
								{sideNote && (
									<Box
										width={500}
										style={{
											maxWidth: '320px',
										}}
									>
										<SmallText
											fontSize={12}
											style={{
												color: theme.color.grey6,
											}}
										>
											{sideNote}
										</SmallText>
									</Box>
								)}
								<Box row justifyContent="flex-end">
									{(closeable || forceCloseviaButton) && (
										<Button
											plain
											label={closelabel}
											onClick={onButtonClose}
											extendStyles={{
												marginRight: 16,
												fontSize: theme.fontSize.l,
											}}
										/>
									)}
									{forceSkipviaButton && (
										<Button
											outline
											label={skiplabel}
											onClick={onSkip}
											extendStyles={{
												marginRight: 16,
												fontSize: theme.fontSize.l,
											}}
										/>
									)}
									{secondaryButtonLabel && (
										<Button
											outline
											label={secondaryButtonLabel}
											onClick={secondaryButtonClick}
											disabled={isDisabled}
											extendStyles={{
												marginRight: 16,
												fontSize: theme.fontSize.l,
											}}
										/>
									)}
									{!noPrimaryButton && (
										<Button
											isLoading={isLoading}
											disabled={isDisabled}
											label={confirmlabel}
											onClick={onConfirm}
											extendStyles={{
												fontSize: theme.fontSize.l,
												color: theme.color.white,

												background:
													onConfirmWarning &&
													'#C43F3F',
												border:
													onConfirmWarning && 'none',
											}}
											primary
										/>
									)}
								</Box>
							</Box>
						)}
					</GrommBox>
				</Layer>
			)}
		</>
	)
}

Modal.defaultProps = {
	onConfirm: () => {},
	onClose: () => {},
	onSkip: () => {},
	secondaryButtonClick: () => {},
}
export const BoxSize = styled.div({
	width: '268px',
	borderRight: `1px solid ${theme.color.grey4}`,
	borderRadius: 'none',
	overflow: 'hidden',
	...applyMediaQueries(['mobile'], {
		minWidth: '209px',
	}),
})

const FeedbackModal = ({
	heading,
	noPadding,
	icon,
	overflow,
	sideNote,
	inputHeading,
	data,
	onSubmit,
	value,
	returnKeyValue,
	onChange,
	uniqueRef,
}: Props) => {
	const [showFeedback, setShowFeedback] = React.useState(false)
	const [_value, setValue] = useState(value)

	const disableCTA = _value === ''

	return (
		<>
			<Box overflow>
				<RoundedFeedbackButton
					action
					primary
					rounded
					customIcon={
						<ButtonFeedbackIconWrapper>
							<Icon glyph={icon} />
						</ButtonFeedbackIconWrapper>
					}
					onClick={() => setShowFeedback(true)}
				/>
			</Box>
			{showFeedback && (
				<Layer
					modal
					responsive={false}
					animation="fadeIn"
					onClickOutside={() => setShowFeedback(false)}
					onEsc={() => setShowFeedback(false)}
					position="bottom-right"
					margin={{
						right: '16',
						bottom: '16',
					}}
					plain
					style={{
						border: `1px solid ${theme.color.grey4}`,
						borderRadius: '4px',
						opacity: 1,
						boxShadow: '4px 4px 4px #00000029',
					}}
				>
					<GrommBox
						pad={noPadding ? 'none' : '16px 32px'}
						gap={noPadding ? 'none' : 'none'}
						width="400px"
						maxHeight="464px"
						overflow={overflow}
						background={theme.color.white1}
						boxShadow="4px 4px 4px #00000029"
						borderRadius="4px"
						opacity={0}
						style={{
							border: `1px solid ${theme.color.grey4}`,
						}}
					>
						<Box
							row
							justifyContent="space-between"
							alignItems="center"
							style={{
								height: '32px',
							}}
						>
							<Box row alignItems="center">
								{heading && (
									<H2
										style={{
											lineHeight: theme.lineHeight.l,
										}}
									>
										{heading}
									</H2>
								)}
							</Box>
							<Button
								plain
								customIcon={
									<IconWrapper size={16}>
										<Icon glyph={CloseIcon} />
									</IconWrapper>
								}
								onClick={() => setShowFeedback(false)}
							/>
						</Box>
						<Box style={{ overflow: 'auto' }}>
							<>
								<P
									style={{
										color: theme.color.grey6,
										fontSize: theme.fontSize.xs,
									}}
								>
									{sideNote}
								</P>
								{data.length > 0 && (
									<>
										<Box
											size={336}
											height={208}
											style={{
												overflow: 'auto',
												background: theme.color.white,
												boxShadow:
													'0px 4px 4px #00000029',
												border: `1px solid ${theme.color.grey4}`,
												borderRadius: '4px',
												opacity: 1,
												padding: '16px 32px',
												marginTop: '16px',
												marginBottom: '16px',
											}}
										>
											{data.map(m => (
												<Box
													key={m.id}
													style={{
														background:
															theme.color.white3,
														borderRadius: '4px',
														opacity: 1,
														padding:
															'4px 12px 4px 16px',
														marginBottom: '16px',
														width: '272px',
														letterSpacing: '0px',
														fontSize:
															theme.fontSize.m,
														lineHeight: '1.35',
														color:
															theme.color.accent2,
													}}
												>
													{m.message}
													<Box>
														<span
															style={{
																font:
																	theme.fonts
																		.regular,
																fontSize:
																	theme
																		.fontSize
																		.xxs,
																color:
																	theme.color
																		.grey6,
																textAlign:
																	'end',
																paddingTop:
																	'4px',
															}}
														>
															SENT {m.date}
														</span>
													</Box>
												</Box>
											))}
										</Box>
										<P
											style={{
												color: theme.color.accent2,
											}}
										>
											{inputHeading}
										</P>
									</>
								)}
								<Box
									row
									height={96}
									style={{
										background: theme.color.white,
										boxShadow: '0px 4px 8px #00000029',
										border: `1px solid ${theme.color.grey4}`,
										borderRadius: '4px',
										opacity: 1,
										marginTop: '12px',
									}}
								>
									<BoxSize>
										<TextArea
											value={_value}
											onChange={e => {
												setValue(e.target.value)
												onChange(
													returnKeyValue
														? {
																[uniqueRef]:
																	e.target
																		.value,
														  }
														: e
												)
											}}
											style={{
												height: '94px',
												border: 'none',
												font: theme.fontSize.m,
												letterSpacing: '0px',
												color: theme.color.accent2,
												opacity: 1,
												boxShadow: 'none',
												lineHeight: theme.lineHeight.m,
												resize: 'none',
											}}
										/>
									</BoxSize>
									<Button
										plain
										disabled={disableCTA}
										customIcon={
											<IconWrapper
												size={32}
												style={{
													margin: '31px 16px',
												}}
											>
												<Icon
													glyph={SendIcon}
													color={
														disableCTA
															? theme.color.grey8
															: theme.color
																	.primary
													}
												/>
											</IconWrapper>
										}
										onClick={() => {
											setShowFeedback(false)
											onSubmit(_value)
											setValue('')
										}}
									/>
								</Box>
							</>
						</Box>
					</GrommBox>
				</Layer>
			)}
		</>
	)
}

FeedbackModal.defaultProps = {
	onConfirm: () => {},
	onClose: () => {},
	onSkip: () => {},
	onChange: () => {},
}

export { Modal, FeedbackModal }
