import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AdminDuc } from 'core-app/modules/Admin/duc'
import { KeyValueSegment } from 'core-app/modules/Admin/components/KeyValueSegment'
import { Box } from 'ui-lib/utils/Box'
import { ButtonWithNoBorder } from 'ui-lib/components/Button'
import theme from 'ui-lib/utils/base-theme'
import LeftArrowIcon from 'ui-lib/icons/arrow_back.svg'
import RightArrowIcon from 'ui-lib/icons/arrow_forward_black_24dp.svg'
import { Document, Page } from 'react-pdf'
import { Icon } from 'ui-lib/icons/components/Icon'

const WeighbridgeDetails = ({ assetID }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const weighbridgeDetails = useSelector(
		AdminDuc.selectors.getWeighbridgeDetails
	)

	const {
		name,
		model,
		manufacturer,
		capacity,
		capacityUOM,
		meta = {},
	} = weighbridgeDetails
	const { files } = meta

	const [pageNumber, setPageNumber] = useState(1)
	const [totalPages, setTotalPages] = useState(null)

	function onDocumentLoadSuccess({ numPages }) {
		setTotalPages(numPages)
	}

	return (
		<>
			<Box row justifyContent="flex-end">
				<ButtonWithNoBorder
					label={t('common.edit').toUpperCase()}
					onClick={() => {
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.ADMIN$SUBMODULEWDOCREFERENCE,
								{
									rootModule: 'assets',
									subModule: 'weighbridges',
									action: 'edit-asset',
									documentReference: assetID,
								}
							)
						)
					}}
				/>
			</Box>

			<Box
				style={{
					border: `1px solid ${theme.color.grey7}`,
					borderRadius: '4px',
				}}
			>
				<KeyValueSegment label={t('viewAsset.name')} value={name} />
				<KeyValueSegment
					label={t('viewAsset.manufacturer')}
					value={manufacturer}
				/>
				<KeyValueSegment label={t('viewAsset.model')} value={model} />
				<KeyValueSegment
					label={t('viewAsset.capacity')}
					value={`${capacity} ${capacityUOM}`}
				/>
				<KeyValueSegment
					label={t('viewAsset.lastcalibrated')}
					value={moment(meta.certificate.lastCaliberated).format(
						'DD-MM-YYYY'
					)}
				/>
				<KeyValueSegment
					label={t('viewAsset.calibrationvalidity')}
					value={moment(meta.certificate.calibrationValidity).format(
						'DD-MM-YYYY'
					)}
				/>
				<KeyValueSegment
					label={t('viewAsset.calibratedby')}
					value={meta.certificate.calibratedBy}
				/>
				<KeyValueSegment
					label={t('viewAsset.calibratedcertificate')}
					value={
						(files && files.length > 0 && files[0]?.meta.fullURL) ||
						'N/A'
					}
				/>
			</Box>
			{files && files.length > 0 && (
				<div>
					<div
						style={{
							marginTop: 25,
							padding: '40px 0',
							background: theme.color.grey19,
						}}
					>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<div
								style={{
									padding: '5%',
									width: '1000%',
								}}
							>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										padding: '0 0 30px 0',
										justifyContent: 'space-between',
									}}
								>
									<div
										style={{
											display: 'flex',
											alignItems: 'end',
										}}
									>
										<div
											tabIndex={0}
											role="button"
											style={{
												width: 48,
												height: 48,
												borderRadius: 10,
												color:
													pageNumber > 1
														? theme.color.white
														: theme.color.grey15,
												background:
													pageNumber > 1
														? theme.color.grey15
														: theme.color.grey18,
												border: `1px solid ${theme.color.grey15}`,
												cursor:
													pageNumber > 1
														? 'pointer'
														: 'unset',
												pointerEvents:
													pageNumber > 1
														? 'auto'
														: 'none',
											}}
											onClick={() => {
												setPageNumber(pageNumber - 1)
											}}
											onKeyPress={() => {
												setPageNumber(pageNumber - 1)
											}}
										>
											<Icon glyph={LeftArrowIcon} />
										</div>
										<span
											style={{
												marginLeft: 10,
												color: theme.color.grey15,
											}}
										>
											{t('contracts.prevPage')}
										</span>
									</div>
									<div
										style={{
											fontSize: 24,
											fontWeight: 500,
											marginLeft: -15,
											color: theme.color.accent2,
										}}
									>
										Page {pageNumber} of {totalPages}
									</div>
									<div
										style={{
											display: 'flex',
											alignItems: 'end',
										}}
									>
										<span
											style={{
												marginRight: 10,
												color: theme.color.grey15,
											}}
										>
											{t('contracts.nextpage')}
										</span>
										<div
											tabIndex={0}
											role="button"
											style={{
												width: 48,
												height: 48,
												borderRadius: 10,
												color:
													pageNumber < totalPages
														? theme.color.white
														: theme.color.grey15,
												background:
													pageNumber < totalPages
														? theme.color.grey15
														: theme.color.grey18,
												border: `1px solid ${theme.color.grey15}`,
												cursor:
													pageNumber < totalPages
														? 'pointer'
														: 'unset',
												pointerEvents:
													pageNumber < totalPages
														? 'auto'
														: 'none',
											}}
											onClick={() => {
												setPageNumber(pageNumber + 1)
											}}
											onKeyPress={() => {
												setPageNumber(pageNumber + 1)
											}}
										>
											<Icon glyph={RightArrowIcon} />
										</div>
									</div>
								</div>
								<Document
									file={{
										url:
											files &&
											files.length > 0 &&
											files[0].meta.fullURL,
										withCredentials: true,
									}}
									onLoadSuccess={onDocumentLoadSuccess}
								>
									<Page pageNumber={pageNumber} />
								</Document>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}
export { WeighbridgeDetails }
