import React from 'react'
import { Box } from 'ui-lib/utils/Box'
import { Label, TableTitle } from 'ui-lib/components/Typography'
import { ButtonWithNoBorder } from 'ui-lib/components/Button'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { SortDown, SortReset } from 'ui-lib/components/Table'
import { getNextSortOrder } from 'core-app/utils/helpers'
import { getDateByFormat } from 'core-app/utils/date'
import { getIn } from 'timm'
import { SortableKeys, TABLE_ALIAS } from '../config'

export const getDocumentColumnConfig = ({
	type,
	docType,
	sortsMap,
	onChange,
	t,
	partnerList,
}) => {
	return [
		{
			property: 'date',
			header: (
				<Box
					row
					alignItems="center"
					style={{ cursor: 'pointer' }}
					onClick={() => {
						if (SortableKeys.includes('date')) {
							onChange('initiate_sort', {
								type,
								docType,
								field: 'date',
								order: getNextSortOrder(sortsMap.date),
							})
						}
					}}
				>
					{SortableKeys.includes('date') && (
						<IconWrapper
							width={10}
							margin="0 5px"
							sortUp={sortsMap.date === 'asc'}
							sortDown={sortsMap.date === 'desc'}
						>
							<Icon
								rotate180={sortsMap.date === 'asc'}
								glyph={sortsMap.date ? SortDown : SortReset}
							/>
						</IconWrapper>
					)}
					<TableTitle>{t(TABLE_ALIAS.date)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { createdAt } = datum

				return <Label as="span">{getDateByFormat(createdAt)}</Label>
			},
		},
		{
			property: 'smallholder',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.smallholder)}</TableTitle>
				</Box>
			),
			render: datum => {
				const smallholderID =
					getIn(datum, ['meta', 'smallholderID']) || '---'
				const smallHolder = partnerList.filter(
					partner => partner.id === smallholderID
				)
				const smallHolderName = getIn(smallHolder, [0, 'name']) || '---'

				return <Label as="span">{smallHolderName}</Label>
			},
		},

		{
			property: 'location',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.location)}</TableTitle>
				</Box>
			),
			render: datum => {
				const smallholderID =
					getIn(datum, ['meta', 'smallholderID']) || '---'

				const smallHolder = partnerList.filter(
					partner => partner.id === smallholderID
				)
				const smallHolderState =
					getIn(smallHolder, [0, 'primaryAddress', 'state']) || '---'

				return <Label as="span">{smallHolderState}</Label>
			},
		},

		{
			property: 'callToAction',
			render: datum => {
				return (
					<ButtonWithNoBorder
						label={t('common.viewBtn')}
						onClick={() =>
							onChange('open_document', {
								row: datum,
							})
						}
					/>
				)
			},
		},
	]
}
