import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { removeLast, getIn } from 'timm'
import { MainRouteDuc } from 'core-app/routes/duc'
import { PlantationDuc } from 'core-app/modules/Plantation/duc'
import { AppDuc } from 'core-app/modules/App/duc'
import { KeyValueSegment } from 'core-app/shared/components/KeyValueSegment'
import { Title } from 'core-app/shared/components/Title'
import { getDateByFormat } from 'core-app/utils/date'
import { chunkArrayInGroups } from 'ui-lib/utils/helpers'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { BoxShadowWrapper } from 'ui-lib/components/Card'
import { Button, ButtonIconWrapper } from 'ui-lib/components/Button'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import {
	SCHEDULE_STATUS,
	STATUS_BACKGROUND,
	STATUS_TEXT_COLOR,
} from 'core-app/modules/Plantation/config'
import { Label } from 'ui-lib/components/Typography'
import { Line } from 'core-app/modules/Settings/containers/MainDashboard'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import VerifiedIcon from 'ui-lib/icons/verified.svg'

const KeyValueSegmentWithClickableLink = ({ name, description, onClick }) => {
	return (
		<>
			<Box row padding="2px 0px">
				<Box width={160}>
					<Label>{name} :</Label>
				</Box>
				<Box style={{ marginLeft: 20, width: 250 }} flex row>
					<Label
						link={description}
						style={{
							color: 'rgb(38, 59, 151)',
						}}
						onClick={onClick}
					>
						{description}
					</Label>
				</Box>
			</Box>
			<Spacer size={8} />
		</>
	)
}

const getPairsAndOddOne = arr => {
	let targetArr = arr
	let lastOdd = null
	if (targetArr.length % 2 > 0) {
		lastOdd = targetArr[targetArr.length - 1]
		targetArr = removeLast(targetArr)
	}

	return { pairs: chunkArrayInGroups(targetArr, 5), lastOdd }
}

const ALIASES_KEYS = {
	date: 'plantationViewTicket.date',
	tCode: 'plantationViewTicket.tractorCode',
	assigneeName: 'tdmPreviewDetails.driverName',
	number: 'plantationColumnConfig.tripNo',
	vehicleNumber: 'createWork.vehicleNumber',
	product: 'plantationViewTicket.product',
	quantity: 'plantationViewTicket.quantity',
	dropPoint: 'plantationViewTicket.dropPoint',
	tripDuration: 'plantationViewTicket.tripDuration',
}

const { pairs, lastOdd } = getPairsAndOddOne(Object.keys(ALIASES_KEYS))

const TripDetails = ({ values, isMobile, t }) => {
	return (
		<>
			<Box>
				<Box justifyContent="space-between" row={!isMobile}>
					{pairs.map((pair, index) => (
						<>
							<Box>
								{pair.map(_key => (
									<KeyValueSegment
										key={_key}
										name={t(ALIASES_KEYS[_key])}
										description={
											_key === 'date'
												? getDateByFormat(values[_key])
												: values[_key]
										}
										userStatus={values.userStatus}
										t={t}
									/>
								))}
							</Box>
							{index % 2 === 0 && index !== pairs.length && (
								<Spacer size={16} horizontal />
							)}
						</>
					))}
				</Box>
				{lastOdd && (
					<Box>
						<KeyValueSegment
							name={t(ALIASES_KEYS[lastOdd])}
							description={values[lastOdd]}
						/>
					</Box>
				)}
			</Box>
		</>
	)
}

const PreviewTrips = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const location = useSelector(PlantationDuc.selectors.location)

	const { payload = {} } = location
	const { rootModule } = payload

	const ticketInformation = useSelector(
		PlantationDuc.selectors.fetchTripInformation
	)
	const assets = useSelector(AuthDuc.selectors.getOrgAssets)

	const binList = getIn(assets, ['storageunits']) || []
	const bin = binList.filter(_bin => _bin.id === ticketInformation.binName)
	const binName = getIn(bin, [0, 'name']) || 'NA'
	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.records'),
			name: rootModule,
			isActive: true,
		},
		{
			label: t('breadcrumb.trips'),
			name: 'home',
			isActive: false,
		},
	]

	/** Handlers */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === rootModule) {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.PLANTATION$SUBROOT,
					{ rootModule }
				)
			)
		}
	}
	const { isSynced } = ticketInformation

	return (
		<Box padding={8} width="100%" height="100%">
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Title title={t('plantationViewTicket.viewTicket')} />
			<BoxShadowWrapper>
				{isSynced && (
					<Box
						padding="16px 16px 0px 0px"
						style={{ flex: 1 }}
						alignItems="flex-end"
					>
						<IconWrapper size={25} color="#13835A">
							<Icon glyph={VerifiedIcon} />
						</IconWrapper>
					</Box>
				)}
				<Box
					row
					padding="16px"
					style={{
						borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
						width: '100%',
					}}
				>
					<Box
						style={{
							width: '70%',
						}}
					>
						<TripDetails
							values={ticketInformation}
							isMobile={isMobile}
							t={t}
						/>
					</Box>
					<Box
						style={{
							width: '30%',
						}}
						justifyContent="flex-end"
						row
					>
						<Box
							row
							alignItems="center"
							style={{
								backgroundColor:
									STATUS_BACKGROUND[
										ticketInformation.tripStatus
									],
								padding: '8px 16px',
								margin: '0 0 8px',
								borderRadius: '2px',
								opacity: 1,
								height: 'fit-content',
								width: 'fit-content',
							}}
						>
							<Label
								bold
								style={{
									color:
										STATUS_TEXT_COLOR[
											ticketInformation.tripStatus
										],
								}}
							>
								{t('viewSchedule.status')} :
							</Label>
							<Label
								style={{
									marginLeft: 48,
									color:
										STATUS_TEXT_COLOR[
											ticketInformation.tripStatus
										],
								}}
							>
								{t(
									SCHEDULE_STATUS[
										ticketInformation.tripStatus
									]
								)}
							</Label>
						</Box>
					</Box>
				</Box>
				<Line />
				<Box row padding="16px" justifyContent="space-between">
					<KeyValueSegmentWithClickableLink
						name={t('auditReport.binNumber')}
						description={binName}
						onClick={() =>
							dispatch(
								MainRouteDuc.creators.switchPage(
									MainRouteDuc.types
										.PLANTATION$DOCUMENTREFERENCETYPE,
									{
										rootModule: 'document',
										action: 'view',
										documentReference:
											ticketInformation.binID,
										documentType: 'bin',
									}
								)
							)
						}
					/>
					<KeyValueSegmentWithClickableLink
						name={t('auditReport.doNumber')}
						description={ticketInformation.entityNumber}
						onClick={() =>
							dispatch(
								MainRouteDuc.creators.switchPage(
									MainRouteDuc.types
										.TRADE_DOCUMENT_MANAGER$VIEWWDOCREFERENCE,
									{
										rootModule: 'delivery-order',
										documentReference:
											ticketInformation.entityID,
									},
									{},
									{},
									true
								)
							)
						}
					/>
				</Box>
			</BoxShadowWrapper>
			<Box padding="25px 2px" style={{ maxWidth: 150 }}>
				<Button
					label={t('common.back')}
					rounded
					customIcon={
						<ButtonIconWrapper lightBG>
							<Icon glyph={LeftArrowIcon} />
						</ButtonIconWrapper>
					}
					onClick={() =>
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.PLANTATION$SUBROOT,
								{ rootModule, type: 'driver' }
							)
						)
					}
				/>
			</Box>
		</Box>
	)
}

export { PreviewTrips }
