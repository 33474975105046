import { all, takeLatest, put, select } from 'redux-saga/effects'
import LogHelper from 'core-app/utils/logger'
import { AppDuc } from 'core-app/modules/App/duc'
import { CookieDuc } from 'core-app/modules/App/cookieDuc'
import { getCoreEndPoint } from 'core-app/config'
import { getIn, omit, merge } from 'timm'
import querySerializer from 'query-string'
import { AuthDuc } from 'core-app/modules/Auth/duc'

import {
	transformFilterStringsToBEQueries,
	transformSortStringsToBEQueries,
	extractSortQueries,
	responseDocsMapper,
} from 'core-app/shared/helpers'
import { isEmptyObject } from 'core-app/utils/helpers'

import { CallWithRefreshCheck } from 'core-app/modules/Auth/AuthSaga'
import { TripsDuc } from './duc'

const logger = LogHelper('client:tripsSaga')

const documentTypes = ['completed', 'in-progress']

const PAGINATION_LIMIT = 20

const extractUserIDsFromResponses = (responses = [], type = '') => {
	const uniqueOrgIds = []

	const allLists = responses.reduce((agg, resp) => {
		return agg.concat(getIn(resp, ['data', 'list']) || [])
	}, [])

	if (type === 'users') {
		allLists.forEach(list => {
			if (!uniqueOrgIds.includes(list.clientID)) {
				uniqueOrgIds.push(list.clientID)
			}
			if (!uniqueOrgIds.includes(list.clientID)) {
				uniqueOrgIds.push(list.clientID)
			}
		})
	}

	return uniqueOrgIds
}

function* fetchDocuments(action) {
	try {
		const { locationState = {}, skipGlobalLoader } = action

		if (!skipGlobalLoader)
			yield put(AppDuc.creators.showGlobalLoader('document-listing'))

		yield put(TripsDuc.creators.setDocumentLoading(true))
		yield put(AuthDuc.creators.fetchOrgsUser())

		const targetTypes = documentTypes
		const { query } = locationState
		const existingQueryFromUrl = query || {}
		const frontendTargetQuery = {} // this would appear in search bar
		let backendTargetQuery = {} // this would go to backend api call

		const paginationQuery = {
			activeIndex: existingQueryFromUrl.activeIndex
				? existingQueryFromUrl.activeIndex
				: 0,
			limit: Math.min(
				existingQueryFromUrl.limit || PAGINATION_LIMIT,
				PAGINATION_LIMIT
			),
			nextIndex: existingQueryFromUrl.nextIndex,
		}
		if (paginationQuery.limit) {
			backendTargetQuery.limit = paginationQuery.limit
		}

		if (paginationQuery.activeIndex > 0 && paginationQuery.nextIndex) {
			backendTargetQuery.startID = paginationQuery.nextIndex
		}

		// prepare the filter query
		const filterQueries =
			omit(existingQueryFromUrl, [
				'sort',
				'q',
				'activeIndex',
				'limit',
				'nextIndex',
			]) || {}

		if (!isEmptyObject(filterQueries)) {
			// form the backend queries form the object
			backendTargetQuery = merge(
				backendTargetQuery,
				transformFilterStringsToBEQueries(filterQueries)
			)
		}
		const sortQuery = existingQueryFromUrl.sort || []
		const _sortKeys = Array.isArray(sortQuery) ? sortQuery : [sortQuery]
		let _sortQuery = _sortKeys && transformSortStringsToBEQueries(_sortKeys)
		_sortQuery = getIn(_sortQuery, ['trips']) || ''
		const __query = {
			...backendTargetQuery,
		}

		const selectedCPID = yield select(CookieDuc.selectors.getSelectedCPID)

		let requestUrl = ''
		if (selectedCPID) {
			requestUrl = `${getCoreEndPoint()}trips/childorg/${selectedCPID}`
		} else {
			requestUrl = `${getCoreEndPoint()}organizations/-/trips`
		}

		const callsPipeLine = targetTypes.map(_type => {
			return CallWithRefreshCheck(
				`${requestUrl}?status=${_type}&${querySerializer.stringify({
					...__query,
					...{ sort: _sortQuery },
				})}`
			)
		})
		const origResponse = yield all(callsPipeLine)
		const userIDs = extractUserIDsFromResponses(origResponse, 'users')
		if (userIDs.length) yield put(AuthDuc.creators.fetchOrgsUser(userIDs))

		const responses = responseDocsMapper(targetTypes, origResponse)
		const sortQueriesFromBE = extractSortQueries(responses, _sortKeys)

		if (sortQueriesFromBE.length) {
			frontendTargetQuery.trips = sortQueriesFromBE
		}

		const completedTrips = getIn(responses, ['completed', 'list']) || []
		const InProgressTrips = getIn(responses, ['in-progress', 'list']) || []
		const response = {
			completed: completedTrips,
			'in-progress': InProgressTrips,
		}
		yield put(TripsDuc.creators.fetchDocumentSuccess(response))
		yield put(TripsDuc.creators.setActiveSorts(frontendTargetQuery))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(TripsDuc.creators.setDocumentLoading(false))
		yield put(AppDuc.creators.hideGlobalLoader('document-listing'))
	}
}

function* viewDocument(action) {
	const { documentReference } = action
	try {
		yield put(TripsDuc.creators.setDocumentViewLoading(true))
		yield put(AuthDuc.creators.fetchPartnerOrgs())

		const selectedCPID = yield select(CookieDuc.selectors.getSelectedCPID)

		let requestUrl = ''
		if (selectedCPID) {
			requestUrl = `${getCoreEndPoint()}organizations/-/trips/${documentReference}/childorg/${selectedCPID}`
		} else {
			requestUrl = `${getCoreEndPoint()}organizations/-/trips/${documentReference}`
		}
		const { data } = yield CallWithRefreshCheck(requestUrl)
		const entities = getIn(data, ['meta', 'entities']) || {}
		const entitiesList = Object.assign([], Object.values(entities))
		const callsPipeLine = entitiesList.map(entity => {
			return CallWithRefreshCheck(
				`${getCoreEndPoint()}entities/${entity.id}`
			)
		})
		const origResponse = yield all(callsPipeLine)
		yield put(AuthDuc.creators.fetchOrgsUser([data.clientID]))

		const entityListing = origResponse.map(entitydata => entitydata.data)
		const tripsData = {
			data,
			entityListing,
		}
		yield put(TripsDuc.creators.fetchDocumentViewSuccess(tripsData))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(TripsDuc.creators.setDocumentViewLoading(false))
	}
}

export default function* TripsSaga() {
	try {
		yield all([
			takeLatest(TripsDuc.creators.fetchDocuments().type, fetchDocuments),
			takeLatest(TripsDuc.creators.viewDocument().type, viewDocument),
		])
	} catch (e) {
		logger.error(e)
	}
}
