import fetch from 'isomorphic-unfetch'
import { omit } from 'timm'
/*
	eslint-disable compat/compat
*/

let refreshTokenCount = 0

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
async function parseJSON(response) {
	if (response.status === 401) {
		// do refresh only if in the permissible limit of 2
		// if not, then let it proceed as a valid error case.
		if (refreshTokenCount <= 2) {
			refreshTokenCount += 1

			return Promise.resolve({ retryWithRefreshToken: true })
		}

		refreshTokenCount = 0
	}
	if (response.status === 204 || response.status === 205) {
		return null
	}

	if (response.status >= 200 && response.status < 300) {
		return response.json()
	}

	let error = new Error(response.statusText)
	error.status = response.status
	error.response = response

	try {
		const errorResp = await response.json()
		let errors = errorResp.error || errorResp.errors
		if (errors) {
			if (Array.isArray(errors)) errors = errors.join('\n')
			error = new Error(errors)
			error.status = response.status
			error.response = response
			error.responseJson = errorResp
		}
	} catch (e) {} // eslint-disable-line no-empty

	throw error
}

/**
 * Parses the response in text format returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
async function parseText(response) {
	if (response.status === 401) {
		// do refresh only if in the permissible limit of 2
		// if not, then let it proceed as a valid error case.
		if (refreshTokenCount <= 2) {
			refreshTokenCount += 1

			return Promise.resolve({ retryWithRefreshToken: true })
		}

		refreshTokenCount = 0
	}

	if (response.status === 204 || response.status === 205) {
		return null
	}
	if (response.status >= 200 && response.status < 300) {
		return response.text()
	}

	// Need to parse the error response as well or front end cant read it
	const error = new Error(response.statusText)
	error.response = await response.text()
	throw error
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(
	url,
	options = {},
	responseType = 'json',
	timeout = 300000
) {
	let defaultHeaders = {
		'content-type': 'application/json',
		...options.headers,
	}

	if (options.formData) {
		// fetch api add proper multi-part headers based on the data type which is why we do not need explicitly.
		defaultHeaders = omit(defaultHeaders, ['content-type'])
	}

	const _options = {
		...options,
		headers: defaultHeaders,
		timeout: 120000,
	}

	return Promise.race([
		fetch(
			url,
			_options.credentials
				? _options
				: { ..._options, credentials: 'include' }
		).then(responseType === 'text' ? parseText : parseJSON),
		/** new Promise((_, reject) =>
			setTimeout(
				() => reject(new Error('Network Timeout. Please try again.')),
				timeout
			)
		), */
	]).catch(e => {
		// if network connectivity issue, adjust proper error message
		if (e.message === 'Failed to fetch') {
			throw new Error(
				'Unable to reach our Servers.  Please check your network connection.'
			)
		}

		throw e
	})
}
