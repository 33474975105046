/** Plugged from https://frontend-cookbook.ack.ee/pages/implementation/NetworkStatusAndSagaChannels.html */
import LogHelper from 'core-app/utils/logger'
import { eventChannel } from 'redux-saga'
import { takeLatest, put } from 'redux-saga/effects'
import { AppDuc } from './duc'

const logger = LogHelper('client:networkSaga')

const Status = {
	ONLINE: true,
	OFFLINE: false,
}

function createConnectionChannel() {
	const connection =
		navigator.connection ||
		navigator.mozConnection ||
		navigator.webkitConnection

	if (!connection) {
		return eventChannel(emit => {
			function onlineHandler() {
				emit(Status.ONLINE)
			}

			function offlineHandler() {
				emit(Status.OFFLINE)
			}

			window.addEventListener('online', onlineHandler)
			window.addEventListener('offline', offlineHandler)

			return () => {
				window.removeEventListener('online', onlineHandler)
				window.removeEventListener('offline', offlineHandler)
			}
		})
	}

	return eventChannel(emit => {
		let prevStatus = null

		function handleConnectionStatusChange() {
			const status =
				connection.downlink === 0 ? Status.OFFLINE : Status.ONLINE

			if (status !== prevStatus) {
				emit(status)

				prevStatus = status
			}
		}

		connection.addEventListener('change', handleConnectionStatusChange)

		return () =>
			connection.removeEventListener(
				'change',
				handleConnectionStatusChange
			)
	})
}

function* handleConnectionChange(status) {
	try {
		switch (status) {
			case Status.OFFLINE:
				yield put(AppDuc.creators.setNetworkConnectivityStatus(false))
				break
			case Status.ONLINE:
				yield put(AppDuc.creators.setNetworkConnectivityStatus(true))
				break
			default:
				break
		}
	} catch (e) {
		logger.log(e)
	}
}

export default function* handleNetworkConnection() {
	const connectionChannel = yield createConnectionChannel()

	yield takeLatest(connectionChannel, handleConnectionChange)
}
