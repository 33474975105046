import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MainRouteDuc } from 'core-app/routes/duc'
import { KeyValueSegment } from 'core-app/modules/Admin/components/KeyValueSegment'
import { Card } from 'ui-lib/components/Card'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Label, H3 } from 'ui-lib/components/Typography'
import { Box } from 'ui-lib/utils/Box'
import theme from 'ui-lib/utils/base-theme'
import { ProductsDuc } from 'core-app/modules/Products/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { getIn } from 'timm'

const ViewProduct = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const activeProduct = useSelector(
		ProductsDuc.selectors.getProductInformation
	)
	const actor = useSelector(AuthDuc.selectors.getActor)

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.products'),
			name: 'products',
			isActive: true,
		},
		{
			label: t('breadcrumb.details'),
			name: 'details',
			isActive: false,
		},
	]

	/** Handlers to react on actions */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'products') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.PRODUCTS)
			)
		}
	}

	return (
		<Box padding={8} width="100%" height="100%">
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Label bold style={{ fontSize: '32px' }}>
				{t('viewProduct.title')}
			</Label>
			<Card height="auto" textAlign="left" style={{ margin: '32px 0' }}>
				<Box
					padding={8}
					style={{
						backgroundColor: theme.color.accent3,
					}}
				>
					<H3
						bold
						color={theme.color.accent2}
						style={{
							position: 'relative',
							right: '-5px',
						}}
					>
						{t('viewProduct.cardHeading')}
					</H3>
				</Box>
				<Box style={{ padding: '8px 22px 30px 2px' }}>
					<KeyValueSegment
						label={t('products.productName')}
						value={
							getIn(activeProduct, ['product', 'name']) || '---'
						}
					/>
					<KeyValueSegment
						label={t('products.productID')}
						value={
							getIn(activeProduct, ['product', 'code']) || '---'
						}
					/>
					<KeyValueSegment
						label={t('products.hsn')}
						value={
							getIn(activeProduct, ['product', 'hsnsac']) || '---'
						}
					/>
					<KeyValueSegment
						label={t('products.availableQty')}
						value={
							`${getIn(activeProduct, ['availableQty'])} ${
								actor.includes('palmoil_plantation')
									? 'Nos'
									: getIn(activeProduct, ['product', 'uom'])
							}` || '---'
						}
					/>
				</Box>
			</Card>
		</Box>
	)
}

export { ViewProduct }
