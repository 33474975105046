import React from 'react'
import { useSelector } from 'react-redux'
import { NotFoundBlock } from 'core-app/modules/App/components/Error'
import { EndManufacturerDuc } from './duc'
import { MainDashboard } from './containers/MainDashboard'

const modulesMap = {
	dashboard: MainDashboard,
}

const StorageCompany = () => {
	const activeModule = useSelector(EndManufacturerDuc.selectors.activeModule)
	const Component = modulesMap[activeModule] || NotFoundBlock
	// if we are here, we have a submodule, route it to the module

	return <Component />
}

export default StorageCompany
