import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MainRouteDuc } from 'core-app/routes/duc'
import { PartnerDuc } from 'core-app/modules/Partner/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { AppDuc } from 'core-app/modules/App/duc'
import { useDebouncedCallback, isEmptyObject } from 'core-app/utils/helpers'
import { CookieDuc } from 'core-app/modules/App/cookieDuc'
import { AUTH_COOKIE_KEYS, currentPlatform } from 'ui-lib/utils/config'
import { Title } from 'core-app/shared/components/Title'
import { Storage } from 'ui-lib/utils/storage'
import { columnConfig as getColumnConfig } from 'core-app/modules/Partner/components/Columns'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Modal } from 'ui-lib/components/Modal'
import { getIn, addLast } from 'timm'
import { CheckBox } from 'grommet'
import {
	ButtonIconWrapper,
	Button,
	ButtonWithNoBorder,
} from 'ui-lib/components/Button'
import { P } from 'ui-lib/components/Typography'
import { Label } from 'ui-lib/components/Label'
import { Table } from 'ui-lib/components/Table'
import { ErrorBlock } from 'ui-lib/components/ErrorBlocks'
import { TransparentBgSpinner } from 'ui-lib/components/Spinner/TransparentBgSpinner'
import { Tabs, DefaultTabIndicator } from 'ui-lib/components/Tabs'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Icon } from 'ui-lib/icons/components/Icon'
import { Input, SuggestionsInput } from 'ui-lib/components/Input'
import { SelectSearch } from 'ui-lib/components/Select'
import { getStates, getCountries } from 'country-state-picker'
import theme from 'ui-lib/utils/base-theme'
import PhoneInput from 'react-phone-input-2'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import PartnerIcon from 'ui-lib/icons/partner.svg'
import styled from 'styled-components'
import { SUPPLYBASE_MEMBERS_TAB_CONFIG } from '../config'

export const CTAWrapper = styled(Box)(p => ({
	padding: 10,
	minHeight: 62,
	background: p.theme.color.white,
	borderBottomLeftRadius: 4,
	borderBottomRightRadius: 4,
	color: p.theme.color.primary,
	borderTop: '1px solid #ccc',
	display: 'flex',
	flexDirection: 'row',
	border: `1px solid ${p.theme.color.grey4}`,
}))

const getTabComponents = ({
	type,
	rows,
	orgDetails,
	onChange,
	loadingStatus,
	currentOrgID,
	activeFilters,
	t,
	selectedCPID,
	isMill,
	stateList,
	setStateList,
	countryFilter,
	stateFilter,
	statusFilter,
	orgTypeFilter,
	setStateFilter,
	setStatusFilter,
	setCountryFilter,
	setOrgTypeFilter,
	isMobile = false,
}) => ({ name, key }) => () => {
	const statusList = [
		{ name: 'deactivated', label: t('partnerStatusLabel.deactivated') },
		{ name: 'blacklisted', label: t('partnerStatusLabel.blacklisted') },
	]

	const countries = getCountries()
	const countryList = []

	countries.forEach(value => {
		if (value.code !== 'gg') {
			countryList.push({
				name: value.code,
				label: value.name,
			})
		}
	})

	const getStateCode = code => {
		const _stateList = getStates(code)
		const stateDataList = _stateList.map(state => {
			return {
				name: state,
				label: state,
			}
		})
		setStateList(stateDataList)
	}

	return (
		<Box style={{ overflow: 'auto', position: 'relative' }}>
			{loadingStatus && <TransparentBgSpinner />}
			{!(
				name === 'supplybaseMembers' || name === 'globalPartnersList'
			) && (
				<Box style={{ background: '#fff' }} padding={8}>
					<Box
						row={!isMobile}
						justifyContent="space-between"
						padding={8}
					>
						<Box margin="20px 0 0">
							<SelectSearch
								required
								placeholder={t('onboarding.countryPlaceHolder')}
								value={countryFilter}
								options={countryList}
								onChange={value => {
									if (!isEmptyObject(value)) {
										getStateCode(value.name)
									}
									setCountryFilter(value.name)
									onChange('country', {
										name,
										country: value.label,
									})
								}}
								labelKey="label"
								valueKey="name"
								defaultLabel={t('common.selectDefaultLabel')}
								returnOnlyValue
								style={{
									opacity: 1,
									width: '100%',
									borderRadius: '4px',
									border: `1px solid ${theme.color.blue5}`,
									boxShadow: '0px 6px 16px #05050707',
									background: `${theme.color.grey11} 0% 0% no-repeat padding-box`,
								}}
							/>
						</Box>
						<Box margin="20px 0 0">
							<SelectSearch
								placeholder={t('onboarding.statePlaceHolder')}
								options={stateList}
								value={stateFilter}
								onChange={value => {
									setStateFilter(value.name)
									onChange('state', {
										name,
										state: value.label,
									})
								}}
								labelKey="name"
								valueKey="label"
								defaultLabel={t('common.selectDefaultLabel')}
								returnOnlyValue
								style={{
									opacity: 1,
									width: '100%',
									borderRadius: '4px',
									border: `1px solid ${theme.color.blue5}`,
									boxShadow: `0px 2px 8px ${theme.color.blue6}`,
									background: `${theme.color.white} 0% 0% no-repeat padding-box`,
								}}
							/>
						</Box>
						{name === 'partners' && (
							<Box margin="20px 0 0">
								<SelectSearch
									placeholder={t(
										'partnerHome.statusPlaceHolder'
									)}
									options={statusList}
									value={statusFilter}
									onChange={value => {
										setStatusFilter(value.name)
										onChange('status', {
											name,
											status: value.name,
										})
									}}
									labelKey="label"
									valueKey="name"
									defaultLabel={t(
										'common.selectDefaultLabel'
									)}
									returnOnlyValue
									style={{
										opacity: 1,
										width: '100%',
										borderRadius: '4px',
										border: `1px solid ${theme.color.blue5}`,
										boxShadow: `0px 2px 8px ${theme.color.blue6}`,
										background: `${theme.color.white} 0% 0% no-repeat padding-box`,
									}}
								/>
							</Box>
						)}
						<Box margin="20px 0 0">
							<SelectSearch
								placeholder={t('login.orgTypeSelect')}
								value={orgTypeFilter}
								onChange={value => {
									setOrgTypeFilter(value.id)
									onChange('orgType', {
										name,
										orgType: value.id,
									})
								}}
								options={
									currentPlatform() === 'rice'
										? [
												{
													name: t(
														'invitePartner.rice-farmer'
													),
													id: 'rice-farmer',
												},
												{
													name: t(
														'invitePartner.rice-mill'
													),
													id: 'rice-mill',
												},
												{
													name: t(
														'invitePartner.rice-serviceprovider'
													),
													id: 'rice-serviceprovider',
												},
												{
													name: t(
														'invitePartner.rice-dealer'
													),
													id: 'rice-dealer',
												},
												{
													name: t(
														'invitePartner.rice-packingcompany'
													),
													id: 'rice-packingcompany',
												},
												{
													name: t(
														'invitePartner.rice-packer'
													),
													id: 'rice-packer',
												},
												{
													name: t(
														'invitePartner.rice-trader'
													),
													id: 'rice-trader',
												},
												{
													name: t(
														'invitePartner.rice-wholesaler'
													),
													id: 'rice-wholesaler',
												},
												{
													name: t(
														'invitePartner.rice-importer'
													),
													id: 'rice-importer',
												},
												{
													name: t(
														'invitePartner.rice-endbuyer'
													),
													id: 'rice-endbuyer',
												},
										  ]
										: [
												{
													name: t(
														'invitePartner.end-manufacturer'
													),
													id:
														'palmoil-endManufacturer',
												},
												{
													name: t(
														'invitePartner.ffb-dealer'
													),
													id: 'palmoil-ffbDealer',
												},
												{
													name: t(
														'invitePartner.oleochemicals'
													),
													id: 'palmoil-oleochemicals',
												},
												{
													name: t(
														'invitePartner.subDealer'
													),
													id: 'palmoil-ffbSubDealer',
												},
												{
													name: t(
														'invitePartner.plantation'
													),
													id: 'palmoil-plantation',
												},
												{
													name: t(
														'invitePartner.palm-oil-mill'
													),
													id: 'palmoil-mill',
												},
												{
													name: t(
														'invitePartner.palm-kernel-oil-mill'
													),
													id: 'palmoil-kernelMill',
												},
												{
													name: t(
														'invitePartner.palm-oil-refinery'
													),
													id: 'palmoil-refinery',
												},
												{
													name: t(
														'invitePartner.trader'
													),
													id: 'palmoil-trader',
												},
												{
													name: t(
														'invitePartner.storage-company'
													),
													id: 'palmoil-storage',
												},
										  ]
								}
								labelKey="name"
								valueKey="id"
								defaultLabel={t('common.selectDefaultLabel')}
								returnOnlyValue
								style={{
									opacity: 1,
									width: '100%',
									borderRadius: '4px',
									border: `1px solid ${theme.color.blue5}`,
									boxShadow: `0px 2px 8px ${theme.color.blue6}`,
									background: `${theme.color.white} 0% 0% no-repeat padding-box`,
								}}
							/>
						</Box>
						<Box row>
							<ButtonWithNoBorder
								label={t('auditReport.clear')}
								style={{
									fontSize: theme.fontSize.xl,
									margin: '0px 12px 0px 0px',
								}}
								onClick={() => {
									onChange('clearFilters', { name })
								}}
							/>
						</Box>
					</Box>
				</Box>
			)}
			<Table
				noRoundedBorder
				columnConfig={getColumnConfig({
					type,
					docType: name,
					orgDetails,
					onChange,
					currentOrgID,
					t,
					selectedCPID,
					isMill,
					isMobile,
				})}
				rowData={rows[name] || []}
				mobileViewCSSOn={isMobile}
			/>
			{!(rows[name] || []).length && (
				<Box style={{ minHeight: 300 }}>
					<ErrorBlock
						hideButton
						status="empty"
						message={t('common.errorBlockMessage')}
					/>
				</Box>
			)}
			<CTAWrapper>
				<Label
					color="inherit"
					bold
					small
					link
					as="a"
					style={{
						padding: '10px 4px',
					}}
				>
					{!(rows[name] || []).length
						? t('common.errorBlockMessage')
						: `${t('common.showingListFor')}
						${(rows[name] || []).length}
						${t('common.of')}
						${getIn(activeFilters, [key, 'serverPaginationQuery', 'total']) ||
							(rows[name] || []).length}`}
				</Label>
				<Box
					style={{
						width: '75%',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<Button
						plain
						extendStyles={{
							background: '#F2F4FF',
							padding: '8px 24px',
							borderRadius: '24px',
							width: '170px',
							textAlign: 'center',
						}}
						disabled={
							(rows[name] || []).length === 0 ||
							(rows[name] || []).length >=
								getIn(activeFilters, [
									key,
									'serverPaginationQuery',
									'total',
								]) ||
							(rows[name] || []).length
						}
						label={t('common.showMore')}
						onClick={() =>
							onChange('paginate', {
								docType: getIn(activeFilters, [key, 'type']),
								filterParams: getIn(activeFilters, [
									key,
									'serverPaginationQuery',
								]),
							})
						}
					/>
				</Box>
			</CTAWrapper>
		</Box>
	)
}

const MainDashboard = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const orgDetails = useSelector(AuthDuc.selectors.getAvailableOrgs)
	const location = useSelector(PartnerDuc.selectors.location)
	const selectedCPID = useSelector(CookieDuc.selectors.getSelectedCPID)
	const _activeDocuments = useSelector(PartnerDuc.selectors.getPartnerList)
	const supplybaseDetails = useSelector(
		PartnerDuc.selectors.getSupplyBaseValues
	)
	const referralList = useSelector(PartnerDuc.selectors.getReferralList)
	const globalPartnersList = useSelector(
		PartnerDuc.selectors.getGlobalPartnersList
	) // from excel entry
	const actor = useSelector(AuthDuc.selectors.getActor)[0] || []
	const isMill = actor.includes('palmoil_mill')
	const activeFilters = useSelector(PartnerDuc.selectors.getActiveFilters)
	const _activeTypes = useSelector(PartnerDuc.selectors.getPartnerActiveTabs)
	const activeTypes = isMill
		? addLast(_activeTypes, SUPPLYBASE_MEMBERS_TAB_CONFIG)
		: _activeTypes
	const { members = [] } = supplybaseDetails
	const activeDocuments = isMill
		? {
				..._activeDocuments,
				referralList,
				globalPartnersList,
				supplybaseMembers: members,
		  }
		: { ..._activeDocuments, referralList, globalPartnersList }
	const [, currentOrgID] = Storage.get({
		name: AUTH_COOKIE_KEYS.CLIENT_ID,
	}).split('@')
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const [showError, setShowError] = useState(0)
	const [countryFilter, setCountryFilter] = useState('')
	const [stateFilter, setStateFilter] = useState('')
	const [statusFilter, setStatusFilter] = useState('')
	const [orgTypeFilter, setOrgTypeFilter] = useState('')
	const [stateList, setStateList] = useState([])
	const [stateQuery, setStateQuery] = useState('')
	const [countryQuery, setCountryQuery] = useState('')
	const [statusQuery, setStatusQuery] = useState('')
	const [orgTypeQuery, setOrgTypeQuery] = useState('')
	const [mobile, setMobile] = useState('')
	const [email, setEmail] = useState('')
	const [_supplybaseDetails, setSupplyBaseDetails] = useState({})
	const [inviteSBMember, setInviteSBMember] = useState(false)

	const showAcceptModal = useSelector(
		PartnerDuc.selectors.getPartnerAcceptModalStatus
	)

	const showDeclineModal = useSelector(
		PartnerDuc.selectors.getPartnerDeclineModalStatus
	)
	const result = useSelector(AuthDuc.selectors.getTargetPartnersOfUser)
	const partnerList = result.map(list => {
		return {
			value: list.id,
			label: list.name,
		}
	})

	const [showSupplybaseModal, setShowSupplybaseModal] = useState(false)

	const getTabs = useCallback(() => {
		return activeTypes.map(({ code, title }, key) => ({
			name: code,
			title: t(title),
			key,
			IndicatorComponent:
				parseInt(code, 10) > 0 &&
				(({ isActive }) => (
					<DefaultTabIndicator active={isActive}>
						{parseInt(code, 10)}
					</DefaultTabIndicator>
				)),
		}))
	}, [activeTypes, t])
	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.partner'),
			name: 'partner',
			isActive: false,
		},
	]

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
	}

	const handleChange = useCallback(
		(actionType, meta = {}) => {
			switch (actionType) {
				case 'open_document': {
					const { row = {}, docType } = meta
					const { meta: innerMeta = {} } = row
					const { partnership = {} } = innerMeta
					const { initiatorID, receiverID, requestID } = partnership

					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.PARTNER$WREQREFERENCE,
							{
								action: 'view',
								partnerRef:
									currentOrgID === initiatorID
										? receiverID
										: initiatorID,
							},
							{
								docType: 'partner-listing',
								delete: true,
								requestID,
								canDeactivateOrBlackList:
									docType === 'partners',
							}
						)
					)

					break
				}
				case 'paginate': {
					const { filterParams, docType } = meta
					const { nextIndex } = filterParams
					const locationState = {
						query: {
							...location.query,
							nextIndex,
							limit: 20,
						},
					}
					dispatch(
						PartnerDuc.creators.fetchPartnerList(
							docType,
							locationState
						)
					)
					break
				}
				case 'partnerStatus':
					{
						const { partnerReqID, status } = meta

						dispatch(
							PartnerDuc.creators.updateRequestStatus(
								partnerReqID,
								status
							)
						)
					}

					break

				case 'supplybaseMemberUpdate': {
					setShowSupplybaseModal(true)

					setSupplyBaseDetails(meta.row)

					break
				}

				case 'country': {
					const { name, country } = meta

					const queryForCountry = `&primaryAddress=eq(country->${country})`
					setCountryQuery(countryQuery + queryForCountry)

					dispatch(
						PartnerDuc.creators.fetchPartnerList(
							name,
							{},
							false,
							countryQuery +
								queryForCountry +
								stateQuery +
								statusQuery +
								orgTypeQuery
						)
					)

					break
				}

				case 'state': {
					const { name, state } = meta

					const queryForState = `&primaryAddress=eq(state->${state})`
					setStateQuery(stateQuery + queryForState)

					dispatch(
						PartnerDuc.creators.fetchPartnerList(
							name,
							{},
							false,
							countryQuery +
								stateQuery +
								queryForState +
								statusQuery +
								orgTypeQuery
						)
					)

					break
				}

				case 'status': {
					const { name, status } = meta

					const queryForStatus = `&partnershipstatus=${status}`
					setStatusQuery(statusQuery + queryForStatus)

					dispatch(
						PartnerDuc.creators.fetchPartnerList(
							name,
							{},
							false,
							countryQuery +
								stateQuery +
								statusQuery +
								queryForStatus +
								orgTypeQuery
						)
					)

					break
				}

				case 'orgType': {
					const { name, orgType } = meta

					const queryForOrgType = `&categories=cnt(id->${orgType})`
					setOrgTypeQuery(orgTypeQuery + queryForOrgType)

					dispatch(
						PartnerDuc.creators.fetchPartnerList(
							name,
							{},
							false,
							countryQuery +
								stateQuery +
								statusQuery +
								orgTypeQuery +
								queryForOrgType
						)
					)

					break
				}

				case 'clearFilters': {
					const { name } = meta
					setCountryFilter('')
					setStateFilter('')
					setStatusFilter('')
					setOrgTypeFilter('')
					setStateQuery('')
					setCountryQuery('')
					setStatusQuery('')
					setOrgTypeQuery('')
					dispatch(
						PartnerDuc.creators.fetchPartnerList(
							name,
							{},
							false,
							''
						)
					)

					break
				}

				default:
					break
			}
		},
		[
			dispatch,
			currentOrgID,
			location.query,
			stateQuery,
			statusQuery,
			orgTypeQuery,
			countryQuery,
		]
	)

	const handleErrors = () => {
		if (showError < 4 && showError >= 1) {
			return t('validation.minSearchLengthRequired')
		}
		if (result.length === 0 && showError > 3) {
			return t('validation.noPartnersFound')
		}
	}

	const handleSearch = useDebouncedCallback(value => {
		setShowError(value.length)
		dispatch(AuthDuc.creators.searchPartner(value))
	}, 800)

	const handleSelect = selected => {
		const { value, label } = selected

		dispatch(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.PARTNER$WREQREFERENCE,
				{
					action: 'view',
					partnerRef: value,
				},
				{
					docType: 'partner-listing',
					orgName: label,
				}
			)
		)
	}

	return (
		<>
			<Box padding={8} width="100%" height="100%">
				<Modal
					show={showAcceptModal}
					size="medium"
					body={
						<>
							<P large bold>
								{t('partnerHome.partnerAcceptMessage')}
							</P>
						</>
					}
					confirmlabel={t('common.ok')}
					onConfirm={() => {
						dispatch(
							PartnerDuc.creators.partnerAcceptModalToggle(false)
						)

						dispatch(
							PartnerDuc.creators.fetchPartnerList(
								'all',
								location,
								false,
								''
							)
						)
					}}
				/>
				<Modal
					show={showDeclineModal}
					body={
						<>
							<P large bold>
								{t('partnerHome.partnerDeclineMessage')}
							</P>
						</>
					}
					confirmlabel={t('common.ok')}
					onConfirm={() => {
						dispatch(
							PartnerDuc.creators.partnerDeclineModalToggle(false)
						)
						dispatch(
							PartnerDuc.creators.fetchPartnerList(
								'all',
								location,
								false,
								''
							)
						)
					}}
				/>
				<Modal
					show={showSupplybaseModal}
					heading={t('partnerHome.update')}
					body={
						<>
							<Label
								style={{
									fontSize: '16px',
									margin: '0 0 20px',
								}}
								required
							>
								{t('partnerHome.phone')}
							</Label>
							<div>
								<PhoneInput
									country="my"
									value={mobile}
									onChange={phone => {
										setMobile(phone)
									}}
									enableSearch
									disableSearchIcon
								/>
							</div>
							<div style={{ marginTop: 40 }}>
								<Input
									required
									label={t('partnerHome.email')}
									value={email}
									name="email"
									type="text"
									onChange={e => {
										setEmail(e.target.value)
									}}
								/>
							</div>
							<div style={{ marginTop: 20 }}>
								<CheckBox
									value={inviteSBMember}
									label={t('partnerHome.sendEmail')}
									onChange={e => {
										setInviteSBMember(e.target.checked)
									}}
								/>
							</div>
						</>
					}
					isDisabled={email === '' || mobile === ''}
					confirmlabel={t('common.ok')}
					closeable
					onConfirm={() => {
						const details = { ..._supplybaseDetails, email, mobile }
						dispatch(
							PartnerDuc.creators.updateSupplybaseMembers(
								details,
								inviteSBMember
							)
						)
						setShowSupplybaseModal(false)
						setEmail('')
						setMobile('')
					}}
					onClose={() => {
						setShowSupplybaseModal(false)
						setEmail('')
						setMobile('')
					}}
				/>

				<Box style={{ padding: '0 5' }}>
					<Breadcrumb
						links={breadCrumbs}
						onClick={target => handleBreadCrumbClick(target)}
					/>
				</Box>
				<Box
					row={!isMobile}
					justifyContent="space-between"
					alignItems="baseline"
				>
					<Title title={t('partnerHome.title')} icon={PartnerIcon} />
					{!selectedCPID && (
						<Box
							row
							justifyContent={isMobile ? 'center' : 'flex-end'}
							width={isMobile ? '100%' : 'auto'}
						>
							<Box margin="1px" id="addPartner">
								<Button
									label={t('partnerHome.addPartner')}
									action
									primary
									rounded
									onClick={() =>
										dispatch(
											MainRouteDuc.creators.switchPage(
												MainRouteDuc.types
													.PARTNER$ACTION,
												{
													action: 'add-partner',
												}
											)
										)
									}
								/>
							</Box>
							<Box margin="1px 1px 1px 5px">
								<Button
									label={`${t('common.upload')} ${t(
										'breadcrumb.partner'
									)}`}
									action
									primary
									rounded
									onClick={() =>
										dispatch(
											MainRouteDuc.creators.switchPage(
												MainRouteDuc.types
													.PARTNER$ACTION,
												{
													action: 'upload-partners',
												}
											)
										)
									}
								/>
							</Box>
						</Box>
					)}
				</Box>
				{!selectedCPID && (
					<Box
						width={isMobile ? '100%' : 300}
						margin="1px"
						style={{
							justifyContent: 'center',
							marginTop: isMobile ? '35px' : '0px',
						}}
					>
						<SuggestionsInput
							value=""
							placeholder={t('addPartner.searchPlaceholder')}
							onChange={e => handleSearch(e.target.value)}
							suggestions={partnerList}
							onSelect={selected => handleSelect(selected)}
							error={handleErrors()}
							extendStyles={{ borderRadius: '4px' }}
						/>
					</Box>
				)}
				<Spacer size={8} />

				<Tabs
					tabs={getTabs()}
					tabComponentsMap={getTabComponents({
						type: 'partner-requests',
						onChange: handleChange,
						rows: activeDocuments,
						orgDetails,
						currentOrgID,
						activeFilters,
						t,
						selectedCPID,
						isMill,
						stateList,
						setStateList,
						countryFilter,
						stateFilter,
						statusFilter,
						orgTypeFilter,
						setCountryFilter,
						setStateFilter,
						setStatusFilter,
						setOrgTypeFilter,
						isMobile,
					})}
					onChange={({ name }) => {
						handleChange('clearFilters', { name })
					}}
					isMobile={isMobile}
				/>
				<Box padding="25px 2px" style={{ maxWidth: 150 }}>
					<Button
						label={t('common.back')}
						rounded
						customIcon={
							<ButtonIconWrapper lightBG>
								<Icon glyph={LeftArrowIcon} />
							</ButtonIconWrapper>
						}
						onClick={() =>
							dispatch(
								MainRouteDuc.creators.switchPage(
									MainRouteDuc.types.DASHBOARD
								)
							)
						}
					/>
				</Box>
			</Box>
		</>
	)
}

export { MainDashboard }
