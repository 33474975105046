import React from 'react'
import { useSelector } from 'react-redux'
import { NotFoundBlock } from 'core-app/modules/App/components/Error'
import { PartnerDuc } from '../duc'
import { ViewPartner } from './PartnerManagement/ViewPartner'
import { AddPartner } from './PartnerManagement/AddPartner'
import { InvitePartner } from './PartnerManagement/InvitePartner'
import { UploadPartners } from './PartnerManagement/UploadPartners'

const modulesMap = {
	view: ViewPartner,
	'add-partner': AddPartner,
	'invite-partner': InvitePartner,
	'upload-partners': UploadPartners,
}

const SubDashboard = () => {
	const location = useSelector(PartnerDuc.selectors.location)
	const { payload = {} } = location
	const activeModule = payload.action
	const Component = modulesMap[activeModule] || NotFoundBlock
	// if we are here, we have a submodule, route it to the module

	return <Component />
}

export default SubDashboard
