/* eslint-disable array-callback-return */
/* @flow */

import React, { Suspense, useState, useEffect } from 'react'
import { MainRouteDuc } from 'core-app/routes/duc'
// $FlowFixMe
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { getIn, removeFirst } from 'timm'
import Onboard from 'core-app/modules/Onboard'
import Auth from 'core-app/modules/Auth'
import { Icon } from 'ui-lib/icons/components/Icon'
import {
	RoundedFeedbackButton,
	ButtonFeedbackIconWrapper,
} from 'ui-lib/components/Button'
import {
	UnexpectedErrorBlock,
	NotFoundBlock,
	NotVerifiedBlock,
} from 'core-app/modules/App/components/Error'
import ErrorBoundary from 'core-app/modules/App/components/ErrorBoundary'
import { FeedbackModalBlock } from 'core-app/modules/App/components/FeedbackModalBlock'
import { ToastHandler } from 'core-app/modules/App/components/ToastHandler'
import Dashboard from 'core-app/modules/Dashboard'
import Plantation from 'core-app/modules/Plantation'
import Mill from 'core-app/modules/Mill'
import Refinery from 'core-app/modules/Refinery'
import Admin from 'core-app/modules/Admin'
import Products from 'core-app/modules/Products'
import Settings from 'core-app/modules/Settings'
import Partner from 'core-app/modules/Partner'
import Feedback from 'core-app/modules/Feedback'
import Messages from 'core-app/modules/Messages'
import ProductTrace from 'core-app/modules/Trace'
import StorageCompany from 'core-app/modules/StorageCompany'
import EndManufacturer from 'core-app/modules/EndManufacturer'
import Trader from 'core-app/modules/Trader'
import Dealer from 'core-app/modules/Dealer'
import Trips from 'core-app/modules/Trips'
import WbTicket from 'core-app/modules/WbTicket'
import GeneralScheduler from 'core-app/modules/GeneralScheduler'
import { FirstTimeSignInModal } from 'core-app/modules/App/components/FirstTimeSignInModal'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { AppDuc } from 'core-app/modules/App/duc'
import { WebTourDuc } from 'core-app/modules/WebTour/duc'
import { LoaderFixedWrapper } from 'ui-lib/components/Spinner'
import { Modal } from 'ui-lib/components/Modal'
import { P } from 'ui-lib/components/Typography'
import { NewErrorBlock } from 'ui-lib/components/ErrorBlocks'
import { InfiniteSpinner } from 'ui-lib/components/Spinner/InfiniteSpinner'
import { useInjectSaga } from 'core-app/store/injectSaga'
import FeedbackIcon from 'ui-lib/icons/feedback.svg'
import { isIframeForTeamsApp } from 'ui-lib/utils/helpers'
import { Header } from './containers/Header'
import saga from './AppSaga'
import networkSaga from './networkSaga'
import { CookieDuc } from './cookieDuc'

const RoundedFeedbackButton2 = styled(RoundedFeedbackButton)`
	border-radius: 150px;
	height: 56px;
	width: 56px;
	right: 16px;
	bottom: 80px;
	position: fixed !important;
	background: ${p => p.theme.color.error};
	box-shadow: 3px 3px 6px #00000029;
	color: ${p => (p.primary ? p.theme.color.white : p.theme.color.primary)};
`

/* The core routing map that drive the
	active root component controlled by `page` state key
	via routes/duc.js
*/
const ROUTING_COMPONENTS_MAP = {
	Onboard,
	Auth,
	Dashboard,
	Plantation,
	Mill,
	Refinery,
	Admin,
	Partner,
	Settings,
	Feedback,
	Messages,
	ProductTrace,
	StorageCompany,
	EndManufacturer,
	Trader,
	Dealer,
	Trips,
	Wbticket: WbTicket,
	Products,
	GeneralScheduler,
}

const Wrapper = styled.div(p => ({
	position: 'relative',
	width: 'auto',
	minHeight: '100vh',
	background: `${p.theme.color.white1} url("/images/hex.png") repeat 0 0`,
	backgroundSize: '16px auto',
	margin: '0 auto',
}))

const Container = styled.div(p => ({
	width: '100%',
	margin: '0 auto',
	...(!p.isOnline && {
		paddingTop: 8,
		'-webkit-filter': 'grayscale(1)' /* Older Webkit */,
		'-moz-filter': 'grayscale(100%)',
		'-ms-filter': 'grayscale(100%)',
		'-o-filter': 'grayscale(100%)',
		filter: 'grayscale(100%)',
	}),
}))

const Loader = () => (
	<LoaderFixedWrapper fixed>
		<InfiniteSpinner size={30} />
	</LoaderFixedWrapper>
)
export const sentMessage = []

const App = () => {
	const isVerified = useSelector(AuthDuc.selectors.checkVerified) || ''
	const virtualAccess = useSelector(AuthDuc.selectors.getVirtualAccess)
	const dispatch = useDispatch()
	useInjectSaga({ key: 'app', saga })
	useInjectSaga({ key: 'network-handler', saga: networkSaga })

	const [language, setLanguage] = useState('eng')
	const modalStatus = useSelector(AuthDuc.selectors.getModalStatus)
	const modalStatusLogin = useSelector(AuthDuc.selectors.getModalStatusLogin)
	const signInComponents = useSelector(AuthDuc.selectors.getModalStatusRoute)

	const [feedback, setFeedback] = useState('')
	const location = useSelector(AppDuc.selectors.location)
	const module = location.pathname.split('/')[1]
	const page = useSelector(MainRouteDuc.selectors.page)
	const {
		isFetching: isProfileInfoFetching,
		isError: isProfileFetchErrored,
	} = useSelector(AuthDuc.selectors.getProfileFetchStatus)
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const isOnline = useSelector(AppDuc.selectors.isOnline)
	const lastOnline = useSelector(AppDuc.selectors.lastOnline)
	const {
		show: showConfirmationModal,
		heading,
		message,
		isCloseable,
		confirmationLabel,
		declineLabel,
	} = useSelector(AppDuc.selectors.confirmationModal)

	const {
		show: showUpdateModal,
		heading: updateModalHeading,
		message: updateModaleMessage,
		isCloseable: updateModalClosable,
		confirmationLabel: updateModalConfirmLabel,
		declineLabel: updateDeclineLabel,
		rejectStatus,
	} = useSelector(AppDuc.selectors.appUpdateModal)

	const globalLoadingStatus = useSelector(AppDuc.selectors.loading)
	const CoreComponent = ROUTING_COMPONENTS_MAP[page] || NotFoundBlock
	const getTourStatus = useSelector(WebTourDuc.selectors.tourStatus)
	const [tourStatus, setTourStatus] = useState(false)

	useEffect(() => {
		setTourStatus(getTourStatus)
	}, [getTourStatus])

	useEffect(() => {
		// which runs in each minute
		window.setInterval(() => {
			const currentPage = window.location.pathname.split('/')[1]
			if (
				!(
					currentPage === 'auth' ||
					currentPage === 'signup' ||
					currentPage === 'signup-detail'
				)
			) {
				dispatch(AuthDuc.creators.initiateRefreshToken())
			}
		}, 60000)
	}, [dispatch])

	// if auth route, return back the full exclusive component
	if (page === 'Auth' || page === 'Onboard' || page === 'Policy') {
		return (
			<Suspense fallback={<Loader />}>
				<ToastHandler />
				<CoreComponent />
			</Suspense>
		)
	}

	const isPageAErrorType = ['401', '500'].includes(page)
	const isLoading = globalLoadingStatus || isProfileInfoFetching

	if (isProfileFetchErrored || isPageAErrorType) {
		// unable to get the user profile info, so show error page.
		return (
			<div width="100vw" height="100vh">
				{!isProfileFetchErrored && <Header loading={isLoading} />}
				<UnexpectedErrorBlock
					status={isPageAErrorType ? page : 401}
					onClick={
						!isProfileFetchErrored
							? () => window.location.reload()
							: () => {}
					}
				/>
			</div>
		)
	}

	const routes = {
		chooseLanguage: [
			{
				action: MainRouteDuc.types.SETTINGS$SUBROOT,
				payload: { rootModule: 'change-language' },
			},
		],
		createIncomingArea: [
			{
				action: MainRouteDuc.types.ADMIN$SUBMODULEWACTION,
				payload: {
					rootModule: 'assets',
					subModule: 'storageunits',
					action: 'create-asset',
				},
			},
		],
		createOutgoingArea: [
			{
				action: MainRouteDuc.types.ADMIN$SUBMODULEWACTION,
				payload: {
					rootModule: 'assets',
					subModule: 'storageunits',
					action: 'create-asset',
				},
			},
		],
		createIncomingTank: [
			{
				action: MainRouteDuc.types.ADMIN$SUBMODULEWACTION,
				payload: {
					rootModule: 'assets',
					subModule: 'storageunits',
					action: 'create-asset',
				},
			},
		],
		createOutgoingTank: [
			{
				action: MainRouteDuc.types.ADMIN$SUBMODULEWACTION,
				payload: {
					rootModule: 'assets',
					subModule: 'storageunits',
					action: 'create-asset',
				},
			},
		],
		createWeighbridge: [
			{
				action: MainRouteDuc.types.ADMIN$SUBMODULEWACTION,
				payload: {
					rootModule: 'assets',
					subModule: 'weighbridges',
					action: 'create-asset',
				},
			},
		],
		choosePartner: [
			{
				action: MainRouteDuc.types.PARTNER$ACTION,
				payload: { action: 'add-partner' },
			},
		],
		chooseEmployee: [
			{
				action: MainRouteDuc.types.ADMIN$WACTION,
				payload: {
					rootModule: 'employee-management',
					action: 'add-employee',
				},
			},
		],
	}

	const selectedRoutes = {}

	for (const mod in routes) {
		if (signInComponents.includes(mod)) {
			selectedRoutes[mod] = routes[mod]
		}
	}

	const activeModule = getIn(selectedRoutes, [signInComponents[0], 0], {})

	const getSideBarMargin = () => {
		let margin = '16px 0 0 310px'
		if (isMobile) {
			margin = '0 auto'
		} else if (isIframeForTeamsApp()) {
			margin = '0px'
		} else if (!isOnline) {
			margin = '45px 0 0 310px'
		}

		return margin
	}

	const getMaxWidth = () => {
		let width = 'calc(100vw - 338px)'
		if (isIframeForTeamsApp()) {
			width = '100%'
		}

		return width
	}

	return (
		<Wrapper>
			{isVerified && (
				<ErrorBoundary>
					<Suspense fallback={<Loader />}>
						{!(
							isVerified === 'in-review' ||
							isVerified === 'verified' ||
							virtualAccess
						) ? (
							<NotVerifiedBlock />
						) : (
							<>
								<ToastHandler />
								<Header
									loading={isLoading}
									isOnline={isOnline}
									lastOnline={lastOnline}
								/>
								{isOnline ? (
									<>
										<FirstTimeSignInModal
											components={signInComponents}
											show={
												modalStatus &&
												modalStatusLogin &&
												!getTourStatus
											}
											selectLanguage={active =>
												setLanguage(active)
											}
											onConfirm={() => {
												const currentModal =
													getIn(signInComponents, [
														0,
													]) || ''

												dispatch(
													AuthDuc.creators.setFirstTimeSignInModalStatus(
														!modalStatus
													)
												)
												dispatch(
													AuthDuc.creators.setFirstTimeSignInModalStatusLogin(
														false
													)
												)
												dispatch(
													AuthDuc.creators.setFirstTimeSignInModal(
														removeFirst(
															signInComponents
														)
													)
												)
												if (
													currentModal ===
													'chooseLanguage'
												) {
													dispatch(
														AuthDuc.creators.setLanguage(
															language
														)
													)
												} else {
													dispatch(
														MainRouteDuc.creators.switchPage(
															activeModule.action,
															activeModule.payload
														)
													)
												}
											}}
											onSkip={() => {
												dispatch(
													AuthDuc.creators.setFirstTimeSignInModalStatus(
														!modalStatus
													)
												)
												dispatch(
													AuthDuc.creators.setFirstTimeSignInModalStatusLogin(
														false
													)
												)
												dispatch(
													AuthDuc.creators.setFirstTimeSignInModal(
														removeFirst(
															signInComponents
														)
													)
												)
												dispatch(
													CookieDuc.creators.setCookie(
														{
															cookieName:
																'SKIP_INITIAL_SETUP',
															cookieValue: true,
															storage: 'C',
														}
													)
												)
											}}
										/>

										<Container
											isOnline={isOnline}
											style={{
												maxWidth: isMobile
													? '100vw'
													: getMaxWidth(),
												margin: getSideBarMargin(),
												overflow: tourStatus
													? 'hidden'
													: 'overflow',
												paddingBottom: isIframeForTeamsApp()
													? '0'
													: '90px',
												paddingRight: isIframeForTeamsApp()
													? '75px'
													: '0px',
											}}
										>
											{isLoading && <Loader />}
											<div id="DashboardHeader">
												<CoreComponent />
											</div>

											{rejectStatus && (
												<RoundedFeedbackButton2
													action
													primary
													rounded
													customIcon={
														<ButtonFeedbackIconWrapper>
															<Icon
																glyph={
																	FeedbackIcon
																}
															/>
														</ButtonFeedbackIconWrapper>
													}
													onClick={() =>
														dispatch(
															AppDuc.creators.showUpdateModal(
																true
															)
														)
													}
												/>
											)}
											<FeedbackModalBlock
												icon={FeedbackIcon}
												data={sentMessage}
												onSubmit={() => {
													dispatch(
														AppDuc.creators.saveUserFeedback(
															feedback,
															module
														)
													)
												}}
												value={feedback}
												onChange={e => {
													setFeedback(e.target.value)
												}}
											/>
										</Container>
									</>
								) : (
									<div
										style={{
											width: '100%',
											maxWidth: isMobile
												? '100vw'
												: 'calc(100vw - 338px)',
											margin: getSideBarMargin(),
											overflow: 'auto',
											paddingBottom: '90px',
										}}
									>
										<NewErrorBlock
											status="offline"
											title="DIBIZ seems to be offline."
											subtitle="Make sure that you're connected to the internet and try again"
										/>
									</div>
								)}
							</>
						)}
					</Suspense>
				</ErrorBoundary>
			)}
			<Modal
				closeable={isCloseable}
				show={showConfirmationModal}
				heading={heading}
				body={
					<P large bold>
						{message}
					</P>
				}
				closelabel={declineLabel}
				confirmlabel={confirmationLabel}
				onClose={() => {
					dispatch(AppDuc.creators.hideConfirmationModal())
					dispatch(AppDuc.creators.declinedOnConfirmationModal())
				}}
				onConfirm={() => {
					dispatch(AppDuc.creators.hideConfirmationModal())
					dispatch(AppDuc.creators.acceptedOnConfirmationModal())
				}}
				isMobile={isMobile}
			/>
			<Modal
				closeable={updateModalClosable}
				show={showUpdateModal}
				heading={updateModalHeading}
				body={
					<P large bold>
						{updateModaleMessage}
					</P>
				}
				closelabel={updateDeclineLabel}
				confirmlabel={updateModalConfirmLabel}
				onClose={() => {
					dispatch(AppDuc.creators.hideUpdateModal())
					dispatch(AppDuc.creators.updateRejectStatus(true))
				}}
				onConfirm={() => {
					dispatch(AppDuc.creators.hideUpdateModal())
					dispatch(AppDuc.creators.updateRejectStatus(false))
					dispatch(AppDuc.creators.updateAppVersion())
					window.location.reload(true)
				}}
				isMobile={isMobile}
			/>
		</Wrapper>
	)
}

export default App
