import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AdminDuc } from 'core-app/modules/Admin/duc'
import { KeyValueSegment } from 'core-app/modules/Admin/components/KeyValueSegment'
import { Box } from 'ui-lib/utils/Box'
import { ButtonWithNoBorder } from 'ui-lib/components/Button'
import theme from 'ui-lib/utils/base-theme'

const ProductionLineDetails = ({ assetID }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const prodLineDetails = useSelector(
		AdminDuc.selectors.getProductionLineDetails
	)

	const { name, capacity, capacityUOM } = prodLineDetails

	return (
		<>
			<Box row justifyContent="flex-end">
				<ButtonWithNoBorder
					label={t('common.edit').toUpperCase()}
					onClick={() => {
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.ADMIN$SUBMODULEWDOCREFERENCE,
								{
									rootModule: 'assets',
									subModule: 'production-line',
									action: 'edit-asset',
									documentReference: assetID,
								}
							)
						)
					}}
				/>
			</Box>

			<Box
				style={{
					border: `1px solid ${theme.color.grey7}`,
					borderRadius: '4px',
				}}
			>
				<KeyValueSegment label={t('viewAsset.unitName')} value={name} />
				<KeyValueSegment
					label={t('viewAsset.capacity')}
					value={`${capacity} ${capacityUOM}`}
				/>
			</Box>
		</>
	)
}
export { ProductionLineDetails }
