import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AdminDuc } from 'core-app/modules/Admin/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { AppDuc } from 'core-app/modules/App/duc'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Title } from 'core-app/shared/components/Title'
import { Spacer } from 'ui-lib/utils/Spacer'
import { getIn } from 'timm'
import { Button, ButtonIconWrapper } from 'ui-lib/components/Button'
import { Input } from 'ui-lib/components/Input'
import { SelectSearch } from 'ui-lib/components/Select'
import { CollapsableSegment } from 'ui-lib/components/CollapsableSegments'
import { P } from 'ui-lib/components/Typography'
import { Label } from 'ui-lib/components/Label'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { Box } from 'ui-lib/utils/Box'
import { useFormik } from 'formik'
import { FileUpload } from 'ui-lib/components/FileUpload'
import * as yup from 'yup'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { isValidPhoneNumber } from 'react-phone-number-input'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import WebUserIcon from 'ui-lib/icons/desktop_windows.svg'
import PhoneUserIcon from 'ui-lib/icons/phone_iphone.svg'
import theme from 'ui-lib/utils/base-theme'
import { getEmployeeUploadSampleFile } from 'core-app/config'
import { isEmptyObject } from 'core-app/utils/helpers'
import { isMarketplaceHost } from 'ui-lib/utils/helpers'
import { DIbizRoleActorBased } from '../../config'

const InfoBlock = ({ name, detail, labelColor, bgColor, icon }) => {
	return (
		<>
			<Box
				row
				alignItems="end"
				style={{
					marginBottom: 8,
				}}
			>
				<Label
					style={{
						padding: '2px 9px 1px 8px',
						color: labelColor,
						background: bgColor,
						fontSize: theme.fontSize.m,
						width: 'max-content',
						borderRadius: 4,
						fontWeight: 500,
					}}
				>
					{name.toUpperCase()}
				</Label>
				<Spacer size={8} horizontal />
				<IconWrapper size={24}>
					<Icon glyph={icon} />
				</IconWrapper>
			</Box>

			<P
				small
				style={{
					fontSize: theme.fontSize.m,
					padding: 4,
					marginBottom: 18,
					color: '#7B8088',
				}}
			>
				{detail}
			</P>
		</>
	)
}

const AddEmployee = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const actor = useSelector(AuthDuc.selectors.getActor)
	const actorType = getIn(actor, [0]) || ''
	const _dibizRole = getIn(DIbizRoleActorBased, [actorType]) || []
	const dibizRole = _dibizRole.filter(({ name }) => name !== 'owner')

	const dibizRoleWithTranslations = dibizRole.map(({ name, label }) => {
		return { name, label: t(label) }
	})

	const dibizRoleInfo = _dibizRole.map(({ name, details }) => {
		return { name, details: t(details) }
	})

	const { orgRole: loggedinUserRole } = useSelector(
		AuthDuc.selectors.getUserProfile
	)

	const location = useSelector(AdminDuc.selectors.location)
	const { payload = {} } = location
	const { rootModule, action, documentReference } = payload

	const initialValues = useSelector(
		AdminDuc.selectors.getInitialEmployeeDetails
	)
	const [editRefresh, setEditRefresh] = useState(false)
	const [role, setDibizRole] = useState('')
	const [ownerFields, disableOwnerFields] = useState(false)
	const [employeeListAdded, setEmployeeListAdded] = useState(false)

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.admin'),
			name: 'admin',
			isActive: true,
		},
		{
			label: t('breadcrumb.empMgmt'),
			name: 'employee-management',
			isActive: true,
		},
		{
			label:
				action === 'edit-employee'
					? t('breadcrumb.editEmployee')
					: t('breadcrumb.addEmp'),
			name: 'add-employee',
			isActive: false,
		},
	]

	/** Handlers to react on actions */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'admin') {
			dispatch(MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN))
		}
		if (target === 'employee-management') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.ADMIN$SUBROOT,
					{
						rootModule: 'employee-management',
					}
				)
			)
		}
	}

	const isMobileAppUser =
		role === 'weighbridgeClerk' ||
		role === 'tractor-operator' ||
		role === 'checker' ||
		role === 'harvester' ||
		role === 'driver' ||
		role === 'grader'

	const validationSchema = () => {
		const shape = yup.object().shape({
			employeeName: yup.string(),
			employeeID: yup.string(),
			mobile: yup.string(),
			dibizRole: yup.string(),
			email: yup.string().email(t('validation.emailFormat')),
			employeeListDoc: yup.array(),
		})

		if (employeeListAdded) {
			shape.fields.employeeListDoc = shape.fields.employeeListDoc.required()
		} else {
			shape.fields.employeeName = shape.fields.employeeName.required(
				t('validation.empNameRequired')
			)
			shape.fields.dibizRole = shape.fields.dibizRole.required(
				t('validation.dibizRoleRequired')
			)
		}

		return shape
	}

	const {
		handleSubmit,
		submitForm,
		values,
		handleChange,
		handleBlur,
		setFieldValue,
		touched,
		errors,
	} = useFormik({
		initialValues,
		enableReinitialize: true,
		validationSchema,
		onSubmit: (_values, { setSubmitting }) => {
			if (action === 'edit-employee') {
				dispatch(
					AdminDuc.creators.setModifiedEmployeeRole(_values.dibizRole)
				)
			}
			const employee = {
				firstName: _values.employeeName,
				primaryContact: {
					email: _values.email,
				},
				orgRole: _values.dibizRole,
				meta: {
					employeeData: {
						id: _values.employeeID,
						designation: _values.designation,
					},
				},
			}

			if (values.mobile) {
				employee.primaryContact.mobile =
					values.mobile[0] === '+'
						? values.mobile
						: `+${values.mobile}`
			}
			setSubmitting(false)

			dispatch(
				AdminDuc.creators.setEmployeeDetails(
					values?.employeeListDoc?.length > 0
						? { employeeDoc: values.employeeListDoc }
						: employee
				)
			)
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.ADMIN$WACTION,
					{
						rootModule,
						action: 'preview-employee',
					},
					{ userID: documentReference }
				)
			)
		},
	})

	useEffect(() => {
		dispatch(AdminDuc.creators.setEmployeeUploadEditSuccess(false))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (values.employeeName) {
			setEditRefresh(true)
		}
	}, [values])

	useEffect(() => {
		if (values.dibizRole) setDibizRole(values.dibizRole)
		if (
			loggedinUserRole === 'owner' &&
			values.dibizRole === 'owner' &&
			action === 'edit-employee'
		) {
			disableOwnerFields(true)
		}
	}, [action, loggedinUserRole, values.dibizRole])

	const checkIfPhoneNumberValid =
		isMobileAppUser &&
		isValidPhoneNumber(
			values && values.mobile && values.mobile[0] === '+'
				? values.mobile
				: `+${values.mobile}`
		)

	const checkValidation = isMobileAppUser
		? !values.mobile || !checkIfPhoneNumberValid
		: !values.email
	const disableCTA =
		values?.employeeListDoc?.length > 0
			? false
			: !isEmptyObject(errors) || !values.employeeName || checkValidation

	const platform = isMarketplaceHost() ? 'marketplace' : 'app'

	return (
		<Box padding={8} width="100%" height="100%">
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Box row justifyContent="space-between" alignItems="center">
				<Title
					title={
						action === 'edit-employee'
							? t('empMgmtHome.editEmployee')
							: t('empMgmtHome.title')
					}
					note={
						action === 'edit-employee'
							? t('empMgmtHome.editNote')
							: t('empMgmtHome.note')
					}
				/>
			</Box>
			<Spacer size={8} />
			<CollapsableSegment
				expand
				title={`${t('empMgmtHome.collapsegtitle')}${
					!documentReference ? `/${t('empMgmtHome.employees')}` : ''
				}`}
				hasError={disableCTA}
				open
				message={
					disableCTA
						? t('validation.collapsableSegmentWarning')
						: t('validation.collapsableSegmentSuccess')
				}
			>
				<form onSubmit={handleSubmit}>
					<Box row={!isMobile} justifyContent="space-between">
						<Box>
							<div key={values}>
								{(action !== 'edit-employee' ||
									editRefresh) && (
									<>
										<Box width={320} margin="8px 0">
											<Input
												label={t('empMgmtHome.empName')}
												value={values.employeeName}
												name="employeeName"
												type="text"
												onChange={handleChange}
												onBlur={handleBlur}
												error={
													touched.employeeName &&
													errors.employeeName
												}
												disabled={ownerFields}
												required
											/>
										</Box>
										<SelectSearch
											label={t('empMgmtHome.dibizRole')}
											value={values.dibizRole}
											name="dibizRole"
											options={dibizRoleWithTranslations}
											onChange={value => {
												setFieldValue(
													'dibizRole',
													value.name
												)
												setDibizRole(value.name)
											}}
											returnOnlyValue
											labelKey="label"
											valueKey="name"
											defaultLabel={t(
												'common.selectDefaultLabel'
											)}
											onBlur={handleBlur}
											error={
												touched.dibizRole &&
												errors.dibizRole
											}
											disabled={ownerFields}
											style={{
												width: 320,
												borderRadius: 4,
											}}
											required
										/>
										<Box width={320} margin="8px 0">
											<Input
												label={t(
													'empMgmtHome.designation'
												)}
												value={values.designation}
												name="designation"
												type="text"
												onChange={handleChange}
												onBlur={handleBlur}
												error={
													touched.designation &&
													errors.designation
												}
											/>
										</Box>
										<Box width={320} margin="8px 0">
											<Input
												label={t(
													'empMgmtHome.employeeID'
												)}
												value={values.employeeID}
												name="employeeID"
												type="text"
												onChange={handleChange}
												onBlur={handleBlur}
												error={
													touched.employeeID &&
													errors.employeeID
												}
											/>
										</Box>
										<Box width={320} margin="8px 0">
											<Input
												label={t('empMgmtHome.email')}
												value={values.email}
												name="email"
												type="text"
												disabled={ownerFields}
												onChange={handleChange}
												onBlur={handleBlur}
												error={
													touched.email &&
													errors.email
												}
												required={!isMobileAppUser}
											/>
										</Box>

										<Box
											width={320}
											margin="8px 0 35px 0"
											overflow="initial"
										>
											<Label
												color={theme.color.grey8}
												style={{
													fontSize: '16px',
													margin: '8px 0',
												}}
												required={isMobileAppUser}
											>
												{t('empMgmtHome.phoneNo')}
											</Label>

											<PhoneInput
												country="my"
												value={values.mobile}
												onChange={phone =>
													setFieldValue(
														'mobile',
														phone
													)
												}
												enableSearch
												disableSearchIcon
											/>
											{isMobileAppUser && (
												<P
													style={{
														margin: '80px 0',
														color:
															theme.color.grey6,
													}}
												>
													{t(
														'empMgmtHome.phoneNumberNote'
													)}
												</P>
											)}
										</Box>
									</>
								)}
							</div>
							{!documentReference && (
								<div>
									<Box
										style={{
											marginTop: '20px',
											width: '300px',
											display: 'flex',
											textAlign: 'center',
											marginBottom: '40px',
											justifyContent: 'center',
											color: theme.color.accent,
										}}
									>
										<div
											style={{
												display: 'flex',
												justifyContent: 'center',
											}}
										>
											<div
												style={{
													border: '1px dashed',
													width: '70px',
													height: '1px',
													marginTop: '20px',
												}}
											/>
											<div
												style={{
													margin: '10px',
												}}
											>
												{t('empMgmtHome.or')}
											</div>
											<div
												style={{
													border: '1px dashed',
													width: '70px',
													height: '1px',
													marginTop: '20px',
												}}
											/>
										</div>
									</Box>
									<Box
										width={300}
										margin="8px 0"
										style={{
											paddingTop: 20,
											display: 'flex',
											borderRadius: 6,
											paddingLeft: 30,
											paddingBottom: 5,
											border: `1px solid ${theme.color.grey4}`,
										}}
									>
										<Label
											color={theme.color.grey8}
											style={{
												width: '240px',
												margin: '8px 0',
												fontSize: '16px',
											}}
											required={isMobileAppUser}
										>
											{t(
												'empMgmtHome.attachEmployeesList'
											)}
										</Label>
										<div
											style={{
												display: 'flex',
											}}
										>
											<Box
												style={{
													padding: '1px',
													color: theme.color.blue8,
													backgroundColor:
														theme.color.white,
												}}
											>
												<FileUpload
													modalTriggerBtnLabel={t(
														'common.upload'
													)}
													type="private"
													name="attachments"
													size="2097152"
													supportedFileType=".xlsx"
													hideButtonOneUpload
													onDocumentChange={async documents => {
														setEmployeeListAdded(
															documents?.length >
																0
														)
														setFieldValue(
															'employeeListDoc',
															documents
														)
													}}
													onUploadFailure={({
														message,
													}) => {
														dispatch(
															AppDuc.creators.showToast(
																{
																	messageType:
																		'error',
																	message,
																}
															)
														)
													}}
												/>
											</Box>
										</div>
										<div
											style={{
												display: 'flex',
												paddingRight: '20px',
												paddingBottom: '10px',
												justifyContent: 'start',
											}}
										>
											<a
												href={`${getEmployeeUploadSampleFile()}`}
												target="_blank"
												rel="noopener noreferrer"
												style={{
													textDecoration: 'underline',
													color: theme.color.blue1,
												}}
											>
												{t(
													'empMgmtHome.clickHereToDownloadSampleFile'
												)}
											</a>
										</div>
									</Box>
								</div>
							)}
						</Box>
						<Box
							margin={8}
							padding="32px 24px"
							style={{
								border: '1px solid #CAD2DD',
								borderRadius: 6,
								maxWidth: 520,
							}}
						>
							{dibizRoleInfo.map(({ name, details }) => {
								const labelColor =
									name === 'administrator' ||
									name === 'owner' ||
									name === 'office-management' ||
									name === 'purchase-officer' ||
									name === 'sales-marketing-officer'
										? '#5E847D'
										: '#595D64'
								const bgColor =
									name === 'administrator' ||
									name === 'owner' ||
									name === 'office-management' ||
									name === 'purchase-officer' ||
									name === 'sales-marketing-officer'
										? '#CFE5E1'
										: '#DADDE3'
								const icon =
									name === 'administrator' ||
									name === 'owner' ||
									name === 'office-management' ||
									name === 'purchase-officer' ||
									name === 'sales-marketing-officer'
										? WebUserIcon
										: PhoneUserIcon

								return (
									<InfoBlock
										key={name}
										name={t(`empMgmtHome.${name}`)}
										detail={details}
										labelColor={labelColor}
										bgColor={bgColor}
										icon={icon}
									/>
								)
							})}
						</Box>
					</Box>
				</form>
			</CollapsableSegment>
			<Spacer size={12} />
			<Box row justifyContent="space-between">
				<Box padding="2px" style={{ maxWidth: 150 }}>
					<Button
						label={t('common.back')}
						rounded
						customIcon={
							<ButtonIconWrapper lightBG>
								<Icon glyph={LeftArrowIcon} />
							</ButtonIconWrapper>
						}
						onClick={() =>
							dispatch(
								MainRouteDuc.creators.switchPage(
									MainRouteDuc.types.ADMIN$SUBROOT,
									{
										rootModule: 'employee-management',
									}
								)
							)
						}
					/>
				</Box>
				<Box padding="2px" style={{ maxWidth: 200 }}>
					<Button
						disabled={disableCTA}
						label={
							employeeListAdded
								? t('empMgmtHome.assignApplication')
								: t('common.preview')
						}
						primary
						rounded
						onClick={() => {
							if (values?.employeeListDoc?.length > 0) {
								const finalUrl = `${values?.employeeListDoc[0].urlPath.replace(
									'private/',
									''
								)}/${values?.employeeListDoc[0].id}.`
								dispatch(
									AdminDuc.creators.employeeUploadUserValidation(
										finalUrl,
										platform
									)
								)
							} else {
								dispatch(
									AdminDuc.creators.setEmployeeUploadList([])
								)
								dispatch(
									AdminDuc.creators.setUpdatedEmployeeUploadArray(
										{}
									)
								)
								submitForm()
							}
						}}
					/>
				</Box>
			</Box>
		</Box>
	)
}

export { AddEmployee }
