import React from 'react'
import { PlantationDuc } from 'core-app/modules/Plantation/duc'
import { useSelector } from 'react-redux'
import { UnexpectedErrorBlock } from 'core-app/modules/App/components/Error'
import { CreateTicket } from './CreateTicket'
import { PreviewTicket } from './PreviewTicket'

const TicketCreation = () => {
	const { payload = {} } = useSelector(PlantationDuc.selectors.location)

	const { action } = payload

	if (action === 'create' || action === 'edit') {
		return <CreateTicket />
	}

	if (action === 'view') {
		return <PreviewTicket />
	}

	return <UnexpectedErrorBlock status={401} />
}

export { TicketCreation }
