import React from 'react'
import { getIn } from 'timm'
import { CheckBox } from 'grommet'
import { AddDriver } from 'core-app/modules/GeneralScheduler/components/DriverSelection'
import {
	TABLE_ALIAS,
	SERVICE_TYPES,
	TIME,
} from 'core-app/modules/GeneralScheduler/config'
import { isEmptyObject } from 'core-app/utils/helpers'
import { getDateByFormat } from 'core-app/utils/date'
import { Select } from 'ui-lib/components/Select'
import { RadioInputGroup } from 'ui-lib/components/Radio'
import { Button } from 'ui-lib/components/Button'
import { Box } from 'ui-lib/utils/Box'
import { Label, TableTitle } from 'ui-lib/components/Typography'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import theme from 'ui-lib/utils/base-theme'
import EditEllipsis from 'ui-lib/icons/editEllipsis.svg'

export const getColumnConfig = ({ t, partners, onChange, selectedCPID }) => {
	return [
		{
			property: 'scheduledDate',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.scheduledDate)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { startAt } = datum

				const participantOrganization = getIn(datum, [
					'participants',
				]).filter(org => org.clientType === 'org')

				const scheduledPeriod =
					getIn(participantOrganization, [0, 'scheduledPeriod']) || ''

				return (
					<>
						<Label bold style={{ color: theme.color.accent2 }}>
							{getDateByFormat(startAt)}
						</Label>
						<Label style={{ color: '#6a6a6a' }}>
							{t(TIME[scheduledPeriod])}
						</Label>
					</>
				)
			},
		},
		{
			property: 'supplierName',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.supplierName)}</TableTitle>
				</Box>
			),
			render: datum => {
				const participantOrganization = getIn(datum, [
					'participants',
				]).filter(org => org.clientType === 'org')

				const participantOrganizationID =
					getIn(participantOrganization, [0, 'participantID']) || ''

				const partnerDetails =
					getIn(partners, [participantOrganizationID]) || {}

				const partnerName = getIn(partnerDetails, ['name']) || '---'

				const scheduleType = getIn(datum, ['type'])

				return (
					<>
						<Label bold style={{ color: theme.color.accent2 }}>
							{partnerName}
						</Label>
						<Label style={{ color: '#6a6a6a' }}>
							{t(SERVICE_TYPES[scheduleType])}
						</Label>
					</>
				)
			},
		},
		{
			property: 'previousSchedule',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.previousSchedule)}</TableTitle>
				</Box>
			),
			render: datum => {
				const previousSchedule = getIn(datum, [
					'participants',
					0,
					'previousOccurrence',
				])

				return <Label>{getDateByFormat(previousSchedule)}</Label>
			},
		},
		{
			property: 'status',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.status)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { status } = datum
				const scheduleType = getIn(datum, ['type'])

				const isDriverAssigned =
					status === 'upcoming' &&
					getIn(datum, ['participants']).filter(
						org => org.clientType === 'user'
					).length === 0

				return (
					<Select
						options={[
							{
								name: 'upcoming',
								label: t('schedulerListing.status'),
								color: '#ABB3BE',
							},
							{
								name: 'pending',
								label: t(
									'schedulerListing.pending'
								).toUpperCase(),
								color: '#378AEC',
							},
							{
								name: 'intransit',
								label: t(
									'schedulerListing.intransit'
								).toUpperCase(),
								color: '#DB9C1F',
							},
							{
								name: 'delivered',
								label: t(
									'schedulerListing.delivered'
								).toUpperCase(),
								color: '#4AA025',
							},
						]}
						labelKey="label"
						valueKey="name"
						value={status}
						disabled={
							status === 'delivered' ||
							(scheduleType === 'palmoil-service-transport' &&
								isDriverAssigned) ||
							selectedCPID
						}
						onChange={({ name }) => {
							onChange('statusUpdate', {
								name,
								id: datum.id,
							})
						}}
						hideError
						returnOnlyValue
						key={datum.id}
						customInputPadding={8}
						style={{
							background: '#FAFAFC',
							borderRadius: '18px',
							width: '150px',
							border: 'none',
						}}
					/>
				)
			},
			size: '16%',
		},
		{
			property: 'transporter',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.transporter)}</TableTitle>
				</Box>
			),
			render: datum => {
				const scheduleStatus = getIn(datum, ['status'])
				const scheduleType = getIn(datum, ['type'])

				const scheduleTransport =
					getIn(datum, ['meta', 'scheduleTransport']) || false

				if (scheduleType === 'palmoil-service-transport') {
					return (
						<AddDriver
							data={datum}
							onClick={() => {
								if (!selectedCPID) {
									onChange('triggerDriverModal', {
										row: datum,
									})
								}
							}}
						/>
					)
				}

				if (
					scheduleType === 'palmoil-service-harvest' &&
					scheduleStatus === 'upcoming'
				) {
					return (
						<Button
							label={`${t('createSchedule.schedule')} ${t(
								'createSchedule.transport'
							)}`}
							disabled={scheduleTransport || selectedCPID}
							rounded
							onClick={() => {
								onChange('schedule_transport', {
									row: datum,
								})
							}}
							extendStyles={{
								fontSize: theme.fontSize.m,
								maxWidth: 192,
								border: '1px solid #3F56C4',
								borderRadius: '18px',
							}}
						/>
					)
				}
			},
			size: '20%',
		},
		{
			property: 'callToAction',
			render: datum => {
				return (
					<IconWrapper
						size={20}
						style={{ cursor: 'pointer' }}
						onClick={() =>
							onChange('open_document', {
								row: datum,
							})
						}
					>
						<Icon glyph={EditEllipsis} />
					</IconWrapper>
				)
			},
			align: 'end',
		},
	]
}

export const createScheduleColumnConfig = ({
	t,
	onChange,
	activeDocuments,
}) => {
	return [
		{
			property: 'checkbox',
			render: datum => {
				const { id } = datum

				const item = activeDocuments.find(e => e.participantID === id)

				return (
					<CheckBox
						onChange={() => {
							if (isEmptyObject(item)) {
								onChange('add_Supplier', {
									participantID: datum.id,
								})
							} else {
								onChange('remove_Supplier', {
									participantID: datum.id,
								})
							}
						}}
					/>
				)
			},
			size: '8%',
		},
		{
			property: 'supplierName',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.supplierName)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { name } = datum

				return <Label>{name}</Label>
			},
		},
		{
			property: 'scheduledPeriod',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle small style={{ marginLeft: 10 }}>
						{t('createSchedule.morning')}
					</TableTitle>
					<TableTitle small style={{ marginLeft: 30 }}>
						{t('createSchedule.afternoon')}
					</TableTitle>
					<TableTitle small style={{ marginLeft: 44 }}>
						{t('createSchedule.evening')}
					</TableTitle>
				</Box>
			),
			render: datum => {
				const { id } = datum

				const item = activeDocuments.find(e => e.participantID === id)
				const scheduledPeriod = getIn(item, ['scheduledPeriod']) || ''

				return (
					<RadioInputGroup
						direction="row"
						selected={scheduledPeriod}
						disabled={isEmptyObject(item)}
						gap="medium"
						options={[
							{
								label: `${t('schedulerListing.morning')}`,
								name: 'morning',
							},
							{
								label: `${t('schedulerListing.afternoon')}`,
								name: 'afternoon',
							},
							{
								label: `${t('schedulerListing.evening')}`,
								name: 'evening',
							},
						]}
						onChange={e => {
							onChange('add_ScheduledPeriod', {
								scheduledPeriod: e.target.value,
								participantID: datum.id,
							})
						}}
					/>
				)
			},
		},
	]
}
