import React from 'react'
import { useSelector } from 'react-redux'
import { NotFoundBlock } from 'core-app/modules/App/components/Error'
import { GeneralSchedulerDuc } from 'core-app/modules/GeneralScheduler/duc'
import { MainDashboard } from 'core-app/modules/GeneralScheduler/containers/MainDashboard'
import { ViewSchedule } from 'core-app/modules/GeneralScheduler/containers/ViewSchedule'

const modulesMap = {
	dashboard: MainDashboard,
	view: ViewSchedule,
}

const GeneralScheduler = () => {
	const activeModule = useSelector(GeneralSchedulerDuc.selectors.activeModule)
	const Component = modulesMap[activeModule] || NotFoundBlock

	return <Component />
}

export default GeneralScheduler
