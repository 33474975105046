import { AdminDuc } from 'core-app/modules/Admin/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { MainRouteDuc } from 'core-app/routes/duc'
import { getIn } from 'timm'
import saga from 'core-app/modules/Admin/AdminSaga'

export default async (dispatch, getState, { action, extra }) => {
	const { type, customMeta = {} } = action
	const currentLocationFromAction = getIn(action, [
		'meta',
		'location',
		'current',
	])
	/** if someone specifically wants to skip thunk fetch again, this check helps */
	const { skipRouteThunk } = customMeta
	if (skipRouteThunk) return

	// check if nothing changed in the route.

	const state = getState()

	const locationState = currentLocationFromAction || state.location

	const { payload, query = {} } = locationState
	const previewEdit = getIn(query, ['previewEdit']) || false

	// Inject the saga here. If it wasn't already

	const isMainDashboard = MainRouteDuc.types.ADMIN === type
	const isSubDashboard = MainRouteDuc.types.ADMIN$SUBROOT === type
	const isDocumentCreation =
		type === MainRouteDuc.types.ADMIN$WACTION ||
		type === MainRouteDuc.types.ADMIN$SUBMODULEWACTION
	const isDocumentUpdation =
		type === MainRouteDuc.types.ADMIN$WDOCREFERENCE ||
		type === MainRouteDuc.types.ADMIN$SUBMODULEWDOCREFERENCE
	const isDocumentTypeControl = isDocumentCreation || isDocumentUpdation

	let activeModule = ''
	dispatch(AuthDuc.creators.fetchActor())

	if (isMainDashboard) {
		// load the dashboard of the core module
		activeModule = 'dashboard'
	} else if (isSubDashboard) {
		activeModule = 'subDashboard'
		if (payload.rootModule === 'assets') {
			dispatch(AdminDuc.creators.fetchAssetsListing())
		} else if (payload.rootModule === 'employee-management') {
			dispatch(AdminDuc.creators.fetchEmployeesListing())
		} else if (payload.rootModule === 'company-information') {
			dispatch(AdminDuc.creators.fetchCompanyInfo())
		} else if (payload.rootModule === 'other-settings') {
			dispatch(AdminDuc.creators.fetchCompanyInfo())
		} else if (payload.rootModule === 'webhook-api-settings') {
			dispatch(AdminDuc.creators.fetchWebhookApiKey())
		}
	} else if (isDocumentTypeControl) {
		if (payload.rootModule === 'assets') {
			activeModule = 'assetManagement'
			if (payload.subModule === 'vehicles') {
				if (payload.action === 'create-asset') {
					dispatch(AdminDuc.creators.flushVehicleFormValues())
				}
				if (payload.action === 'edit-asset') {
					dispatch(
						AdminDuc.creators.initiateVehicleEdit(
							payload.documentReference
						)
					)
				}
			}
			if (payload.subModule === 'production-line') {
				if (
					payload.action === 'create-asset' ||
					payload.action === 'edit-asset'
				) {
					dispatch(AdminDuc.creators.flushProductionLineFormValues())
					dispatch(AuthDuc.creators.fetchAllProducts())
				}
				if (payload.action === 'edit-asset') {
					dispatch(
						AdminDuc.creators.initiateProductionLineEdit(
							payload.documentReference
						)
					)
				}
			}
			if (payload.subModule === 'storageunits') {
				if (
					payload.action === 'create-asset' ||
					payload.action === 'edit-asset'
				) {
					dispatch(AdminDuc.creators.flushStorageUnitFormValues())
					dispatch(AuthDuc.creators.fetchPartnerOrgs())
					dispatch(AuthDuc.creators.fetchAllProducts())
					dispatch(AuthDuc.creators.fetchCertificates())
					dispatch(AdminDuc.creators.fetchAssetsListing())
				}
				if (payload.action === 'edit-asset') {
					dispatch(
						AdminDuc.creators.initiateStorageUnitEdit(
							payload.documentReference
						)
					)
				}
			}
			if (payload.subModule === 'weighbridges') {
				if (payload.action === 'create-asset') {
					dispatch(AdminDuc.creators.flushWeighbridgesFormValues())
				}
				if (payload.action === 'edit-asset') {
					dispatch(
						AdminDuc.creators.initiateWeighbridgesEdit(
							payload.documentReference
						)
					)
				}
			}
		} else if (payload.rootModule === 'employee-management') {
			activeModule = 'employeeManagement'

			if (
				payload.action === 'add-employee' ||
				activeModule === 'employeeManagement'
			) {
				dispatch(AuthDuc.creators.fetchActor())
			}

			if (payload.action === 'view-employee') {
				dispatch(
					AdminDuc.creators.viewEmployeeDetails(
						payload.documentReference
					)
				)
			}
			if (payload.action === 'edit-employee' && previewEdit === false) {
				dispatch(
					AdminDuc.creators.initiateEditEmployeeDetails(
						payload.documentReference
					)
				)
			}
		} else if (payload.rootModule === 'collection-point') {
			activeModule = 'collectionPoint'
		}
	}

	// load the dashboard of the core module
	dispatch(AdminDuc.creators.setActiveModule(activeModule))

	// based on the payload or action, initiate saga's
}
