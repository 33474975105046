import styled from 'styled-components'

const FieldText = styled.div(
	({ error, theme, show, customLeftMargin, minHeight }) => ({
		textAlign: 'left',
		fontSize: theme.fontSize.xs,
		minHeight: minHeight || '24px',
		marginLeft: customLeftMargin || '10px',
		marginRight: '15px',
		color: error ? theme.color.error : theme.color.grey7,
		visibility: show ? 'visible' : 'hidden',
	})
)

export { FieldText }
