/* @flow */
import React from 'react'
// $FLowFixMe
import styled from 'styled-components'
import { Box } from 'ui-lib/utils/Box'
import { Icon } from 'ui-lib/icons/components/Icon'
import { H2, H3 } from 'ui-lib/components/Typography'

const TitleWrapper = styled.div(p => ({
	marginTop: p.small ? 'auto' : 24,
	marginBottom: p.small ? 'auto' : 24,
}))

const DirectionIconWrapper = styled.div`
	height: 24px;
	width: 24px;
	margin-right: 10px;
`

const Note = styled.span(p => ({
	...p.theme.fonts.regular,
	fontSize: p.fontSize ? p.fontSize : p.theme.fontSize.xs,
	color: p.noteColor ? p.noteColor : p.theme.color.black2,
}))

type Props = {
	id?: string,
	title: string,
	note?: string,
	icon?: {},
	small?: boolean,
	link?: boolean,
	noteColor?: string,
	noteFontSize?: string,
	onClick: () => void,
}

const Title = ({
	title,
	note,
	icon,
	small,
	onClick,
	noteColor,
	noteFontSize,
}: Props) => {
	const Component = small ? H3 : H2

	return (
		<TitleWrapper small={small} onClick={onClick}>
			<Box row alignItems="center">
				{icon && (
					<DirectionIconWrapper>
						<Icon glyph={icon} />
					</DirectionIconWrapper>
				)}

				<Component>{title}</Component>
			</Box>
			{note && (
				<Note noteColor={noteColor} fontSize={noteFontSize}>
					{note}
				</Note>
			)}
		</TitleWrapper>
	)
}

Title.defaultProps = {
	onClick: () => {},
}

export { Title, Note }
