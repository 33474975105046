/* Uncomment below line/s if you want to exclude the package from prod bundle */
// const methods = ['debug', 'log', 'info', 'warn', 'error']
// const LogDown = () => {}

export default (loggerName = 'ui-lib') => {
	const logger = require('logdown')(loggerName, { markdown: false })
	if (process.env.NODE_ENV === 'development' || process.env.NODE_DEBUG) {
		logger.state.isEnabled = true
	}

	return logger
}
