import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { merge } from 'timm'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { FileUpload } from 'ui-lib/components/FileUpload'
import { Label } from 'ui-lib/components/Label'
import theme from 'ui-lib/utils/base-theme'
import { Select } from 'ui-lib/components/Select'
import { useDispatch, useSelector } from 'react-redux'
import { AppDuc } from 'core-app/modules/App/duc'
import { Input } from 'ui-lib/components/Input'
import { WebTourDuc } from 'core-app/modules/WebTour/duc'

const EditBusiness = ({ edit = false, certDetails, onChange }) => {
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)
	const { t } = useTranslation()
	const dispatch = useDispatch()
	if (tourModule !== '') {
		dispatch(WebTourDuc.creators.setActiveTourModule(''))
	}
	const handleChange = useCallback(
		keyValue => {
			onChange(merge(certDetails, keyValue))
		},

		[onChange, certDetails]
	)

	useEffect(() => {
		handleChange()
	}, [handleChange])

	return (
		<>
			<Box>
				<Label
					required
					style={{
						fontWeight: '300px',
						fontSize: '16px',
						color: theme.color.accent2,
					}}
				>
					{t('onboarding.refNoType')}
				</Label>
				<Spacer size={8} />
				<Select
					options={[
						{
							name: t('onboarding.companyRegNum'),
							id: 'tax',
						},
						{
							name: t('viewCompanyInfo.other'),
							id: 'other',
						},
						{
							name: t('viewCompanyInfo.gst'),
							id: 'gst',
						},
					]}
					disabled={edit}
					labelKey="name"
					valueKey="id"
					value={certDetails.certType}
					onChange={value => handleChange({ certType: value.id })}
					returnOnlyValue
				/>
			</Box>

			<Box>
				<Label
					required
					style={{
						fontWeight: '300px',
						fontSize: '16px',
						color: theme.color.accent2,
					}}
				>
					{t('onboarding.refNo')}
				</Label>
				<Spacer size={8} />
				<Input
					value={certDetails.certNum}
					name="certNum"
					type="text"
					onChange={handleChange}
					returnKeyValue
					extendStyles={{
						background:
							certDetails.certNum.length === 0
								? `${theme.color.grey11} 0% 0% no-repeat padding-box`
								: `${theme.color.white} 0% 0% no-repeat padding-box`,
						boxShadow:
							certDetails.certNum.length === 0
								? '0px 6px 16px #05050707'
								: `0px 2px 8px ${theme.color.blue6}`,
						border:
							certDetails.certNum.length === 0
								? `1px solid ${theme.color.grey4}`
								: `1px solid ${theme.color.blue5}`,
						borderRadius: '4px',
						opacity: 1,
					}}
				/>
			</Box>

			<FileUpload
				type="private"
				name="file"
				size="5242880"
				label={t('onboarding.certCopy')}
				required
				supportedFileType=".jpg,.png,.pdf"
				outline
				modalTriggerBtnLabel={t('common.upload')}
				currentUploads={certDetails.files}
				onDocumentChange={document => handleChange({ files: document })}
				onUploadFailure={({ message }) => {
					dispatch(
						AppDuc.creators.showToast({
							messageType: 'error',
							message,
						})
					)
				}}
			/>
		</>
	)
}

export default EditBusiness
