import { FeedbackDuc } from 'core-app/modules/Feedback/duc'
import { MainRouteDuc } from 'core-app/routes/duc'
import saga from 'core-app/modules/Feedback/saga'

export default async (dispatch, getState, { action, extra }) => {
	const { type, customMeta = {} } = action

	/** if someone specifically wants to skip thunk fetch again, this check helps */
	const { skipRouteThunk } = customMeta
	if (skipRouteThunk) return

	// eslint-disable-next-line no-unused-vars

	extra.getSagaInjectors().injectSaga('feedback', { saga })

	let activeModule = ''

	// const state = getState()
	// Inject the saga here. If it wasn't already
	// extra.getSagaInjectors().injectSaga('listing', { saga })
	const isMainDashboard = MainRouteDuc.types.FEEDBACK === type
	if (isMainDashboard) {
		// load the dashboard of the core module
		activeModule = 'feedback'
	}
	dispatch(FeedbackDuc.creators.setActiveModule(activeModule))
}
