/* @flow */
import React from 'react'
import styled from 'styled-components'

type Props = {
	children: any,
	onClick: string => void,
	links: Array<{
		name?: string,
		label: string,
		isActive: 'string',
		url: 'string',
	}>,
}

const List = styled.ul`
	padding: 10px 0px;
	list-style: none;
`

const Item = styled.li`
	display: inline;
	&:after {
		padding: 8px;
		color: '#6A6A6A';
		content: '>';
	}
	&:last-child {
		&:after {
			content: '';
		}
	}
`

const Link = styled.a(p => ({
	fontSize: p.theme.fontSize.s,
}))

const LinkLabel = styled.span(({ active }) => ({
	color: '#6A6A6A',
	'text-decoration': 'none',
	cursor: 'not-allowed',
	...(active && { color: '#253D97', cursor: 'pointer' }),
	'&:hover': {
		textDecoration: active ? 'underline' : 'none',
	},
}))

const Breadcrumb = ({ links = [], onClick }: Props) => {
	return (
		<List>
			{links.map(({ name, label, isActive, url }) => {
				return (
					<Item key={label}>
						<Link
							href={url || '#'}
							onClick={e => {
								e.preventDefault()
								if (isActive) onClick(name || label)
							}}
						>
							<LinkLabel active={isActive}>{label}</LinkLabel>
						</Link>
					</Item>
				)
			})}
		</List>
	)
}

export { Breadcrumb }
