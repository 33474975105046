import React from 'react'
import { Box } from 'ui-lib/utils/Box'
import { Label, TableTitle } from 'ui-lib/components/Typography'
import { ButtonWithNoBorder } from 'ui-lib/components/Button'
import { CheckBox } from 'ui-lib/components/Checkbox'
import { getIn } from 'timm'
import { getDateByFormat } from 'core-app/utils/date'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import theme from 'ui-lib/utils/base-theme'
import VerifiedIcon from 'ui-lib/icons/verified.svg'
import NotVerifiedIcon from 'ui-lib/icons/not_verified.svg'
import InfoIcon from 'ui-lib/icons/info_black.svg'
import { Tip, Grommet } from 'grommet'
import { TABLE_ALIAS } from '../config'

export const getTractorColumnConfig = ({ users, binList, onChange, t }) => {
	const tooltipTheme = {
		tip: {
			content: {
				background: '#000000',
			},
		},
	}

	const syncIcon = (
		<Grommet theme={tooltipTheme}>
			<Tip
				content={
					<span style={{ fontSize: 12 }}>
						{t(TABLE_ALIAS.dataSync)}
					</span>
				}
			>
				<IconWrapper size={25} color={theme.color.themeGreen2}>
					<Icon glyph={VerifiedIcon} />
				</IconWrapper>
			</Tip>
		</Grommet>
	)

	const notSyncIcon = (
		<Grommet theme={tooltipTheme}>
			<Tip
				content={
					<span style={{ fontSize: 12 }}>
						{t(TABLE_ALIAS.dataNotSync)}
					</span>
				}
			>
				<IconWrapper size={25} color="#D05858">
					<Icon glyph={NotVerifiedIcon} />
				</IconWrapper>
			</Tip>
		</Grommet>
	)

	return [
		{
			property: 'date',
			header: (
				<Box row alignItems="center">
					<TableTitle>{t(TABLE_ALIAS.date)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { date } = datum

				return <Label as="span">{getDateByFormat(date)}</Label>
			},
		},
		{
			property: 'tractorOperatorName',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.tractorOperator)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { createdBy } = datum

				const tractorOperator = users.filter(
					user => user.id === createdBy
				)
				const name = getIn(tractorOperator, [0, 'fullName'])
				const userStatus = getIn(tractorOperator, [
					0,
					'status',
					'state',
				])

				return (
					<Label as="span">
						{name}
						{userStatus === 'deleted' ? (
							<Label
								style={{ marginLeft: 4 }}
								color={theme.color.grey6}
							>
								{t('common.inactive')}
							</Label>
						) : (
							''
						)}
					</Label>
				)
			},
		},
		{
			property: 'binNo',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.binNo)}</TableTitle>
				</Box>
			),
			render: datum => {
				const binNumber = datum.name
				const bin = (binList || []).filter(
					_bin => _bin.id === binNumber
				)
				const binName = getIn(bin, [0, 'name']) || 'NA'

				return <Label as="span">{binName}</Label>
			},
			primary: true,
		},
		{
			property: 'noOfTickets',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.noOfTickets)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { meta = {} } = datum
				const { checkerCodes } = meta

				return <Label as="span">{(checkerCodes || []).length}</Label>
			},
		},
		{
			property: 'status',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.syncStatus)}</TableTitle>
				</Box>
			),
			render: datum => {
				const status =
					getIn(datum, ['meta', 'dataSyncEnabled']) || false

				return status ? syncIcon : notSyncIcon
			},
		},
		{
			property: 'callToAction',
			render: datum => {
				return (
					<ButtonWithNoBorder
						label={t('common.viewBtn')}
						onClick={() =>
							onChange('open_document', {
								row: datum,
								docType: 'bin',
							})
						}
					/>
				)
			},
		},
	]
}

export const getDriverColumnConfig = ({ users, onChange, t, allPartners }) => {
	const tooltipTheme = {
		tip: {
			content: {
				background: '#000000',
			},
		},
	}

	const syncIcon = (
		<Grommet theme={tooltipTheme}>
			<Tip
				content={
					<span style={{ fontSize: 12 }}>
						{t(TABLE_ALIAS.dataSync)}
					</span>
				}
			>
				<IconWrapper size={25} color={theme.color.themeGreen2}>
					<Icon glyph={VerifiedIcon} />
				</IconWrapper>
			</Tip>
		</Grommet>
	)

	const notSyncIcon = (
		<Grommet theme={tooltipTheme}>
			<Tip
				content={
					<span style={{ fontSize: 12 }}>
						{t(TABLE_ALIAS.dataNotSync)}
					</span>
				}
			>
				<IconWrapper size={25} color="#D05858">
					<Icon glyph={NotVerifiedIcon} />
				</IconWrapper>
			</Tip>
		</Grommet>
	)

	const getReceiverName = receiverOrgID => {
		const partner = allPartners.find(item => item.id === receiverOrgID)

		return partner ? partner.name : ''
	}

	return [
		{
			property: 'date',
			header: (
				<Box row alignItems="center">
					<TableTitle>{t(TABLE_ALIAS.date)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { createdAt } = datum

				return <Label as="span">{getDateByFormat(createdAt)}</Label>
			},
		},
		{
			property: 'tractorOperatorName',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.driverName)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { clientID } = datum

				const driverName = users.filter(user => user.id === clientID)
				const name = getIn(driverName, [0, 'fullName'])
				const userStatus = getIn(driverName, [0, 'status', 'state'])

				return (
					<Label as="span">
						{name}
						{userStatus === 'deleted' ? (
							<Label
								style={{ marginLeft: 4 }}
								color={theme.color.grey6}
							>
								{t('common.inactive')}
							</Label>
						) : (
							''
						)}
					</Label>
				)
			},
		},
		{
			property: 'tripNo',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.tripNo)}</TableTitle>
				</Box>
			),
			render: datum => {
				const number = getIn(datum, ['number']) || 'NA'

				return <Label as="span">{number}</Label>
			},
			primary: true,
		},
		{
			property: 'quantity',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.quantity)}</TableTitle>
				</Box>
			),
			render: datum => {
				const quantity =
					getIn(datum, ['meta', 'storageDetails', 'quantity']) || 'NA'
				const uom =
					getIn(datum, ['meta', 'storageDetails', 'uom']) || 'NA'

				return <Label as="span">{`${quantity} ${uom}`}</Label>
			},
		},
		{
			property: 'receiver',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.receiver)}</TableTitle>
				</Box>
			),
			render: datum => {
				const receiverOrgID =
					getIn(datum, ['meta', 'receiverOrgID']) || ''

				return <Label as="span">{getReceiverName(receiverOrgID)}</Label>
			},
		},
		{
			property: 'status',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.syncStatus)}</TableTitle>
				</Box>
			),
			render: datum => {
				const status =
					getIn(datum, ['meta', 'storageDetailsSynced']) || false

				return status ? syncIcon : notSyncIcon
			},
		},
		{
			property: 'callToAction',
			render: datum => {
				return (
					<ButtonWithNoBorder
						label={t('common.viewBtn')}
						onClick={() =>
							onChange('open_document', {
								row: datum,
								docType: 'trips',
							})
						}
					/>
				)
			},
		},
	]
}

export const getSyncStatusColumnConfig = ({ t }) => {
	const tooltipTheme = {
		tip: {
			content: {
				background: '#000000',
			},
		},
	}

	const syncIcon = (
		<Grommet theme={tooltipTheme}>
			<Tip
				content={
					<span style={{ fontSize: 12 }}>
						{t(TABLE_ALIAS.dataSync)}
					</span>
				}
			>
				<IconWrapper size={25} color={theme.color.themeGreen2}>
					<Icon glyph={VerifiedIcon} />
				</IconWrapper>
			</Tip>
		</Grommet>
	)

	const notSyncIcon = (
		<Grommet theme={tooltipTheme}>
			<Tip
				content={
					<span style={{ fontSize: 12 }}>
						{t(TABLE_ALIAS.dataNotSync)}
					</span>
				}
			>
				<IconWrapper size={25} color="#D05858">
					<Icon glyph={NotVerifiedIcon} />
				</IconWrapper>
			</Tip>
		</Grommet>
	)

	const dataNotSyncDetail = (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<span style={{ float: 'left' }}>Not Assigned Yet</span>
			<Grommet
				theme={tooltipTheme}
				style={{ float: 'left', marginLeft: 10 }}
			>
				<Tip
					content={
						<span style={{ fontSize: 12 }}>
							{t(TABLE_ALIAS.dataNotSyncDetail)}
						</span>
					}
				>
					<IconWrapper size={25} color="#D05858">
						<Icon glyph={InfoIcon} />
					</IconWrapper>
				</Tip>
			</Grommet>
		</div>
	)

	return [
		{
			property: 'doNo',
			header: (
				<Box row alignItems="center">
					<TableTitle>{t(TABLE_ALIAS.doNumber)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { entityNumber } = datum

				return (
					<Label as="span">{entityNumber || dataNotSyncDetail}</Label>
				)
			},
		},
		{
			property: 'harvester',
			header: (
				<Box row alignItems="center">
					<TableTitle>{t(TABLE_ALIAS.harvester)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { harvester } = datum

				return harvester ? syncIcon : notSyncIcon
			},
		},
		{
			property: 'checker',
			header: (
				<Box row alignItems="center">
					<TableTitle>{t(TABLE_ALIAS.checker)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { checker } = datum

				return checker ? syncIcon : notSyncIcon
			},
		},
		{
			property: 'tractor',
			header: (
				<Box row alignItems="center">
					<TableTitle>{t(TABLE_ALIAS.tractor)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { tractorOperator } = datum

				return tractorOperator ? syncIcon : notSyncIcon
			},
		},
		{
			property: 'driver',
			header: (
				<Box row alignItems="center">
					<TableTitle>{t(TABLE_ALIAS.driver)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { driver } = datum

				return driver ? syncIcon : notSyncIcon
			},
		},
	]
}

export const getCheckerColumnConfig = ({ users, onChange, t }) => {
	const tooltipTheme = {
		tip: {
			content: {
				background: '#000000',
			},
		},
	}

	const syncIcon = (
		<Grommet theme={tooltipTheme}>
			<Tip
				content={
					<span style={{ fontSize: 12 }}>
						{t(TABLE_ALIAS.dataSync)}
					</span>
				}
			>
				<IconWrapper size={25} color={theme.color.themeGreen2}>
					<Icon glyph={VerifiedIcon} />
				</IconWrapper>
			</Tip>
		</Grommet>
	)

	const notSyncIcon = (
		<Grommet theme={tooltipTheme}>
			<Tip
				content={
					<span style={{ fontSize: 12 }}>
						{t(TABLE_ALIAS.dataNotSync)}
					</span>
				}
			>
				<IconWrapper size={25} color="#D05858">
					<Icon glyph={NotVerifiedIcon} />
				</IconWrapper>
			</Tip>
		</Grommet>
	)

	return [
		{
			property: 'date',
			header: (
				<Box row alignItems="center">
					<TableTitle>{t(TABLE_ALIAS.date)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { date } = datum

				return <Label as="span">{getDateByFormat(date)}</Label>
			},
		},
		{
			property: 'checker',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.checkerName)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { meta = {} } = datum
				const { updatedBy } = meta

				const checker = users.filter(user => user.id === updatedBy)
				const name = getIn(checker, [0, 'fullName']) || '---'
				const userStatus = getIn(checker, [0, 'status', 'state'])

				return (
					<Label as="span">
						{name}
						{userStatus === 'deleted' ? (
							<Label
								style={{ marginLeft: 4 }}
								color={theme.color.grey6}
							>
								{t('common.inactive')}
							</Label>
						) : (
							''
						)}
					</Label>
				)
			},
		},
		{
			property: 'ticketNo',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.ticketNo)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { cCode } = datum

				return <Label as="span">{cCode}</Label>
			},
			primary: true,
		},
		{
			property: 'harvesterName',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.harvesterName)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { createdBy } = datum

				const harvester = users.filter(user => user.id === createdBy)
				const name = getIn(harvester, [0, 'fullName']) || '---'
				const userStatus = getIn(harvester, [0, 'status', 'state'])

				return (
					<Label as="span">
						{name}
						{userStatus === 'deleted' ? (
							<Label
								style={{ marginLeft: 4 }}
								color={theme.color.grey6}
							>
								{t('common.inactive')}
							</Label>
						) : (
							''
						)}
					</Label>
				)
			},
		},
		{
			property: 'status',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.syncStatus)}</TableTitle>
				</Box>
			),
			render: datum => {
				const status =
					getIn(datum, ['meta', 'dataSyncEnabled']) || false

				return status ? syncIcon : notSyncIcon
			},
		},
		{
			property: 'callToAction',
			render: datum => {
				return (
					<ButtonWithNoBorder
						label={t('common.viewBtn')}
						onClick={() =>
							onChange('open_document', {
								row: datum,
								docType: 'ticket',
							})
						}
					/>
				)
			},
		},
	]
}

export const getHarvesterColumnConfig = ({ users, onChange, t }) => {
	const tooltipTheme = {
		tip: {
			content: {
				background: '#000000',
			},
		},
	}

	const syncIcon = (
		<Grommet theme={tooltipTheme}>
			<Tip
				content={
					<span style={{ fontSize: 12 }}>
						{t(TABLE_ALIAS.dataSync)}
					</span>
				}
			>
				<IconWrapper size={25} color={theme.color.themeGreen2}>
					<Icon glyph={VerifiedIcon} />
				</IconWrapper>
			</Tip>
		</Grommet>
	)

	const notSyncIcon = (
		<Grommet theme={tooltipTheme}>
			<Tip
				content={
					<span style={{ fontSize: 12 }}>
						{t(TABLE_ALIAS.dataNotSync)}
					</span>
				}
			>
				<IconWrapper size={25} color="#D05858">
					<Icon glyph={NotVerifiedIcon} />
				</IconWrapper>
			</Tip>
		</Grommet>
	)

	return [
		{
			property: 'date',
			header: (
				<Box row alignItems="center">
					<TableTitle>{t(TABLE_ALIAS.date)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { date } = datum

				return <Label as="span">{getDateByFormat(date)}</Label>
			},
		},
		{
			property: 'harvesterName',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.harvesterName)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { createdBy } = datum
				const harvester = users.filter(user => user.id === createdBy)
				const name = getIn(harvester, [0, 'fullName'])
				const userStatus = getIn(harvester, [0, 'status', 'state'])

				return (
					<Label as="span">
						{name}
						{userStatus === 'deleted' ? (
							<Label
								style={{ marginLeft: 4 }}
								color={theme.color.grey6}
							>
								{t('common.inactive')}
							</Label>
						) : (
							''
						)}
					</Label>
				)
			},
		},
		{
			property: 'ticketNo',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.ticketNo)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { number } = datum

				return <Label as="span">{number}</Label>
			},
			primary: true,
		},
		{
			property: 'quantity',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.quantity)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { ffbCount } = datum

				return <Label as="span">{ffbCount}</Label>
			},
		},
		{
			property: 'status',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.syncStatus)}</TableTitle>
				</Box>
			),
			render: datum => {
				const status =
					getIn(datum, ['meta', 'dataSyncEnabled']) || false

				return status ? syncIcon : notSyncIcon
			},
		},
		{
			property: 'callToAction',
			render: datum => {
				return (
					<ButtonWithNoBorder
						label={t('common.viewBtn')}
						onClick={() =>
							onChange('open_document', {
								row: datum,
								docType: 'harvester',
							})
						}
					/>
				)
			},
		},
	]
}

export const getUnassignedTicketColumnConfig = ({
	onChange,
	checkStatus,
	t,
}) => {
	return [
		{
			property: 'ticketNo',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.ticketNo)}</TableTitle>
				</Box>
			),
			render: datum => {
				return (
					<Box overflow>
						<CheckBox
							hideError
							label={datum.number}
							name={datum.id}
							checked={checkStatus}
							returnKeyValue
							onChange={state => {
								const isToggled = state[datum.id]
								onChange('set_assign_ticket', {
									id: datum.id,
									action: isToggled ? 'add' : 'remove',
								})
							}}
						/>
					</Box>
				)
			},
		},
		{
			property: 'quantity',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.quantity)} (Nos)</TableTitle>
				</Box>
			),
			render: datum => {
				const { ffbCount } = datum

				return <Label as="span">{ffbCount}</Label>
			},
		},
		{
			property: 'area',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.area)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { ffbDetails = {} } = datum
				const { location = {} } = ffbDetails
				const { area } = location

				return <Label as="span">{area}</Label>
			},
		},
		{
			property: 'blockNo',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.blockNo)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { ffbDetails = {} } = datum
				const { location = {} } = ffbDetails
				const { block } = location

				return <Label as="span">{block}</Label>
			},
		},
	]
}

export const getAssignedTicketColumnConfig = ({ t }) => {
	return [
		{
			property: 'ticketNo',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.ticketNo)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { number } = datum

				return <Label as="span">{number}</Label>
			},
		},
		{
			property: 'area',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.area)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { ffbDetails = {} } = datum
				const { location = {} } = ffbDetails
				const { area } = location

				return <Label as="span">{area}</Label>
			},
		},
		{
			property: 'blockNo',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.blockNo)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { ffbDetails = {} } = datum
				const { location = {} } = ffbDetails
				const { block } = location

				return <Label as="span">{block}</Label>
			},
		},
		{
			property: 'hCode',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.hCode)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { hCode } = datum

				return <Label as="span">{hCode}</Label>
			},
		},
		{
			property: 'cCode',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.cCode)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { cCode } = datum

				return <Label as="span">{cCode}</Label>
			},
		},
	]
}
