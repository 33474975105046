import Duck from 'extensible-duck'
import { setIn, getIn } from 'timm'
import { createSelector } from 'reselect'
import { initialState } from 'core-app/modules/Dealer/config'

export const DealerDuc = new Duck({
	namespace: 'dealer',
	store: 'global',
	types: [
		'SET_ACTIVE_MODULE',
		'FETCH_INSIGHTS',
		'FETCH_INSIGHTS_SUCCESS',
		'FETCH_WORK_LISTING',
		'SET_WORK_LISTING',
		'CREATE_WORK',
		'INITIATE_WORK_EDIT',
		'VIEW_WORK_DETAILS',
		'SET_WORK_DETAILS',
		'DELETE_WORK_DETAILS',
		'SET_INITIAL_FORM_VALUES',
		'SET_LOADING_STATUS',
		'SAVE_PAGINATION_QUERIES',
		'SET_DEALER_PARTICIPANT_ID', // ugly hack to fix re-rendering in create schedule
	],
	initialState,
	reducer: (state, action, duck) => {
		switch (action.type) {
			case duck.types.SET_ACTIVE_MODULE: {
				return setIn(state, ['activeModule'], action.module)
			}

			case duck.types.FETCH_INSIGHTS_SUCCESS: {
				const { userInsight } = action

				return setIn(state, ['insightsData'], userInsight)
			}

			case duck.types.SET_WORK_LISTING: {
				const { isSkip, activeDocuments } = action
				const currentDocs =
					getIn(state, ['modules', 'work', 'workListing']) || []

				return setIn(
					state,
					['modules', 'work', 'workListing'],
					isSkip
						? activeDocuments
						: currentDocs.concat(activeDocuments)
				)
			}

			case duck.types.SET_WORK_DETAILS: {
				return setIn(
					state,
					['modules', 'work', 'workDetails'],
					action.details
				)
			}

			case duck.types.SET_INITIAL_FORM_VALUES: {
				return setIn(
					state,
					['modules', 'work', 'initialWorkDetails'],
					action.details
				)
			}

			case duck.types.SET_LOADING_STATUS: {
				return setIn(
					state,
					['modules', 'work', 'loading'],
					action.status
				)
			}

			case duck.types.SAVE_PAGINATION_QUERIES: {
				const { paginate } = action

				return setIn(state, ['modules', 'work', 'pagination'], paginate)
			}
			case duck.types.SET_DEALER_PARTICIPANT_ID: {
				return setIn(
					state,
					['modules', 'dealerParticipantID'],
					action.id
				)
			}
			default:
				return state
		}
	},
	selectors: {
		auth: state => state.auth,
		location: state => state.location,
		activeModule: state =>
			getIn(state, ['dealer', 'activeModule']) || 'Error',
		workState: state => getIn(state, ['dealer', 'modules', 'work']),
		getInsightsData: state =>
			getIn(state, ['dealer', 'insightsData']) || {},
		getWorkListingDocuments: new Duck.Selector(selectors =>
			createSelector(selectors.workState, work =>
				getIn(work, ['workListing'])
			)
		),
		getInitialWorkDetails: state =>
			getIn(state, ['dealer', 'modules', 'work', 'initialWorkDetails']) ||
			{},
		getWorkInformation: new Duck.Selector(selectors =>
			createSelector(selectors.workState, work =>
				getIn(work, ['workDetails'])
			)
		),
		getLoadingStatus: new Duck.Selector(selectors =>
			createSelector(selectors.workState, work =>
				getIn(work, ['loading'])
			)
		),
		fetchMainDashboardPagination: new Duck.Selector(selectors =>
			createSelector(
				selectors.workState,
				work => getIn(work, ['pagination']) || []
			)
		),
		getDealerParticipantID: state =>
			getIn(state, ['dealer', 'modules', 'dealerParticipantID']),
	},
	creators: duck => ({
		setActiveModule: module => ({
			type: duck.types.SET_ACTIVE_MODULE,
			module,
		}),
		fetchInsights: () => ({
			type: duck.types.FETCH_INSIGHTS,
		}),
		fetchInsightsSuccess: userInsight => ({
			type: duck.types.FETCH_INSIGHTS_SUCCESS,
			userInsight,
		}),
		fetchWorkListing: (
			isSkip,
			locationState,
			skipGlobalLoader = false
		) => ({
			type: duck.types.FETCH_WORK_LISTING,
			isSkip,
			locationState,
			skipGlobalLoader,
		}),
		setWorkListing: (isSkip, activeDocuments) => ({
			type: duck.types.SET_WORK_LISTING,
			isSkip,
			activeDocuments,
		}),
		createWork: (details, toastMessage) => ({
			type: duck.types.CREATE_WORK,
			details,
			toastMessage,
		}),
		initiateWorkEdit: ticketID => ({
			type: duck.types.INITIATE_WORK_EDIT,
			ticketID,
		}),
		setInitialFormValues: details => ({
			type: duck.types.SET_INITIAL_FORM_VALUES,
			details,
		}),
		viewWorkDetails: ticketID => ({
			type: duck.types.VIEW_WORK_DETAILS,
			ticketID,
		}),
		setWorkDetails: details => ({
			type: duck.types.SET_WORK_DETAILS,
			details,
		}),
		deleteTicket: ticketID => ({
			type: duck.types.DELETE_WORK_DETAILS,
			ticketID,
		}),
		setLoadingStatus: status => ({
			type: duck.types.SET_LOADING_STATUS,
			status,
		}),
		savePaginationQueries: paginate => ({
			type: duck.types.SAVE_PAGINATION_QUERIES,
			paginate,
		}),
		setDealerParticipantID: id => ({
			type: duck.types.SET_DEALER_PARTICIPANT_ID,
			id,
		}),
	}),
})
