/* @flow */
import React from 'react'
import styled, { keyframes } from 'styled-components'

const motion = () => keyframes`
  0% {
      transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const LoaderWrapper = styled.div(
	({ theme, backGroundColor, lockToScreen }) => ({
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: lockToScreen ? '100vh' : '100%',
		width: lockToScreen ? '100vw' : '100%',
		backgroundColor: backGroundColor || theme.color.grey2,
		zIndex: theme.zIndex.level5,
		position: lockToScreen ? 'fixed' : 'relative',
	})
)

const LoaderFixedWrapper = styled(LoaderWrapper)(p => ({
	top: '0px',
	left: '0px',
	height: '100vh',
	width: '100%',
	position: p.fixed ? 'fixed' : 'absolute',
	zIndex: p.theme.zIndex.level4,
	...(p.relative && { position: 'relative' }),
}))

const RingSpinner = styled.div`
	box-sizing: border-box;
	display: block;
	position: ${p => (p.relative ? 'relative' : 'absolute')};
	width: ${p => `${p.size}${p.sizeUnit}`};
	height: ${p => `${p.size}${p.sizeUnit}`};
	border: 3px solid ${p => p.color || p.theme.color.accent};
	border-radius: 50%;
	animation: ${() => motion()} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: ${p => p.color || p.theme.color.accent} transparent
		transparent transparent;
	:nth-child(1) {
		animation-delay: -0.45s;
	}
	:nth-child(2) {
		animation-delay: -0.3s;
	}
	:nth-child(3) {
		animation-delay: -0.15s;
	}
`

type SpinnerProps = {
	size?: number | string,
	sizeUnit?: string,
	color?: string | null,
	relative?: boolean,
}

const Spinner = ({ size, sizeUnit, color, relative }: SpinnerProps) => (
	<RingSpinner
		size={size}
		sizeUnit={sizeUnit}
		color={color}
		relative={relative}
	/>
)

Spinner.defaultProps = {
	size: 20,
	sizeUnit: 'px',
}

export { Spinner, LoaderFixedWrapper, LoaderWrapper }
