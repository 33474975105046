/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { combineReducers } from 'redux'
import reduceReducers from 'reduce-reducers'
import { AppDuc } from 'core-app/modules/App/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { CookieDuc } from 'core-app/modules/App/cookieDuc'
import { MainRouteDuc as PageDuc } from 'core-app/routes/duc'
import { PlantationDuc } from 'core-app/modules/Plantation/duc'
import { MillDuc } from 'core-app/modules/Mill/duc'
import { RefineryDuc } from 'core-app/modules/Refinery/duc'
import { AdminDuc } from 'core-app/modules/Admin/duc'
import { PartnerDuc } from 'core-app/modules/Partner/duc'
import { FeedbackDuc } from 'core-app/modules/Feedback/duc'
import { UploadContentDuc } from 'core-app/modules/UploadContent/duc'
import { TraceDuc } from 'core-app/modules/Trace/duc'
import { TraderDuc } from 'core-app/modules/Trader/duc'
import { EndManufacturerDuc } from 'core-app/modules/EndManufacturer/duc'
import { StorageCompanyDuc } from 'core-app/modules/StorageCompany/duc'
import { SettingsDuc } from 'core-app/modules/Settings/duc'
import { TripsDuc } from 'core-app/modules/Trips/duc'
import { WbTicketDuc } from 'core-app/modules/WbTicket/duc'
import { MessagesDuc } from 'core-app/modules/Messages/duc'
import { GeneralSchedulerDuc } from 'core-app/modules/GeneralScheduler/duc'
import { OnboardDuc } from 'core-app/modules/Onboard/duc'
import { ProductsDuc } from 'core-app/modules/Products/duc'
import { DealerDuc } from 'core-app/modules/Dealer/duc'
import { i18nextReducer } from 'i18next-redux-languagedetector'
import { WebTourDuc } from 'core-app/modules/WebTour/duc'
import { DeviceDetectionDuc } from './deviceDetectionDuc'

/*
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react-router-redux@5
 *
 */

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(injectedReducers) {
	return combineReducers({
		i18next: i18nextReducer,
		app: reduceReducers(
			AppDuc.reducer,
			CookieDuc.reducer,
			DeviceDetectionDuc.reducer
		),
		auth: AuthDuc.reducer,
		page: PageDuc.reducer,
		plantation: PlantationDuc.reducer,
		mill: MillDuc.reducer,
		refinery: RefineryDuc.reducer,
		admin: AdminDuc.reducer,
		partner: PartnerDuc.reducer,
		settings: SettingsDuc.reducer,
		onboard: OnboardDuc.reducer,
		feedback: FeedbackDuc.reducer,
		messages: MessagesDuc.reducer,
		trace: TraceDuc.reducer,
		storageCompany: StorageCompanyDuc.reducer,
		trader: TraderDuc.reducer,
		endManufacturer: EndManufacturerDuc.reducer,
		dealer: DealerDuc.reducer,
		trips: TripsDuc.reducer,
		ticket: WbTicketDuc.reducer,
		uploadContent: UploadContentDuc.reducer,
		'general-scheduler': GeneralSchedulerDuc.reducer,
		webTour: WebTourDuc.reducer,
		products: ProductsDuc.reducer,
		...injectedReducers,
	})
}
