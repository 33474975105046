import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { OnboardDuc } from 'core-app/modules/Onboard/duc'
import { NotFoundBlock } from 'core-app/modules/App/components/Error'
import { AddressBlock } from 'core-app/modules/Onboard/containers/NewSignup/AddressBlock'
import { CertificateBlock } from 'core-app/modules/Onboard/containers/NewSignup/CertificateBlock'
import { ProductBlock } from 'core-app/modules/Onboard/containers/NewSignup/ProductBlock'
import { QRRoutePage } from 'core-app/modules/Onboard/containers/NewSignup/QRRoutePage'
import { UploadCertificate } from 'core-app/modules/Onboard/containers/NewSignup/UploadCertificate'
import { ThankYou } from 'core-app/modules/Onboard/containers/NewSignup/ThankYou'
import { AllSet } from 'core-app/modules/Onboard/containers/NewSignup/AllSet'
import { AddCPMenu } from 'core-app/modules/Onboard/containers/NewSignup/AddCPMenu'
import { AddCollectionPoint } from 'core-app/modules/Onboard/containers/NewSignup/AddCollectionPoint'
import { SuccessWithCP } from 'core-app/modules/Onboard/containers/NewSignup/SuccessWithCP'
import { SuccessWithNoCP } from 'core-app/modules/Onboard/containers/NewSignup/SuccessWithNoCP'
import { CarbonNumber } from 'core-app/modules/Onboard/containers/NewSignup/CarbonNumber'

const Wrapper = styled.div({
	height: '100%',
	overflow: 'auto',
})

const modulesMap = {
	'add-cp-menu': AddCPMenu,
	'add-collection-point': AddCollectionPoint,
	'all-set': AllSet,
	qrredirect: QRRoutePage,
	'success-with-cp': SuccessWithCP,
	'success-with-no-cp': SuccessWithNoCP,
	thankyou: ThankYou,
	'update-address': AddressBlock,
	'update-certDetails': CertificateBlock,
	'update-prodDetails': ProductBlock,
	'upload-certificate': UploadCertificate,
	'carbon-number': CarbonNumber,
}

const Onboard = () => {
	const activeModule = useSelector(OnboardDuc.selectors.activeModule)
	const CoreComponent = modulesMap[activeModule] || NotFoundBlock

	return (
		<Wrapper>
			<div
				style={{
					minHeight: '100vh',
					position: 'relative',
					width: '100%',
					height: '100%',
				}}
			>
				<CoreComponent />
			</div>
		</Wrapper>
	)
}

export default Onboard
