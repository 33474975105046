export const SortableKeys = ['createdAt']

export const TRACE_GROUP_STATUS = ['transforming', 'transformed']

export const TABLE_ALIAS = {
	createdAt: 'production.createdAt',
	consignmentNumber: 'production.consignmentNumber',
	batchReference: 'production.batchReference',
	productionLine: 'production.productionLine',
	batchDetails: 'production.batchDetails',
	quantityUsed: 'production.quantityUsed',
	availableUsed: 'production.availableUsed',
	totalQuantity: 'production.totalQuantity',
	sourceStorage: 'common.sourceStorage',
	product: 'production.product',
	quantity: 'production.quantity',
	assignTank: 'production.assignTank',
	assignedTank: 'production.assignedTank',
	inputBatchReference: 'production.inputBatchReference',
	outputBatchReference: 'production.outputBatchReference',
	inputBatchDetails: 'production.inputBatchDetails',
	outputBatchDetails: 'production.outputBatchDetails',
	inputMaterial: 'production.inputMaterial',
	outputProduct: 'production.outputProduct',
	sourcedQuantity: 'production.sourcedQuantity',
	producedQuantity: 'production.producedQuantity',
	certificationType: 'assetsHome.certificationType',
	supplyChainModel: 'assetsHome.scModel',
	assignStorage: 'production.assignStorage',
}

export const PRODUCTION_INITIAL_TYPES = [
	'production-plot-input',
	'production-plot-output',
]
export const NAME_ALIASES = {
	transformimg: 'Sourced Batch',
	transformed: 'Produced Batch',
}

export const LabelsMap = {
	'produced-batch': 'production.transformed',
	'sourced-batch': 'production.transformimg',
}

export const initialState = {
	activeModule: 'refinery',
	loading: false,
	error: false,
	insightsData: {},
	modules: {
		production: {
			loading: false,
			activeTimeOffset: 5,
			storageTanks: [],
			allTypes: PRODUCTION_INITIAL_TYPES,
			activeSorts: {},
			documentListing: [],
			documentListingPagination: {},
			assignTank: [],
			activeDocuments: {
				plotInput: {},
				plotOutput: {},
			},
			activeRecords: {},
			tabsConfig: [
				{
					code: 'transforming',
					title: 'Input Batch',
				},
				{
					code: 'transformed',
					title: 'Output Batch',
				},
			],
		},
		listing: {
			loading: false,
			pagination: {
				activeIndex: 0, // total, pageCount
				limit: 0,
				total: 0,
				nextCursor: '',
			},
			activeDocuments: [],
			activeSorts: {},
			activeFilters: {},
		},
	},
}
