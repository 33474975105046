import React from 'react'
import { supplyChainModels } from 'core-app/modules/Admin/components/StorageDetails'
import { Box } from 'ui-lib/utils/Box'
import { Label, TableTitle } from 'ui-lib/components/Typography'
import { getIn } from 'timm'
import { ButtonWithNoBorder } from 'ui-lib/components/Button'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { SelectSearch } from 'ui-lib/components/Select'
import { SortDown, SortReset } from 'ui-lib/components/Table'
import { getNextSortOrder } from 'core-app/utils/helpers'
import { getDateByFormat } from 'core-app/utils/date'
import { SortableKeys, TABLE_ALIAS } from '../config'

export const getProductionTableColumnConfig = ({
	type,
	docType,
	sortsMap,
	onChange,
	subModuleType,
	allProducts,
	t,
}) => {
	return [
		{
			property: 'createdAt',
			header: (
				<Box
					row
					alignItems="center"
					style={{ cursor: 'pointer' }}
					onClick={() => {
						if (SortableKeys.includes('createdAt')) {
							onChange('initiate_sort', {
								type,
								docType: subModuleType,
								field: 'createdAt',
								order: getNextSortOrder(sortsMap.createdAt),
							})
						}
					}}
				>
					{SortableKeys.includes('createdAt') && (
						<IconWrapper
							width={10}
							margin="0 5px"
							sortUp={sortsMap.createdAt === 'asc'}
							sortDown={sortsMap.createdAt === 'desc'}
						>
							<Icon
								rotate180={sortsMap.createdAt === 'asc'}
								glyph={
									sortsMap.createdAt ? SortDown : SortReset
								}
							/>
						</IconWrapper>
					)}
					<TableTitle>{t(TABLE_ALIAS.createdAt)}</TableTitle>
				</Box>
			),
			render: datum => {
				const createdAt = getIn(datum, ['createdAt'])

				return <Label as="span">{getDateByFormat(createdAt)}</Label>
			},
			size: '15%',
		},
		{
			property: 'batchReference',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>
						{docType === 'transforming'
							? t(TABLE_ALIAS.inputBatchReference)
							: t(TABLE_ALIAS.outputBatchReference)}
					</TableTitle>
				</Box>
			),
			render: datum => {
				const batchReference =
					docType === 'transforming'
						? getIn(datum, ['batchReference'])
						: getIn(datum, ['meta', 'batchReference'])

				return <Label as="span">{batchReference}</Label>
			},
			primary: true,
			size: '22%',
		},
		{
			property: 'product',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>
						{docType === 'transforming'
							? t(TABLE_ALIAS.inputMaterial)
							: t(TABLE_ALIAS.outputProduct)}
					</TableTitle>
				</Box>
			),
			render: datum => {
				const productIDs =
					docType === 'transforming'
						? getIn(datum, ['meta', 'productIDs'])
						: [getIn(datum, ['productID'])]
				const productList = allProducts.filter(product =>
					(productIDs || []).includes(product.id)
				)
				const name = productList
					.map(item => {
						return item.name
					})
					.join(', ')

				return <Label as="span">{name}</Label>
			},
			size: '20%',
		},
		{
			property: 'quantity',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>
						{docType === 'transforming'
							? t(TABLE_ALIAS.sourcedQuantity)
							: t(TABLE_ALIAS.producedQuantity)}
					</TableTitle>
				</Box>
			),
			render: datum => {
				const productID =
					docType === 'transforming'
						? getIn(datum, ['meta', 'productIDs', 0])
						: getIn(datum, ['productID'])
				const productName = allProducts.filter(
					product => product.id === productID
				)
				const uom = getIn(productName, [0, 'uom']) || ''
				const totalQuantity =
					docType === 'transforming'
						? (datum.traces || [])
								.map(trace => trace.quantityUtilized)
								.reduce((ttl, trace) => trace + ttl, 0)
						: getIn(datum, ['totalQty'])

				return (
					<Label as="span">{`${
						totalQuantity
							?.toString()
							.match(/^-?\d+(?:\.\d{0,3})?/)[0]
					} ${uom} (${totalQuantity * 1000} kg)`}</Label>
				)
			},
			size: '10%',
		},
		{
			property: 'batchDetails',
			header: (
				<Box row center style={{ cursor: 'pointer' }}>
					<TableTitle>
						{docType === 'transforming'
							? t(TABLE_ALIAS.inputBatchDetails)
							: t(TABLE_ALIAS.outputBatchDetails)}
					</TableTitle>
				</Box>
			),
			render: datum => {
				return (
					<ButtonWithNoBorder
						label={t('common.viewBtn')}
						onClick={() =>
							onChange('open_document', {
								row: datum,
								docType,
							})
						}
					/>
				)
			},
			align: 'center',
		},
		{
			property: 'callToAction',
			render: datum => {
				if (docType === 'transforming')
					return (
						<ButtonWithNoBorder
							label={t('production.createOutputBatch')}
							onClick={() =>
								onChange('open_plot_output_workflow', {
									row: datum,
								})
							}
						/>
					)

				return ''
			},
			align: 'center',
		},
	]
}

export const getPlotOutputColumnConfig = ({
	activeProducts,
	handleChange,
	assignTank,
	productionLine,
	// activeRecords,
	t,
	activeDocuments,
}) => {
	const records = [
		// {
		// 	property: 'createdAt',
		// 	header: (
		// 		<Box row center style={{ cursor: 'pointer' }}>
		// 			<TableTitle>{t(TABLE_ALIAS.createdAt)}</TableTitle>
		// 		</Box>
		// 	),
		// 	render: datum => {
		// 		const date = getIn(datum, ['date']) || ''

		// 		return (
		// 			<DatePicker
		// 				name={`date-${datum.activeIndex}`}
		// 				value={date}
		// 				toDate
		// 				disabled
		// 				minDate={activeRecords.createdAt}
		// 				onChange={value => {
		// 					handleChange('add_Values', {
		// 						rowIndex: datum.activeIndex,
		// 						value,
		// 						type: 'date',
		// 					})
		// 				}}
		// 				placeholder={t('common.selectDate')}
		// 			/>
		// 		)
		// 	},
		// },
		{
			property: 'product',
			header: (
				<Box row style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.product)}</TableTitle>
				</Box>
			),
			render: datum => {
				const value = getIn(datum, ['productID']) || ''

				return (
					<SelectSearch
						value={value}
						name={`productID-${datum.activeIndex}`}
						onChange={values => {
							handleChange('add_Values', {
								rowIndex: datum.activeIndex,
								value: values.id,
								name: values.label,
								type: 'productID',
							})
						}}
						labelKey="label"
						valueKey="id"
						defaultLabel={t('common.selectDefaultLabel')}
						returnOnlyValue
						options={activeProducts}
						required
					/>
				)
			},
		},
		{
			property: 'productionLine',
			header: (
				<Box row style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.productionLine)}</TableTitle>
				</Box>
			),
			render: datum => {
				const value = getIn(datum, ['productionLine'])

				const options = productionLine.map(prodLine => {
					return {
						label: prodLine.name,
						id: prodLine.id,
					}
				})

				return (
					<SelectSearch
						value={value}
						name={`productID-${datum.activeIndex}`}
						onChange={values => {
							handleChange('add_Values', {
								rowIndex: datum.activeIndex,
								value: values.id,
								type: 'productionLine',
							})
						}}
						labelKey="label"
						valueKey="id"
						defaultLabel={t('common.selectDefaultLabel')}
						returnOnlyValue
						options={options}
						required
					/>
				)
			},
		},
	]
	if (
		activeDocuments &&
		activeDocuments[0] &&
		activeDocuments[0].storageUnits.length > 0
	) {
		records.push({
			property: 'quantity',
			header: (
				<Box row center style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.quantity)}</TableTitle>
				</Box>
			),
			render: datum => {
				const productID = getIn(datum, ['productID']) || ''
				const currentDocument =
					activeDocuments?.length > 0
						? activeDocuments.find(
								item => item.productID === productID
						  )
						: {}
				const quantity =
					currentDocument &&
					currentDocument.storageUnits &&
					currentDocument.storageUnits.length > 0
						? currentDocument.storageUnits
								.map(obj => obj.quantity)
								.reduce(
									(accumulator, current) =>
										accumulator + current,
									0
								)
						: 0

				return (
					<Box style={{ marginBottom: '24px', textAlign: 'center' }}>
						{`${
							quantity.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0]
						} MT`}
					</Box>
				)
			},
		})
	}
	records.push({
		property: 'assignTank',
		header: (
			<Box row center style={{ cursor: 'pointer' }}>
				<TableTitle>{t(TABLE_ALIAS.assignTank)}</TableTitle>
			</Box>
		),
		render: datum => {
			const productID = getIn(datum, ['productID'])

			return (
				<>
					<Box style={{ marginBottom: '24px' }}>
						<ButtonWithNoBorder
							disabled={!(productID && productionLine)}
							label={t('production.assignTank')}
							onClick={() =>
								assignTank(datum.activeIndex, productID)
							}
						/>
					</Box>
				</>
			)
		},
	})

	return records
}

export const getPlotInputColumnConfig = ({
	activeProducts,
	activeDocuments,
	handleChange,
	assignTank,
	productionLine,
	t,
}) => {
	const records = [
		// {
		// 	property: 'createdAt',
		// 	header: (
		// 		<Box row center style={{ cursor: 'pointer' }}>
		// 			<TableTitle>{t(TABLE_ALIAS.createdAt)}</TableTitle>
		// 		</Box>
		// 	),
		// 	render: datum => {
		// 		const date = getIn(datum, ['date']) || ''

		// 		return (
		// 			<DatePicker
		// 				name={`date-${datum.activeIndex}`}
		// 				value={date}
		// 				onChange={value => {
		// 					handleChange('add_Values', {
		// 						rowIndex: datum.activeIndex,
		// 						value,
		// 						type: 'date',
		// 					})
		// 				}}
		// 				disabled
		// 				placeholder={t('common.selectDate')}
		// 			/>
		// 		)
		// 	},
		// },
		{
			property: 'product',
			header: (
				<Box row style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.product)}</TableTitle>
				</Box>
			),
			render: datum => {
				const value = getIn(datum, ['productID']) || ''

				return (
					<SelectSearch
						value={value}
						name={`productID-${datum.activeIndex}`}
						onChange={values => {
							handleChange('add_Values', {
								rowIndex: datum.activeIndex,
								value: values.id,
								type: 'productID',
							})
						}}
						labelKey="label"
						valueKey="id"
						defaultLabel={t('common.selectDefaultLabel')}
						returnOnlyValue
						options={activeProducts}
						required
					/>
				)
			},
		},
		{
			property: 'productionLine',
			header: (
				<Box row style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.productionLine)}</TableTitle>
				</Box>
			),
			render: datum => {
				const value = getIn(datum, ['productionLine'])

				const options = productionLine.map(prodLine => {
					return {
						label: prodLine.name,
						id: prodLine.id,
					}
				})

				return (
					<SelectSearch
						value={value}
						name={`productID-${datum.activeIndex}`}
						onChange={values => {
							handleChange('add_Values', {
								rowIndex: datum.activeIndex,
								value: values.id,
								type: 'productionLine',
							})
						}}
						labelKey="label"
						valueKey="id"
						defaultLabel={t('common.selectDefaultLabel')}
						returnOnlyValue
						options={options}
						required
					/>
				)
			},
		},
	]

	if (
		activeDocuments &&
		activeDocuments[0] &&
		activeDocuments[0].storageUnits.length > 0
	) {
		records.push({
			property: 'quantity',
			header: (
				<Box row style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.quantity)}</TableTitle>
				</Box>
			),
			render: datum => {
				const productID = getIn(datum, ['productID']) || ''
				const currentDocument =
					activeDocuments?.length > 0
						? activeDocuments.find(
								item => item.productID === productID
						  )
						: {}
				const quantity =
					currentDocument &&
					currentDocument.storageUnits &&
					currentDocument.storageUnits.length > 0
						? currentDocument.storageUnits
								.map(obj => obj.quantity)
								.reduce(
									(accumulator, current) =>
										accumulator + current,
									0
								)
						: 0

				return (
					<Box style={{ marginBottom: '24px', textAlign: 'center' }}>
						{`${
							quantity.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0]
						} MT`}
					</Box>
				)
			},
		})
	}

	records.push({
		property: 'assignTank',
		header: (
			<Box row center style={{ cursor: 'pointer' }}>
				<TableTitle>{t(TABLE_ALIAS.assignStorage)}</TableTitle>
			</Box>
		),
		render: datum => {
			const productID = getIn(datum, ['productID'])
			const productTotalQuantity = productID
				? activeProducts.find(item => item.id === productID)
						.availableQty
				: 0

			return (
				<Box style={{ marginBottom: '24px' }}>
					<ButtonWithNoBorder
						key={`${datum.activeIndex}-${productID}`}
						disabled={
							!productID ||
							!productionLine ||
							productTotalQuantity === 0
						}
						label={t('production.assignStorage')}
						onClick={() => assignTank(datum.activeIndex, productID)}
					/>
				</Box>
			)
		},
	})

	return records
}

export const getProducedBatchConfig = ({
	allProducts,
	t,
	createdAt,
	storageList,
}) => {
	return [
		{
			property: 'createdAt',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.createdAt)}</TableTitle>
				</Box>
			),
			render: () => {
				return <Label as="span">{getDateByFormat(createdAt)}</Label>
			},
		},
		{
			property: 'product',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>Produced Product</TableTitle>
				</Box>
			),
			render: datum => {
				const { productID } = datum
				const productName = allProducts.filter(
					product => product.id === productID
				)
				const name = getIn(productName, [0, 'name']) || ''

				return <Label as="span">{name || '---'}</Label>
			},
		},
		{
			property: 'supplyChainModel',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.supplyChainModel)}</TableTitle>
				</Box>
			),
			render: datum => {
				const supplyChainModel =
					getIn(datum, ['supplyChainModel']) || '---'

				return (
					<Label as="span">
						{t(supplyChainModels[supplyChainModel])}
					</Label>
				)
			},
		},
		{
			property: 'assignedTank',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.assignedTank)}</TableTitle>
				</Box>
			),
			render: datum => {
				const storageID = getIn(datum, ['storageUnitID']) || '---'
				const storageUnit = getIn(
					storageList.filter(tank => tank.id === storageID),
					[0, 'name']
				)

				return <Label small>{storageUnit}</Label>
			},
		},
		{
			property: 'totalQuantity',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.totalQuantity)}</TableTitle>
				</Box>
			),
			render: datum => {
				const quantityUtilized = getIn(datum, ['totalQty']) || '---'
				const { productID } = datum
				const productName = allProducts.filter(
					product => product.id === productID
				)
				const uom = getIn(productName, [0, 'uom']) || ''

				return (
					<Label as="span">{`${quantityUtilized} ${uom} (${quantityUtilized *
						1000} Kg)`}</Label>
				)
			},
		},
	]
}

export const getSourcedBatchConfig = ({ allProducts, storageList, t }) => {
	return [
		{
			property: 'createdAt',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.createdAt)}</TableTitle>
				</Box>
			),
			render: datum => {
				const createdAt = getIn(datum, ['createdAt']) || '---'

				return <Label as="span">{getDateByFormat(createdAt)}</Label>
			},
		},
		{
			property: 'product',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.product)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { productID } = datum
				const productName = allProducts.filter(
					product => product.id === productID
				)
				const name = getIn(productName, [0, 'name']) || ''

				return <Label as="span">{name || '---'}</Label>
			},
			primary: true,
		},
		{
			property: 'supplyChainModel',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.supplyChainModel)}</TableTitle>
				</Box>
			),
			render: datum => {
				const supplyChainModel =
					getIn(datum, ['supplyChainModel']) || '---'

				return (
					<Label as="span">
						{t(supplyChainModels[supplyChainModel])}
					</Label>
				)
			},
		},
		{
			property: 'sourceStorage',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.sourceStorage)}</TableTitle>
				</Box>
			),
			render: datum => {
				const storageID = getIn(datum, ['storageUnitID']) || '---'
				const storageUnit = getIn(
					storageList.filter(tank => tank.id === storageID),
					[0, 'name']
				)

				return <Label small>{storageUnit}</Label>
			},
		},
		{
			property: 'totalQuantity',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.totalQuantity)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { productID } = datum
				const productName = allProducts.filter(
					product => product.id === productID
				)
				const uom = getIn(productName, [0, 'uom']) || ''
				const quantityUtilized =
					getIn(datum, ['quantityUtilized']) || '---'

				return (
					<Label as="span">{`${quantityUtilized} ${uom} (${quantityUtilized *
						1000} Kg)`}</Label>
				)
			},
		},
	]
}

export const getConsumedBatches = ({ allProducts, storageList, t }) => {
	return [
		{
			property: 'createdAt',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.createdAt)}</TableTitle>
				</Box>
			),
			render: datum => {
				const createdAt = getIn(datum, ['createdAt']) || '---'

				return <Label as="span">{getDateByFormat(createdAt)}</Label>
			},
		},
		{
			property: 'product',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>Sourced Product</TableTitle>
				</Box>
			),
			render: datum => {
				const { productID } = datum
				const productName = allProducts.filter(
					product => product.id === productID
				)
				const name = getIn(productName, [0, 'name']) || ''

				return <Label as="span">{name || '---'}</Label>
			},
		},
		{
			property: 'supplyChainModel',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.supplyChainModel)}</TableTitle>
				</Box>
			),
			render: datum => {
				const supplyChainModel =
					getIn(datum, ['supplyChainModel']) || '---'

				return (
					<Label as="span">
						{t(supplyChainModels[supplyChainModel])}
					</Label>
				)
			},
		},
		{
			property: 'assignedStorage',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.sourceStorage)}</TableTitle>
				</Box>
			),
			render: datum => {
				const storageID = getIn(datum, ['storageUnitID']) || '---'
				const storageUnit = getIn(
					storageList.filter(tank => tank.id === storageID),
					[0, 'name']
				)

				return <Label small>{storageUnit}</Label>
			},
		},
		{
			property: 'totalQuantity',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.totalQuantity)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { quantityUtilized, productID } = datum
				const productName = allProducts.filter(
					product => product.id === productID
				)
				const uom = getIn(productName, [0, 'uom']) || ''

				return (
					<Label as="span">{`${quantityUtilized} ${uom} (${quantityUtilized *
						1000} Kg)`}</Label>
				)
			},
		},
	]
}

export const getStorageInputConfig = ({ t, assignTank, viewDetails }) => {
	return [
		{
			property: 'consignmentNumber',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.consignmentNumber)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { number } = datum

				return <Label as="span">{number}</Label>
			},
			primary: true,
		},
		{
			property: 'createdAt',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.createdAt)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { createdAt } = datum

				return <Label as="span">{getDateByFormat(createdAt)}</Label>
			},
		},
		{
			property: 'product',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.product)}</TableTitle>
				</Box>
			),
			render: datum => {
				const product = getIn(datum, ['products', 0, 'name']) || ''

				return <Label as="span">{product}</Label>
			},
		},
		{
			property: 'certificationType',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.certificationType)}</TableTitle>
				</Box>
			),
			render: datum => {
				const certification =
					getIn(datum, ['products', 0, 'certification']) || ''

				return (
					<Label as="span">
						{certification === 'uncertified'
							? t('common.uncertified')
							: certification}
					</Label>
				)
			},
		},
		{
			property: 'supplyChainModel',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.supplyChainModel)}</TableTitle>
				</Box>
			),
			render: datum => {
				const supplyChainModel =
					getIn(datum, ['products', 0, 'supplyChainModel']) || ''

				return (
					<Label as="span">
						{supplyChainModel
							? t(`tdmDetailsEntry.${supplyChainModel}`)
							: ''}
					</Label>
				)
			},
		},
		{
			property: 'quantity',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.quantity)}</TableTitle>
				</Box>
			),
			render: datum => {
				const quantity = datum.receiverTraceIDs
					? datum.receiverTraceIDs
							.reduce((accumulator, object) => {
								return accumulator + object.availableQty
							}, 0)
							.toString()
							.match(/^-?\d+(?:\.\d{0,3})?/)[0]
					: 0

				const uom = getIn(datum, ['products', 0, 'uom']) || ''

				return <Label as="span">{`${quantity} ${uom}`}</Label>
			},
		},
		{
			property: 'viewDocument',
			render: datum => {
				const traceID =
					getIn(datum, ['receiverTraceIDs', 0, 'traceID']) || ''
				const isAssigned =
					getIn(datum, ['meta', 'movedQuantity']) !==
					getIn(datum, ['meta', 'wbNetWeight'])

				const product = getIn(datum, ['products', 0, 'name']) || ''
				const productID = getIn(datum, ['products', 0, 'id']) || ''

				const quantity = datum.receiverTraceIDs
					? datum.receiverTraceIDs
							.reduce((accumulator, object) => {
								return accumulator + object.availableQty
							}, 0)
							.toString()
							.match(/^-?\d+(?:\.\d{0,3})?/)[0]
					: 0

				const uom = getIn(datum, ['products', 0, 'uom']) || ''
				const qtyWithUOM = `${quantity} ${uom}`

				const supplyChainModel = getIn(datum, [
					'meta',
					'supplyChainModel',
					'id',
				])

				const entityID = getIn(datum, ['id'])

				const consignmentNumber = getIn(datum, ['number'])
				const issueDate = getIn(datum, ['createdAt'])
				const certification = getIn(datum, [
					'products',
					0,
					'certification',
				])

				const traceIDs = (datum.receiverTraceIDs || [])
					.map(trace => trace.traceID)
					.join(',')

				return isAssigned ? (
					<ButtonWithNoBorder
						label={t('production.assignStorage')}
						onClick={() =>
							assignTank({
								traceID,
								product,
								productID,
								qtyWithUOM,
								quantity,
								supplyChainModel,
								entityID,
								certification,
							})
						}
					/>
				) : (
					<ButtonWithNoBorder
						label={t('storage.detailsTank')}
						onClick={() =>
							viewDetails(traceIDs, consignmentNumber, issueDate)
						}
					/>
				)
			},
		},
	]
}

export const getColumnConfig = ({ allProducts, storageUnits, t }) => {
	return [
		{
			property: 'createdAt',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.createdAt)}</TableTitle>
				</Box>
			),
			render: datum => {
				const createdAt = getIn(datum, ['createdAt'])

				return <Label as="span">{getDateByFormat(createdAt)}</Label>
			},
			size: '12%',
		},
		{
			property: 'rawMaterials',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.inputMaterial)}</TableTitle>
				</Box>
			),
			render: datum => {
				const traces =
					getIn(datum, ['meta', 'plotIOPayload', 'traces']) || []

				const inputMaterials = traces
					?.filter(ipm => ipm.inventoryType === 'incoming')
					?.map(im => {
						const { quantity } = im

						const product = allProducts.filter(
							ap => ap.product.id === im.id
						)

						const uom = getIn(product, [0, 'product', 'uom'])

						return `${getIn(product, [
							0,
							'product',
							'name',
						])} - ${quantity} (${uom})`
					})

				return (
					<>
						{inputMaterials.map(ipmp => (
							<Label as="span" key={ipmp[0]}>
								{ipmp}
							</Label>
						))}
					</>
				)
			},
			size: '25%',
		},
		{
			property: 'finalProduct',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.outputProduct)}</TableTitle>
				</Box>
			),
			render: datum => {
				const traces =
					getIn(datum, ['meta', 'plotIOPayload', 'traces']) || []
				const outputMaterials = traces?.filter(
					ipm => ipm.inventoryType === 'outgoing'
				)

				const product = allProducts.filter(
					ap => ap.product.id === outputMaterials[0]?.id
				)

				const outputProductName =
					getIn(product, [0, 'product', 'name']) || '---'

				return <Label as="span">{`${outputProductName}`}</Label>
			},
			size: '25%',
		},
		{
			property: 'producedQuantity',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.producedQuantity)}</TableTitle>
				</Box>
			),
			render: datum => {
				const traces =
					getIn(datum, ['meta', 'plotIOPayload', 'traces']) || []
				const outputMaterials = traces?.filter(
					ipm => ipm.inventoryType === 'outgoing'
				)

				const product = allProducts.filter(
					ap => ap.id === outputMaterials[0]?.id
				)
				const uom = getIn(product, [0, 'uom']) || ''

				const quantity =
					getIn(outputMaterials, [0, 'quantity']) || '---'

				return (
					<Label as="span" style={{ textAlign: 'center' }}>
						{quantity} {uom}
					</Label>
				)
			},
			size: '18%',
		},
		{
			property: 'assignedTank',
			header: (
				<Box row center style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.assignedTank)}</TableTitle>
				</Box>
			),
			render: datum => {
				const traces =
					getIn(datum, ['meta', 'plotIOPayload', 'traces']) || []
				const tankID = traces?.filter(
					ipm => ipm.inventoryType === 'outgoing'
				)[0]?.storageUnitID

				const tankName = storageUnits.filter(st => st.id === tankID)[0]
					?.name

				return <Label as="span">{tankName}</Label>
			},
			align: 'center',
		},
	]
}
