import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { NumericFormat } from 'react-number-format'
import { useTranslation } from 'react-i18next'
import { getIn } from 'timm'
import { AppDuc } from 'core-app/modules/App/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { DealerDuc } from 'core-app/modules/Dealer/duc'
import { MainRouteDuc } from 'core-app/routes/duc'
import { CustomPieChart } from 'core-app/modules/Dashboard'
import { CustomCard } from 'core-app/modules/GeneralScheduler/containers/ViewSchedule'
import { Card } from 'ui-lib/components/Card'
import { H1, H2, SmallText, Text, Label, P } from 'ui-lib/components/Typography'
import { Box } from 'ui-lib/utils/Box'
import { isMarketplaceHost } from 'ui-lib/utils/helpers'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import DeliveryTruckIcon from 'ui-lib/icons/delivery-truck.svg'
import TimerIcon from 'ui-lib/icons/timer.svg'
import TimerOffIcon from 'ui-lib/icons/alarm_off.svg'
import theme from 'ui-lib/utils/base-theme'
import { Setup } from 'core-app/modules/App/containers/Setup'

const Note = styled.span(p => ({
	fontSize: p.theme.fontSize.xs,
	color: p.theme.color.black2,
}))

const KeyValueSegment = ({
	name,
	description,
	limitKeyWidth,
	limitValueWidth,
	valueMargin,
	percentage,
}) => {
	return (
		<Box
			row
			padding="0 0 8px 0"
			justifyContent="flex-start"
			alignItems="flex-start"
		>
			<Box style={{ width: limitKeyWidth ? 150 : 'auto' }}>
				<Label small bold>
					{`${name}                 `}
				</Label>
			</Box>
			<Box
				style={{
					margin: valueMargin || 0,
					width: limitValueWidth ? 250 : 'auto',
				}}
				flex
				justifyContent="center"
				alignItems="flex-start"
			>
				{description &&
				(typeof description === 'string' ||
					typeof description === 'number') ? (
					<P>{percentage ? `${description} %` : description}</P>
				) : (
					description
				)}
			</Box>
		</Box>
	)
}

const MainDashboard = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const insights = useSelector(DealerDuc.selectors.getInsightsData)
	const {
		weighbridgeAvailable,
		partnersAvailable,
		employeesAvailable,
		languageSet,
	} = useSelector(AuthDuc.selectors.getFirstTimeStats)
	const {
		productPurchased = {},
		productSold = {},
		trips = {},
		bestSuppliers = [],
		incomingFFBQuality = [],
		ffbPricing = [],
		region,
	} = insights
	const { today = {}, avgTripTiming, avgTripTimeUnit } = trips
	const todayPrice = getIn(ffbPricing, [0, 'value']) || 0
	const yesterdayPrice = getIn(ffbPricing, [1, 'value']) || 0
	const weeklyAvgPrice = getIn(ffbPricing, [2, 'value']) || 0
	const headingColor = theme.color.accent2
	const subHeadingColor = theme.color.grey10
	const showSetup = isMarketplaceHost()
		? employeesAvailable === false || languageSet === false
		: employeesAvailable === false ||
		  languageSet === false ||
		  partnersAvailable === false ||
		  weighbridgeAvailable === false

	return (
		<>
			{showSetup && <Setup />}

			<Box row justifyContent="space-between" alignItems="center">
				<H1 color={headingColor} style={{ margin: 0 }}>
					{t('dealerDashboard.dashboard')}
				</H1>
				<CustomCard
					style={{
						width: '204px',
						height: '32px',
						background: '#F4D7B1 0% 0% no-repeat padding-box',
						boxShadow: '0px 6px 8px #0000000F',
						borderRadius: '16px',
						opacity: 1,
						cursor: 'pointer',
						textAlign: 'center',
						padding: '4px 0 0',
						margin: '0 12px 0 0',
					}}
					onClick={() => {
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types
									.TRADE_DOCUMENT_MANAGER$AUDIT_REPORT,
								{
									rootModule: 'general-report',
								},
								{},
								{},
								true
							)
						)
					}}
				>
					<P
						style={{
							color: '#D3803A',
							fontSize: theme.fontSize.l,
						}}
					>
						{t('dealerDashboard.generateReport')}
					</P>
				</CustomCard>
			</Box>
			<Box row={!isMobile}>
				<Card textAlign="left" style={{ margin: '8px 0 0' }}>
					<Box
						row
						justifyContent="space-between"
						alignItems="baseline"
					>
						<H2
							style={{
								padding: '12px 12px 0px 12px',
								color: headingColor,
							}}
						>
							{t('dealerDashboard.purchase')}
						</H2>
						<Text
							style={{
								backgroundColor: '#EAF4FD',
								color: '#0644AD',
								margin: '0 12px 0 0',
								padding: ' 8px 12px',
								borderRadius: '16px',
								fontSize: theme.fontSize.l,
							}}
						>
							{t('dealerDashboard.lastMonth')}:
							{productPurchased.prevMonth || 0}&nbsp;
							{productPurchased.uom || 'Nos'}
						</Text>
					</Box>
					<SmallText
						style={{
							padding: '0 12px',
							color: subHeadingColor,
						}}
						fontSize={theme.fontSize.s}
					>
						{t('dealerDashboard.thisMonth')}
					</SmallText>
					<Box
						row
						padding={12}
						alignItems="baseline"
						justifyContent="space-between"
					>
						<Text style={{ fontSize: theme.fontSize.s }}>
							<b>{t('dealerDashboard.product')}:</b>&nbsp;
							{productPurchased.productName ||
								'Fresh Fruit Bunch'}
						</Text>
						<Text bold style={{ fontSize: theme.fontSize.xxxxl }}>
							<NumericFormat
								value={productPurchased.thisMonth || 0}
								displayType="text"
								thousandSeparator
							/>
							<SmallText>
								&nbsp;{productPurchased.uom || 'Nos'}
							</SmallText>
						</Text>
					</Box>
				</Card>
				<Card
					textAlign="left"
					style={{ margin: isMobile ? '12px 0 ' : '8px 12px 0' }}
				>
					<Box
						row
						justifyContent="space-between"
						alignItems="baseline"
					>
						<H2
							style={{
								padding: '12px 12px 0px 12px',
								color: headingColor,
							}}
						>
							{t('dealerDashboard.sales')}
						</H2>
						<Text
							style={{
								backgroundColor: '#EAF4FD',
								color: '#0644AD',
								margin: '0 12px 0 0',
								padding: ' 8px 12px',
								borderRadius: '16px',
								fontSize: theme.fontSize.l,
							}}
						>
							{t('dealerDashboard.lastMonth')}:
							{productSold.prevMonth || 0} &nbsp;
							{productSold.uom || 'MT'}
						</Text>
					</Box>
					<SmallText
						style={{
							padding: '0 12px',
							color: subHeadingColor,
						}}
						fontSize={theme.fontSize.s}
					>
						{t('dealerDashboard.thisMonth')}
					</SmallText>
					<Box
						row
						padding={12}
						alignItems="baseline"
						justifyContent="space-between"
					>
						<Text style={{ fontSize: theme.fontSize.s }}>
							<b>{t('dealerDashboard.product')}:</b>&nbsp;
							{productSold.productName || 'Fresh Fruit Bunch'}
						</Text>
						<Text bold style={{ fontSize: theme.fontSize.xxxxl }}>
							{productSold.thisMonth || 0}
							<SmallText>
								&nbsp;{productSold.uom || 'MT'}
							</SmallText>
						</Text>
					</Box>
				</Card>
			</Box>
			<Box row={!isMobile}>
				<Card textAlign="left" style={{ margin: '12px 0 0' }}>
					<Box
						row
						justifyContent="space-between"
						alignItems="baseline"
					>
						<H2
							style={{
								padding: '12px 12px 0px 12px',
								color: headingColor,
							}}
						>
							{t('dealerDashboard.trips')}
						</H2>
						<Text
							style={{
								backgroundColor: '#FFF4CB',
								color: '#A88920',
								margin: '0 12px 0 0',
								padding: ' 8px 12px',
								borderRadius: '16px',
								fontSize: theme.fontSize.l,
							}}
						>
							{t('dealerDashboard.thisMonth')}:
							{trips.thisMonth || 0}&nbsp;
							{t('dealerDashboard.trips')}
						</Text>
					</Box>
					<SmallText
						style={{
							padding: '0 12px',
							color: subHeadingColor,
						}}
						fontSize={theme.fontSize.s}
					>
						{t('dealerDashboard.tripsSubheading')}
					</SmallText>
					<Box
						row
						style={{ padding: '8px 0 0' }}
						justifyContent="space-between"
						alignItems="center"
					>
						<Box
							padding={12}
							style={{
								borderRadius: '50%',
								backgroundColor: '#E3E8FF',
								margin: '0 0 0 12px',
							}}
						>
							<IconWrapper size={80}>
								<Icon glyph={DeliveryTruckIcon} />
							</IconWrapper>
						</Box>
						{today.activeTrips || today.completedTrips ? (
							<Box>
								<Box
									row
									padding={12}
									alignItems="baseline"
									justifyContent="space-between"
								>
									<Text
										style={{ fontSize: theme.fontSize.l }}
									>
										<b>
											{t('dealerDashboard.activeTrips')}:
										</b>
									</Text>
									<Text
										style={{
											fontSize: theme.fontSize.xxxxl,
										}}
									>
										{today.activeTrips || 0}
										<SmallText>&nbsp;Nos</SmallText>
									</Text>
								</Box>
								<Note
									style={{
										padding: '0 0 0 12px',
									}}
								>
									{t('dealerDashboard.today')}
								</Note>
								<Box
									row
									padding={12}
									alignItems="baseline"
									justifyContent="space-between"
								>
									<Text
										style={{
											fontSize: theme.fontSize.l,
										}}
									>
										<b>
											{t(
												'dealerDashboard.completedTrips'
											)}
											:
										</b>
									</Text>
									<Text
										style={{
											fontSize: theme.fontSize.xxxxl,
											padding: '0 0 0 20px',
										}}
									>
										{today.completedTrips || 0}
										<SmallText>&nbsp;Nos</SmallText>
									</Text>
								</Box>
								<Note
									style={{
										padding: isMobile
											? '0 0 12px 12px'
											: '0 0 0 12px',
									}}
								>
									{t('dealerDashboard.today')}
								</Note>
							</Box>
						) : (
							<Box
								height="150px"
								center
								style={{
									margin: '0 12px 0 0',
									color: headingColor,
									fontSize: theme.fontSize.m,
									lineHeight: '1.5',
									maxWidth: '170px',
									textAlign: 'center',
									fontWeight: '500',
								}}
							>
								{t('dealerDashboard.noTrips')}
							</Box>
						)}
					</Box>
				</Card>
				<Card
					textAlign="left"
					style={{ margin: isMobile ? '12px 0 ' : '12px 12px 0' }}
				>
					<H2
						style={{
							padding: '12px 12px 0px 12px',
							color: headingColor,
						}}
					>
						{t('dealerDashboard.bestSuppliers')}
					</H2>
					<SmallText
						style={{
							padding: '0 12px',
							color: subHeadingColor,
						}}
						fontSize={theme.fontSize.s}
					>
						{t('dealerDashboard.supplierSubheading')}
					</SmallText>
					<CustomPieChart
						data={bestSuppliers}
						width={isMobile ? '150' : '170'}
						height={isMobile ? '150' : '170'}
						innerRadius="55"
						outerRadius={isMobile ? '70' : '85'}
						errorLabel={t('dealerGeneralReports.noRecords')}
					/>
				</Card>
			</Box>

			<Card style={{ margin: '12px 0 0', width: '99%' }}>
				<Box row={!isMobile} justifyContent="space-between">
					<Box padding={20}>
						<H2> {t('dealerDashboard.incomingFFBQuality')}</H2>
						<SmallText
							style={{
								padding: '4px 0 12px 20px',
								color: subHeadingColor,
							}}
							fontSize={theme.fontSize.s}
						>
							{t('dealerDashboard.ffbQualitySubheading')}
						</SmallText>
						{incomingFFBQuality.length ? (
							<Box style={{ padding: '4px 0 0 12px' }}>
								{incomingFFBQuality.map(d => {
									return (
										<Box
											row
											alignItems="baseline"
											key={d.name}
										>
											<SmallText
												style={{
													border: `3px solid ${theme.color.accent4}`,
													backgroundColor: `${theme.color.blue2}`,
													lineHeight: '16px',
													height: '14px',
													width: '14px',
													borderRadius: '50%',
													marginRight: '20px',
												}}
											/>
											<KeyValueSegment
												name={d.name}
												description={d.value}
												valueMargin="0 0 0 20px"
												percentage
											/>
										</Box>
									)
								})}
							</Box>
						) : (
							<Box
								height="150px"
								center
								style={{
									color: '#08090C',
									fontSize: theme.fontSize.s,
								}}
							>
								{t('dealerDashboard.noRecords')}
								<SmallText
									style={{
										padding: '12px',
										color: subHeadingColor,
									}}
								>
									{t('dealerDashboard.ffbErrorMsg')}
								</SmallText>
							</Box>
						)}
					</Box>
					<Box center>
						<>
							{avgTripTiming ? (
								<IconWrapper size={80}>
									<Icon
										glyph={TimerIcon}
										style={{
											color: '#13835A',
											padding: '16px',
											borderRadius: '50%',
											backgroundColor: '#DFFAF0',
										}}
									/>
								</IconWrapper>
							) : (
								<IconWrapper size={80}>
									<Icon
										glyph={TimerOffIcon}
										style={{
											color: '#F45E58',
											padding: '16px',
											borderRadius: '50%',
											backgroundColor: '#FFDEDD',
										}}
									/>
								</IconWrapper>
							)}
						</>
						{avgTripTiming ? (
							<Text
								style={{
									fontSize: theme.fontSize.xxxxl,
									margin: '12px 0',
								}}
							>
								{avgTripTiming}
								<SmallText>
									&nbsp;&nbsp;{avgTripTimeUnit}
								</SmallText>
							</Text>
						) : (
							<Box
								height="60px"
								center
								style={{
									color: '#08090C',
									fontSize: theme.fontSize.s,
								}}
							>
								{t('dealerDashboard.noRecords')}
							</Box>
						)}
						<Text
							style={{
								maxWidth: '200px',
								padding: '0 0 12px',
								color: theme.color.grey9,
							}}
						>
							{t('dealerDashboard.avgTripTime')}
						</Text>
						<SmallText
							style={{
								maxWidth: '200px',
								color: theme.color.grey10,
							}}
						>
							{t('dealerDashboard.avgTripTimeSubheading')}
						</SmallText>
					</Box>

					<Box center padding={20}>
						<Card
							width={240}
							height={220}
							style={{
								padding: '20px',
								position: 'relative',
								overflow: 'visible',
								boxShadow: ' 0px 6px 16px #00000014',
							}}
							textAlign="left"
						>
							<SmallText
								style={{
									position: 'absolute',
									fontSize: theme.fontSize.m,
									color: theme.color.accent2,
									top: '-10px',
									left: '20px',
									display: 'inline-block',
									backgroundColor: theme.color.white,
									padding: '0 8px',
								}}
							>
								{t('dealerDashboard.ffbPrice')}
							</SmallText>
							<Box
								row
								alignItems="center"
								justifyContent="space-between"
							>
								<Text
									style={{
										backgroundColor: '#EAF4FD',
										color: '#0644AD',
										padding: '8px 20px',
										borderRadius: '16px',
										fontSize: theme.fontSize.l,
										width: '110px',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap',
									}}
									title={region}
								>
									{region}
								</Text>
								<SmallText>
									{t('dealerDashboard.currency')}
								</SmallText>
							</Box>
							<Box style={{ margin: '20px 0 ' }}>
								<KeyValueSegment
									name={t('dealerDashboard.today')}
									description={todayPrice}
									limitKeyWidth
								/>
								<KeyValueSegment
									name={t('dealerDashboard.yesterday')}
									description={yesterdayPrice}
									limitKeyWidth
								/>
								<KeyValueSegment
									name={t('dealerDashboard.weeklyAvg')}
									description={weeklyAvgPrice}
									limitKeyWidth
								/>
							</Box>
							<SmallText
								link
								style={{
									color: '#0644AD',
								}}
							>
								<a
									href="https://bepi.mpob.gov.my/admin2/price_ffb_region_view3.php"
									// eslint-disable-next-line react/jsx-no-target-blank
									target="_blank"
									rel="noreferrer"
								>
									{t('dealerDashboard.learnMore')}
								</a>
							</SmallText>
						</Card>
					</Box>
				</Box>
			</Card>
		</>
	)
}

export { MainDashboard }
