export const initialState = {
	activeModule: '',
	scheduleListing: [],
	scheduleDetails: {},
	pagination: {},
	rescheduleModalStatus: false,
	newScheduleModalStatus: false,
	insights: {},
	newScheduleInitialValues: {
		startAt: '',
		type: [],
		participants: [],
	},
}

export const TABLE_ALIAS = {
	scheduledDate: 'viewSchedule.scheduledDate',
	supplierName: 'productTrace.supplierName',
	previousSchedule: 'schedulerListing.previousSchedule',
	status: 'schedulerListing.status',
	transporter: 'productTrace.transporter',
}

export const SERVICE_TYPES = {
	'palmoil-service-harvest': 'viewSchedule.harvest',
	'palmoil-service-transport': 'viewSchedule.transport',
	'palmoil-service-fertilize': 'viewSchedule.fertilizer',
	'palmoil-service-recievegoods': 'createSchedule.receiveGoods',
}

export const TIME = {
	morning: 'schedulerListing.morning',
	afternoon: 'schedulerListing.afternoon',
	evening: 'schedulerListing.evening',
}
