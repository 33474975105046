import React from 'react'
import styled from 'styled-components'
import { Box } from 'ui-lib/utils/Box'
import { Label, P } from 'ui-lib/components/Typography'
import { applyMediaQueries } from 'ui-lib/utils/helpers'

const SummaryWrapper = styled(Box)({
	'&:last-child': {
		paddingRight: 'initial',
	},
	...applyMediaQueries(['mobile'], {
		paddingTop: 4,
		paddingBottom: 4,
	}),
})

const SummaryBlock = ({ name, detail, color }) => (
	<SummaryWrapper>
		<Label color={color || '#7680A7'} small bold>
			{name}
		</Label>
		{typeof detail === 'string' ? (
			<P small bold>
				{detail}
			</P>
		) : (
			detail
		)}
	</SummaryWrapper>
)

export { SummaryBlock }
