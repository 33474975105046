import React from 'react'
import { useSelector } from 'react-redux'
import { Layer, Box as GrommBox } from 'grommet'
import { useTranslation } from 'react-i18next'
import { isEmptyObject } from 'core-app/utils/helpers'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { Box } from 'ui-lib/utils/Box'
import { H3, P } from 'ui-lib/components/Typography'
import { Select, SelectSearch } from 'ui-lib/components/Select'
import { Button } from 'ui-lib/components/Button'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { useFormik } from 'formik'
import * as yup from 'yup'
import EditIcon from 'ui-lib/icons/edit.svg'

const EditSchedule = ({ show, onClose, onConfirm }) => {
	const { t } = useTranslation()
	const partnerList = useSelector(AuthDuc.selectors.getTargetPartnersOfUser)

	const partners = partnerList.map(list => {
		return {
			id: list.id,
			label: list.name,
		}
	})

	const validationSchema = yup.object().shape({
		participantID: yup.string(),
		type: yup.string(),
	})

	const {
		handleSubmit,
		values,
		setFieldValue,
		errors,
		handleBlur,
		touched,
	} = useFormik({
		initialValues: {
			participantID: '',
			type: '',
		},
		enableReinitialize: true,
		validationSchema,
		onSubmit: _values => {
			onConfirm(_values)
		},
	})

	const disableCTA = !isEmptyObject(errors) || !values.participantID

	return (
		<>
			{show && (
				<Layer
					modal
					responsive={false}
					animation="fadeIn"
					position="center"
					onClickOutside={onClose}
					onEsc={onClose}
				>
					<GrommBox pad="32px 48px" gap="medium">
						<Box row>
							<IconWrapper size={24}>
								<Icon glyph={EditIcon} />
							</IconWrapper>
							<H3 style={{ margin: '0 8px' }}>{`${t(
								'common.edit'
							)} ${t('createSchedule.schedule')}`}</H3>
						</Box>
						<P>
							{t(
								'schedulerListing.changeAndUpdateTheSupplierAndScheduleType'
							)}
						</P>
						<form onSubmit={handleSubmit}>
							<Box width={330}>
								<SelectSearch
									disableChooseOne
									name="participantID"
									key={values.participantID}
									label={t('createSchedule.chooseSM')}
									placeholder={t(
										'schedulerListing.supplierName'
									)}
									value={values.participantID}
									options={partners}
									onChange={({ id }) => {
										setFieldValue('participantID', id)
									}}
									returnOnlyValue
									labelKey="label"
									valueKey="id"
									defaultLabel={t(
										'common.selectDefaultLabel'
									)}
									onBlur={handleBlur}
									error={touched.type && errors.type}
									style={{
										width: 330,
										border: '1px solid #0b20aa',
										borderRadius: 4,
									}}
								/>
							</Box>
							<Select
								label={t('viewSchedule.serviceType')}
								value={values.type}
								placeholder={t('viewSchedule.serviceType')}
								options={[
									{
										label: t('createSchedule.receiveGoods'),
										name: 'palmoil-service-recievegoods',
									},
									{
										label: t('createSchedule.transport'),
										name: 'palmoil-service-transport',
									},
									{
										label: t('createSchedule.harvest'),
										name: 'palmoil-service-harvest',
									},
									{
										label: t('createSchedule.fertilizer'),
										name: 'palmoil-service-fertilize',
									},
								]}
								key={values.type}
								labelKey="label"
								valueKey="name"
								onChange={({ name }) => {
									setFieldValue('type', name)
								}}
								returnOnlyValue
							/>
							<Box width={330}>
								<Button
									type="submit"
									disabled={disableCTA}
									label={t('common.submit')}
									primary
									rounded
								/>
							</Box>
						</form>
					</GrommBox>
				</Layer>
			)}
		</>
	)
}

export { EditSchedule }
