import LogHelper from 'core-app/utils/logger'
import { MainRouteDuc } from 'core-app/routes/duc'
import { Toast } from 'ui-lib/components/Toast'
import { AppDuc } from 'core-app/modules/App/duc'
import { CookieDuc } from 'core-app/modules/App/cookieDuc'
import { AUTH_COOKIE_KEYS } from 'ui-lib/utils/config'
import { getIAMEndPoint, getCoreEndPoint } from 'core-app/config'
import {
	fetchClientID,
	CallWithRefreshCheck,
	getOrgIDFromLoggedUser,
} from 'core-app/modules/Auth/AuthSaga'
import request from 'core-app/utils/request'
import { all, takeLatest, put, call, select } from 'redux-saga/effects'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { Storage } from 'ui-lib/utils/storage'
import { getIn } from 'timm'
import { OnboardDuc } from './duc'

const logger = LogHelper('client:OnboardSaga')

function* verifyOtp(action) {
	try {
		const { loginID, secret, successToast } = action
		const values = {
			loginID,
			secret,
		}
		yield put(AppDuc.creators.showGlobalLoader('verify-email'))

		const requestUrl = `${getIAMEndPoint()}clients/authenticate/otp`

		const options = {
			method: 'PUT',
			body: JSON.stringify(values),
		}
		const { data } = yield call(request, requestUrl, options)
		yield all(
			CookieDuc.options.helpers.setExpiryTokens(data).map(c => put(c))
		)

		const clientID = yield fetchClientID()
		yield put(AuthDuc.creators.fetchUserProfile(clientID))

		yield Toast({
			type: 'success',
			message: successToast,
		})

		yield put(
			MainRouteDuc.creators.redirect(MainRouteDuc.types.ONBOARD$ACTION, {
				action: 'thankyou',
			})
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(
			MainRouteDuc.creators.redirect(MainRouteDuc.types.ONBOARD$ACTION, {
				action: 'thankyou',
			})
		)
		yield put(AppDuc.creators.hideGlobalLoader('verify-email'))
	}
}

function* initiateOtp(action) {
	try {
		const { loginID, successToast } = action
		const values = {
			loginID,
		}

		yield put(AppDuc.creators.showGlobalLoader('verify-email'))

		const requestOtpUrl = `${getIAMEndPoint()}clients/authenticate/otp`
		const otpOptions = {
			method: 'POST',

			body: JSON.stringify(values),
		}
		yield call(request, requestOtpUrl, otpOptions)

		// yield put(AuthDuc.creators.fetchUserProfile(clientID))
		yield Toast({
			type: 'success',
			message: successToast,
		})
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('verify-email'))
	}
}

function* uploadCertificate(action) {
	const {
		values,
		successToast,
		helpers: { setSubmitting },
	} = action

	try {
		yield put(AppDuc.creators.showGlobalLoader('upload-certificate'))
		const files = []
		if (values.gstNumber) {
			const payload = {
				type: 'gst',
				number: values.gstNumber,
				files: values.gstDocument,
			}
			files.push(payload)
		}
		if (values.certType && values.certType !== 'UNCERTIFIED') {
			const payload = {
				type: 'certificate',
				number: values.certNumber,
				meta: { certificate: { issuingBody: values.certType } },
				files: values.certificationDocument,
			}
			files.push(payload)
		}

		const orgId = yield select(AuthDuc.selectors.getUserOrgId)

		if (values.universalMillID) {
			const updateRequestUrl = `${getIAMEndPoint()}clients/organizations/${orgId}/updateMeta`
			const uplaodPayload = {
				meta: {
					universalMillID: values.universalMillID,
				},
			}

			const updateOption = {
				method: 'PATCH',
				body: JSON.stringify(uplaodPayload),
			}

			yield call(request, updateRequestUrl, updateOption)
		}

		yield all(
			files.map(doc => {
				const requestUrl = `${getIAMEndPoint()}clients/organizations/${orgId}/documents`

				const options = {
					method: 'POST',
					body: JSON.stringify(doc),
				}

				return call(request, requestUrl, options)
			})
		)

		yield Toast({
			type: 'success',
			message: successToast,
		})

		yield put(
			MainRouteDuc.creators.redirect(MainRouteDuc.types.ONBOARD$ACTION, {
				action: 'thankyou',
			})
		)
		setSubmitting(false)
	} catch (err) {
		setSubmitting(false)

		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('upload-certificate'))
	}
}

function* register(action) {
	const {
		values: { line1, line2, city, state, country, postalCode },
		loaderProgress,
		currentFormStatus,
		helpers: { setSubmitting },
	} = action
	try {
		let progress

		if (loaderProgress === 100) {
			progress = 100
		} else if (loaderProgress >= 50 && loaderProgress < 100) {
			progress = 75
		} else if (loaderProgress < 50) {
			progress = 50
		}

		const values = {
			primaryAddress: {
				line1,
				line2,
				postalCode,
				city,
				state,
				country,
			},
			meta: {
				processValue: progress,
				formStatus: currentFormStatus,
			},
		}

		const orgId = yield select(AuthDuc.selectors.getUserOrgId)

		yield put(AppDuc.creators.showGlobalLoader('organization'))

		const requestUrl = `${getIAMEndPoint()}clients/organizations/${orgId}`

		const options = {
			method: 'PUT',
			body: JSON.stringify(values),
		}
		yield CallWithRefreshCheck(requestUrl, options)

		yield put(
			MainRouteDuc.creators.redirect(MainRouteDuc.types.ONBOARD$ACTION, {
				action: 'business',
			})
		)
		setSubmitting(false)
	} catch (err) {
		setSubmitting(false)

		const { message } = err

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('organization'))
	}
}

function* business(action) {
	const {
		values: { taxReference, attachments, other },
		loaderProgress,
		currentFormStatus,
		helpers: { setSubmitting },
	} = action
	try {
		const orgId = yield select(AuthDuc.selectors.getUserOrgId)
		const documents = []
		if (taxReference) {
			const tax = {}
			tax.type = 'tax'
			tax.number = taxReference
			if (attachments.length !== 0) {
				tax.files = attachments
			}
			documents.push(tax)
		}

		if (other.length !== 0) {
			const bill = {}
			bill.type = 'other'
			bill.files = other
			documents.push(bill)
		}

		const certificationsURL = `${getIAMEndPoint()}clients/organizations/-/documents`
		const origResponse = yield CallWithRefreshCheck(certificationsURL)
		const certificateList = getIn(origResponse, ['data', 'list']) || []

		const certificates = certificateList
			.filter(
				cert =>
					cert.type === 'tax' ||
					cert.type === 'license' ||
					cert.type === 'other'
			)
			.map(certID => certID.id)

		if (certificates.length) {
			yield all(
				certificates.map(cert => {
					const options = {
						method: 'DELETE',
					}

					const deleteCertificatesUrl = `${getIAMEndPoint()}clients/organizations/-/documents/${cert}`

					return call(request, deleteCertificatesUrl, options)
				})
			)
		}

		const requestUrl = `${getIAMEndPoint()}clients/organizations/${orgId}/documents`

		yield all(
			documents.map(doc => {
				const options = {
					method: 'POST',
					body: JSON.stringify(doc),
				}

				return call(request, requestUrl, options)
			})
		)

		let progress
		if (loaderProgress >= 75 && loaderProgress <= 100) {
			progress = 100
		} else if (loaderProgress >= 50 && loaderProgress < 75) {
			progress = 75
		} else if (loaderProgress < 50) {
			progress = 50
		}

		const payload = {
			meta: {
				processValue: progress,
				formStatus: currentFormStatus,
			},
		}

		const requestUrl2 = `${getIAMEndPoint()}clients/organizations/${orgId}`

		const options2 = {
			method: 'PUT',
			body: JSON.stringify(payload),
		}
		yield CallWithRefreshCheck(requestUrl2, options2)

		yield put(
			MainRouteDuc.creators.redirect(MainRouteDuc.types.ONBOARD$ACTION, {
				action: 'addcerts',
			})
		)
		setSubmitting(false)
	} catch (err) {
		setSubmitting(false)

		const { message } = err

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('organization'))
	}
}

function* addCertifications(action) {
	const {
		values,
		loaderProgress,
		currentFormStatus,
		helpers: { setSubmitting },
	} = action

	try {
		const orgId = yield select(AuthDuc.selectors.getUserOrgId)

		yield all(
			values.map(doc => {
				let requestUrl = `${getIAMEndPoint()}clients/organizations/${orgId}/documents`

				const value = {
					type: 'certificate',
					number: doc.number,
					meta: {
						certificate: {
							name: doc.name,
							issuingBody: doc.type,
						},
					},
					files: doc.files,
				}
				if (doc.docID) {
					requestUrl += `/${doc.docID}`
				}

				const options = {
					method: doc.docID ? 'PUT' : 'POST',
					body: JSON.stringify(value),
				}

				return call(request, requestUrl, options)
			})
		)

		let progress
		if (loaderProgress >= 75 && loaderProgress <= 100) {
			progress = 100
		} else if (loaderProgress >= 50 && loaderProgress < 75) {
			progress = 75
		} else if (loaderProgress < 50) {
			progress = 50
		}
		const payload = {
			meta: {
				processValue: progress,
				formStatus: currentFormStatus,
			},
		}

		const requestUrl2 = `${getIAMEndPoint()}clients/organizations/${orgId}`

		const options2 = {
			method: 'PUT',
			body: JSON.stringify(payload),
		}
		yield CallWithRefreshCheck(requestUrl2, options2)

		yield put(
			MainRouteDuc.creators.redirect(MainRouteDuc.types.ONBOARD$ACTION, {
				action: 'congrats',
			})
		)
		setSubmitting(false)
	} catch (err) {
		setSubmitting(false)

		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('addcerts'))
	}
}

function* initiateReferralInvite(action) {
	try {
		const { details } = action
		const { partnerRefers } = details
		const requestUrl = `${getIAMEndPoint()}/clients/organizations/:orgID/referrals`
		const referrals = partnerRefers.map(referral => {
			return {
				receiver: {
					firstName: referral.orgName,
					contact: {
						email: referral.email,
						mobileNumber: referral.mobileNumber,
					},
					address: {
						city: referral.city,
						country: referral.country,
					},
				},
			}
		})

		const options = {
			method: 'POST',
			body: JSON.stringify({ referrals }),
		}
		yield put(AppDuc.creators.showGlobalLoader('referral invite'))
		yield call(request, requestUrl, options)
		yield put(OnboardDuc.creators.inviteReferralReqSuccess(true))
		yield put(AppDuc.creators.hideGlobalLoader('referral invite'))
		yield put(
			MainRouteDuc.creators.redirect(MainRouteDuc.types.ONBOARD$ACTION, {
				action: 'successinvite',
			})
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}
function* setInitialValues() {
	try {
		yield put(OnboardDuc.creators.documentLoading(true))

		const [, orgID] = Storage.get({
			name: AUTH_COOKIE_KEYS.CLIENT_ID,
		}).split('@')
		const orgDetailsUrl = `${getIAMEndPoint()}clients/organizations/${orgID}`
		const { data: orgDetails } = yield CallWithRefreshCheck(orgDetailsUrl)
		const { name = '', meta: orgMeta = {}, categories = [] } =
			orgDetails || {}
		const { universalMillID = '' } = orgMeta
		const category = getIn(categories, [0, 'id']) || ''
		const isMill = category === 'palmoil-mill' // no need to add rice mill here, as rice has no rspo certificate
		const query =
			universalMillID !== ''
				? `uimID=${universalMillID}`
				: `name=${name.toUpperCase()}`

		const certificationsURL = `${getIAMEndPoint()}clients/organizations/-/documents`
		const origResponse = yield CallWithRefreshCheck(certificationsURL)
		const certificateList = getIn(origResponse, ['data', 'list']) || []

		if (isMill) {
			const rspoCertificationsURL = `${getIAMEndPoint()}clients/rspo-details?${query}`
			const rspoResponse = yield CallWithRefreshCheck(
				rspoCertificationsURL
			)
			const certificates = certificateList.filter(
				doc => doc.type === 'certificate'
			)
			const rspoCertificateList = getIn(rspoResponse, ['data']) || {}
			const {
				meta: {
					certificate: {
						name: rspoName = '',
						type = '',
						number,
						issuingBody = '',
						supplychainModel = [],
					} = {},
					license: { startDate = '', endDate = '' } = {},
				} = {},
				members = [],
			} = rspoCertificateList || {}
			const _docID = getIn(certificates, ['0', 'id']) || ''
			const _issuingBody =
				getIn(certificates, [
					'0',
					'meta',
					'certificate',
					'issuingBody',
				]) || ''

			const _name =
				getIn(certificates, ['0', 'meta', 'certificate', 'name']) || ''
			const _number = getIn(certificates, ['0', 'number']) || ''
			const _files = getIn(certificates, ['0', 'files']) || []
			let val
			if (certificates.length > 0 && _issuingBody === type) {
				val = {
					activeIndex: 0,
					id: _docID,
					name: _name,
					number: _number,
					files: _files,
					expiryEndDate: new Date(endDate),
					expiryStartDate: new Date(startDate),
					supplybaseMembers: {
						...rspoCertificateList,
						members,
					},
					meta: {
						certificate: {
							issuingBody: type,
							certificationBody: issuingBody,
							supplychainModel,
						},
					},
				}
			} else if (certificates.length > 0 && _issuingBody !== type) {
				val = {
					activeIndex: 0,
					id: _docID,
					number: _number,
					files: _files,
					meta: {
						certificate: {
							issuingBody: _issuingBody,
						},
					},
					supplybaseMembers: { ...rspoCertificateList, members },
				}
			} else if (certificates.length === 0 && members.length > 0) {
				val = {
					activeIndex: 0,
					number,
					name: rspoName,
					expiryEndDate: new Date(endDate),
					expiryStartDate: new Date(startDate),
					meta: {
						certificate: {
							issuingBody: type,
							certificationBody: issuingBody,
							supplychainModel,
						},
					},
					supplybaseMembers: { ...rspoCertificateList, members },
				}
			} else {
				val = {}
			}
			yield put(OnboardDuc.creators.setSupplyBaseValues(val))
		}

		const taxType = certificateList.filter(
			cert => getIn(cert, ['type']) === 'tax'
		)

		const utilityBill = certificateList.filter(
			cert => getIn(cert, ['type']) === 'other'
		)

		const tax = getIn(taxType, [0])
		const bill = getIn(utilityBill, [0])
		const certificate = certificateList.filter(
			cert => getIn(cert, ['type']) === 'certificate'
		)
		const _certificate = (certificate || []).map((__certificate, index) => {
			return {
				activeIndex: index,
				docID: getIn(__certificate, ['id']) || '',
				type:
					getIn(__certificate, [
						'meta',
						'certificate',
						'issuingBody',
					]) || '',
				name:
					getIn(__certificate, ['meta', 'certificate', 'name']) || '',
				number: getIn(__certificate, ['number']) || '',
				files: getIn(__certificate, ['files']) || [],
			}
		})

		const { primaryAddress = {}, meta = {} } = orgDetails
		const {
			state = '',
			country = '',
			line1 = '',
			line2 = '',
			city = '',
			postalCode = '',
		} = primaryAddress
		const processValue = getIn(meta, ['processValue']) || 100
		const formStatus = getIn(meta, ['formStatus']) || 100

		const initialValues = {
			other: getIn(meta, ['other']) || {},
			addressDetails: {
				line1,
				line2,
				city,
				state,
				country,
				postalCode,
				processValue,
			},
			businessDetails: {
				taxReference: getIn(tax, ['number']) || '',
				attachments: getIn(tax, ['files']) || [],
				others: getIn(bill, ['files']) || [],
				processValue,
				other: [],
			},
			certificateDetails: _certificate.length
				? _certificate
				: [
						{
							activeIndex: 0,
							type: '',
							number: '',
							files: [],
						},
				  ],
			processValue,
			formStatus,
		}
		yield put(OnboardDuc.creators.setInitialValuesSuccess(initialValues))
		yield put(OnboardDuc.creators.documentLoading(false))
	} catch (err) {
		logger.log(err)
	}
}

function* setLanguage(action) {
	try {
		const { language } = action
		yield put(
			CookieDuc.creators.setCookie({
				cookieName: 'I18N_LANGUAGE',
				cookieValue: language,
				storage: 'C',
			})
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* updateAllProductProfile(action) {
	try {
		const { profiles } = action
		const requestUrl = `${getCoreEndPoint()}clients/organizations/products?limit=100`

		const payload = profiles.map(({ id, tradeAccess }) => {
			return {
				product: {
					id,
				},
				meta: {
					tradeAccess,
				},
			}
		})

		const options = {
			method: 'PUT',
			body: JSON.stringify(payload),
		}
		yield call(request, requestUrl, options)

		yield Toast({
			type: 'success',
		})

		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.ONBOARD$ACTION,
				{
					action: 'all-set',
				}
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* updateAddress(action) {
	const {
		details,
		helpers: { setSubmitting },
	} = action

	try {
		const orgID = yield getOrgIDFromLoggedUser()

		const requestUrl = `${getIAMEndPoint()}clients/organizations/${orgID}`
		const payload = {
			primaryAddress: { ...details },
		}
		const options = {
			method: 'PUT',
			body: JSON.stringify(payload),
		}
		yield call(request, requestUrl, options)

		yield Toast({
			type: 'success',
		})

		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.ONBOARD$ACTION,
				{
					action: 'update-certDetails',
				}
			)
		)
	} catch (err) {
		setSubmitting(false)
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* updateCertificationMeta(action) {
	const { unCertified } = action
	try {
		const orgId = yield select(AuthDuc.selectors.getUserOrgId)
		const orgdetails = yield select(AuthDuc.selectors.getCurrentOrgDetails)

		const currentMeta = orgdetails.meta ? { ...orgdetails.meta } : {}

		currentMeta.unCertified = unCertified

		const data = {
			meta: currentMeta,
		}
		const requestUrl = `${getIAMEndPoint()}clients/organizations/${orgId}`

		const options = {
			method: 'PUT',
			body: JSON.stringify(data),
		}

		yield call(request, requestUrl, options)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* updateCertificate(action) {
	const {
		details,
		id,
		helpers: { setSubmitting },
		// actor,
		unCertified,
		carbonNumberID,
	} = action
	try {
		const orgId = yield select(AuthDuc.selectors.getUserOrgId)
		const orgdetails = yield select(AuthDuc.selectors.getCurrentOrgDetails)

		const currentMeta = orgdetails.meta ? { ...orgdetails.meta } : {}

		currentMeta.unCertified = unCertified

		const data = {
			meta: currentMeta,
		}
		const metaRequestUrl = `${getIAMEndPoint()}clients/organizations/${orgId}`

		const metaOptions = {
			method: 'PUT',
			body: JSON.stringify(data),
		}
		yield call(request, metaRequestUrl, metaOptions)

		if (!unCertified) {
			const requestUrl = `${getIAMEndPoint()}clients/organizations/-/documents/${id}`
			const options = {
				method: 'PUT',
				body: JSON.stringify(details),
			}
			yield call(request, requestUrl, options)
		}

		const { supplybaseMembers = [] } = details
		const { members = [] } = supplybaseMembers
		if (members.length > 0) {
			const supplyPayload = {
				...supplybaseMembers,
				organizationID: orgId,
				documentID: id,
				members: [],
			}
			members.forEach(d => {
				const name = getIn(d, ['name']) || ''
				const primaryContact =
					getIn(d, ['meta', 'primaryContact']) || {}
				const primaryAddress =
					getIn(d, ['meta', 'certificate', 'address']) || {}
				supplyPayload.members.push({
					name,
					meta: {
						organizationID: orgId,
						primaryContact,
						primaryAddress,
					},
				})
			})

			const supplyContactUrl = `${getIAMEndPoint()}clients/supplybase`
			const options = {
				method: 'POST',
				body: JSON.stringify(supplyPayload),
			}
			yield call(request, supplyContactUrl, options)
		}

		yield Toast({
			type: 'success',
		})

		if (!carbonNumberID) {
			yield put(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.ONBOARD$ACTION,
					{
						action: 'carbon-number',
					}
				)
			)
		} else {
			yield put(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.ONBOARD$ACTION,
					{
						action: 'all-set',
					}
				)
			)
		}
	} catch (err) {
		setSubmitting(false)
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* addCertificate(action) {
	const {
		details,
		helpers: { setSubmitting },
		unCertified,
		carbonNumberID,
	} = action
	try {
		const orgId = yield select(AuthDuc.selectors.getUserOrgId)
		const orgdetails = yield select(AuthDuc.selectors.getCurrentOrgDetails)

		const currentMeta = orgdetails.meta ? { ...orgdetails.meta } : {}

		currentMeta.unCertified = unCertified

		const data = {
			meta: currentMeta,
		}
		const metaRequestUrl = `${getIAMEndPoint()}clients/organizations/${orgId}`

		const metaOptions = {
			method: 'PUT',
			body: JSON.stringify(data),
		}
		yield call(request, metaRequestUrl, metaOptions)
		let documentID = ''
		if (!unCertified) {
			const requestUrl = `${getIAMEndPoint()}clients/organizations/-/documents`
			const options = {
				method: 'POST',
				body: JSON.stringify(details),
			}
			const docResponse = yield call(request, requestUrl, options)
			documentID = getIn(docResponse, ['data', 'id']) || ''
		}

		const { supplybaseMembers = [] } = details
		const { members = [] } = supplybaseMembers
		if (members.length > 0) {
			const supplyPayload = {
				...supplybaseMembers,
				organizationID: orgId,
				documentID,
				members: [],
			}
			members.forEach(d => {
				const name = getIn(d, ['name']) || ''
				const primaryContact =
					getIn(d, ['meta', 'primaryContact']) || {}
				const primaryAddress =
					getIn(d, ['meta', 'certificate', 'address']) || {}
				supplyPayload.members.push({
					name,
					meta: {
						organizationID: orgId,
						primaryContact,
						primaryAddress,
					},
				})
			})
			const supplyContactUrl = `${getIAMEndPoint()}clients/supplybase`
			const options = {
				method: 'POST',
				body: JSON.stringify(supplyPayload),
			}
			yield call(request, supplyContactUrl, options)
		}
		yield Toast({
			type: 'success',
		})

		if (!carbonNumberID) {
			yield put(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.ONBOARD$ACTION,
					{
						action: 'carbon-number',
					}
				)
			)
		} else {
			yield put(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.ONBOARD$ACTION,
					{
						action: 'all-set',
					}
				)
			)
		}
	} catch (err) {
		setSubmitting(false)
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* addCollectionPoint(action) {
	const {
		details,
		organizationDetail,
		helpers: { setSubmitting },
	} = action
	try {
		let tempchild = []
		const orgID = getIn(organizationDetail, ['id']) || ''
		const childOrganization =
			getIn(organizationDetail, ['meta', 'childOrganization']) || []
		tempchild = [...tempchild, ...childOrganization]

		details.collectionPoints.map(async org => {
			const {
				email,
				orgName,
				organisationType,
				addressLine1,
				addressLine2,
				state,
				country,
				postalCode,
				certType,
				certNumber,
				certBody,
				issueDate,
				expiryDate,
				certStartDate,
				city,
				supplychainMode,
			} = org

			const values = {
				name: orgName,
				primaryContact: { email },
				categories: [
					{
						name: organisationType.name,
						id: organisationType.id,
					},
				],
				primaryAddress: {
					line1: addressLine1,
					line2: addressLine2,
					postalCode,
					city,
					state,
					country,
				},
				meta: {
					parentOrganization: [organizationDetail],
					platform: 'app',
					processValue: 25,
					formStatus: {
						organization: false,
						business: false,
						certifications: false,
					},
				},
			}

			const docPayload = {
				number: certNumber,
				meta: {
					certificate: {
						issuingBody: certType,
						certificationBody: certBody,
						supplychainModel: supplychainMode,
					},
				},
			}

			if (certStartDate) {
				docPayload.expiryStartDate = new Date(certStartDate)
			}
			if (expiryDate) {
				docPayload.expiryEndDate = new Date(expiryDate)
			}
			if (issueDate) {
				docPayload.issueDate = new Date(issueDate)
			}

			const requestUrl = `${getIAMEndPoint()}clients/organizations`

			const options = {
				method: 'POST',
				body: JSON.stringify(values),
			}
			const { data = {} } = await request(requestUrl, options)
			if (Object.keys(data).length > 0) {
				tempchild = [...tempchild, data]

				const updateRequestUrl = `${getIAMEndPoint()}clients/organizations/${orgID}/updateMeta`
				const uplaodPayload = {
					meta: {
						childOrganization: tempchild,
					},
				}

				const updateOption = {
					method: 'PATCH',
					body: JSON.stringify(uplaodPayload),
				}
				await request(updateRequestUrl, updateOption)

				const childOrrgID = getIn(data, ['id']) || ''
				const docRequestUrl = `${getIAMEndPoint()}clients/organizations/${childOrrgID}/documents`
				const docOption = {
					method: 'POST',
					body: JSON.stringify(docPayload),
				}
				await request(docRequestUrl, docOption)
			}
		})

		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.ONBOARD$ACTION,
				{
					action: 'success-with-cp',
				}
			)
		)
	} catch (err) {
		setSubmitting(false)
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

export default function* OnboardSaga() {
	try {
		yield all([
			takeLatest(OnboardDuc.creators.setLanguage().type, setLanguage),
			takeLatest(OnboardDuc.creators.verifyOtp().type, verifyOtp),
			takeLatest(OnboardDuc.creators.initiateOtp().type, initiateOtp),
			takeLatest(
				OnboardDuc.creators.uploadCertificate().type,
				uploadCertificate
			),
			takeLatest(OnboardDuc.creators.register().type, register),
			takeLatest(OnboardDuc.creators.business().type, business),
			takeLatest(
				OnboardDuc.creators.addCertifications().type,
				addCertifications
			),
			takeLatest(
				OnboardDuc.creators.setInitialValues().type,
				setInitialValues
			),
			takeLatest(
				OnboardDuc.creators.initiateReferralInvite().type,
				initiateReferralInvite
			),
			takeLatest(
				OnboardDuc.creators.updateAllProductProfile().type,
				updateAllProductProfile
			),
			takeLatest(OnboardDuc.creators.updateAddress().type, updateAddress),
			takeLatest(
				OnboardDuc.creators.updateCertificate().type,
				updateCertificate
			),
			takeLatest(
				OnboardDuc.creators.updateCertificationMeta().type,
				updateCertificationMeta
			),
			takeLatest(
				OnboardDuc.creators.addCertificate().type,
				addCertificate
			),
			takeLatest(
				OnboardDuc.creators.addCollectionPoint().type,
				addCollectionPoint
			),
		])
	} catch (err) {
		logger.log(err)
	}
}
