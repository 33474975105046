import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { AppDuc } from 'core-app/modules/App/duc'
import { MainRouteDuc } from 'core-app/routes/duc'
import { PartnerDuc } from 'core-app/modules/Partner/duc'
import { WebTourDuc } from 'core-app/modules/WebTour/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { Title } from 'core-app/shared/components/Title'
import {
	isEmptyObject,
	getOrganizationTypes,
	getOrganizationTypesForRice,
} from 'core-app/utils/helpers'
import { currentPlatform } from 'ui-lib/utils/config'
import { Modal } from 'ui-lib/components/Modal'
import { Card } from 'ui-lib/components/Card'
import { Input } from 'ui-lib/components/Input'
import { Label } from 'ui-lib/components/Label'
import PhoneInput from 'react-phone-input-2'
import { ButtonIconWrapper, Button } from 'ui-lib/components/Button'
import { SelectSearch, CreatableSelect } from 'ui-lib/components/Select'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { SmallText, H3, P } from 'ui-lib/components/Typography'
import theme from 'ui-lib/utils/base-theme'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Icon } from 'ui-lib/icons/components/Icon'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import PartnerIcon from 'ui-lib/icons/partner.svg'
import { useFormik } from 'formik'
import * as yup from 'yup'
import {
	ALPHANUMERICWITHLIMITEDSPECIALREGEX,
	SCRIPTREPLACEREGEX,
} from 'core-app/utils/constants'

const InvitePartner = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { isMobile } = useSelector(AppDuc.selectors.detection)

	const globalOrgs = useSelector(AuthDuc.selectors.getGlobalOrgs)
	const showInviteSuccessModal = useSelector(
		PartnerDuc.selectors.getInvitePartnerSuccessModalStatus
	)
	const actor = useSelector(AuthDuc.selectors.getActor)

	const [showSmallholderConfModal, setSHConfModalStatus] = useState(false)
	const [showPartnerInviteConfModal, setPartnerConfModalStatus] = useState(
		false
	)
	const [phoneCheck, setPhoneCheck] = useState(false)
	const [phoneError, setPhoneError] = useState(false)
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)
	if (tourModule !== '') {
		dispatch(WebTourDuc.creators.setActiveTourModule(''))
	}
	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.partner'),
			name: 'partner',
			isActive: true,
		},
		{
			label: t('breadcrumb.addPartner'),
			name: 'addPartner',
			isActive: true,
		},
		{
			label: t('breadcrumb.addNewPartner'),
			name: 'addNewPartner',
			isActive: false,
		},
	]

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'partner') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER)
			)
		}
		if (target === 'addPartner') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.PARTNER$ACTION,
					{
						action: 'add-partner',
					}
				)
			)
		}
	}

	const validationSchema = yup.object().shape({
		name: yup.string().required(t('validation.orgNameRequired')),
		orgType: yup.string().required(t('validation.orgTypeRequired')),
		email: yup
			.string()
			.required(t('validation.emailRequired'))
			.email(t('validation.emailFormat')),
		mobile: yup
			.number()
			.positive()
			.required(t('validation.phoneNumRequired')),
	})

	const {
		handleSubmit,
		submitForm,
		values,
		handleChange,
		handleBlur,
		setFieldValue,
		touched,
		errors,
	} = useFormik({
		initialValues: {
			name: '',
			orgType: '',
			location: '',
			mobile: '',
			email: '',
		},
		validationSchema,
		onSubmit: () => {
			if (actor[0] === 'palmoil_smallholder') {
				setSHConfModalStatus(true)
			} else {
				setPartnerConfModalStatus(true)
			}
		},
	})

	const disableCTA = !isEmptyObject(errors) || !values.email || !phoneCheck

	return (
		<>
			<Box padding={8} width="100%" height="100%">
				{/* Confirmation popup displayed when orgType === 'Smallholder' */}

				<Modal
					forceCloseviaButton
					show={showPartnerInviteConfModal}
					heading={t('invitePartner.partnerReq')}
					size="large"
					body={
						<>
							<P large>
								{t('invitePartner.partnerReqMsgLine1')}
								&nbsp;{values.name}&nbsp;
								{t('invitePartner.partnerReqMsgLine2')}
							</P>

							<Spacer size={8} />

							<P large>
								{t('invitePartner.note')}:&nbsp;
								{t('invitePartner.partnerNote')}
							</P>
						</>
					}
					closelabel={t('common.back')}
					confirmlabel={t('common.proceed')}
					onClose={() => {
						setPartnerConfModalStatus(false)
					}}
					onConfirm={() => {
						// replace all script tags
						values.name = values.name.replace(
							SCRIPTREPLACEREGEX,
							''
						)
						values.location = values.location.replace(
							SCRIPTREPLACEREGEX,
							''
						)
						// replace all script tags ends

						dispatch(
							PartnerDuc.creators.initiatePartnerInvite(values)
						)

						setPartnerConfModalStatus(false)
					}}
				/>

				{/* Confirmation popup displayed when orgType !== 'Smallholder' */}

				<Modal
					forceCloseviaButton
					show={showSmallholderConfModal}
					heading={t('invitePartner.partnerReq')}
					size="large"
					body={
						<>
							<P large>
								{t('invitePartner.partnerReqMsgLine1')}
								&nbsp;{values.name}&nbsp;
								{t('invitePartner.partnerReqMsgLine2')}
							</P>
							<P large>
								{t('invitePartner.note')}:&nbsp;
								{t('invitePartner.smallholderNote')}
							</P>
						</>
					}
					closelabel={t('common.back')}
					confirmlabel={t('common.proceed')}
					onClose={() => {
						setSHConfModalStatus(false)
					}}
					onConfirm={() => {
						dispatch(
							PartnerDuc.creators.initiateSmallholderInvite(
								values
							)
						)

						setSHConfModalStatus(false)
					}}
				/>

				{/* Success popup after partner details submission */}

				<Modal
					show={showInviteSuccessModal}
					size="large"
					body={
						<>
							<P large bold>
								{t('invitePartner.partnerInviteSuccessMsg')}
							</P>
						</>
					}
					confirmlabel={t('common.ok')}
					onConfirm={() => {
						dispatch(
							PartnerDuc.creators.invitePartnerReqSuccessToggle(
								false
							)
						)
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.PARTNER
							)
						)
					}}
				/>

				<Box style={{ padding: '0 5' }}>
					<Breadcrumb
						links={breadCrumbs}
						onClick={target => handleBreadCrumbClick(target)}
					/>
				</Box>
				<Box>
					<Title
						title={t('invitePartner.title')}
						icon={PartnerIcon}
					/>
				</Box>
				<Box>
					<SmallText
						fontSize={
							isMobile ? theme.fontSize.s : theme.fontSize.m
						}
					>
						{t('invitePartner.pageInfo')}
					</SmallText>
				</Box>
				<Spacer size={10} />
				<Card
					width={isMobile ? '320px' : '100%'}
					height={isMobile ? '350px' : 'auto'}
					isMobile={isMobile}
					textAlign="left"
				>
					<Box
						padding={8}
						style={{
							backgroundColor: '#D8DDEF',
						}}
					>
						<H3
							bold
							color="#263b97"
							style={{
								position: 'relative',
								right: '-25px',
							}}
						>
							{t('invitePartner.cardHeading')}
						</H3>
					</Box>
					<Box padding={16}>
						<form onSubmit={handleSubmit}>
							<Box
								overflow
								width={500}
								style={{ marginBottom: 25 }}
							>
								<Label
									style={{
										width: '100%',
										fontSize: '16px',
										fontWeight: '300px',
										color: theme.color.accent2,
									}}
								>
									{t('invitePartner.orgName')}
								</Label>
								<Spacer size={4} />
								<CreatableSelect
									name="name"
									options={globalOrgs}
									value={values.name}
									onChange={keyValue => {
										setFieldValue(
											'name',
											keyValue.replace(
												ALPHANUMERICWITHLIMITEDSPECIALREGEX,
												''
											)
										)
									}}
									disableBoxShadow
									onBlur={handleBlur}
									error={touched.name && errors.name}
									minLenth={3}
									returnOnlyValue
								/>
							</Box>
							<Box>
								<SelectSearch
									label={t('invitePartner.orgType')}
									value={values.orgType}
									name="orgType"
									options={
										currentPlatform() === 'rice'
											? getOrganizationTypesForRice(t)
											: getOrganizationTypes(t)
									}
									onChange={value => {
										setFieldValue('orgType', value.name)
									}}
									returnOnlyValue
									labelKey="label"
									valueKey="name"
									defaultLabel={t(
										'common.selectDefaultLabel'
									)}
									onBlur={handleBlur}
									error={touched.orgType && errors.orgType}
									required
								/>
							</Box>
							<Box width={500}>
								<Input
									regex={ALPHANUMERICWITHLIMITEDSPECIALREGEX}
									label={t('invitePartner.location')}
									value={values.location}
									name="location"
									type="text"
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched.location && errors.location}
								/>
							</Box>
							<Label
								color={theme.color.grey8}
								style={{
									fontSize: '16px',
									margin: '8px 0',
								}}
								required
							>
								{t('empMgmtHome.phoneNo')}
							</Label>
							<div
								style={{
									marginTop: '10px',
									marginBottom: '30px',
								}}
								onBlur={() => setPhoneError(true)}
							>
								<PhoneInput
									country="my"
									value={values.mobile}
									onChange={phone => {
										setFieldValue('mobile', `+${phone}`)
									}}
									enableSearch
									disableSearchIcon
									isValid={(value, country) => {
										if (country && country.format) {
											const number = country.format.match(
												/\./g
											).length
											setPhoneCheck(
												!!(
													value &&
													value.length === number
												)
											)
										}
									}}
								/>
								{!phoneCheck && phoneError ? (
									<div
										style={{
											fontSize: '11px',
											marginTop: '9px',
											marginLeft: '12px',
											color: theme.color.error,
										}}
									>
										{t('validation.phoneNumRequired')}
									</div>
								) : (
									''
								)}
							</div>

							<Box width={500} style={{ marginBottom: '30px' }}>
								<Input
									label={t('invitePartner.email')}
									value={values.email}
									name="email"
									type="text"
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched.email && errors.email}
									required
								/>
							</Box>
							<Box row justifyContent="flex-end">
								<Box padding={2} style={{ maxWidth: 150 }}>
									<Button
										disabled={disableCTA}
										onClick={() => {
											submitForm()
											dispatch(
												WebTourDuc.creators.setActiveTourModule(
													''
												)
											)
										}}
										type="submit"
										primary
										label={t('common.submit')}
									/>
								</Box>
							</Box>
						</form>
					</Box>
				</Card>
				<Box padding="25px 2px" style={{ maxWidth: 150 }}>
					<Button
						label={t('common.back')}
						rounded
						customIcon={
							<ButtonIconWrapper lightBG>
								<Icon glyph={LeftArrowIcon} />
							</ButtonIconWrapper>
						}
						onClick={() =>
							dispatch(
								MainRouteDuc.creators.switchPage(
									MainRouteDuc.types.PARTNER$ACTION,
									{
										action: 'add-partner',
									}
								)
							)
						}
					/>
				</Box>
			</Box>
		</>
	)
}

export { InvitePartner }
