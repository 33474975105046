import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NotFoundBlock } from 'core-app/modules/App/components/Error'
import { WbTicketDuc } from './duc'
import { MainDashboard } from './containers/MainDashboard'
import { DocumentView } from './containers/DocumentView'
import { DocumentCreation } from './containers/DocumentCreation'
import { WebTour } from '../WebTour'
import { WebTourDuc } from '../WebTour/duc'

const modulesMap = {
	dashboard: MainDashboard,
	document: DocumentView,
	upload: DocumentCreation,
}

const WbTicket = () => {
	const dispatch = useDispatch()
	const activeModule = useSelector(WbTicketDuc.selectors.activeModule)
	const Component = modulesMap[activeModule] || NotFoundBlock
	// if we are here, we have a submodule, route it to the module
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)

	useEffect(() => {
		if (tourModule === 'wbTickets') {
			dispatch(WebTourDuc.creators.setWBTktVisit(true))
		}
		if (tourModule === 'uploadWBTkt') {
			dispatch(WebTourDuc.creators.setUploadWBTkt(true))
		}
	}, [tourModule, dispatch])

	return (
		<>
			<Component />
			{(tourModule === 'wbTickets' || tourModule === 'uploadWBTkt') && (
				<WebTour />
			)}
		</>
	)
}

export default WbTicket
