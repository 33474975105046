import React from 'react'
import * as d3 from 'd3'
// import theme from '../../utils/base-theme'

const Arc = ({ data, index, createArc, colors }) => (
	<g key={index} className="arc">
		<path className="arc" d={createArc(data)} fill={colors(index)} />
	</g>
)

const PieChart = ({
	innerRadius = 0,
	outerRadius = 100,
	values,
	width = 300,
	height = 300,
	colors = i => (i % 2 === 0 ? '#CECECE' : '#ebebeb'),
}) => {
	const createPie = d3
		.pie()
		.value(d => d.value)
		.sort(null)
	const createArc = d3
		.arc()
		.innerRadius(innerRadius)
		.outerRadius(outerRadius)
	const format = d3.format('.1f')
	const data = createPie(values)

	return (
		<svg width={width} height={height}>
			<g transform={`translate(${outerRadius} ${outerRadius})`}>
				{data.map((d, i) => (
					<Arc
						// eslint-disable-next-line react/no-array-index-key
						key={`arc - ${i}`}
						data={d}
						index={i}
						createArc={createArc}
						colors={colors}
						format={format}
					/>
				))}
			</g>
		</svg>
	)
}

export { PieChart }
