import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NotFoundBlock } from 'core-app/modules/App/components/Error'
import { SettingsDuc } from './duc'
import { MainDashboard } from './containers/MainDashboard'
import { ChangeLanguage } from './containers/ChangeLanguage'
import { ChangePassword } from './containers/ChangePassword'
import { Notifications } from './containers/Notifications'
import { WebTour } from '../WebTour'
import { WebTourDuc } from '../WebTour/duc'

const modulesMap = {
	dashboard: MainDashboard,
	'change-language': ChangeLanguage,
	'change-password': ChangePassword,
	notifications: Notifications,
}

const Admin = () => {
	const dispatch = useDispatch()
	const activeModule = useSelector(SettingsDuc.selectors.activeModule)
	const Component = modulesMap[activeModule] || NotFoundBlock
	// if we are here, we have a submodule, route it to the module
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)
	useEffect(() => {
		if (tourModule === 'settings') {
			dispatch(WebTourDuc.creators.setSettingsVisit(true))
		}
		if (tourModule === 'passwordUpdate') {
			dispatch(WebTourDuc.creators.setPasswordUpdate(true))
		}
		if (tourModule === 'languageUpdate') {
			dispatch(WebTourDuc.creators.setLangUpdate(true))
		}
	}, [tourModule, dispatch])

	return (
		<>
			<Component />
			{(tourModule === 'settings' ||
				tourModule === 'languageUpdate' ||
				tourModule === 'passwordUpdate') && <WebTour />}
		</>
	)
}

export default Admin
