import React, { useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MainRouteDuc } from 'core-app/routes/duc'
import { UploadContentDuc } from 'core-app/modules/UploadContent/duc'
import { AppDuc } from 'core-app/modules/App/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { getRandomNumber } from 'core-app/utils/helpers'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Button } from 'ui-lib/components/Button'
import { Label } from 'ui-lib/components/Label'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { FileUpload } from 'ui-lib/components/FileUpload'
import moment from 'moment'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import theme from 'ui-lib/utils/base-theme'
import { NAME_ALIASES, SAMPLE_FILES } from '../config'

const HeaderWrapper = styled.div`
	display: flex;
	margin-top: 24px;
	margin-bottom: 24px;
`
const Header = styled.div`
	font-size: ${props => (props.isMobile ? '25px' : '30px')};
	font-weight: 500;
	margin-top: -6px;
	padding-left: 28px;
	color: ${theme.color.accent2};
`

const SubWrapper = styled.div`
	display: flex;
	cursor: pointer;
	color: ${theme.color.blue8};
`

const UploadExcel = ({
	breadCrumbs,
	titleicon,
	title,
	type,
	subModule = '',
}) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const activeLocale = useSelector(AppDuc.selectors.activeLocale)
	const orgdetails = useSelector(AuthDuc.selectors.getCurrentOrgDetails)

	const [disabled, setDisabled] = useState(false)
	const [currentDoc, setCurrentDoc] = useState({})

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		} else if (target === 'trade-doc-dashboard') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
					{
						rootModule:
							subModule === 'incoming' ||
							subModule === 'outgoing' ||
							subModule === 'all'
								? subModule
								: 'incoming',
					},
					{},
					{},
					true
				)
			)
		} else if (target === 'admin') {
			dispatch(MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN))
		} else if (target === 'assets') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.ADMIN$SUBROOT,
					{
						rootModule: 'assets',
					}
				)
			)
		} else if (target === 'partner') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER)
			)
		}
	}

	const getNextRoute = () => {
		switch (type) {
			case 'assets':
				setTimeout(() => {
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.ADMIN$SUBROOT,
							{
								rootModule: 'assets',
							}
						)
					)
				}, 300)

				break

			case 'delivery-order':
			case 'weighbridge':
				dispatch(
					MainRouteDuc.creators.switchPage(
						MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
						{
							rootModule:
								subModule === 'incoming' ||
								subModule === 'outgoing' ||
								subModule === 'all'
									? subModule
									: 'incoming',
						},
						{},
						{},
						true
					)
				)
				break
			case 'partners':
				dispatch(
					MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER)
				)
				break
			default:
				break
		}
	}

	return (
		<>
			<Box padding={8} width="100%" height="100%">
				<Box style={{ padding: '0 5' }}>
					<Breadcrumb
						links={breadCrumbs}
						onClick={target => handleBreadCrumbClick(target)}
					/>
				</Box>

				<Box
					row
					justifyContent="space-between"
					alignItems="baseline"
					style={{
						padding: '10px 0 15px',
					}}
				>
					<HeaderWrapper>
						<SubWrapper>
							<IconWrapper
								size={24}
								style={{
									color: theme.color.blue8,
									cursor: 'pointer',
								}}
							>
								<Icon glyph={titleicon} />
							</IconWrapper>
						</SubWrapper>
						<Header isMobile={isMobile}>{title}</Header>
					</HeaderWrapper>
				</Box>
				<Spacer size={8} />
				<Box
					style={{
						backgroundColor: theme.color.white,
						padding: '35px',
						border: `1px solid ${theme.color.grey4}`,
						borderRadius: '5px',
					}}
				>
					<Box
						style={{
							color: theme.color.accent2,
							fontSize: '18px',
							fontWeight: 500,
						}}
					>
						<Box
							width={400}
							margin="8px 0"
							style={{
								paddingTop: 20,
								display: 'flex',
								borderRadius: 6,
								paddingLeft: 30,
								paddingBottom: 5,
								border: `1px solid ${theme.color.grey4}`,
							}}
						>
							<Label
								color={theme.color.grey8}
								style={{
									width: '335px',
									margin: '8px 0',
									fontSize: '16px',
								}}
								required
							>
								{`${t('breadcrumb.attach')} ${t(
									NAME_ALIASES[type]
								)}`}
							</Label>
							<div
								style={{
									display: 'flex',
								}}
							>
								<Box
									style={{
										padding: '1px',
										color: theme.color.blue8,
										backgroundColor: theme.color.white,
									}}
								>
									<FileUpload
										modalTriggerBtnLabel={t(
											'common.upload'
										)}
										type="private"
										name="attachments"
										size="2097152"
										supportedFileType=".xlsx,.csv"
										hideButtonOneUpload
										onDocumentChange={async documents => {
											if (
												documents &&
												documents.length > 0
											) {
												setCurrentDoc(documents[0])
											} else {
												setCurrentDoc({})
											}
										}}
										onUploadFailure={({ message }) => {
											dispatch(
												AppDuc.creators.showToast({
													messageType: 'error',
													message,
												})
											)
										}}
										width="auto"
										newAlignment={activeLocale !== 'eng'}
									/>
								</Box>
							</div>
							<div
								style={{
									display: 'flex',
									fontSize: '14px',
									paddingRight: '20px',
									paddingBottom: '10px',
									justifyContent: 'start',
								}}
							>
								<a
									href={SAMPLE_FILES[type]()}
									target="_blank"
									rel="noopener noreferrer"
									style={{
										textDecoration: 'underline',
										color: theme.color.blue1,
									}}
								>
									{t(
										'empMgmtHome.clickHereToDownloadSampleFile'
									)}
								</a>
							</div>
						</Box>
					</Box>
					{/* <Box
						style={{
							fontSize: '12px',
							color: theme.color.themeRed1,
						}}
					>
						{`* ${t(REMARKS_TEXT[type])}`}
					</Box> */}
					<Spacer size={32} horizontal={!isMobile} />
					<Box
						row
						justifyContent="start"
						style={{ paddingLeft: '166px' }}
					>
						<Button
							label={`${t('common.send')}`}
							key={getRandomNumber()}
							disabled={
								disabled || !(currentDoc && currentDoc.id)
							}
							primary
							rounded
							extendStyles={{
								width: '232px',
								opacity: 'unset',
								backgroundColor: !(currentDoc && currentDoc.id)
									? theme.color.themeGrey1
									: theme.color.blue8,
								border: !(currentDoc && currentDoc.id)
									? theme.color.themeGrey1
									: theme.color.blue8,
							}}
							onClick={() => {
								setDisabled(true)
								const finalUrl = currentDoc?.meta?.fullURL
								const uploadPayload = {
									initiatorName: orgdetails?.name || '',
									initiatorEmail:
										orgdetails?.primaryContact?.email || '',
									fileUrl: finalUrl,
									dateTime: moment(new Date()).format(
										'DD MM YYYY HH:MM:SS'
									),
									fileType: 'xlsx',
									email: false,
									uploadType: type,
								}
								const successMsg = `${t(
									NAME_ALIASES[type]
								)} ${t('tdmDetailsEntry.uploadedSuccessfully')}`
								dispatch(
									UploadContentDuc.creators.uploadDocumentFile(
										uploadPayload,
										successMsg
									)
								)
								getNextRoute()
							}}
						/>
					</Box>
				</Box>
			</Box>
		</>
	)
}

export { UploadExcel }
