import React from 'react'
import { MillDuc } from 'core-app/modules/Mill/duc'
import { useSelector } from 'react-redux'
import { UnexpectedErrorBlock } from 'core-app/modules/App/components/Error'
import { CreatePlotInput } from './CreatePlotInput'
import { CreatePlotOutput } from './CreatePlotOutput'
import { PreviewPlot } from './PreviewPlot'

const ProductionDocCreation = () => {
	const { payload = {} } = useSelector(MillDuc.selectors.location)

	if (payload.rootModule === 'production-plot-input') {
		const { action } = payload

		if (action === 'create' || action === 'edit') {
			return <CreatePlotInput />
		}
	} else if (payload.rootModule === 'production-plot-output') {
		const { action } = payload
		if (action === 'create' || action === 'edit') {
			return <CreatePlotOutput />
		}
	} else if (
		payload.rootModule === 'sourced-batch' ||
		payload.rootModule === 'produced-batch'
	) {
		const { action } = payload
		if (action === 'view') {
			return <PreviewPlot />
		}
	}

	return <UnexpectedErrorBlock status={401} />
}

export { ProductionDocCreation }
