import React from 'react'
import { useSelector } from 'react-redux'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { Box } from 'ui-lib/utils/Box'
import { H2, P } from 'ui-lib/components/Typography'
import { SelectSearch } from 'ui-lib/components/Select'
import { Button, ButtonWithNoBorder } from 'ui-lib/components/Button'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { isEmptyObject } from 'core-app/utils/helpers'
import { Spacer } from 'ui-lib/utils/Spacer'
import { getIn } from 'timm'
import {
	FormHeader,
	FormBody,
	FormFooter,
} from 'core-app/shared/components/Fragments'

const ProductCodeRegex = /^(.+) \(#(.*)\)$/

const ProductSelection = ({ onClose, onConfirm, activeProductID }) => {
	const allProducts = useSelector(AuthDuc.selectors.getProducts)
	const { t } = useTranslation()
	const activeProducts = allProducts.map(({ code: _code, name }) => {
		return {
			name,
			label: `${name} (#${_code})`,
		}
	})

	const validationSchema = yup.object().shape({
		productID: yup.string().required(t('validation.productRequired')),
	})

	const {
		handleSubmit,
		submitForm,
		values,
		handleBlur,
		errors,
		isSubmitting,
		touched,
		setFieldValue,
	} = useFormik({
		initialValues: {
			productID: activeProductID,
		},
		enableReinitialize: true,
		validationSchema,
		onSubmit: _values => {
			const activeSelection =
				allProducts.find(prod => prod.id === _values.productID) || {}
			onConfirm(activeSelection)
		},
	})

	const activeProduct = allProducts.find(prod => prod.id === values.productID)

	const activeKey = activeProduct
		? `${getIn(activeProduct, ['name'])} (#${getIn(activeProduct, [
				'code',
		  ])})`
		: ''
	const disableCTA = !isEmptyObject(errors) || !values.productID

	return (
		<Box>
			<FormHeader
				row
				justifyContent="space-between"
				alignItems="flex-end"
			>
				<H2 primary>{t('common.selectProduct')}</H2>
			</FormHeader>
			<form onSubmit={handleSubmit}>
				<FormBody padding="20px 0px">
					<Box overflow width="inherit" padding="0 20px">
						<Box overflow width={300}>
							<SelectSearch
								name="productID"
								placeholder={t('common.selectProduct')}
								options={activeProducts}
								onChange={({ productID }) => {
									const _productID =
										getIn(productID, ['label']) || ''
									const match = _productID
										.trim()
										.match(ProductCodeRegex)
									if (match) {
										const productName = match[1].trim()
										const productCode = match[2].trim()

										const activeSelection =
											allProducts.find(
												prod =>
													prod.code === productCode &&
													prod.name === productName
											) || {}
										setFieldValue(
											'productID',
											activeSelection.id
										)
									}
								}}
								value={activeKey}
								onBlur={handleBlur}
								labelKey="label"
								valueKey="name"
								defaultLabel={t('common.selectDefaultLabel')}
								error={touched.productID && errors.productID}
							/>
						</Box>
					</Box>
					<Spacer size={64} />
				</FormBody>
				<FormFooter
					row
					justifyContent="flex-end"
					style={{ width: '100%' }}
				>
					<Box center row style={{ maxWidth: 375 }}>
						<ButtonWithNoBorder
							label={t('common.cancel')}
							tabIndex="-1"
							onClick={onClose}
						/>
						<Spacer size={8} horizontal />
						<Box
							justifyContent="center"
							width={150}
							padding={1}
							height="inherit"
							overflow
						>
							<Button
								label={
									<P color="white">
										<strong>{t('common.submit')}</strong>
									</P>
								}
								isLoading={isSubmitting}
								disabled={disableCTA}
								onClick={() => submitForm()}
								primary
							/>
						</Box>
					</Box>
				</FormFooter>
			</form>
		</Box>
	)
}

export { ProductSelection }
