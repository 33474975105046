import { all, takeLatest, put, select, call } from 'redux-saga/effects'
import LogHelper from 'core-app/utils/logger'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { AppDuc } from 'core-app/modules/App/duc'
import { ProductsDuc } from 'core-app/modules/Products/duc'
import {
	getCoreEndPoint,
	getIAMEndPoint,
	getTraceabilityEndPoint,
	getUtilitiesEndPoint,
} from 'core-app/config'
import { TraceDuc } from 'core-app/modules/Trace/duc'
import { getIn, omit, merge } from 'timm'
import querySerializer from 'query-string'
import request from 'core-app/utils/request'
import { CallWithRefreshCheck } from 'core-app/modules/Auth/AuthSaga'
import {
	isEmptyObject,
	removeDuplicates,
	getQuantityWithUnit,
} from 'core-app/utils/helpers'
import { Storage } from 'ui-lib/utils/storage'
import { CookieDuc } from 'core-app/modules/App/cookieDuc'
import { AUTH_COOKIE_KEYS, USER_COOKIE_KEYS } from 'ui-lib/utils/config'
import {
	extractOrgIDsFromResponses,
	transformFilterStringsToBEQueries,
	extractFilterQueries,
	transformSortStringsToBEQueries,
	extractSortQueries,
} from 'core-app/shared/helpers'
import { IAMAuth } from 'core-app/utils/iam'
import Lodash from 'lodash'

const logger = LogHelper('client:trace')

const PAGINATION_LIMIT = 20

function* fetchTraceListing(action) {
	try {
		const [, orgID] = Storage.get({
			name: AUTH_COOKIE_KEYS.CLIENT_ID,
		}).split('@')
		yield put(TraceDuc.creators.traceDocumentLoading(true))
		yield put(ProductsDuc.creators.fetchProducts())

		const { locationState = {} } = action
		yield put(
			CookieDuc.creators.getCookie({
				cookieName: USER_COOKIE_KEYS.TRACE_PRODUCT_ID,
			})
		)
		const cookies = yield select(CookieDuc.selectors.cookies)
		const activeProductID = getIn(cookies, ['traceProduct'])
		yield put(TraceDuc.creators.setActiveProductID(activeProductID))

		const { query, payload, type } = locationState
		const existingQueryFromUrl = query || {}
		let frontendTargetQuery = {} // this would appear in search bar
		let backendTargetQuery = {} // this would go to backend api call

		const paginationQuery = {
			activeIndex: existingQueryFromUrl.activeIndex
				? existingQueryFromUrl.activeIndex
				: 0,
			limit: Math.min(
				existingQueryFromUrl.limit || PAGINATION_LIMIT,
				PAGINATION_LIMIT
			),
			nextIndex: existingQueryFromUrl.nextIndex,
		}

		if (paginationQuery.limit) {
			backendTargetQuery.limit = paginationQuery.limit
		}

		if (paginationQuery.activeIndex > 0 && paginationQuery.nextIndex) {
			backendTargetQuery.startID = paginationQuery.nextIndex
		}

		// prepare the filter query
		const filterQueries =
			omit(existingQueryFromUrl, [
				'sort',
				'q',
				'activeIndex',
				'limit',
				'nextIndex',
			]) || {}

		if (!isEmptyObject(filterQueries)) {
			// form the backend queries form the object
			backendTargetQuery = merge(
				backendTargetQuery,
				transformFilterStringsToBEQueries(filterQueries)
			)
		}
		const sortQuery = existingQueryFromUrl.sort || []
		const _sortKeys = Array.isArray(sortQuery) ? sortQuery : [sortQuery]
		let _sortQuery = _sortKeys && transformSortStringsToBEQueries(_sortKeys)
		_sortQuery = getIn(_sortQuery, ['delivery-order']) || ''
		const __query = {
			...backendTargetQuery,
		}

		const params = {}
		if (activeProductID) {
			params.products = `cnt(id-%3E${activeProductID})`
		}
		if (__query.products) {
			delete __query.products
		}
		if (!__query.receivingPartyID) {
			params.receivingPartyID = orgID
		}
		if (!__query.type) {
			params.type = 'delivery-order'
		}
		params.traceGenerated = true

		const paramString = Object.entries(params)
			.map(([key, val]) => `${key}=${val}`)
			.join('&')

		const requestUrl = `${getCoreEndPoint()}entities?${paramString}&status=eq(state-%3Edelivered)&${querySerializer.stringify(
			{
				...__query,
				...{ sort: _sortQuery },
			}
		)}&meta=neq(receiverTraceGroupID-%3E'')&meta=eq(traceGenerated-%3Etrue)`
		const origResponse = yield CallWithRefreshCheck(requestUrl)

		const response = getIn(origResponse, ['data']) || {}
		const serverPaginationQuery = {
			activeIndex: paginationQuery.activeIndex // &&
				? // We should have this check so the sequence of pagination is right.
				  // getIn(response, ['startID']) === backendTargetQuery.startID
				  paginationQuery.activeIndex
				: 0,
			limit: Math.min(getIn(response, ['pageSize']) || PAGINATION_LIMIT),
			nextIndex: getIn(response, ['nextStartID']),
		}

		// extract pagination queries from response
		yield put(
			TraceDuc.creators.setTracePaginationEntries(
				serverPaginationQuery.activeIndex,
				getIn(response, ['pageSize']),
				getIn(response, ['total']),
				getIn(response, ['nextStartID']),
				getIn(response, ['prevStartID'])
			)
		)
		// update the queries as applied in backend to stay in sync
		const sortQueriesFromBE = extractSortQueries(
			{ 'delivery-order': response },
			_sortKeys
		)
		// fetch org ids
		const orgIDs = extractOrgIDsFromResponses([origResponse])
		if (orgIDs.length)
			yield put(
				AuthDuc.creators.fetchOrgDetails(removeDuplicates(orgIDs))
			)

		// extract filter queries
		const { queryTree, stateTree } = extractFilterQueries(response)

		frontendTargetQuery = merge(
			frontendTargetQuery,
			queryTree,
			serverPaginationQuery
		)
		if (sortQueriesFromBE.length) {
			frontendTargetQuery.sort = sortQueriesFromBE
		}
		yield put(TraceDuc.creators.setActiveFilters(stateTree))

		yield put(TraceDuc.creators.traceDocumentLoading(true))
		if (response) {
			yield put(TraceDuc.creators.traceDocumentLoading(false))
			yield put(TraceDuc.creators.setActiveProductListing(response.list))
			if (!isEmptyObject(frontendTargetQuery)) {
				yield put(TraceDuc.creators.setActiveSorts(frontendTargetQuery))
				// we have some query, lets update the url to reflect that.
				yield put(
					MainRouteDuc.creators.redirect(
						type,
						payload,
						frontendTargetQuery,
						{
							skipRouteThunk: true,
						}
					)
				)
			}
		}
		yield put(TraceDuc.creators.traceDocumentLoading(false))
	} catch (err) {
		logger.log(err)
	}
}

const getAllOrgTraceGroupMapLCV = sourceTraces => {
	let lcv = 0
	if (sourceTraces.length > 0) {
		sourceTraces.forEach(item => {
			lcv += item.lcv || 0
		})
	}

	return lcv
}

const getAllOrgTraceGroupMap = sourceTraces => {
	let traceGroups = {}
	if (sourceTraces.length > 0) {
		sourceTraces.forEach(trace => {
			if (
				trace.orgTraceGroupMap &&
				Object.keys(trace.orgTraceGroupMap).length > 0
			) {
				Object.keys(trace.orgTraceGroupMap).forEach(traceKey => {
					if (traceGroups[traceKey]) {
						traceGroups[traceKey].sourceTraces = [
							...traceGroups[traceKey].sourceTraces,
							...trace.orgTraceGroupMap[traceKey].sourceTraces,
						]
					} else {
						traceGroups = {
							...traceGroups,
							...trace.orgTraceGroupMap,
						}
					}
				})
			}
		})
	}

	return traceGroups
}

const getAllInitiatorTraceIDS = sourceTraces => {
	let initiatorTraceIDS = []
	if (sourceTraces.length > 0) {
		sourceTraces.forEach(trace => {
			if (
				trace.entity &&
				trace.entity.initiatorTraceIDs &&
				trace.entity.initiatorTraceIDs.length > 0
			) {
				let totalQty = 0
				let availableQty = 0
				let quantityUtilized = 0
				trace.entity.initiatorTraceIDs.forEach(item => {
					availableQty += item.availableQty
					quantityUtilized += item.quantityUtilized
					totalQty += item.totalQty
				})
				const traceIDs = [
					{
						...trace.entity.initiatorTraceIDs[0],
						totalQty,
						availableQty,
						quantityUtilized,
						products: trace.entity.products,
						supplyChainModel:
							trace.entity.meta.supplyChainModel || {},
					},
				]
				initiatorTraceIDS = [...initiatorTraceIDS, ...traceIDs]
			}
		})
	}

	return initiatorTraceIDS
}

const getAllReceiverTraceIDs = sourceTraces => {
	let receiverTraceIDs = []
	if (sourceTraces.length > 0) {
		sourceTraces.forEach(trace => {
			if (
				trace.entity &&
				trace.entity.receiverTraceIDs &&
				trace.entity.receiverTraceIDs.length > 0
			) {
				let totalQty = 0
				let availableQty = 0
				let quantityUtilized = 0
				trace.entity.receiverTraceIDs.forEach(item => {
					availableQty += item.availableQty
					quantityUtilized += item.quantityUtilized
					totalQty += item.totalQty
				})
				const traceIDs = [
					{
						...trace.entity.receiverTraceIDs[0],
						totalQty,
						availableQty,
						quantityUtilized,
						products: trace.entity.products,
						supplyChainModel:
							trace.entity.meta.supplyChainModel || {},
					},
				]
				receiverTraceIDs = [...receiverTraceIDs, ...traceIDs]
			}
		})
	}

	return receiverTraceIDs
}

const getAllSupplyChainModel = sourceTraces => {
	const supplyChainType = []
	if (sourceTraces.length > 0) {
		sourceTraces.forEach(trace => {
			if (
				trace.entity &&
				trace.entity.meta.supplyChainModel &&
				trace.entity.meta.supplyChainModel.id
			) {
				supplyChainType.push(trace.entity.meta.supplyChainModel.id)
			}
		})
	}

	return supplyChainType
}

const getGroupTraceDataSet = traceDataset => {
	if (
		traceDataset.orgTraceGroupMap &&
		Object.keys(traceDataset.orgTraceGroupMap).length &&
		traceDataset.orgTraceGroupMap[
			Object.keys(traceDataset.orgTraceGroupMap)[0]
		].sourceTraces.length
	) {
		return traceDataset.orgTraceGroupMap[
			Object.keys(traceDataset.orgTraceGroupMap)[0]
		].sourceTraces[0]
	}

	return {}
}

const getAllProductEntity = sourceTraces => {
	const enitity = []
	if (sourceTraces.length > 0) {
		sourceTraces.forEach(trace => {
			if (trace.entity) {
				enitity.push(trace.entity)
			}
		})
	}

	return enitity
}

const getLCV = traceDataset => {
	return traceDataset?.lcv || 0
}

const getAllChildLCV = orgTraceGroupMap => {
	let lcv = 0
	if (Object.keys(orgTraceGroupMap).length) {
		Object.keys(orgTraceGroupMap).forEach(traceGroup => {
			if (orgTraceGroupMap[traceGroup].sourceTraces.length > 0) {
				orgTraceGroupMap[traceGroup].sourceTraces.forEach(item => {
					if (
						item.lcv &&
						item.orgTraceGroupMap &&
						Object.keys(item.orgTraceGroupMap).length
					) {
						lcv += item?.lcv || 0
					}
					if (item.orgTraceGroupMap) {
						lcv += getAllChildLCV(item.orgTraceGroupMap, lcv)
					}
				})
			}
		})
	}

	return lcv
}

const transformDataForTree = (
	dataResponse,
	mapResponse,
	badNodePaths,
	isTraceGroup = false
) => {
	let traceDataset = {}
	if (isTraceGroup) {
		traceDataset = dataResponse.traceDataset
			? getGroupTraceDataSet(dataResponse.traceDataset)
			: dataResponse
	} else {
		traceDataset = dataResponse.traceDataset
			? dataResponse.traceDataset
			: dataResponse
	}

	const currentOrgID = dataResponse.traceGroup
		? dataResponse.traceGroup
		: Object.keys(dataResponse.traceDataset.orgTraceGroupMap)[0]

	const currentOrg =
		currentOrgID === traceDataset?.destinationOrg?.id
			? traceDataset.destinationOrg
			: traceDataset.originatingOrg
	let traceIDs = []
	if (
		traceDataset.entity &&
		traceDataset.entity.receiverTraceIDs &&
		traceDataset.entity.receiverTraceIDs.length > 0
	) {
		traceIDs = traceDataset.entity.receiverTraceIDs
	} else if (
		traceDataset.entity &&
		traceDataset.entity.initiatorTraceIDs &&
		traceDataset.entity.initiatorTraceIDs.length > 0
	) {
		traceIDs = traceDataset.entity.initiatorTraceIDs
	} else {
		traceIDs = traceDataset?.productionInput?.quantities || []
	}
	const errorMsg =
		traceDataset?.productionInput?.extractionRatio?.messages[0] || ''
	const { id: _id } = currentOrg
	// eslint-disable-next-line no-param-reassign
	dataResponse.path = (dataResponse.path || '') + _id
	const primaryAddress = currentOrg.primaryAddress || {}
	const blockChainStatus = 'verified'
	const treeData = {
		childIndex: traceDataset.childIndex || 0,
	}
	treeData.path = (dataResponse.path || '') + _id
	treeData.id = _id
	treeData.title =
		currentOrg.name + (traceDataset.productionInput ? ' (Production)' : '')
	treeData.status = 'good'
	treeData.orgType = getIn(currentOrg, ['categories', 0, 'name']) || ''
	treeData.orgTypeID = getIn(currentOrg, ['categories', 0, 'id']) || ''
	treeData.blockChainStatus = blockChainStatus
	treeData.errorMsg = errorMsg
	treeData.virtualTraceData = !(
		getIn(currentOrg, ['status', 'state']) === 'verified' ||
		getIn(currentOrg, ['status', 'state']) === 'pending'
	)
	treeData.unKnownPercentage = traceDataset?.unknownPercentage
		? traceDataset?.unknownPercentage
		: null
	const { certificateTypes } = dataResponse
	const certificateTypesList =
		certificateTypes && certificateTypes.length > 0
			? certificateTypes.map(item => {
					return {
						label: item === 'UNCERTIFIED' ? 'NONE' : item,
						key: item,
					}
			  })
			: []
	treeData.certificateTypes = certificateTypesList
	const { supplyChainTypes } = dataResponse
	const supplyChainTypesList = []
	if (supplyChainTypes?.length > 0) {
		supplyChainTypes.forEach(supplyChainType => {
			supplyChainTypesList.push({
				name: supplyChainType.value,
				label: supplyChainType.label,
			})
		})
	}
	treeData.supplyChainTypes = supplyChainTypesList
	const address = `
		${getIn(primaryAddress, ['line1']) || ''}
		${getIn(primaryAddress, ['line2']) || ''}
		${getIn(primaryAddress, ['postalCode']) || ''} - 
		${getIn(primaryAddress, ['city']) || ''} 
		${getIn(primaryAddress, ['state']) || ''} 
		${getIn(primaryAddress, ['country']) || ''}
	`
	const certType = []
	const allCerts = getIn(traceDataset, ['orgCertificates']) || []
	if (allCerts.length > 0) {
		allCerts.forEach(certificateData => {
			const ctype =
				getIn(certificateData, [
					'meta',
					'certificate',
					'issuingBody',
				]) || null
			const certURL =
				getIn(certificateData, ['files', 0, 'meta', 'fullURL']) || null
			const currentState =
				getIn(certificateData, ['status', 'state']) || null
			const expiryEndDate =
				getIn(certificateData, ['expiryEndDate']) || ''
			let currentStateList = []
			if (currentState && currentState.length > 0) {
				currentStateList = currentState.split('-')
				currentStateList.forEach((word, index) => {
					currentStateList[index] =
						word[0].toUpperCase() + word.substr(1)
				})
			}
			const state =
				currentStateList.length > 0
					? currentStateList.join(' and ')
					: ''
			if (ctype) {
				certType.push({ ctype, certURL, state, expiryEndDate })
			}
		})
	}

	let supplyBase = false
	if (traceDataset.supplyBase) {
		supplyBase = true
		if (traceDataset.supplyBase.length > 0) {
			traceDataset.supplyBase.forEach(item => {
				certType.push({
					ctype: item.certifiedBy,
					state: item.documentState,
					certURL: item.certificateLink,
					expiryEndDate: item.expiryEndDate,
					supplyChainModel: item.supplyChainModel,
				})
			})
		}
	}
	if (certType.length === 0) {
		certType.push({
			ctype: 'UNCERTIFIED',
			state: 'Active',
		})
	}

	treeData.supplyBase = supplyBase
	treeData.certType = certType
	let supplyChainType = []
	if (traceDataset.entity && traceDataset.entity.meta.supplyChainType) {
		// eslint-disable-next-line prefer-destructuring
		supplyChainType = traceDataset.entity.meta.supplyChainType
	} else if (
		traceDataset.entity &&
		traceDataset.entity.meta.supplyChainModel
	) {
		supplyChainType = [traceDataset.entity.meta.supplyChainModel.id]
	}
	treeData.supplyChainType = supplyChainType
	treeData.deforestationData =
		getIn(currentOrg, ['meta', 'deforestationData']) || []
	treeData.deforestationAlertData =
		getIn(currentOrg, ['meta', 'deforestationAlertData']) || []
	treeData.shape =
		getIn(primaryAddress, ['location', 'geoData', 'shape']) || {}
	treeData.geoType =
		getIn(primaryAddress, ['location', 'geoData', 'type']) || ''
	treeData.transforming = []
	treeData.transformed = []

	treeData.transaction = []
	if (traceIDs && traceIDs.length > 0) {
		const uniqueTraceIDs = [
			...new Map(traceIDs.map(item => [item.traceID, item])).values(),
		]
		const totalQuantity = uniqueTraceIDs.reduce((sum, current) => {
			return (
				sum +
				(current.quantityUtilized ||
					current.totalQty ||
					current.quantity ||
					0)
			)
		}, 0)

		const totalLCV =
			getLCV(traceDataset) + getAllChildLCV(traceDataset.orgTraceGroupMap)

		const getExchangesListed = exchangeListed => {
			return exchangeListed ? 'yes' : 'no'
		}

		treeData.currentOrgDetail = {
			orgID: getIn(currentOrg, ['id']) || '',
			orgType: getIn(currentOrg, ['categories', 0, 'id']) || '',
			exchangeListed:
				typeof currentOrg?.meta?.exchangeListed !== 'undefined'
					? getExchangesListed(currentOrg?.meta?.exchangeListed)
					: '',
			exchangeListedCountry:
				currentOrg?.meta?.exchangeListedCountry || [],
			carbonNumberData: currentOrg?.meta?.carbonNumberData || false,
			deforestationAlert: currentOrg?.deforestationAlert || false,
			eudrComplianceData: currentOrg?.meta?.eudrComplianceData || {},
			deforestationAlertData:
				currentOrg?.meta?.deforestationAlertData || [],
		}

		const transaction = traceIDs.map(item => {
			const quantity =
				item.quantityUtilized || item.totalQty || item.quantity || 0

			return {
				createdAt: traceDataset.entity.createdAt,
				product: traceDataset.product
					? traceDataset.product.name
					: ' --- ',
				quantity: getQuantityWithUnit(quantity, getIn(item, ['uom'])),
				entityID: traceDataset.entity?.id || '---',
				entityNumber: traceDataset.entity?.number || '---',
				certType,
				totalLCV: `${Lodash.round(totalLCV, 2)} KgCO2e/ton`,
				lcv: `${
					item.products
						? getIn(item, ['products', 0, 'lcv'])
						: getIn(traceDataset, ['entity', 'products', 0, 'lcv'])
				} KgCO2e/ton`,
				location: {
					long: parseFloat(
						getIn(primaryAddress, ['location', 'long']) || 0,
						5
					),
					lat: parseFloat(
						getIn(primaryAddress, ['location', 'lat']) || 0,
						5
					),
					geoData:
						getIn(primaryAddress, ['location', 'geoData']) || {},
				},
				locationOfTrace: address,
				totalQuantity: getQuantityWithUnit(
					totalQuantity,
					getIn(item, ['uom'])
				),
				blockChainFailed: false,
				traceID: item.traceID,
				supplyChainModel: item.supplyChainModel
					? item.supplyChainModel
					: traceDataset.entity.meta.supplyChainModel || {},
			}
		})
		treeData.transaction = transaction
		if (treeData.traceGroupStatus !== 'transformed') {
			treeData.transforming = transaction
		} else {
			treeData.transformed = transaction
		}
	}
	mapResponse.push({
		name: currentOrg.name,
		orgTypeID: getIn(currentOrg, ['categories', 0, 'id']) || '',
		orgType: getIn(currentOrg, ['categories', 0, 'name']) || '',
		orgID: getIn(currentOrg, ['id']) || '',
		eudrComplianceData: currentOrg?.meta?.eudrComplianceData || {},
		coordinates: [
			parseFloat(
				getIn(primaryAddress, [
					'location',
					'geoData',
					'shape',
					'center',
					0,
				]) ||
					getIn(primaryAddress, ['location', 'long']) ||
					0,
				5
			),
			parseFloat(
				getIn(primaryAddress, [
					'location',
					'geoData',
					'shape',
					'center',
					1,
				]) ||
					getIn(primaryAddress, ['location', 'lat']) ||
					0,
				5
			),
		],
		status: '',
		universalMillID: getIn(currentOrg, ['meta', 'universalMillID']) || '',
		path: dataResponse.path,
		certType,
		certificateTypes: certificateTypesList,
		supplyChainTypes: supplyChainTypesList,
		location: {
			long: parseFloat(
				getIn(primaryAddress, ['location', 'long']) || 0,
				5
			),
			lat: parseFloat(getIn(primaryAddress, ['location', 'lat']) || 0, 5),
		},
		locationOfTrace: address,
		totalQuantity: traceIDs
			? traceIDs.reduce((sum, current) => {
					return sum + (current.totalQty || 0)
			  }, 0)
			: 0,
		deforestationData:
			getIn(currentOrg, ['meta', 'deforestationAlertData']) || [],
	})

	if (
		traceDataset.orgTraceGroupMap &&
		Object.keys(traceDataset.orgTraceGroupMap).length
	) {
		treeData.children = Object.keys(traceDataset.orgTraceGroupMap).map(
			traceGroup => {
				return traceDataset.orgTraceGroupMap[traceGroup]
					?.sourceTraces[0]
			}
		)
		Object.keys(traceDataset.orgTraceGroupMap).forEach(
			(traceGroup, index) => {
				const sourceTraces =
					traceDataset.orgTraceGroupMap[traceGroup]?.sourceTraces
				const data = sourceTraces[0] || []
				data.lcv = getAllOrgTraceGroupMapLCV(sourceTraces)
				data.orgTraceGroupMap = getAllOrgTraceGroupMap(
					sourceTraces || []
				)
				data.productEntity = getAllProductEntity(sourceTraces || [])
				if (data.entity) {
					data.entity.initiatorTraceIDs = getAllInitiatorTraceIDS(
						sourceTraces || []
					)
					data.entity.meta.supplyChainType = getAllSupplyChainModel(
						sourceTraces || []
					)
					data.entity.receiverTraceIDs = getAllReceiverTraceIDs(
						sourceTraces || []
					)
				}
				const d = Object.assign({ childIndex: index }, data)
				d.path = `${dataResponse.path}|`
				d.certificateTypes = certificateTypes
				d.supplyChainTypes = supplyChainTypes
				d.errorMsg = errorMsg
				d.traceGroup = traceGroup
				const traceData = transformDataForTree(
					d,
					mapResponse,
					badNodePaths
				)
				treeData.children[index] = traceData.treeData
			}
		)
	}

	return { treeData, mapResponse, badNodePaths }
}

const orgTransformDataForTree = (
	dataResponse,
	mapResponse,
	common,
	badNodePaths
) => {
	const { organization, productTotals, massBalanceStatus } = dataResponse
	const { id: _id } = organization
	// eslint-disable-next-line no-param-reassign

	const { organizations, products } = common

	const currentOrg = organizations[_id]
	const { primaryAddress } = currentOrg
	// eslint-disable-next-line no-param-reassign
	dataResponse.path = (dataResponse.path || '') + _id

	const blockChainStatus =
		getIn(currentOrg, ['blockchain', 'status']) || 'verified'

	const treeData = {
		childIndex: dataResponse.childIndex || 0,
	}
	treeData.path = dataResponse.path
	treeData.id = currentOrg.id
	treeData.certificationStatus = currentOrg.certificationStatus.status
	treeData.title = currentOrg.name
	treeData.status = massBalanceStatus.status
	treeData.orgType = getIn(currentOrg, ['categories', 0, 'name']) || ''
	treeData.blockChainStatus = blockChainStatus

	if (
		massBalanceStatus.status === 'bad' ||
		currentOrg.certificationStatus.status !== 'verified'
	) {
		treeData.massBalanceErrorMsg = massBalanceStatus.messages
		// eslint-disable-next-line no-param-reassign
		badNodePaths[treeData.path] = true
	}

	const address = `
		${getIn(primaryAddress, ['line1']) || ''}
		${getIn(primaryAddress, ['line2']) || ''}
		${getIn(primaryAddress, ['postalCode']) || ''} - 
		${getIn(primaryAddress, ['city']) || ''} 
		${getIn(primaryAddress, ['state']) || ''} 
		${getIn(primaryAddress, ['country']) || ''}
	`

	const certType = []
	const allCerts =
		getIn(currentOrg, ['certificationStatus', 'certificates']) || []
	allCerts.forEach(c => {
		const ctype = getIn(c, ['meta', 'certificate', 'issuingBody']) || null
		const certURL = getIn(c, ['files', 0, 'meta', 'fullURL']) || null
		if (ctype) {
			certType.push({ ctype, certURL })
		}
	})

	treeData.transforming = []
	treeData.transformed = []
	treeData.transaction = (dataResponse.batches || []).map(item => {
		const product = products ? products[item.productID] : null
		const entityID = item.meta === undefined ? '' : item.meta.entityID
		const entityNumber =
			item.meta === undefined ? '' : item.meta.entityNumber

		return {
			createdAt: item.createdAt,
			product: product ? product.name : ' --- ',
			quantity: `${item.totalQty || 0} ${
				product ? product.uom : ' --- '
			}`,
			entityID: entityID || '---',
			entityNumber: entityNumber || '---',
			certType,
			location: {
				long: parseFloat(
					getIn(primaryAddress, ['location', 'long']) || 0,
					5
				),
				lat: parseFloat(
					getIn(primaryAddress, ['location', 'lat']) || 0,
					5
				),
			},
			locationOfTrace: address,
			totalQuantity: `${productTotals[product.id]} ${product.uom}`,
			blockChainFailed:
				getIn(item, ['blockchainVerificationFailed']) || false,
			traceID: item.traceID,
		}
	})
	mapResponse.push({
		name: currentOrg.name,
		orgTypeID: getIn(currentOrg, ['categories', 0, 'id']) || '',
		orgType: getIn(currentOrg, ['categories', 0, 'name']) || '',
		orgID: getIn(currentOrg, ['id']) || '',
		coordinates: [
			parseFloat(getIn(primaryAddress, ['location', 'long']) || 0, 5),
			parseFloat(getIn(primaryAddress, ['location', 'lat']) || 0, 5),
		],
		certificationStatus: currentOrg.certificationStatus.status,
		status: massBalanceStatus.status,
		path: dataResponse.path,
		certType: certType.join(','),
		location: {
			long: parseFloat(
				getIn(primaryAddress, ['location', 'long']) || 0,
				5
			),
			lat: parseFloat(getIn(primaryAddress, ['location', 'lat']) || 0, 5),
		},
		locationOfTrace: address,
		totalQuantity: 0,
		deforestationData:
			getIn(currentOrg, ['meta', 'deforestationData']) || [],
	})

	if (dataResponse.parents && dataResponse.parents.length) {
		treeData.children = dataResponse.parents
		dataResponse.parents.forEach((data, index) => {
			const d = Object.assign({ products, childIndex: index }, data)

			d.path = `${dataResponse.path}|`
			const traceData = orgTransformDataForTree(
				d,
				mapResponse,
				common,
				badNodePaths
			)
			treeData.children[index] = traceData.treeData
		})
	}

	return { treeData, mapResponse, badNodePaths }
}

const orgTraceTreeData = dataResponse => {
	const mapResponse = []
	const badNodePaths = {}

	dataResponse.forEach((ob, index) => {
		// eslint-disable-next-line no-param-reassign
		dataResponse[index] = orgTransformDataForTree(
			ob,
			mapResponse,
			ob.common,
			badNodePaths
		)
	})

	return { dataResponse, badNodePaths }
}

const traceTreeData = (dataResponse, isTraceGroup = false) => {
	const mapResponse = []
	const badNodePaths = {}
	dataResponse.forEach((ob, index) => {
		// eslint-disable-next-line no-param-reassign
		dataResponse[index] = transformDataForTree(
			ob,
			mapResponse,
			badNodePaths,
			isTraceGroup
		)
	})

	return { dataResponse, badNodePaths }
}

// Organisation level Product Trace
function* fetchProductOrgTrace(action) {
	try {
		const { traceID } = action
		yield put(TraceDuc.creators.traceDocumentLoading(true))

		const requestUrl = `${getCoreEndPoint()}v2/inventory/products/trace/${traceID}/backward/orgs`
		const { data } = yield CallWithRefreshCheck(requestUrl)

		const { dataResponse, badNodePaths } = orgTraceTreeData([data])
		const treeData = getIn(dataResponse, [0, 'treeData']) || {}
		const graphData = Object.assign({}, treeData)
		const mapResponse = getIn(dataResponse, [0, 'mapResponse']) || []
		yield put(TraceDuc.creators.fetchProductTreeTraceSuccess([treeData]))
		yield put(TraceDuc.creators.fetchProductMapTraceSuccess(mapResponse))
		yield put(TraceDuc.creators.fetchProductGraphTraceSuccess(graphData))
		yield put(TraceDuc.creators.fetchBadNodePaths(badNodePaths))
		yield put(TraceDuc.creators.traceDocumentLoading(false))
	} catch (err) {
		logger.log(err)
	} finally {
		yield put(TraceDuc.creators.traceDocumentLoading(false))
	}
}

const validOrgTypes = ['palmoil-smallholder', 'palmoil-plantation']

function extractDeforestationOrgIDs(data) {
	const ids = new Set()

	function traverse(node) {
		if (validOrgTypes.includes(node.orgTypeID)) {
			ids.add(node.id)
		}
		if (node.children) {
			node.children.forEach(child => traverse(child))
		}
	}

	traverse(data)

	return Array.from(ids)
}

// Organisation level Product Trace
function* fetchProductTrace(action) {
	try {
		const { traceID, orgID, isTraceGroup } = action
		yield put(TraceDuc.creators.traceDocumentLoading(true))
		yield put(AppDuc.creators.showGlobalLoader('fetch-product-trace'))

		const queryParam = isTraceGroup
			? `traceGroupID=${traceID}`
			: `traceID=${traceID}`
		const requestUrl = `${getTraceabilityEndPoint()}clients/organizations/${orgID}/transaction/backward?${queryParam}`
		const { data } = yield CallWithRefreshCheck(requestUrl)

		const { dataResponse, badNodePaths } = traceTreeData(
			[data],
			isTraceGroup
		)
		const treeData = getIn(dataResponse, [0, 'treeData']) || {}
		const graphData = Object.assign({}, treeData)
		const mapResponse = getIn(dataResponse, [0, 'mapResponse']) || []
		yield put(TraceDuc.creators.fetchProductTreeTraceSuccess([treeData]))
		yield put(TraceDuc.creators.fetchProductMapTraceSuccess(mapResponse))
		yield put(TraceDuc.creators.fetchProductGraphTraceSuccess(graphData))

		const deforestationOrgIDs = extractDeforestationOrgIDs(treeData)
		const countRequestUrl = `${getUtilitiesEndPoint()}deforestation/count?orgIDs=${deforestationOrgIDs.toString()}`
		const { data: countData = [] } = yield call(request, countRequestUrl)
		const parsedCountObject = {}
		countData.forEach(item => {
			if (item.id && item.count) {
				parsedCountObject[item.id] = item.count
			}
		})
		yield put(TraceDuc.creators.setDeforestatioOrgCount(parsedCountObject))
		yield put(TraceDuc.creators.fetchBadNodePaths(badNodePaths))
		yield put(TraceDuc.creators.traceDocumentLoading(false))
	} catch (err) {
		logger.log(err)
	} finally {
		yield put(TraceDuc.creators.traceDocumentLoading(false))
		yield put(AppDuc.creators.hideGlobalLoader('fetch-product-trace'))
	}
}

function* fetchGeoData(action) {
	try {
		const { geoID, orgID } = action
		yield put(AppDuc.creators.showGlobalLoader('fetch-geo-data'))

		const requestUrl = `${getIAMEndPoint()}clients/organizations/${orgID}/geodata/${geoID}`
		const { data } = yield CallWithRefreshCheck(requestUrl)
		yield put(TraceDuc.creators.setGeoData(data))
	} catch (err) {
		logger.log(err)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-geo-data'))
	}
}

// temporary use case
function* checkBlockchainDisabled() {
	try {
		const requestUrl = `${getIAMEndPoint()}clients/_/computed-access`
		const options = {
			method: 'GET',
		}

		yield put(AppDuc.creators.showGlobalLoader('check-blockchain-enabled'))

		const accesses = yield CallWithRefreshCheck(requestUrl)

		const blockChainStatus = IAMAuth(accesses.data).dataFilters(
			'fe.tdm.trace.r',
			'other',
			'fe.disableBlockchainStatus'
		)

		yield put(TraceDuc.creators.setBlockChainStatus(blockChainStatus))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('check-blockchain-enabled'))
	}
}

function* getHederaMessages(action) {
	const { traceIDs } = action
	try {
		const requestUrl = `${getCoreEndPoint()}trace/hedera/messages?traceid=${traceIDs}`
		const { data } = yield call(request, requestUrl)
		yield put(TraceDuc.creators.setHederaMessages({ data }))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

export function* getDeforestationEvents(action) {
	const { orgID } = action
	try {
		yield put(AppDuc.creators.showGlobalLoader('fetch-deforestation-alert'))

		const requestUrl = `${getUtilitiesEndPoint()}deforestationlist?orgID=${orgID}`
		const { data } = yield call(request, requestUrl)
		yield put(TraceDuc.creators.setDeforestationEvents(data))
		yield put(AppDuc.creators.hideGlobalLoader('fetch-deforestation-alert'))
	} catch (err) {
		const { message } = err
		yield put(AppDuc.creators.hideGlobalLoader('fetch-deforestation-alert'))
		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

export default function* TraceSaga() {
	try {
		yield all([
			takeLatest(
				TraceDuc.creators.fetchTraceListing().type,
				fetchTraceListing
			),
			takeLatest(
				TraceDuc.creators.fetchProductOrgTrace().type,
				fetchProductOrgTrace
			),
			takeLatest(
				TraceDuc.creators.fetchProductTrace().type,
				fetchProductTrace
			),
			takeLatest(TraceDuc.creators.fetchGeoData().type, fetchGeoData),
			takeLatest(
				TraceDuc.creators.checkBlockchainDisabled().type,
				checkBlockchainDisabled
			),
			takeLatest(
				TraceDuc.creators.getHederaMessages().type,
				getHederaMessages
			),
			takeLatest(
				TraceDuc.creators.getDeforestationEvents().type,
				getDeforestationEvents
			),
		])
	} catch (e) {
		logger.error(e)
	}
}
