import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AppDuc } from 'core-app/modules/App/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { useDispatch, useSelector } from 'react-redux'
import { Card } from 'ui-lib/components/Card'
import { Box } from 'ui-lib/utils/Box'
import { isMarketplaceHost } from 'ui-lib/utils/helpers'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { Spacer } from 'ui-lib/utils/Spacer'
import { H4 } from 'ui-lib/components/Typography'
import EmployeeIcon from 'ui-lib/icons/employee-help.svg'
import LanguageIcon from 'ui-lib/icons/language-help.svg'
import AssetIcon from 'ui-lib/icons/asset-help.svg'
import PartnerIcon from 'ui-lib/icons/partner-help.svg'

const Title = styled.div(p => ({
	paddingLeft: ((p.index || 0) + 1) * 10,
	color: '#686868',
	maxWidth: '210px',
	lineHeight: 1.6,
}))

const MenuWrap = styled.a({
	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'center',
})

const Setup = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const virtualAccess = useSelector(AuthDuc.selectors.getVirtualAccess)

	const {
		incomingAreaAvailable,
		outgoingAreaAvailable,
		incomingTankAvailable,
		outgoingTankAvailable,
		weighbridgeAvailable,
		partnersAvailable,
		employeesAvailable,
		languageSet,
	} = useSelector(AuthDuc.selectors.getFirstTimeStats)

	const actor = useSelector(AuthDuc.selectors.getActor)[0] || []

	const getAssetsStatus = () => {
		if (actor.includes('palmoil_ffbdealer')) {
			return !weighbridgeAvailable
		}
		if (actor.includes('palmoil_mill')) {
			return (
				!incomingAreaAvailable ||
				!outgoingAreaAvailable ||
				!outgoingTankAvailable ||
				!weighbridgeAvailable
			)
		}
		if (actor.includes('palmoil_kernelmill')) {
			return (
				!incomingAreaAvailable ||
				!outgoingTankAvailable ||
				!weighbridgeAvailable
			)
		}
		if (
			actor.includes('palmoil_refinery') ||
			actor.includes('palmoil_oleochemicals')
		) {
			return (
				!incomingTankAvailable ||
				!outgoingTankAvailable ||
				!weighbridgeAvailable
			)
		}
	}

	const setupConfig = [
		{
			icon: LanguageIcon,
			status: languageSet === false ? 'pending' : 'active',
			description: t('setup.setPrefLang'),
			path: '/settings/change-language',
			action: MainRouteDuc.types.SETTINGS$SUBROOT,
			payload: { rootModule: 'change-language' },
		},
		{
			icon: EmployeeIcon,
			status: !employeesAvailable ? 'pending' : 'active',
			description: t('setup.setEmpList'),
			path: '/admin/employee-management/add-employee',
			action: MainRouteDuc.types.ADMIN$WACTION,
			payload: {
				rootModule: 'employee-management',
				action: 'add-employee',
			},
		},
	]

	if (!isMarketplaceHost()) {
		setupConfig.push(
			{
				icon: AssetIcon,
				status: getAssetsStatus() ? 'pending' : 'active',
				description: t('setup.setAssetList'),
				path: '/admin/assets',
				action: MainRouteDuc.types.ADMIN$SUBROOT,
				payload: { rootModule: 'assets' },
			},
			{
				icon: PartnerIcon,
				status: !partnersAvailable ? 'pending' : 'active',
				description: t('setup.setPartnerList'),
				path: '/partner/add-partner',
				action: MainRouteDuc.types.PARTNER$ACTION,
				payload: { action: 'add-partner' },
			}
		)
	}

	return (
		<Box row={!isMobile}>
			<Card
				style={{
					padding: '17px 25px',
					margin: '0 12px 0 0',
				}}
				textAlign="left"
				height="146px"
			>
				<Box>
					<H4
						style={{
							color: '#3F56C4',
							fontSize: '20px',
						}}
					>
						{t('setup.title')}
					</H4>
				</Box>
				<Spacer size={24} />

				<Box row justifyContent="space-between">
					{setupConfig.map(setup => {
						return (
							<Box row key={setup.path}>
								<MenuWrap
									href={setup.path}
									onClick={e => {
										e.preventDefault()
										if (!virtualAccess) {
											dispatch(
												MainRouteDuc.creators.switchPage(
													setup.action,
													setup.payload
												)
											)
										}
									}}
									title={
										virtualAccess
											? t(
													'setup.pleaseSetUpYourAccountWithProperDocuments'
											  )
											: setup.description
									}
								>
									<IconWrapper
										style={{
											backgroundColor: '#E9ECF0',
											padding: '10px',
											borderRadius: '50%',
											alignItems: 'center',
											width: '43px',
											height: '45px',
										}}
									>
										<Icon
											glyph={setup.icon}
											color={
												setup.status === 'active'
													? 'green'
													: '#7B8088'
											}
										/>
									</IconWrapper>
									<Spacer size={8} />
									<Title>{setup.description}</Title>
								</MenuWrap>
							</Box>
						)
					})}
				</Box>
			</Card>
		</Box>
	)
}

export { Setup }
