import React from 'react'
import { useSelector } from 'react-redux'
import { NotFoundBlock } from 'core-app/modules/App/components/Error'
import { PlantationDuc } from '../duc'
import { Tickets } from './subModules/TicketListing'
import { BinListing } from './subModules/BinListing'
import { Document } from './subModules/Documents'

const modulesMap = {
	ticket: Tickets,
	bin: BinListing,
	document: Document,
}

const SubDashboard = () => {
	const location = useSelector(PlantationDuc.selectors.location)
	const { payload = {} } = location
	const activeModule = payload.rootModule

	const Component = modulesMap[activeModule] || NotFoundBlock
	// if we are here, we have a submodule, route it to the module

	return <Component />
}

export default SubDashboard
