import React, { useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { removeLast } from 'timm'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AppDuc } from 'core-app/modules/App/duc'
import { Title } from 'core-app/shared/components/Title'
import { KeyValueSegment } from 'core-app/shared/components/KeyValueSegment'
import {
	LabelsMap,
	NAME_ALIASES,
	PlantationDuc,
} from 'core-app/modules/Plantation/duc'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { BoxShadowWrapper } from 'ui-lib/components/Card'
import { Button, ButtonIconWrapper } from 'ui-lib/components/Button'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { chunkArrayInGroups } from 'ui-lib/utils/helpers'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import EditIcon from 'ui-lib/icons/edit.svg'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import VerifiedIcon from 'ui-lib/icons/verified.svg'

const getPairsAndOddOne = arr => {
	let targetArr = arr
	let lastOdd = null
	if (targetArr.length % 2 > 0) {
		lastOdd = targetArr[targetArr.length - 1]
		targetArr = removeLast(targetArr)
	}

	return { pairs: chunkArrayInGroups(targetArr, 6), lastOdd }
}

const ALIASES_KEYS = {
	assigneeName: 'plantationViewTicket.harvesterName',
	number: 'plantationViewTicket.ticketNo',
	hCode: 'plantationViewTicket.harvesterCode',
	ffbCount: 'plantationViewTicket.quantity',
	area: 'plantationViewTicket.area',
	phase: 'plantationViewTicket.phase',
	block: 'plantationViewTicket.block',
	ripeBunches: 'plantationViewTicket.ripe',
	overripeBunches: 'plantationViewTicket.overripe',
	underripeBunches: 'plantationViewTicket.underripe',
	emptyBunches: 'plantationViewTicket.empty',
	wetBunches: 'plantationViewTicket.wet',
	unripeBunches: 'plantationViewTicket.unripe',
}

const { pairs, lastOdd } = getPairsAndOddOne(Object.keys(ALIASES_KEYS))

const PreviewTicketBlock = ({ values, isMobile, t }) => {
	const { isSynced } = values

	return (
		<>
			{isSynced && (
				<Box style={{ flex: 1 }} alignItems="flex-end">
					<IconWrapper size={25} color="#13835A">
						<Icon glyph={VerifiedIcon} />
					</IconWrapper>
				</Box>
			)}
			<Box justifyContent="space-between" row={!isMobile}>
				{pairs.map((pair, index) => (
					<>
						<Box>
							{pair.map(_key => (
								<KeyValueSegment
									key={_key}
									name={t(ALIASES_KEYS[_key])}
									description={values[_key]}
									userStatus={values.userStatus}
									t={t}
								/>
							))}
						</Box>
						{index % 2 === 0 && index !== pairs.length && (
							<Spacer size={16} horizontal />
						)}
					</>
				))}
			</Box>
			{lastOdd && (
				<Box>
					<KeyValueSegment
						name={t(ALIASES_KEYS[lastOdd])}
						description={values[lastOdd]}
					/>
				</Box>
			)}
		</>
	)
}

export const PreviewTicket = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const locationState = useSelector(PlantationDuc.selectors.location)

	const ticketInformation = useSelector(
		PlantationDuc.selectors.fetchTicketInformation
	)

	const { payload = {} } = locationState
	const { rootModule, action } = payload

	const title = LabelsMap[rootModule]
	const actionTitle = NAME_ALIASES[action]

	const getBreadCrumbsList = useCallback(
		() => [
			{
				label: t('breadcrumb.home'),
				name: 'home',
				isActive: true,
			},
			{
				label:
					rootModule === 'document'
						? t('breadcrumb.records')
						: t('breadcrumb.tickets'),
				name: rootModule,
				isActive: true,
			},
			{
				label: `${t(actionTitle)} ${t(title)}`,
				name: 'submodule-dashboard',
				isActive: false,
			},
		],
		[title, actionTitle, rootModule, t]
	)

	const breadCrumbs = useMemo(() => getBreadCrumbsList(title, rootModule), [
		title,
		rootModule,
		getBreadCrumbsList,
	])

	/** Handlers */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === rootModule) {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.PLANTATION$SUBROOT,
					{ rootModule }
				)
			)
		}
	}

	return (
		<Box padding={8} width="100%" height="100%">
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>

			<Box row justifyContent="space-between" alignItems="baseline">
				<Title title={t('plantationViewTicket.viewTicket')} />
				<Box row justifyContent="flex-end">
					{rootModule === 'ticket' && (
						<Button
							label={t(
								'plantationViewTicket.editTicket'
							).toUpperCase()}
							customIcon={
								<ButtonIconWrapper lightBG>
									<Icon glyph={EditIcon} />
								</ButtonIconWrapper>
							}
							rounded
							onClick={() =>
								dispatch(
									MainRouteDuc.creators.switchPage(
										MainRouteDuc.types
											.PLANTATION$DOCUMENTREFERENCE,
										{
											rootModule: 'ticket',
											action: 'edit',
											documentReference:
												ticketInformation.id,
										}
									)
								)
							}
							extendStyles={{ width: '200px' }}
						/>
					)}
				</Box>
			</Box>
			<BoxShadowWrapper>
				<Box
					padding="16px"
					style={{
						borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
					}}
				>
					<PreviewTicketBlock
						values={ticketInformation}
						isMobile={isMobile}
						t={t}
					/>
				</Box>
			</BoxShadowWrapper>
			<Box padding="25px 2px" style={{ maxWidth: 150 }}>
				<Button
					label={t('common.back')}
					rounded
					customIcon={
						<ButtonIconWrapper lightBG>
							<Icon glyph={LeftArrowIcon} />
						</ButtonIconWrapper>
					}
					onClick={() =>
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.PLANTATION$SUBROOT,
								{ rootModule, type: 'checker' }
							)
						)
					}
				/>
			</Box>
		</Box>
	)
}
