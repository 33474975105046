import React, { useMemo, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { merge, getIn, omit, setIn } from 'timm'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { Paginator } from 'ui-lib/components/Paginator'
import { Label } from 'ui-lib/components/Typography'
import { getStartIndex, getEndIndex } from 'ui-lib/components/Paginator/utils'
import { DatePicker } from 'ui-lib/components/Datepicker'
import { AppDuc } from 'core-app/modules/App/duc'
import { useTranslation } from 'react-i18next'
import { Icon } from 'ui-lib/icons/components/Icon'
import {
	ButtonIconWrapper,
	Button,
	ButtonWithNoBorder,
} from 'ui-lib/components/Button'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import Documents from 'ui-lib/icons/documents.svg'
import { Title } from 'core-app/shared/components/Title'
import { BoxShadowTable } from 'core-app/shared/components/BoxShadowTable'
import { initiateSort, getTargetFilterQueries } from 'core-app/shared/helpers'
import theme from 'ui-lib/utils/base-theme'
import { MillDuc } from '../duc'
import { NAME_ALIASES, LabelsMap } from '../config'
import { getProductionTableColumnConfig as getColumnConfig } from '../components/Columns'

const getBreadCrumbsList = (rootModule, subModule, t) => [
	{
		label: t('breadcrumb.home'),
		name: 'home',
		isActive: true,
	},
	{
		label: t('breadcrumb.production'),
		name: 'production',
		isActive: true,
	},
	{
		label: `${t(rootModule)} ${t(subModule)}`,
		name: 'submodule-dashboard',
		isActive: false,
	},
]

const PaginatorBlock = React.memo(
	({ paginationConfig, onChange, documents, startDesc, toDesc, ofDesc }) => (
		<Box padding={8}>
			<Paginator
				onClick={direction => onChange('paginate', { direction })}
				startIndex={getStartIndex(
					paginationConfig.activeIndex,
					paginationConfig.limit
				)}
				endIndex={getEndIndex(
					paginationConfig.activeIndex,
					paginationConfig.limit,
					paginationConfig.total
				)}
				totalCount={paginationConfig.total}
				startDesc={startDesc}
				toDesc={toDesc}
				ofDesc={ofDesc}
				hidePageResults={(documents || []).length === 0}
			/>
		</Box>
	)
)

const DocumentListing = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const [activeDateType, setActiveDateType] = useState('createdAt')
	const location = useSelector(MillDuc.selectors.location)
	const documents = useSelector(MillDuc.selectors.getListingDocuments)
	const orgDetails = useSelector(AuthDuc.selectors.getAvailableOrgs)
	const isLoading = useSelector(MillDuc.selectors.getListingLoadingStatus)
	const activeFilters = useSelector(MillDuc.selectors.getListingActiveFilters)
	const allProducts = useSelector(AuthDuc.selectors.getProducts)

	const activeDateFilters = getIn(activeFilters, [activeDateType]) || {}
	let activeStartDate = getIn(activeDateFilters, ['gte', 0]) || null
	if (activeStartDate) activeStartDate = new Date(activeStartDate)
	let activeEndDate = getIn(activeDateFilters, ['lte', 0]) || null
	if (activeEndDate) activeEndDate = new Date(activeEndDate)

	const [startDate, setStartDate] = useState(activeStartDate)
	const [endDate, setEndDate] = useState(activeEndDate || new Date())

	const activeSorts = useSelector(
		MillDuc.selectors.getDocumentListingActiveSorts
	)
	const paginationConfig = useSelector(
		MillDuc.selectors.getListingPaginationEntries
	)

	const { payload = {} } = location
	const { rootModule, submodule: subModule } = payload
	const subModuleType =
		subModule === 'sourced-batch' ? 'transforming' : 'transformed'

	const title = LabelsMap[subModule]
	const subModuleTitle = NAME_ALIASES[subModule]

	const breadCrumbs = useMemo(
		() => getBreadCrumbsList(title, subModuleTitle, t),
		[title, subModuleTitle, t]
	)

	/** Handlers */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}

		if (target === 'production') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.MILL$SUBROOT,
					{ rootModule }
				)
			)
		}
	}

	const handleChange = useCallback(
		(actionType, meta = {}) => {
			switch (actionType) {
				case 'initiate_sort': {
					initiateSort(
						dispatch,
						meta,
						MillDuc.creators.fetchDocumentListing,
						location,
						'sort'
					)
					break
				}
				case 'apply_search': {
					const { searchName, searchValue, docType, type } = meta

					const currentQuery = getIn(location, ['query']) || {}

					const targetQuery = merge(currentQuery, {
						q: searchName
							? `${searchName}->${searchValue}`
							: searchValue,
					})

					dispatch(
						MillDuc.creators.fetchDocumentListing(
							docType,
							[type],
							setIn(location, ['query'], targetQuery),
							true
						)
					)

					break
				}

				case 'remove_filter': {
					setActiveDateType('createdAt')
					setStartDate('')
					setEndDate(new Date())
					const { docType, type, filterType } = meta

					const currentQuery = getIn(location, ['query']) || {}

					const segments = Array.isArray(filterType)
						? filterType
						: [filterType]

					const targetFilters = omit(currentQuery, segments)

					dispatch(
						MillDuc.creators.fetchDocumentListing(
							docType,
							[type],
							setIn(location, ['query'], targetFilters),
							true
						)
					)

					break
				}

				case 'apply_filter': {
					const {
						docType,
						type,
						filterType, // the root filter key e.g status
						filterSegment, // optional when you need to clear multiple
						filterValue,
						prefix,
					} = meta

					const currentQuery = getIn(location, ['query']) || {}
					const filterQueries = omit(currentQuery, ['sort', 'q']) // exclude sort and search convention

					let targetQueries = getIn(filterQueries, [filterType]) || []
					// if there are multiple segments being invoked as part of one filter, map them one to one via index
					if (
						Array.isArray(filterSegment) &&
						Array.isArray(filterValue)
					) {
						targetQueries = filterSegment.reduce(
							(agg, _filterSegment, _index) => {
								const _filterValue = filterValue[_index]

								if (!_filterValue) return agg

								return getTargetFilterQueries(
									agg,
									_filterSegment,
									_filterValue,
									prefix
								)
							},
							[]
						)
					} else {
						targetQueries = getTargetFilterQueries(
							targetQueries,
							filterSegment,
							filterValue,
							prefix
						)
					}

					const targetFilters = merge(
						currentQuery,
						merge(filterQueries, { [filterType]: targetQueries })
					)

					dispatch(
						MillDuc.creators.fetchDocumentListing(
							docType,
							[type],
							setIn(location, ['query'], targetFilters),
							true
						)
					)

					break
				}

				case 'paginate': {
					const { direction } = meta || {}

					const {
						activeIndex = 0,
						currentCursor,
						nextCursor,
					} = paginationConfig

					const targetIndex = Math.max(
						direction === 'next'
							? activeIndex + 1
							: activeIndex - 1,
						0
					)

					let nextIndex = null

					if (nextCursor && activeIndex > targetIndex) {
						nextIndex = nextCursor
					} else if (currentCursor && activeIndex < targetIndex) {
						nextIndex = currentCursor
					}

					// retrigger the current route so new data is fetched again.
					dispatch(
						MainRouteDuc.creators.redirect(
							location.type,
							location.payload,
							{
								...location.query,
								activeIndex: targetIndex,
								limit: location.query.limit || 5,
								nextIndex,
							}
						)
					)

					break
				}

				case 'open_document': {
					if (meta.row) {
						let doc
						let documentReference
						if (meta.docType === 'transforming') {
							doc = 'sourced-batch'
							documentReference = meta.row.id
						} else {
							doc = 'produced-batch'
							documentReference = meta.row.meta.traceGroupID
						}
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.MILL$WDOCREFERENCE,
								{
									rootModule: doc,
									action: 'view',
									documentReference,
								}
							)
						)
					}
					break
				}
				case 'open_plot_output_workflow': {
					if (meta.row) {
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.MILL$WACTION,
								{
									rootModule: 'production-plot-output',
									action: 'create',
								},
								{
									documentReference: meta.row.id,
								}
							)
						)
					}
					break
				}

				default:
					break
			}
		},
		[dispatch, location, paginationConfig]
	)

	const activeLocale = useSelector(AppDuc.selectors.activeLocale)

	return (
		<Box padding={8} width="100%" height="100%">
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<>
				<Box row justifyContent="space-between" alignItems="baseline">
					<Title title={t(title)} icon={Documents} />

					<Box row>
						<Box alignItems="flex-start" width={125} margin="0 5px">
							<Label small>{t('tdmDocumentListing.from')}</Label>
							<div style={{ fontSize: theme.fontSize.s }}>
								<DatePicker
									name="startDate"
									placeholder={t('tdmDocumentListing.choose')}
									hideError
									value={startDate}
									maxDate={endDate}
									onChange={value => {
										setStartDate(value)
									}}
								/>
							</div>
						</Box>
						<Box alignItems="flex-start" width={125} margin="0 5px">
							<Label small>{t('tdmDocumentListing.to')}</Label>
							<div style={{ fontSize: theme.fontSize.s }}>
								<DatePicker
									name="toDate"
									placeholder={t('tdmDocumentListing.choose')}
									hideError
									toDate
									minDate={startDate}
									value={endDate}
									maxDate={endDate}
									onChange={value => {
										setEndDate(value)
									}}
								/>
							</div>
						</Box>
						<Spacer size={10} horizontal />

						<Box
							alignItems="flex-end"
							width={150}
							margin="0 5px"
							row
						>
							<Button
								label={t('productTrace.apply')}
								type="submit"
								extendStyles={{
									padding: '2px 20px',
								}}
								primary
								rounded
								disabled={!startDate}
								onClick={() => {
									handleChange('apply_filter', {
										docType: subModule,
										type: rootModule,
										filterSegment: [
											'end_date',
											'start_date',
										],
										filterType: activeDateType,
										filterValue: [
											new Date(startDate).toISOString(),
											new Date(endDate).toISOString(),
										],
									})
								}}
							/>
							<Spacer size={10} horizontal />

							<ButtonWithNoBorder
								label={t('productTrace.clear')}
								style={{
									fontSize: theme.fontSize.xl,
								}}
								onClick={() => {
									handleChange('remove_filter', {
										docType: subModule,
										type: rootModule,
										filterType: ['createdAt', 'updatedAt'],
									})
								}}
							/>
						</Box>
					</Box>
				</Box>

				<PaginatorBlock
					paginationConfig={paginationConfig}
					onChange={handleChange}
					documents={documents}
					startDesc={t('common.showing')}
					toDesc={t('common.to')}
					ofDesc={t('common.of')}
				/>
				<BoxShadowTable
					isLoading={isLoading}
					size="large"
					columnConfig={getColumnConfig({
						type: rootModule,
						docType: subModuleType,
						subModuleType: subModule,
						sortsMap: activeSorts,
						onChange: handleChange,
						orgDetails,
						activeLocale,
						allProducts,
						t,
					})}
					rowData={documents}
				/>
				<PaginatorBlock
					paginationConfig={paginationConfig}
					onChange={handleChange}
					documents={documents}
					startDesc={t('common.showing')}
					toDesc={t('common.to')}
					ofDesc={t('common.of')}
				/>
			</>
			<Box padding="25px 0" style={{ maxWidth: 150 }}>
				<Button
					label={t('common.back')}
					rounded
					customIcon={
						<ButtonIconWrapper lightBG>
							<Icon glyph={LeftArrowIcon} />
						</ButtonIconWrapper>
					}
					onClick={() =>
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.MILL$SUBROOT,
								{ rootModule }
							)
						)
					}
				/>
			</Box>
		</Box>
	)
}

export { DocumentListing }
