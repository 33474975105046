import Duck from 'extensible-duck'
import { setIn, getIn, merge } from 'timm'
import { createSelector } from 'reselect'

const initialState = {
	activeModule: '',
	loading: false,
	error: false,
	modules: {
		mainDashboard: {
			loading: false,
			activeTimeOffset: 5,
			activeSorts: {},
			activeFilters: {},
			activeDocuments: [],
			pagination: {},
		},
		listing: {},
		document: {
			loading: false,
			tickets: {},
		},
	},
}

export const WbTicketDuc = new Duck({
	namespace: 'tickets',
	store: 'global',
	types: [
		'SET_ACTIVE_MODULE',
		'FETCH_DASHBOARD_DOCUMENT',
		'FETCH_DASHBOARD_DOCUMENT_SUCCESS',
		'SET_DASHBOARD_LOADING_STATUS',
		'VIEW_DOCUMENT',
		'SET_DASHBOARD_VIEW_LOADING_STATUS',
		'SET_DASHBOARD_VIEW_SUCCESS',
		'SAVE_UPLOADED_TICKET',
		'FLUSH_DOCUMENT_STATE',
		'SAVE_PAGINATION_QUERIES',
		'SET_ACTIVE_FILTER_ENTRIES',
	],
	initialState,
	reducer: (state, action, duck) => {
		switch (action.type) {
			case duck.types.SET_ACTIVE_MODULE: {
				return setIn(state, ['activeModule'], action.module)
			}

			case duck.types.FETCH_DASHBOARD_DOCUMENT_SUCCESS: {
				const { isSkip, activeDocuments } = action
				const currentDocs =
					getIn(state, [
						'modules',
						'mainDashboard',
						'activeDocuments',
					]) || []

				return setIn(
					state,
					['modules', 'mainDashboard', 'activeDocuments'],
					isSkip
						? activeDocuments
						: currentDocs.concat(activeDocuments)
				)
			}

			case duck.types.SET_DASHBOARD_LOADING_STATUS: {
				const { status } = action

				return setIn(
					state,
					['modules', 'mainDashboard', 'loading'],
					status
				)
			}

			case duck.types.SET_DASHBOARD_VIEW_LOADING_STATUS: {
				const { status } = action

				return setIn(state, ['modules', 'document', 'loading'], status)
			}

			case duck.types.SET_DASHBOARD_VIEW_SUCCESS: {
				const { document } = action

				return setIn(state, ['modules', 'document', 'ticket'], document)
			}

			case duck.types.FLUSH_DOCUMENT_STATE: {
				return setIn(
					state,
					['modules', 'document', 'ticket'],
					initialState.modules.document.ticket
				)
			}

			case duck.types.SAVE_PAGINATION_QUERIES: {
				const { paginate } = action

				return setIn(
					state,
					['modules', 'mainDashboard', 'pagination'],
					paginate
				)
			}

			case duck.types.SET_ACTIVE_FILTER_ENTRIES: {
				const { filters } = action

				const root = ['modules', 'mainDashboard', 'activeFilters']

				const prevState = getIn(state, [...root]) || {}

				return setIn(state, [...root], merge(prevState, filters))
			}

			default:
				return state
		}
	},
	selectors: {
		auth: state => state.auth,
		location: state => state.location,
		documentState: state =>
			getIn(state, ['ticket', 'modules', 'mainDashboard']),
		documentViewState: state =>
			getIn(state, ['ticket', 'modules', 'document']),
		activeModule: state =>
			getIn(state, ['ticket', 'activeModule']) || 'Error',
		mainDashboardLoadingStatus: new Duck.Selector(selectors =>
			createSelector(
				selectors.documentState,
				document => getIn(document, ['loading']) || false
			)
		),
		fetchMainDashboardDocuments: new Duck.Selector(selectors =>
			createSelector(
				selectors.documentState,
				document => getIn(document, ['activeDocuments']) || []
			)
		),
		fetchMainDashboardPagination: new Duck.Selector(selectors =>
			createSelector(
				selectors.documentState,
				document => getIn(document, ['pagination']) || []
			)
		),
		getDocumentActiveTabs: new Duck.Selector(selectors =>
			createSelector(
				selectors.documentState,
				document => getIn(document, ['tabsConfig']) || {}
			)
		),
		getMainDashboardActiveSorts: new Duck.Selector(selectors =>
			createSelector(
				selectors.documentState,
				document => getIn(document, ['activeSorts']) || {}
			)
		),
		documentLoadingStatus: new Duck.Selector(selectors =>
			createSelector(
				selectors.documentViewState,
				document => getIn(document, ['loading']) || false
			)
		),
		documentInformation: new Duck.Selector(selectors =>
			createSelector(
				selectors.documentViewState,
				document => getIn(document, ['ticket']) || false
			)
		),
		getActiveFilters: state =>
			getIn(state, [
				'ticket',
				'modules',
				'mainDashboard',
				'activeFilters',
			]) || {},
	},
	creators: duck => ({
		setActiveModule: module => ({
			type: duck.types.SET_ACTIVE_MODULE,
			module,
		}),
		fetchDocuments: (isSkip, locationState, skipGlobalLoader = false) => ({
			type: duck.types.FETCH_DASHBOARD_DOCUMENT,
			isSkip,
			locationState,
			skipGlobalLoader,
		}),
		fetchDocumentSuccess: (isSkip, activeDocuments) => ({
			type: duck.types.FETCH_DASHBOARD_DOCUMENT_SUCCESS,
			isSkip,
			activeDocuments,
		}),
		setDocumentLoading: status => ({
			type: duck.types.SET_DASHBOARD_LOADING_STATUS,
			status,
		}),
		viewDocument: documentReference => ({
			type: duck.types.VIEW_DOCUMENT,
			documentReference,
		}),
		setDocumentViewLoading: status => ({
			type: duck.types.SET_DASHBOARD_VIEW_LOADING_STATUS,
			status,
		}),
		fetchDocumentViewSuccess: document => ({
			type: duck.types.SET_DASHBOARD_VIEW_SUCCESS,
			document,
		}),
		saveUploadTicket: ticket => ({
			type: duck.types.SAVE_UPLOADED_TICKET,
			ticket,
		}),
		flushDocumentState: () => ({
			type: duck.types.FLUSH_DOCUMENT_STATE,
		}),
		savePaginationQueries: paginate => ({
			type: duck.types.SAVE_PAGINATION_QUERIES,
			paginate,
		}),
		setActiveFilterEntries: filters => ({
			type: duck.types.SET_ACTIVE_FILTER_ENTRIES,
			filters,
		}),
	}),
})
