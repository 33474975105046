import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn } from 'timm'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AppDuc } from 'core-app/modules/App/duc'
import { AdminDuc } from 'core-app/modules/Admin/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { ShowMore } from 'core-app/modules/App/components/ShowMore'
import { YEAR } from 'core-app/modules/Admin/config'
import { Label } from 'ui-lib/components/Label'
import { CheckBox } from 'ui-lib/components/Checkbox'
import { SelectSearch } from 'ui-lib/components/Select'
import { Modal } from 'ui-lib/components/Modal'
import { FileUpload } from 'ui-lib/components/FileUpload'
import { TransparentBgSpinner } from 'ui-lib/components/Spinner/TransparentBgSpinner'
import { H2 } from 'ui-lib/components/Typography'
import { Button, ButtonWithNoBorder } from 'ui-lib/components/Button'
import { Input } from 'ui-lib/components/Input'
import { Table } from 'ui-lib/components/Table'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import theme from 'ui-lib/utils/base-theme'
import { getProductLCAUploadSampleFile } from 'core-app/config'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { FILE_HEADERS } from '../config'

const CarbonNumberForm = ({
	dispatch,
	pagination = {},
	initialValues = {},
	handleAddCarbonNumber = () => {},
	handleProductsListingWithPagination = () => {},
	products = [],
	fromOnBoard = false,
}) => {
	const { t } = useTranslation()
	const [reportStatus, setReportStatus] = useState(!!initialValues.reportYear)
	const actor = useSelector(AuthDuc.selectors.getActor)[0] || []
	const [pomeStatus, setPOMEStatus] = useState(
		initialValues.biogasCaptureFlag
	)
	const [thankYouMessage, setThankYouMessage] = useState(false)
	const [confirmationModal, setConfirmationModal] = useState(false)
	const [warningModal, setWarningModal] = useState(false)
	const [lcvValues, setLcvValues] = useState(() => {
		// Initialize lcvValues directly with products if available
		const initialLcvValues = products?.map(product => ({
			product: {
				id: product?.product?.id,
			},
			meta: {
				lcv: product?.meta?.lcv || 0,
			}, // Assuming default value is 0 if not present
		}))

		return initialLcvValues
	})
	const [currentFormData, setCurrentFormData] = useState({})
	const [currentExcelFile, setCurrentExcelFile] = useState({})
	const [fileFormatCheck, setFileFormatCheck] = useState(false)
	const orgdetails = useSelector(AuthDuc.selectors.getCurrentOrgDetails)

	const validationSchema = () => {
		const shape = yup.object().shape({
			baseYear: yup.number(),
			scope1: yup.number().min(0),
			scope2: yup.number().min(0),
			files: yup.array(),
			auditedBy: yup.string(),
			line1: yup.string(),
			line2: yup.string(),
			reportYear: yup.number(),
			productLCVFile: yup.array(),
			biogasCaptureDocumentProof: yup.array(),
		})

		if (reportStatus) {
			shape.fields.baseYear = shape.fields.baseYear.required(
				t('common.fieldRequired')
			)
			shape.fields.reportYear = shape.fields.reportYear.required(
				t('common.fieldRequired')
			)
			shape.fields.files = shape.fields.files.required(
				t('common.fieldRequired')
			)
		}

		if (pomeStatus) {
			shape.fields.biogasCaptureDocumentProof = shape.fields.biogasCaptureDocumentProof.required(
				t('common.fieldRequired')
			)
		}

		return shape
	}

	const {
		handleSubmit,
		values,
		handleBlur,
		setFieldValue,
		touched,
		errors,
	} = useFormik({
		initialValues,
		enableReinitialize: true,
		validationSchema,
		onSubmit: (_values, { setSubmitting }) => {
			const updatedProducts = lcvValues.filter(
				item => item.meta.lcv !== 0
			)
			if (currentExcelFile && currentExcelFile?.meta?.name) {
				// api needs all the data in formData

				currentFormData.append('initiatorName', orgdetails?.name || '')
				currentFormData.append(
					'initiatorEmail',
					orgdetails?.primaryContact?.email || ''
				)
				currentFormData.append('fileType', 'xlsx')
				currentFormData.append('email', false)
				currentFormData.append('uploadType', 'productLCV')
			}

			const payload = {
				score: {
					scope1: _values.scope1,
					scope2: _values.scope2,
				},
				files: reportStatus ? _values.files : [],
				meta: {
					auditedBy: reportStatus ? _values.auditedBy : '',
					auditorAddress: reportStatus
						? {
								line1: _values.line1,
								line2: _values.line2,
						  }
						: null,
					baseYear: _values.baseYear,
					reportYear: reportStatus ? _values.reportYear : '',
				},
			}

			if (actor.includes('palmoil_mill')) {
				payload.meta.biogasCaptureFlag = pomeStatus
				if (
					pomeStatus &&
					values?.biogasCaptureDocumentProof?.length > 0
				) {
					payload.meta.biogasCaptureDocumentProof =
						values.biogasCaptureDocumentProof
				}
			}

			handleAddCarbonNumber(
				payload,
				updatedProducts,
				{
					setSubmitting,
				},
				currentFormData,
				currentExcelFile && currentExcelFile?.meta?.name
			)
		},
	})

	const handleLcvChange = (productId, lcv) => {
		// Update LCV values state
		setLcvValues(prevLcvValues => {
			const updatedLcvValues = prevLcvValues.map(item => {
				if (item.product.id === productId) {
					return { ...item, meta: { lcv } }
				}

				return item
			})

			return updatedLcvValues
		})
	}

	const columnConfig = () => {
		return [
			{
				property: 'product',
				header: (
					<Box style={{ cursor: 'pointer' }}>
						<Label bold style={{ color: '#4B4552', fontSize: 16 }}>
							{t('assetsHome.product')}
						</Label>
					</Box>
				),
				render: datum => {
					const product = getIn(datum, ['product']) || {}
					const productName = getIn(product, ['name'])

					return <Label as="span">{`${productName}`}</Label>
				},
				size: '60%',
			},
			{
				property: 'lca',
				header: (
					<Box style={{ cursor: 'pointer' }}>
						<Label bold style={{ color: '#4B4552', fontSize: 16 }}>
							LCA (kgeqCO2/Ton)
						</Label>
					</Box>
				),
				render: datum => {
					const productId = getIn(datum, ['product', 'id'])

					return (
						<Input
							value={
								lcvValues?.find(
									item => item?.product?.id === productId
								)?.meta.lcv || ''
							}
							name="lcv"
							type="number"
							onChange={event =>
								handleLcvChange(
									productId,
									event.target.valueAsNumber
								)
							}
						/>
					)
				},
			},
		]
	}

	const handleProductsPagination = () => {
		const paginationParams = {
			startID: pagination.nextIndex,
		}

		handleProductsListingWithPagination(paginationParams)
	}

	const onSingleApiUploadChange = excelData => {
		const { jsonFromExcel, formData, currentFile } = excelData
		const currentHeader = (jsonFromExcel.length && jsonFromExcel[1]) || []
		let isFileHeadersCorrect = true
		setFileFormatCheck(false)

		if (currentHeader?.length > 0 && FILE_HEADERS.productLCV?.length > 0) {
			// check for excel headers
			FILE_HEADERS.productLCV.forEach((item, index) => {
				if (FILE_HEADERS.productLCV[index] !== currentHeader[index]) {
					isFileHeadersCorrect = false
				}
			})
		} else {
			setCurrentFormData({})
			setCurrentExcelFile({})
			isFileHeadersCorrect = false
		}

		if (isFileHeadersCorrect) {
			setCurrentFormData(formData)
			setCurrentExcelFile(currentFile)
			setFileFormatCheck(false)
		} else {
			setCurrentFormData({})
			setCurrentExcelFile({})
			if (currentHeader?.length > 0) {
				setFileFormatCheck(true)
			}
		}
	}

	useEffect(() => {
		// Initialize the state with existing product LCV values
		const initialLcvValues = products.map(product => ({
			product: {
				id: product?.product?.id,
			},
			meta: {
				lcv: product.meta.lcv || 0,
			}, // Assuming default value is 0 if not present
		}))
		setLcvValues(initialLcvValues)
	}, [products])

	useEffect(() => {
		setThankYouMessage(false)
	}, [])

	const disableCTA =
		!values.baseYear ||
		!values.scope1 ||
		!values.scope2 ||
		fileFormatCheck ||
		(reportStatus &&
			(!values.baseYear || !values.reportYear || !values.files.length)) ||
		(pomeStatus && !values?.biogasCaptureDocumentProof?.length > 0)

	return (
		<Box
			style={{
				width: fromOnBoard ? '100vw' : 'auto',
				height: fromOnBoard ? '100vh' : 'auto',
				overflow: 'hidden',
				background: fromOnBoard ? '#1D285D' : theme.color.white,
				paddingBottom: fromOnBoard ? '20px' : '0px',
			}}
			center
		>
			{fromOnBoard ? (
				<>
					<H2
						style={{
							fontSize: 40,
							marginTop: 50,
							lineHeight: '52px',
							fontWeight: 'bold',
							color: theme.color.white,
						}}
					>
						{t('onboarding.updateCNHere')}
					</H2>
					<Spacer size={15} />
				</>
			) : (
				<Box
					row
					alignItems="baseline"
					style={{
						backgroundColor: theme.color.white,
					}}
				>
					<Label
						large
						style={{
							display: 'flex',
							fontWeight: 'bold',
							fontSize: '18px',
						}}
					>
						<span style={{ width: 300 }}>
							{t('onboarding.carbonNumberUpdateTitle')}
						</span>
					</Label>
				</Box>
			)}
			<Box
				style={{
					width: '979px',
					height: 'fit-content',
					maxHeight: '90%',
					background: theme.color.white,
					borderRadius: '10px',
					boxShadow: '0px 3px 6px #00000029',
					paddingBottom: fromOnBoard ? '30px' : '0px',
				}}
				padding={fromOnBoard ? 50 : 30}
			>
				<form onSubmit={handleSubmit}>
					<Box
						style={{
							position: 'relative',
							height: fromOnBoard ? '480px' : '400px',
						}}
					>
						<Box
							style={{
								marginBottom: '50px',
								height: fromOnBoard ? '385px' : '290px',
								overflow: 'scroll',
							}}
							id="carbonDataCard"
						>
							<Box
								row
								justifyContent="flex-start"
								alignItems="baseline"
								margin="20px 0px 0px"
							>
								<div style={{ display: 'flex' }}>
									<Label required style={{ width: 230 }}>
										<span>
											{t('onboarding.yearConsideredBase')}
										</span>
									</Label>
									<span>:</span>
								</div>
								<Spacer size={40} horizontal />
								<SelectSearch
									placeholder={t('createAsset.choose')}
									value={values.baseYear}
									name="baseYear"
									options={YEAR}
									returnOnlyValue
									labelKey="label"
									valueKey="name"
									defaultLabel={t(
										'common.selectDefaultLabel'
									)}
									onBlur={handleBlur}
									onChange={({ name }) => {
										setFieldValue('baseYear', name)
									}}
									style={{
										width: 246,
										border: `1px solid ${theme.color.blue5}`,
										borderRadius: '4px',
									}}
								/>
							</Box>
							<Box
								row
								justifyContent="flex-start"
								alignItems="baseline"
								margin="4px 0px 0px"
							>
								<div style={{ display: 'flex' }}>
									<Label required style={{ width: 230 }}>
										<span>
											{`${t('onboarding.scope1')} ${t(
												'onboarding.carbon'
											)}`}
										</span>
									</Label>
									<span>:</span>
								</div>

								<Spacer size={40} horizontal />
								<Input
									value={values.scope1}
									name="scope1"
									type="number"
									onBlur={handleBlur}
									onChange={event =>
										setFieldValue(
											'scope1',
											event.target.valueAsNumber
										)
									}
									extendStyles={{
										width: 246,
									}}
								/>
								<Spacer size={20} horizontal />
								<Label>kg.eqCO2</Label>
							</Box>
							<Box
								row
								justifyContent="flex-start"
								alignItems="baseline"
								margin="4px 0px 0px"
							>
								<div style={{ display: 'flex' }}>
									<Label required style={{ width: 230 }}>
										<span>
											{`${t('onboarding.scope2')} ${t(
												'onboarding.carbon'
											)}`}
										</span>
									</Label>
									<span>:</span>
								</div>

								<Spacer size={40} horizontal />
								<Input
									value={values.scope2}
									name="scope2"
									type="number"
									onBlur={handleBlur}
									onChange={event =>
										setFieldValue(
											'scope2',
											event.target.valueAsNumber
										)
									}
									extendStyles={{
										width: 246,
									}}
								/>
								<Spacer size={20} horizontal />
								<Label>kg.eqCO2</Label>
							</Box>
							<Box
								row
								justifyContent="flex-start"
								alignItems="center"
								margin="4px 0px 0px"
							>
								<Label style={{ display: 'flex' }}>
									<span style={{ width: 230 }}>
										{t('onboarding.ESGReportAvailable')}
									</span>
									<span>:</span>
								</Label>
								<Spacer size={40} horizontal />
								<CheckBox
									key={reportStatus}
									hideError
									name="reportStatus"
									checked={reportStatus}
									returnKeyValue
									onChange={data => {
										setReportStatus(data.reportStatus)
									}}
									showToggle
								/>
							</Box>
							{reportStatus && (
								<>
									<Box
										row
										justifyContent="flex-start"
										alignItems="baseline"
										margin="28px 0px 0px"
									>
										<div style={{ display: 'flex' }}>
											<Label
												required={reportStatus}
												style={{ width: 230 }}
											>
												<span>
													{t(
														'onboarding.uploadESGReport'
													)}
												</span>
											</Label>
											<span>:</span>
										</div>
										<Spacer size={40} horizontal />
										<FileUpload
											type="public"
											name="carbonNumber"
											size="5242880"
											outline
											width={190}
											disableUploadForOne
											disabled={!reportStatus}
											hideButtonOneUpload
											supportedFileType=".pdf"
											modalTriggerBtnLabel="Upload"
											currentUploads={values.files}
											onDocumentChange={document => {
												setFieldValue('files', document)
											}}
											onUploadFailure={({ message }) => {
												dispatch(
													AppDuc.creators.showToast({
														messageType: 'error',
														message,
													})
												)
											}}
										/>
									</Box>
									<Box
										row
										justifyContent="flex-start"
										alignItems="baseline"
									>
										<Label style={{ display: 'flex' }}>
											<span style={{ width: 230 }}>
												{t(
													'viewCompanyInfo.verifiedBy'
												)}
											</span>
											<span>:</span>
										</Label>
										<Spacer size={40} horizontal />
										<Input
											value={values.auditedBy}
											name="auditedBy"
											type="text"
											error={
												touched.auditedBy &&
												errors.auditedBy
											}
											onBlur={handleBlur}
											onChange={event =>
												setFieldValue(
													'auditedBy',
													event.target.value
												)
											}
											extendStyles={{
												width: 246,
											}}
										/>
									</Box>
									<Box
										row
										justifyContent="flex-start"
										alignItems="baseline"
										width="65%"
									>
										<Spacer size={274} horizontal />
										<Input
											value={values.line1}
											name="line1"
											type="text"
											placeholder="Address-1"
											error={
												touched.line1 && errors.line1
											}
											onBlur={handleBlur}
											onChange={event =>
												setFieldValue(
													'line1',
													event.target.value
												)
											}
											extendStyles={{
												width: 246,
											}}
										/>
									</Box>
									<Box
										row
										justifyContent="flex-start"
										alignItems="baseline"
										width="65%"
									>
										<Spacer size={274} horizontal />
										<Input
											value={values.line2}
											name="line2"
											type="text"
											placeholder="Address-2"
											error={
												touched.line2 && errors.line2
											}
											onBlur={handleBlur}
											onChange={event =>
												setFieldValue(
													'line2',
													event.target.value
												)
											}
											extendStyles={{
												width: 246,
											}}
										/>
									</Box>
									<Box
										row
										justifyContent="flex-start"
										alignItems="baseline"
										margin="20px 0px 0px"
									>
										<div style={{ display: 'flex' }}>
											<Label
												required={reportStatus}
												style={{ width: 230 }}
											>
												<span>
													{t(
														'onboarding.yearOfReport'
													)}
												</span>
											</Label>
											<span>:</span>
										</div>
										<Spacer size={40} horizontal />
										<SelectSearch
											placeholder={t(
												'createAsset.choose'
											)}
											value={values.reportYear}
											name="type"
											options={YEAR}
											onChange={({ name }) => {
												setFieldValue(
													'reportYear',
													name
												)
											}}
											returnOnlyValue
											labelKey="label"
											valueKey="name"
											defaultLabel={t(
												'common.selectDefaultLabel'
											)}
											onBlur={handleBlur}
											style={{
												width: 246,
												border: `1px solid ${theme.color.blue5}`,
												borderRadius: '4px',
											}}
										/>
									</Box>
								</>
							)}
							{actor.includes('palmoil_mill') && (
								<Box
									row
									justifyContent="flex-start"
									alignItems="center"
									margin="40px 0px 0px"
								>
									<Label style={{ display: 'flex' }}>
										<span style={{ width: 230 }}>
											POME gas extraction installed:
										</span>
										<span>:</span>
									</Label>
									<Spacer size={40} horizontal />
									<CheckBox
										key={pomeStatus}
										hideError
										name="pomeStatus"
										checked={pomeStatus}
										returnKeyValue
										onChange={data => {
											if (data.pomeStatus === true) {
												setPOMEStatus(true)
												setConfirmationModal(true)
											} else if (
												data.pomeStatus === false
											) {
												setWarningModal(true)
												setPOMEStatus(false)
											}
										}}
										showToggle
									/>
								</Box>
							)}
							{/* Confirmation Modal */}
							<Modal
								forceCloseviaButton
								size="large"
								show={confirmationModal}
								heading="Confirmation"
								body={
									<div>
										To prove that you have gas extraction
										plants installed, you will have to
										upload proof of inspection or auditing
										by relevant agencies. Are you sure to
										proceed?
									</div>
								}
								closelabel={t('common.cancel')}
								confirmlabel={t('common.yes')}
								onClose={() => {
									setPOMEStatus(false)
									setConfirmationModal(false)
								}}
								onConfirm={() => {
									setConfirmationModal(false)
									setPOMEStatus(true)
								}}
							/>
							{/* Warning Modal */}
							<Modal
								forceCloseviaButton
								size="large"
								heading="Warning"
								body={
									<div>
										This option will increase the carbon
										values of your product. Are you sure you
										want to proceed?
									</div>
								}
								closelabel={t('common.cancel')}
								confirmlabel={t('common.yes')}
								show={warningModal}
								onClose={() => {
									setPOMEStatus(true)
									setWarningModal(false)
								}}
								onConfirm={() => {
									setWarningModal(false)
									setPOMEStatus(false)
								}}
							/>
							{/* ThankYou Modal */}
							<Modal
								size="large"
								show={thankYouMessage}
								heading="Thank You"
								body={
									<div>
										Thank you for uploading the proof. Dibiz
										will verify and approve this.
									</div>
								}
								confirmlabel={t('common.ok')}
								onConfirm={() => setThankYouMessage(false)}
							/>
							<Spacer
								size={
									pomeStatus && !confirmationModal ? 20 : 50
								}
							/>
							{pomeStatus && !confirmationModal && (
								<Box
									row
									justifyContent="flex-start"
									alignItems="baseline"
									margin="28px 0px 0px"
								>
									<div style={{ display: 'flex' }}>
										<Label
											required={reportStatus}
											style={{ width: 230 }}
										>
											<span>
												Upload proof of gas extraction
												plant
											</span>
										</Label>
										<span>:</span>
									</div>
									<Spacer size={40} horizontal />
									<FileUpload
										type="private"
										name="biogasCaptureDocumentProof"
										size="5242880"
										outline
										width={190}
										disableUploadForOne
										hideButtonOneUpload
										supportedFileType=".pdf"
										modalTriggerBtnLabel="Upload"
										currentUploads={
											values?.biogasCaptureDocumentProof
										}
										onDocumentChange={document => {
											setFieldValue(
												'biogasCaptureDocumentProof',
												document
											)
											if (document.length > 0)
												setThankYouMessage(true)
										}}
										onUploadFailure={({ message }) => {
											dispatch(
												AppDuc.creators.showToast({
													messageType: 'error',
													message,
												})
											)
										}}
									/>
								</Box>
							)}
							<Label
								large
								style={{
									display: 'flex',
									fontWeight: 'bold',
									fontSize: '20px',
								}}
							>
								<span style={{ width: 230 }}>
									{t('onboarding.updateProductLCA')}
								</span>
								<span>:</span>
							</Label>
							<Box style={{ alignItems: 'center' }}>
								<Box
									row
									alignItems="center"
									margin="15px 0px 0px"
									style={{ paddingLeft: '20px' }}
								>
									<Label>
										{t('onboarding.uploadLCAText')}
									</Label>
									<Spacer size={20} horizontal />
									<Box
										width={300}
										margin="0px 40px"
										style={{
											paddingTop: 20,
											display: 'flex',
											borderRadius: 6,
											paddingLeft: 30,
											paddingBottom: 12,
											border: `1px solid ${theme.color.grey4}`,
										}}
									>
										<div
											style={{
												display: 'flex',
											}}
										>
											<Box
												style={{
													padding: '1px',
													color: theme.color.blue8,
													backgroundColor:
														theme.color.white,
												}}
											>
												<FileUpload
													modalTriggerBtnLabel={t(
														'common.upload'
													)}
													singleUploadApi
													type="private"
													name="productLCVFile"
													size="2097152"
													supportedFileType=".xlsx"
													hideButtonOneUpload
													onUploadFailure={({
														message,
													}) => {
														dispatch(
															AppDuc.creators.showToast(
																{
																	messageType:
																		'error',
																	message,
																}
															)
														)
													}}
													onSingleApiUploadChange={excelData => {
														onSingleApiUploadChange(
															excelData
														)
													}}
													width="auto"
													hasFormatError={
														fileFormatCheck
													}
													fileFormatErrorMsg={t(
														'common.fileFormatErrorMsg'
													)}
												/>
											</Box>
										</div>
										<div
											style={{
												display: 'flex',
												paddingRight: '20px',
												paddingBottom: '10px',
												justifyContent: 'start',
											}}
										>
											<a
												href={`${getProductLCAUploadSampleFile()}`}
												target="_blank"
												rel="noopener noreferrer"
												style={{
													textDecoration: 'underline',
													color: theme.color.blue1,
												}}
											>
												{t(
													'empMgmtHome.clickHereToDownloadSampleFile'
												)}
											</a>
										</div>
									</Box>
								</Box>
							</Box>
							<Box
								style={{
									marginTop: '20px',
									width: '100%',
									display: 'flex',
									textAlign: 'center',
									justifyContent: 'center',
									color: theme.color.accent,
								}}
							>
								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									<div
										style={{
											border: '1px dashed',
											width: '70px',
											height: '1px',
											marginTop: '20px',
										}}
									/>
									<div
										style={{
											margin: '10px',
										}}
									>
										{t('empMgmtHome.or')}
									</div>
									<div
										style={{
											border: '1px dashed',
											width: '70px',
											height: '1px',
											marginTop: '20px',
										}}
									/>
								</div>
							</Box>
							{products?.length > 0 ? (
								<>
									<Spacer size={20} />
									<Box style={{ alignItems: 'center' }}>
										<Box
											width="80%"
											margin="0px 20px 0px 0px"
										>
											<Table
												columnConfig={columnConfig()}
												rowData={products || []}
												size="small"
												noRoundedBorder
											/>
											<ShowMore
												documents={products}
												total={pagination.total}
												handleChange={
													handleProductsPagination
												}
												type="products_pagination"
											/>
										</Box>
									</Box>
								</>
							) : (
								<TransparentBgSpinner />
							)}
						</Box>
						<Box
							style={{
								width: '900px',
								bottom: '0px',
								position: 'absolute',
								padding: '30px 0px 0px',
								alignItems: 'center',
								backgroundColor: theme.color.white,
								borderTop: `1px solid ${theme.color.grey3}`,
							}}
						>
							<Box row>
								<Button
									disabled={disableCTA}
									type="submit"
									primary
									label={t('onboarding.updateCN')}
								/>
								{(fromOnBoard || initialValues.baseYear) && (
									<ButtonWithNoBorder
										label={t('common.skip')}
										onClick={() => {
											if (fromOnBoard) {
												dispatch(
													MainRouteDuc.creators.switchPage(
														MainRouteDuc.types
															.ONBOARD$ACTION,
														{
															action: 'all-set',
														}
													)
												)
											} else {
												dispatch(
													AdminDuc.creators.setCarbonNumberEditFlag(
														false
													)
												)
											}
										}}
										extendStyles={{
											marginLeft: 10,
										}}
									/>
								)}
							</Box>
						</Box>
					</Box>
				</form>
			</Box>
		</Box>
	)
}
export { CarbonNumberForm }
