import Theme from 'ui-lib/utils/base-theme'
import * as yup from 'yup'

export const SortableKeys = ['createdAt', 'updatedAt']
export const TABLE_ALIAS = {
	createdAt: 'tdmColumnHeaders.receivedDate',
	supplyChainModel: 'tdmColumnHeaders.supplyChainModel',
	generatedDate: 'tdmColumnHeaders.generatedDate',
	partner: 'tdmColumnHeaders.partner',
	status: 'binListing.status',
	updatedAt: 'tdmColumnHeaders.updatedAt',
	docType: 'tdmColumnHeaders.docType',
	partnerAddress: 'tdmColumnHeaders.partnerAddress',
	myAddress: 'Receiving Address',
	productCode: 'tdmColumnHeaders.productCode',
	productName: 'tdmColumnHeaders.productName',
	quantity: 'tdmColumnHeaders.quantity',
	defaultUOM: 'tdmColumnHeaders.uom',
	uom: 'tdmColumnHeaders.quantity',
	count: 'workListing.ffbCount',
	unitPrice: 'tdmColumnHeaders.unitPrice',
	tax: 'tdmColumnHeaders.tax',
	metricUnitPrice: 'tdmColumnHeaders.unitPrice',
	totalPrice: 'tdmColumnHeaders.totalPrice',
	dateSent: 'tdmColumnHeaders.dateSent',
	dateReceived: 'tdmColumnHeaders.dateReceived',
	sellerName: 'tdmColumnHeaders.sellerName',
	buyerName: 'tdmColumnHeaders.buyerName',
	broker: 'tdmColumnHeaders.broker',
	contractType: 'tdmColumnHeaders.contractType',
	product: 'tdmColumnHeaders.product',
	quantityWithUOM: 'tdmColumnHeaders.quantityWithUOM',
	deliveryPeriod: 'tdmColumnHeaders.deliveryPeriod',
	statusLabel: 'tdmColumnHeaders.statusLabel',
}

export const STATUS_COLOR = {
	draft: Theme.color.grey7,
	submitted: Theme.color.blue4,
	received: Theme.color.blue4,
	acknowledged: Theme.color.purple,
	accepted: Theme.color.purple,
	rejected: Theme.color.error,
	delayed: Theme.color.warn,
	intransit: Theme.color.info,
	hold: Theme.color.warn,
	delivered: Theme.color.success,
	complete: Theme.color.primary,
	partial: Theme.color.primary,
	'receiver-accepted': Theme.color.success,
	'receiver-rejected': Theme.color.error,
	pending: Theme.color.warn,
}

export const RECEIVER_ACTION_LABELS = {
	rejected: 'entityActionStatus.rejected',
	hold: 'entityActionStatus.hold',
	delivered: 'entityActionStatus.delivered',
	acknowledged: 'entityActionStatus.acknowledged',
}

export const SENDER_ACTION_LABELS = {
	accepted: 'entityActionStatus.accepted',
	rejected: 'entityActionStatus.rejected',
	hold: 'entityActionStatus.hold',
	intransit: 'entityActionStatus.intransit',
	complete: 'entityActionStatus.complete',
	acknowledged: 'entityActionStatus.acknowledged',
}

export const RECEIVER_STATUS_ALIASES = {
	submitted: 'entityStatusLabel.received',
	received: 'entityStatusLabel.received',
	acknowledged: 'entityStatusLabel.acknowledged',
	accepted: 'entityStatusLabel.accepted',
	rejected: 'entityStatusLabel.rejected',
	delayed: 'entityStatusLabel.delayed',
	intransit: 'entityStatusLabel.intransit',
	hold: 'entityStatusLabel.hold',
	delivered: 'entityStatusLabel.delivered',
	complete: 'entityStatusLabel.complete',
	partial: 'entityStatusLabel.partial',
	pending: 'partnerStatusLabel.pending',
	'receiver-accepted': 'partnerStatusLabel.receiver-accepted',
	'receiver-rejected': 'partnerStatusLabel.receiver-rejected',
	deactivated: 'partnerStatusLabel.deactivated',
	blacklisted: 'partnerStatusLabel.blacklisted',
}

export const SENDER_STATUS_ALIASES = {
	submitted: 'entityStatusLabel.submitted',
	received: 'entityStatusLabel.received',
	acknowledged: 'entityStatusLabel.acknowledged',
	accepted: 'entityStatusLabel.accepted',
	rejected: 'entityStatusLabel.rejected',
	delayed: 'entityStatusLabel.delayed',
	intransit: 'entityStatusLabel.intransit',
	hold: 'entityStatusLabel.hold',
	delivered: 'entityStatusLabel.delivered',
	complete: 'entityStatusLabel.complete',
	partial: 'entityStatusLabel.partial',
}

export const DASHBOARD_STATS_ALIASES = {
	submitted: 'Submitted',
	received: 'Received',
	acknowledged: 'Acknowledged',
	accepted: 'Accepted',
	rejected: 'Rejected',
	delayed: 'Delayed',
	intransit: 'Intransit',
	hold: 'On Hold',
	delivered: 'Delivered',
	complete: 'Complete',
	partial: 'Partial',
}

export const ALL_DOC_STATUSES = [
	'submitted',
	'received',
	'acknowledged',
	'accepted',
	'rejected',
	'delayed',
	'intransit',
	'hold',
	'delivered',
	'complete',
	'partial',
	'goods-dispatched',
]

export const STATUS_ALIAS = {
	hold: 'On Hold',
}

export const COLUMN_ALIASES = {
	createdAt: 'Received Date',
	partner: 'Partner Name',
	amount: 'Amount',
	number: 'Reference Number',
	state: 'Status',
	actionToTake: 'Action',
	updatedAt: 'Action Date',
}

export const INITIAL_TYPES = [
	// 'excel-uploads', Removing the tab
	'purchase-order',
	'invoice',
	'delivery-order',
	'contracts',
]

export const NAME_ALIASES = {
	'purchase-order': 'tdmColumnHeaders.purchase-order',
	invoice: 'tdmColumnHeaders.invoice',
	draft: 'tdmColumnHeaders.draft',
	'delivery-order': 'tdmColumnHeaders.delivery-order',
	'incoming-delivery-order': 'tdmColumnHeaders.incoming-delivery-order',
	'work-entry': 'tdmColumnHeaders.workEntry',
	'weighbridge-slip': 'tdmColumnHeaders.weighBridgeSlip',
	weighbridge: 'tdmViewDocument.weighbridge',
}

export const STATS_TYPE_ALIAS = {
	deliveryOrders: 'delivery-order',
	invoices: 'invoice',
	purchaseOrders: 'purchase-order',
}

export const ALL_MODULES = ['incoming', 'outgoing']

export const LabelsMap = {
	incoming: 'tdmSubDashboard.incoming',
	outgoing: 'tdmSubDashboard.outgoing',
}

export const PRODUCT_TRACE_FILTERS_CONFIG = [
	{
		name: 'monthly',
		label: 'Monthly',
	},
	{
		name: 'quarterly',
		label: 'Quarterly',
	},
	{
		name: 'half yearly',
		label: 'Half Yearly',
	},
	{
		name: 'yearly',
		label: 'Yearly',
	},
]

export const PRODUCT_TRACE_TABLE_ALIAS = {
	createdAt: 'Date',
	partner: 'Supplier',
	docNumber: 'Document Number',
	product: 'Product',
	quantity: 'Quantity (Kg)',
}

export const AUDIT_REPORTS_FILTERS_CONFIG = [
	{
		name: 'monthly',
		label: 'Monthly',
	},
	{
		name: 'quarterly',
		label: 'Quarterly',
	},
	{
		name: 'half yearly',
		label: 'Half Yearly',
	},
	{
		name: 'yearly',
		label: 'Yearly',
	},
]

export const AUDIT_REPORTS_TABLE_ALIAS = {
	createdAt: 'Date',
	partner: 'Partner',
	quantityTransacted: 'Quantity Transacted',
	uom: 'UOM',
}

export const excludedReadableSections = [
	'additionalInfo',
	'remarks',
	'type',
	'id',
	'updatedAt',
	'docStatus',
	'currentDocHistoryReference',
	'initiatingPartyID',
	'initiatingUserID',
	'receivingUserID',
	'receivingPartyID',
	'authorization',
	'createdAt',
]

export const DRAFT_TAB_CONFIG = {
	code: 'draft',
	title: 'tdmTabConfig.draft',
}

export const TABS_CONFIG = [
	// {
	// 	code: 'excel-uploads',
	// 	title: 'tdmTabConfig.excel-uploads',
	// },  Removing the tab
	{
		code: 'purchase-order',
		title: 'tdmTabConfig.purchase-orders',
	},
	{
		code: 'invoice',
		title: 'tdmTabConfig.invoices',
	},
	{
		code: 'delivery-order',
		title: 'tdmTabConfig.delivery-orders',
	},
	{
		code: 'contracts',
		title: 'tdmTabConfig.contracts',
	},
]

export const initialState = {
	activeModule: 'Error',
	loading: false,
	error: false,
	receiverTraceIDs: [],
	rules: {
		activeModules: ['incoming', 'outgoing'],
		allowedTypes: [
			// 'excel-uploads',  Removing the tab
			'purchase-order',
			'invoice',
			'delivery-order',
			'contracts',
		],
	},
	products: {
		loading: false,
		list: [],
		details: {},
	},
	storageTankLoadingStatus: false,
	productsBasedonSupplyChainModel: false,
	isSupplyChainModelEnforced: true,
	modules: {
		mainDashboard: {
			loading: {
				incoming: [],
				outgoing: [],
			},
			activeTimeOffset: 45, // 5days
			allTypes: INITIAL_TYPES,
			activeTypes: [],
			activeTab: {
				incoming: '',
				outgoing: '',
			},
			activeSorts: {
				incoming: {},
				outgoing: {},
			},
			activeDocuments: {
				incoming: {},
				outgoing: {},
			},
		},
		subDashboard: {
			status: {
				incoming: {},
				outgoing: {},
			},
		},
		listing: {
			status: {
				incoming: {},
				outgoing: {},
			},
			loading: false,
			pagination: {
				activeIndex: 0, // total, pageCount
				limit: 0,
				total: 0,
				nextCursor: '',
			},
			activeTab: 'any',
			activeDocuments: [],
			activeSorts: {},
			activeFilters: {},
		},
		document: {
			loading: false,
			hasError: false,
			isSaving: false,
			activeSections: [],
			readOnlySections: [],
			activePartner: {},
			activeRecord: {
				meta: {},
			},
			activeAssociatedDoc: {},
			parentDocRef: {},
			participantingUsers: {},
			associatedDocRemarks: {},
			documentTrace: [],
			attachments: [],
			backwardTrace: {},
			activeTab: 'original',
		},
		reports: {
			loading: false,
			activeDocuments: [],
			activeSorts: {},
			pagination: {
				activeIndex: 0, // total, pageCount
				limit: 0,
				total: 0,
				nextCursor: '',
			},
			activeFilters: {},
			certificateDetails: [],
		},
		generalReports: {
			ffbDealer: {
				details: {},
			},
		},
	},
}

export const plantationDocSources = ['plantation', 'storage']

export const getSchemaDefinition = (t, type) => {
	const Schema = {
		activePartner: yup.string().required(),
		meta: yup.object().shape({
			entityReference: yup.string(),
			issueDate: yup.date().required(t('validation.issuingDateRequired')),
			authorization: yup.object().shape({
				reportedBy: yup
					.string()
					.required(t('validation.reportedByNameRequired')),
				reportedByRole: yup
					.string()
					.required(t('validation.reporterRoleRequired')),
				authorisedSignatory: yup
					.string()
					.required(t('validation.authorisedSignatoryReq')),
			}),
			expectedDeliveryDate: yup
				.date()
				.required(t('validation.expectedDateRequired')),
			...(type === 'delivery-order' && {
				vehicleNumber: yup.string(),
				driverName: yup
					.string()
					.matches(/[a-zA-Z]/, t('validation.driverNameCheck')),
			}),
			...((type === 'purchase-order' || type === 'invoice') && {
				supplyChainModel: yup.string().required(),
			}),
		}),
		products: yup
			.array()
			.of(
				yup.object().shape({
					id: yup
						.string()
						.required(t('validation.addAtleast1Product')),
				})
			)
			.required(),
		additionalInfo: yup.object().shape({
			remarks: yup.string(),
			attachments: yup.array().of(
				yup.object().shape({
					url: yup.string().url(),
					name: yup.string(),
				})
			),
		}),
	}

	return yup.object().shape(Schema)
}

/** ROLE CONSTANTS */
export const ROLE_ACCESSES = {
	canReadIncoming: 'fe.tdm.incoming.r',
	canReadOutgoing: 'fe.tdm.outgoing.r',
	// canReadExcel: 'fe.tdm.delivery-order.r',  Removing the tab
	canReadDeliveryOrder: 'fe.tdm.delivery-order.r',
	canControlDeliveryOrder: 'fe.tdm.delivery-order.c',
	canControlIncomingDeliveryOrder: 'fe.tdm.delivery-order.c',
	canControlUploadDeliveryOrder: 'fe.tdm.delivery-order.c',
	canReadPurchaseOrder: 'fe.tdm.purchase-order.r',
	canControlPurchaseOrder: 'fe.tdm.purchase-order.c',
	canReadInvoice: 'fe.tdm.invoice.r',
	canControlInvoice: 'fe.tdm.invoice.c',
	canReadContracts: 'fe.marketplace.contracts.r',
}

/**
 * Given a array of types and role access boolean flags, the below return the active document types
 * array as response.
 * @param {array} types
 * @param {object} roleFlags
 */
export const getActiveTypesBasedOnRole = (types = [], roleFlags = {}) =>
	types.filter(key => {
		return Object.keys(roleFlags)
			.filter(_key => _key.match(new RegExp(key.replace(/-/g, ''), 'i')))
			.every(flag => !!roleFlags[flag])
	})

export const mostQtyColors = [
	'#00A505',
	'#00A505CC',
	'#00A50599',
	'#00A50566',
	'#00A50533',
]

export const leastQtyColors = [
	'#FF000033',
	'#FF000066',
	'#FF000099',
	'#FF0000CC',
	'#FF0000',
]

export const ffbQualityKeys = [
	'ripeBunches',
	'underripeBunches',
	'overripeBunches',
	'unripeBunches',
	'damaged',
]

export const FFB_QUALITY_ALIASES_KEYS = {
	ripeBunches: 'dealerGeneralReports.ripeBunches',
	underripeBunches: 'dealerGeneralReports.underripeBunches',
	overripeBunches: 'dealerGeneralReports.overripeBunches',
	unripeBunches: 'dealerGeneralReports.unripeBunches',
	damaged: 'dealerGeneralReports.damaged',
}
