import React, { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn, omit, setIn, merge } from 'timm'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { AppDuc } from 'core-app/modules/App/duc'
import { PlantationDuc } from 'core-app/modules/Plantation/duc'
import { initiateSort, getTargetFilterQueries } from 'core-app/shared/helpers'
import { BoxShadowTable } from 'core-app/shared/components/BoxShadowTable'
import { Title } from 'core-app/shared/components/Title'
import { getCheckerColumnConfig } from 'core-app/modules/Plantation/components/Columns'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Label } from 'ui-lib/components/Typography'
import {
	ButtonIconWrapper,
	Button,
	ButtonWithNoBorder,
} from 'ui-lib/components/Button'
import { getStartIndex, getEndIndex } from 'ui-lib/components/Paginator/utils'
import { Paginator } from 'ui-lib/components/Paginator'
import { DatePicker } from 'ui-lib/components/Datepicker'
import { Icon } from 'ui-lib/icons/components/Icon'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import Documents from 'ui-lib/icons/documents.svg'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import theme from 'ui-lib/utils/base-theme'
import { WebTour } from 'core-app/modules/WebTour'
import { WebTourDuc } from 'core-app/modules/WebTour/duc'

const PaginatorBlock = React.memo(
	({ paginationConfig, onChange, documents, startDesc, toDesc, ofDesc }) => (
		<Box padding={8}>
			<Paginator
				onClick={direction => onChange('paginate', { direction })}
				startIndex={getStartIndex(
					paginationConfig.activeIndex,
					paginationConfig.limit
				)}
				endIndex={getEndIndex(
					paginationConfig.activeIndex,
					paginationConfig.limit,
					paginationConfig.total
				)}
				totalCount={paginationConfig.total}
				startDesc={startDesc}
				toDesc={toDesc}
				ofDesc={ofDesc}
				hidePageResults={(documents || []).length === 0}
			/>
		</Box>
	)
)

const Tickets = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { isMobile } = useSelector(AppDuc.selectors.detection)

	const [activeDateType, setActiveDateType] = useState('date')
	const users = useSelector(AuthDuc.selectors.getOrgUsers)

	const location = useSelector(PlantationDuc.selectors.location)
	const isLoading = useSelector(
		PlantationDuc.selectors.getListingLoadingStatus
	)

	const activeSorts = useSelector(
		PlantationDuc.selectors.getMainDashboardActiveSorts
	)

	const tickets = useSelector(PlantationDuc.selectors.getListingTickets)

	const activeFilters = useSelector(
		PlantationDuc.selectors.getListingActiveFilters
	)
	const activeDateFilters = getIn(activeFilters, [activeDateType]) || {}
	let activeStartDate = getIn(activeDateFilters, ['gte', 0]) || null
	if (activeStartDate) activeStartDate = new Date(activeStartDate)
	let activeEndDate = getIn(activeDateFilters, ['lte', 0]) || null
	if (activeEndDate) activeEndDate = new Date(activeEndDate)

	const [startDate, setStartDate] = useState(activeStartDate)
	const [endDate, setEndDate] = useState(activeEndDate || new Date())

	const paginationConfig = useSelector(
		PlantationDuc.selectors.getListingPaginationEntries
	)
	const { payload = {} } = location
	const { rootModule } = payload
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)

	useEffect(() => {
		if (tourModule === 'createTicket') {
			dispatch(WebTourDuc.creators.setCreateTicket(true))
		}
	}, [tourModule, dispatch])
	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.tickets'),
			name: 'tickets',
			isActive: false,
		},
	]

	/** Handlers to react on actions */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
	}

	const handleOnChange = useCallback(
		(actionType, meta = {}) => {
			switch (actionType) {
				case 'initiate_sort': {
					initiateSort(
						dispatch,
						meta,
						PlantationDuc.creators.fetchListingTickets,
						location,
						meta.type
					)
					break
				}

				case 'remove_filter': {
					setActiveDateType('date')
					setStartDate('')
					setEndDate(new Date())
					const { docType, type, filterType } = meta

					const currentQuery = getIn(location, ['query']) || {}

					const segments = Array.isArray(filterType)
						? filterType
						: [filterType]

					const targetFilters = omit(currentQuery, segments)

					dispatch(
						PlantationDuc.creators.fetchListingTickets(
							docType,
							[type],
							setIn(location, ['query'], targetFilters),
							false
						)
					)

					break
				}

				case 'apply_filter': {
					const {
						docType,
						type,
						filterType,
						filterValue,
						prefix,
					} = meta

					const currentQuery = getIn(location, ['query']) || {}
					const filterQueries = omit(currentQuery, ['sort', 'q']) // exclude sort and search convention

					const targetQueries = getTargetFilterQueries(
						'',
						'date_range',
						filterValue,
						prefix
					)

					const targetFilters = merge(
						currentQuery,
						merge(filterQueries, { [filterType]: targetQueries })
					)

					dispatch(
						PlantationDuc.creators.fetchListingTickets(
							docType,
							type,
							setIn(location, ['query'], targetFilters),
							false
						)
					)

					break
				}

				case 'paginate': {
					const { direction } = meta || {}

					const {
						activeIndex = 0,
						currentCursor,
						nextCursor,
					} = paginationConfig

					const targetIndex = Math.max(
						direction === 'next'
							? activeIndex + 1
							: activeIndex - 1,
						0
					)

					let nextIndex = null

					if (nextCursor && activeIndex > targetIndex) {
						nextIndex = nextCursor
					} else if (currentCursor && activeIndex < targetIndex) {
						nextIndex = currentCursor
					}

					// retrigger the current route so new data is fetched again.
					dispatch(
						MainRouteDuc.creators.redirect(
							location.type,
							location.payload,
							{
								...location.query,
								activeIndex: targetIndex,
								limit: location.query.limit || 5,
								nextIndex,
							}
						)
					)

					break
				}

				case 'open_document': {
					if (meta.row) {
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.PLANTATION$DOCUMENTREFERENCE,
								{
									rootModule: 'ticket',
									action: 'view',
									documentReference: meta.row.id,
								}
							)
						)
					}

					break
				}

				default:
					break
			}
		},
		[dispatch, location, paginationConfig]
	)

	return (
		<>
			<Box padding={8} width="100%" height="100%">
				<Box style={{ padding: '0 5' }}>
					<Breadcrumb
						links={breadCrumbs}
						onClick={target => handleBreadCrumbClick(target)}
					/>
				</Box>

				<Box
					row
					style={{ padding: '0 5' }}
					justifyContent="space-between"
				>
					<Title title={t('ticketListing.title')} icon={Documents} />
					<Box row justifyContent="flex-end" width={200}>
						<Box style={{ padding: 2 }}>
							<Box id="input-createTicket">
								<Button
									label={t(
										'ticketListing.createTicket'
									).toUpperCase()}
									primary
									rounded
									onClick={() =>
										dispatch(
											MainRouteDuc.creators.switchPage(
												MainRouteDuc.types
													.PLANTATION$WACTION,
												{
													rootModule: 'ticket',
													action: 'create',
												}
											)
										)
									}
								/>
							</Box>
						</Box>
					</Box>
				</Box>

				<Box row justifyContent="flex-end">
					<Box alignItems="flex-start" width={125} margin="0 5px">
						<Label small>{t('tdmDocumentListing.from')}</Label>
						<div style={{ fontSize: theme.fontSize.s }}>
							<DatePicker
								name="startDate"
								placeholder={t('tdmDocumentListing.choose')}
								hideError
								value={startDate}
								maxDate={endDate}
								onChange={value => {
									setStartDate(value)
								}}
							/>
						</div>
					</Box>
					<Box alignItems="flex-start" width={125} margin="0 5px">
						<Label small>{t('tdmDocumentListing.to')}</Label>
						<div style={{ fontSize: theme.fontSize.s }}>
							<DatePicker
								name="toDate"
								placeholder={t('tdmDocumentListing.choose')}
								hideError
								toDate
								minDate={startDate}
								value={endDate}
								maxDate={endDate}
								onChange={value => {
									setEndDate(value)
								}}
							/>
						</div>
					</Box>
					<Spacer size={10} horizontal={!isMobile} />

					<Box alignItems="flex-end" width={150} margin="0 5px" row>
						<Button
							label={t('productTrace.apply')}
							type="submit"
							extendStyles={{
								padding: '2px 20px',
							}}
							primary
							rounded
							disabled={!startDate}
							onClick={() => {
								handleOnChange('apply_filter', {
									type: rootModule,
									filterSegment: 'end_date',
									filterType: activeDateType,
									filterValue: {
										startDate: new Date(
											startDate
										).toISOString(),
										endDate: new Date(
											endDate
										).toISOString(),
									},
								})
							}}
						/>
						<Spacer size={10} horizontal={!isMobile} />

						<ButtonWithNoBorder
							label={t('productTrace.clear')}
							style={{
								fontSize: theme.fontSize.xl,
							}}
							onClick={() => {
								handleOnChange('remove_filter', {
									docType: rootModule,
									type: rootModule,
									filterType: ['date', 'updatedAt'],
								})
							}}
						/>
					</Box>
				</Box>

				<PaginatorBlock
					paginationConfig={paginationConfig}
					onChange={handleOnChange}
					documents={tickets}
					startDesc={t('common.showing')}
					toDesc={t('common.to')}
					ofDesc={t('common.of')}
				/>
				<BoxShadowTable
					isLoading={isLoading}
					size="large"
					columnConfig={getCheckerColumnConfig({
						type: rootModule,
						docType: 'ticket',
						sortsMap: activeSorts,
						onChange: handleOnChange,
						users,
						t,
					})}
					rowData={tickets}
				/>
				<PaginatorBlock
					paginationConfig={paginationConfig}
					onChange={handleOnChange}
					documents={tickets}
					startDesc={t('common.showing')}
					toDesc={t('common.to')}
					ofDesc={t('common.of')}
				/>

				<Box padding="25px 2px" style={{ maxWidth: 150 }}>
					<Button
						label={t('common.back')}
						rounded
						customIcon={
							<ButtonIconWrapper lightBG>
								<Icon glyph={LeftArrowIcon} />
							</ButtonIconWrapper>
						}
						onClick={() =>
							dispatch(
								MainRouteDuc.creators.switchPage(
									MainRouteDuc.types.DASHBOARD
								)
							)
						}
					/>
				</Box>
			</Box>
			{tourModule === 'createTicket' && <WebTour />}
		</>
	)
}

export { Tickets }
