export const SortableKeys = ['createdAt', 'updatedAt']
export const PRODUCT_TRACE_TABLE_ALIAS = {
	createdAt: 'productTrace.createdAt',
	partner: 'productTrace.partner',
	docNumber: 'productTrace.docNumber',
	product: 'productTrace.product',
	quantity: 'productTrace.quantity',
}
export const EUDR_COMPLIANCE_LIST = [
	'eudrFlag',
	'deforestationAlert',
	'landLegalityFlag',
	'geoJsonFlag',
]

export const EUDR_COMPLIANCE_LIST_FOR_SUMMARY = [
	'deforestationAlert',
	'landLegalityFlag',
	'geoJsonFlag',
]

export const EUDR_COMPLIANCE_LIST_LABELS = {
	eudrFlag: 'productTrace.eudr',
	deforestationAlert: 'productTrace.deforestationAlert',
	landLegalityFlag: 'productTrace.certification/license',
	geoJsonFlag: 'productTrace.geocoordinates',
}
