import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { TraderDuc } from 'core-app/modules/Trader/duc'
import { AppDuc } from 'core-app/modules/App/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { Setup } from 'core-app/modules/App/containers/Setup'
import { Card } from 'ui-lib/components/Card'
import { H4, SmallText } from 'ui-lib/components/Typography'
import { BarChart } from 'ui-lib/components/BarChart'
import { isMarketplaceHost } from 'ui-lib/utils/helpers'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Box } from 'ui-lib/utils/Box'
import theme from 'ui-lib/utils/base-theme'
import {
	KeyValueSegment,
	CustomPieChart,
	Line,
} from 'core-app/modules/Dashboard'
import { getSalesSvg, getPurchaseSvg } from 'core-app/utils/helpers'

const MainDashboard = () => {
	const { t } = useTranslation()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const insights = useSelector(TraderDuc.selectors.getInsightsData)
	const { partnersAvailable, employeesAvailable, languageSet } = useSelector(
		AuthDuc.selectors.getFirstTimeStats
	)
	const {
		messageExchanged,
		monthlySales = {},
		monthlyPurchase = {},
		salesPurchaseUOM,
		tradeDetails = {},
		tradeDetailsUOM,
		bestSuppliers = [],
		topProducts = [],
		topProductsUOM,
		certifiedSupply = [],
		certifiedSupplyUOM,
		supplierCategory = [],
		supplierCategoryUOM,
	} = insights
	const headingColor = theme.color.accent2
	const subHeadingColor = theme.color.grey10

	const showSetup = isMarketplaceHost()
		? employeesAvailable === false || languageSet === false
		: employeesAvailable === false ||
		  languageSet === false ||
		  partnersAvailable === false

	return (
		<>
			{showSetup && <Setup />}
			<Box row={!isMobile}>
				<Card
					style={{
						margin: '12px 0 0',
						backgroundImage: getSalesSvg(monthlySales),
						backgroundSize: 'contain',
						backgroundPosition: 'bottom',
					}}
					textAlign="left"
					height="174px"
				>
					<Box>
						<H4
							style={{
								padding: '12px 12px 0px 12px',
								color: headingColor,
							}}
						>
							{t('traderDashboard.salesHeading')}
						</H4>
						<SmallText
							style={{
								padding: '0 12px',
								color: subHeadingColor,
							}}
						>
							{t('traderDashboard.subheading')}
						</SmallText>
					</Box>
					<Box style={{ padding: '28px 0' }}>
						<KeyValueSegment
							label={t('traderDashboard.currentMonthSales')}
							value={`${monthlySales.currentMonth || 0} ${
								monthlySales.currentMonth
									? salesPurchaseUOM
									: ''
							}`}
						/>
						<KeyValueSegment
							label={t('traderDashboard.previousMonthSales')}
							value={`${monthlySales.previousMonth || 0} ${
								monthlySales.previousMonth
									? salesPurchaseUOM
									: ''
							}`}
						/>
					</Box>
				</Card>

				<Card
					style={{
						margin: isMobile ? '12px 0' : '12px 12px 0',
						backgroundImage: getPurchaseSvg(monthlyPurchase),
						backgroundSize: 'contain',
						backgroundPosition: 'bottom',
					}}
					textAlign="left"
					height="174px"
				>
					<Box>
						<H4
							style={{
								padding: '12px 12px 0px 12px',
								color: headingColor,
							}}
						>
							{t('traderDashboard.purchaseHeading')}
						</H4>
						<SmallText
							style={{ padding: '0 12px', subHeadingColor }}
						>
							{t('traderDashboard.purchaseSubheading')}
						</SmallText>
					</Box>
					<Box style={{ padding: '28px 0' }}>
						<KeyValueSegment
							label={t('traderDashboard.currentMonthPurchase')}
							value={`${monthlyPurchase.currentMonth || 0} ${
								monthlyPurchase.currentMonth
									? salesPurchaseUOM
									: ''
							}`}
						/>
						<KeyValueSegment
							label={t('traderDashboard.previousMonthPurchase')}
							value={`${monthlyPurchase.previousMonth || 0} ${
								monthlyPurchase.previousMonth
									? salesPurchaseUOM
									: ''
							}`}
						/>
					</Box>
				</Card>
			</Box>

			<Box row={!isMobile}>
				<Box width={isMobile ? 'auto' : '75%'}>
					<Box row={!isMobile}>
						<Card
							style={{
								margin: isMobile ? '0' : '12px 0 0',
								backgroundImage: "url('/images/blue-wave.svg')",
								backgroundSize: 'contain',
								backgroundPosition: 'bottom',
							}}
							textAlign="left"
							height={
								bestSuppliers.len || topProducts.len
									? ''
									: '236px'
							}
						>
							<Box>
								<H4
									style={{
										padding: '12px 12px 0px 12px',
										color: headingColor,
									}}
								>
									{t('traderDashboard.bestSuppliers')}
								</H4>
								<SmallText
									style={{
										padding: '0 12px',
										color: subHeadingColor,
									}}
								>
									{t('traderDashboard.suppliersSubheading')}
								</SmallText>
							</Box>
							<CustomPieChart
								data={bestSuppliers}
								width={isMobile ? '150' : '170'}
								height={isMobile ? '150' : '170'}
								innerRadius="55"
								outerRadius="70"
							/>
						</Card>

						<Card
							style={{
								margin: isMobile ? '12px 0' : '12px 12px 0',
								backgroundImage: "url('/images/blue-wave.svg')",
								backgroundSize: 'contain',
								backgroundPosition: 'bottom',
							}}
							textAlign="left"
						>
							<Box>
								<H4
									style={{
										padding: '12px 12px 0px 12px',
										color: headingColor,
									}}
								>
									{t('traderDashboard.topProducts')}
								</H4>
								<SmallText
									style={{
										padding: '0 12px',
										color: subHeadingColor,
									}}
								>
									{t('traderDashboard.productsSubheading')}
								</SmallText>
								{topProducts.length ? (
									<Box
										row
										style={{ padding: '16px 8px 8px 16px' }}
									>
										<BarChart
											data={topProducts}
											width="150"
											height="150"
											UOM={topProductsUOM}
										/>
									</Box>
								) : (
									<Box
										height="150px"
										center
										style={{ color: '#08090C' }}
									>
										{t('common.errorBlockMessage')}
									</Box>
								)}
							</Box>
						</Card>
					</Box>

					<Box row={!isMobile}>
						<Card
							style={{
								margin: isMobile ? '0' : '12px 0 0',
								backgroundImage: "url('/images/blue-wave.svg')",
								backgroundSize: 'contain',
								backgroundPosition: 'bottom',
							}}
							textAlign="left"
							height={
								certifiedSupply.len || supplierCategory.len
									? ''
									: '252px'
							}
						>
							<Box>
								<H4
									style={{
										padding: '12px 12px 0px 12px',
										color: headingColor,
									}}
								>
									{t('traderDashboard.certSuppliers')}
								</H4>
								<SmallText
									style={{
										padding: '0 12px',
										color: subHeadingColor,
									}}
								>
									{t(
										'traderDashboard.certSuppliersSubheading'
									)}
								</SmallText>
							</Box>
							{certifiedSupply.length ? (
								<Box
									row
									style={{ padding: '16px 8px 8px 16px' }}
								>
									<BarChart
										data={certifiedSupply}
										width="150"
										height="150"
										UOM={certifiedSupplyUOM}
									/>
								</Box>
							) : (
								<Box
									height="150px"
									center
									style={{ color: '#08090C' }}
								>
									{t('common.errorBlockMessage')}
								</Box>
							)}
						</Card>

						<Card
							style={{
								margin: isMobile ? '12px 0 0' : '12px 12px 0',
								backgroundImage: "url('/images/blue-wave.svg')",
								backgroundSize: 'contain',
								backgroundPosition: 'bottom',
							}}
							textAlign="left"
						>
							<Box>
								<H4
									style={{
										padding: '12px 12px 0px 12px',
										color: headingColor,
									}}
								>
									{t('traderDashboard.supplierCategories')}
								</H4>
								<SmallText
									style={{
										padding: '0 12px',
										color: subHeadingColor,
									}}
								>
									{t(
										'traderDashboard.supplierCategorySubheading'
									)}
								</SmallText>
							</Box>
							{supplierCategory.length ? (
								<Box
									row
									style={{ padding: '16px 8px 8px 16px' }}
								>
									<BarChart
										data={supplierCategory}
										width="150"
										height="150"
										UOM={supplierCategoryUOM}
									/>
								</Box>
							) : (
								<Box
									height="150px"
									center
									style={{ color: '#08090C' }}
								>
									{t('common.errorBlockMessage')}
								</Box>
							)}
						</Card>
					</Box>
				</Box>
				<Box width={isMobile ? 'auto' : '25%'}>
					<Card
						style={{
							margin: isMobile ? '12px 0' : '12px 12px 0 0',
							height: '500px',
							width: 'auto',
							backgroundImage: "url('/images/blue-wave.svg')",
							backgroundSize: 'contain',
							backgroundPosition: 'bottom',
						}}
						textAlign="left"
					>
						<Box>
							<H4
								style={{
									padding: '12px 12px 0px 12px',
									color: headingColor,
								}}
							>
								{t('traderDashboard.tradeDetails')}
							</H4>
							<SmallText
								style={{
									padding: '8px 12px 0px',
									color: subHeadingColor,
								}}
							>
								{t('traderDashboard.tradeDetailsSubHeading')}
							</SmallText>
						</Box>
						<Spacer size={40} />
						<Box>
							<KeyValueSegment
								label={t('traderDashboard.incomingConsgmnts')}
								value={`${tradeDetails.incoming || 0} ${
									tradeDetails.incoming ? tradeDetailsUOM : ''
								}`}
							/>
							<KeyValueSegment
								label={t('traderDashboard.outgoingConsgmnts')}
								value={`${tradeDetails.outgoing || 0} ${
									tradeDetails.outgoing ? tradeDetailsUOM : ''
								}`}
							/>
						</Box>
						<Spacer size={20} />
						<Line />
						<Box>
							<H4
								style={{
									padding: '12px 12px 0px 12px',
									color: headingColor,
								}}
							>
								{t('traderDashboard.msgsXchanged')}
							</H4>
							<SmallText
								style={{
									padding: '8px 12px 0px',
									color: subHeadingColor,
								}}
							>
								{t('traderDashboard.msgsXchangesSubheading')}
							</SmallText>
						</Box>
						<Box style={{ padding: '10px 0' }}>
							<KeyValueSegment
								label={t('common.messages')}
								value={messageExchanged}
							/>
						</Box>
					</Card>
				</Box>
			</Box>
		</>
	)
}

export { MainDashboard }
