import LogHelper from 'core-app/utils/logger'
import { AppDuc } from 'core-app/modules/App/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { DealerDuc } from 'core-app/modules/Dealer/duc'
import { MainRouteDuc } from 'core-app/routes/duc'
import {
	getPlantationEndPoint,
	getCoreEndPoint,
	getIAMEndPoint,
	getInsightsEndPoint,
} from 'core-app/config'
import { getRegion } from 'core-app/modules/Dealer/config'
import { Storage } from 'ui-lib/utils/storage'
import { CookieDuc } from 'core-app/modules/App/cookieDuc'
import { AUTH_COOKIE_KEYS } from 'ui-lib/utils/config'
import request from 'core-app/utils/request'
import {
	CallWithRefreshCheck,
	fetchOrgDetailsCount,
} from 'core-app/modules/Auth/AuthSaga'
import { transformDataforPieChart } from 'core-app/utils/helpers'
import { all, takeLatest, put, call, select } from 'redux-saga/effects'
import { Toast } from 'ui-lib/components/Toast'
import { getIn } from 'timm'
import { DocumentListing } from 'core-app/modules/App/AppSaga'

const logger = LogHelper('client:DealerSaga')

function* fetchInsights() {
	try {
		yield put(AppDuc.creators.showGlobalLoader('fetch-dealer-insights'))
		yield fetchOrgDetailsCount()

		const [, orgID] = Storage.get({
			name: AUTH_COOKIE_KEYS.CLIENT_ID,
		}).split('@')

		const orgUrl = `${getIAMEndPoint()}clients/organizations/${orgID}`
		const { data: orgResponse = {} } = yield CallWithRefreshCheck(orgUrl)

		const { primaryAddress = {} } = orgResponse
		const { state, country } = primaryAddress
		const region = getRegion(state, country)
		const selectedCPID = yield select(CookieDuc.selectors.getSelectedCPID)

		let requestUrl = ''
		if (selectedCPID) {
			requestUrl = `${getPlantationEndPoint()}reports/ffbdealer/insights/childOrg/${selectedCPID}?countryCode=MY&regionCode=${encodeURIComponent(
				region
			)}&productCode=FFB`
		} else {
			requestUrl = `${getInsightsEndPoint()}dashboard/ffbdealer/insights?countryCode=MY&regionCode=${encodeURIComponent(
				region
			)}&productCode=FFB`
		}

		const data = yield CallWithRefreshCheck(requestUrl)
		const userInsight = getIn(data, ['data']) || {}

		const bestSuppliers = yield transformDataforPieChart(
			userInsight.bestSuppliers,
			'best-smallholders'
		)

		const finaltransFormedInsight = {
			...userInsight,
			bestSuppliers,
			region,
		}

		yield put(
			DealerDuc.creators.fetchInsightsSuccess(finaltransFormedInsight)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-dealer-insights'))
	}
}

const PAGINATION_LIMIT = 30

function* fetchWorkListing(action) {
	try {
		const { isSkip, skipGlobalLoader, locationState } = action
		if (!skipGlobalLoader)
			yield put(AppDuc.creators.showGlobalLoader('fetch-work-listing'))
		yield put(DealerDuc.creators.setLoadingStatus(true))
		const selectedCPID = yield select(CookieDuc.selectors.getSelectedCPID)
		let requestUrl = ''
		if (selectedCPID) {
			requestUrl = `${getPlantationEndPoint()}ffbdealer/tickets/childOrg/${selectedCPID}`
		} else {
			requestUrl = `${getPlantationEndPoint()}ffbdealer/tickets`
		}

		const { data, serverPaginationQuery } = yield DocumentListing(
			requestUrl,
			locationState,
			PAGINATION_LIMIT
		)

		yield put(
			DealerDuc.creators.savePaginationQueries(serverPaginationQuery)
		)

		yield put(DealerDuc.creators.setWorkListing(isSkip, data.list))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-work-listing'))
		yield put(DealerDuc.creators.setLoadingStatus(false))
	}
}

function* createWork(action) {
	try {
		const { details, toastMessage } = action
		const {
			createdDate,
			ffbCount,
			ripeBunches,
			overripeBunches,
			underripeBunches,
			emptyBunches,
			wetBunches,
			unripe,
			area,
			block,
			smallholderID,
			harvesterCount,
		} = details

		yield put(AppDuc.creators.showGlobalLoader('create-work'))

		const selectedCPID = yield select(CookieDuc.selectors.getSelectedCPID)
		let productIDURL = ''
		if (selectedCPID) {
			productIDURL = `${getCoreEndPoint()}clients/organizations/-/products/childorg/${selectedCPID}`
		} else {
			productIDURL = `${getCoreEndPoint()}clients/organizations/-/products`
		}

		const { data = {} } = yield CallWithRefreshCheck(productIDURL)
		const productID = getIn(data, ['list', 0, 'product', 'id']) || ''
		const workDetails = {
			ffbCount,
			date: createdDate,
			ffbDetails: {
				ripeBunches,
				overripeBunches,
				underripeBunches,
				emptyBunches,
				wetBunches,
				unripe,
				location: {
					area,
					block,
				},
			},
			meta: {
				smallholderID,
				harvesterCount,
				productID,
			},
		}

		let requestUrl = ''
		if (selectedCPID) {
			requestUrl = `${getPlantationEndPoint()}ffbdealer/tickets/childOrg/${selectedCPID}`
		} else {
			requestUrl = `${getPlantationEndPoint()}ffbdealer/tickets`
		}
		const options = {
			method: 'POST',
			body: JSON.stringify(workDetails),
		}

		yield call(request, requestUrl, options)

		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)

		Toast({
			type: 'success',
			message: toastMessage,
			isMobile: isMobile || isTablet,
		})
		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.DEALER$SUBROOT,
				{
					rootModule: 'work',
				}
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('create-work'))
	}
}

function* initiateWorkEdit(action) {
	try {
		const { ticketID } = action

		yield put(AppDuc.creators.showGlobalLoader('initiate-work-edit'))

		const requestUrl = `${getPlantationEndPoint()}ffbdealer/tickets/${ticketID}`
		const options = {
			method: 'GET',
		}
		const { data } = yield call(request, requestUrl, options)
		const initialValues = {
			smallholderID: getIn(data, ['meta', 'smallholderID']),
			harvesterCount: getIn(data, ['meta', 'harvesterCount']),
			ffbCount: getIn(data, ['ffbCount']),
			area: getIn(data, ['ffbDetails', 'location', 'area']),
			block: getIn(data, ['ffbDetails', 'location', 'block']),
			ripeBunches: getIn(data, ['ffbDetails', 'ripeBunches']),
			overripeBunches: getIn(data, ['ffbDetails', 'overripeBunches']),
			underripeBunches: getIn(data, ['ffbDetails', 'underripeBunches']),
			emptyBunches: getIn(data, ['ffbDetails', 'emptyBunches']),
			wetBunches: getIn(data, ['ffbDetails', 'wetBunches']),
			unripe: getIn(data, ['ffbDetails', 'unripe']),
		}
		yield put(DealerDuc.creators.setInitialFormValues(initialValues))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('initiate-work-edit'))
	}
}

// for fetching Work Details to display in VIEW page
function* viewWorkDetails(action) {
	try {
		const { ticketID } = action
		yield put(AppDuc.creators.showGlobalLoader('work-details'))

		const selectedCPID = yield select(CookieDuc.selectors.getSelectedCPID)
		let requestUrl = ''
		if (selectedCPID) {
			requestUrl = `${getPlantationEndPoint()}ffbdealer/tickets/${ticketID}/childOrg/${selectedCPID}`
		} else {
			requestUrl = `${getPlantationEndPoint()}ffbdealer/tickets/${ticketID}`
		}

		const options = {
			method: 'GET',
		}
		const { data } = yield call(request, requestUrl, options)
		yield put(AuthDuc.creators.fetchPartnerOrgs())

		yield put(DealerDuc.creators.setWorkDetails(data))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('work-details'))
	}
}

// for delete existing Tickets
function* deleteTicket(action) {
	try {
		const { ticketID } = action
		yield put(AppDuc.creators.showGlobalLoader('delete-work-details'))

		const requestUrl = `${getPlantationEndPoint()}ffbdealer/tickets/${ticketID}`
		const options = {
			method: 'DELETE',
		}
		const { data } = yield call(request, requestUrl, options)

		yield put(DealerDuc.creators.setWorkDetails(data))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('delete-work-details'))
	}
}

export default function* DealerSaga() {
	try {
		yield all([
			takeLatest(DealerDuc.creators.fetchInsights().type, fetchInsights),
			takeLatest(
				DealerDuc.creators.fetchWorkListing().type,
				fetchWorkListing
			),
			takeLatest(DealerDuc.creators.createWork().type, createWork),
			takeLatest(
				DealerDuc.creators.initiateWorkEdit().type,
				initiateWorkEdit
			),
			takeLatest(
				DealerDuc.creators.viewWorkDetails().type,
				viewWorkDetails
			),
			takeLatest(DealerDuc.creators.deleteTicket().type, deleteTicket),
		])
	} catch (err) {
		logger.log(err)
	}
}
