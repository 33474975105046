import Duck from 'extensible-duck'
import { setIn, getIn } from 'timm'
import { createSelector } from 'reselect'
import { initialState } from './config'

export const WebTourDuc = new Duck({
	namespace: 'webTour',
	store: 'global',
	types: [
		'WEBTOUR_SET_TOUR_OPEN',
		'WEBTOUR_SET_TOUR_POPUP',
		'WEBTOUR_SET_CREATE_TOUR',
		'WEBTOUR_SET_ACTIVE_TOUR_MODULE',
		'WEBTOUR_SET_TOUR_STATUS',
		'WEBTOUR_SET_DASHBOARD_VISIT',
		'WEBTOUR_SET_TDM_VISIT',
		'WEBTOUR_SET_PARTNER_VISIT',
		'WEBTOUR_SET_PRODN_VISIT',
		'WEBTOUR_SET_TRACE_VISIT',
		'WEBTOUR_SET_TRIPS_VISIT',
		'WEBTOUR_SET_SCHEDULER_VISIT',
		'WEBTOUR_SET_WBTKT_VISIT',
		'WEBTOUR_SET_AUDIT_VISIT',
		'WEBTOUR_SET_ADMIN_VISIT',
		'WEBTOUR_SET_RECORDS_VISIT',
		'WEBTOUR_SET_SETTINGS_VISIT',
		'WEBTOUR_SET_PASSWORD_UPDATE',
		'WEBTOUR_SET_LANGUAGE_UPDATE',
		'WEBTOUR_CREATE_WORK',
		'WEBTOUR_CREATE_DO_DETAILS',
		'WEBTOUR_CREATE_INPUT_BATCH',
		'WEBTOUR_CREATE_OUTPUT_BATCH',
		'WEBTOUR_CREATE_PARTNER',
		'WEBTOUR_CREATE_SCHEDULE',
		'WEBTOUR_CREATE_BIN',
		'WEBTOUR_CREATE_TICKET',
		'WEBTOUR_UPLOAD_WB_TKT',
		'WEBTOUR_ASSETS_UPDATE',
		'WEBTOUR_COMPANYINFO_UPDATE',
		'WEBTOUR_EMPLOYEE_UPDATE',
		'WEBTOUR_VIEW_TDM',
	],
	initialState,
	reducer: (state, action, duck) => {
		switch (action.type) {
			case duck.types.WEBTOUR_SET_TOUR_OPEN: {
				return setIn(state, ['isTourOpen'], action.isTourOpen)
			}
			case duck.types.WEBTOUR_SET_TOUR_POPUP: {
				return setIn(state, ['tourPopupOpen'], action.tourPopupOpen)
			}

			case duck.types.WEBTOUR_SET_CREATE_TOUR: {
				return setIn(
					state,
					['isCreateTourOpen'],
					action.isCreateTourOpen
				)
			}
			case duck.types.WEBTOUR_SET_ACTIVE_TOUR_MODULE: {
				return setIn(
					state,
					['activeTourModule'],
					action.activeTourModule
				)
			}
			case duck.types.WEBTOUR_SET_TOUR_STATUS: {
				return setIn(state, ['tourStatus'], action.tourStatus)
			}
			case duck.types.WEBTOUR_SET_DASHBOARD_VISIT: {
				return setIn(state, ['dashboardVisit'], action.dashboardVisit)
			}

			case duck.types.WEBTOUR_SET_TDM_VISIT: {
				return setIn(state, ['tdmVisit'], action.tdmVisit)
			}
			case duck.types.WEBTOUR_SET_PARTNER_VISIT: {
				return setIn(state, ['partnerVisit'], action.partnerVisit)
			}
			case duck.types.WEBTOUR_SET_PRODN_VISIT: {
				return setIn(state, ['productionVisit'], action.productionVisit)
			}
			case duck.types.WEBTOUR_SET_TRACE_VISIT: {
				return setIn(state, ['traceVisit'], action.traceVisit)
			}
			case duck.types.WEBTOUR_SET_TRIPS_VISIT: {
				return setIn(state, ['tripsVisit'], action.tripsVisit)
			}
			case duck.types.WEBTOUR_SET_SCHEDULER_VISIT: {
				return setIn(state, ['schedulerVisit'], action.schedulerVisit)
			}
			case duck.types.WEBTOUR_SET_WBTKT_VISIT: {
				return setIn(state, ['wbtktVisit'], action.wbtktVisit)
			}
			case duck.types.WEBTOUR_SET_AUDIT_VISIT: {
				return setIn(state, ['auditVisit'], action.auditVisit)
			}
			case duck.types.WEBTOUR_SET_ADMIN_VISIT: {
				return setIn(state, ['adminVisit'], action.adminVisit)
			}
			case duck.types.WEBTOUR_SET_RECORDS_VISIT: {
				return setIn(state, ['recordsVisit'], action.recordsVisit)
			}
			case duck.types.WEBTOUR_SET_SETTINGS_VISIT: {
				return setIn(state, ['settingsVisit'], action.settingsVisit)
			}
			case duck.types.WEBTOUR_SET_PASSWORD_UPDATE: {
				return setIn(state, ['passwordUpdate'], action.passwordUpdate)
			}
			case duck.types.WEBTOUR_SET_LANGUAGE_UPDATE: {
				return setIn(state, ['languageUpdate'], action.languageUpdate)
			}
			case duck.types.WEBTOUR_CREATE_WORK: {
				return setIn(state, ['workCreate'], action.workCreate)
			}
			case duck.types.WEBTOUR_CREATE_DO_DETAILS: {
				return setIn(state, ['deliveryCreate'], action.deliveryCreate)
			}
			case duck.types.WEBTOUR_CREATE_INPUT_BATCH: {
				return setIn(state, ['createIpBatch'], action.createIpBatch)
			}
			case duck.types.WEBTOUR_CREATE_OUTPUT_BATCH: {
				return setIn(state, ['createOpBatch'], action.createOpBatch)
			}
			case duck.types.WEBTOUR_CREATE_PARTNER: {
				return setIn(state, ['createPartner'], action.createPartner)
			}
			case duck.types.WEBTOUR_CREATE_SCHEDULE: {
				return setIn(state, ['scheduleCreate'], action.scheduleCreate)
			}
			case duck.types.WEBTOUR_CREATE_BIN: {
				return setIn(state, ['createBin'], action.createBin)
			}
			case duck.types.WEBTOUR_CREATE_TICKET: {
				return setIn(state, ['createTicket'], action.createTicket)
			}
			case duck.types.WEBTOUR_UPLOAD_WB_TKT: {
				return setIn(state, ['uploadWBTkt'], action.uploadWBTkt)
			}
			case duck.types.WEBTOUR_ASSETS_UPDATE: {
				return setIn(state, ['assetsUpdate'], action.assetsUpdate)
			}
			case duck.types.WEBTOUR_COMPANYINFO_UPDATE: {
				return setIn(
					state,
					['companyInfoUpdate'],
					action.companyInfoUpdate
				)
			}
			case duck.types.WEBTOUR_EMPLOYEE_UPDATE: {
				return setIn(state, ['employeeUpdate'], action.employeeUpdate)
			}

			case duck.types.WEBTOUR_VIEW_TDM: {
				return setIn(state, ['tdmView'], action.tdmView)
			}
			default:
				return state
		}
	},
	selectors: {
		auth: state => state.auth,
		webTour: state => state.webTour,
		location: state => state.location,
		isTourOpen: state => getIn(state, ['webTour', 'isTourOpen']),
		tourPopupOpen: state => getIn(state, ['webTour', 'tourPopupOpen']),
		isCreateTourOpen: state =>
			getIn(state, ['webTour', 'isCreateTourOpen']),
		activeTourModule: new Duck.Selector(selectors =>
			createSelector(
				selectors.webTour,
				webTour => webTour.activeTourModule || ''
			)
		),
		tourStatus: state => getIn(state, ['webTour', 'tourStatus']),
		dashboardVisit: new Duck.Selector(selectors =>
			createSelector(selectors.webTour, webTour => webTour.dashboardVisit)
		),
		tdmVisit: new Duck.Selector(selectors =>
			createSelector(selectors.webTour, webTour => webTour.tdmVisit)
		),
		partnerVisit: new Duck.Selector(selectors =>
			createSelector(selectors.webTour, webTour => webTour.partnerVisit)
		),
		productionVisit: new Duck.Selector(selectors =>
			createSelector(
				selectors.webTour,
				webTour => webTour.productionVisit
			)
		),
		traceVisit: new Duck.Selector(selectors =>
			createSelector(selectors.webTour, webTour => webTour.traceVisit)
		),
		tripsVisit: new Duck.Selector(selectors =>
			createSelector(selectors.webTour, webTour => webTour.tripsVisit)
		),
		schedulerVisit: new Duck.Selector(selectors =>
			createSelector(selectors.webTour, webTour => webTour.schedulerVisit)
		),
		wbtktVisit: new Duck.Selector(selectors =>
			createSelector(selectors.webTour, webTour => webTour.wbtktVisit)
		),
		auditVisit: new Duck.Selector(selectors =>
			createSelector(selectors.webTour, webTour => webTour.auditVisit)
		),
		adminVisit: new Duck.Selector(selectors =>
			createSelector(selectors.webTour, webTour => webTour.adminVisit)
		),
		recordsVisit: new Duck.Selector(selectors =>
			createSelector(selectors.webTour, webTour => webTour.recordsVisit)
		),
		settingsVisit: new Duck.Selector(selectors =>
			createSelector(selectors.webTour, webTour => webTour.settingsVisit)
		),
		passwordUpdate: state => getIn(state, ['webTour', 'passwordUpdate']),
		languageUpdate: state => getIn(state, ['webTour', 'languageUpdate']),
		workCreate: state => getIn(state, ['webTour', 'workCreate']),
		deliveryCreate: state => getIn(state, ['webTour', 'deliveryCreate']),
		createIpBatch: state => getIn(state, ['webTour', 'createIpBatch']),
		createOpBatch: state => getIn(state, ['webTour', 'createOpBatch']),
		createPartner: state => getIn(state, ['webTour', 'createPartner']),
		scheduleCreate: state => getIn(state, ['webTour', 'scheduleCreate']),
		createTicket: state => getIn(state, ['webTour', 'createTicket']),
		createBin: state => getIn(state, ['webTour', 'createBin']),
		uploadWBTkt: state => getIn(state, ['webTour', 'uploadWBTkt']),
		assetsUpdate: state => getIn(state, ['webTour', 'assetsUpdate']),
		companyInfoUpdate: state =>
			getIn(state, ['webTour', 'companyInfoUpdate']),
		employeeUpdate: state => getIn(state, ['webTour', 'employeeUpdate']),
		tdmView: state => getIn(state, ['webTour', 'tdmView']),
	},
	creators: duck => ({
		setTourOpenStatus: isTourOpen => ({
			type: duck.types.WEBTOUR_SET_TOUR_OPEN,
			isTourOpen,
		}),
		setTourPopupOpen: tourPopupOpen => ({
			type: duck.types.WEBTOUR_SET_TOUR_POPUP,
			tourPopupOpen,
		}),

		setCreateTourOpenStatus: isCreateTourOpen => ({
			type: duck.types.WEBTOUR_SET_CREATE_TOUR,
			isCreateTourOpen,
		}),

		setActiveTourModule: activeTourModule => ({
			type: duck.types.WEBTOUR_SET_ACTIVE_TOUR_MODULE,
			activeTourModule,
		}),
		setTourStatus: tourStatus => ({
			type: duck.types.WEBTOUR_SET_TOUR_STATUS,
			tourStatus,
		}),
		setDashboardVisit: dashboardVisit => ({
			type: duck.types.WEBTOUR_SET_DASHBOARD_VISIT,
			dashboardVisit,
		}),

		setTdmVisit: tdmVisit => ({
			type: duck.types.WEBTOUR_SET_TDM_VISIT,
			tdmVisit,
		}),
		setPartnerVisit: partnerVisit => ({
			type: duck.types.WEBTOUR_SET_PARTNER_VISIT,
			partnerVisit,
		}),
		setProductionVisit: productionVisit => ({
			type: duck.types.WEBTOUR_SET_PRODN_VISIT,
			productionVisit,
		}),
		setTraceVisit: traceVisit => ({
			type: duck.types.WEBTOUR_SET_TRACE_VISIT,
			traceVisit,
		}),
		setWBTktVisit: wbtktVisit => ({
			type: duck.types.WEBTOUR_SET_WBTKT_VISIT,
			wbtktVisit,
		}),
		setTripsVisit: tripsVisit => ({
			type: duck.types.WEBTOUR_SET_TRIPS_VISIT,
			tripsVisit,
		}),
		setSchedulerVisit: schedulerVisit => ({
			type: duck.types.WEBTOUR_SET_SCHEDULER_VISIT,
			schedulerVisit,
		}),
		setAuditVisit: auditVisit => ({
			type: duck.types.WEBTOUR_SET_AUDIT_VISIT,
			auditVisit,
		}),
		setAdminVisit: adminVisit => ({
			type: duck.types.WEBTOUR_SET_ADMIN_VISIT,
			adminVisit,
		}),
		setRecordsVisit: recordsVisit => ({
			type: duck.types.WEBTOUR_SET_RECORDS_VISIT,
			recordsVisit,
		}),
		setSettingsVisit: settingsVisit => ({
			type: duck.types.WEBTOUR_SET_SETTINGS_VISIT,
			settingsVisit,
		}),
		setPasswordUpdate: passwordUpdate => ({
			type: duck.types.WEBTOUR_SET_PASSWORD_UPDATE,
			passwordUpdate,
		}),
		setLangUpdate: languageUpdate => ({
			type: duck.types.WEBTOUR_SET_LANGUAGE_UPDATE,
			languageUpdate,
		}),
		setCreateWork: workCreate => ({
			type: duck.types.WEBTOUR_CREATE_WORK,
			workCreate,
		}),
		setCreateDODetails: deliveryCreate => ({
			type: duck.types.WEBTOUR_CREATE_DO_DETAILS,
			deliveryCreate,
		}),
		setCreateIpBatch: createIpBatch => ({
			type: duck.types.WEBTOUR_CREATE_INPUT_BATCH,
			createIpBatch,
		}),
		setCreateOpBatch: createOpBatch => ({
			type: duck.types.WEBTOUR_CREATE_OUTPUT_BATCH,
			createOpBatch,
		}),
		setCreatePartner: createPartner => ({
			type: duck.types.WEBTOUR_CREATE_PARTNER,
			createPartner,
		}),
		setCreateSchedule: scheduleCreate => ({
			type: duck.types.WEBTOUR_CREATE_SCHEDULE,
			scheduleCreate,
		}),
		setCreateBin: createBin => ({
			type: duck.types.WEBTOUR_CREATE_BIN,
			createBin,
		}),
		setCreateTicket: createTicket => ({
			type: duck.types.WEBTOUR_CREATE_TICKET,
			createTicket,
		}),
		setUploadWBTkt: uploadWBTkt => ({
			type: duck.types.WEBTOUR_UPLOAD_WB_TKT,
			uploadWBTkt,
		}),
		setAssetsUpdate: assetsUpdate => ({
			type: duck.types.WEBTOUR_ASSETS_UPDATE,
			assetsUpdate,
		}),
		setCompanyInfoUpdate: companyInfoUpdate => ({
			type: duck.types.WEBTOUR_COMPANYINFO_UPDATE,
			companyInfoUpdate,
		}),
		setEmployeeUpdate: employeeUpdate => ({
			type: duck.types.WEBTOUR_EMPLOYEE_UPDATE,
			employeeUpdate,
		}),
		setTDMView: tdmView => ({
			type: duck.types.WEBTOUR_VIEW_TDM,
			tdmView,
		}),
	}),
})
