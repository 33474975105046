import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { OnboardDuc } from 'core-app/modules/Onboard/duc'
import { useTranslation } from 'react-i18next'
import { AppDuc } from 'core-app/modules/App/duc'
import { currentPlatform } from 'ui-lib/utils/config'
import { H2, SmallText } from 'ui-lib/components/Typography'
import { FileUpload } from 'ui-lib/components/FileUpload'
import { Button, ButtonWithNoBorder } from 'ui-lib/components/Button'
import { Input } from 'ui-lib/components/Input'
import { Select } from 'ui-lib/components/Select'
import { Label } from 'ui-lib/components/Label'
import { Box } from 'ui-lib/utils/Box'
import theme from 'ui-lib/utils/base-theme'
import { Spacer } from 'ui-lib/utils/Spacer'
import { useFormik } from 'formik'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AuthDuc } from '../../../Auth/duc'

const UploadCertificate = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const orgdetails = useSelector(AuthDuc.selectors.getCurrentOrgDetails)
	const [gstDocument, setGSTDocument] = useState([])
	const [certificationDocument, setCertificationDocument] = useState([])
	const [disableCTA, setDisableCTA] = useState(true)
	const categories =
		orgdetails && orgdetails.categories && orgdetails.categories.length > 0
			? orgdetails.categories.map(item => item.id)
			: []

	const {
		handleSubmit,
		values,
		handleChange,
		handleBlur,
		touched,
		errors,
		isSubmitting,
		setFieldValue,
	} = useFormik({
		initialValues: {
			gstNumber: '',
			certType: '',
			certNumber: '',
		},
		onSubmit: (_values, { setSubmitting }) => {
			const formValues = {
				..._values,
				gstDocument,
				certificationDocument,
			}

			dispatch(
				OnboardDuc.creators.uploadCertificate(
					formValues,
					'Certificate added successfully',
					{ setSubmitting }
				)
			)
		},
	})

	const getMeasure = (palmoilMeasure, riceMeasure) => {
		let measure = palmoilMeasure

		if (currentPlatform() !== 'palmoil') {
			measure = riceMeasure
		}

		return measure
	}

	useEffect(() => {
		setDisableCTA(true)

		if (values.gstNumber) {
			if (gstDocument.length > 0) {
				if (values.certType && values.certType !== 'UNCERTIFIED') {
					if (values.certNumber && certificationDocument.length > 0) {
						setDisableCTA(false)
					} else {
						setDisableCTA(true)
					}
				} else {
					setDisableCTA(false)
				}
			} else {
				setDisableCTA(true)
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values, gstDocument, certificationDocument])

	return (
		<Box
			style={{
				width: '100vw',
				height: '100vh',
				background: ' #1D285D',
				overflowY: 'scroll',
			}}
			padding={8}
			center
		>
			<Spacer size={isMobile ? 128 : 0} />
			<Box>
				<H2
					style={{
						fontSize: isMobile ? 32 : 40,
						color: theme.color.white,
						lineHeight: isMobile ? '48px' : '52px',
						fontWeight: 'bold',
					}}
				>
					Enter your GST/VAT or Certification number
				</H2>

				<Spacer size={6} />
				<SmallText
					style={{
						color: '#7D8AC7',
						fontSize: isMobile ? '14px' : '16px',
						lineHeight: isMobile ? '26px' : '32px',
						textAlign: 'center',
					}}
				>
					Your accounts are required to be approved by DIBIZ,
					therefore its important to complete this step.
				</SmallText>
				<Spacer size={20} />
				<Box
					style={{
						width: '770px',
						height: 'fit-content',
						background: theme.color.white,
						borderRadius: '10px',
						boxShadow: '0px 3px 6px #00000029',
					}}
					padding={isMobile ? 12 : 25}
					alignItems="center"
				>
					<SmallText
						style={{
							color: '#2D2D2D',
							fontSize: '30px',
							lineHeight: '17px',
							marginTop: '10px',
							width: '500px',
							fontWeight: 'bold',
							textAlign: 'center',
							display: isMobile && 'none',
						}}
					>
						One more step!
					</SmallText>
					<Spacer size={10} />
					<SmallText
						style={{
							color: '#555454',
							fontSize: '16px',
							lineHeight: isMobile ? '28px' : '22px',
							width: isMobile ? 'auto' : 500,
							textAlign: 'center',
						}}
					>
						For us to verify your authenticity, please share your
						GST/VAT or certification number
					</SmallText>
					<Spacer size={20} />
					<Box>
						<form onSubmit={handleSubmit}>
							<Box row={!isMobile} justifyContent="space-between">
								<Label
									required
									style={{
										fontSize: '14px',
										color: '#555454',
									}}
								>
									Company registration/GST/VST/SST numbers
								</Label>

								<Label
									required
									style={{
										fontSize: '14px',
										color: '#555454',
										paddingRight:
											gstDocument &&
											gstDocument.length > 0
												? 57
												: 72,
									}}
								>
									{gstDocument && gstDocument.length > 0
										? 'Uploaded Certificate'
										: 'Upload Certificate'}
								</Label>
							</Box>
							<Box row={!isMobile}>
								<Input
									name="gstNumber"
									value={values.gstNumber}
									onChange={handleChange}
									onBlur={handleBlur}
									placeholder="Company registration/GST/VST/SST numbers"
									error={
										touched.gstNumber && errors.gstNumber
									}
									extendStyles={{
										border: '1px solid #ACB7CD',
										borderRadius: '4px',
										opacity: 1,
										width: isMobile ? 300 : 410,
									}}
								/>
								<Box
									padding={isMobile ? 0 : '0 0 0 24px'}
									style={{ marginBottom: '-16px' }}
								>
									<FileUpload
										type="private"
										name="gstDocument"
										size="5242880"
										outline
										width={192}
										disableUploadForOne
										hideButtonOneUpload
										modalTriggerBtnLabel="Upload"
										currentUploads={gstDocument}
										onDocumentChange={document => {
											setGSTDocument(document)
										}}
										onUploadFailure={({ message }) => {
											dispatch(
												AppDuc.creators.showToast({
													messageType: 'error',
													message,
												})
											)
										}}
									/>
								</Box>
							</Box>
							<div
								style={{
									display: 'flex',
									marginBottom: '10px',
									justifyContent: 'end',
								}}
							>
								<div style={{ marginTop: -1 }}>
									<span
										style={{
											color: theme.color.grey6,
										}}
									>
										{' '}
										*{' '}
									</span>
								</div>
								<Label
									style={{
										fontSize: '11px',
										fontStyle: 'italic',
										color: theme.color.grey6,
									}}
								>
									Company registration/GST/VST/SST numbers
									required for BUYER functionalities
								</Label>
							</div>
							<Box
								style={{
									padding: '0px 20px',
									position: 'relative',
									overflow: 'visible',
									background: '#FAFBFF',
									marginBottom: '40px',
									border: '1px solid #D9DEE5',
									borderRadius: '6px',
									width: isMobile ? 300 : 'auto',
								}}
								textAlign="left"
							>
								<SmallText
									style={{
										position: 'absolute',
										fontSize: theme.fontSize.m,
										color: '#D8E0F0',
										top: '-10px',
										left: isMobile ? '130px' : '290px',
										display: 'inline-block',
										backgroundColor: '#ffffff',
										padding: '0 8px',
									}}
								>
									&nbsp;
								</SmallText>
							</Box>

							{/* <Spacer size={30} /> */}
							<Box row={!isMobile} justifyContent="space-between">
								<Box row>
									<Label
										style={{
											fontSize: '14px',
											color: '#555454',
											width: getMeasure('auto', 180),
										}}
									>
										Certificate Type
									</Label>
									<Label
										style={{
											fontSize: '14px',
											color: '#555454',
											marginLeft: isMobile ? 20 : 56,
										}}
									>
										Certificate Number
									</Label>
									<Label
										style={{
											fontSize: '14px',
											color: '#555454',
											paddingRight:
												certificationDocument &&
												certificationDocument.length > 0
													? 57
													: 72,
											marginLeft: 160,
										}}
									>
										{certificationDocument &&
										certificationDocument.length > 0
											? 'Uploaded Certificate'
											: 'Upload Certificate'}
									</Label>
								</Box>
							</Box>
							<Box row={!isMobile}>
								<Box row>
									<Box
										style={{
											width: isMobile
												? 100
												: getMeasure(130, 225),
										}}
									>
										{currentPlatform() === 'palmoil' ? (
											<Select
												name="certType"
												placeholder="Select"
												options={[
													'UNCERTIFIED',
													'RSPO',
													'MSPO',
													'MPOB',
													'ISCC',
													'ISPO',
												]}
												returnOnlyValue
												value={values.certType}
												onChange={value => {
													setFieldValue(
														'certType',
														value
													)
													if (
														value === 'UNCERTIFIED'
													) {
														setFieldValue(
															'certNumber',
															''
														)
														setCertificationDocument(
															[]
														)
													}
												}}
											/>
										) : (
											<Input
												name="certType"
												value={values.certType}
												onChange={handleChange}
												onBlur={handleBlur}
												placeholder="Enter Certificate Type"
												error={
													touched.certType &&
													errors.certType
												}
												extendStyles={{
													border: '1px solid #ACB7CD',
													borderRadius: '4px',
													opacity: 1,
													width: isMobile ? 300 : 225,
												}}
											/>
										)}
									</Box>
									<Box
										style={{
											paddingLeft: getMeasure(24, 10),
										}}
									>
										<Input
											name="certNumber"
											key={values.certType}
											value={values.certNumber}
											onChange={handleChange}
											onBlur={handleBlur}
											disabled={
												values.certType ===
												'UNCERTIFIED'
											}
											placeholder={
												isMobile
													? 'Certification number'
													: 'Enter your certification number'
											}
											error={
												touched.certNumber &&
												errors.certNumber
											}
											extendStyles={{
												border: '1px solid #ACB7CD',
												borderRadius: '4px',
												opacity: 1,
												width: isMobile ? 190 : 262,
											}}
										/>
									</Box>
								</Box>
								<Box
									padding={isMobile ? 0 : '0 0 0 16px'}
									style={{ marginBottom: '-16px' }}
								>
									<FileUpload
										type="public"
										name="certificationDocument"
										size="5242880"
										outline
										width={192}
										modalTriggerBtnLabel="Upload"
										disableUploadForOne
										hideButtonOneUpload
										currentUploads={certificationDocument}
										onDocumentChange={document => {
											setCertificationDocument(document)
										}}
										onUploadFailure={({ message }) => {
											dispatch(
												AppDuc.creators.showToast({
													messageType: 'error',
													message,
												})
											)
										}}
									/>
								</Box>
							</Box>
							<div
								style={{
									display: 'flex',
									justifyContent: 'end',
								}}
							>
								<div style={{ marginTop: -1 }}>
									<span
										style={{
											color: theme.color.grey6,
										}}
									>
										{' '}
										*{' '}
									</span>
								</div>
								<Label
									style={{
										fontSize: '11px',
										fontStyle: 'italic',
										color: theme.color.grey6,
									}}
								>
									Certificate required for SELLER
									functionalities
								</Label>
							</div>
							<Box
								style={{
									padding: '0px 20px',
									position: 'relative',
									overflow: 'visible',
									background: '#FAFBFF',
									border: '1px solid #D9DEE5',
									marginBottom: '40px',
									borderRadius: '6px',
									width: isMobile ? 300 : 'auto',
								}}
								textAlign="left"
							>
								<SmallText
									style={{
										position: 'absolute',
										fontSize: theme.fontSize.m,
										color: '#D8E0F0',
										top: '-10px',
										left: isMobile ? '130px' : '290px',
										display: 'inline-block',
										backgroundColor: '#ffffff',
										padding: '0 8px',
									}}
								>
									&nbsp;
								</SmallText>
							</Box>
							{categories.includes('palmoil-mill') ||
							categories.includes('rice-mill') ? (
								<Box>
									<div
										style={{
											display: 'flex',
										}}
									>
										<Label
											style={{
												fontSize: '14px',
												color: '#555454',
												marginTop: 9,
											}}
										>
											Universal Mill ID
										</Label>
										<Box style={{ paddingLeft: 24 }}>
											<Input
												name="universalMillID"
												value={values.universalMillID}
												onChange={handleChange}
												onBlur={handleBlur}
												placeholder={
													isMobile
														? 'Enter ID'
														: 'Enter your Universal Mill ID'
												}
												error={
													touched.universalMillID &&
													errors.universalMillID
												}
												extendStyles={{
													border: '1px solid #ACB7CD',
													borderRadius: '4px',
													opacity: 1,
													width: isMobile ? 190 : 262,
												}}
												hideError
											/>
										</Box>
									</div>
									<Box justifyContent="flex-end">
										<div
											style={{
												display: 'flex',
												marginTop: '2px',
												marginBottom: '10px',
												justifyContent: 'end',
											}}
										>
											<div style={{ marginTop: -1 }}>
												<span
													style={{
														color:
															theme.color.grey6,
													}}
												>
													{' '}
													*{' '}
												</span>
											</div>
											<Label
												style={{
													fontSize: '11px',
													fontStyle: 'italic',
													color: theme.color.grey6,
												}}
											>
												Providing Universal Mill ID will
												help your buyers to verify your
												identity. We strongly recommend
												to provide this information
											</Label>
										</div>
									</Box>
								</Box>
							) : (
								''
							)}
							<div
								style={{
									width: '100%',
									display: 'flex',
									justifyContent: 'space-between',
								}}
								title="Trading with virtual accounts gives you an experience of Dibiz Marketplace  and Trade Document Management features."
							>
								<ButtonWithNoBorder
									label={t('login.skipToVirtualAccount')}
									onClick={() => {
										dispatch(
											MainRouteDuc.creators.switchPage(
												MainRouteDuc.types.DASHBOARD
											)
										)
									}}
									extendStyles={{
										marginLeft: 10,
									}}
								/>
								<div
									style={{
										width: '50%',
									}}
								>
									<Button
										isLoading={isSubmitting}
										disabled={disableCTA}
										type="submit"
										primary
										label="Proceed"
										extendStyles={{
											width: '100%',
										}}
									/>
								</div>
							</div>
						</form>
					</Box>
				</Box>
			</Box>
			<Spacer size={60} />
		</Box>
	)
}

export { UploadCertificate }
