import Duck from 'extensible-duck'
import { setIn, getIn } from 'timm'
import { initialState } from './config'

export const ProductsDuc = new Duck({
	namespace: 'products',
	store: 'global',
	types: [
		'SET_ACTIVE_MODULE',
		'FETCH_PRODUCTS_LISTING',
		'SET_PRODUCTS_LISTING',
		'FLUSH_PRODUCTS_LISTING',
		'PRODUCTS_PAGINATION_QUERY',
		'VIEW_PRODUCT_DETAILS',
		'VIEW_PRODUCT_DETAILS_SUCCESS',
		'ADD_PRODUCT',
		'EDIT_PRODUCT',
		'FETCH_PRODUCTION_LINE',
		'FETCH_PRODUCTS',
		'FETCH_INCOMING_PRODUCTS',
		'FETCH_OUTGOING_PRODUCTS',
		'FETCH_STORAGE_UNITS',
		'FETCH_INCOMING_STORAGE_UNITS',
		'FETCH_OUTGOING_STORAGE_UNITS',
		'SET_PRODUCTION_LINE',
		'SET_PRODUCTS',
		'SET_INCOMING_PRODUCTS',
		'SET_OUTGOING_PRODUCTS',
		'SET_STORAGE_UNITS',
		'SET_INCOMING_STORAGE_UNITS',
		'SET_OUTGOING_STORAGE_UNITS',
		'SET_PRODUCTION_LINE_LOADING',
		'SET_PRODUCTS_LOADING',
		'SET_INCOMING_PRODUCTS_LOADING',
		'SET_OUTGOING_PRODUCTS_LOADING',
		'SET_STORAGE_UNITS_LOADING',
		'SET_INCOMING_STORAGE_UNITS_LOADING',
		'SET_OUTGOING_STORAGE_UNITS_LOADING',
		'FETCH_PRODUCTS_LISTING_WITH_PAGINATION',
		'SET_PRODUCTS_LISTING_WITH_PAGINATION',
		'FLUSH_PRODUCTS_LISTING_WITH_PAGINATION',
	],
	initialState,
	reducer: (state, action, duck) => {
		switch (action.type) {
			case duck.types.SET_ACTIVE_MODULE: {
				return setIn(state, ['activeModule'], action.module)
			}

			case duck.types.SET_PRODUCTS_LISTING: {
				const { productList = [] } = action

				const existingList = getIn(state, ['productsListing']) || []
				const newList = existingList.concat(productList)

				return setIn(state, ['productsListing'], newList)
			}

			case duck.types.PRODUCTS_PAGINATION_QUERY: {
				const { query } = action

				return setIn(state, ['pagination'], query)
			}

			case duck.types.VIEW_PRODUCT_DETAILS_SUCCESS: {
				const { product } = action

				return setIn(state, ['activeProduct'], product)
			}

			case duck.types.SET_PRODUCTION_LINE: {
				const { productionLine } = action

				return setIn(state, ['productionLine'], productionLine)
			}

			case duck.types.SET_PRODUCTS: {
				const { products } = action

				return setIn(state, ['products'], products)
			}

			case duck.types.SET_INCOMING_PRODUCTS: {
				const { products } = action

				return setIn(state, ['incomingProducts'], products)
			}

			case duck.types.SET_OUTGOING_PRODUCTS: {
				const { products } = action

				return setIn(state, ['outgoingProducts'], products)
			}

			case duck.types.SET_STORAGE_UNITS: {
				const { storageUnits } = action

				return setIn(state, ['storageUnits'], storageUnits)
			}

			case duck.types.SET_INCOMING_STORAGE_UNITS: {
				const { storageUnits } = action

				return setIn(state, ['incomingStorageUnits'], storageUnits)
			}

			case duck.types.SET_OUTGOING_STORAGE_UNITS: {
				const { storageUnits } = action

				return setIn(state, ['outgoingStorageUnits'], storageUnits)
			}

			case duck.types.SET_PRODUCTION_LINE_LOADING: {
				const { status } = action

				return setIn(state, ['productionLineLoading'], status)
			}

			case duck.types.SET_PRODUCTS_LOADING: {
				const { status } = action

				return setIn(state, ['productsLoading'], status)
			}

			case duck.types.SET_INCOMING_PRODUCTS_LOADING: {
				const { status } = action

				return setIn(state, ['incomingProductsLoading'], status)
			}

			case duck.types.SET_OUTGOING_PRODUCTS_LOADING: {
				const { status } = action

				return setIn(state, ['outgoingProductsLoading'], status)
			}

			case duck.types.SET_STORAGE_UNITS_LOADING: {
				const { status } = action

				return setIn(state, ['storageUnitsLoading'], status)
			}

			case duck.types.SET_INCOMING_STORAGE_UNITS_LOADING: {
				const { status } = action

				return setIn(state, ['incomingStorageUnitsLoading'], status)
			}

			case duck.types.SET_OUTGOING_STORAGE_UNITS_LOADING: {
				const { status } = action

				return setIn(state, ['outgoingStorageUnitsLoading'], status)
			}

			case duck.types.FLUSH_PRODUCTS_LISTING: {
				return setIn(
					state,
					['productsListing'],
					initialState.productsListing
				)
			}

			case duck.types.SET_PRODUCTS_LISTING_WITH_PAGINATION: {
				const { listing } = action
				const initialArray = getIn(state, [
					'productsListingWithPagination',
				])

				const finalArray = initialArray.concat(listing)

				return setIn(
					state,
					['productsListingWithPagination'],
					finalArray
				)
			}

			case duck.types.FLUSH_PRODUCTS_LISTING_WITH_PAGINATION: {
				return setIn(
					state,
					['productsListingWithPagination'],
					initialState.productsListingWithPagination
				)
			}

			default:
				return state
		}
	},
	selectors: {
		auth: state => state.auth,
		location: state => state.location,
		activeModule: state =>
			getIn(state, ['products', 'activeModule']) || 'Error',
		getProductsListing: state =>
			getIn(state, ['products', 'productsListing']) || [],
		getProductsListWithPagination: state =>
			getIn(state, ['products', 'productsListingWithPagination']) || [],
		getPaginationQuery: state =>
			getIn(state, ['products', 'pagination']) || {},
		getProductInformation: state =>
			getIn(state, ['products', 'activeProduct']) || {},
		getProductionLine: state =>
			getIn(state, ['products', 'productionLine']) || [],
		getProducts: state => getIn(state, ['products', 'products']) || [],
		getIncomingProducts: state =>
			getIn(state, ['products', 'incomingProducts']) || [],
		getOutgoingProducts: state =>
			getIn(state, ['products', 'outgoingProducts']) || [],
		getStorageUnits: state =>
			getIn(state, ['products', 'storageUnits']) || [],
		getIncomingStorageUnits: state =>
			getIn(state, ['products', 'incomingStorageUnits']) || [],
		getOutgoingStorageUnits: state =>
			getIn(state, ['products', 'outgoingStorageUnits']) || [],
		getProductsLoadingState: state =>
			getIn(state, ['products', 'productionLineLoading']) ||
			getIn(state, ['products', 'productsLoading']) ||
			getIn(state, ['products', 'incomingProductsLoading']) ||
			getIn(state, ['products', 'outgoingProductsLoading']) ||
			getIn(state, ['products', 'storageUnitsLoading']) ||
			getIn(state, ['products', 'incomingStorageUnitsLoading']) ||
			getIn(state, ['products', 'outgoingStorageUnitsLoading']) ||
			false,
	},
	creators: duck => ({
		setActiveModule: module => ({
			type: duck.types.SET_ACTIVE_MODULE,
			module,
		}),
		fetchProductsListing: paginationParams => ({
			type: duck.types.FETCH_PRODUCTS_LISTING,
			paginationParams,
		}),
		setProductsListing: productList => ({
			type: duck.types.SET_PRODUCTS_LISTING,
			productList,
		}),
		productsPaginationQuery: query => ({
			type: duck.types.PRODUCTS_PAGINATION_QUERY,
			query,
		}),
		viewProductDetails: productID => ({
			type: duck.types.VIEW_PRODUCT_DETAILS,
			productID,
		}),
		viewProductDetailsSuccess: product => ({
			type: duck.types.VIEW_PRODUCT_DETAILS_SUCCESS,
			product,
		}),
		addProduct: (productDetails, toastMessage) => ({
			type: duck.types.ADD_PRODUCT,
			productDetails,
			toastMessage,
		}),
		editProduct: (details, prodID, toastMessage) => ({
			type: duck.types.EDIT_PRODUCT,
			details,
			prodID,
			toastMessage,
		}),
		fetchProductionLine: () => ({
			type: duck.types.FETCH_PRODUCTION_LINE,
		}),
		fetchProducts: () => ({
			type: duck.types.FETCH_PRODUCTS,
		}),
		fetchIncomingProducts: () => ({
			type: duck.types.FETCH_INCOMING_PRODUCTS,
		}),
		fetchOutgoingProducts: () => ({
			type: duck.types.FETCH_OUTGOING_PRODUCTS,
		}),
		fetchStorageUnits: () => ({
			type: duck.types.FETCH_STORAGE_UNITS,
		}),
		fetchIncomingStorageUnits: () => ({
			type: duck.types.FETCH_INCOMING_STORAGE_UNITS,
		}),
		fetchOutgoingStorageUnits: (storageType = '') => ({
			type: duck.types.FETCH_OUTGOING_STORAGE_UNITS,
			storageType,
		}),
		setProductionLine: productionLine => ({
			type: duck.types.SET_PRODUCTION_LINE,
			productionLine,
		}),
		setProducts: products => ({
			type: duck.types.SET_PRODUCTS,
			products,
		}),
		setIncomingProducts: products => ({
			type: duck.types.SET_INCOMING_PRODUCTS,
			products,
		}),
		setOutgoingProducts: products => ({
			type: duck.types.SET_OUTGOING_PRODUCTS,
			products,
		}),
		setStorageUnits: storageUnits => ({
			type: duck.types.SET_STORAGE_UNITS,
			storageUnits,
		}),
		setIncomingStorageUnits: storageUnits => ({
			type: duck.types.SET_INCOMING_STORAGE_UNITS,
			storageUnits,
		}),
		setOutgoingStorageUnits: storageUnits => ({
			type: duck.types.SET_OUTGOING_STORAGE_UNITS,
			storageUnits,
		}),
		setProductionLineLoading: status => ({
			type: duck.types.SET_PRODUCTION_LINE_LOADING,
			status,
		}),
		setProductsLoading: status => ({
			type: duck.types.SET_PRODUCTS_LOADING,
			status,
		}),
		setIncomingProductsLoading: status => ({
			type: duck.types.SET_INCOMING_PRODUCTS_LOADING,
			status,
		}),
		setOutgoingProductsLoading: status => ({
			type: duck.types.SET_OUTGOING_PRODUCTS_LOADING,
			status,
		}),
		setStorageUnitsLoading: status => ({
			type: duck.types.SET_STORAGE_UNITS_LOADING,
			status,
		}),
		setIncomingStorageUnitsLoading: status => ({
			type: duck.types.SET_INCOMING_STORAGE_UNITS_LOADING,
			status,
		}),
		setOutgoingStorageUnitsLoading: status => ({
			type: duck.types.SET_OUTGOING_STORAGE_UNITS_LOADING,
			status,
		}),
		flushProductsListing: () => ({
			type: duck.types.FLUSH_PRODUCTS_LISTING,
		}),
		fetchProductsListingWithPagination: (query, limit = 100) => ({
			type: duck.types.FETCH_PRODUCTS_LISTING_WITH_PAGINATION,
			query,
			limit,
		}),
		setProductsListWithPagination: listing => ({
			type: duck.types.SET_PRODUCTS_LISTING_WITH_PAGINATION,
			listing,
		}),
		flushProductsListingWithPagination: () => ({
			type: duck.types.FLUSH_PRODUCTS_LISTING_WITH_PAGINATION,
		}),
	}),
})
