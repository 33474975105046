/* @flow */
import React from 'react'
import styled from 'styled-components'

type Props = {
	for?: string,
	children: any,
	required?: boolean,
	style?: {},
	size?: string,
	link?: boolean,
	black?: boolean,
	onClick?: () => void,
}

const BaseLabel = styled.label(p => ({
	width: 'inherit',
	...(p.size === 'x-large' && { fontSize: p.theme.fontSize.xl }),
	// $FlowFixMe
	...(p.size === 'medium' && { fontSize: p.theme.fontSize.m }),
	lineHeight: p.plain ? 'initial' : '21px',
	color: p.black ? p.theme.color.black1 : p.theme.color.grey8,
	cursor: p.link ? 'pointer' : 'inherit',
	userSelect: 'auto',
	'::after': {
		content: p.required ? "' * '" : '',
		color: p.theme.color.error,
	},
}))

const Label = ({
	for: forInput,
	required,
	children,
	style,
	size,
	link,
	onClick,
	black,
}: Props) => {
	return (
		<BaseLabel
			link={link}
			htmlFor={forInput}
			required={required}
			style={style}
			size={size}
			onClick={onClick}
			black={black}
		>
			{children}
		</BaseLabel>
	)
}

export { Label }
