import React from 'react'
import { useSelector } from 'react-redux'
import { NotFoundBlock } from 'core-app/modules/App/components/Error'
import { StorageCompanyDuc } from './duc'
import { MainDashboard } from './containers/MainDashboard'
import { DocumentListing } from './containers/DocumentListing'
import { DocumentCreation } from './containers/DocumentCreation'
import { StorageListing } from './containers/StorageListing'

const modulesMap = {
	dashboard: MainDashboard,
	documentListing: DocumentListing,
	document: DocumentCreation,
	storageListing: StorageListing,
}

const StorageCompany = () => {
	const activeModule = useSelector(StorageCompanyDuc.selectors.activeModule)
	const Component = modulesMap[activeModule] || NotFoundBlock
	// if we are here, we have a submodule, route it to the module

	return <Component />
}

export default StorageCompany
