import React from 'react'
import { Box } from 'ui-lib/utils/Box'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Card } from 'ui-lib/components/Card'
import { currentPlatform } from 'ui-lib/utils/config'
import {
	ButtonWithNoBorder,
	ButtonIconWrapper,
	Button,
} from 'ui-lib/components/Button'
import { H3, SmallText } from 'ui-lib/components/Typography'
import AdminIcon from 'ui-lib/icons/admin.svg'
import { Title } from 'core-app/shared/components/Title'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AppDuc } from 'core-app/modules/App/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import theme from 'ui-lib/utils/base-theme'
import { Line } from 'core-app/modules/Settings/containers/MainDashboard'
import { Icon } from 'ui-lib/icons/components/Icon'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import { isMarketplaceOrg } from 'ui-lib/utils/helpers'

const MainDashboard = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const orgdetails = useSelector(AuthDuc.selectors.getCurrentOrgDetails)
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const actor = useSelector(AuthDuc.selectors.getActor)[0] || []
	const isOfficeManagement = actor.indexOf('officemgmt') !== -1
	const isBroker = actor.includes('palmoil_broker')
	const isDealerFlow =
		actor?.includes('palmoil_ffbdealer') ||
		actor?.includes('palmoil_ffbsubdealer') ||
		actor?.includes('palmoil_collectionpoint')

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.admin'),
			name: 'settings',
			isActive: false,
		},
	]

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
	}

	return (
		<Box padding={8} width="100%" height="100%">
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Box>
				<Title
					title={t('adminHome.title')}
					icon={AdminIcon}
					note={t('adminHome.note')}
				/>
			</Box>
			<Card
				width={isMobile ? '320px' : '100%'}
				height="auto"
				textAlign="left"
			>
				<Box
					padding={8}
					style={{
						backgroundColor: theme.color.accent3,
					}}
				>
					<H3
						bold
						color={theme.color.accent2}
						style={{
							position: 'relative',
							right: '-25px',
						}}
					>
						{t('adminHome.administrator')}
					</H3>
				</Box>

				{!isBroker &&
					!isMarketplaceOrg(orgdetails) &&
					currentPlatform() !== 'rice' && (
						<Box
							id="assetMgmt"
							row
							justifyContent="space-between"
							alignItems="center"
							height={70}
						>
							<SmallText
								fontSize={theme.fontSize.xl}
								style={{
									position: 'relative',
									right: '-25px',
								}}
							>
								{t('adminHome.assets')}
							</SmallText>
							<Box
								justifyContent="flex-end"
								width={isMobile ? 120 : 200}
							>
								<ButtonWithNoBorder
									label={t('common.viewBtn').toUpperCase()}
									onClick={() =>
										dispatch(
											MainRouteDuc.creators.switchPage(
												MainRouteDuc.types
													.ADMIN$SUBROOT,
												{
													rootModule: 'assets',
												}
											)
										)
									}
								/>
							</Box>
						</Box>
					)}
				<Line />
				<Box
					id="employeeMgmt"
					row
					justifyContent="space-between"
					alignItems="center"
					height={75}
				>
					<SmallText
						fontSize={theme.fontSize.xl}
						style={{
							position: 'relative',
							right: '-25px',
						}}
					>
						{t('adminHome.employeeMgmt')}
					</SmallText>
					<Box justifyContent="flex-end" width={isMobile ? 120 : 200}>
						<ButtonWithNoBorder
							label={t('common.viewBtn').toUpperCase()}
							onClick={() =>
								dispatch(
									MainRouteDuc.creators.switchPage(
										MainRouteDuc.types.ADMIN$SUBROOT,
										{
											rootModule: 'employee-management',
										}
									)
								)
							}
						/>
					</Box>
				</Box>
				<Line />
				{!isOfficeManagement && (
					<>
						<Box
							id="companyInfo"
							row
							justifyContent="space-between"
							alignItems="center"
							height={70}
						>
							<SmallText
								fontSize={theme.fontSize.xl}
								style={{
									position: 'relative',
									right: '-25px',
								}}
							>
								{t('adminHome.companyInfo')}
							</SmallText>
							<Box
								justifyContent="flex-end"
								width={isMobile ? 120 : 200}
							>
								<ButtonWithNoBorder
									label={t('common.viewBtn').toUpperCase()}
									onClick={() =>
										dispatch(
											MainRouteDuc.creators.switchPage(
												MainRouteDuc.types
													.ADMIN$SUBROOT,
												{
													rootModule:
														'company-information',
												}
											)
										)
									}
								/>
							</Box>
						</Box>
						<Line />
						{!isMarketplaceOrg(orgdetails) &&
							currentPlatform() !== 'rice' &&
							!isDealerFlow && (
								<Box
									id="otherSettings"
									row
									justifyContent="space-between"
									alignItems="center"
									height={70}
								>
									<SmallText
										fontSize={theme.fontSize.xl}
										style={{
											position: 'relative',
											right: '-25px',
										}}
									>
										{t('adminHome.otherSettings')}
									</SmallText>
									<Box
										justifyContent="flex-end"
										width={isMobile ? 120 : 200}
									>
										<ButtonWithNoBorder
											label={t(
												'common.viewBtn'
											).toUpperCase()}
											onClick={() =>
												dispatch(
													MainRouteDuc.creators.switchPage(
														MainRouteDuc.types
															.ADMIN$SUBROOT,
														{
															rootModule:
																'other-settings',
														}
													)
												)
											}
										/>
									</Box>
								</Box>
							)}
						<Line />
						<Box
							id="webhookApiSettings"
							row
							justifyContent="space-between"
							alignItems="center"
							height={70}
						>
							<SmallText
								fontSize={theme.fontSize.xl}
								style={{
									position: 'relative',
									right: '-25px',
								}}
							>
								{t('adminHome.webhookApiSettings')}
							</SmallText>
							<Box
								justifyContent="flex-end"
								width={isMobile ? 120 : 200}
							>
								<ButtonWithNoBorder
									label={t('common.viewBtn').toUpperCase()}
									onClick={() =>
										dispatch(
											MainRouteDuc.creators.switchPage(
												MainRouteDuc.types
													.ADMIN$SUBROOT,
												{
													rootModule:
														'webhook-api-settings',
												}
											)
										)
									}
								/>
							</Box>
						</Box>
					</>
				)}
			</Card>
			<Box padding="25px 0" style={{ maxWidth: 150 }}>
				<Button
					label={t('common.back')}
					rounded
					customIcon={
						<ButtonIconWrapper lightBG>
							<Icon glyph={LeftArrowIcon} />
						</ButtonIconWrapper>
					}
					onClick={() =>
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.DASHBOARD
							)
						)
					}
				/>
			</Box>
		</Box>
	)
}

export { MainDashboard }
