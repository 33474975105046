/* @flow */
import React from 'react'
import styled from 'styled-components'
import { lighten } from 'polished'
import { Icon, IconWrapper } from '../../icons/components/Icon'
import { Box } from '../../utils/Box'
import { H2, H3, Text, Label } from '../Typography'
import theme from '../../utils/base-theme'

type Props = {
	children?: any,
	onClick?: () => void,
	shadow?: boolean,
	width?: number | string,
	height?: number | string,
	icon?: string,
	label?: string,
	description?: string,
	color?: string,
	value?: string,
	status?: string,
	textAlign?: string,
	uom?: string,
	isMobile?: boolean,
	iconColor?: string,
	backgroundColor?: string,
	capacity?: string,
	filled?: string,
	tankName?: string,
	capacityLabel?: string,
	filledLabel?: string,
	cursor?: string,
	style?: Object,
	border?: string,
}

const Line = styled.div(p => ({
	width: p.size || 'inherit',
}))

const StatCardWrapper = styled.div(p => {
	let backgroundColor

	if (p.bgColor === 'profit')
		backgroundColor = lighten(0.4, p.theme.color.success)
	if (p.bgColor === 'loss')
		backgroundColor = lighten(0.4, p.theme.color.error)
	if (p.bgColor === 'neutral') backgroundColor = p.theme.color.white

	return {
		width: p.isMobile ? '110px' : '150px',
		height: p.height || 'auto',
		backgroundColor,
		border: p.bgColor === 'neutral' ? '1px solid #c9c9c9' : 'none',
		display: 'flex',
		padding: '5px',
	}
})

const LabelFont = styled(H2)(p => {
	let color

	if (p.status === 'profit') color = p.theme.color.success
	if (p.status === 'loss') color = p.theme.color.error
	if (p.status === 'neutral') color = p.theme.color.primary

	return {
		fontSize: p.isMobile ? theme.fontSize.xl : theme.fontSize.xxxl,
		color,
		lineHeight: '1.5',
	}
})

const BoxShadowWrapper = styled.div(p => ({
	width: p.width || '100%',
	height: p.height || 'auto',
	borderRadius: 4,
	backgroundColor: p.theme.color.white,
	border: `1px solid ${p.theme.color.grey4}`,
	overflow: p.scroll ? 'auto' : 'hidden',
	transition: 'border-color 0.3s',
	...(p.style ? p.style : {}),
}))

const RoundedBoxWrapper = styled(BoxShadowWrapper)(p => {
	return {
		width: p.isMobile ? '170px' : '250px',
		height: p.height,
		borderRadius: 4,
		backgroundColor: p.theme.color.white,
		margin: '0px 16px 16px 0px',
		overflow: p.scroll ? 'auto' : 'hidden',
	}
})

const CardBody = styled.div(p => ({
	cursor: p.cursor ? p.cursor : 'auto',
	textAlign: p.textAlign ? p.textAlign : 'center',
	color: p.theme.color.grey8,
	borderRadius: 4,
	overflow: p.isMobile ? 'auto' : 'hidden',
	width: '100%',
	height: '100%',
}))

const Card = ({
	children,
	onClick,
	width,
	height,
	cursor,
	textAlign,
	style,
	border,
	isMobile,
}: Props) => {
	// eslint-disable-next-line newline-before-return
	return (
		<BoxShadowWrapper
			width={width}
			height={height}
			style={style}
			border={border}
		>
			<CardBody
				onClick={onClick}
				cursor={cursor}
				textAlign={textAlign}
				isMobile={isMobile}
			>
				{children}
			</CardBody>
		</BoxShadowWrapper>
	)
}

Card.defaultProps = {
	isMobile: false,
	onClick: () => {},
}

const CardWithIcon = ({
	onClick,
	width,
	height,
	icon,
	label,
	description,
	backgroundColor,
	iconColor,
	isMobile,
}: Props) => {
	// eslint-disable-next-line newline-before-return
	return (
		<RoundedBoxWrapper isMobile={isMobile}>
			<CardBody onClick={onClick} cursor="pointer">
				<Box row>
					<Box
						width={isMobile ? '60px' : '90px'}
						padding={isMobile ? 20 : 15}
						center
						height={height}
						style={{ background: backgroundColor }}
					>
						<IconWrapper
							size={isMobile ? 35 : 45}
							color={iconColor}
						>
							<Icon glyph={icon} />
						</IconWrapper>
					</Box>
					<Box
						width={width}
						alignItems="flex-start"
						style={{
							padding: '8px 0 0 10px',
							maxWidth: '62%',
						}}
					>
						<H3
							color="rgba(0,0,0,1)"
							style={{
								textAlign: 'left',
							}}
						>
							{label}
						</H3>
						<Text
							color="rgba(0,0,0,0.6)"
							style={{
								fontSize: theme.fontSize.s,
								lineHeight: '1.3em',
								textAlign: 'left',
								cursor: 'pointer',
							}}
						>
							{description}
						</Text>
					</Box>
				</Box>
			</CardBody>
		</RoundedBoxWrapper>
	)
}

CardWithIcon.defaultProps = {
	onClick: () => {},
}

const StatCard = ({
	width,
	height,
	label,
	value,
	status,
	uom,
	isMobile,
}: Props) => {
	// eslint-disable-next-line newline-before-return
	return (
		<StatCardWrapper
			width={width}
			height={height}
			bgColor={status}
			isMobile={isMobile}
		>
			<Box width="inherit" justifyContent="space-between">
				<Box alignItems="flex-start">
					<Label style={{ fontSize: theme.fontSize.s }}>
						{label}
					</Label>
				</Box>
				<Line size={60} />
				<Box alignItems="center">
					<LabelFont status={status} isMobile={isMobile}>
						{value}{' '}
						<Label
							style={{
								fontSize: theme.fontSize.s,
								color: 'rgba(0,0,0,0.6)',
							}}
						>
							{uom}
						</Label>
					</LabelFont>
				</Box>
				<Box alignItems="flex-end">
					<Label
						style={{
							color: 'rgba(0,0,0,0.6)',
							fontSize: theme.fontSize.s,
						}}
					>
						Yield/Hectare
					</Label>
				</Box>
			</Box>
		</StatCardWrapper>
	)
}

StatCard.defaultProps = {
	onClick: () => {},
}

export { BoxShadowWrapper, Card, CardWithIcon, StatCard }
