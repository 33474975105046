import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { AppDuc } from 'core-app/modules/App/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { RefineryDuc } from 'core-app/modules/Refinery/duc'
import { Setup } from 'core-app/modules/App/containers/Setup'
import { Card } from 'ui-lib/components/Card'
import { H4, SmallText } from 'ui-lib/components/Typography'
import { Spacer } from 'ui-lib/utils/Spacer'
import { BarChart } from 'ui-lib/components/BarChart'
import { Box } from 'ui-lib/utils/Box'
import {
	KeyValueSegment,
	CustomPieChart,
	Line,
} from 'core-app/modules/Dashboard'
import theme from 'ui-lib/utils/base-theme'
import { getSalesSvg, getPurchaseSvg } from 'core-app/utils/helpers'

const MainDashboard = () => {
	const { t } = useTranslation()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const insights = useSelector(RefineryDuc.selectors.getInsightsData)
	const {
		incomingTankAvailable,
		outgoingTankAvailable,
		weighbridgeAvailable,
		partnersAvailable,
		employeesAvailable,
		languageSet,
	} = useSelector(AuthDuc.selectors.getFirstTimeStats)
	const {
		monthlySales = {},
		monthlyPurchase = {},
		salesPurchaseUOM,
		messageExchanged,
		extractionRatio = [],
		extractionRatioUOM,
		bestSuppliers = [],
		topProducts = [],
		topProductsUOM,
		certifiedSupply = [],
		certifiedSupplyUOM,
		supplierCategory = [],
		supplierCategoryUOM,
	} = insights
	const headingColor = theme.color.accent2
	const subHeadingColor = theme.color.grey10

	return (
		<>
			{(incomingTankAvailable === false ||
				outgoingTankAvailable === false ||
				weighbridgeAvailable === false ||
				partnersAvailable === false ||
				employeesAvailable === false ||
				languageSet === false) && <Setup />}
			<Box row={!isMobile}>
				<Card
					style={{
						margin: '12px 0 0',
						backgroundImage: getSalesSvg(monthlySales),
						backgroundSize: 'contain',
						backgroundPosition: 'bottom',
					}}
					textAlign="left"
					height="174px"
				>
					<Box>
						<H4
							style={{
								padding: '12px 12px 0px 12px',
								color: headingColor,
							}}
						>
							{t('millDashboard.salesHeading')}
						</H4>
						<SmallText
							style={{
								padding: '0 12px',
								color: subHeadingColor,
							}}
						>
							{t('refineryDashboard.subheading')}
						</SmallText>
					</Box>
					<Box style={{ padding: '12px 0 ' }}>
						<KeyValueSegment
							label={t('millDashboard.currentMonthSales')}
							value={`${monthlySales.currentMonth || 0} ${
								monthlySales.currentMonth
									? salesPurchaseUOM
									: ''
							} (${monthlySales.currentMonth * 1000} kg)`}
						/>
						<KeyValueSegment
							label={t('millDashboard.previousMonthSales')}
							value={`${monthlySales.previousMonth || 0} ${
								monthlySales.previousMonth
									? salesPurchaseUOM
									: ''
							} (${monthlySales.previousMonth * 1000} kg)`}
						/>
					</Box>
				</Card>

				<Card
					style={{
						margin: isMobile ? '12px 0' : '12px 12px 0',
						backgroundImage: getPurchaseSvg(monthlyPurchase),
						backgroundSize: 'contain',
						backgroundPosition: 'bottom',
					}}
					textAlign="left"
					height="174px"
				>
					<Box>
						<H4
							style={{
								padding: '12px 12px 0px 12px',
								color: headingColor,
							}}
						>
							{t('millDashboard.purchaseHeading')}
						</H4>
						<SmallText
							style={{
								padding: '0 12px',
								color: subHeadingColor,
							}}
						>
							{t('refineryDashboard.purchaseSubheading')}
						</SmallText>
					</Box>
					<Box style={{ padding: '12px 0 ' }}>
						<KeyValueSegment
							label={t('millDashboard.currentMonthSales')}
							value={`${monthlyPurchase.currentMonth || 0} ${
								monthlyPurchase.currentMonth
									? salesPurchaseUOM
									: ''
							} (${monthlyPurchase.currentMonth * 1000} kg)`}
						/>
						<KeyValueSegment
							label={t('millDashboard.previousMonthSales')}
							value={`${monthlyPurchase.previousMonth || 0} ${
								monthlyPurchase.previousMonth
									? salesPurchaseUOM
									: ''
							} (${monthlyPurchase.previousMonth * 1000} kg)`}
							labelWidth="40%"
						/>
					</Box>
				</Card>
			</Box>
			<Box row={!isMobile}>
				<Box width={isMobile ? 'auto' : '75%'}>
					<Box row={!isMobile}>
						<Card
							style={{
								margin: isMobile ? '0' : '12px 0 0',
								backgroundImage: "url('/images/blue-wave.svg')",
								backgroundSize: 'contain',
								backgroundPosition: 'bottom',
							}}
							textAlign="left"
							height={
								bestSuppliers.len || topProducts.len
									? ''
									: '236px'
							}
						>
							<Box>
								<H4
									style={{
										padding: '12px 12px 0px 12px',
										color: headingColor,
									}}
								>
									{t('refineryDashboard.topSuppliers')}
								</H4>
								<SmallText
									style={{
										padding: '0 12px',
										color: subHeadingColor,
									}}
								>
									{t('refineryDashboard.suppliersSubheading')}
								</SmallText>
							</Box>
							<CustomPieChart
								data={bestSuppliers}
								width={isMobile ? '150' : '170'}
								height={isMobile ? '150' : '170'}
								innerRadius="55"
								outerRadius="70"
							/>
						</Card>

						<Card
							style={{
								margin: isMobile ? '12px 0' : '12px 12px 0',
								backgroundImage: "url('/images/blue-wave.svg')",
								backgroundSize: 'contain',
								backgroundPosition: 'bottom',
							}}
							textAlign="left"
						>
							<Box>
								<H4
									style={{
										padding: '12px 12px 0px 12px',
										color: headingColor,
									}}
								>
									{t('refineryDashboard.topProducts')}
								</H4>
								<SmallText
									style={{
										padding: '0 12px',
										color: subHeadingColor,
									}}
								>
									{t('refineryDashboard.productsSubheading')}
								</SmallText>
							</Box>
							{topProducts.length ? (
								<Box
									row
									style={{ padding: '16px 8px 8px 16px' }}
								>
									<BarChart
										data={topProducts}
										width="150"
										height="150"
										UOM={topProductsUOM}
									/>
								</Box>
							) : (
								<Box
									height="150px"
									center
									style={{ color: '#08090C' }}
								>
									{t('common.errorBlockMessage')}
								</Box>
							)}
						</Card>
					</Box>

					<Box row={!isMobile}>
						<Card
							style={{
								margin: isMobile ? '0' : '12px 0 0',
								backgroundImage: "url('/images/blue-wave.svg')",
								backgroundSize: 'contain',
								backgroundPosition: 'bottom',
							}}
							textAlign="left"
							height={
								certifiedSupply.len || supplierCategory.len
									? ''
									: '248px'
							}
						>
							<Box>
								<H4
									style={{
										padding: '12px 12px 0px 12px',
										color: headingColor,
									}}
								>
									{t('refineryDashboard.certSuppliers')}
								</H4>
								<SmallText
									style={{
										padding: '0 12px',
										color: subHeadingColor,
									}}
								>
									{t(
										'refineryDashboard.certSuppliersSubheading'
									)}
								</SmallText>
							</Box>
							{certifiedSupply.length ? (
								<Box
									row
									style={{ padding: '16px 8px 0px 16px' }}
								>
									<BarChart
										data={certifiedSupply}
										width="150"
										height="150"
										UOM={certifiedSupplyUOM}
									/>
								</Box>
							) : (
								<Box
									height="150px"
									center
									style={{ color: '#08090C' }}
								>
									{t('common.errorBlockMessage')}
								</Box>
							)}
						</Card>

						<Card
							style={{
								margin: isMobile ? '12px 0 0' : '12px 12px 0',
								backgroundImage: "url('/images/blue-wave.svg')",
								backgroundSize: 'contain',
								backgroundPosition: 'bottom',
							}}
							textAlign="left"
						>
							<Box>
								<H4
									style={{
										padding: '12px 12px 0px 12px',
										color: headingColor,
									}}
								>
									{t('refineryDashboard.supplierCategories')}
								</H4>
								<SmallText
									style={{
										padding: '0 12px',
										color: subHeadingColor,
									}}
								>
									{t(
										'refineryDashboard.supplierCategorySubheading'
									)}
								</SmallText>
							</Box>
							{supplierCategory.length ? (
								<Box
									row
									style={{ padding: '16px 8px 0px 16px' }}
								>
									<BarChart
										data={supplierCategory}
										width="150"
										height="150"
										UOM={supplierCategoryUOM}
									/>
								</Box>
							) : (
								<Box
									height="150px"
									center
									style={{ color: '#08090C' }}
								>
									{t('common.errorBlockMessage')}
								</Box>
							)}
						</Card>
					</Box>
				</Box>
				<Box width={isMobile ? 'auto' : '25%'}>
					<Card
						style={{
							margin: isMobile ? '12px 0' : '12px 12px 0 0',
							height: '496px',
							width: 'auto',
							backgroundImage: "url('/images/blue-wave.svg')",
							backgroundSize: 'contain',
							backgroundPosition: 'bottom',
						}}
						textAlign="left"
					>
						<Box>
							<H4
								style={{
									padding: '12px 12px 0px 12px',
									color: headingColor,
								}}
							>
								{t('refineryDashboard.conversionRatio')}
							</H4>
						</Box>
						<Spacer size={20} />
						<Box>
							{extractionRatio.map(data => {
								return (
									<KeyValueSegment
										label={data.name}
										key={data.productID}
										value={`${
											data.ratio ? data.ratio : ''
										} ${
											data.ratio ? extractionRatioUOM : ''
										}`}
										title={data.name}
									/>
								)
							})}
						</Box>
						<Spacer size={20} />
						<Line />
						<Spacer size={20} />
						<Box>
							<H4
								style={{
									padding: '12px 12px 0px 12px',
									color: headingColor,
								}}
							>
								{t('refineryDashboard.msgsXchanged')}
							</H4>
							<SmallText
								style={{
									padding: '8px 12px 0px',
									color: subHeadingColor,
								}}
							>
								{t('refineryDashboard.msgsXchangesSubheading')}
							</SmallText>
						</Box>
						<Box style={{ padding: '10px 0' }}>
							<KeyValueSegment
								label={t('common.messages')}
								value={messageExchanged}
							/>
						</Box>
					</Card>
				</Box>
			</Box>
		</>
	)
}

export { MainDashboard }
