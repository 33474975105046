import React from 'react'
import { useSelector } from 'react-redux'
import { NotFoundBlock } from 'core-app/modules/App/components/Error'
import { PlantationDuc } from './duc'
import { MainDashboard } from './containers/MainDashboard'
import SubDashboard from './containers/SubDashboard'
import { TicketCreation } from './containers/TicketCreation'
import { BinCreation } from './containers/BinCreation'
import { Documents } from './containers/Documents'

const modulesMap = {
	plantation: MainDashboard,
	plantationSubDashboard: SubDashboard,
	ticket: TicketCreation,
	bin: BinCreation,
	document: Documents,
}

const Plantation = () => {
	const activeModule = useSelector(PlantationDuc.selectors.activeModule)

	const Component = modulesMap[activeModule] || NotFoundBlock
	// if we are here, we have a submodule, route it to the module

	return <Component />
}

export default Plantation
