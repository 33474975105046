import Duck from 'extensible-duck'
import { merge, setIn, getIn } from 'timm'

const initialState = {}

export const DeviceDetectionDuc = new Duck({
	namespace: 'app',
	store: 'global',
	types: ['SYNC_DEVICE_DETECTION'],
	initialState,
	reducer: (state, action, duck) => {
		if (!action) return state // will be used for ssr initial state
		const { type, detection } = action
		if (type === duck.types.SYNC_DEVICE_DETECTION) {
			const existingDetection = getIn(state, ['detection']) || {}

			return setIn(
				state,
				['detection'],
				merge(existingDetection, detection)
			)
		} else {
			return state
		}
	},
	selectors: {
		app: state => state.app,
		detection: state => getIn(state, ['app', 'detection']) || {},
	},

	creators: duck => ({
		syncDeviceDetection: detection => ({
			type: duck.types.SYNC_DEVICE_DETECTION,
			detection,
		}),
	}),
})
