export const SortableKeys = ['createdAt']

export const TABLE_ALIAS = {
	createdAt: 'dealerListing.date',
	smallholder: 'dealerListing.smallholder',
	ffbCount: 'dealerListing.ffbCount',
}

export const initialState = {
	activeModule: 'dealer',
	loading: false,
	error: false,
	insightsData: {},
	modules: {
		work: {
			loading: false,
			workListing: [],
			workDetails: {},
			pagination: {},
			dealerParticipantID: '',
			initialWorkDetails: {
				createdDate: new Date(),
				smallholderID: '',
				harvesterCount: '',
				ffbCount: '',
				area: '',
				block: '',
				ripeBunches: 0,
				overripeBunches: 0,
				underripeBunches: 0,
				emptyBunches: 0,
				wetBunches: 0,
				unripe: 0,
			},
		},
	},
}

const countryWithStateRegion = {
	malaysia: [
		{ state: 'Perlis', region: 'North/Utara' },
		{ state: 'Kedah', region: 'North/Utara' },
		{ state: 'Penang', region: 'North/Utara' },
		{ state: 'Perak', region: 'North/Utara' },
		{ state: 'Kelantan', region: 'East Coast/Pantai Timur' },
		{ state: 'Terengganu', region: 'East Coast/Pantai Timur' },
		{ state: 'Pahang', region: 'East Coast/Pantai Timur' },
		{ state: 'Selangor', region: 'Central/Tengah' },
		{ state: 'Kuala Lumpur', region: 'Central/Tengah' },
		{ state: 'Putrajaya', region: 'Central/Tengah' },
		{ state: 'Negeri Sembilan', region: 'South/Selatan' },
		{ state: 'Malacca', region: 'South/Selatan' },
		{ state: 'Johor', region: 'South/Selatan' },
		{ state: 'Sarawak', region: 'Sarawak' },
		{ state: 'Sabah', region: 'Sabah' },
	],
}

export const getRegion = (state, country) => {
	const trimState = state.trim().toLowerCase()
	const trimCountry = country.trim().toLowerCase()

	if (countryWithStateRegion[trimCountry] === undefined) return ''

	if (state && country) {
		const data = countryWithStateRegion[trimCountry].filter(
			d => d.state.toLowerCase() === trimState
		)
		if (data.length > 0) {
			return data[0].region
		}
	}

	return 'North/Utara' // fallback
}
