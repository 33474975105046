import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NotFoundBlock } from 'core-app/modules/App/components/Error'
import { WebTour } from 'core-app/modules/WebTour'
import { WebTourDuc } from 'core-app/modules/WebTour/duc'
import { MillDuc } from './duc'
import { MainDashboard } from './containers/MainDashboard'
import { SubDashboard } from './containers/SubDashboard'
import { ProductionDocCreation } from './containers/ProductionCreation'
import { DocumentListing } from './containers/DocumentListing'
import { Inventory } from './containers/ProductionCreation/Inventory'

const modulesMap = {
	dashboard: MainDashboard,
	subDashboard: SubDashboard,
	'production-plot-input': ProductionDocCreation,
	'production-plot-output': ProductionDocCreation,
	'sourced-batch': ProductionDocCreation,
	'produced-batch': ProductionDocCreation,
	documentListing: DocumentListing,
	inventory: Inventory,
}

const Mill = () => {
	const dispatch = useDispatch()
	const activeModule = useSelector(MillDuc.selectors.activeModule)

	const Component = modulesMap[activeModule] || NotFoundBlock

	// if we are here, we have a submodule, route it to the module
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)
	useEffect(() => {
		if (tourModule === 'production') {
			dispatch(WebTourDuc.creators.setProductionVisit(true))
		}
		if (tourModule === 'createIpBatch') {
			dispatch(WebTourDuc.creators.setCreateIpBatch(true))
		}
	}, [tourModule, dispatch])

	return (
		<>
			<Component />
			{(tourModule === 'production' ||
				tourModule === 'createIpBatch') && <WebTour />}
		</>
	)
}

export default Mill
