export const ORG_STATUS_TEXT_COLOR = {
	pending: '#F09112',
	verified: '#158853',
	active: '#228686',
	'in-review': '#574D9B',
	unverified: '#BF3333',
	rejected: '#BF3333',
}

export const ORG_STATUS_BACKGROUND = {
	pending: '#FFEED4',
	verified: '#C9F7C3',
	active: '#A1E6E6',
	'in-review': '#D0CCFA',
	unverified: '#F8BFBF',
	rejected: '#F8BFBF',
}

export const chatAccessBasedOnActor = {
	owner: 'master-chat',
	administrator: 'master-chat',
	'office-management': 'saas-tdm-chat',
	'sales-marketing-officer': 'marketplace-chat',
	'purchase-officer': 'marketplace-chat',
}
