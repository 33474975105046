import React, { useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn } from 'timm'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { CookieDuc } from 'core-app/modules/App/cookieDuc'
import { GeneralSchedulerDuc } from 'core-app/modules/GeneralScheduler/duc'
import { Reschedule } from 'core-app/modules/GeneralScheduler/components/Reschedule'
import { EditSchedule } from 'core-app/modules/GeneralScheduler/components/EditSchedule'
import { getDateByFormat } from 'core-app/utils/date'
import { SERVICE_TYPES } from 'core-app/modules/GeneralScheduler/config'
import { Box } from 'ui-lib/utils/Box'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Select } from 'ui-lib/components/Select'
import { ButtonWithNoBorder } from 'ui-lib/components/Button'
import { Modal } from 'ui-lib/components/Modal'
import { Label, P } from 'ui-lib/components/Typography'
import theme from 'ui-lib/utils/base-theme'

export const CustomCard = styled.div(p => ({
	width: p.width,
	height: p.height,
}))

const KeyValueSegment = ({ name, description }) => {
	return (
		<Box
			row
			padding="0 0 36px 0"
			justifyContent="flex-start"
			alignItems="flex-start"
		>
			<Box style={{ width: 150 }}>
				<Label bold style={{ fontSize: theme.fontSize.xl }}>
					{name}
				</Label>
			</Box>
			<Box
				style={{
					marginLeft: 44,
					width: 250,
					color: theme.color.accent2,
				}}
				flex
				justifyContent="center"
				alignItems="flex-start"
			>
				{description || '---'}
			</Box>
		</Box>
	)
}

const ViewSchedule = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const [deleteModalStatus, setDeleteModalStatus] = useState(false)

	const [editScheduleModalStatus, setEditScheduleModalStatus] = useState(
		false
	)
	const rescheduleModalStatus = useSelector(
		GeneralSchedulerDuc.selectors.getRescheduleModalStatus
	)
	const selectedCPID = useSelector(CookieDuc.selectors.getSelectedCPID)

	const locationState = useSelector(GeneralSchedulerDuc.selectors.location)
	const { payload = {} } = locationState
	const { documentReference } = payload
	const details = useSelector(
		GeneralSchedulerDuc.selectors.getScheduleDetails
	)
	const {
		status,
		participantID,
		clientID,
		type,
		startAt,
		createdAt,
		previousOccurrence,
		orgScheduleParticipantID,
		netWeight,
		uom,
	} = details

	// Extract driver Name
	const userList = useSelector(AuthDuc.selectors.getOrgUsers)
	const user = userList.filter(usr => usr.id === clientID)
	const driver = getIn(user, [0, 'fullName'])

	// Extract Supplier Name
	const orgDetails = useSelector(AuthDuc.selectors.getTargetPartnersOfUser)
	const organisation = Object.values(orgDetails).filter(
		org => org.id === participantID
	)

	const supplier = getIn(organisation, [0, 'name'])

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.scheduler'),
			name: 'scheduler',
			isActive: true,
		},
		{
			label: t('breadcrumb.viewSchedule'),
			name: 'view-schedule',
			isActive: false,
		},
	]

	/** Handlers to react on actions */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'scheduler') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.GENERAL_SCHEDULER
				)
			)
		}
	}

	const handleEditSchedule = scheduleDetails => {
		const newScheduleDetails = {
			orgScheduleParticipantID,
			participantID: getIn(scheduleDetails, ['participantID']),
			type: getIn(scheduleDetails, ['type']),
			startAt,
		}

		setEditScheduleModalStatus(false)
		dispatch(
			GeneralSchedulerDuc.creators.editSchedule(
				newScheduleDetails,
				documentReference,
				t('createSchedule.updateScheduleToast')
			)
		)
	}

	return (
		<Box padding={8} width="100%" height="100%">
			<Modal
				forceCloseviaButton
				size="large"
				show={deleteModalStatus}
				heading="Delete Schedule"
				body={
					<P large bold>
						{t(
							'schedulerListing.areYouSureYouWantToDeleteTheSelecteSchedule'
						)}
					</P>
				}
				closelabel={t('common.no')}
				confirmlabel={t('common.yes')}
				onClose={() => setDeleteModalStatus(false)}
				onConfirm={() => {
					setDeleteModalStatus(false)
					dispatch(
						GeneralSchedulerDuc.creators.deleteSchedules(
							[documentReference],
							t('toast.deleteSuccess'),
							true
						)
					)
				}}
			/>
			<Modal
				forceCloseviaButton
				size="auto"
				show={rescheduleModalStatus}
				body={
					<Reschedule
						onConfirm={values => {
							dispatch(
								GeneralSchedulerDuc.creators.setRescheduleModalStatus(
									false
								)
							)
							dispatch(
								GeneralSchedulerDuc.creators.multipleReschedules(
									[
										{
											scheduleID: documentReference,
											scheduleParticipantID: orgScheduleParticipantID,
										},
									],
									values,
									t('common.rescheduleSuccess')
								)
							)
						}}
						onClose={() => {
							dispatch(
								GeneralSchedulerDuc.creators.setRescheduleModalStatus(
									false
								)
							)
						}}
					/>
				}
				hideButtons
			/>
			<EditSchedule
				show={editScheduleModalStatus}
				onClose={() => {
					setEditScheduleModalStatus(false)
				}}
				onConfirm={values => {
					handleEditSchedule(values)
				}}
			/>
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>

			<CustomCard
				style={{
					padding: '24px 0 0 36px',
					margin: '40px 0 0',
					background: '#ffffff 0% 0% no-repeat padding-box',
					boxShadow: '0px 6px 16px #172B4D14',
					border: '1px solid #cad2dd',
					borderRadius: '4px',
					opacity: 1,
				}}
			>
				<Label bold style={{ fontSize: '32px' }}>
					{t('viewSchedule.cardHeading')}
				</Label>

				{!selectedCPID && (
					<Box row justifyContent="space-between" margin="20px 0">
						{(status === 'pending' || status === 'upcoming') && (
							<>
								<Select
									options={[
										{
											name: 'upcoming',
											label: t('schedulerListing.status'),
											color: '#ABB3BE',
										},
										{
											name: 'intransit',
											label: t(
												'schedulerListing.intransit'
											).toUpperCase(),
											color: '#DB9C1F',
										},
										{
											name: 'pending',
											label: t(
												'schedulerListing.pending'
											).toUpperCase(),
											color: '#378AEC',
										},
										{
											name: 'delivered',
											label: t(
												'schedulerListing.delivered'
											).toUpperCase(),
											color: '#4AA025',
										},
									]}
									labelKey="label"
									valueKey="name"
									key={status}
									value={status}
									onChange={({ name }) => {
										dispatch(
											GeneralSchedulerDuc.creators.scheduleStatusUpdate(
												name,
												documentReference,
												t(
													'schedulerListing.statusUpdateToast'
												)
											)
										)
									}}
									hideError
									returnOnlyValue
									customInputPadding={8}
									style={{
										background: '#FAFAFC',
										borderRadius: '18px',
										width: '150px',
										border: 'none',
									}}
								/>

								<Box row alignItems="baseline" margin="0 20px">
									<Box margin={8}>
										<ButtonWithNoBorder
											label={t('common.edit')}
											textDecoration="none"
											color="#2680EB"
											fontSize={theme.fontSize.xl}
											onClick={() => {
												setEditScheduleModalStatus(true)
											}}
										/>
									</Box>
									<Box margin={8}>
										<ButtonWithNoBorder
											label={t(
												'schedulerListing.reschedule'
											)}
											textDecoration="none"
											fontSize={theme.fontSize.xl}
											color="#845C9A"
											onClick={() => {
												dispatch(
													GeneralSchedulerDuc.creators.setRescheduleModalStatus(
														true
													)
												)
											}}
										/>
									</Box>
									<Box margin={8}>
										<ButtonWithNoBorder
											label={t('common.delete')}
											textDecoration="none"
											fontSize={theme.fontSize.xl}
											color={theme.color.primary}
											onClick={() => {
												setDeleteModalStatus(true)
											}}
										/>
									</Box>
								</Box>
							</>
						)}
					</Box>
				)}

				<Box row justifyContent="space-between">
					<Box>
						<KeyValueSegment
							name={t('productTrace.supplierName')}
							description={supplier}
						/>
						<KeyValueSegment
							name={t('viewSchedule.scheduledDate')}
							description={getDateByFormat(startAt)}
						/>
						<KeyValueSegment
							name={t('viewSchedule.serviceType')}
							description={t(SERVICE_TYPES[type])}
						/>
						<KeyValueSegment
							name={t('viewSchedule.createdOn')}
							description={getDateByFormat(createdAt)}
						/>
						{type === 'palmoil-service-transport' && (
							<KeyValueSegment
								name={t('viewSchedule.driver')}
								description={driver}
							/>
						)}
						<KeyValueSegment
							name={
								type === 'palmoil-service-harvest'
									? t('viewSchedule.prevHarvest')
									: t('viewSchedule.prevService')
							}
							description={getDateByFormat(previousOccurrence)}
						/>
					</Box>
					{type === 'palmoil-service-transport' && (
						<Box style={{ padding: '4px 40px 0 0' }}>
							<CustomCard
								width="202px"
								height="218px"
								style={{
									background:
										'#F2F4FF 0% 0% no-repeat padding-box',
									color: theme.color.blue5,
									borderRadius: '4px',
									opacity: 1,
									padding: '20px 0 0 28px',
								}}
							>
								<Label
									bold
									style={{
										fontSize: theme.fontSize.xxl,
										color: theme.color.blue5,
									}}
								>
									{t('viewSchedule.netWeight')}
								</Label>
								<div
									style={{
										fontSize: theme.fontSize.xs,
										margin: '4px 0',
									}}
								>
									{t('viewSchedule.netWeightDesc')}
								</div>
								<div
									style={{
										fontSize: '36px',
										margin: '40px 0',
										fontWeight: 'bold',
									}}
								>
									{netWeight || 'N/A'}&nbsp;
									{netWeight && (
										<Label
											style={{
												fontSize: theme.fontSize.xxl,
												color: theme.color.blue5,
											}}
										>
											{uom}
										</Label>
									)}
								</div>
							</CustomCard>
						</Box>
					)}
				</Box>
			</CustomCard>
		</Box>
	)
}

export { ViewSchedule }
