import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MainRouteDuc } from 'core-app/routes/duc'
import { SmallText } from 'ui-lib/components/Typography'
import { Button } from 'ui-lib/components/Button'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import theme from 'ui-lib/utils/base-theme'

const AddCPMenu = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	return (
		<Box
			style={{
				width: '100vw',
				height: '100vh',
				background: ' #1D285D',
				overflowY: 'scroll',
			}}
			center
			padding={8}
		>
			<Box center>
				<Box
					style={{
						width: '770px',
						height: 'fit-content',
						background: theme.color.white,
						borderRadius: '10px',
						boxShadow: '0px 3px 6px #00000029',
					}}
					padding={25}
					alignItems="center"
				>
					<Box style={{ width: '583px' }}>
						<Spacer size={20} />
						<SmallText
							style={{
								color: '#2D2D2D',
								fontSize: '32px',
								lineHeight: '32px',

								fontWeight: 'bold',
								textAlign: 'center',
							}}
						>
							{t('onboarding.collectionPt4')}
						</SmallText>
						<Spacer size={18} />
						<SmallText>{t('onboarding.collectionPt5')}</SmallText>
						<Spacer size={60} />
						<Box row justifyContent="space-between">
							<Box>
								<Button
									action
									rounded
									label={t('onboarding.noCollectionPt')}
									reverse
									onClick={() => {
										dispatch(
											MainRouteDuc.creators.switchPage(
												MainRouteDuc.types
													.ONBOARD$ACTION,
												{
													action:
														'success-with-no-cp',
												}
											)
										)
									}}
								/>
							</Box>

							<Box>
								<Button
									action
									rounded
									label={t('onboarding.addCollectionPt')}
									reverse
									onClick={() => {
										dispatch(
											MainRouteDuc.creators.switchPage(
												MainRouteDuc.types
													.ONBOARD$ACTION,
												{
													action:
														'add-collection-point',
												}
											)
										)
									}}
									extendStyles={{
										background: '#3F56C4',
										color: theme.color.white,
									}}
								/>
							</Box>
						</Box>
						<Spacer size={60} />
						<SmallText>{t('onboarding.collectionPt6')}</SmallText>
						<Spacer size={20} />
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

export { AddCPMenu }
