import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AppDuc } from 'core-app/modules/App/duc'
import { Box } from 'ui-lib/utils/Box'
import { Input } from 'ui-lib/components/Input'
import { Spacer } from 'ui-lib/utils/Spacer'
import { ModuleIconMap } from 'ui-lib/utils/config'
import { Button } from 'ui-lib/components/Button'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { SelectSearch } from 'ui-lib/components/Select'

import { isEmptyObject } from 'core-app/utils/helpers'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { WebTour } from 'core-app/modules/WebTour'
import { WebTourDuc } from 'core-app/modules/WebTour/duc'
import { addLast, getIn } from 'timm'
import { CollapsableSegment } from 'ui-lib/components/CollapsableSegments'
import { DatePicker } from 'ui-lib/components/Datepicker'
import { Title } from 'core-app/shared/components/Title'
import { BoxShadowTable } from 'core-app/shared/components/BoxShadowTable'
import { NAME_ALIASES, LabelsMap, PlantationDuc } from '../../duc'
import { getUnassignedTicketColumnConfig } from '../../components/Columns'

const CreateBin = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const allProducts = useSelector(AuthDuc.selectors.getProducts)

	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const locationState = useSelector(PlantationDuc.selectors.location)
	const orgDetails = useSelector(AuthDuc.selectors.getAvailableOrgs)

	const initialValues = useSelector(
		PlantationDuc.selectors.fetchBinInitialValues
	)
	const assets = useSelector(AuthDuc.selectors.getOrgAssets)
	const vehicleList = (getIn(assets, ['tractorList']) || []).map(vehicle => {
		return {
			name: getIn(vehicle, ['id']),
			label: getIn(vehicle, ['meta', 'name']),
		}
	})
	const binList = (getIn(assets, ['storageunits']) || []).map(
		({ id, name }) => {
			return { name: id, label: name }
		}
	)
	const isLoading = useSelector(
		PlantationDuc.selectors.getListingLoadingStatus
	)

	const activeSorts = useSelector(
		PlantationDuc.selectors.getMainDashboardActiveSorts
	)

	const ticketListing = useSelector(
		PlantationDuc.selectors.unAssignedTicketListing
	)

	const [startDate, setStartDate] = useState(new Date())

	const { payload = {} } = locationState
	const { rootModule, action } = payload
	const TitleIcon = ModuleIconMap[rootModule]
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)

	useEffect(() => {
		if (tourModule === 'createBin') {
			dispatch(WebTourDuc.creators.setActiveTourModule('createBin2'))
		}
	}, [tourModule, dispatch])
	const getBreadCrumbsList = useCallback(
		(title, actionTitle) => [
			{
				label: t('breadcrumb.home'),
				name: 'home',
				isActive: true,
			},
			{
				label: t('breadcrumb.bins'),
				name: 'bins',
				isActive: true,
			},
			{
				label: ` ${t(actionTitle)} ${t(title)}`,
				name: 'submodule-dashboard',
				isActive: false,
			},
		],
		[t]
	)

	const validationSchema = yup.object().shape({
		vehicleType: yup.string().required(t('validation.vehicleTypeRequired')),
		vehicleNumber: yup
			.string()
			.required(t('validation.vehicleNumRequired')),
		crewMember: yup.string().required(t('validation.crewMemberRequired')),
		binNumber: yup.string().required(t('validation.binLabelRequired')),
		tickets: yup
			.array()
			.of(yup.string())
			.required(t('validation.ticketsRequired')),
	})

	/** Handlers */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'bins') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.PLANTATION$SUBROOT,
					{ rootModule: 'bin' }
				)
			)
		}
	}

	const title = LabelsMap[rootModule]
	const actionTitle = NAME_ALIASES[action]

	const breadCrumbs = useMemo(() => getBreadCrumbsList(title, actionTitle), [
		title,
		actionTitle,
		getBreadCrumbsList,
	])

	const {
		handleSubmit,
		submitForm,
		values,
		handleChange,
		handleBlur,
		touched,
		errors,
		setFieldValue,
		isSubmitting,
	} = useFormik({
		initialValues,
		enableReinitialize: true,
		validationSchema,
		onSubmit: _values => {
			dispatch(
				PlantationDuc.creators.createBin(
					_values,
					t('common.successCreate')
				)
			)
		},
	})

	const handleOnChange = (actionType, meta = {}) => {
		if (actionType === 'set_assign_ticket') {
			const { action: __action } = meta
			const tickets = values.tickets || []
			const targetTickets =
				__action === 'add'
					? addLast(tickets, meta.id)
					: tickets.filter(id => id !== meta.id)
			setFieldValue('tickets', targetTickets)
		}
	}

	const disableCTA = !isEmptyObject(errors) || !values.binNumber

	return (
		<>
			<Box padding={8} width="100%" height="100%">
				<Box style={{ padding: '0 5' }}>
					<Breadcrumb
						links={breadCrumbs}
						onClick={target => handleBreadCrumbClick(target)}
					/>
				</Box>

				<Box row justifyContent="space-between" alignItems="baseline">
					<Title
						title={`${t(actionTitle)} ${t('createBin.bin')}`}
						icon={TitleIcon}
					/>
				</Box>
				<Spacer size={8} />
				<Box
					row
					justifyContent="space-between"
					alignItems="center"
					style={{ paddingBottom: 24 }}
				>
					<Box
						style={{
							marginTop: '16px',
							marginRight: '8px',
							width: 255,
						}}
					>
						<DatePicker
							value={startDate}
							onChange={value => {
								setStartDate(value)
								setFieldValue('createdDate', new Date(value))
							}}
							placeholder={t('common.selectDate')}
						/>
					</Box>
				</Box>

				<CollapsableSegment
					expand
					title={`${t(actionTitle)} ${t('createBin.bin')}`}
					hasError={disableCTA}
					open
					message={
						disableCTA
							? t('validation.collapsableSegmentWarning')
							: t('validation.collapsableSegmentSuccess')
					}
					onToggle={() => undefined}
				>
					<Box padding={isMobile ? '16px' : '24px'}>
						<form onSubmit={handleSubmit}>
							<Spacer size={16} />
							<Box id="input-enterBinDetails">
								<Box row={!isMobile}>
									<Box width={300}>
										<Box id="input-chooseVehicle">
											<SelectSearch
												value={values.vehicleType}
												label={t(
													'createBin.chooseVehicle'
												)}
												name="vehicleType"
												options={vehicleList}
												onChange={value => {
													setFieldValue(
														'vehicleType',
														value.name
													)
													const vehicleNumber = (
														getIn(assets, [
															'tractorList',
														]) || []
													).filter(
														tractor =>
															tractor.id ===
															value.name
													)
													setFieldValue(
														'vehicleNumber',
														getIn(vehicleNumber, [
															0,
															'number',
														])
													)
												}}
												returnOnlyValue
												labelKey="label"
												valueKey="name"
												defaultLabel={t(
													'common.selectDefaultLabel'
												)}
												onBlur={handleBlur}
												error={
													touched.vehicleType &&
													errors.vehicleType
												}
												required
											/>
										</Box>
									</Box>
									<Spacer size={16} />

									<Box width={300}>
										<Input
											label={t('createBin.vehicleNumber')}
											value={values.vehicleNumber}
											name="vehicleNumber"
											disabled
											type="text"
											onChange={handleChange}
											onBlur={handleBlur}
											error={
												touched.vehicleNumber &&
												errors.vehicleNumber
											}
											required
										/>
									</Box>
								</Box>

								<Spacer size={16} />
								<Box row={!isMobile}>
									<Box width={300}>
										<Input
											label={t('createBin.crewMember')}
											value={values.crewMember}
											name="crewMember"
											type="text"
											onChange={handleChange}
											onBlur={handleBlur}
											error={
												touched.crewMember &&
												errors.crewMember
											}
											required
										/>
									</Box>
									<Spacer size={16} />
									<Box width={300}>
										<SelectSearch
											value={values.binNumber}
											label={t('createBin.binLabel')}
											name="binNumber"
											options={binList}
											onChange={value => {
												setFieldValue(
													'binNumber',
													value.name
												)
											}}
											returnOnlyValue
											labelKey="label"
											valueKey="name"
											defaultLabel={t(
												'common.selectDefaultLabel'
											)}
											onBlur={handleBlur}
											error={
												touched.binNumber &&
												errors.binNumber
											}
											required
										/>
									</Box>
								</Box>
								<Spacer size={16} />
							</Box>
							<Box id="input-selectTickets">
								{action === 'edit' && (
									<>
										<Title
											title={t(
												'createBin.assignedTickets'
											)}
										/>
										<Spacer size={4} />

										<BoxShadowTable
											isLoading={isLoading}
											size="small"
											columnConfig={getUnassignedTicketColumnConfig(
												{
													type: rootModule,
													docType: 'ticket',
													sortsMap: activeSorts,
													onChange: handleOnChange,
													checkStatus: true,
													orgDetails,
													allProducts,
													t,
												}
											)}
											rowData={ticketListing.assigned}
										/>
										<Spacer size={16} />
									</>
								)}

								<Title
									title={t('createBin.unAssignedTickets')}
								/>
								<Spacer size={4} />

								<BoxShadowTable
									isLoading={isLoading}
									size="small"
									columnConfig={getUnassignedTicketColumnConfig(
										{
											type: rootModule,
											docType: 'ticket',
											sortsMap: activeSorts,
											onChange: handleOnChange,
											orgDetails,
											allProducts,
											t,
										}
									)}
									rowData={ticketListing.unAssigned}
								/>
							</Box>
						</form>
					</Box>
				</CollapsableSegment>

				<Spacer size={24} />

				<Box row justifyContent="flex-end">
					<Box style={{ padding: 2 }} width="200px">
						<Box id="input-updateCreateBin">
							<Button
								label={`${t(actionTitle)}`}
								primary
								disabled={disableCTA}
								isLoading={isSubmitting}
								rounded
								onClick={() => {
									submitForm()
									dispatch(
										WebTourDuc.creators.setActiveTourModule(
											''
										)
									)
								}}
							/>
						</Box>
					</Box>
				</Box>
			</Box>
			{tourModule === 'createBin2' && <WebTour />}
		</>
	)
}

export { CreateBin }
