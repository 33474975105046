/* @flow */

/** Helpers */
export const getStartIndex = (currentIndex: number, currentLimit: number) =>
	Math.max(1, currentIndex * currentLimit + 1)

export const getEndIndex = (
	currentIndex: number,
	currentLimit: number,
	totalPageCount: number
) => Math.min(totalPageCount, (Math.max(currentIndex, 0) + 1) * currentLimit)

export const getMaxIndex = (currentLimit: number, totalPageCount: number) =>
	Math.max(0, Math.floor(totalPageCount / currentLimit))

export const boundIndexRange = (
	currentIndex: number,
	currentLimit: number,
	totalPageCount: number
) =>
	Math.max(
		0,
		Math.min(currentIndex, getMaxIndex(currentLimit, totalPageCount))
	)
