import { TripsDuc } from 'core-app/modules/Trips/duc'
import { MainRouteDuc } from 'core-app/routes/duc'
import saga from 'core-app/modules/Trips/TripsSaga'
import { getIn } from 'timm'

export default async (dispatch, getState, { action, extra }) => {
	const { type, customMeta = {} } = action
	const currentLocationFromAction = getIn(action, [
		'meta',
		'location',
		'current',
	])
	/** if someone specifically wants to skip thunk fetch again, this check helps */
	const { skipRouteThunk } = customMeta
	if (skipRouteThunk) return

	// check if nothing changed in the route.

	const state = getState()

	const locationState = currentLocationFromAction || state.location

	const { payload } = locationState

	const documentReference = getIn(payload, ['documentReference'])

	// Inject the saga here. If it wasn't already
	extra.getSagaInjectors().injectSaga('TRIPS', { saga })

	const isMainDashboard = MainRouteDuc.types.TRIPS === type
	const isDocumentView = MainRouteDuc.types.TRIPS$WDOCREFERENCE === type

	let activeModule = ''

	if (isMainDashboard) {
		// load the dashboard of the core module
		activeModule = 'dashboard'
		dispatch(
			TripsDuc.creators.fetchDocuments(
				'document',
				payload.rootModule,
				locationState
			)
		)
	} else if (isDocumentView) {
		activeModule = 'document'
		dispatch(TripsDuc.creators.viewDocument(documentReference))
	}
	// load the dashboard of the core module
	dispatch(TripsDuc.creators.setActiveModule(activeModule))
}
