import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn } from 'timm'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AppDuc } from 'core-app/modules/App/duc'
import { PartnerDuc } from 'core-app/modules/Partner/duc'
import { CookieDuc } from 'core-app/modules/App/cookieDuc'
import { getDateByFormat } from 'core-app/utils/date'
import { Title } from 'core-app/shared/components/Title'
import { Modal } from 'ui-lib/components/Modal'
import { FileLists } from 'ui-lib/components/FileUpload'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { H3, P, Text } from 'ui-lib/components/Typography'
import { Card } from 'ui-lib/components/Card'
import { ButtonIconWrapper, Button } from 'ui-lib/components/Button'
import theme from 'ui-lib/utils/base-theme'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import { Icon } from 'ui-lib/icons/components/Icon'
import PartnerIcon from 'ui-lib/icons/partner.svg'
import { TextArea } from 'ui-lib/components/TextArea'
import { Label } from 'ui-lib/components/Label'
import { Status } from 'core-app/shared/components/Status'
import { Timeline } from 'ui-lib/components/Timeline/index'
import { WebTourDuc } from '../../../WebTour/duc'

const partnerInfoKeys = [
	'status',
	'name',
	'category',
	'email',
	'phoneNo',
	'address',
	'pinCode',
	'city',
	'state',
	'country',
]

const ALIASES_KEYS = {
	status: 'viewPartner.status',
	name: 'viewPartner.name',
	category: 'viewPartner.category',
	city: 'viewPartner.city',
	state: 'viewPartner.state',
	country: 'viewPartner.country',
	pinCode: 'viewPartner.pincode',
	phoneNo: 'viewPartner.phoneNo',
	email: 'viewPartner.email',
	address: 'viewPartner.address',
}

const ViewPartner = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)
	if (tourModule !== '') {
		dispatch(WebTourDuc.creators.setActiveTourModule(''))
	}
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const location = useSelector(PartnerDuc.selectors.location)
	const partnerList = useSelector(PartnerDuc.selectors.getPartnerList)
	const selectedCPID = useSelector(CookieDuc.selectors.getSelectedCPID)
	const canDelete = getIn(location, ['query', 'delete']) && !selectedCPID

	const certificates = useSelector(PartnerDuc.selectors.getCertificateDetails)
	const partnerInfo = useSelector(PartnerDuc.selectors.getPartnerInfo) || {}
	const showAcceptModal = useSelector(
		PartnerDuc.selectors.getViewPageAcceptModalStatus
	)

	const showDeclineModal = useSelector(
		PartnerDuc.selectors.getViewPageDeclineModalStatus
	)

	const showDeleteSuccessModal = useSelector(
		PartnerDuc.selectors.getDeletePartnerModalStatus
	)

	const requestSuccessModal = useSelector(
		PartnerDuc.selectors.getSendPartnerRequestModalStatus
	)

	const partnerStatus = useSelector(
		PartnerDuc.selectors.getPartnerRequestStatus
	)

	const partnershipTimeline = useSelector(
		PartnerDuc.selectors.getPartnershipTimeline
	)

	const farmCertificates = useSelector(
		PartnerDuc.selectors.getFarmCertificates
	)

	const { payload } = location

	const docType = getIn(location, ['query', 'docType'])
	const orgName = getIn(location, ['query', 'orgName'])
	const partnerReqID = getIn(payload, ['partnerRef'])
	const requestID = getIn(location, ['query', 'requestID'])
	const canDeactivateOrBlackList = getIn(location, [
		'query',
		'canDeactivateOrBlackList',
	])

	const [deletePartnerModalStatus, setDeletePartnerModalStatus] = useState(
		false
	)

	const [reason, setReason] = useState('')

	const [type, setType] = useState('')
	const [showSendRequestModal, setSendRequestModal] = useState(false)

	const activeLocale = useSelector(AppDuc.selectors.activeLocale)

	const details = partnershipTimeline.map(pi => {
		return {
			date: getDateByFormat(
				pi.createdAt,
				'dd MMM yyyy hh:mm a',
				activeLocale
			),
			status: <Status currentStatus={pi.state} />,
		}
	})

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.partner'),
			name: 'partner',
			isActive: true,
		},
		{
			label: t('breadcrumb.partnerDetails'),
			name: 'partnerDetails',
			isActive: false,
		},
	]

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'partner') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER)
			)
		}
	}

	const getPartnerStatus = () => {
		let status = ''
		if (Object.keys(partnerList).length) {
			const { partners } = partnerList
			if (partners.length) {
				const partner = partners.find(
					item =>
						item.initiatorID === partnerInfo.id ||
						item.receiverID === partnerInfo.id
				)
				if (partner) {
					status = partner.status
				}
			}
		}

		return status
	}

	useEffect(() => {
		if (!Object.keys(partnerList).length) {
			dispatch(PartnerDuc.creators.fetchPartnerList('all', {}, false, ''))
		}
	}, [dispatch, partnerList])

	return (
		<>
			<Box padding={8} width="100%" height="100%">
				{/* Popup after a partner is accepted */}

				<Modal
					show={showAcceptModal}
					size="medium"
					body={
						<>
							<P large bold>
								{t('viewPartner.partnerAcceptMessage')}
							</P>
						</>
					}
					confirmlabel={t('common.ok')}
					onConfirm={() => {
						dispatch(
							PartnerDuc.creators.viewPageAcceptModalToggle(false)
						)
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.PARTNER
							)
						)
					}}
				/>

				{/* Popup after a partner is declined */}

				<Modal
					show={showDeclineModal}
					size="medium"
					body={
						<>
							<P large bold>
								{t('viewPartner.partnerDeclineMessage')}
							</P>
						</>
					}
					confirmlabel={t('common.ok')}
					onConfirm={() => {
						dispatch(
							PartnerDuc.creators.viewPageDeclineModalToggle(
								false
							)
						)
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.PARTNER
							)
						)
					}}
				/>

				{/* Confirmation popup when 'Delete Partner' is clicked */}

				<Modal
					forceCloseviaButton
					show={deletePartnerModalStatus}
					heading={
						type === 'deactivate'
							? t('viewPartner.deactivatePartner')
							: t('viewPartner.blacklistPartner')
					}
					size="large"
					body={
						<>
							<P large>
								{type === 'deactivate'
									? t('viewPartner.deactivatePartnerMsgLine1')
									: t('viewPartner.blacklistPartnerMsgLine1')}
							</P>
							<P large>{t('viewPartner.msgLine2')}</P>
							<Label
								style={{
									margin: '8px 0',
									color: theme.color.black3,
								}}
							>
								Reason:
							</Label>
							<TextArea
								value={reason}
								name="productSpecification"
								onChange={e => {
									setReason(e.target.value)
								}}
								autoFocus={false}
							/>
						</>
					}
					closelabel={t('common.back')}
					confirmlabel={t('common.proceed')}
					onClose={() => {
						setDeletePartnerModalStatus(false)
					}}
					onConfirm={() => {
						dispatch(
							PartnerDuc.creators.blackListOrDeactivatePartner(
								requestID,
								{ type, reason }
							)
						)
						setReason('')
						setDeletePartnerModalStatus(false)
					}}
				/>

				{/* Popup after a partner is deleted  */}

				<Modal
					show={showDeleteSuccessModal}
					size="large"
					body={
						<>
							<P large>
								{t('viewPartner.deletePartnerSuccessMsg')}
							</P>
						</>
					}
					confirmlabel={t('common.ok')}
					onConfirm={() => {
						dispatch(
							PartnerDuc.creators.deletePartnerSuccessToggle(
								false
							)
						)
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.PARTNER
							)
						)
					}}
				/>

				{/* Confirmation popup when 'Send Request' is clicked */}

				<Modal
					forceCloseviaButton
					show={showSendRequestModal}
					size="large"
					body={
						<>
							<P large>
								{t('viewPartner.sendRequestMsgLine1')}
								&nbsp;{orgName}&nbsp;
								{t('viewPartner.sendRequestMsgLine2')}
							</P>
						</>
					}
					closelabel={t('common.back')}
					confirmlabel={t('common.proceed')}
					onClose={() => {
						setSendRequestModal(false)
					}}
					onConfirm={() => {
						dispatch(
							PartnerDuc.creators.sendPartnershipRequest(
								partnerReqID
							)
						)
						setSendRequestModal(false)
					}}
				/>

				{/* Popup after a partner request is sent */}

				<Modal
					show={requestSuccessModal}
					size="large"
					body={
						<>
							<P large bold>
								{t('viewPartner.partnerRequestSuccess')}
							</P>
						</>
					}
					confirmlabel={t('common.ok')}
					onConfirm={() => {
						dispatch(
							PartnerDuc.creators.sendPartnerReqSuccessToggle(
								false
							)
						)
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.PARTNER
							)
						)
					}}
				/>
				<Box style={{ padding: '0 5' }}>
					<Breadcrumb
						links={breadCrumbs}
						onClick={target => handleBreadCrumbClick(target)}
					/>
				</Box>
				<Box
					row={!isMobile}
					width="auto"
					alignItems="baseline"
					justifyContent="space-between"
				>
					<Title title={t('viewPartner.title')} icon={PartnerIcon} />
					<Box
						row={!isMobile}
						width={isMobile ? 200 : 600}
						justifyContent="flex-end"
					>
						{(docType === 'partnerships' ||
							partnerStatus === 'pending') && (
							<>
								<Button
									label={t('common.decline')}
									rounded
									extendStyles={{
										fontSize: isMobile && theme.fontSize.m,
										padding: isMobile && '8px',
									}}
									onClick={() => {
										dispatch(
											PartnerDuc.creators.updateRequestStatusFromDetailsPage(
												partnerReqID,
												'false'
											)
										)
									}}
								/>

								<Spacer size={16} />

								<Button
									label={t('common.accept')}
									extendStyles={{
										fontSize: isMobile && theme.fontSize.m,
										padding: isMobile && '8px',
									}}
									rounded
									primary
									onClick={() => {
										dispatch(
											PartnerDuc.creators.updateRequestStatusFromDetailsPage(
												partnerReqID,
												'true'
											)
										)
									}}
								/>
							</>
						)}
						{docType === 'partner-listing' && (
							<Box
								style={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
								}}
							>
								<Label
									style={{
										marginRight: 15,
										color: theme.color.black3,
									}}
								>
									<Status
										currentStatus={getPartnerStatus()}
									/>
								</Label>
							</Box>
						)}
						{docType === 'partner-listing' &&
							canDelete &&
							canDeactivateOrBlackList && (
								<Button
									label={t('viewPartner.deactivatePartner')}
									rounded
									extendStyles={{
										fontSize: isMobile && theme.fontSize.m,
										padding: isMobile && '8px',
										marginRight: isMobile ? '0px' : '15px',
										marginTop: isMobile ? '15px' : '0px',
									}}
									onClick={() => {
										setType('deactivate')
										setDeletePartnerModalStatus(true)
									}}
								/>
							)}
						{docType === 'partner-listing' &&
							canDelete &&
							canDeactivateOrBlackList && (
								<Button
									label={t('viewPartner.blacklistPartner')}
									rounded
									extendStyles={{
										fontSize: isMobile && theme.fontSize.m,
										padding: isMobile && '8px',
										marginTop: isMobile ? '15px' : '0px',
										marginBottom: isMobile ? '15px' : '0px',
									}}
									onClick={() => {
										setType('blacklist')
										setDeletePartnerModalStatus(true)
									}}
								/>
							)}
						{docType === 'partner-search' && (
							<Button
								label={t('viewPartner.sendRequest')}
								extendStyles={{
									fontSize: isMobile && theme.fontSize.m,
									padding: isMobile && '8px',
								}}
								rounded
								primary
								onClick={() => {
									setSendRequestModal(true)
									dispatch(
										WebTourDuc.creators.setActiveTourModule(
											''
										)
									)
								}}
							/>
						)}
					</Box>
				</Box>

				<Spacer size={8} />
				<Box id="partnerDetails">
					<Card width="100%" textAlign="left">
						<Box
							padding={8}
							style={{
								backgroundColor: theme.color.accent3,
							}}
						>
							<H3
								bold
								color={theme.color.accent2}
								style={{
									position: 'relative',
									right: '-25px',
								}}
							>
								{t('viewPartner.title')}
							</H3>
						</Box>
						<Box>
							<table
								style={{
									marginLeft: isMobile ? '0px' : '40px',
									marginTop: isMobile ? '8px' : '24px',
								}}
							>
								<tbody>
									{partnerInfoKeys.map(key => {
										return (
											<tr key={partnerInfo[key]}>
												<td
													style={{
														padding: '12px',
														minWidth: isMobile
															? 'auto'
															: '256px',
													}}
												>
													{t(ALIASES_KEYS[key])}
												</td>
												<td
													style={{
														padding:
															'12px 12px 12px 20px',
														width:
															key === 'email' &&
															isMobile &&
															'200px',
														display: 'block',
														overflowWrap:
															'break-word',
													}}
												>
													{partnerInfo[key] || '---'}
												</td>
											</tr>
										)
									})}
								</tbody>
							</table>
						</Box>
						<Spacer size={20} />
						<H3
							bold
							color={theme.color.accent2}
							style={{
								position: 'relative',
								right: '-25px',
								paddingLeft: 8,
							}}
						>
							{t('viewPartner.certifications')}
						</H3>
						<Spacer size={20} />
						{partnerInfo &&
						partnerInfo.categoryID &&
						(partnerInfo.categoryID === 'palmoil-smallholder' ||
							partnerInfo.categoryID === 'rice-farmer')
							? (farmCertificates || []).map(cert => {
									return (
										<Box
											row
											padding="8px 8px 8px 33px"
											alignItems="baseline"
										>
											{cert?.files?.length ? (
												<FileLists
													documents={
														cert?.files || []
													}
													isReadOnly
												/>
											) : (
												''
											)}
										</Box>
									)
							  })
							: (certificates || []).map(cert => {
									return (
										<Box
											row
											key={cert.certOrg}
											padding="8px 8px 8px 33px"
											alignItems="baseline"
										>
											<Text style={{ width: 100 }}>
												{cert.certOrg}
											</Text>
											{cert.files.length ? (
												<FileLists
													documents={cert.files || []}
													isReadOnly
												/>
											) : (
												<Text>Not Available</Text>
											)}
										</Box>
									)
							  })}
						<Spacer size={20} />
						<H3
							bold
							color={theme.color.accent2}
							style={{
								position: 'relative',
								right: '-25px',
								paddingLeft: 8,
							}}
						>
							{t('viewPartner.timeline')}
						</H3>
						<Timeline data={details} isMobile={isMobile} />
						<Spacer size={60} />
					</Card>
				</Box>
				<Box padding="25px 2px" style={{ maxWidth: 150 }}>
					<Button
						label={t('common.back')}
						rounded
						customIcon={
							<ButtonIconWrapper lightBG>
								<Icon glyph={LeftArrowIcon} />
							</ButtonIconWrapper>
						}
						onClick={() =>
							dispatch(
								MainRouteDuc.creators.switchPage(
									MainRouteDuc.types.PARTNER
								)
							)
						}
					/>
				</Box>
			</Box>
		</>
	)
}

export { ViewPartner }
