import React from 'react'
import { useTranslation } from 'react-i18next'
import { CTAWrapper } from 'core-app/modules/Dealer/containers/subModules/Work'
import { Label } from 'ui-lib/components/Typography'
import { Button } from 'ui-lib/components/Button'
import { Box } from 'ui-lib/utils/Box'

const ShowMore = ({ documents, total, handleChange, type }) => {
	const { t } = useTranslation()

	return (
		<CTAWrapper>
			<Label
				color="inherit"
				bold
				small
				style={{
					padding: '10px 4px',
				}}
			>
				{(documents || []).length > 0
					? `${t('common.showingListFor')}
							  ${(documents || []).length}
							  ${t('common.of')}
							  ${total}`
					: t('common.errorBlockMessage')}
			</Label>
			<Box
				style={{
					width: '75%',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Button
					plain
					disabled={
						(documents || []).length === 0 ||
						(documents || []).length === total
					}
					extendStyles={{
						background: '#F2F4FF',
						padding: '8px 24px',
						borderRadius: '24px',
						width: '170px',
						textAlign: 'center',
					}}
					label={t('common.showMore')}
					onClick={() => {
						handleChange(type, {})
					}}
				/>
			</Box>
		</CTAWrapper>
	)
}

export { ShowMore }
