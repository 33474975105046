import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { MainRouteDuc } from 'core-app/routes/duc'
import { getIn } from 'timm'
import { Box } from 'ui-lib/utils/Box'
import { BoxShadowWrapper } from 'ui-lib/components/Card'
import { BoxShadowTable } from 'core-app/shared/components/BoxShadowTable'
import { CollapsableSegment } from 'ui-lib/components/CollapsableSegments'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Modal } from 'ui-lib/components/Modal'
import { Label, P } from 'ui-lib/components/Typography'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { SelectSearch } from 'ui-lib/components/Select'
import { Input } from 'ui-lib/components/Input'
import theme from 'ui-lib/utils/base-theme'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { isEmptyObject } from 'core-app/utils/helpers'
import { getEmployeeDocColumnConfig } from '../../components/Columns'
import { DIbizRoleActorBased } from '../../config'
import { AdminDuc } from '../../duc'

const EmployeeDocPreview = ({ rowData, isLoading, isMobile }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [showModal, setShowModal] = useState(false)
	const [showConfirmModal, setShowConfirmModal] = useState(false)
	const [currentEntry, setCurrentEntry] = useState({})
	const [role, setDibizRole] = useState('')
	const [invalidArray, setInvalidArray] = useState([])
	let disableCTA = false

	const actor = useSelector(AuthDuc.selectors.getActor)
	const employeeUploadEditSuccess =
		useSelector(AdminDuc.selectors.getEmployeeUploadEditSuccess) || false
	const actorType = getIn(actor, [0]) || ''
	const _dibizRole = getIn(DIbizRoleActorBased, [actorType]) || []
	const dibizRole = _dibizRole.filter(({ name }) => name !== 'owner')

	const dibizRoleWithTranslations = dibizRole.map(({ name, label }) => {
		return { name, label: t(label) }
	})

	const onChange = (actionType, meta = {}) => {
		switch (actionType) {
			case 'edit_entry': {
				setCurrentEntry(meta)
				setShowModal(true)
				dispatch(AdminDuc.creators.setEmployeeUploadEditSuccess(false))
				break
			}
			case 'discard_entry': {
				setCurrentEntry(meta)
				dispatch(AdminDuc.creators.setEmployeeUploadEditSuccess(false))
				setShowConfirmModal(true)
				break
			}
			default:
				break
		}
	}
	const isMobileAppUser =
		role === 'weighbridgeClerk' ||
		role === 'tractor-operator' ||
		role === 'checker' ||
		role === 'harvester' ||
		role === 'driver' ||
		role === 'grader'

	const validationSchema = () => {
		const shape = yup.object().shape({
			name: yup.string(),
			employeeID: yup.string(),
			mobileNumber: yup.string(),
			role: yup.string(),
			emailAddress: yup.string().email(t('validation.emailFormat')),
			employeeListDoc: yup.array(),
		})

		return shape
	}

	const initialValues = currentEntry || {}

	const {
		handleSubmit,
		submitForm,
		values,
		handleChange,
		handleBlur,
		setFieldValue,
		touched,
		errors,
	} = useFormik({
		initialValues,
		enableReinitialize: true,
		validationSchema,
		onSubmit: _values => {
			const employee = {
				firstName: _values.name,
				primaryContact: {
					email: _values.emailAddress,
				},
				orgRole: _values.role,
				meta: {
					employeeData: {
						id: _values.employeeID,
						designation: _values.designation,
					},
				},
			}
			if (_values.mobileNumber) {
				employee.primaryContact.mobile =
					_values.mobileNumber[0] === '+'
						? _values.mobileNumber
						: `+${_values.mobileNumber}`
			}

			const tempInvalidArray = [...invalidArray]
			const tempItem = { ..._values }
			const index = tempInvalidArray.findIndex(
				item => item.tempKey === _values.tempKey
			)
			if (
				index !== -1 &&
				Object.entries(tempInvalidArray[index])
					.sort()
					.toString() !==
					Object.entries(_values)
						.sort()
						.toString()
			) {
				dispatch(
					AdminDuc.creators.addEmployee(
						employee,
						null,
						t('viewEmployee.empAddToast'),
						true
					)
				)
				if (employeeUploadEditSuccess) {
					tempItem.valid = true
					setInvalidArray(
						invalidArray.filter(
							ele => ele.tempKey !== _values.tempKey
						)
					)
				}
			}
		},
	})

	useEffect(() => {
		if (employeeUploadEditSuccess) {
			const tempItem = { ...currentEntry }
			tempItem.valid = true
			setInvalidArray(
				invalidArray.filter(ele => ele.tempKey !== currentEntry.tempKey)
			)
			setShowModal(false)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [employeeUploadEditSuccess])

	useEffect(() => {
		if (rowData && rowData.length > 0) {
			const currentInvalidArray = []
			rowData.forEach(item => {
				if (!item.valid) {
					currentInvalidArray.push(item)
				}
			})
			setInvalidArray(currentInvalidArray)
		}
	}, [rowData])

	useEffect(() => {
		const currentEmployeeArray = {
			invalidArray,
		}
		dispatch(
			AdminDuc.creators.setUpdatedEmployeeUploadArray({
				employeeArray: currentEmployeeArray,
			})
		)
		if (
			employeeUploadEditSuccess &&
			invalidArray &&
			invalidArray.length === 0
		) {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.ADMIN$SUBROOT,
					{
						rootModule: 'employee-management',
					}
				)
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [invalidArray])

	const getEditModalContent = () => {
		const checkIfPhoneNumberValid = isValidPhoneNumber(
			values && values.mobileNumber && values.mobileNumber[0] === '+'
				? values.mobileNumber
				: `+${values.mobileNumber}`
		)

		const checkValidation = isMobileAppUser
			? !values.mobileNumber || !checkIfPhoneNumberValid
			: values?.mobileNumber && !checkIfPhoneNumberValid
		disableCTA =
			!isEmptyObject(errors) ||
			!values.name ||
			checkValidation ||
			!values.role ||
			Object.entries(currentEntry)
				.sort()
				.toString() ===
				Object.entries(values)
					.sort()
					.toString()

		return (
			<>
				<div
					style={{
						height: '350px',
						padding: '10px',
						overflow: 'auto',
						border: `1px solid ${theme.color.grey13}`,
					}}
				>
					<form onSubmit={handleSubmit}>
						<>
							<Box row={!isMobile} justifyContent="space-between">
								<Box>
									<Box width={320} margin="8px 0">
										<Input
											label={t('empMgmtHome.empName')}
											value={values.name}
											name="name"
											type="text"
											onChange={handleChange}
											onBlur={handleBlur}
											error={touched.name && errors.name}
											required
										/>
									</Box>

									<Box width={320} margin="8px 0">
										<Input
											label={t('empMgmtHome.email')}
											value={values.emailAddress}
											name="emailAddress"
											type="text"
											onChange={handleChange}
											onBlur={handleBlur}
											error={
												touched.emailAddress &&
												errors.emailAddress
											}
											required={!isMobileAppUser}
										/>
									</Box>
									<Box
										width={320}
										margin="8px 0 35px 0"
										overflow="initial"
									>
										<Label
											color={theme.color.grey8}
											style={{
												fontSize: '16px',
												margin: '8px 0',
											}}
											required={isMobileAppUser}
										>
											{t('empMgmtHome.phoneNo')}
										</Label>

										<PhoneInput
											country="my"
											value={values.mobileNumber}
											onChange={phone =>
												setFieldValue(
													'mobileNumber',
													phone
												)
											}
											enableSearch
											disableSearchIcon
											inputStyle={{ width: '320px' }}
										/>
										{checkValidation && (
											<div
												style={{
													color: theme.color.error,
													fontSize: '12px',
												}}
											>
												{isMobileAppUser &&
												!values.mobileNumber
													? t(
															'validation.phoneNumRequired'
													  )
													: t(
															'empMgmtHome.invalidPhoneNumber'
													  )}
											</div>
										)}
										{isMobileAppUser && (
											<P
												style={{
													margin: '10px 0 20px 0',
													color: theme.color.grey6,
												}}
											>
												{t(
													'empMgmtHome.phoneNumberNote'
												)}
											</P>
										)}
									</Box>
									<SelectSearch
										label={t('empMgmtHome.dibizRole')}
										value={values.role}
										name="role"
										options={dibizRoleWithTranslations}
										onChange={value => {
											setFieldValue('role', value.name)
											setDibizRole(value.name)
										}}
										returnOnlyValue
										labelKey="label"
										valueKey="name"
										defaultLabel={t(
											'common.selectDefaultLabel'
										)}
										onBlur={handleBlur}
										error={touched.role && errors.role}
										style={{
											width: 320,
											borderRadius: 4,
										}}
										required
									/>
									<Box width={320} margin="8px 0">
										<Input
											label={t('empMgmtHome.designation')}
											value={values.designation}
											name="designation"
											type="text"
											onChange={handleChange}
											onBlur={handleBlur}
											error={
												touched.designation &&
												errors.designation
											}
										/>
									</Box>
									<Box width={320} margin="8px 0">
										<Input
											label={t('empMgmtHome.employeeID')}
											value={values.employeeID}
											name="employeeID"
											type="text"
											onChange={handleChange}
											onBlur={handleBlur}
											error={
												touched.employeeID &&
												errors.employeeID
											}
										/>
									</Box>
								</Box>
							</Box>
						</>
					</form>
				</div>
				<div
					style={{
						marginTop: '5px',
						fontSize: '12px',
						color: theme.color.error,
					}}
				>
					{disableCTA && <div>{`* ${values?.remarks}`}</div>}
				</div>
			</>
		)
	}

	return (
		<Box padding="8px 0" width="100%" height="100%">
			<Modal
				forceCloseviaButton
				size="470px"
				show={showModal}
				heading={t('empMgmtHome.editEmployee')}
				body={getEditModalContent()}
				closelabel={t('common.cancel')}
				secondaryButtonLabel={t('empMgmtHome.addEmployee')}
				onClose={() => {
					setShowModal(false)
					setCurrentEntry({})
				}}
				isDisabled={disableCTA}
				secondaryButtonClick={() => {
					submitForm()
				}}
				noPrimaryButton
			/>
			<Modal
				forceCloseviaButton
				show={showConfirmModal}
				heading={t('previewBin.modalHeading')}
				body={
					<P large bold>
						{t('empMgmtHome.confirmModalText')}
					</P>
				}
				closelabel={t('common.no')}
				confirmlabel={t('common.yes')}
				onClose={() => {
					setShowConfirmModal(false)
				}}
				onConfirm={() => {
					dispatch(
						AdminDuc.creators.setEmployeeUploadEditSuccess(true)
					)
					setShowConfirmModal(false)
				}}
			/>
			<Box overflow>
				<BoxShadowWrapper>
					{invalidArray && invalidArray.length > 0 && (
						<CollapsableSegment
							expand
							title={t('empMgmtHome.invalidEntires')}
							hasError
							open
						>
							<div key={invalidArray}>
								<Box
									width="100%"
									row
									justifyContent="space-between"
								>
									<Box row wrap style={{ width: '100%' }}>
										<BoxShadowTable
											isLoading={isLoading}
											size="large"
											scroll={!isMobile}
											tableSize={isMobile && 'small'}
											columnConfig={getEmployeeDocColumnConfig(
												{
													onChange,
													t,
												}
											)}
											rowData={invalidArray}
											widthProp
										/>
									</Box>
								</Box>
							</div>
						</CollapsableSegment>
					)}

					{!(invalidArray && invalidArray.length > 0) && (
						<div
							style={{
								margin: '100px 30px',
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							{t('traceReport.noDataAvailable')}
						</div>
					)}
				</BoxShadowWrapper>
			</Box>
		</Box>
	)
}

export { EmployeeDocPreview }
