import React from 'react'
import { Box } from 'ui-lib/utils/Box'
import { H2, P } from 'ui-lib/components/Typography'
import { SelectSearch } from 'ui-lib/components/Select'
import { Button, ButtonWithNoBorder } from 'ui-lib/components/Button'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { isEmptyObject } from 'core-app/utils/helpers'
import { Spacer } from 'ui-lib/utils/Spacer'
import {
	FormHeader,
	FormBody,
	FormFooter,
} from 'core-app/shared/components/Fragments'

const DOSelection = ({ onClose, onConfirm, entityList }) => {
	const { t } = useTranslation()

	const validationSchema = yup.object().shape({
		entityID: yup.string().required(t('validation.doRequired')),
	})

	const {
		handleSubmit,
		submitForm,
		values,
		handleBlur,
		errors,
		isSubmitting,
		touched,
		setFieldValue,
	} = useFormik({
		initialValues: {
			entityID: '',
		},
		enableReinitialize: true,
		validationSchema,
		onSubmit: _values => {
			onConfirm(_values)
		},
	})

	const disableCTA = !isEmptyObject(errors) || !values.entityID

	return (
		<Box>
			<FormHeader
				row
				justifyContent="space-between"
				alignItems="flex-end"
			>
				<H2 primary>{t('qualityListing.popupTitle')}</H2>
			</FormHeader>
			<form onSubmit={handleSubmit}>
				<FormBody padding="20px 0px">
					<Box overflow width="inherit" padding="0 20px">
						<Box overflow width={300}>
							<SelectSearch
								name="entityID"
								placeholder={t('qualityListing.selectDO')}
								options={entityList}
								onChange={value => {
									setFieldValue('entityID', value.id)
								}}
								labelKey="label"
								valueKey="id"
								defaultLabel={t('common.selectDefaultLabel')}
								onBlur={handleBlur}
								error={touched.entityID && errors.entityID}
								returnOnlyValue
							/>
						</Box>
					</Box>
					<Spacer size={64} />
				</FormBody>
				<FormFooter
					row
					justifyContent="flex-end"
					style={{ width: '100%' }}
				>
					<Box center row style={{ maxWidth: 375 }}>
						<ButtonWithNoBorder
							label={t('common.cancel')}
							tabIndex="-1"
							onClick={onClose}
						/>
						<Spacer size={8} horizontal />
						<Box
							justifyContent="center"
							width={150}
							padding={1}
							height="inherit"
							overflow
						>
							<Button
								label={
									<P color="white">
										<strong>{t('common.submit')}</strong>
									</P>
								}
								isLoading={isSubmitting}
								disabled={disableCTA}
								onClick={() => submitForm()}
								primary
							/>
						</Box>
					</Box>
				</FormFooter>
			</form>
		</Box>
	)
}

export { DOSelection }
