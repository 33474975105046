/* eslint-disable no-useless-concat */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
	Button,
	ButtonWithNoBorder,
	ButtonIconWrapper,
} from 'ui-lib/components/Button'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { useFormik } from 'formik'
import { AppDuc } from 'core-app/modules/App/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { MainRouteDuc } from 'core-app/routes/duc'
import { useDispatch, useSelector } from 'react-redux'
import OtpInput from 'react-otp-input'
import theme from 'ui-lib/utils/base-theme'
import { Label, Text, H2 } from 'ui-lib/components/Typography'
import { applyMediaQueries } from 'ui-lib/utils/helpers'
import { Icon } from 'ui-lib/icons/components/Icon'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'

const LinkBlock = styled.span(p => ({
	textAlign: 'right',
	color: p.theme.color.primary,
	cursor: 'pointer',
	width: '160px',
	alignSelf: 'center',
	':hover': {
		textDecoration: 'underline',
	},
}))

const LoginWrapper = styled.div(p => ({
	width: '90%',
	maxHeight: '600px',
	maxWidth: p.isMobile ? '320px' : '380px',
	position: 'relative',
	top: p.isMobile ? '5%' : '20%',
	background: p.theme.color.white,
	overflow: 'initial',
	display: 'flex',
	justifyContent: 'center',
	alignSelf: 'center',
	alignItems: 'center',
	flexDirection: 'column',
	margin: '2% 0%',
	zIndex: p.theme.zIndex.level2,
	...applyMediaQueries(['mobile'], {
		justifyContent: 'center',
		flexDirection: 'column',
	}),
}))

const OrBlock = styled.div({
	height: '5px',
	borderTop: `1px solid ${theme.color.grey4}`,
	textAlign: 'center',
	position: 'relative',
})
const OrWrapper = styled.span({
	position: 'relative',
	top: '-.7em',
	backgroundColor: 'white',
	display: 'inline-block',
	color: theme.color.accent2,
	fontSize: theme.fontSize.xl,
})

export const VerifyOtp = () => {
	const { t } = useTranslation()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const [otpValues, setOtpValues] = useState('')
	const handleOTPChange = otp => setOtpValues(otp)
	const dispatch = useDispatch()
	const location = useSelector(AuthDuc.selectors.location)
	const { query = {} } = location
	const { email } = query
	const { handleSubmit, isSubmitting } = useFormik({
		initialValues: {
			secret: '',
		},

		onSubmit: () => {
			dispatch(AuthDuc.creators.verifyOtp(email, otpValues))
		},
	})

	const disableCTA = otpValues.length !== 6

	return (
		<Box
			style={{
				position: 'relative',
				width: '100%',
				height: '100%',
				backgroundColor: theme.color.white,
				zIndex: 0,
				overflow: 'auto',
			}}
			alignItems={!isMobile && 'flex-end'}
		>
			<LoginWrapper isMobile={isMobile}>
				<Box
					style={{
						width: '100%',
						backgroundColor: theme.color.white,
					}}
				>
					{!isMobile && (
						<Box
							row
							justifyContent="flex-start"
							style={{
								paddingbottom: '30px',
								paddingLeft: '0px',
							}}
						>
							<ButtonWithNoBorder
								style={{
									width: '100%',
								}}
								customIcon={
									<ButtonIconWrapper
										lightBG
										style={{
											color: theme.color.blue5,
										}}
									>
										<Icon glyph={LeftArrowIcon} />
									</ButtonIconWrapper>
								}
								onClick={() => {
									dispatch(
										MainRouteDuc.creators.redirect(
											MainRouteDuc.types.AUTH,
											{
												action: 'otp-login',
											}
										)
									)
								}}
								extendStyles={{
									fontWeight: 'bold',
									fontSize: theme.fontSize.xl,
									textDecoration: 'none',
									color: theme.color.blue5,
									padding: '0px',
								}}
								label={t('common.back')}
							/>
						</Box>
					)}
					<Spacer size={10} />
					<form onSubmit={handleSubmit}>
						<H2
							style={{
								color: theme.color.accent2,
							}}
						>
							{t('login.loginwithOTP')}
						</H2>
						<Box
							style={{
								width: '100%',
							}}
						>
							<Text
								style={{
									maxWidth: '500px',
									width: '100%',
									padding: '20px 0px',
									color: theme.color.accent2,
									lineHeight: '1.5em',
								}}
							>
								A{' '}
								<Text
									style={{
										color: '#676A83',
										lineHeight: '1.5em',
									}}
								>
									{t('login.loginwithOTPNote1')}
								</Text>
								{`${t('login.loginwithOTPNote2')}${email}.
								${t('login.loginwithOTPNote3')}`}
							</Text>
							<Box
								alignItems="baseline"
								justifyContent="space-between"
							>
								<LinkBlock
									style={{
										color: theme.color.blue4,
										fontSize: theme.fontSize.xl,
										alignSelf: 'flex-end',
									}}
									onClick={() => {
										dispatch(
											AuthDuc.creators.initiateOTP(
												email,
												t('common.verifyEmail')
											)
										)
										dispatch(
											MainRouteDuc.creators.redirect(
												MainRouteDuc.types.AUTH,
												{
													action: 'verify-email',
												},
												{ email }
											)
										)
									}}
								>
									{t('login.resendOTP')}
								</LinkBlock>
							</Box>
							<Box center paddingbottom="20px">
								<OtpInput
									shouldAutoFocus
									value={otpValues}
									onChange={handleOTPChange}
									numInputs={6}
									renderSeparator="&nbsp;"
									renderInput={props => <input {...props} />}
								/>
							</Box>
							<Spacer size={30} />
							<Button
								disabled={disableCTA}
								isLoading={isSubmitting}
								type="submit"
								primary
								label={t('login.signIn')}
							/>
						</Box>
					</form>
					<Spacer size={48} />
					<OrBlock>
						<OrWrapper>&nbsp;Or&nbsp;</OrWrapper>
					</OrBlock>
					<Spacer size={40} />
					<LinkBlock
						style={{
							color: theme.color.blue5,
							fontSize: theme.fontSize.xl,
							width: '100%',
							textAlign: 'center',
						}}
						onClick={() =>
							dispatch(
								MainRouteDuc.creators.redirect(
									MainRouteDuc.types.AUTH,
									{
										action: 'sign-in',
									},
									{ redirect_to: '/' }
								)
							)
						}
					>
						{t('login.loginPassword')}
					</LinkBlock>
				</Box>
				<Spacer size={10} />
				{isMobile && (
					<Box>
						<Box row alignItems="baseline" justifyContent="center">
							<Label>{t('login.notAMember')}</Label>
							<ButtonWithNoBorder
								onClick={() =>
									dispatch(
										MainRouteDuc.creators.redirect(
											MainRouteDuc.types.ONBOARD,
											{
												action: 'signup',
											}
										)
									)
								}
								label={t('login.join')}
								extendStyles={{
									border: 'none',
									borderRadius: 0,
									textDecoration: 'none',
									color: theme.color.blue4,
								}}
							/>
						</Box>
					</Box>
				)}
				<Box
					row
					alignItems="baseline"
					justifyContent="center"
					alignSelf={isMobile ? 'center' : 'flex-start'}
				>
					{!isMobile && <Label>{t('login.agree')}&nbsp; </Label>}
					<ButtonWithNoBorder
						extendStyles={{
							width: 'fit-content',
							color: theme.color.blue4,
							textDecoration: 'none',
							padding: '0',
						}}
						onClick={() =>
							dispatch(
								MainRouteDuc.creators.switchPage(
									MainRouteDuc.types.POLICY,
									{
										action: 'terms-conditions',
									}
								)
							)
						}
						label={t('login.terms')}
					/>
					&nbsp;
					<Label>{!isMobile ? ',' : '|'}&nbsp;</Label>
					<ButtonWithNoBorder
						extendStyles={{
							color: theme.color.blue4,
							width: 'fit-content',
							textDecoration: 'none',
							padding: '0',
						}}
						onClick={() =>
							dispatch(
								MainRouteDuc.creators.switchPage(
									MainRouteDuc.types.POLICY,
									{
										action: 'privacy-policy',
									}
								)
							)
						}
						label={t('login.privacy')}
					/>
				</Box>
			</LoginWrapper>
		</Box>
	)
}
