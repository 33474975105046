import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NotFoundBlock } from 'core-app/modules/App/components/Error'
import { TripsDuc } from './duc'
import { MainDashboard } from './containers/MainDashboard'
import { DocumentView } from './containers/DocumentView'
import { WebTour } from '../WebTour'
import { WebTourDuc } from '../WebTour/duc'

const modulesMap = {
	dashboard: MainDashboard,
	document: DocumentView,
}

const Trips = () => {
	const dispatch = useDispatch()
	const activeModule = useSelector(TripsDuc.selectors.activeModule)
	const Component = modulesMap[activeModule] || NotFoundBlock
	// if we are here, we have a submodule, route it to the module
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)

	useEffect(() => {
		if (tourModule === 'trips') {
			dispatch(WebTourDuc.creators.setTripsVisit(true))
		}
	}, [tourModule, dispatch])

	return (
		<>
			<Component />
			{tourModule === 'trips' && <WebTour />}
		</>
	)
}

export default Trips
