import { getIn, setIn, addLast, merge } from 'timm'
import theme from 'ui-lib/utils/base-theme'
import {
	convertFirstCharToLower,
	convertToTitleCase,
	getQuantityWithUnit,
} from 'core-app/utils/helpers'
import { getDateByFormat } from 'core-app/utils/date'
import {
	RECEIVER_STATUS_ALIASES,
	SENDER_STATUS_ALIASES,
	RECEIVER_ACTION_LABELS,
	SENDER_ACTION_LABELS,
} from './config'

export const initiateSort = (
	dispatch,
	meta,
	targetActionCreator,
	location,
	queryRoot
) => {
	const sortRoot = `${meta.docType}|${meta.field}`
	let currentFiltersForType = getIn(location, ['query', meta.type]) || []
	currentFiltersForType = Array.isArray(currentFiltersForType)
		? currentFiltersForType
		: [currentFiltersForType]
	currentFiltersForType = currentFiltersForType.filter(
		key => key.indexOf(sortRoot) === 0
	)

	if (meta.order) {
		const sortKey = `${sortRoot}|${meta.order}`

		currentFiltersForType = addLast(currentFiltersForType, sortKey)
	}
	dispatch(
		targetActionCreator(
			meta.docType,
			[meta.type],
			setIn(
				location,
				['query', queryRoot || meta.type],
				currentFiltersForType
			),
			true
		)
	)
}

export const transformSortStringsToBEQueries = (str = []) =>
	str.reduce((agg, _str = '') => {
		const splitArr = _str.split('|')

		if (splitArr.length < 3) return agg

		const root = splitArr.shift()
		const sortString = `${splitArr[1]}(${splitArr[0]})`

		return merge(agg, { [root]: sortString })
	}, {})

export const responseDocsMapper = (targetTypes, responses) =>
	targetTypes.reduce((agg, _type, index) => {
		const aggregator = agg

		aggregator[_type] = getIn(responses[index], ['data']) || []

		return aggregator
	}, {})

export const extractOrgIDsFromResponses = (responses = [], type = '') => {
	const uniqueOrgIds = []
	const allLists = responses.reduce((agg, resp) => {
		return agg.concat(getIn(resp, ['data', 'list']) || [])
	}, [])
	if (type === 'partner') {
		allLists.forEach(list => {
			if (!uniqueOrgIds.includes(list?.meta?.partnership?.initiatorID)) {
				uniqueOrgIds.push(list.meta.partnership.initiatorID)
			}
			if (!uniqueOrgIds.includes(list?.meta?.partnership?.receiverID)) {
				uniqueOrgIds.push(list.meta.partnership.receiverID)
			}
		})
	} else
		allLists.forEach(list => {
			if (!uniqueOrgIds.includes(list.initiatingPartyID)) {
				uniqueOrgIds.push(list.initiatingPartyID)
			}

			if (!uniqueOrgIds.includes(list.receivingPartyID)) {
				uniqueOrgIds.push(list.receivingPartyID)
			}
		})

	return uniqueOrgIds
}

export const extractOrgIdFromResponses = (responses = []) => {
	const uniqueOrgId = []
	const allLists = responses.reduce((agg, resp) => {
		return agg.concat(getIn(resp, ['data', 'list']) || [])
	}, [])
	allLists.forEach(list => {
		if (!uniqueOrgId.includes(list.organizationID)) {
			uniqueOrgId.push(list.organizationID)
		}
	})

	return uniqueOrgId
}

export const extractSortQueries = (responses = {}, currentSortQueries = []) => {
	let _currentSortQueries = [...currentSortQueries]
	const newQueries = Object.keys(responses).reduce((agg, key) => {
		const sortsApplied = getIn(responses, [key, 'sortBy']) || []
		_currentSortQueries = _currentSortQueries.filter(
			_key => _key.indexOf(key) < 0
		)
		if (sortsApplied.length) {
			return agg.concat(
				...sortsApplied.map(({ field, descending }) => {
					const rootKey = `${key}|${convertFirstCharToLower(field)}`

					return `${rootKey}|${descending ? 'desc' : 'asc'}`
				})
			)
		}

		return agg
	}, [])

	return [..._currentSortQueries, ...newQueries]
}

/** Parses query filter strings to be part of backend query group */
export const transformFilterStringsToBEQueries = (filters = {}) => {
	return Object.keys(filters).reduce((agg, key) => {
		const aggregator = agg
		let criterias = filters[key] || []
		criterias = Array.isArray(criterias) ? criterias : [criterias]

		const queryGroup = []
		// group values by the filter condition

		const criteriasGrouped = criterias.reduce((_agg, _filterStr) => {
			// here the final one should be singled out into a string of key value pairs

			const _aggregator = _agg

			if (key === 'virtualOrg') {
				_aggregator[key] = _filterStr

				return _aggregator
			}

			let parts = _filterStr.split('|')

			if (key === 'query') {
				parts = [key, _filterStr]
			}

			if (parts.length !== 2) {
				// default to equal
				parts = ['eq', parts[0]]
			}

			const criteria = parts[0]
			const value = parts[1]

			if (
				criteria === 'eq' &&
				['any', 'state->any', 'cnt'].includes(value)
			) {
				// skip these since they are default filters
				return _aggregator
			}

			const existing = getIn(_aggregator, [criteria]) || []

			if (!existing.includes(value))
				_aggregator[criteria] = addLast(existing, value)

			return _aggregator
		}, {})

		if (criteriasGrouped.lte && criteriasGrouped.gte) {
			// possible range, lets group them as such.
			const start = criteriasGrouped.lte[0] // singular values we ignore rest
			const end = criteriasGrouped.gte[0] // singular values we ignore rest
			if (start && end) queryGroup.push(`eq(${start}~${end})`)
		} else if (criteriasGrouped.lte) {
			queryGroup.push(`lte(${criteriasGrouped.lte.join(',')})`)
		} else if (criteriasGrouped.gte) {
			queryGroup.push(`gte(${criteriasGrouped.gte.join(',')})`)
		} else if (criteriasGrouped.lt) {
			queryGroup.push(`lt(${criteriasGrouped.lt.join(',')})`)
		} else if (criteriasGrouped.cnt) {
			queryGroup.push(`cnt(${criteriasGrouped.cnt.join(',')})`)
		}

		// check for other criterias
		if (criteriasGrouped.in) {
			// logical or
			queryGroup.push(`in(${criteriasGrouped.in.join(',')})`)
		}

		if (criteriasGrouped.eq) {
			// logical and
			queryGroup.push(`eq(${criteriasGrouped.eq.join(',')})`)
		}

		if (criteriasGrouped.query) {
			queryGroup.push(criteriasGrouped.query)
		}

		if (key === 'virtualOrg') {
			queryGroup.push(criteriasGrouped.virtualOrg)
		}

		aggregator[key] = queryGroup

		return aggregator
	}, {})
}

export const extractFilterQueries = (response = {}) =>
	(getIn(response, ['filterBy']) || []).reduce(
		(agg, { field, operator, value }) => {
			let { stateTree, queryTree } = agg
			let _filters = getIn(stateTree, [field, operator]) || []
			let _queries = getIn(queryTree, [field]) || []

			// if value is a range, set it appropriately
			if (value.indexOf('->') > -1) {
				// if the filter has prefix, exclude it
				_queries = addLast(
					_queries,
					`${operator}|${value.split('->')[1]}`
				)
				_filters = addLast(_filters, value.split('->')[1])
			} else if (value.indexOf('~') > -1) {
				const [start, end] = value.split('~')

				_queries = addLast(_queries, `lte|${start}`)
				_queries = addLast(_queries, `gte|${end}`)

				queryTree = setIn(queryTree, [field], _queries)

				stateTree = setIn(stateTree, [field, 'lte'], [start])
				stateTree = setIn(stateTree, [field, 'gte'], [end])
			} else {
				// break values into parts
				const targetValue = value.split(',')
				_queries = addLast(
					_queries,
					targetValue.map(t => `${operator}|${t}`)
				)
				queryTree = setIn(queryTree, [field], _queries)

				_filters = addLast(_filters, targetValue)
				// push the new data into the set
				stateTree = setIn(stateTree, [field, operator], _filters)
			}

			return { stateTree, queryTree }
		},
		{ queryTree: {}, stateTree: {} }
	)

export const getTargetFilterQueries = (
	currentFilters,
	filterSegment,
	filterValue,
	prefix = ''
) => {
	let targetQueries = currentFilters || []
	targetQueries = Array.isArray(targetQueries)
		? targetQueries
		: [targetQueries]

	let targetFilterValue = `eq|${prefix}${filterValue}`
	const addMultiple = filterSegment === 'multiple'
	const notEqual = filterSegment === 'not-equal'
	const isStartDate = filterSegment === 'start_date'
	const isEndDate = filterSegment === 'end_date'
	const dateRange = filterSegment === 'date_range'
	const abc = filterSegment === 'abc'

	if (isStartDate) {
		// remove all existing start dates
		targetQueries = targetQueries.filter(q => q.indexOf('gte|') < 0)

		// add the end date range
		targetFilterValue = `gte|${prefix}${filterValue}`
	}

	if (isEndDate) {
		targetQueries = targetQueries.filter(q => q.indexOf('lte|') < 0)
		// add the end date range
		targetFilterValue = `lte|${prefix}${filterValue}`
	}

	if (addMultiple) {
		targetFilterValue = `in|${prefix}${filterValue}`
	}

	if (notEqual) {
		targetFilterValue = `neq|${prefix}${filterValue}`
	}
	if (dateRange) {
		targetFilterValue = `eq|${getIn(filterValue, [
			'startDate',
		])}~${getIn(filterValue, ['endDate'])}`
	}

	if (abc) {
		targetFilterValue = `cnt|${prefix}->${filterValue}`
	}

	// check if already in the filter
	if (!targetQueries.includes(targetFilterValue))
		targetQueries = addLast(targetQueries, targetFilterValue)

	return targetQueries
}

export const extractNonStandardAttributes = parentDocRef => {
	let meta = []
	// if type is work-entry
	if (parentDocRef.type === 'work-entry') {
		if (parentDocRef.ffbCount) {
			meta = addLast(meta, {
				name: 'Source Count',
				description: parentDocRef.ffbCount || '---',
			})
		}
		if (parentDocRef.ffbDetails) {
			// flatten details
			const { location, ...otherFFBDetails } = parentDocRef.ffbDetails
			meta = addLast(
				meta,
				Object.keys(otherFFBDetails).map(key => ({
					name: `Source ${convertToTitleCase(key)}`,
					description: otherFFBDetails[key],
				}))
			)
			meta = addLast(
				meta,
				Object.keys(location).map(key => ({
					name: `Location ${convertToTitleCase(key)}`,
					description: location[key],
				}))
			)
		}
	}

	return meta
}

export const getStateBasedTargetActions = (
	docType,
	currentStatus,
	isDocumentOwner,
	isTraderOrEndManufacturer
) => {
	/* For  owner of the document */
	if (isDocumentOwner) {
		if (currentStatus === 'acknowledged') {
			return ['hold', 'rejected']
		}
		if (currentStatus === 'accepted') {
			if (docType === 'invoice' || docType === 'delivery-order')
				return ['hold', 'intransit']
		}
		if (currentStatus === 'hold') {
			return ['accepted', 'rejected']
		}

		if (currentStatus === 'delayed') {
			if (docType === 'invoice' || docType === 'delivery-order')
				return ['rejected', 'hold', 'intransit']

			return ['rejected', 'hold']
		}

		if (currentStatus === 'partial') {
			return ['complete']
		}

		/* For non owner of the document */
	} else if (currentStatus === 'submitted') {
		return ['acknowledged', 'accepted', 'rejected']
	} else if (currentStatus === 'acknowledged') {
		return ['accepted', 'rejected', 'hold']
	} else if (currentStatus === 'accepted') {
		if (docType === 'invoice') return ['hold']
		if (docType === 'delivery-order' && isTraderOrEndManufacturer)
			return ['hold', 'delivered']
	} else if (currentStatus === 'hold') {
		return ['accepted', 'rejected']
	} else if (currentStatus === 'delayed') {
		if (docType === 'invoice' || docType === 'delivery-order')
			return ['rejected', 'hold', 'delivered']

		return ['rejected', 'hold']
	} else if (currentStatus === 'intransit') {
		if (docType === 'delivery-order' && isTraderOrEndManufacturer)
			return ['delivered']

		return []
	} else if (currentStatus === 'partial') {
		return ['complete']
	}

	return []
}

export const getStatusLabel = (currentStatus, isDocumentOwner) => {
	if (!currentStatus) return '---'

	const status = isDocumentOwner
		? SENDER_STATUS_ALIASES[currentStatus]
		: RECEIVER_STATUS_ALIASES[currentStatus]

	return status || '---'
}

export const mapLabelsToStatusActions = (
	t,
	isDocumentOwner,
	labelsArr = []
) => {
	return labelsArr.map(key => {
		let label

		if (t) {
			if (isDocumentOwner) {
				label =
					t(SENDER_ACTION_LABELS[key]) ||
					t(SENDER_STATUS_ALIASES[key])
			} else {
				label =
					t(RECEIVER_ACTION_LABELS[key]) ||
					t(RECEIVER_STATUS_ALIASES[key])
			}
		} else {
			label = key
		}

		return {
			name: key,
			label,
		}
	})
}

export const weighbridgeData = (
	attachment,
	orgDetails,
	organizations = [],
	products = [],
	entitiesResponse = {}
) => {
	const orgID = attachment.organizationID
	const organization = organizations.filter(org => org.id === orgID)
	const orgName = getIn(organization, [0, 'name']) || orgDetails.name
	const { id } = attachment
	const {
		productID,
		defaultUOM,
		grossWeight,
		tareWeight,
		deductionWeight,
		netWeight,
		ticketNumber,
	} = attachment.meta.weighbridge
	const uom = 'MT' // actually uom should take from attachment.meta.weighbridge, since its throwing wrong value for plantation, hardcoding it
	const productName = products.filter(p => p.product.id === productID)
	const name = getIn(productName, [0, 'product', 'name']) || ''
	const file = getIn(attachment, ['file', 'meta']) || {}

	return {
		title: 'Weighbridge Ticket',
		ticketInfo: {
			id,
			issuedBy: orgName || '',
			file,
			organizationID: orgID,
			doNumber: entitiesResponse.number,
			wbCertification: attachment.meta.wbCertification,
			driverName:
				getIn(entitiesResponse, [
					'meta',
					'transporter',
					'driverName',
				]) || '---',
			dispatchDate: getDateByFormat(
				getIn(entitiesResponse, ['meta', 'issueDate']) || '---'
			),
			vehicleNumber:
				getIn(entitiesResponse, [
					'meta',
					'transporter',
					'vehicleNumber',
				]) || '---',
			product: name,
			grossWeight:
				grossWeight === undefined || grossWeight === 0
					? 'N/A'
					: getQuantityWithUnit(
							grossWeight.toFixed(2),
							defaultUOM,
							uom
					  ),
			tareWeight:
				tareWeight === undefined || tareWeight === 0
					? 'N/A'
					: getQuantityWithUnit(
							tareWeight.toFixed(2),
							defaultUOM,
							uom
					  ),

			deductionWeight:
				deductionWeight === undefined || deductionWeight === 0
					? 'N/A'
					: getQuantityWithUnit(
							deductionWeight.toFixed(2),
							defaultUOM,
							uom
					  ),
			ticketNumber: ticketNumber || ' ',
			netWeight:
				netWeight === undefined || netWeight === 0
					? 'N/A'
					: getQuantityWithUnit(
							netWeight.toFixed(2),
							defaultUOM,
							uom
					  ),
			netWeightNumeric: netWeight,
			uom: defaultUOM || uom,
			generatedDate: getDateByFormat(
				getIn(entitiesResponse, ['createdAt']) || '---'
			),
		},
		category: 'weighbridge',
	}
}

export const ffbQualityData = (
	attachment,
	orgDetails,
	organizations = [],
	entitiesResponse = {}
) => {
	const orgID = attachment.organizationID
	const organization = organizations.filter(org => org.id === orgID)
	const orgName = getIn(organization, [0, 'name']) || orgDetails.name
	const file = getIn(attachment, ['file', 'meta']) || {}
	const { meta } = attachment
	const { ffbQuality } = meta
	const {
		ripeBunches,
		overripeBunches,
		underripeBunches,
		emptyBunches,
		unripeBunches,
		wetBunches,
		date,
	} = ffbQuality

	return {
		title: 'FFB Quality Report',
		ffbQualityInfo: {
			issuedBy: orgName || '',
			file,
			dispatchDate: getDateByFormat(
				getIn(entitiesResponse, ['meta', 'issuedOn']) ||
					getIn(entitiesResponse, [
						'meta',
						'other',
						'core',
						'entityDate',
					]) ||
					attachment.createdAt ||
					date ||
					''
			),
			createdDate: getDateByFormat(date || attachment.createdAt),
			product: 'Fresh Fruit Bunch',
			ripeBunches,
			overripeBunches,
			underripeBunches,
			emptyBunches,
			unripeBunches,
			wetBunches,
		},
		category: 'quality-palmoil-ffb',
	}
}

export const cpoQualityData = (
	attachment,
	orgDetails,
	organizations = [],
	entitiesResponse = {}
) => {
	const orgID = attachment.organizationID
	const organization = organizations.filter(org => org.id === orgID)
	const orgName = getIn(organization, [0, 'name']) || orgDetails.name
	const { meta } = attachment
	const { cpoQuality } = meta
	const {
		ffa,
		dobi,
		mniValue,
		acidity,
		humidity,
		impurities,
		meltingPoint,
		iodineIndex,
		colour,
	} = cpoQuality

	return {
		title: 'CPO Quality Report',
		cpoQualityInfo: {
			issuedBy: orgName || '',
			dispatchDate: getDateByFormat(
				getIn(entitiesResponse, ['meta', 'issuedOn']) ||
					getIn(entitiesResponse, [
						'meta',
						'other',
						'core',
						'entityDate',
					]) ||
					''
			),
			createdDate: getDateByFormat(attachment.createdAt),
			product: 'Crude Palm Oil',
			ffa: ffa || 'N/A',
			dobi: dobi || 'N/A',
			mniValue: mniValue || 'N/A',
			acidity: acidity || 'N/A',
			humidity: humidity || 'N/A',
			impurities: impurities || 'N/A',
			meltingPoint: meltingPoint || 'N/A',
			iodineIndex: iodineIndex || 'N/A',
			colour: colour || 'N/A',
		},
		category: 'quality-palmoil-cpo',
	}
}

export const otherAttachmentData = attachment => {
	const { id, entityID, organizationID, file, type, meta } = attachment

	return {
		attachmentID: id,
		entityID,
		organizationID,
		title: 'Attachments',
		file,
		category: 'documents',
		type,
		meta,
	}
}

export const colorArray = [
	theme.color.themeBlue11,
	theme.color.themeBlue9,
	theme.color.themeBlue8,
	theme.color.themeBlue7,
	theme.color.themeBlue6,
]

export const billOfLadingAttachmentData = attachment => {
	const { id, entityID, organizationID, file, type } = attachment

	return {
		attachmentID: id,
		entityID,
		organizationID,
		title: 'Attachments',
		file,
		category: 'bill-of-lading',
		type,
	}
}
