import React from 'react'
import { useTranslation } from 'react-i18next'
import { FeedbackModal } from 'ui-lib/components/Modal'

const FeedbackModalBlock = ({ icon, data, onSubmit, value, onChange }) => {
	const { t } = useTranslation()

	return (
		<FeedbackModal
			heading={t('feedback.title')}
			sideNote={t('feedback.sideNote')}
			icon={icon}
			data={data}
			onSubmit={onSubmit}
			value={value}
			onChange={onChange}
		/>
	)
}

export { FeedbackModalBlock }
