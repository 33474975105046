import { getIn } from 'timm'
import { all, takeLatest, put, call, select } from 'redux-saga/effects'
import { AppDuc } from 'core-app/modules/App/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { MainRouteDuc } from 'core-app/routes/duc'
import { CookieDuc } from 'core-app/modules/App/cookieDuc'
import { DocumentListing } from 'core-app/modules/App/AppSaga'
import LogHelper from 'core-app/utils/logger'
import request from 'core-app/utils/request'
import { CallWithRefreshCheck } from 'core-app/modules/Auth/AuthSaga'
import { GeneralSchedulerDuc } from 'core-app/modules/GeneralScheduler/duc'
import { getPlantationEndPoint, getCoreEndPoint } from 'core-app/config'
import { Toast } from 'ui-lib/components/Toast'

const logger = LogHelper('client:GeneralSchedulerSaga')

const PAGINATION_LIMIT = 100

function* fetchScheduleListing(action) {
	try {
		const { locationState } = action

		yield put(AppDuc.creators.showGlobalLoader('fetch-schedule-listing'))

		yield put(AuthDuc.creators.fetchPartnerOrgs())

		let insightsURL = `${getPlantationEndPoint()}scheduler/insights`
		const selectedCPID = yield select(CookieDuc.selectors.getSelectedCPID)
		let requestUrl = `${getPlantationEndPoint()}schedule`
		if (selectedCPID) {
			requestUrl = `${getCoreEndPoint()}schedulelist/childorg/${selectedCPID}`
			insightsURL = `${getPlantationEndPoint()}scheduler/insights/childOrg/${selectedCPID}`
		}

		const { data: insights = {} } = yield CallWithRefreshCheck(insightsURL)
		const { data, serverPaginationQuery } = yield DocumentListing(
			requestUrl,
			locationState,
			PAGINATION_LIMIT
		)

		yield put(GeneralSchedulerDuc.creators.setInsights(insights))
		yield put(GeneralSchedulerDuc.creators.setScheduleListing(data.list))
		yield put(
			GeneralSchedulerDuc.creators.savePaginationQueries(
				serverPaginationQuery
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-schedule-listing'))
	}
}

function* fetchScheduleDetails(action) {
	try {
		yield put(AuthDuc.creators.fetchPartnerOrgs())

		const { scheduleID } = action

		yield put(AppDuc.creators.showGlobalLoader('view-schedule'))

		const selectedCPID = yield select(CookieDuc.selectors.getSelectedCPID)

		let requestUrl = ''
		if (selectedCPID) {
			requestUrl = `${getPlantationEndPoint()}schedule/${scheduleID}/childorg/${selectedCPID}`
		} else {
			requestUrl = `${getPlantationEndPoint()}schedule/${scheduleID}`
		}

		const { data = {} } = yield CallWithRefreshCheck(requestUrl)

		const participantOrganization = getIn(data, ['participants']).filter(
			org => org.clientType === 'org'
		)

		const participantUser = getIn(data, ['participants']).filter(
			drvr => drvr.clientType === 'user'
		)

		const scheduleDetails = {
			orgScheduleParticipantID: getIn(participantOrganization, [0, 'id']),
			participantID: getIn(participantOrganization, [0, 'participantID']),
			type: [getIn(data, ['type'])],
			startAt: getIn(data, ['startAt']),
			createdAt: getIn(data, ['createdAt']),
			clientID: getIn(participantUser, [0, 'participantID']),
			userScheduleParticipantID: getIn(participantUser, [0, 'id']),
			previousOccurrence: getIn(data, [
				'participants',
				0,
				'previousOccurrence',
			]),
			status: getIn(data, ['status']),
			netWeight: getIn(data, ['meta', 'netWeight']),
			uom: getIn(data, ['meta', 'uom']),
		}

		if (participantOrganization.length)
			yield put(
				AuthDuc.creators.fetchOrgDetails([
					scheduleDetails.participantID,
				])
			)

		if (participantUser.length)
			yield put(
				AuthDuc.creators.fetchOrgsUser([scheduleDetails.clientID])
			)

		yield put(
			GeneralSchedulerDuc.creators.setScheduleDetails(scheduleDetails)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('view-schedule'))
	}
}

function* multipleReschedules(action) {
	try {
		const { participantDetails, values, successToast } = action

		const { scheduledPeriod, startAt } = values

		const endAt = new Date(startAt.setHours(23, 59, 59))

		const rescheduleDetails = participantDetails.map(participant => {
			return {
				id: participant.scheduleID,
				startAt: new Date(startAt.setHours(0, 0, 0)),
				endAt,
				participants: [
					{
						scheduledPeriod,
						id: participant.scheduleParticipantID,
					},
				],
			}
		})

		const requestUrl = `${getPlantationEndPoint()}schedules`
		const options = {
			method: 'PUT',
			body: JSON.stringify(rescheduleDetails),
		}

		yield call(request, requestUrl, options)
		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)

		Toast({
			type: 'success',
			message: successToast,
			isMobile: isMobile || isTablet,
		})

		window.location.reload(true)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('reschedule'))
	}
}

function* deleteSchedules(action) {
	try {
		const { scheduleIDs = [], successToast, redirect } = action
		const deletescheduleDetails = scheduleIDs.map(scheduleID => {
			return {
				id: scheduleID,
			}
		})
		const requestUrl = `${getPlantationEndPoint()}schedules`
		const options = {
			method: 'DELETE',
			body: JSON.stringify(deletescheduleDetails),
		}

		yield call(request, requestUrl, options)
		const message = successToast
		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)

		Toast({
			customHeading: message,
			type: 'success',
			isMobile: isMobile || isTablet,
		})

		if (redirect)
			yield put(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.GENERAL_SCHEDULER
				)
			)

		window.location.reload(true)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* scheduleTransport(action) {
	try {
		const { scheduleDetails } = action
		const {
			id,
			organizationID,
			clientID,
			startAt,
			endAt,
			participants,
		} = scheduleDetails

		const requestUrl = `${getPlantationEndPoint()}schedules`

		const payload = [
			{
				type: 'palmoil-service-transport',
				organizationID,
				clientID,
				startAt: new Date(new Date(startAt).setHours(0, 0, 0)),
				endAt,
				meta: {
					harvestScheduleID: id,
					serviceType: 'palmoil-service-harvest',
				},
				participants,
			},
		]

		const options = {
			method: 'POST',
			body: JSON.stringify(payload),
		}

		yield call(request, requestUrl, options)
		const message = 'Transport Scheduled'
		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)

		Toast({
			customHeading: message,
			type: 'success',
			isMobile: isMobile || isTablet,
		})
		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.GENERAL_SCHEDULER
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('view-schedule'))
	}
}

function* scheduleStatusUpdate(action) {
	try {
		const { value, id, toastMessage } = action
		const scheduleDetails = {
			status: value,
		}
		const requestUrl = `${getPlantationEndPoint()}schedule/${id}`
		const options = {
			method: 'PUT',
			body: JSON.stringify(scheduleDetails),
		}
		yield call(request, requestUrl, options)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'success',
				message: toastMessage,
			})
		)
		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.GENERAL_SCHEDULER
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* createNewSchedules(action) {
	try {
		const { details, toastMessage } = action
		const { type, startAt, participants } = details

		const endAt = new Date(startAt.setHours(23, 59, 59))

		const scheduleDetails = type.map(t => {
			return {
				type: t,
				startAt: new Date(startAt.setHours(0, 0, 0)),
				endAt,
				participants,
			}
		})

		const requestUrl = `${getPlantationEndPoint()}schedules`
		const options = {
			method: 'POST',
			body: JSON.stringify(scheduleDetails),
		}

		yield call(request, requestUrl, options)

		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)

		Toast({
			type: 'success',
			message: toastMessage,
			isMobile: isMobile || isTablet,
		})

		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.GENERAL_SCHEDULER
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* editSchedule(action) {
	try {
		const { scheduleID, details, toastMessage } = action
		const { participantID, orgScheduleParticipantID, type } = details

		const scheduleDetails = {
			type,
		}

		const participantDetails = {
			id: orgScheduleParticipantID,
			participantType: 'palmoil_smallholder',
			participantID,
			clientType: 'org',
		}

		const updateScheduleURL = `${getPlantationEndPoint()}schedule/${scheduleID}`
		const updateParticipantURL = `${getPlantationEndPoint()}schedule/${scheduleID}/participant`

		const scheduleOptions = {
			method: 'PUT',
			body: JSON.stringify(scheduleDetails),
		}

		const participantOptions = {
			method: 'PUT',
			body: JSON.stringify(participantDetails),
		}

		yield call(request, updateScheduleURL, scheduleOptions)
		yield call(request, updateParticipantURL, participantOptions)

		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)

		Toast({
			type: 'success',
			message: toastMessage,
			isMobile: isMobile || isTablet,
		})

		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.GENERAL_SCHEDULER
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* assignDriver(action) {
	try {
		const { details = {}, toastMessage } = action
		const { scheduleID, userID } = details
		const requestUrl = `${getPlantationEndPoint()}schedule/${scheduleID}/participant`

		const driverDetails = {
			participantType: 'palmoil_ffbdealer_driver',
			participantID: userID,
			clientType: 'user',
		}

		const options = {
			method: 'PUT',
			body: JSON.stringify(driverDetails),
		}

		yield call(request, requestUrl, options)

		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)

		Toast({
			type: 'success',
			message: toastMessage,
			isMobile: isMobile || isTablet,
		})
		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.GENERAL_SCHEDULER
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* updateDriver(action) {
	try {
		const { details = {}, toastMessage } = action
		const { scheduleID, userID, scheduleParticipantID } = details
		const requestUrl = `${getPlantationEndPoint()}schedule/${scheduleID}/participant`

		const driverDetails = {
			id: scheduleParticipantID,
			participantType: 'palmoil_ffbdealer_driver',
			participantID: userID,
			clientType: 'user',
		}

		const options = {
			method: 'PUT',
			body: JSON.stringify(driverDetails),
		}

		yield call(request, requestUrl, options)

		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)

		Toast({
			type: 'success',
			message: toastMessage,
			isMobile: isMobile || isTablet,
		})
		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.GENERAL_SCHEDULER
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

export default function* GeneralSchedulerSaga() {
	try {
		yield all([
			takeLatest(
				GeneralSchedulerDuc.creators.fetchScheduleListing().type,
				fetchScheduleListing
			),
			takeLatest(
				GeneralSchedulerDuc.creators.fetchScheduleDetails().type,
				fetchScheduleDetails
			),
			takeLatest(
				GeneralSchedulerDuc.creators.multipleReschedules().type,
				multipleReschedules
			),
			takeLatest(
				GeneralSchedulerDuc.creators.deleteSchedules().type,
				deleteSchedules
			),
			takeLatest(
				GeneralSchedulerDuc.creators.scheduleTransport().type,
				scheduleTransport
			),
			takeLatest(
				GeneralSchedulerDuc.creators.editSchedule().type,
				editSchedule
			),
			takeLatest(
				GeneralSchedulerDuc.creators.scheduleStatusUpdate().type,
				scheduleStatusUpdate
			),
			takeLatest(
				GeneralSchedulerDuc.creators.createNewSchedules().type,
				createNewSchedules
			),
			takeLatest(
				GeneralSchedulerDuc.creators.assignDriver().type,
				assignDriver
			),
			takeLatest(
				GeneralSchedulerDuc.creators.updateDriver().type,
				updateDriver
			),
		])
	} catch (err) {
		logger.log(err)
	}
}
