/* @flow */
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import type { Node } from 'react'
import { Box } from '../../utils/Box'
import { applyMediaQueries } from '../../utils/helpers'
import theme from '../../utils/base-theme'
import { Icon } from '../../icons/components/Icon'
import InfoIcon from '../../icons/info_black.svg'

type TabConfig = {
	name: string,
	title: string,
	toolTip?: string,
	IndicatorComponent: ({ isActive: boolean }) => Node,
	hideIndicator?: boolean,
	noshadow?: boolean,
	bodyShadow?: boolean,
}

type Props = {
	id?: string,
	activeTabIndex?: number,
	tabComponentsMap:
		| { string: ({ activeTab: TabConfig }) => Node }
		| (TabConfig => Node),
	onChange: ({
		name: string,
		title: string,
		IndicatorComponent: ({ isActive: boolean }) => Node,
		hideIndicator?: boolean,
	}) => {},
	tabs: Array<TabConfig>,
	extendSpace?: boolean,
	selectedTab?: Number,
	headerOverFlow?: boolean,
	tabHeaderwidth?: String,
	noshadow?: boolean,
	tabWidth?: string, // width of each tab
	bodyWidth?: string, // width of the Tab's body
	bodyHeight?: string, // height of the Tab's body
	bodyShadow?: boolean,
	extendStyles?: {},
	style?: {},
}

const TabWrapper = styled.div(p => ({
	display: p.isMobile ? 'block' : 'flex',
	background: 'transparent',
}))

const BodyWrapper = styled.div(p => ({
	width: p.width ? p.width : 'inherit',
	height: 'auto',
	minHeight: p.minHeight ? p.minHeight : 152,
	margin: '0 4px 4px',
	borderBottomLeftRadius: '4px',
	borderBottomRightRadius: '4px',
	boxShadow: p.bodyShadow ? '' : 'rgba(0, 0, 0, 0.1) 0 0 3px',
	...p.extendStyles,
}))

const getBorder = isMobileCheck => {
	return isMobileCheck ? '1px solid #ccc' : 'none'
}

const TabHeader = styled.div(p => ({
	display: 'flex',
	width: 'auto',
	minWidth: p.minWidth ? p.minWidth : 150,
	padding: '16px',
	background: p.active ? p.theme.color.grey13 : p.theme.color.white,
	borderBottom: p.active
		? `3px solid ${p.theme.color.blue2}`
		: getBorder(p.isMobile),
	color: p.active ? p.theme.color.primary : p.theme.color.accent2,
	fontWeight: p.active ? 500 : 400,
	borderRight: p.noshadow ? 'none' : '1px solid #ccc',
	alignItems: 'center',
	justifyContent: 'center',
	cursor: 'pointer',
	flexShrink: 0,
	'&:first-child': {
		borderTopLeftRadius: '4px',
	},
	...(p.selectedTab && {
		[`:nth-child(${p.selectedTab})`]: {
			backgroundColor: p.theme.color.grey2,
		},
	}),
	'&:last-child': {
		borderTopRightRadius: '4px',
	},
}))

const TabHeaderFullWidth = styled.div(p => ({
	display: 'flex',
	width: 'auto',
	minWidth: p.minWidth ? p.minWidth : 150,
	padding: '16px',
	background: '#EDEEF2',
	borderBottom: p.active
		? `3px solid ${p.theme.color.blue2}`
		: getBorder(p.isMobile),
	color: p.active ? p.theme.color.primary : p.theme.color.accent2,
	fontWeight: p.active ? 500 : 400,
	alignItems: 'center',
	justifyContent: 'center',
	cursor: 'pointer',
	flexShrink: 0,
	'&:first-child': {
		borderTopLeftRadius: '4px',
	},
	'&:last-child': {
		borderTopRightRadius: '4px',
	},
}))

const TabLabel = styled.div({
	padding: '0 4px',
})

const DefaultTabIndicator = styled.div(p => ({
	padding: '2px 7px',
	background: p.theme.color.grey2,
	borderRadius: '4px',
	fontSize: p.theme.fontSize.s,
	margin: '0 8px',
	...p.extendStyles,
}))

const ShadowWrapper = styled.div(p => ({
	marginLeft: '4px',
	marginRight: '4px',
	marginTop: '4px',
	...(p.headerOverFlow && { overflowX: 'auto' }),
	width: p.tabHeaderwidth ? p.tabHeaderwidth : 'fit-content',
	borderTopLeftRadius: '4px',
	borderTopRightRadius: '4px',
	boxShadow: p.noshadow
		? 'none'
		: '0 0 3px rgba(0,0,0,0.05), 0 0px 3px rgba(0,0,0,0.08)',
	...applyMediaQueries(['mobile'], {
		width: '100%',
		paddingRight: '9px',
	}),
}))
const FullWidthWrapper = styled.div(p => ({
	marginLeft: '4px',
	marginRight: '4px',
	marginTop: '4px',
	width: '100%',
	background: '#EDEEF2',
	...applyMediaQueries(['mobile'], {
		width: '100%',
		paddingRight: '10px',
	}),
}))

const Tabs = ({
	id,
	tabWidth,
	bodyWidth,
	bodyHeight,
	tabs = [],
	activeTabIndex,
	tabComponentsMap,
	onChange,
	extendSpace = false,
	selectedTab = null,
	headerOverFlow = false,
	bodyShadow = false,
	extendStyles = {},
	style = {},
	noshadow,
	tabHeaderwidth = '',
	isMobile = false,
}: Props) => {
	const [activeIndex, setActiveIndex] = useState(activeTabIndex)

	useEffect(() => {
		setActiveIndex(activeTabIndex)
	}, [activeTabIndex])

	const activeConf = tabs.find((_, _index) => activeIndex === _index) || {}
	const { name: activeName } = activeConf

	const ActiveTabComponent =
		typeof tabComponentsMap === 'function'
			? tabComponentsMap(activeConf)
			: tabComponentsMap[activeName] || null

	return (
		<>
			<Box style={{ borderRadius: 4, ...style }}>
				<ShadowWrapper
					noshadow={noshadow}
					headerOverFlow={headerOverFlow}
					tabHeaderwidth={tabHeaderwidth}
				>
					<TabWrapper id={id || 'tabWrapper'} isMobile={isMobile}>
						{tabs.map(
							(
								{
									name,
									title,
									IndicatorComponent,
									hideIndicator,
									toolTip,
								},
								_index
							) => (
								<TabHeader
									extendSpace={extendSpace}
									key={`${name}-${title}`}
									id={`${name}_${title.replace(/ /g, '')}`}
									active={_index === activeIndex}
									// $FlowFixMe
									onClick={() => {
										setActiveIndex(_index)

										onChange(
											tabs.find(
												(_, __index) =>
													_index === __index
											) || {}
										)
									}}
									noshadow={noshadow}
									minWidth={tabWidth}
									title={toolTip}
									selectedTab={selectedTab} // color change for selected header
									isMobile={isMobile}
								>
									<TabLabel>{title}</TabLabel>
									{!hideIndicator && IndicatorComponent && (
										<IndicatorComponent
											isActive={_index === activeIndex}
										/>
									)}
								</TabHeader>
							)
						)}
					</TabWrapper>
				</ShadowWrapper>
				<BodyWrapper
					width={bodyWidth}
					minHeight={bodyHeight}
					bodyShadow={bodyShadow}
					extendStyles={extendStyles}
				>
					{ActiveTabComponent && ( // $FlowFixMe
						<ActiveTabComponent
							activeTab={
								tabs.find(
									(_, __index) => activeIndex === __index
								) || {}
							}
						/>
					)}
				</BodyWrapper>
			</Box>
		</>
	)
}
const TabsWithFullWidth = ({
	id,
	tabWidth,
	bodyWidth,
	bodyHeight,
	tabs = [],
	activeTabIndex,
	tabComponentsMap,
	onChange,
	extendSpace = false,
	bodyShadow = false,
	noshadow,
	isMobile = false,
}: Props) => {
	const [activeIndex, setActiveIndex] = useState(activeTabIndex)

	useEffect(() => {
		setActiveIndex(activeTabIndex)
	}, [activeTabIndex])

	const activeConf = tabs.find((_, _index) => activeIndex === _index) || {}
	const { name: activeName } = activeConf

	const ActiveTabComponent =
		typeof tabComponentsMap === 'function'
			? tabComponentsMap(activeConf)
			: tabComponentsMap[activeName] || null

	return (
		<>
			<Box style={{ borderRadius: 4 }}>
				<FullWidthWrapper noshadow={noshadow}>
					<TabWrapper id={id || 'tabWrapper'} isMobile={isMobile}>
						{tabs.map(
							(
								{
									name,
									title,
									IndicatorComponent,
									hideIndicator,
									showToolTip,
									toolTipText,
								},
								_index
							) => (
								<TabHeaderFullWidth
									extendSpace={extendSpace}
									key={`${name}-${title}`}
									id={`${name}_${title.replace(/ /g, '')}`}
									active={_index === activeIndex}
									// $FlowFixMe
									onClick={() => {
										setActiveIndex(_index)

										onChange(
											tabs.find(
												(_, __index) =>
													_index === __index
											) || {}
										)
									}}
									noshadow={noshadow}
									minWidth={tabWidth}
									isMobile={isMobile}
								>
									<TabLabel>{title}</TabLabel>
									{!hideIndicator && IndicatorComponent && (
										<IndicatorComponent
											isActive={_index === activeIndex}
										/>
									)}
									{showToolTip && (
										<Box
											style={{
												width: 40,
												height: 40,
												padding: 10,
											}}
											center
											title={toolTipText}
										>
											<Icon
												glyph={InfoIcon}
												color={theme.color.black1}
											/>
										</Box>
									)}
								</TabHeaderFullWidth>
							)
						)}
					</TabWrapper>
				</FullWidthWrapper>
				<BodyWrapper
					width={bodyWidth}
					minHeight={bodyHeight}
					bodyShadow={bodyShadow}
					extendStyles={{
						margin: '20px 0px 4px 4px',
					}}
				>
					{ActiveTabComponent && ( // $FlowFixMe
						<ActiveTabComponent
							activeTab={
								tabs.find(
									(_, __index) => activeIndex === __index
								) || {}
							}
						/>
					)}
				</BodyWrapper>
			</Box>
		</>
	)
}

Tabs.defaultProps = {
	activeTabIndex: 0,
	onChange: () => {},
}

TabsWithFullWidth.defaultProps = {
	activeTabIndex: 0,
	onChange: () => {},
}

export { Tabs, DefaultTabIndicator, TabsWithFullWidth }
