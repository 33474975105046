import React, { useCallback, useEffect, useState } from 'react'
import { Box } from 'ui-lib/utils/Box'
import { Input } from 'ui-lib/components/Input'
import { Label } from 'ui-lib/components/Label'
import theme from 'ui-lib/utils/base-theme'
import { Spacer } from 'ui-lib/utils/Spacer'
import { merge, getIn } from 'timm'
import { AppDuc } from 'core-app/modules/App/duc'
import { useTranslation } from 'react-i18next'
import { TextArea } from 'ui-lib/components/TextArea'
import { useSelector, useDispatch } from 'react-redux'
import { getStates, getCountries } from 'country-state-picker'
import { SelectSearch } from 'ui-lib/components/Select'
import { isEmptyObject } from 'core-app/utils/helpers'
import { WebTourDuc } from 'core-app/modules/WebTour/duc'

const EditOrgnisation = ({ orgDetails, onChange }) => {
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const { t } = useTranslation()
	const countries = getCountries()
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)
	const dispatch = useDispatch()
	if (tourModule !== '') {
		dispatch(WebTourDuc.creators.setActiveTourModule(''))
	}
	const countryList = []
	// eslint-disable-next-line array-callback-return
	countries.map(value => {
		if (value.code !== 'gg') {
			countryList.push({
				name: value.code,
				label: value.name,
			})
		}
	})
	const defaultCountry = countryList.filter(
		_country => _country.label === orgDetails.country
	)
	const countryCode = !isEmptyObject(defaultCountry)
		? getIn(defaultCountry, [0, 'name']) || ''
		: ''
	const [stateList, setStateList] = useState([])
	const getStateCode = code => {
		const _stateList = getStates(code)

		const stateDataList = _stateList.map(state => {
			return {
				name: state,
				label: state,
			}
		})
		setStateList(stateDataList)
	}

	const handleChange = useCallback(
		keyValue => {
			onChange(merge(orgDetails, keyValue))
		},

		[onChange, orgDetails]
	)

	const countryNames = []
	countryList.forEach(obj => countryNames.push(obj.name))

	useEffect(() => {
		handleChange()
	}, [handleChange])

	useEffect(() => {
		if (countryCode) {
			// eslint-disable-next-line no-param-reassign
			getStateCode(countryCode)
		}
	}, [countryCode, orgDetails.country])

	return (
		<Box>
			<Box row style={{ width: '100%' }} justifyContent="space-between">
				<Box style={{ width: '100%' }}>
					<Label
						style={{
							fontWeight: '300px',
							fontSize: '16px',
							color: theme.color.accent2,
							width: '100%',
						}}
					>
						{t('onboarding.orgName')}
					</Label>
					<Spacer size={8} />
					<Box style={{ width: '100%' }}>
						<Input
							value={orgDetails.name}
							name="name"
							type="text"
							returnKeyValue
							disabled
						/>
					</Box>
				</Box>
			</Box>
			<Box row style={{ width: '100%' }} justifyContent="space-between">
				<Box style={{ width: '100%' }}>
					<Label
						style={{
							fontWeight: '300px',
							fontSize: '16px',
							color: theme.color.accent2,
							width: '100%',
						}}
					>
						{t('tdmDetailsEntry.description')}
					</Label>
					<Spacer size={8} />
					<Box style={{ width: '100%' }}>
						<TextArea
							type="text"
							returnKeyValue
							name="description"
							onChange={handleChange}
							value={orgDetails.description}
						/>
					</Box>
				</Box>
			</Box>
			<Box row style={{ width: '100%' }} justifyContent="space-between">
				<Box style={{ width: '48%' }}>
					<Label
						style={{
							fontWeight: '300px',
							fontSize: '16px',
							color: theme.color.accent2,
							width: '100%',
						}}
					>
						{t('onboarding.phoneNumber')}
					</Label>
					<Spacer size={8} />
					<Box style={{ width: '100%' }}>
						<Input
							value={orgDetails.mobile}
							name="mobile"
							type="text"
							returnKeyValue
							disabled
						/>
					</Box>
				</Box>

				<Box style={{ width: '48%' }}>
					<Label
						style={{
							fontWeight: '300px',
							fontSize: '16px',
							color: theme.color.accent2,
							width: '100%',
						}}
					>
						{t('onboarding.email')}
					</Label>
					<Spacer size={8} />
					<Box style={{ width: '100%' }}>
						<Input
							value={orgDetails.email}
							name="email"
							type="text"
							returnKeyValue
							disabled
						/>
					</Box>
				</Box>
			</Box>
			<Box
				row={!isMobile}
				style={{ width: '100%' }}
				justifyContent="space-between"
			>
				<Box
					style={{
						width: isMobile ? '100%' : '47.5%',
					}}
				>
					<Label
						required
						style={{
							fontWeight: '400px',
							fontSize: '16px',
							color: theme.color.accent2,
						}}
					>
						{t('onboarding.country')}
					</Label>
					<SelectSearch
						required
						placeholder={t('onboarding.countryPlaceHolder')}
						value={countryCode}
						options={countryList}
						onChange={value => {
							if (!isEmptyObject(value)) {
								handleChange({
									country: value.label,
								})
								getStateCode(value.name)
							}
						}}
						labelKey="label"
						valueKey="name"
						defaultLabel={t('common.selectDefaultLabel')}
						returnOnlyValue
						disabled
						style={{
							background:
								orgDetails.country === ''
									? `${theme.color.grey11} 0% 0% no-repeat padding-box`
									: `${theme.color.white} 0% 0% no-repeat padding-box`,
							boxShadow:
								orgDetails.country === ''
									? '0px 6px 16px #05050707'
									: `0px 2px 8px ${theme.color.blue6}`,
							border:
								orgDetails.country === ''
									? `1px solid ${theme.color.grey4}`
									: `1px solid ${theme.color.blue5}`,
							borderRadius: '4px',
							width: '100%',
							opacity: 1,
							marginTop: '8px',
						}}
					/>
				</Box>
				<Box
					style={{
						width: isMobile ? '100%' : '47.5%',
					}}
				>
					<Label
						required
						style={{
							fontWeight: '400px',
							fontSize: '16px',
							color: theme.color.accent2,
						}}
					>
						{t('onboarding.state')}
					</Label>
					<SelectSearch
						placeholder={t('onboarding.statePlaceHolder')}
						value={orgDetails.state}
						options={stateList}
						onChange={value => {
							handleChange({ state: value.name })
						}}
						labelKey="name"
						valueKey="label"
						defaultLabel={t('common.selectDefaultLabel')}
						returnOnlyValue
						disabled
						style={{
							background:
								orgDetails.state === ''
									? `${theme.color.grey11} 0% 0% no-repeat padding-box`
									: `${theme.color.white} 0% 0% no-repeat padding-box`,

							boxShadow:
								orgDetails.state === ''
									? '0px 6px 16px #05050707'
									: `0px 2px 8px ${theme.color.blue6}`,
							border:
								orgDetails.state === ''
									? `1px solid ${theme.color.grey4}`
									: `1px solid ${theme.color.blue5}`,
							borderRadius: '4px',
							width: '100%',
							opacity: 1,
							marginTop: '8px',
						}}
					/>
				</Box>
			</Box>

			<Box
				row={!isMobile}
				style={{ width: '100%' }}
				justifyContent="space-between"
			>
				<Box style={{ width: '33%' }}>
					<Box style={{ width: '100%' }}>
						<Label
							required
							style={{
								fontWeight: '300px',
								fontSize: '16px',
								color: theme.color.accent2,
								width: '100%',
							}}
						>
							{t('onboarding.addressLine1')}
						</Label>
						<Spacer size={8} />
						<Box style={{ width: '100%' }}>
							<Input
								value={orgDetails.line1}
								name="line1"
								type="text"
								returnKeyValue
								onChange={handleChange}
								disabled
								extendStyles={{
									background: `${theme.color.white} 0% 0% no-repeat padding-box`,
									boxShadow: `0px 2px 8px ${theme.color.blue6}`,
									border: `1px solid ${theme.color.blue5}`,
									borderRadius: '4px',
									opacity: 1,
								}}
							/>
						</Box>
					</Box>
				</Box>
				<Box style={{ width: '33%' }}>
					<Box style={{ width: '100%' }}>
						<Label
							style={{
								fontWeight: '300px',
								fontSize: '16px',
								color: theme.color.accent2,
								width: '100%',
							}}
						>
							{t('onboarding.addressLine2')}
						</Label>
						<Spacer size={8} />
						<Box style={{ width: '100%' }}>
							<Input
								value={orgDetails.line2}
								name="line2"
								type="text"
								returnKeyValue
								onChange={handleChange}
								disabled
								extendStyles={{
									background: `${theme.color.white} 0% 0% no-repeat padding-box`,
									boxShadow: `0px 2px 8px ${theme.color.blue6}`,
									border: `1px solid ${theme.color.blue5}`,
									borderRadius: '4px',
									opacity: 1,
								}}
							/>
						</Box>
					</Box>
				</Box>
				<Box style={{ width: '33%' }}>
					<Box style={{ width: '100%' }}>
						<Label
							required
							style={{
								fontWeight: '300px',
								fontSize: '16px',
								color: theme.color.accent2,
								width: '100%',
							}}
						>
							{t('onboarding.village/city')}
						</Label>
						<Spacer size={8} />
						<Box style={{ width: '100%' }}>
							<Input
								value={orgDetails.city}
								name="city"
								type="text"
								returnKeyValue
								disabled
								onChange={handleChange}
								extendStyles={{
									background: `${theme.color.white} 0% 0% no-repeat padding-box`,
									boxShadow: `0px 2px 8px ${theme.color.blue6}`,
									border: `1px solid ${theme.color.blue5}`,
									borderRadius: '4px',
									opacity: 1,
								}}
							/>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}
export default EditOrgnisation
