import React from 'react'
import { StorageCompanyDuc } from 'core-app/modules/StorageCompany/duc'
import { useSelector } from 'react-redux'
import { UnexpectedErrorBlock } from 'core-app/modules/App/components/Error'
import { CreateMapOutput } from './CreateMapOutput'
import { ViewMapOutput } from './ViewMapOutput'
import { AssignTank } from './AssignTank'
import { AssignTankView } from './AssignTankView'

const DocumentCreation = () => {
	const { payload = {} } = useSelector(StorageCompanyDuc.selectors.location)

	if (payload.rootModule === 'map-output') {
		const { action } = payload

		if (action === 'create' || action === 'edit') {
			return <CreateMapOutput />
		}

		if (action === 'view') {
			return <ViewMapOutput />
		}
	} else if (payload.rootModule === 'assign-tank') {
		const { action } = payload
		if (action === 'create' || action === 'edit') {
			return <AssignTank />
		}

		if (action === 'view') {
			return <AssignTankView />
		}
	}

	return <UnexpectedErrorBlock status={401} />
}

export { DocumentCreation }
