import React from 'react'
import { Box } from 'ui-lib/utils/Box'
import { Label, TableTitle } from 'ui-lib/components/Typography'
import { getIn } from 'timm'
import { ButtonWithNoBorder } from 'ui-lib/components/Button'
import { getDateByFormat } from 'core-app/utils/date'
import { TABLE_ALIAS } from '../config'

export const getColumnConfig = ({ orgDetails, onChange, t }) => {
	return [
		{
			property: 'createdAt',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.createdAt)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { createdAt } = datum

				return <Label as="span">{getDateByFormat(createdAt)}</Label>
			},
		},
		{
			property: 'smallholder',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.smallholder)}</TableTitle>
				</Box>
			),
			render: datum => {
				const orgID = getIn(datum, ['organizationID'])
				const organization = orgDetails[orgID]
				const orgName = getIn(organization, ['name'])

				return <Label as="span">{orgName}</Label>
			},
			primary: true,
		},
		{
			property: 'ffbCount',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.ffbCount)}</TableTitle>
				</Box>
			),
			render: datum => {
				const value = getIn(datum, ['ffbCount']) || '- - -'

				return <Label as="span">{value}</Label>
			},
			primary: true,
		},
		{
			property: 'callToAction',
			render: datum => {
				return (
					<ButtonWithNoBorder
						label={t('common.viewBtn').toUpperCase()}
						onClick={() =>
							onChange('open_document', {
								row: datum,
							})
						}
					/>
				)
			},
		},
	]
}
