import React from 'react'
import { useSelector } from 'react-redux'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { NotFoundBlock } from 'core-app/modules/App/components/Error'
import { VerifyOtp } from 'core-app/modules/Auth/containers/VerifyOtp'
import VerifySocial from 'core-app/modules/Auth/containers/VerifySocial'

const ComponentsMap = {
	'verify-email': VerifyOtp,
	'verify-social': VerifySocial,
}

const AuthCore = () => {
	const { payload = {} } = useSelector(AuthDuc.selectors.location)
	const { action } = payload
	const CoreComponent = ComponentsMap[action] || NotFoundBlock

	return <CoreComponent />
}

export default AuthCore
