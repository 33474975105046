/* eslint-disable radix */
import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AppDuc } from 'core-app/modules/App/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { Box } from 'ui-lib/utils/Box'
import { getIn } from 'timm'
import { CookieDuc } from 'core-app/modules/App/cookieDuc'
import { USER_COOKIE_KEYS } from 'ui-lib/utils/config'
import { Modal } from 'ui-lib/components/Modal'
import EditIcon from 'ui-lib/icons/edit.svg'
import { Input } from 'ui-lib/components/Input'
import { SelectSearch } from 'ui-lib/components/Select'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Icon } from 'ui-lib/icons/components/Icon'
import { H2, H3, Label } from 'ui-lib/components/Typography'
import { Button, ButtonIconWrapper } from 'ui-lib/components/Button'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { isEmptyObject } from 'core-app/utils/helpers'
import { TransparentBgSpinner } from 'ui-lib/components/Spinner/TransparentBgSpinner'
import { CollapsableSegment } from 'ui-lib/components/CollapsableSegments'
import { DatePicker } from 'ui-lib/components/Datepicker'
import { ProductSelection } from 'core-app/shared/components/ProductSelection'
import { SummaryBlock } from 'core-app/modules/Plantation/components/SummaryBlock'
import { Title } from 'core-app/shared/components/Title'
import {
	NAME_ALIASES,
	LabelsMap,
	PlantationDuc,
} from 'core-app/modules/Plantation/duc'
import { WebTour } from 'core-app/modules/WebTour'
import { WebTourDuc } from 'core-app/modules/WebTour/duc'

const CreateTicket = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const [startDate, setStartDate] = useState(new Date())
	const sDate = new Date().fp_incr(-6)
	const [errMsgColor, setErrMsgColor] = useState('#172B4D')

	const locationState = useSelector(PlantationDuc.selectors.location)
	const { isMobile } = useSelector(AppDuc.selectors.detection)

	const users = useSelector(AuthDuc.selectors.getOrgUsers)
	const harvesterList = users.map(user => {
		return {
			id: user.id,
			label: user.fullName,
		}
	})
	const isLoading = useSelector(
		PlantationDuc.selectors.getListingLoadingStatus
	)
	const initialValues = useSelector(
		PlantationDuc.selectors.fetchInitialValues
	)
	const showModal = useSelector(
		PlantationDuc.selectors.getProductSelectionModalStatus
	)

	const allProducts = useSelector(AuthDuc.selectors.getProducts)

	const activeProductID = useSelector(
		PlantationDuc.selectors.getActiveProductID
	)

	const activeProduct = allProducts.find(
		product => product.id === activeProductID
	)
	const code = getIn(activeProduct, ['code']) || ''
	const name = getIn(activeProduct, ['name']) || ''

	const { payload = {} } = locationState
	const { rootModule, action } = payload
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)

	useEffect(() => {
		if (tourModule === 'createTicket') {
			dispatch(WebTourDuc.creators.setActiveTourModule('createTicket2'))
		}
	}, [tourModule, dispatch])
	const getBreadCrumbsList = useCallback(
		(title, actionTitle) => [
			{
				label: t('breadcrumb.home'),
				name: 'home',
				isActive: true,
			},
			{
				label: t(title),
				name: 'ticket',
				isActive: true,
			},
			{
				label: `${t(actionTitle)} ${t(title)}`,
				name: 'submodule-dashboard',
				isActive: false,
			},
		],
		[t]
	)

	const validationSchema = yup
		.object()
		.shape({
			harvester: yup.string().required(t('validation.harvesterRequired')),
			ffbCount: yup
				.number()
				.min(0)
				.required(t('validation.ffbCountRequired')),
			area: yup
				.string()
				.trim()
				.matches(/^[a-zA-Z0-9]+$/, 'Area should be alphanumeric ')
				.required(t('validation.areaRequired')),
			block: yup
				.string()
				.trim()
				.matches(/^[a-zA-Z0-9]+$/, 'Block should be alphanumeric ')
				.required(t('validation.blockRequired')),
			phase: yup
				.string()
				.trim()
				.matches(/^[a-zA-Z0-9]+$/, 'Phase should be alphanumeric ')
				.required(t('validation.phaseRequired')),
			ripeBunches: yup
				.number()
				.integer(t('validation.numericalValuew/oDecimal'))
				.min(0),
			overripeBunches: yup
				.number()
				.integer(t('validation.numericalValuew/oDecimal'))
				.min(0),
			underripeBunches: yup
				.number()
				.integer(t('validation.numericalValuew/oDecimal'))
				.min(0),
			emptyBunches: yup
				.number()
				.integer(t('validation.numericalValuew/oDecimal'))
				.min(0),
			wetBunches: yup
				.number()
				.integer(t('validation.numericalValuew/oDecimal'))
				.min(0),
			unripeBunches: yup
				.number()
				.integer(t('validation.numericalValuew/oDecimal'))
				.min(0),
		})
		// eslint-disable-next-line func-names
		.test('sums', function(value) {
			if (!value) return true
			const {
				ripeBunches,
				emptyBunches,
				overripeBunches,
				underripeBunches,
				wetBunches,
				unripeBunches,
				ffbCount,
			} = value
			let valid = false

			valid =
				(parseInt(ripeBunches) || 0) +
					(parseInt(emptyBunches) || 0) +
					(parseInt(overripeBunches) || 0) +
					(parseInt(underripeBunches) || 0) +
					(parseInt(wetBunches) || 0) +
					(parseInt(unripeBunches) || 0) ===
				ffbCount

			if (!valid && ffbCount) {
				setErrMsgColor('#cb4262')
			} else {
				setErrMsgColor('#172B4D')
			}

			return (
				valid ||
				this.createError({
					path: 'ffbCount',
					message: '',
				})
			)
		})

	/** Handlers */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'ticket') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.PLANTATION$SUBROOT,
					{ rootModule: 'ticket' }
				)
			)
		}
	}

	const handleClose = useCallback(() => {
		dispatch(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.PLANTATION$SUBROOT,
				{
					rootModule,
				}
			)
		)
	}, [dispatch, rootModule])

	const handleConfirm = value => {
		const productID = getIn(value, ['id'])
		dispatch(
			CookieDuc.creators.setCookie({
				cookieName: USER_COOKIE_KEYS.PRODUCT_ID,
				cookieValue: productID,
			})
		)

		dispatch(PlantationDuc.creators.setActiveProductID(productID))
		dispatch(PlantationDuc.creators.toggleProductSelectionPopup(false))
	}

	const title = LabelsMap[rootModule]
	const actionTitle = NAME_ALIASES[action]

	const breadCrumbs = useMemo(() => getBreadCrumbsList(title, actionTitle), [
		getBreadCrumbsList,
		title,
		actionTitle,
	])

	const {
		handleSubmit,
		submitForm,
		values,
		handleChange,
		handleBlur,
		touched,
		errors,
		setFieldValue,
		isSubmitting,
	} = useFormik({
		initialValues,
		enableReinitialize: true,
		validationSchema,
		onSubmit: _values => {
			dispatch(
				PlantationDuc.creators.createTicket(
					_values,
					t('common.successCreate'),
					t('common.updateSuccess')
				)
			)
		},
	})

	const disableCTA =
		!isEmptyObject(errors) ||
		!(values.ffbCount > 0 || values.ffbCount === 0)

	return (
		<>
			<Box padding={8} width="100%" height="100%">
				<Modal
					forceCloseviaButton
					show={showModal}
					noPadding
					size="large"
					body={
						<ProductSelection
							onClose={handleClose}
							onConfirm={handleConfirm}
							activeProductID={activeProductID}
						/>
					}
					hideButtons
					onClose={handleClose}
				/>

				{!showModal && (
					<>
						<Box style={{ padding: '0 5' }}>
							<Breadcrumb
								links={breadCrumbs}
								onClick={target =>
									handleBreadCrumbClick(target)
								}
							/>
						</Box>
						<Spacer size={16} />

						<Box row justifyContent="space-between">
							<H3>{t('common.enterDetails')}</H3>
							<Box row justifyContent="flex-end">
								<SummaryBlock
									name={t('createWork.productLabel')}
									detail={`${name} (#${code})`}
								/>
								<Spacer size={16} horizontal />
								<Button
									rounded
									customIcon={
										<ButtonIconWrapper lightBG>
											<Icon glyph={EditIcon} />
										</ButtonIconWrapper>
									}
									onClick={() =>
										dispatch(
											PlantationDuc.creators.toggleProductSelectionPopup(
												true
											)
										)
									}
								/>
							</Box>
						</Box>

						<Box
							row
							justifyContent="space-between"
							alignItems="baseline"
						>
							<Title
								title={`${t(actionTitle)} ${t(
									'common.ticket'
								)}`}
							/>
						</Box>
						<Spacer size={8} />
						<Box
							row
							justifyContent="space-between"
							alignItems="center"
							style={{ paddingBottom: 24 }}
						>
							<Box
								style={{
									marginTop: '16px',
									marginRight: '8px',
									width: 255,
								}}
							>
								<DatePicker
									value={startDate}
									minDate={sDate}
									maxDate={new Date()}
									onChange={value => {
										setStartDate(value)
										setFieldValue('createdDate', value)
									}}
									placeholder={t('common.selectDate')}
								/>
							</Box>
						</Box>

						<CollapsableSegment
							expand
							title={`${t(actionTitle)} ${t(
								'common.ticket'
							)} - ${t('common.checker')}`}
							hasError={disableCTA}
							open
							message={
								disableCTA
									? t('validation.collapsableSegmentWarning')
									: t('validation.collapsableSegmentSuccess')
							}
							onToggle={() => {}}
						>
							<Box padding={isMobile ? '16px' : '24px'}>
								{isLoading && <TransparentBgSpinner />}
								<form onSubmit={handleSubmit}>
									<Box row={!isMobile}>
										<Box width={300}>
											<Box id="input-chooseTicket">
												<SelectSearch
													label={t(
														'plantationCreateTicket.harvester'
													)}
													value={values.harvester}
													name="harvester"
													onChange={value => {
														setFieldValue(
															'harvester',
															value.id
														)
													}}
													returnOnlyValue
													options={harvesterList}
													labelKey="label"
													valueKey="id"
													defaultLabel={t(
														'common.selectDefaultLabel'
													)}
													error={
														touched.harvester &&
														errors.harvester
													}
													required
												/>
											</Box>
										</Box>
										<Spacer size={32} />
										<Box id="input-enterTktDetails">
											<Box width={300}>
												<Input
													label={t(
														'plantationCreateTicket.ffbCount'
													)}
													name="ffbCount"
													value={values.ffbCount}
													type="number"
													onChange={handleChange}
													onBlur={handleBlur}
													error={
														touched.ffbCount &&
														errors.ffbCount
													}
													required
												/>
											</Box>
											<Spacer size={32} />
											<Box width={300}>
												<Input
													label={t(
														'plantationCreateTicket.selectArea'
													)}
													value={values.area}
													name="area"
													type="text"
													onChange={handleChange}
													onBlur={handleBlur}
													error={
														touched.area &&
														errors.area
													}
													required
												/>
											</Box>

											<Box row={!isMobile}>
												<Box width={300}>
													<Input
														label={t(
															'plantationCreateTicket.selectBlock'
														)}
														value={values.block}
														name="block"
														type="text"
														onChange={handleChange}
														onBlur={handleBlur}
														error={
															touched.block &&
															errors.block
														}
														required
													/>
												</Box>
												<Spacer size={32} />
												<Box width={300}>
													<Input
														label={t(
															'plantationCreateTicket.selectPhase'
														)}
														value={values.phase}
														name="phase"
														type="text"
														onChange={handleChange}
														onBlur={handleBlur}
														error={
															touched.phase &&
															errors.phase
														}
														required
													/>
												</Box>
											</Box>
										</Box>
									</Box>

									<Box id="input-qualityParams">
										<H2 style={{ padding: '32px 0 0px' }}>
											{t(
												'plantationCreateTicket.qualityDetails'
											)}
										</H2>
										<Label
											style={{
												color: errMsgColor,
											}}
										>
											{t(
												'plantationCreateTicket.qualityParamsText'
											)}
										</Label>
										<Spacer size={32} />

										<Box row={!isMobile}>
											<Box width={300}>
												<Input
													label={t(
														'plantationCreateTicket.ripe'
													)}
													name="ripeBunches"
													value={values.ripeBunches}
													type="number"
													onChange={handleChange}
													onBlur={handleBlur}
													error={
														touched.ripeBunches &&
														errors.ripeBunches
													}
												/>
											</Box>
											<Spacer size={32} />
											<Box width={300}>
												<Input
													label={t(
														'plantationCreateTicket.overripe'
													)}
													name="overripeBunches"
													value={
														values.overripeBunches
													}
													type="number"
													onChange={handleChange}
													onBlur={handleBlur}
													error={
														touched.overripeBunches &&
														errors.overripeBunches
													}
												/>
											</Box>
										</Box>
										<Box row={!isMobile}>
											<Box width={300}>
												<Input
													label={t(
														'plantationCreateTicket.underripe'
													)}
													name="underripeBunches"
													value={
														values.underripeBunches
													}
													type="number"
													onChange={handleChange}
													onBlur={handleBlur}
													error={
														touched.underripeBunches &&
														errors.underripeBunches
													}
												/>
											</Box>
											<Spacer size={32} />
											<Box width={300}>
												<Input
													label={t(
														'plantationCreateTicket.unripe'
													)}
													name="unripeBunches"
													value={values.unripeBunches}
													type="number"
													onChange={handleChange}
													onBlur={handleBlur}
													error={
														touched.unripeBunches &&
														errors.unripeBunches
													}
												/>
											</Box>
										</Box>
										<Box row={!isMobile}>
											<Box width={300}>
												<Input
													label={t(
														'plantationCreateTicket.empty'
													)}
													name="emptyBunches"
													value={values.emptyBunches}
													type="number"
													onChange={handleChange}
													onBlur={handleBlur}
													error={
														touched.emptyBunches &&
														errors.emptyBunches
													}
												/>
											</Box>
											<Spacer size={32} />
											<Box width={300}>
												<Input
													label={t(
														'plantationCreateTicket.wet'
													)}
													name="wetBunches"
													value={values.wetBunches}
													type="number"
													onChange={handleChange}
													onBlur={handleBlur}
													error={
														touched.wetBunches &&
														errors.wetBunches
													}
												/>
											</Box>
										</Box>
									</Box>
								</form>
							</Box>
						</CollapsableSegment>
						<Spacer size={24} />
						<Box row justifyContent="flex-end">
							<Box style={{ padding: 2 }} width="200px">
								<Box id="input-createTicket">
									<Button
										label={`${t(actionTitle)}`}
										primary
										disabled={disableCTA}
										isLoading={isSubmitting}
										rounded
										onClick={() => {
											submitForm()
											dispatch(
												WebTourDuc.creators.setActiveTourModule(
													''
												)
											)
										}}
									/>
								</Box>
							</Box>
						</Box>
					</>
				)}
			</Box>
			{tourModule === 'createTicket2' && <WebTour />}
		</>
	)
}

export { CreateTicket }
