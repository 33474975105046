import { PlantationDuc } from 'core-app/modules/Plantation/duc'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { getIn } from 'timm'
import saga from 'core-app/modules/Plantation/PlantationSaga'

const binInitialValues = {
	createdDate: new Date(),
	vehicleName: '',
	vehicleNumber: '',
	crewMember: '',
	binNumber: '',
	tickets: [],
}

export default async (dispatch, getState, { action, extra }) => {
	const { type, customMeta = {} } = action
	const currentLocationFromAction = getIn(action, [
		'meta',
		'location',
		'current',
	])
	/** if someone specifically wants to skip thunk fetch again, this check helps */
	const { skipRouteThunk } = customMeta
	if (skipRouteThunk) return

	// check if nothing changed in the route.

	const state = getState()

	const locationState = currentLocationFromAction || state.location

	const { payload } = locationState
	// Inject the saga here. If it wasn't already
	extra.getSagaInjectors().injectSaga('plantation', { saga })
	dispatch(AuthDuc.creators.fetchActor())
	const isMainDashboard = MainRouteDuc.types.PLANTATION === type
	const isSubDashboard = MainRouteDuc.types.PLANTATION$SUBROOT === type
	const isTicketCreation = MainRouteDuc.types.PLANTATION$WACTION === type
	const isTicketEditorUpdateType =
		MainRouteDuc.types.PLANTATION$DOCUMENTREFERENCE === type
	const isDocumentView =
		MainRouteDuc.types.PLANTATION$DOCUMENTREFERENCETYPE === type
	const isTicketControl =
		isTicketCreation || isTicketEditorUpdateType || isDocumentView

	let activeModule = ''

	if (isMainDashboard) {
		// load the dashboard of the core module
		activeModule = 'plantation'
		dispatch(PlantationDuc.creators.fetchInsights())
	} else if (isSubDashboard) {
		activeModule = 'plantationSubDashboard'
		if (payload.rootModule === 'ticket') {
			dispatch(
				PlantationDuc.creators.fetchListingTickets(
					'ticket',
					payload.rootModule,
					locationState
				)
			)
			dispatch(AuthDuc.creators.fetchOrgsUser([], true, false))
		} else if (payload.rootModule === 'bin') {
			dispatch(
				PlantationDuc.creators.fetchListingBins(
					'bin',
					payload.rootModule,
					locationState
				)
			)
		} else if (payload.rootModule === 'document') {
			dispatch(AuthDuc.creators.fetchAllPartners())
			dispatch(AuthDuc.creators.fetchOrgsUser([], true, false))
			dispatch(PlantationDuc.creators.fetchDocuments())
		}
	} else if (isTicketControl) {
		activeModule = payload.rootModule
		if (activeModule === 'ticket') {
			if (payload.action === 'create') {
				dispatch(PlantationDuc.creators.initiateTicketCreation())
				dispatch(AuthDuc.creators.fetchOrgsUser([], true, false))
			} else if (payload.action === 'view') {
				const { documentReference } = payload
				dispatch(PlantationDuc.creators.flushTicketState())
				dispatch(AuthDuc.creators.fetchOrgsUser([], true, false))
				dispatch(
					PlantationDuc.creators.ticketPreview(documentReference)
				)
			} else if (payload.action === 'edit') {
				const { documentReference } = payload
				dispatch(PlantationDuc.creators.flushTicketState())

				dispatch(
					PlantationDuc.creators.fetchTicketInitialValues(
						documentReference
					)
				)
				dispatch(AuthDuc.creators.fetchOrgsUser([], true, false))
			}
		} else if (activeModule === 'bin') {
			if (payload.action === 'view') {
				const { documentReference } = payload
				dispatch(PlantationDuc.creators.flushBinState())

				dispatch(
					dispatch(
						PlantationDuc.creators.binPreview(documentReference)
					)
				)
			}
			if (payload.action === 'create') {
				dispatch(
					PlantationDuc.creators.fetchBinTickets(
						'bin',
						payload.rootModule,
						locationState,
						binInitialValues
					)
				)
			}
			if (payload.action === 'edit') {
				const { documentReference } = payload
				dispatch(PlantationDuc.creators.flushBinState())

				dispatch(PlantationDuc.creators.fetchBinInfo(documentReference))
			}
		} else if (activeModule === 'document') {
			const { documentReference, documentType } = payload
			if (payload.action === 'view') {
				if (documentType === 'bin') {
					dispatch(PlantationDuc.creators.flushBinState())

					dispatch(
						PlantationDuc.creators.binPreview(documentReference)
					)
				} else if (documentType === 'trips') {
					dispatch(PlantationDuc.creators.flushTicketState())
					dispatch(AuthDuc.creators.fetchOrgsUser([], true, false))
					dispatch(AuthDuc.creators.fetchPartnerOrgs([], true, false))

					dispatch(
						PlantationDuc.creators.tripPreview(
							documentReference,
							documentType
						)
					)
				} else {
					dispatch(PlantationDuc.creators.flushTicketState())
					dispatch(AuthDuc.creators.fetchOrgsUser([], true, false))
					dispatch(
						PlantationDuc.creators.ticketPreview(
							documentReference,
							documentType
						)
					)
				}
			}
		}
	}
	// load the dashboard of the core module
	dispatch(PlantationDuc.creators.setActiveModule(activeModule))

	// based on the payload or action, initiate saga's
}
