import React from 'react'
import { Box } from 'ui-lib/utils/Box'
import { H3 } from 'ui-lib/components/Typography'
import { Spacer } from 'ui-lib/utils/Spacer'

const stepStyle = {
	color: '#F6F6F6',
	background: 'linear-gradient(45deg, #5a3a75 0%, #b374e9 60%)',
	backgroundColor: '#b374e9 ',
}

export const workCreateSteps = t => {
	return [
		{
			selector: '#input-createWork',
			content: () => (
				<Box>
					<p>{t('tourCommon.createDONote')}</p>
				</Box>
			),
			action: node => {
				node.focus()
			},
			style: stepStyle,
		},
	]
}
export const workCreateSteps2 = t => {
	return [
		{
			selector: '#choose-smallholder',
			content: () => (
				<Box>
					<H3 style={{ color: '#F6F6F6', fontSize: '22px' }}>
						{`${t('tourCommon.stepNo')} 1`}
					</H3>
					<Spacer size={8} />
					<p>{t('tourAdmin.chooseSmallholder')}</p>
				</Box>
			),
			action: node => {
				node.focus()
			},
			style: stepStyle,
		},

		{
			selector: '#input-FFBandHarvester',
			content: () => (
				<Box margin="8px">
					<H3 style={{ color: '#F6F6F6', fontSize: '22px' }}>
						{`${t('tourCommon.stepNo')} 2`}
					</H3>
					<Spacer size={8} />
					<p>{t('tourAdmin.enterFFBandHarvester')}</p>
				</Box>
			),
			action: node => {
				node.focus()
			},
			style: stepStyle,
		},

		{
			selector: '#input-qualityDetails',
			content: () => (
				<Box>
					<H3 style={{ color: '#F6F6F6', fontSize: '22px' }}>
						{`${t('tourCommon.stepNo')} 3`}
					</H3>
					<Spacer size={8} />
					<p>{t('tourAdmin.addQualityDetails')}</p>
				</Box>
			),

			style: stepStyle,
		},
		{
			selector: '#update-work',
			content: () => (
				<Box>
					<H3 style={{ color: '#F6F6F6', fontSize: '22px' }}>
						{`${t('tourCommon.stepNo')} 4`}
					</H3>
					<Spacer size={8} />
					<p>{t('tourAdmin.createWork')}</p>
				</Box>
			),
			action: node => {
				node.focus()
			},
			style: stepStyle,
		},
	]
}
export const tdmCreateSteps = t => {
	return [
		{
			selector: '#input-createWork',
			content: () => (
				<Box>
					<H3 style={{ color: '#F6F6F6', fontSize: '22px' }}>
						{`${t('tourCommon.stepNo')} 1`}
					</H3>
					<Spacer size={8} />
					<p>{t('tourCommon.createDONote')}</p>
				</Box>
			),
			action: node => {
				node.focus()
			},
			style: stepStyle,
		},
	]
}
export const DOCreateSteps = t => {
	return [
		{
			selector: '#input-partner-picker',
			content: () => (
				<Box>
					<H3 style={{ color: '#F6F6F6', fontSize: '22px' }}>
						{`${t('tourCommon.stepNo')} 1`}
					</H3>
					<Spacer size={8} />
					<p>{t('tourAdmin.selectPartner')}</p>
				</Box>
			),

			style: stepStyle,
		},

		{
			selector: '#input-date-picker ',
			content: () => (
				<Box>
					<H3 style={{ color: '#F6F6F6', fontSize: '22px' }}>
						{`${t('tourCommon.stepNo')}  2`}
					</H3>
					<Spacer size={8} />
					<p>{t('tourAdmin.setDODate')}</p>
				</Box>
			),

			style: stepStyle,
		},

		{
			selector: '#updateDODetails',
			content: () => (
				<Box>
					<H3 style={{ color: '#F6F6F6', fontSize: '22px' }}>
						{`${t('tourCommon.stepNo')}  3`}
					</H3>
					<Spacer size={8} />
					<p>{t('tourAdmin.updateDO')}</p>
				</Box>
			),
			action: node => {
				node.focus()
			},
			style: stepStyle,
		},
	]
}
export const POCreateSteps = t => {
	return [
		{
			selector: '#input-partner-picker',
			content: () => (
				<Box>
					<H3 style={{ color: '#F6F6F6', fontSize: '22px' }}>
						{`${t('tourCommon.stepNo')} 1`}
					</H3>
					<Spacer size={8} />
					<p>{t('tourAdmin.selectPOPartner')}</p>
				</Box>
			),
			style: stepStyle,
		},

		{
			selector: '#input-date-picker ',
			content: () => (
				<Box>
					<H3 style={{ color: '#F6F6F6', fontSize: '22px' }}>
						{`${t('tourCommon.stepNo')}  2`}
					</H3>
					<Spacer size={8} />
					<p>{t('tourAdmin.setDODate')}</p>
				</Box>
			),
			action: node => {
				node.focus()
			},
			style: stepStyle,
		},

		{
			selector: '#updateDODetails',
			content: () => (
				<Box>
					<H3 style={{ color: '#F6F6F6', fontSize: '22px' }}>
						{`${t('tourCommon.stepNo')}  3`}
					</H3>
					<Spacer size={8} />
					<p>{t('tourAdmin.updatePO')}</p>
				</Box>
			),
			action: node => {
				node.focus()
			},
			style: stepStyle,
		},
	]
}
export const invoiceCreateSteps = t => {
	return [
		{
			selector: '#input-partner-picker',
			content: () => (
				<Box>
					<H3 style={{ color: '#F6F6F6', fontSize: '22px' }}>
						{`${t('tourCommon.stepNo')} 1`}
					</H3>
					<Spacer size={8} />
					<p>{t('tourAdmin.selectInvoicePartner')}</p>
				</Box>
			),
			action: node => {
				node.focus()
			},
			style: stepStyle,
		},

		{
			selector: '#input-date-picker ',
			content: () => (
				<Box>
					<H3 style={{ color: '#F6F6F6', fontSize: '22px' }}>
						{`${t('tourCommon.stepNo')}  2`}
					</H3>
					<Spacer size={8} />
					<p>{t('tourAdmin.setDODate')}</p>
				</Box>
			),
			action: node => {
				node.focus()
			},
			style: stepStyle,
		},

		{
			selector: '#updateDODetails',
			content: () => (
				<Box>
					<H3 style={{ color: '#F6F6F6', fontSize: '22px' }}>
						{`${t('tourCommon.stepNo')}  3`}
					</H3>
					<Spacer size={8} />
					<p>{t('tourAdmin.updateInvoice')}</p>
				</Box>
			),
			action: node => {
				node.focus()
			},
			style: stepStyle,
		},
	]
}

export const dealerScheduleCreateSteps = t => {
	return [
		{
			selector: '#createSchedule',
			content: () => (
				<Box margin="8px">{t('tourCommon.createSchedule')}</Box>
			),
			style: stepStyle,
			position: 'right',
		},
	]
}

export const scheduleCreateSteps = t => {
	return [
		{
			selector: '#input-schedulerSM',
			content: () => (
				<Box>
					<H3 style={{ color: '#F6F6F6', fontSize: '22px' }}>
						{`${t('tourCommon.stepNo')} 1`}
					</H3>
					<Spacer size={8} />
					<p>{t('tourDealer.chooseSmalholder')}</p>
				</Box>
			),
			action: node => {
				node.focus()
			},
			style: stepStyle,
		},

		{
			selector: '#input-schedulerType ',
			content: () => (
				<Box>
					<H3 style={{ color: '#F6F6F6', fontSize: '22px' }}>
						{`${t('tourCommon.stepNo')} 2`}
					</H3>
					<Spacer size={8} />
					<p>{t('tourDealer.chooseType')}</p>
				</Box>
			),
			action: node => {
				node.focus()
			},
			style: stepStyle,
		},

		{
			selector: '#input-schedulerDate',
			content: () => (
				<Box>
					<H3 style={{ color: '#F6F6F6', fontSize: '22px' }}>
						{`${t('tourCommon.stepNo')}  3`}
					</H3>
					<Spacer size={8} />
					<p>{t('tourDealer.chooseDate')}</p>
				</Box>
			),

			style: stepStyle,
		},
		{
			selector: '#createScheduleSubmit',
			content: () => (
				<Box>
					<H3 style={{ color: '#F6F6F6', fontSize: '22px' }}>
						{`${t('tourCommon.stepNo')}  4`}
					</H3>
					<Spacer size={8} />
					<p>{t('tourDealer.createSchedule')}</p>
				</Box>
			),
			action: node => {
				node.focus()
			},

			style: stepStyle,
		},
	]
}
export const createIpBatchSteps2 = t => {
	return [
		{
			selector: '#select-drop-productID-0__input',
			content: () => (
				<Box>
					<H3 style={{ color: '#F6F6F6', fontSize: '22px' }}>
						{`${t('tourCommon.stepNo')} 1`}
					</H3>
					<Spacer size={8} />
					<p>{t('tourRefinery.selectConsgts')}</p>
				</Box>
			),
			style: stepStyle,
		},

		{
			selector: '#input-quantity-0',
			content: () => (
				<Box>
					<H3 style={{ color: '#F6F6F6', fontSize: '22px' }}>
						{`${t('tourCommon.stepNo')}  2`}
					</H3>
					<Spacer size={8} />
					<p>{t('tourRefinery.enterData')}</p>
				</Box>
			),
			style: stepStyle,
		},

		{
			selector: '#createIpSubmit',
			content: () => (
				<Box>
					<H3 style={{ color: '#F6F6F6', fontSize: '22px' }}>
						{`${t('tourCommon.stepNo')}  3`}
					</H3>
					<Spacer size={8} />
					<p>{t('tourRefinery.createIpBatch')}</p>
				</Box>
			),
			style: stepStyle,
		},
	]
}

export const ipBatchCreateSteps = t => {
	return [
		{
			selector: '#plotInputMillCreate',
			content: () => (
				<Box margin="8px">{t('tourCommon.createIpBatchNote')}</Box>
			),
			style: stepStyle,
			position: 'right',
		},
	]
}

export const ipBatchCreateRefinerySteps = t => {
	return [
		{
			selector: '#plotInputRefineryCreate',
			content: () => (
				<Box margin="8px">{t('tourCommon.createIpBatchNote')}</Box>
			),
			style: stepStyle,
			position: 'right',
		},
	]
}

export const createIpBatchSteps = t => {
	return [
		{
			selector: '#input-enterDataProdn',
			content: () => (
				<Box>
					<H3 style={{ color: '#F6F6F6', fontSize: '22px' }}>
						{`${t('tourCommon.stepNo')} 1`}
					</H3>
					<Spacer size={8} />
					<p>{t('tourRefinery.enterData')}</p>
				</Box>
			),
			style: stepStyle,
		},

		{
			selector: '#createIpSubmit',
			content: () => (
				<Box>
					<H3 style={{ color: '#F6F6F6', fontSize: '22px' }}>
						{`${t('tourCommon.stepNo')}  2`}
					</H3>
					<Spacer size={8} />
					<p>{t('tourRefinery.createIpBatch')}</p>
				</Box>
			),
			style: stepStyle,
		},
	]
}

export const createOpBatchSteps = t => {
	return [
		{
			selector: '#select-drop-productID-0__input',
			content: () => (
				<Box>
					<H3 style={{ color: '#F6F6F6', fontSize: '22px' }}>
						{`${t('tourCommon.stepNo')} 1`}
					</H3>
					<Spacer size={8} />
					<p>{t('tourRefinery.selectProdts')}</p>
				</Box>
			),
			style: stepStyle,
		},

		{
			selector: '#input-quantity-0 ',
			content: () => (
				<Box>
					<H3 style={{ color: '#F6F6F6', fontSize: '22px' }}>
						{`${t('tourCommon.stepNo')}  2`}
					</H3>
					<Spacer size={8} />
					<p>{t('tourRefinery.assignStorage')}</p>
				</Box>
			),
			style: stepStyle,
		},

		{
			selector: '#input-saveTkt',
			content: () => (
				<Box>
					<H3 style={{ color: '#F6F6F6', fontSize: '22px' }}>
						{`${t('tourCommon.stepNo')}  3`}
					</H3>
					<Spacer size={8} />
					<p>{t('tourRefinery.createOpBatch')}</p>
				</Box>
			),
			style: stepStyle,
		},
	]
}
export const partnerCreateSteps = t => {
	return [
		{
			selector: '#addPartner',
			content: () => (
				<Box margin="8px">{t('tourCommon.addPartnerNote')}</Box>
			),
			style: stepStyle,
			position: 'right',
		},
	]
}

export const createPartnerSteps = t => {
	return [
		{
			selector: '#input-addPartner',
			content: () => (
				<Box>
					<H3 style={{ color: '#F6F6F6', fontSize: '22px' }}>
						{`${t('tourCommon.stepNo')} 1`}
					</H3>
					<Spacer size={8} />
					<p>{t('tourCommon.addPartnerSearch')}</p>
				</Box>
			),
			style: stepStyle,
		},
		{
			selector: '#input-addPartnerNotFound',
			content: () => (
				<Box>
					<H3 style={{ color: '#F6F6F6', fontSize: '22px' }}>
						{`${t('tourCommon.stepNo')}  2`}
					</H3>
					<Spacer size={8} />
					<p>{t('tourCommon.addPartnerNotFound')}</p>
				</Box>
			),
			style: stepStyle,
		},
	]
}
export const tdmDOCreateSteps = t => {
	return [
		{
			selector: '#tdmCreate',
			content: () => (
				<Box margin="8px">{t('tourCommon.createTradeDoc')}</Box>
			),
			style: stepStyle,
			position: 'right',
		},
	]
}
export const assetAdminCreateSteps = t => {
	return [
		{
			selector: '#assetMgmt',
			content: () => <Box margin="8px">{t('tourCommon.assetMgmt')}</Box>,
			style: stepStyle,
			position: 'right',
		},
	]
}
export const assetCreateSteps = t => {
	return [
		{
			selector: '#input-assetMgmt',
			content: () => (
				<Box margin="8px">{t('tourCommon.assetMgmtNote')}</Box>
			),
			style: stepStyle,
			position: 'right',
		},
	]
}

export const companyAdminCreateSteps = t => {
	return [
		{
			selector: '#companyInfo',
			content: () => (
				<Box margin="8px">{t('tourCommon.companyInfo')}</Box>
			),
			style: stepStyle,
			position: 'right',
		},
	]
}
export const companyCreateSteps = t => {
	return [
		{
			selector: '#input-companyInfo',
			content: () => (
				<Box margin="8px">{t('tourCommon.companyInfoNote')}</Box>
			),
			style: stepStyle,
			position: 'right',
		},
	]
}
export const employeeAdminCreateSteps = t => {
	return [
		{
			selector: '#employeeMgmt',
			content: () => (
				<Box margin="8px">{t('tourCommon.employeeMgmt')}</Box>
			),
			style: stepStyle,
			position: 'right',
		},
	]
}
export const employeeCreateSteps = t => {
	return [
		{
			selector: '#input-employeeMgmt',
			content: () => (
				<Box margin="8px">{t('tourCommon.employeeMgmtNote')}</Box>
			),
			style: stepStyle,
			position: 'right',
		},
	]
}
export const plantationTicketCreateSteps = t => {
	return [
		{
			selector: '#input-createTicket',
			content: () => (
				<Box margin="8px">{t('tourPlantation.createTicket')}</Box>
			),
			style: stepStyle,
			position: 'right',
		},
	]
}

export const ticketCreateSteps = t => {
	return [
		{
			selector: '#input-chooseTicket',
			content: () => (
				<Box margin="8px">{t('tourPlantation.chooseHarverster')}</Box>
			),
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#input-enterTktDetails',
			content: () => (
				<Box margin="8px">
					{t('tourPlantation.fillHarvesterDetails')}
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#input-qualityParams',
			content: () => (
				<Box margin="8px">
					{t('tourPlantation.updateQualityDetails')}
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#input-createTicket',
			content: () => (
				<Box margin="8px">{t('tourPlantation.createTicket')}</Box>
			),
			style: stepStyle,
			position: 'right',
		},
	]
}
export const plantationBinCreateSteps = t => {
	return [
		{
			selector: '#input-createBin',
			content: () => (
				<Box margin="8px">{t('tourPlantation.createBin')}</Box>
			),
			style: stepStyle,
			position: 'right',
		},
	]
}

export const binCreateSteps = t => {
	return [
		{
			selector: '#input-chooseVehicle',
			content: () => (
				<Box margin="8px">{t('tourPlantation.chooseVehicle')}</Box>
			),
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#input-enterBinDetails',
			content: () => (
				<Box margin="8px">{t('tourPlantation.fillVehicleDetails')}</Box>
			),
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#input-selectTickets',
			content: () => (
				<Box margin="8px">{t('tourPlantation.selectTickets')}</Box>
			),
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#input-updateCreateBin',
			content: () => (
				<Box margin="8px">{t('tourPlantation.updateCreateBin')}</Box>
			),
			style: stepStyle,
			position: 'right',
		},
	]
}
