import React from 'react'
import { useTranslation } from 'react-i18next'
import { Close } from 'grommet-icons'
import { isEmptyObject } from 'core-app/utils/helpers'
import { Box } from 'ui-lib/utils/Box'
import { DatePicker } from 'ui-lib/components/Datepicker'
import { RadioInputGroup } from 'ui-lib/components/Radio'
import { H2, Text } from 'ui-lib/components/Typography'
import { Button } from 'ui-lib/components/Button'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { Spacer } from 'ui-lib/utils/Spacer'
import { useFormik } from 'formik'
import * as yup from 'yup'
import theme from 'ui-lib/utils/base-theme'
import RescheduleIcon from 'ui-lib/icons/reschedule.svg'

const Reschedule = ({ onConfirm, onClose }) => {
	const { t } = useTranslation()

	const validationSchema = yup.object().shape({
		startAt: yup.date().required(),
	})

	const { handleSubmit, values, setFieldValue, errors } = useFormik({
		initialValues: {
			startAt: '',
			scheduledPeriod: '',
		},
		enableReinitialize: true,
		validationSchema,
		onSubmit: _values => {
			onConfirm(_values)
		},
	})

	const disableCTA = !isEmptyObject(errors) || !values.startAt

	return (
		<>
			<Box row justifyContent="flex-end">
				<Button plain customIcon={<Close />} onClick={onClose} />
			</Box>
			<Box row alignItems="center">
				<IconWrapper size={40}>
					<Icon glyph={RescheduleIcon} />
				</IconWrapper>
				<Spacer size={24} horizontal />

				<H2>{t('schedulerListing.reschedule')}</H2>
			</Box>
			<Text
				style={{
					color: theme.color.grey6,
					margin: '16px 0',
				}}
			>
				{t('schedulerListing.changeAndUpdateTheScheduleToAnotherDate')}
			</Text>
			<form onSubmit={handleSubmit}>
				<DatePicker
					value={values.startAt}
					minDate={new Date().setHours(0, 0, 0, 0)}
					onChange={value => {
						setFieldValue('startAt', new Date(value))
					}}
					placeholder={t('createSchedule.chooseDate')}
				/>
				<RadioInputGroup
					direction="row"
					options={[
						{
							label: `${t('schedulerListing.morning')}`,
							name: 'morning',
						},
						{
							label: `${t('schedulerListing.afternoon')}`,
							name: 'afternoon',
						},
						{
							label: `${t('schedulerListing.evening')}`,
							name: 'evening',
						},
					]}
					onChange={e => {
						setFieldValue('scheduledPeriod', e.target.value)
					}}
				/>

				<Box width={330}>
					<Button
						type="submit"
						disabled={disableCTA}
						label={t('schedulerListing.reschedule')}
						primary
						rounded
					/>
				</Box>
			</form>
		</>
	)
}

export { Reschedule }
