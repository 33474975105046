import { getIn } from 'timm'
import { isMarketplaceHost } from 'ui-lib/utils/helpers'

const lookUpConfig = key =>
	getIn(window._env_, [key]) || getIn(process.env, [key]) || ''

// All the config env variables should starts with REACT_APP_
export const getCurrentProject = () => lookUpConfig('REACT_APP_PROJECT') || ''
export const getIAMEndPoint = () =>
	`${lookUpConfig(
		isMarketplaceHost()
			? 'REACT_APP_MARKETPLACE_IAM_URL'
			: 'REACT_APP_IAM_URL'
	) || ''}/`
export const getCoreEndPoint = () =>
	`${lookUpConfig(
		isMarketplaceHost()
			? 'REACT_APP_MARKETPLACE_CORE_URL'
			: 'REACT_APP_CORE_URL'
	) || ''}/`
export const getPlantationEndPoint = () =>
	`${lookUpConfig(
		isMarketplaceHost()
			? 'REACT_APP_MARKETPLACE_PLANTATION_URL'
			: 'REACT_APP_PLANTATION_URL'
	) || ''}/`
export const getUtilitiesEndPoint = () =>
	`${lookUpConfig(
		isMarketplaceHost()
			? 'REACT_APP_MARKETPLACE_UTILITIES_URL'
			: 'REACT_APP_UTILITIES_URL'
	) || ''}/`
export const getMirrorNodeBaseUrl = () =>
	`${lookUpConfig('REACT_APP_MIRROR_NODE_BASE_URL') || ''}/`
export const getExplorerBaseurl = () =>
	`${lookUpConfig('REACT_APP_EXPLORER_BASE_URL') || ''}/`
export const getGMAPAddressFetchUrl = pincode =>
	`https://maps.googleapis.com/maps/api/geocode/json?address=${pincode}&key=${lookUpConfig(
		'REACT_APP_GMAP_AUTH_TOKEN'
	) || '---'}`
export const getNotifierEndPoint = () =>
	`${lookUpConfig(
		isMarketplaceHost()
			? 'REACT_APP_MARKETPLACE_NOTIFIER_URL'
			: 'REACT_APP_NOTIFIER_URL'
	) || ''}/`

export const getInfoAddressLink = () =>
	lookUpConfig('REACT_APP_INFO_ADDRESS_LINK') || ''
export const getDibizAdminMail = () =>
	lookUpConfig('REACT_APP_DIBIZ_ADMIN_MAIL') || ''

export const featureFlags = {
	enableLoginCaptcha:
		lookUpConfig('REACT_APP_FTRS_LOGIN_CAPTCHA') !== 'false',
	enableSSO: lookUpConfig('REACT_APP_SSO') === 'true',
}

export const getGoogleAppId = () => lookUpConfig('REACT_APP_GG_APP_ID') || ''
export const getMicrosoftAppId = () =>
	lookUpConfig('REACT_APP_MICROSOFT_APP_ID') || ''
export const getMicrosoftRedirectURI = () =>
	lookUpConfig('REACT_APP_MICROSOFT_REDIRECT_URI') || ''
export const getLoginURL = () => lookUpConfig('REACT_APP_LOGIN_URL') || ''
export const getSignupDetailURL = () =>
	lookUpConfig('REACT_APP_SIGNUP_DETAIL_URL') || ''

export const getRecaptchaKey = () =>
	lookUpConfig('REACT_APP_RECAPTCHA_KEY') || ''

export const getAppVersion = () => lookUpConfig('REACT_APP_VERSION') || ''
export const getMapBoxAccessToken = () =>
	lookUpConfig('REACT_APP_MAPBOX_ACCESS_TOKEN') || ''

export const getMarketplaceEndPoint = () =>
	`${lookUpConfig(
		isMarketplaceHost()
			? 'REACT_APP_MARKETPLACE_MARKETPLACE_URL'
			: 'REACT_APP_MARKETPLACE_URL'
	) || ''}/`

export const getInsightsEndPoint = () =>
	`${lookUpConfig(
		isMarketplaceHost()
			? 'REACT_APP_MARKETPLACE_INSIGHTS_URL'
			: 'REACT_APP_INSIGHTS_URL'
	) || ''}/`

export const getseparatedTDMHost = () =>
	`${lookUpConfig(
		isMarketplaceHost()
			? 'REACT_APP_MARKETPLACE_TDM_HOST'
			: 'REACT_APP_TDM_HOST'
	) || ''}`
export const getChatEndPoint = () =>
	`${lookUpConfig('REACT_APP_CHAT_URL') || ''}/`
export const getEmployeeUploadSampleFile = () =>
	`${lookUpConfig(
		isMarketplaceHost()
			? 'REACT_APP_MARKETPLACE_EMPLOYEE_UPLOAD_SAMPLE_FILE'
			: 'REACT_APP_EMPLOYEE_UPLOAD_SAMPLE_FILE'
	) || ''}`
export const getProductLCAUploadSampleFile = () =>
	`${lookUpConfig(
		isMarketplaceHost()
			? 'REACT_APP_MARKETPLACE_PRODUCT_LCA_UPLOAD_SAMPLE_FILE'
			: 'REACT_APP_PRODUCT_LCA_UPLOAD_SAMPLE_FILE'
	) || ''}`
export const getUploadAssetsSampleFile = () =>
	`${lookUpConfig(
		isMarketplaceHost()
			? 'REACT_APP_MARKETPLACE_UPLOAD_SAMPLE_ASSETS_FILE'
			: 'REACT_APP_UPLOAD_SAMPLE_ASSETS_FILE'
	) || ''}`
export const getUploadPartnersSampleFile = () =>
	`${lookUpConfig(
		isMarketplaceHost()
			? 'REACT_APP_MARKETPLACE_UPLOAD_SAMPLE_PARTNERS_FILE'
			: 'REACT_APP_UPLOAD_SAMPLE_PARTNERS_FILE'
	) || ''}`
export const getChatAccessKey = () =>
	`${lookUpConfig('REACT_APP_CHAT_ACCESS_KEY') || ''}`

export const getTraceabilityEndPoint = () =>
	`${lookUpConfig(
		isMarketplaceHost()
			? 'REACT_APP_MARKETPLACE_TRACEABILITY_URL'
			: 'REACT_APP_TRACEABILITY_URL'
	) || ''}/`

export const getSwitchProfileOrgsIds = () =>
	lookUpConfig('REACT_APP_SWITCH_PROFILE_ORGS')
		? JSON.parse(lookUpConfig('REACT_APP_SWITCH_PROFILE_ORGS'))
		: {}

export const getSwitchProfileCreds = () =>
	lookUpConfig('REACT_APP_SWITCH_PROFILE_CREDS')
		? JSON.parse(lookUpConfig('REACT_APP_SWITCH_PROFILE_CREDS'))
		: {}

export const getGuidedTourStatus = () => lookUpConfig('REACT_APP_GUIDED_TOUR')

export const apiConfigAvailable =
	getIAMEndPoint().length > 1 &&
	getCoreEndPoint().length > 1 &&
	getPlantationEndPoint().length > 1 &&
	getNotifierEndPoint().length > 1 &&
	getChatEndPoint().length > 1 &&
	getChatAccessKey().length > 1 &&
	getTraceabilityEndPoint().length > 1 &&
	getInsightsEndPoint().length > 1
