import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getIn } from 'timm'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import {
	FormHeader,
	FormBody,
	FormFooter,
} from 'core-app/shared/components/Fragments'
import { supplyChainModels } from 'core-app/modules/Admin/components/StorageDetails'
import request from 'core-app/utils/request'
import { getCoreEndPoint } from 'core-app/config'
import { Table } from 'ui-lib/components/Table'
import { Box } from 'ui-lib/utils/Box'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { TransparentBgSpinner } from 'ui-lib/components/Spinner/TransparentBgSpinner'
import { Select } from 'ui-lib/components/Select'
import { Card } from 'ui-lib/components/Card'
import { Input } from 'ui-lib/components/Input'
import { H2, P, SmallText, Text, Label } from 'ui-lib/components/Typography'
import { Button, ButtonWithNoBorder } from 'ui-lib/components/Button'
import { Spacer } from 'ui-lib/utils/Spacer'
import theme from 'ui-lib/utils/base-theme'
import DeleteIcon from 'ui-lib/icons/deleteTrash.svg'
import ReportProblemIcon from 'ui-lib/icons/report_problem.svg'
import { getSupplyChainModelsWithId } from 'core-app/modules/Admin/config'

const columnConfig = ({ t, onDelete }) => {
	return [
		{
			property: 'tank',
			header: (
				<Box style={{ cursor: 'pointer' }}>
					<Label
						bold
						style={{
							color: '#4B4552',
							fontSize: 16,
							textAlign: 'center',
						}}
					>
						Tank
					</Label>
				</Box>
			),
			render: datum => {
				const tank = getIn(datum, ['name'])

				return (
					<Label
						as="span"
						style={{
							fontSize: 16,
							textAlign: 'center',
						}}
					>
						{tank}
					</Label>
				)
			},
			size: '25%',
		},
		{
			property: 'quantity',
			header: (
				<Box style={{ cursor: 'pointer' }}>
					<Label
						bold
						style={{
							color: '#4B4552',
							fontSize: 16,
							textAlign: 'center',
						}}
					>
						Quantity
					</Label>
				</Box>
			),
			render: datum => {
				const quantity = getIn(datum, ['quantity'])
					.toString()
					.match(/^-?\d+(?:\.\d{0,3})?/)[0]

				return (
					<Label
						as="span"
						style={{
							fontSize: 16,
							textAlign: 'center',
						}}
					>
						{quantity}
					</Label>
				)
			},
			size: '25%',
		},
		{
			property: 'scModel',
			header: (
				<Box style={{ cursor: 'pointer' }}>
					<Label
						bold
						style={{
							color: '#4B4552',
							fontSize: 16,
							textAlign: 'center',
						}}
					>
						Supply Chain Model
					</Label>
				</Box>
			),
			render: datum => {
				const supplyChainModel = getIn(datum, ['supplyChainModel'])

				return (
					<Label
						as="span"
						style={{
							fontSize: 16,
							textAlign: 'center',
						}}
					>
						{t(supplyChainModels[supplyChainModel])}
					</Label>
				)
			},
			size: '25%',
		},
		{
			property: 'delete',
			render: datum => {
				const tank = getIn(datum, ['storageUnitID'])

				return (
					<Box row justifyContent="center">
						<IconWrapper
							hoverEffect
							width={25}
							height={25}
							onClick={() => {
								onDelete(tank)
							}}
						>
							<Icon glyph={DeleteIcon} center />
						</IconWrapper>
					</Box>
				)
			},
			size: '25%',
		},
	]
}

const InventoryHandler = ({
	onClose,
	productDetails,
	storageList,
	activeTanks,
	onChange,
}) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const allProducts = useSelector(AuthDuc.selectors.getProducts)
	const certificationListing = useSelector(AuthDuc.selectors.getCertificates)
	const certificationTypes = certificationListing.map(certs => {
		return {
			label: certs,
			id: certs,
		}
	})
	const uncertifiedType = {
		label: t('common.uncertified'),
		id: 'uncertified',
	}
	const checkIfCertificatesExist = certificationListing.length !== 0
	const productName = allProducts.filter(
		product => product.id === productDetails.productID
	)
	const curentProductName = getIn(productName, [0, 'name']) || ''

	// Filter values
	const [supplyChainModel, setSupplyChainModel] = useState('')
	const [_certification, setCertification] = useState('')

	// list from API
	const [storageTanks, setStorageTanks] = useState([])
	const [isLoading, setisLoading] = useState(false)

	// set quantity, storageUnit & storageQuantityEntry based on user input
	const [quantity, setQuantity] = useState(0)
	const [storageUnit, setStorageUnit] = useState({})
	const [storageQuantityEntry, setStorageQuantityEntry] = useState(
		activeTanks || []
	)

	const [warning, showWarning] = useState(false)
	const [tankSelection, setTankSelection] = useState(false)

	const getStorageQuantity = storageUnitID => {
		const currentTank = storageTanks.find(
			item => item.storageUnitID === storageUnitID
		)

		return storageUnitID && currentTank ? currentTank.availableQty : 0
	}

	const disableInventoryAssignment =
		storageUnit === {} ||
		!quantity ||
		quantity > getStorageQuantity(storageUnit.storageUnitID)

	useEffect(() => {
		if (supplyChainModel !== '' && _certification !== '') {
			// eslint-disable-next-line no-inner-declarations
			async function fetchProduct() {
				const requestUrl = `${getCoreEndPoint()}clients/organizations/-/products/${
					productDetails.productID
				}/
				stats?supplyChainModel=${supplyChainModel}&inventoryType=incoming,outgoing&certification=${_certification}`
				setisLoading(true)
				const { data = {} } = await request(requestUrl)
				const list = getIn(data, ['list']) || []
				setStorageTanks(list)
				setisLoading(false)
				setTankSelection(list.length > 0)
			}

			fetchProduct()
		}
	}, [dispatch, _certification, supplyChainModel, productDetails.productID])

	const storageUnitIDs = storageTanks.map(pr => pr.storageUnitID)
	const storageQuantityEntryStorageUnitIDs = storageQuantityEntry.map(
		pr => pr.storageUnitID
	)

	const filteredStorageList = storageList
		.filter(
			alt =>
				storageUnitIDs.includes(alt.id) &&
				!storageQuantityEntryStorageUnitIDs.includes(alt.id)
		)
		.map(({ name, id, meta }) => {
			return {
				storageUnitID: id,
				name: `${name} (${
					getStorageQuantity(id)
						.toString()
						.match(/^-?\d+(?:\.\d{0,3})?/)[0]
				} MT)`,
				supplyChainModel: getIn(meta, ['supplyChainModel']),
				inventoryType: getIn(meta, ['inventoryType']),
				certification: getIn(meta, ['certificationType']),
			}
		})

	const handleDelete = useCallback(
		tank => {
			const newStorageList = storageQuantityEntry.filter(
				tk => tk.storageUnitID !== tank
			)
			setStorageQuantityEntry(newStorageList)
		},
		[storageQuantityEntry]
	)

	return (
		<Box>
			{isLoading && <TransparentBgSpinner />}
			<FormHeader
				row
				justifyContent="space-between"
				alignItems="flex-end"
			>
				<H2 primary>{t('common.selectStorageAndQuantity')}</H2>
				<Box>
					{productDetails.productID && (
						<Box row>
							<Text
								style={{
									lineHeight: '1.5',
									fontSize: theme.fontSize.m,
								}}
							>
								Source Product
							</Text>
							<P>{` : ${curentProductName}`}</P>
						</Box>
					)}
				</Box>
			</FormHeader>
			<FormBody padding="20px 0px">
				<Box row overflow width="inherit" padding="0 20px">
					<Select
						required
						label="Certification"
						value={_certification}
						name="certificationType"
						options={
							checkIfCertificatesExist
								? [...certificationTypes, uncertifiedType]
								: [uncertifiedType]
						}
						onChange={value => {
							setCertification(value.id)
							if (value.id !== 'uncertified') {
								setSupplyChainModel('mass-balance')
							} else {
								setSupplyChainModel('none')
							}
						}}
						returnOnlyValue
						labelKey="label"
						valueKey="id"
					/>
					<Spacer size={100} horizontal />
					<Select
						required
						name="supplyChainModel"
						label={t('tdmDetailsEntry.supplyChainModel')}
						key={supplyChainModel || ''}
						options={
							checkIfCertificatesExist &&
							_certification !== 'uncertified'
								? getSupplyChainModelsWithId(t)
								: [
										{
											name: t('createAsset.none'),
											id: 'none',
										},
								  ]
						}
						labelKey="name"
						valueKey="id"
						value={supplyChainModel}
						onChange={value => {
							setSupplyChainModel(value.id)
						}}
						returnOnlyValue
					/>
				</Box>
				{tankSelection && (
					<Card
						style={{
							padding: '0px 20px',
							position: 'relative',
							overflow: 'visible',
							background: '#FAFBFF',
							border: '1px solid #D9DEE5',
							borderRadius: '6px',
							margin: '20px 0',
						}}
						textAlign="left"
					>
						<SmallText
							style={{
								position: 'absolute',
								fontSize: theme.fontSize.m,
								color: theme.color.accent2,
								top: '-10px',
								left: '20px',
								display: 'inline-block',
								backgroundColor: '#FAFBFF',
								padding: '0 8px',
							}}
						>
							{t('common.selectStorageAndQuantity')}
						</SmallText>
						<Box row style={{ width: '100%', marginTop: '20px' }}>
							<Select
								required
								name="storage"
								label={t('production.storage')}
								options={filteredStorageList}
								labelKey="name"
								valueKey="storageUnitID"
								key={storageUnit.storageUnitID}
								value={storageUnit.storageUnitID}
								onChange={value => {
									setStorageUnit(value)
								}}
								returnOnlyValue
							/>
							<Spacer size={40} horizontal />
							<Box style={{ width: '200px' }}>
								<Input
									label={t('tdmDetailsEntry.quantity')}
									name="quantity"
									type="number"
									required
									value={quantity}
									onChange={e =>
										setQuantity(e.target.valueAsNumber)
									}
									placeholder="0"
								/>
							</Box>
							<Spacer size={40} horizontal />
							<Box
								justifyContent="center"
								width={156}
								padding={1}
								height="inherit"
								overflow
							>
								<Button
									label={
										<P color="white">
											<strong>Assign Inventory</strong>
										</P>
									}
									onClick={() => {
										setStorageQuantityEntry([
											...storageQuantityEntry,
											{
												...storageUnit,
												quantity,
											},
										])
										setStorageUnit({})
										setQuantity(0)
									}}
									primary
									disabled={disableInventoryAssignment}
								/>
							</Box>
						</Box>
					</Card>
				)}
				{warning && (
					<>
						<Box row padding={8} alignItems="center">
							<IconWrapper
								size={30}
								style={{
									padding: 4,
									display: 'flex',
									color: theme.color.error,
									background: '#fff',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<Icon glyph={ReportProblemIcon} />
							</IconWrapper>
							<SmallText
								style={{
									marginLeft: 12,
									fontWeight: 'bold',
									color: theme.color.error,
									fontSize: 16,
								}}
							>
								Warning!
							</SmallText>
						</Box>
						<P
							style={{
								marginTop: 4,
								padding: '0 12px',
							}}
						>
							You are mixing inventory of different Supply Chain
							Model. This would change the Supply Chain of the
							Produced Inventory.
						</P>

						<Box
							row
							justifyContent="center"
							alignItems="baseline"
							style={{
								background: '#fff',
								fontSize: 16,
								margin: '8px 0',
							}}
						>
							Are you sure you want to proceed?
							<Button
								extendStyles={{ margin: '0px 28px 0px 50px' }}
								primary
								label="No"
								onClick={() => {
									showWarning(false)
									setTankSelection(true)
								}}
							/>
							<Button
								outline
								label="Yes"
								onClick={() => {
									setStorageQuantityEntry([
										...storageQuantityEntry,
										{
											...storageUnit,
											quantity,
										},
									])
									showWarning(false)
									setTankSelection(true)
								}}
							/>
						</Box>
					</>
				)}
				{storageQuantityEntry.length > 0 && (
					<Table
						columnConfig={columnConfig({
							t,
							onDelete: handleDelete,
						})}
						rowData={storageQuantityEntry}
						size="small"
						noRoundedBorder
					/>
				)}
				<Spacer size={64} />
			</FormBody>
			<FormFooter row justifyContent="flex-end" style={{ width: '100%' }}>
				<Box center row style={{ maxWidth: 375 }}>
					<ButtonWithNoBorder
						label={t('common.cancel')}
						tabIndex="-1"
						onClick={() => {
							onClose()
						}}
					/>
					<Spacer size={10} horizontal />
					<Box
						justifyContent="center"
						width={150}
						padding={1}
						height="inherit"
						overflow
					>
						<Button
							label={
								<P color="white">
									<strong>{t('common.submit')}</strong>
								</P>
							}
							onClick={() => {
								onChange(storageQuantityEntry)
							}}
							primary
							disabled={!storageQuantityEntry.length}
						/>
					</Box>
				</Box>
			</FormFooter>
		</Box>
	)
}

export { InventoryHandler }
