import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn, omit, setIn, merge } from 'timm'
import { MainRouteDuc } from 'core-app/routes/duc'
import { TripsDuc } from 'core-app/modules/Trips/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { initiateSort, getTargetFilterQueries } from 'core-app/shared/helpers'
import { Title } from 'core-app/shared/components/Title'
import { getDocumentColumnConfig } from 'core-app/modules/Trips/components/Columns'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Tabs, DefaultTabIndicator } from 'ui-lib/components/Tabs'
import { TransparentBgSpinner } from 'ui-lib/components/Spinner/TransparentBgSpinner'
import { Table } from 'ui-lib/components/Table'
import { ErrorBlock } from 'ui-lib/components/ErrorBlocks'
import { ButtonIconWrapper, Button } from 'ui-lib/components/Button'
import { Box } from 'ui-lib/utils/Box'
import { Icon } from 'ui-lib/icons/components/Icon'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import Truck from 'ui-lib/icons/delivery-truck.svg'

const getTabComponents = ({
	type,
	sortsMap,
	rows,
	onChange,
	orgDetails,
	loadingStatus,
	userList,
	t,
}) => ({ name }) => () => (
	<Box style={{ overflow: 'auto', position: 'relative' }}>
		{loadingStatus && <TransparentBgSpinner />}
		<Table
			noRoundedBorder
			columnConfig={getDocumentColumnConfig({
				type,
				docType: name,
				sortsMap: sortsMap[name] || {},
				onChange,
				orgDetails,
				userList,
				t,
			})}
			rowData={rows[name] || []}
		/>
		{!(rows[name] || []).length && (
			<Box style={{ minHeight: 300 }}>
				<ErrorBlock
					hideButton
					status="empty"
					message={t('common.errorBlockMessage')}
				/>
			</Box>
		)}
	</Box>
)

const MainDashboard = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const userList = useSelector(AuthDuc.selectors.getOrgUsers)
	const location = useSelector(TripsDuc.selectors.location)
	const isLoading = useSelector(TripsDuc.selectors.mainDashboardLoadingStatus)

	const activeSorts = useSelector(
		TripsDuc.selectors.getMainDashboardActiveSorts
	)
	const documents = useSelector(
		TripsDuc.selectors.fetchMainDashboardDocuments
	)

	const activeTypes = useSelector(TripsDuc.selectors.getDocumentActiveTabs)

	const { payload = {} } = location
	const { rootModule } = payload

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.trips'),
			name: 'trips',
			isActive: false,
		},
	]

	/** Handlers to react on actions */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
	}

	const getTabs = useCallback(() => {
		return activeTypes.map(({ code, title }) => ({
			name: code,
			title: t(title),
			IndicatorComponent:
				parseInt(code, 10) > 0 &&
				(({ isActive }) => (
					<DefaultTabIndicator active={isActive}>
						{parseInt(code, 10)}
					</DefaultTabIndicator>
				)),
		}))
	}, [activeTypes, t])

	const handleOnChange = useCallback(
		(actionType, meta = {}) => {
			switch (actionType) {
				case 'initiate_sort': {
					initiateSort(
						dispatch,
						meta,
						TripsDuc.creators.fetchDocuments,
						location,
						meta.docType
					)
					break
				}
				case 'apply_filter': {
					const {
						docType,
						type,
						filterType,
						filterSegment,
						filterValue,
					} = meta

					const currentQuery = getIn(location, ['query']) || {}
					const filterQueries = omit(currentQuery, ['sort', 'q']) // exclude sort and search convention

					let targetQueries = getIn(filterQueries, [filterType]) || []
					// if there are multiple segments being invoked as part of one filter, map them one to one via index
					if (
						Array.isArray(filterSegment) &&
						Array.isArray(filterValue)
					) {
						targetQueries = filterSegment.reduce(
							(agg, _filterSegment, _index) => {
								const _filterValue = filterValue[_index]

								if (!_filterValue) return agg

								return getTargetFilterQueries(
									agg,
									_filterSegment,
									_filterValue
								)
							},
							[]
						)
					} else {
						targetQueries = getTargetFilterQueries(
							targetQueries,
							filterSegment,
							filterValue
						)
					}

					const targetFilters = merge(
						currentQuery,
						merge(filterQueries, { [filterType]: targetQueries })
					)
					dispatch(
						TripsDuc.creators.fetchListingTickets(
							docType,
							type,
							setIn(location, ['query'], targetFilters),
							true
						)
					)

					break
				}
				case 'open_document': {
					if (meta.row) {
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.TRIPS$WDOCREFERENCE,
								{
									documentReference: meta.row.id,
								}
							)
						)
					}

					break
				}

				default:
					break
			}
		},
		[dispatch, location]
	)

	return (
		<Box padding={8} width="100%" height="100%">
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>

			<Box row style={{ padding: '0 5' }} justifyContent="space-between">
				<Title title={t('tripsListing.title')} icon={Truck} />
			</Box>

			<Tabs
				id="tripsListing"
				tabs={getTabs()}
				tabComponentsMap={getTabComponents({
					type: rootModule,
					sortsMap: activeSorts,
					rows: documents,
					onChange: handleOnChange,
					loadingStatus: isLoading,
					userList,
					t,
				})}
			/>

			<Box padding="25px 2px" style={{ maxWidth: 150 }}>
				<Button
					label={t('common.back')}
					rounded
					customIcon={
						<ButtonIconWrapper lightBG>
							<Icon glyph={LeftArrowIcon} />
						</ButtonIconWrapper>
					}
					onClick={() =>
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.DASHBOARD
							)
						)
					}
				/>
			</Box>
		</Box>
	)
}

export { MainDashboard }
