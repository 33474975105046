import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { addLast, removeAt, replaceAt } from 'timm'
import { Close } from 'grommet-icons'
import { GeneralSchedulerDuc } from 'core-app/modules/GeneralScheduler/duc'
import { isEmptyObject } from 'core-app/utils/helpers'
import { createScheduleColumnConfig } from 'core-app/modules/GeneralScheduler/components/Columns'
import { Box as GrommBox } from 'grommet'
import { Button } from 'ui-lib/components/Button'
import { Table } from 'ui-lib/components/Table'
import { Label } from 'ui-lib/components/Label'
import { H2 } from 'ui-lib/components/Typography'
import { CheckBoxGroup } from 'ui-lib/components/Checkbox'
import { Box } from 'ui-lib/utils/Box'
import { DatePicker } from 'ui-lib/components/Datepicker'
import { useFormik } from 'formik'
import * as yup from 'yup'

const NewSchedule = ({ onClose, onConfirm, documents }) => {
	const { t } = useTranslation()

	const initialValues = useSelector(
		GeneralSchedulerDuc.selectors.getNewScheduleInitialValues
	)

	const [activeDocuments, setActiveDocuments] = useState([])
	const [trigger, setTrigger] = useState(false)

	const validationSchema = yup.object().shape({
		startAt: yup.date().required(),
		type: yup.array().required(),
	})

	const { handleSubmit, values, setFieldValue, errors } = useFormik({
		initialValues,
		validationSchema,
		onSubmit: _values => {
			const { startAt, type } = _values
			const scheduleDetails = {
				startAt,
				type,
				participants: activeDocuments,
			}

			onConfirm(scheduleDetails)
		},
	})

	const handleOnChange = useCallback(
		(actionType, meta = {}) => {
			switch (actionType) {
				case 'add_Supplier': {
					if (meta.participantID) {
						const activeDoc = {
							participantType: 'palmoil_smallholder',
							participantID: meta.participantID,
							scheduledPeriod: '',
							clientType: 'org',
						}

						const newRow = addLast(activeDocuments, activeDoc)

						setActiveDocuments(newRow)
						setTrigger(true)
					}

					break
				}

				case 'remove_Supplier': {
					if (meta.participantID) {
						const rowIndex = activeDocuments.findIndex(
							i => i.participantID === meta.participantID
						)
						const removeRow = removeAt(activeDocuments, rowIndex)
						setActiveDocuments(removeRow)
					}

					// eslint-disable-next-line no-unused-expressions
					activeDocuments.length > 1
						? setTrigger(true)
						: setTrigger(false)

					break
				}

				case 'add_ScheduledPeriod': {
					if (meta.participantID) {
						const rowIndex = activeDocuments.findIndex(
							i => i.participantID === meta.participantID
						)
						const updatedDocs = replaceAt(
							activeDocuments,
							rowIndex,
							{
								participantType: 'palmoil_smallholder',
								participantID: meta.participantID,
								scheduledPeriod: meta.scheduledPeriod,
								clientType: 'org',
							}
						)

						setTrigger(true)
						setActiveDocuments(updatedDocs)
					}

					break
				}

				default:
					break
			}
		},
		[activeDocuments]
	)

	const disableCTA = !isEmptyObject(errors) || !values.startAt || !trigger

	return (
		<>
			<GrommBox width="880px">
				<Box row justifyContent="flex-end">
					<Button plain customIcon={<Close />} onClick={onClose} />
				</Box>
				<H2 color="#3F56C4" style={{ margin: '8px 0' }}>
					{`${t('common.create')} ${t(
						'schedulerListing.newSchedule'
					)}`}
				</H2>
				<Box margin="8px 0 32px">
					<form onSubmit={handleSubmit}>
						<Label required>{t('tourDealer.chooseDate')}</Label>
						<Box width={350} style={{ padding: '16px 0 ' }}>
							<DatePicker
								value={values.startAt}
								minDate={new Date().setHours(0, 0, 0, 0)}
								onChange={value => {
									setFieldValue('startAt', new Date(value))
								}}
								placeholder={t('createSchedule.chooseDate')}
							/>
						</Box>
						<Label required>{t('createSchedule.chooseST')}</Label>
						<Box width={350} padding="16px 0">
							<CheckBoxGroup
								selected={values.type}
								options={[
									{
										label: t('createSchedule.receiveGoods'),
										key: 'palmoil-service-recievegoods',
									},
									{
										label: t('createSchedule.transport'),
										key: 'palmoil-service-transport',
									},
									{
										label: t('createSchedule.harvest'),
										key: 'palmoil-service-harvest',
									},
									{
										label: t('createSchedule.fertilizer'),
										key: 'palmoil-service-fertilize',
									},
								]}
								onChange={value => {
									setFieldValue('type', value)
								}}
							/>
						</Box>
						<Box margin="16px 0">
							<Label required>
								{t('createSchedule.chooseSM')}
							</Label>
						</Box>
						<Table
							noRoundedBorder
							columnConfig={createScheduleColumnConfig({
								t,
								onChange: handleOnChange,
								activeDocuments,
							})}
							rowData={documents}
						/>
						<Box row justifyContent="flex-end" margin="16px 0">
							<Button
								label={t('common.cancel')}
								onClick={onClose}
								rounded
								extendStyles={{ width: 150, margin: '0 16px' }}
							/>
							<Button
								type="submit"
								disabled={disableCTA}
								label={t('common.submit')}
								primary
								rounded
								extendStyles={{ width: 150 }}
							/>
						</Box>
					</form>
				</Box>
			</GrommBox>
		</>
	)
}

export { NewSchedule }
