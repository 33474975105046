import Duck from 'extensible-duck'
import { setIn, getIn } from 'timm'
import { initialState } from './config'

export const GeneralSchedulerDuc = new Duck({
	namespace: 'general-scheduler',
	store: 'global',
	types: [
		'SET_ACTIVE_MODULE',
		'FETCH_SCHEDULE_LISTING',
		'SET_SCHEDULE_LISTING',
		'FETCH_SCHEDULE_DETAILS',
		'SET_SCHEDULE_DETAILS',
		'MULTIPLE_RESCHEDULES',
		'DELETE_SCHEDULES',
		'SET_RESCHEDULE_MODAL_STATUS',
		'SET_NEW_SCHEDULE_STATUS',
		'SCHEDULE_TRANSPORT',
		'EDIT_SCHEDULE',
		'SCHEDULE_STATUS_UPDATE',
		'CREATE_NEW_SCHEDULE',
		'ASSIGN_DRIVER',
		'UPDATE_DRIVER',
		'SET_INSIGHTS',
		'SAVE_PAGINATION_QUERIES',
		'FLUSH_MAIN_DASHBOARD',
		'FLUSH_NEW_SCHEDULE',
	],
	initialState,
	reducer: (state, action, duck) => {
		switch (action.type) {
			case duck.types.SET_ACTIVE_MODULE: {
				return setIn(state, ['activeModule'], action.module)
			}

			case duck.types.SET_SCHEDULE_LISTING: {
				const { schedules } = action

				return setIn(state, ['scheduleListing'], schedules)
			}

			case duck.types.SET_SCHEDULE_DETAILS: {
				return setIn(state, ['scheduleDetails'], action.details)
			}

			case duck.types.SET_RESCHEDULE_MODAL_STATUS: {
				return setIn(state, ['rescheduleModalStatus'], action.status)
			}

			case duck.types.SET_NEW_SCHEDULE_STATUS: {
				return setIn(state, ['newScheduleModalStatus'], action.status)
			}

			case duck.types.SET_INSIGHTS: {
				return setIn(state, ['insights'], action.details)
			}

			case duck.types.SAVE_PAGINATION_QUERIES: {
				const { paginate } = action

				return setIn(state, ['pagination'], paginate)
			}

			case duck.types.FLUSH_MAIN_DASHBOARD: {
				return setIn(
					state,
					['scheduleListing'],
					initialState.scheduleListing
				)
			}

			case duck.types.FLUSH_NEW_SCHEDULE: {
				return setIn(
					state,
					['newScheduleInitialValues'],
					initialState.newScheduleInitialValues
				)
			}

			default:
				return state
		}
	},
	selectors: {
		auth: state => state.auth,
		location: state => state.location,
		activeModule: state =>
			getIn(state, ['general-scheduler', 'activeModule']) || 'Error',
		getScheduleListing: state =>
			getIn(state, ['general-scheduler', 'scheduleListing']) || [],
		getScheduleDetails: state =>
			getIn(state, ['general-scheduler', 'scheduleDetails']) || {},
		getRescheduleModalStatus: state =>
			getIn(state, ['general-scheduler', 'rescheduleModalStatus']) ||
			false,
		getNewScheduleModalStatus: state =>
			getIn(state, ['general-scheduler', 'newScheduleModalStatus']) ||
			false,
		getInsights: state =>
			getIn(state, ['general-scheduler', 'insights']) || {},
		getPaginationQueries: state =>
			getIn(state, ['general-scheduler', 'pagination']) || {},
		getNewScheduleInitialValues: state =>
			getIn(state, ['general-scheduler', 'newScheduleInitialValues']) ||
			{},
	},
	creators: duck => ({
		setActiveModule: module => ({
			type: duck.types.SET_ACTIVE_MODULE,
			module,
		}),
		fetchScheduleListing: locationState => ({
			type: duck.types.FETCH_SCHEDULE_LISTING,
			locationState,
		}),
		setScheduleListing: schedules => ({
			type: duck.types.SET_SCHEDULE_LISTING,
			schedules,
		}),
		fetchScheduleDetails: scheduleID => ({
			type: duck.types.FETCH_SCHEDULE_DETAILS,
			scheduleID,
		}),
		setScheduleDetails: details => ({
			type: duck.types.SET_SCHEDULE_DETAILS,
			details,
		}),

		multipleReschedules: (participantDetails, values, successToast) => ({
			type: duck.types.MULTIPLE_RESCHEDULES,
			participantDetails,
			values,
			successToast,
		}),
		deleteSchedules: (scheduleIDs, successToast, redirect = false) => ({
			type: duck.types.DELETE_SCHEDULES,
			scheduleIDs,
			successToast,
			redirect,
		}),
		setRescheduleModalStatus: status => ({
			type: duck.types.SET_RESCHEDULE_MODAL_STATUS,
			status,
		}),
		setNewScheduleModalStatus: status => ({
			type: duck.types.SET_NEW_SCHEDULE_STATUS,
			status,
		}),
		scheduleTransport: scheduleDetails => ({
			type: duck.types.SCHEDULE_TRANSPORT,
			scheduleDetails,
		}),
		editSchedule: (details, scheduleID, toastMessage) => ({
			type: duck.types.EDIT_SCHEDULE,
			details,
			scheduleID,
			toastMessage,
		}),
		scheduleStatusUpdate: (value, id, toastMessage) => ({
			type: duck.types.SCHEDULE_STATUS_UPDATE,
			value,
			id,
			toastMessage,
		}),
		createNewSchedules: (details, toastMessage) => ({
			type: duck.types.CREATE_NEW_SCHEDULE,
			details,
			toastMessage,
		}),
		assignDriver: (details, toastMessage) => ({
			type: duck.types.ASSIGN_DRIVER,
			details,
			toastMessage,
		}),
		updateDriver: (details, toastMessage) => ({
			type: duck.types.UPDATE_DRIVER,
			details,
			toastMessage,
		}),
		setInsights: details => ({
			type: duck.types.SET_INSIGHTS,
			details,
		}),
		savePaginationQueries: paginate => ({
			type: duck.types.SAVE_PAGINATION_QUERIES,
			paginate,
		}),
		flushMainDashboard: () => ({
			type: duck.types.FLUSH_MAIN_DASHBOARD,
		}),
		flushNewSchedule: () => ({
			type: duck.types.FLUSH_NEW_SCHEDULE,
		}),
	}),
})
