import React, { useState, useRef } from 'react'
import ReactMapGL, {
	WebMercatorViewport,
	Marker,
	Popup,
	NavigationControl,
	// FullscreenControl,
	// ScaleControl,
	// GeolocateControl,
	// Source,
	// Layer,
} from 'react-map-gl'
import { currentPlatform } from '../../utils/config'
import { Box } from '../../utils/Box'
import { Label } from '../Typography'

import { getMapBoxAccessToken } from '../../config'

// import request from '../../utils/request'
// eslint-disable-next-line import/extensions
// import _data from './sample.json'
// eslint-disable-next-line import/extensions
// import _dataa from './sample2.json'
import './styles.css'

// const getInitials = (name, delimeter) => {
// 	if (name) {
// 		const arr = name.split(delimeter)

// 		switch (arr.length) {
// 			case 1:
// 				return `${arr[0].charAt(0).toUpperCase()}${arr[0]
// 					.charAt(1)
// 					.toUpperCase()} `
// 			default:
// 				return (
// 					arr[0].charAt(0).toUpperCase() +
// 					arr[arr.length - 1].charAt(0).toUpperCase()
// 				)
// 		}
// 	}

// 	return ''
// }

const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`

const pinStyle = {
	cursor: 'pointer',
	position: 'absolute',
	left: 0,
	top: 0,
	zIndex: -1,
}

const pinBorderColor = {
	'palmoil-mill': '#D38900',
	'palmoil-smallholder': '#748E37',
	'palmoil-plantation': '#ffffff',
	'palmoil-ffbDealer': '#ffffff',
	'kerneloil-mill': '#ffffff',
	'palmoil-kernelMill': '#ffffff',
	'palmoil-refinery': '#ffffff',
	'palmoil-oleochemicals': '#ffffff',
	'palmoil-storage': '#0A6A48',
	'palmoil-trader': '#3E92DF',
	'palmoil-endManufacturer': '#ffffff',
}

const pinBorderColorForRice = {
	'rice-farmer': '#748E37',
	'rice-mill': '#D38900',
	'rice-serviceprovider': '#ffffff',
	'rice-dealer': '#ffffff',
	'rice-packingcompany': '#0A6A48',
	'rice-packer': '#ffffff',
	'rice-trader': '#3E92DF',
	'rice-wholesaler': '#ffffff',
	'rice-importer': '#ffffff',
	'rice-endbuyer': '#ffffff',
}

const pinColor = {
	'palmoil-mill': '#FFA602',
	'palmoil-smallholder': '#B2FF00',
	'palmoil-plantation': '#1B4239',
	'palmoil-ffbDealer': '#AD3B1B',
	'kerneloil-mill': '#393830',
	'palmoil-kernelMill': '#624515',
	'palmoil-oleochemicals': '#BA6826',
	'palmoil-refinery': '#BA6826',
	'palmoil-storage': '#66C3A2',
	'palmoil-trader': '#3E92DF',
	'palmoil-endManufacturer': '#233EC0',
}

const pinColorForRice = {
	'rice-farmer': '#B2FF00',
	'rice-mill': '#FFA602',
	'rice-serviceprovider': '#1B4239',
	'rice-dealer': '#AD3B1B',
	'rice-packingcompany': '#66C3A2',
	'rice-packer': '#BA6826',
	'rice-trader': '#3E92DF',
	'rice-wholesaler': '#BA6826',
	'rice-importer': '#624515',
	'rice-endbuyer': '#233EC0',
}

const Pins = ({ data, onHover, onClick }) => {
	const SIZE = 40

	return data.map((city, index) =>
		Number.isNaN(city.coordinates[0]) ||
		Number.isNaN(city.coordinates[1]) ? (
			<> </>
		) : (
			<div
			// onMouseEnter={() => onHover(city)}
			// onMouseLeave={() => onHover({})}
			>
				<Marker
					// eslint-disable-next-line react/no-array-index-key
					key={`marker-${index}`}
					longitude={city.coordinates[0]}
					latitude={city.coordinates[1]}
				>
					<svg
						height={SIZE}
						viewBox="0 0 24 24"
						fill={
							currentPlatform() === 'rice'
								? pinColorForRice[city.orgTypeID] || '#171fa9'
								: pinColor[city.orgTypeID] || '#171fa9'
						}
						strokeWidth={1}
						stroke={
							currentPlatform() === 'rice'
								? pinBorderColorForRice[city.orgTypeID]
								: pinBorderColor[city.orgTypeID]
						}
						style={{
							...pinStyle,
							transform: `translate(${-SIZE / 2}px,${-SIZE}px)`,
						}}
						onClick={() => {
							onClick(city)
							onHover(city)
						}}
					>
						<path d={ICON} />
					</svg>
				</Marker>
			</div>
		)
	)
}

// const dataLayer1 = {
// 	id: 'data',
// 	type: 'fill',
// 	paint: {
// 		'fill-color': {
// 			property: 'percentile',
// 			stops: [
// 				[0, '#3288bd'],
// 				[1, '#66c2a5'],
// 				[2, '#abdda4'],
// 				[3, '#e6f598'],
// 				[4, '#ffffbf'],
// 				[5, '#fee08b'],
// 				[6, '#fdae61'],
// 				[7, '#f46d43'],
// 				[8, '#d53e4f'],
// 			],
// 		},
// 		'fill-opacity': 0.8,
// 	},
// }

// const dataLayer = {
// 	id: 'earthquakes-heat',
// 	type: 'heatmap',
// 	source: 'earthquakes',
// 	maxzoom: 9,
// 	paint: {
// 		// Increase the heatmap weight based on frequency and property magnitude
// 		'heatmap-weight': [
// 			'interpolate',
// 			['linear'],
// 			['get', 'mag'],
// 			0,
// 			0,
// 			6,
// 			1,
// 		],
// 		// Increase the heatmap color weight weight by zoom level
// 		// heatmap-intensity is a multiplier on top of heatmap-weight
// 		'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 1, 9, 3],
// 		// Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
// 		// Begin color ramp at 0-stop with a 0-transparancy color
// 		// to create a blur-like effect.
// 		'heatmap-color': [
// 			'interpolate',
// 			['linear'],
// 			['heatmap-density'],
// 			0,
// 			'rgba(33,102,172,0)',
// 			0.2,
// 			'rgb(103,169,207)',
// 			0.4,
// 			'rgb(209,229,240)',
// 			0.6,
// 			'rgb(253,219,199)',
// 			0.8,
// 			'rgb(239,138,98)',
// 			1,
// 			'rgb(178,24,43)',
// 		],
// 		// Adjust the heatmap radius by zoom level
// 		'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 2, 9, 20],
// 		// Transition from heatmap to circle layer by zoom level
// 		'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 7, 1, 9, 0],
// 	},
// }

const TraceMapsV1 = ({ coordinatesets = [], onClick, treeData }) => {
	const coordinates = []
	coordinatesets.forEach(coordinate => {
		if (!Number.isNaN(coordinate.coordinates[0])) {
			coordinates.push(coordinate.coordinates)
		}
	})
	const dimensions = {
		// eslint-disable-next-line no-restricted-globals
		width: screen.width,
		// eslint-disable-next-line no-restricted-globals
		height: screen.height,
	}
	const _viewport = new WebMercatorViewport(dimensions)
	const { latitude, longitude, zoom } = _viewport.fitBounds(coordinates, {
		padding: 20,
	})
	const [clickMarker, setClickMarker] = useState({})
	// const [layerData, setLayerData] = useState({})
	const [viewport, setViewport] = useState({
		...dimensions,
		latitude,
		longitude,
		zoom: zoom - 3,
	})
	const checkEmpty =
		Object.keys(clickMarker).length === 0 &&
		clickMarker.constructor === Object

	const mapRef = useRef(null)

	const addMapLayer = (map, firstCoordinateData, secondCoordinateData) => {
		let firstCoordinate = []
		let secondCoordinate = []
		if (coordinatesets.length > 0) {
			firstCoordinate = coordinatesets.find(
				set => set.orgID === firstCoordinateData.id
			)
			secondCoordinate = coordinatesets.find(
				set => set.orgID === secondCoordinateData.id
			)
		}
		if (firstCoordinate.coordinates && secondCoordinate.coordinates) {
			const routeJson = {
				type: 'Feature',
				properties: {},
				geometry: {
					type: 'LineString',
					coordinates: [
						firstCoordinate.coordinates,
						secondCoordinate.coordinates,
					],
				},
			}
			map.addLayer({
				id: `route-${firstCoordinateData.id}-${secondCoordinateData.id}`,
				type: 'line',
				source: {
					type: 'geojson',
					data: routeJson,
				},
				layout: {
					'line-join': 'round',
					'line-cap': 'round',
				},
				paint: {
					'line-color': '#498B20',
					'line-width': 2,
					'line-dasharray': [3, 3],
				},
			})
			map.addLayer({
				id: `arrowLayer-${firstCoordinateData.id}-${secondCoordinateData.id}`,
				type: 'symbol',
				source: {
					type: 'geojson',
					data: routeJson,
				},
				layout: {
					'symbol-placement': 'line',
					'symbol-spacing': 2,
					'icon-image': 'arrowIcon',
					'icon-size': 1,
				},
			})
		}
	}

	const getChildren = (map, tree) => {
		const parent = tree
		if (tree.children && tree.children.length > 0) {
			tree.children.forEach(child => {
				addMapLayer(map, child, parent)
				getChildren(map, child)
			})
		}
	}

	const onMapLoad = React.useCallback(() => {
		const map = mapRef.current.getMap()
		const img = new Image(15, 15)
		img.onload = () => map.addImage('arrowIcon', img)
		img.src =
			"data:image/svg+xml,%3Csvg viewBox='0 0 1024 1024' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M529.6128 512L239.9232 222.4128 384.7168 77.5168 819.2 512 384.7168 946.4832 239.9232 801.5872z' p-id='9085' fill='%23498B20'%3E%3C/path%3E%3C/svg%3E"
		if (treeData && treeData.length > 0) {
			treeData.forEach(tree => {
				getChildren(map, tree)
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [treeData])

	/** const addLines = useCallback(() => {
		console.log('hello')
		console.log(coordinatesets)
	}, [coordinatesets])
	*/

	// useEffect(() => {
	// 	async function getData() {
	// 		await setLayerData(_data)
	// 	}
	// 	getData()
	// }, [])
	// const addLines = useCallback(() => {
	// 	const map = mapRef.current.getMap()
	// 	coordinatesets.forEach(coordinates => {
	// 		map.addLayer({
	// 			id: 'route',
	// 			type: 'line',
	// 			source: {
	// 				type: 'geojson',
	// 				data: {
	// 					type: 'Feature',
	// 					properties: {},
	// 					geometry: {
	// 						type: 'LineString',
	// 						coordinates,
	// 					},
	// 				},
	// 			},
	// 			layout: {
	// 				'line-join': 'round',
	// 				'line-cap': 'round',
	// 			},
	// 			paint: {
	// 				'line-color': '#263b97',
	// 				'line-width': 5,
	// 			},
	// 		})
	// 	})
	// }, [coordinatesets])

	return (
		<ReactMapGL
			width="100vw"
			height="82vh"
			ref={mapRef}
			onLoad={onMapLoad}
			// https://docs.mapbox.com/mapbox-gl-js/api/map/#map-parameters
			mapStyle="mapbox://styles/mapbox/outdoors-v11"
			mapboxApiAccessToken={getMapBoxAccessToken()}
			{...viewport}
			onViewportChange={setViewport}
		>
			<div
				style={{
					width: '30px',
				}}
			>
				<NavigationControl showCompass={false} position="top-left" />
			</div>
			{/* <Source type="geojson" data={_dataa}>
				<Layer {...dataLayer1} />
			</Source>
			<Source type="geojson" data={layerData}>
				<Layer {...dataLayer} />
			</Source> */}
			{/* <div
					style={{
						width: '30px',
					}}
				>
					<GeolocateControl />
					<FullscreenControl />
					<NavigationControl />
				</div> */}

			<Pins
				data={coordinatesets}
				onHover={data => {
					if (data.orgID && data.orgID === clickMarker.orgID) {
						setClickMarker({})
					} else {
						setClickMarker(data)
					}
				}}
				onClick={data => onClick(data)}
			/>

			{!checkEmpty && (
				<Popup
					key={clickMarker.orgID}
					tipSize={10}
					anchor="left"
					longitude={clickMarker.coordinates[0]}
					latitude={clickMarker.coordinates[1]}
					color="red"
					// closeOnClick={false}
					// onClose={() => setClickMarker({})}
				>
					<Box
						style={{
							maxWidth: 300,
						}}
					>
						<Label
							color="#fff"
							small
							style={{
								borderLeft: '3px solid #e07616',
								paddingLeft: 16,
								cursor: 'inherit',
							}}
						>
							{clickMarker.name}
						</Label>
						<Label
							color={
								clickMarker.certificationStatus === 'unverified'
									? '#e07616'
									: 'green'
							}
							small
							style={{
								borderLeft: '3px solid #e07616',
								paddingLeft: 16,
								cursor: 'inherit',
								textTransform: 'capitalize',
							}}
						>
							Status: {clickMarker.certificationStatus}
						</Label>
						{clickMarker.orgType && (
							<Label
								color="#fff"
								small
								style={{
									borderLeft: '3px solid #e07616',
									paddingLeft: 16,
									cursor: 'inherit',
									textTransform: 'capitalize',
								}}
							>
								{clickMarker.orgType}
							</Label>
						)}
						{clickMarker.deforestationData.length > 0 && (
							<div>
								{clickMarker.deforestationData.map(
									deforestationData => [
										<br />,
										<div>
											<Label
												color="#fff"
												small
												key={
													deforestationData.universalID
												}
												style={{
													borderLeft:
														'3px solid #e07616',
													paddingLeft: 16,
													cursor: 'inherit',
													textTransform: 'capitalize',
												}}
											>
												{`${deforestationData.startPeriod} - ${deforestationData.endPeriod}: ${deforestationData.deforestArea} ha`}
											</Label>
										</div>,
										<div>
											<Label
												color="#fff"
												small
												key={`${deforestationData.universalID}-${deforestationData.deforestArea}`}
												style={{
													borderLeft:
														'3px solid #e07616',
													paddingLeft: 16,
													cursor: 'inherit',
													textTransform: 'capitalize',
												}}
											>
												{`Mill Score - ${Number(
													deforestationData.deforestScore
												).toFixed(3)}`}
											</Label>
										</div>,
									]
								)}
								<br />
								<div>
									<Label
										color="#fff"
										small
										key="descartes"
										style={{
											borderLeft: '3px solid #e07616',
											paddingLeft: 16,
											cursor: 'inherit',
											textTransform: 'capitalize',
										}}
									>
										Data provided by Descartes Labs, USA
									</Label>
								</div>
							</div>
						)}
					</Box>
				</Popup>
			)}
		</ReactMapGL>
	)
}

TraceMapsV1.defaultProps = {
	data: [],
	onClick: () => {},
}

export { TraceMapsV1 }
