import React from 'react'
import theme from 'ui-lib/utils/base-theme'
import { Box } from 'ui-lib/utils/Box'
import { getIn } from 'timm'
import { P, H3 } from 'ui-lib/components/Typography'

export const DriverCard = ({ driver, onClick, activeDriver }) => {
	const isActive = activeDriver && activeDriver.id === driver.id
	const address =
		getIn(driver, ['primaryAddress', 'city']) ||
		getIn(driver, ['primaryAddress', 'state']) ||
		getIn(driver, ['primaryAddress', 'country'])

	return (
		<Box
			alignItems="center"
			row
			style={{
				borderRadius: 4,
				border: `1px solid ${theme.color.grey4}`,
				marginBottom: '8px',
				boxShadow: '3px 3px 6px #00000029',
				background: isActive ? '#EBEEF9' : '#FFFFFF',
				cursor: 'pointer',
			}}
			padding={8}
			onClick={() => onClick(driver)}
		>
			<Box width="20%">
				<img
					alt="driver"
					src={
						getIn(driver, ['meta', 'profileImage']) ||
						'https://www.startupsg.gov.sg/api/v0/profile-images/17400/image/avatar'
					}
					style={{
						width: '50px',
						height: '50px',
						borderRadius: '50%',
					}}
				/>
			</Box>
			<Box width="80%" style={{ marginTop: '1%' }}>
				<H3 color={theme.color.accent2}>{driver.fullName}</H3>
				<P
					style={{
						color: '#8891A2',
					}}
				>
					{address}
				</P>
			</Box>
		</Box>
	)
}
