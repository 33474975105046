import React, { useState, useEffect } from 'react'
import { Box } from 'ui-lib/utils/Box'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Card } from 'ui-lib/components/Card'
import { Modal } from 'ui-lib/components/Modal'
import { RadioInputGroup } from 'ui-lib/components/Radio'
import { H3, P } from 'ui-lib/components/Typography'
import { Icon } from 'ui-lib/icons/components/Icon'
import { ButtonIconWrapper, Button } from 'ui-lib/components/Button'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import SettingsIcon from 'ui-lib/icons/settings.svg'
import { Title } from 'core-app/shared/components/Title'
import { MainRouteDuc } from 'core-app/routes/duc'
import { CookieDuc } from 'core-app/modules/App/cookieDuc'
import { SettingsDuc } from 'core-app/modules/Settings/duc'
import { WebTourDuc } from 'core-app/modules/WebTour/duc'
import { WebTour } from 'core-app/modules/WebTour'
import { AppDuc } from 'core-app/modules/App/duc'
import theme from 'ui-lib/utils/base-theme'

const ChangeLanguage = () => {
	const dispatch = useDispatch()
	const { t, i18n } = useTranslation()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const activeLanguage =
		useSelector(CookieDuc.selectors.getActiveLanguage) || 'eng'
	const [modal, showModal] = useState(false)
	const [language, setLanguage] = useState(activeLanguage)
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)
	useEffect(() => {
		if (tourModule === 'languageUpdate') {
			dispatch(WebTourDuc.creators.setActiveTourModule('languageUpdate2'))
		}
	}, [tourModule, dispatch])

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.settings'),
			name: 'settings',
			isActive: true,
		},
		{
			label: t('changeLanguage.langUpdate'),
			name: 'change language',
			isActive: false,
		},
	]

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'settings') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.SETTINGS)
			)
		}
	}

	function changeLanguage(lng) {
		i18n.changeLanguage(lng)
	}

	return (
		<>
			{showModal && (
				<Modal
					forceCloseviaButton
					heading={t('changeLanguage.langSettings')}
					show={modal}
					body={
						<P small bold>
							{t('changeLanguage.languageModalText')}
						</P>
					}
					closelabel={t('common.back')}
					confirmlabel={t('common.proceed')}
					size="large"
					onClose={() => showModal(false)}
					onConfirm={() => {
						showModal(false)
						changeLanguage(language)
						dispatch(SettingsDuc.creators.setLanguage(language))
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.SETTINGS
							)
						)
					}}
				/>
			)}
			<Box padding={8} width="100%" height="100%">
				<Box style={{ padding: '0 5' }}>
					<Breadcrumb
						links={breadCrumbs}
						onClick={target => handleBreadCrumbClick(target)}
					/>
				</Box>
				<Box row justifyContent="space-between" alignItems="center">
					<Title
						title={t('changeLanguage.title')}
						icon={SettingsIcon}
						note={t('changeLanguage.note')}
					/>
				</Box>
				<Card
					width={isMobile ? '320px' : '100%'}
					height="420px"
					textAlign="left"
				>
					<Box
						padding={8}
						style={{
							backgroundColor: theme.color.accent3,
						}}
					>
						<H3
							bold
							color={theme.color.accent2}
							style={{
								position: 'relative',
								right: '-25px',
							}}
						>
							{t('changeLanguage.langSettings')}
						</H3>
					</Box>
					<Box
						id="input-language"
						style={{ padding: '20px 30px 0 30px' }}
					>
						<RadioInputGroup
							name="languagePref"
							label={t('changeLanguage.langPref')}
							selected={language}
							options={[
								{
									label: t('changeLanguage.english'),
									name: 'eng',
								},
								{
									label: t('changeLanguage.spanish'),
									name: 'spa',
								},
								{
									label: t('changeLanguage.malay'),
									name: 'may',
								},
								{
									label: t('changeLanguage.indonesian'),
									name: 'ind',
								},
								{
									label: t('changeLanguage.mandarin'),
									name: 'chi',
								},
								{
									label: t('changeLanguage.tamil'),
									name: 'tam',
								},
								{
									label: t('changeLanguage.vietnamese'),
									name: 'vie',
								},
							]}
							onChange={e => {
								setLanguage(e.target.value)
							}}
						/>

						<Box alignItems="flex-end">
							<Box width={150} id="update-language">
								<Button
									onClick={() => {
										showModal(true)

										dispatch(
											WebTourDuc.creators.setActiveTourModule(
												''
											)
										)
									}}
									type="submit"
									primary
									label={t('common.update')}
								/>
							</Box>
						</Box>
					</Box>
				</Card>
				<Box padding="25px 0" style={{ maxWidth: 150 }}>
					<Button
						label={t('common.back')}
						rounded
						customIcon={
							<ButtonIconWrapper lightBG>
								<Icon glyph={LeftArrowIcon} />
							</ButtonIconWrapper>
						}
						onClick={() =>
							dispatch(
								MainRouteDuc.creators.switchPage(
									MainRouteDuc.types.SETTINGS
								)
							)
						}
					/>
				</Box>
			</Box>
			{tourModule === 'languageUpdate2' && <WebTour />}
		</>
	)
}

export { ChangeLanguage }
