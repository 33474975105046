import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AppDuc } from 'core-app/modules/App/duc'
import { AdminDuc } from 'core-app/modules/Admin/duc'
import { Title } from 'core-app/shared/components/Title'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Card } from 'ui-lib/components/Card'
import { Button } from 'ui-lib/components/Button'
import { Input } from 'ui-lib/components/Input'
import { H3 } from 'ui-lib/components/Typography'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { isEmptyObject, getRandomNumber } from 'core-app/utils/helpers'
import { useFormik } from 'formik'
import * as yup from 'yup'
import theme from 'ui-lib/utils/base-theme'
import { DatePicker } from 'ui-lib/components/Datepicker/index'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import PlusIcon from 'ui-lib/icons/plus.svg'
import { Label } from 'ui-lib/components/Label'

const CreateWeighBridge = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const locationState = useSelector(AdminDuc.selectors.location)
	const { payload = {} } = locationState
	const { action } = payload
	const initialValues = useSelector(
		AdminDuc.selectors.getInitialWeighBridgeDetails
	)
	const [uploadedFiles, setUploadedFiles] = useState([])
	const [key, setKey] = useState(getRandomNumber())

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.admin'),
			name: 'admin',
			isActive: true,
		},
		{
			label: t('breadcrumb.assets'),
			name: 'assets',
			isActive: true,
		},
		{
			label:
				action === 'edit-asset'
					? t('breadcrumb.editAsset')
					: t('breadcrumb.create-asset'),
			name: 'create-asset',
			isActive: false,
		},
	]

	/** Handlers to react on actions */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'admin') {
			dispatch(MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN))
		}
		if (target === 'assets') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.ADMIN$SUBROOT,
					{
						rootModule: 'assets',
					}
				)
			)
		}
	}

	const validationSchema = yup.object().shape({
		name: yup.string().required(t('validation.assetNameReq')),
		manufacturer: yup.string().required(t('validation.manufacturerReq')),
		model: yup.string().required(t('validation.modelReq')),
		capacity: yup
			.number()
			.positive(t('validation.positiveCapacity'))
			.required(t('validation.capacityReq')),
		lastcalibrated: yup.date().required(t('validation.lastcalibratedReq')),
		calibrationvalidity: yup
			.date()
			.required(t('validation.calibrationvalidityReq')),
		calibratedby: yup.string().required(t('validation.calibratedbyReq')),
	})

	const {
		handleSubmit,
		values,
		handleChange,
		handleBlur,
		setFieldValue,
		errors,
	} = useFormik({
		initialValues,
		enableReinitialize: true,
		validationSchema,
		onSubmit: _values => {
			switch (action) {
				case 'create-asset': {
					dispatch(
						AdminDuc.creators.createWeighbrigeAsAsset(
							_values,
							uploadedFiles,
							t('createAsset.weighbridgeToast')
						)
					)

					break
				}
				case 'edit-asset': {
					const updatedValues = {
						..._values,
						weighbridgeID: payload.documentReference,
					}
					dispatch(
						AdminDuc.creators.editWeighbridge(
							updatedValues,
							uploadedFiles,
							t('createAsset.weighbridgeUpdateToast')
						)
					)

					break
				}

				default:
					break
			}
		},
	})

	const disableCTA = !isEmptyObject(errors)

	// Execute when user select a file
	const handleContractUpload = async event => {
		const { files } = event.target
		if (
			files &&
			files.length > 0 &&
			files[0] &&
			files[0].size / 1048576 > 5
		) {
			dispatch(
				AppDuc.creators.showToast({
					messageType: 'error',
					message: 'File size exceeds 5 MB',
				})
			)
		} else {
			setUploadedFiles([...files])
		}
	}

	useEffect(() => {
		if (action === 'edit-asset') {
			setKey(getRandomNumber())
		}
	}, [action, initialValues])

	return (
		<Box padding={8} width="100%" height="100%">
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Box row justifyContent="space-between" alignItems="center">
				<Title
					title={
						action === 'edit-asset'
							? t('createAsset.editAsset')
							: t('createAsset.title')
					}
				/>
			</Box>
			<Card
				width={isMobile ? '320px' : '100%'}
				height={isMobile ? '350px' : 'auto'}
				textAlign="left"
			>
				<Box
					padding={8}
					style={{
						backgroundColor: theme.color.accent3,
					}}
				>
					<H3
						bold
						color={theme.color.accent2}
						style={{
							position: 'relative',
							right: '-25px',
						}}
					>
						{action === 'edit-asset'
							? t('createAsset.editAsset')
							: t('createAsset.title')}
					</H3>
				</Box>
				<React.Fragment key={key}>
					<Box style={{ padding: '24px 16px 16px 24px' }}>
						<form onSubmit={handleSubmit}>
							<Box row>
								<Box width={300}>
									<Input
										label={t('createAsset.assetName')}
										value={values.name}
										name="name"
										type="text"
										onChange={handleChange}
										onBlur={handleBlur}
										error={errors.name}
										required
									/>
								</Box>
								<Spacer size={32} />
								<Box width={300}>
									<Input
										label={t('createAsset.manufacturer')}
										value={values.manufacturer}
										name="manufacturer"
										type="text"
										onChange={handleChange}
										onBlur={handleBlur}
										error={errors.manufacturer}
										required
									/>
								</Box>
							</Box>
							<Box row>
								<Box width={300}>
									<Input
										label={t('createAsset.model')}
										value={values.model}
										name="model"
										type="text"
										onChange={handleChange}
										onBlur={handleBlur}
										error={errors.model}
										required
									/>
								</Box>
								<Spacer size={32} />
								<Box width={300}>
									<Input
										label={t('createAsset.capacity')}
										value={values.capacity}
										name="capacity"
										type="number"
										onChange={handleChange}
										onBlur={handleBlur}
										error={errors.capacity}
										required
									/>
								</Box>
							</Box>
							<Box row>
								<Box width={300} style={{ marginTop: 20 }}>
									<DatePicker
										value={values.lastcalibrated}
										onChange={value => {
											setFieldValue(
												'lastcalibrated',
												value
											)
										}}
										maxDate={
											values.calibrationvalidity &&
											new Date(
												values.calibrationvalidity
											).getTime() < new Date().getTime()
												? new Date(
														values.calibrationvalidity
												  ).setDate(
														new Date(
															values.calibrationvalidity
														).getDate() - 1
												  )
												: new Date()
										}
										label={t('createAsset.lastcalibrated')}
										error={errors.lastcalibrated}
										required
									/>
								</Box>
								<Spacer size={32} />
								<Box width={300} style={{ marginTop: 20 }}>
									<DatePicker
										value={values.calibrationvalidity}
										onChange={value => {
											setFieldValue(
												'calibrationvalidity',
												value
											)
										}}
										minDate={new Date(
											values.lastcalibrated
										).setDate(
											new Date(
												values.lastcalibrated
											).getDate() + 1
										)}
										label={t(
											'createAsset.calibrationvalidity'
										)}
										error={errors.calibrationvalidity}
										required
									/>
								</Box>
							</Box>
							<Box row>
								<Box width={300} style={{ marginTop: 20 }}>
									<Input
										label={t('createAsset.calibratedby')}
										value={values.calibratedby}
										name="calibratedby"
										type="text"
										onChange={handleChange}
										onBlur={handleBlur}
										error={errors.calibratedby}
										required
									/>
								</Box>
							</Box>
							<Box row>
								<Box width="100%" style={{ marginTop: 20 }}>
									<Label>
										{t(
											'createAsset.calibrationcertificate'
										)}
									</Label>
									<div style={{ marginTop: 20 }}>
										<input
											id="file-upload"
											style={{ display: 'none' }}
											type="file"
											accept="application/pdf"
											onChange={handleContractUpload}
										/>
										<label htmlFor="file-upload">
											<Box
												style={{
													height: 88,
													width: 88,
													borderRadius: '50%',
													background: '#645DB7',
													cursor: 'pointer',
													boxShadow:
														'inset 0 0 0 10px #E4E2FA, inset 0 0 0 20px #B6B2E5',
												}}
												center
											>
												<IconWrapper size={24}>
													<Icon
														glyph={PlusIcon}
														color={
															theme.color.white
														}
													/>
												</IconWrapper>
											</Box>
										</label>
										{uploadedFiles.length > 0 && (
											<p>{uploadedFiles[0].name}</p>
										)}
										{uploadedFiles.length === 0 &&
											initialValues?.files !== '' && (
												<p>
													{initialValues.files &&
														initialValues.files
															.length > 0 &&
														initialValues.files[0]
															.meta.fullURL}
												</p>
											)}
									</div>
								</Box>
							</Box>
							<Box alignItems="flex-end">
								<Box width={150}>
									<Button
										disabled={disableCTA}
										type="submit"
										primary
										label={t('common.submit')}
									/>
								</Box>
							</Box>
						</form>
					</Box>
				</React.Fragment>
			</Card>
		</Box>
	)
}

export { CreateWeighBridge }
