import React from 'react'
import { getIn } from 'timm'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { FileUpload } from 'ui-lib/components/FileUpload'
import { Label } from 'ui-lib/components/Label'
import theme from 'ui-lib/utils/base-theme'
import { currentPlatform } from 'ui-lib/utils/config'
import {
	Select,
	MultiSelectWithSearch,
	CreatableSelect,
} from 'ui-lib/components/Select'
import { useDispatch, useSelector } from 'react-redux'
import { AppDuc } from 'core-app/modules/App/duc'
import { Input } from 'ui-lib/components/Input'
import { DatePicker } from 'ui-lib/components/Datepicker'
import { useTranslation } from 'react-i18next'
import { WebTourDuc } from 'core-app/modules/WebTour/duc'
import { isEmptyObject } from 'core-app/utils/helpers'
import { Button, ButtonWithNoBorder } from 'ui-lib/components/Button'
import { AdminDuc } from 'core-app/modules/Admin/duc'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { certificationBodyList } from '../../../Onboard/config'

const EditCertificate = ({ certDetails, handleShowModal, edit = false }) => {
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)
	const dispatch = useDispatch()
	if (tourModule !== '') {
		dispatch(WebTourDuc.creators.setActiveTourModule(''))
	}

	const { t } = useTranslation()

	const initialValues = {
		id: certDetails && certDetails.id ? certDetails.id : '',
		certBody:
			certDetails && certDetails.certBody ? certDetails.certBody : '',
		certNum: certDetails && certDetails.certNum ? certDetails.certNum : '',
		certName:
			certDetails && certDetails.certName ? certDetails.certName : '',
		certOrg: certDetails && certDetails.certOrg ? certDetails.certOrg : '',
		certStartDate:
			certDetails &&
			certDetails.certStartDate &&
			certDetails.certStartDate !== '- - -'
				? certDetails.certStartDate
				: '',
		expiryDate:
			certDetails &&
			certDetails.expiryDate &&
			certDetails.expiryDate !== '- - -'
				? certDetails.expiryDate
				: '',
		issueDate:
			certDetails &&
			certDetails.issueDate &&
			certDetails.issueDate !== '- - -'
				? certDetails.issueDate
				: '',
		scmodel: certDetails && certDetails.scmodel ? certDetails.scmodel : [],
		files: certDetails && certDetails.files ? certDetails.files : [],
	}

	const validationSchema = () => {
		const shape = yup.object().shape({
			certNum: yup.string().required(t('common.fieldRequired')),
			certOrg: yup.string().required(t('common.fieldRequired')),
			issueDate: yup.string().required(t('common.fieldRequired')),
			expiryDate: yup.string().required(t('common.fieldRequired')),
			certStartDate: yup.string().required(t('common.fieldRequired')),
			scmodel: yup.array(),
		})
		if (currentPlatform() === 'palmoil') {
			shape.fields.scmodel = shape.fields.scmodel.required(
				t('common.fieldRequired')
			)
		}

		return shape
	}

	const {
		values,
		setFieldValue,
		handleSubmit,
		handleBlur,
		touched,
		errors,
	} = useFormik({
		initialValues,
		enableReinitialize: true,
		validationSchema,
		onSubmit: _values => {
			const payload = {
				number: _values.certNum,
				files: _values.files,
				meta: {
					certificate: {
						issuingBody: _values.certOrg,
						certificationBody: _values.certBody,
						...(currentPlatform() === 'palmoil'
							? { supplychainModel: _values.scmodel }
							: {}),
					},
				},
			}
			if (!edit) {
				payload.type = 'certificate'
			}

			if (_values.certOrg === 'Others') {
				payload.meta.certificate.name = _values.certName
			}

			if (_values.certStartDate) {
				payload.expiryStartDate = new Date(_values.certStartDate)
			}

			if (_values.expiryDate) {
				payload.expiryEndDate = new Date(_values.expiryDate)
			}

			if (_values.issueDate) {
				payload.issueDate = new Date(_values.issueDate)
			}

			if (edit) {
				dispatch(
					AdminDuc.creators.updateSingleCertificate(
						payload,
						values.id,
						t('common.certUpdateSuccess')
					)
				)
			} else {
				dispatch(
					AdminDuc.creators.addNewCertificate(
						payload,
						t('common.certCreateSuccess')
					)
				)
			}
		},
	})

	const disableCTA = !isEmptyObject(errors)

	return (
		<Box
			style={{
				height: '400px',
				overflow: 'scroll',
			}}
		>
			<form onSubmit={handleSubmit}>
				<Box style={{ paddingRight: '6px' }}>
					<Box
						row
						justifyContent="space-between"
						style={{ width: '100%' }}
					>
						<Box style={{ width: '48%' }}>
							<Label
								required
								style={{
									fontWeight: '300px',
									fontSize: '16px',
									color: theme.color.accent2,
									width: '100%',
								}}
							>
								{t('viewCompanyInfo.selectCertType')}
							</Label>
							<Spacer size={8} />

							{currentPlatform() === 'palmoil' ? (
								<Select
									placeholder={t(
										'viewCompanyInfo.selectCertType'
									)}
									name={`type-${certDetails.activeIndex}`}
									options={[
										'RSPO',
										'MSPO',
										'MPOB',
										'ISCC',
										'ISPO',
										'Others',
									]}
									onBlur={handleBlur}
									error={touched.certOrg && errors.certOrg}
									value={certDetails.certOrg}
									onChange={value => {
										setFieldValue('certOrg', value)
									}}
									returnOnlyValue
								/>
							) : (
								<Input
									value={certDetails.certOrg}
									name="certOrg"
									type="text"
									onChange={e => {
										setFieldValue('certOrg', e.target.value)
									}}
									onBlur={handleBlur}
									disabled={
										certDetails &&
										certDetails.certOrg === 'UNCERTIFIED'
									}
									extendStyles={{ width: 210 }}
									error={
										certDetails.certOrg !== 'UNCERTIFIED' &&
										touched.certOrg &&
										errors.certOrg
									}
								/>
							)}
						</Box>
						{certDetails.certOrg === 'Others' && (
							<Box style={{ width: '48%' }}>
								<Label
									required
									style={{
										fontWeight: '300px',
										fontSize: '16px',
										color: theme.color.accent2,
										width: '100%',
									}}
								>
									{t('viewCompanyInfo.certName')}
								</Label>
								<Spacer size={8} />
								<Box style={{ width: '100%' }}>
									<Input
										value={certDetails.certName}
										name="certName"
										type="text"
										onChange={e => {
											setFieldValue(
												'certName',
												e.target.value
											)
										}}
										extendStyles={{
											background:
												certDetails?.certName
													?.length === 0
													? `${theme.color.grey11} 0% 0% no-repeat padding-box`
													: `${theme.color.white} 0% 0% no-repeat padding-box`,
											boxShadow:
												certDetails?.certName
													?.length === 0
													? '0px 6px 16px #05050707'
													: `0px 2px 8px ${theme.color.blue6}`,
											border:
												certDetails?.certName
													?.length === 0
													? `1px solid ${theme.color.grey4}`
													: `1px solid ${theme.color.blue5}`,
											borderRadius: '4px',
											opacity: 1,
										}}
									/>
								</Box>
							</Box>
						)}
					</Box>
					<Box
						row
						overflow
						justifyContent="space-between"
						style={{ width: '100%' }}
					>
						{currentPlatform() === 'palmoil' && (
							<Box style={{ width: '32%' }}>
								<Label
									required
									style={{
										fontWeight: '300px',
										fontSize: '16px',
										color: theme.color.accent2,
										width: '100%',
									}}
								>
									{t('viewAsset.scModel')}
								</Label>
								<Spacer size={8} />
								<MultiSelectWithSearch
									selected={values?.scmodel}
									value={values?.scmodel}
									options={[
										{
											name: t(
												'tdmDetailsEntry.identity-preserved'
											),
											id: 'identity-preserved',
										},
										{
											name: t(
												'tdmDetailsEntry.segregated'
											),
											id: 'segregated',
										},
										{
											name: t(
												'tdmDetailsEntry.mass-balance'
											),
											id: 'mass-balance',
										},
									]}
									onChange={value => {
										setFieldValue('scmodel', value)
									}}
									returnOnlyValue
								/>
							</Box>
						)}
						<Box overflow style={{ width: '32%' }}>
							<Label
								style={{
									fontWeight: '300px',
									fontSize: '16px',
									color: theme.color.accent2,
									width: '100%',
								}}
							>
								{t('viewCompanyInfo.certBody')}
							</Label>
							<Spacer size={8} />

							{currentPlatform() === 'palmoil' ? (
								<CreatableSelect
									options={certificationBodyList}
									value={values.certBody}
									onChange={keyValue => {
										setFieldValue('certBody', keyValue)
									}}
									error={touched.certBody && errors.certBody}
									returnOnlyValue
								/>
							) : (
								<Input
									value={values.certBody}
									name="certBody"
									type="text"
									onChange={e => {
										setFieldValue(
											'certBody',
											e.target.value
										)
									}}
									extendStyles={{
										width: 210,
									}}
									onBlur={handleBlur}
									disabled={
										values &&
										values.certBody === 'UNCERTIFIED'
									}
								/>
							)}
						</Box>
						<Box style={{ width: '32%' }}>
							<Label
								required
								style={{
									fontWeight: '300px',
									fontSize: '16px',
									color: theme.color.accent2,
									width: '100%',
								}}
							>
								{t('viewCompanyInfo.certNum')}
							</Label>
							<Spacer size={8} />
							<Box style={{ width: '100%' }}>
								<Input
									value={certDetails.certNum}
									name="certNum"
									type="text"
									onChange={e => {
										setFieldValue('certNum', e.target.value)
									}}
									onBlur={handleBlur}
									error={touched.certNum && errors.certNum}
									extendStyles={{
										background:
											certDetails &&
											certDetails.certNum &&
											certDetails.certNum.length === 0
												? `${theme.color.grey11} 0% 0% no-repeat padding-box`
												: `${theme.color.white} 0% 0% no-repeat padding-box`,
										boxShadow:
											certDetails &&
											certDetails.certNum &&
											certDetails.certNum.length === 0
												? '0px 6px 16px #05050707'
												: `0px 2px 8px ${theme.color.blue6}`,
										border:
											certDetails &&
											certDetails.certNum &&
											certDetails.certNum.length === 0
												? `1px solid ${theme.color.grey4}`
												: `1px solid ${theme.color.blue5}`,
										borderRadius: '4px',
										opacity: 1,
									}}
								/>
							</Box>
						</Box>
						{currentPlatform() !== 'palmoil' && (
							<Box style={{ width: '32%' }} />
						)}
					</Box>

					<Box row justifyContent="space-between">
						<Box width="200px">
							<Label
								required
								style={{
									fontWeight: '300px',
									fontSize: '16px',
									color: theme.color.accent2,
								}}
							>
								{t('viewCompanyInfo.issueDate')}
							</Label>
							<Spacer size={8} />
							<DatePicker
								required
								name="issueDate"
								maxDate={new Date()}
								returnKeyValue
								value={new Date(values.issueDate)}
								error={touched.issueDate && errors.issueDate}
								onChange={keyValue =>
									setFieldValue(
										'issueDate',
										keyValue.issueDate
									)
								}
							/>
						</Box>
						<Box width="200px">
							<Label
								required
								style={{
									fontWeight: '300px',
									fontSize: '16px',
									color: theme.color.accent2,
								}}
							>
								{t('viewPartner.startDate')}
							</Label>
							<Spacer size={8} />
							<DatePicker
								required
								name="certStartDate"
								returnKeyValue
								value={values.certStartDate}
								minDate={values.issueDate}
								maxDate={values.expiryDate}
								error={
									touched.certStartDate &&
									errors.certStartDate
								}
								onChange={keyValue =>
									setFieldValue(
										'certStartDate',
										keyValue.certStartDate
									)
								}
							/>
						</Box>
						<Box width="200px">
							<Label
								required
								style={{
									fontWeight: '300px',
									fontSize: '16px',
									color: theme.color.accent2,
								}}
							>
								{t('viewCompanyInfo.expiryDate')}
							</Label>
							<Spacer size={8} />
							<DatePicker
								required
								name="expiryDate"
								returnKeyValue
								error={touched.expiryDate && errors.expiryDate}
								value={values.expiryDate}
								minDate={values.certStartDate}
								onChange={keyValue =>
									setFieldValue(
										'expiryDate',
										keyValue.expiryDate
									)
								}
							/>
						</Box>
					</Box>

					<FileUpload
						type="public"
						name="file"
						size="5242880"
						modalTriggerBtnLabel={t('common.upload')}
						label={t('onboarding.certCopy')}
						supportedFileType=".jpg,.png,.pdf"
						outline
						currentUploads={certDetails.files}
						onDocumentChange={document =>
							setFieldValue('files', document)
						}
						onUploadFailure={({ message }) => {
							dispatch(
								AppDuc.creators.showToast({
									messageType: 'error',
									message,
								})
							)
						}}
					/>
					<Box
						style={{
							bottom: 0,
							right: '62px',
							position: 'absolute',
						}}
						row
						margin="20px 0"
					>
						<ButtonWithNoBorder
							label={t('common.close')}
							onClick={() => {
								handleShowModal(false)
							}}
							extendStyles={{
								marginRight: 10,
								color: theme.color.grey6,
							}}
						/>
						<Button
							disabled={disableCTA}
							type="submit"
							primary
							label={edit ? t('common.update') : t('common.add')}
							extendStyles={{
								padding: '0 20px',
							}}
						/>
					</Box>
				</Box>
			</form>
		</Box>
	)
}

export default EditCertificate
