import Duck from 'extensible-duck'
import { setIn, getIn } from 'timm'

const initialState = {
	activeModule: '',
}

export const FeedbackDuc = new Duck({
	namespace: 'partner',
	store: 'global',
	types: ['SET_ACTIVE_MODULE', 'SAVE_USER_FEEDBACK'],
	initialState,
	reducer: (state, action, duck) => {
		if (action.type === duck.types.SET_ACTIVE_MODULE) {
			return setIn(state, ['activeModule'], action.module)
		}

		return state
	},
	selectors: {
		auth: state => state.auth,
		location: state => state.location,
		activeModule: state =>
			getIn(state, ['partner', 'activeModule']) || 'Error',
	},
	creators: duck => ({
		setActiveModule: module => ({
			type: duck.types.SET_ACTIVE_MODULE,
			module,
		}),
		saveUserFeedback: (feedback, successToast) => ({
			type: duck.types.SAVE_USER_FEEDBACK,
			feedback,
			successToast,
		}),
	}),
})
