import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn } from 'timm'
import { MainRouteDuc } from 'core-app/routes/duc'
import { ProductsDuc } from 'core-app/modules/Products/duc'
import { ProductsModal } from 'core-app/modules/Products/components/ProductsModal'
import { ProductProfile } from 'core-app/modules/Products/components/ProductProfile'
import { BoxShadowTable } from 'core-app/shared/components/BoxShadowTable'
import { FormSearch } from 'grommet-icons'
// import { ShowMore } from 'core-app/modules/App/components/ShowMore'
import { Title } from 'core-app/shared/components/Title'
import { columnConfig } from 'core-app/modules/Products/components/Columns'
import { ButtonIconWrapper, Button } from 'ui-lib/components/Button'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Modal } from 'ui-lib/components/Modal'
import { Box } from 'ui-lib/utils/Box'
import { Input } from 'ui-lib/components/Input'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import ReportProblemIcon from 'ui-lib/icons/report_problem.svg'
import { H2, P, Label } from 'ui-lib/components/Typography'
import theme from 'ui-lib/utils/base-theme'

const labelStyles = {
	fontSize: '16px',
	margin: '0 0 8px',
	color: theme.color.black3,
}

const MainDashboard = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const documents = useSelector(ProductsDuc.selectors.getProductsListing)
	const [showModal, setShowModal] = useState(false)
	const [activeProducts, setActiveProducts] = useState([])
	const [confModal, setConfModal] = useState(false)
	const [productProfileModal, setProductProfileModal] = useState(false)
	const [isUpdate, setIsUpdate] = useState(false)
	const [currentProduct, setCurrentProduct] = useState(null)
	const [currentLcvValue, setCurrentLcvValue] = useState(null)
	const [currentProductList, setCurrentProductList] = useState(documents)
	const actor = useSelector(AuthDuc.selectors.getActor)
	const pagination = useSelector(ProductsDuc.selectors.getPaginationQuery)

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.products'),
			name: 'products',
			isActive: false,
		},
	]

	/** Handlers to react on actions */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
	}

	const handleChange = useCallback((meta = {}, isUpdateFromComp = false) => {
		setIsUpdate(isUpdateFromComp)
		setCurrentProduct(meta)
	}, [])

	const editDetails = () => {
		return (
			<Box
				style={{
					width: '412px',
					margin: '24px 0 0 40px',
				}}
			>
				<Label style={labelStyles}>
					{t('marketPlaceProducts.lcaFullForm')} (KgCO2e/ton)
				</Label>
				<Input
					value={currentProduct?.row?.meta?.lcv}
					name="lcv"
					type="number"
					onChange={e => setCurrentLcvValue(e.target.value)}
				/>
			</Box>
		)
	}

	const getProductsToBeDisabled = () => {
		let products = []
		if (documents.length > 0) {
			products = documents.map(item => {
				return item.product.id
			})
		}

		return products
	}

	// const handleProductsPagination = () => {
	// 	const paginationParams = {
	// 		startID: pagination.nextIndex,
	// 	}

	// 	dispatch(ProductsDuc.creators.fetchProductsListing(paginationParams))
	// }

	const handleProductsearch = value => {
		const newList = documents.filter(item => {
			const name = item?.product?.name.toUpperCase()
			const code = item?.product?.code.toUpperCase()

			return (
				name.includes(value.toUpperCase()) ||
				code.includes(value.toUpperCase())
			)
		})
		setCurrentProductList(newList)
	}

	useEffect(() => {
		setCurrentProductList(documents)
	}, [documents])

	return (
		<Box padding={8} width="100%" height="100%">
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<ProductsModal
				show={showModal}
				productsToBeDisabled={getProductsToBeDisabled()}
				onClose={() => {
					setShowModal(false)
				}}
				onProductConfirm={({ products }) => {
					setActiveProducts(products)
					setShowModal(false)
					setConfModal(true)
				}}
			/>
			<Modal
				forceCloseviaButton
				show={confModal}
				size="large"
				heading={
					<Box row>
						<IconWrapper
							size={40}
							style={{
								padding: 4,
								background: '#FFEFD8',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								borderRadius: 6,
							}}
						>
							<Icon
								glyph={ReportProblemIcon}
								style={{ color: '#F09112' }}
							/>
						</IconWrapper>
						<H2
							style={{
								marginLeft: 12,
								fontWeight: 'bold',
								color: '#F09112',
							}}
						>
							{t('rfq.confirmation')}
						</H2>
					</Box>
				}
				body={
					<P large bold>
						{t('products.confirmationMessage')}
					</P>
				}
				closelabel={t('common.no')}
				confirmlabel={t('common.proceed')}
				onClose={() => {
					setConfModal(false)
				}}
				onConfirm={() => {
					setConfModal(false)
					setProductProfileModal(true)
				}}
			/>
			<Modal
				size="auto"
				overflow="auto"
				show={productProfileModal}
				body={
					<ProductProfile
						documents={activeProducts}
						onConfirm={values => {
							dispatch(
								ProductsDuc.creators.addProduct(
									values,
									t('common.successCreate')
								)
							)
							setActiveProducts(values)
							setProductProfileModal(false)
						}}
					/>
				}
				hideButtons
			/>
			<Modal
				size="680px"
				show={isUpdate}
				closeable
				heading={t('products.editProductTitle')}
				body={editDetails()}
				confirmlabel={t('common.update')}
				closelabel={t('common.close')}
				onClose={() => setIsUpdate(false)}
				extendStyles={{ borderRadius: '10px' }}
				isDisabled={currentLcvValue < 0}
				onConfirm={() => {
					const _payload = {
						meta: {
							lcv: parseFloat(currentLcvValue),
						},
					}
					dispatch(
						ProductsDuc.creators.editProduct(
							_payload,
							getIn(currentProduct, ['row', 'product', 'id']),
							t('common.updateSuccess')
						)
					)
					setIsUpdate(false)
				}}
			/>
			<Box row justifyContent="space-between" alignItems="center">
				<Title title={t('products.products')} />
				<div style={{ marginTop: '24px', display: 'flex' }}>
					<Input
						name="productSearch"
						type="text"
						onChange={e => {
							handleProductsearch(e.target.value)
						}}
						key={productProfileModal} // to clear the box when new product added
						placeholder={t('schedulerListing.search')}
						extendStyles={{
							width: '220px',
							borderRadius: '5px',
							borderRight: 'none',
							borderTopRightRadius: '0px',
							borderBottomRightRadius: '0px',
							backgroundColor: theme.color.white1,
							borderBottom: `1px solid ${theme.color.blue8}`,
						}}
					/>
					<div
						style={{
							width: '40px',
							height: '41px',
							paddingTop: '8px',
							cursor: 'pointer',
							borderRadius: '5px',
							borderTopLeftRadius: '0px',
							borderBottomLeftRadius: '0px',
							backgroundColor: theme.color.white1,
							border: `1px solid ${theme.color.blue8}`,
							borderLeft: 'none',
						}}
					>
						<FormSearch />
					</div>
				</div>
				<Box row justifyContent="flex-end">
					<Button
						label={t('products.addProduct')}
						primary
						rounded
						extendStyles={{
							height: '40px',
						}}
						onClick={() => setShowModal(true)}
					/>
				</Box>
			</Box>

			<BoxShadowTable
				size="large"
				columnConfig={columnConfig({
					onChange: handleChange,
					t,
					actor,
				})}
				rowData={currentProductList}
			/>
			{/* showing without pagination */}
			{/* <ShowMore
				documents={documents}
				total={pagination.total}
				handleChange={handleProductsPagination}
				type="products_pagination"
			/> */}
			<Box padding="25px 2px" style={{ maxWidth: 150 }}>
				<Button
					label={t('common.back')}
					rounded
					customIcon={
						<ButtonIconWrapper lightBG>
							<Icon glyph={LeftArrowIcon} />
						</ButtonIconWrapper>
					}
					onClick={() =>
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.DASHBOARD
							)
						)
					}
				/>
			</Box>
		</Box>
	)
}

export { MainDashboard }
