import React, { useEffect, useRef } from 'react'
import { Box } from 'ui-lib/utils/Box'
import { Box as GrommBox } from 'grommet'
import { Message } from './Message'

const MessageGroup = ({ key, msg, senderOrgId, handleClick }) => {
	const chatAreaRef = useRef(null)

	useEffect(() => {
		if (chatAreaRef && chatAreaRef.current) {
			chatAreaRef.current.scrollTop = chatAreaRef.current.scrollHeight
		}
	}, [key])

	return (
		<Box
			ref={chatAreaRef}
			style={{
				overflow: 'scroll',
				height: '100%',
				position: 'relative',
			}}
		>
			<GrommBox
				width="100%"
				pad="10px 0px"
				overflow="scroll"
				style={{ position: 'absolute' }}
			>
				{(msg || []).map(
					_message =>
						_message.type === 'text' &&
						_message.content.message !== '' && (
							<Message
								canEditDelete
								key={_message?.id}
								message={_message}
								senderOrgId={senderOrgId}
								handleClick={(id, message) => {
									handleClick(id, message)
								}}
							/>
						)
				)}
			</GrommBox>
		</Box>
	)
}

export { MessageGroup }
