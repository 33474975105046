import React, { useState } from 'react'
import { Box } from 'ui-lib/utils/Box'
import { ButtonWithNoBorder, ButtonIconWrapper } from 'ui-lib/components/Button'
import { Icon } from 'ui-lib/icons/components/Icon'
import EditIcon from 'ui-lib/icons/edit.svg'
import { Modal } from 'ui-lib/components/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { AdminDuc } from 'core-app/modules/Admin/duc'
import { SmallText } from 'ui-lib/components/Typography'
import { Card } from 'ui-lib/components/Card'
import { ErrorBlock } from 'ui-lib/components/ErrorBlocks'
import theme from 'ui-lib/utils/base-theme'
import { getIn } from 'timm'
import DeleteIcon from 'ui-lib/icons/deleteTrash.svg'
import { Spacer } from 'ui-lib/utils/Spacer'
import { FileLists } from 'ui-lib/components/FileUpload'
import { KeyValueSegment } from 'core-app/modules/Admin/components/KeyValueSegment'
import { AppDuc } from 'core-app/modules/App/duc'
import { WebTourDuc } from 'core-app/modules/WebTour/duc'
import EditBusiness from './EditBusiness'

const BusinessLabel = {
	license: 'viewCompanyInfo.licenseNum',
	tax: 'onboarding.companyRegNum',
	gst: 'viewCompanyInfo.gst',
	other: 'viewCompanyInfo.other',
}

const BusinessBlock = ({ isMobile, t, certDetails }) => {
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [values, setValues] = useState(certDetails)
	const dispatch = useDispatch()

	return (
		<>
			<Modal
				forceCloseviaButton
				size="large"
				show={showDeleteModal}
				heading={t('onboarding.deleteBusinessDetails')}
				body={<div>{t('onboarding.deleteBusinessDetailsNote')}</div>}
				closelabel={t('common.cancel')}
				confirmlabel={t('common.yes')}
				onClose={() => setShowDeleteModal(false)}
				onConfirm={() => {
					setShowDeleteModal(false)
					dispatch(
						AdminDuc.creators.deleteSingleCertificate(
							values.id,
							t('toast.deleteSuccess')
						)
					)
				}}
			/>
			<Modal
				forceCloseviaButton
				size="large"
				show={showModal}
				heading={t('onboarding.updateBusinessDetails')}
				body={
					<EditBusiness
						edit
						certDetails={values}
						onChange={_values => {
							setValues(_values)
						}}
					/>
				}
				closelabel={t('common.close')}
				confirmlabel={t('common.update')}
				onClose={() => setShowModal(false)}
				isDisabled={
					!values.certNum ||
					!values.certType ||
					values.files.length <= 0
				}
				onConfirm={() => {
					setShowModal(false)
					const payload = {
						id: values.id,
						number: values.certNum,
						files: values.files,
					}
					dispatch(
						AdminDuc.creators.updateSingleCertificate(
							payload,
							values.id,
							t('common.certUpdateSuccess')
						)
					)
				}}
			/>
			<Box row={!isMobile} padding="20px">
				<Card
					style={{
						padding: '20px',
						position: 'relative',
						overflow: 'visible',
						boxShadow: ' 0px 6px 16px #00000014',
					}}
					textAlign="left"
				>
					<SmallText
						style={{
							position: 'absolute',
							fontSize: theme.fontSize.m,
							color: theme.color.accent2,
							top: '-10px',
							left: '20px',
							display: 'inline-block',
							backgroundColor: theme.color.white,
							padding: '0 8px',
						}}
					>
						{t(BusinessLabel[getIn(certDetails, ['certType'])])}
					</SmallText>
					<Box row justifyContent="flex-end">
						<ButtonWithNoBorder
							label={t('common.delete')}
							action
							rounded
							customIcon={
								<ButtonIconWrapper lightBG>
									<Icon glyph={DeleteIcon} />
								</ButtonIconWrapper>
							}
							onClick={() => {
								dispatch(
									WebTourDuc.creators.setActiveTourModule('')
								)
								setShowDeleteModal(true)
							}}
						/>
						<Spacer size={16} />

						<ButtonWithNoBorder
							label={t('common.edit')}
							action
							rounded
							customIcon={
								<ButtonIconWrapper lightBG>
									<Icon glyph={EditIcon} />
								</ButtonIconWrapper>
							}
							onClick={() => {
								dispatch(
									WebTourDuc.creators.setActiveTourModule('')
								)
								setShowModal(true)
							}}
						/>
					</Box>
					<Box row style={{ width: '100%' }}>
						<Box style={{ width: '50%' }}>
							<KeyValueSegment
								labelWidth="50%"
								label={t('onboarding.registrtationNumber')}
								value={getIn(certDetails, ['certNum'])}
							/>
						</Box>
						<Box style={{ width: '50%' }}>
							<FileLists
								documents={certDetails.files || []}
								isReadOnly
							/>
						</Box>
					</Box>
				</Card>
			</Box>
		</>
	)
}

const Business = ({ business, t }) => {
	const [showModal, setShowModal] = useState(false)
	const certDetails = {
		certNum: '',
		certType: '',
		files: [],
	}
	const [values, setValues] = useState(certDetails)
	const dispatch = useDispatch()
	const { isMobile } = useSelector(AppDuc.selectors.detection)

	return (
		<>
			<Modal
				forceCloseviaButton
				size="large"
				show={showModal}
				heading={t('onboarding.addBusinessDetails')}
				body={
					<EditBusiness
						certDetails={values}
						onChange={_values => {
							setValues(_values)
						}}
					/>
				}
				isDisabled={
					!values.certNum ||
					!values.certType ||
					values.files.length <= 0
				}
				closelabel={t('common.close')}
				confirmlabel={t('common.add')}
				onClose={() => setShowModal(false)}
				onConfirm={() => {
					setShowModal(false)
					const _values = {
						type: values.certType,
						number: values.certNum,
						files: values.files,
					}
					dispatch(
						AdminDuc.creators.addNewCertificate(
							_values,
							t('common.certCreateSuccess')
						)
					)
				}}
			/>
			<Box row justifyContent="flex-end" padding="20px 20px 0px 0px">
				<ButtonWithNoBorder
					label={t('onboarding.addNew')}
					action
					rounded
					customIcon={
						<ButtonIconWrapper lightBG>
							<Icon glyph={EditIcon} />
						</ButtonIconWrapper>
					}
					onClick={() => {
						dispatch(WebTourDuc.creators.setActiveTourModule(''))
						setShowModal(true)
					}}
				/>
			</Box>
			{business.length ? (
				business.map(certificates => {
					const _certificates = {
						id: getIn(certificates, ['id']) || '',
						certNum: getIn(certificates, ['number']) || '',
						certType: getIn(certificates, ['type']) || '',
						files: getIn(certificates, ['files']) || [],
					}

					return (
						<BusinessBlock
							key={_certificates.id}
							isMobile={isMobile}
							t={t}
							certDetails={_certificates}
						/>
					)
				})
			) : (
				<Box style={{ minHeight: 300 }}>
					<ErrorBlock
						hideButton
						status="empty"
						message={t('common.errorBlockMessage')}
					/>
				</Box>
			)}
		</>
	)
}

export default Business
