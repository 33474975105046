import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Stepper } from 'react-form-stepper'
import { isEmptyObject } from 'core-app/utils/helpers'
import { Label } from 'ui-lib/components/Label'
import { H2 } from 'ui-lib/components/Typography'
import { Button } from 'ui-lib/components/Button'
import { useTranslation } from 'react-i18next'
import { getIn } from 'timm'
import { Input } from 'ui-lib/components/Input'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import theme from 'ui-lib/utils/base-theme'
import { SelectSearch } from 'ui-lib/components/Select'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { OnboardDuc } from 'core-app/modules/Onboard/duc'
import { getStates, getCountries } from 'country-state-picker'

export const MandatoryLabel = () => {
	return (
		<Label
			style={{
				padding: 2,
				borderRadius: 4,
				marginLeft: 6,
				font: '14px Avenir',
				letterSpacing: 0,
				color: '#C74949',
				opacity: 1,
			}}
		>
			*
		</Label>
	)
}

const labelStyles = {
	color: '#555454',
}

export const stepStyles = {
	activeBgColor: '#fff',
	activeTextColor: '#68C9A6',
	inactiveBgColor: '#7D8AC7',
	inactiveTextColor: '#1D285D',
	completedBgColor: '#68C9A6',
	completedTextColor: '#FFFFFF',
}

export const connectorStyles = {
	completedColor: '#68C9A6',
	disabledColor: '#7D8AC7',
	activeColor: '#68C9A6',
}

const AddressBlock = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const countries = getCountries()
	const companyInfo = useSelector(AuthDuc.selectors.getCurrentOrganization)
	const [countryNotChanged, setCountryNotChanged] = useState(true)

	const currentCountry =
		getIn(companyInfo, ['primaryAddress', 'country']) || ''

	const countryList = []
	// eslint-disable-next-line array-callback-return
	countries.map(value => {
		if (value.code !== 'gg') {
			countryList.push({
				name: value.code,
				label: value.name,
			})
		}
	})

	const defaultCountry = countryList.filter(
		_country => _country.label === currentCountry
	)

	const countryCode = !isEmptyObject(defaultCountry)
		? getIn(defaultCountry, [0, 'name']) || ''
		: ''
	const [stateList, setStateList] = useState([])
	const getStateCode = code => {
		const _stateList = getStates(code)
		if (_stateList && _stateList.length > 0) {
			const stateDataList = _stateList.map(state => {
				return {
					name: state,
					label: state,
				}
			})
			setStateList(stateDataList)
		} else {
			setStateList([{ name: '', label: '' }])
		}
	}

	const validationSchema = yup.object().shape({
		line1: yup.string().required(),
		line2: yup.string(),
		city: yup.string().required(),
		state: yup.string().required(),
		country: yup.string().required(),
		postalCode: yup.string().required(),
	})

	const {
		values,
		handleSubmit,
		handleChange,
		handleBlur,
		setFieldValue,
		touched,
		errors,
		isSubmitting,
	} = useFormik({
		initialValues: {
			line1: '',
			line2: '',
			city: '',
			country: countryCode || '',
			state: '',
			postalCode: '',
		},
		enableReinitialize: true,
		validationSchema,
		onSubmit: (_values, { setSubmitting }) => {
			if (countryNotChanged) {
				// eslint-disable-next-line no-param-reassign
				_values.country = getIn(defaultCountry, [0, 'label'])
			}
			dispatch(
				OnboardDuc.creators.updateAddress(_values, {
					setSubmitting,
				})
			)
		},
	})

	useEffect(() => {
		if (countryCode) {
			setFieldValue('country', getIn(defaultCountry, [0, 'name']))
			if (
				getIn(companyInfo, ['primaryAddress', 'country']) &&
				getIn(companyInfo, ['primaryAddress', 'state'])
			) {
				setFieldValue(
					'state',
					getIn(companyInfo, ['primaryAddress', 'state'])
				)
			}
			getStateCode(countryCode)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countryCode, getIn(companyInfo, ['primaryAddress', 'country'])])

	const disableCTA = !isEmptyObject(errors) || !values.line1

	return (
		<Box
			style={{
				padding: '80px 0 40px',
				background: ' #1D285D',
				height: '100vh',
				overflow: 'scroll',
			}}
			center
		>
			<Spacer size={20} />
			<Box width="650px">
				<Stepper
					steps={[{}, {}]}
					activeStep={0}
					connectorStateColors
					styleConfig={stepStyles}
					connectorStyleConfig={connectorStyles}
				/>
			</Box>
			<H2
				style={{
					fontSize: 40,
					color: theme.color.white,
					lineHeight: '52px',
					fontWeight: 'bold',
				}}
			>
				Enter your company address
			</H2>
			<Spacer size={12} />
			<Box
				style={{
					width: '770px',
					height: 'fit-content',
					background: theme.color.white,
					borderRadius: '10px',
					boxShadow: '0px 3px 6px #00000029',
				}}
				padding={25}
			>
				<Box row justifyContent="flex-end">
					<MandatoryLabel />
					<Label
						style={{
							marginLeft: 12,
							color: '#ACB7CD',
							fontSize: 14,
						}}
					>
						Mandatory
					</Label>
				</Box>
				<form onSubmit={handleSubmit}>
					<Box
						style={{
							width: '100%',
						}}
						alignItems="center"
					>
						<Box width={410}>
							<Box row alignItems="baseline" margin="0 0 8px">
								<Label style={labelStyles}>
									Address Line 1
								</Label>
								<MandatoryLabel />
							</Box>
							<Input
								value={values.line1}
								name="line1"
								type="text"
								onChange={handleChange}
								onBlur={handleBlur}
								error={touched.line1 && errors.line1}
							/>
						</Box>
						<Box width={410}>
							<Label style={labelStyles}>Address Line 2</Label>
							<Input
								value={values.line2}
								name="line2"
								type="text"
								onChange={handleChange}
								onBlur={handleBlur}
								error={touched.line2 && errors.line2}
							/>
						</Box>
						<Box width={410}>
							<Box row alignItems="baseline" margin="0 0 8px">
								<Label style={labelStyles}>City</Label>
								<MandatoryLabel />
							</Box>
							<Input
								value={values.city}
								name="city"
								type="text"
								onChange={handleChange}
								onBlur={handleBlur}
								error={touched.city && errors.city}
							/>
						</Box>
						<Box width={410}>
							<Box row alignItems="baseline" margin="0 0 8px">
								<Label style={labelStyles}>State</Label>
								<MandatoryLabel />
							</Box>
							<SelectSearch
								required
								name="state"
								placeholder={t('onboarding.statePlaceHolder')}
								value={
									getIn(companyInfo, [
										'primaryAddress',
										'country',
									]) &&
									getIn(companyInfo, [
										'primaryAddress',
										'state',
									])
										? getIn(companyInfo, [
												'primaryAddress',
												'state',
										  ])
										: ''
								}
								options={stateList}
								onChange={value => {
									if (!isEmptyObject(value)) {
										setFieldValue('state', value.name)
									} else {
										setFieldValue('state', '')
									}
								}}
								labelKey="label"
								valueKey="name"
								defaultLabel={t('common.selectDefaultLabel')}
								returnOnlyValue
								style={{
									width: '100%',
									border: '1px solid #263b97',
									borderRadius: '5px',
								}}
							/>
						</Box>
						<Box width={410}>
							<Box row alignItems="baseline" margin="0 0 8px">
								<Label style={labelStyles}>Country</Label>
								<MandatoryLabel />
							</Box>
							<SelectSearch
								required
								name="country"
								placeholder={t('onboarding.countryPlaceHolder')}
								value={values.country}
								key={countryCode}
								options={countryList}
								onChange={value => {
									if (!isEmptyObject(value)) {
										setFieldValue('country', value.label)
										setFieldValue('state', '')
										getStateCode(value.name)
										setCountryNotChanged(false)
									} else {
										setFieldValue('country', '')
										setFieldValue('state', '')
										getStateCode('')
									}
								}}
								labelKey="label"
								valueKey="name"
								defaultLabel={t('common.selectDefaultLabel')}
								returnOnlyValue
								style={{
									width: '100%',
									border: '1px solid #263b97',
									borderRadius: '5px',
								}}
							/>
						</Box>
						<Box width={410}>
							<Box row alignItems="baseline" margin="0 0 8px">
								<Label style={labelStyles}>Postal Code</Label>
								<MandatoryLabel />
							</Box>

							<Input
								value={values.postalCode}
								name="postalCode"
								type="text"
								onChange={handleChange}
								onBlur={handleBlur}
								error={touched.postalCode && errors.postalCode}
							/>
						</Box>
						<Box width={410}>
							<Button
								disabled={disableCTA}
								isLoading={isSubmitting}
								type="submit"
								primary
								label="Update Address"
								style={{
									width: 410,
								}}
							/>
						</Box>
					</Box>
				</form>
			</Box>
			<Spacer size={20} />
		</Box>
	)
}

export { AddressBlock }
