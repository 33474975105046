import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { CheckBox } from 'ui-lib/components/Checkbox'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { AdminDuc } from 'core-app/modules/Admin/duc'
import { Title } from 'core-app/shared/components/Title'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { Label } from 'ui-lib/components/Label'
import { Button } from 'ui-lib/components/Button'
import { Input } from 'ui-lib/components/Input'
import { RadioInputGroup } from 'ui-lib/components/Radio'
import backArrow from 'ui-lib/icons/arrow_back.svg'
import { getSupplyChainModelsForRadioButton } from 'core-app/modules/Admin/config'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import theme from 'ui-lib/utils/base-theme'
import { getRandomNumber } from 'ui-lib/utils/helpers'
import { WebTour } from '../../../WebTour'
import { WebTourDuc } from '../../../WebTour/duc'

const OtherSettings = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const actorArray = useSelector(AuthDuc.selectors.getActor) || []
	const actor = actorArray && actorArray.length > 0 ? actorArray[0] : ''
	const orgDetails = useSelector(AdminDuc.selectors.getCompanyInfo)
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)

	const [key, setKey] = useState(getRandomNumber())
	const [graderValue, setGraderValue] = useState(null)
	const [virtualInventory, setVirtualInventory] = useState(null)
	const [supplyChainModel, setSupplyChainModel] = useState(null)
	const [averageBunchWeight, setAverageBunchWeight] = useState(0)

	const isNotSupplyChainFlow =
		actor?.includes('palmoil_plantation') ||
		actor?.includes('palmoil_ffbdealer') ||
		actor?.includes('palmoil_ffbsubdealer')

	useEffect(() => {
		if (tourModule === 'otherSettings') {
			dispatch(WebTourDuc.creators.setActiveTourModule('otherSettings'))
		}
	}, [tourModule, dispatch])

	useEffect(() => {
		if (orgDetails?.id) {
			if (actor?.includes('palmoil_plantation')) {
				setGraderValue(!!orgDetails?.meta?.plantation?.enableGrading)
			}
			setVirtualInventory(orgDetails?.meta?.enforceSupplyChainModel)
			setSupplyChainModel(orgDetails?.meta?.supplyChainModel)
			setAverageBunchWeight(
				orgDetails?.meta?.mill?.averageBunchWeight || 0
			)
			setKey(getRandomNumber())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orgDetails, orgDetails?.meta])

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.admin'),
			name: 'admin',
			isActive: true,
		},
		{
			label: `${t('common.view')} ${t('adminHome.otherSettings')}`,
			name: 'view-other-settings',
			isActive: false,
		},
	]

	/** Handlers */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'admin') {
			dispatch(MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN))
		}
	}

	return (
		<>
			<Box
				id="input-otherSettings"
				padding={8}
				width="100%"
				height="100%"
			>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
				<Box row alignItems="center">
					<IconWrapper
						size={35}
						style={{ color: theme.color.blue5, cursor: 'pointer' }}
						onClick={() => {
							dispatch(
								MainRouteDuc.creators.switchPage(
									MainRouteDuc.types.ADMIN
								)
							)
						}}
					>
						<Icon glyph={backArrow} />
					</IconWrapper>
					<Box style={{ marginLeft: '25px' }}>
						<Title title={t('adminHome.otherSettings')} />
					</Box>
				</Box>
				<Spacer size={20} />
				{actor?.includes('palmoil_plantation') && (
					<Box
						style={{
							height: 65,
							display: 'flex',
							paddingLeft: 25,
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'space-between',
							backgroundColor: theme.color.white,
							border: `1px solid ${theme.color.grey23}`,
						}}
					>
						<Box>{t('adminHome.enableCheckerGrading')}</Box>
						<Box style={{ paddingRight: '25px' }}>
							<CheckBox
								key={graderValue}
								hideError
								name="graderCheck"
								checked={graderValue}
								returnKeyValue
								onChange={data => {
									dispatch(
										AdminDuc.creators.saveOtherSettings(
											{ graderCheck: data.graderCheck },
											actor,
											orgDetails,
											t('common.updateSuccess')
										)
									)
								}}
								showToggle
							/>
						</Box>
					</Box>
				)}
				{!isNotSupplyChainFlow && (
					<Box
						style={{
							height: 65,
							display: 'flex',
							paddingLeft: 25,
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'space-between',
							backgroundColor: theme.color.white,
							border: `1px solid ${theme.color.grey23}`,
							marginTop: 20,
						}}
					>
						<Box>{t('adminHome.enforceSupplyChainModel')}</Box>
						<Box style={{ paddingRight: '25px' }}>
							<CheckBox
								key={virtualInventory}
								hideError
								name="virtualInventory"
								checked={virtualInventory}
								returnKeyValue
								onChange={data => {
									dispatch(
										AdminDuc.creators.saveOtherSettings(
											{
												virtualInventory:
													data.virtualInventory,
											},
											actor,
											orgDetails,
											t('common.updateSuccess')
										)
									)
								}}
								showToggle
							/>
						</Box>
					</Box>
				)}
				{!isNotSupplyChainFlow && (
					<Box
						style={{
							height: 65,
							display: 'flex',
							paddingLeft: 25,
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'space-between',
							backgroundColor: theme.color.white,
							border: `1px solid ${theme.color.grey23}`,
							marginTop: 20,
						}}
					>
						<Box>{t('assetsHome.scModel')}</Box>
						<Box
							style={{ paddingRight: '25px', paddingTop: '25px' }}
						>
							<RadioInputGroup
								name="supplyChainModel"
								selected={supplyChainModel}
								direction="row"
								options={getSupplyChainModelsForRadioButton(t)}
								onChange={e => {
									dispatch(
										AdminDuc.creators.saveOtherSettings(
											{
												supplyChainModel:
													e.target.value,
											},
											actor,
											orgDetails,
											t('common.updateSuccess')
										)
									)
								}}
							/>
						</Box>
					</Box>
				)}
				{actor?.includes('palmoil_mill') && (
					<Box
						style={{
							paddingLeft: 25,
							margin: '20px 0px 4px',
							backgroundColor: theme.color.white,
							border: `1px solid ${theme.color.grey23}`,
						}}
					>
						<Label required style={{ padding: '15px 0' }}>
							{t('adminHome.averageBunchWeight')}
						</Label>
						<Box row>
							<Input
								key={key}
								value={averageBunchWeight}
								name="averageBunchWeight"
								type="number"
								onChange={e => {
									setAverageBunchWeight(
										e.target.valueAsNumber
									)
								}}
							/>
							<Box
								style={{
									maxWidth: 150,
									marginLeft: 12,
								}}
							>
								<Button
									label={t('common.submit')}
									primary
									disabled={isNaN(averageBunchWeight)}
									onClick={() => {
										dispatch(
											AdminDuc.creators.updateAverageBunchWeight(
												averageBunchWeight,
												orgDetails
											)
										)
									}}
								/>
							</Box>
						</Box>
					</Box>
				)}
			</Box>
			{tourModule === 'otherSettings' && <WebTour />}
		</>
	)
}

export { OtherSettings }
