import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { MillDuc } from 'core-app/modules/Mill/duc'
import { WebTourDuc } from 'core-app/modules/WebTour/duc'
import { initiateSort } from 'core-app/shared/helpers'
import { Title } from 'core-app/shared/components/Title'
import { getProductionTableColumnConfig as getColumnConfig } from 'core-app/modules/Mill/components/Columns'
import { StorageInventoryModal } from 'core-app/modules/Refinery/components/StorageInventoryModal'
// eslint-disable-next-line import/extensions
import ProductionInfoImage from 'core/src/assets/production.jpg'
import { Label } from 'ui-lib/components/Typography'
import { ButtonIconWrapper, Button } from 'ui-lib/components/Button'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Tabs, DefaultTabIndicator } from 'ui-lib/components/Tabs'
import { Table } from 'ui-lib/components/Table'
import { ErrorBlock } from 'ui-lib/components/ErrorBlocks'
import { TransparentBgSpinner } from 'ui-lib/components/Spinner/TransparentBgSpinner'
import { Box } from 'ui-lib/utils/Box'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import { Icon } from 'ui-lib/icons/components/Icon'
import ProductionIcon from 'ui-lib/icons/production.svg'
import StorageTankIcon from 'ui-lib/icons/storageTank.svg'
import { Modal } from 'ui-lib/components/Modal'
import { applyMediaQueriesStr } from 'ui-lib/utils/helpers'

const CTAWrapper = styled(Box)(p => ({
	padding: 20,
	minHeight: 62,
	background: p.theme.color.white,
	borderBottomLeftRadius: 4,
	borderBottomRightRadius: 4,
	color: p.theme.color.primary,
	border: `1px solid ${p.theme.color.grey4}`,
}))

const Illustration = styled.img`
	width: 100%;
	${applyMediaQueriesStr(['mobile'], 'width: 60%;top:10%')}
`

const getTabComponents = ({
	type,
	sortsMap,
	rows,
	allProducts,
	onChange,
	orgDetails,
	loadingStatus,
	t,
}) => ({ name, title }) => () => (
	<Box style={{ overflow: 'auto', position: 'relative' }}>
		{loadingStatus && <TransparentBgSpinner />}
		<Table
			noRoundedBorder
			columnConfig={getColumnConfig({
				type,
				docType: name,
				subModuleType: name,
				sortsMap: sortsMap[name] || {},
				onChange,
				orgDetails,
				allProducts,
				t,
			})}
			rowData={rows[name] || []}
		/>
		{!(rows[name] || []).length && (
			<Box style={{ minHeight: 300 }}>
				<ErrorBlock
					hideButton
					status="empty"
					message={t('common.errorBlockMessage')}
				/>
			</Box>
		)}
		<CTAWrapper
			onClick={() =>
				onChange('open_listing', {
					type,
					docType: name,
				})
			}
		>
			<Label color="inherit" bold small link as="a">
				{title === 'Input Batch'
					? t('production.viewAllInputBatchDetails').toUpperCase()
					: t('production.viewAllProducedDetails').toUpperCase()}
			</Label>
		</CTAWrapper>
	</Box>
)

const Production = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const [showModal, setShowModal] = useState(false)
	const [showKnowMoreModal, setShowKnowMoreModal] = useState(false)

	const storageTanks = useSelector(MillDuc.selectors.getStorageTanksList)
	const orgDetails = useSelector(AuthDuc.selectors.getAvailableOrgs)
	const location = useSelector(MillDuc.selectors.location)
	const { payload = {} } = location

	const { submodule, rootModule } = payload
	const subModuleType =
		submodule === 'transforming' ? 'sourced-batch' : 'produced-batch'
	const activeTypes = useSelector(MillDuc.selectors.getProductionActiveTabs)
	const activeSorts = useSelector(MillDuc.selectors.getProductionActiveSorts)

	const activeIncomingDocuments = useSelector(
		MillDuc.selectors.getProductionDocuments
	)
	const activeTimeOffset = useSelector(
		MillDuc.selectors.getProductionActiveTimeOffset
	)
	const loading = useSelector(MillDuc.selectors.getProductionLoadingStates)
	const allProducts = useSelector(AuthDuc.selectors.getProducts)
	const tourStatus = useSelector(WebTourDuc.selectors.tourStatus)

	useEffect(() => {
		const showPopupCookie = 'showMillProductionPopup=true'

		const shouldShowPopup = document.cookie.indexOf(showPopupCookie) === -1

		if (!tourStatus && shouldShowPopup && rootModule === 'production') {
			setShowKnowMoreModal(true)
			// Set cookie for max-age of 86400 seconds (24hrs)
			const expires = new Date(Date.now() + 86400 * 1000).toUTCString()
			document.cookie = `${showPopupCookie}; expires=${expires}; path=/;`
		}
	}, [rootModule, tourStatus])

	const getTabs = useCallback(() => {
		return activeTypes.map(({ code, title }) => ({
			name: code,
			title: t(title),
			IndicatorComponent:
				parseInt(code, 10) > 0 &&
				(({ isActive }) => (
					<DefaultTabIndicator active={isActive}>
						{parseInt(code, 10)}
					</DefaultTabIndicator>
				)),
		}))
	}, [activeTypes, t])

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.production'),
			name: 'production',
			isActive: false,
		},
	]

	/** Handlers to react on actions */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
	}

	const handleOnChange = useCallback(
		(actionType, meta = {}) => {
			switch (actionType) {
				case 'initiate_sort': {
					initiateSort(
						dispatch,
						meta,
						MillDuc.creators.fetchProductionDocuments,
						location,
						meta.type
					)
					break
				}
				case 'open_document': {
					if (meta.row) {
						let documentReference
						let docType
						if (meta.docType === 'transforming') {
							documentReference = meta.row.id
							docType = 'sourced-batch'
						} else {
							documentReference = meta.row.meta.traceGroupID
							docType = 'produced-batch'
						}
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.MILL$WDOCREFERENCE,
								{
									rootModule: docType,
									action: 'view',
									documentReference,
								}
							)
						)
					}
					break
				}
				case 'open_plot_output_workflow': {
					dispatch(WebTourDuc.creators.setActiveTourModule(''))

					if (meta.row) {
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.MILL$WACTION,
								{
									rootModule: 'production-plot-output',
									action: 'create',
								},
								{
									documentReference: meta.row.id,
									scModel: meta.row.meta.supplyChainModel,
								}
							)
						)
					}
					break
				}
				case 'delete_tracegroup': {
					if (meta.docID) {
						dispatch(
							MillDuc.creators.deleteTraceGroupID(meta.docID)
						)
					}
					break
				}

				case 'open_listing': {
					const { type, docType } = meta || {}
					let _subModuleType
					if (docType === 'transforming') {
						_subModuleType = 'sourced-batch'
					} else {
						_subModuleType = 'produced-batch'
					}

					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.MILL$LISTING,
							{
								rootModule: type,
								submodule: _subModuleType,
							}
						)
					)
					break
				}

				default:
					break
			}
		},
		[dispatch, location]
	)

	const note = `${t('common.showingForLast')} ${activeTimeOffset ||
		'few'} ${t('common.days')}`

	return (
		<>
			<StorageInventoryModal
				show={showModal}
				heading={t('production.storageInventory')}
				data={storageTanks}
				onClose={() => {
					setShowModal(false)
				}}
				size="800px"
				height="940px"
				overflow="auto"
			/>
			<Modal
				closeable
				forceCloseviaButton
				hideButtons
				size="large"
				show={showKnowMoreModal}
				body={
					<Box>
						<Illustration
							src={ProductionInfoImage}
							alt="info image"
						/>
					</Box>
				}
				onClose={() => {
					setShowKnowMoreModal(false)
				}}
			/>
			<Box padding={8} width="100%" height="100%">
				<Box style={{ padding: '0 5' }}>
					<Breadcrumb
						links={breadCrumbs}
						onClick={target => handleBreadCrumbClick(target)}
					/>
				</Box>

				<Box row justifyContent="space-between" alignItems="baseline">
					<Box alignItems="flex-start">
						<Box row alignItems="center">
							<Title
								note={note}
								title={t('production.title')}
								icon={ProductionIcon}
							/>
						</Box>
					</Box>

					<Box row justifyContent="flex-end">
						<Box
							padding={2}
							margin="0 12px 0 0"
							title={t('production.viewStorageInventory')}
						>
							<Button
								rounded
								customIcon={
									<Icon
										glyph={StorageTankIcon}
										style={{
											height: 24,
											width: 24,
										}}
									/>
								}
								onClick={() => {
									setShowModal(true)
								}}
								extendStyles={{
									border: '1px solid rgb(63,86,196)',
									borderRadius: 4,
								}}
							/>
						</Box>
						<Box style={{ padding: 2 }} id="plotInputMillCreate">
							<Button
								label={t('production.createInputBatch')}
								rounded
								onClick={() =>
									dispatch(
										MainRouteDuc.creators.switchPage(
											MainRouteDuc.types.MILL$WACTION,
											{
												rootModule:
													'production-plot-input',
												action: 'create',
											}
										)
									)
								}
								extendStyles={{
									paddingTop: 8,
									paddingBottom: 8,
								}}
							/>
						</Box>
						<Box style={{ padding: 2 }}>
							<Button
								label={t('production.inventory')}
								rounded
								onClick={() =>
									dispatch(
										MainRouteDuc.creators.switchPage(
											MainRouteDuc.types.MILL$WACTION,
											{
												rootModule: 'inventory',
												action: 'create',
											}
										)
									)
								}
								extendStyles={{
									paddingTop: 8,
									paddingBottom: 8,
								}}
							/>
						</Box>
					</Box>
				</Box>
				<Tabs
					tabs={getTabs()}
					tabComponentsMap={getTabComponents({
						type: 'production',
						subModuleType,
						sortsMap: activeSorts,
						rows: activeIncomingDocuments,
						onChange: handleOnChange,
						orgDetails,
						allProducts,
						loadingStatus: loading,
						t,
					})}
				/>
				<Box padding="25px 2px" style={{ maxWidth: 150 }}>
					<Button
						label={t('common.back')}
						rounded
						customIcon={
							<ButtonIconWrapper lightBG>
								<Icon glyph={LeftArrowIcon} />
							</ButtonIconWrapper>
						}
						onClick={() =>
							dispatch(
								MainRouteDuc.creators.switchPage(
									MainRouteDuc.types.DASHBOARD
								)
							)
						}
					/>
				</Box>
			</Box>
		</>
	)
}

export { Production }
