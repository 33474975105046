import { TraderDuc } from 'core-app/modules/Trader/duc'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import saga from 'core-app/modules/Trader/TraderSaga'

export default async (dispatch, getState, { action, extra }) => {
	const { type, customMeta = {} } = action

	/** if someone specifically wants to skip thunk fetch again, this check helps */
	const { skipRouteThunk } = customMeta
	if (skipRouteThunk) return

	// Inject the saga here. If it wasn't already
	extra.getSagaInjectors().injectSaga('trader', { saga })
	dispatch(AuthDuc.creators.fetchActor())
	const isMainDashboard = MainRouteDuc.types.TRADER === type

	let activeModule = ''

	if (isMainDashboard) {
		// load the dashboard of the core module
		activeModule = 'dashboard'
		dispatch(TraderDuc.creators.fetchInsights())
	}
	dispatch(TraderDuc.creators.setActiveModule(activeModule))
}
