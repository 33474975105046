import React from 'react'
import { getIn } from 'timm'
import { TABLE_ALIAS } from 'core-app/modules/Products/config'
import { Label, TableTitle } from 'ui-lib/components/Typography'
import { Box } from 'ui-lib/utils/Box'
import { ButtonWithNoBorder } from 'ui-lib/components/Button'

export const columnConfig = ({ onChange, t, actor }) => {
	return [
		{
			property: 'productName',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.productName)}</TableTitle>
				</Box>
			),
			render: datum => {
				const productName = getIn(datum, ['product', 'name'])
				const productCode = getIn(datum, ['product', 'code'])

				return (
					<Label as="span">
						{`${productName}     (${productCode})` || '---'}
					</Label>
				)
			},
		},
		{
			property: 'hsn',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.hsn)}</TableTitle>
				</Box>
			),
			render: datum => {
				const hsn = getIn(datum, ['product', 'hsnsac'])

				return <Label as="span">{hsn || '---'}</Label>
			},
		},
		{
			property: 'availableQty',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.availableQty)}</TableTitle>
				</Box>
			),
			render: datum => {
				const uom = actor.includes('palmoil_plantation')
					? 'Nos'
					: getIn(datum, ['product', 'uom']) || ''
				const availableQty = (
					getIn(datum, ['product', 'availableQty']) || 0
				)
					.toString()
					.match(/^-?\d+(?:\.\d{0,3})?/)[0]

				return <Label as="span">{`${availableQty} ${uom}`}</Label>
			},
		},
		{
			property: 'lcv',
			header: (
				<Box style={{ cursor: 'pointer' }}>
					<TableTitle>{t('products.carbonValue')}</TableTitle>
				</Box>
			),
			render: datum => {
				const lcv = getIn(datum, ['meta', 'lcv'])

				return <Label as="span">{`${lcv} KgCO2e/ton`}</Label>
			},
		},
		{
			property: 'callToAction',
			render: datum => {
				return (
					<ButtonWithNoBorder
						label={t('common.edit').toUpperCase()}
						extendStyles={{
							minWidth: '62px',
						}}
						onClick={() => {
							onChange(
								{
									row: datum,
								},
								true
							)
						}}
					/>
				)
			},
		},
	]
}
