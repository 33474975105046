import Duck from 'extensible-duck'
import { setIn, getIn } from 'timm'

const initialState = {
	activeModule: '',
	modules: {
		organisationList: {
			list: [],
		},
	},
}

export const MessagesDuc = new Duck({
	namespace: 'messages',
	store: 'global',
	types: [
		'SET_ACTIVE_MODULE',
		'FETCH_ORGANISATION_LIST',
		'FETCH_ORGANISATION_PAGINATED_LIST',
		'FETCH_ORG_INFO',
		'SET_ORG_PAGINATED_LIST',
		'SET_ORG_INFO',
		'FETCH_TRANSACTION_LIST',
		'FETCH_UPDATE_UNREAD_LIST',
		'FETCH_SEND_MESSAGE',
		'SET_ORG_LIST',
		'SET_TRANS_LIST',
		'SET_EVENT_LIST',
		'SET_DOCUMENT_DETAILS',
		'FETCH_EVENT_LIST',
		'FETCH_CHAT_SEARCH',
		'FETCH_VIEW_RFQ_MSG',
		'GET_CHAT_TOKEN',
		'SET_CHAT_TOKEN',
		'GET_CHAT_THREAD_ID',
		'GET_CHAT_THREAD_BY_TRADE_ID',
		'GET_CHAT_COUNT_ONLY',
		'SET_CHAT_THREADS',
		'SET_DOCTYPE_LIST',
		'SET_DOCUMENT_NUMBER',
		'SET_CONTRACT_CHAT_IDENTIFIER',
		'SET_DOCUMENT_CHAT_LIST',
		'SET_CHAT_UNREAD_COUNT',
		'SET_SEARCH',
	],
	initialState,
	reducer: (state, action, duck) => {
		switch (action.type) {
			case duck.types.SET_ACTIVE_MODULE: {
				return setIn(state, ['activeModule'], action.module)
			}
			case duck.types.SET_ORG_LIST: {
				const { data } = action

				return setIn(
					state,
					['modules', 'organisationList', 'list'],
					data
				)
			}
			case duck.types.SET_ORG_INFO: {
				const { data } = action

				return setIn(state, ['modules', 'orgInfo'], data)
			}
			case duck.types.SET_ORG_PAGINATED_LIST: {
				const { data } = action
				const currentOrgList = getIn(state, [
					'modules',
					'organisationPaginatedList',
					'list',
				])

				const finalList = currentOrgList
					? currentOrgList.concat(data || [])
					: data

				return setIn(
					state,
					['modules', 'organisationPaginatedList', 'list'],
					finalList
				)
			}
			case duck.types.SET_TRANS_LIST: {
				const { data } = action

				return setIn(
					state,
					['modules', 'transactionList', 'list'],
					data
				)
			}
			case duck.types.SET_EVENT_LIST: {
				const { data } = action

				return setIn(state, ['modules', 'EventList', 'list'], data)
			}
			case duck.types.SET_DOCUMENT_DETAILS: {
				const { data } = action

				return setIn(state, ['modules', 'document', 'details'], data)
			}
			case duck.types.SET_SEARCH: {
				const { data } = action

				return setIn(state, ['modules', 'searchList'], data)
			}
			case duck.types.SET_CHAT_TOKEN: {
				const { chatToken } = action

				return setIn(state, ['modules', 'chat', 'chatToken'], chatToken)
			}
			case duck.types.SET_CHAT_UNREAD_COUNT: {
				const { count } = action

				return setIn(
					state,
					['modules', 'chat', 'chatUnreadCount'],
					count
				)
			}
			case duck.types.SET_CHAT_THREADS: {
				const { chatThreads } = action

				return setIn(
					state,
					['modules', 'chat', 'chatThreads'],
					chatThreads
				)
			}
			case duck.types.SET_DOCTYPE_LIST: {
				const { docTypeList } = action

				return setIn(
					state,
					['modules', 'chat', 'docTypeList'],
					docTypeList
				)
			}
			case duck.types.SET_DOCUMENT_NUMBER: {
				const { documentNumberList } = action

				return setIn(
					state,
					['modules', 'chat', 'documentNumberList'],
					documentNumberList
				)
			}
			case duck.types.SET_CONTRACT_CHAT_IDENTIFIER: {
				const { identifier } = action

				return setIn(
					state,
					['modules', 'chat', 'contractChatIdentifier'],
					identifier
				)
			}
			case duck.types.SET_DOCUMENT_CHAT_LIST: {
				const { list } = action

				return setIn(
					state,
					['modules', 'chat', 'documentChatList'],
					list
				)
			}

			default:
				return state
		}
	},
	selectors: {
		auth: state => state.auth,
		location: state => state.location,
		activeModule: state =>
			getIn(state, ['messages', 'activeModule']) || 'Error',
		getOrganisationList: state =>
			getIn(state, ['messages', 'modules', 'organisationList', 'list']) ||
			{},
		getOrganisationPaginatedList: state =>
			getIn(state, [
				'messages',
				'modules',
				'organisationPaginatedList',
				'list',
			]) || {},
		getOrgInfo: state =>
			getIn(state, ['messages', 'modules', 'orgInfo']) || {},
		getTransactionList: state =>
			getIn(state, ['messages', 'modules', 'transactionList', 'list']) ||
			{},
		getEventList: state =>
			getIn(state, ['messages', 'modules', 'EventList', 'list']) || {},
		getDocumentdetails: state =>
			getIn(state, ['messages', 'modules', 'document', 'details']) || {},
		getsearchList: state =>
			getIn(state, ['messages', 'modules', 'searchList']) || {},
		getChatToken: state =>
			getIn(state, ['messages', 'modules', 'chat', 'chatToken']) || '',
		getChatThreadId: state =>
			getIn(state, ['messages', 'modules', 'chat', 'chatThreads']) || {},
		getDocTypeList: state =>
			getIn(state, ['messages', 'modules', 'chat', 'docTypeList']) || [],
		getDocumentNumberList: state =>
			getIn(state, [
				'messages',
				'modules',
				'chat',
				'documentNumberList',
			]) || [],
		getContractChatIdentifier: state =>
			getIn(state, [
				'messages',
				'modules',
				'chat',
				'contractChatIdentifier',
			]) || null,
		getDocumentChatList: state =>
			getIn(state, ['messages', 'modules', 'chat', 'documentChatList']) ||
			[],
		getChatUnreadCount: state =>
			getIn(state, ['messages', 'modules', 'chat', 'chatUnreadCount']) ||
			0,
	},
	creators: duck => ({
		setActiveModule: module => ({
			type: duck.types.SET_ACTIVE_MODULE,
			module,
		}),
		organisationListing: () => ({
			type: duck.types.FETCH_ORGANISATION_LIST,
		}),
		organisationPaginatedListing: (partnersList, partnersListString) => ({
			type: duck.types.FETCH_ORGANISATION_PAGINATED_LIST,
			partnersList,
			partnersListString,
		}),
		getOrgInfo: orgId => ({
			type: duck.types.FETCH_ORG_INFO,
			orgId,
		}),
		setOrgList: data => ({
			type: duck.types.SET_ORG_LIST,
			data,
		}),
		setOrgPaginatedList: data => ({
			type: duck.types.SET_ORG_PAGINATED_LIST,
			data,
		}),
		setOrgInfo: data => ({
			type: duck.types.SET_ORG_INFO,
			data,
		}),
		transactionListing: (id, orgList, startId, currentList) => ({
			type: duck.types.FETCH_TRANSACTION_LIST,
			id,
			orgList,
			startId,
			currentList,
		}),
		updateUnReadCount: (
			data,
			organisationListFull,
			transactionListFull
		) => ({
			type: duck.types.FETCH_UPDATE_UNREAD_LIST,
			data,
			organisationListFull,
			transactionListFull,
		}),
		sendMessageAction: (id, actionMsg) => ({
			type: duck.types.FETCH_SEND_MESSAGE,
			id,
			actionMsg,
		}),
		eventListing: id => ({
			type: duck.types.FETCH_EVENT_LIST,
			id,
		}),
		searchHandling: id => ({
			type: duck.types.FETCH_CHAT_SEARCH,
			id,
		}),
		viewDocumentFromMSG: (id, event) => ({
			type: duck.types.FETCH_VIEW_RFQ_MSG,
			id,
			event,
		}),
		setTransList: data => ({
			type: duck.types.SET_TRANS_LIST,
			data,
		}),
		setEventList: data => ({
			type: duck.types.SET_EVENT_LIST,
			data,
		}),
		setMSGDocumentDetails: data => ({
			type: duck.types.SET_DOCUMENT_DETAILS,
			data,
		}),
		setSearch: data => ({
			type: duck.types.SET_SEARCH,
			data,
		}),
		getChatToken: orgRole => ({
			type: duck.types.GET_CHAT_TOKEN,
			orgRole,
		}),
		setChatToken: chatToken => ({
			type: duck.types.SET_CHAT_TOKEN,
			chatToken,
		}),
		getChatThreadId: (tradeId, docType, documentId) => ({
			type: duck.types.GET_CHAT_THREAD_BY_TRADE_ID,
			tradeId,
			docType,
			documentId,
		}),
		chatCountCallOnly: chatThread => ({
			type: duck.types.GET_CHAT_COUNT_ONLY,
			chatThread,
		}),
		setChatThreads: chatThreads => ({
			type: duck.types.SET_CHAT_THREADS,
			chatThreads,
		}),
		setDocTypeList: docTypeList => ({
			type: duck.types.SET_DOCTYPE_LIST,
			docTypeList,
		}),
		setDocumentNumber: documentNumberList => ({
			type: duck.types.SET_DOCUMENT_NUMBER,
			documentNumberList,
		}),
		setContractChatIdentifier: (identifier = null) => ({
			type: duck.types.SET_CONTRACT_CHAT_IDENTIFIER,
			identifier,
		}),
		setDocumentChatList: list => ({
			type: duck.types.SET_DOCUMENT_CHAT_LIST,
			list,
		}),
		setChatUnreadCount: count => ({
			type: duck.types.SET_CHAT_UNREAD_COUNT,
			count,
		}),
	}),
})
