import React from 'react'
import { useSelector } from 'react-redux'
import { NotFoundBlock } from 'core-app/modules/App/components/Error'
import { ProductsDuc } from 'core-app/modules/Products/duc'
import { MainDashboard } from 'core-app/modules/Products/containers/MainDashboard'
import { ViewProduct } from 'core-app/modules/Products/containers/ViewProduct'

const modulesMap = {
	dashboard: MainDashboard,
	'view-product': ViewProduct,
}

const Products = () => {
	const activeModule = useSelector(ProductsDuc.selectors.activeModule)
	const Component = modulesMap[activeModule] || NotFoundBlock
	// if we are here, we have a submodule, route it to the module

	return <Component />
}

export default Products
