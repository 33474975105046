import React from 'react'
import { Box } from 'ui-lib/utils/Box'
import { useTranslation } from 'react-i18next'
import { P } from 'ui-lib/components/Typography'
import { MainRouteDuc } from 'core-app/routes/duc'
import { useDispatch, useSelector } from 'react-redux'
import { TracePolygonMaps } from 'ui-lib/components/TracePolygonMaps'
import { BoxShadowWrapper } from 'ui-lib/components/Card'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { TraceDuc } from '../duc'

const GenerateMap = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const geoData = useSelector(TraceDuc.selectors.getGeoData)

	/** Handlers to react on actions */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'product-trace') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.PRODUCT_TRACE
				)
			)
		}
	}

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.productTrace'),
			name: 'product-trace',
			isActive: true,
		},
		{
			label: t('breadcrumb.generateMap'),
			name: 'generate-map',
			isActive: false,
		},
	]

	const getTracePolygonMap = () => (
		<TracePolygonMaps
			height={800}
			shape={geoData.shape ? geoData.shape : {}}
			geoType={geoData.type ? geoData.type : ''}
		/>
	)

	const getMapNotAvailable = () => (
		<Box alignItems="center" justifyContent="center" padding={8}>
			<P>{t('productTrace.mapNotAvailable')}</P>
		</Box>
	)

	return (
		<>
			<Box padding={8} width="100%" height="100%">
				<Box style={{ padding: '0 5' }}>
					<Breadcrumb
						links={breadCrumbs}
						onClick={target => handleBreadCrumbClick(target)}
					/>
				</Box>
				<BoxShadowWrapper id="polygon-map">
					<Box row justifyContent="space-between">
						{Object.keys(geoData).length > 0
							? getTracePolygonMap()
							: getMapNotAvailable()}
					</Box>
				</BoxShadowWrapper>
			</Box>
		</>
	)
}

export { GenerateMap }
