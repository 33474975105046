import Duck from 'extensible-duck'
import { setIn, getIn } from 'timm'

const initialState = {
	activeModule: '',
	loadingStatus: false,
	orgDetails: {},
	userDetails: {},
	initialValues: {},
	inviteReferral: {
		inviteReferralSuccessModalStatus: false,
	},
	supplyBase: {},
}

export const OnboardDuc = new Duck({
	namespace: 'onboard',
	store: 'global',
	types: [
		'SET_ACTIVE_MODULE',
		'SIGNUP_RESET',
		'SIGNUP_USER',
		'VERIFY_OTP',
		'SAVE_AUTH_DETAILS',
		'INITIATE_OTP',
		'REGISTER',
		'BUSINESS',
		'ADD_CERTIFICATION',
		'SAVE_USER_DETAILS',
		'SET_INITIAL_VALUES',
		'GET_SUPPLYBASE_VALUES',
		'SET_SUPPLYBASE_VALUES',
		'SET_INITIAL_VALUES_SUCCESS',
		'DOCUMENT_CREATION_LOADING',
		'INVITE_REFERRAL_REQUEST_SUCCESS',
		'INITIATE_REFERRAL_INVITE',
		'SET_LANGUAGE',
		'UPLOAD_CERTIFICATE',
		'UPDATE_ALL_PRODUCT_PROFILE',
		'UPDATE_ADDRESS',
		'UPDATE_CERTIFICATE',
		'UPDATE_CERTIFICATION_META',
		'ADD_CERTIFICATE',
		'ADD_COLLECTION_POINT',
		'ADD_CARBON_NUMBER',
	],
	initialState,
	reducer: (state, action, duck) => {
		switch (action.type) {
			case duck.types.SET_ACTIVE_MODULE: {
				return setIn(state, ['activeModule'], action.module)
			}
			case duck.types.SAVE_AUTH_DETAILS: {
				return setIn(state, ['orgDetails'], action.values)
			}
			case duck.types.SAVE_USER_DETAILS: {
				return setIn(state, ['userDetails'], action.values)
			}
			case duck.types.SET_INITIAL_VALUES_SUCCESS: {
				const { values } = action

				return setIn(state, ['initialValues'], values)
			}
			case duck.types.DOCUMENT_CREATION_LOADING: {
				const { isLoading } = action

				return setIn(state, ['loadingStatus'], isLoading)
			}
			case duck.types.INVITE_REFERRAL_REQUEST_SUCCESS: {
				return setIn(
					state,
					['inviteReferral', 'inviteReferralSuccessModalStatus'],
					action.status
				)
			}
			case duck.types.SET_SUPPLYBASE_VALUES: {
				return setIn(state, ['supplyBase'], action.values)
			}

			default:
				return state
		}
	},
	selectors: {
		auth: state => state.auth,
		location: state => state.location,
		activeModule: state =>
			getIn(state, ['onboard', 'activeModule']) || 'Error',
		orgDetails: state => getIn(state, ['onboard', 'orgDetails']) || {},
		userDetails: state => getIn(state, ['onboard', 'userDetails']) || {},
		getLoadingStatus: state =>
			getIn(state, ['onboard', 'loadingStatus']) || false,

		getInitialValues: state =>
			getIn(state, ['onboard', 'initialValues']) || {},

		getInvitePartnerSuccessModalStatus: state =>
			getIn(state, [
				'onboard',
				'inviteReferel',
				'inviteReferalSuccessModalStatus',
			]),
		getSupplyBaseValues: state =>
			getIn(state, ['onboard', 'supplyBase']) || {},
	},
	creators: duck => ({
		setActiveModule: module => ({
			type: duck.types.SET_ACTIVE_MODULE,
			module,
		}),
		signUp: (values, helpers) => ({
			type: duck.types.SIGNUP_USER,
			values,
			helpers,
		}),
		verifyOtp: (loginID, secret, successToast) => ({
			type: duck.types.VERIFY_OTP,
			loginID,
			secret,
			successToast,
		}),
		initiateOtp: (loginID, successToast) => ({
			type: duck.types.INITIATE_OTP,
			loginID,
			successToast,
		}),
		saveAuthDetails: values => ({
			type: duck.types.SAVE_AUTH_DETAILS,
			values,
		}),
		saveUserDetails: values => ({
			type: duck.types.SAVE_USER_DETAILS,
			values,
		}),
		register: (values, loaderProgress, currentFormStatus, helpers) => ({
			type: duck.types.REGISTER,
			values,
			loaderProgress,
			currentFormStatus,
			helpers,
		}),
		business: (values, loaderProgress, currentFormStatus, helpers) => ({
			type: duck.types.BUSINESS,
			values,
			loaderProgress,
			currentFormStatus,
			helpers,
		}),
		addCertifications: (
			values,
			loaderProgress,
			currentFormStatus,
			helpers
		) => ({
			type: duck.types.ADD_CERTIFICATION,
			values,
			loaderProgress,
			currentFormStatus,
			helpers,
		}),
		resetToSignupInitial: () => ({
			type: duck.types.SIGNUP_RESET,
		}),
		setInitialValues: () => ({
			type: duck.types.SET_INITIAL_VALUES,
		}),
		setSupplyBaseValues: values => ({
			type: duck.types.SET_SUPPLYBASE_VALUES,
			values,
		}),
		setInitialValuesSuccess: values => ({
			type: duck.types.SET_INITIAL_VALUES_SUCCESS,
			values,
		}),
		documentLoading: (isLoading = false) => ({
			type: duck.types.DOCUMENT_CREATION_LOADING,
			isLoading,
		}),
		inviteReferralReqSuccess: status => ({
			type: duck.types.INVITE_REFERRAL_REQUEST_SUCCESS,
			status,
		}),
		initiateReferralInvite: details => ({
			type: duck.types.INITIATE_REFERRAL_INVITE,
			details,
		}),
		setLanguage: language => ({
			type: duck.types.SET_LANGUAGE,
			language,
		}),
		uploadCertificate: (values, successToast, helpers) => ({
			type: duck.types.UPLOAD_CERTIFICATE,
			values,
			successToast,
			helpers,
		}),
		updateAllProductProfile: profiles => ({
			type: duck.types.UPDATE_ALL_PRODUCT_PROFILE,
			profiles,
		}),
		updateAddress: (details, helpers) => ({
			type: duck.types.UPDATE_ADDRESS,
			details,
			helpers,
		}),
		updateCertificate: (
			details,
			id,
			helpers,
			actor,
			unCertified = false,
			carbonNumberID = ''
		) => ({
			type: duck.types.UPDATE_CERTIFICATE,
			details,
			id,
			helpers,
			actor,
			unCertified,
			carbonNumberID,
		}),
		updateCertificationMeta: unCertified => ({
			type: duck.types.UPDATE_CERTIFICATION_META,
			unCertified,
		}),
		addCertificate: (
			details,
			helpers,
			actor,
			unCertified = false,
			carbonNumberID = ''
		) => ({
			type: duck.types.ADD_CERTIFICATE,
			details,
			helpers,
			actor,
			unCertified,
			carbonNumberID,
		}),
		addCollectionPoint: (details, organizationDetail, helpers) => ({
			type: duck.types.ADD_COLLECTION_POINT,
			details,
			organizationDetail,
			helpers,
		}),
		addCarbonNumber: (payload, lcvPayload, helpers) => ({
			type: duck.types.ADD_CARBON_NUMBER,
			payload,
			lcvPayload,
			helpers,
		}),
	}),
})
