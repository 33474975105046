import { all, takeLatest, put, select } from 'redux-saga/effects'
import LogHelper from 'core-app/utils/logger'
import { AppDuc } from 'core-app/modules/App/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { CookieDuc } from 'core-app/modules/App/cookieDuc'
import { MainRouteDuc } from 'core-app/routes/duc'

import { getPlantationEndPoint, getCoreEndPoint } from 'core-app/config'
import { CallWithRefreshCheck } from 'core-app/modules/Auth/AuthSaga'
import { getIn } from 'timm'
import { DocumentListing } from 'core-app/modules/App/AppSaga'

import { WbTicketDuc } from './duc'

const logger = LogHelper('client:wbTicketSaga')

const PAGINATION_LIMIT = 30

function* fetchDocuments(action) {
	try {
		yield put(AuthDuc.creators.fetchPartnerOrgs())

		const { isSkip, skipGlobalLoader, locationState } = action
		if (!skipGlobalLoader)
			yield put(AppDuc.creators.showGlobalLoader('document-listing'))

		const selectedCPID = yield select(CookieDuc.selectors.getSelectedCPID)

		yield put(WbTicketDuc.creators.setDocumentLoading(true))

		let requestUrl = `${getPlantationEndPoint()}ffbdealer/tickets`
		if (selectedCPID) {
			requestUrl = `${getPlantationEndPoint()}ffbdealer/tickets/childOrg/${selectedCPID}`
		}
		const {
			data,
			serverPaginationQuery,
			stateTree,
		} = yield DocumentListing(requestUrl, locationState, PAGINATION_LIMIT)
		yield put(
			WbTicketDuc.creators.savePaginationQueries(serverPaginationQuery)
		)
		yield put(WbTicketDuc.creators.setActiveFilterEntries(stateTree))

		yield put(WbTicketDuc.creators.fetchDocumentSuccess(isSkip, data.list))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(WbTicketDuc.creators.setDocumentLoading(false))
		yield put(AppDuc.creators.hideGlobalLoader('document-listing'))
	}
}

function* viewDocument(action) {
	const { documentReference } = action
	try {
		yield put(WbTicketDuc.creators.flushDocumentState())

		yield put(AuthDuc.creators.fetchPartnerOrgs())

		yield put(WbTicketDuc.creators.setDocumentViewLoading(true))
		const locationState = yield select(WbTicketDuc.selectors.location)
		const { query = {} } = locationState
		const { entityID } = query
		const selectedCPID = yield select(CookieDuc.selectors.getSelectedCPID)

		let requestUrl = `${getPlantationEndPoint()}ffbdealer/tickets/${documentReference}`
		if (selectedCPID) {
			requestUrl = `${getPlantationEndPoint()}ffbdealer/tickets/${documentReference}/childOrg/${selectedCPID}`
		}

		const { data } = yield CallWithRefreshCheck(requestUrl)

		const getAttachedFile = `${getCoreEndPoint()}entities/${entityID}/attachments`
		const { data: _fileData } = yield CallWithRefreshCheck(getAttachedFile)

		const documentData = {
			data,
			file:
				getIn(_fileData, [
					0,
					'meta',
					'weighbridge',
					'files',
					0,
					'meta',
				]) || {},
		}
		yield put(WbTicketDuc.creators.fetchDocumentViewSuccess(documentData))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(WbTicketDuc.creators.setDocumentViewLoading(false))
	}
}

function* saveUploadTicket(action) {
	const { ticket } = action
	try {
		const files = getIn(ticket, ['attachedFile', 0]) || {}
		yield put(AppDuc.creators.showGlobalLoader('upload-weighbridge-ticket'))

		const productIDURL = `${getCoreEndPoint()}clients/organizations/-/products`
		const { data = {} } = yield CallWithRefreshCheck(productIDURL)
		const productID = getIn(data, ['list', 0, 'product', 'id']) || ''

		const ticketDetails = {
			issuedFor: ticket.smallholderID,
			netWeight: ticket.netWeight,
			productID,
			files: [files],
		}

		const requestUrl = `${getPlantationEndPoint()}ffbdealer/tickets/weighbridge`
		const options = {
			method: 'POST',
			body: JSON.stringify(ticketDetails),
		}
		const ticketUploaddata = yield CallWithRefreshCheck(requestUrl, options)
		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.WBTICKET$WDOCREFERENCE,
				{
					documentReference: ticketUploaddata.data.id,
				},
				{
					entityID: ticketUploaddata.data.meta.entityID,
				}
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('upload-weighbridge-ticket'))
	}
}

export default function* WbTicketSaga() {
	try {
		yield all([
			takeLatest(
				WbTicketDuc.creators.fetchDocuments().type,
				fetchDocuments
			),
			takeLatest(
				WbTicketDuc.creators.saveUploadTicket().type,
				saveUploadTicket
			),
			takeLatest(WbTicketDuc.creators.viewDocument().type, viewDocument),
		])
	} catch (e) {
		logger.error(e)
	}
}
