/* @flow */
import React, { useContext } from 'react'
import { ThemeContext } from 'grommet'
import leftArrow from '../../icons/left-arrow.svg'
import rightArrow from '../../icons/right-arrow.svg'
import { Icon, IconWrapper } from '../../icons/components/Icon'
import { Box } from '../../utils/Box'
import { P } from '../Typography'

type Props = {
	onClick: string => {},
	startIndex?: number,
	endIndex?: number,
	maxIndex?: number,
	totalCount?: number,
	hidePageResults?: boolean,
}

type PaginatorIndexProps = {
	startDesc: string,
	toDesc: string,
	ofDesc: string,
	startIndex: number,
	endIndex: number,
	totalCount: number,
}

export const PaginatorIndexBlock = ({
	startDesc = 'Showing',
	toDesc = 'to',
	ofDesc = 'of',
	startIndex = 0,
	endIndex = 0,
	totalCount = 0,
}: PaginatorIndexProps) => (
	<Box row>
		{startIndex > 0 && (
			<>
				<P as="span" small>
					{startDesc}
					<P as="span" small bold style={{ margin: '0 3px' }}>
						{startIndex}
					</P>
					{endIndex > 0 && (
						<>
							{toDesc}
							<P as="span" small bold style={{ margin: '0 3px' }}>
								{endIndex}
							</P>
						</>
					)}
				</P>
				{totalCount > 0 && (
					<P as="span" small>
						{ofDesc}
						<P small as="span" bold style={{ margin: '0 3px' }}>
							{totalCount}
						</P>
					</P>
				)}
			</>
		)}
	</Box>
)

const Paginator = ({
	onClick,
	startIndex,
	endIndex,
	totalCount,
	startDesc = 'Showing',
	toDesc = 'to',
	ofDesc = 'of',
	next = false,
	hidePageResults,
}: Props) => {
	const theme = useContext(ThemeContext)

	const _startIndex = parseInt(startIndex, 10)
	const _endIndex = parseInt(endIndex, 10)
	const _totalCount = parseInt(totalCount, 10)
	const hasPrev = _startIndex > 1
	// $FlowFixMe
	const hasNext = _endIndex < totalCount

	return (
		<Box
			row
			alignItems="center"
			justifyContent={hidePageResults ? 'flex-end' : 'space-between'}
		>
			{!hidePageResults && (
				<PaginatorIndexBlock
					startDesc={startDesc}
					toDesc={toDesc}
					ofDesc={ofDesc}
					startIndex={_startIndex}
					endIndex={_endIndex}
					totalCount={_totalCount}
				/>
			)}
			<Box row>
				<Box
					onClick={() => hasPrev && onClick('prev')}
					center
					style={{
						padding: 7,
						border: '1px solid #ccc',
						borderTopLeftRadius: '8px',
						borderBottomLeftRadius: '8px',
						transition: 'color 0.1s ease 0.1s',
						color: theme.color.primary,
						cursor: 'pointer',
						borderColor: theme.color.primary,
						...(!hasPrev
							? {
									cursor: 'not-allowed',
									color: theme.color.grey2,
									// borderRight: 'none',
									borderColor: theme.color.grey2,
							  }
							: {}),
					}}
				>
					<IconWrapper size={15} style={{ color: 'currentColor' }}>
						<Icon next={next} glyph={leftArrow} />
					</IconWrapper>
				</Box>
				<Box
					onClick={() => hasNext && onClick('next')}
					center
					style={{
						padding: 7,
						border: '1px solid #ccc',
						borderTopRightRadius: '8px',
						borderBottomRightRadius: '8px',
						transition: 'color 0.1s ease 0.1s',
						color: theme.color.primary,
						cursor: 'pointer',
						borderColor: theme.color.primary,
						...(!hasNext
							? {
									cursor: 'not-allowed',
									color: theme.color.grey2,
									// borderLeft: 'none',
									borderColor: theme.color.grey2,
							  }
							: {}),
					}}
				>
					<IconWrapper size={15} style={{ color: 'currentColor' }}>
						<Icon next={next} glyph={rightArrow} />
					</IconWrapper>
				</Box>
			</Box>
		</Box>
	)
}

export { Paginator }
