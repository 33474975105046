export const FILTERS_STATUS_CONFIG = [
	{
		name: 'monthly',
		label: 'Monthly',
	},
	{
		name: 'quarterly',
		label: 'Quarterly',
	},
	{
		name: 'half-yearly',
		label: 'Half Yearly',
	},
	{
		name: 'yearly',
		label: 'Yearly',
	},
]

export const SortableKeys = [
	'date',
	'partner',
	'quantity',
	'issuedBy',
	'receiptNo',
	'consignment_number',
	'batch_reference',
	'doNumber',
	'vehicleNo',
	'checkerName',
	'ticketNo',
	'harvester',
	'blockNo',
	'unripe',
	'rotten',
	'looseFruits',
]

export const TABLE_ALIAS = {
	date: 'plantationColumnConfig.date',
	binNo: 'plantationColumnConfig.binNo',
	quantity: 'plantationColumnConfig.quantity',
	noOfTickets: 'plantationColumnConfig.noOfTickets',
	ticketNo: 'plantationColumnConfig.ticketNo',
	harvesterName: 'plantationColumnConfig.harvesterName',
	tractor: 'plantationColumnConfig.tractor',
	tractorOperator: 'plantationColumnConfig.tractorOperator',
	checkerName: 'plantationColumnConfig.checker',
	blockNo: 'plantationColumnConfig.blockNo',
	area: 'plantationColumnConfig.area',
	driverName: 'auditReport.driverName',
	tripNo: 'plantationColumnConfig.tripNo',
	hCode: 'plantationViewTicket.harvesterCode',
	cCode: 'plantationViewTicket.checkerCode',
	doNumber: 'plantationViewTicket.doNumber',
	driver: 'empMgmtHome.driver',
	checker: 'plantationRecordsListing.checker',
	harvester: 'plantationCreateTicket.harvester',
	dataSync: 'plantationCreateTicket.dataSync',
	dataNotSync: 'plantationCreateTicket.dataNotSync',
	dataNotSyncDetail: 'plantationCreateTicket.dataNotSyncDetail',
	syncStatus: 'plantationRecordsListing.syncStatus',
	receiver: 'plantationRecordsListing.receiver',
}

export const SCHEDULE_STATUS = {
	'in-progress': 'viewSchedule.ongoing',
	completed: 'viewSchedule.completed',
}

export const STATUS_TEXT_COLOR = {
	'in-progress': '#3E92E0',
	completed: '#4D8E48',
}

export const STATUS_BACKGROUND = {
	'in-progress': '#EBF5FF',
	completed: '#DDFADC',
}
export const initialState = {
	activeModule: 'plantation',
	loading: false,
	error: false,
	activeProductID: '',
	prodSelectionModal: false,
	insightsData: {},
	rules: {
		allowedTypes: [],
	},
	modules: {
		ticket: {
			loading: false,
			pagination: {
				activeIndex: 0, // total, pageCount
				limit: 0,
				total: 0,
				nextCursor: '',
			},
			activeDocuments: [],
			activeSorts: {},
			activeFilters: {},
			ticketInformation: {},
			users: [],
			initialValues: {
				createdDate: new Date(),
				harvester: '',
				ffbCount: '',
				ripeBunches: 0,
				overripeBunches: 0,
				underripeBunches: 0,
				emptyBunches: 0,
				wetBunches: 0,
				unripeBunches: 0,
				area: '',
				block: '',
				phase: '',
			},
		},
		bin: {
			loading: false,
			pagination: {
				activeIndex: 0, // total, pageCount
				limit: 0,
				total: 0,
				nextCursor: '',
			},
			activeDocuments: [],
			ticketList: {
				assigned: [],
				unAssigned: [],
			},
			activeSorts: {},
			activeFilters: {},
			binInformation: {},
			orgassets: {},
			initialValues: {
				createdDate: '',
				vehicleType: '',
				vehicleNumber: '',
				crewMember: '',
				binNumber: '',
				tickets: [],
			},
		},
		trips: {
			tripData: {},
		},
		document: {
			loading: false,
			activeTimeOffset: 5,
			activeSorts: {},
			activeDocuments: {},
			activeFilter: [],
			activePagination: [],
			tabsConfig: [
				{
					code: 'harvester',
					title: 'plantationRecordsListing.harvester',
				},
				{
					code: 'checker',
					title: 'plantationRecordsListing.checker',
				},
				{
					code: 'tractorOperator',
					title: 'plantationRecordsListing.tractorOperator',
				},
				{
					code: 'driver',
					title: 'empMgmtHome.driver',
				},
				{
					code: 'syncStatus',
					title: 'plantationRecordsListing.syncStatus',
				},
			],
		},
	},
}
