import React from 'react'
import styled from 'styled-components'
import { getIn } from 'timm'
import { Label, TableTitle } from 'ui-lib/components/Typography'
import { ButtonWithNoBorder, ButtonWithDrop } from 'ui-lib/components/Button'
import { Box } from 'ui-lib/utils/Box'
import { Status } from 'core-app/shared/components/Status'
import { orgTypesMapped, orgTypesForRiceMapped } from 'core-app/utils/helpers'
import { currentPlatform } from 'ui-lib/utils/config'
import { TABLE_ALIAS } from '../config'

const LinkLabel = styled(Label)({
	color: '#0000EE',
	textDecoration: 'underline',
	'&:hover': {
		color: '#551A8B',
	},
})

export const columnConfig = ({
	docType,
	onChange,
	orgDetails,
	t,
	currentOrgID,
	selectedCPID,
	isMill,
	isMobile = false,
}) => {
	const targetColumn = docType === 'partners' ? 'status' : 'inviteStatus'
	const action = docType === 'partners' ? '' : 'action'
	const location = docType === 'partners' ? '' : 'location'
	if (docType === 'referralList') {
		return [
			{
				property: 'name',
				header: (
					<Box row alignItems="center" style={{ cursor: 'pointer' }}>
						<TableTitle>{t(TABLE_ALIAS.partner)}</TableTitle>
					</Box>
				),
				render: datum => {
					const { receiver = {} } = datum
					const { name } = receiver

					return (
						<Label
							style={{
								padding: 9,
								overflowWrap: 'break-word',
								width: isMobile ? '100px' : 'auto',
							}}
							as="span"
						>
							{name}
						</Label>
					)
				},
				size: '15%',
			},
			...(!isMobile
				? [
						{
							property: 'status',
							header: (
								<Box
									row
									alignItems="center"
									style={{
										cursor: 'pointer',
									}}
								>
									<TableTitle>
										{t(TABLE_ALIAS[targetColumn])}
									</TableTitle>
								</Box>
							),
							render: datum => {
								const status = getIn(datum, ['status', 'state'])

								return <Status currentStatus={status} />
							},
							size: isMobile && '20%',
						},
				  ]
				: []),
			{
				property: 'email',
				header: (
					<Box
						row
						alignItems="center"
						style={{
							cursor: 'pointer',
						}}
					>
						<TableTitle>{t('login.email')}</TableTitle>
					</Box>
				),
				render: datum => {
					const { receiver = {} } = datum
					const { contact = {} } = receiver
					const { email } = contact

					return (
						<Label
							style={{
								width: isMobile ? '120px' : '75%',

								...(isMobile
									? { overflowWrap: 'break-word' }
									: {
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											whiteSpace: 'nowrap',
									  }),
							}}
							title={email}
							as="span"
						>
							{email}
						</Label>
					)
				},
				size: isMobile && '30%',
			},
			...(!isMobile
				? [
						{
							property: 'mobile',
							header: (
								<Box
									row
									alignItems="center"
									style={{
										cursor: 'pointer',
									}}
								>
									<TableTitle>
										{t('viewCompanyInfo.mobile')}
									</TableTitle>
								</Box>
							),
							render: datum => {
								const { receiver = {} } = datum
								const { contact = {} } = receiver
								const { mobile } = contact

								return <Label as="span">{mobile}</Label>
							},
						},
				  ]
				: []),

			{
				property: 'orgType',
				header: (
					<Box
						row
						alignItems="center"
						style={{
							cursor: 'pointer',
						}}
					>
						<TableTitle>{t(TABLE_ALIAS.orgType)}</TableTitle>
					</Box>
				),
				render: datum => {
					const { receiver = {} } = datum
					const { categories = [] } = receiver
					const orgType = getIn(categories, [0, 'name'])

					return (
						<Label as="span" style={{ overflowWrap: 'break-word' }}>
							{currentPlatform() === 'rice'
								? orgTypesForRiceMapped(t, orgType) || '---'
								: orgTypesMapped(t, orgType) || '---'}
						</Label>
					)
				},
				size: isMobile && '25%',
			},
		]
	}

	if (docType === 'globalPartnersList') {
		return [
			{
				property: 'name',
				header: (
					<Box row alignItems="center" style={{ cursor: 'pointer' }}>
						<TableTitle>{t(TABLE_ALIAS.partner)}</TableTitle>
					</Box>
				),
				render: datum => {
					const { name } = datum

					return (
						<Label
							style={{
								padding: 9,
								overflowWrap: 'break-word',
								width: isMobile ? '100px' : 'auto',
							}}
							as="span"
						>
							{name}
						</Label>
					)
				},
				size: '15%',
			},
			...(!isMobile
				? [
						{
							property: 'status',
							header: (
								<Box
									row
									alignItems="center"
									style={{
										cursor: 'pointer',
									}}
								>
									<TableTitle>
										{t(TABLE_ALIAS[targetColumn])}
									</TableTitle>
								</Box>
							),
							render: datum => {
								const status = getIn(datum, [
									'meta',
									'partnership',
									'status',
								])

								return <Status currentStatus={status} />
							},
							size: isMobile && '20%',
						},
				  ]
				: []),
			{
				property: 'email',
				header: (
					<Box
						row
						alignItems="center"
						style={{
							cursor: 'pointer',
						}}
					>
						<TableTitle>{t('login.email')}</TableTitle>
					</Box>
				),
				render: datum => {
					const email = getIn(datum, ['meta', 'partnership', 'email'])

					return (
						<Label
							style={{
								width: isMobile ? '120px' : '75%',

								...(isMobile
									? { overflowWrap: 'break-word' }
									: {
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											whiteSpace: 'nowrap',
									  }),
							}}
							title={email}
							as="span"
						>
							{email}
						</Label>
					)
				},
				size: isMobile && '30%',
			},
			...(!isMobile
				? [
						{
							property: 'mobile',
							header: (
								<Box
									row
									alignItems="center"
									style={{
										cursor: 'pointer',
									}}
								>
									<TableTitle>
										{t('viewCompanyInfo.mobile')}
									</TableTitle>
								</Box>
							),
							render: datum => {
								const mobile = getIn(datum, [
									'meta',
									'partnership',
									'mobile',
								])

								return <Label as="span">{mobile}</Label>
							},
						},
				  ]
				: []),

			{
				property: 'orgType',
				header: (
					<Box
						row
						alignItems="center"
						style={{
							cursor: 'pointer',
						}}
					>
						<TableTitle>{t(TABLE_ALIAS.orgType)}</TableTitle>
					</Box>
				),
				render: datum => {
					const orgType = getIn(datum, [
						'meta',
						'partnership',
						'organizationType',
					])

					return (
						<Label as="span" style={{ overflowWrap: 'break-word' }}>
							{currentPlatform() === 'rice'
								? orgTypesForRiceMapped(t, orgType) || '---'
								: orgTypesMapped(t, orgType) || '---'}
						</Label>
					)
				},
				size: isMobile && '25%',
			},
			...(!isMobile
				? [
						{
							property: 'location',
							header: (
								<Box
									row
									alignItems="center"
									style={{
										cursor: 'pointer',
									}}
								>
									<TableTitle>
										{t('weighbridge.location')}
									</TableTitle>
								</Box>
							),
							render: datum => {
								const orgLocation = getIn(datum, [
									'meta',
									'partnership',
									'location',
								])

								return <Label as="span">{orgLocation}</Label>
							},
						},
				  ]
				: []),
		]
	}

	if (isMill && docType === 'supplybaseMembers') {
		return [
			{
				property: 'name',
				header: (
					<Box row alignItems="center" style={{ cursor: 'pointer' }}>
						<TableTitle>{t(TABLE_ALIAS.partner)}</TableTitle>
					</Box>
				),
				render: datum => {
					const { name } = datum

					return (
						<Label
							style={{
								padding: 9,
								overflowWrap: 'break-word',
								width: isMobile ? '100px' : 'auto',
							}}
							as="span"
						>
							{name}
						</Label>
					)
				},
				size: '25%',
			},
			...(!isMobile
				? [
						{
							property: 'location',
							header: (
								<Box
									row
									alignItems="center"
									style={{ cursor: 'pointer' }}
								>
									<TableTitle>
										{t(TABLE_ALIAS[location])}
									</TableTitle>
								</Box>
							),
							render: datum => {
								const { meta = {} } = datum
								const { primaryAddress = {} } = meta
								const {
									city = '',
									state = '',
									country = '',
								} = primaryAddress
								let addressDetail = [city, state, country]
								addressDetail = addressDetail.filter(
									str => str !== ''
								)

								return (
									<Label as="span">{`${addressDetail.join(
										', '
									)}`}</Label>
								)
							},
						},
				  ]
				: []),
			{
				property: 'email',
				header: (
					<Box row alignItems="center" style={{ cursor: 'pointer' }}>
						<TableTitle>{t('login.email')}</TableTitle>
					</Box>
				),
				render: datum => {
					const { meta = {} } = datum
					const { primaryContact = {} } = meta
					const { email } = primaryContact

					return (
						<Label
							as="span"
							style={{
								overflowWrap: 'break-word',
								width: isMobile ? '120px' : 'auto',
							}}
						>
							{email}
						</Label>
					)
				},
				size: isMobile && '25%',
			},
			{
				property: 'action',
				header: (
					<Box row alignItems="center" style={{ cursor: 'pointer' }}>
						<TableTitle />
					</Box>
				),
				render: datum => {
					return (
						<ButtonWithNoBorder
							label={t(TABLE_ALIAS.update)}
							onClick={() => {
								onChange('supplybaseMemberUpdate', {
									row: datum,
								})
							}}
						/>
					)
				},
				size: isMobile && '25%',
			},
		]
	}

	return [
		{
			property: 'organisation',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.partner)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { meta = {} } = datum
				const { partnership = {} } = meta
				const { initiatorID, receiverID } = partnership

				const orgName =
					orgDetails[
						currentOrgID === initiatorID ? receiverID : initiatorID
					]

				const category = getIn(orgName, ['categories', 0, 'id']) || ''

				const parentName =
					getIn(orgName, ['meta', 'parentOrganization', 0, 'name']) ||
					''

				const hasParent =
					category === 'palmoil-collectionPoint' && parentName !== ''
				const name = getIn(orgName, ['name'])

				const newName = hasParent ? `${parentName} - ${name}` : name

				return (
					<LinkLabel
						as="span"
						link
						onClick={() =>
							onChange('open_document', {
								row: datum,
								docType,
							})
						}
					>
						{newName}
					</LinkLabel>
				)
			},
			size: '20%',
		},
		{
			property: 'orgType',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.orgType)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { categories = [] } = datum
				const orgType = getIn(categories, [0, 'name'])

				return <Label as="span">{orgType}</Label>
			},
			size: '15%',
		},
		...(!isMobile
			? [
					{
						property: 'location',
						header: (
							<Box
								row
								alignItems="center"
								style={{ cursor: 'pointer' }}
							>
								<TableTitle>
									{t(TABLE_ALIAS[location])}
								</TableTitle>
							</Box>
						),
						render: datum => {
							const { meta = {} } = datum
							const { partnership = {} } = meta
							const { initiatorID } = partnership
							const orgName = orgDetails[initiatorID]
							const orgLocation = getIn(orgName, [
								'primaryAddress',
								'city',
							])
							if (docType === 'partnerships') {
								return <Label as="span">{orgLocation}</Label>
							}
						},
						size: '20%',
					},
			  ]
			: []),
		{
			property: 'status',
			header: (
				<Box
					row
					alignItems="center"
					style={{
						cursor: 'pointer',
					}}
				>
					<TableTitle>{t(TABLE_ALIAS[targetColumn])}</TableTitle>
				</Box>
			),
			render: datum => {
				const status = getIn(datum, ['meta', 'partnership', 'status'])

				return <Status currentStatus={status} />
			},
			size: docType === 'partners' ? '20%' : '15%',
		},
		...(!isMobile
			? [
					{
						property: 'viewDetails',
						render: datum => {
							return (
								<ButtonWithNoBorder
									label={t('common.viewBtn').toUpperCase()}
									onClick={() => {
										onChange('open_document', {
											row: datum,
											docType,
										})
									}}
								/>
							)
						},
						size: '15%',
					},
			  ]
			: []),
		{
			property: 'action',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS[action])}</TableTitle>
				</Box>
			),
			render: datum => {
				const { meta = {} } = datum
				const { partnership = {} } = meta
				const { initiatorID, requestID } = partnership

				if (
					docType === 'partnerships' &&
					currentOrgID !== initiatorID &&
					!selectedCPID
				)
					return (
						<ButtonWithDrop
							items={[
								{
									label: t('common.accept'),
									name: 'true',
								},
								{
									label: t('common.decline'),
									name: 'false',
								},
							]}
							label={
								isMobile
									? t('createAsset.choose')
									: t('partnerHome.chooseAction')
							}
							noBackground
							onChange={active => {
								onChange('partnerStatus', {
									status: active,
									partnerReqID: requestID,
								})
							}}
						/>
					)
			},
			size: isMobile && '10%',
		},
	]
}
