import { GeneralSchedulerDuc } from 'core-app/modules/GeneralScheduler/duc'
import { MainRouteDuc } from 'core-app/routes/duc'
import { getIn } from 'timm'
import saga from 'core-app/modules/GeneralScheduler/GeneralSchedulerSaga'

export default async (dispatch, getState, { action, extra }) => {
	const { type, customMeta = {} } = action
	const currentLocationFromAction = getIn(action, [
		'meta',
		'location',
		'current',
	])
	/** if someone specifically wants to skip thunk fetch again, this check helps */
	const { skipRouteThunk } = customMeta
	if (skipRouteThunk) return

	// check if nothing changed in the route.

	const state = getState()

	const locationState = currentLocationFromAction || state.location

	const { payload } = locationState

	// Inject the saga here. If it wasn't already
	extra.getSagaInjectors().injectSaga('GENERAL_SCHEDULER', { saga })

	const isMainDashboard = MainRouteDuc.types.GENERAL_SCHEDULER === type

	let activeModule = ''

	if (isMainDashboard) {
		// load the dashboard of the core module
		activeModule = 'dashboard'
		dispatch(GeneralSchedulerDuc.creators.flushMainDashboard())
		dispatch(
			GeneralSchedulerDuc.creators.fetchScheduleListing(locationState)
		)
	} else {
		activeModule = payload.action
		dispatch(
			GeneralSchedulerDuc.creators.fetchScheduleDetails(
				payload.documentReference
			)
		)
	}

	// load the dashboard of the core module
	dispatch(GeneralSchedulerDuc.creators.setActiveModule(activeModule))

	// based on the payload or action, initiate saga's
}
