import React from 'react'
import { RefineryDuc } from 'core-app/modules/Refinery/duc'
import { useSelector } from 'react-redux'
import { UnexpectedErrorBlock } from 'core-app/modules/App/components/Error'
import { StorageListing } from './StorageListing'
import { AssignTankView } from './View'

const StorageInput = () => {
	const { payload = {} } = useSelector(RefineryDuc.selectors.location)
	if (payload.rootModule === 'input-storage') {
		if (payload.action === 'view') {
			return <AssignTankView />
		}

		return <StorageListing />
	}

	return <UnexpectedErrorBlock status={401} />
}

export { StorageInput }
