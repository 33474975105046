export const TABLE_ALIAS = {
	date: 'storage.date',
	consignmentNumber: 'storage.consignmentNumber',
	product: 'storage.product',
	quantity: 'storage.quantity',
	viewDocument: 'storage.viewDocument',
	storageUnit: 'storage.storageUnit',
	batchNumber: 'storage.batchNumber',
	viewBatch: 'storage.viewBatch',
}

export const TRACE_GROUP_STATUS = ['mapIncoming', 'mapOutgoing']

export const initialState = {
	activeModule: '',
	loading: false,
	error: false,
	insightsData: {},
	modules: {
		storage: {
			loading: false,
			activeTimeOffset: 5,
			storageTanks: [],
			activeSorts: {},
			activeDocuments: {
				mapIncoming: {},
				mapOutgoing: {},
			},
			activeRecords: {},
			assignTank: [],
			parentRef: {},
			outgoingBatch: [],
			tabsConfig: [
				{
					code: 'mapIncoming',
					title: 'common.receiveGoods',
				},
				// {
				// 	code: 'mapOutgoing',
				// 	title: 'common.deliverGoods',
				// },
			],
		},
		listing: {
			loading: false,
			pagination: {
				activeIndex: 0, // total, pageCount
				limit: 0,
				total: 0,
				nextCursor: '',
			},
			activeDocuments: [],
			activeSorts: {},
			activeFilters: {},
		},
	},
}
