import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NotFoundBlock } from 'core-app/modules/App/components/Error'
import { TraceDuc } from './duc'
import { GenerateMap } from './containers/GenerateMap'
import { TraceListing } from './containers/TraceListing'
import { GenerateTrace } from './containers/GenerateTrace'
import { GenerateTraceV1 } from './containers/GenerateTraceV1'
import { GenerateTraceV2 } from './containers/GenerateTraceV2'
import { WebTour } from '../WebTour'
import { WebTourDuc } from '../WebTour/duc'

const modulesMap = {
	listing: TraceListing,
	generateTraceV1: GenerateTraceV1,
	generateTrace: GenerateTrace,
	generateTraceGroup: GenerateTrace,
	generateTraceGroupV2: GenerateTraceV2,
	generateTraceMap: GenerateMap,
}

const Trace = () => {
	const dispatch = useDispatch()
	const activeModule = useSelector(TraceDuc.selectors.activeModule)
	const Component = modulesMap[activeModule] || NotFoundBlock
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)
	useEffect(() => {
		if (tourModule === 'productTrace') {
			dispatch(WebTourDuc.creators.setTraceVisit(true))
		}
	}, [tourModule, dispatch])

	// if we are here, we have a submodule, route it to the module

	return (
		<>
			<Component />
			{tourModule === 'productTrace' && <WebTour />}
		</>
	)
}

export default Trace
