import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AdminDuc } from 'core-app/modules/Admin/duc'
import { WebTourDuc } from 'core-app/modules/WebTour/duc'
import { UnexpectedErrorBlock } from 'core-app/modules/App/components/Error'
import { CreateVehicle } from 'core-app/modules/Admin/containers/AssetManagement/CreateVehicle'
import { CreateStorageUnit } from 'core-app/modules/Admin/containers/AssetManagement/CreateStorageUnit'
import { CreateProductionLine } from 'core-app/modules/Admin/containers/AssetManagement/CreateProductionLine'
import { UploadDocuments } from '../subModules/UploadDocuments'
import { CreateWeighBridge } from './CreateWeighBridge'

const AssetManagement = () => {
	const dispatch = useDispatch()
	const { payload = {} } = useSelector(AdminDuc.selectors.location)

	const { subModule, action } = payload
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)

	if (tourModule !== '') {
		dispatch(WebTourDuc.creators.setActiveTourModule(''))
	}

	if (action === 'upload-assets') {
		return <UploadDocuments />
	}

	switch (subModule) {
		case 'vehicles':
			if (action === 'create-asset' || action === 'edit-asset') {
				return <CreateVehicle />
			}
			break
		case 'storageunits':
			if (action === 'create-asset' || action === 'edit-asset') {
				return <CreateStorageUnit />
			}
			break
		case 'production-line':
			if (action === 'create-asset' || action === 'edit-asset') {
				return <CreateProductionLine />
			}
			break
		case 'weighbridges':
			if (action === 'create-asset' || action === 'edit-asset') {
				return <CreateWeighBridge />
			}
			break
		default:
			break
	}

	return <UnexpectedErrorBlock status={401} />
}

export { AssetManagement }
