import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { useTranslation } from 'react-i18next'
import { Button } from 'ui-lib/components/Button'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AdminDuc } from 'core-app/modules/Admin/duc'
import { P } from 'ui-lib/components/Typography'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { RefineryDuc } from 'core-app/modules/Refinery/duc'
import { ProductsDuc } from 'core-app/modules/Products/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { getColumnConfig } from 'core-app/modules/Refinery/components/Columns'
import { Table } from 'ui-lib/components/Table'
import { SelectSearch } from 'ui-lib/components/Select'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { Input } from 'ui-lib/components/Input'
import { Title } from 'core-app/shared/components/Title'
import { TransparentBgSpinner } from 'ui-lib/components/Spinner/TransparentBgSpinner'
import { StorageInventoryModal } from 'core-app/modules/Refinery/components/StorageInventoryModal'
import Documents from 'ui-lib/icons/documents.svg'
import DeleteIcon from 'ui-lib/icons/deleteTrash.svg'
import StorageTankIcon from 'ui-lib/icons/storageTank.svg'
import theme from 'ui-lib/utils/base-theme'

const ProductionDaily = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { transformed = [] } = useSelector(
		RefineryDuc.selectors.getProductionDocuments
	)
	const allProducts = useSelector(ProductsDuc.selectors.getProducts)
	const orgDetails = useSelector(AdminDuc.selectors.getCompanyInfo)
	const certificates = useSelector(AuthDuc.selectors.getCertificates)

	const [showModal, setShowModal] = useState(false)
	const [productionEntries, setProductionEntries] = useState([
		{
			traces: [
				{
					id: '',
					quantity: '',
					storageUnitID: '',
					inventoryType: 'incoming',
				},
			],
			meta: {
				productionLine: {
					id: '',
				},
			},
		},
	])

	const refineryLoadingState = useSelector(
		RefineryDuc.selectors.getRefineryLoadingState
	)
	const productsLoadingState = useSelector(
		ProductsDuc.selectors.getProductsLoadingState
	)
	const storageUnits = useSelector(ProductsDuc.selectors.getStorageUnits)

	const outgoingProducts = useSelector(
		ProductsDuc.selectors.getOutgoingProducts
	)

	const outgoingStorageUnits = useSelector(
		ProductsDuc.selectors.getOutgoingStorageUnits
	)

	const rawMaterials = allProducts?.map(prod => {
		return {
			id: prod?.product?.id,
			label: prod?.product?.name,
		}
	})

	const modifiedOutgoingProducts = outgoingProducts?.map(op => {
		return {
			id: op?.product?.id,
			label: op?.product?.name,
		}
	})

	const getIncomingStorageUnits = productId => {
		return storageUnits
			?.filter(isu => isu.products.includes(productId))
			.map(isu => ({
				id: isu.id,
				label: isu.name,
			}))
	}

	const getOutgoingStorageUnits = productId => {
		return outgoingStorageUnits
			?.filter(osu => osu.products.includes(productId))
			.map(osu => ({
				id: osu.id,
				label: osu.name,
			}))
	}

	const productionLines = useSelector(ProductsDuc.selectors.getProductionLine)

	const modifiedProductionLines = productionLines?.map(pl => {
		return {
			id: pl.id,
			label: pl.name,
		}
	})

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.production'),
			name: 'production',
			isActive: false,
		},
	]

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
	}

	const handleProductionLineChange = (productionLineId, entryIndex) => {
		setProductionEntries(prevEntries => {
			const updatedEntries = [...prevEntries]
			updatedEntries[entryIndex] = {
				...updatedEntries[entryIndex],
				meta: {
					...updatedEntries[entryIndex].meta,
					productionLine: {
						id: productionLineId,
					},
				},
			}

			return updatedEntries
		})
	}

	const handleInitialRawMaterialChange = (
		productId,
		entryIndex,
		traceIndex
	) => {
		setProductionEntries(prevEntries => {
			const updatedEntries = [...prevEntries]
			updatedEntries[entryIndex].traces[traceIndex].id = productId

			return updatedEntries
		})
	}

	const handleTankChange = (storageUnitId, entryIndex, traceIndex) => {
		const currentTankDetails = storageUnits.find(
			item => item?.id === storageUnitId
		)
		setProductionEntries(prevEntries => {
			const updatedEntries = [...prevEntries]
			updatedEntries[entryIndex].traces[
				traceIndex
			].storageUnitID = storageUnitId
			updatedEntries[entryIndex].traces[
				traceIndex
			].supplyChainModel = currentTankDetails?.meta?.supplyChainModel
				? currentTankDetails?.meta?.supplyChainModel
				: 'none'

			return updatedEntries
		})
	}

	const handleQuantityChange = (quantity, entryIndex, traceIndex) => {
		setProductionEntries(prevEntries => {
			const updatedEntries = [...prevEntries]
			updatedEntries[entryIndex].traces[traceIndex].quantity = quantity

			return updatedEntries
		})
	}

	const handleFinalProductChange = (productId, entryIndex) => {
		setProductionEntries(prevEntries => {
			const updatedEntries = [...prevEntries]
			const entry = updatedEntries[entryIndex]

			// Check if there is already a trace with inventoryType 'outgoing'
			const existingOutgoingTraceIndex = entry.traces.findIndex(
				trace => trace.inventoryType === 'outgoing'
			)

			if (existingOutgoingTraceIndex === -1) {
				// If there is no outgoing trace, add a new one
				entry.traces.push({
					id: productId,
					quantity: '',
					storageUnitID: '',
					inventoryType: 'outgoing',
					supplyChainModel:
						certificates?.length === 0
							? 'none'
							: orgDetails?.meta?.supplyChainModel,
				})
			} else {
				// If there is an existing outgoing trace, update it
				entry.traces[existingOutgoingTraceIndex].id = productId
			}

			return updatedEntries
		})
	}

	const handleFinalTankChange = (storageUnitId, entryIndex) => {
		const currentTankDetails = outgoingStorageUnits.find(
			item => item?.id === storageUnitId
		)
		setProductionEntries(prevEntries => {
			const updatedEntries = [...prevEntries]
			const entry = updatedEntries[entryIndex]

			// Check if there is already a trace with inventoryType 'outgoing'
			const existingOutgoingTraceIndex = entry.traces.findIndex(
				trace => trace.inventoryType === 'outgoing'
			)

			if (existingOutgoingTraceIndex === -1) {
				// If there is no outgoing trace, add a new one
				entry.traces.push({
					id: '',
					quantity: '',
					storageUnitID: storageUnitId,
					inventoryType: 'outgoing',
					supplyChainModel: currentTankDetails?.meta?.supplyChainModel
						? currentTankDetails?.meta?.supplyChainModel
						: 'none',
				})
			} else {
				// If there is an existing outgoing trace, update the storageUnitID
				entry.traces[
					existingOutgoingTraceIndex
				].storageUnitID = storageUnitId
				entry.traces[
					existingOutgoingTraceIndex
				].supplyChainModel = currentTankDetails?.meta?.supplyChainModel
					? currentTankDetails?.meta?.supplyChainModel
					: 'none'
			}

			return updatedEntries
		})
	}

	const handleFinalQuantityChange = (quantity, entryIndex) => {
		setProductionEntries(prevEntries => {
			const updatedEntries = [...prevEntries]
			const entry = updatedEntries[entryIndex]

			// Check if there is already a trace with inventoryType 'outgoing'
			const existingOutgoingTraceIndex = entry.traces.findIndex(
				trace => trace.inventoryType === 'outgoing'
			)

			if (existingOutgoingTraceIndex === -1) {
				// If there is no outgoing trace, add a new one
				entry.traces.push({
					id: '',
					quantity,
					storageUnitID: '',
					inventoryType: 'outgoing',
					supplyChainModel:
						certificates?.length === 0
							? 'none'
							: orgDetails?.meta?.supplyChainModel,
				})
			} else {
				// If there is an existing outgoing trace, update the quantity
				entry.traces[existingOutgoingTraceIndex].quantity = quantity
			}

			return updatedEntries
		})
	}

	const addRawMaterialRow = entryIndex => {
		setProductionEntries(prevEntries => {
			const updatedEntries = [...prevEntries]
			updatedEntries[entryIndex].traces.push({
				id: '',
				quantity: '',
				storageUnitID: '',
				inventoryType: 'incoming',
			})

			return updatedEntries
		})
	}

	const handleDeleteRawMaterialRow = (entryIndex, traceIndex) => {
		setProductionEntries(prevEntries => {
			const updatedEntries = [...prevEntries] // Create a copy of the array
			updatedEntries[entryIndex] = {
				...updatedEntries[entryIndex], // Create a copy of the entry
				traces: updatedEntries[entryIndex].traces.filter(
					(_, i) => i !== traceIndex
				), // Filter out the row to be deleted
			}

			return updatedEntries
		})
	}

	const addProductionEntry = () => {
		setProductionEntries(prevEntries => [
			...prevEntries,
			{
				traces: [
					{
						id: '',
						quantity: '',
						storageUnitID: '',
						inventoryType: 'incoming',
					},
				],
				meta: {
					productionLine: {
						id: '',
					},
				},
			},
		])
	}

	const disableCTA = () => {
		for (const entry of productionEntries) {
			let hasIncomingEntry = false
			let hasOutgoingEntry = false

			for (const trace of entry.traces) {
				if (trace.inventoryType === 'incoming') {
					hasIncomingEntry = true
				} else if (trace.inventoryType === 'outgoing') {
					hasOutgoingEntry = true
				}

				if (
					!trace.id ||
					!trace.storageUnitID ||
					((trace.inventoryType === 'incoming' ||
						trace.inventoryType === 'outgoing') &&
						!trace.quantity)
				) {
					return true // Disable the button if any of the required fields is not filled in
				}
			}

			if (!(hasIncomingEntry && hasOutgoingEntry)) {
				return true // Disable the button if an entry lacks either 'incoming' or 'outgoing'
			}
		}

		return false
	}

	return (
		<Box padding={8} width="100%" height="100%">
			<StorageInventoryModal
				show={showModal}
				heading="Storage Inventory"
				data={storageUnits}
				onClose={() => {
					setShowModal(false)
				}}
				size="800px"
				height="940px"
				overflow="auto"
			/>
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			{(refineryLoadingState || productsLoadingState) && (
				<TransparentBgSpinner />
			)}
			<Box
				row
				justifyContent="space-between"
				alignItems="baseline"
				style={{ marginBottom: '15px' }}
			>
				<Box alignItems="flex-start">
					<Box row alignItems="center">
						<Title
							title={`${t('production.title')}`}
							icon={Documents}
						/>
					</Box>
				</Box>
				<Box row justifyContent="flex-end">
					<Box padding={2} margin="0 12px 0 0">
						<Button
							rounded
							customIcon={
								<Icon
									glyph={StorageTankIcon}
									style={{
										height: 24,
										width: 24,
									}}
								/>
							}
							onClick={() => {
								setShowModal(true)
							}}
							extendStyles={{
								border: '1px solid rgb(63,86,196)',
								borderRadius: 4,
							}}
						/>
					</Box>
					<Box style={{ padding: 2 }}>
						<Button
							label={t('production.updateIncomingStorage')}
							rounded
							onClick={() =>
								dispatch(
									MainRouteDuc.creators.switchPage(
										MainRouteDuc.types.REFINERY$SUBROOT,
										{
											rootModule: 'input-storage',
										}
									)
								)
							}
							extendStyles={{
								paddingTop: 8,
								paddingBottom: 8,
							}}
						/>
					</Box>
					<Box style={{ padding: 2 }}>
						<Button
							label={t('production.updateInventory')}
							rounded
							onClick={() => {
								dispatch(
									MainRouteDuc.creators.switchPage(
										MainRouteDuc.types.REFINERY$WACTION,
										{
											rootModule: 'inventory',
											action: 'create',
										}
									)
								)
							}}
							extendStyles={{
								paddingTop: 8,
								paddingBottom: 8,
							}}
						/>
					</Box>
				</Box>
			</Box>
			{productionEntries.map((entry, entryIndex) => (
				<Box
					key={entryIndex}
					padding={8}
					margin="16px 0px"
					style={{ border: '1px solid #cad2dd', borderRadius: 4 }}
				>
					<Spacer size={12} />
					<Box>
						<SelectSearch
							hideError
							name="productionLine"
							returnOnlyValue
							valueKey="id"
							options={modifiedProductionLines}
							labelKey="label"
							defaultLabel={t('common.selectDefaultLabel')}
							placeholder={t('assetsHome.productionLine')}
							onChange={productionLine => {
								handleProductionLineChange(
									productionLine.id,
									entryIndex
								)
							}}
						/>
						<Box
							padding="0px 8px 0px"
							style={{
								color: theme.color.error,
								fontSize: 14,
								alignItems: 'end',
							}}
						>
							* {`${t('production.quantity')}(MT)`}
						</Box>
					</Box>
					<Spacer size={18} />
					<Box
						row
						style={{ display: 'flex' }}
						justifyContent="space-between"
					>
						<Box
							style={{
								padding: '10px',
								backgroundColor: 'rgb(38, 59, 151)',
								width: '49%',
								textAlign: 'center',
								boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
								borderRadius: '4px',
							}}
						>
							<P style={{ color: '#fff', fontSize: 16 }}>
								{t('tdmDetailsEntry.rawMaterials')}
							</P>
						</Box>
						<Box
							style={{
								padding: '10px',
								backgroundColor: 'rgb(38, 59, 151)',
								width: '49%',
								textAlign: 'center',
								boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
								borderRadius: '4px',
							}}
						>
							<P style={{ color: '#fff', fontSize: 16 }}>
								{t('production.finalProd')}
							</P>
						</Box>
					</Box>
					<Box row justifyContent="space-between">
						<Box width="49%" margin="8px 0px">
							<Box>
								{entry.traces.map((rawMaterial, traceIndex) => {
									if (
										rawMaterial.inventoryType === 'incoming'
									) {
										const filteredIncomingStorageUnits = getIncomingStorageUnits(
											rawMaterial.id
										)

										return (
											<Box
												key={traceIndex}
												row
												style={{ margin: '5px 0' }}
												justifyContent="space-between"
											>
												<Box width="30%">
													<SelectSearch
														hideError
														name={`initialRawMaterial-${entryIndex}-${traceIndex}`}
														value={
															productionEntries[
																entryIndex
															].traces[traceIndex]
																.id
														}
														returnOnlyValue
														valueKey="id"
														options={rawMaterials}
														labelKey="label"
														defaultLabel={t(
															'common.selectDefaultLabel'
														)}
														placeholder={t(
															'production.rawMaterial'
														)}
														onChange={product =>
															handleInitialRawMaterialChange(
																product.id,
																entryIndex,
																traceIndex
															)
														}
													/>
												</Box>
												<Box width="30%">
													<SelectSearch
														hideError
														name={`tank-${entryIndex}-${traceIndex}`}
														value={
															productionEntries[
																entryIndex
															].traces[traceIndex]
																.storageUnitID
														}
														returnOnlyValue
														valueKey="id"
														options={
															filteredIncomingStorageUnits
														}
														labelKey="label"
														defaultLabel={t(
															'common.selectDefaultLabel'
														)}
														placeholder={t(
															'products.tank'
														)}
														onChange={storageUnit =>
															handleTankChange(
																storageUnit.id,
																entryIndex,
																traceIndex
															)
														}
													/>
												</Box>
												<Box width="18%">
													<Input
														hideError
														type="number"
														value={
															productionEntries[
																entryIndex
															].traces[traceIndex]
																.quantity
														}
														name={`quantity-${entryIndex}-${traceIndex}`}
														width="fit-content"
														placeholder={t(
															'production.qtyUsed'
														)}
														onChange={e =>
															handleQuantityChange(
																e.target
																	.valueAsNumber,
																entryIndex,
																traceIndex
															)
														}
													/>
												</Box>
												<Box
													row
													width="5.5%"
													style={{
														paddingTop: '8px',
														marginRight: 20,
													}}
												>
													<IconWrapper
														hoverEffect
														width={30}
														height={30}
														onClick={() => {
															handleDeleteRawMaterialRow(
																entryIndex,
																traceIndex
															)
														}}
													>
														<Icon
															glyph={DeleteIcon}
															style={{
																cursor:
																	'pointer',
																width: 25,
																height: 25,
															}}
														/>
													</IconWrapper>
												</Box>
											</Box>
										)
									}

									return null
								})}
							</Box>
							<Box
								padding="16px 8px"
								style={{
									color: '#333',
									fontSize: 14,
									cursor: 'pointer',
								}}
								onClick={() => addRawMaterialRow(entryIndex)}
							>
								+ {t('production.addMoreRawMaterials')}
							</Box>
						</Box>

						<Box width="49%" margin="8px 0px">
							<Box
								row
								style={{ margin: '5px 0' }}
								justifyContent="space-between"
							>
								<Box width="45%">
									<SelectSearch
										hideError
										name="finalProduct"
										returnOnlyValue
										valueKey="id"
										options={modifiedOutgoingProducts}
										labelKey="label"
										defaultLabel={t(
											'common.selectDefaultLabel'
										)}
										placeholder={t(
											'production.finalProduct'
										)}
										onChange={finalProduct => {
											handleFinalProductChange(
												finalProduct.id,
												entryIndex
											)
										}}
									/>
								</Box>
								<Box width="30%">
									<SelectSearch
										hideError
										name="finalTank"
										returnOnlyValue
										valueKey="id"
										options={getOutgoingStorageUnits(
											productionEntries[
												entryIndex
											].traces.find(
												trace =>
													trace.inventoryType ===
													'outgoing'
											)?.id
										)}
										labelKey="label"
										defaultLabel={t(
											'common.selectDefaultLabel'
										)}
										placeholder={t('products.tank')}
										onChange={finalTank => {
											handleFinalTankChange(
												finalTank.id,
												entryIndex
											)
										}}
									/>
								</Box>
								<Box width="20%">
									<Input
										hideError
										type="number"
										name="finalQuantity"
										width="fit-content"
										options={[]}
										placeholder={t('production.quantity')}
										onChange={e => {
											handleFinalQuantityChange(
												e.target.valueAsNumber,
												entryIndex
											)
										}}
									/>
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
			))}
			<Box
				row
				style={{ margin: '10px 0px' }}
				justifyContent="space-between"
			>
				<Box
					padding="16px 8px"
					style={{
						color: '#333',
						fontSize: 14,
						cursor: 'pointer',
					}}
					onClick={addProductionEntry}
				>
					+ {t('production.addProductionEntry')}
				</Box>
				<Button
					label={t('common.save')}
					primary
					disabled={disableCTA()}
					rounded
					extendStyles={{ width: 200 }}
					onClick={() => {
						dispatch(
							RefineryDuc.creators.mapProduction(
								productionEntries,
								t('common.successUpdate')
							)
						)
					}}
				/>
			</Box>
			<Spacer size={20} />
			<Table
				noRoundedBorder
				columnConfig={getColumnConfig({
					allProducts,
					storageUnits,
					t,
				})}
				rowData={transformed || []}
			/>
		</Box>
	)
}

export { ProductionDaily }
