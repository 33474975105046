import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { RefineryDuc } from 'core-app/modules/Refinery/duc'
import { Title } from 'core-app/shared/components/Title'
import { BoxShadowTable } from 'core-app/shared/components/BoxShadowTable'
import { AssignTankForm } from 'core-app/modules/Refinery/components/AssignTankForm'
import { ShowMore } from 'core-app/modules/App/components/ShowMore'
import { Modal } from 'ui-lib/components/Modal'
import { TransparentBgSpinner } from 'ui-lib/components/Spinner/TransparentBgSpinner'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import BackArrowIcon from 'ui-lib/icons/arrow-back.svg'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { getStorageInputConfig } from 'core-app/modules/Refinery/components/Columns'
import { getIn } from 'timm'

const getBreadCrumbsList = t => [
	{
		label: t('breadcrumb.home'),
		name: 'home',
		isActive: true,
	},
	{
		label: t('breadcrumb.production'),
		name: 'production',
		isActive: true,
	},
	{
		label: t('breadcrumb.inputStorage'),
		name: 'inputStorage',
		isActive: false,
	},
]

const StorageListing = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const activeDocuments = useSelector(
		RefineryDuc.selectors.getActiveDocuments
	)
	const [showModal, setShowModal] = useState(false)

	const [productDetails, setProductDetails] = useState({})
	const [currentEntity, setCurrentEntity] = useState('')
	const [storageTanks, setStorageTanks] = useState([])
	const [currentProducedQty, setCurrentProducedQty] = useState(0)
	const [_supplyChainModel, setSupplyChainModel] = useState('')

	const storageList = useSelector(AuthDuc.selectors.getStorageUnits)

	const paginationConfig = useSelector(
		RefineryDuc.selectors.getStorageListingPaginationConfig
	)

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.REFINERY$SUBROOT,
					{ rootModule: 'production' }
				)
			)
		}
		if (target === 'production') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.REFINERY$SUBROOT,
					{
						rootModule: 'production',
					}
				)
			)
		}
	}

	const assignTank = ({
		currentRow,
		product,
		productID,
		qtyWithUOM,
		quantity,
		supplyChainModel,
		entityID,
		certification,
	}) => {
		const tankDetails =
			getIn(activeDocuments, [currentRow, 'storageUnits']) || []
		setStorageTanks(tankDetails)
		setShowModal(!showModal)
		setCurrentProducedQty(quantity)
		setCurrentEntity(entityID)
		setSupplyChainModel(supplyChainModel)
		setProductDetails({
			product,
			productID,
			quantity: qtyWithUOM,
			certification,
		})
	}

	const handleClose = () => {
		setShowModal(false)
	}

	const viewDetails = (receiverTraceIDs, consignmentNumber, issueDate) => {
		dispatch(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.REFINERY$WACTION,
				{ rootModule: 'input-storage', action: 'view' },
				{
					parentRef: receiverTraceIDs,
					consignmentNumber,
					issueDate,
				}
			)
		)
	}

	const handleLoadMore = () => {
		const paginationQuery = {
			nextStartID: paginationConfig.nextStartID,
		}
		dispatch(RefineryDuc.creators.fetchStorageListing(paginationQuery))
	}

	return (
		<Box padding={8} width="100%" height="100%">
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={getBreadCrumbsList(t)}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Box row justifyContent="space-between" alignItems="baseline">
				<Box row style={{ marginTop: '10px' }}>
					<Box>
						<IconWrapper
							size={30}
							onClick={() =>
								dispatch(
									MainRouteDuc.creators.switchPage(
										MainRouteDuc.types.REFINERY$SUBROOT,
										{ rootModule: 'production' }
									)
								)
							}
						>
							<Icon glyph={BackArrowIcon} />
						</IconWrapper>
					</Box>
					<Spacer size={8} horizontal />
					<Title
						small
						style={{ marginTop: 0, marginBottom: 0 }}
						title={t('production.inputStorage')}
						note={t('production.inputStorageNote')}
					/>
				</Box>
			</Box>

			<Spacer size={24} />
			{!activeDocuments && <TransparentBgSpinner />}
			<Box>
				<Spacer size={24} />
				<BoxShadowTable
					size="large"
					columnConfig={getStorageInputConfig({
						type: 'plot',
						docType: 'plot',
						assignTank,
						viewDetails,
						t,
					})}
					rowData={activeDocuments}
				/>
				<ShowMore
					documents={activeDocuments}
					total={paginationConfig.total}
					handleChange={handleLoadMore}
					type="entity_pagination"
				/>

				<Modal
					noPadding
					show={showModal}
					size="large"
					body={
						<AssignTankForm
							activeTanks={storageTanks}
							storageList={storageList}
							onClose={handleClose}
							producedQty={currentProducedQty}
							productDetails={productDetails}
							certificationType={productDetails.certification}
							isIncomingStorage
							supplyChainModel={_supplyChainModel}
							onChange={values => {
								dispatch(
									RefineryDuc.creators.assignTank(
										values.storage,
										_supplyChainModel,
										productDetails.productID,
										currentEntity,
										t('production.assignTankSuccessToast')
									)
								)
								setShowModal(false)
							}}
						/>
					}
					hideButtons
					onClose={handleClose}
				/>
			</Box>
		</Box>
	)
}

export { StorageListing }
