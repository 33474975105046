import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NotFoundBlock } from 'core-app/modules/App/components/Error'
import { AdminDuc } from './duc'
import { MainDashboard } from './containers/MainDashboard'
import SubDashboard from './containers/SubDashboard'
import { AssetManagement } from './containers/AssetManagement'
import { EmployeeManagement } from './containers/EmployeeManagement'
import { WebTour } from '../WebTour'
import { WebTourDuc } from '../WebTour/duc'
import { CollectionPoint } from './containers/CollectionPoint'

const modulesMap = {
	dashboard: MainDashboard,
	subDashboard: SubDashboard,
	assetManagement: AssetManagement,
	employeeManagement: EmployeeManagement,
	collectionPoint: CollectionPoint,
}

const Admin = () => {
	const dispatch = useDispatch()
	const activeModule = useSelector(AdminDuc.selectors.activeModule)
	const Component = modulesMap[activeModule] || NotFoundBlock

	// if we are here, we have a submodule, route it to the module
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)
	useEffect(() => {
		if (tourModule === 'admin') {
			dispatch(WebTourDuc.creators.setAdminVisit(true))
		}
		if (tourModule === 'assetMgmtHeader') {
			dispatch(WebTourDuc.creators.setAssetsUpdate(true))
		}
		if (tourModule === 'employeeMgmtHeader') {
			dispatch(WebTourDuc.creators.setEmployeeUpdate(true))
		}
		if (tourModule === 'companyInfoHeader') {
			dispatch(WebTourDuc.creators.setCompanyInfoUpdate(true))
		}
	}, [tourModule, dispatch])

	return (
		<>
			<Component />
			{(tourModule === 'admin' ||
				tourModule === 'assetMgmtHeader' ||
				tourModule === 'employeeMgmtHeader' ||
				tourModule === 'companyInfoHeader') && <WebTour />}
		</>
	)
}

export default Admin
