import { all, takeLatest, put, call, select } from 'redux-saga/effects'
import LogHelper from 'core-app/utils/logger'
import { AppDuc } from 'core-app/modules/App/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { PlantationDuc } from 'core-app/modules/Plantation/duc'
import { CookieDuc } from 'core-app/modules/App/cookieDuc'
import { USER_COOKIE_KEYS } from 'ui-lib/utils/config'
import request from 'core-app/utils/request'
import { Toast } from 'ui-lib/components/Toast'
import { MainRouteDuc } from 'core-app/routes/duc'
import {
	CallWithRefreshCheck,
	getOrgIDFromLoggedUser,
	fetchOrgDetailsCount,
} from 'core-app/modules/Auth/AuthSaga'
import { isEmptyObject, transformDataforPieChart } from 'core-app/utils/helpers'
import { getIn, omit, merge } from 'timm'
import querySerializer from 'query-string'
import {
	getPlantationEndPoint,
	getCoreEndPoint,
	getInsightsEndPoint,
} from 'core-app/config'
import {
	transformFilterStringsToBEQueries,
	transformSortStringsToBEQueries,
	extractFilterQueries,
	extractSortQueries,
} from 'core-app/shared/helpers'

const logger = LogHelper('client:plantationSaga')

export const responseActiveFilterMapper = (targetTypes, responses) =>
	targetTypes.reduce((agg, _type, index) => {
		const aggregator = agg
		aggregator[_type] = responses[index] || []

		return aggregator
	}, {})

function* fetchInsights() {
	try {
		yield put(AppDuc.creators.showGlobalLoader('fetch-plantation-insights'))
		yield fetchOrgDetailsCount()

		const requestUrl = `${getInsightsEndPoint()}dashboard/plantations/insights`
		const data = yield CallWithRefreshCheck(requestUrl)
		const userInsight = getIn(data, ['data']) || {}
		const blocks = yield transformDataforPieChart(
			userInsight.blocks,
			'best-blocks'
		)

		const finaltransFormedInsight = {
			...userInsight,
			blocks,
		}

		yield put(
			PlantationDuc.creators.fetchInsightsSuccess(finaltransFormedInsight)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-plantation-insights'))
	}
}

const PAGINATION_LIMIT = 50

function* fetchListingTickets(action) {
	try {
		yield put(AuthDuc.creators.fetchAllProducts())
		const { docType, locationState = {}, skipGlobalLoader } = action

		if (!skipGlobalLoader)
			yield put(
				AppDuc.creators.showGlobalLoader('checker-ticket-listing')
			)

		const { type, payload, query } = locationState
		const existingQueryFromUrl = query || {}

		let frontendTargetQuery = {} // this would appear in search bar
		let backendTargetQuery = {} // this would go to backend api call

		const orgID = yield getOrgIDFromLoggedUser()

		yield put(PlantationDuc.creators.setTicketLoading('ticket'))

		const paginationQuery = {
			activeIndex: existingQueryFromUrl.activeIndex
				? existingQueryFromUrl.activeIndex
				: 0,
			limit: Math.min(
				existingQueryFromUrl.limit || PAGINATION_LIMIT,
				PAGINATION_LIMIT
			),
			nextIndex: existingQueryFromUrl.nextIndex,
		}

		// prepare backend query based on the pagination factors
		if (paginationQuery.limit) {
			backendTargetQuery.limit = paginationQuery.limit
		}

		if (paginationQuery.activeIndex > 0 && paginationQuery.nextIndex) {
			backendTargetQuery.startID = paginationQuery.nextIndex
		}

		const searchQuery = existingQueryFromUrl.q || ''

		const filterQueries =
			omit(existingQueryFromUrl, [
				'sort',
				'q',
				'activeIndex',
				'limit',
				'nextIndex',
			]) || {}

		if (!isEmptyObject(filterQueries)) {
			// form the backend queries form the object
			backendTargetQuery = merge(
				backendTargetQuery,
				transformFilterStringsToBEQueries(filterQueries)
			)
		}
		const __query = {
			...backendTargetQuery,
			receivingPartyID: orgID,
		}

		const _recordQuery = existingQueryFromUrl.ticket || []

		const _sortKeys = Array.isArray(_recordQuery)
			? _recordQuery
			: [_recordQuery]

		const sortQuery =
			_sortKeys && transformSortStringsToBEQueries(_sortKeys)

		yield put(PlantationDuc.creators.setActiveSorts(existingQueryFromUrl))

		// based on the selection create the blocks to call.
		const _sortQuery = getIn(sortQuery, [docType]) || ''

		const requestUrl = `${getPlantationEndPoint()}plantation/tickets?type=${docType}&${querySerializer.stringify(
			{
				...__query,
				...{ sort: _sortQuery },
				...(searchQuery && { search: searchQuery }),
			}
		)}`

		const origResponse = yield CallWithRefreshCheck(requestUrl)
		const response = getIn(origResponse, ['data']) || []
		const serverPaginationQuery = {
			activeIndex: paginationQuery.activeIndex // &&
				? paginationQuery.activeIndex
				: 0,
			limit: Math.min(getIn(response, ['pageSize']) || PAGINATION_LIMIT),
			nextIndex: getIn(response, ['lastID']),
		}
		// extract pagination queries from response
		yield put(
			PlantationDuc.creators.setPaginationEntries(
				serverPaginationQuery.activeIndex,
				getIn(response, ['pageSize']),
				getIn(response, ['total']),
				getIn(response, ['prevStartID']),
				getIn(response, ['nextStartID'])
			)
		)
		// extract filter queries
		const { queryTree, stateTree } = extractFilterQueries(response)

		yield put(PlantationDuc.creators.setActiveListingFilters(stateTree))

		frontendTargetQuery = merge(
			frontendTargetQuery,
			queryTree,
			serverPaginationQuery
		)

		yield put(PlantationDuc.creators.fetchListingTicketsSuccess(response))
		const sortQueriesFromBE = extractSortQueries(response, _sortKeys)
		if (sortQueriesFromBE.length) {
			frontendTargetQuery.ticket = sortQueriesFromBE
		}
		if (!isEmptyObject(frontendTargetQuery)) {
			yield put(
				PlantationDuc.creators.setActiveSorts(frontendTargetQuery)
			)
			// we have some query, lets update the url to reflect that.
			yield put(
				MainRouteDuc.creators.redirect(
					type,
					payload,
					frontendTargetQuery,
					{
						skipRouteThunk: true,
					}
				)
			)
		}
	} catch (e) {
		logger.error(e)
	} finally {
		yield put(PlantationDuc.creators.resetTicketLoading())
		yield put(AppDuc.creators.hideGlobalLoader('checker-ticket-listing'))
	}
}

function* createTicket(value, createSuccessToast, updateSuccessToast) {
	const {
		values: {
			createdDate,
			harvester,
			ffbCount,
			ripeBunches,
			overripeBunches,
			underripeBunches,
			emptyBunches,
			wetBunches,
			unripeBunches,
			area,
			block,
			phase,
		},
	} = value
	try {
		let requestUrl
		let options
		let message

		yield put(
			CookieDuc.creators.getCookie({
				cookieName: USER_COOKIE_KEYS.PRODUCT_ID,
			})
		)
		const cookies = yield select(CookieDuc.selectors.cookies)

		const productID = getIn(cookies, ['userPreferredProduct'])
		const values = {
			assigneeID: harvester,
			date: createdDate,
			ffbCount,
			ffbDetails: {
				ripeBunches,
				overripeBunches,
				underripeBunches,
				emptyBunches,
				wetBunches,
				unripeBunches,
				location: {
					area,
					block,
					phase,
				},
			},
			meta: {
				productID,
			},
		}
		const { payload = {} } = yield select(PlantationDuc.selectors.location)
		const { action } = payload
		if (action === 'edit') {
			const { documentReference } = payload
			requestUrl = `${getPlantationEndPoint()}plantation/tickets/${documentReference}`
			options = {
				method: 'PUT',
				body: JSON.stringify(values),
			}
			message = updateSuccessToast
		} else if (action === 'create') {
			requestUrl = `${getPlantationEndPoint()}plantation/tickets`

			options = {
				method: 'POST',
				body: JSON.stringify(values),
			}
			message = createSuccessToast
		}

		const { data } = yield call(request, requestUrl, options)
		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)
		Toast({
			type: 'success',
			message,
			isMobile: isMobile || isTablet,
		})
		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.PLANTATION$DOCUMENTREFERENCE,
				{
					rootModule: 'ticket',
					action: 'view',
					documentReference: data.id,
				}
			)
		)
	} catch (err) {
		logger.log(err)
	}
}

function* ticketPreview(action) {
	try {
		const { ticketID, documentType } = action
		yield put(AppDuc.creators.showGlobalLoader('ticket-preview'))
		const requestUrl =
			documentType === 'ticket'
				? `${getPlantationEndPoint()}plantation/checker/tickets/${ticketID}`
				: `${getPlantationEndPoint()}plantation/tickets/${ticketID}`
		const options = {
			method: 'GET',
		}
		const { data = {} } = yield call(request, requestUrl, options)

		const users = yield select(AuthDuc.selectors.getOrgUsers)

		const orgID = yield getOrgIDFromLoggedUser()
		const productUrl = `${getCoreEndPoint()}clients/organizations/${orgID}/products?limit=100`
		const { data: _productData = {} } = yield CallWithRefreshCheck(
			productUrl
		)

		const product = _productData.list.filter(
			prod => prod.product.id === data.meta.productID
		)

		const productName = getIn(product, [0, 'product', 'name'])

		const assignee = users.filter(user => user.id === data.createdBy)

		const assigneeName = getIn(assignee, [0, 'fullName'])
		const userStatus = getIn(assignee, [0, 'status', 'state'])
		const harvesterID = getIn(assignee, [0, 'meta', 'employeeData', 'id'])
		const ticketInfo = {
			id: data.id,
			date: data.date,
			organizationID: data.organizationID,
			assigneeName,
			number: documentType === 'ticket' ? data.cCode : data.hCode,
			cCode: getIn(data, ['cCode']) || '',
			hCode: getIn(data, ['hCode']) || '',
			ffbCount: data.ffbCount,
			ripeBunches: data.ffbDetails.ripeBunches,
			overripeBunches: data.ffbDetails.overripeBunches,
			underripeBunches: data.ffbDetails.underripeBunches,
			emptyBunches: data.ffbDetails.emptyBunches,
			wetBunches: data.ffbDetails.wetBunches,
			unripeBunches: data.ffbDetails.unripeBunches,
			area: data.ffbDetails.location.area,
			phase: data.ffbDetails.location.phase,
			block: data.ffbDetails.location.block,
			createdBy: data.createdBy,
			treeNumber: getIn(data, ['meta', 'other', 'treeNumber']),
			uom: getIn(data, ['meta', 'uom']),
			harvesterID,
			quantity: `${data.ffbCount} ${getIn(data, ['meta', 'uom'])}`,
			product: productName,
			userStatus,
			isSynced: getIn(data, ['meta', 'dataSyncEnabled']),
		}
		yield put(PlantationDuc.creators.getTicketInfo(ticketInfo))
	} catch (err) {
		logger.log(err)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('ticket-preview'))
	}
}

function* tripPreview(action) {
	try {
		const { tripID } = action
		yield put(AppDuc.creators.showGlobalLoader('ticket-preview'))
		const requestUrl = `${getCoreEndPoint()}organizations/-/trips/${tripID}`
		const options = {
			method: 'GET',
		}
		const { data = {} } = yield call(request, requestUrl, options)

		const users = yield select(AuthDuc.selectors.getOrgUsers)
		const partners = yield select(AuthDuc.selectors.getTargetPartnersOfUser)

		const partnerID = getIn(data, ['meta', 'receiverOrgID'])
		const partner = partners.filter(prod => prod.id === partnerID)

		const partnerName = getIn(partner, [0, 'name'])
		const orgID = yield getOrgIDFromLoggedUser()
		const productUrl = `${getCoreEndPoint()}clients/organizations/${orgID}/products?limit=100`
		const { data: _productData = {} } = yield CallWithRefreshCheck(
			productUrl
		)
		const productID = getIn(data, ['meta', 'storageDetails', 'productID'])
		const product = _productData.list.filter(
			prod => prod.product.id === productID
		)

		const productName = getIn(product, [0, 'product', 'name'])

		const assignee = users.filter(user => user.id === data.clientID)

		const assigneeName = getIn(assignee, [0, 'fullName'])
		const userStatus = getIn(assignee, [0, 'status', 'state'])

		const startTime = new Date(getIn(data, ['startTime']))
		const endTime = new Date(getIn(data, ['endTime']))

		let timeDiff = (endTime.getTime() - startTime.getTime()) / 1000
		timeDiff /= 60
		const hours = Math.trunc(timeDiff / 60)
		const minutes = Math.round(timeDiff % 60)
		const entityID = getIn(data, ['meta', 'entityIDs', 0])
		let _entityData = {}
		if (entityID && getIn(data, ['meta', 'storageDetailsSynced'])) {
			const entityRequestUrl = `${getPlantationEndPoint()}entities/${entityID}`
			const { data: entityData = {} } = yield CallWithRefreshCheck(
				entityRequestUrl
			)
			_entityData = entityData
		}

		const tripInfo = {
			id: data.id,
			date: data.createdAt,
			assigneeName,
			number: data.number,
			vehicleNumber: getIn(data, ['meta', 'vehicle', 'number']),
			vehicleID: getIn(data, ['meta', 'vehicle', 'id']),
			quantity: `${getIn(data, ['meta', 'storageDetails', 'quantity']) ||
				'NA'} ${getIn(data, ['meta', 'storageDetails', 'uom'])}`,
			product: productName,
			dropPoint: partnerName,
			tripStatus: getIn(data, ['status']),
			startTime: getIn(data, ['startTime']),
			endTime: getIn(data, ['endTime']),
			tripDuration: timeDiff > 0 ? `${hours}h ${minutes}m` : '---',
			userStatus,
			entityID,
			entityNumber: getIn(_entityData, ['number']) || 'NA',
			binID: getIn(data, ['meta', 'storageDetails', 'ticketGroupID']),
			binName: getIn(data, ['meta', 'storageDetails', 'name']),
			tCode: getIn(data, ['meta', 'tCode']),
			isSynced: getIn(data, ['meta', 'storageDetailsSynced']),
		}

		yield put(PlantationDuc.creators.getTripInfo(tripInfo))
	} catch (err) {
		logger.log(err)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('ticket-preview'))
	}
}

function* fetchTicketInitialValues(action) {
	try {
		yield put(AuthDuc.creators.fetchAllProducts())

		const { ticketID } = action

		const requestUrl = `${getPlantationEndPoint()}plantation/tickets/${ticketID}`
		const options = {
			method: 'GET',
		}
		const { data } = yield call(request, requestUrl, options)
		const ticketInitialValues = {
			harvester: data.assigneeID,
			ffbCount: data.ffbCount,
			ripeBunches: data.ffbDetails.ripeBunches,
			overripeBunches: data.ffbDetails.overripeBunches,
			underripeBunches: data.ffbDetails.underripeBunches,
			emptyBunches: data.ffbDetails.emptyBunches,
			wetBunches: data.ffbDetails.wetBunches,
			unripeBunches: data.ffbDetails.unripeBunches,
			area: data.ffbDetails.location.area,
			phase: data.ffbDetails.location.phase,
			block: data.ffbDetails.location.block,
		}
		const activeProductID = getIn(data, ['meta', 'productID'])
		yield put(PlantationDuc.creators.setActiveProductID(activeProductID))

		yield put(PlantationDuc.creators.setInitialValues(ticketInitialValues))
	} catch (err) {
		logger.log(err)
	}
}

function* createBin(action) {
	const {
		values: {
			createdDate,
			vehicleType,
			vehicleNumber,
			crewMember,
			binNumber,
			tickets,
		},
		successToast,
	} = action
	try {
		const ticketList = tickets.map(ticket => {
			return { id: ticket }
		})
		const values = {
			name: binNumber,
			type: 'bin',
			tickets: ticketList,
			date: createdDate,
			meta: {
				other: {
					vehicleType,
					vehicleNumber,
					crewMember,
				},
			},
		}
		let requestUrl
		let options
		let message

		const { payload = {} } = yield select(PlantationDuc.selectors.location)
		const { action: _action } = payload
		if (_action === 'edit') {
			const { documentReference } = payload
			requestUrl = `${getPlantationEndPoint()}plantation/ticketgroups/${documentReference}`
			options = {
				method: 'PUT',
				body: JSON.stringify(values),
			}
			message = 'Successfully Updated'
		} else if (_action === 'create') {
			requestUrl = `${getPlantationEndPoint()}plantation/ticketgroups`

			options = {
				method: 'POST',
				body: JSON.stringify(values),
			}
			message = successToast
		}

		const { data } = yield call(request, requestUrl, options)
		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)

		Toast({
			type: 'success',
			message,
			isMobile: isMobile || isTablet,
		})
		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.PLANTATION$DOCUMENTREFERENCE,
				{
					rootModule: 'bin',
					action: 'view',
					documentReference: data.id,
				}
			)
		)
	} catch (err) {
		logger.log(err)
	}
}

function* fetchListingBins(action) {
	try {
		yield put(AuthDuc.creators.fetchAllProducts())

		const { docType, locationState = {}, skipGlobalLoader } = action

		if (!skipGlobalLoader)
			yield put(AppDuc.creators.showGlobalLoader('tractor-bin-listing'))

		const { type, payload, query } = locationState

		const existingQueryFromUrl = query || {}

		let frontendTargetQuery = {} // this would appear in search bar
		let backendTargetQuery = {} // this would go to backend api call

		const orgID = yield getOrgIDFromLoggedUser()

		yield put(PlantationDuc.creators.setBinLoading('bin'))

		const paginationQuery = {
			activeIndex: existingQueryFromUrl.activeIndex
				? existingQueryFromUrl.activeIndex
				: 0,
			limit: Math.min(
				existingQueryFromUrl.limit || PAGINATION_LIMIT,
				PAGINATION_LIMIT
			),
			nextIndex: existingQueryFromUrl.nextIndex,
		}

		// prepare backend query based on the pagination factors
		if (paginationQuery.limit) {
			backendTargetQuery.limit = paginationQuery.limit
		}

		if (paginationQuery.activeIndex > 0 && paginationQuery.nextIndex) {
			backendTargetQuery.startID = paginationQuery.nextIndex
		}

		const searchQuery = existingQueryFromUrl.q || ''

		const filterQueries =
			omit(existingQueryFromUrl, [
				'sort',
				'q',
				'activeIndex',
				'limit',
				'nextIndex',
			]) || {}

		if (!isEmptyObject(filterQueries)) {
			// form the backend queries form the object
			backendTargetQuery = merge(
				backendTargetQuery,
				transformFilterStringsToBEQueries(filterQueries)
			)
		}

		const __query = {
			...backendTargetQuery,
			receivingPartyID: orgID,
		}
		const _recordQuery = existingQueryFromUrl.bin || []

		const _sortKeys = Array.isArray(_recordQuery)
			? _recordQuery
			: [_recordQuery]

		const sortQuery =
			_sortKeys && transformSortStringsToBEQueries(_sortKeys)
		yield put(
			PlantationDuc.creators.setBinActiveSorts(existingQueryFromUrl)
		)

		const _sortQuery = getIn(sortQuery, [docType]) || ''
		// based on the selection create the blocks to call.
		const requestUrl = `${getPlantationEndPoint()}plantation/ticketgroups?type=${docType}&${querySerializer.stringify(
			{
				...__query,
				...{ sort: _sortQuery },
				...(searchQuery && { search: searchQuery }),
			}
		)}`

		const origResponse = yield CallWithRefreshCheck(requestUrl)
		const response = getIn(origResponse, ['data']) || []
		const serverPaginationQuery = {
			activeIndex: paginationQuery.activeIndex // &&
				? paginationQuery.activeIndex
				: 0,
			limit: Math.min(getIn(response, ['pageSize']) || PAGINATION_LIMIT),
			nextIndex: getIn(response, ['lastID']),
		}
		// extract pagination queries from response
		yield put(
			PlantationDuc.creators.setBinPaginationEntries(
				serverPaginationQuery.activeIndex,
				getIn(response, ['pageSize']),
				getIn(response, ['total']),
				getIn(response, ['prevStartID']),
				getIn(response, ['nextStartID'])
			)
		)
		// extract filter queries
		const { queryTree, stateTree } = extractFilterQueries(response)

		yield put(PlantationDuc.creators.setBinActiveListingFilters(stateTree))

		frontendTargetQuery = merge(
			frontendTargetQuery,
			queryTree,
			serverPaginationQuery
		)

		yield put(PlantationDuc.creators.fetchListingBinsSuccess(response))
		const sortQueriesFromBE = extractSortQueries(response, _sortKeys)
		if (sortQueriesFromBE.length) {
			frontendTargetQuery.bin = sortQueriesFromBE
		}
		if (!isEmptyObject(frontendTargetQuery)) {
			yield put(
				PlantationDuc.creators.setBinActiveSorts(frontendTargetQuery)
			)
			// we have some query, lets update the url to reflect that.
			yield put(
				MainRouteDuc.creators.redirect(
					type,
					payload,
					frontendTargetQuery,
					{
						skipRouteThunk: true,
					}
				)
			)
		}
	} catch (e) {
		logger.error(e)
	} finally {
		yield put(PlantationDuc.creators.resetBinLoading())
		yield put(AppDuc.creators.hideGlobalLoader('tractor-bin-listing'))
	}
}

function* binPreview(action) {
	try {
		const { documentReference } = action
		yield put(AppDuc.creators.showGlobalLoader('bin-preview'))
		const requestUrl = `${getPlantationEndPoint()}plantation/ticketgroups/${documentReference}`
		const { data } = yield call(request, requestUrl)
		const binComplete = getIn(data, ['meta', 'other', 'binMarked']) || false
		const totalQuantity = data.tickets
			.map(ticket => ticket.ffbCount)
			.reduce((ttl, ticket) => ticket + ttl)
		const response = {
			id: data.id,
			name: data.name,
			tickets: data.tickets,
			isDOCreated: data.locked,
			numberOfTickets: data.tickets.length,
			quantity: totalQuantity,
			binComplete,
			isSynced: getIn(data, ['meta', 'dataSyncEnabled']),
		}

		yield put(PlantationDuc.creators.getBinInfo(response))
	} catch (err) {
		logger.log(err)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('bin-preview'))
	}
}

function* fetchBinTickets(action) {
	try {
		yield put(AuthDuc.creators.fetchAllProducts())

		const { skipGlobalLoader } = action
		if (!skipGlobalLoader)
			yield put(
				AppDuc.creators.showGlobalLoader('tractor-ticket-listing')
			)
		yield put(PlantationDuc.creators.setBinLoading('bin', 'meta'))
		// Fetch tractor information for that day so don't need to enter every time second time it will fetch from previous one

		const _sortKeysForm = ['sort|createdAt|desc']

		const today = new Date(new Date().setHours(5, 30, 0, 0)).toISOString()
		const _sortKeysFormDate = [`createdAt|${today}|gt`]

		const sortFormQueryDate =
			_sortKeysFormDate &&
			transformSortStringsToBEQueries(_sortKeysFormDate)
		const _sortFormQueryDate = getIn(sortFormQueryDate, ['createdAt']) || ''

		const sortFormQuery =
			_sortKeysForm && transformSortStringsToBEQueries(_sortKeysForm)
		const _sortFormQuery = getIn(sortFormQuery, ['sort']) || ''

		const formValuesUrl = `${getPlantationEndPoint()}plantation/ticketgroups?${querySerializer.stringify(
			{
				sort: _sortFormQuery,
				limit: '1',
				createdBy: 'user-K8K1Kb8tKdRAeAOi',
				createdAt: _sortFormQueryDate,
			}
		)}`
		const { data } = yield call(request, formValuesUrl)
		const responseForm = getIn(data, ['list']) || ''
		const binInitial =
			responseForm === ''
				? {
						createdDate: new Date(),
						vehicleType: '',
						vehicleNumber: '',
						crewMember: '',
						binNumber: '',
						tickets: [],
				  }
				: {
						createdDate: new Date(),
						vehicleType: responseForm[0].meta.other.vehicleType,
						vehicleNumber: responseForm[0].meta.other.vehicleNumber,
						crewMember: responseForm[0].meta.other.crewMember,
						binNumber: '',
						tickets: [],
				  }
		yield put(PlantationDuc.creators.setBinInitialValues(binInitial))

		const _sortKeys = ['meta|groupID|eq']

		const sortQuery =
			_sortKeys && transformSortStringsToBEQueries(_sortKeys)

		const _sortQuery = getIn(sortQuery, ['meta']) || ''
		const requestUrl = `${getPlantationEndPoint()}plantation/tickets?${querySerializer.stringify(
			{ meta: _sortQuery }
		)}`
		const origResponse = yield CallWithRefreshCheck(requestUrl)
		const response = getIn(origResponse, ['data']) || []
		const tickets = {
			assigned: [],
			unAssigned: response.list,
		}
		yield put(PlantationDuc.creators.fetchBinTicketsSuccess(tickets))
	} catch (e) {
		logger.error(e)
	} finally {
		yield put(PlantationDuc.creators.resetBinLoading())
		yield put(AppDuc.creators.hideGlobalLoader('tractor-ticket-listing'))
	}
}

function* fetchBinInfo(action) {
	try {
		const { documentReference } = action

		const requestUrl = `${getPlantationEndPoint()}plantation/ticketgroups/${documentReference}`

		const { data } = yield call(request, requestUrl)
		const binResponse = getIn(data, ['meta', 'other']) || ''
		const assignedTickets = data.tickets.map(ticket => ticket.id)
		const response = {
			vehicleType: binResponse.vehicleType,
			vehicleNumber: binResponse.vehicleNumber,
			crewMember: binResponse.crewMember,
			binNumber: data.name,
			tickets: assignedTickets,
		}
		const _sortKeys = ['meta|groupID|eq']

		const sortQuery =
			_sortKeys && transformSortStringsToBEQueries(_sortKeys)

		const _sortQuery = getIn(sortQuery, ['meta']) || ''
		const ticketRequestUrl = `${getPlantationEndPoint()}plantation/tickets?${querySerializer.stringify(
			{ meta: _sortQuery }
		)}`
		const origResponse = yield CallWithRefreshCheck(ticketRequestUrl)
		const ticketResponse = getIn(origResponse, ['data']) || []
		const tickets = {
			assigned: data.tickets,
			unAssigned: ticketResponse.list,
		}
		yield put(PlantationDuc.creators.fetchBinTicketsSuccess(tickets))

		yield put(PlantationDuc.creators.fetchBinInfoSuccess(response))
	} catch (err) {
		logger.log(err)
	}
}

function* markedBin(action) {
	const { bin } = action
	try {
		const values = {
			meta: {
				other: {
					binMarked: true,
					date: new Date(),
				},
			},
		}
		const options = {
			method: 'PUT',
			body: JSON.stringify(values),
		}
		const requestUrl = `${getPlantationEndPoint()}plantation/ticketgroups/${bin}`

		const { data } = yield call(request, requestUrl, options)
		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.PLANTATION$DOCUMENTREFERENCE,
				{
					rootModule: 'bin',
					action: 'view',
					documentReference: data.id,
				}
			)
		)
	} catch (err) {
		logger.log(err)
	}
}

function* fetchDocuments(action) {
	try {
		const { docType, query } = action

		yield put(AppDuc.creators.showGlobalLoader('document-listing'))

		const INITIAL_TYPES = [
			'harvester',
			'checker',
			'tractorOperator',
			'driver',
		]

		let harvester = `${getPlantationEndPoint()}plantation/tickets?limit=100&`
		let checker = `${getPlantationEndPoint()}plantation/checker/tickets?limit=100&`
		let tractorOperator = `${getPlantationEndPoint()}v2/plantation/ticketgroups?limit=100&`
		let driver = `${getCoreEndPoint()}organizations/-/trips?limit=100&`

		let URLs = []

		if (docType === '') {
			URLs = [harvester, checker, tractorOperator, driver]
		} else if (docType === 'harvester') {
			harvester += `${query}`
			URLs = [harvester]
		} else if (docType === 'checker') {
			checker += `${query}`
			URLs = [checker]
		} else if (docType === 'tractorOperator') {
			tractorOperator += `${query}`
			URLs = [tractorOperator]
		} else if (docType === 'driver') {
			driver += `${query}`
			URLs = [driver]
		}

		const callsPipeLine = URLs.map(url => {
			return CallWithRefreshCheck(url)
		})

		const origResponse = yield all(callsPipeLine)

		const responses = {}

		if (docType === '') {
			INITIAL_TYPES.forEach((_type, key) => {
				responses[_type] =
					getIn(origResponse[key], ['data', 'list']) || []
			})
		} else {
			const typeIndex = INITIAL_TYPES.indexOf(docType)
			responses[docType] = getIn(origResponse[0], ['data', 'list']) || []
			INITIAL_TYPES.slice(typeIndex + 1).forEach(_type => {
				responses[_type] = []
			})
			INITIAL_TYPES.slice(0, typeIndex).forEach(_type => {
				responses[_type] = []
			})
		}

		const pagination = INITIAL_TYPES.reduce((agg, _type, key) => {
			const aggregator = agg
			aggregator[_type] = {}
			aggregator[_type].nextStartID =
				getIn(origResponse[key], ['data', 'nextStartID']) || ''
			aggregator[_type].total =
				getIn(origResponse[key], ['data', 'total']) || ''

			return aggregator
		}, {})

		responses.syncStatus = [
			{
				entityNumber: 'DO-WOF-006-20230104',
				driver: true,
				tractorOperator: true,
				checker: false,
				harvester: true,
			},
			{
				entityNumber: 'DO-POI-006-20230103',
				driver: true,
				tractorOperator: true,
				checker: true,
				harvester: true,
			},
			{
				entityNumber: 'DO-OIL-005-20230103',
				driver: true,
				tractorOperator: true,
				checker: true,
				harvester: false,
			},
			{
				entityNumber: '',
				driver: false,
				tractorOperator: true,
				checker: false,
				harvester: true,
			},
			{
				entityNumber: '',
				driver: false,
				tractorOperator: true,
				checker: true,
				harvester: true,
			},
			{
				entityNumber: 'DO-WOW-004-20230101',
				driver: true,
				tractorOperator: true,
				checker: true,
				harvester: true,
			},
			{
				entityNumber: 'DO-POP-003-20221225',
				driver: true,
				tractorOperator: true,
				checker: false,
				harvester: true,
			},
			{
				entityNumber: 'DO-NBN-003-20221222',
				driver: true,
				tractorOperator: true,
				checker: false,
				harvester: true,
			},
			{
				entityNumber: 'DO-KKL-003-20221218',
				driver: true,
				tractorOperator: true,
				checker: false,
				harvester: false,
			},
			{
				entityNumber: 'DO-UIO-002-20221218',
				driver: true,
				tractorOperator: false,
				checker: false,
				harvester: false,
			},
			{
				entityNumber: 'DO-UIO-001-20221215',
				driver: true,
				tractorOperator: false,
				checker: false,
				harvester: false,
			},
		]

		yield put(
			PlantationDuc.creators.fetchDocumentSuccess(docType, responses)
		)
		yield put(
			PlantationDuc.creators.setRecordsPaginationQueries(pagination)
		)
	} catch (e) {
		logger.error(e)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('document-listing'))
	}
}

function* initiateTicketCreation() {
	try {
		yield put(AuthDuc.creators.fetchAllProducts())
		yield put(
			CookieDuc.creators.getCookie({
				cookieName: USER_COOKIE_KEYS.PRODUCT_ID,
			})
		)
		const cookies = yield select(CookieDuc.selectors.cookies)

		const activeProductID = getIn(cookies, ['userPreferredProduct'])

		if (activeProductID) {
			yield put(PlantationDuc.creators.toggleProductSelectionPopup(false))
		} else {
			yield put(PlantationDuc.creators.toggleProductSelectionPopup(true))
		}
		yield put(PlantationDuc.creators.setActiveProductID(activeProductID))
	} catch (err) {
		const { message } = err
		logger.log(err)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

export default function* PlantationSaga() {
	try {
		yield all([
			takeLatest(
				PlantationDuc.creators.fetchListingTickets().type,
				fetchListingTickets
			),
			takeLatest(
				PlantationDuc.creators.createTicket().type,
				createTicket
			),
			takeLatest(
				PlantationDuc.creators.ticketPreview().type,
				ticketPreview
			),
			takeLatest(PlantationDuc.creators.tripPreview().type, tripPreview),

			takeLatest(
				PlantationDuc.creators.fetchTicketInitialValues().type,
				fetchTicketInitialValues
			),
			takeLatest(PlantationDuc.creators.createBin().type, createBin),
			takeLatest(
				PlantationDuc.creators.fetchListingBins().type,
				fetchListingBins
			),
			takeLatest(PlantationDuc.creators.binPreview().type, binPreview),
			takeLatest(
				PlantationDuc.creators.fetchBinTickets().type,
				fetchBinTickets
			),
			takeLatest(
				PlantationDuc.creators.fetchBinInfo().type,
				fetchBinInfo
			),
			takeLatest(
				PlantationDuc.creators.fetchDocuments().type,
				fetchDocuments
			),
			takeLatest(PlantationDuc.creators.markedBin().type, markedBin),
			takeLatest(
				PlantationDuc.creators.fetchInsights().type,
				fetchInsights
			),
			takeLatest(
				PlantationDuc.creators.initiateTicketCreation().type,
				initiateTicketCreation
			),
		])
	} catch (e) {
		logger.error(e)
	}
}
