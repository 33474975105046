import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NotFoundBlock } from 'core-app/modules/App/components/Error'
import { PartnerDuc } from './duc'
import { MainDashboard } from './containers/MainDashboard'
import SubDashboard from './containers/SubDashboard'
import { WebTour } from '../WebTour'
import { WebTourDuc } from '../WebTour/duc'

const modulesMap = {
	dashboard: MainDashboard,
	subdashboard: SubDashboard,
}

const Partner = () => {
	const dispatch = useDispatch()
	const activeModule = useSelector(PartnerDuc.selectors.activeModule)
	const Component = modulesMap[activeModule] || NotFoundBlock
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)

	useEffect(() => {
		if (tourModule === 'partnerMgmt') {
			dispatch(WebTourDuc.creators.setPartnerVisit(true))
		}
		if (tourModule === 'createPartner') {
			dispatch(WebTourDuc.creators.setCreatePartner(true))
		}
	}, [tourModule, dispatch])
	// if we are here, we have a submodule, route it to the module

	return (
		<>
			<Component />
			{(tourModule === 'partnerMgmt' ||
				tourModule === 'createPartner') && <WebTour />}
		</>
	)
}

export default Partner
