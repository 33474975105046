import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { H2, SmallText } from 'ui-lib/components/Typography'
import { Button, ButtonIconWrapper } from 'ui-lib/components/Button'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import theme from 'ui-lib/utils/base-theme'
import SuccessIcon from 'ui-lib/icons/success-round.svg'
import LeftArrowIcon from 'ui-lib/icons/arrow_forward.svg'

const AllSet = () => {
	const dispatch = useDispatch()
	const actor = useSelector(AuthDuc.selectors.getActor) || []
	const isDealer = actor.includes('palmoil_ffbdealer')
	const isSubDealer = actor.includes('palmoil_ffbsubdealer')

	return (
		<Box
			style={{
				width: '100vw',
				height: '100vh',
				background: ' #1D285D',
				overflowY: 'scroll',
			}}
			center
			padding={8}
		>
			<Box center>
				<H2
					style={{
						fontSize: 40,
						color: theme.color.white,
						lineHeight: '52px',
						fontWeight: 'bold',
					}}
				>
					Excellent Work!
				</H2>
				<Spacer size={20} />
				<Box
					style={{
						width: '770px',
						height: 'fit-content',
						background: theme.color.white,
						borderRadius: '10px',
						boxShadow: '0px 3px 6px #00000029',
					}}
					padding={25}
					alignItems="center"
				>
					<IconWrapper color="#CE924B" size={70}>
						<Icon glyph={SuccessIcon} />
					</IconWrapper>
					<Spacer size={20} />
					<SmallText
						style={{
							color: '#2D2D2D',
							fontSize: '32px',
							lineHeight: '32px',
							width: '500px',
							fontWeight: 'bold',
							textAlign: 'center',
						}}
					>
						All Set!
					</SmallText>
					<Spacer size={30} />
					<SmallText
						style={{
							color: '#555454',
							fontSize: '18px',
							lineHeight: '32px',
							textAlign: 'center',
						}}
					>
						Awesome work. You have now setup your account
						successfully. Happy trading!
					</SmallText>
					<Spacer size={20} />

					<Spacer size={30} />
					<Box
						style={{
							width: '410px',
						}}
					>
						<Button
							rounded
							label={
								isDealer || isSubDealer
									? 'Next'
									: 'Proceed to Homepage'
							}
							customIcon={
								<ButtonIconWrapper color="white" lightBG>
									<Icon glyph={LeftArrowIcon} />
								</ButtonIconWrapper>
							}
							reverse
							onClick={() => {
								if (isDealer || isSubDealer) {
									dispatch(
										MainRouteDuc.creators.switchPage(
											MainRouteDuc.types.ONBOARD$ACTION,
											{
												action: 'add-cp-menu',
											}
										)
									)
								} else {
									dispatch(
										MainRouteDuc.creators.switchPage(
											MainRouteDuc.types.DASHBOARD
										)
									)
								}
							}}
							extendStyles={{
								background: '#3F56C4',
								color: theme.color.white,
							}}
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

export { AllSet }
