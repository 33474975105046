import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn } from 'timm'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { CustomCard } from 'core-app/modules/GeneralScheduler/containers/ViewSchedule'
import { Box } from 'ui-lib/utils/Box'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { P } from 'ui-lib/components/Typography'
import PlusIcon from 'ui-lib/icons/plus.svg'
import theme from 'ui-lib/utils/base-theme'

const AddDriver = ({ onClick, data }) => {
	const { t } = useTranslation()
	const users = useSelector(AuthDuc.selectors.getOrgUsers)
	const driverList = (getIn(data, ['participants']) || []).filter(
		p => p.clientType === 'user'
	)

	const driverID = getIn(driverList, [0, 'participantID']) || ''
	const driver = users.filter(usr => usr.id === driverID)
	const driverName = getIn(driver, [0, 'fullName'])
	const status = getIn(data, ['status'])
	const checkStatus = status === 'delivered' || status === 'intransit'

	return (
		<>
			{driver.length === 0 && status === 'upcoming' && (
				<CustomCard
					style={{
						// width: '170px',
						height: '36px',
						background: '#F2F4FF 0% 0% no-repeat padding-box',
						borderRadius: '18px',
						opacity: 1,
						cursor: 'pointer',
						padding: 8,
					}}
					onClick={onClick}
				>
					<Box row alignItems="center">
						<IconWrapper
							size={16}
							style={{ marginLeft: 12, color: theme.color.blue5 }}
						>
							<Icon glyph={PlusIcon} />
						</IconWrapper>

						<P
							style={{
								marginLeft: 12,
								color: theme.color.blue5,
								fontSize: theme.fontSize.m,
							}}
						>
							{t('schedulerListing.addDriver')}
						</P>
					</Box>
				</CustomCard>
			)}
			{!checkStatus && driver.length > 0 ? (
				<CustomCard
					style={{
						width: '148px',
						height: '36px',
						background: '#F2F4FF 0% 0% no-repeat padding-box',
						borderRadius: '18px',
						opacity: 1,
						cursor: 'pointer',
						padding: 8,
						textAlign: 'center',
					}}
					onClick={onClick}
				>
					<P
						style={{
							color: theme.color.blue5,
							fontSize: theme.fontSize.m,
							width: '100%',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
						}}
					>
						{driverName}
					</P>
				</CustomCard>
			) : (
				<P>{driverName}</P>
			)}
		</>
	)
}

export { AddDriver }
