import Duck from 'extensible-duck'
import { setIn, getIn, merge } from 'timm'
import { Storage } from 'ui-lib/utils/storage'
import { createSelector } from 'reselect'
import { isDateValid } from 'ui-lib/utils/helpers'
import {
	AuthKeyArrInCookie,
	AuthKeyArrInStorage,
	cookiesToBeCleared,
	AUTH_COOKIE_KEYS,
} from 'ui-lib/utils/config'

const initialState = {}
const expiryBasedCookies = ['auth']

export const CookieDuc = new Duck({
	namespace: 'app',
	store: 'global',
	types: ['GET_COOKIE', 'SET_COOKIE', 'DELETE_COOKIE', 'DELETE_ALL_COOKIES'],
	initialState,
	reducer: (state, action, duck) => {
		if (!action) return state // will be used for ssr initial state

		switch (action.type) {
			case duck.types.SET_COOKIE: {
				const {
					cookieName,
					cookieValue,
					storage,
					stateKey,
					stringify,
				} = action

				if (!cookieName || !cookieValue) return state

				const cookieExpiry =
					expiryBasedCookies.indexOf(cookieName) > -1
						? {
								sess: false,
								expires: new Date(
									new Date().getTime() + 720 * 1000
								).toUTCString(),
						  }
						: {}

				Storage.set({
					name: cookieName,
					value: cookieValue,
					storage,
					...cookieExpiry,
					stringify,
				})

				return setIn(
					state,
					['cookies', stateKey || cookieName],
					cookieValue
				)
			}
			case duck.types.GET_COOKIE: {
				return setIn(
					state,
					['cookies', action.stateKey || action.cookieName],
					Storage.get({ name: action.cookieName })
				)
			}
			case duck.types.DELETE_COOKIE: {
				return setIn(
					state,
					['cookies', action.stateKey || action.cookieName],
					Storage.del({ name: action.cookieName }) || ''
				)
			}
			case duck.types.DELETE_ALL_COOKIES: {
				return setIn(
					state,
					['cookies'],
					Storage.deleteAllCookies({
						exceptions: action.exceptionArr,
					})
				)
			}

			default:
				return state
		}
	},
	selectors: {
		page: state => state.page,
		app: state => state.app,
		getCookieInStateOnly: (cookieName = 'null') => state =>
			getIn(state, ['app', 'cookies', cookieName]) || null,
		cookies: new Duck.Selector(selectors =>
			createSelector(selectors.app, app => app.cookies || {})
		),
		getAuthExpiry: new Duck.Selector(selectors =>
			createSelector(selectors.cookies, cookies => ({
				[AUTH_COOKIE_KEYS.ACCESS_TOKEN]:
					getIn(cookies, [AUTH_COOKIE_KEYS.ACCESS_EXPIRY]) || '',
				[AUTH_COOKIE_KEYS.ID_TOKEN]:
					getIn(cookies, [AUTH_COOKIE_KEYS.ID_EXPIRY]) || '',
				[AUTH_COOKIE_KEYS.REFRESH_TOKEN]:
					getIn(cookies, [AUTH_COOKIE_KEYS.REFRESH_EXPIRY]) || '',
			}))
		),
		/* eslint-enable func-names, object-shorthand */
		getActiveAuthTokens: new Duck.Selector(selectors =>
			createSelector(selectors.getAuthExpiry, tokenExpiry => {
				const currentTime = new Date().getTime()

				return Object.keys(tokenExpiry).filter(tokName => {
					const expiry = parseInt(tokenExpiry[tokName], 10) * 1000

					let targetTime = currentTime
					if (tokName === AUTH_COOKIE_KEYS.ACCESS_TOKEN) {
						// if access token only has 2 minutes to expire
						targetTime += 2 * 60 * 1000
					}

					return (
						tokenExpiry[tokName] &&
						isDateValid(expiry) &&
						targetTime < new Date(expiry).getTime()
					)
				})
			})
		),
		getActiveProductID: new Duck.Selector(selectors =>
			createSelector(selectors.cookies, cookies =>
				getIn(cookies, ['userPreferredProduct'])
			)
		),
		getActiveGuidedTour: new Duck.Selector(selectors =>
			createSelector(selectors.cookies, cookies =>
				getIn(cookies, ['SHOW_GUIDED_TOUR'])
			)
		),
		getActiveLanguage: new Duck.Selector(selectors =>
			createSelector(selectors.cookies, cookies =>
				getIn(cookies, ['I18N_LANGUAGE'])
			)
		),
		getSelectedCPID: new Duck.Selector(selectors =>
			createSelector(selectors.cookies, cookies =>
				getIn(cookies, ['SELECTED_CP_ID'])
			)
		),
	},
	helpers: {
		fetchAuthExpiries: dispatch => {
			AuthKeyArrInCookie.forEach(key =>
				dispatch(CookieDuc.creators.getCookie({ cookieName: key }))
			)
		},
		/* eslint-disable func-names, object-shorthand */
		fetchAuthExpirieswYield: function*(put) {
			yield AuthKeyArrInCookie.map(key =>
				put(CookieDuc.creators.getCookie({ cookieName: key }))
			)
		},
		getAuthTokensByName: (name = AUTH_COOKIE_KEYS.ACCESS_TOKEN) => ({
			[name]: Storage.get({
				name,
			}),
		}),
		getAllAuthTokens: () =>
			AuthKeyArrInStorage.reduce(
				(agg, key) =>
					merge(
						agg,
						CookieDuc.options.helpers.getAuthTokensByName(key)
					),
				{}
			),
		setExpiryTokens: function(userTokens = {}) {
			return AuthKeyArrInCookie.map(key => {
				const val = userTokens[key]
				if (val)
					return CookieDuc.creators.setCookie({
						cookieName: key,
						cookieValue: val,
						storage: 'C',
					})

				return null
			}).filter(Boolean)
		},
		setTokens: function(userTokens = {}) {
			return AuthKeyArrInStorage.map(key => {
				const val = userTokens[key]
				if (val)
					return CookieDuc.creators.setCookie({
						cookieName: key,
						cookieValue: val,
					})

				return null
			}).filter(Boolean)
		},
		deleteAllTokens: function() {
			return cookiesToBeCleared.map(key => {
				return CookieDuc.creators.deleteCookie({
					cookieName: key,
				})
			})
		},
	},
	creators: duck => ({
		getCookie: ({ cookieName, stateKey }) => ({
			type: duck.types.GET_COOKIE,
			cookieName,
			stateKey,
		}),
		setCookie: ({
			cookieName,
			cookieValue,
			storage = '',
			stateKey,
			stringify,
		}) => ({
			type: duck.types.SET_COOKIE,
			cookieName,
			cookieValue,
			storage,
			stateKey,
			stringify,
		}),
		deleteCookie: ({ cookieName, stateKey }) => ({
			type: duck.types.DELETE_COOKIE,
			cookieName,
			stateKey,
		}),
		deleteAllCookies: (exceptionArr = []) => ({
			type: duck.types.DELETE_ALL_COOKIES,
			exceptionArr,
		}),
	}),
})
