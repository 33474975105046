import Duck from 'extensible-duck'
import { createSelector } from 'reselect'
import { setIn, getIn } from 'timm'
import { initialState, TRACE_GROUP_STATUS } from './config'

export const NAME_ALIASES = {
	create: 'Create',
	edit: 'Update',
}

export const LabelsMap = {
	'quality-cpo': 'breadcrumb.quality-cpo',
	'quality-ffb': 'breadcrumb.quality-ffb',
	'sourced-batch': 'breadcrumb.sourced-batch',
	'produced-batch': 'breadcrumb.produced-batch',
}

export const RefineryDuc = new Duck({
	namespace: 'refinery',
	store: 'global',
	types: [
		'SET_ACTIVE_MODULE',
		'FETCH_INSIGHTS',
		'FETCH_INSIGHTS_SUCCESS',
		'FETCH_PRODUCTION_DOCUMENTS',
		'SET_PRODUCTION_LOADING_STATUS',
		'FETCH_PRODUCTION_DOCUMENTS_SUCCESS',
		'RESET_PRODUCTION_LOADING_STATUS',
		'CREATE_PLOT_INPUT',
		'UPDATE_INVENTORY',
		'FETCH_INCOMING_INVENTORY',
		'FETCH_OUTGOING_INVENTORY',
		'SET_INCOMING_INVENTORY',
		'SET_OUTGOING_INVENTORY',
		'SET_UPDATE_INVENTORY_LOADING',
		'SET_INCOMING_INVENTORY_LOADING',
		'SET_OUTGOING_INVENTORY_LOADING',
		'SET_PRODUCTION_ACTIVE_SORTS',
		'CREATE_PLOT_OUTPUT',
		'FETCH_CPO_DOCUMENT_SUCCESS',
		'FETCH_CPO_DOCUMENT',
		'FETCH_FFB_DOCUMENT_SUCCESS',
		'FETCH_FFB_DOCUMENT',
		'FETCH_ENTITY_LISTING',
		'SET_ENTITY_PRODUCT',
		'FLUSH_STORAGE_LISTING',
		'SHOW_DOCUMENT_LIST_MODAL',
		'FETCH_INITIAL_PLOT_INPUT',
		'FETCH_INITIAL_PLOT_INPUT_SUCCESS',
		'PRODUCTION_LOADING_STATUS',
		'VIEW_PLOT_DOCUMENT',
		'VIEW_PLOT_DOCUMENT_SUCCESS',
		'FETCH_INITIAL_PLOT_OUTPUT',
		'FETCH_DASHBOARD_LISTING',
		'FETCH_DASHBOARD_LISTING_SUCCESS',
		'FETCH_DASHBOARD_LISTING_LOADING',
		'FETCH_DASHBOARD_LISTING_LOADING',
		'FETCH_DASHBOARD_LISTING_SUCCESS',
		'SET_PAGINATION_ENTRIES',
		'SET_ACTIVE_LISTING_FILTERS',
		'SET_ACTIVE_LISTING_SORTS',
		'FETCH_STORAGE_LISTING',
		'FETCH_STORAGE_LISTING_SUCCESS',
		'APPEND_STORAGE_LISTING',
		'SET_STORAGE_LISTING_PAGINATION_ENTRIES',
		'ASSIGN_TANK',
		'ASSIGN_TANK_VIEW',
		'ASSIGN_TANK_VIEW_SUCCESS',
		'FLUSH_LISTING_DATA',
		'SET_STORAGE_TANKS',
		'DELETE_TRACE_GROUP_ID',
		'MAP_PRODUCTION',
	],
	initialState,
	reducer: (state, action, duck) => {
		switch (action.type) {
			case duck.types.SET_ACTIVE_MODULE: {
				return setIn(state, ['activeModule'], action.module)
			}

			case duck.types.SET_INCOMING_INVENTORY: {
				const { incomingInventoy } = action

				return setIn(
					state,
					['modules', 'production', 'incomingInventory'],
					incomingInventoy
				)
			}

			case duck.types.SET_OUTGOING_INVENTORY: {
				const { outgoingInventoy } = action

				return setIn(
					state,
					['modules', 'production', 'outgoingInventory'],
					outgoingInventoy
				)
			}

			case duck.types.SET_UPDATE_INVENTORY_LOADING: {
				const { status } = action

				return setIn(
					state,
					['modules', 'production', 'updateInventoryLoading'],
					status
				)
			}

			case duck.types.SET_INCOMING_INVENTORY_LOADING: {
				const { status } = action

				return setIn(
					state,
					['modules', 'production', 'incomingInventoryLoading'],
					status
				)
			}

			case duck.types.SET_OUTGOING_INVENTORY_LOADING: {
				const { status } = action

				return setIn(
					state,
					['modules', 'production', 'outgoingInventoryLoading'],
					status
				)
			}

			case duck.types.SET_PRODUCTION_ACTIVE_SORTS: {
				const { sortMap } = action
				let sorts = {}
				;(sortMap.production || []).forEach(key => {
					const arr = key.split('|')

					const lastVal = arr.pop()

					sorts = setIn(sorts, arr, lastVal)
				})

				return setIn(
					state,
					['modules', 'production', 'activeSorts'],
					sorts
				)
			}

			case duck.types.FETCH_INSIGHTS_SUCCESS: {
				const { userInsight } = action

				return setIn(state, ['insightsData'], userInsight)
			}

			case duck.types.FETCH_PRODUCTION_DOCUMENTS_SUCCESS: {
				const { activeDocuments } = action

				return setIn(
					state,
					['modules', 'production', 'activeDocuments'],
					activeDocuments
				)
			}

			case duck.types.FETCH_INITIAL_PLOT_INPUT_SUCCESS: {
				const { response } = action

				return setIn(
					state,
					['modules', 'production', 'activeDocuments', 'plotInput'],
					response
				)
			}

			case duck.types.PRODUCTION_LOADING_STATUS: {
				const { status } = action
				const rootPath = ['modules', 'production', 'loading']

				return setIn(state, [...rootPath], status)
			}

			case duck.types.VIEW_PLOT_DOCUMENT_SUCCESS: {
				const { traceGroup } = action

				return setIn(
					state,
					['modules', 'production', 'activeRecords'],
					traceGroup
				)
			}
			case duck.types.FETCH_STORAGE_LISTING_SUCCESS: {
				const { listing } = action
				const currentDocs = getIn(state, [
					'modules',
					'production',
					'documentListing',
				])

				const finalList = currentDocs.concat(listing || [])

				return setIn(
					state,
					['modules', 'production', 'documentListing'],
					finalList
				)
			}

			case duck.types.SET_STORAGE_LISTING_PAGINATION_ENTRIES: {
				const { paginationQuery } = action

				return setIn(
					state,
					['modules', 'production', 'documentListingPagination'],
					paginationQuery
				)
			}

			case duck.types.ASSIGN_TANK_VIEW_SUCCESS: {
				const { details } = action

				return setIn(
					state,
					['modules', 'production', 'assignTank'],
					details
				)
			}

			case duck.types.FETCH_DASHBOARD_LISTING_LOADING: {
				return setIn(
					state,
					['modules', 'listing', 'loading'],
					action.status || false
				)
			}

			case duck.types.FETCH_DASHBOARD_LISTING_SUCCESS: {
				const { activeDocuments } = action

				return setIn(
					state,
					['modules', 'listing', 'activeDocuments'],
					activeDocuments
				)
			}

			case duck.types.FLUSH_LISTING_DATA: {
				return setIn(
					state,
					['modules', 'listing', 'activeDocuments'],
					initialState.modules.listing.activeDocuments
				)
			}

			case duck.types.SET_PAGINATION_ENTRIES: {
				const {
					activeIndex,
					limit,
					total,
					nextCursor,
					currentCursor,
				} = action

				const root = ['modules', 'listing', 'pagination']
				let nextState = state

				nextState = setIn(
					nextState,
					[...root, 'activeIndex'],
					activeIndex || 0
				)

				if (limit)
					nextState = setIn(nextState, [...root, 'limit'], limit)

				if (total)
					nextState = setIn(nextState, [...root, 'total'], total)

				if (nextCursor)
					nextState = setIn(
						nextState,
						[...root, 'nextCursor'],
						nextCursor
					)

				if (currentCursor)
					nextState = setIn(
						nextState,
						[...root, 'currentCursor'],
						currentCursor
					)

				return nextState
			}

			case duck.types.SET_ACTIVE_LISTING_FILTERS: {
				const { filters = {} } = action

				return setIn(
					state,
					['modules', 'listing', 'activeFilters'],
					filters
				)
			}

			case duck.types.SET_ACTIVE_LISTING_SORTS: {
				const { sortMap } = action

				let sorts = {}
				;(sortMap.sort || []).forEach(key => {
					const arr = key.split('|')
					arr.shift() // remove the first fragment since type is already decided
					const lastVal = arr.pop()

					sorts = setIn(sorts, arr, lastVal)
				})

				return setIn(
					state,
					['modules', 'listing', 'activeSorts'],
					sorts
				)
			}

			case duck.types.SET_STORAGE_TANKS: {
				return setIn(
					state,
					['modules', 'production', 'storageTanks'],
					action.storageTanks
				)
			}

			case duck.types.RESET_PRODUCTION_LOADING_STATUS: {
				return setIn(
					state,
					['modules', 'production', 'loading'],
					action.status
				)
			}

			case duck.types.FLUSH_STORAGE_LISTING: {
				return setIn(
					state,
					['modules', 'production', 'documentListing'],
					initialState.modules.production.documentListing
				)
			}

			default:
				return state
		}
	},
	helpers: {
		extractDocuments: documents =>
			[...TRACE_GROUP_STATUS].reduce((agg, key) => {
				const aggregator = agg
				aggregator[key] = getIn(documents, [key, 'list']) || []

				return aggregator
			}, {}),
	},
	selectors: {
		auth: state => state.auth,
		location: state => state.location,
		activeModule: state =>
			getIn(state, ['refinery', 'activeModule']) || 'Error',
		listingState: state =>
			getIn(state, ['refinery', 'modules', 'listing']) || {},
		productionState: state =>
			getIn(state, ['refinery', 'modules', 'production']),
		getInsightsData: state =>
			getIn(state, ['refinery', 'insightsData']) || {},
		getQualityActiveTimeOffset: state =>
			getIn(state, [
				'refinery',
				'modules',
				'quality',
				'activeTimeOffset',
			]),
		getProductionActiveTimeOffset: state =>
			getIn(state, [
				'refinery',
				'modules',
				'production',
				'activeTimeOffset',
			]),

		getProductionActiveTabs: new Duck.Selector(selectors =>
			createSelector(selectors.productionState, production =>
				getIn(production, ['tabsConfig'])
			)
		),
		getProductionActiveSorts: new Duck.Selector(selectors =>
			createSelector(
				selectors.productionState,
				production => getIn(production, ['activeSorts']) || {}
			)
		),
		getIncomingInventory: state =>
			getIn(state, [
				'refinery',
				'modules',
				'production',
				'incomingInventory',
			]) || {},
		getOutgoingInventory: state =>
			getIn(state, [
				'refinery',
				'modules',
				'production',
				'outgoingInventory',
			]) || {},
		getRefineryLoadingState: state =>
			getIn(state, [
				'refinery',
				'modules',
				'production',
				'updateInventoryLoading',
			]) ||
			getIn(state, [
				'refinery',
				'modules',
				'production',
				'incomingInventoryLoading',
			]) ||
			getIn(state, [
				'refinery',
				'modules',
				'production',
				'outgoingInventoryLoading',
			]) ||
			false,
		getProductionDocuments: new Duck.Selector(selectors =>
			createSelector(selectors.productionState, production => {
				const incomingDocuments =
					getIn(production, ['activeDocuments']) || {}

				return RefineryDuc.options.helpers.extractDocuments(
					incomingDocuments
				)
			})
		),

		getActiveDocuments: new Duck.Selector(selectors =>
			createSelector(
				selectors.productionState,
				production => getIn(production, ['documentListing']) || []
			)
		),
		getStorageListingPaginationConfig: new Duck.Selector(selectors =>
			createSelector(
				selectors.productionState,
				production =>
					getIn(production, ['documentListingPagination']) || {}
			)
		),
		getAssignedTank: new Duck.Selector(selectors =>
			createSelector(
				selectors.productionState,
				production => getIn(production, ['assignTank']) || []
			)
		),
		getActiveRecords: new Duck.Selector(selectors =>
			createSelector(
				selectors.productionState,
				production => getIn(production, ['activeRecords']) || {}
			)
		),
		getProductionLoadingStatus: new Duck.Selector(selectors =>
			createSelector(
				selectors.productionState,
				production => getIn(production, ['loading']) || false
			)
		),
		gePlotInputDocuments: new Duck.Selector(selectors =>
			createSelector(
				selectors.productionState,
				production =>
					getIn(production, ['activeDocuments', 'plotInput']) || {}
			)
		),
		getProductionLoadingStates: new Duck.Selector(selectors =>
			createSelector(selectors.productionState, production =>
				getIn(production, ['loading'])
			)
		),

		getModalStatus: new Duck.Selector(selectors =>
			createSelector(selectors.qualityState, slips =>
				getIn(slips, ['showModal'])
			)
		),

		getListingDocuments: new Duck.Selector(selectors =>
			createSelector(
				selectors.listingState,
				listing => getIn(listing, ['activeDocuments', 'list']) || []
			)
		),
		getListingLoadingStatus: new Duck.Selector(selectors =>
			createSelector(
				selectors.listingState,
				listing => listing.loading || false
			)
		),
		getListingPaginationEntries: new Duck.Selector(selectors =>
			createSelector(
				selectors.listingState,
				listing => listing.pagination
			)
		),
		getListingActiveFilters: new Duck.Selector(selectors =>
			createSelector(
				selectors.listingState,
				listing => listing.activeFilters
			)
		),
		getDocumentListingActiveSorts: new Duck.Selector(selectors =>
			createSelector(
				selectors.listingState,
				listing => getIn(listing, ['activeSorts']) || {}
			)
		),
		getStorageTanksList: state =>
			getIn(state, [
				'refinery',
				'modules',
				'production',
				'storageTanks',
			]) || [],
	},
	creators: duck => ({
		setActiveModule: module => ({
			type: duck.types.SET_ACTIVE_MODULE,
			module,
		}),
		fetchInsights: () => ({
			type: duck.types.FETCH_INSIGHTS,
		}),
		fetchInsightsSuccess: userInsight => ({
			type: duck.types.FETCH_INSIGHTS_SUCCESS,
			userInsight,
		}),
		flushStorageListing: () => ({
			type: duck.types.FLUSH_STORAGE_LISTING,
		}),
		fetchQualityDocuments: (
			docType,
			rootModules,
			locationState,
			skipGlobalLoader = false
		) => ({
			type: duck.types.FETCH_QUALITY_DOCUMENTS,
			docType,
			rootModules,
			locationState,
			skipGlobalLoader,
		}),
		setQualityLoading: module => ({
			type: duck.types.SET_QUALITY_LOADING_STATUS,
			module,
		}),
		fetchQualityDocumentsSuccess: activeDocuments => ({
			type: duck.types.FETCH_QUALITY_DOCUMENTS_SUCCESS,
			activeDocuments,
		}),
		resetQualityLoading: () => ({
			type: duck.types.RESET_QUALITY_LOADING_STATUS,
		}),
		initiateFfbQuality: () => ({
			type: duck.types.INITIATE_FFB_QUALITY,
		}),
		setEntityProductList: (products, productID) => ({
			type: duck.types.INITIATE_FFB_QUALITY_PRODUCT_LIST,
			products,
			productID,
		}),
		fetchEntityListing: listing => ({
			type: duck.types.FETCH_ENTITY_LISTING,
			listing,
		}),
		createCPOQualityReport: docValue => ({
			type: duck.types.CREATE_CPO_QUALITY_REPORT,
			docValue,
		}),
		createFFBQualityReport: (values, entityID, productID) => ({
			type: duck.types.CREATE_FFB_QUALITY_REPORT,
			values,
			entityID,
			productID,
		}),
		fetchProductionDocuments: (
			docType,
			rootModules,
			locationState,
			skipGlobalLoader = false
		) => ({
			type: duck.types.FETCH_PRODUCTION_DOCUMENTS,
			docType,
			rootModules,
			locationState,
			skipGlobalLoader,
		}),
		setProductionLoading: (root, _modules) => ({
			type: duck.types.SET_PRODUCTION_LOADING_STATUS,
			root,
			_modules,
		}),
		fetchProductionDocumentsSuccess: activeDocuments => ({
			type: duck.types.FETCH_PRODUCTION_DOCUMENTS_SUCCESS,
			activeDocuments,
		}),
		resetProductionLoading: status => ({
			type: duck.types.RESET_PRODUCTION_LOADING_STATUS,
			status,
		}),
		setQualityActiveSorts: sortMap => ({
			type: duck.types.SET_QUALITY_ACTIVE_SORTS,
			sortMap,
		}),
		createPlotInput: (docValue, allTanks, helpers, toastMessage) => ({
			type: duck.types.CREATE_PLOT_INPUT,
			docValue,
			allTanks,
			helpers,
			toastMessage,
		}),
		updateInventory: (payload, toastMessage) => ({
			type: duck.types.UPDATE_INVENTORY,
			payload,
			toastMessage,
		}),
		fetchIncomingInventory: (weekNumber, year) => ({
			type: duck.types.FETCH_INCOMING_INVENTORY,
			weekNumber,
			year,
		}),
		fetchOutgoingInventory: (weekNumber, year) => ({
			type: duck.types.FETCH_OUTGOING_INVENTORY,
			weekNumber,
			year,
		}),
		setIncomingInventory: incomingInventoy => ({
			type: duck.types.SET_INCOMING_INVENTORY,
			incomingInventoy,
		}),
		setOutgoingInventory: outgoingInventoy => ({
			type: duck.types.SET_OUTGOING_INVENTORY,
			outgoingInventoy,
		}),
		setUpdateInventoryLoading: status => ({
			type: duck.types.SET_UPDATE_INVENTORY_LOADING,
			status,
		}),
		setIncomingInventoryLoading: status => ({
			type: duck.types.SET_INCOMING_INVENTORY_LOADING,
			status,
		}),
		setOutgoingInventoryLoading: status => ({
			type: duck.types.SET_OUTGOING_INVENTORY_LOADING,
			status,
		}),
		setProductionActiveSorts: sortMap => ({
			type: duck.types.SET_PRODUCTION_ACTIVE_SORTS,
			sortMap,
		}),
		createPlotOutput: (docValue, helpers, toastMessage) => ({
			type: duck.types.CREATE_PLOT_OUTPUT,
			docValue,
			helpers,
			toastMessage,
		}),
		fetchCpoDocument: documentReference => ({
			type: duck.types.FETCH_CPO_DOCUMENT,
			documentReference,
		}),
		fetchCpoDocumentSuccess: documents => ({
			type: duck.types.FETCH_CPO_DOCUMENT_SUCCESS,
			documents,
		}),
		fetchFfbDocument: documentReference => ({
			type: duck.types.FETCH_FFB_DOCUMENT,
			documentReference,
		}),
		fetchFfbDocumentSuccess: documents => ({
			type: duck.types.FETCH_FFB_DOCUMENT_SUCCESS,
			documents,
		}),
		setProductFromEntity: product => ({
			type: duck.types.SET_ENTITY_PRODUCT,
			product,
		}),
		handleModalStatus: status => ({
			type: duck.types.SHOW_DOCUMENT_LIST_MODAL,
			status,
		}),
		fetchInitialPlotInput: productID => ({
			type: duck.types.FETCH_INITIAL_PLOT_INPUT,
			productID,
		}),
		fetchInitialPlotInputSuccess: response => ({
			type: duck.types.FETCH_INITIAL_PLOT_INPUT_SUCCESS,
			response,
		}),
		productionLoadingStatus: status => ({
			type: duck.types.PRODUCTION_LOADING_STATUS,
			status,
		}),
		viewPlotDocuments: traceGroupID => ({
			type: duck.types.VIEW_PLOT_DOCUMENT,
			traceGroupID,
		}),
		viewPlotDocumentsSuccess: traceGroup => ({
			type: duck.types.VIEW_PLOT_DOCUMENT_SUCCESS,
			traceGroup,
		}),
		fetchInitialPlotOutput: () => ({
			type: duck.types.FETCH_INITIAL_PLOT_OUTPUT,
		}),
		fetchDocumentListing: (
			docType,
			rootModules = [],
			locationState = {},
			skipGlobalLoader = false
		) => ({
			type: duck.types.FETCH_DASHBOARD_LISTING,
			rootModule: rootModules[0],
			submodule: docType,
			locationState,
			skipGlobalLoader,
		}),
		fetchDocumentLoading: status => ({
			type: duck.types.FETCH_DASHBOARD_LISTING_LOADING,
			status,
		}),
		fetchDocumentListingSuccess: activeDocuments => ({
			type: duck.types.FETCH_DASHBOARD_LISTING_SUCCESS,
			activeDocuments,
		}),
		setPaginationEntries: (
			activeIndex,
			limit,
			total,
			nextCursor,
			currentCursor
		) => ({
			type: duck.types.SET_PAGINATION_ENTRIES,
			activeIndex,
			limit,
			total,
			nextCursor,
			currentCursor,
		}),
		setActiveListingFilters: filters => ({
			type: duck.types.SET_ACTIVE_LISTING_FILTERS,
			filters,
		}),
		setActiveListingSorts: sortMap => ({
			type: duck.types.SET_ACTIVE_LISTING_SORTS,
			sortMap,
		}),
		fetchStorageListing: (paginationQuery = {}) => ({
			type: duck.types.FETCH_STORAGE_LISTING,
			paginationQuery,
		}),
		fetchStorageListingSuccess: listing => ({
			type: duck.types.FETCH_STORAGE_LISTING_SUCCESS,
			listing,
		}),
		setStorageListingPaginationEntries: paginationQuery => ({
			type: duck.types.SET_STORAGE_LISTING_PAGINATION_ENTRIES,
			paginationQuery,
		}),
		appendStorageListing: locationState => ({
			type: duck.types.APPEND_STORAGE_LISTING,
			locationState,
		}),
		assignTank: (
			values,
			supplyChainModel,
			productID,
			entityID,
			toastMessage
		) => ({
			type: duck.types.ASSIGN_TANK,
			values,
			supplyChainModel,
			productID,
			entityID,
			toastMessage,
		}),
		assignTankView: () => ({
			type: duck.types.ASSIGN_TANK_VIEW,
		}),
		assignTankViewSuccess: details => ({
			type: duck.types.ASSIGN_TANK_VIEW_SUCCESS,
			details,
		}),
		flushListingData: () => ({
			type: duck.types.FLUSH_LISTING_DATA,
		}),
		setStorageTanks: storageTanks => ({
			type: duck.types.SET_STORAGE_TANKS,
			storageTanks,
		}),
		deleteTraceGroupID: traceGrpID => ({
			type: duck.types.DELETE_TRACE_GROUP_ID,
			traceGrpID,
		}),
		mapProduction: (productionEntries, toastMessage) => ({
			type: duck.types.MAP_PRODUCTION,
			productionEntries,
			toastMessage,
		}),
	}),
})
