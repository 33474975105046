import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MainRouteDuc } from 'core-app/routes/duc'
import { WbTicketDuc } from 'core-app/modules/WbTicket/duc'
import { AppDuc } from 'core-app/modules/App/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { WebTourDuc } from 'core-app/modules/WebTour/duc'
import { WebTour } from 'core-app/modules/WebTour'
import { isEmptyObject } from 'core-app/utils/helpers'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { BoxShadowWrapper } from 'ui-lib/components/Card'
import { FileUpload } from 'ui-lib/components/FileUpload'
import { H3, P } from 'ui-lib/components/Typography'
import { Button, ButtonWithNoBorder } from 'ui-lib/components/Button'
import { Input } from 'ui-lib/components/Input'
import { SelectSearch } from 'ui-lib/components/Select'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import * as yup from 'yup'
import { useFormik } from 'formik'

const NumberIndicator = styled.div(p => ({
	width: 20,
	height: 20,
	background: p.theme.color.blue0,
	display: 'flex',
	justifyContent: 'center',
	fontWeight: 500,
	alignItems: 'center',
	borderRadius: '50%',
	padding: '16px',
	color: p.theme.color.blue3,
	border: `1px solid ${p.theme.color.blue0}`,
}))

const DocumentCreation = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const partnerList = useSelector(AuthDuc.selectors.getTargetPartnersOfUser)
	const partners = partnerList.map(list => {
		return {
			id: list.id,
			label: list.name,
		}
	})
	const validationSchema = yup.object().shape({
		smallholderID: yup.string().required(),
		netWeight: yup.number().required(t('validation.netWeightRequired')),
	})
	const [uplodedTickets, setUplodedTickets] = useState([])
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)

	useEffect(() => {
		if (tourModule === 'uploadWBTkt') {
			dispatch(WebTourDuc.creators.setActiveTourModule('uploadWBTkt2'))
		}
	}, [tourModule, dispatch])
	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.weighBridge'),
			name: 'weighBridge',
			isActive: true,
		},
		{
			label: t('breadcrumb.uploadTicket'),
			name: 'uploadTicket',
			isActive: false,
		},
	]

	/** Handlers */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'weighBridge') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.WBTICKET)
			)
		}
	}

	const {
		handleSubmit,
		submitForm,
		values,
		handleChange,
		handleBlur,
		setFieldValue,
		touched,
		errors,
	} = useFormik({
		initialValues: {
			smallholderID: '',
			netWeight: '',
			attachedFile: [],
		},
		enableReinitialize: true,
		validationSchema,
		onSubmit: _values => {
			dispatch(WbTicketDuc.creators.saveUploadTicket(_values))
		},
	})

	const disableCTA =
		!isEmptyObject(errors) ||
		!values.netWeight ||
		uplodedTickets.length === 0

	return (
		<>
			<Box padding={8} width="100%" height="100%">
				<Box style={{ padding: '0 5' }}>
					<Breadcrumb
						links={breadCrumbs}
						onClick={target => handleBreadCrumbClick(target)}
					/>
				</Box>
				<Spacer size={32} />
				<BoxShadowWrapper>
					<Box padding={16}>
						<Box row>
							<NumberIndicator>1</NumberIndicator>
							<Spacer size={10} horizontal />
							<H3>{t('weighbridge.chooseSmallholder')}</H3>
						</Box>
						<Box padding={isMobile ? '15px' : '25px'}>
							<form onSubmit={handleSubmit}>
								<Box row={!isMobile}>
									<Box width={300} id="input-WBTktSM">
										<SelectSearch
											label={t(
												'weighbridge.smallHolderListing'
											)}
											placeholder={t(
												'weighbridge.smallHolderListing'
											)}
											value={values.smallholderID}
											name="smallholderID"
											options={partners}
											onChange={value => {
												setFieldValue(
													'smallholderID',
													value.id
												)
											}}
											returnOnlyValue
											labelKey="label"
											valueKey="id"
											defaultLabel={t(
												'common.selectDefaultLabel'
											)}
											onBlur={handleBlur}
											error={
												touched.smallholderID &&
												errors.smallholderID
											}
											required
										/>
									</Box>
									<Spacer size={32} />
									<Box width={300}>
										<Input
											label={t('weighbridge.netWeight')}
											name="netWeight"
											value={values.netWeight}
											type="number"
											onChange={handleChange}
											onBlur={handleBlur}
											error={
												touched.netWeight &&
												errors.netWeight
											}
											required
										/>
									</Box>
								</Box>
							</form>
						</Box>
					</Box>
				</BoxShadowWrapper>

				<Spacer size={16} />
				<BoxShadowWrapper>
					<Box padding={16} id="input-WBTktFile">
						<Box row>
							<NumberIndicator>2</NumberIndicator>
							<Spacer size={10} horizontal />
							<H3>{t('weighbridge.uploadTicketPicture')}</H3>
						</Box>
						<Box padding={16}>
							<FileUpload
								dropzone
								type="private"
								name="attachments"
								size="5242880"
								modalTriggerBtnLabel={t('common.upload')}
								buttonLabel={t('common.uploadBtnLabel')}
								supportedFileType=".jpeg,.png,.jpg"
								currentUploads={uplodedTickets}
								onDocumentChange={documents => {
									setUplodedTickets(documents)
									setFieldValue('attachedFile', documents)
								}}
								onUploadFailure={({ message }) => {
									dispatch(
										AppDuc.creators.showToast({
											messageType: 'error',
											message,
										})
									)
								}}
								dropLabel={t('common.dropFile')}
							/>
							<Spacer size={4} />
							<P>{`${t('common.note')}: ${t(
								'common.upload'
							)} .png, .jpg, .pdf, ${t('common.only')}`}</P>
						</Box>
					</Box>
				</BoxShadowWrapper>
				<Spacer size={16} />
				<Box row justifyContent="flex-end">
					<Box style={{ padding: 2, maxWidth: 150 }}>
						<ButtonWithNoBorder
							type="submit"
							primary
							label={t('common.back')}
							onClick={() =>
								dispatch(
									MainRouteDuc.creators.switchPage(
										MainRouteDuc.types.WBTICKET
									)
								)
							}
						/>
					</Box>
					<Box style={{ padding: 2 }} width="120px">
						<ButtonWithNoBorder
							type="submit"
							primary
							label={t('weighbridge.uploadAgain')}
							onClick={() => setUplodedTickets([])}
						/>
					</Box>
					<Box
						id="input-saveTkt"
						style={{ padding: 2 }}
						width="120px"
					>
						<Button
							disabled={disableCTA}
							type="submit"
							primary
							label={t('common.save')}
							onClick={() => submitForm()}
						/>
					</Box>
				</Box>
			</Box>
			{tourModule === 'uploadWBTkt2' && <WebTour />}
		</>
	)
}

export { DocumentCreation }
