import {
	all,
	takeLatest,
	takeEvery,
	take,
	put,
	call,
	select,
	race,
} from 'redux-saga/effects'
import LogHelper from 'core-app/utils/logger'
import { Toast } from 'ui-lib/components/Toast'
import { isMarketplaceHost } from 'ui-lib/utils/helpers'
import request from 'core-app/utils/request'
import {
	getIAMEndPoint,
	getInsightsEndPoint,
	getCoreEndPoint,
	getGuidedTourStatus,
	getNotifierEndPoint,
} from 'core-app/config'
import { getSeparatedLoginHost } from 'ui-lib/config'
import { CookieDuc } from 'core-app/modules/App/cookieDuc'
import { AUTH_COOKIE_KEYS } from 'ui-lib/utils/config'
import { getIn } from 'timm'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AppDuc } from 'core-app/modules/App/duc'
import { WebTourDuc } from 'core-app/modules/WebTour/duc'
import { Storage } from 'ui-lib/utils/storage'
import {
	isEmptyObject,
	getRelativeUrlFromAbsoluteUrl,
} from 'core-app/utils/helpers'
import {
	featureAccessBasedOnType,
	getActiveUserDashboardAction,
} from 'core-app/routes/base'
import { checkIfAtleastOneFeatureAllowed } from 'core-app/modules/Auth/helpers'
import i18n from 'i18next'
import { IAMAuth } from 'core-app/utils/iam'
import { AuthDuc } from './duc'

const logger = LogHelper('client:authSaga')

function* SignOutUser(action = {}) {
	const { redirectTo } = action
	const targetRedirect = getRelativeUrlFromAbsoluteUrl(redirectTo)
	try {
		const requestUrl = `${getIAMEndPoint()}clients/logout`
		const options = {
			method: 'DELETE',
		}

		yield call(request, requestUrl, options)

		yield put(AuthDuc.creators.flushState())
		yield all(CookieDuc.options.helpers.deleteAllTokens().map(c => put(c)))

		window.location = `${getSeparatedLoginHost()}/auth/sign-in${
			targetRedirect ? `?redirect_to=${targetRedirect}` : ''
		}`
	} catch (e) {
		logger.error(e)
	}
}

const refreshingToken = {
	status: false,
	set writeStatus(status) {
		logger.log('Refreshing Status: ', status)
		this.status = status
	},
}

function* refreshTokenHandler(callCount = 0) {
	try {
		// get tokens from the store
		const activeTokens = yield select(
			CookieDuc.selectors.getActiveAuthTokens
		)

		const accessTokenExpired = !activeTokens.includes(
			AUTH_COOKIE_KEYS.ACCESS_TOKEN
		)

		const canRetry =
			activeTokens.includes(AUTH_COOKIE_KEYS.REFRESH_TOKEN) &&
			activeTokens.includes(AUTH_COOKIE_KEYS.ID_TOKEN)

		if (canRetry && accessTokenExpired) {
			const requestUrl = `${getIAMEndPoint()}clients/tokens`
			const options = {
				method: 'PUT',
			}
			if (navigator.onLine || callCount > 10) {
				const response = yield call(request, requestUrl, options)

				if (response.retryWithRefreshToken) {
					throw new Error('Unable to refresh the token')
				}

				const { data = {} } = response
				// set the user expiry into the store and sync up.
				yield all(
					CookieDuc.options.helpers
						.setExpiryTokens(data)
						.map(c => put(c))
				)

				// we have synced the new tokens, now sync state
				yield put(AuthDuc.creators.successRefreshToken())
			} else {
				const count = callCount + 1

				return yield refreshTokenHandler(count)
			}
		} else if (!canRetry) {
			throw new Error('User is not authorized. Please sign in again.')
		}
	} catch (e) {
		const { type } = yield select(AuthDuc.selectors.location)
		const onboardCheck = MainRouteDuc.types.ONBOARD === type
		const onPolicyCheck = MainRouteDuc.types.POLICY === type
		if (!onboardCheck && !onPolicyCheck) {
			logger.log(e)

			// yield put(
			// 	AppDuc.creators.showToast({
			// 		messageType: 'error',
			// 		message:
			// 			'You have been logged out. Please log in again to continue.',
			// 	})
			// )

			// show alert and throw to login screen.
			yield put(AuthDuc.creators.errorRefreshToken())

			// yield SignOutUser({
			// 	redirectTo: window.location.href,
			// })
		}
	}
}

/** Call this function when you need to invoke api call with refresh token check */

export function* CallWithRefreshCheck(
	url,
	options,
	type = 'json',
	timeout = 300000,
	callCount = 0
) {
	try {
		if (refreshingToken.status) {
			// wait for the token to come and then retry

			const { success } = yield race({
				success: take(AuthDuc.creators.successRefreshToken().type),
				fail: take(AuthDuc.creators.errorRefreshToken().type),
			})

			if (success) {
				const response = yield call(
					request,
					url,
					options,
					type,
					timeout
				)

				return response
			}

			return {}
		}

		let response = {}
		const authCookies = yield select(
			CookieDuc.selectors.getActiveAuthTokens
		)

		if (
			authCookies.includes('Authorization') &&
			(navigator.onLine || callCount > 10)
		) {
			try {
				response = yield call(request, url, options, type, timeout)
			} catch (e) {
				if (e.message === 'Failed to Fetch') {
					yield put(
						AppDuc.creators.showToast({
							messageType: 'error',
							message:
								'Unable to authorize user. Please login again to proceed.',
						})
					)

					// unable to fetch the call, logout the user with toast.
					yield SignOutUser({
						redirectTo: window.location.href,
					})
				} else if (
					e.status === 500 &&
					e.message === 'Failed to read the record.'
				) {
					logger.error(e)
				} else {
					logger.error(e)
					const { message } = e
					yield put(
						AppDuc.creators.showToast({
							messageType: 'error',
							message,
						})
					)
				}

				return response || {}
			}

			if (response.retryWithRefreshToken === true) {
				// refresh the tokens
				yield refreshTokenHandler()

				return yield CallWithRefreshCheck(url, options, type, timeout)
			}

			return response || {}
		} else {
			const count = callCount + 1
			yield refreshTokenHandler()

			return yield CallWithRefreshCheck(
				url,
				options,
				type,
				timeout,
				count
			)
		}
	} catch (e) {
		logger.log(e)
	}
}

function* fetchUserRoles(action) {
	const { skipLoading } = action || {}
	try {
		if (!skipLoading)
			yield put(AppDuc.creators.showGlobalLoader('fetch-roles'))

		const requestUrl = `${getIAMEndPoint()}clients/_/computed-access`
		// fetch and set the roles of the user
		const { data: roles } = yield CallWithRefreshCheck(requestUrl)
		const actor = IAMAuth(roles).dataFilters('iam.clients.users.r', 'actor')

		yield put(AuthDuc.creators.setActor(actor))
		yield put(AuthDuc.creators.setActiveUserRoles(roles))
	} catch (e) {
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message:
					'Unable to get the user permissions. Please try again later.',
			})
		)
		logger.log(e)
		yield SignOutUser({
			redirectTo: window.location.href,
		})
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-roles'))
	}
}

function* fetchOrgsUser(action) {
	const { userIDs = [], returnResponse, fetchAll, skipLoading } = action

	// if there were no orgId
	try {
		if (!skipLoading)
			yield put(AuthDuc.creators.handleOrgFetchStatuses(true))
		const targetUserIDs = userIDs.filter(a => a)
		const requestUrl = `${getIAMEndPoint()}clients/users`
		const response = yield CallWithRefreshCheck(
			`${requestUrl}?limit=100&${
				fetchAll ? '' : `id=${targetUserIDs.join(',')}`
			}`,
			requestUrl
		)
		const list = getIn(response, ['data', 'list']) || []
		if (list.length) {
			const userCount = {}

			const orgMap = list.reduce((agg, org) => {
				if (org.orgRole in userCount) {
					userCount[org.orgRole] += 1
				} else {
					userCount[org.orgRole] = 1
				}
				const aggregator = agg
				aggregator[org.id] = org

				return aggregator
			}, {})
			// push the org list to the state
			yield put(AuthDuc.creators.setOrgUserDetails(orgMap))
			yield put(AuthDuc.creators.fetchUserListsCountSuccess(userCount))
		} else {
			logger.log('There are no users for this org')
		}
		yield put(AuthDuc.creators.handleOrgFetchStatuses(false, false))
		if (returnResponse) return list
	} catch (e) {
		if (returnResponse) return []
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message: message || 'Unable to fetch user lists',
			})
		)
		if (!skipLoading)
			yield put(AuthDuc.creators.handleOrgFetchStatuses(false, true))
	}
}

export function* fetchCurrentOrgsDetail(action) {
	const { orgID, returnResponse, skipLoading } = action

	// if there were no orgId
	try {
		const orgDetailsUrl = `${getIAMEndPoint()}clients/organizations/${orgID}`
		const { data } = yield CallWithRefreshCheck(orgDetailsUrl)
		yield put(AuthDuc.creators.setCurrentOrg(data))
		if (returnResponse) return data
	} catch (e) {
		if (returnResponse) return []
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message: message || 'Unable to fetch org lists',
			})
		)
		if (!skipLoading)
			yield put(AuthDuc.creators.handleOrgFetchStatuses(false, true))
	}
}

const profileFetchStatus = {
	status: false,
	set writeStatus(status) {
		logger.log('Profile Status: ', status)
		this.status = status
	},
}

export function* fetchClientID(action = {}) {
	const { withRetry = false } = action
	const requestUrl = `${getIAMEndPoint()}clients/token`

	try {
		const response = withRetry
			? yield CallWithRefreshCheck(requestUrl)
			: yield call(request, requestUrl)

		const clientID = getIn(response, ['data', 'clientID'])
		if (clientID) {
			yield put(
				CookieDuc.creators.setCookie({
					cookieName: AUTH_COOKIE_KEYS.CLIENT_ID,
					cookieValue: clientID,
					storage: 'C',
				})
			)

			return clientID
		}

		return null
	} catch (err) {
		logger.error(err)
		const { message } = err
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)

		return null
	}
}

export function* GetUserProfile(action) {
	const { clientID, returnValue, skipLoading } = action || {}

	try {
		if (profileFetchStatus.status) return {}

		profileFetchStatus.writeStatus = true
		if (!skipLoading)
			yield put(AuthDuc.creators.setProfileLoadingStatus(true))
		let targetClientID =
			clientID || Storage.get({ name: AUTH_COOKIE_KEYS.CLIENT_ID })
		if (!targetClientID) {
			// try and fetch client id from api state
			const clientIDFromAPI = yield fetchClientID({ withRetry: true })
			if (!clientIDFromAPI) {
				throw new Error('Unable to fetch the user details')
			}

			targetClientID = clientIDFromAPI
		}

		const requestUrl = `${getIAMEndPoint()}clients/users/${targetClientID}`
		const { data = {} } = yield CallWithRefreshCheck(requestUrl)

		const language = getIn(data, ['meta', 'language'])

		i18n.changeLanguage(language || 'eng')

		yield put(
			CookieDuc.creators.setCookie({
				cookieName: 'I18N_LANGUAGE',
				cookieValue: language,
				storage: 'C',
			})
		)

		// fetch the user roles
		yield fetchUserRoles({ skipLoading: true })

		yield fetchOrgsUser({ fetchAll: true, skipLoading: true })

		// fetch the org details for this fetch
		const orgIDFromProfile = getIn(data, ['organization', 'id'])

		if (orgIDFromProfile) {
			const orgDetails = yield fetchCurrentOrgsDetail({
				orgID: orgIDFromProfile,
				returnResponse: true,
			})

			yield put(AuthDuc.creators.setCurrentOrg(orgDetails))
		}

		profileFetchStatus.writeStatus = false
		yield put(AuthDuc.creators.setProfileLoadingStatus(false))
		if (returnValue) return data

		yield put(AuthDuc.creators.setUserProfile(data))

		return data
	} catch (e) {
		logger.log(e)

		const {
			message = 'Unable to fetch user profile. Please login again.',
		} = e

		yield put(AuthDuc.creators.setProfileErrorStatus(true))

		yield SignOutUser({
			redirectTo: window.location.href,
		})

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
		yield put(AuthDuc.creators.setProfileLoadingStatus(false))
		profileFetchStatus.writeStatus = false

		return {}
	}
}

export function* getOrgIDFromLoggedUser() {
	try {
		let orgID = yield select(AuthDuc.selectors.getUserOrgId)

		if (!orgID) {
			let profile = {}
			if (profileFetchStatus.status) {
				// check if already fetching, if yes, wait for it to arrive,
				const { success } = yield race({
					success: take(AuthDuc.creators.setUserProfile().type),
					fail: take(AuthDuc.creators.setProfileErrorStatus().type),
				})

				if (success) {
					profile = yield select(AuthDuc.selectors.getUserProfile)
				}
			} else {
				profile = yield GetUserProfile()
			}

			orgID = getIn(profile, ['organization', 'id'])

			if (!orgID) {
				yield SignOutUser({
					redirectTo: window.location.href,
				})
				throw new Error(
					'You do not seem to be associated to an Organization. Please contact admin'
				)
			}
		}

		return orgID
	} catch (e) {
		logger.log(e)
		throw e
	}
}

function* fetchCertificates() {
	try {
		const requestUrl = `${getIAMEndPoint()}clients/organizations/-/documents?type=certificate`

		const options = {
			method: 'GET',
		}
		const { data } = yield CallWithRefreshCheck(requestUrl, options)

		const certificates = data?.list?.length
			? data.list.map(certs => certs.meta.certificate.issuingBody)
			: []

		yield put(AuthDuc.creators.setCertificates(certificates))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

export function* fetchOrgDetailsCount() {
	try {
		const insightsUrl = `${getInsightsEndPoint()}dashboard/apistats/insights`
		const { data } = yield CallWithRefreshCheck(insightsUrl)

		const {
			partnersAvailable = false,
			employeesAvailable = false,
			languageSet = false,
			assets = {},
		} = data

		const {
			incomingAreaAvailable = false,
			outgoingAreaAvailable = false,
			incomingTankAvailable = false,
			outgoingTankAvailable = false,
			weighbridgeAvailable = false,
		} = assets

		const clientID = yield fetchClientID()
		const userID = clientID.substring(0, clientID.indexOf('@org-'))
		const orgID = clientID.slice(clientID.indexOf('@') + 1)

		const orgDetailsUrl = `${getIAMEndPoint()}clients/organizations/${orgID}`
		const { data: orgDetails = {} } = yield CallWithRefreshCheck(
			orgDetailsUrl
		)

		const currentOrganisation = getIn(orgDetails, ['categories', 0, 'id'])
		const currentStatus = yield getIn(orgDetails, ['status', 'state'])

		const isGuidedTourOpen = yield select(WebTourDuc.selectors.isTourOpen)
		const requestUrl = `${getIAMEndPoint()}clients/guidedtour/users/${userID}`
		const options = {
			method: 'GET',
		}
		const { data: guidedTour } = yield call(request, requestUrl, options)
		const guidedTourCount = guidedTour.gtAccessCount

		yield put(
			CookieDuc.creators.getCookie({
				cookieName: 'GUIDED_TOUR_SKIPPED',
			})
		)

		const cookies = yield select(CookieDuc.selectors.cookies)

		const isGuidedTourSkipped = getIn(cookies, ['GUIDED_TOUR_SKIPPED'])

		const isGuidedTourActive = getGuidedTourStatus()

		if (
			currentStatus === 'verified' &&
			isGuidedTourActive === 'true' &&
			guidedTourCount <= 15 &&
			!isGuidedTourSkipped
		) {
			yield put(WebTourDuc.creators.setTourStatus(true))
			yield put(WebTourDuc.creators.setTourOpenStatus(true))
			yield put(AuthDuc.creators.guidedTourCounter(userID))
		}

		const orgsWithWeighBridge =
			currentOrganisation.includes('palmoil-ffbDealer') ||
			currentOrganisation.includes('palmoil-ffbSubDealer') ||
			currentOrganisation.includes('rice-dealer')

		// Incoming Area, Outgoing Area, Outgoing Tank and Weighbridge is mandatory
		const isMill =
			currentOrganisation.includes('palmoil-mill') ||
			currentOrganisation.includes('rice-mill')

		// Incoming Area, Outgoing Tank and Weighbridge is mandatory
		const isKernelMill = currentOrganisation.includes('palmoil-kernelMill')

		// Incoming Tank, Outgoing Tank and Weighbridge
		const orgsWithTanksAndWeighbridge =
			currentOrganisation.includes('palmoil-refinery') ||
			currentOrganisation.includes('palmoil-oleochemicals')

		const modals = []
		let modalStatus = false

		if (!isGuidedTourOpen) {
			if (!languageSet) {
				modals.push('chooseLanguage')
				modalStatus = true
			}
			if (!employeesAvailable) {
				modals.push('chooseEmployee')
				modalStatus = true
			}
			if (!partnersAvailable) {
				modals.push('choosePartner')
				modalStatus = true
			}
			if (!isMarketplaceHost()) {
				if (isMill && !outgoingAreaAvailable) {
					modals.push('createOutgoingArea')
					modalStatus = true
				}
				if (isMill || isKernelMill) {
					if (!incomingAreaAvailable) {
						modals.push('createIncomingArea')
						modalStatus = true
					}
					if (!outgoingTankAvailable) {
						modals.push('createOutgoingTank')
						modalStatus = true
					}
					if (!weighbridgeAvailable) {
						modals.push('createWeighbridge')
						modalStatus = true
					}
				}
				if (orgsWithWeighBridge && !weighbridgeAvailable) {
					modals.push('createWeighbridge')
					modalStatus = true
				}
				if (orgsWithTanksAndWeighbridge) {
					if (!incomingTankAvailable) {
						modals.push('createIncomingTank')
						modalStatus = true
					}
					if (!outgoingTankAvailable) {
						modals.push('createOutgoingTank')
						modalStatus = true
					}
					if (!weighbridgeAvailable) {
						modals.push('createWeighbridge')
						modalStatus = true
					}
				}
			}
		}

		const firstTimeSignInStats = {
			incomingAreaAvailable,
			outgoingAreaAvailable,
			incomingTankAvailable,
			outgoingTankAvailable,
			weighbridgeAvailable,
			partnersAvailable,
			employeesAvailable,
			languageSet,
		}

		yield put(AuthDuc.creators.setFirstTimeSignInModalStatus(modalStatus))

		yield put(AuthDuc.creators.setFirstTimeSignInModal(modals))
		yield put(
			AuthDuc.creators.setFirstTimeSignInStats(firstTimeSignInStats)
		)
	} catch (e) {
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message:
					'Unable to get the user permissions. Please try again later.',
			})
		)
		logger.log(e)
		yield SignOutUser({
			redirectTo: window.location.href,
		})
	}
}

export function* fetchOrgAssets() {
	try {
		const vehicleUrl = `${getCoreEndPoint()}assets/vehicles?state=active`
		const vehicles = yield CallWithRefreshCheck(vehicleUrl)
		const tractorList = (getIn(vehicles, ['data', 'list']) || []).filter(
			asset => asset.meta.type === 'Tractor'
		)

		const binUrl = `${getCoreEndPoint()}assets/storageunits?type=container&state=active`
		const bins = yield CallWithRefreshCheck(binUrl)

		const productionLineUrl = `${getCoreEndPoint()}assets/productionlinelist?state=active`
		const productionLineList = yield CallWithRefreshCheck(productionLineUrl)

		const tankYardWareHouseUrl = `${getCoreEndPoint()}assets/storageunits?type=neq(production-line)&state=active`
		const tankYardWareHouseList = yield CallWithRefreshCheck(
			tankYardWareHouseUrl
		)

		const orgAssets = {
			tractorList,
			vehicles: getIn(vehicles, ['data', 'list']) || [],
			storageunits: getIn(bins, ['data', 'list']) || [],
			productionLine: getIn(productionLineList, ['data', 'list']) || [],
			tankYardWareHouse:
				getIn(tankYardWareHouseList, ['data', 'list']) || [],
		}

		yield put(AuthDuc.creators.fetchOrgAssetsSuccess(orgAssets))
	} catch (e) {
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message:
					'Unable to get the user permissions. Please try again later.',
			})
		)
		logger.log(e)
		yield SignOutUser({
			redirectTo: window.location.href,
		})
	}
}

export function* fetchUserLists() {
	try {
		const employeeUrl = `${getIAMEndPoint()}clients/users?limit=100`
		const { data = {} } = yield CallWithRefreshCheck(employeeUrl)
		const userCount = {}

		data.list.forEach(user => {
			if (user.orgRole in userCount) {
				userCount[user.orgRole] += 1
			} else {
				userCount[user.orgRole] = 1
			}
		})
		yield put(AuthDuc.creators.fetchUserListsCountSuccess(userCount))
		yield put(AuthDuc.creators.fetchUserListsSuccess(data.list))
	} catch (e) {
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message:
					'Unable to get the user permissions. Please try again later.',
			})
		)
		logger.log(e)
		yield SignOutUser({
			redirectTo: window.location.href,
		})
	}
}

export function* checkAppVersion() {
	try {
		const requestUrl = '/-/health.json'
		const data = yield call(request, requestUrl)
		const appVersion = getIn(data, ['release', 'version']) || ''

		return appVersion
	} catch (err) {
		logger.log(err)
	}
}

function* guidedTourCounter(action) {
	try {
		const { clientID } = action
		yield put(
			AppDuc.creators.showGlobalLoader('Update-guided-tour-Counter')
		)
		const requestUrl = `${getIAMEndPoint()}clients/guidedtour/users/${clientID}`
		const options = {
			method: 'PUT',
		}
		yield call(request, requestUrl, options)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(
			AppDuc.creators.hideGlobalLoader('Update-guided-tour-Counter')
		)
	}
}

function* SignInUser(action) {
	const {
		credentials: {
			email,
			password,
			enableLoginCaptcha,
			recaptcha,
			provider,
			token,
		},
		helpers: { setSubmitting },
	} = action
	try {
		const requestUrl = `${getIAMEndPoint()}clients/authenticate`
		const creds = provider
			? {
					provider,
					token,
			  }
			: {
					loginID: email,
					password,
			  }

		if (enableLoginCaptcha) {
			creds.captchaToken = recaptcha
		}

		const options = {
			method: 'POST',
			body: JSON.stringify(creds),
		}
		const { data, status } = yield call(request, requestUrl, options)

		if (status === 202) {
			const { query } = yield select(AuthDuc.selectors.location)
			yield put(
				MainRouteDuc.creators.redirect(
					MainRouteDuc.types.AUTH,
					{
						action: 'two-step-verification',
					},
					{
						email,
						redirect_to: query?.redirect_to,
					}
				)
			)
		} else {
			yield put(AuthDuc.creators.onSuccessfulLogin(data, email))
		}
	} catch (e) {
		if (setSubmitting) {
			setSubmitting(false)
		}
		logger.error(e)

		const { message, status } = e

		if (status === 418) {
			yield put(
				MainRouteDuc.creators.redirect(
					MainRouteDuc.types.AUTH,
					{
						action: 'two-step-verification',
					},
					{
						email,
					}
				)
			)
		} else {
			yield put(
				AppDuc.creators.showToast({
					messageType: 'error',
					message,
				})
			)
		}
	}
}

function* forgotPassword({ email }) {
	try {
		yield put(AuthDuc.creators.handleForgotPasswordLoading(true))
		const requestUrl = `${getIAMEndPoint()}clients/users/password/reset`

		const values = {
			email,
			path: 'auth/reset-password',
		}

		const options = {
			method: 'POST',
			body: JSON.stringify(values),
		}

		const { data } = yield call(request, requestUrl, options)

		if (data) {
			yield put(AuthDuc.creators.handleForgotPasswordLoading(false))
			yield put(AuthDuc.creators.switchToSuccessOnForgotPassword(data))
		} else {
			throw new Error('Unable to fetch details')
		}
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(AuthDuc.creators.handleForgotPasswordLoading(false))

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

export function* fetchOrgsDetails(action) {
	const { orgIDs = [], returnResponse, fetchAll, skipLoading } = action

	// if there were no orgId
	try {
		if (!skipLoading)
			yield put(AuthDuc.creators.handleOrgFetchStatuses(true))
		const requestUrl = `${getIAMEndPoint()}clients/organizations`
		const targetOrgIDs = orgIDs.filter(a => a)
		if (!fetchAll && !targetOrgIDs.length)
			throw new Error("Empty OrgIds can't be fetched")
		const response = yield CallWithRefreshCheck(
			`${requestUrl}?limit=100&${
				fetchAll ? '' : `id=${targetOrgIDs.join(',')}`
			}`,
			requestUrl
		)

		const list = getIn(response, ['data', 'list']) || []
		if (list.length) {
			const orgMap = list.reduce((agg, org) => {
				const aggregator = agg
				aggregator[org.id] = org

				return aggregator
			}, {})

			// push the org list to the state
			yield put(AuthDuc.creators.updateOrgDetails(orgMap))
		} else {
			logger.log('There are no associated information for orgs: ', orgIDs)
		}
		yield put(AuthDuc.creators.handleOrgFetchStatuses(false, false))
		if (returnResponse) return list
	} catch (e) {
		if (returnResponse) return []
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message: message || 'Unable to fetch org lists',
			})
		)
		if (!skipLoading)
			yield put(AuthDuc.creators.handleOrgFetchStatuses(false, true))
	}
}

function* fetchPartnerOrgs(action) {
	const { returnResponse, fetchAll, skipLoading } = action

	// if there were no orgId
	try {
		if (!skipLoading)
			yield put(AuthDuc.creators.handleOrgFetchStatuses(true))
		const requestUrl = `${getIAMEndPoint()}clients/organizations/-/partners`
		const response = yield CallWithRefreshCheck(
			`${requestUrl}${fetchAll ? '' : '?limit=100'}`
		)
		const list = getIn(response, ['data', 'list']) || []
		if (list.length) {
			const orgMap = list.reduce((agg, organisation) => {
				const aggregator = agg
				const org = organisation
				const category = getIn(org, ['categories', 0, 'id']) || ''
				const parentName =
					getIn(org, ['meta', 'parentOrganization', 0, 'name']) || ''
				const hasParent =
					category === 'palmoil-collectionPoint' && parentName !== ''
				org.name = hasParent ? `${parentName} - ${org.name}` : org.name
				aggregator[org.id] = org

				return aggregator
			}, {})
			// push the org list to the state
			yield put(AuthDuc.creators.setPartnerDetails(orgMap))
		} else {
			logger.log('There are no associated partners for  this org')
		}
		yield put(AuthDuc.creators.handleOrgFetchStatuses(false, false))
		if (returnResponse) return list
	} catch (e) {
		if (returnResponse) return []
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message: message || 'Unable to fetch org lists',
			})
		)
		if (!skipLoading)
			yield put(AuthDuc.creators.handleOrgFetchStatuses(false, true))
	}
}

function* fetchGlobalOrgs() {
	try {
		yield put(
			AppDuc.creators.showGlobalLoader('fetch-global-organizations')
		)
		const requestUrl = `${getIAMEndPoint()}clients/globalorganizations`
		const { data } = yield CallWithRefreshCheck(requestUrl)
		const list = data || []
		const formattedList = []
		if (list.length) {
			list.forEach(item => {
				formattedList.push({
					label: item.name,
					value: item.name,
					id: item.id,
				})
			})
		}
		yield put(AuthDuc.creators.setGlobalOrgs(formattedList))
	} catch (err) {
		logger.log(err)
	} finally {
		yield put(
			AppDuc.creators.hideGlobalLoader('fetch-global-organizations')
		)
	}
}

function* fetchAllPartners() {
	try {
		yield put(AppDuc.creators.showGlobalLoader('fetch-all-partners'))
		const orgID = yield getOrgIDFromLoggedUser()
		const requestUrl = `${getIAMEndPoint()}clients/organizations/${orgID}/partners/nopagination`
		const { data } = yield CallWithRefreshCheck(requestUrl)
		const list = data || []
		yield put(AuthDuc.creators.setAllPartners(list))
	} catch (err) {
		logger.log(err)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-all-partners'))
	}
}

function* searchPartner(action) {
	try {
		const { query } = action

		const requestUrl = `${getIAMEndPoint()}clients/organizations/-/partners/search?query=${query}`
		const response = yield CallWithRefreshCheck(requestUrl)

		const list = getIn(response, ['data', 'list']) || []

		const orgMap = list.reduce((agg, organisation) => {
			const aggregator = agg
			const org = organisation
			const category = getIn(org, ['categories', 0, 'id']) || ''

			const parentName =
				getIn(org, ['meta', 'parentOrganization', 0, 'name']) || ''
			const hasParent =
				category === 'palmoil-collectionPoint' && parentName !== ''
			org.name = hasParent ? `${parentName} - ${org.name}` : org.name

			aggregator[org.id] = org

			return aggregator
		}, {})

		// push the org list to the state
		yield put(AuthDuc.creators.setPartnerDetails(orgMap))
	} catch (err) {
		logger.log(err)
	}
}

function* searchOrganization(action) {
	try {
		const { query, virtualAccess } = action

		const requestUrl = `${getIAMEndPoint()}clients/organizations/search?query=${query}&virtualOrg=${virtualAccess}`

		if (query.length < 3) {
			yield put(
				AuthDuc.creators.setOrganizationSearchDetails({
					results: {},
					message: 'expected atleast 3 characters in search query',
				})
			)

			return
		}

		const response = yield CallWithRefreshCheck(requestUrl)

		const list = getIn(response, ['data', 'list']) || []

		const orgMap = list.reduce((agg, organisation) => {
			const aggregator = agg

			const org = organisation
			const category = getIn(org, ['categories', 0, 'id']) || ''

			const parentName =
				getIn(org, ['meta', 'parentOrganization', 0, 'name']) || ''
			const hasParent =
				category === 'palmoil-collectionPoint' && parentName !== ''
			org.name = hasParent ? `${parentName} - ${org.name}` : org.name

			aggregator[org.id] = org

			return aggregator
		}, {})

		// push the org list to the state
		yield put(
			AuthDuc.creators.setOrganizationSearchDetails({
				results: orgMap,
				message: list.length === 0 ? 'No partners found' : '',
			})
		)
	} catch (err) {
		logger.log(err)
	}
}

function* searchProducts(action) {
	try {
		const { query } = action

		const requestUrl = `${getCoreEndPoint()}products/search?q=${query}`

		if (query.length < 3) {
			yield put(
				AuthDuc.creators.setProductSearchDetails({
					results: [],
					message: 'expected atleast 3 characters in search query',
				})
			)

			return
		}

		const response = yield CallWithRefreshCheck(requestUrl)

		const list = getIn(response, ['data', 'list']) || []

		const productMap = list.reduce((agg, prod) => {
			const aggregator = agg
			aggregator[prod.id] = prod

			return aggregator
		}, {})

		// push the org list to the state
		yield put(
			AuthDuc.creators.setProductSearchDetails({
				results: productMap,
				message: list.length === 0 ? 'No product found' : '',
			})
		)
	} catch (err) {
		logger.log(err)
	}
}

function* searchPartnerOrganization(action) {
	try {
		const { query } = action

		const requestUrl = `${getIAMEndPoint()}clients/organizations/-/partners/search?query=${query}`

		if (query.length < 3) {
			yield put(
				AuthDuc.creators.setOrganizationSearchDetails({
					results: {},
					message: 'expected atleast 3 characters in search query',
				})
			)

			return
		}

		const response = yield CallWithRefreshCheck(requestUrl)

		const list = getIn(response, ['data', 'list']) || []

		const orgMap = list.reduce((agg, org) => {
			const aggregator = agg
			aggregator[org.id] = org

			return aggregator
		}, {})

		// push the org list to the state
		yield put(
			AuthDuc.creators.setOrganizationSearchDetails({
				results: orgMap,
				message: list.length === 0 ? 'No partners found' : '',
			})
		)
	} catch (err) {
		logger.log(err)
	}
}

function* readSelectedOrg(action) {
	try {
		const { id } = action
		yield logger.log(id)
	} catch (err) {
		logger.log(err)
	}
}

export function* getUserProfileFromLoggedUser() {
	try {
		let userProfile = yield select(AuthDuc.selectors.getUserProfile)

		if (!isEmptyObject(userProfile)) return userProfile
		if (profileFetchStatus.status) {
			// check if already fetching, if yes, wait for it to arrive,
			const { success } = yield race({
				success: take(AuthDuc.creators.setUserProfile().type),
				fail: take(AuthDuc.creators.setProfileErrorStatus().type),
			})

			if (success) {
				userProfile = yield select(AuthDuc.selectors.getUserProfile)
			}
		} else {
			userProfile = yield GetUserProfile()
		}

		return userProfile
	} catch (e) {
		logger.log(e)
		throw e
	}
}

function* addOrgAddress(action) {
	const { address, successToast, helpers } = action
	const { setSubmitting, onSuccess } = helpers
	// if there were no orgId
	try {
		const orgID = yield getOrgIDFromLoggedUser()

		const requestUrl = `${getIAMEndPoint()}clients/organizations/${orgID}`
		const options = {
			method: 'PUT',
			body: JSON.stringify({
				secondaryAddress: address,
			}),
		}

		yield CallWithRefreshCheck(requestUrl, options)

		// fetch org list now
		yield fetchOrgsDetails({
			orgIDs: [orgID],
		})

		yield put(
			AppDuc.creators.showToast({
				messageType: 'success',
				message: successToast,
			})
		)
		if (onSuccess) onSuccess()
	} catch (e) {
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message:
					message ||
					'Unable to update shipping address. Please try again later.',
			})
		)
	} finally {
		if (setSubmitting) setSubmitting(false)
	}
}

/**
 * This handler is always active on route changes and ensures the user doesn't land up
 * some how
 */
function* fetchUserRolesAndCheckActiveRoute(action) {
	let { routeActionType } = action
	// if there were no orgId
	try {
		// if user profile is being fetch, wait up
		if (profileFetchStatus.status) {
			// check if already fetching, if yes, wait for it to arrive,
			const { fail } = yield race({
				success: take(AuthDuc.creators.setUserProfile().type),
				fail: take(AuthDuc.creators.setProfileErrorStatus().type),
			})

			if (fail) {
				throw new Error('Unable to fetch roles')
			}
		}

		if (!routeActionType) {
			const locationState = yield select(AuthDuc.creators.location)
			routeActionType = locationState.type
		}
		// fetch the last time stamp
		const { timeStamp } = yield select(
			AuthDuc.selectors.getCurrentUserRoles
		)

		if (!timeStamp) {
			// fresh fetch

			yield fetchUserRoles({ skipLoading: true })
		}

		const rules = featureAccessBasedOnType[routeActionType]

		// check if user is allowed
		const isAllowed = yield checkIfAtleastOneFeatureAllowed(rules)

		// redirect to proper dashboard based on users allowed roles
		const { allowed, timeStamp: lastTimeStamp } = yield select(
			AuthDuc.selectors.getCurrentUserRoles
		)

		if (!isAllowed) {
			// User does not have access to main dashboard, which means we need to find alternative
			// dashboard
			const targetAction = getActiveUserDashboardAction(allowed)
			// Ensure its not the main dashboard, else it would go in loop of no accesses
			// this assumes that atleast one dashboard is allowed.
			if (targetAction.type && targetAction.type !== routeActionType) {
				// send them to dashboard
				yield put(targetAction)

				return
			}
			logger.log(
				'Not Allowed - Route:',
				routeActionType,
				' Rules:',
				rules
			)
			if (routeActionType !== MainRouteDuc.types.Auth) {
				yield put(MainRouteDuc.creators.switch401())
			}

			return
		}

		const has10minPassed =
			(new Date().getTime() - new Date(lastTimeStamp).getTime()) / 1000 >
			600

		// check if we should flush new rules now
		if (!timeStamp || has10minPassed) {
			// fresh fetch

			yield fetchUserRoles({
				skipLoading: true,
			})
		}
	} catch (e) {
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message:
					message ||
					'Unable to update shipping address. Please try again later.',
			})
		)
	}
}

export function* fetchAllProducts(action) {
	try {
		yield put(AuthDuc.creators.fetchProductsStatus(true))
		yield put(AppDuc.creators.showGlobalLoader('fetch-products-listing'))

		let requestUrl = `${getCoreEndPoint()}clients/organizations/products/stats/nopagination`
		if (action.inventoryType === 'incoming') {
			requestUrl += '?inventoryType=incoming'
		} else if (action.inventoryType === 'outgoing') {
			requestUrl += '?inventoryType=outgoing'
		}

		const { data = {} } = yield CallWithRefreshCheck(requestUrl)

		const list = (getIn(data, ['list']) || []).map(k => {
			const productInfo = k.product
			productInfo.lcv = k?.meta?.lcv

			return productInfo
		})

		yield put(AuthDuc.creators.fetchProductsSuccess(list))
	} catch (e) {
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
		yield put(AuthDuc.creators.fetchProductsStatus(false, true))
	} finally {
		yield put(AuthDuc.creators.fetchProductsStatus(false))
		yield put(AppDuc.creators.hideGlobalLoader('fetch-products-listing'))
	}
}

function* fetchAllStorageTank() {
	try {
		const requestUrl = `${getCoreEndPoint()}assets/storageunits?type=tank&state=active`
		const { data = {} } = yield CallWithRefreshCheck(requestUrl)
		yield put(AuthDuc.creators.fetchAllStorageTankSuccess(data.list))
	} catch (e) {
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* fetchAllStorageUnits() {
	try {
		const requestUrl = `${getCoreEndPoint()}assets/storageunits?state=active`
		const { data = {} } = yield CallWithRefreshCheck(requestUrl)
		yield put(AuthDuc.creators.fetchAllStorageUnitsSuccess(data.list))
	} catch (e) {
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* fetchNotifications(limit = 10, status = 'sent') {
	try {
		// Added this condition because limit is receiving some object value from somewhere
		let max = 10
		// eslint-disable-next-line no-restricted-globals
		if (!isNaN(limit)) {
			max = limit
		}
		const requestUrl = `${getNotifierEndPoint()}clients/-/notifications?limit=${max}&sort=desc(createdAt)&status=${status}`
		const data = yield CallWithRefreshCheck(requestUrl)
		const notifications = getIn(data, ['data'])
		yield put(AuthDuc.creators.setNotifications(notifications))
	} catch (e) {
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* singleNotificationUpdateStatus(action) {
	try {
		const { id, status } = action
		const value = {
			status,
		}
		const requestUrl = `${getNotifierEndPoint()}clients/-/notifications/${id}`
		const options = {
			method: 'PUT',
			body: JSON.stringify(value),
		}
		yield call(request, requestUrl, options)
		yield put(AuthDuc.creators.fetchNotifications())
	} catch (e) {
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* fetchActor() {
	try {
		const requestUrl = `${getIAMEndPoint()}clients/_/computed-access`
		const options = {
			method: 'GET',
		}

		yield put(AppDuc.creators.showGlobalLoader('fetch-actor'))

		const accesses = yield CallWithRefreshCheck(requestUrl)
		const actor = IAMAuth(accesses.data).dataFilters(
			'iam.clients.users.r',
			'actor'
		)

		yield put(AuthDuc.creators.setActor(actor))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-actor'))
	}
}

function* resetPassword(action) {
	const {
		values,
		helpers: { setSubmitting },
	} = action
	try {
		yield put(AuthDuc.creators.handleResetPasswordLoading(true))
		const requestUrl = `${getIAMEndPoint()}clients/users/password/reset`

		const options = {
			method: 'PUT',
			body: JSON.stringify(values),
		}

		const { data } = yield call(request, requestUrl, options)
		if (data) {
			yield put(AuthDuc.creators.handleResetPasswordLoading(false))
			yield put(AuthDuc.creators.switchToSuccessOnResetPassword(data))
		}
		setSubmitting(false)
	} catch (err) {
		setSubmitting(false)

		const { message } = err

		logger.log(err)

		yield put(AuthDuc.creators.handleResetPasswordLoading(false))
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}
function* verifyOtp(action) {
	const {
		currentUserEmail,
		secret,
		helpers: { setSubmitting },
	} = action
	try {
		const requestUrl = `${getIAMEndPoint()}clients/authenticate/otp`
		const creds = {
			loginID: currentUserEmail,
			secret,
		}
		const options = {
			method: 'PUT',
			body: JSON.stringify(creds),
		}
		const { data } = yield call(request, requestUrl, options)

		yield put(AuthDuc.creators.onSuccessfulLogin(data))
	} catch (e) {
		if (setSubmitting) {
			setSubmitting(false)
		}
		const { message } = e
		logger.error(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* initiateOTP(action) {
	try {
		const { currentUserEmail } = action
		const values = {
			loginID: currentUserEmail,
		}

		yield put(AppDuc.creators.showGlobalLoader('verify-email'))

		const requestOtpUrl = `${getIAMEndPoint()}clients/authenticate/otp`
		const otpOptions = {
			method: 'POST',
			body: JSON.stringify(values),
		}

		const { data } = yield call(request, requestOtpUrl, otpOptions)

		yield Toast({
			type: 'success',
			message: data,
		})
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('verify-email'))
	}
}

function* checkIfInitialSetupIsRequired() {
	try {
		yield fetchOrgDetailsCount()
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* setLanguage(action) {
	try {
		const { language } = action
		yield put(
			CookieDuc.creators.setCookie({
				cookieName: 'I18N_LANGUAGE',
				cookieValue: language,
				storage: 'C',
			})
		)

		const newLanguge = {
			meta: {
				language,
			},
		}

		const userID = yield select(AuthDuc.selectors.getClientID)
		const requestUrl = `${getIAMEndPoint()}clients/users/${userID}`
		const options = {
			method: 'PUT',
			body: JSON.stringify(newLanguge),
		}

		yield call(request, requestUrl, options)
		i18n.changeLanguage(language)
		yield fetchOrgDetailsCount()
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

export default function* AuthSaga() {
	try {
		yield all([
			takeEvery(AuthDuc.creators.fetchUserProfile().type, GetUserProfile),
			takeEvery(
				AuthDuc.creators.fetchCertificates().type,
				fetchCertificates
			),
			takeEvery(
				AuthDuc.creators.guidedTourCounter().type,
				guidedTourCounter
			),
			takeEvery(
				AuthDuc.creators.fetchNotifications().type,
				fetchNotifications
			),
			takeLatest(
				AuthDuc.creators.singleNotificationUpdateStatus().type,
				singleNotificationUpdateStatus
			),
			takeLatest(
				AuthDuc.creators.fetchLoggedInUserOrg().type,
				getOrgIDFromLoggedUser
			),
			takeLatest(AuthDuc.creators.loginUser().type, SignInUser),
			takeLatest(AuthDuc.creators.signOutUser().type, SignOutUser),
			takeLatest(AuthDuc.creators.initiateOTP().type, initiateOTP),
			takeLatest(AuthDuc.creators.verifyOtp().type, verifyOtp),
			takeLatest(
				AuthDuc.creators.initiateForgotPassword().type,
				forgotPassword
			),
			takeEvery(
				AuthDuc.creators.initiateRefreshToken().type,
				refreshTokenHandler
			),
			takeLatest(
				AuthDuc.creators.fetchOrgDetails().type,
				fetchOrgsDetails
			),
			takeLatest(
				AuthDuc.creators.fetchPartnerOrgs().type,
				fetchPartnerOrgs
			),
			takeLatest(
				AuthDuc.creators.fetchGlobalOrgs().type,
				fetchGlobalOrgs
			),
			takeLatest(AuthDuc.creators.fetchOrgsUser().type, fetchOrgsUser),
			takeLatest(
				AuthDuc.creators.fetchAllPartners().type,
				fetchAllPartners
			),
			takeLatest(
				AuthDuc.creators.addOrgShippingAddress().type,
				addOrgAddress
			),
			takeLatest(
				AuthDuc.creators.validateUserRouteChange().type,
				fetchUserRolesAndCheckActiveRoute
			),
			takeLatest(
				AuthDuc.creators.fetchAllProducts().type,
				fetchAllProducts
			),
			takeLatest(AuthDuc.creators.searchPartner().type, searchPartner),
			takeLatest(
				AuthDuc.creators.searchOrganization().type,
				searchOrganization
			),
			takeLatest(AuthDuc.creators.searchProducts().type, searchProducts),

			takeLatest(
				AuthDuc.creators.searchPartnerOrganization().type,
				searchPartnerOrganization
			),
			takeLatest(
				AuthDuc.creators.readSelectedOrg().type,
				readSelectedOrg
			),
			takeLatest(AuthDuc.creators.fetchActor().type, fetchActor),
			takeLatest(
				AuthDuc.creators.fetchAllStorageTank().type,
				fetchAllStorageTank
			),
			takeLatest(
				AuthDuc.creators.fetchAllStorageUnits().type,
				fetchAllStorageUnits
			),
			takeLatest(AuthDuc.creators.resetPassword().type, resetPassword),
			takeLatest(AuthDuc.creators.setLanguage().type, setLanguage),
			takeLatest(AuthDuc.creators.fetchUserLists().type, fetchUserLists),
			takeLatest(
				AuthDuc.creators.checkIfInitialSetupIsRequired().type,
				checkIfInitialSetupIsRequired
			),
		])
	} catch (e) {
		logger.error(e)
	}
}
