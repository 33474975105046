import React from 'react'
import { useSelector } from 'react-redux'
import { NotFoundBlock } from 'core-app/modules/App/components/Error'
import { DealerDuc } from 'core-app/modules/Dealer/duc'
import { MainDashboard } from 'core-app/modules/Dealer/containers/MainDashboard'
import SubDashboard from 'core-app/modules/Dealer/containers/SubDashboard'
import { WorkCreation } from 'core-app/modules/Dealer/containers/Work'

const modulesMap = {
	dashboard: MainDashboard,
	subDashboard: SubDashboard,
	workCreation: WorkCreation,
}

const Dealer = () => {
	const activeModule = useSelector(DealerDuc.selectors.activeModule)

	const Component = modulesMap[activeModule] || NotFoundBlock
	// if we are here, we have a submodule, route it to the module

	return <Component />
}

export default Dealer
