import React from 'react'
import { Box } from 'ui-lib/utils/Box'
import { Label, TableTitle } from 'ui-lib/components/Typography'
import { ButtonWithNoBorder } from 'ui-lib/components/Button'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { SortDown, SortReset } from 'ui-lib/components/Table'
import { getNextSortOrder } from 'core-app/utils/helpers'
import { getDateByFormat } from 'core-app/utils/date'
import { Status } from 'core-app/shared/components/Status'
import { getIn } from 'timm'
import { SortableKeys, TABLE_ALIAS } from '../config'

export const getDocumentColumnConfig = ({
	type,
	docType,
	sortsMap,
	onChange,
	userList,
	t,
}) => {
	return [
		{
			property: 'date',
			header: (
				<Box
					row
					alignItems="center"
					style={{ cursor: 'pointer' }}
					onClick={() => {
						if (SortableKeys.includes('date')) {
							onChange('initiate_sort', {
								type,
								docType,
								field: 'date',
								order: getNextSortOrder(sortsMap.date),
							})
						}
					}}
				>
					{SortableKeys.includes('date') && (
						<IconWrapper
							width={10}
							margin="0 5px"
							sortUp={sortsMap.date === 'asc'}
							sortDown={sortsMap.date === 'desc'}
						>
							<Icon
								rotate180={sortsMap.date === 'asc'}
								glyph={sortsMap.date ? SortDown : SortReset}
							/>
						</IconWrapper>
					)}
					<TableTitle>{t(TABLE_ALIAS.date)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { startTime } = datum

				return <Label as="span">{getDateByFormat(startTime)}</Label>
			},
		},
		{
			property: 'tripsNumber',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.tripsNumber)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { number } = datum

				return <Label as="span">{`Trip ${number}`}</Label>
			},
		},

		{
			property: 'driverName',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.driverName)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { clientID } = datum
				const driver = userList.filter(user => user.id === clientID)
				const driverName = getIn(driver, [0, 'fullName']) || '---'

				return <Label as="span">{driverName}</Label>
			},
		},

		{
			property: 'vehicleNumber',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.vehicleNumber)}</TableTitle>
				</Box>
			),
			render: datum => {
				const vehicleName =
					getIn(datum, ['meta', 'vehicle', 'number']) || '---'

				return <Label as="span">{vehicleName}</Label>
			},
		},

		{
			property: 'quantity',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.quantity)}</TableTitle>
				</Box>
			),
			render: datum => {
				let totalQty = 0
				const entities = getIn(datum, ['meta', 'entities']) || {}
				const entitiesList = Object.assign([], Object.values(entities))
				if (entitiesList.length > 0) {
					totalQty = entitiesList
						.map(entity =>
							getIn(entity, ['products', 0, 'quantity'])
						)
						.reduce((ttlQty = 0, entity = 0) => ttlQty + entity)
				}

				return <Label as="span">{`${totalQty} `}</Label>
			},
		},
		{
			property: 'callToAction',
			render: datum => {
				return (
					<ButtonWithNoBorder
						label={t('common.viewBtn')}
						onClick={() =>
							onChange('open_document', {
								row: datum,
							})
						}
					/>
				)
			},
		},
	]
}

export const getDocumentViewColumnConfig = ({
	onChange,
	partnerList,
	currentUserOrg,
	t,
}) => {
	return [
		{
			property: 'date',
			header: <TableTitle>{t(TABLE_ALIAS.date)}</TableTitle>,
			render: datum => {
				const { createdAt } = datum

				return <Label as="span">{getDateByFormat(createdAt)}</Label>
			},
		},
		{
			property: 'partnerName',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.partnerName)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { receivingPartyID, initiatingPartyID } = datum
				const partnerID =
					currentUserOrg.id === initiatingPartyID
						? receivingPartyID
						: initiatingPartyID
				const partner = partnerList.filter(
					_partner => _partner.id === partnerID
				)
				const partnerName = getIn(partner, [0, 'name']) || '--'

				return <Label as="span">{partnerName}</Label>
			},
		},

		{
			property: 'doNumber',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.doNumber)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { number } = datum

				return <Label as="span">{number || '---'}</Label>
			},
		},

		{
			property: 'quantity',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.quantity)}</TableTitle>
				</Box>
			),
			render: datum => {
				const quantity = getIn(datum, ['products', 0, 'quantity'])
				const quantityUOM = getIn(datum, ['products', 0, 'uom'])

				return <Label as="span">{`${quantity} ${quantityUOM}`}</Label>
			},
		},

		{
			property: 'action',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.action)}</TableTitle>
				</Box>
			),
			render: datum => {
				const status = getIn(datum, ['status', 'state']) || '---'

				return <Status currentStatus={status} />
			},
		},
		{
			property: 'callToAction',
			render: datum => {
				return (
					<ButtonWithNoBorder
						label={t('common.viewBtn')}
						onClick={() =>
							onChange('open_document', {
								row: datum,
							})
						}
					/>
				)
			},
		},
	]
}
