import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn, omit, setIn, merge } from 'timm'
import { MainRouteDuc } from 'core-app/routes/duc'
import { CookieDuc } from 'core-app/modules/App/cookieDuc'
import { WbTicketDuc } from 'core-app/modules/WbTicket/duc'
import { AppDuc } from 'core-app/modules/App/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { isEmptyObject, useDebouncedCallback } from 'core-app/utils/helpers'
import { initiateSort, getTargetFilterQueries } from 'core-app/shared/helpers'
import { Title } from 'core-app/shared/components/Title'
import { getDocumentColumnConfig } from 'core-app/modules/WbTicket/components/Columns'
import { BoxShadowTable } from 'core-app/shared/components/BoxShadowTable'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import {
	ButtonIconWrapper,
	Button,
	ButtonWithNoBorder,
} from 'ui-lib/components/Button'
import { Input } from 'ui-lib/components/Input'
import { Label } from 'ui-lib/components/Typography'
import { DatePicker } from 'ui-lib/components/Datepicker'
import { Icon } from 'ui-lib/icons/components/Icon'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import Records from 'ui-lib/icons/receipt.svg'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import theme from 'ui-lib/utils/base-theme'

const CTAWrapper = styled(Box)(p => ({
	padding: 10,
	minHeight: 62,
	background: p.theme.color.white,
	borderBottomLeftRadius: 4,
	borderBottomRightRadius: 4,
	border: `1px solid ${p.theme.color.grey4}`,
	color: p.theme.color.primary,
	display: 'flex',
	flexDirection: 'row',
}))

const MainDashboard = () => {
	const searchName = 'query'
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const selectedCPID = useSelector(CookieDuc.selectors.getSelectedCPID)
	const location = useSelector(WbTicketDuc.selectors.location)
	const isLoading = useSelector(
		WbTicketDuc.selectors.mainDashboardLoadingStatus
	)

	const partnerList = useSelector(AuthDuc.selectors.getTargetPartnersOfUser)
	const activeFilters = useSelector(WbTicketDuc.selectors.getActiveFilters)
	const activeDateFilters = getIn(activeFilters, ['createdAt']) || {}
	let activeStartDate = getIn(activeDateFilters, ['gte', 0]) || null
	if (activeStartDate) activeStartDate = new Date(activeStartDate)
	let activeEndDate = getIn(activeDateFilters, ['lte', 0]) || null
	if (activeEndDate) activeEndDate = new Date(activeEndDate)

	const [searchValue, setSearchValue] = useState('')

	const [startDate, setStartDate] = useState(activeStartDate)
	const [endDate, setEndDate] = useState(activeEndDate)

	const activeSorts = useSelector(
		WbTicketDuc.selectors.getMainDashboardActiveSorts
	)

	const pagination = useSelector(
		WbTicketDuc.selectors.fetchMainDashboardPagination
	)
	const documents = useSelector(
		WbTicketDuc.selectors.fetchMainDashboardDocuments
	)
	const totalDocument = getIn(pagination, ['total']) || ''

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.weighBridge'),
			name: 'weighBridge',
			isActive: false,
		},
	]

	/** Handlers to react on actions */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
	}

	const handleOnChange = useCallback(
		(actionType, meta = {}) => {
			switch (actionType) {
				case 'initiate_sort': {
					initiateSort(
						dispatch,
						meta,
						WbTicketDuc.creators.fetchDocuments,
						location,
						meta.type
					)
					break
				}
				case 'apply_filter': {
					const { filterType, filterValue } = meta
					const currentQuery = getIn(location, ['query']) || {}
					const filterQueries = omit(currentQuery, ['sort', 'q'])
					let targetFilters = {}
					if (isEmptyObject(filterValue)) {
						targetFilters = merge(
							currentQuery,
							merge(filterQueries, {
								[searchName]: searchValue,
							})
						)
					} else {
						const targetQueries = getTargetFilterQueries(
							'',
							'date_range',
							filterValue
						)

						targetFilters = merge(
							currentQuery,
							merge(
								filterQueries,
								{ [filterType]: targetQueries },
								{
									[searchName]: searchValue,
								}
							)
						)
					}

					dispatch(
						WbTicketDuc.creators.fetchDocuments(
							true,
							setIn(location, ['query'], targetFilters),
							true
						)
					)

					break
				}

				case 'open_document': {
					if (meta.row) {
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.WBTICKET$WDOCREFERENCE,
								{
									documentReference: meta.row.id,
								},
								{
									entityID: meta.row.meta.entityID,
								}
							)
						)
					}

					break
				}

				case 'paginate': {
					const { filterParams, filterValue } = meta
					const isSkip = false
					const filterType = 'createdAt'
					const { nextIndex } = filterParams
					let locationState = {}
					if (!isEmptyObject(filterValue)) {
						const targetQueries = getTargetFilterQueries(
							'',
							'date_range',
							filterValue
						)
						locationState = {
							query: {
								...location.query,
								[searchName]: searchValue,
								[filterType]: targetQueries,
								nextIndex,
								limit: 20,
							},
						}
					} else {
						locationState = {
							query: {
								...location.query,
								[searchName]: searchValue,
								nextIndex,
								limit: 20,
							},
						}
					}

					dispatch(
						WbTicketDuc.creators.fetchDocuments(
							isSkip,
							locationState
						)
					)
					break
				}

				default:
					break
			}
		},
		[dispatch, location, searchValue]
	)

	const handleSearch = useDebouncedCallback(value => {
		if (value.length > 3) {
			let filterValue = {}
			setSearchValue(value)
			if (startDate && endDate) {
				filterValue = {
					startDate: new Date(startDate).toISOString(),
					endDate: new Date(endDate).toISOString(),
				}
			}
			handleOnChange('apply_filter', {
				filterType: 'createdAt',
				filterValue,
				searchValue: value,
			})
		}
	}, 800)

	return (
		<Box padding={8} width="100%" height="100%">
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>

			<Box row justifyContent="space-between" alignItems="center">
				<Title title={t('weighbridge.title')} icon={Records} />

				<Box justifyContent="flex-end">
					{!selectedCPID && (
						<Box id="wbTicketUpload">
							<Button
								label={t('weighbridge.uploadTicket')}
								rounded
								primary
								onClick={() =>
									dispatch(
										MainRouteDuc.creators.switchPage(
											MainRouteDuc.types.WBTICKET$WACTION
										)
									)
								}
							/>
						</Box>
					)}
				</Box>
			</Box>
			<Box
				row
				justifyContent="space-between"
				alignItems="center"
				margin="8px 0"
			>
				<Box width={300}>
					<Input
						placeholder={t('createWork.chooseSmallholder')}
						value={searchValue}
						onChange={e => handleSearch(e.target.value)}
						extendStyles={{
							background: theme.color.grey13,
							borderRadius: '4px',
							borderWidth: '0.5px',
							borderStyle: 'solid',
							borderColor: theme.color.grey12,
							fontSize: '14px',
						}}
						hideError
					/>
				</Box>
				<Box row justifyContent="flex-end">
					<Box alignItems="flex-start" width={125} margin="0 5px">
						<Label small>{t('tdmDocumentListing.from')}</Label>
						<div style={{ fontSize: theme.fontSize.s }}>
							<DatePicker
								name="startDate"
								placeholder={t('tdmDocumentListing.choose')}
								hideError
								value={startDate}
								maxDate={endDate || new Date()}
								onChange={value => {
									setStartDate(value)
								}}
							/>
						</div>
					</Box>
					<Box alignItems="flex-start" width={125} margin="0 5px">
						<Label small>{t('tdmDocumentListing.to')}</Label>
						<div style={{ fontSize: theme.fontSize.s }}>
							<DatePicker
								name="toDate"
								placeholder={t('tdmDocumentListing.choose')}
								hideError
								toDate
								minDate={startDate}
								value={endDate}
								maxDate={endDate || new Date()}
								onChange={value => {
									setEndDate(value)
								}}
							/>
						</div>
					</Box>
					<Spacer size={10} horizontal={!isMobile} />

					<Box row alignItems="flex-end" margin="0 5px">
						<Button
							label={t('productTrace.apply')}
							type="submit"
							extendStyles={{
								padding: '2px 20px',
							}}
							primary
							rounded
							disabled={!endDate}
							onClick={() => {
								handleOnChange('apply_filter', {
									filterType: 'createdAt',
									filterValue: {
										startDate: new Date(
											startDate
										).toISOString(),
										endDate: new Date(
											endDate
										).toISOString(),
									},
									searchValue,
								})
							}}
						/>
						<Spacer size={10} horizontal={!isMobile} />

						<ButtonWithNoBorder
							label={t('productTrace.clear')}
							style={{
								fontSize: theme.fontSize.xl,
							}}
							onClick={() => {
								dispatch(
									WbTicketDuc.creators.fetchDocuments(
										true,
										location
									)
								)
								setEndDate(null)
								setStartDate(null)
								setSearchValue('')
							}}
						/>
					</Box>
				</Box>
			</Box>
			<Box>
				<BoxShadowTable
					size="large"
					isLoading={isLoading}
					columnConfig={getDocumentColumnConfig({
						onChange: handleOnChange,
						sortsMap: activeSorts,
						partnerList,
						t,
					})}
					rowData={documents}
				/>

				<CTAWrapper>
					<Label
						color="inherit"
						bold
						small
						style={{
							padding: '10px 4px',
						}}
					>
						{documents.length > 0
							? `${t('common.showingListFor')}
						  ${documents.length}
						 ${t('common.of')}
						  ${totalDocument}`
							: t('common.errorBlockMessage')}
					</Label>
					<Box
						style={{
							width: '75%',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Button
							plain
							disabled={
								documents.length === 0 ||
								documents.length === totalDocument
							}
							extendStyles={{
								background: '#F2F4FF',
								padding: '8px 24px',
								borderRadius: '24px',
								width: '170px',
								textAlign: 'center',
							}}
							label={t('common.showMore')}
							onClick={() => {
								let filterValue = {}
								if (startDate && endDate) {
									filterValue = {
										startDate: new Date(
											startDate
										).toISOString(),
										endDate: new Date(
											endDate
										).toISOString(),
									}
								}
								handleOnChange('paginate', {
									filterParams: pagination,
									filterValue,
								})
							}}
						/>
					</Box>
				</CTAWrapper>
			</Box>
			<Box padding="25px 2px" style={{ maxWidth: 150 }}>
				<Button
					label={t('common.back')}
					rounded
					customIcon={
						<ButtonIconWrapper lightBG>
							<Icon glyph={LeftArrowIcon} />
						</ButtonIconWrapper>
					}
					onClick={() =>
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.DASHBOARD
							)
						)
					}
				/>
			</Box>
		</Box>
	)
}

export { MainDashboard }
