export const countryCode = [
	{
		name: 'Malaysia',
		label: '+60',
	},

	{
		name: 'Indonesia',
		label: '+62',
	},

	{
		name: 'Colombia',
		label: '+57',
	},
	{
		name: 'India',
		label: '+91',
	},
	{
		name: 'Åland Islands',
		label: '+358',
	},
	{
		name: 'Albania',
		label: '+355',
	},
	{
		name: 'Algeria',
		label: '+213',
	},
	{
		name: 'American Samoa',
		label: '+1684',
	},
	{
		name: 'Andorra',
		label: '+376',
	},
	{
		name: 'Angola',
		label: '+244',
	},
	{
		name: 'Anguilla',
		label: '+1264',
	},
	{
		name: 'Antarctica',
		label: '+672',
	},
	{
		name: 'Antigua and Barbuda',
		label: '+1268',
	},
	{
		name: 'Argentina',
		label: '+54',
	},
	{
		name: 'Armenia',
		label: '+374',
	},
	{
		name: 'Aruba',
		label: '+297',
	},
	{
		name: 'Australia',
		label: '+61',
	},
	{
		name: 'Austria',
		label: '+43',
	},
	{
		name: 'Azerbaijan',
		label: '+994',
	},
	{
		name: 'Bahamas',
		label: '+1242',
	},
	{
		name: 'Bahrain',
		label: '+973',
	},
	{
		name: 'Bangladesh',
		label: '+880',
	},
	{
		name: 'Barbados',
		label: '+1246',
	},
	{
		name: 'Belarus',
		label: '+375',
	},
	{
		name: 'Belgium',
		label: '+32',
	},
	{
		name: 'Belize',
		label: '+501',
	},
	{
		name: 'Benin',
		label: '+229',
	},
	{
		name: 'Bermuda',
		label: '+1441',
	},
	{
		name: 'Bhutan',
		label: '+975',
	},
	{
		name: 'Bolivia, Plurinational State of bolivia',
		label: '+591',
	},
	{
		name: 'Bosnia and Herzegovina',
		label: '+387',
	},
	{
		name: 'Botswana',
		label: '+267',
	},
	{
		name: 'Bouvet Island',
		label: '+47',
	},
	{
		name: 'Brazil',
		label: '+55',
	},
	{
		name: 'British Indian Ocean Territory',
		label: '+246',
	},
	{
		name: 'Brunei Darussalam',
		label: '+673',
	},
	{
		name: 'Bulgaria',
		label: '+359',
	},
	{
		name: 'Burkina Faso',
		label: '+226',
	},
	{
		name: 'Burundi',
		label: '+257',
	},
	{
		name: 'Cambodia',
		label: '+855',
	},
	{
		name: 'Cameroon',
		label: '+237',
	},
	{
		name: 'Canada',
		label: '+1',
	},
	{
		name: 'Cape Verde',
		label: '+238',
	},
	{
		name: 'Cayman Islands',
		label: '+345',
	},
	{
		name: 'Central African Republic',
		label: '+236',
	},
	{
		name: 'Chad',
		label: '+235',
	},
	{
		name: 'Chile',
		label: '+56',
	},
	{
		name: 'China',
		label: '+86',
	},
	{
		name: 'Christmas Island',
		label: '+61',
	},
	{
		name: 'Cocos (Keeling) Islands',
		label: '+61',
	},
	{
		name: 'Comoros',
		label: '+269',
	},
	{
		name: 'Congo',
		label: '+242',
	},
	{
		name: 'Congo, The Democratic Republic of the Congo',
		label: '+243',
	},
	{
		name: 'Cook Islands',
		label: '+682',
	},
	{
		name: 'Costa Rica',
		label: '+506',
	},
	{
		name: "Cote d'Ivoire",
		label: '+225',
	},
	{
		name: 'Croatia',
		label: '+385',
	},
	{
		name: 'Cuba',
		label: '+53',
	},
	{
		name: 'Cyprus',
		label: '+357',
	},
	{
		name: 'Czech Republic',
		label: '+420',
	},
	{
		name: 'Denmark',
		label: '+45',
	},
	{
		name: 'Djibouti',
		label: '+253',
	},
	{
		name: 'Dominica',
		label: '+1767',
	},
	{
		name: 'Dominican Republic',
		label: '+1849',
	},
	{
		name: 'Ecuador',
		label: '+593',
	},
	{
		name: 'Egypt',
		label: '+20',
	},
	{
		name: 'El Salvador',
		label: '+503',
	},
	{
		name: 'Equatorial Guinea',
		label: '+240',
	},
	{
		name: 'Eritrea',
		label: '+291',
	},
	{
		name: 'Estonia',
		label: '+372',
	},
	{
		name: 'Ethiopia',
		label: '+251',
	},
	{
		name: 'Falkland Islands (Malvinas)',
		label: '+500',
	},
	{
		name: 'Faroe Islands',
		label: '+298',
	},
	{
		name: 'Fiji',
		label: '+679',
	},
	{
		name: 'Finland',
		label: '+358',
	},
	{
		name: 'France',
		label: '+33',
	},
	{
		name: 'French Guiana',
		label: '+594',
	},
	{
		name: 'French Polynesia',
		label: '+689',
	},
	{
		name: 'French Southern Territories',
		label: '+262',
	},
	{
		name: 'Gabon',
		label: '+241',
	},
	{
		name: 'Gambia',
		label: '+220',
	},
	{
		name: 'Georgia',
		label: '+995',
	},
	{
		name: 'Germany',
		label: '+49',
	},
	{
		name: 'Ghana',
		label: '+233',
	},
	{
		name: 'Gibraltar',
		label: '+350',
	},
	{
		name: 'Greece',
		label: '+30',
	},
	{
		name: 'Greenland',
		label: '+299',
	},
	{
		name: 'Grenada',
		label: '+1473',
	},
	{
		name: 'Guadeloupe',
		label: '+590',
	},
	{
		name: 'Guam',
		label: '+1671',
	},
	{
		name: 'Guatemala',
		label: '+502',
	},
	{
		name: 'Guernsey',
		label: '+44',
	},
	{
		name: 'Guinea',
		label: '+224',
	},
	{
		name: 'Guinea-Bissau',
		label: '+245',
	},
	{
		name: 'Guyana',
		label: '+592',
	},
	{
		name: 'Haiti',
		label: '+509',
	},
	{
		name: 'Heard Island and Mcdonald Islands',
		label: '+672',
	},
	{
		name: 'Holy See (Vatican City State)',
		label: '+379',
	},
	{
		name: 'Honduras',
		label: '+504',
	},
	{
		name: 'Hong Kong',
		label: '+852',
	},
	{
		name: 'Hungary',
		label: '+36',
	},
	{
		name: 'Iceland',
		label: '+354',
	},
	{
		name: 'Iran, Islamic Republic of Persian Gulf',
		label: '+98',
	},
	{
		name: 'Iraq',
		label: '+964',
	},
	{
		name: 'Ireland',
		label: '+353',
	},
	{
		name: 'Isle of Man',
		label: '+44',
	},
	{
		name: 'Israel',
		label: '+972',
	},
	{
		name: 'Italy',
		label: '+39',
	},
	{
		name: 'Jamaica',
		label: '+1876',
	},
	{
		name: 'Japan',
		label: '+81',
	},
	{
		name: 'Jersey',
		label: '+44',
	},
	{
		name: 'Jordan',
		label: '+962',
	},
	{
		name: 'Kazakhstan',
		label: '+7',
	},
	{
		name: 'Kenya',
		label: '+254',
	},
	{
		name: 'Kiribati',
		label: '+686',
	},
	{
		name: "Korea, Democratic People's Republic of Korea",
		label: '+850',
	},
	{
		name: 'Korea, Republic of South Korea',
		label: '+82',
	},
	{
		name: 'Kosovo',
		label: '+383',
	},
	{
		name: 'Kuwait',
		label: '+965',
	},
	{
		name: 'Kyrgyzstan',
		label: '+996',
	},
	{
		name: 'Laos',
		label: '+856',
	},
	{
		name: 'Latvia',
		label: '+371',
	},
	{
		name: 'Lebanon',
		label: '+961',
	},
	{
		name: 'Lesotho',
		label: '+266',
	},
	{
		name: 'Liberia',
		label: '+231',
	},
	{
		name: 'Libyan Arab Jamahiriya',
		label: '+218',
	},
	{
		name: 'Liechtenstein',
		label: '+423',
	},
	{
		name: 'Lithuania',
		label: '+370',
	},
	{
		name: 'Luxembourg',
		label: '+352',
	},
	{
		name: 'Macao',
		label: '+853',
	},
	{
		name: 'Macedonia',
		label: '+389',
	},
	{
		name: 'Madagascar',
		label: '+261',
	},
	{
		name: 'Malawi',
		label: '+265',
	},
	{
		name: 'Maldives',
		label: '+960',
	},
	{
		name: 'Mali',
		label: '+223',
	},
	{
		name: 'Malta',
		label: '+356',
	},
	{
		name: 'Marshall Islands',
		label: '+692',
	},
	{
		name: 'Martinique',
		label: '+596',
	},
	{
		name: 'Mauritania',
		label: '+222',
	},
	{
		name: 'Mauritius',
		label: '+230',
	},
	{
		name: 'Mayotte',
		label: '+262',
	},
	{
		name: 'Mexico',
		label: '+52',
	},
	{
		name: 'Micronesia, Federated States of Micronesia',
		label: '+691',
	},
	{
		name: 'Moldova',
		label: '+373',
	},
	{
		name: 'Monaco',
		label: '+377',
	},
	{
		name: 'Mongolia',
		label: '+976',
	},
	{
		name: 'Montenegro',
		label: '+382',
	},
	{
		name: 'Montserrat',
		label: '+1664',
	},
	{
		name: 'Morocco',
		label: '+212',
	},
	{
		name: 'Mozambique',
		label: '+258',
	},
	{
		name: 'Myanmar',
		label: '+95',
	},
	{
		name: 'Namibia',
		label: '+264',
	},
	{
		name: 'Nauru',
		label: '+674',
	},
	{
		name: 'Nepal',
		label: '+977',
	},
	{
		name: 'Netherlands',
		label: '+31',
	},
	{
		name: 'Netherlands Antilles',
		label: '+599',
	},
	{
		name: 'New Caledonia',
		label: '+687',
	},
	{
		name: 'New Zealand',
		label: '+64',
	},
	{
		name: 'Nicaragua',
		label: '+505',
	},
	{
		name: 'Niger',
		label: '+227',
	},
	{
		name: 'Nigeria',
		label: '+234',
	},
	{
		name: 'Niue',
		label: '+683',
	},
	{
		name: 'Norfolk Island',
		label: '+672',
	},
	{
		name: 'Northern Mariana Islands',
		label: '+1670',
	},
	{
		name: 'Norway',
		label: '+47',
	},
	{
		name: 'Oman',
		label: '+968',
	},
	{
		name: 'Pakistan',
		label: '+92',
	},
	{
		name: 'Palau',
		label: '+680',
	},
	{
		name: 'Palestinian Territory, Occupied',
		label: '+970',
	},
	{
		name: 'Panama',
		label: '+507',
	},
	{
		name: 'Papua New Guinea',
		label: '+675',
	},
	{
		name: 'Paraguay',
		label: '+595',
	},
	{
		name: 'Peru',
		label: '+51',
	},
	{
		name: 'Philippines',
		label: '+63',
	},
	{
		name: 'Pitcairn',
		label: '+64',
	},
	{
		name: 'Poland',
		label: '+48',
	},
	{
		name: 'Portugal',
		label: '+351',
	},
	{
		name: 'Puerto Rico',
		label: '+1939',
	},
	{
		name: 'Qatar',
		label: '+974',
	},
	{
		name: 'Romania',
		label: '+40',
	},
	{
		name: 'Russia',
		label: '+7',
	},
	{
		name: 'Rwanda',
		label: '+250',
	},
	{
		name: 'Reunion',
		label: '+262',
	},
	{
		name: 'Saint Barthelemy',
		label: '+590',
	},
	{
		name: 'Saint Helena, Ascension and Tristan Da Cunha',
		label: '+290',
	},
	{
		name: 'Saint Kitts and Nevis',
		label: '+1869',
	},
	{
		name: 'Saint Lucia',
		label: '+1758',
	},
	{
		name: 'Saint Martin',
		label: '+590',
	},
	{
		name: 'Saint Pierre and Miquelon',
		label: '+508',
	},
	{
		name: 'Saint Vincent and the Grenadines',
		label: '+1784',
	},
	{
		name: 'Samoa',
		label: '+685',
	},
	{
		name: 'San Marino',
		label: '+378',
	},
	{
		name: 'Sao Tome and Principe',
		label: '+239',
	},
	{
		name: 'Saudi Arabia',
		label: '+966',
	},
	{
		name: 'Senegal',
		label: '+221',
	},
	{
		name: 'Serbia',
		label: '+381',
	},
	{
		name: 'Seychelles',
		label: '+248',
	},
	{
		name: 'Sierra Leone',
		label: '+232',
	},
	{
		name: 'Singapore',
		label: '+65',
	},
	{
		name: 'Slovakia',
		label: '+421',
	},
	{
		name: 'Slovenia',
		label: '+386',
	},
	{
		name: 'Solomon Islands',
		label: '+677',
	},
	{
		name: 'Somalia',
		label: '+252',
	},
	{
		name: 'South Africa',
		label: '+27',
	},
	{
		name: 'South Sudan',
		label: '+211',
	},
	{
		name: 'South Georgia and the South Sandwich Islands',
		label: '+500',
	},
	{
		name: 'Spain',
		label: '+34',
	},
	{
		name: 'Sri Lanka',
		label: '+94',
	},
	{
		name: 'Sudan',
		label: '+249',
	},
	{
		name: 'Suriname',
		label: '+597',
	},
	{
		name: 'Svalbard and Jan Mayen',
		label: '+47',
	},
	{
		name: 'Swaziland',
		label: '+268',
	},
	{
		name: 'Sweden',
		label: '+46',
	},
	{
		name: 'Switzerland',
		label: '+41',
	},
	{
		name: 'Syrian Arab Republic',
		label: '+963',
	},
	{
		name: 'Taiwan',
		label: '+886',
	},
	{
		name: 'Tajikistan',
		label: '+992',
	},
	{
		name: 'Tanzania, United Republic of Tanzania',
		label: '+255',
	},
	{
		name: 'Thailand',
		label: '+66',
	},
	{
		name: 'Timor-Leste',
		label: '+670',
	},
	{
		name: 'Togo',
		label: '+228',
	},
	{
		name: 'Tokelau',
		label: '+690',
	},
	{
		name: 'Tonga',
		label: '+676',
	},
	{
		name: 'Trinidad and Tobago',
		label: '+1868',
	},
	{
		name: 'Tunisia',
		label: '+216',
	},
	{
		name: 'Turkey',
		label: '+90',
	},
	{
		name: 'Turkmenistan',
		label: '+993',
	},
	{
		name: 'Turks and Caicos Islands',
		label: '+1649',
	},
	{
		name: 'Tuvalu',
		label: '+688',
	},
	{
		name: 'Uganda',
		label: '+256',
	},
	{
		name: 'Ukraine',
		label: '+380',
	},
	{
		name: 'United Arab Emirates',
		label: '+971',
	},
	{
		name: 'United Kingdom',
		label: '+44',
	},
	{
		name: 'United States',
		label: '+1',
	},
	{
		name: 'Uruguay',
		label: '+598',
	},
	{
		name: 'Uzbekistan',
		label: '+998',
	},
	{
		name: 'Vanuatu',
		label: '+678',
	},
	{
		name: 'Venezuela, Bolivarian Republic of Venezuela',
		label: '+58',
	},
	{
		name: 'Vietnam',
		label: '+84',
	},
	{
		name: 'Virgin Islands, British',
		label: '+1284',
	},
	{
		name: 'Virgin Islands, U.S.',
		label: '+1340',
	},
	{
		name: 'Wallis and Futuna',
		label: '+681',
	},
	{
		name: 'Yemen',
		label: '+967',
	},
	{
		name: 'Zambia',
		label: '+260',
	},
	{
		name: 'Zimbabwe',
		label: '+263',
	},
]

export const certificationBodyList = [
	{
		value: 'Control Union Certifications Sdn. Bhd',
		label: 'Control Union Certifications Sdn. Bhd',
	},
	{ value: 'BSI Group', label: 'BSI Group' },
	{
		value: 'SIRIM QAS International Sdn. Bhd',
		label: 'SIRIM QAS International Sdn. Bhd',
	},
	{
		value: 'SIRIM QAS International Sdn. Bhd',
		label: 'SIRIM QAS International Sdn. Bhd',
	},
	{ value: 'Intertek-Moody', label: 'Intertek-Moody' },
	{
		value: 'Global Gateway Certifications Sdn. Bhd',
		label: 'Global Gateway Certifications Sdn. Bhd',
	},
	{
		value: 'BUREAU VERITAS Certification (M) Sdn. Bhd',
		label: 'BUREAU VERITAS Certification (M) Sdn. Bhd',
	},
	{
		value: 'PT. TUV Rheinland Indonesia',
		label: 'PT. TUV Rheinland Indonesia',
	},
	{
		value: 'Care Certification International (M) Sdn. Bhd',
		label: 'Care Certification International (M) Sdn. Bhd',
	},
	{
		value: 'Intertek Certification International Sdn. Bhd',
		label: 'Intertek Certification International Sdn. Bhd',
	},
	{
		value: 'Dima Certification Sdn. Bhd',
		label: 'Dima Certification Sdn. Bhd',
	},
	{ value: 'SGS Malaysia Sdn. Bhd', label: 'SGS Malaysia Sdn. Bhd' },
	{
		value: 'Rehpro Certification Sdn. Bhd',
		label: 'Rehpro Certification Sdn. Bhd',
	},
	{
		value: 'Prmia Certification International Sdn Bhd',
		label: 'Prmia Certification International Sdn Bhd',
	},
	{
		value: 'DQS Certification (M) Sdn. Bhd',
		label: 'DQS Certification (M) Sdn. Bhd',
	},
	{
		value: 'Platinum Shauffmantz Veritas Sdn. Bhd',
		label: 'Platinum Shauffmantz Veritas Sdn. Bhd',
	},
]

export const MandatoryProducts = {
	palmoil_mill: ['FFB', 'CPO'],
}

export const FILE_HEADERS = {
	productLCV: ['Products', 'LCA'],
}
