import React from 'react'
import { getIn } from 'timm'
import { Box } from 'ui-lib/utils/Box'
import { Label, TableTitle } from 'ui-lib/components/Typography'
import { ButtonWithNoBorder } from 'ui-lib/components/Button'
import { TABLE_ALIAS } from 'core-app/modules/Admin/config'
import { supplyChainModels } from 'core-app/modules/Admin/components/StorageDetails'
import { Icon } from 'ui-lib/icons/components/Icon'
import LockIcon from 'ui-lib/icons/lock.svg'
import UnlockIcon from 'ui-lib/icons/unlock.svg'

export const getVehicleColumnConfig = ({
	docType,
	onChange,
	t,
	selectedCPID,
}) => {
	return [
		{
			property: 'assetName',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.assetName)}</TableTitle>
				</Box>
			),
			render: datum => {
				const name = getIn(datum, ['meta', 'name']) || '---'

				return <Label as="span">{name}</Label>
			},
		},
		{
			property: 'assetNumber',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.assetNumber)}</TableTitle>
				</Box>
			),
			render: datum => {
				const number = getIn(datum, ['number']) || '---'

				return <Label as="span">{number}</Label>
			},
		},
		{
			property: 'viewDetails',
			render: datum => {
				return (
					<ButtonWithNoBorder
						label={t('common.viewBtn').toUpperCase()}
						onClick={() => {
							onChange('vehicle_details', {
								row: datum,
								docType,
							})
						}}
					/>
				)
			},
		},
		...(!selectedCPID
			? [
					{
						property: 'deleteVehicle',
						render: datum => {
							return (
								<ButtonWithNoBorder
									label={t('common.delete').toUpperCase()}
									onClick={() => {
										onChange('delete_vehicle', {
											row: datum,
											docType,
										})
									}}
								/>
							)
						},
					},
			  ]
			: []),
	]
}

export const getStorageUnitColumnConfig = ({
	docType,
	onChange,
	t,
	allProducts,
}) => {
	return [
		{
			property: 'assetName',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.assetName)}</TableTitle>
				</Box>
			),
			render: datum => {
				const name = getIn(datum, ['name']) || '---'

				return <Label as="span">{name}</Label>
			},
		},
		{
			property: 'currentQuantity',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.currentQuantity)}</TableTitle>
				</Box>
			),
			render: datum => {
				const currentQuantity = getIn(datum, [
					'meta',
					'storedProducts',
					0,
					'availableQty',
				])

				const uom = getIn(datum, ['capacityUOM']) || 'mT'

				return (
					<Label as="span">
						{currentQuantity || 0} {uom}
					</Label>
				)
			},
		},
		{
			property: 'product',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.product)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { meta = {} } = datum
				const productID = getIn(meta, [
					'storedProducts',
					0,
					'productID',
				])

				const product = allProducts.filter(
					prod => prod.id === productID
				)
				const productName = getIn(product, [0, 'name'])

				return <Label as="span">{productName}</Label>
			},
		},
		{
			property: 'supplyChainModel',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.supplyChainModel)}</TableTitle>
				</Box>
			),
			render: datum => {
				const supplyChainModel =
					getIn(datum, ['meta', 'supplyChainModel']) || '---'

				return (
					<Label as="span">
						{t(supplyChainModels[supplyChainModel])}
					</Label>
				)
			},
		},
		{
			property: 'certificationType',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t('assetsHome.certificationType')}</TableTitle>
				</Box>
			),
			render: datum => {
				const certificationType =
					getIn(datum, ['meta', 'certificationType']) || '---'

				return <Label as="span">{certificationType}</Label>
			},
		},
		{
			property: 'viewDetails',
			render: datum => {
				return (
					<ButtonWithNoBorder
						label={t('common.viewBtn').toUpperCase()}
						onClick={() => {
							onChange('storageunit_details', {
								row: datum,
								docType,
							})
						}}
					/>
				)
			},
			size: '8%',
		},
		{
			property: 'deleteStorageUnit',
			render: datum => {
				const quantity = getIn(datum, [
					'meta',
					'storedProducts',
					0,
					'availableQty',
				])

				return (
					<>
						{!quantity && (
							<ButtonWithNoBorder
								label={t('common.delete').toUpperCase()}
								onClick={() => {
									onChange('delete_storageunit', {
										row: datum,
										docType,
									})
								}}
							/>
						)}
					</>
				)
			},
		},
		{
			property: 'tankStatus',
			render: datum => {
				const isLocked = getIn(datum, ['meta', 'status']) === 'locked'

				return (
					<Icon
						glyph={isLocked ? LockIcon : UnlockIcon}
						style={{
							height: 24,
							width: 24,
						}}
					/>
				)
			},
			size: '8%',
		},
	]
}

export const getWeighBridgesColumnConfig = ({ docType, onChange, t }) => {
	return [
		{
			property: 'assetName',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.assetName)}</TableTitle>
				</Box>
			),
			render: data => {
				const name = getIn(data, ['name']) || '---'

				return <Label as="span">{name}</Label>
			},
		},
		{
			property: 'manufacturer',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.manufacturer)}</TableTitle>
				</Box>
			),
			render: datum => {
				const manufacturer = getIn(datum, ['manufacturer']) || '---'

				return <Label as="span">{manufacturer}</Label>
			},
		},
		{
			property: 'model',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.model)}</TableTitle>
				</Box>
			),
			render: datum => {
				const model = getIn(datum, ['model']) || '---'

				return <Label as="span">{model}</Label>
			},
		},
		{
			property: 'viewDetails',
			render: datum => {
				return (
					<ButtonWithNoBorder
						label={t('common.viewBtn').toUpperCase()}
						onClick={() => {
							onChange('weighbridge_details', {
								row: datum,
								docType,
							})
						}}
					/>
				)
			},
		},
		{
			property: 'deleteStorageUnit',
			render: datum => {
				return (
					<ButtonWithNoBorder
						label={t('common.delete').toUpperCase()}
						onClick={() => {
							onChange('delete_weighbridge', {
								row: datum,
								docType,
							})
						}}
					/>
				)
			},
		},
	]
}

export const getProductionLineColumnConfig = ({ docType, onChange, t }) => {
	return [
		{
			property: 'unitNumber',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.unitNumber)}</TableTitle>
				</Box>
			),
			render: datum => {
				const unitNumber = getIn(datum, ['name']) || '---'

				return <Label as="span">{unitNumber}</Label>
			},
		},
		{
			property: 'capacity',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.capacity)}</TableTitle>
				</Box>
			),
			render: datum => {
				const capacity = getIn(datum, ['capacity']) || '---'

				return (
					<Label as="span">
						{`${capacity}  ${getIn(datum, ['capacityUOM'])}`}
					</Label>
				)
			},
		},
		{
			property: 'viewDetails',
			render: datum => {
				return (
					<ButtonWithNoBorder
						label={t('common.viewBtn').toUpperCase()}
						onClick={() => {
							onChange('productionLine_details', {
								row: datum,
								docType,
							})
						}}
					/>
				)
			},
		},
		{
			property: 'deleteProductionLine',
			render: datum => {
				return (
					<ButtonWithNoBorder
						label={t('common.delete').toUpperCase()}
						onClick={() => {
							onChange('delete_productionLine', {
								row: datum,
								docType,
							})
						}}
					/>
				)
			},
		},
	]
}

export const getEmployeeMgmtColumnConfig = ({ onChange, t }) => {
	return [
		{
			property: 'employeeID',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.employeeID)}</TableTitle>
				</Box>
			),
			render: datum => {
				const employeeID =
					getIn(datum, ['meta', 'employeeData', 'id']) || '---'

				return <Label as="span">{employeeID}</Label>
			},
		},
		{
			property: 'employeeName',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.employeeName)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { firstName } = datum

				return <Label as="span">{firstName || '---'}</Label>
			},
		},
		{
			property: 'designation',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.designation)}</TableTitle>
				</Box>
			),
			render: datum => {
				const employeeDesignation =
					getIn(datum, ['meta', 'employeeData', 'designation']) ||
					'---'

				return <Label as="span">{employeeDesignation}</Label>
			},
		},
		{
			property: 'dibizRole',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.dibizRole)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { orgRole } = datum

				return (
					<Label as="span">
						{t(`empMgmtHome.${orgRole}`) || '---'}
					</Label>
				)
			},
		},
		{
			property: 'callToAction',
			render: datum => {
				return (
					<ButtonWithNoBorder
						label={t('common.viewBtn').toUpperCase()}
						onClick={() =>
							onChange('open_document', { row: datum })
						}
					/>
				)
			},
		},
	]
}

export const getEmployeeDocColumnConfig = ({ onChange, t }) => {
	return [
		{
			property: 'employeeID',
			header: (
				<Box
					row
					alignItems="center"
					style={{ cursor: 'pointer', minWidth: '110px' }}
				>
					<TableTitle>{t(TABLE_ALIAS.employeeID)}</TableTitle>
				</Box>
			),
			render: datum => {
				const employeeID = getIn(datum, ['employeeID']) || '---'

				return (
					<Box>
						<Label as="span">
							<div
								style={{
									maxWidth: '110px',
									overflowWrap: 'break-word',
								}}
								title={employeeID}
							>
								{employeeID}
							</div>
						</Label>
					</Box>
				)
			},
		},
		{
			property: 'employeeName',
			header: (
				<Box
					row
					alignItems="center"
					style={{ cursor: 'pointer', minWidth: '110px' }}
				>
					<TableTitle>{t(TABLE_ALIAS.employeeName)}</TableTitle>
				</Box>
			),
			render: datum => {
				const firstName = getIn(datum, ['name'])

				if (firstName)
					return (
						<Label as="span">
							<div
								style={{
									maxWidth: '110px',
									overflowWrap: 'break-word',
								}}
								title={firstName}
							>
								{firstName}
							</div>
						</Label>
					)

				return '---'
			},
		},
		{
			property: 'designation',
			header: (
				<Box
					row
					alignItems="center"
					style={{ cursor: 'pointer', minWidth: '100px' }}
				>
					<TableTitle>{t(TABLE_ALIAS.designation)}</TableTitle>
				</Box>
			),
			render: datum => {
				const employeeDesignation =
					getIn(datum, ['designation']) || '---'

				return (
					<Label as="span">
						<div
							style={{
								maxWidth: '100px',
								overflowWrap: 'break-word',
							}}
							title={employeeDesignation}
						>
							{employeeDesignation}
						</div>
					</Label>
				)
			},
		},
		{
			property: 'dibizRole',
			header: (
				<Box
					row
					alignItems="center"
					style={{ cursor: 'pointer', minWidth: '100px' }}
				>
					<TableTitle>{t(TABLE_ALIAS.dibizRole)}</TableTitle>
				</Box>
			),
			render: datum => {
				const orgRole = getIn(datum, ['role']) || '---'

				return (
					<Label as="span">
						<div
							style={{
								maxWidth: '100px',
								overflowWrap: 'break-word',
							}}
							title={orgRole}
						>
							{orgRole}
						</div>
					</Label>
				)
			},
		},
		{
			property: 'email',
			header: (
				<Box
					row
					alignItems="center"
					style={{ cursor: 'pointer', minWidth: '200px' }}
				>
					<TableTitle>{t('empMgmtHome.email')}</TableTitle>
				</Box>
			),
			render: datum => {
				const email = getIn(datum, ['emailAddress']) || '---'

				return (
					<Label as="span">
						<div
							style={{
								maxWidth: '200px',
								overflowWrap: 'break-word',
							}}
							title={email}
						>
							{email}
						</div>
					</Label>
				)
			},
		},
		{
			property: 'phoneNo',
			header: (
				<Box
					row
					alignItems="center"
					style={{ cursor: 'pointer', minWidth: '90px' }}
				>
					<TableTitle>{t('empMgmtHome.phoneNo')}</TableTitle>
				</Box>
			),
			render: datum => {
				const phoneNo = getIn(datum, ['mobileNumber']) || '---'

				return (
					<Label as="span">
						<div
							style={{
								maxWidth: '90px',
								overflowWrap: 'break-word',
							}}
							title={phoneNo}
						>
							{phoneNo}
						</div>
					</Label>
				)
			},
		},
		{
			property: 'callToAction',
			render: datum => {
				return (
					<ButtonWithNoBorder
						label={t('common.edit').toUpperCase()}
						onClick={() => onChange('edit_entry', datum)}
					/>
				)
			},
		},
		{
			property: 'callToAction',
			render: datum => {
				return (
					<ButtonWithNoBorder
						label={t('empMgmtHome.discard').toUpperCase()}
						onClick={() => onChange('discard_entry', datum)}
					/>
				)
			},
		},
	]
}
