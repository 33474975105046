import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AppDuc } from 'core-app/modules/App/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { DealerDuc } from 'core-app/modules/Dealer/duc'
import { CookieDuc } from 'core-app/modules/App/cookieDuc'
import { getColumnConfig } from 'core-app/modules/Dealer/components/Columns'
import { BoxShadowTable } from 'core-app/shared/components/BoxShadowTable'
import { Title } from 'core-app/shared/components/Title'
import { Box } from 'ui-lib/utils/Box'
import styled from 'styled-components'
import { Label } from 'ui-lib/components/Typography'
import { getIn } from 'timm'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Button } from 'ui-lib/components/Button'
import WorkOutline from 'ui-lib/icons/work-outline.svg'
import theme from 'ui-lib/utils/base-theme'
import { WebTour } from '../../../WebTour'
import { WebTourDuc } from '../../../WebTour/duc'

export const CTAWrapper = styled(Box)(p => ({
	padding: 10,
	minHeight: 62,
	background: p.theme.color.white,
	borderBottomLeftRadius: 4,
	borderBottomRightRadius: 4,
	color: p.theme.color.primary,
	display: 'flex',
	flexDirection: 'row',
	border: `1px solid ${theme.color.grey4}`,
}))

const WorkListing = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const orgDetails = useSelector(AuthDuc.selectors.getAvailablePartners)
	const documents = useSelector(DealerDuc.selectors.getWorkListingDocuments)
	const loadingStatus = useSelector(DealerDuc.selectors.getLoadingStatus)
	const location = useSelector(DealerDuc.selectors.location)
	const pagination = useSelector(
		DealerDuc.selectors.fetchMainDashboardPagination
	)
	const selectedCPID = useSelector(CookieDuc.selectors.getSelectedCPID)
	const { payload = {} } = location
	const { rootModule } = payload
	const totalDocument = getIn(pagination, ['total']) || ''

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.workListing'),
			name: 'work-listing',
			isActive: false,
		},
	]

	/** Handlers to react on actions */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
	}
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)
	useEffect(() => {
		if (tourModule === 'workCreate') {
			dispatch(WebTourDuc.creators.setCreateWork(true))
		}
	}, [tourModule, dispatch])

	const handleChange = useCallback(
		(actionType, meta = {}) => {
			switch (actionType) {
				case 'open_document': {
					if (meta.row) {
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.DEALER$WDOCREFERENCE,
								{
									rootModule,
									action: 'view',
									documentReference: meta.row.id,
								}
							)
						)
					}

					break
				}

				case 'paginate': {
					const { filterParams } = meta
					const isSkip = false
					const { nextIndex } = filterParams
					const locationState = {
						query: {
							...location.query,
							nextIndex,
							limit: 20,
						},
					}
					dispatch(
						DealerDuc.creators.fetchWorkListing(
							isSkip,
							locationState
						)
					)
					break
				}

				default:
					break
			}
		},
		[dispatch, rootModule, location]
	)

	return (
		<>
			<Box padding={8} width="100%" height="100%">
				<Box style={{ padding: '0 5' }}>
					<Breadcrumb
						links={breadCrumbs}
						onClick={target => handleBreadCrumbClick(target)}
					/>
				</Box>

				<Box row justifyContent="space-between" alignItems="center">
					<Title title={t('workListing.title')} icon={WorkOutline} />
					{!selectedCPID && (
						<Box justifyContent="flex-end" id="input-createWork">
							<Button
								label={t(
									'workListing.createWorkButton'
								).toUpperCase()}
								extendStyles={{
									fontSize: isMobile && theme.fontSize.m,
									padding: isMobile && '8px',
								}}
								primary
								rounded
								onClick={() =>
									dispatch(
										MainRouteDuc.creators.switchPage(
											MainRouteDuc.types.DEALER$WACTION,
											{
												rootModule,
												action: 'create',
											}
										)
									)
								}
							/>
						</Box>
					)}
				</Box>
				<Spacer size={8} />
				<BoxShadowTable
					size="large"
					isLoading={loadingStatus}
					scroll={!isMobile}
					tableSize={isMobile && 'small'}
					columnConfig={getColumnConfig({
						orgDetails,
						onChange: handleChange,
						t,
					})}
					rowData={documents}
				/>
				<CTAWrapper>
					<Label
						color="inherit"
						bold
						small
						style={{
							padding: '10px 4px',
						}}
					>
						{(documents || []).length > 0
							? `${t('common.showingListFor')}
							  ${(documents || []).length}
							  ${t('common.of')}
							  ${totalDocument}`
							: t('common.errorBlockMessage')}
					</Label>
					<Box
						style={{
							width: '75%',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Button
							plain
							disabled={
								(documents || []).length === 0 ||
								(documents || []).length === totalDocument
							}
							extendStyles={{
								background: '#F2F4FF',
								padding: '8px 24px',
								borderRadius: '24px',
								width: '170px',
								textAlign: 'center',
							}}
							label={t('common.showMore')}
							onClick={() =>
								handleChange('paginate', {
									filterParams: pagination,
								})
							}
						/>
					</Box>
				</CTAWrapper>
			</Box>
			{tourModule === 'workCreate' && <WebTour />}
		</>
	)
}

export { WorkListing }
