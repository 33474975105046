import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box as GrommBox, Layer } from 'grommet'
import { Close } from 'grommet-icons'
import { MainRouteDuc } from 'core-app/routes/duc'
import { ErrorBlock } from 'ui-lib/components/ErrorBlocks'
import { Box } from 'ui-lib/utils/Box'
import { H2, Text } from 'ui-lib/components/Typography'
import { Button } from 'ui-lib/components/Button'
import theme from 'ui-lib/utils/base-theme'
import { StorageInventoryCard } from 'core-app/modules/Admin/components/StorageDetails'

const StorageInventoryModal = ({
	show,
	noPadding,
	size,
	height,
	isMobile,
	margin,
	overflow,
	heading,
	onClose,
	data,
}) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	return (
		<>
			{show && (
				<Layer
					modal
					responsive={false}
					animation="fadeIn"
					position={isMobile ? 'bottom' : 'center'}
					margin={margin}
					onClickOutside={onClose}
					onEsc={onClose}
				>
					<GrommBox
						pad={noPadding ? 'none' : '32px 48px'}
						gap={noPadding ? 'none' : 'small'}
						width={size}
						height={height}
						overflow={overflow}
					>
						<Box
							row
							justifyContent="space-between"
							alignItems="center"
						>
							<H2>{heading}</H2>
							<Button
								extendStyles={{
									position: 'absolute',
									right: 10,
									top: 32,
									zIndex: 1,
									padding: 8,
								}}
								plain
								customIcon={<Close />}
								onClick={onClose}
							/>
						</Box>
						<Box row wrap overflow>
							{data.length > 0 &&
								data.map(storage => {
									return (
										<StorageInventoryCard
											details={storage}
											key={storage.id}
										/>
									)
								})}
						</Box>
						{!data.length && (
							<>
								<Box height={420}>
									<ErrorBlock
										hideButton
										status="empty"
										message={t('common.errorBlockMessage')}
									/>
								</Box>
								<Box center>
									<Text
										style={{
											marginTop: 16,
											padding: 4,
											fontSize: theme.fontSize.xl,
										}}
									>
										{t('production.errorHelp')}
									</Text>
									<Button
										label={t('production.errorHelpCTA')}
										primary
										extendStyles={{
											marginTop: 16,
										}}
										onClick={() => {
											dispatch(
												MainRouteDuc.creators.switchPage(
													MainRouteDuc.types
														.ADMIN$SUBMODULEWACTION,
													{
														rootModule: 'assets',
														subModule:
															'storageunits',
														action: 'create-asset',
													}
												)
											)
										}}
									/>
								</Box>
							</>
						)}
					</GrommBox>
				</Layer>
			)}
		</>
	)
}

export { StorageInventoryModal }
