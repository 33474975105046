import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { getIn } from 'timm'
import { useTranslation } from 'react-i18next'
import { Stepper } from 'react-form-stepper'
import { AppDuc } from 'core-app/modules/App/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { OnboardDuc } from 'core-app/modules/Onboard/duc'
import { MainRouteDuc } from 'core-app/routes/duc'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { currentPlatform } from 'ui-lib/utils/config'
import { TransparentBgSpinner } from 'ui-lib/components/Spinner/TransparentBgSpinner'
import {
	stepStyles,
	connectorStyles,
} from 'core-app/modules/Onboard/containers/NewSignup/AddressBlock'
import { isEmptyObject } from 'core-app/utils/helpers'
import { Label } from 'ui-lib/components/Label'
import {
	Select,
	MultiSelectWithSearch,
	CreatableSelect,
} from 'ui-lib/components/Select'
import { H2, H3, P } from 'ui-lib/components/Typography'
import { FileUpload } from 'ui-lib/components/FileUpload'
import { Button, ButtonWithNoBorder } from 'ui-lib/components/Button'
import { DatePicker } from 'ui-lib/components/Datepicker'
import { getSupplyChainModelsWithId } from 'core-app/modules/Admin/config'
import { Input } from 'ui-lib/components/Input'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import theme from 'ui-lib/utils/base-theme'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { certificationBodyList } from '../../config'

const labelStyles = {
	color: '#555454',
	fontSize: theme.fontSize.m,
	marginBottom: 8,
}
const Line = styled.div(p => ({
	borderRight: p.vertical ? '1px solid #c9c9c9' : 0,
	height: p.vertical ? '216px' : 0,
	margin: p.margin,
	borderBottom: p.horizontal ? '1px solid #c9c9c9' : 0,
}))

const CertificateBlock = () => {
	const orgDetails = useSelector(AuthDuc.selectors.getCurrentOrganization)
	const carbonNumberID = getIn(orgDetails, ['meta', 'carbonNumberID'])

	const { t } = useTranslation()
	const dispatch = useDispatch()
	const incompleteCertificate = useSelector(
		AuthDuc.selectors.getIncompleteCertificate
	)
	const supplybaseDetails = useSelector(
		OnboardDuc.selectors.getSupplyBaseValues
	)
	const actor = useSelector(AuthDuc.selectors.getActor)[0] || []
	const isMill = actor.includes('palmoil_mill')
	const {
		number,
		expiryEndDate,
		expiryStartDate,
		issueDate,
		files = [],
		id,
		supplybaseMembers = {},
	} = isMill ? supplybaseDetails : incompleteCertificate
	const { meta = {} } = isMill ? supplybaseDetails : incompleteCertificate
	const { certificate = {} } = meta
	const {
		issuingBody = '',
		certificationBody = '',
		supplychainModel = [],
	} = certificate
	const { members = [] } = supplybaseMembers

	const initialValues = {
		number,
		expiryEndDate,
		expiryStartDate,
		issueDate,
		files,
		issuingBody,
		certificationBody,
		supplychainModel,
		members,
	}

	const validationSchema = yup.object().shape({
		expiryStartDate: yup.string().when('issuingBody', {
			is: item => item !== 'UNCERTIFIED',
			then: yup.string().required(t('common.fieldRequired')),
			otherwise: yup.string(),
		}),
		expiryEndDate: yup.string().when('issuingBody', {
			is: item => item !== 'UNCERTIFIED',
			then: yup.string().required(t('common.fieldRequired')),
			otherwise: yup.string(),
		}),
		issueDate: yup.string().when('issuingBody', {
			is: item => item !== 'UNCERTIFIED',
			then: yup.string().required(t('common.fieldRequired')),
			otherwise: yup.string(),
		}),
		issuingBody: yup.string().required(),
		certificationBody: yup.string(),
		supplychainModel: yup.array().when('issuingBody', {
			is: item => {
				return item !== 'UNCERTIFIED' && currentPlatform() === 'palmoil'
			},
			then: yup.array().required(t('common.fieldRequired')),
			otherwise: yup.array(),
		}),
		files: yup.array().when('issuingBody', {
			is: item => item !== 'UNCERTIFIED',
			then: yup.array().required(t('common.fieldRequired')),
			otherwise: yup.array(),
		}),
		number: yup.string().when('issuingBody', {
			is: item => item !== 'UNCERTIFIED',
			then: yup.string().required(t('common.fieldRequired')),
			otherwise: yup.string(),
		}),
		members: yup
			.array()
			.of(
				yup.object().shape({
					meta: yup.object().shape({
						primaryContact: yup.object().shape({
							email: yup.string().email(),
							mobile: yup
								.string()
								.test(
									'IsValidMobile',
									'Invalid mobile number',
									value =>
										value ? isValidPhoneNumber(value) : true
								),
						}),
					}),
				})
			)
			.test(
				'unique-email-mobile',
				'Duplicate Email/Mobile',
				// eslint-disable-next-line func-names
				function(value) {
					if (!value) {
						return true
					}

					const uemail = value
						.map(v => v.meta.primaryContact.email)
						.filter(item => item !== undefined)
					const umobile = value
						.map(v => v.meta.primaryContact.mobile)
						.filter(item => item !== undefined)

					const uniqueEmail = new Set(uemail)
					const uniqueMobile = new Set(umobile)

					return (
						uniqueEmail.size === uemail.length &&
						uniqueMobile.size === umobile.length
					)
				}
			),
	})

	const {
		values,
		setFieldValue,
		setValues,
		handleSubmit,
		handleChange,
		handleBlur,
		touched,
		errors,
		isSubmitting,
	} = useFormik({
		initialValues,
		enableReinitialize: true,
		validationSchema,
		onSubmit: (_values, { setSubmitting }) => {
			const payload = {
				type: 'certificate',
				number: _values.number,
				files: _values.files,
				expiryStartDate: _values.expiryStartDate,
				expiryEndDate: _values.expiryEndDate,
				issueDate: _values.issueDate,
				meta: {
					certificate: {
						issuingBody: _values.issuingBody,
						certificationBody: _values.certificationBody,
						...(currentPlatform() === 'palmoil'
							? { supplychainModel: _values.supplychainModel }
							: {}),
					},
				},
				...(currentPlatform() === 'palmoil'
					? {
							supplybaseMembers: {
								...supplybaseMembers,
								members: _values.members,
							},
					  }
					: {}),
			}

			dispatch(
				OnboardDuc.creators.updateCertificationMeta(
					_values.issuingBody === 'UNCERTIFIED'
				)
			)

			if (
				!isEmptyObject(incompleteCertificate) &&
				_values.issuingBody !== 'UNCERTIFIED' &&
				currentPlatform() === 'palmoil'
			) {
				dispatch(
					OnboardDuc.creators.updateCertificate(
						payload,
						id,
						{
							setSubmitting,
						},
						actor,
						_values.issuingBody === 'UNCERTIFIED',
						carbonNumberID
					)
				)
			} else if (
				_values.issuingBody !== 'UNCERTIFIED' &&
				currentPlatform() === 'palmoil'
			) {
				dispatch(
					OnboardDuc.creators.addCertificate(
						payload,
						{
							setSubmitting,
						},
						actor,
						_values.issuingBody === 'UNCERTIFIED',
						carbonNumberID
					)
				)
			} else {
				dispatch(
					MainRouteDuc.creators.switchPage(
						MainRouteDuc.types.ONBOARD$ACTION,
						{
							action: 'all-set',
						}
					)
				)
			}
		},
	})

	const disableCTA =
		!isEmptyObject(errors) && values.issuingBody !== 'UNCERTIFIED'

	return (
		<Box
			style={{
				width: '100vw',
				height: '100vh',
				overflow: 'scroll',
				background: ' #1D285D',
				paddingBottom: '20px',
			}}
			center
		>
			{!(orgDetails && orgDetails.id) && <TransparentBgSpinner />}
			<Spacer size={20} />
			<Box width="650px">
				<Stepper
					steps={[{}, {}]}
					activeStep={1}
					connectorStateColors
					styleConfig={stepStyles}
					connectorStyleConfig={connectorStyles}
				/>
			</Box>
			<H2
				style={{
					fontSize: 40,
					color: theme.color.white,
					lineHeight: '52px',
					fontWeight: 'bold',
				}}
			>
				{t('onboarding.addCertificate1')}
			</H2>
			<Spacer size={48} />
			<Box
				style={{
					width: '979px',
					height: 'fit-content',
					maxHeight: '70%',
					overflow: 'scroll',
					background: theme.color.white,
					borderRadius: '10px',
					boxShadow: '0px 3px 6px #00000029',
				}}
				padding={25}
			>
				<Box center margin="0px 0 40px">
					<H2 style={{ fontSize: 32 }}>
						{t('onboarding.addCertificate2')}
					</H2>
					<P style={{ fontSize: 18 }}>
						{t('onboarding.addCertificate3')}
					</P>
				</Box>
				<form onSubmit={handleSubmit}>
					<Box
						overflow
						row
						margin="0px 40px 0"
						justifyContent="space-between"
					>
						<Box key={orgDetails && orgDetails.id}>
							<Label required style={labelStyles}>
								{t('marketPlace.certificateType')}
							</Label>
							<Box width={164}>
								{currentPlatform() === 'palmoil' ? (
									<Select
										options={[
											'UNCERTIFIED',
											'RSPO',
											'MSPO',
											'MPOB',
											'ISCC',
											'ISPO',
										]}
										value={
											getIn(orgDetails, [
												'meta',
												'unCertified',
											])
												? 'UNCERTIFIED'
												: values.issuingBody
										}
										onChange={value => {
											if (value === 'UNCERTIFIED') {
												setValues({
													number: ' ',
													files: [],
													supplychainModel: [],
													certificationBody: '',
													issueDate: '',
													expiryStartDate: '',
													expiryEndDate: '',
													issuingBody: value,
												})
											} else {
												setFieldValue(
													'issuingBody',
													value
												)
												setFieldValue(
													'members',
													supplybaseMembers.members
												)
											}
										}}
										returnOnlyValue
									/>
								) : (
									<Input
										value={
											getIn(orgDetails, [
												'meta',
												'unCertified',
											])
												? 'UNCERTIFIED'
												: values.issuingBody
										}
										name="issuingBody"
										type="text"
										onChange={handleChange}
										onBlur={handleBlur}
										disabled={
											values &&
											values.issuingBody === 'UNCERTIFIED'
										}
										error={
											values.issuingBody !==
												'UNCERTIFIED' &&
											touched.issuingBody &&
											errors.issuingBody
										}
									/>
								)}
							</Box>
						</Box>
						<Box>
							<Label required style={labelStyles}>
								{t('auditReport.certificationNumber')}
							</Label>
							<Box width={164}>
								<Input
									value={values.number}
									name="number"
									type="text"
									onChange={handleChange}
									onBlur={handleBlur}
									disabled={
										values &&
										values.issuingBody === 'UNCERTIFIED'
									}
									error={
										values.issuingBody !== 'UNCERTIFIED' &&
										touched.number &&
										errors.number
									}
								/>
							</Box>
						</Box>
						<Box overflow>
							<Label style={labelStyles}>
								{t('viewCompanyInfo.certBody')}
							</Label>
							<Box
								style={{
									flex: '1 0 auto',
									overflow: 'visible',
								}}
								width={164}
							>
								{currentPlatform() === 'palmoil' ? (
									<CreatableSelect
										value={values?.certificationBody}
										options={certificationBodyList}
										onChange={keyValue => {
											setFieldValue(
												'certificationBody',
												keyValue
											)
										}}
										disabled={
											values &&
											values.issuingBody === 'UNCERTIFIED'
										}
										error={
											touched.certificationBody &&
											errors.certificationBody
										}
										returnOnlyValue
									/>
								) : (
									<Input
										value={values?.certificationBody}
										name="certificationBody"
										type="text"
										onChange={handleChange}
										onBlur={handleBlur}
										disabled={
											values &&
											values.issuingBody === 'UNCERTIFIED'
										}
									/>
								)}
							</Box>
						</Box>

						<Box>
							<Label required style={labelStyles}>
								{values.files && values.files.length > 0
									? 'Uploaded Certificate'
									: 'Upload Certificate'}
							</Label>
							<FileUpload
								type="public"
								name="gstDocument"
								size="5242880"
								outline
								width={190}
								disableUploadForOne
								hideButtonOneUpload
								supportedFileType=".jpg,.png,.pdf"
								modalTriggerBtnLabel="Upload"
								currentUploads={values.files}
								onDocumentChange={document => {
									setFieldValue('files', document)
								}}
								onUploadFailure={({ message }) => {
									dispatch(
										AppDuc.creators.showToast({
											messageType: 'error',
											message,
										})
									)
								}}
								disabled={
									values &&
									values.issuingBody === 'UNCERTIFIED'
								}
							/>
						</Box>
					</Box>
					<Box
						row
						margin="12px 40px 0"
						justifyContent="space-between"
					>
						{currentPlatform() === 'palmoil' && (
							<Box>
								<Label required style={labelStyles}>
									{t('viewCompanyInfo.scModel')}
								</Label>
								<Box width={192}>
									<MultiSelectWithSearch
										value={values.supplychainModel}
										placeholder={t('createAsset.choose')}
										options={getSupplyChainModelsWithId(t)}
										onChange={value => {
											setFieldValue(
												'supplychainModel',
												value
											)
										}}
										disabled={
											values &&
											values.issuingBody === 'UNCERTIFIED'
										}
									/>
								</Box>
							</Box>
						)}
						<Box>
							<Label required style={labelStyles}>
								{t('viewCompanyInfo.issueDate')}
							</Label>
							<Box width={164}>
								<DatePicker
									required
									name="issueDate"
									disabled={
										values &&
										values.issuingBody === 'UNCERTIFIED'
									}
									returnKeyValue
									value={values.issueDate}
									onChange={keyValue =>
										setFieldValue(
											'issueDate',
											keyValue.issueDate
										)
									}
								/>
							</Box>
						</Box>
						<Box>
							<Label required style={labelStyles}>
								{t('viewPartner.startDate')}
							</Label>
							<Box width={164}>
								<DatePicker
									required
									name="expiryStartDate"
									returnKeyValue
									value={values.expiryStartDate}
									disabled={
										values &&
										values.issuingBody === 'UNCERTIFIED'
									}
									onChange={keyValue =>
										setFieldValue(
											'expiryStartDate',
											keyValue.expiryStartDate
										)
									}
								/>
							</Box>
						</Box>
						<Box>
							<Label required style={labelStyles}>
								{t('viewCompanyInfo.expiryDate')}
							</Label>
							<Box width={164}>
								<DatePicker
									required
									name="expiryEndDate"
									returnKeyValue
									value={values.expiryEndDate}
									disabled={
										values &&
										values.issuingBody === 'UNCERTIFIED'
									}
									onChange={keyValue =>
										setFieldValue(
											'expiryEndDate',
											keyValue.expiryEndDate
										)
									}
								/>
							</Box>
						</Box>
						{currentPlatform() !== 'palmoil' && <Box width={192} />}
					</Box>

					{values.members?.length > 0 && (
						<Box margin="0px 40px 0">
							<Line horizontal margin="10px 0px" />
							<Box center margin="0px 0px 20px 0">
								<H2 style={{ fontSize: 24 }}>
									{t('onboarding.addCertificate4')}
								</H2>
							</Box>
							{values.members?.map((_supply, index) => (
								<Box
									key={
										_supply?.meta?.certificate?.address
											?.location?.long
									}
								>
									<Box row>
										<Box flex>
											<H3>{_supply?.name}</H3>
											<P>
												{`${getIn(_supply, [
													'meta',
													'certificate',
													'address',
													'line1',
												]) || ''}, ${getIn(_supply, [
													'meta',
													'certificate',
													'address',
													'street',
												]) || ''}, ${getIn(_supply, [
													'meta',
													'certificate',
													'address',
													'city',
												]) || ''}`}
											</P>
											<P>
												{`${getIn(_supply, [
													'meta',
													'certificate',
													'address',
													'state',
												]) || ''}, ${getIn(_supply, [
													'meta',
													'certificate',
													'address',
													'country',
												]) || ''}`}
											</P>
										</Box>

										<Box padding="0px 0px 0px 20px" flex>
											<Label style={labelStyles}>
												{t('login.email')}
											</Label>
											<Box>
												<Input
													placeholder={t(
														'shareRFQModal.label'
													)}
													value={
														_supply?.meta
															?.primaryContact
															?.email
													}
													key={`${_supply?.meta?.certificate?.address?.location?.long} - ${_supply?.meta?.certificate?.address?.location?.lat}`}
													name={`members.${index}.meta.primaryContact.email`}
													type="text"
													onChange={handleChange}
													onBlur={handleBlur}
													error={
														values.issuingBody !==
															'UNCERTIFIED' &&
														touched?.members?.meta
															?.primaryContact
															?.email &&
														errors?.members?.meta
															?.primaryContact
															?.email
													}
												/>
											</Box>
										</Box>
										<Box flex margin="0px 20px">
											<Label style={labelStyles}>
												{t('invitePartner.phoneNo')}
											</Label>
											<Box>
												<Input
													placeholder="+60123456789"
													value={
														_supply?.meta
															?.primaryContact
															?.mobile
													}
													key={`${_supply?.meta?.certificate?.address?.location?.lat} - ${_supply?.meta?.certificate?.address?.location?.long}`}
													name={`members.${index}.meta.primaryContact.mobile`}
													type="text"
													onChange={handleChange}
													onBlur={handleBlur}
													error={
														values.issuingBody !==
															'UNCERTIFIED' &&
														touched?.members?.meta
															?.primaryContact
															?.mobile &&
														errors?.members?.meta
															?.primaryContact
															?.mobile
													}
												/>
											</Box>
										</Box>
									</Box>
									<Line horizontal margin="10px 0px" />
								</Box>
							))}
						</Box>
					)}
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<Box center margin="20px 0">
							<Button
								disabled={disableCTA}
								isLoading={isSubmitting}
								type="submit"
								primary
								label={t('onboarding.updateCert')}
								extendStyles={{
									width: 410,
								}}
							/>
						</Box>
						<ButtonWithNoBorder
							label={t('common.skip')}
							onClick={() => {
								if (!carbonNumberID) {
									dispatch(
										MainRouteDuc.creators.switchPage(
											MainRouteDuc.types.ONBOARD$ACTION,
											{
												action: 'carbon-number',
											}
										)
									)
								} else {
									dispatch(
										MainRouteDuc.creators.switchPage(
											MainRouteDuc.types.ONBOARD$ACTION,
											{
												action: 'all-set',
											}
										)
									)
								}
							}}
							extendStyles={{
								marginLeft: 10,
							}}
						/>
					</div>
				</form>
			</Box>
		</Box>
	)
}

export { CertificateBlock }
