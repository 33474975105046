import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AdminDuc } from 'core-app/modules/Admin/duc'
import { WebTourDuc } from 'core-app/modules/WebTour/duc'
import { UnexpectedErrorBlock } from 'core-app/modules/App/components/Error'
import { AddEmployee } from 'core-app/modules/Admin/containers/EmployeeManagement/AddEmployee'
import { ViewEmployee } from 'core-app/modules/Admin/containers/EmployeeManagement/ViewEmployee'
import { PreviewEmployee } from 'core-app/modules/Admin/containers/EmployeeManagement/PreviewEmployee'

const EmployeeManagement = () => {
	const dispatch = useDispatch()
	const { payload = {} } = useSelector(AdminDuc.selectors.location)

	const { action } = payload
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)

	if (tourModule !== '') {
		dispatch(WebTourDuc.creators.setActiveTourModule(''))
	}

	if (action === 'add-employee' || action === 'edit-employee') {
		return <AddEmployee />
	}

	if (action === 'preview-employee') {
		return <PreviewEmployee />
	}

	if (action === 'view-employee') {
		return <ViewEmployee />
	}

	return <UnexpectedErrorBlock status={401} />
}

export { EmployeeManagement }
