/* @flow */
import React, { useState } from 'react'
import { Close } from 'grommet-icons'
import { Layer } from 'grommet'
import { Button } from '../Button'
import { Box } from '../../utils/Box'
import { Icon, IconWrapper } from '../../icons/components/Icon'

type Props = {
	children?: any,
	onClick?: () => void,
	icon?: string,
	style?: Object,
}

const FullScreen = ({ children, icon }: Props) => {
	const [showLayer, setShowLayer] = useState(false)

	return (
		<>
			<IconWrapper
				color="grey"
				size={25}
				style={{
					marginTop: '8px',
					marginRight: '16px',
					cursor: 'pointer',
				}}
				onClick={() => setShowLayer(true)}
			>
				<Icon glyph={icon} />
			</IconWrapper>

			{showLayer && (
				<Layer full animation="fadeIn" style={{ overflow: 'auto' }}>
					<Box
						alignItems="flex-end"
						style={{
							padding: '10px 20px 0px 0px',
							position: 'fixed',
							zIndex: 10,
							width: '100%',
						}}
					>
						<Button
							plain
							style={{ cursor: 'pointer' }}
							customIcon={<Close />}
							onClick={() => setShowLayer(false)}
						/>
					</Box>
					<Box
						justifyContent="space-between"
						alignItems="center"
						style={{
							marginTop: 40,
						}}
					>
						{children}
					</Box>
				</Layer>
			)}
		</>
	)
}

FullScreen.defaultProps = {
	onClick: () => {},
}

export { FullScreen }
