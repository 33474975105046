import { PartnerDuc } from 'core-app/modules/Partner/duc'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { getIn } from 'timm'
import saga from 'core-app/modules/Partner/PartnerSaga'

export default async (dispatch, getState, { action, extra }) => {
	const { type, customMeta = {} } = action
	const currentLocationFromAction = getIn(action, [
		'meta',
		'location',
		'current',
	])
	/** if someone specifically wants to skip thunk fetch again, this check helps */
	const { skipRouteThunk } = customMeta
	if (skipRouteThunk) return

	// check if nothing changed in the route.

	const state = getState()

	const locationState = currentLocationFromAction || state.location

	const { payload, query } = locationState

	const docType = getIn(query, ['docType'])

	// Inject the saga here. If it wasn't already
	extra.getSagaInjectors().injectSaga('PARTNER', { saga })

	const isMainDashboard = MainRouteDuc.types.PARTNER === type
	const isSubDashboard = MainRouteDuc.types.PARTNER$ACTION === type
	const isDocumentView = MainRouteDuc.types.PARTNER$WREQREFERENCE === type

	let activeModule = ''

	if (isMainDashboard) {
		// load the dashboard of the core module
		activeModule = 'dashboard'
		dispatch(
			PartnerDuc.creators.fetchPartnerList(
				'all',
				locationState,
				false,
				''
			)
		)
	} else if (isSubDashboard || isDocumentView) {
		activeModule = 'subdashboard'
		if (payload.action === 'view') {
			if (docType === 'partner-search') {
				dispatch(
					PartnerDuc.creators.fetchPartnerDetails(payload.partnerRef)
				)
			}
			if (docType === 'partner-listing') {
				dispatch(
					PartnerDuc.creators.fetchPartnerDetails(payload.partnerRef)
				)
				dispatch(
					PartnerDuc.creators.fetchPartnershipTimeline(
						query.requestID
					)
				)
			}
			if (docType === 'notifications') {
				dispatch(
					PartnerDuc.creators.initiatePartnerDetails(
						payload.partnerRef
					)
				)
			}
		}
		if (payload.action === 'invite-partner') {
			dispatch(AuthDuc.creators.fetchActor())
			dispatch(AuthDuc.creators.fetchGlobalOrgs())
		}
	}

	// load the dashboard of the core module
	dispatch(PartnerDuc.creators.setActiveModule(activeModule))

	// based on the payload or action, initiate saga's
}
