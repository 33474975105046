import React, { useRef } from 'react'
import { DataTable } from 'grommet'
import styled from 'styled-components'
import SortDown from '../../icons/sort-down.svg'
import SortReset from '../../icons/sort-reset.svg'
import { applyMediaQueries } from '../../utils/helpers'
import theme from '../../utils/base-theme'

export const Wrapper = styled.div(p => {
	let width = '100%'

	if (p.tableSize === 'small') width = '50%'

	return {
		width,
		height: 'auto',
		backgroundColor: p.theme.color.white,
		borderRadius: p.noRoundedBorder ? 'initial' : 10,
		color: p.theme.color.grey8,
		border: p.noBorder ? 'none' : `1px solid ${theme.color.grey4}`,
		borderWidth: '1px',
		'& tbody tr:last-child th, & tbody tr:last-child td': {
			borderBottom: 'none',
		},
		'& thead': {
			backgroundColor: '#F6FBFF',
			height: 56,
		},
		'& thead > tr > th': {
			paddingTop: 8,
			paddingBottom: 8,
			fontSize: theme.fontSize.s,
		},
		'& tbody > tr > td': {
			overflow: 'visible',
		},
		...p.overrideStyles,
		...applyMediaQueries(['mobile'], {
			minWidth: p.mobileViewCSSOn ? 200 : 780,
			overflow: p.mobileViewCSSOn ? 'auto' : 'scroll',
		}),
	}
})

const Table = ({
	columnConfig,
	rowData,
	// size = 'medium',
	noRoundedBorder,
	overrideStyles,
	step,
	tableSize,
	noBorder,
	mobileViewCSSOn = false,
}) => {
	const wrapperRef = useRef()

	// useLayoutEffect(() => {
	// 	// There is always a weird dom element that comes up at the end created by
	// 	// InfiniteScroll element used in DataTable.js by grommet. TODO: fix this and
	// 	// and figure out alternative
	// 	const dom = wrapperRef.current
	// 	if (dom) {
	// 		const stupidDom = dom.querySelector('td > div:empty')
	// 		if (stupidDom && stupidDom.parentElement) {
	// 			const targetDom = stupidDom.parentElement
	// 			targetDom.style.padding = 0
	// 			targetDom.parentElement.previousSibling
	// 				.querySelectorAll('th, td')
	// 				.forEach(e => {
	// 					e.style.borderBottom = 'none'
	// 				})
	// 		}
	// 	}
	// }, [rowData])

	return (
		<Wrapper
			ref={wrapperRef}
			noRoundedBorder={noRoundedBorder}
			overrideStyles={overrideStyles}
			tableSize={tableSize}
			noBorder={noBorder}
			mobileViewCSSOn={mobileViewCSSOn}
		>
			<DataTable
				alignSelf="center"
				border={{
					header: {
						color: theme.color.grey4,
						side: 'bottom',
					},
					body: {
						color: theme.color.grey4,
						side: 'bottom',
					},
				}}
				pad={{ horizontal: 'small', vertical: 'small' }}
				columns={columnConfig}
				data={rowData}
				// size={size}
				step={step}
				background={{
					header: '#CBD2EF',
					body: ['white', '#F7FAFD'],
				}}
				// onClickRow={onClickRow}
			/>
		</Wrapper>
	)
}

Table.defaultProps = {
	onClickRow: () => {},
}

export { Table, SortDown, SortReset }
