/* @flow */
import React, { useState, useEffect } from 'react'
import { RadioButton, RadioButtonGroup } from 'grommet'
import styled from 'styled-components'
import { Label } from '../Label'
import { FieldText } from '../FieldText'
import { Box } from '../../utils/Box'

type Props = {
	selected: boolean,
	disabled: boolean,
	name: string,
	label?: string,
	required?: boolean,
	returnKeyValue?: boolean,
	error?: string,
	link?: boolean,
	onChange: (param: string | {}) => {},
	hideError?: boolean,
}

const RadioInput = ({
	selected: selectedProp,
	onChange: onChangeProp,
	name,
	label,
	returnKeyValue,
	disabled,
	required = false,
	error,
	hideError,
	link,
}: Props) => {
	const identifier = name || label || '---'
	const [selected, setSelected] = useState(selectedProp ? identifier : '')

	const onChange = event => {
		const { value } = event.target

		setSelected(value)
		onChangeProp(
			returnKeyValue ? { [identifier]: selected === identifier } : event
		)
	}

	useEffect(() => setSelected(selectedProp ? identifier : ''), [
		identifier,
		selectedProp,
	])

	return (
		<>
			<RadioButton
				id={`radio-${identifier}`}
				name={name}
				disabled={disabled}
				value={identifier}
				label={
					label && (
						<Label
							link={link}
							for={`radio-${identifier}`}
							required={required}
						>
							{label}
						</Label>
					)
				}
				checked={selected === identifier}
				onChange={onChange}
			/>
			{!hideError && (
				<FieldText customLeftMargin="auto" error show={!!error}>
					{error}
				</FieldText>
			)}
		</>
	)
}

type Options = {
	label?: string,
	value: string,
	name: string,
}

type RadioGroupProps = {
	selected: string,
	options: Array<Options>,
	name: string,
	label: string,
	required: boolean,
	returnKeyValue: boolean,
	disabled?: boolean,
	error: string,
	gap?: String,
	hideError?: boolean,
	direction?: string,
	link?: boolean,
	onChange: (param: string | {}) => {},
}

const RadioButtonWrapper = styled(Box)`
	padding: 1px;
	& input[type='radio']:checked + div {
		border-color: ${p => p.theme.color.primary};
	}
`
const RadioInputGroup = ({
	selected,
	options,
	label,
	disabled = false,
	gap = 'small',
	required,
	name,
	direction = 'column',
	returnKeyValue,
	onChange: onChangeProp,
	error,
	hideError,
	link,
}: RadioGroupProps) => {
	const uniqueId = name || label || '---'
	const [value, setValue] = useState(selected)

	const onChange = event => {
		const { value: eventValue } = event.target

		setValue(eventValue)
		onChangeProp(returnKeyValue ? { [uniqueId]: eventValue } : event)
	}

	useEffect(() => setValue(selected), [selected])

	return (
		<RadioButtonWrapper>
			{label && (
				<Label
					link={link}
					style={{ paddingBottom: 10 }}
					for={`radio-group-${uniqueId}`}
					required={required}
				>
					{label}
				</Label>
			)}
			<RadioButtonGroup
				gap={gap}
				disabled={disabled}
				id={`radio-group-${uniqueId}`}
				name={name}
				direction={direction}
				options={options.map(({ name: _name, label: _label }) => ({
					name: _name,
					label: _label,
					value: _name || _label,
				}))}
				value={value}
				onChange={onChange}
			/>
			{!hideError && (
				<FieldText customLeftMargin="auto" error show={!!error}>
					{error}
				</FieldText>
			)}
		</RadioButtonWrapper>
	)
}

export { RadioInput, RadioInputGroup }
