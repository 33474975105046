import React from 'react'
import { useSelector } from 'react-redux'
import { NotFoundBlock } from 'core-app/modules/App/components/Error'
import { MessagesDuc } from './duc'
import { AggregatedMessages } from './containers/AggregatedMessages'

const modulesMap = {
	messages: AggregatedMessages,
}

const Messages = () => {
	const activeModule = useSelector(MessagesDuc.selectors.activeModule)
	const Component = modulesMap[activeModule] || NotFoundBlock
	// if we are here, we have a submodule, route it to the module

	return <Component />
}

export default Messages
