import React, { useState, useCallback } from 'react'
import { Box } from 'ui-lib/utils/Box'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { addLast, merge, getIn } from 'timm'
import { useTranslation } from 'react-i18next'
import { MainRouteDuc } from 'core-app/routes/duc'
import { H2, Label, Text, P } from 'ui-lib/components/Typography'
import { FieldText } from 'ui-lib/components/FieldText'
import { SelectSearch } from 'ui-lib/components/Select'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Input } from 'ui-lib/components/Input'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import DeleteIcon from 'ui-lib/icons/deleteTrash.svg'
import rightArrow from 'ui-lib/icons/right-arrow.svg'
import * as yup from 'yup'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { applyMediaQueries } from 'ui-lib/utils/helpers'
import { Button, ButtonWithNoBorder } from 'ui-lib/components/Button'
import { useFormik } from 'formik'
import { isEmptyObject } from 'core-app/utils/helpers'
import theme from 'ui-lib/utils/base-theme'

const validationSchema = yup.object().shape({
	storage: yup
		.array()
		.of(
			yup.object().shape({
				storageUnitID: yup
					.string()
					.strict()
					.required(),
				quantity: yup
					.number()
					.strict()
					.required(),
			})
		)
		.strict()
		.required(),
})

export const FormHeader = styled(Box)(p => ({
	background: p.theme.color.grey2,
	padding: '24px',
}))

export const CreateStorageTankContent = styled(Box)(p => ({
	alignItems: 'end',
	margin: '10px 25px',
	justifyContent: 'end',
	display: '-webkit-inline-box',
	color: p.theme.color.primary,
	'&:hover': {
		cursor: 'pointer',
		textDecoration: 'underline',
		color: p.theme.color.blue1,
	},
}))

export const FormBody = styled(Box)(p => ({
	background: p.theme.color.white,
	padding: '24px',
	overflowY: 'auto',
	height: '45vh',
	marginBottom: '20px',
	...applyMediaQueries(['mobile'], {
		maxHeight: '80vh',
	}),
}))

export const FormFooter = styled(Box)(p => ({
	position: 'absolute',
	bottom: 0,
	height: 60,
	background: p.theme.color.grey2,
	padding: '4px 24px',
}))

export const CTAWrapper = styled(Box)(p => ({
	width: 'fit-content',
	padding: 12,
	background: p.theme.color.white,
	color: p.theme.color.primary,
}))

export const AssignTankForm = ({
	activeTanks,
	storageList,
	onClose,
	onChange,
	producedQty,
	productDetails,
	supplyChainModel,
	certificationType = '',
	isIncomingStorage = false,
}) => {
	const storageTank = [
		{
			activeIndex: 0,
			storageUnitID: '',
			quantity: '',
		},
	]
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const _activeTanks = activeTanks.length === 0 ? storageTank : activeTanks
	const [tanks, setTanks] = useState(_activeTanks)
	const allProducts = useSelector(AuthDuc.selectors.getProducts)
	const productName = allProducts.filter(
		product => product.id === productDetails.productID
	)
	const curentProductName = getIn(productName, [0, 'name']) || ''
	const uom = getIn(productName, [0, 'uom']) || ''

	const [activeIndex, setActiveIndex] = useState(activeTanks.length + 1)
	const [trigger, setTrigger] = useState(false)
	const { submitForm, errors, setFieldValue, values } = useFormik({
		initialValues: {
			storage: tanks,
		},
		validationSchema,
		enableReinitialize: true,
		onSubmit: _values => {
			onChange(_values)
		},
	})

	const handleChange = useCallback(
		(action, meta) => {
			if (action === 'add_Values') {
				const { id, value, type } = meta

				// eslint-disable-next-line array-callback-return
				tanks.map((row, index) => {
					if (row.activeIndex === id && type === 'storageUnitID') {
						tanks[index] = merge(tanks[index], {
							storageUnitID: value.id,
							supplyChainModel: value.supplyChainModel,
							inventoryType: value.inventoryType,
							certification: value.certification,
						})
					}
					if (row.activeIndex === id && type === 'quantity') {
						tanks[index] = merge(tanks[index], {
							quantity: parseFloat(value, 5),
						})
					}
				})

				setFieldValue('storage', tanks)
				setTrigger(true)
			}
		},
		[tanks, setFieldValue]
	)

	const quantity =
		values.storage.length === 0
			? 0
			: values.storage
					.map(_storage => _storage.quantity)
					.reduce(
						(ttl, _storage) =>
							parseFloat(_storage, 5) + parseFloat(ttl, 5)
					)

	const qtyCheck = producedQty ? quantity !== Number(producedQty) : ''

	const disableCTA = !isEmptyObject(errors) || !trigger || qtyCheck

	const filteredStorageList = storageList
		.filter(tk => {
			return (
				productDetails.productID ===
					getIn(tk, ['meta', 'storedProducts', 0, 'productID']) &&
				// supplyChainModel === getIn(tk, ['meta', 'supplyChainModel']) &&
				getIn(tk, ['meta', 'inventoryType']) === 'incoming'
			)
		})
		.map(({ name, id, capacity, capacityUOM, meta }) => {
			const currentQty = meta?.storedProducts
				? meta.storedProducts.reduce((accumulator, item) => {
						return accumulator + item.availableQty
				  }, 0)
				: 0

			const getCapacity = capacity
				? `(${t('viewAsset.capacity')}: ${capacity}${capacityUOM})`
				: ''

			return {
				id,
				label: `${name} (${t(
					'viewAsset.currentQty'
				)}: ${currentQty}${capacityUOM || ''}) ${getCapacity}`,
			}
		})

	const storageListTransformed = storageList
		.filter(
			tk =>
				productDetails.productID ===
					getIn(tk, ['meta', 'storedProducts', 0, 'productID']) &&
				supplyChainModel === getIn(tk, ['meta', 'supplyChainModel']) &&
				getIn(tk, ['meta', 'inventoryType']) === 'outgoing' &&
				certificationType === getIn(tk, ['meta', 'certificationType'])
		)
		.map(({ name, id, meta }) => {
			return {
				id,
				label: name,
				supplyChainModel: getIn(meta, ['supplyChainModel']),
				inventoryType: getIn(meta, ['inventoryType']),
				certification: getIn(meta, ['certificationType']),
			}
		})

	return (
		<Box>
			<FormHeader
				row
				justifyContent="space-between"
				alignItems="flex-end"
			>
				<H2 primary>{t('storage.selectTank')}</H2>
				<Box>
					{productDetails.product && (
						<Box row>
							<Text
								style={{
									lineHeight: '1.5',
									fontSize: theme.fontSize.m,
								}}
							>
								{t('storage.inputMaterial')}
							</Text>
							<P>{` : ${productDetails.product}`}</P>
						</Box>
					)}

					{productDetails.quantity && (
						<Box row>
							<Text
								style={{
									lineHeight: '1.5',
									fontSize: theme.fontSize.m,
								}}
							>
								{t('storage.quantity')}
							</Text>
							<P>{` : ${productDetails.quantity}`}</P>
						</Box>
					)}
					{productDetails.productID && (
						<Box row>
							<Text
								style={{
									lineHeight: '1.5',
									fontSize: theme.fontSize.m,
								}}
							>
								{t('storage.producedProduct')}
							</Text>
							<P>{` : ${curentProductName}`}</P>
						</Box>
					)}

					{productDetails.producedQty && (
						<Box row>
							<Text
								style={{
									lineHeight: '1.5',
									fontSize: theme.fontSize.m,
								}}
							>
								{t('storage.producedQty')}
							</Text>
							<P>{` : ${productDetails.producedQty} ${uom}`}</P>
						</Box>
					)}
				</Box>
			</FormHeader>
			{/* <Spacer size={16} /> */}
			<CreateStorageTankContent
				onClick={() => {
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.ADMIN$SUBMODULEWACTION,
							{
								rootModule: 'assets',
								subModule: 'storageunits',
								action: 'create-asset',
							}
						)
					)
				}}
			>
				{`${t('common.create')} ${t('production.storageTank')}`}
				<IconWrapper
					hoverEffect
					width={15}
					height={15}
					style={{
						paddingLeft: '8px',
						alignItems: 'center',
						paddingBottom: '7px',
					}}
				>
					<Icon glyph={rightArrow} />
				</IconWrapper>
				<IconWrapper
					hoverEffect
					width={13}
					height={13}
					style={{
						alignItems: 'center',
						paddingBottom: '9px',
					}}
				>
					<Icon glyph={rightArrow} />
				</IconWrapper>
			</CreateStorageTankContent>
			<form>
				<FormBody padding="20px">
					{tanks.map(track => {
						return (
							<Box key={track.activeIndex} row padding={8}>
								<SelectSearch
									value={track.storageUnitID}
									name={`storageUnitID-${track.activeIndex}`}
									placeholder={t('production.assignStorage')}
									onChange={value => {
										handleChange('add_Values', {
											id: track.activeIndex,
											value,
											type: 'storageUnitID',
										})
									}}
									labelKey="label"
									valueKey="id"
									defaultLabel={t(
										'common.selectDefaultLabel'
									)}
									returnOnlyValue
									options={
										isIncomingStorage
											? filteredStorageList
											: storageListTransformed
									}
									required
								/>
								<Spacer size={16} horizontal />
								<Box overflow>
									<Input
										extendStyles={{
											lineHeight: 1,
										}}
										name={`quantity-${track.activeIndex}`}
										value={track.quantity}
										placeholder={t('storage.quantity')}
										onChange={e =>
											handleChange('add_Values', {
												id: track.activeIndex,
												value: e.target.value,
												type: 'quantity',
											})
										}
									/>
								</Box>
								<Spacer size={16} horizontal />
								<Box
									style={{
										marginTop: '8px',
									}}
								>
									<IconWrapper
										hoverEffect
										width={25}
										height={25}
										onClick={() => {
											const document = tanks.filter(
												currentDoc =>
													currentDoc.activeIndex !==
													track.activeIndex
											)
											setTanks(document)

											// eslint-disable-next-line no-unused-expressions
											tanks.length > 1
												? setTrigger(true)
												: setTrigger(false)
										}}
									>
										<Icon glyph={DeleteIcon} />
									</IconWrapper>
								</Box>
							</Box>
						)
					})}
					<Spacer size={8} />

					<CTAWrapper
						onClick={() => {
							const initialActiveBatch = {
								activeIndex,
								quantity: '',
								storageUnitID: '',
							}
							setActiveIndex(Math.max(0, activeIndex + 1))

							const addRow = addLast(tanks, initialActiveBatch)
							setTanks(addRow)
							setTrigger(false)
						}}
					>
						<Label color="inherit" bold small link as="a">
							{t('storage.addMore')}
						</Label>
					</CTAWrapper>
					{quantity !== producedQty && producedQty && (
						<div style={{ marginBottom: '20px' }}>
							<FieldText show error>
								{t('storage.qualityMismatch')}
							</FieldText>
						</div>
					)}
				</FormBody>
			</form>

			<FormFooter row justifyContent="flex-end" style={{ width: '100%' }}>
				<Box center row style={{ maxWidth: 375 }}>
					<ButtonWithNoBorder
						label={t('common.cancel')}
						onClick={() => {
							onClose()
						}}
					/>
				</Box>
				<Spacer size={10} horizontal />
				<Box
					justifyContent="center"
					width={150}
					padding={1}
					height="inherit"
					overflow
				>
					<Button
						label={t('common.submit')}
						primary
						disabled={disableCTA}
						onClick={() => {
							submitForm()
						}}
					/>
				</Box>
			</FormFooter>
		</Box>
	)
}

export const KeyValueSegment = ({ label, value }) => {
	return (
		<Box
			row
			padding="0 12px"
			justifyContent="flex-start"
			alignItems="center"
		>
			<Text style={{ lineHeight: '1.5', fontSize: theme.fontSize.m }}>
				{label} : {value}
			</Text>
		</Box>
	)
}
