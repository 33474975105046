export const initialState = {
	activeModule: '',
	notifications: [],
	pagination: {
		activeIndex: 0, // total, pageCount
		limit: 0,
		total: 0,
		nextCursor: '',
	},
}
