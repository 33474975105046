import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn } from 'timm'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AppDuc } from 'core-app/modules/App/duc'
import { AdminDuc } from 'core-app/modules/Admin/duc'
import { Title } from 'core-app/shared/components/Title'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Card } from 'ui-lib/components/Card'
import { Button } from 'ui-lib/components/Button'
import { Input } from 'ui-lib/components/Input'
import { Select, SelectSearch } from 'ui-lib/components/Select'
import { H3 } from 'ui-lib/components/Typography'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import AdminIcon from 'ui-lib/icons/admin.svg'
import { isEmptyObject, getRandomNumber } from 'core-app/utils/helpers'
import { useFormik } from 'formik'
import * as yup from 'yup'
import theme from 'ui-lib/utils/base-theme'
import { getAssetUOMs, getVehicleTypes } from '../../config'

const CreateVehicle = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const locationState = useSelector(AdminDuc.selectors.location)
	const { payload = {} } = locationState
	const { action, subModule } = payload
	const initialValues = useSelector(
		AdminDuc.selectors.getInitialVehicleDetails
	)
	const [key, setKey] = useState(getRandomNumber())

	const uom = getIn(getAssetUOMs, ['vehicle']) || []

	const uomList = uom.map(({ name, label }) => {
		return { name, label: t(label) }
	})

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.admin'),
			name: 'admin',
			isActive: true,
		},
		{
			label: t('breadcrumb.assets'),
			name: 'assets',
			isActive: true,
		},
		{
			label:
				action === 'edit-asset'
					? t('breadcrumb.editAsset')
					: t('breadcrumb.create-asset'),
			name: 'create-asset',
			isActive: false,
		},
	]

	/** Handlers to react on actions */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'admin') {
			dispatch(MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN))
		}
		if (target === 'assets') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.ADMIN$SUBROOT,
					{
						rootModule: 'assets',
					}
				)
			)
		}
	}

	const validationSchema = yup.object().shape({
		name: yup.string().required(t('validation.assetNameReq')),
		number: yup.string().required(t('validation.assetNumReq')),
		capacity: yup.number().positive(t('validation.positiveCapacity')),
		type: yup.string().required(),
	})

	const {
		handleSubmit,
		values,
		handleChange,
		handleBlur,
		setFieldValue,
		touched,
		errors,
	} = useFormik({
		initialValues,
		enableReinitialize: true,
		validationSchema,
		onSubmit: _values => {
			switch (action) {
				case 'create-asset': {
					dispatch(
						AdminDuc.creators.createVehicleAsAsset(
							_values,
							t('createAsset.vehicleAddedToast')
						)
					)

					break
				}
				case 'edit-asset': {
					const updatedValues = {
						..._values,
						vehicleID: payload.documentReference,
					}
					dispatch(
						AdminDuc.creators.editVehicle(
							updatedValues,
							t('createAsset.vehicleUpdatedToast')
						)
					)

					break
				}

				default:
					break
			}
		},
	})

	const disableCTA = !isEmptyObject(errors) || !values.number

	useEffect(() => {
		if (action === 'edit-asset') {
			setKey(getRandomNumber())
		}
	}, [action, initialValues])

	return (
		<Box padding={8} width="100%" height="100%">
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Box row justifyContent="space-between" alignItems="center">
				<Title
					title={
						action === 'edit-asset'
							? `${t('createAsset.editAsset')} - ${t(
									`assetsHome.${subModule}`
							  )}`
							: `${t('common.create')} ${t(
									`assetsHome.${subModule}`
							  )}`
					}
					icon={AdminIcon}
					note={t('createAsset.note')}
				/>
			</Box>
			<Card
				width={isMobile ? '320px' : '100%'}
				height={isMobile ? '350px' : 'auto'}
				textAlign="left"
			>
				<Box
					padding={8}
					style={{
						backgroundColor: theme.color.accent3,
					}}
				>
					<H3
						bold
						color={theme.color.accent2}
						style={{
							position: 'relative',
							right: '-25px',
						}}
					>
						{action === 'edit-asset'
							? `${t('createAsset.editAsset')} - ${t(
									`assetsHome.${subModule}`
							  )}`
							: `${t('assetsHome.createAsset')} - ${t(
									`assetsHome.${subModule}`
							  )}`}
					</H3>
				</Box>
				<React.Fragment key={key}>
					<Box style={{ padding: '24px 16px 16px 24px' }}>
						<form onSubmit={handleSubmit}>
							<Box row>
								<Box>
									<Box width={300}>
										<Input
											label={t('createAsset.assetName')}
											value={values.name}
											name="name"
											type="text"
											onChange={handleChange}
											onBlur={handleBlur}
											error={touched.name && errors.name}
											required
										/>
									</Box>

									<Box width={300}>
										<Input
											label={t('createAsset.assetNumber')}
											value={values.number}
											name="number"
											type="text"
											onChange={handleChange}
											onBlur={handleBlur}
											error={
												touched.number && errors.number
											}
											required
										/>
									</Box>
								</Box>
								<Spacer size={32} />
								<Box>
									<Box width={300}>
										<Input
											label={t('createAsset.make')}
											value={values.make}
											name="make"
											type="text"
											onChange={handleChange}
											onBlur={handleBlur}
											error={touched.make && errors.make}
										/>
									</Box>

									<Box width={300}>
										<Input
											label={t('createAsset.model')}
											value={values.model}
											name="model"
											type="text"
											onChange={handleChange}
											onBlur={handleBlur}
											error={
												touched.model && errors.model
											}
										/>
									</Box>
								</Box>
							</Box>
							<Box row>
								<Box width={300}>
									<Input
										label={t('createAsset.capacity')}
										value={values.capacity}
										name="capacity"
										type="number"
										onChange={handleChange}
										onBlur={handleBlur}
										error={
											touched.capacity && errors.capacity
										}
									/>
								</Box>
								<Spacer size={32} />
								<Box style={{ padding: '20px 0px' }}>
									<Select
										value={values.capacityUOM}
										placeholder={t('createAsset.chooseUOM')}
										options={uomList}
										key={values.capacityUOM}
										labelKey="label"
										valueKey="name"
										onChange={value =>
											setFieldValue(
												'capacityUOM',
												value.name
											)
										}
										returnOnlyValue
									/>
								</Box>
							</Box>
							<Box>
								<SelectSearch
									label={t('createAsset.type')}
									placeholder={t('createAsset.chooseVehtype')}
									value={values.type}
									name="type"
									options={getVehicleTypes(t)}
									onChange={value => {
										setFieldValue('type', value.name)
									}}
									returnOnlyValue
									labelKey="label"
									valueKey="name"
									onBlur={handleBlur}
									error={touched.type && errors.type}
									required
									defaultLabel={t(
										'common.selectDefaultLabel'
									)}
								/>
							</Box>
							<Box alignItems="flex-end">
								<Box width={150}>
									<Button
										disabled={disableCTA}
										type="submit"
										primary
										label={t('common.submit')}
									/>
								</Box>
							</Box>
						</form>
					</Box>
				</React.Fragment>
			</Card>
		</Box>
	)
}

export { CreateVehicle }
