import { TraceDuc } from 'core-app/modules/Trace/duc'
import { MainRouteDuc } from 'core-app/routes/duc'
import { getIn } from 'timm'
import saga from 'core-app/modules/Trace/TraceSaga'

export default async (dispatch, getState, { action, extra }) => {
	const { type, customMeta = {} } = action

	const currentLocationFromAction = getIn(action, [
		'meta',
		'location',
		'current',
	])
	/** if someone specifically wants to skip thunk fetch again, this check helps */
	const { skipRouteThunk } = customMeta
	if (skipRouteThunk) return

	// check if nothing changed in the route.

	const state = getState()

	const locationState = currentLocationFromAction || state.location
	// eslint-disable-next-line no-unused-vars
	const { payload } = locationState

	extra.getSagaInjectors().injectSaga('trace', { saga })

	let activeModule = ''

	// const state = getState()
	// Inject the saga here. If it wasn't already
	// extra.getSagaInjectors().injectSaga('listing', { saga })
	const isTraceListing = MainRouteDuc.types.PRODUCT_TRACE === type
	const isGenerateTraceV1 =
		MainRouteDuc.types.PRODUCT_TRACE$GENERATE_PRODUCT_TRACE_V1 === type
	const isGenerateTrace =
		MainRouteDuc.types.PRODUCT_TRACE$GENERATE_PRODUCT_TRACE === type
	const isGenerateTraceGroup =
		MainRouteDuc.types.PRODUCT_TRACE$GENERATE_PRODUCT_TRACE_GROUP === type
	const isGenerateTraceGroupV2 =
		MainRouteDuc.types.PRODUCT_TRACE$GENERATE_PRODUCT_TRACE_GROUP_V2 ===
		type
	const isGenerateTraceMap =
		MainRouteDuc.types.PRODUCT_TRACE$GENERATE_PRODUCT_TRACE_MAP === type

	if (isTraceListing) {
		// load the dashboard of the core module
		activeModule = 'listing'
		dispatch(
			TraceDuc.creators.fetchTraceListing(
				'product-trace',
				'delivery-order',
				locationState
			)
		)
	} else if (isGenerateTraceV1) {
		activeModule = 'generateTraceV1'
		const { traceID } = payload
		dispatch(TraceDuc.creators.flushTraceDocument())
		dispatch(TraceDuc.creators.checkBlockchainDisabled())
		dispatch(TraceDuc.creators.fetchProductOrgTrace(traceID))
	} else if (isGenerateTrace) {
		activeModule = 'generateTrace'
		const { traceID, orgID } = payload
		dispatch(TraceDuc.creators.flushTraceDocument())
		dispatch(TraceDuc.creators.checkBlockchainDisabled())
		dispatch(TraceDuc.creators.fetchProductTrace(traceID, orgID))
	} else if (isGenerateTraceGroup) {
		activeModule = 'generateTraceGroup'
		const { traceGroupID, orgID } = payload
		dispatch(TraceDuc.creators.flushTraceDocument())
		dispatch(TraceDuc.creators.checkBlockchainDisabled())
		dispatch(TraceDuc.creators.fetchProductTrace(traceGroupID, orgID, true))
	} else if (isGenerateTraceGroupV2) {
		activeModule = 'generateTraceGroupV2'
		const { traceGroupID, orgID } = payload
		dispatch(TraceDuc.creators.flushTraceDocument())
		dispatch(TraceDuc.creators.checkBlockchainDisabled())
		dispatch(TraceDuc.creators.fetchProductTrace(traceGroupID, orgID, true))
	} else if (isGenerateTraceMap) {
		activeModule = 'generateTraceMap'
		const { geoID, orgID } = payload
		dispatch(TraceDuc.creators.fetchGeoData(geoID, orgID))
	}
	dispatch(TraceDuc.creators.setActiveModule(activeModule))
}
