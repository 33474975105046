import React from 'react'
import { PlantationDuc } from 'core-app/modules/Plantation/duc'
import { useSelector } from 'react-redux'
import { UnexpectedErrorBlock } from 'core-app/modules/App/components/Error'
import { CreateBin } from './CreateBin'
import { PreviewBin } from './PreviewBin'

const BinCreation = () => {
	const { payload = {} } = useSelector(PlantationDuc.selectors.location)
	const { action } = payload
	if (action === 'create' || action === 'edit') {
		return <CreateBin />
	}

	if (action === 'view') {
		return <PreviewBin />
	}

	return <UnexpectedErrorBlock status={401} />
}

export { BinCreation }
