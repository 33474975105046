import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MainRouteDuc } from 'core-app/routes/duc'
import { StorageCompanyDuc } from 'core-app/modules/StorageCompany/duc'
import { Title } from 'core-app/shared/components/Title'
import { StorageInventoryModal } from 'core-app/modules/Refinery/components/StorageInventoryModal'
import { Tabs, DefaultTabIndicator } from 'ui-lib/components/Tabs'
import styled from 'styled-components'
import { Box } from 'ui-lib/utils/Box'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Button, ButtonIconWrapper } from 'ui-lib/components/Button'
import { TransparentBgSpinner } from 'ui-lib/components/Spinner/TransparentBgSpinner'
import { Table } from 'ui-lib/components/Table'
import { ErrorBlock } from 'ui-lib/components/ErrorBlocks'
import { Label } from 'ui-lib/components/Typography'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import BackArrowIcon from 'ui-lib/icons/arrow-back.svg'
import StorageTankIcon from 'ui-lib/icons/storageTank.svg'
import {
	getColumnConfig,
	getOutgoingColumnConfig,
} from 'core-app/modules/StorageCompany/components/Columns'
import { useTranslation } from 'react-i18next'

const CTAWrapper = styled(Box)(p => ({
	padding: 20,
	minHeight: 62,
	background: p.theme.color.white,
	borderBottomLeftRadius: 4,
	borderBottomRightRadius: 4,
	color: p.theme.color.primary,
	border: `1px solid ${p.theme.color.grey4}`,
}))

const getBreadCrumbsList = t => [
	{
		label: t('breadcrumb.home'),
		name: 'home',
		isActive: true,
	},
	{
		label: t('breadcrumb.storage'),
		name: 'storage',
		isActive: false,
	},
]

const getTabComponents = ({
	type,
	sortsMap,
	rows,
	allProducts,
	onChange,
	orgDetails,
	loadingStatus,
	t,
}) => ({ name }) => () => {
	const columns =
		name === 'mapIncoming' ? getColumnConfig : getOutgoingColumnConfig

	return (
		<Box style={{ overflow: 'auto', position: 'relative' }}>
			{loadingStatus && <TransparentBgSpinner />}
			<Table
				noRoundedBorder
				columnConfig={columns({
					type,
					docType: name,
					sortsMap: sortsMap[name] || {},
					onChange,
					orgDetails,
					allProducts,
					t,
				})}
				rowData={rows[name] || []}
			/>
			{!(rows[name] || []).length && (
				<Box style={{ minHeight: 300 }}>
					<ErrorBlock
						hideButton
						status="empty"
						message={t('common.errorBlockMessage')}
					/>
				</Box>
			)}
			<CTAWrapper
				onClick={() =>
					onChange('open_listing', {
						type: 'storage',
						docType: name,
					})
				}
			>
				<Label color="inherit" bold small link as="a">
					{t('storage.assignmentHistory').toUpperCase()}
				</Label>
			</CTAWrapper>
		</Box>
	)
}

export const DocumentListing = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [showModal, setShowModal] = useState(false)
	const storageTanks = useSelector(
		StorageCompanyDuc.selectors.getStorageTanksList
	)
	const allProducts = useSelector(AuthDuc.selectors.getProducts)

	const activeTypes = useSelector(StorageCompanyDuc.selectors.getActiveTabs)
	const activeSorts = useSelector(StorageCompanyDuc.selectors.getActiveSorts)
	const activeDocuments = useSelector(
		StorageCompanyDuc.selectors.getActiveDocuments
	)

	const getTabs = useCallback(() => {
		return activeTypes.map(({ code, title }) => ({
			name: code,
			title: t(title),
			IndicatorComponent:
				parseInt(code, 10) > 0 &&
				(({ isActive }) => (
					<DefaultTabIndicator active={isActive}>
						{parseInt(code, 10)}
					</DefaultTabIndicator>
				)),
		}))
	}, [activeTypes, t])
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.STORAGE_COMPANY
				)
			)
		}
	}

	const handleOnChange = useCallback(
		(actionType, meta = {}) => {
			switch (actionType) {
				case 'open_document': {
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types
								.TRADE_DOCUMENT_MANAGER$VIEWWDOCREFERENCE,
							{
								rootModule: meta.row.type,
								documentReference: meta.row.id,
								documentStatus: null,
							},
							{},
							{},
							true
						)
					)

					break
				}

				case 'assign_tank': {
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.STORAGE_COMPANY$SUBROOT,
							{
								rootModule: 'assign-tank',
								action: 'create',
							},
							{
								parentRef: meta.rowId,
							}
						)
					)
					break
				}

				case 'view_tank': {
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.STORAGE_COMPANY$SUBROOT,
							{
								rootModule: 'assign-tank',
								action: 'view',
							},
							{
								parentRef: meta.traceId,
							}
						)
					)
					break
				}

				case 'open_batch': {
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.STORAGE_COMPANY$SUBROOT,
							{
								rootModule: 'map-output',
								action: 'view',
							},

							{
								traceID: meta.row.id,
							}
						)
					)
					break
				}

				case 'generateDO': {
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$WACTION,
							{
								rootModule: 'delivery-order',
								action: 'details',
							},
							{
								parentDocRef: `storage~${meta.row.id}`,
							},
							{},
							true
						)
					)

					break
				}
				case 'open_listing': {
					const { type, docType } = meta || {}
					let subModuleType
					if (docType === 'mapIncoming') {
						subModuleType = 'map-incoming'
					} else {
						subModuleType = 'map-outgoing'
					}
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.STORAGE_COMPANY$SUBMODULELISTING,
							{
								type,
								submodule: subModuleType,
							}
						)
					)
					break
				}
				default:
					break
			}
		},
		[dispatch]
	)

	return (
		<Box padding={8} width="100%" height="100%">
			<StorageInventoryModal
				show={showModal}
				heading={t('production.storageInventory')}
				data={storageTanks || []}
				onClose={() => {
					setShowModal(false)
				}}
				size="800px"
				height="940px"
				overflow="auto"
			/>
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={getBreadCrumbsList(t)}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Box row justifyContent="space-between" alignItems="baseline">
				<Box row style={{ marginTop: '10px' }}>
					<Box>
						<IconWrapper
							size={30}
							onClick={() =>
								dispatch(
									MainRouteDuc.creators.switchPage(
										MainRouteDuc.types.STORAGE_COMPANY
									)
								)
							}
							style={{ cursor: 'pointer' }}
						>
							<Icon glyph={BackArrowIcon} />
						</IconWrapper>
					</Box>
					<Spacer size={8} horizontal />
					<Title
						small
						style={{ marginTop: 0, marginBottom: 0 }}
						title={t('storage.storageAssignment')}
						note={t('storage.assignmentNote')}
					/>
				</Box>
				<Box row justifyContent="flex-end">
					<Box
						padding={2}
						margin="0 12px 0 0"
						title={t('production.viewStorageInventory')}
					>
						<Button
							rounded
							customIcon={
								<Icon
									glyph={StorageTankIcon}
									style={{
										height: 24,
										width: 24,
									}}
								/>
							}
							onClick={() => {
								setShowModal(true)
							}}
							extendStyles={{
								border: '1px solid rgb(63,86,196)',
								borderRadius: 4,
							}}
						/>
					</Box>
					<Button
						label={t('common.recoredOutput')}
						rounded
						onClick={() =>
							dispatch(
								MainRouteDuc.creators.switchPage(
									MainRouteDuc.types.STORAGE_COMPANY$SUBROOT,
									{
										rootModule: 'map-output',
										action: 'create',
									}
								)
							)
						}
					/>
				</Box>
			</Box>
			<Spacer size={24} />
			<Tabs
				tabs={getTabs()}
				tabComponentsMap={getTabComponents({
					type: 'storage',
					sortsMap: activeSorts,
					rows: activeDocuments,
					onChange: handleOnChange,
					allProducts,
					t,
				})}
			/>
			<Box padding="25px 2px" style={{ maxWidth: 150 }}>
				<Button
					label={t('common.back')}
					rounded
					customIcon={
						<ButtonIconWrapper lightBG>
							<Icon glyph={LeftArrowIcon} />
						</ButtonIconWrapper>
					}
					onClick={() =>
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.DASHBOARD
							)
						)
					}
				/>
			</Box>
		</Box>
	)
}
