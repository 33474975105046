import React from 'react'
import styled from 'styled-components'
import { LoaderWrapper, Spinner } from '.'

const Wrapper = styled.div(p => ({
	position: 'absolute',
	width: '100%',
	height: '100%',
	cursor: 'not-allowed',
	opacity: 0.5,
	left: 0,
	top: 0,
	zIndex: p.theme.zIndex.level3,
	background: p.theme.color.white,
}))

const TransparentBgSpinner = ({ lockToScreen }) => {
	return (
		<Wrapper>
			<LoaderWrapper
				lockToScreen={lockToScreen}
				backGroundColor="transparent"
			>
				<Spinner size={30} />
			</LoaderWrapper>
		</Wrapper>
	)
}

export { TransparentBgSpinner }
