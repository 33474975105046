import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn } from 'timm'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AppDuc } from 'core-app/modules/App/duc'
import { AdminDuc } from 'core-app/modules/Admin/duc'
import { CookieDuc } from 'core-app/modules/App/cookieDuc'
import { EmployeeDetailsSegment } from 'core-app/modules/Admin/components/EmployeeDetailsSegment'
import { Box } from 'ui-lib/utils/Box'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Modal } from 'ui-lib/components/Modal'
import { Icon } from 'ui-lib/icons/components/Icon'
import { ButtonIconWrapper, Button } from 'ui-lib/components/Button'
import { P } from 'ui-lib/components/Typography'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import { EmployeeDocPreview } from './EmployeeDocPreview'

const PreviewEmployee = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { query = {} } = useSelector(AdminDuc.selectors.location)
	const employee = useSelector(AdminDuc.selectors.getEmployeeDetails)
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const { original = '', modified = '' } = useSelector(
		AdminDuc.selectors.getEmployeeRole
	)
	const selectedCPID = useSelector(CookieDuc.selectors.getSelectedCPID)
	const employeeUploadList = useSelector(
		AdminDuc.selectors.getEmployeeUploadList
	)

	const updatedEmployeeUploadList = useSelector(
		AdminDuc.selectors.getUpdateEmployeeUploadList
	)

	const [showModal, setShowModal] = useState(false)

	const { firstName, orgRole, primaryContact = {}, meta = {} } = employee
	const { email, mobile } = primaryContact
	const { employeeData = {} } = meta
	const { userID } = query

	// preparing the proper structure so that when user goes from preview to back, the form is prepopulated
	const employeeValues =
		employee?.employeeDoc?.length > 0
			? employee
			: {
					employeeName: firstName,
					designation: getIn(employeeData, ['designation']) || '',
					email,
					mobile,
					employeeID: getIn(employeeData, ['id']) || '',
					dibizRole: orgRole,
			  }

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.empMgmt'),
			name: 'employee-management',
			isActive: true,
		},
		{
			label: t('breadcrumb.previewDetails'),
			name: 'preview-employee',
			isActive: false,
		},
	]

	/** Handlers */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'employee-management') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.ADMIN$SUBROOT,
					{
						rootModule: 'employee-management',
					}
				)
			)
		}
	}

	const getButtonLabelForCreate = () => {
		let text = t('empMgmtHome.assignApplication')
		if (updatedEmployeeUploadList?.employeeArray?.invalidArray.length > 0) {
			text = t('empMgmtHome.title')
		}

		return text
	}

	return (
		<Box padding={8} width="100%" height="100%">
			<Modal
				forceCloseviaButton
				size="large"
				show={showModal}
				heading={t('viewEmployee.confirm')}
				body={
					<P large bold>
						{t('viewEmployee.roleChangeConfMessage')}
					</P>
				}
				closelabel={t('common.no')}
				confirmlabel={t('common.yes')}
				onClose={() => {
					dispatch(
						AdminDuc.creators.setInitialEmployeeDetails(
							employeeValues
						)
					)
					dispatch(
						MainRouteDuc.creators.redirect(
							MainRouteDuc.types.ADMIN$WDOCREFERENCE,
							{
								rootModule: 'employee-management',
								action: 'edit-employee',
								documentReference: userID,
							},
							{
								previewEdit: true,
							}
						)
					)
					setShowModal(false)
				}}
				onConfirm={() => {
					dispatch(
						AdminDuc.creators.addEmployee(
							employee,
							userID,
							t('viewEmployee.empUpdateToast')
						)
					)
					setShowModal(false)
				}}
			/>
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			{employeeUploadList?.length > 0 ? (
				<EmployeeDocPreview
					rowData={employeeUploadList}
					isLoading={false}
					isMobile={isMobile}
				/>
			) : (
				<EmployeeDetailsSegment
					firstName={firstName}
					designation={employeeData.designation}
					email={email}
					mobile={mobile}
					id={employeeData.id}
					orgRole={orgRole}
				/>
			)}
			<Box row justifyContent="space-between" padding="8px 0">
				<Box style={{ maxWidth: 150 }}>
					<Button
						label={t('common.back')}
						rounded
						customIcon={
							<ButtonIconWrapper lightBG>
								<Icon glyph={LeftArrowIcon} />
							</ButtonIconWrapper>
						}
						onClick={() => {
							dispatch(
								AdminDuc.creators.setInitialEmployeeDetails(
									employeeValues
								)
							)
							if (userID) {
								dispatch(
									MainRouteDuc.creators.redirect(
										MainRouteDuc.types.ADMIN$WDOCREFERENCE,
										{
											rootModule: 'employee-management',
											action: 'edit-employee',
											documentReference: userID,
										},
										{
											previewEdit: true,
										}
									)
								)
							} else
								dispatch(
									MainRouteDuc.creators.switchPage(
										MainRouteDuc.types.ADMIN$WACTION,
										{
											rootModule: 'employee-management',
											action: 'add-employee',
										}
									)
								)
						}}
					/>
				</Box>
				{!selectedCPID &&
					!updatedEmployeeUploadList?.employeeArray?.invalidArray
						.length > 0 && (
						<Box>
							<Button
								label={
									userID
										? t('common.update')
										: getButtonLabelForCreate()
								}
								primary
								rounded
								disabled={
									employee?.employeeDoc?.length > 0 &&
									updatedEmployeeUploadList?.employeeArray
										?.validArray.length === 0
								}
								onClick={() => {
									if (userID) {
										if (original !== modified) {
											setShowModal(true)
										} else {
											dispatch(
												AdminDuc.creators.addEmployee(
													employee,
													userID,
													t(
														'viewEmployee.empUpdateToast'
													)
												)
											)
										}
									} else if (
										updatedEmployeeUploadList?.employeeArray
											?.invalidArray.length > 0
									) {
										dispatch(
											MainRouteDuc.creators.switchPage(
												MainRouteDuc.types
													.ADMIN$SUBROOT,
												{
													rootModule:
														'employee-management',
												}
											)
										)
									} else if (!userID) {
										dispatch(
											AdminDuc.creators.addEmployee(
												employee,
												userID,
												t('viewEmployee.empAddToast')
											)
										)
									}
								}}
							/>
						</Box>
					)}
			</Box>
		</Box>
	)
}

export { PreviewEmployee }
