import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MainRouteDuc } from 'core-app/routes/duc'
import { MillDuc } from 'core-app/modules/Mill/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { removeAt } from 'timm'
import { Title } from 'core-app/shared/components/Title'
import { DOSelection } from 'core-app/modules/Mill/components/DOSelection'
import {
	getFFBQualityColumnConfig,
	getCPOQualityColumnConfig,
} from 'core-app/modules/Mill/components/Columns'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import {
	ButtonIconWrapper,
	Button,
	ButtonWithDrop,
} from 'ui-lib/components/Button'
import { Table } from 'ui-lib/components/Table'
import { Modal } from 'ui-lib/components/Modal'
import { ErrorBlock } from 'ui-lib/components/ErrorBlocks'
import { TransparentBgSpinner } from 'ui-lib/components/Spinner/TransparentBgSpinner'
import { Tabs, DefaultTabIndicator } from 'ui-lib/components/Tabs'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Icon } from 'ui-lib/icons/components/Icon'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import QualityIcon from 'ui-lib/icons/quality.svg'

const getTabComponents = ({ rows, onChange, loadingStatus, t }) => ({
	name,
}) => () => {
	const columns =
		name === 'quality-palmoil-ffb'
			? getFFBQualityColumnConfig
			: getCPOQualityColumnConfig

	return (
		<Box style={{ overflow: 'auto', position: 'relative' }}>
			{loadingStatus && <TransparentBgSpinner />}
			<Table
				noRoundedBorder
				columnConfig={columns({
					docType: name,
					rows,
					onChange,
					t,
				})}
				rowData={rows[name] || []}
			/>
			{!(rows[name] || []).length && (
				<Box style={{ minHeight: 300 }}>
					<ErrorBlock
						hideButton
						status="empty"
						message={t('common.errorBlockMessage')}
					/>
				</Box>
			)}
		</Box>
	)
}

const Quality = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const actor = useSelector(AuthDuc.selectors.getActor)
	const isFFBDealer = actor.includes('palmoil_ffbdealer')
	const isSubDealer = actor.includes('palmoil_ffbsubdealer')

	const orgDetails = useSelector(AuthDuc.selectors.getAvailableOrgs)

	const activeDocuments = useSelector(MillDuc.selectors.getQualityList)

	const _activeTypes = useSelector(MillDuc.selectors.getQualityActiveTabs)

	const showModal = useSelector(MillDuc.selectors.getDOSelectionModalStatus)

	const activeTypes =
		isFFBDealer || isSubDealer
			? removeAt(_activeTypes, 1) || []
			: _activeTypes

	const doList = useSelector(MillDuc.selectors.getDOList) || []

	const entityList = doList.map(list => {
		return {
			id: list.id,
			label: list.number,
		}
	})

	const getTabs = useCallback(() => {
		return activeTypes.map(({ code, title }) => ({
			name: code,
			title: t(title),
			IndicatorComponent:
				parseInt(code, 10) > 0 &&
				(({ isActive }) => (
					<DefaultTabIndicator active={isActive}>
						{parseInt(code, 10)}
					</DefaultTabIndicator>
				)),
		}))
	}, [activeTypes, t])

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.quality'),
			name: 'quality',
			isActive: false,
		},
	]

	const handleClose = useCallback(() => {
		dispatch(
			MainRouteDuc.creators.switchPage(MainRouteDuc.types.MILL$SUBROOT, {
				rootModule: 'quality',
			})
		)
		dispatch(MillDuc.creators.toggleDOSelectionPopup(false))
	}, [dispatch])

	const handleConfirm = value => {
		const { entityID } = value

		dispatch(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.MILL$WACTION,
				{
					rootModule: 'quality-ffb',
					action: 'create',
				},
				{
					parentDocRef: entityID,
				}
			)
		)
		dispatch(MillDuc.creators.toggleDOSelectionPopup(false))
	}

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
	}

	const handleChange = useCallback(
		(actionType, meta = {}) => {
			switch (actionType) {
				case 'open_creation_Workflow': {
					const { qualityDocType } = meta || {}

					if (qualityDocType === 'quality-ffb') {
						dispatch(MillDuc.creators.fetchDOList())
					}

					break
				}

				case 'edit_ffb_quality': {
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.MILL$WACTION,
							{
								rootModule: 'quality-ffb',
								action: 'edit',
								attachmentID: meta.row.id,
							},
							{
								documentReference: meta.row.entityID,
							}
						)
					)
					break
				}

				case 'edit_cpo_quality': {
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.MILL$WACTION,
							{
								rootModule: 'quality-cpo',
								action: 'edit',
								attachmentID: meta.row.id,
							},
							{
								documentReference: meta.row.entityID,
							}
						)
					)
					break
				}

				case 'view_cpo_quality_report': {
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.MILL$WACTION,
							{
								rootModule: 'quality-cpo',
								action: 'view',
								attachmentID: meta.row.id,
							},
							{
								documentReference: meta.row.entityID,
							}
						)
					)
					break
				}

				case 'view_ffb_quality_report': {
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.MILL$WACTION,
							{
								rootModule: 'quality-ffb',
								action: 'view',
								attachmentID: meta.row.id,
							},
							{
								documentReference: meta.row.entityID,
							}
						)
					)
					break
				}

				default:
					break
			}
		},
		[dispatch]
	)

	return (
		<Box padding={8} width="100%" height="100%">
			<Modal
				forceCloseviaButton
				show={showModal}
				noPadding
				size="large"
				body={
					<DOSelection
						onClose={handleClose}
						onConfirm={handleConfirm}
						entityList={entityList}
					/>
				}
				hideButtons
				onClose={handleClose}
			/>
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Box row justifyContent="space-between" alignItems="baseline">
				<Title title={t('qualityListing.title')} icon={QualityIcon} />
				<Box justifyContent="flex-end" width={200} padding={2}>
					<ButtonWithDrop
						label={t('qualityListing.createReport').toUpperCase()}
						items={[
							{
								label: t(
									'qualityListing.ffbQuality'
								).toUpperCase(),
								name: 'quality-ffb',
							},
						]}
						onChange={active =>
							handleChange('open_creation_Workflow', {
								qualityDocType: active,
							})
						}
						alignSelf="flex-end"
					/>
				</Box>
			</Box>
			<Spacer size={8} />
			<Tabs
				tabs={getTabs()}
				tabComponentsMap={getTabComponents({
					type: 'quality-reports',
					onChange: handleChange,
					rows: activeDocuments || {},
					orgDetails,
					t,
				})}
			/>
			<Box padding="25px 2px" style={{ maxWidth: 150 }}>
				<Button
					label={t('common.back')}
					rounded
					customIcon={
						<ButtonIconWrapper lightBG>
							<Icon glyph={LeftArrowIcon} />
						</ButtonIconWrapper>
					}
					onClick={() =>
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.DASHBOARD
							)
						)
					}
				/>
			</Box>
		</Box>
	)
}

export { Quality }
