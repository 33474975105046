import { OnboardDuc } from 'core-app/modules/Onboard/duc'
import { ProductsDuc } from 'core-app/modules/Products/duc'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { getIn } from 'timm'
import saga from 'core-app/modules/Onboard/OnboardSaga'

export default async (dispatch, getState, { action, extra }) => {
	const { type, customMeta = {} } = action
	const currentLocationFromAction = getIn(action, [
		'meta',
		'location',
		'current',
	])
	/** if someone specifically wants to skip thunk fetch again, this check helps */
	const { skipRouteThunk } = customMeta
	if (skipRouteThunk) return

	// check if nothing changed in the route.

	const state = getState()

	const locationState = currentLocationFromAction || state.location

	const { payload } = locationState

	extra.getSagaInjectors().injectSaga('ONBOARD', { saga })
	if (
		!(
			payload.action === 'signup' ||
			payload.action === 'signup-detail' ||
			payload.action === 'verify-email' ||
			payload.action === 'set-password'
		)
	) {
		dispatch(AuthDuc.creators.fetchActor())
	}
	const isMainDashboard = MainRouteDuc.types.ONBOARD === type

	const isSubDashboard = MainRouteDuc.types.ONBOARD$ACTION === type

	let activeModule = ''

	if (isMainDashboard) {
		// load the dashboard of the core module
		activeModule = payload.action
		if (payload.action === 'signup-detail') {
			dispatch(AuthDuc.creators.fetchGlobalOrgs())
		}
	} else if (isSubDashboard) {
		activeModule = payload.action
		if (
			payload.action === 'organization' ||
			payload.action === 'business' ||
			payload.action === 'addcerts' ||
			payload.action === 'update-certDetails'
		) {
			dispatch(OnboardDuc.creators.setInitialValues())
		} else if (payload.action === 'carbon-number') {
			dispatch(
				ProductsDuc.creators.fetchProductsListingWithPagination({}, 20)
			)
		}
	}

	dispatch(OnboardDuc.creators.setActiveModule(activeModule))
}
