import Duck from 'extensible-duck'

const initialState = {}

export const UploadContentDuc = new Duck({
	namespace: 'uploadContent',
	store: 'global',
	types: ['UPLOAD_DOCUMENT_FILE'],
	initialState,
	reducer: state => {
		return state
	},
	selectors: {
		auth: state => state.auth,
		location: state => state.location,
	},
	creators: duck => ({
		uploadDocumentFile: (uploadPayload, successMsg) => ({
			type: duck.types.UPLOAD_DOCUMENT_FILE,
			uploadPayload,
			successMsg,
		}),
	}),
})
