import React, { useState, useRef, useEffect } from 'react'
import ReactMapGL, {
	WebMercatorViewport,
	NavigationControl,
} from 'react-map-gl'
import * as turf from '@turf/turf'
import distance from '@turf/distance'
import { getMapBoxAccessToken } from '../../config'
import './styles.css'

const TracePolygonMaps = ({ shape = {}, geoType = '', height }) => {
	const { tilesetId, tilesetName, bounds } = shape

	const getCircleData = () => {
		const options = {
			steps: 64,
			units: 'kilometers',
		}
		let radius = 0
		shape.coordinates.forEach(item => {
			const coorDistance = distance(shape.center, item)
			if (coorDistance > radius) {
				radius = coorDistance
			}
		})

		return turf.circle(shape.center, radius, options)
	}

	const dimensions = {
		// eslint-disable-next-line no-restricted-globals
		width: screen.width,
		// eslint-disable-next-line no-restricted-globals
		height,
	}

	let zoomDifference = 8
	let coordinates = [
		[0, 0],
		[0, 0],
	]

	if (geoType === 'coordinates') {
		const circleData = getCircleData()
		// eslint-disable-next-line prefer-destructuring
		coordinates = circleData.geometry.coordinates[0]
	} else if (
		geoType === 'shapefile' &&
		bounds &&
		bounds[0] &&
		bounds[1] &&
		bounds[2] &&
		bounds[3]
	) {
		// eslint-disable-next-line prefer-destructuring
		coordinates = [
			[bounds[0], bounds[1]],
			[bounds[2], bounds[3]],
		]
		zoomDifference = 2
	}

	const _viewport = new WebMercatorViewport(dimensions)
	const { longitude, latitude, zoom } = _viewport.fitBounds(coordinates, {
		padding: 20,
	})

	const [viewport, setViewport] = useState({
		...dimensions,
		longitude,
		latitude,
		zoom: zoom - zoomDifference,
	})

	const setShapeViewPort = async () => {
		const response = await fetch(
			`https://api.mapbox.com/v4/${tilesetId}.json?secure&access_token=${getMapBoxAccessToken()}`
		)
		const data = await response.json()
		if (data) {
			if (
				data.bounds &&
				data.bounds[0] &&
				data.bounds[1] &&
				data.bounds[2] &&
				data.bounds[3]
			) {
				const currentcoordinates = [
					[data.bounds[0], data.bounds[1]],
					[data.bounds[2], data.bounds[3]],
				]
				const _currentviewport = new WebMercatorViewport(dimensions)
				const currentdata = _currentviewport.fitBounds(
					currentcoordinates,
					{
						padding: 20,
					}
				)
				// const [layerData, setLayerData] = useState({})
				setViewport({
					...dimensions,
					latitude: currentdata.latitude,
					longitude: currentdata.longitude,
					zoom: currentdata.zoom - 2,
				})
			}
		}
	}

	const mapRef = useRef(null)

	const addPolygon = map => {
		const id = 'polygon-maps'
		if (geoType === 'shapefile') {
			map.addLayer({
				id,
				type: 'fill',
				source: {
					type: 'vector',
					url: `mapbox://${tilesetId}`, //  Mapbox tileset Map ID
				},
				'source-layer': tilesetName, // name of tilesets
				paint: {
					'fill-color': '#90EE90',
					'fill-opacity': 0.5,
					'fill-outline-color': 'yellow',
				},
			})
		}

		if (geoType === 'coordinates') {
			map.addSource(id, {
				type: 'geojson',
				data: getCircleData(),
			})

			// Add a new layer to visualize the polygon.
			map.addLayer({
				id,
				type: 'fill',
				source: id, // reference the data source
				layout: {},
				paint: {
					'fill-color': '#90EE90', // color fill
					'fill-opacity': 0.5,
				},
			})
			// Add a outline around the polygon.
			map.addLayer({
				id: 'outline',
				type: 'line',
				source: id,
				layout: {},
				paint: {
					'line-width': 1,
					'line-color': 'yellow',
				},
			})
		}
	}

	const onMapLoad = React.useCallback(() => {
		const map = mapRef.current.getMap()
		const img = new Image(15, 15)
		img.onload = () => map.addImage('arrowIcon', img)
		img.src =
			"data:image/svg+xml,%3Csvg viewBox='0 0 1024 1024' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M529.6128 512L239.9232 222.4128 384.7168 77.5168 819.2 512 384.7168 946.4832 239.9232 801.5872z' p-id='9085' fill='%23FFFFFF'%3E%3C/path%3E%3C/svg%3E"
		addPolygon(map)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shape])

	const myRef = useRef(null)
	const executeScroll = () => myRef.current.scrollIntoView()

	useEffect(() => {
		executeScroll()
	}, [])

	useEffect(() => {
		if (geoType === 'shapefile' && !bounds) {
			setShapeViewPort()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div ref={myRef} style={{ display: 'block' }}>
			<ReactMapGL
				width="100vw"
				height="82vh"
				ref={mapRef}
				onLoad={onMapLoad}
				mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
				mapboxApiAccessToken={getMapBoxAccessToken()}
				{...viewport}
				onViewportChange={setViewport}
			>
				<div
					style={{
						width: '30px',
					}}
				>
					<NavigationControl
						showCompass={false}
						position="top-left"
					/>
				</div>
			</ReactMapGL>
		</div>
	)
}

TracePolygonMaps.defaultProps = {
	shape: {},
}

export { TracePolygonMaps }
