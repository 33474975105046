export const initialState = {
	activeModule: '',
	mainDashboardListing: {
		partnerAcceptModalStatus: false,
		partnerDeclineModalStatus: false,
	},
	viewPartner: {
		viewPageAcceptModalStatus: false,
		viewPageDeclineModalStatus: false,
		deletePartnerModalStatus: false,
		sendPartnerRequestModalStatus: false,
		partnerStatus: '',
	},
	invitePartner: {
		invitePartnerSuccessModalStatus: false,
	},
	partnerDetails: {
		partnerInfo: {},
		certificateDetails: [],
	},
	tabsConfig: [
		{
			code: 'partners',
			title: 'partnerHome.partners',
		},
		{
			code: 'partnerships',
			title: 'partnerHome.pendingRequest',
		},
		{
			code: 'referralList',
			title: 'partnerHome.referralList',
		},
		{
			code: 'globalPartnersList', // excel upload
			title: 'partnerHome.globalPartnersList',
		},
	],
	partnersListing: [],
	activeFilter: [],
	supplyBase: [],
	referralList: [],
	globalPartnersList: [],
	partnershipTimeline: [],
	farmCertificates: [],
}
export const SUPPLYBASE_MEMBERS_TAB_CONFIG = {
	code: 'supplybaseMembers',
	title: 'partnerHome.supplybaseMembers',
}
export const INITIAL_TYPES = ['partners', 'partnerships']

export const TABLE_ALIAS = {
	partner: 'partnerHome.partnerName',
	location: 'partnerHome.location',
	inviteStatus: 'partnerHome.inviteStatus',
	status: 'partnerHome.status',
	action: 'partnerHome.chooseAction',
	orgType: 'partnerHome.orgType',
	update: 'partnerHome.update',
}
