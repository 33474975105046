export const SortableKeys = ['createdAt']

export const TABLE_ALIAS = {
	createdAt: 'production.createdAt',
	cpoBatchReference: 'production.cpoBatchReference',
	ffa: 'production.ffa',
	dobi: 'production.dobi',
	mni: 'production.mni',
	storageTank: 'production.storageTank',
	consignmentNumber: 'production.consignmentNumber',
	ripe: 'production.ripe',
	underRipe: 'production.underRipe',
	overRipe: 'production.overRipe',
	empty: 'production.empty',
	batchReference: 'production.batchReference',
	totalFfb: 'Total FFB',
	productionLine: 'production.productionLine',
	palmKernels: 'Palm Kernels',
	batchDetails: 'production.batchDetails',
	quantityUsed: 'production.quantityUsed',
	availableUsed: 'production.availableUsed',
	totalQuantity: 'production.totalQuantity',
	product: 'production.product',
	quantity: 'production.quantity',
	producedQuantity: 'production.producedQuantity',
	assignStorage: 'production.assignStorage',
	assignTank: 'production.assignTank',
	assignedTank: 'production.assignedTank',
	inputMaterial: 'production.inputMaterial',
	inputBatchReference: 'production.inputBatchReference',
	outputBatchReference: 'production.outputBatchReference',
	inputBatchDetails: 'production.inputBatchDetails',
	outputBatchDetails: 'production.outputBatchDetails',
	outputProduct: 'production.outputProduct',
	sourcedQuantity: 'production.sourcedQuantity',
}

export const NAME_ALIASES = {
	transformimg: 'production.inputBatch',
	transformed: 'production.outputBatch',
	create: 'common.create',
	edit: 'common.edit',
	view: 'common.viewBtn',
}

export const TRACE_GROUP_STATUS = ['transforming', 'transformed']

export const QUALITY_INITIAL_TYPES = [
	'quality-palmoil-ffb',
	'quality-palmoil-cpo',
]

export const PRODUCTION_INITIAL_TYPES = [
	'production-plot-input',
	'production-plot-output',
]

export const LabelsMap = {
	'produced-batch': 'production.outputBatch',
	'sourced-batch': 'production.inputBatch',
}

export const initialState = {
	activeModule: 'plantation',
	loading: false,
	error: false,
	insightsData: {},
	modules: {
		quality: {
			listing: {
				activeDocuments: {},
				tabsConfig: [
					{
						code: 'quality-palmoil-ffb',
						title: 'qualityListing.ffbQuality',
					},
					{
						code: 'quality-palmoil-cpo',
						title: 'qualityListing.cpoQuality',
					},
				],
			},
			ffbQuality: {
				entityInfoforFFBReport: {},
				initialFormValues: {
					ripeBunches: 0,
					overripeBunches: 0,
					underripeBunches: 0,
					emptyBunches: 0,
					wetBunches: 0,
					unripeBunches: 0,
				},
				modalStatusDOSelection: false,
				entityList: [],
				ffbReport: {},
			},
			cpoQuality: {
				initialFormValues: {
					ffa: '',
					dobi: '',
					cpoBatchReference: '',
					mniValue: '',
					acidity: '',
					humidity: '',
					impurities: '',
					meltingPoint: '',
					iodineIndex: '',
					colour: '',
				},
				cpoReport: {},
				entityInfo: {},
			},
		},
		production: {
			loading: false,
			activeTimeOffset: 5,
			storageTanks: [],
			allTypes: PRODUCTION_INITIAL_TYPES,
			activeSorts: {},
			activeDocuments: {
				plotInput: {},
				plotOutput: {},
				plotInputConsignments: [],
			},
			activeRecords: {},
			tabsConfig: [
				{
					code: 'transforming',
					title: 'production.inputBatch',
				},
				{
					code: 'transformed',
					title: 'production.outputBatch',
				},
			],
			createPlotInput: {
				pagination: {
					activeIndex: 0, // total, pageCount
					limit: 0,
					total: 0,
					nextCursor: '',
				},
			},
		},
		listing: {
			loading: false,
			pagination: {
				activeIndex: 0, // total, pageCount
				limit: 0,
				total: 0,
				nextCursor: '',
			},
			activeDocuments: [],
			activeSorts: {},
			activeFilters: {},
		},
	},
}
