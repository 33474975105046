import React from 'react'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MainRouteDuc } from 'core-app/routes/duc'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import BackArrowIcon from 'ui-lib/icons/arrow-back.svg'
import { Title } from 'core-app/shared/components/Title'
import { Spacer } from 'ui-lib/utils/Spacer'
import { BoxShadowTable } from 'core-app/shared/components/BoxShadowTable'
import { Label } from 'ui-lib/components/Typography'
import { getMapOutputPreview } from 'core-app/modules/StorageCompany/components/Columns'
import { Box } from 'ui-lib/utils/Box'
import { StorageCompanyDuc } from '../../duc'

const getBreadCrumbsList = t => [
	{
		label: t('breadcrumb.home'),
		name: 'home',
		isActive: true,
	},
	{
		label: t('breadcrumb.storageAssignment'),
		name: 'storageAssignment',
		isActive: true,
	},
	{
		label: t('breadcrumb.details'),
		name: 'mapOutput',
		isActive: false,
	},
]

export const ViewMapOutput = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const allTanks = useSelector(AuthDuc.selectors.getTanks)

	const allProducts = useSelector(AuthDuc.selectors.getProducts)
	const outgoingBatch = useSelector(
		StorageCompanyDuc.selectors.getOutgoingBatch
	)

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.STORAGE_COMPANY
				)
			)
		} else if (target === 'storageAssignment') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.STORAGE_COMPANY$LISTING
				)
			)
		}
	}

	return (
		<Box padding={8} width="100%" height="100%">
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={getBreadCrumbsList(t)}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Box row justifyContent="space-between" alignItems="baseline">
				<Box row style={{ marginTop: '10px' }}>
					<Box>
						<IconWrapper
							size={30}
							onClick={() =>
								dispatch(
									MainRouteDuc.creators.switchPage(
										MainRouteDuc.types
											.STORAGE_COMPANY$LISTING
									)
								)
							}
							style={{ cursor: 'pointer' }}
						>
							<Icon glyph={BackArrowIcon} />
						</IconWrapper>
					</Box>
					<Spacer size={8} horizontal />
					<Title
						small
						style={{ marginTop: 0, marginBottom: 0 }}
						title={t('storage.details')}
						note={t('storage.outgoingDetails')}
					/>
				</Box>
			</Box>

			<Spacer size={24} />
			<Box row>
				<Label>{t('storage.showingDetails')} : </Label>
				<Spacer size={12} />
				<Label bold>{outgoingBatch.batchReference}</Label>
			</Box>
			<Spacer size={24} />

			<BoxShadowTable
				size="large"
				columnConfig={getMapOutputPreview({
					rootModule: 'storage-assignment',
					allTanks,
					allProducts,
					t,
				})}
				rowData={outgoingBatch.outputTrace}
			/>
		</Box>
	)
}
