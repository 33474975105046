import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { AppDuc } from 'core-app/modules/App/duc'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AdminDuc } from 'core-app/modules/Admin/duc'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Modal } from 'ui-lib/components/Modal'
import { Button } from 'ui-lib/components/Button'
import { Title } from 'core-app/shared/components/Title'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { Box } from 'ui-lib/utils/Box'
import theme from 'ui-lib/utils/base-theme'
import backArrow from 'ui-lib/icons/arrow_back.svg'

const WebhookApiSettings = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const webhookApiKey = useSelector(AdminDuc.selectors.getWebhookApiKey)

	const [showConfirmationModal, setShowConfirmationModal] = useState(false)

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.admin'),
			name: 'admin',
			isActive: true,
		},
		{
			label: `${t('common.view')} ${t('adminHome.webhookApiSettings')}`,
			name: 'view-webhook-api-settings',
			isActive: false,
		},
	]

	/** Handlers */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'admin') {
			dispatch(MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN))
		}
	}

	return (
		<Box id="input-otherSettings" padding={8} width="100%" height="100%">
			<Modal
				forceCloseviaButton
				size="large"
				show={showConfirmationModal}
				heading={t('viewEmployee.confirm')}
				body={t('tdmDetailsEntry.deleteConfirmation')}
				closelabel={t('common.no')}
				confirmlabel={t('common.yes')}
				onClose={() => setShowConfirmationModal(false)}
				onConfirm={() => {
					dispatch(
						AdminDuc.creators.deleteWebhookApiKey(
							t('toast.deleteSuccess')
						)
					)
					setShowConfirmationModal(false)
				}}
			/>
			<Breadcrumb
				links={breadCrumbs}
				onClick={target => handleBreadCrumbClick(target)}
			/>
			<Box row alignItems="center">
				<IconWrapper
					size={35}
					style={{ color: theme.color.blue5, cursor: 'pointer' }}
					onClick={() => {
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.ADMIN
							)
						)
					}}
				>
					<Icon glyph={backArrow} />
				</IconWrapper>
				<Box style={{ marginLeft: '25px' }}>
					<Title title={t('adminHome.webhookApiSettings')} />
				</Box>
			</Box>
			<Spacer size={20} />
			{!webhookApiKey && (
				<Box
					id="generate-api"
					justifyContent="flex-end"
					style={{
						flexDirection: 'row',
					}}
				>
					<Button
						label={t('adminHome.generateApi')}
						extendStyles={{
							fontSize: isMobile && theme.fontSize.m,
							padding: isMobile && '8px',
						}}
						primary
						rounded
						onClick={() => {
							dispatch(
								AdminDuc.creators.generateWebhookApiKey(
									t('toast.createSuccess')
								)
							)
						}}
					/>
				</Box>
			)}
			{webhookApiKey && (
				<Box
					style={{
						padding: '25px 25px',
						backgroundColor: theme.color.white,
						border: `1px solid ${theme.color.grey23}`,
					}}
				>
					<Box style={{ wordWrap: 'break-word' }}>
						{webhookApiKey}
					</Box>
					<Box
						justifyContent="flex-end"
						style={{
							margin: '20px 0',
							flexDirection: 'row',
						}}
					>
						<Button
							label={t('adminHome.copy')}
							extendStyles={{
								width: 100,
								margin: '0 5px',
								fontSize: isMobile && theme.fontSize.m,
								padding: isMobile && '8px',
							}}
							rounded
							onClick={() => {
								// Copy the text inside the text field
								navigator.clipboard.writeText(webhookApiKey)
							}}
						/>
						<Button
							label={t('adminHome.revoke')}
							extendStyles={{
								width: 120,
								color: '#FFF',
								borderColor: '#dd2b0e',
								backgroundColor: '#dd2b0e',
								boxShadow: 'inset 0 0 0 1px #c91c00',
								fontSize: isMobile && theme.fontSize.m,
								padding: isMobile && '8px',
							}}
							rounded
							onClick={() => {
								setShowConfirmationModal(true)
							}}
						/>
					</Box>
				</Box>
			)}
		</Box>
	)
}

export { WebhookApiSettings }
