import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn } from 'timm'
import { AppDuc } from 'core-app/modules/App/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { MainRouteDuc } from 'core-app/routes/duc'
import { DealerDuc } from 'core-app/modules/Dealer/duc'
import { KeyValueSegment } from 'core-app/shared/components/KeyValueSegment'
import { Title } from 'core-app/shared/components/Title'
import { getDateByFormat } from 'core-app/utils/date'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { BoxShadowWrapper } from 'ui-lib/components/Card'
import { Button, ButtonIconWrapper } from 'ui-lib/components/Button'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Icon } from 'ui-lib/icons/components/Icon'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'

const ViewWork = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const activeLocale = useSelector(AppDuc.selectors.activeLocale)
	const partners = useSelector(AuthDuc.selectors.getTargetPartnersOfUser)

	const locationState = useSelector(DealerDuc.selectors.location)
	const { payload = {} } = locationState
	const { rootModule } = payload

	const workInfo = useSelector(DealerDuc.selectors.getWorkInformation) || {}
	const {
		date,
		ffbCount,
		organizationID,
		ffbDetails = {},
		meta = {},
	} = workInfo
	const {
		location = {},
		ripeBunches,
		overripeBunches,
		underripeBunches,
		emptyBunches,
		wetBunches,
		unripe,
	} = ffbDetails
	const { area, block } = location
	const { harvesterCount } = meta

	const partner = partners.filter(p => p.id === organizationID)
	const partnerName = getIn(partner, [0, 'name']) || '---'

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.workListing'),
			name: 'work',
			isActive: true,
		},
		{
			label: t('breadcrumb.viewWorkDetails'),
			name: 'view-work',
			isActive: false,
		},
	]

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'work') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.DEALER$SUBROOT,
					{
						rootModule,
					}
				)
			)
		}
	}

	return (
		<Box>
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Box
				row
				width="auto"
				alignItems="baseline"
				justifyContent="space-between"
			>
				<Title title={t('previewWork.workEntry')} />
			</Box>
			<Spacer size={8} />
			<BoxShadowWrapper
				style={{
					width: isMobile ? 325 : 'auto',
				}}
			>
				<Box padding={20}>
					<KeyValueSegment
						name={t('viewWork.createdOn')}
						description={
							getDateByFormat(date, null, activeLocale) || '---'
						}
					/>
					<KeyValueSegment
						name={t('viewWork.smallholder')}
						description={partnerName}
					/>
					<KeyValueSegment
						name={t('viewWork.harvesterCount')}
						description={harvesterCount || '---'}
					/>
					<KeyValueSegment
						name={t('viewWork.ffbCount')}
						description={ffbCount || '---'}
					/>
					<KeyValueSegment
						name={t(t('viewWork.area'))}
						description={area || '---'}
					/>
					<KeyValueSegment
						name={t('viewWork.block')}
						description={block || '---'}
					/>
				</Box>
				<Box style={{ borderTop: '1px solid #ddd' }}>
					<Box
						row
						style={{
							flexWrap: 'wrap',
							paddingBottom: '16px',
							paddingTop: '16px',
							paddingLeft: 15,
							paddingRight: 60,
						}}
					>
						<Box padding="0 12px 0 0">
							<KeyValueSegment
								name={t('viewWork.ripe')}
								description={ripeBunches}
							/>

							<KeyValueSegment
								name={t('viewWork.overripe')}
								description={overripeBunches}
							/>
							<KeyValueSegment
								name={t('viewWork.underripe')}
								description={underripeBunches}
							/>
							<KeyValueSegment
								name={t('viewWork.unripe')}
								description={unripe}
							/>
							<KeyValueSegment
								name={t('viewWork.empty')}
								description={emptyBunches}
							/>
							<KeyValueSegment
								name={t('viewWork.wet')}
								description={wetBunches}
							/>
						</Box>
					</Box>
				</Box>
			</BoxShadowWrapper>
			<Box padding="25px 0" style={{ maxWidth: 150 }}>
				<Button
					label={t('common.back')}
					rounded
					customIcon={
						<ButtonIconWrapper lightBG>
							<Icon glyph={LeftArrowIcon} />
						</ButtonIconWrapper>
					}
					onClick={() =>
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.DEALER
							)
						)
					}
				/>
			</Box>
		</Box>
	)
}

export { ViewWork }
