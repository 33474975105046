import React, { useMemo, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { removeLast, getIn } from 'timm'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AppDuc } from 'core-app/modules/App/duc'
import { PlantationDuc, LabelsMap } from 'core-app/modules/Plantation/duc'
import { BoxShadowTable } from 'core-app/shared/components/BoxShadowTable'
import { Title } from 'core-app/shared/components/Title'
import { KeyValueSegment } from 'core-app/shared/components/KeyValueSegment'
import { getAssignedTicketColumnConfig } from 'core-app/modules/Plantation/components/Columns'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { BoxShadowWrapper } from 'ui-lib/components/Card'
import { Button, ButtonIconWrapper } from 'ui-lib/components/Button'
import { Modal } from 'ui-lib/components/Modal'
import { P } from 'ui-lib/components/Typography'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { chunkArrayInGroups } from 'ui-lib/utils/helpers'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import VerifiedIcon from 'ui-lib/icons/verified.svg'

const getPairsAndOddOne = arr => {
	let targetArr = arr
	let lastOdd = null
	if (targetArr.length % 2 > 0) {
		lastOdd = targetArr[targetArr.length - 1]
		targetArr = removeLast(targetArr)
	}

	return { pairs: chunkArrayInGroups(targetArr, 2), lastOdd }
}

const ALIASES_KEYS = {
	name: 'Bin Label',
	numberOfTickets: 'Number of Tickets',
	quantity: 'Quanity',
}

const { pairs, lastOdd } = getPairsAndOddOne(Object.keys(ALIASES_KEYS))

const PreviewBinBlock = ({ values, isMobile, binName }) => {
	return (
		<>
			<Box
				justifyContent="space-between"
				row={!isMobile}
				padding="8px 0 0"
			>
				{pairs.map((pair, index) => (
					<>
						<Box>
							{pair.map(_key => {
								return _key === 'name' ? (
									<KeyValueSegment
										name={ALIASES_KEYS[_key]}
										description={binName}
									/>
								) : (
									<KeyValueSegment
										name={ALIASES_KEYS[_key]}
										description={values[_key]}
									/>
								)
							})}
						</Box>
						{index % 2 === 0 && index !== pairs.length && (
							<Spacer size={20} horizontal />
						)}
					</>
				))}
			</Box>
			{lastOdd && (
				<Box>
					<KeyValueSegment
						name={ALIASES_KEYS[lastOdd]}
						description={values[lastOdd]}
					/>
				</Box>
			)}
		</>
	)
}

export const PreviewBin = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const locationState = useSelector(PlantationDuc.selectors.location)
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const isLoading = useSelector(
		PlantationDuc.selectors.getListingLoadingStatus
	)
	const assets = useSelector(AuthDuc.selectors.getOrgAssets)

	const binList = getIn(assets, ['storageunits']) || []

	const binInformation = useSelector(
		PlantationDuc.selectors.fetchBinInformation
	)
	const bin = binList.filter(_bin => _bin.id === binInformation.name)
	const binName = getIn(bin, [0, 'name']) || 'NA'
	const { payload = {} } = locationState
	const { rootModule } = payload
	const title = LabelsMap[rootModule]

	const [binCompletionModalStatus, setModalStatus] = useState(false)

	const getBreadCrumbsList = useCallback(
		() => [
			{
				label: t('breadcrumb.home'),
				name: 'home',
				isActive: true,
			},
			{
				label:
					rootModule === 'document'
						? t('breadcrumb.records')
						: t('breadcrumb.bins'),
				name: rootModule,
				isActive: true,
			},
			{
				label: t('breadcrumb.viewBin'),
				name: 'view-bin',
				isActive: false,
			},
		],
		[rootModule, t]
	)

	const breadCrumbs = useMemo(() => getBreadCrumbsList(title, rootModule), [
		title,
		rootModule,
		getBreadCrumbsList,
	])
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === rootModule) {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.PLANTATION$SUBROOT,
					{ rootModule }
				)
			)
		}
	}
	const { isSynced } = binInformation

	return (
		<Box padding={8} width="100%" height="100%">
			<Modal
				forceCloseviaButton
				show={binCompletionModalStatus}
				heading={t('previewBin.modalHeading')}
				body={
					<P large bold>
						{t('previewBin.modalText')}
					</P>
				}
				closelabel={t('common.no')}
				confirmlabel={t('common.yes')}
				onClose={() => {
					setModalStatus(false)
				}}
				onConfirm={() => {
					dispatch(
						PlantationDuc.creators.markedBin(binInformation.id)
					)
					setModalStatus(false)
				}}
			/>
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>

			<Box row justifyContent="space-between" alignItems="baseline">
				<Title title={t('previewBin.title')} />
			</Box>

			<BoxShadowWrapper>
				<Box
					padding="4px 15px"
					style={{
						borderBottom: '1px solid #E6E6E6',
						boxShadow:
							'0 0 10px rgba(0,0,0,0.05), 0 0px 10px rgba(0,0,0,0.08)',
					}}
				>
					{isSynced && (
						<Box style={{ flex: 1 }} alignItems="flex-end">
							<IconWrapper size={25} color="#13835A">
								<Icon glyph={VerifiedIcon} />
							</IconWrapper>
						</Box>
					)}
					<PreviewBinBlock
						values={binInformation}
						isMobile={isMobile}
						binName={binName}
					/>
				</Box>
			</BoxShadowWrapper>
			<Box row justifyContent="space-between" alignItems="baseline">
				<Title title={t('previewBin.assignedTicket')} />
			</Box>
			<BoxShadowTable
				isLoading={isLoading}
				size="large"
				columnConfig={getAssignedTicketColumnConfig({ t })}
				rowData={binInformation.tickets}
			/>
			<Box padding="25px 0" row justifyContent="space-between">
				<Box padding={2} style={{ maxWidth: 150 }}>
					<Button
						label={t('common.back')}
						rounded
						customIcon={
							<ButtonIconWrapper lightBG>
								<Icon glyph={LeftArrowIcon} />
							</ButtonIconWrapper>
						}
						onClick={() =>
							dispatch(
								MainRouteDuc.creators.switchPage(
									MainRouteDuc.types.PLANTATION$SUBROOT,
									{ rootModule, type: 'tractorOperator' }
								)
							)
						}
					/>
				</Box>
				{rootModule === 'bin' && (
					<Box row={!isMobile} style={{ padding: 2 }}>
						{isMobile && <Spacer size={16} />}
						<Button
							label={
								binInformation.binComplete
									? t('previewBin.alreadyMarkAsComplete')
									: t('previewBin.markAsComplete')
							}
							primary
							rounded
							disabled={binInformation.binComplete}
							onClick={() => setModalStatus(true)}
						/>
					</Box>
				)}
			</Box>
		</Box>
	)
}
