/* @flow */
// $FlowFixMe
import cogoToast from 'cogo-toast'
import type { Node } from 'react'
import baseTheme from '../../utils/base-theme'

type Params = {
	delay?: number,
	type: 'error' | 'warn' | 'info' | 'success',
	position?: string,
	customHeading?: string,
	message: string | Node,
	isMobile?: boolean,
	onClose: () => void,
}

const getBarStyles = type => ({
	size: '3px',
	style: 'solid',
	color: baseTheme.color[type] || baseTheme.color.primary,
})

const defaultAlias = {
	success: 'Success',
	error: 'Error',
	info: 'Information',
	warn: 'Warning',
}

export const Toast = ({
	delay = 5,
	customHeading,
	type,
	message,
	onClose = () => {},
}: Params) => {
	const position = 'bottom-center' // isMobile ? 'bottom-center' : 'bottom-center'
	const bar = getBarStyles(type)
	let closed = false

	const heading = customHeading || defaultAlias[type] || ''
	const trigger = cogoToast[type]
	if (trigger) {
		const promise = trigger(message, {
			heading,
			onClick: () => {
				promise.hide()
				if (!closed) {
					closed = true
					onClose()
				}
			},
			bar,
			hideAfter: delay,
			position,
		})
		promise.then(() => {
			if (!closed) {
				closed = true
				onClose()
			}
		})
	} else {
		closed = true
		onClose()
	}
}
