import React, { useEffect, useState } from 'react'

import theme from '../../utils/base-theme'
import { Button } from '../Button'
import { Box } from '../../utils/Box'

const ButtonGroup = ({
	buttons = [],
	onChange = () => {},
	flexDirection = 'row',
}) => {
	const [data, setData] = useState(buttons)
	const [clickedIds, setClickedIds] = useState([])
	const [selectedItems, setSelectedItems] = useState([])

	useEffect(() => {
		setData(buttons)
	}, [buttons])

	const onSelect = button => {
		const isExist = selectedItems.find(item => item.value === button.value)
		if (isExist) {
			const newItem = selectedItems.filter(
				item => item.value !== button.value
			)
			setSelectedItems(newItem)
			setClickedIds(clickedIds.filter(item => item !== button.value))
			onChange(newItem)
		} else {
			const newItem = [...selectedItems, button]
			setSelectedItems(newItem)
			setClickedIds(oldArray => [...oldArray, button.value])
			onChange(newItem)
		}
	}

	return (
		<Box
			style={{
				flexDirection,
			}}
		>
			{data.map(btn => (
				<Button
					label={btn.label}
					key={btn.value}
					name={btn.label}
					onClick={() => onSelect(btn)}
					extendStyles={{
						background: clickedIds.includes(btn.value)
							? theme.color.primary
							: 'none',
						color: clickedIds.includes(btn.value)
							? theme.color.white
							: theme.color.primary,
						margin: flexDirection === 'row' ? '2px 6px' : '6px 2px',
						padding: '2px 8px',
						fontSize: '14px',
						flex: 1,
					}}
				/>
			))}
		</Box>
	)
}

export { ButtonGroup }
