import React, { useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { addLast, getIn, removeAt, replaceAt } from 'timm'
import { Stepper } from 'react-form-stepper'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import {
	stepStyles,
	connectorStyles,
} from 'core-app/modules/Onboard/containers/NewSignup/AddressBlock'
import { OnboardDuc } from 'core-app/modules/Onboard/duc'
import { Grommet, CheckBox } from 'grommet'
import { Table } from 'ui-lib/components/Table'
import { H2, P, Label } from 'ui-lib/components/Typography'
import { Button } from 'ui-lib/components/Button'
import { ErrorBlock } from 'ui-lib/components/ErrorBlocks'
import { TransparentBgSpinner } from 'ui-lib/components/Spinner/TransparentBgSpinner'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import theme from 'ui-lib/utils/base-theme'
import { MandatoryProducts } from '../../config'

export const customCheckBoxTheme = {
	checkBox: {
		color: '#13835A',
		check: {
			thickness: '2px',
		},
	},
}

const columnConfig = ({ onChange, profiles, actor }) => {
	return [
		{
			property: 'product',
			render: datum => {
				const { code, name, id } = datum

				const profile = profiles.filter(prof => prof.id === id)
				const isMandatory = (MandatoryProducts[actor] || []).find(
					prof => prof === code
				)
				const mandatoryChecked = !!isMandatory
				const isChecked =
					getIn(profile, [0, 'isBuyer']) === true ||
					getIn(profile, [0, 'isSeller']) === true ||
					mandatoryChecked

				return (
					<Label
						as="span"
						style={{
							fontSize: 16,
							color: isChecked ? '#13835A' : '#4B4552',
						}}
					>{`${name} (${code})`}</Label>
				)
			},
			size: '60%',
		},
		{
			property: 'buyer',
			header: (
				<Box center style={{ cursor: 'pointer' }}>
					<Label bold style={{ color: '#4B4552', fontSize: 16 }}>
						Buy
					</Label>
				</Box>
			),
			render: datum => {
				const { id, code } = datum
				const product = profiles.filter(prof => prof.id === id)
				const isMandatory = (MandatoryProducts[actor] || []).find(
					prof => prof === code
				)

				return (
					<Box center>
						<Grommet theme={customCheckBoxTheme}>
							<CheckBox
								defaultChecked={!!isMandatory}
								disabled={!!isMandatory}
								onChange={e => {
									onChange('toggle_trade_access', {
										id,
										isBuyer: e.target.checked,
										isSeller: getIn(product, [
											0,
											'isSeller',
										]),
									})
								}}
							/>
						</Grommet>
					</Box>
				)
			},
		},
		{
			property: 'seller',
			header: (
				<Box center style={{ cursor: 'pointer' }}>
					<Label bold style={{ color: '#4B4552', fontSize: 16 }}>
						Sell
					</Label>
				</Box>
			),
			render: datum => {
				const { id, code } = datum

				const product = profiles.filter(prof => prof.id === id)
				const isMandatory = (MandatoryProducts[actor] || []).find(
					prof => prof === code
				)

				return (
					<Box center>
						<Grommet theme={customCheckBoxTheme}>
							<CheckBox
								defaultChecked={!!isMandatory}
								disabled={!!isMandatory}
								onChange={e => {
									onChange('toggle_trade_access', {
										id,
										isSeller: e.target.checked,
										isBuyer: getIn(product, [0, 'isBuyer']),
									})
								}}
							/>
						</Grommet>
					</Box>
				)
			},
		},
	]
}

const ProductBlock = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const products = useSelector(AuthDuc.selectors.getProducts)
	const actor = useSelector(AuthDuc.selectors.getActor)[0] || []

	const [profiles, setProfiles] = useState([])

	const disableCTA = profiles.length !== 0

	// Adding madatory product for a specific org type
	useEffect(() => {
		const mandatoryProduct = (products || []).filter(prof =>
			(MandatoryProducts[actor] || []).includes(prof.code)
		)

		const selectedProducts = mandatoryProduct.filter(
			({ id }) => !profiles.find(d => d.id === id)
		)

		selectedProducts.forEach(meta => {
			const newRow = addLast(profiles, [
				{
					id: meta.id,
					tradeAccess: 'trade-buy-access',
					isBuyer: true,
					isSeller: undefined,
				},
				{
					id: meta.id,
					tradeAccess: 'trade-sell-access',
					isBuyer: undefined,
					isSeller: true,
				},
			])

			setProfiles(newRow)
		})
	}, [actor, products, profiles])

	const handleOnChange = useCallback(
		(actionType, meta = {}) => {
			if (actionType === 'toggle_trade_access') {
				// check for existing profile type
				const existingProfile = profiles.filter(
					profile => profile.id === meta.id
				)

				// get current row index
				const index = profiles.findIndex(i => i.id === meta.id)

				let tradeAccess
				if (existingProfile.length === 0) {
					if (meta.isBuyer) {
						tradeAccess = 'trade-buy-access'
					}

					if (meta.isSeller) {
						tradeAccess = 'trade-sell-access'
					}

					const newRow = addLast(profiles, {
						id: meta.id,
						tradeAccess,
						isBuyer: meta.isBuyer,
						isSeller: meta.isSeller,
					})

					setProfiles(newRow)
				} else {
					if (meta.isBuyer || meta.isSeller) {
						if (meta.isBuyer && meta.isSeller) {
							tradeAccess = 'trade-buy-sell-access'
						}

						if (meta.isBuyer && meta.isSeller === false) {
							tradeAccess = 'trade-buy-access'
						}

						if (meta.isSeller && meta.isBuyer === false) {
							tradeAccess = 'trade-sell-access'
						}

						const doc = replaceAt(profiles, index, {
							id: meta.id,
							tradeAccess,
							isBuyer: meta.isBuyer,
							isSeller: meta.isSeller,
						})

						setProfiles(doc)
					}

					if (
						meta.isBuyer === undefined ||
						meta.isSeller === undefined ||
						(meta.isBuyer === false && meta.isSeller === false)
					) {
						const updatedProfiles = removeAt(profiles, index)
						setProfiles(updatedProfiles)
					}
				}
			}
		},
		[profiles]
	)

	return (
		<Box
			style={{
				background: ' #1D285D',
				paddingTop: '280px',
				height: '100vh',
				overflow: 'scroll',
			}}
			center
		>
			<Spacer size={20} />
			<Box width="650px">
				<Stepper
					steps={[{}, {}, {}]}
					activeStep={2}
					connectorStateColors
					styleConfig={stepStyles}
					connectorStyleConfig={connectorStyles}
				/>
			</Box>
			<H2
				style={{
					fontSize: 40,
					color: theme.color.white,
					lineHeight: '52px',
					fontWeight: 'bold',
				}}
			>
				Update product profile
			</H2>
			<Spacer size={24} />
			<Box
				style={{
					width: '920px',
					background: theme.color.white,
					borderRadius: '10px',
					boxShadow: '0px 3px 6px #00000029',
				}}
			>
				<Box margin="48px 0 0 60px">
					<H2 style={{ fontSize: 32 }}>
						Select Profile for the products
					</H2>
					<P style={{ fontSize: 14, color: '#f09112' }}>
						Updating all the product profiles are mandatory
					</P>
				</Box>
				<Box
					height="auto"
					width={800}
					style={{ margin: '28px 55px 0 60px', overflow: 'auto' }}
				>
					{products.length === 0 && <TransparentBgSpinner />}
					<Table
						columnConfig={columnConfig({
							onChange: handleOnChange,
							profiles,
							actor,
						})}
						rowData={products}
						size="small"
						noRoundedBorder
					/>
					{(products || []).length === 0 && (
						<Box style={{ minHeight: 300 }}>
							<ErrorBlock
								hideButton
								status="empty"
								message={t('common.errorBlockMessage')}
							/>
						</Box>
					)}
					{!disableCTA && (
						<P
							style={{
								fontSize: 14,
								color: '#f09112',
								marginTop: 24,
							}}
						>
							Please update for all products
						</P>
					)}
				</Box>

				<Box center margin="20px 0">
					<Button
						type="submit"
						disabled={!disableCTA}
						primary
						label="Update Products"
						extendStyles={{
							width: 410,
						}}
						onClick={() => {
							dispatch(
								OnboardDuc.creators.updateAllProductProfile(
									profiles
								)
							)
						}}
					/>
				</Box>
			</Box>
		</Box>
	)
}

export { ProductBlock }
