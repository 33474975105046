import React, { useCallback, useEffect, useState } from 'react'
import { merge, getIn } from 'timm'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Label } from 'ui-lib/components/Label'
import theme from 'ui-lib/utils/base-theme'
import { useDispatch, useSelector } from 'react-redux'
import { AppDuc } from 'core-app/modules/App/duc'
import { Input } from 'ui-lib/components/Input'
import { WebTourDuc } from 'core-app/modules/WebTour/duc'
import { DatePicker } from 'ui-lib/components/Datepicker'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { SelectSearch } from 'ui-lib/components/Select'
import { getStates, getCountries } from 'country-state-picker'
import { isEmptyObject } from 'core-app/utils/helpers'

const Line = styled.div({
	borderBottom: '1px solid grey',
	width: '80%',
})
const FarmDetails = ({ details, onChange }) => {
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const { t } = useTranslation()
	const countries = getCountries()

	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)

	const dispatch = useDispatch()
	if (tourModule !== '') {
		dispatch(WebTourDuc.creators.setActiveTourModule(''))
	}
	const handleChange = useCallback(
		keyValue => {
			onChange(merge(details, keyValue))
		},

		[onChange, details]
	)

	const countryList = []
	// eslint-disable-next-line array-callback-return
	countries.map(value => {
		if (value.code !== 'gg') {
			countryList.push({
				name: value.code,
				label: value.name,
			})
		}
	})
	const defaultCountry = countryList.filter(
		_country => _country.label === details.country
	)
	const countryCode = !isEmptyObject(defaultCountry)
		? getIn(defaultCountry, [0, 'name']) || ''
		: ''
	const [stateList, setStateList] = useState([])
	const getStateCode = code => {
		const _stateList = getStates(code)

		const stateDataList = _stateList.map(state => {
			return {
				name: state,
				label: state,
			}
		})
		setStateList(stateDataList)
	}
	useEffect(() => {
		handleChange()
	}, [handleChange])

	useEffect(() => {
		if (countryCode) {
			// eslint-disable-next-line no-param-reassign
			getStateCode(countryCode)
		}
	}, [countryCode, details.country])

	return (
		<>
			<Box row style={{ width: '100%' }} justifyContent="space-between">
				<Box style={{ width: '48%' }}>
					<Label
						required
						style={{
							fontWeight: '300px',
							fontSize: '16px',
							color: theme.color.accent2,
							width: '100%',
						}}
					>
						{t('onboarding.fieldSize')}
					</Label>
					<Spacer size={8} />

					<Box style={{ width: '100%' }}>
						<Input
							value={details.area}
							name="area"
							type="number"
							onChange={handleChange}
							returnKeyValue
							extendStyles={{
								background:
									details.area.length === 0
										? `${theme.color.grey11} 0% 0% no-repeat padding-box`
										: `${theme.color.white} 0% 0% no-repeat padding-box`,
								boxShadow:
									details.area.length === 0
										? '0px 6px 16px #05050707'
										: `0px 2px 8px ${theme.color.blue6}`,
								border:
									details.area.length === 0
										? `1px solid ${theme.color.grey4}`
										: `1px solid ${theme.color.blue5}`,
								borderRadius: '4px',
								opacity: 1,
							}}
						/>
					</Box>
				</Box>
				<Box style={{ width: '48%' }}>
					<Label
						required
						style={{
							fontWeight: '300px',
							fontSize: '16px',
							color: theme.color.accent2,
							width: '100%',
						}}
					>
						{t('onboarding.plantationArea')}
					</Label>
					<Spacer size={8} />
					<Box style={{ width: '100%' }}>
						<Input
							value={details.plantationArea}
							name="plantationArea"
							type="number"
							onChange={handleChange}
							returnKeyValue
							extendStyles={{
								background:
									details.plantationArea.length === 0
										? `${theme.color.grey11} 0% 0% no-repeat padding-box`
										: `${theme.color.white} 0% 0% no-repeat padding-box`,
								boxShadow:
									details.plantationArea.length === 0
										? '0px 6px 16px #05050707'
										: `0px 2px 8px ${theme.color.blue6}`,
								border:
									details.plantationArea.length === 0
										? `1px solid ${theme.color.grey4}`
										: `1px solid ${theme.color.blue5}`,
								borderRadius: '4px',
								opacity: 1,
							}}
						/>
					</Box>
				</Box>
			</Box>
			<Box>
				<Label
					required
					style={{
						fontWeight: '300px',
						fontSize: '16px',
						color: theme.color.accent2,
					}}
				>
					{t('onboarding.plantationTime')}
				</Label>
				<Spacer size={8} />
				<DatePicker
					required
					name="plantationTime"
					returnKeyValue
					maxDate={new Date()}
					value={new Date(details.plantationTime)}
					onChange={keyValue =>
						handleChange({
							plantationTime: keyValue.plantationTime,
						})
					}
				/>
			</Box>
			<Box alignItems="center" row>
				<Label
					style={{
						fontWeight: '300px',
						width: '20%',
						fontSize: '16px',
						color: theme.color.accent2,
					}}
				>
					{t('onboarding.farmAddress')}
				</Label>
				<Line />
			</Box>
			<Spacer size={16} />

			<Box
				row={!isMobile}
				style={{ width: '100%' }}
				justifyContent="space-between"
			>
				<Box
					style={{
						width: isMobile ? '100%' : '47.5%',
					}}
				>
					<Label
						required
						style={{
							fontWeight: '400px',
							fontSize: '16px',
							color: theme.color.accent2,
						}}
					>
						{t('onboarding.country')}
					</Label>
					<SelectSearch
						required
						placeholder={t('onboarding.countryPlaceHolder')}
						value={countryCode}
						options={countryList}
						onChange={value => {
							if (!isEmptyObject(value)) {
								handleChange({
									country: value.label,
								})
								getStateCode(value.name)
							}
						}}
						labelKey="label"
						valueKey="name"
						defaultLabel={t('common.selectDefaultLabel')}
						returnOnlyValue
						style={{
							background:
								details.country === ''
									? `${theme.color.grey11} 0% 0% no-repeat padding-box`
									: `${theme.color.white} 0% 0% no-repeat padding-box`,
							boxShadow:
								details.country === ''
									? '0px 6px 16px #05050707'
									: `0px 2px 8px ${theme.color.blue6}`,
							border:
								details.country === ''
									? `1px solid ${theme.color.grey4}`
									: `1px solid ${theme.color.blue5}`,
							borderRadius: '4px',
							width: '100%',
							opacity: 1,
							marginTop: '8px',
						}}
					/>
				</Box>
				<Box
					style={{
						width: isMobile ? '100%' : '47.5%',
					}}
				>
					<Label
						required
						style={{
							fontWeight: '400px',
							fontSize: '16px',
							color: theme.color.accent2,
						}}
					>
						{t('onboarding.state')}
					</Label>
					<SelectSearch
						placeholder={t('onboarding.statePlaceHolder')}
						value={details.state}
						options={stateList}
						onChange={value => {
							handleChange({ state: value.name })
						}}
						labelKey="name"
						valueKey="label"
						defaultLabel={t('common.selectDefaultLabel')}
						returnOnlyValue
						style={{
							background:
								details.state === ''
									? `${theme.color.grey11} 0% 0% no-repeat padding-box`
									: `${theme.color.white} 0% 0% no-repeat padding-box`,

							boxShadow:
								details.state === ''
									? '0px 6px 16px #05050707'
									: `0px 2px 8px ${theme.color.blue6}`,
							border:
								details.state === ''
									? `1px solid ${theme.color.grey4}`
									: `1px solid ${theme.color.blue5}`,
							borderRadius: '4px',
							width: '100%',
							opacity: 1,
							marginTop: '8px',
						}}
					/>
				</Box>
			</Box>

			<Box
				row={!isMobile}
				style={{ width: '100%' }}
				justifyContent="space-between"
			>
				<Box style={{ width: '33%' }}>
					<Box style={{ width: '100%' }}>
						<Label
							required
							style={{
								fontWeight: '300px',
								fontSize: '16px',
								color: theme.color.accent2,
								width: '100%',
							}}
						>
							{t('onboarding.addressLine1')}
						</Label>
						<Spacer size={8} />
						<Box style={{ width: '100%' }}>
							<Input
								value={details.line1}
								name="line1"
								type="text"
								returnKeyValue
								onChange={handleChange}
								extendStyles={{
									background: `${theme.color.white} 0% 0% no-repeat padding-box`,
									boxShadow: `0px 2px 8px ${theme.color.blue6}`,
									border: `1px solid ${theme.color.blue5}`,
									borderRadius: '4px',
									opacity: 1,
								}}
							/>
						</Box>
					</Box>
				</Box>
				<Box style={{ width: '33%' }}>
					<Box style={{ width: '100%' }}>
						<Label
							required
							style={{
								fontWeight: '300px',
								fontSize: '16px',
								color: theme.color.accent2,
								width: '100%',
							}}
						>
							{t('onboarding.district')}
						</Label>
						<Spacer size={8} />
						<Box style={{ width: '100%' }}>
							<Input
								value={details.district}
								name="district"
								type="text"
								returnKeyValue
								onChange={handleChange}
								extendStyles={{
									background: `${theme.color.white} 0% 0% no-repeat padding-box`,
									boxShadow: `0px 2px 8px ${theme.color.blue6}`,
									border: `1px solid ${theme.color.blue5}`,
									borderRadius: '4px',
									opacity: 1,
								}}
							/>
						</Box>
					</Box>
				</Box>
				<Box style={{ width: '33%' }}>
					<Box style={{ width: '100%' }}>
						<Label
							required
							style={{
								fontWeight: '300px',
								fontSize: '16px',
								color: theme.color.accent2,
								width: '100%',
							}}
						>
							{t('onboarding.village/city')}
						</Label>
						<Spacer size={8} />
						<Box style={{ width: '100%' }}>
							<Input
								value={details.city}
								name="city"
								type="text"
								returnKeyValue
								onChange={handleChange}
								extendStyles={{
									background: `${theme.color.white} 0% 0% no-repeat padding-box`,
									boxShadow: `0px 2px 8px ${theme.color.blue6}`,
									border: `1px solid ${theme.color.blue5}`,
									borderRadius: '4px',
									opacity: 1,
								}}
							/>
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	)
}

export default FarmDetails
