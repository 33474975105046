import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AdminDuc } from 'core-app/modules/Admin/duc'
import { CookieDuc } from 'core-app/modules/App/cookieDuc'

import { KeyValueSegment } from 'core-app/modules/Admin/components/KeyValueSegment'
import { Box } from 'ui-lib/utils/Box'
import { ButtonWithNoBorder } from 'ui-lib/components/Button'
import theme from 'ui-lib/utils/base-theme'
import { getVehicleTypes } from '../config'

const VehicleDetails = ({ assetID }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const vehicleDetails = useSelector(AdminDuc.selectors.getVehicleDetails)
	const { number, meta = {} } = vehicleDetails
	const { capacity, capacityUOM, make, model, name, type } = meta
	const vehicleTypes = getVehicleTypes(t)
	const selectedCPID = useSelector(CookieDuc.selectors.getSelectedCPID)
	const getLabel = typeName => {
		const typeInfo = vehicleTypes.find(
			vehicleType => vehicleType.name === typeName
		)

		return typeInfo?.label
	}

	return (
		<>
			{!selectedCPID && (
				<Box row justifyContent="flex-end">
					<ButtonWithNoBorder
						label={t('common.edit').toUpperCase()}
						onClick={() => {
							dispatch(
								MainRouteDuc.creators.switchPage(
									MainRouteDuc.types
										.ADMIN$SUBMODULEWDOCREFERENCE,
									{
										rootModule: 'assets',
										subModule: 'vehicles',
										action: 'edit-asset',
										documentReference: assetID,
									}
								)
							)
						}}
					/>
				</Box>
			)}

			<Box
				style={{
					border: `1px solid ${theme.color.grey7}`,
					borderRadius: '4px',
				}}
			>
				<KeyValueSegment
					label={t('viewAsset.assetName')}
					value={name}
				/>
				<KeyValueSegment
					label={t('viewAsset.assetNumber')}
					value={number}
				/>
				<KeyValueSegment label={t('viewAsset.make')} value={make} />
				<KeyValueSegment label={t('viewAsset.model')} value={model} />
				<KeyValueSegment
					label={t('viewAsset.type')}
					value={getLabel(type)}
				/>
				<KeyValueSegment
					label={t('viewAsset.capacity')}
					value={
						capacity === undefined
							? ''
							: `${capacity} ${capacityUOM || ''}`
					}
				/>
			</Box>
		</>
	)
}

export { VehicleDetails }
