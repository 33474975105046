import LogHelper from 'core-app/utils/logger'
import { getIn } from 'timm'
import { AppDuc } from 'core-app/modules/App/duc'
import { EndManufacturerDuc } from 'core-app/modules/EndManufacturer/duc'
import { getInsightsEndPoint } from 'core-app/config'
import {
	CallWithRefreshCheck,
	fetchOrgDetailsCount,
} from 'core-app/modules/Auth/AuthSaga'
import { all, takeLatest, put } from 'redux-saga/effects'
import {
	transformDataForBarChart,
	transformDataforPieChart,
} from 'core-app/utils/helpers'

const logger = LogHelper('client:EndManufacturerSaga')

function* fetchInsights() {
	try {
		yield put(
			AppDuc.creators.showGlobalLoader('fetch-endManufacturer-insights')
		)
		yield fetchOrgDetailsCount()

		const requestUrl = `${getInsightsEndPoint()}dashboard/oil-manufacturer/insights`
		const data = yield CallWithRefreshCheck(requestUrl)
		const userInsight = getIn(data, ['data']) || {}

		const bestSuppliers = yield transformDataforPieChart(
			userInsight.bestSuppliers,
			'best-suppliers'
		)

		const topProducts = yield transformDataForBarChart(
			userInsight.topProducts,
			'products'
		)

		const certifiedSupply = yield transformDataForBarChart(
			userInsight.certifiedSupply,
			'certified-suppliers'
		)

		const supplierCategory = yield transformDataForBarChart(
			userInsight.supplierCategory,
			'supplier-categories'
		)

		const finaltransFormedInsight = {
			...userInsight,
			bestSuppliers,
			topProducts,
			certifiedSupply,
			supplierCategory,
		}

		yield put(
			EndManufacturerDuc.creators.fetchInsightsSuccess(
				finaltransFormedInsight
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(
			AppDuc.creators.hideGlobalLoader('fetch-endManufacturer-insights')
		)
	}
}

export default function* EndManufacturerSaga() {
	try {
		yield all([
			takeLatest(
				EndManufacturerDuc.creators.fetchInsights().type,
				fetchInsights
			),
		])
	} catch (err) {
		logger.log(err)
	}
}
