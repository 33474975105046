import { AdminDuc } from 'core-app/modules/Admin/duc'
import { AppDuc } from 'core-app/modules/App/duc'
import { WebTourDuc } from 'core-app/modules/WebTour/duc'
import { isEmptyObject } from 'core-app/utils/helpers'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getIn } from 'timm'
import { AreaMapPreview } from 'ui-lib/components/AreaMapPreview'
import { Button, ButtonWithNoBorder } from 'ui-lib/components/Button'
import { ShapeFileFileUpload } from 'ui-lib/components/FileUpload'
import { Box } from 'ui-lib/utils/Box'
import theme from 'ui-lib/utils/base-theme'
import { getUtilitiesEndPoint } from '../../../../config'

const EditAreaDetails = ({ handleShowModal }) => {
	const [uploadedDoc, setUploadedDoc] = useState([])
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)
	const dispatch = useDispatch()
	if (tourModule !== '') {
		dispatch(WebTourDuc.creators.setActiveTourModule(''))
	}

	const { t } = useTranslation()

	const initialValues = {
		files: [],
	}

	const {
		values,
		setFieldValue,
		handleSubmit,
		isSubmitting,
		errors,
	} = useFormik({
		initialValues,
		enableReinitialize: true,
		onSubmit: _values => {
			const payload = {
				type: 'coordinates',
				shape: {
					coordinates:
						getIn(_values, [
							'files',
							0,
							'json',
							'features',
							0,
							'geometry',
							'coordinates',
							0,
						]) || [],
					center: [],
				},
			}

			dispatch(
				AdminDuc.creators.addAreaDetails(
					payload,
					values.id,
					t('viewCompanyInfo.areaUpdateSuccess')
				)
			)
		},
	})

	const disableCTA =
		!isEmptyObject(errors) || !(values.files && values.files.length)

	return (
		<Box
			style={{
				minHeight: '310px',
				maxHeight: '510px',
				overflow: 'scroll',
			}}
		>
			<form onSubmit={handleSubmit}>
				<Box style={{ paddingRight: '6px' }}>
					<ShapeFileFileUpload
						required
						type="private"
						name="file"
						size="5242880"
						modalTriggerBtnLabel={t('common.upload')}
						label={t('viewCompanyInfo.areaCopy')}
						supportedFileType=".zip, .geojson"
						outline
						currentUploads={values.files}
						utilitiesUrl={`${getUtilitiesEndPoint()}geojson/converter?file_type=zip`}
						onDocumentChange={document => {
							setUploadedDoc(document)
							setFieldValue('files', document)
						}}
						disableUploadForOne
						onUploadFailure={({ message }) => {
							dispatch(
								AppDuc.creators.showToast({
									messageType: 'error',
									message,
								})
							)
						}}
					/>
					<Box
						style={{
							height: '250px',
						}}
					>
						{uploadedDoc.map(d => {
							return <AreaMapPreview geoJSON={d.json} />
						})}
					</Box>
					<Box
						style={{
							bottom: 0,
							right: '62px',
							position: 'absolute',
							margin: '20px 0px 20px 0px',
						}}
						row
					>
						<ButtonWithNoBorder
							label={t('common.close')}
							onClick={() => {
								handleShowModal(false)
							}}
							extendStyles={{
								marginRight: 10,
								color: theme.color.grey6,
							}}
						/>
						<Button
							primary
							type="submit"
							disabled={disableCTA}
							isLoading={isSubmitting}
							label={t('breadcrumb.confirm')}
							extendStyles={{
								width: 100,
							}}
						/>
					</Box>
				</Box>
			</form>
		</Box>
	)
}

export default EditAreaDetails
