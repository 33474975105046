import React from 'react'
import { Box } from 'ui-lib/utils/Box'
import { P, H3 } from 'ui-lib/components/Typography'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import SettingsIcon from 'ui-lib/icons/settings.svg'
import NotificationIcon from 'ui-lib/icons/notification.svg'
import { Spacer } from 'ui-lib/utils/Spacer'
import PartnerIcon from 'ui-lib/icons/partner.svg'
import tradeDocIcon from 'ui-lib/icons/trade.svg'
import AdminIcon from 'ui-lib/icons/admin.svg'
import GraphUp from 'ui-lib/icons/graph-up.svg'
import Trust from 'ui-lib/icons/trust.svg'
import Truck from 'ui-lib/icons/delivery-truck.svg'
import ProductionIcon from 'ui-lib/icons/production.svg'
import Receipt from 'ui-lib/icons/receipt.svg'
import Records from 'ui-lib/icons/records.svg'

const stepStyle = {
	color: '#F6F6F6',
	background: 'linear-gradient(45deg, #5a3a75 0%, #b374e9 60%)',
	backgroundColor: '#b374e9 ',
}

export const dashboardSteps = t => {
	return [
		{
			selector: '#DashboardHeader',
			content: () => (
				<Box>
					<H3
						style={{
							textAlign: 'left',
							font: 'normal normal bold 22px Roboto',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.dashboard')}
					</H3>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px Roboto',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.dashboardNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#SettingsHeader',
			content: () => (
				<Box>
					<Box row>
						<IconWrapper
							size={20}
							style={{ padding: '1% 0% 0% 0%' }}
						>
							<Icon glyph={SettingsIcon} color="#F6F6F6" />
						</IconWrapper>
						<Spacer size={8} horizontal />

						<H3
							style={{
								textAlign: 'left',
								font: 'normal normal bold 22px ',
								letterSpacing: '0px',
								color: '#F6F6F6',
								opacity: 1,
							}}
						>
							{t('tourCommon.settings')}
						</H3>
					</Box>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px ',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.settingsNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#NotificationsHeader',
			content: () => (
				<Box>
					<Box row>
						<IconWrapper
							size={20}
							style={{ padding: '1% 0% 0% 0%' }}
						>
							<Icon glyph={NotificationIcon} color="#F6F6F6" />
						</IconWrapper>
						<Spacer size={8} horizontal />
						<H3
							style={{
								textAlign: 'left',
								font: 'normal normal bold 22px ',
								letterSpacing: '0px',
								color: '#F6F6F6',
								opacity: 1,
							}}
						>
							{t('tourCommon.notifications')}
						</H3>
					</Box>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px ',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.notificationsNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#sideBartdm',
			content: () => (
				<Box>
					<Box row>
						<IconWrapper
							size={20}
							style={{ padding: '1% 0% 0% 0%' }}
						>
							<Icon glyph={tradeDocIcon} color="#F6F6F6" />
						</IconWrapper>
						<Spacer size={8} horizontal />
						<H3
							style={{
								textAlign: 'left',
								font: 'normal normal bold 22px ',
								letterSpacing: '0px',
								color: '#F6F6F6',
								opacity: 1,
							}}
						>
							{t('tourCommon.tdm')}
						</H3>
					</Box>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px ',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.tdmModuleNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},

		{
			selector: '#sideBarproductTrace',
			content: () => (
				<Box>
					<Box row>
						<IconWrapper
							size={20}
							style={{ padding: '1% 0% 0% 0%' }}
						>
							<Icon glyph={Trust} color="#F6F6F6" />
						</IconWrapper>
						<Spacer size={8} horizontal />
						<H3
							style={{
								textAlign: 'left',
								font: 'normal normal bold 22px ',
								letterSpacing: '0px',
								color: '#F6F6F6',
								opacity: 1,
							}}
						>
							{t('tourCommon.productTrace')}
						</H3>
					</Box>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px ',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.productTraceNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#sideBarpartnetMgnt',
			content: () => (
				<Box>
					<Box row>
						<IconWrapper
							size={20}
							style={{ padding: '1% 0% 0% 0%' }}
						>
							<Icon glyph={PartnerIcon} color="#F6F6F6" />
						</IconWrapper>
						<Spacer size={8} horizontal />
						<H3
							style={{
								textAlign: 'left',
								font: 'normal normal bold 22px ',
								letterSpacing: '0px',
								color: '#F6F6F6',
								opacity: 1,
							}}
						>
							{t('tourCommon.partnerMgmt')}
						</H3>
					</Box>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px ',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.partnerMgmtNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#sideBarauditReports',
			content: () => (
				<Box>
					<Box row>
						<IconWrapper
							size={20}
							style={{ padding: '1% 0% 0% 0%' }}
						>
							<Icon glyph={GraphUp} color="#F6F6F6" />
						</IconWrapper>
						<Spacer size={8} horizontal />
						<H3
							style={{
								textAlign: 'left',
								font: 'normal normal bold 22px ',
								letterSpacing: '0px',
								color: '#F6F6F6',
								opacity: 1,
							}}
						>
							{t('tourCommon.auditReport')}
						</H3>
					</Box>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px ',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.auditReportNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},

		{
			selector: '#sideBaradministrator',
			content: () => (
				<Box>
					<Box row>
						<IconWrapper
							size={20}
							style={{ padding: '1% 0% 0% 0%' }}
						>
							<Icon glyph={AdminIcon} color="#F6F6F6" />
						</IconWrapper>
						<Spacer size={8} horizontal />
						<H3
							style={{
								textAlign: 'left',
								font: 'normal normal bold 22px/36px ',
								letterSpacing: '0px',
								color: '#F6F6F6',
								opacity: 1,
							}}
						>
							{t('tourCommon.admin')}
						</H3>
					</Box>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px ',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.adminNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},
	]
}

export const dealerDashboardSteps = t => {
	return [
		{
			selector: '#DashboardHeader',
			content: () => (
				<Box>
					<H3
						style={{
							textAlign: 'left',
							font: 'normal normal bold 22px Roboto',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.dashboard')}
					</H3>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px Roboto',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.dashboardNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#SettingsHeader',
			content: () => (
				<Box>
					<Box row>
						<IconWrapper
							size={20}
							style={{ padding: '1% 0% 0% 0%' }}
						>
							<Icon glyph={SettingsIcon} color="#F6F6F6" />
						</IconWrapper>
						<Spacer size={8} horizontal />

						<H3
							style={{
								textAlign: 'left',
								font: 'normal normal bold 22px ',
								letterSpacing: '0px',
								color: '#F6F6F6',
								opacity: 1,
							}}
						>
							{t('tourCommon.settings')}
						</H3>
					</Box>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px ',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.settingsNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#NotificationsHeader',
			content: () => (
				<Box>
					<Box row>
						<IconWrapper
							size={20}
							style={{ padding: '1% 0% 0% 0%' }}
						>
							<Icon glyph={NotificationIcon} color="#F6F6F6" />
						</IconWrapper>
						<Spacer size={8} horizontal />
						<H3
							style={{
								textAlign: 'left',
								font: 'normal normal bold 22px ',
								letterSpacing: '0px',
								color: '#F6F6F6',
								opacity: 1,
							}}
						>
							{t('tourCommon.notifications')}
						</H3>
					</Box>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px ',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.notificationsNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#sideBartdm',
			content: () => (
				<Box>
					<Box row>
						<IconWrapper
							size={20}
							style={{ padding: '1% 0% 0% 0%' }}
						>
							<Icon glyph={tradeDocIcon} color="#F6F6F6" />
						</IconWrapper>
						<Spacer size={8} horizontal />
						<H3
							style={{
								textAlign: 'left',
								font: 'normal normal bold 22px ',
								letterSpacing: '0px',
								color: '#F6F6F6',
								opacity: 1,
							}}
						>
							{t('tourCommon.tdm')}
						</H3>
					</Box>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px ',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.tdmModuleNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#sideBartrips',
			content: () => (
				<Box>
					<Box row>
						<IconWrapper
							size={20}
							style={{ padding: '1% 0% 0% 0%' }}
						>
							<Icon glyph={Truck} color="#F6F6F6" />
						</IconWrapper>
						<Spacer size={8} horizontal />
						<H3
							style={{
								textAlign: 'left',
								font: 'normal normal bold 22px ',
								letterSpacing: '0px',
								color: '#F6F6F6',
								opacity: 1,
							}}
						>
							{t('tourCommon.trips')}
						</H3>
					</Box>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px ',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.tripNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#sideBarwbTickets',
			content: () => (
				<Box>
					<Box row>
						<IconWrapper
							size={20}
							style={{ padding: '1% 0% 0% 0%' }}
						>
							<Icon glyph={Receipt} color="#F6F6F6" />
						</IconWrapper>
						<Spacer size={8} horizontal />
						<H3
							style={{
								textAlign: 'left',
								font: 'normal normal bold 22px ',
								letterSpacing: '0px',
								color: '#F6F6F6',
								opacity: 1,
							}}
						>
							{t('tourCommon.wbTickets')}
						</H3>
					</Box>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px ',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.wbTicketsNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#sideBarpartnetMgnt',
			content: () => (
				<Box>
					<Box row>
						<IconWrapper
							size={20}
							style={{ padding: '1% 0% 0% 0%' }}
						>
							<Icon glyph={PartnerIcon} color="#F6F6F6" />
						</IconWrapper>
						<Spacer size={8} horizontal />
						<H3
							style={{
								textAlign: 'left',
								font: 'normal normal bold 22px ',
								letterSpacing: '0px',
								color: '#F6F6F6',
								opacity: 1,
							}}
						>
							{t('tourCommon.partnerMgmt')}
						</H3>
					</Box>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px ',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.partnerMgmtNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#sideBaradministrator',
			content: () => (
				<Box>
					<Box row>
						<IconWrapper
							size={20}
							style={{ padding: '1% 0% 0% 0%' }}
						>
							<Icon glyph={AdminIcon} color="#F6F6F6" />
						</IconWrapper>
						<Spacer size={8} horizontal />
						<H3
							style={{
								textAlign: 'left',
								font: 'normal normal bold 22px/36px ',
								letterSpacing: '0px',
								color: '#F6F6F6',
								opacity: 1,
							}}
						>
							{t('tourCommon.admin')}
						</H3>
					</Box>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px ',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.adminNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},
	]
}
export const plantationDashboardSteps = t => {
	return [
		{
			selector: '#DashboardHeader',
			content: () => (
				<Box>
					<H3
						style={{
							textAlign: 'left',
							font: 'normal normal bold 22px Roboto',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.dashboard')}
					</H3>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px Roboto',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.dashboardNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#SettingsHeader',
			content: () => (
				<Box>
					<Box row>
						<IconWrapper
							size={20}
							style={{ padding: '1% 0% 0% 0%' }}
						>
							<Icon glyph={SettingsIcon} color="#F6F6F6" />
						</IconWrapper>
						<Spacer size={8} horizontal />

						<H3
							style={{
								textAlign: 'left',
								font: 'normal normal bold 22px ',
								letterSpacing: '0px',
								color: '#F6F6F6',
								opacity: 1,
							}}
						>
							{t('tourCommon.settings')}
						</H3>
					</Box>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px ',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.settingsNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#NotificationsHeader',
			content: () => (
				<Box>
					<Box row>
						<IconWrapper
							size={20}
							style={{ padding: '1% 0% 0% 0%' }}
						>
							<Icon glyph={NotificationIcon} color="#F6F6F6" />
						</IconWrapper>
						<Spacer size={8} horizontal />
						<H3
							style={{
								textAlign: 'left',
								font: 'normal normal bold 22px ',
								letterSpacing: '0px',
								color: '#F6F6F6',
								opacity: 1,
							}}
						>
							{t('tourCommon.notifications')}
						</H3>
					</Box>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px ',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.notificationsNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#sideBartdm',
			content: () => (
				<Box>
					<Box row>
						<IconWrapper
							size={20}
							style={{ padding: '1% 0% 0% 0%' }}
						>
							<Icon glyph={tradeDocIcon} color="#F6F6F6" />
						</IconWrapper>
						<Spacer size={8} horizontal />
						<H3
							style={{
								textAlign: 'left',
								font: 'normal normal bold 22px ',
								letterSpacing: '0px',
								color: '#F6F6F6',
								opacity: 1,
							}}
						>
							{t('tourCommon.tdm')}
						</H3>
					</Box>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px ',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.tdmModuleNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},

		{
			selector: '#sideBarrecords',
			content: () => (
				<Box>
					<Box row>
						<IconWrapper
							size={20}
							style={{ padding: '1% 0% 0% 0%' }}
						>
							<Icon glyph={Records} color="#F6F6F6" />
						</IconWrapper>
						<Spacer size={8} horizontal />
						<H3
							style={{
								textAlign: 'left',
								font: 'normal normal bold 22px ',
								letterSpacing: '0px',
								color: '#F6F6F6',
								opacity: 1,
							}}
						>
							{t('tourCommon.records')}
						</H3>
					</Box>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px ',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.recordsNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#sideBarpartnetMgnt',
			content: () => (
				<Box>
					<Box row>
						<IconWrapper
							size={20}
							style={{ padding: '1% 0% 0% 0%' }}
						>
							<Icon glyph={PartnerIcon} color="#F6F6F6" />
						</IconWrapper>
						<Spacer size={8} horizontal />
						<H3
							style={{
								textAlign: 'left',
								font: 'normal normal bold 22px ',
								letterSpacing: '0px',
								color: '#F6F6F6',
								opacity: 1,
							}}
						>
							{t('tourCommon.partnerMgmt')}
						</H3>
					</Box>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px ',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.partnerMgmtNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#sideBarauditReports',
			content: () => (
				<Box>
					<Box row>
						<IconWrapper
							size={20}
							style={{ padding: '1% 0% 0% 0%' }}
						>
							<Icon glyph={GraphUp} color="#F6F6F6" />
						</IconWrapper>
						<Spacer size={8} horizontal />
						<H3
							style={{
								textAlign: 'left',
								font: 'normal normal bold 22px/36px ',
								letterSpacing: '0px',
								color: '#F6F6F6',
								opacity: 1,
							}}
						>
							{t('tourCommon.auditReport')}
						</H3>
					</Box>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px ',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.auditReportNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},

		{
			selector: '#sideBaradministrator',
			content: () => (
				<Box>
					<Box row>
						<IconWrapper
							size={20}
							style={{ padding: '1% 0% 0% 0%' }}
						>
							<Icon glyph={AdminIcon} color="#F6F6F6" />
						</IconWrapper>
						<Spacer size={8} horizontal />
						<H3
							style={{
								textAlign: 'left',
								font: 'normal normal bold 22px/36px ',
								letterSpacing: '0px',
								color: '#F6F6F6',
								opacity: 1,
							}}
						>
							{t('tourCommon.admin')}
						</H3>
					</Box>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px ',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.adminNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},
	]
}

export const smallholderDashboardSteps = t => {
	return [
		{
			selector: '#DashboardHeader',
			content: () => (
				<Box>
					<H3
						style={{
							textAlign: 'left',
							font: 'normal normal bold 22px Roboto',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.dashboard')}
					</H3>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px Roboto',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.dashboardNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#SettingsHeader',
			content: () => (
				<Box>
					<Box row>
						<IconWrapper
							size={20}
							style={{ padding: '1% 0% 0% 0%' }}
						>
							<Icon glyph={SettingsIcon} color="#F6F6F6" />
						</IconWrapper>
						<Spacer size={8} horizontal />

						<H3
							style={{
								textAlign: 'left',
								font: 'normal normal bold 22px ',
								letterSpacing: '0px',
								color: '#F6F6F6',
								opacity: 1,
							}}
						>
							{t('tourCommon.settings')}
						</H3>
					</Box>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px ',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.settingsNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#NotificationsHeader',
			content: () => (
				<Box>
					<Box row>
						<IconWrapper
							size={20}
							style={{ padding: '1% 0% 0% 0%' }}
						>
							<Icon glyph={NotificationIcon} color="#F6F6F6" />
						</IconWrapper>
						<Spacer size={8} horizontal />
						<H3
							style={{
								textAlign: 'left',
								font: 'normal normal bold 22px ',
								letterSpacing: '0px',
								color: '#F6F6F6',
								opacity: 1,
							}}
						>
							{t('tourCommon.notifications')}
						</H3>
					</Box>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px ',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.notificationsNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#sideBartdm',
			content: () => (
				<Box>
					<Box row>
						<IconWrapper
							size={20}
							style={{ padding: '1% 0% 0% 0%' }}
						>
							<Icon glyph={tradeDocIcon} color="#F6F6F6" />
						</IconWrapper>
						<Spacer size={8} horizontal />
						<H3
							style={{
								textAlign: 'left',
								font: 'normal normal bold 22px ',
								letterSpacing: '0px',
								color: '#F6F6F6',
								opacity: 1,
							}}
						>
							{t('tourCommon.tdm')}
						</H3>
					</Box>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px ',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.tdmModuleNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},

		{
			selector: '#sideBarpartnetMgnt',
			content: () => (
				<Box>
					<Box row>
						<IconWrapper
							size={20}
							style={{ padding: '1% 0% 0% 0%' }}
						>
							<Icon glyph={PartnerIcon} color="#F6F6F6" />
						</IconWrapper>
						<Spacer size={8} horizontal />
						<H3
							style={{
								textAlign: 'left',
								font: 'normal normal bold 22px ',
								letterSpacing: '0px',
								color: '#F6F6F6',
								opacity: 1,
							}}
						>
							{t('tourCommon.partnerMgmt')}
						</H3>
					</Box>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px ',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.partnerMgmtNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#sideBarauditReports',
			content: () => (
				<Box>
					<Box row>
						<IconWrapper
							size={20}
							style={{ padding: '1% 0% 0% 0%' }}
						>
							<Icon glyph={GraphUp} color="#F6F6F6" />
						</IconWrapper>
						<Spacer size={8} horizontal />
						<H3
							style={{
								textAlign: 'left',
								font: 'normal normal bold 22px ',
								letterSpacing: '0px',
								color: '#F6F6F6',
								opacity: 1,
							}}
						>
							{t('tourCommon.auditReport')}
						</H3>
					</Box>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px ',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.auditReportNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},

		{
			selector: '#sideBaradministrator',
			content: () => (
				<Box>
					<Box row>
						<IconWrapper
							size={20}
							style={{ padding: '1% 0% 0% 0%' }}
						>
							<Icon glyph={AdminIcon} color="#F6F6F6" />
						</IconWrapper>
						<Spacer size={8} horizontal />
						<H3
							style={{
								textAlign: 'left',
								font: 'normal normal bold 22px/36px ',
								letterSpacing: '0px',
								color: '#F6F6F6',
								opacity: 1,
							}}
						>
							{t('tourCommon.admin')}
						</H3>
					</Box>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px ',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.adminNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},
	]
}
export const refineryDashboardSteps = t => {
	return [
		{
			selector: '#DashboardHeader',
			content: () => (
				<Box>
					<H3
						style={{
							textAlign: 'left',
							font: 'normal normal bold 22px Roboto',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.dashboard')}
					</H3>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px Roboto',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.dashboardNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'left',
		},
		{
			selector: '#SettingsHeader',
			content: () => (
				<Box>
					<Box row>
						<IconWrapper
							size={20}
							style={{ padding: '1% 0% 0% 0%' }}
						>
							<Icon glyph={SettingsIcon} color="#F6F6F6" />
						</IconWrapper>
						<Spacer size={8} horizontal />

						<H3
							style={{
								textAlign: 'left',
								font: 'normal normal bold 22px ',
								letterSpacing: '0px',
								color: '#F6F6F6',
								opacity: 1,
							}}
						>
							{t('tourCommon.settings')}
						</H3>
					</Box>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px ',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.settingsNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#NotificationsHeader',
			content: () => (
				<Box>
					<Box row>
						<IconWrapper
							size={20}
							style={{ padding: '1% 0% 0% 0%' }}
						>
							<Icon glyph={NotificationIcon} color="#F6F6F6" />
						</IconWrapper>
						<Spacer size={8} horizontal />
						<H3
							style={{
								textAlign: 'left',
								font: 'normal normal bold 22px ',
								letterSpacing: '0px',
								color: '#F6F6F6',
								opacity: 1,
							}}
						>
							{t('tourCommon.notifications')}
						</H3>
					</Box>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px ',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.notificationsNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#sideBartdm',
			content: () => (
				<Box>
					<Box row>
						<IconWrapper
							size={20}
							style={{ padding: '1% 0% 0% 0%' }}
						>
							<Icon glyph={tradeDocIcon} color="#F6F6F6" />
						</IconWrapper>
						<Spacer size={8} horizontal />
						<H3
							style={{
								textAlign: 'left',
								font: 'normal normal bold 22px ',
								letterSpacing: '0px',
								color: '#F6F6F6',
								opacity: 1,
							}}
						>
							{t('tourCommon.tdm')}
						</H3>
					</Box>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px ',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.tdmModuleNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#sideBarproductTrace',
			content: () => (
				<Box>
					<Box row>
						<IconWrapper
							size={20}
							style={{ padding: '1% 0% 0% 0%' }}
						>
							<Icon glyph={Trust} color="#F6F6F6" />
						</IconWrapper>
						<Spacer size={8} horizontal />
						<H3
							style={{
								textAlign: 'left',
								font: 'normal normal bold 22px ',
								letterSpacing: '0px',
								color: '#F6F6F6',
								opacity: 1,
							}}
						>
							{t('tourCommon.productTrace')}
						</H3>
					</Box>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px ',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.productTraceNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#sideBarproduction',
			content: () => (
				<Box>
					<Box row>
						<IconWrapper
							size={20}
							style={{ padding: '1% 0% 0% 0%' }}
						>
							<Icon glyph={ProductionIcon} color="#F6F6F6" />
						</IconWrapper>
						<Spacer size={8} horizontal />
						<H3
							style={{
								textAlign: 'left',
								font: 'normal normal bold 22px ',
								letterSpacing: '0px',
								color: '#F6F6F6',
								opacity: 1,
							}}
						>
							{t('tourCommon.production')}
						</H3>
					</Box>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px ',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.productionNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},

		{
			selector: '#sideBarpartnetMgnt',
			content: () => (
				<Box>
					<Box row>
						<IconWrapper
							size={20}
							style={{ padding: '1% 0% 0% 0%' }}
						>
							<Icon glyph={PartnerIcon} color="#F6F6F6" />
						</IconWrapper>
						<Spacer size={8} horizontal />
						<H3
							style={{
								textAlign: 'left',
								font: 'normal normal bold 22px ',
								letterSpacing: '0px',
								color: '#F6F6F6',
								opacity: 1,
							}}
						>
							{t('tourCommon.partnerMgmt')}
						</H3>
					</Box>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px ',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.partnerMgmtNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#sideBarauditReports',
			content: () => (
				<Box>
					<Box row>
						<IconWrapper
							size={20}
							style={{ padding: '1% 0% 0% 0%' }}
						>
							<Icon glyph={GraphUp} color="#F6F6F6" />
						</IconWrapper>
						<Spacer size={8} horizontal />
						<H3
							style={{
								textAlign: 'left',
								font: 'normal normal bold 22px ',
								letterSpacing: '0px',
								color: '#F6F6F6',
								opacity: 1,
							}}
						>
							{t('tourCommon.auditReport')}
						</H3>
					</Box>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px ',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.auditReportNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},

		{
			selector: '#sideBaradministrator',
			content: () => (
				<Box>
					<Box row>
						<IconWrapper
							size={20}
							style={{ padding: '1% 0% 0% 0%' }}
						>
							<Icon glyph={AdminIcon} color="#F6F6F6" />
						</IconWrapper>
						<Spacer size={8} horizontal />
						<H3
							style={{
								textAlign: 'left',
								font: 'normal normal bold 22px/36px ',
								letterSpacing: '0px',
								color: '#F6F6F6',
								opacity: 1,
							}}
						>
							{t('tourCommon.admin')}
						</H3>
					</Box>
					<Spacer size={10} />
					<P
						style={{
							textAlign: 'left',
							font: 'normal normal normal 16px ',
							letterSpacing: '0px',
							color: '#F6F6F6',
							opacity: 1,
						}}
					>
						{t('tourCommon.adminNote')}
					</P>
				</Box>
			),
			style: stepStyle,
			position: 'right',
		},
	]
}

export const tdmSteps = t => {
	return [
		{
			selector: '#tdmCreate',
			content: () => (
				<Box margin="8px">{t('tourCommon.createTradeDoc')}</Box>
			),
			style: stepStyle,
			position: 'right',
		},

		{
			selector: '#TDMTabs',
			content: () => (
				<Box margin="8px">{t('tourCommon.incominglist')}</Box>
			),
			style: stepStyle,
			position: 'right',
		},
	]
}

export const recordSteps = t => {
	return [
		{
			selector: '#tractorOperator_TractorOperator',
			content: () => (
				<Box margin="8px">{t('tourPlantation.tractorOpNote')}</Box>
			),
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#checker_Checker',
			content: () => (
				<Box margin="8px">{t('tourPlantation.checkerNote')}</Box>
			),
			style: stepStyle,
			position: 'right',
		},
	]
}
export const partnerSteps = t => {
	return [
		{
			selector: '#addPartner',
			content: () => <Box margin="8px">{t('tourCommon.addPartner')}</Box>,
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#partners_PartnerList',
			content: () => (
				<Box margin="8px">{t('tourCommon.viewPartners')}</Box>
			),
			style: stepStyle,
			position: 'right',
		},

		{
			selector: '#partnerships_PendingRequest',
			content: () => (
				<Box margin="8px">{t('tourCommon.pendingRequests')}</Box>
			),
			style: stepStyle,
			position: 'right',
		},
	]
}

export const tripsSteps = t => {
	return [
		{
			selector: '#tripsListing',
			content: () => <Box margin="8px">{t('tourCommon.viewTrips')}</Box>,
			style: stepStyle,
			position: 'right',
		},
	]
}

export const viewtripsSteps = t => {
	return [
		{
			selector: '#tripDO',
			content: () => (
				<Box margin="8px">{t('tourCommon.completedTripDetail')}</Box>
			),
			style: stepStyle,
			position: 'right',
		},

		{
			selector: '#viewTripDO',
			content: () => (
				<Box margin="8px">{t('tourCommon.viewDODetail')}</Box>
			),
			style: stepStyle,
			position: 'right',
		},
	]
}

export const wbTicketsSteps = t => {
	return [
		{
			selector: '#wbTicketUpload',
			content: () => (
				<Box margin="8px">{t('tourCommon.wbTicketsNote')}</Box>
			),
			style: stepStyle,
			position: 'right',
		},
		{
			// no id in the code as there is a scroll issue related. Need to add the id once scrollabilty solved
			selector: '#wbTicketListing',
			content: () => (
				<Box margin="8px">{t('tourCommon.wbTicketsListing')}</Box>
			),
			style: stepStyle,
			position: 'right',
		},
	]
}

export const traceSteps = t => {
	return [
		{
			selector: '#chooseTraceProduct',
			content: () => (
				<Box margin="8px">{t('tourCommon.viewProductTrace')}</Box>
			),
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#traceListing',
			content: () => (
				<Box margin="8px">{t('tourCommon.viewAllProdConsigmts')}</Box>
			),
			style: stepStyle,
			position: 'right',
		},
	]
}

export const viewTraceSteps = t => {
	return [
		{
			selector: '#viewOrgList',
			content: () => (
				<Box margin="8px">{t('tourCommon.viewAllOrgList')}</Box>
			),
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#product-trace-graph',
			content: () => (
				<Box margin="8px">{t('tourCommon.viewTraceGraph')}</Box>
			),
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#product-trace-map',
			content: () => (
				<Box margin="8px">{t('tourCommon.viewTraceMap')}</Box>
			),
			style: stepStyle,
			position: 'right',
		},
	]
}

export const adminSteps = t => {
	return [
		{
			selector: '#assetMgmt',
			content: () => <Box margin="8px">{t('tourCommon.assetMgmt')}</Box>,
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#employeeMgmt',
			content: () => (
				<Box margin="8px">{t('tourCommon.employeeMgmt')}</Box>
			),
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#companyInfo',
			content: () => (
				<Box margin="8px">{t('tourCommon.companyInfo')}</Box>
			),
			style: stepStyle,
			position: 'right',
		},
	]
}

export const adminStepsOfficeMgmt = t => {
	return [
		{
			selector: '#assetMgmt',
			content: () => <Box margin="8px">{t('tourCommon.assetMgmt')}</Box>,
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#employeeMgmt',
			content: () => (
				<Box margin="8px">{t('tourCommon.employeeMgmt')}</Box>
			),
			style: stepStyle,
			position: 'right',
		},
	]
}
export const settingsSteps = t => {
	return [
		{
			selector: '#settingsInfo',
			content: () => (
				<Box margin="8px">{t('tourCommon.settingsinfo')}</Box>
			),

			style: stepStyle,
			position: 'right',
		},
	]
}

export const productionMillSteps = t => {
	return [
		{
			selector: '#plotInputMillCreate',
			content: () => <Box margin="8px">{t('tourCommon.chooseProd')}</Box>,
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#transforming_InputBatch',
			content: () => (
				<Box margin="8px">{t('tourCommon.inputProdnList')}</Box>
			),
			style: stepStyle,
			position: 'right',
		},

		{
			selector: '#transformed_OutputBatch',
			content: () => <Box margin="8px">{t('tourCommon.outputList')}</Box>,
			style: stepStyle,
			position: 'right',
		},
	]
}
export const productionRefinerySteps = t => {
	return [
		{
			selector: '#plotInputRefineryCreate',
			content: () => <Box margin="8px">{t('tourCommon.chooseProd')}</Box>,
			style: stepStyle,
			position: 'right',
		},
		{
			selector: '#transforming_InputBatch',
			content: () => (
				<Box margin="8px">{t('tourCommon.inputProdnList')}</Box>
			),
			style: stepStyle,
			position: 'right',
		},

		{
			selector: '#transformed_OutputBatch',
			content: () => <Box margin="8px">{t('tourCommon.outputList')}</Box>,
			style: stepStyle,
			position: 'right',
		},
	]
}
