import { Tip } from 'grommet'
import React from 'react'
import { P } from 'ui-lib/components/Typography'
import { Box } from 'ui-lib/utils/Box'

const Tooltip = ({ heading = '', message = '' }) => {
	return (
		<Tip
			plain
			content={
				<Box
					style={{
						borderRadius: '8px',
					}}
				>
					<Box
						style={{
							fontSize: 12,
							maxWidth: 350,
							marginLeft: -1,
							marginTop: -4,
							background: '#141C2C',
							padding: '18px 24px',
						}}
					>
						<Box row>
							<Box
								alignItems="center"
								justifyContent="center"
								style={{
									background: '#FFEFD8',
									borderRadius: '18px',
									height: '36px',
									width: '36px',
								}}
							>
								<svg
									fill="#F0911E"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									width="24px"
									height="24px"
								>
									<path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 9 L 13 9 L 13 7 L 11 7 z M 11 11 L 11 17 L 13 17 L 13 11 L 11 11 z" />
								</svg>
							</Box>
							<P
								style={{
									marginLeft: '10px',
									color: '#F0911E',
									fontWeight: 'bold',
								}}
							>
								{heading}
							</P>
						</Box>

						<P
							style={{
								marginLeft: '48px',
								marginTop: '-12px',
								color: '#FFEFD8',
								fontSize: '16px',
							}}
						>
							{message}
						</P>
					</Box>
				</Box>
			}
			border={{ color: 'red' }}
		>
			<svg
				fill="#3F56C4"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 24 24"
				width="24px"
				height="24px"
			>
				<path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 9 L 13 9 L 13 7 L 11 7 z M 11 11 L 11 17 L 13 17 L 13 11 L 11 11 z" />
			</svg>
		</Tip>
	)
}
export { Tooltip }
