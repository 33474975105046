import React, { useEffect, useState } from 'react'
import { DataTable, CheckBox } from 'grommet'
import { getIn } from 'timm'
import theme from 'ui-lib/utils/base-theme'
import { Wrapper } from 'ui-lib/components/Table'

const TableWithSelect = ({
	columnConfig,
	rowData,
	onChecked: onCheckValues,
	participantInfo,
}) => {
	const [checked, setChecked] = useState([])
	const [documentIDCount, setDocumentIDCount] = useState(0)
	const [checkAllStatus, setCheckAllStatus] = useState(false)
	const [participantDetails, setParticipantDetails] = useState([])
	const onCheck = (event, value, participant) => {
		if (event.target.checked) {
			setChecked([...checked, value])
			setParticipantDetails([...participantDetails, participant])
		} else {
			setChecked(checked.filter(item => item !== value))
			setParticipantDetails(
				participantDetails.filter(
					p =>
						p.scheduleParticipantID !==
						participant.scheduleParticipantID
				)
			)
		}
	}

	const onCheckAll = event => {
		const documentIDs = []
		const allParticipantsInfo = []
		// eslint-disable-next-line array-callback-return
		rowData.map(d => {
			if (d.status === 'pending' || d.status === 'upcoming') {
				documentIDs.push(d.id)
				const participantOrganization = d.participants.filter(
					participant => participant.clientType === 'org'
				)
				allParticipantsInfo.push({
					scheduleID: getIn(participantOrganization, [
						0,
						'scheduleID',
					]),
					scheduleParticipantID: getIn(participantOrganization, [
						0,
						'id',
					]),
				})
			}
		})

		setDocumentIDCount(documentIDs.length)
		setChecked(event.target.checked && !checkAllStatus ? documentIDs : [])
		setParticipantDetails(
			event.target.checked && !checkAllStatus ? allParticipantsInfo : []
		)
		setCheckAllStatus(!checkAllStatus)
	}

	useEffect(() => {
		onCheckValues(checked)
		participantInfo(participantDetails)
	}, [checked, onCheckValues, participantDetails, participantInfo])

	return (
		<Wrapper>
			<DataTable
				alignSelf="center"
				border={{
					header: {
						color: theme.color.grey4,
						side: 'bottom',
					},
					body: {
						color: theme.color.grey4,
						side: 'bottom',
					},
				}}
				pad={{ horizontal: 'small', vertical: 'small' }}
				columns={[
					{
						property: 'checkbox',
						header: (
							<CheckBox
								checked={
									documentIDCount > 0 &&
									checked.length === documentIDCount
								}
								indeterminate={
									checked.length > 0 &&
									checked.length < documentIDCount
								}
								onChange={onCheckAll}
							/>
						),
						render: datum => {
							const { id, status, participants = [] } = datum
							const participantOrganization = participants.filter(
								participant => participant.clientType === 'org'
							)

							if (status === 'pending' || status === 'upcoming')
								return (
									<CheckBox
										key={id}
										checked={checked.indexOf(id) !== -1}
										onChange={e => {
											const participant = {
												scheduleID: getIn(
													participantOrganization,
													[0, 'scheduleID']
												),
												scheduleParticipantID: getIn(
													participantOrganization,
													[0, 'id']
												),
											}

											onCheck(e, id, participant)
										}}
									/>
								)
						},
						size: '5%',
					},
					...columnConfig,
				]}
				data={rowData}
			/>
		</Wrapper>
	)
}

export { TableWithSelect }
