import React from 'react'
import theme from '../../utils/base-theme'

type Props = {
	title: string,
	data: Record<string, unknown>[],
	isMobile: Boolean,
}

const Timeline = ({ title, data, isMobile = false }: Props) => {
	return (
		<div>
			<div
				style={{
					fontSize: 24,
					fontWeight: 600,
					color: '#8D93A0',
					marginBottom: 50,
				}}
			>
				{title}
			</div>
			<div
				style={{
					width: 1,
					marginLeft: isMobile ? 150 : 300,
					position: 'relative',
					borderLeft: '1px solid rgb(100, 93, 183, 0.1)',
					height: data.length > 0 ? (data.length - 1) * 100 : 0,
				}}
			>
				{data.map((item, index) => (
					<>
						<div
							style={{
								top: index * 100 - 13,
								left: isMobile ? -135 : -150,
								zIndex: 10,
								width: 100,
								fontSize: 14,
								display: 'flex',
								color: theme.color.blue8,
								position: 'absolute',
								alignItems: 'center',
							}}
						>
							{item && item.date ? item.date : ''}
						</div>
						<div
							style={{
								top: index * 100,
								left: -6,
								width: 12,
								height: 12,
								zIndex: 10,
								borderRadius: 12,
								position: 'absolute',
								backgroundColor: '#645DB7',
							}}
						/>
						<div
							style={{
								top: index * 100 - 14,
								left: isMobile ? 10 : 100,
								zIndex: 10,
								width: isMobile ? 215 : 500,
								display: 'flex',
								color: '#3F57C6',
								position: 'absolute',
								alignItems: 'center',
							}}
						>
							{!isMobile && (
								<div
									style={{
										width: 32,
										height: 32,
										borderRadius: 4,
										background: '#E5E5E5',
									}}
								/>
							)}
							<div style={{ marginLeft: 32 }}>
								<div style={{ fontSize: 16, fontWeight: 600 }}>
									{item && item.status ? item.status : ''}
								</div>
								{item && item.statusDetail ? (
									<div style={{ fontSize: 16 }}>
										{item && item.statusDetail
											? item.statusDetail
											: ''}
									</div>
								) : (
									''
								)}
								{item && item.version ? (
									<div
										style={{
											fontSize: 16,
											cursor: item.versionPath
												? 'pointer'
												: 'default',
										}}
										onClick={() => {
											if (item.versionPath) {
												window.open(
													item.versionPath,
													'_blank'
												)
											}
										}}
										role="button"
										tabIndex={0}
										onKeyDown={() => {}}
									>
										{`version: ${
											item && item.version
												? item.version
												: ''
										}`}
									</div>
								) : (
									''
								)}
							</div>
						</div>
						{(index === 0 || index === data.length - 1) && (
							<div
								style={{
									top: index * 100 - 6,
									left: -12,
									width: 24,
									height: 24,
									borderRadius: 24,
									position: 'absolute',
									backgroundColor: '#B6B2E5',
								}}
							/>
						)}
					</>
				))}
			</div>
		</div>
	)
}

export { Timeline }
