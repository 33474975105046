import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { getIn } from 'timm'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { MainRouteDuc } from 'core-app/routes/duc'
import {
	FormHeader,
	FormBody,
	FormFooter,
} from 'core-app/shared/components/Fragments'
import { supplyChainModels } from 'core-app/modules/Admin/components/StorageDetails'
import { Table } from 'ui-lib/components/Table'
import { Box } from 'ui-lib/utils/Box'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { Select } from 'ui-lib/components/Select'
import { Card } from 'ui-lib/components/Card'
import { TextArea } from 'ui-lib/components/TextArea'
import { Input } from 'ui-lib/components/Input'
import { H2, P, SmallText, Text, Label } from 'ui-lib/components/Typography'
import { Button, ButtonWithNoBorder } from 'ui-lib/components/Button'
import { Spacer } from 'ui-lib/utils/Spacer'
import theme from 'ui-lib/utils/base-theme'
import DeleteIcon from 'ui-lib/icons/deleteTrash.svg'
import ReportProblemIcon from 'ui-lib/icons/report_problem.svg'

const columnConfig = ({ t, onDelete }) => {
	return [
		{
			property: 'tank',
			header: (
				<Box style={{ cursor: 'pointer' }}>
					<Label
						bold
						style={{
							color: '#4B4552',
							fontSize: 16,
							textAlign: 'center',
						}}
					>
						Tank
					</Label>
				</Box>
			),
			render: datum => {
				const tank = getIn(datum, ['name'])

				return (
					<Label
						as="span"
						style={{
							fontSize: 16,
							textAlign: 'center',
						}}
					>
						{tank}
					</Label>
				)
			},
			size: '25%',
		},
		{
			property: 'quantity',
			header: (
				<Box style={{ cursor: 'pointer' }}>
					<Label
						bold
						style={{
							color: '#4B4552',
							fontSize: 16,
							textAlign: 'center',
						}}
					>
						Quantity
					</Label>
				</Box>
			),
			render: datum => {
				const quantity = getIn(datum, ['quantity'])
					.toString()
					.match(/^-?\d+(?:\.\d{0,3})?/)[0]

				return (
					<Label
						as="span"
						style={{
							fontSize: 16,
							textAlign: 'center',
						}}
					>
						{quantity}
					</Label>
				)
			},
			size: '25%',
		},
		{
			property: 'scModel',
			header: (
				<Box style={{ cursor: 'pointer' }}>
					<Label
						bold
						style={{
							color: '#4B4552',
							fontSize: 16,
							textAlign: 'center',
						}}
					>
						Supply Chain Model
					</Label>
				</Box>
			),
			render: datum => {
				const supplyChainModel = getIn(datum, ['supplyChainModel'])

				return (
					<Label
						as="span"
						style={{
							fontSize: 16,
							textAlign: 'center',
						}}
					>
						{t(supplyChainModels[supplyChainModel])}
					</Label>
				)
			},
			size: '25%',
		},
		{
			property: 'delete',
			render: datum => {
				const tank = getIn(datum, ['storageUnitID'])

				return (
					<Box row justifyContent="center">
						<IconWrapper
							hoverEffect
							width={25}
							height={25}
							onClick={() => {
								onDelete(tank)
							}}
						>
							<Icon glyph={DeleteIcon} center />
						</IconWrapper>
					</Box>
				)
			},
			size: '25%',
		},
	]
}

const supplyChainMixingCriteria = {
	'identity-preserved ~ identity-preserved': 'identity-preserved',
	'identity-preserved ~ segregated': 'segregated',
	'identity-preserved ~ mass-balance': 'mass-balance',
	'mass-balance ~ identity-preserved': 'mass-balance',
	'mass-balance ~ segregated': 'mass-balance',
	'mass-balance ~ mass-balance': 'mass-balance',
	'segregated ~ identity-preserved': 'segregated',
	'segregated ~ segregated': 'segregated',
	'segregated ~ mass-balance': 'mass-balance',
	'none ~ identity-preserved': 'mass-balance',
	'none ~ segregated': 'mass-balance',
	'none ~ mass-balance': 'mass-balance',
	'none ~ none': 'none',
}

const AssignInventoryToTank = ({
	onClose,
	productDetails,
	activeTanks,
	storageList,
	inventorySCModel,
	onChange,
}) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const allProducts = useSelector(AuthDuc.selectors.getProducts)
	const productName = allProducts.filter(
		product => product.id === productDetails.productID
	)
	const curentProductName = getIn(productName, [0, 'name']) || ''
	// const uom = getIn(productName, [0, 'uom']) || ''

	const [quantity, setQuantity] = useState(0)
	const [storageUnit, setStorageUnit] = useState({})
	const [storageQuantityEntry, setStorageQuantityEntry] = useState(
		activeTanks || [{}]
	)
	const [warning, showWarning] = useState(false)
	const [confirmWithUserInput, setConfirmWithUserInput] = useState(false)
	const [userInput, setUserInput] = useState('')
	const disableInventoryAssignment = storageUnit[0] === {} || !quantity

	const filteredStorageList = storageList
		.filter(
			tk =>
				productDetails.productID ===
					getIn(tk, ['meta', 'initialProducts', 0, 'productID']) &&
				getIn(tk, ['meta', 'inventoryType']) === 'outgoing' &&
				getIn(tk, ['meta', 'status']) !== 'locked'
		)
		.map(({ name, id, capacity, capacityUOM, meta }) => {
			const currentQty = meta?.storedProducts
				? meta.storedProducts.reduce((accumulator, item) => {
						return accumulator + item.availableQty
				  }, 0)
				: 0

			const getCapacity = capacity
				? `(${t('viewAsset.capacity')}: ${capacity}${capacityUOM})`
				: ''

			return {
				storageUnitID: id,
				name: `${name} (${t(
					'viewAsset.currentQty'
				)}: ${currentQty}${capacityUOM || ''}) ${getCapacity}`,
				supplyChainModel: getIn(meta, ['supplyChainModel']),
				inventoryType: getIn(meta, ['inventoryType']),
				certification: getIn(meta, ['certificationType']),
			}
		})

	function convertedSupplyChainModel() {
		const key = `${inventorySCModel} ~ ${storageUnit.supplyChainModel}`

		return supplyChainMixingCriteria[key]
	}

	function checkIfSupplyChainIsMixed() {
		const allowedCombinations = [
			'identity-preserved ~ identity-preserved',
			'segregated ~ segregated',
			'mass-balance ~ mass-balance',
			'none ~ none',
		]

		const key = `${inventorySCModel} ~ ${storageUnit.supplyChainModel}`

		if (!allowedCombinations.includes(key)) {
			showWarning(true)
		} else {
			setStorageQuantityEntry([
				...storageQuantityEntry,
				{
					...storageUnit,
					quantity,
					supplyChainModel: convertedSupplyChainModel(),
				},
			])
			setStorageUnit([{}])
			setQuantity(0)
		}
	}

	const handleDelete = useCallback(
		tank => {
			const newStorageList = storageQuantityEntry.filter(
				tk => tk.storageUnitID !== tank
			)
			setStorageQuantityEntry(newStorageList)
		},
		[storageQuantityEntry]
	)

	return (
		<Box>
			<FormHeader
				row
				justifyContent="space-between"
				alignItems="flex-end"
			>
				<H2 primary>Assign Inventory</H2>
				<Box>
					{productDetails.productID && (
						<Box row>
							<Text
								style={{
									lineHeight: '1.5',
									fontSize: theme.fontSize.m,
								}}
							>
								{t('storage.producedProduct')}
							</Text>
							<P>{` : ${curentProductName}`}</P>
						</Box>
					)}
					{inventorySCModel && (
						<Box row>
							<Text
								style={{
									lineHeight: '1.5',
									fontSize: theme.fontSize.m,
								}}
							>
								{t('tdmDetailsEntry.supplyChainModel')} of
								Produced Inventory
							</Text>
							<P>{` : ${t(
								supplyChainModels[inventorySCModel]
							)}`}</P>
						</Box>
					)}
				</Box>
			</FormHeader>
			<FormBody padding="20px 0px">
				<Card
					style={{
						padding: '0px 20px',
						position: 'relative',
						overflow: 'visible',
						background: '#FAFBFF',
						border: '1px solid #D9DEE5',
						borderRadius: '6px',
						margin: '20px 0',
					}}
					textAlign="left"
				>
					<SmallText
						style={{
							position: 'absolute',
							fontSize: theme.fontSize.m,
							color: theme.color.accent2,
							top: '-10px',
							left: '20px',
							display: 'inline-block',
							backgroundColor: '#FAFBFF',
							padding: '0 8px',
						}}
					>
						{t('common.selectStorageAndQuantity')}
					</SmallText>
					<Box row style={{ width: '100%', marginTop: '20px' }}>
						<Select
							required
							name="storage"
							key={storageUnit.storageUnitID}
							label={t('production.storage')}
							options={filteredStorageList}
							labelKey="name"
							valueKey="storageUnitID"
							value={storageUnit.storageUnitID}
							onChange={value => {
								setStorageUnit(value)
							}}
							returnOnlyValue
						/>
						<Spacer size={40} horizontal />
						<Box style={{ width: '200px' }}>
							<Input
								label={t('tdmDetailsEntry.quantity')}
								name="quantity"
								type="number"
								required
								value={quantity}
								onChange={e =>
									setQuantity(e.target.valueAsNumber)
								}
								placeholder="0"
							/>
						</Box>
						<Spacer size={40} horizontal />
						<Box
							justifyContent="center"
							width={156}
							padding={1}
							height="inherit"
							overflow
						>
							<Button
								label={
									<P color="white">
										<strong>Assign Inventory</strong>
									</P>
								}
								onClick={() => {
									checkIfSupplyChainIsMixed()
								}}
								primary
								disabled={disableInventoryAssignment}
							/>
						</Box>
					</Box>
				</Card>
				{filteredStorageList.length === 0 && (
					<SmallText
						style={{
							fontSize: theme.fontSize.m,
							color: theme.color.accent2,
						}}
					>
						No tanks available for {curentProductName}.
						<ButtonWithNoBorder
							label={t('production.errorHelpCTA')}
							tabIndex="-1"
							onClick={() => {
								dispatch(
									MainRouteDuc.creators.switchPage(
										MainRouteDuc.types
											.ADMIN$SUBMODULEWACTION,
										{
											rootModule: 'assets',
											subModule: 'storageunits',
											action: 'create-asset',
										}
									)
								)
							}}
						/>
					</SmallText>
				)}
				{warning && (
					<>
						<Box row padding={8} alignItems="center">
							<IconWrapper
								size={30}
								style={{
									padding: 4,
									display: 'flex',
									color: theme.color.error,
									background: '#fff',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<Icon glyph={ReportProblemIcon} />
							</IconWrapper>
							<SmallText
								style={{
									marginLeft: 12,
									fontWeight: 'bold',
									color: theme.color.error,
									fontSize: 16,
								}}
							>
								Warning!
							</SmallText>
						</Box>
						<P
							style={{
								marginTop: 4,
								padding: '0 12px',
							}}
						>
							The chosen tank has a Supply Chain Model of&nbsp;
							<strong>
								{t(
									supplyChainModels[
										storageUnit.supplyChainModel
									]
								)}
							</strong>
							. Produced Inventory has a Supply Chain Model of
							&nbsp;
							<strong>
								{t(supplyChainModels[inventorySCModel])}
							</strong>
							. Assigning the produced inventory to this tank
							would change the supply chain model of the tank
							to&nbsp;
							<strong>
								{t(
									supplyChainModels[
										convertedSupplyChainModel()
									]
								)}
							</strong>
						</P>

						<Box
							row
							justifyContent="center"
							alignItems="baseline"
							style={{
								background: '#fff',
								fontSize: 16,
								margin: '8px 0',
							}}
						>
							Are you sure you want to proceed?
							<Button
								extendStyles={{ margin: '0px 28px 0px 50px' }}
								primary
								label="No"
								onClick={() => {
									showWarning(false)
								}}
							/>
							<Button
								outline
								label="Yes"
								onClick={() => {
									showWarning(false)
									setConfirmWithUserInput(true)
								}}
							/>
						</Box>
					</>
				)}
				{confirmWithUserInput && (
					<>
						<Box padding="4px 12px" width="auto">
							<TextArea
								label="Please type YES"
								size={150}
								maxLength={150}
								name="userInput"
								value={userInput}
								onChange={e => {
									setUserInput(e.target.value)
								}}
							/>
							<Box row justifyContent="flex-end">
								<Button
									label="Submit"
									primary
									disabled={userInput.toLowerCase() !== 'yes'}
									onClick={() => {
										setStorageQuantityEntry([
											...storageQuantityEntry,
											{
												...storageUnit,
												quantity,
												supplyChainModel: convertedSupplyChainModel(),
											},
										])
										setStorageUnit([{}])
										setQuantity(0)
										setConfirmWithUserInput(false)
										setUserInput('')
									}}
									extendStyles={{
										height: 40,
										width: 120,
									}}
								/>
							</Box>
						</Box>
					</>
				)}
				{storageQuantityEntry.length > 0 && (
					<Table
						columnConfig={columnConfig({
							t,
							onDelete: handleDelete,
						})}
						rowData={storageQuantityEntry}
						size="small"
						noRoundedBorder
					/>
				)}
				<Spacer size={64} />
			</FormBody>
			<FormFooter row justifyContent="flex-end" style={{ width: '100%' }}>
				<Box center row style={{ maxWidth: 375 }}>
					<ButtonWithNoBorder
						label={t('common.cancel')}
						tabIndex="-1"
						onClick={() => {
							onClose()
						}}
					/>
					<Spacer size={10} horizontal />
					<Box
						justifyContent="center"
						width={150}
						padding={1}
						height="inherit"
						overflow
					>
						<Button
							label={
								<P color="white">
									<strong>{t('common.submit')}</strong>
								</P>
							}
							onClick={() => {
								onChange(storageQuantityEntry)
							}}
							primary
							disabled={!storageQuantityEntry.length}
						/>
					</Box>
				</Box>
			</FormFooter>
		</Box>
	)
}

export { AssignInventoryToTank }
