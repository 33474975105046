import React from 'react'
import styled from 'styled-components'
import { Icon } from 'ui-lib/icons/components/Icon'
import { Button, ButtonIconWrapper } from 'ui-lib/components/Button'
import { useTranslation } from 'react-i18next'
import { useDateByFormat } from 'core-app/utils/date'
import leftArrow from 'ui-lib/icons/left-arrow.svg'
import rightArrow from 'ui-lib/icons/right-arrow.svg'
import theme from 'ui-lib/utils/base-theme'

const MainContainer = styled.div`
	display: flex;
	justify-content: space-between;
`
const PrevButtonContainer = styled.div``
const DateContainer = styled.div`
	width: 300px;
`
const MainTextContainer = styled.div`
	display: flex;
	font-size: 18px;
	font-weight: 500;
	justify-content: center;
`
const SubTextContainer = styled.div`
	display: flex;
	justify-content: center;
`

const SubTextSpan = styled.div`
	color: ${theme.color.grey21};
`
const NextButtonContainer = styled.div``

const WeekHandlingComponent = ({
	weekNumber,
	firstDayOfWeek,
	lastDayOfWeek,
	currentDate,
	onChange,
}) => {
	const { t } = useTranslation()

	return (
		<MainContainer>
			<PrevButtonContainer>
				<Button
					label={t('production.previousWeek')}
					type="submit"
					// disabled={disableCTA}
					primary
					rounded
					customIcon={
						<ButtonIconWrapper lightBG>
							<Icon glyph={leftArrow} />
						</ButtonIconWrapper>
					}
					onClick={() => {
						const previousWeekStartDate = new Date(firstDayOfWeek)
						previousWeekStartDate.setDate(
							previousWeekStartDate.getDate() - 7
						)

						const previousWeekEndDate = new Date(lastDayOfWeek)
						previousWeekEndDate.setDate(
							previousWeekEndDate.getDate() - 7
						)
						onChange(previousWeekStartDate, previousWeekEndDate)
					}}
					extendStyles={{
						width: '232px',
					}}
				/>
			</PrevButtonContainer>
			<DateContainer>
				<MainTextContainer>{`${t(
					'production.week'
				)} ${weekNumber}`}</MainTextContainer>
				<SubTextContainer>
					<SubTextSpan>
						{`${useDateByFormat(
							firstDayOfWeek
						)} - ${useDateByFormat(lastDayOfWeek)}`}
					</SubTextSpan>
				</SubTextContainer>
			</DateContainer>
			<NextButtonContainer>
				<Button
					label={t('production.nextWeek')}
					type="submit"
					disabled={
						new Date(currentDate) >= new Date(firstDayOfWeek) &&
						new Date(currentDate) <= new Date(lastDayOfWeek)
					}
					primary
					rounded
					reverse
					customIcon={
						<ButtonIconWrapper lightBG>
							<Icon glyph={rightArrow} />
						</ButtonIconWrapper>
					}
					onClick={() => {
						const nextWeekStartDate = new Date(firstDayOfWeek)
						nextWeekStartDate.setDate(
							nextWeekStartDate.getDate() + 7
						)

						const nextWeekEndDate = new Date(lastDayOfWeek)
						nextWeekEndDate.setDate(nextWeekEndDate.getDate() + 7)
						onChange(nextWeekStartDate, nextWeekEndDate)
					}}
					extendStyles={{
						width: '232px',
					}}
				/>
			</NextButtonContainer>
		</MainContainer>
	)
}

export { WeekHandlingComponent }
