import React, { useCallback, useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { MainRouteDuc } from 'core-app/routes/duc'
import { getIn } from 'timm'
import { TabButtons } from 'ui-lib/components/TabButtons'
import { Label, P, H2, TableTitle } from 'ui-lib/components/Typography'
import { Box } from 'ui-lib/utils/Box'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Spacer } from 'ui-lib/utils/Spacer'
import { applyMediaQueries } from 'ui-lib/utils/helpers'
import { BoxShadowWrapper } from 'ui-lib/components/Card'
import { TraceGraphV1 } from 'ui-lib/components/Trace-Graph-V1'
import { TraceTreeV1 } from 'ui-lib/components/Trace-Tree-V1'
import { getDateByFormat } from 'core-app/utils/date'
import { useTranslation } from 'react-i18next'
import ErrorIcon from 'ui-lib/icons/error-round.svg'
import SuccessIcon from 'ui-lib/icons/success-round.svg'
import { Drop } from 'grommet'
import {
	Button,
	ButtonIconWrapper,
	ButtonWithNoBorder,
} from 'ui-lib/components/Button'
import { TransparentBgSpinner } from 'ui-lib/components/Spinner/TransparentBgSpinner'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { FullScreen } from 'ui-lib/components/FullScreen'
import FullScreenIcon from 'ui-lib/icons/fullscreen.svg'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import NotVerifiedIcon from 'ui-lib/icons/not_verified.svg'
// import VerifiedIcon from 'ui-lib/icons/verified.svg'
import { TraceMapsV1 } from 'ui-lib/components/TraceMaps-V1'
import { BoxShadowTable } from 'core-app/shared/components/BoxShadowTable'
import theme from 'ui-lib/utils/base-theme'
import { TraceDuc } from '../duc'

const TABLE_ALIAS = {
	createdAt: 'productTrace.createdAt',
	product: 'productTrace.product',
	quantity: 'productTrace.quantity',
	consignmentNumber: 'productTrace.consignmentNumber',
	transporter: 'productTrace.transporter',
	certificate: 'productTrace.certificate',
}

const SegmentSeparatorWrapper = styled.div({
	position: 'relative',
	height: 'inherit',
})

const Line = styled.div({
	borderRight: '1px dotted grey',
})

const SegmentSeparatorLine = styled.div({
	height: '100%',
	width: 'auto',
	border: '1px solid #eae9e9',
	position: 'absolute',
	left: '50%',
	top: '50%',
	transform: 'translate(-50%, -50%)',
	...applyMediaQueries(['mobile'], {
		height: 'auto',
		width: '100%',
	}),
})

const Heading = styled(H2)({
	marginBottom: 0,
})

const SummaryWrapper = styled(Box)({
	paddingRight: 40,
	'&:last-child': {
		paddingRight: 'initial',
	},
	...applyMediaQueries(['mobile'], {
		paddingTop: 4,
		paddingBottom: 4,
	}),
})

const SummaryBlock = ({
	name,
	detail,
	tagline,
	icon,
	status,
	tooltip,
	tooltipMsg,
	detailStyle,
}) => {
	const [tooltipToggle, setTooltipToggle] = useState(true)
	const ref = useRef()

	return (
		<SummaryWrapper
			style={{ cursor: tooltip ? 'pointer' : '' }}
			onClick={() => tooltip && setTooltipToggle(!tooltipToggle)}
		>
			<Box row alignItems="center">
				{icon && (
					<>
						<IconWrapper color="green" size={20} ref={ref}>
							<Icon glyph={icon} />
						</IconWrapper>
						<Spacer size={8} horizontal />
						{tooltipToggle && tooltip && (
							<Drop
								target={ref.current}
								style={{
									background: 'none',
									boxShadow: 'none',
									border: 'none',
								}}
							>
								<Box
									style={{
										background: '#333',
										width: '99%',
										padding: 16,
										maxWidth: 300,
										marginTop: 24,
									}}
								>
									{tooltipMsg.map(msg => (
										<Label
											color="#fff"
											small
											key={msg}
											style={{
												borderLeft: '3px solid #e07616',
												paddingLeft: 16,
												cursor: 'inherit',
											}}
										>
											{msg}
										</Label>
									))}
								</Box>
							</Drop>
						)}
					</>
				)}

				<Label color="#263b97" style={{ cursor: 'inherit' }}>
					{name}:
				</Label>
				<Spacer size={16} horizontal />
				<Label
					color={status || '#000000'}
					style={detailStyle || { cursor: 'inherit' }}
					title={detail}
				>
					{detail}
				</Label>
				{tagline && (
					<>
						<Spacer size={12} horizontal />
						<P small>{tagline}</P>
					</>
				)}
			</Box>
		</SummaryWrapper>
	)
}

const KeyValueSegmentWithClickableLink = ({ name, detail }) => {
	return (
		<>
			<Box row padding="2px 0px">
				<Box width={150}>
					<Label color="#263b97" bold>
						{name}
					</Label>
				</Box>
				<Box style={{ marginLeft: 10 }} flex row>
					{detail.map(item => (
						<>
							<Label
								style={{
									color: item.certURL
										? 'rgb(38, 59, 151)'
										: theme.color.black,
								}}
								link={item.certURL}
								onClick={() => {
									window.open(item.certURL, '_blank')
								}}
							>
								{item.ctype},
							</Label>
							<Spacer size={4} horizontal />
						</>
					))}
				</Box>
			</Box>
			<Spacer size={8} />
		</>
	)
}
const KeyValueSegment = ({ name, detail, openlink }) => {
	return (
		<>
			<Box row padding="2px 0px">
				<Box width={150}>
					<Label color="#263b97" bold>
						{name}
					</Label>
				</Box>
				<Box style={{ marginLeft: 10 }} flex row>
					<P large>{detail}</P>
					{openlink && (
						<>
							<Spacer size={16} horizontal />
							<Label
								style={{
									color: 'rgb(38,59,151)',
								}}
								link
								onClick={() => {
									const [long, lat] = detail.split(',')
									window.open(
										`https://www.google.com/maps/search/?api=1&query=${lat},${long}`,
										'_blank'
									)
								}}
							>
								(view on map)
							</Label>
						</>
					)}
				</Box>
			</Box>
			<Spacer size={8} />
		</>
	)
}

export const traceTableConfig = t => {
	return [
		{
			property: 'createdAt',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.createdAt)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { createdAt } = datum

				return <Label as="span">{getDateByFormat(createdAt)}</Label>
			},
		},
		{
			property: 'product',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.product)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { product } = datum

				if (product) return <Label as="span">{product}</Label>

				return 'N/A'
			},
		},
		{
			property: 'quantity',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.quantity)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { quantity } = datum

				if (quantity) return <Label as="span">{quantity}</Label>

				return 'N/A'
			},
		},
		{
			property: 'blockchainVerification',
			render: datum => {
				const { blockChainFailed } = datum

				if (blockChainFailed)
					return (
						<IconWrapper size={25} color="#D05858">
							<Icon glyph={NotVerifiedIcon} />
						</IconWrapper>
					)
			},
		},
		{
			property: 'status',
			render: datum => {
				const { status } = datum

				if (status === 'success')
					return (
						<IconWrapper color="green" size={20}>
							<Icon glyph={SuccessIcon} />
						</IconWrapper>
					)
			},
		},
	]
}

const GenerateTraceV1 = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const treeRecord = useSelector(TraceDuc.selectors.getActiveTraceTreeRecord)
	const hederaMessages = useSelector(TraceDuc.selectors.getHederaMessages)
	const initialOrgName = getIn(treeRecord, [0, 'title']) || ''
	// const initialBlockChainStatus = getIn(treeRecord, [0, 'blockChainStatus'])

	const status = getIn(treeRecord, [0, 'status']) || ''
	const certificationStatus = getIn(treeRecord, [0, 'certificationStatus'])
	const [tooltipMsg, setTooltipMsg] = useState('')

	const entityOrg = getIn(treeRecord, [0, 'transaction', 0, 'entityID']) || ''
	const entityNumber =
		getIn(treeRecord, [0, 'transaction', 0, 'entityNumber']) || ''

	const partnerName = getIn(treeRecord, [0, 'children', 0, 'title']) || ''
	const productName =
		getIn(treeRecord, [0, 'transaction', 0, 'product']) || ''
	const quantity = getIn(treeRecord, [0, 'transaction', 0, 'quantity']) || ''
	const graphRecord = useSelector(
		TraceDuc.selectors.getActiveTraceGraphRecord
	)

	const loadingState = useSelector(
		TraceDuc.selectors.getProductTraceLoadingState
	)

	const _graphRecord = Object.assign({}, graphRecord)
	const [orgName, setActiveOrgName] = useState(initialOrgName)
	const [massBalance, setMassBalance] = useState(status)
	const [certStatus, setCertStatus] = useState(certificationStatus)
	// const [blockChainStatus, setBlockChainStatus] = useState(
	// 	initialBlockChainStatus
	// )

	const intitalDocument = getIn(treeRecord, [0, 'transaction']) || []
	const intitalTransformedDocument =
		getIn(treeRecord, [0, 'transformed']) || []
	const intitalTransformingDocument =
		getIn(treeRecord, [0, 'transforming']) || []

	const mapRecord = useSelector(TraceDuc.selectors.getActiveTraceMapRecord)
	const [documents, setDocuments] = useState(intitalDocument)
	const [transformedDocuments, setTransformedDocuments] = useState(
		intitalTransformedDocument
	)
	const [transformingDocuments, setTransformingDocuments] = useState(
		intitalTransformingDocument
	)

	const [activeStatus, setActiveStatus] = useState('trace-tree')
	const badNodePaths = useSelector(TraceDuc.selectors.getBadNodePaths)

	// const blockchainDisabledStatus = useSelector(
	// 	TraceDuc.selectors.getBlockChainStatus
	// )

	// const isBlockchainDisabled = getIn(blockchainDisabledStatus, [0]) || false
	// const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)
	const certificationStatusLabel = {
		verified: t('productTrace.verified'),
		unverified: t('productTrace.notVerified'),
	}

	const typesStatusLabel = {
		good: t('productTrace.verified'),
		bad: t('productTrace.notVerified'),
	}

	/** Handlers to react on actions */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'product-trace') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.PRODUCT_TRACE
				)
			)
		}
	}

	const handleOnChange = useCallback((actionType, meta = {}) => {
		switch (actionType) {
			case 'apply_filter': {
				const { filterValue } = meta
				setActiveStatus(filterValue)
				break
			}
			case 'on_node_click': {
				const { transforming, transformed, transaction } = meta
				setDocuments(transaction)
				setTransformedDocuments(transforming)
				setTransformingDocuments(transformed)
				break
			}

			default:
				break
		}
	}, [])

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.productTrace'),
			name: 'product-trace',
			isActive: true,
		},
		{
			label: t('breadcrumb.generateTrace'),
			name: 'generate-trace',
			isActive: false,
		},
	]

	const FILTERS_STATUS_CONFIG = [
		{
			name: 'trace-tree',
			label: t('productTrace.tree'),
		},
		{
			name: 'trace-graph',
			label: t('productTrace.graph'),
		},
		{
			name: 'trace-map',
			label: t('productTrace.map'),
		},
	]

	let boxheight = activeStatus === 'trace-tree' ? 600 : 400
	boxheight = activeStatus === 'trace-map' ? 600 : boxheight

	const coordinates = []
	mapRecord.forEach(coordinate => {
		if (!Number.isNaN(coordinate.coordinates[0])) {
			coordinates.push(coordinate.coordinates)
		}
	})

	useEffect(() => {
		let trasformingUpdated = transformedDocuments
		if (transformedDocuments.length > 0 && hederaMessages.data.length > 0) {
			trasformingUpdated = transformedDocuments.map(item => ({
				...item,
				status: hederaMessages.data.find(
					message => message.docReferenceID === item.traceID
				)
					? 'success'
					: '',
			}))
		}
		setTransformedDocuments(trasformingUpdated)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hederaMessages])

	return (
		<>
			<Box padding={8} width="100%" height="100%">
				<Box style={{ padding: '0 5' }}>
					<Breadcrumb
						links={breadCrumbs}
						onClick={target => handleBreadCrumbClick(target)}
					/>
				</Box>
				{loadingState && <TransparentBgSpinner />}
				<Spacer size={16} />
				<Box row justifyContent="space-between" alignItems="baseline">
					<Box>
						<Heading>{t('productTrace.title')}</Heading>
						<Box row alignItems="center" width>
							<Box>
								<P>{`${t(
									'productTrace.showingTraceOf'
								)} ${partnerName} ${t(
									'productTrace.forConsgmt'
								)}`}</P>
							</Box>
							<ButtonWithNoBorder
								type="submit"
								extendStyles={{ padding: '0px 5px	' }}
								onClick={() =>
									dispatch(
										MainRouteDuc.creators.switchPage(
											MainRouteDuc.types
												.TRADE_DOCUMENT_MANAGER$VIEWWDOCREFERENCE,
											{
												rootModule: 'delivery-order',
												documentReference: entityOrg,
												documentStatus: null,
											},
											{},
											{},
											true
										)
									)
								}
								label={entityNumber}
							/>
						</Box>

						<P>{`${t('productTrace.product')}: ${productName} & ${t(
							'productTrace.srcdQty'
						)}: ${quantity}.`}</P>
					</Box>

					<Box row justifyContent="flex-end">
						<Box style={{ padding: 2 }} width="200px">
							<Button
								label={t('common.back')}
								rounded
								customIcon={
									<ButtonIconWrapper lightBG>
										<Icon glyph={LeftArrowIcon} />
									</ButtonIconWrapper>
								}
								onClick={() =>
									dispatch(
										MainRouteDuc.creators.switchPage(
											MainRouteDuc.types.PRODUCT_TRACE
										)
									)
								}
							/>
						</Box>
					</Box>
				</Box>

				<Spacer size={32} />
				<BoxShadowWrapper>
					<Box
						row
						wrap
						justifyContent="space-between"
						style={{ padding: '20px 32px' }}
					>
						<SummaryBlock
							name={t('productTrace.companyName')}
							detail={orgName}
							detailStyle={{
								width: 225,
								overflow: 'hidden',
								whiteSpace: 'nowrap',
								textOverflow: 'ellipsis',
							}}
						/>
						<Line />
						<SummaryBlock
							name={t('productTrace.certificationStatus')}
							detail={certificationStatusLabel[certStatus]}
							icon={
								certStatus === 'verified'
									? SuccessIcon
									: ErrorIcon
							}
							status={certStatus === 'verified' ? 'green' : 'red'}
						/>
						<Line />

						<SummaryBlock
							name={t('productTrace.transactions')}
							detail={typesStatusLabel[massBalance]}
							icon={
								massBalance === 'good' ? SuccessIcon : ErrorIcon
							}
							status={massBalance === 'good' ? 'green' : 'red'}
							tooltip={
								massBalance === 'bad' &&
								tooltipMsg &&
								tooltipMsg.length
							}
							tooltipMsg={tooltipMsg}
						/>
					</Box>
				</BoxShadowWrapper>
				<Spacer size={4} />
				<BoxShadowWrapper id="viewOrgList">
					<Box row justifyContent="space-between">
						<Box>
							<TabButtons
								id="product"
								list={FILTERS_STATUS_CONFIG}
								activeIndex={FILTERS_STATUS_CONFIG.findIndex(
									q => q.name === activeStatus
								)}
								onChange={({ name }) =>
									handleOnChange('apply_filter', {
										filterValue: name,
										filterType: 'status',
									})
								}
							/>
						</Box>

						{activeStatus !== 'trace-tree' && (
							<FullScreen icon={FullScreenIcon}>
								{activeStatus === 'trace-graph' && (
									<>
										<BoxShadowWrapper>
											<Box
												row
												wrap
												justifyContent="space-between"
												style={{ padding: '20px 32px' }}
											>
												<SummaryBlock
													name={t(
														'productTrace.companyName'
													)}
													detail={orgName}
												/>
												<Line />
												<SummaryBlock
													name={t(
														'productTrace.certificationStatus'
													)}
													detail={
														certificationStatusLabel[
															certStatus
														]
													}
													icon={
														certStatus ===
														'verified'
															? SuccessIcon
															: ErrorIcon
													}
													status={
														certStatus ===
														'verified'
															? 'green'
															: 'red'
													}
												/>
												<Line />

												<SummaryBlock
													name={t(
														'productTrace.transactions'
													)}
													detail={
														typesStatusLabel[
															massBalance
														]
													}
													icon={
														massBalance === 'good'
															? SuccessIcon
															: ErrorIcon
													}
													status={
														massBalance === 'good'
															? 'green'
															: 'red'
													}
													tooltip={
														massBalance === 'bad' &&
														tooltipMsg &&
														tooltipMsg.length
													}
													tooltipMsg={tooltipMsg}
												/>
											</Box>
										</BoxShadowWrapper>
										<Box
											style={{
												overflow: 'auto',
												// transform: 'scale(-1,1)',
												backgroundColor:
													theme.color.grey11,
											}}
										>
											<TraceGraphV1
												data={{
													nodes: _graphRecord,
													badNodePaths,
												}}
												isFullScreen
												onClick={data => {
													if (
														data.massBalanceErrorMsg
													)
														setTooltipMsg(
															data.massBalanceErrorMsg
														)
													setActiveOrgName(data.title)
													setMassBalance(data.status)
													setCertStatus(
														data.certificationStatus
													)
													// setBlockChainStatus(
													// 	data.blockChainStatus
													// )
													handleOnChange(
														'on_node_click',
														{
															nodeId: data.id,
															transaction:
																data.transaction,
															transforming:
																data.transforming,
															transformed:
																data.transformed,
														}
													)
												}}
											/>
										</Box>
									</>
								)}
								{activeStatus === 'trace-map' && (
									<Box
										style={{
											overflow: 'auto',
										}}
									>
										<BoxShadowWrapper>
											<Box
												row
												wrap
												justifyContent="space-between"
												style={{ padding: '20px 32px' }}
											>
												<SummaryBlock
													name={t(
														'productTrace.companyName'
													)}
													detail={orgName}
												/>
												<Line />
												<SummaryBlock
													name={t(
														'productTrace.certificationStatus'
													)}
													detail={
														certificationStatusLabel[
															certStatus
														]
													}
													icon={
														certStatus ===
														'verified'
															? SuccessIcon
															: ErrorIcon
													}
													status={
														certStatus ===
														'verified'
															? 'green'
															: 'red'
													}
												/>
												<Line />

												<SummaryBlock
													name={t(
														'productTrace.transactions'
													)}
													detail={
														typesStatusLabel[
															massBalance
														]
													}
													icon={
														massBalance === 'good'
															? SuccessIcon
															: ErrorIcon
													}
													status={
														massBalance === 'good'
															? 'green'
															: 'red'
													}
													tooltip={
														massBalance === 'bad' &&
														tooltipMsg &&
														tooltipMsg.length
													}
													tooltipMsg={tooltipMsg}
												/>
											</Box>
										</BoxShadowWrapper>
										{coordinates.length > 0 ? (
											<TraceMapsV1
												coordinatesets={mapRecord}
												treeData={treeRecord}
												height={650}
												onClick={data => {
													if (
														data.massBalanceErrorMsg
													)
														setTooltipMsg(
															data.massBalanceErrorMsg
														)

													setActiveOrgName(data.name)
													setMassBalance(data.status)
													setCertStatus(
														data.certificationStatus
													)
													handleOnChange(
														'on_node_click',
														{
															nodeId: data.name,
															transforming: [
																data,
															],
															transformed: [],
														}
													)
												}}
											/>
										) : (
											<Box
												alignItems="center"
												justifyContent="center"
												padding={8}
											>
												<P>
													{t(
														'productTrace.mapNotAvailable'
													)}
												</P>
											</Box>
										)}
									</Box>
								)}
							</FullScreen>
						)}
					</Box>

					<Box
						row
						width="100%"
						padding="4px 15px"
						style={{
							justifyContent: 'center',
							borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
						}}
					>
						<Box
							flex="auto"
							padding="20px 15px"
							width={activeStatus === 'trace-tree' ? 600 : 'auto'}
							height={boxheight}
							style={{
								overflow: 'scroll',
								position: 'relative',
							}}
						>
							{activeStatus === 'trace-graph' && (
								<Box
									style={{
										overflow: 'auto',
										// transform: 'scale(-1,1)',
										backgroundColor: theme.color.grey11,
									}}
									padding={8}
								>
									<TraceGraphV1
										data={{
											nodes: _graphRecord,
											badNodePaths,
										}}
										onClick={data => {
											if (data.massBalanceErrorMsg)
												setTooltipMsg(
													data.massBalanceErrorMsg
												)
											setActiveOrgName(data.title)
											setMassBalance(data.status)
											setCertStatus(
												data.certificationStatus
											)
											// setBlockChainStatus(
											// 	data.blockChainStatus
											// )
											handleOnChange('on_node_click', {
												nodeId: data.id,
												transaction: data.transaction,
												transforming: data.transforming,
												transformed: data.transformed,
											})
										}}
									/>
								</Box>
							)}
							{activeStatus === 'trace-map' && (
								<>
									<Spacer size={12} />

									<Box
										style={{ overflow: 'auto' }}
										padding={8}
									>
										{coordinates.length > 0 ? (
											<TraceMapsV1
												coordinatesets={mapRecord}
												treeData={treeRecord}
												height={650}
												onClick={data => {
													if (
														data.massBalanceErrorMsg
													)
														setTooltipMsg(
															data.massBalanceErrorMsg
														)
													setActiveOrgName(data.name)
													setMassBalance(data.status)
													setCertStatus(
														data.certificationStatus
													)
													handleOnChange(
														'on_node_click',
														{
															nodeId: data.name,
															transforming: [
																data,
															],
															transformed: [],
														}
													)
												}}
											/>
										) : (
											<Box
												alignItems="center"
												justifyContent="center"
												padding={8}
											>
												<P>
													{t(
														'productTrace.mapNotAvailable'
													)}
												</P>
											</Box>
										)}
									</Box>
								</>
							)}
							{activeStatus === 'trace-tree' && (
								<Box style={{ overflow: 'auto' }} padding={8}>
									<TraceTreeV1
										track={{
											nodes: treeRecord,
											badNodePaths,
										}}
										expandDepth={0}
										onClick={data => {
											if (data.massBalanceErrorMsg)
												setTooltipMsg(
													data.massBalanceErrorMsg
												)
											setActiveOrgName(data.title)
											setMassBalance(data.status)
											setCertStatus(
												data.certificationStatus
											)
											// setBlockChainStatus(
											// 	data.blockChainStatus
											// )
											handleOnChange('on_node_click', {
												nodeId: data.id,
												transaction: data.transaction,
												transforming: data.transforming,
												transformed: data.transformed,
											})
											if (
												data.transforming &&
												data.transforming.length > 0
											) {
												const tracevalueList = data.transforming.map(
													transforming =>
														transforming.traceID
												)
												const traceIDs = tracevalueList.join(
													','
												)
												dispatch(
													TraceDuc.creators.getHederaMessages(
														traceIDs
													)
												)
											}
										}}
									/>
								</Box>
							)}
						</Box>
						{activeStatus === 'trace-tree' && (
							<SegmentSeparatorWrapper>
								<SegmentSeparatorLine />
							</SegmentSeparatorWrapper>
						)}
						{activeStatus === 'trace-tree' && (
							<Box flex="auto" padding="20px 15px" width={600}>
								<BoxShadowWrapper>
									<Box
										padding={8}
										style={{
											backgroundColor:
												theme.color.accent3,
										}}
									>
										<P bold color="#263b97">
											{t('productTrace.orgInfo')}
										</P>
									</Box>
									<Box padding={8}>
										<KeyValueSegmentWithClickableLink
											name={t(
												'productTrace.certificationType'
											)}
											detail={
												getIn(documents, [
													0,
													'certType',
												]) || []
											}
										/>
										<KeyValueSegment
											name={t('productTrace.address')}
											detail={
												getIn(documents, [
													0,
													'locationOfTrace',
												]) || t('common.notAvailable')
											}
										/>
										<KeyValueSegment
											name={t('productTrace.location')}
											detail={
												getIn(documents, [
													0,
													'location',
													'long',
												])
													? `${getIn(documents, [
															0,
															'location',
															'long',
													  ])} , ${getIn(documents, [
															0,
															'location',
															'lat',
													  ])}`
													: t('common.notAvailable')
											}
											openlink={
												getIn(documents, [
													0,
													'location',
													'long',
												]) || ''
											}
										/>
										<KeyValueSegment
											name={t(
												'productTrace.totalQuantity'
											)}
											detail={
												getIn(documents, [
													0,
													'totalQuantity',
												]) || t('common.notAvailable')
											}
										/>
									</Box>
								</BoxShadowWrapper>

								{transformingDocuments.length > 0 && (
									<>
										<Spacer size={24} />
										<Box row justifyContent="space-between">
											<Label>
												{t(
													'productTrace.producedGoods'
												)}
											</Label>
										</Box>
										<Spacer size={8} />
										<BoxShadowTable
											columnConfig={traceTableConfig(t)}
											rowData={transformingDocuments}
											loading={loadingState}
										/>
									</>
								)}
								{transformedDocuments.length > 0 && (
									<>
										<Spacer size={24} />
										<Box row justifyContent="space-between">
											<Label>
												{t(
													'productTrace.transactionsList'
												)}
											</Label>
										</Box>
										<Spacer size={8} />

										<BoxShadowTable
											columnConfig={traceTableConfig(t)}
											rowData={transformedDocuments}
											loading={loadingState}
										/>
									</>
								)}
							</Box>
						)}
					</Box>
					{activeStatus !== 'trace-tree' &&
						activeStatus !== 'trace-map' && (
							<Box flex="auto" padding="20px 15px">
								<BoxShadowWrapper>
									<Box
										padding={8}
										style={{
											backgroundColor:
												theme.color.accent3,
										}}
									>
										<P bold color="#263b97">
											{t('productTrace.orgInfo')}
										</P>
									</Box>

									<Box padding={8}>
										<KeyValueSegmentWithClickableLink
											name={t(
												'productTrace.certificationType'
											)}
											detail={
												getIn(documents, [
													0,
													'certType',
												]) || []
											}
										/>
										<KeyValueSegment
											name={t('productTrace.address')}
											detail={
												getIn(documents, [
													0,
													'locationOfTrace',
												]) || t('common.notAvailable')
											}
										/>
										<KeyValueSegment
											name={t('productTrace.location')}
											detail={
												getIn(documents, [
													0,
													'location',
													'long',
												])
													? `${getIn(documents, [
															0,
															'location',
															'long',
													  ])} , ${getIn(documents, [
															0,
															'location',
															'lat',
													  ])}`
													: t('common.notAvailable')
											}
											openlink={
												getIn(documents, [
													0,
													'location',
													'long',
												]) || ''
											}
										/>
										<KeyValueSegment
											name={t(
												'productTrace.totalQuantity'
											)}
											detail={
												getIn(documents, [
													0,
													'totalQuantity',
												]) || t('common.notAvailable')
											}
										/>
									</Box>
								</BoxShadowWrapper>
								{transformingDocuments.length > 0 && (
									<>
										<Spacer size={24} />
										<Box row justifyContent="space-between">
											<Label>
												{t(
													'productTrace.producedGoods'
												)}
											</Label>
										</Box>
										<Spacer size={8} />
										<BoxShadowTable
											columnConfig={traceTableConfig(t)}
											rowData={transformingDocuments}
											loading={loadingState}
										/>
									</>
								)}

								{transformedDocuments.length > 0 && (
									<>
										<Spacer size={24} />
										<Box row justifyContent="space-between">
											<Label>
												{t(
													'productTrace.transactionsList'
												)}
											</Label>
										</Box>
										<Spacer size={8} />

										<BoxShadowTable
											columnConfig={traceTableConfig(t)}
											rowData={transformedDocuments}
											loading={loadingState}
										/>
									</>
								)}
							</Box>
						)}
				</BoxShadowWrapper>
			</Box>
		</>
	)
}

export { GenerateTraceV1 }
