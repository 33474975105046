import React, { useCallback, useEffect } from 'react'
import { Box } from 'ui-lib/utils/Box'
import { getIn } from 'timm'
import { useDispatch, useSelector } from 'react-redux'
import { MainRouteDuc } from 'core-app/routes/duc'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { AppDuc } from 'core-app/modules/App/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { CookieDuc } from 'core-app/modules/App/cookieDuc'
import WorkOutline from 'ui-lib/icons/work-outline.svg'
import { Button, ButtonIconWrapper } from 'ui-lib/components/Button'
import { Spacer } from 'ui-lib/utils/Spacer'
import { useTranslation } from 'react-i18next'
import { Title } from 'core-app/shared/components/Title'
import { getEmployeeMgmtColumnConfig } from 'core-app/modules/Admin/components/Columns'
import { BoxShadowTable } from 'core-app/shared/components/BoxShadowTable'
import { AdminDuc } from 'core-app/modules/Admin/duc'
import { CTAWrapper } from 'core-app/modules/Dealer/containers/subModules/Work'
import { Label } from 'ui-lib/components/Typography'
import theme from 'ui-lib/utils/base-theme'
import { Icon } from 'ui-lib/icons/components/Icon'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import { WebTour } from '../../../WebTour'
import { WebTourDuc } from '../../../WebTour/duc'

const Employees = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const isLoading = useSelector(AdminDuc.selectors.getEmployeeLoadingStatus)
	const { orgRole } = useSelector(AuthDuc.selectors.getUserProfile)

	const location = useSelector(AdminDuc.selectors.location)
	const documents = useSelector(AdminDuc.selectors.getEmployeesListing)

	const pagination = useSelector(AdminDuc.selectors.fetchEmployeePagination)
	const totalDocument = getIn(pagination, ['total']) || ''

	const { payload = {} } = location
	const { rootModule } = payload
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)
	const selectedCPID = useSelector(CookieDuc.selectors.getSelectedCPID)
	useEffect(() => {
		if (tourModule === 'employeeMgmtHeader') {
			if (orgRole !== 'office-management') {
				dispatch(
					WebTourDuc.creators.setActiveTourModule(
						'employeeMgmtHeader2'
					)
				)
			} else {
				dispatch(WebTourDuc.creators.setActiveTourModule(''))
			}
		}
	}, [tourModule, dispatch, orgRole])
	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.admin'),
			name: 'admin',
			isActive: true,
		},
		{
			label: t('breadcrumb.empMgmt'),
			name: 'employee-management',
			isActive: false,
		},
	]

	/** Handlers to react on actions */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'admin') {
			dispatch(MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN))
		}
	}

	const handleChange = useCallback(
		(actionType, meta = {}) => {
			switch (actionType) {
				case 'open_document': {
					if (meta.row) {
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.ADMIN$WDOCREFERENCE,
								{
									rootModule,
									action: 'view-employee',
									documentReference: meta.row.id,
								}
							)
						)
					}

					break
				}

				case 'paginate': {
					const { filterParams } = meta
					const isSkip = false
					const { nextIndex } = filterParams
					const locationState = {
						query: {
							...location.query,
							nextIndex,
							limit: 20,
						},
					}
					dispatch(
						AdminDuc.creators.fetchEmployeesListing(
							isSkip,
							locationState
						)
					)
					break
				}

				default:
					break
			}
		},
		[dispatch, location, rootModule]
	)

	return (
		<>
			<Box>
				<Box padding={8} width="100%" height="100%">
					<Box style={{ padding: '0 5' }}>
						<Breadcrumb
							links={breadCrumbs}
							onClick={target => handleBreadCrumbClick(target)}
						/>
					</Box>
					<Box>
						<Box
							row
							justifyContent="space-between"
							alignItems="center"
						>
							<Title
								title={t('empMgmtHome.title')}
								icon={WorkOutline}
								note={t('empMgmtHome.note')}
							/>
							{orgRole !== 'office-management' && !selectedCPID && (
								<Box
									id="input-employeeMgmt"
									justifyContent="flex-end"
								>
									<Button
										label={t('empMgmtHome.addEmployee')}
										extendStyles={{
											fontSize:
												isMobile && theme.fontSize.m,
											padding: isMobile && '8px',
										}}
										primary
										rounded
										onClick={() => {
											dispatch(
												AdminDuc.creators.flushEmployeeState()
											)

											dispatch(
												MainRouteDuc.creators.switchPage(
													MainRouteDuc.types
														.ADMIN$WACTION,
													{
														rootModule:
															'employee-management',
														action: 'add-employee',
													}
												)
											)
										}}
									/>
								</Box>
							)}
						</Box>
						<Spacer size={8} />

						<BoxShadowTable
							isLoading={isLoading}
							size="large"
							scroll={!isMobile}
							tableSize={isMobile && 'small'}
							columnConfig={getEmployeeMgmtColumnConfig({
								onChange: handleChange,
								t,
							})}
							rowData={documents}
						/>
						<CTAWrapper>
							<Label
								color="inherit"
								bold
								small
								style={{
									padding: '10px 4px',
								}}
							>
								{(documents || []).length > 0
									? `${t('common.showingListFor')}
							  ${(documents || []).length}
							  ${t('common.of')}
							  ${totalDocument}`
									: t('common.errorBlockMessage')}
							</Label>
							<Box
								style={{
									width: '75%',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<Button
									plain
									disabled={
										(documents || []).length === 0 ||
										(documents || []).length ===
											totalDocument
									}
									extendStyles={{
										background: '#F2F4FF',
										padding: '8px 24px',
										borderRadius: '24px',
										width: '170px',
										textAlign: 'center',
									}}
									label={t('common.showMore')}
									onClick={() =>
										handleChange('paginate', {
											filterParams: pagination,
										})
									}
								/>
							</Box>
						</CTAWrapper>
					</Box>
					<Box padding="25px 0" style={{ maxWidth: 150 }}>
						<Button
							label={t('common.back')}
							rounded
							customIcon={
								<ButtonIconWrapper lightBG>
									<Icon glyph={LeftArrowIcon} />
								</ButtonIconWrapper>
							}
							onClick={() =>
								dispatch(
									MainRouteDuc.creators.switchPage(
										MainRouteDuc.types.ADMIN
									)
								)
							}
						/>
					</Box>
				</Box>
			</Box>
			{tourModule === 'employeeMgmtHeader2' && <WebTour />}
		</>
	)
}

export { Employees }
