import React, { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn } from 'timm'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { CookieDuc } from 'core-app/modules/App/cookieDuc'
import { GeneralSchedulerDuc } from 'core-app/modules/GeneralScheduler/duc'
import { Title } from 'core-app/shared/components/Title'
import { getColumnConfig } from 'core-app/modules/GeneralScheduler/components/Columns'
import { Reschedule } from 'core-app/modules/GeneralScheduler/components/Reschedule'
import { NewSchedule } from 'core-app/modules/GeneralScheduler/components/NewSchedule'
import { DriverCard } from 'core-app/shared/components/AssignDriverModal'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import {
	Button,
	ButtonIconWrapper,
	ButtonWithDrop,
	ButtonWithNoBorder,
} from 'ui-lib/components/Button'
import { ErrorBlock } from 'ui-lib/components/ErrorBlocks'
import { Modal } from 'ui-lib/components/Modal'
import { Spacer } from 'ui-lib/utils/Spacer'
import { TableWithSelect } from 'core-app/modules/GeneralScheduler/components/TableWithSelect'
import { H3, P, Label } from 'ui-lib/components/Typography'
import { Icon } from 'ui-lib/icons/components/Icon'
import { Box } from 'ui-lib/utils/Box'
import theme from 'ui-lib/utils/base-theme'
import Scheduler from 'ui-lib/icons/scheduler.svg'

const SummaryBlock = ({ name, detail, color }) => (
	<Box padding={16}>
		<Box height={35}>
			<P small bold color={color || theme.color.accent2}>
				{detail}
			</P>
		</Box>
		<Label color={color || theme.color.accent2} small>
			{name}
		</Label>
	</Box>
)

const MainDashboard = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const location = useSelector(GeneralSchedulerDuc.selectors.location)

	const [scheduleDetails, setScheduleDetails] = useState({})

	// gets driver and other info from existing driver
	const [activeRow, setActiveRow] = useState([])
	const [driverfromActiveRow, setDriverfromActiveRow] = useState([])

	// set the new driver
	const [driver, setDriver] = useState('')

	const [checkedSchedules, setCheckedSchedules] = useState([])
	const [participantDetails, setParticipantDetails] = useState([])
	const [deleteModalStatus, setDeleteModalStatus] = useState(false)
	const [scheduleTransportModal, setScheduleTransportModal] = useState(false)
	const [showDriverAssignModal, setShowDriverAssignModal] = useState(false)
	const [updateDriverModal, setUpdateDriverModal] = useState(false)
	const insights = useSelector(GeneralSchedulerDuc.selectors.getInsights)
	const selectedCPID = useSelector(CookieDuc.selectors.getSelectedCPID)

	const rescheduleModalStatus = useSelector(
		GeneralSchedulerDuc.selectors.getRescheduleModalStatus
	)
	const newScheduleModalStatus = useSelector(
		GeneralSchedulerDuc.selectors.getNewScheduleModalStatus
	)

	const scheduleList = useSelector(
		GeneralSchedulerDuc.selectors.getScheduleListing
	)
	const partners = useSelector(AuthDuc.selectors.getAvailablePartners)
	const suppliers = useSelector(AuthDuc.selectors.getTargetPartnersOfUser)
	const users = useSelector(AuthDuc.selectors.getOrgUsers)
	const pagination = useSelector(
		GeneralSchedulerDuc.selectors.getPaginationQueries
	)

	const driverList = users.filter(user => user.orgRole === 'driver')

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.scheduler'),
			name: 'scheduler',
			isActive: false,
		},
	]

	const onChecked = schedules => {
		setCheckedSchedules(schedules)
	}

	const getParticipantInfo = participantInfo => {
		setParticipantDetails(participantInfo)
	}

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
	}

	const handleOnChange = useCallback(
		(actionType, meta = {}) => {
			switch (actionType) {
				case 'open_document': {
					if (meta.row.id) {
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types
									.GENERAL_SCHEDULER$WDOCREFERENCE,
								{
									action: 'view',
									documentReference: meta.row.id,
								}
							)
						)
					}

					break
				}

				case 'schedule_transport': {
					if (meta.row.id) {
						setScheduleTransportModal(true)
						setScheduleDetails(meta.row)
					}
					break
				}

				case 'statusUpdate': {
					const { id, name } = meta
					dispatch(
						GeneralSchedulerDuc.creators.scheduleStatusUpdate(
							name,
							id,
							t('schedulerListing.statusUpdateToast')
						)
					)

					break
				}

				case 'triggerDriverModal': {
					const { row = {} } = meta

					setActiveRow(row)

					const driverDetails = (
						getIn(row, ['participants']) || []
					).filter(p => p.clientType === 'user')

					setDriverfromActiveRow(driverDetails)

					const isDriverEditable = getIn(driverDetails, [
						0,
						'isEditable',
					])

					// eslint-disable-next-line no-unused-expressions
					isDriverEditable
						? setUpdateDriverModal(true)
						: setShowDriverAssignModal(true)

					break
				}

				case 'paginate': {
					const { filterParams } = meta
					const { nextIndex } = filterParams
					const locationState = {
						query: {
							...location.query,
							nextIndex,
							limit: 20,
						},
					}

					dispatch(
						GeneralSchedulerDuc.creators.fetchScheduleListing(
							locationState
						)
					)
					break
				}

				default:
					break
			}
		},
		[dispatch, location.query, t]
	)

	const handleAction = active => {
		if (checkedSchedules.length && active === 'delete') {
			setDeleteModalStatus(true)
		}

		if (checkedSchedules.length && active === 'reschedule') {
			dispatch(
				GeneralSchedulerDuc.creators.setRescheduleModalStatus(true)
			)
		}
	}

	const isDriverEditable = getIn(driverfromActiveRow, [0, 'isEditable'])

	const driverIDfromActiveRow =
		getIn(driverfromActiveRow, [0, 'participantID']) || ''

	const scheduleParticipantID = getIn(driverfromActiveRow, [0, 'id'])

	const existingDriver = users.filter(usr => usr.id === driverIDfromActiveRow)
	const address =
		getIn(existingDriver, [0, 'primaryAddress', 'city']) ||
		getIn(existingDriver, [0, 'primaryAddress', 'state']) ||
		getIn(existingDriver, [0, 'primaryAddress', 'country'])

	return (
		<Box padding={8} width="100%" height="100%">
			<Modal
				forceCloseviaButton
				size="medium"
				show={deleteModalStatus}
				heading="Delete Schedule"
				body={
					<P large bold>
						{t(
							'schedulerListing.areYouSureYouWantToDeleteTheSelecteSchedule'
						)}
					</P>
				}
				closelabel={t('common.no')}
				confirmlabel={t('common.yes')}
				onClose={() => setDeleteModalStatus(false)}
				onConfirm={() => {
					setDeleteModalStatus(false)
					dispatch(
						GeneralSchedulerDuc.creators.deleteSchedules(
							checkedSchedules,
							t('toast.deleteSuccess')
						)
					)
				}}
			/>

			<Modal
				forceCloseviaButton
				size="auto"
				show={rescheduleModalStatus}
				body={
					<Reschedule
						onConfirm={values => {
							dispatch(
								GeneralSchedulerDuc.creators.setRescheduleModalStatus(
									false
								)
							)
							dispatch(
								GeneralSchedulerDuc.creators.multipleReschedules(
									participantDetails,
									values,
									t('common.rescheduleSuccess')
								)
							)
						}}
						onClose={() => {
							dispatch(
								GeneralSchedulerDuc.creators.setRescheduleModalStatus(
									false
								)
							)
						}}
					/>
				}
				hideButtons
			/>

			<Modal
				size="auto"
				overflow="auto"
				show={newScheduleModalStatus}
				body={
					<NewSchedule
						documents={suppliers}
						onConfirm={values => {
							dispatch(
								GeneralSchedulerDuc.creators.setNewScheduleModalStatus(
									false
								)
							)
							dispatch(
								GeneralSchedulerDuc.creators.createNewSchedules(
									values,
									t('createSchedule.schedulesSuccessToast')
								)
							)
						}}
						onClose={() => {
							dispatch(
								GeneralSchedulerDuc.creators.setNewScheduleModalStatus(
									false
								)
							)
							dispatch(
								GeneralSchedulerDuc.creators.flushNewSchedule()
							)
						}}
					/>
				}
				hideButtons
			/>

			<Modal
				forceCloseviaButton
				size="medium"
				show={scheduleTransportModal}
				heading="Confirm"
				body={
					<P large bold>
						{t(
							'schedulerListing.youHaveChosenToScheduleTransportDoYouWantToProceed'
						)}
					</P>
				}
				closelabel={t('common.no')}
				confirmlabel={t('common.yes')}
				onClose={() => setScheduleTransportModal(false)}
				onConfirm={() => {
					setScheduleTransportModal(false)
					dispatch(
						GeneralSchedulerDuc.creators.scheduleTransport(
							scheduleDetails
						)
					)
				}}
			/>
			<Modal
				show={showDriverAssignModal}
				size="600px"
				body={
					<Box padding={8}>
						{!driver ? (
							<>
								<Box alignItems="flex-start">
									<P
										bold
										style={{
											fontSize: '32px',
											color: theme.color.accent2,
										}}
									>
										{t('tdmDetailsEntry.assignDriver')}
									</P>
									<P
										style={{
											color: '#8891A2',
											marginBottom: '16px',
										}}
									>
										{t(
											'tdmDetailsEntry.assignConsignmentToDriver'
										)}
									</P>
								</Box>
								<Spacer size={32} />
								<Box
									style={{ overflow: 'scroll' }}
									height={320}
								>
									{(driverList || []).map(_driver => {
										return (
											<DriverCard
												key={_driver.id}
												driver={_driver}
												activeDriver={driver}
												onClick={activeDriver => {
													setDriver(activeDriver)
												}}
											/>
										)
									})}
								</Box>
							</>
						) : (
							<>
								<Box alignItems="flex-start">
									<P
										bold
										style={{
											fontSize: '32px',
											color: theme.color.accent2,
										}}
									>
										{t('tdmDetailsEntry.assignDriver')}
									</P>
									<P
										style={{
											color: '#8891A2',
											marginBottom: '16px',
										}}
									>
										{t(
											'tdmDetailsEntry.assignConsignmentToDriver'
										)}
									</P>
								</Box>
								<Spacer size={16} />
								<DriverCard driver={driver} />
								<Spacer size={16} />
								<P
									style={{
										color: '#172B4D',
										marginBottom: '16px',
										textAlign: 'center',
									}}
								>
									{`${t(
										'schedulerListing.youHaveChosenToAssign'
									)} ${driver.fullName}. ${t(
										'schedulerListing.doYouWantToProceed'
									)}`}
								</P>
								<Spacer size={16} />
								<Box row justifyContent="flex-end" padding={4}>
									<Button
										label={t('common.back')}
										outline
										extendStyles={{
											width: '173px',
											height: '48px',
											marginTop: 24,
											background: '#F2F4FF',
											color: theme.color.blue5,
										}}
										onClick={() => {
											setDriver('')
										}}
									/>
									<Spacer size={16} horizontal />
									<Button
										label={t('common.yes')}
										primary
										extendStyles={{
											width: '173px',
											height: '48px',
											marginTop: 24,
											background: theme.color.blue5,
										}}
										onClick={() => {
											const driverDetails = {
												scheduleParticipantID,
												scheduleID: activeRow.id,
												userID: driver.id,
											}

											if (isDriverEditable) {
												dispatch(
													GeneralSchedulerDuc.creators.updateDriver(
														driverDetails,
														t(
															'schedulerListing.driverUpdateToast'
														)
													)
												)
											}

											if (!isDriverEditable) {
												dispatch(
													GeneralSchedulerDuc.creators.assignDriver(
														driverDetails,
														t('viewSchedule.driver')
													)
												)
											}
											setShowDriverAssignModal(false)
											setDriver('')
										}}
									/>
								</Box>
							</>
						)}
					</Box>
				}
				closeable
				onClose={() => {
					setShowDriverAssignModal(false)
				}}
				hideButtons
			/>
			<Modal
				show={updateDriverModal}
				size="600px"
				body={
					<Box padding={8}>
						<Box alignItems="flex-start">
							<P
								bold
								style={{
									fontSize: '32px',
									color: theme.color.accent2,
								}}
							>
								{t('tdmDetailsEntry.assignDriver')}
							</P>
							<P
								style={{
									color: '#8891A2',
									marginBottom: '16px',
								}}
							>
								{t(
									'schedulerListing.driverCurrentlyAssignedToTheSchedule'
								)}
							</P>
						</Box>
						<Spacer size={16} />
						<Box
							alignItems="center"
							row
							style={{
								borderRadius: 4,
								border: `1px solid ${theme.color.grey4}`,
								marginBottom: '8px',
								boxShadow: '3px 3px 6px #00000029',
								background: '#EBEEF9',
							}}
							padding={8}
						>
							<Box width="20%">
								<img
									alt="driver"
									src={
										getIn(existingDriver, [
											0,
											'meta',
											'profileImage',
										]) ||
										'https://www.startupsg.gov.sg/api/v0/profile-images/17400/image/avatar'
									}
									style={{
										width: '50px',
										height: '50px',
										borderRadius: '50%',
									}}
								/>
							</Box>
							<Box width="80%" style={{ marginTop: '1%' }}>
								<H3 color={theme.color.accent2}>
									{getIn(existingDriver, [0, 'fullName'])}
								</H3>
								<P
									style={{
										color: '#8891A2',
									}}
								>
									{address}
								</P>
							</Box>
						</Box>
						<Spacer size={16} />
						<Box row justifyContent="flex-end" padding={4}>
							<ButtonWithNoBorder
								label={t('schedulerListing.changeDriver?')}
								textDecoration="none"
								fontSize={theme.fontSize.xl}
								color="#3F56C4"
								onClick={() => {
									setUpdateDriverModal(false)
									setShowDriverAssignModal(true)
								}}
							/>
						</Box>
					</Box>
				}
				closeable
				onClose={() => {
					setUpdateDriverModal(false)
				}}
				hideButtons
			/>
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Box row justifyContent="space-between" alignItems="baseline">
				<Title title={t('schedulerListing.title')} />
				{!selectedCPID && (
					<Box row justifyContent="flex-end">
						<Button
							label={t('schedulerListing.newSchedule')}
							primary
							rounded
							onClick={() => {
								dispatch(
									GeneralSchedulerDuc.creators.setNewScheduleModalStatus(
										true
									)
								)
							}}
							customIcon={
								<ButtonIconWrapper
									style={{ width: 18, height: 18 }}
								>
									<Icon glyph={Scheduler} />
								</ButtonIconWrapper>
							}
						/>
					</Box>
				)}
			</Box>

			<Box row justifyContent="space-between" margin="16px 0">
				<Box
					width={450}
					style={{
						background: '#F4F5F7',
						border: '5px solid white',
						borderRadius: 4,
					}}
				>
					<H3 style={{ fontSize: 16, padding: '4px 16px 0' }}>
						{t('schedulerListing.schedulesPlanned')}
					</H3>
					<Box row>
						<SummaryBlock
							name={t('plantationDashboard.today')}
							detail={getIn(insights, ['today'])}
							color={theme.color.blue5}
						/>
						<SummaryBlock
							name={t('plantationDashboard.nextDay')}
							detail={getIn(insights, ['nextDay'])}
						/>
						<SummaryBlock
							name={t('plantationDashboard.thisWeek')}
							detail={getIn(insights, ['thisWeek'])}
						/>
						<SummaryBlock
							name={t('plantationDashboard.NextWeek')}
							detail={getIn(insights, ['nextWeek'])}
						/>
					</Box>
				</Box>
				{!selectedCPID && (
					<Box row alignItems="flex-end">
						<Box width={200}>
							<ButtonWithDrop
								label={t('schedulerListing.chooseAction')}
								noBackground
								items={[
									{
										label: `${t(
											'schedulerListing.reschedule'
										)}`,
										name: 'reschedule',
									},
									{
										label: `${t('common.delete')}`,
										name: 'delete',
									},
								]}
								onChange={active => {
									handleAction(active)
								}}
							/>
						</Box>
					</Box>
				)}
			</Box>

			<TableWithSelect
				columnConfig={getColumnConfig({
					t,
					partners,
					onChange: handleOnChange,
					selectedCPID,
				})}
				rowData={scheduleList}
				onChecked={onChecked}
				participantInfo={getParticipantInfo}
			/>
			{!(scheduleList || []).length && (
				<Box
					style={{
						backgroundColor: 'rgb(236, 238, 243)',
						border: `1px solid ${theme.color.grey4}`,
						borderTop: 'none',
					}}
				>
					<ErrorBlock
						hideButton
						status="empty"
						message={t('common.errorBlockMessage')}
					/>
				</Box>
			)}
			<Label
				color="inherit"
				bold
				small
				link
				as="a"
				style={{
					padding: '10px 4px',
				}}
			>
				{(!scheduleList || []).length
					? t('common.errorBlockMessage')
					: `${t('common.showing')}
						${(scheduleList || []).length}
						${t('common.of')}
						${getIn(pagination, ['total'])}`}
			</Label>
		</Box>
	)
}

export { MainDashboard }
