import React from 'react'
import { Box } from 'ui-lib/utils/Box'
import { H2, SmallText } from 'ui-lib/components/Typography'
import theme from 'ui-lib/utils/base-theme'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import SuccessIcon from 'ui-lib/icons/success-round.svg'
import { useSelector } from 'react-redux'
import { AppDuc } from 'core-app/modules/App/duc'
import { getAppVersion } from 'core-app/config'
import { useTranslation } from 'react-i18next'
import {
	Button,
	ButtonIconWrapper,
	ButtonWithNoBorder,
} from 'ui-lib/components/Button'
import LeftArrowIcon from 'ui-lib/icons/arrow_forward.svg'

const ThankYou = () => {
	const { t } = useTranslation()
	const { isMobile } = useSelector(AppDuc.selectors.detection)

	return (
		<Box
			style={{
				width: '100vw',
				height: '100vh',
				background: ' #1D285D',
				overflowY: 'scroll',
			}}
			center
			padding={8}
		>
			<Box center>
				<H2
					style={{
						fontSize: isMobile ? 32 : 40,
						color: theme.color.white,
						lineHeight: isMobile ? '48px' : '52px',
						fontWeight: 'bold',
					}}
				>
					Process Completed! We will approve soon
				</H2>
				<Spacer size={20} />
				<Box
					style={{
						width: '770px',
						height: 'fit-content',
						background: theme.color.white,
						borderRadius: '10px',
						boxShadow: '0px 3px 6px #00000029',
					}}
					padding={25}
					alignItems="center"
				>
					<IconWrapper color="#CE924B" size={70}>
						<Icon glyph={SuccessIcon} />
					</IconWrapper>
					<Spacer size={20} />
					<SmallText
						style={{
							color: '#2D2D2D',
							fontSize: '32px',
							lineHeight: '32px',
							width: '500px',
							fontWeight: 'bold',
							textAlign: 'center',
						}}
					>
						Congratulations!
					</SmallText>
					<Spacer size={30} />
					<SmallText
						style={{
							color: '#555454',
							fontSize: '18px',
							lineHeight: '32px',
							textAlign: 'center',
						}}
					>
						Thank you for completing your registration.
					</SmallText>
					<Spacer size={20} />

					<SmallText
						style={{
							color: '#555454',
							fontSize: '18px',
							fontWeight: 'bold',
							textAlign: 'center',
						}}
					>
						Your account is under review and we will notify you once
						your account is approved
					</SmallText>
					<Spacer size={30} />
					<Box
						style={{
							width: '410px',
						}}
					>
						<Button
							rounded
							label="Return to Website"
							customIcon={
								<ButtonIconWrapper color="white" lightBG>
									<Icon glyph={LeftArrowIcon} />
								</ButtonIconWrapper>
							}
							reverse
							onClick={() => {
								window.location.href =
									'https://dibizglobal.com/'
							}}
							extendStyles={{
								background: '#3F56C4',
								color: theme.color.white,
							}}
						/>
					</Box>
				</Box>
			</Box>
			<Box
				row
				style={{
					width: '100%',
					position: 'absolute',
					bottom: 0,
					marginBottom: '1px',
				}}
				alignItems="center"
				justifyContent="center"
			>
				<ButtonWithNoBorder
					type="submit"
					extendStyles={{
						color: '#ABB4DB',
						width: 'fit-content',
						textDecoration: 'none',
						padding: '0',
					}}
					onClick={() => window.open('/terms-conditions', '_blank')}
					label="Terms & Conditions"
				/>
				<Spacer size={20} />
				<ButtonWithNoBorder
					type="submit"
					extendStyles={{
						color: '#ABB4DB',
						width: 'fit-content',
						textDecoration: 'none',
						padding: '0',
					}}
					onClick={() => window.open('/privacy-policy', '_blank')}
					label={t('login.privacy')}
				/>
				<Spacer size={20} />

				<SmallText color="#ABB4DB" fontSize="14px">
					{`Version ${getAppVersion()}`}
				</SmallText>
			</Box>
		</Box>
	)
}

export { ThankYou }
