import { RefineryDuc } from 'core-app/modules/Refinery/duc'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { getIn } from 'timm'
import saga from 'core-app/modules/Refinery/RefinerySaga'
import moment from 'moment'
import { ProductsDuc } from 'core-app/modules/Products/duc'
import { AdminDuc } from 'core-app/modules/Admin/duc'

export default async (dispatch, getState, { action, extra }) => {
	const { type, customMeta = {} } = action
	const currentLocationFromAction = getIn(action, [
		'meta',
		'location',
		'current',
	])
	/** if someone specifically wants to skip thunk fetch again, this check helps */
	const { skipRouteThunk } = customMeta
	if (skipRouteThunk) return

	// check if nothing changed in the route.

	const state = getState()

	const locationState = currentLocationFromAction || state.location

	const { payload } = locationState

	// Inject the saga here. If it wasn't already
	extra.getSagaInjectors().injectSaga('REFINERY', { saga })
	dispatch(AuthDuc.creators.fetchActor())
	const isMainDashboard = MainRouteDuc.types.REFINERY === type
	const isSubDashboard = MainRouteDuc.types.REFINERY$SUBROOT === type
	const isDocumentCreation = type === MainRouteDuc.types.REFINERY$WACTION

	const isDocumentListing = type === MainRouteDuc.types.REFINERY$LISTING
	const isDocumentUpdation =
		type === MainRouteDuc.types.REFINERY$WDOCREFERENCE
	const isDocumentTypeControl = isDocumentCreation || isDocumentUpdation

	let activeModule = ''

	if (isMainDashboard) {
		// load the dashboard of the core module
		activeModule = 'dashboard'
		dispatch(RefineryDuc.creators.fetchInsights())
	} else if (isSubDashboard) {
		if (payload.rootModule === 'input-storage') {
			activeModule = 'input-storage'
			dispatch(RefineryDuc.creators.flushStorageListing())
			dispatch(RefineryDuc.creators.fetchStorageListing())
		} else if (payload.rootModule === 'production') {
			activeModule = 'subDashboard'
			dispatch(AdminDuc.creators.fetchCompanyInfo())
			dispatch(
				RefineryDuc.creators.fetchProductionDocuments(
					'production',
					payload.rootModule,
					locationState
				)
			)
			dispatch(ProductsDuc.creators.fetchProductionLine())
			dispatch(ProductsDuc.creators.fetchProducts())
			dispatch(ProductsDuc.creators.fetchStorageUnits())
			dispatch(ProductsDuc.creators.fetchOutgoingProducts())
			dispatch(ProductsDuc.creators.fetchOutgoingStorageUnits())
			dispatch(AuthDuc.creators.fetchCertificates())
		} else {
			activeModule = 'subDashboard'
			dispatch(
				RefineryDuc.creators.fetchProductionDocuments(
					'production',
					payload.rootModule,
					locationState
				)
			)
		}
	} else if (isDocumentTypeControl) {
		activeModule = payload.rootModule
		if (payload.rootModule === 'input-storage') {
			if (payload.action === 'view') {
				dispatch(RefineryDuc.creators.assignTankView())
			}
		}
		if (activeModule === 'production-plot-input') {
			if (payload.action === 'create') {
				dispatch(AuthDuc.creators.fetchCertificates())
				dispatch(RefineryDuc.creators.fetchInitialPlotInput())
			}
		}
		if (activeModule === 'production-plot-output') {
			if (payload.action === 'create') {
				dispatch(RefineryDuc.creators.fetchInitialPlotOutput())
			}
		}
		if (
			activeModule === 'sourced-batch' ||
			activeModule === 'produced-batch'
		) {
			dispatch(
				RefineryDuc.creators.viewPlotDocuments(
					payload.documentReference
				)
			)
			dispatch(AuthDuc.creators.fetchUserLists())
		}
		if (activeModule === 'inventory') {
			const currentDate = new Date()
			const first = currentDate.getDate() - currentDate.getDay() + 1
			const weekNumber = moment(
				new Date(currentDate.setDate(first))
			).week()
			const year = new Date(currentDate).getFullYear()

			dispatch(
				RefineryDuc.creators.fetchIncomingInventory(weekNumber, year)
			)
			dispatch(
				RefineryDuc.creators.fetchOutgoingInventory(weekNumber, year)
			)
			dispatch(ProductsDuc.creators.fetchIncomingProducts())
			dispatch(ProductsDuc.creators.fetchOutgoingProducts())
			dispatch(ProductsDuc.creators.fetchIncomingStorageUnits())
			dispatch(ProductsDuc.creators.fetchOutgoingStorageUnits())
		}
	} else if (isDocumentListing) {
		activeModule = 'documentListing'
		dispatch(
			RefineryDuc.creators.fetchDocumentListing(
				payload.submodule,
				[payload.rootModule],
				locationState
			)
		)
	}

	// load the dashboard of the core module
	dispatch(RefineryDuc.creators.setActiveModule(activeModule))

	// based on the payload or action, initiate saga's
}
