import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { Box } from 'ui-lib/utils/Box'
import { Button, ButtonIconWrapper } from 'ui-lib/components/Button'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import { Icon } from 'ui-lib/icons/components/Icon'
import { CollapsableSegment } from 'ui-lib/components/CollapsableSegments'
import { BoxShadowTable } from 'core-app/shared/components/BoxShadowTable'
import { merge, getIn } from 'timm'
import { useTranslation } from 'react-i18next'
import { Spacer } from 'ui-lib/utils/Spacer'
import { AssignTankForm } from 'core-app/modules/Refinery/components/AssignTankForm'
import {
	MILL_INPUT_PRODUCTS,
	RUBBER_INPUT_PRODUCT_LATEX,
} from 'core-app/utils/constants'
import { Modal } from 'ui-lib/components/Modal'
import * as yup from 'yup'
import { useFormik } from 'formik'
import theme from 'ui-lib/utils/base-theme'
import { isEmptyObject } from 'core-app/utils/helpers'
import { MillDuc, NAME_ALIASES } from '../../duc'
import { getPlotInputColumnConfig } from '../../components/Columns'
import { WebTourDuc } from '../../../WebTour/duc'
import { WebTour } from '../../../WebTour'

const storageTank = {
	activeIndex: 0,
	storageUnitID: '',
	quantity: 0,
}

const initialActiveBatch = {
	activeIndex: 0,
	date: new Date().toISOString(),
	productID: '',
	quantity: 0,
	productionLine: '',
	storageUnits: [storageTank],
}

const validationSchema = (t, currentAvlQty) =>
	yup.object().shape({
		traces: yup
			.array()
			.of(
				yup.object().shape({
					productID: yup
						.string()
						.strict()
						.required(),
					quantity: yup
						.number()
						.typeError(t('validation.morethanZero'))
						.strict()
						.max(currentAvlQty, t('validation.lessthanQuantity'))
						.moreThan(0, t('validation.morethanZero'))
						.required(t('validation.morethanZero')),
				})
			)
			.strict()
			.required(),
	})

const CreatePlotInput = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const allTanks = useSelector(AuthDuc.selectors.getTanks)
	const allProducts = useSelector(AuthDuc.selectors.getProducts)
	const locationState = useSelector(MillDuc.selectors.location)
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)
	const { productionLine = [], tankYardWareHouse = [] } = useSelector(
		AuthDuc.selectors.getOrgAssets
	)
	const currentOrgdetails = useSelector(
		AuthDuc.selectors.getCurrentOrgDetails
	)

	const { payload = {} } = locationState
	const { rootModule } = payload

	const tankList = allTanks.map(({ name, id }) => {
		return { id, label: name }
	})

	const activeProducts = allProducts
		.filter(prod =>
			prod.code.includes(
				getIn(currentOrgdetails, ['name'])
					?.toLowerCase()
					?.includes('rubber')
					? RUBBER_INPUT_PRODUCT_LATEX
					: MILL_INPUT_PRODUCTS
			)
		)
		.map(({ availableQty, name, id, uom: _uom }) => {
			return {
				id,
				label: `${name} - (${
					availableQty.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0]
				} ${_uom})`,
				availableQty,
			}
		})

	const area = tankYardWareHouse.filter(
		a => a.type === 'area' && a.meta.inventoryType === 'incoming'
	)
	const areaID = getIn(area, [0, 'id'])

	const [trigger, setTrigger] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [storageTanks, setStorageTanks] = useState([])
	const [currentAvlQty, setCurrentAvlQty] = useState(0)
	const [productDetails, setProductDetails] = useState({})
	const [lastUpdatedIndex, setLastUpdatedIndex] = useState(0)
	const [currentProducedQty, setCurrentProducedQty] = useState(0)
	const [activeDocuments, setActiveDocuments] = useState([initialActiveBatch])

	useEffect(() => {
		if (tourModule === 'createIpBatch') {
			dispatch(WebTourDuc.creators.setActiveTourModule('createIpBatch2'))
		}
	}, [tourModule, dispatch])

	const getBreadCrumbsList = useCallback(
		() => [
			{
				label: t('breadcrumb.home'),
				name: 'home',
				isActive: true,
			},
			{
				label: t('breadcrumb.production'),
				name: 'production',
				isActive: true,
			},
			{
				label: t('production.createInputBatch'),
				name: 'production-plot-input',
				isActive: false,
			},
		],
		[t]
	)

	/** Handlers */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'production') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.MILL$SUBROOT,
					{
						rootModule: 'production',
					}
				)
			)
		}
	}

	const { submitForm, setFieldValue, isSubmitting, errors } = useFormik({
		initialValues: {
			traces: [],
		},
		validationSchema: validationSchema(t, currentAvlQty),
		enableReinitialize: true,
		onSubmit: (_values, { setSubmitting }) => {
			dispatch(
				MillDuc.creators.createPlotInput(
					{ ..._values, areaID },
					{
						setSubmitting,
					},
					t('production.plotInputSuccessToast')
				)
			)
		},
	})

	const disableCTA = !isEmptyObject(errors) || !trigger
	const handleChange = useCallback(
		(action, meta) => {
			const docs = []
			if (action === 'add_Values') {
				const { rowIndex, value, type, quantity } = meta

				if (type === 'date') {
					docs[rowIndex] = merge(activeDocuments[rowIndex], {
						date: value,
					})
				}
				if (type === 'productID') {
					setCurrentAvlQty(quantity)
					docs[rowIndex] = merge(activeDocuments[rowIndex], {
						quantity: 0,
						productID: value,
					})
				}
				if (type === 'quantity') {
					docs[rowIndex] = merge(activeDocuments[rowIndex], {
						quantity: parseFloat(value, 4),
					})
				}
				if (type === 'storageUnits') {
					docs[rowIndex] = merge(activeDocuments[rowIndex], {
						storageUnits: value,
					})
				}
				if (type === 'productionLine') {
					docs[rowIndex] = merge(activeDocuments[rowIndex], {
						productionLine: value,
					})
				}
				setActiveDocuments(docs)
				setFieldValue('traces', docs)
				setLastUpdatedIndex(rowIndex)
				setTrigger(true)
			}
		},
		[setFieldValue, activeDocuments, setLastUpdatedIndex]
	)
	const handleClose = () => {
		setShowModal(false)
	}
	const assignTank = (quantity, productID) => {
		const tankDetails = getIn(activeDocuments, [0, 'storageUnits']) || []
		setStorageTanks(tankDetails)
		setShowModal(!showModal)
		setCurrentProducedQty(quantity)

		setProductDetails({
			productID,
			producedQty: quantity,
		})
	}

	const title = NAME_ALIASES[rootModule]
	const breadCrumbs = useMemo(() => getBreadCrumbsList({ title }), [
		title,
		getBreadCrumbsList,
	])

	return (
		<>
			<Box padding={8} width="100%" height="100%">
				<Box style={{ padding: '0 5' }}>
					<Breadcrumb
						links={breadCrumbs}
						onClick={target => handleBreadCrumbClick(target)}
					/>
				</Box>
				<Spacer size={24} />
				<Box id="input-enterDataProdn">
					<CollapsableSegment
						expand
						title={t('production.enterProductionDetails')}
						hasError={disableCTA}
						open
						message={
							disableCTA
								? t('validation.collapsableSegmentWarning')
								: t('validation.collapsableSegmentSuccess')
						}
						onToggle={() => {}}
					>
						<Box>
							<Spacer size={14} />
							<div
								style={{
									display: 'flex',
									justifyContent: 'end',
									color: theme.color.themeGreen2,
								}}
							>
								<div>
									{`* ${t(
										'production.productionMappingText'
									)}`}
								</div>
							</div>
							<Spacer size={10} />
							<BoxShadowTable
								size="large"
								key={`
									${activeDocuments[lastUpdatedIndex].productID}-${activeDocuments[lastUpdatedIndex].productionLine}
								`}
								columnConfig={getPlotInputColumnConfig({
									type: 'plot',
									docType: 'plot',
									handleChange,
									activeProducts,
									assignTank,
									productionLine,
									t,
									errors,
								})}
								rowData={activeDocuments}
							/>
							<Modal
								noPadding
								show={showModal}
								size="large"
								body={
									<AssignTankForm
										activeTanks={storageTanks}
										tankList={tankList}
										onClose={handleClose}
										producedQty={currentProducedQty}
										productDetails={productDetails}
										onChange={value => {
											handleChange('add_Values', {
												rowIndex: 0,
												value: value.storage,
												type: 'storageUnits',
											})
											setShowModal(false)
										}}
									/>
								}
								hideButtons
								onClose={handleClose}
							/>
						</Box>
					</CollapsableSegment>
				</Box>
				<Spacer size={24} />
				<Box row justifyContent="space-between">
					<Box style={{ padding: 4 }} width="200px">
						<Button
							label={t('common.back')}
							rounded
							customIcon={
								<ButtonIconWrapper lightBG>
									<Icon glyph={LeftArrowIcon} />
								</ButtonIconWrapper>
							}
							onClick={() =>
								dispatch(
									MainRouteDuc.creators.switchPage(
										MainRouteDuc.types.MILL$SUBROOT,
										{
											rootModule: 'production',
										}
									)
								)
							}
						/>
					</Box>
					<Box
						id="createIpSubmit"
						style={{ padding: 2 }}
						width="200px"
					>
						<Button
							disabled={disableCTA}
							isLoading={isSubmitting}
							label={t('common.submit')}
							primary
							rounded
							onClick={() => {
								submitForm()
								dispatch(
									WebTourDuc.creators.setActiveTourModule('')
								)
							}}
						/>
					</Box>
				</Box>
			</Box>
			{tourModule === 'createIpBatch2' && <WebTour />}
		</>
	)
}

export { CreatePlotInput }
