import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { merge } from 'timm'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AppDuc } from 'core-app/modules/App/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { AdminDuc } from 'core-app/modules/Admin/duc'
import { Title } from 'core-app/shared/components/Title'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Card } from 'ui-lib/components/Card'
import { Button } from 'ui-lib/components/Button'
import { Input } from 'ui-lib/components/Input'
import { SelectSearch, Select } from 'ui-lib/components/Select'
import { H3 } from 'ui-lib/components/Typography'
import { Label } from 'ui-lib/components/Label'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import DeleteIcon from 'ui-lib/icons/deleteTrash.svg'
import theme from 'ui-lib/utils/base-theme'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { isEmptyObject, getRandomNumber } from 'core-app/utils/helpers'
import { useFormik } from 'formik'
import * as yup from 'yup'
import {
	getSupplyChainModels,
	getStorageTypes,
	inventoryTypes,
	ASSETS_ALIAS,
} from '../../config'

const CreateStorageUnit = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const locationState = useSelector(AdminDuc.selectors.location)
	const actorArray = useSelector(AuthDuc.selectors.getActor)
	const actor = actorArray[0] || []
	const assets = useSelector(AdminDuc.selectors.getAssetsListing)
	const { storageunits = [] } = assets

	const { payload = {} } = locationState
	const { action } = payload
	const initialValues = useSelector(
		AdminDuc.selectors.getInitialStorageUnitDetails
	)
	const [storageType, setStorageType] = useState()
	const [isPlantation, setIsPlantation] = useState(false)
	const [key, setKey] = useState(getRandomNumber())
	const [fragmentKey, setFragmentKey] = useState(getRandomNumber())
	const [reservations, setReservations] = useState([
		{
			organizationID: '',
			name: '',
			quantity: '',
		},
	])
	const [deletedReservations, setDeletedReservations] = useState([])

	const allProducts = useSelector(AuthDuc.selectors.getProducts)
	const minimalProductList = allProducts
		.filter(prod => prod.code === 'FFB' || prod.code === 'PK')
		.map(prod => {
			return {
				id: prod.id,
				label: prod.name,
				code: prod.code,
			}
		})

	const productsList = allProducts
		.filter(list => list.code !== 'FFB' && list.code !== 'PK')
		.map(list => {
			return {
				id: list.id,
				label: list.name,
				code: list.code,
			}
		})

	const partnerList = useSelector(AuthDuc.selectors.getTargetPartnersOfUser)
	const partners = partnerList.map(list => {
		return {
			id: list.id,
			label: list.name,
		}
	})

	const certificationListing = useSelector(AuthDuc.selectors.getCertificates)
	const certificationTypes = certificationListing.map(certs => {
		return {
			label: certs,
			id: certs,
		}
	})

	const checkIfCertificatesExist = certificationListing.length !== 0

	const filteredStorageUnits = storageunits.filter(
		stu => stu.type === 'area' && stu.meta.inventoryType === 'incoming'
	)

	const checkIfIncomingAreaExists =
		filteredStorageUnits.length > 0 && action !== 'edit-asset'

	const isStorageCompany = actor.includes('palmoil_storage')

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.admin'),
			name: 'admin',
			isActive: true,
		},
		{
			label: t('breadcrumb.assets'),
			name: 'assets',
			isActive: true,
		},
		{
			label:
				action === 'edit-asset'
					? t('breadcrumb.editAsset')
					: t('breadcrumb.create-asset'),
			name: 'create-asset',
			isActive: false,
		},
	]

	const labelColor = theme.color.accent2

	/** Handlers to react on actions */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'admin') {
			dispatch(MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN))
		}
		if (target === 'assets') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.ADMIN$SUBROOT,
					{
						rootModule: 'assets',
					}
				)
			)
		}
	}

	useEffect(() => {
		setStorageType(initialValues.type)

		const checkIsPlantation = actor.includes('palmoil_plantation')
		setIsPlantation(checkIsPlantation)
	}, [initialValues, actor])

	const disableCurrentQty = action === 'create-asset'

	const validationSchema = type => {
		const shape = yup.object().shape({
			type: yup.string().required(),
			name: yup.string().required(t('validation.nameRequired')),
			capacity: yup.number().min(0),
			productID: yup.string().required(),
			initialQuantity: yup.number(),
			supplyChainModel: yup.string(),
			inventoryType: yup.string(),
			certificationType: yup.string().required(),
			reservedFor: yup.string(),
		})

		if (type && type !== 'container' && checkIfCertificatesExist) {
			shape.fields.supplyChainModel = shape.fields.supplyChainModel.required()
		}

		if (type === 'area') {
			shape.fields.initialQuantity = shape.fields.initialQuantity
				.min(0)
				.required()
		}

		if (type === 'tank') {
			shape.fields.capacity = shape.fields.capacity
				.min(0)
				.required(t('validation.capacityReq'))

			shape.fields.initialQuantity = shape.fields.initialQuantity
				.min(0)
				.required()
				.max(yup.ref('capacity'), t('validation.currentQuantityCheck'))
		}

		if (!actor.includes('palmoil_storage')) {
			shape.fields.inventoryType = shape.fields.inventoryType.required()
		}

		return shape
	}

	const {
		handleSubmit,
		values,
		handleChange,
		handleBlur,
		setFieldValue,
		touched,
		errors,
	} = useFormik({
		initialValues,
		enableReinitialize: true,
		validationSchema: validationSchema(storageType),
		onSubmit: _values => {
			const requestPayload = {
				..._values,
				actor,
			}

			if (reservations.length > 0) {
				requestPayload.multiReservation = reservations.map(
					reservation => ({
						organizationID: reservation.organizationID,
						name: reservation.name,
						quantity: parseInt(reservation.quantity, 10),
					})
				)
				if (action === 'edit-asset' && deletedReservations.length > 0) {
					requestPayload.multiReservation = requestPayload.multiReservation.concat(
						deletedReservations
					)
				}
			}

			if (!checkIfCertificatesExist) {
				requestPayload.supplyChainModel = 'none'
			}

			switch (action) {
				case 'create-asset': {
					dispatch(
						AdminDuc.creators.createStorageUnitAsAsset(
							requestPayload,
							t('createAsset.storageUnitSuccessToast')
						)
					)

					break
				}
				case 'edit-asset': {
					const updatedValues = {
						...requestPayload,
						storageUnitID: payload.documentReference,
					}
					dispatch(
						AdminDuc.creators.editStorageUnit(
							updatedValues,
							t('createAsset.storageUnitUpdateToast')
						)
					)

					break
				}

				default:
					break
			}
		},
	})

	const productOptions =
		values.type === 'area' || values.type === 'container'
			? minimalProductList
			: productsList

	const hasCertificates = checkIfCertificatesExist
	const certificateOptions = hasCertificates
		? certificationTypes
		: [{ label: 'Uncertified', id: 'uncertified' }]

	const supplyChainModelOptions = hasCertificates
		? getSupplyChainModels(t)
		: [{ label: 'None', name: 'none' }]

	const getDisabledIndexes = () => {
		if (initialValues.supplyChainModel === 'mass-balance') return [1, 2]
		if (initialValues.supplyChainModel === 'segregated') return [1]
		if (
			initialValues.supplyChainModel === 'identity-preserved' ||
			initialValues.initialQuantity === 0
		)
			return []
	}

	const disableCTA = !isEmptyObject(errors) || !values.name

	useEffect(() => {
		if (action === 'create-asset') {
			setFieldValue('name', '')
			setFieldValue('capacity', '')
			setFieldValue('productID', '')
			setFieldValue('initialQuantity', '')
			setFieldValue('supplyChainModel', '')
			setFieldValue('inventoryType', '')
			setFieldValue('certificationType', '')
			setFieldValue('reservedFor', '')

			setKey(getRandomNumber())
		}
	}, [storageType, setFieldValue, action])

	const inventoryTypeError = touched.inventoryType && errors.inventoryType
	const storageTypeError = touched.type && errors.type
	const storageUnitNameError = touched.name && errors.name
	const productError = touched.productID && errors.productID
	const certificationTypeError =
		touched.certificationType && errors.certificationType
	const capacityError = touched.capacity && errors.capacity
	const initialQuantityError =
		touched.initialQuantity && errors.initialQuantity
	const supplyChainModelError =
		touched.supplyChainModel && errors.supplyChainModel
	const reservedForError = touched.reservedFor && errors.reservedFor

	useEffect(() => {
		if (action === 'edit-asset') {
			setFragmentKey(getRandomNumber())
		}
	}, [action, initialValues])

	const handleAddRow = () => {
		setReservations([
			...reservations,
			{
				organizationID: '',
				name: '',
				quantity: '',
			},
		])
	}

	const handleInputChange = (type, organizationID, updatedField, index) => {
		const currentActiveDocuments = [...reservations]

		const obj = {
			organizationID:
				type === 'reservedFor'
					? updatedField.organizationID
					: reservations[index]?.organizationID || '',
			name:
				type === 'reservedFor'
					? updatedField.name
					: reservations[index]?.name || '',
			quantity:
				type === 'quantity'
					? updatedField.quantity
					: reservations[index]?.quantity || '',
		}

		currentActiveDocuments[index] = merge(
			currentActiveDocuments[index],
			obj
		)

		setReservations(currentActiveDocuments)
	}

	const handleDeleteRow = organizationID => {
		if (action === 'edit-asset') {
			const reservationToBeDeleted = reservations.filter(
				res => res.organizationID === organizationID
			)[0]
			const newArray = [
				...deletedReservations,
				{ ...reservationToBeDeleted, state: 'remove' },
			]
			setDeletedReservations(newArray.flat())
		}

		const newReservations = reservations.filter(
			res => res.organizationID !== organizationID
		)
		setReservations(newReservations)
	}

	useEffect(() => {
		if (initialValues?.multiReservation?.length > 0) {
			setReservations(initialValues.multiReservation)
		}
	}, [initialValues])

	return (
		<Box padding={8} width="100%" height="100%">
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Box row justifyContent="space-between" alignItems="center">
				<Title
					title={
						action === 'edit-asset'
							? `${t('createAsset.editAsset')} - ${t(
									'assetsHome.storageUnits'
							  )}`
							: `${t('common.create')} ${t(
									'assetsHome.storageUnits'
							  )}`
					}
				/>
			</Box>
			<Card width={isMobile ? '320px' : '100%'} textAlign="left">
				<Box
					padding={8}
					style={{
						backgroundColor: theme.color.accent3,
					}}
				>
					<H3
						bold
						color={theme.color.accent2}
						style={{
							position: 'relative',
							right: '-25px',
						}}
					>
						{action === 'edit-asset'
							? `${t('createAsset.editAsset')} - ${t(
									'assetsHome.storageUnits'
							  )}`
							: `${t('assetsHome.createAsset')} - ${t(
									'assetsHome.storageUnits'
							  )}`}
					</H3>
				</Box>
				<React.Fragment key={fragmentKey}>
					<Box style={{ padding: '24px 16px 16px 24px' }}>
						<form onSubmit={handleSubmit}>
							<Box
								style={{
									border: `1px solid ${theme.color.grey7}`,
									borderRadius: '4px',
									padding: '16px',
								}}
							>
								<Box
									row={!isMobile}
									justifyContent="flex-start"
								>
									<Label
										required
										style={{ color: labelColor }}
									>
										{t('createAsset.chooseStrgtype')}
									</Label>
									<Spacer
										size={isMobile ? 8 : 40}
										horizontal
									/>
									<SelectSearch
										placeholder={t('createAsset.choose')}
										value={values.type}
										name="type"
										options={getStorageTypes(actor, t)}
										onChange={({ name }) => {
											setFieldValue('type', name)
											setStorageType(name)
										}}
										returnOnlyValue
										disabled={action === 'edit-asset'}
										labelKey="label"
										valueKey="name"
										onBlur={handleBlur}
										error={storageTypeError}
										defaultLabel={t(
											'common.selectDefaultLabel'
										)}
									/>
								</Box>
								{!actor.includes('palmoil_storage') && (
									<Box
										row={!isMobile}
										justifyContent="flex-start"
									>
										<Label
											required
											style={{ color: labelColor }}
										>
											{t(
												'createAsset.chooseInventoryType'
											)}
										</Label>
										<Spacer
											size={isMobile ? 8 : 30}
											horizontal
										/>
										<Select
											placeholder={t(
												'createAsset.choose'
											)}
											value={values.inventoryType}
											key={values.inventoryType}
											name="inventoryType"
											options={inventoryTypes(
												values.type,
												checkIfIncomingAreaExists,
												isStorageCompany,
												t
											)}
											onChange={({ name }) => {
												setFieldValue(
													'inventoryType',
													name
												)
											}}
											returnOnlyValue
											labelKey="label"
											valueKey="name"
											onBlur={handleBlur}
											error={inventoryTypeError}
										/>
									</Box>
								)}
							</Box>
							<Box
								style={{
									border: `1px solid ${theme.color.grey7}`,
									borderRadius: '4px',
									padding: '16px',
									margin: '24px 0',
								}}
							>
								<Box
									row={!isMobile}
									justifyContent="flex-start"
								>
									<Label
										required
										style={{
											minWidth: 158,
											color: labelColor,
										}}
									>
										{`${t(ASSETS_ALIAS[storageType])}  ${t(
											'createAsset.name'
										)}`}
									</Label>
									<Spacer
										size={isMobile ? 8 : 45}
										horizontal
									/>
									<Input
										value={values.name}
										key={key}
										name="name"
										type="text"
										onChange={handleChange}
										onBlur={handleBlur}
										error={storageUnitNameError}
										extendStyles={{
											width: 246,
										}}
									/>
								</Box>
								<Box
									row={!isMobile}
									justifyContent="flex-start"
								>
									<Label
										required
										style={{ color: labelColor }}
									>
										{t('createAsset.productStored')}
									</Label>
									<Spacer
										size={isMobile ? 8 : 86}
										horizontal
									/>
									<SelectSearch
										placeholder={t('createAsset.choose')}
										key={values.productID}
										value={values.productID}
										name="productID"
										options={productOptions}
										onChange={value => {
											setFieldValue('productID', value.id)
										}}
										returnOnlyValue
										labelKey="label"
										valueKey="id"
										onBlur={handleBlur}
										error={productError}
										defaultLabel={t(
											'common.selectDefaultLabel'
										)}
									/>
								</Box>
								<Box
									row={!isMobile}
									justifyContent="flex-start"
								>
									<Label
										required
										style={{ color: labelColor }}
									>
										{t('assetsHome.certificationType')}
									</Label>
									<Spacer
										size={isMobile ? 8 : 68}
										horizontal
									/>
									<Select
										placeholder={t('createAsset.choose')}
										value={values.certificationType}
										key={values.certificationType}
										name="certificationType"
										options={certificateOptions}
										onChange={value => {
											setFieldValue(
												'certificationType',
												value.id
											)
										}}
										returnOnlyValue
										labelKey="label"
										valueKey="id"
										onBlur={handleBlur}
										error={certificationTypeError}
									/>
								</Box>
								{!isPlantation && (
									<>
										{values.type !== 'area' && (
											<Box
												row={!isMobile}
												justifyContent="flex-start"
											>
												<Label
													required={
														!isPlantation ||
														values.type === 'area'
													}
													style={{
														color: labelColor,
													}}
												>
													{t('assetsHome.capacity')}
												</Label>
												<Spacer
													size={
														isPlantation ? 141 : 130
													}
													horizontal
												/>
												<Input
													value={values.capacity}
													name="capacity"
													type="number"
													onChange={handleChange}
													onBlur={handleBlur}
													error={capacityError}
													extendStyles={{
														width: 246,
													}}
												/>
												<Spacer size={32} horizontal />
												<Label
													style={{
														color: labelColor,
														padding: '12px 0 0',
													}}
												>
													{t('createAsset.mtExpnd')}
												</Label>
											</Box>
										)}
										<Box
											row={!isMobile}
											justifyContent="flex-start"
										>
											<Label
												required={!isPlantation}
												style={{ color: labelColor }}
											>
												{t('createAsset.currQty')}
											</Label>
											<Spacer
												size={isPlantation ? 87 : 76}
												horizontal
											/>
											<Input
												value={values.initialQuantity}
												key={key}
												name="initialQuantity"
												type="number"
												onChange={handleChange}
												onBlur={handleBlur}
												error={initialQuantityError}
												extendStyles={{
													width: 246,
												}}
												disabled={!disableCurrentQty}
											/>
											{values.type === 'area' && (
												<>
													<Spacer
														size={32}
														horizontal
													/>
													<Label
														style={{
															color: labelColor,
															padding: '12px 0 0',
														}}
													>
														{t(
															'createAsset.mtExpnd'
														)}
													</Label>
												</>
											)}
										</Box>
									</>
								)}
							</Box>

							{!isPlantation && (
								<Box
									style={{
										border: `1px solid ${theme.color.grey7}`,
										borderRadius: '4px',
										padding: '16px',
									}}
								>
									<Box
										row={!isMobile}
										justifyContent="flex-start"
									>
										<Label
											required={checkIfCertificatesExist}
											style={{ color: labelColor }}
										>
											{t('createAsset.scModel')}
										</Label>
										<Spacer
											size={isMobile ? 8 : 50}
											horizontal
										/>
										<SelectSearch
											placeholder={t(
												'createAsset.choose'
											)}
											value={
												checkIfCertificatesExist
													? values.supplyChainModel
													: 'none'
											}
											disabled={!checkIfCertificatesExist}
											key={values.supplyChainModel}
											name="supplyChainModel"
											options={supplyChainModelOptions}
											onChange={value => {
												setFieldValue(
													'supplyChainModel',
													value.name
												)
											}}
											returnOnlyValue
											labelKey="label"
											valueKey="name"
											onBlur={handleBlur}
											error={supplyChainModelError}
											defaultLabel={t(
												'common.selectDefaultLabel'
											)}
											disabledIndexArr={
												action === 'edit-asset'
													? getDisabledIndexes()
													: false
											}
										/>
									</Box>
									{values.type !== 'area' &&
										!actor.includes('palmoil_storage') && (
											<Box
												row={!isMobile}
												justifyContent="flex-start"
											>
												<Label
													style={{
														color: labelColor,
													}}
												>
													{t(
														'createAsset.reservedFor'
													)}
												</Label>
												<Spacer
													size={
														checkIfCertificatesExist
															? 114
															: 100
													}
													horizontal
												/>
												<SelectSearch
													placeholder={t(
														'createAsset.choose'
													)}
													value={values.reservedFor}
													name="reservedFor"
													options={partners}
													onChange={value => {
														setFieldValue(
															'reservedFor',
															value.id
														)
													}}
													returnOnlyValue
													labelKey="label"
													valueKey="id"
													onBlur={handleBlur}
													error={reservedForError}
													defaultLabel={t(
														'common.selectDefaultLabel'
													)}
												/>
											</Box>
										)}
								</Box>
							)}
							{actor.includes('palmoil_storage') && (
								<>
									<H3
										bold
										color={theme.color.accent2}
										style={{
											padding: '0px 16px',
											margin: '12px 0px 0px',
										}}
									>
										Reserved For:
									</H3>
									<Box
										style={{
											border: `1px solid ${theme.color.grey7}`,
											borderRadius: '4px',
											padding: '0px 16px',
											margin: '12px 0px 12px 0px',
										}}
									>
										{reservations.map(
											(reservation, index) => (
												<Box
													key={
														reservation.organizationID
													}
													row
													style={{
														margin: '5px 0',
														width: '60%',
														padding: '12px 0',
													}}
													justifyContent="space-between"
												>
													<Box>
														<SelectSearch
															hideError
															name={`reservedFor${index}`}
															returnOnlyValue
															valueKey="id"
															value={
																reservation.organizationID
															}
															options={partners}
															labelKey="label"
															defaultLabel={t(
																'common.selectDefaultLabel'
															)}
															placeholder={t(
																'createAsset.choose'
															)}
															onChange={value => {
																handleInputChange(
																	'reservedFor',
																	reservation.organizationID,
																	{
																		name:
																			value.label,
																		organizationID:
																			value.id,
																	},
																	index
																)
															}}
														/>
													</Box>
													<Spacer size={20} />
													<Box>
														<Input
															hideError
															type="number"
															name={`quantity${index}`}
															width="fit-content"
															value={
																reservation.quantity
															}
															placeholder={t(
																'production.quantity'
															)}
															onChange={e => {
																handleInputChange(
																	'quantity',
																	reservation.organizationID,
																	{
																		quantity:
																			e
																				.target
																				.valueAsNumber,
																	},
																	index
																)
															}}
														/>
													</Box>
													{reservations.length >
														1 && (
														<Box
															row
															width="5.5%"
															style={{
																paddingTop:
																	'8px',
																marginRight: 20,
															}}
														>
															<IconWrapper
																hoverEffect
																width={30}
																height={30}
																onClick={() => {
																	handleDeleteRow(
																		reservation.organizationID
																	)
																}}
															>
																<Icon
																	glyph={
																		DeleteIcon
																	}
																	style={{
																		cursor:
																			'pointer',
																		width: 25,
																		height: 25,
																	}}
																/>
															</IconWrapper>
														</Box>
													)}
												</Box>
											)
										)}
										<Box
											padding="16px 8px"
											style={{
												color: '#333',
												fontSize: 14,
												cursor: 'pointer',
											}}
											onClick={handleAddRow}
										>
											+ Add More
										</Box>
									</Box>
								</>
							)}
							<Box
								alignItems="flex-end"
								style={{ margin: '24px 0' }}
							>
								<Box width={150}>
									<Button
										disabled={disableCTA}
										type="submit"
										primary
										label={t('common.submit')}
									/>
								</Box>
							</Box>
						</form>
					</Box>
				</React.Fragment>
			</Card>
		</Box>
	)
}

export { CreateStorageUnit }
