import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { getIn } from 'timm'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { WeekHandlingComponent } from 'core-app/modules/Refinery/components/WeekHandlingComponent'
import { StorageInventoryModal } from 'core-app/modules/Refinery/components/StorageInventoryModal'
import { getStorageUnitsList, getStorageCapacity } from 'core-app/utils/helpers'
import { Title } from 'core-app/shared/components/Title'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { WebTourDuc } from 'core-app/modules/WebTour/duc'
import { ProductsDuc } from 'core-app/modules/Products/duc'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Button } from 'ui-lib/components/Button'
import { Label } from 'ui-lib/components/Typography'
import { Box } from 'ui-lib/utils/Box'
import { SelectSearch } from 'ui-lib/components/Select'
import { Modal } from 'ui-lib/components/Modal'
import { Input } from 'ui-lib/components/Input'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import DeleteIcon from 'ui-lib/icons/deleteTrash.svg'
import ProductionIcon from 'ui-lib/icons/production.svg'
import StorageTankIcon from 'ui-lib/icons/storageTank.svg'
import { TransparentBgSpinner } from 'ui-lib/components/Spinner/TransparentBgSpinner'
import { getRandomNumber, applyMediaQueriesStr } from 'ui-lib/utils/helpers'
// eslint-disable-next-line import/extensions
import ProductionInfoImage from 'core/src/assets/production.jpg'
import { MillDuc } from '../../duc'

const Illustration = styled.img`
	width: 100%;
	${applyMediaQueriesStr(['mobile'], 'width: 60%;top:10%')}
`

const ProductionWeekly = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const products = useSelector(AuthDuc.selectors.getProducts)

	// Get the productId of CPO
	const productId = products.filter(ap => ap?.code === 'CPO')?.[0]?.id

	const orgDetails = useSelector(AuthDuc.selectors.getCurrentOrganization)
	const production = useSelector(MillDuc.selectors.getProduction)
	const outgoingStorageUnits = useSelector(
		ProductsDuc.selectors.getOutgoingStorageUnits
	)
	const millLoadingState = useSelector(MillDuc.selectors.getMillLoadingState)
	const productsLoadingState = useSelector(
		ProductsDuc.selectors.getProductsLoadingState
	)
	const tourStatus = useSelector(WebTourDuc.selectors.tourStatus)
	const location = useSelector(MillDuc.selectors.location)
	const { payload = {} } = location
	const { rootModule } = payload

	const currentDate = new Date()
	const initialDailyInventory = {
		monday: '',
		tuesday: '',
		wednesday: '',
		thursday: '',
		friday: '',
		saturday: '',
		sunday: '',
	}
	const initialOtherDailyDetails = {
		monday: {
			updated: false,
			error: false,
		},
		tuesday: {
			updated: false,
			error: false,
		},
		wednesday: {
			updated: false,
			error: false,
		},
		thursday: {
			updated: false,
			error: false,
		},
		friday: {
			updated: false,
			error: false,
		},
		saturday: {
			updated: false,
			error: false,
		},
		sunday: {
			updated: false,
			error: false,
		},
	}
	const initialData = {
		productID: productId,
		storageID: '',
		dailyInventory: initialDailyInventory,
		otherDailyDetails: initialOtherDailyDetails,
	}

	const initialOutgoingRows = Array(10)
		.fill()
		.map(() => initialData)

	const [weekNumber, setWeekNumber] = useState(0)
	const [outgoingID, setOutgoingID] = useState('')
	const [key, setKey] = useState(getRandomNumber())
	const [showModal, setShowModal] = useState(false)
	const [firstDayOfWeek, setFirstDayOfWeek] = useState('')
	const [lastDayOfWeek, setLastDayOfWeek] = useState('')
	const [outgoingRows, setOutgoingRows] = useState(initialOutgoingRows)
	const [currentProduction, setCurrentProduction] = useState({})
	const [supplyChainModel, setSupplyChainModel] = useState('')
	const [showKnowMoreModal, setShowKnowMoreModal] = useState(false)

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.production'),
			name: 'production',
			isActive: false,
		},
	]

	const getFilteredTanks = (
		currentProductId,
		storageId,
		rows,
		storageUnits
	) => {
		const storageUnitsLists = getStorageUnitsList(storageUnits, true)

		const filteredRows = rows.filter(
			item =>
				item.storageID !== '' &&
				item.storageID !== storageId &&
				item.productID === currentProductId
		)

		const alreadySelectedStorageUnits =
			filteredRows.length > 0
				? filteredRows.map(row => {
						return row.storageID
				  })
				: []

		const checkForSupplyChainModel = item => {
			let check = true
			if (
				supplyChainModel &&
				supplyChainModel !== 'none' &&
				supplyChainModel !== item?.supplyChainModel
			) {
				check = false
			}

			return check
		}
		const currentStorageUnit = storageUnitsLists.find(
			item => item?.name === storageId
		)

		const fullList = storageUnitsLists.filter(
			item =>
				!alreadySelectedStorageUnits.includes(item.name) &&
				item.products.includes(currentProductId)
		)
		const filteredListWithSupplyChainModel = fullList.filter(item =>
			checkForSupplyChainModel(item)
		)

		return filteredListWithSupplyChainModel.find(
			item =>
				!storageId ||
				getIn(item, ['supplyChainModel']) ===
					getIn(currentStorageUnit, ['supplyChainModel'])
		)
			? filteredListWithSupplyChainModel
			: fullList
		// to show already selected but not the current supplychainmodel
	}

	const daysOfWeek = [
		'monday',
		'tuesday',
		'wednesday',
		'thursday',
		'friday',
		'saturday',
		'sunday',
	]

	const daysOfWeekShort = [
		t('production.mon'),
		t('production.tue'),
		t('production.wed'),
		t('production.thur'),
		t('production.fri'),
		t('production.sat'),
		t('production.sun'),
	]

	const getWeeklyInventory = () => {
		const weeklyInventory = []
		const updatedRows = [...outgoingRows]
		updatedRows.forEach(item => {
			const {
				productID,
				storageID,
				dailyInventory,
				otherDailyDetails,
				existingInventorySet,
			} = item
			if (productID && storageID) {
				const currentDailyInventory = daysOfWeek.map((item, index) => {
					const date = new Date(firstDayOfWeek)
					date.setDate(date.getDate() + index)
					const data = {
						day: item.charAt(0).toUpperCase() + item.slice(1),
						quantity: dailyInventory[item]
							? parseFloat(dailyInventory[item])
							: 0,
						date: date.toISOString(),
					}
					if (existingInventorySet?.[item]) {
						data.inventory = existingInventorySet[item]
						if (
							existingInventorySet[item].totalQty !==
							dailyInventory[item]
						) {
							data.updated = true
						}
					}
					if (otherDailyDetails?.[item]?.updated) {
						data.updated = true
					}

					return data
				})
				weeklyInventory.push({
					index: weeklyInventory.length,
					productID,
					storageID,
					dailyInventory: currentDailyInventory,
				})
			}
		})

		return weeklyInventory
	}

	const checkDisabled = () => {
		let disabled = false
		if (outgoingRows?.length > 0) {
			outgoingRows.forEach(row => {
				if (row?.otherDailyDetails) {
					Object.keys(row.otherDailyDetails).forEach(item => {
						if (row.otherDailyDetails[item].error) {
							disabled = true
						}
					})
				}
			})
		}

		return disabled
	}

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
	}

	const handleOnChange = (currentFirstDayOfWeek, currentLastDayOfWeek) => {
		const currentWeekNumber = moment(currentFirstDayOfWeek).week()
		const year = new Date(currentFirstDayOfWeek).getFullYear()
		setFirstDayOfWeek(currentFirstDayOfWeek)
		setLastDayOfWeek(currentLastDayOfWeek)
		setWeekNumber(moment(currentFirstDayOfWeek).week())
		setKey(getRandomNumber())
		setOutgoingRows(initialOutgoingRows)
		dispatch(MillDuc.creators.fetchProduction(currentWeekNumber, year))
	}

	const handleRowChange = (index, updatedRow) => {
		const updatedRows = [...outgoingRows]
		updatedRows[index] = updatedRow
		setOutgoingRows(updatedRows)
	}

	const handleDeleteRow = index => {
		const updatedRows = outgoingRows.filter((_, i) => i !== index)
		setOutgoingRows(updatedRows)
		setKey(getRandomNumber())
	}

	const getTotal = day => {
		let total = 0
		const updatedRows = [...outgoingRows]
		if (updatedRows?.length > 0) {
			updatedRows.forEach(row => {
				if (row.dailyInventory[day]) {
					total += parseFloat(row.dailyInventory[day])
				}
			})
		}

		return total
	}

	const getRows = (row, index) => {
		const filteredTanks = getFilteredTanks(
			row.productID,
			row.storageID,
			outgoingRows,
			outgoingStorageUnits
		)

		const hasError = []
		if (row?.otherDailyDetails) {
			Object.keys(row.otherDailyDetails).forEach(item => {
				if (row.otherDailyDetails[item].error) {
					hasError.push(item)
				}
			})
		}

		return (
			<>
				<Box
					row
					margin="8px 0"
					alignItems="center"
					key={`row-${index}`}
				>
					<Box width="20%" padding="0 5px">
						<SelectSearch
							hideError
							name="tank"
							value={row.storageID}
							placeholder={t('products.tank')}
							key={`row-${index}-tank`}
							options={filteredTanks}
							onChange={({ name, capacity }) => {
								handleRowChange(index, {
									...row,
									storageID: name,
									capacity,
									dailyInventory: initialDailyInventory,
									otherDailyDetails: initialOtherDailyDetails,
								})
								setKey(getRandomNumber())
							}}
							disabled={
								(row?.existingInventorySet &&
									Object.keys(row?.existingInventorySet)
										.length > 0) ||
								(row?.storageID &&
									getIn(orgDetails, [
										'meta',
										'enforceSupplyChainModel',
									]) &&
									getIn(orgDetails, [
										'meta',
										'supplyChainModel',
									]) &&
									getIn(orgDetails, [
										'meta',
										'supplyChainModel',
									]) !== 'none' &&
									filteredTanks.find(
										item =>
											getIn(item, ['name']) ===
											row?.storageID
									)?.supplyChainModel !==
										getIn(orgDetails, [
											'meta',
											'supplyChainModel',
										]))
							}
							returnOnlyValue
							labelKey="label"
							valueKey="name"
						/>
					</Box>
					<Box width="80%" row>
						{daysOfWeek.map((day, daysIndex) => (
							<Box width="13.5%" key={day} center>
								<Input
									hideError
									type="number"
									disabled={
										!row.storageID ||
										new Date(
											new Date(firstDayOfWeek).setDate(
												new Date(
													firstDayOfWeek
												).getDate() + daysIndex
											)
										).setHours(0, 0, 0, 0) >
											new Date(currentDate).setHours(
												0,
												0,
												0,
												0
											) ||
										(row?.storageID &&
											getIn(orgDetails, [
												'meta',
												'enforceSupplyChainModel',
											]) &&
											getIn(orgDetails, [
												'meta',
												'supplyChainModel',
											]) &&
											getIn(orgDetails, [
												'meta',
												'supplyChainModel',
											]) !== 'none' &&
											filteredTanks.find(
												item =>
													getIn(item, ['name']) ===
													row?.storageID
											)?.supplyChainModel !==
												getIn(orgDetails, [
													'meta',
													'supplyChainModel',
												]))
									}
									value={row.dailyInventory[day] || ''}
									key={`row-${index}-${day}`}
									name={`row--${index}-${day}`}
									onChange={e => {
										if (
											!row.capacity ||
											e.target.value <= row.capacity
										) {
											const currentItem =
												currentProduction
													?.weeklyInventory?.length >
													0 &&
												currentProduction?.weeklyInventory[
													index
												]?.dailyInventory.find(
													item => item.day === day
												)

											let error = false
											let updated = false
											if (
												currentItem &&
												currentItem.quantity !==
													parseFloat(e.target.value)
											) {
												updated = true
											}
											if (currentItem?.inventory) {
												const totalQty =
													currentItem?.inventory
														?.totalQty
												const availableQty =
													currentItem?.inventory
														?.availableQty
												if (
													parseFloat(e.target.value) <
													totalQty - availableQty
												) {
													error = true
												}
											}
											handleRowChange(index, {
												...row,
												dailyInventory: {
													...row.dailyInventory,
													[day]: e.target.value,
												},
												otherDailyDetails: {
													...row.otherDailyDetails,
													[day]: {
														updated,
														error,
													},
												},
											})
										} else {
											setKey(getRandomNumber())
										}
									}}
									width="90%"
								/>
							</Box>
						))}

						<Box
							row
							width="5.5%"
							style={{
								paddingTop: '8px',
							}}
							margin="0px 0px 0px 4px"
						>
							{!row.hasTrace && (
								<IconWrapper
									hoverEffect
									width={30}
									height={30}
									onClick={() => {
										handleDeleteRow(index)
									}}
								>
									<Icon
										glyph={DeleteIcon}
										style={{
											cursor: 'pointer',
											width: 25,
											height: 25,
										}}
									/>
								</IconWrapper>
							)}
						</Box>
					</Box>
				</Box>
				{hasError.length > 0 && (
					<Box
						row
						margin="8px 0"
						alignItems="center"
						key={`row-error-${index}`}
					>
						<span style={{ color: 'red', textAlign: 'center' }}>
							{`Entered value is less than quantity utilized on ${hasError.join(
								', '
							)}`}
						</span>
					</Box>
				)}
			</>
		)
	}

	useEffect(() => {
		const showPopupCookie = 'showMillProductionPopup=true'

		const shouldShowPopup = document.cookie.indexOf(showPopupCookie) === -1

		if (!tourStatus && shouldShowPopup && rootModule === 'production') {
			setShowKnowMoreModal(true)
			// Set cookie for max-age of 86400 seconds (24hrs)
			const expires = new Date(Date.now() + 86400 * 1000).toUTCString()
			document.cookie = `${showPopupCookie}; expires=${expires}; path=/;`
		}
	}, [rootModule, tourStatus])

	useEffect(() => {
		setSupplyChainModel(
			getIn(orgDetails, ['meta', 'supplyChainModel']) || 'none'
		)
	}, [orgDetails])

	useEffect(() => {
		setOutgoingRows(initialOutgoingRows)
	}, [productId])

	useEffect(() => {
		if (JSON.stringify(currentProduction) !== JSON.stringify(production)) {
			if (production?.weeklyInventory?.length > 0) {
				const currentOutgoingRows = production.weeklyInventory.map(
					item => {
						const { productID, storageID, dailyInventory } = item
						const capacity = getStorageCapacity(
							getStorageUnitsList(outgoingStorageUnits, true),
							storageID
						)
						let hasTrace = false
						const currentDailyInventory = {}
						const existingInventorySet = {}
						daysOfWeek.forEach((item, index) => {
							if (dailyInventory[index]) {
								currentDailyInventory[item] =
									dailyInventory[index].quantity
								if (dailyInventory[index]?.inventory?.traceID) {
									hasTrace = true
									existingInventorySet[item] =
										dailyInventory[index].inventory
								}
							}
						})

						return {
							productID,
							storageID,
							capacity,
							hasTrace,
							dailyInventory: currentDailyInventory,
							existingInventorySet,
						}
					}
				)

				setOutgoingRows(currentOutgoingRows)
			} else {
				setOutgoingRows(initialOutgoingRows)
			}
			setKey(getRandomNumber())
			setOutgoingID(production?.id || '')
			setCurrentProduction(production)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [production, outgoingStorageUnits])

	useEffect(() => {
		const first = currentDate.getDate() - currentDate.getDay() + 1
		setFirstDayOfWeek(new Date(new Date().setDate(first)))
		setWeekNumber(moment(new Date(new Date().setDate(first))).week())
		const last = first + 6
		setLastDayOfWeek(new Date(new Date().setDate(last)))

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<Modal
				closeable
				forceCloseviaButton
				hideButtons
				size="large"
				show={showKnowMoreModal}
				body={
					<Box>
						<Illustration
							src={ProductionInfoImage}
							alt="info image"
						/>
					</Box>
				}
				onClose={() => {
					setShowKnowMoreModal(false)
				}}
			/>
			<StorageInventoryModal
				show={showModal}
				heading={t('production.storageInventory')}
				data={outgoingStorageUnits}
				onClose={() => {
					setShowModal(false)
				}}
				size="800px"
				height="940px"
				overflow="auto"
			/>
			<Box key={key} padding={8} width="100%" height="100%">
				<Box style={{ padding: '0 5' }}>
					<Breadcrumb
						links={breadCrumbs}
						onClick={target => handleBreadCrumbClick(target)}
					/>
				</Box>
				{(millLoadingState || productsLoadingState) && (
					<TransparentBgSpinner />
				)}
				<Box row justifyContent="space-between" alignItems="baseline">
					<Box alignItems="flex-start">
						<Box row alignItems="center">
							<Title
								title={t('production.title')}
								icon={ProductionIcon}
							/>
						</Box>
					</Box>
					<Box row justifyContent="flex-end">
						<Box
							padding={2}
							margin="0 12px 0 0"
							title={t('production.viewStorageInventory')}
						>
							<Button
								rounded
								customIcon={
									<Icon
										glyph={StorageTankIcon}
										style={{
											height: 24,
											width: 24,
										}}
									/>
								}
								onClick={() => {
									setShowModal(true)
								}}
								extendStyles={{
									border: '1px solid rgb(63,86,196)',
									borderRadius: 4,
								}}
							/>
						</Box>
						<Box style={{ padding: 2 }}>
							<Button
								label={t('production.inventory')}
								rounded
								onClick={() =>
									dispatch(
										MainRouteDuc.creators.switchPage(
											MainRouteDuc.types.MILL$WACTION,
											{
												rootModule: 'inventory',
												action: 'create',
											}
										)
									)
								}
								extendStyles={{
									paddingTop: 8,
									paddingBottom: 8,
								}}
							/>
						</Box>
					</Box>
				</Box>
				<Box>
					<WeekHandlingComponent
						weekNumber={weekNumber}
						firstDayOfWeek={firstDayOfWeek}
						lastDayOfWeek={lastDayOfWeek}
						currentDate={currentDate}
						onChange={handleOnChange}
					/>
				</Box>
				<>
					<Box
						row
						alignItems="center"
						justifyContent="space-between"
						style={{
							height: 40,
							marginTop: 20,
							backgroundColor: '#CBD2EF',
							color: 'rgb(63, 86, 196)',
							padding: 8,
							fontWeight: 'bold',
						}}
					>
						<Box
							width="20%"
							style={{
								textAlign: 'center',
							}}
						>
							{t('production.productStorageTankID')}
						</Box>
						<Box
							width="80%"
							style={{
								textAlign: 'center',
							}}
						>
							{`${t('production.qtyProduced')} (${t(
								'createAsset.tons'
							)})`}
						</Box>
					</Box>
					<Box row alignItems="center" style={{ marginTop: 6 }}>
						<Box width="20%" />
						<Box row width="80%">
							{daysOfWeekShort.map(day => (
								<Box
									width="13.5%"
									key={day}
									style={{ textAlign: 'center' }}
								>
									<label htmlFor={day}>{day}</label>
								</Box>
							))}
							<Box width="5.5%" />
						</Box>
					</Box>
					{outgoingRows.map((row, index) => getRows(row, index))}
					<Box row alignItems="center" style={{ marginTop: 6 }}>
						<Box width="20%" style={{ textAlign: 'center' }}>
							{t('production.total')}
						</Box>
						<Box row width="80%">
							{daysOfWeek.map(day => (
								<Box
									width="13.5%"
									key={day}
									style={{ textAlign: 'center' }}
								>
									<label htmlFor={day}>{getTotal(day)}</label>
								</Box>
							))}
							<Box width="5.5%" />
						</Box>
					</Box>
				</>
				<Box row justifyContent="space-between" margin="10px 0 0 0">
					<Box
						row
						alignItems="center"
						width="200px"
						onClick={() => {
							const newRows = [...outgoingRows]
							for (let i = 0; i < 5; i++) {
								newRows.push(initialData)
							}
							setOutgoingRows(newRows)
						}}
					>
						<div
							style={{
								width: 40,
								height: 40,
								borderRadius: '50%',
								backgroundColor: '#00DD00',
								color: '#fff',
								textAlign: 'center',
								lineHeight: '40px',
								fontSize: 32,
								marginRight: 12,
								cursor: 'pointer',
							}}
						>
							+
						</div>
						<Label color="inherit" bold small link as="a">
							{t('production.addMorePrimary')}
						</Label>
					</Box>
				</Box>
				<Box row style={{ padding: 2 }} justifyContent="flex-end">
					<Button
						label={t('common.save')}
						primary
						rounded
						extendStyles={{ width: 150 }}
						disabled={checkDisabled()}
						onClick={() => {
							const weeklyInventory = getWeeklyInventory()
							const payload = {
								weekNumber,
								inventoryType: 'outgoing',
								year: firstDayOfWeek.getFullYear(),
								meta: {
									fromDate: firstDayOfWeek.toISOString(),
									toDate: lastDayOfWeek.toISOString(),
								},
								weeklyInventory,
							}
							if (outgoingID) {
								payload.id = outgoingID
							}
							dispatch(
								MillDuc.creators.updateProduction(
									payload,
									t('common.successUpdate')
								)
							)
						}}
					/>
				</Box>
			</Box>
		</>
	)
}

export { ProductionWeekly }
