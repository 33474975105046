import React from 'react'
import { Box } from 'ui-lib/utils/Box'
import { Label, TableTitle } from 'ui-lib/components/Typography'
import { getIn } from 'timm'
import { ButtonWithNoBorder } from 'ui-lib/components/Button'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { SelectSearch } from 'ui-lib/components/Select'
import { Input } from 'ui-lib/components/Input'
import { SortDown, SortReset } from 'ui-lib/components/Table'
import { getNextSortOrder } from 'core-app/utils/helpers'
import { getDateByFormat } from 'core-app/utils/date'
import { SortableKeys, TABLE_ALIAS } from '../config'

// workaround to implement debounce for quantity change
let timeoutID = null

export const getFFBQualityColumnConfig = ({ docType, onChange, t }) => {
	return [
		{
			property: 'createdAt',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.createdAt)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { createdAt } = datum

				return <Label as="span">{getDateByFormat(createdAt)}</Label>
			},
		},
		{
			property: 'consignmentNumber',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.consignmentNumber)}</TableTitle>
				</Box>
			),
			render: datum => {
				const value = getIn(datum, ['meta', 'entityNumber']) || '- - -'

				return <Label as="span">{value}</Label>
			},
			primary: true,
		},
		{
			property: 'ripe',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.ripe)}</TableTitle>
				</Box>
			),
			render: datum => {
				const value =
					getIn(datum, ['meta', 'ffbQuality', 'ripeBunches']) ||
					'- - -'

				return <Label as="span">{value}</Label>
			},
		},
		{
			property: 'overRipe',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.overRipe)}</TableTitle>
				</Box>
			),
			render: datum => {
				const value =
					getIn(datum, ['meta', 'ffbQuality', 'overripeBunches']) ||
					'- - -'

				return <Label as="span">{value}</Label>
			},
		},
		{
			property: 'underRipe',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.underRipe)}</TableTitle>
				</Box>
			),
			render: datum => {
				const value =
					getIn(datum, ['meta', 'ffbQuality', 'underripeBunches']) ||
					'- - -'

				return <Label as="span">{value}</Label>
			},
		},
		{
			property: 'empty',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.empty)}</TableTitle>
				</Box>
			),
			render: datum => {
				const value =
					getIn(datum, ['meta', 'ffbQuality', 'emptyBunches']) ||
					'- - -'

				return <Label as="span">{value}</Label>
			},
		},
		{
			property: 'callToAction',
			render: datum => {
				const editable = getIn(datum, ['meta', 'editable'])
				if (editable) {
					return (
						<ButtonWithNoBorder
							label={t('common.edit').toUpperCase()}
							onClick={() =>
								onChange('edit_ffb_quality', {
									row: datum,
									docType,
								})
							}
						/>
					)
				}

				return (
					<ButtonWithNoBorder
						label={t('common.viewBtn').toUpperCase()}
						onClick={() =>
							onChange('view_ffb_quality_report', {
								row: datum,
								docType,
							})
						}
					/>
				)
			},
		},
	]
}

export const getCPOQualityColumnConfig = ({ docType, onChange, t }) => {
	return [
		{
			property: 'createdAt',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.createdAt)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { createdAt } = datum

				return <Label as="span">{getDateByFormat(createdAt)}</Label>
			},
		},
		{
			property: 'consignmentNumber',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.consignmentNumber)}</TableTitle>
				</Box>
			),
			render: datum => {
				const value = getIn(datum, ['meta', 'entityNumber']) || '- - -'

				return <Label as="span">{value}</Label>
			},
			primary: true,
		},
		{
			property: 'ffa',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.ffa)}</TableTitle>
				</Box>
			),
			render: datum => {
				const value =
					getIn(datum, ['meta', 'cpoQuality', 'ffa']) || '- - -'

				return <Label as="span">{value}</Label>
			},
		},
		{
			property: 'dobi',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.dobi)}</TableTitle>
				</Box>
			),
			render: datum => {
				const value =
					getIn(datum, ['meta', 'cpoQuality', 'dobi']) || '- - -'

				return <Label as="span">{value}</Label>
			},
		},
		{
			property: 'mni',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.mni)}</TableTitle>
				</Box>
			),
			render: datum => {
				const value =
					getIn(datum, ['meta', 'cpoQuality', 'mniValue']) || '- - -'

				return <Label as="span">{value}</Label>
			},
		},
		{
			property: 'callToAction',
			render: datum => {
				const editable = getIn(datum, ['meta', 'editable'])
				if (editable) {
					return (
						<ButtonWithNoBorder
							label={t('common.edit').toUpperCase()}
							onClick={() =>
								onChange('edit_cpo_quality', {
									row: datum,
									docType,
								})
							}
						/>
					)
				}

				return (
					<ButtonWithNoBorder
						label={t('common.viewBtn').toUpperCase()}
						onClick={() =>
							onChange('view_cpo_quality_report', {
								row: datum,
								docType,
							})
						}
					/>
				)
			},
		},
	]
}

export const getProductionTableColumnConfig = ({
	type,
	docType,
	subModuleType,
	sortsMap,
	onChange,
	allProducts,
	t,
}) => {
	return [
		{
			property: 'createdAt',
			header: (
				<Box
					row
					alignItems="center"
					style={{ cursor: 'pointer' }}
					onClick={() => {
						if (SortableKeys.includes('createdAt')) {
							onChange('initiate_sort', {
								type,
								docType: subModuleType,
								field: 'createdAt',
								order: getNextSortOrder(sortsMap.createdAt),
							})
						}
					}}
				>
					{SortableKeys.includes('createdAt') && (
						<IconWrapper
							width={10}
							margin="0 5px"
							sortUp={sortsMap.createdAt === 'asc'}
							sortDown={sortsMap.createdAt === 'desc'}
						>
							<Icon
								rotate180={sortsMap.createdAt === 'asc'}
								glyph={
									sortsMap.createdAt ? SortDown : SortReset
								}
							/>
						</IconWrapper>
					)}
					<TableTitle>{t(TABLE_ALIAS.createdAt)}</TableTitle>
				</Box>
			),
			render: datum => {
				const createdAt = getIn(datum, ['createdAt'])

				return <Label as="span">{getDateByFormat(createdAt)}</Label>
			},
			size: '15%',
		},
		{
			property: 'batchReference',
			header: (
				<Box
					row
					alignItems="center"
					style={{
						cursor: 'pointer',
					}}
				>
					<TableTitle>
						{docType === 'transforming'
							? t(TABLE_ALIAS.inputBatchReference)
							: t(TABLE_ALIAS.outputBatchReference)}
					</TableTitle>
				</Box>
			),
			render: datum => {
				const batchReference =
					docType === 'transforming'
						? getIn(datum, ['batchReference'])
						: getIn(datum, ['meta', 'batchReference'])

				return <Label as="span">{batchReference}</Label>
			},
			primary: true,
			size: '22%',
		},
		{
			property: 'product',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>
						{docType === 'transforming'
							? t(TABLE_ALIAS.inputMaterial)
							: t(TABLE_ALIAS.outputProduct)}
					</TableTitle>
				</Box>
			),
			render: datum => {
				const productID =
					docType === 'transforming'
						? getIn(datum, ['meta', 'productIDs', 0])
						: getIn(datum, ['productID'])
				const productName = allProducts.filter(
					product => product.id === productID
				)
				const name = getIn(productName, [0, 'name']) || ''

				return <Label as="span">{name}</Label>
			},
			size: '20%',
		},
		{
			property: 'quantity',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>
						{docType === 'transforming'
							? t(TABLE_ALIAS.sourcedQuantity)
							: t(TABLE_ALIAS.producedQuantity)}
					</TableTitle>
				</Box>
			),
			render: datum => {
				const productID =
					docType === 'transforming'
						? getIn(datum, ['meta', 'productIDs', 0])
						: getIn(datum, ['productID'])
				const productName = allProducts.filter(
					product => product.id === productID
				)
				const uom = getIn(productName, [0, 'uom']) || ''

				const totalQuantity =
					docType === 'transforming'
						? datum.traces
								.map(trace => trace.quantityUtilized)
								.reduce((ttl, trace) => trace + ttl)
						: getIn(datum, ['totalQty'])

				return (
					<Label as="span">{`${
						totalQuantity
							.toString()
							.match(/^-?\d+(?:\.\d{0,3})?/)[0]
					} ${uom} (${totalQuantity * 1000} Kg)`}</Label>
				)
			},
			size: '15%',
		},
		{
			property: 'batchDetails',
			render: datum => {
				if (docType !== 'transforming')
					return (
						<ButtonWithNoBorder
							label={t('common.viewBtn')}
							onClick={() =>
								onChange('open_document', {
									row: datum,
									docType,
								})
							}
						/>
					)
			},
			align: 'center',
		},
		{
			property: 'callToAction',
			render: datum => {
				if (docType === 'transforming')
					return (
						<ButtonWithNoBorder
							label={t('production.createOutputBatch')}
							onClick={() =>
								onChange('open_plot_output_workflow', {
									row: datum,
								})
							}
						/>
					)

				return ''
			},
			align: 'center',
		},
	]
}

export const getPlotInputColumnConfig = ({
	activeProducts,
	handleChange,
	productionLine,
	t,
	errors,
}) => {
	return [
		{
			property: 'product',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.product)}</TableTitle>
				</Box>
			),
			render: datum => {
				const value = getIn(datum, ['productID']) || ''

				return (
					<SelectSearch
						value={value}
						key={`productID-${datum.activeIndex}`}
						name={`productID-${datum.activeIndex}`}
						onChange={values => {
							handleChange('add_Values', {
								rowIndex: datum.activeIndex,
								value: values.id,
								type: 'productID',
								quantity: values.availableQty,
							})
						}}
						labelKey="label"
						valueKey="id"
						defaultLabel={t('common.selectDefaultLabel')}
						returnOnlyValue
						options={activeProducts}
						required
					/>
				)
			},
		},
		{
			property: 'productionLine',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.productionLine)}</TableTitle>
				</Box>
			),
			render: datum => {
				const value = getIn(datum, ['productionLine']) || ''

				const options = productionLine.map(prodLine => {
					return {
						label: prodLine.name,
						id: prodLine.id,
					}
				})

				return (
					<SelectSearch
						value={value}
						name={`productID-${datum.activeIndex}`}
						onChange={values => {
							handleChange('add_Values', {
								rowIndex: datum.activeIndex,
								value: values.id,
								type: 'productionLine',
								quantity: values.availableQty,
							})
						}}
						labelKey="label"
						valueKey="id"
						defaultLabel={t('common.selectDefaultLabel')}
						returnOnlyValue
						options={options}
						required
					/>
				)
			},
		},
		{
			property: 'quantity',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.quantity)}</TableTitle>
				</Box>
			),
			render: datum => {
				const quantity = getIn(datum, ['quantity']) || 0

				return (
					<Input
						name={`quantity-${datum.activeIndex}`}
						value={quantity}
						type="number"
						onChange={e => {
							const _value = parseFloat(e.target.value)
							window.clearTimeout(timeoutID)
							timeoutID = window.setTimeout(() => {
								handleChange('add_Values', {
									rowIndex: datum.activeIndex,
									value: _value,
									type: 'quantity',
								})
							}, 1000)
						}}
						error={
							getIn(errors, [
								'traces',
								datum.activeIndex,
								'quantity',
							]) || ''
						}
					/>
				)
			},
		},
	]
}

export const getPlotOutputColumnConfig = ({
	activeProducts,
	handleChange,
	assignTank,
	// activeRecords,
	t,
	activeDocuments,
}) => {
	const records = [
		{
			property: 'product',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.product)}</TableTitle>
				</Box>
			),
			render: datum => {
				const value = getIn(datum, ['productID']) || ''

				return (
					<SelectSearch
						value={value}
						name={`productID-${datum.activeIndex}`}
						onChange={values => {
							handleChange('add_Values', {
								rowIndex: datum.activeIndex,
								value: values.id,
								type: 'productID',
							})
						}}
						labelKey="label"
						valueKey="id"
						defaultLabel={t('common.selectDefaultLabel')}
						returnOnlyValue
						options={activeProducts}
						required
					/>
				)
			},
		},
	]
	if (
		activeDocuments &&
		activeDocuments[0] &&
		activeDocuments[0].storageUnits.length > 0
	) {
		records.push({
			property: 'producedQuantity',
			header: (
				<Box row center style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.producedQuantity)}</TableTitle>
				</Box>
			),
			render: datum => {
				const productID = getIn(datum, ['productID']) || ''
				const currentDocument =
					activeDocuments?.length > 0
						? activeDocuments.find(
								item => item.productID === productID
						  )
						: {}
				const quantity =
					currentDocument &&
					currentDocument.storageUnits &&
					currentDocument.storageUnits.length > 0
						? currentDocument.storageUnits
								.map(obj => obj.quantity)
								.reduce(
									(accumulator, current) =>
										accumulator + current,
									0
								)
						: 0

				return (
					<Box style={{ marginBottom: '24px', textAlign: 'center' }}>
						{`${
							quantity.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0]
						} MT`}
					</Box>
				)
			},
		})
	}
	records.push({
		property: 'assignStorage',
		header: (
			<Box row center style={{ cursor: 'pointer' }}>
				<TableTitle>{t(TABLE_ALIAS.assignStorage)}</TableTitle>
			</Box>
		),
		render: datum => {
			const productID = getIn(datum, ['productID'])

			return (
				<Box style={{ marginBottom: '24px' }}>
					<ButtonWithNoBorder
						disabled={!productID}
						label={t('production.assignStorage')}
						onClick={() => assignTank(datum.activeIndex, productID)}
					/>
				</Box>
			)
		},
	})

	return records
}

export const getProducedBatchConfig = ({
	allProducts,
	createdAt,
	t,
	allTanks,
}) => {
	return [
		{
			property: 'createdAt',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.createdAt)}</TableTitle>
				</Box>
			),
			render: () => {
				return <Label as="span">{getDateByFormat(createdAt)}</Label>
			},
		},
		{
			property: 'product',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.product)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { productID } = datum
				const productName = allProducts.filter(
					product => product.id === productID
				)
				const name = getIn(productName, [0, 'name']) || ''

				return <Label as="span">{name || '---'}</Label>
			},
			primary: true,
		},
		{
			property: 'totalQuantity',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.totalQuantity)}</TableTitle>
				</Box>
			),
			render: datum => {
				const quantityUtilized = getIn(datum, ['totalQty']) || '---'
				const { productID } = datum
				const productName = allProducts.filter(
					product => product.id === productID
				)
				const uom = getIn(productName, [0, 'uom']) || ''

				return (
					<Label as="span">{`${
						quantityUtilized
							.toString()
							.match(/^-?\d+(?:\.\d{0,3})?/)[0]
					} ${uom} (${quantityUtilized * 1000} Kg)`}</Label>
				)
			},
		},
		{
			property: 'assignedTank',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.assignedTank)}</TableTitle>
				</Box>
			),
			render: datum => {
				const storageID = getIn(datum, ['storageUnitID']) || '---'
				const storageUnit = getIn(
					allTanks.filter(tank => tank.id === storageID),
					[0, 'name']
				)

				return <Label small>{storageUnit}</Label>
			},
		},
	]
}
