import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CarbonNumberForm } from 'core-app/modules/Onboard/components/CarbonNumberForm'
import { ProductsDuc } from 'core-app/modules/Products/duc'
import { AdminDuc } from 'core-app/modules/Admin/duc'
import { Box } from 'ui-lib/utils/Box'

const CarbonNumber = () => {
	const dispatch = useDispatch()
	const products = useSelector(
		ProductsDuc.selectors.getProductsListWithPagination
	)
	const pagination = useSelector(ProductsDuc.selectors.getPaginationQuery)

	const initialValues = {
		baseYear: '',
		scope1: '',
		scope2: '',
		files: [],
		auditedBy: '',
		reportYear: '',
		productLCVFile: [],
	}

	const handleAddCarbonNumber = (
		payload,
		updatedProducts,
		setSubmitting,
		currentFormData = {},
		productLcvFile = false
	) => {
		dispatch(
			AdminDuc.creators.addCarbonNumber(
				payload,
				updatedProducts,
				setSubmitting,
				currentFormData,
				productLcvFile
			)
		)
	}

	const handleProductsListingWithPagination = paginationParams => {
		dispatch(
			ProductsDuc.creators.fetchProductsListingWithPagination(
				paginationParams,
				20
			)
		)
	}

	return (
		<Box>
			<CarbonNumberForm
				pagination={pagination}
				initialValues={initialValues}
				handleAddCarbonNumber={handleAddCarbonNumber}
				handleProductsListingWithPagination={
					handleProductsListingWithPagination
				}
				products={products}
				dispatch={dispatch}
				fromOnBoard
			/>
		</Box>
	)
}

export { CarbonNumber }
