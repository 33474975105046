import React from 'react'
import { Box } from 'ui-lib/utils/Box'
import theme from 'ui-lib/utils/base-theme'
import { Label, P } from 'ui-lib/components/Typography'

const KeyValueSegment = ({
	name,
	description,
	userStatus,
	t,
	limitKeyWidth,
	limitValueWidth,
	newDocView = false,
	widthProp = '140px',
}) => {
	if (!name) return null

	return (
		<Box
			row
			padding="0 0 8px 0"
			justifyContent="flex-start"
			alignItems="flex-start"
		>
			<Box
				style={{
					width: limitKeyWidth ? widthProp : 'auto',
				}}
			>
				<Label
					color={
						newDocView
							? theme.color.themeGrey1
							: theme.color.accent2
					}
					small
					bold
				>
					{`${name} :                `}
				</Label>
			</Box>
			<Box
				style={{
					marginLeft: newDocView ? 0 : 20,
					width: limitValueWidth ? 250 : 'auto',
				}}
				flex
				justifyContent="center"
				alignItems="flex-start"
			>
				{description &&
				(typeof description === 'string' ||
					typeof description === 'number') ? (
					<P>
						{description}
						{name === 'Harvester Name' && userStatus === 'deleted' && (
							<Label
								style={{ marginLeft: 8 }}
								color={theme.color.grey6}
							>
								{t('common.inactive')}
							</Label>
						)}
					</P>
				) : (
					description || '---'
				)}
			</Box>
		</Box>
	)
}

KeyValueSegment.defaultProps = {
	limitValueWidth: true,
	limitKeyWidth: true,
	newDocView: false,
	widthProp: '140px',
}

export { KeyValueSegment }
