/* @flow */
import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { TextArea, ThemeContext } from 'grommet'
import { Box } from '../../utils/Box'
import { Label } from '../Label'
import { FieldText } from '../FieldText'

type Props = {
	uniqueID: string,
	value: string,
	placeholder: string,
	label?: string,
	error?: string,
	name: string,
	onChange: (value: SyntheticInputEvent<> | { [string]: string }) => void,
	disabled?: boolean,
	required?: boolean,
	width?: number | string,
	helpText?: string,
	hideLabel?: boolean,
	type?: string,
	returnObject?: boolean,
	onBlur: (value: SyntheticInputEvent<> | { [string]: string }) => void,
	returnKeyValue?: boolean,
	hideError?: boolean,
	fill?: boolean,
	size?: 'small' | 'medium' | 'large' | 'xlarge',
	customStyles?: {},
}

const Wrapper = styled(Box)(p => ({
	padding: 1, // compensating for the hover states
	'& > textarea::placeholder': p.error ? { color: p.theme.color.error } : {},
}))

const TextAreaInput = ({
	fill,
	name,
	label,
	onBlur,
	value,
	returnKeyValue,
	required,
	hideLabel,
	onChange,
	size,
	autoFocus,
	hideError,
	error,
	helpText,
	disabled,
	customStyles,
	placeholder,
	maxLength,
}: Props) => {
	const uniqueRef = name || label || ''
	const ID = `input-${uniqueRef || '---'}`
	const theme = useContext(ThemeContext)
	const [_value, setValue] = useState(value)

	useEffect(() => {
		setValue(value)
	}, [value])

	return (
		<Wrapper error={error}>
			{!hideLabel && label && (
				<Label
					for={ID}
					required={required}
					style={{ paddingBottom: 5, paddingTop: 5 }}
				>
					{label}
				</Label>
			)}
			<TextArea
				id={ID}
				fill={fill}
				disabled={disabled}
				placeholder={placeholder}
				value={_value}
				onBlur={e => {
					onBlur(returnKeyValue ? { [uniqueRef]: e.target.value } : e)
				}}
				onChange={e => {
					setValue(e.target.value)
					onChange(
						returnKeyValue ? { [uniqueRef]: e.target.value } : e
					)
				}}
				size={size}
				maxLength={maxLength}
				style={{
					...(error
						? {
								color: theme.color.error,
								borderColor: theme.color.error,
								// $FlowFixMe
								...customStyles,
						  }
						: customStyles),
				}}
				autoFocus={autoFocus}
			/>
			{helpText && <FieldText show>{helpText}</FieldText>}
			{!hideError && (
				<FieldText error show={!!error}>
					{error}
				</FieldText>
			)}
		</Wrapper>
	)
}

TextAreaInput.defaultProps = {
	/* eslint-disable react/default-props-match-prop-types */
	onBlur: () => {},
	onChange: () => {},
	/* eslint-enable react/default-props-match-prop-types */
	required: false,
	size: 'small',
	customStyles: {},
}

export { TextAreaInput as TextArea }
