import {
	getUploadAssetsSampleFile,
	getUploadPartnersSampleFile,
} from 'core-app/config'

export const NAME_ALIASES = {
	'delivery-order': 'tdmColumnHeaders.delivery-order',
	weighbridge: 'tdmViewDocument.weighbridge',
	assets: 'adminHome.assets',
	partners: 'breadcrumb.partner',
}

export const SAMPLE_FILES = {
	assets: getUploadAssetsSampleFile,
	partners: getUploadPartnersSampleFile,
}

export const REMARKS_TEXT = {
	'delivery-order': 'tdmDetailsEntry.uploadTwoWeekText',
	weighbridge: 'tdmDetailsEntry.uploadTwoWeekTextForWB',
	assets: 'assetsHome.uploadAssetsTwoWeekText',
	partners: 'breadcrumb.partner',
}
