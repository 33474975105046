import Duck from 'extensible-duck'
import { setIn, getIn } from 'timm'
import { createSelector } from 'reselect'

const initialState = {
	activeModule: '',
	loading: false,
	error: false,
	modules: {
		mainDashboard: {
			loading: false,
			activeTimeOffset: 5,
			activeSorts: {},
			activeDocuments: [],
			tabsConfig: [
				{
					code: 'completed',
					title: 'tripsListing.completed',
				},
				{
					code: 'in-progress',
					title: 'tripsListing.in-progress',
				},
			],
		},
		listing: {},
		document: {
			loading: false,
			trips: {},
		},
	},
}

export const TripsDuc = new Duck({
	namespace: 'trips',
	store: 'global',
	types: [
		'SET_ACTIVE_MODULE',
		'FETCH_DASHBOARD_DOCUMENT',
		'FETCH_DASHBOARD_DOCUMENT_SUCCESS',
		'SET_DASHBOARD_LOADING_STATUS',
		'VIEW_DOCUMENT',
		'SET_DASHBOARD_VIEW_LOADING_STATUS',
		'SET_DASHBOARD_VIEW_SUCCESS',
		'SET_ACTIVE_SORTS',
	],
	initialState,
	reducer: (state, action, duck) => {
		switch (action.type) {
			case duck.types.SET_ACTIVE_MODULE: {
				return setIn(state, ['activeModule'], action.module)
			}

			case duck.types.FETCH_DASHBOARD_DOCUMENT_SUCCESS: {
				const { activeDocuments } = action

				return setIn(
					state,
					['modules', 'mainDashboard', 'activeDocuments'],
					activeDocuments
				)
			}

			case duck.types.SET_DASHBOARD_LOADING_STATUS: {
				const { status } = action

				return setIn(
					state,
					['modules', 'mainDashboard', 'loading'],
					status
				)
			}
			case duck.types.SET_DASHBOARD_VIEW_LOADING_STATUS: {
				const { status } = action

				return setIn(state, ['modules', 'document', 'loading'], status)
			}
			case duck.types.SET_DASHBOARD_VIEW_SUCCESS: {
				const { document } = action

				return setIn(state, ['modules', 'document', 'trips'], document)
			}

			case duck.types.SET_ACTIVE_SORTS: {
				const { sortMap } = action
				let trips = {}
				;(sortMap.trips || []).forEach(key => {
					const arr = key.split('|')

					const lastVal = arr.pop()

					trips = setIn(trips, arr, lastVal)
				})

				return setIn(
					state,
					['modules', 'mainDashboard', 'activeSorts'],
					trips
				)
			}

			default:
				return state
		}
	},
	selectors: {
		auth: state => state.auth,
		location: state => state.location,
		documentState: state =>
			getIn(state, ['trips', 'modules', 'mainDashboard']),
		documentViewState: state =>
			getIn(state, ['trips', 'modules', 'document']),
		activeModule: state =>
			getIn(state, ['trips', 'activeModule']) || 'Error',
		mainDashboardLoadingStatus: new Duck.Selector(selectors =>
			createSelector(
				selectors.documentState,
				document => getIn(document, ['loading']) || false
			)
		),
		fetchMainDashboardDocuments: new Duck.Selector(selectors =>
			createSelector(
				selectors.documentState,
				document => getIn(document, ['activeDocuments']) || []
			)
		),
		getDocumentActiveTabs: new Duck.Selector(selectors =>
			createSelector(
				selectors.documentState,
				document => getIn(document, ['tabsConfig']) || {}
			)
		),
		getMainDashboardActiveSorts: new Duck.Selector(selectors =>
			createSelector(
				selectors.documentState,
				document => getIn(document, ['activeSorts']) || {}
			)
		),
		documentLoadingStatus: new Duck.Selector(selectors =>
			createSelector(
				selectors.documentViewState,
				document => getIn(document, ['loading']) || false
			)
		),
		documentInformation: new Duck.Selector(selectors =>
			createSelector(
				selectors.documentViewState,
				document => getIn(document, ['trips']) || false
			)
		),
	},
	creators: duck => ({
		setActiveModule: module => ({
			type: duck.types.SET_ACTIVE_MODULE,
			module,
		}),
		fetchDocuments: (
			docType,
			rootModules,
			locationState,
			skipGlobalLoader = false
		) => ({
			type: duck.types.FETCH_DASHBOARD_DOCUMENT,
			rootModules,
			docType,
			locationState,
			skipGlobalLoader,
		}),
		fetchDocumentSuccess: activeDocuments => ({
			type: duck.types.FETCH_DASHBOARD_DOCUMENT_SUCCESS,
			activeDocuments,
		}),
		setDocumentLoading: status => ({
			type: duck.types.SET_DASHBOARD_LOADING_STATUS,
			status,
		}),
		viewDocument: documentReference => ({
			type: duck.types.VIEW_DOCUMENT,
			documentReference,
		}),
		setDocumentViewLoading: status => ({
			type: duck.types.SET_DASHBOARD_VIEW_LOADING_STATUS,
			status,
		}),
		fetchDocumentViewSuccess: document => ({
			type: duck.types.SET_DASHBOARD_VIEW_SUCCESS,
			document,
		}),
		setActiveSorts: sortMap => ({
			type: duck.types.SET_ACTIVE_SORTS,
			sortMap,
		}),
	}),
})
