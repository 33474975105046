import React from 'react'
import { Box } from 'ui-lib/utils/Box'
import { Label, TableTitle } from 'ui-lib/components/Typography'
import { ButtonWithNoBorder } from 'ui-lib/components/Button'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { getIn } from 'timm'
import { SortDown, SortReset } from 'ui-lib/components/Table'
import { getNextSortOrder, getQuantityWithUnit } from 'core-app/utils/helpers'
import { getDateByFormat } from 'core-app/utils/date'
import NotVerifiedIcon from 'ui-lib/icons/not_verified.svg'
import VerifiedIcon from 'ui-lib/icons/verified.svg'
import { SortableKeys, PRODUCT_TRACE_TABLE_ALIAS } from '../config'

export const productTraceColumnConfig = ({
	type,
	docType,
	sortsMap,
	onChange,
	activeLocale,
	orgDetails,
	t,
}) => {
	return [
		{
			property: 'createdAt',
			header: (
				<Box
					overflow
					row
					alignItems="center"
					style={{ cursor: 'pointer' }}
					onClick={() => {
						if (SortableKeys.includes('createdAt')) {
							onChange('initiate_sort', {
								type,
								docType,
								field: 'createdAt',
								order: getNextSortOrder(sortsMap.createdAt),
							})
						}
					}}
				>
					{SortableKeys.includes('createdAt') && (
						<IconWrapper
							width={10}
							margin="0 5px"
							sortUp={sortsMap.createdAt === 'asc'}
							sortDown={sortsMap.createdAt === 'desc'}
						>
							<Icon
								rotate180={sortsMap.createdAt === 'asc'}
								glyph={
									sortsMap.createdAt ? SortDown : SortReset
								}
							/>
						</IconWrapper>
					)}
					<TableTitle>
						{t(PRODUCT_TRACE_TABLE_ALIAS.createdAt)}
					</TableTitle>
				</Box>
			),
			render: datum => {
				const { createdAt } = datum

				if (createdAt)
					return (
						<Label as="span">
							{getDateByFormat(createdAt, null, activeLocale)}
						</Label>
					)

				return '---'
			},
			size: '10%',
		},
		{
			property: 'partner',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>
						{t(PRODUCT_TRACE_TABLE_ALIAS.partner)}
					</TableTitle>
				</Box>
			),
			render: datum => {
				const { meta, initiatingPartyID } = datum
				const orgName =
					getIn(orgDetails[initiatingPartyID], ['name']) ||
					getIn(meta, ['globalInitiator', 'name']) ||
					''

				return <Label as="span">{orgName}</Label>
			},
			size: '20%',
		},
		{
			property: 'docNumber',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>
						{t(PRODUCT_TRACE_TABLE_ALIAS.docNumber)}
					</TableTitle>
				</Box>
			),
			render: datum => {
				const { number } = datum

				return <Label as="span">{number}</Label>
			},
			primary: true,
			size: '15%',
		},
		{
			property: 'product',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>
						{t(PRODUCT_TRACE_TABLE_ALIAS.product)}
					</TableTitle>
				</Box>
			),
			render: datum => {
				const { products = [] } = datum
				const productName = getIn(products, [0, 'name'])

				return <Label as="span">{productName}</Label>
			},
			primary: true,
			size: '20%',
		},
		{
			property: 'quantity',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>
						{t(PRODUCT_TRACE_TABLE_ALIAS.quantity)}
					</TableTitle>
				</Box>
			),
			render: datum => {
				const { receiverTraceIDs = [] } = datum
				const qty = receiverTraceIDs
					.reduce((accumulator, object) => {
						return accumulator + object.availableQty
					}, 0)
					.toString()
					.match(/^-?\d+(?:\.\d{0,3})?/)[0]
				const uom = getIn(receiverTraceIDs, [0, 'uom']) || ''

				return (
					<Label as="span">
						{getQuantityWithUnit(qty, undefined, uom)}
					</Label>
				)
			},
			size: '20%',
		},
		{
			property: 'docNumber',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>EUDR Compliant</TableTitle>
				</Box>
			),
			render: datum => {
				return (
					<Box style={{ alignItems: 'center' }}>
						<IconWrapper
							color={
								datum?.meta?.incomingEUDRCompliance
									? 'green'
									: 'red'
							}
							size={20}
						>
							<Icon
								glyph={
									datum?.meta?.incomingEUDRCompliance
										? VerifiedIcon
										: NotVerifiedIcon
								}
							/>
						</IconWrapper>
					</Box>
				)
			},
			size: '20%',
		},
		{
			property: 'callToAction',
			render: datum => {
				return (
					<ButtonWithNoBorder
						label={t('productTrace.trace')}
						onClick={() =>
							onChange('open_document', {
								row: datum,
							})
						}
					/>
				)
			},
			size: '15%',
		},
	]
}
