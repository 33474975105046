/* @flow */
/* eslint-disable no-unused-vars */
import React, { useCallback, useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import * as XLSX from 'xlsx'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { addLast, getIn, removeAt } from 'timm'
import request from '../../utils/request'
import CloseIcon from '../../icons/circle-close.svg'
import UploadIcon from '../../icons/upload.svg'
import DocumentIcon from '../../icons/document.svg'
import FileUploadIcon from '../../icons/uploadFile.svg'
import { P, SmallText } from '../Typography'
import { Card } from '../Card'
import { Icon, IconWrapper } from '../../icons/components/Icon'
import { Button } from '../Button'
import { Box } from '../../utils/Box'
import { FieldText } from '../FieldText'
import { Label } from '../Label'
import { Spacer } from '../../utils/Spacer'
import LogHelper from '../../utils/logger'
import { getIAMEndPoint } from '../../config'
import { TransparentBgSpinner } from '../Spinner/TransparentBgSpinner'
import theme from '../../utils/base-theme'

const logger = LogHelper('file-upload')

type Props = {
	type?: string,
	name: string,
	size?: number,
	hideError?: boolean,
	error?: string,
	hideLabel?: boolean,
	label?: string,
	required?: boolean,
	currentUploads: Array<{ url: string, name: string }>,
	onDocumentChange: (Array<{ url: string, name: string }>) => void,
	onUploadFailure: ({ message: string }) => void,
	outline?: boolean,
	dropzone?: boolean,
	fileUploadUrl?: string,
	supportedFileType?: string,
	showFiles?: boolean,
	imageZoom?: Boolean,
	dropLabel?: string,
	buttonLabel?: string,
	afterFileUploadText?: string,
	imageSrc?: string,
	pictureEditLabel?: String,
}

type fileTypeProps = {
	documents: Array<{ url: string, name: string }>,
	isReadOnly: boolean,
	onRemovefile: (index: number) => void,
	showFiles?: boolean,
	imageZoom?: boolean,
}

const getColor = props => {
	if (props.isDragAccept) {
		return '#00e676'
	}
	if (props.isDragReject) {
		return '#ff1744'
	}
	if (props.isDragActive) {
		return '#2196f3'
	}

	return '#eeeeee'
}
const Container = styled.div`
	flex: 1;
	width: 100%;
	height: 256px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px;
	border-width: 1px;
	border-radius: 4px;
	border-color: ${props => getColor(props)};
	border-style: solid;
	background-color: ${theme.color.grey11};
	color: #172b4d;
	outline: none;
	transition: border 0.24s ease-in-out;
`
const HideWrapper = styled.div`
	cursor: pointer;
	position: absolute;
	z-index: 5;
`

const FileItem = styled.a`
	font-size: 0.75em;
	line-height: 1.2;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: ${props => (props.hideButtonOneUpload ? '120px' : '100%')};
	text-decoration: underline;
`

const FileList = styled.li`
	transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1);
	opacity: 1;
	margin: 5px;
	margin-left: 0;
	padding: 1px;
	height: 30px;
	display: inline-flex;
`

const Wrapper = styled.ul`
	display: flex;
	flex-wrap: wrap;
	top: 0;
	margin: 0;
	padding: 0;
	list-style-type: none;
	will-change: transform;
	margin-bottom: ${props =>
		props.hideButtonOneUpload && !props.hasFormatError ? '34px' : '0px'};
`
const Item = styled.div`
	position: static;
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;
	padding: 1px 6px;
	color: #4a4a4a;
	background: #ebf8fe;
	border: 1px solid #5d7480;
	border-radius: 10px;
`

const FileUploadItem = styled.div`
	position: static;
	display: flex;
	height: 40px;
	align-items: center;
	justify-content: center;
	padding: 4px 12px;
	color: #4a4a4a;
	background: #ebf8fe;
	border: 1px solid #5d7480;
	border-radius: 10px;
`
const Close = styled.button`
	margin-left: 10px;
	width: inherit;
	border: none;
	border-radius: 50%;
	background-color: rgb(50, 48, 47);
`

// const FILE_UPLOAD_URL = 'http://api.dibizglobal.org/iam/'

export const ZoomFtr = ({ src, alt }) => {
	return (
		<Zoom>
			<img alt={alt} src={src} width="100" style={{ margin: 5 }} />
		</Zoom>
	)
}

export const FileLists = ({
	documents = [],
	isReadOnly,
	onRemovefile,
	imageZoom,
	hideButtonOneUpload = false,
}: fileTypeProps) => {
	return documents.map((file, index) => (
		<>
			{imageZoom ? (
				<ZoomFtr src={file?.meta?.fullURL} alt={file?.meta?.name} />
			) : (
				<FileList key={`file-${file?.meta?.name}`}>
					<Item title={file?.meta?.name}>
						<IconWrapper size={10} style={{ marginRight: 10 }}>
							<Icon glyph={DocumentIcon} />
						</IconWrapper>
						<FileItem
							href={file?.meta?.fullURL}
							target="_blank"
							download
							hideButtonOneUpload={hideButtonOneUpload}
						>
							{file?.meta?.name}
						</FileItem>
						{!isReadOnly && (
							<Close onClick={() => onRemovefile(index)}>
								<IconWrapper
									size={15}
									style={{
										display: 'flex',
										cursor: 'pointer',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<Icon glyph={CloseIcon} />
								</IconWrapper>
							</Close>
						)}
					</Item>
				</FileList>
			)}
		</>
	))
}

FileLists.defaultProps = {
	documents: [],
	isReadOnly: false,
	onRemovefile: () => {},
}

export const FileUploadedList = ({
	documents = [],
	isReadOnly,
	onRemovefile,
	showFiles,
}: fileTypeProps) => (
	<>
		{!showFiles ? (
			<p
				style={{
					color: '#30b57d',
					fontWeight: 'bold',
					fontSize: '18px',
				}}
			>
				File Uploaded Successfully
			</p>
		) : (
			documents.map((file, index) => (
				<FileList key={`file-${file.entity.id}`}>
					<FileUploadItem>
						<IconWrapper size={20} style={{ marginRight: 10 }}>
							<Icon glyph={DocumentIcon} />
						</IconWrapper>
						<FileItem
							href={
								getIn(file, [
									'entity',
									'meta',
									'files',
									0,
									'meta',
									'fullURL',
								]) || '#'
							}
							target="_blank"
							download
							style={{ fontSize: '16px' }}
						>
							{file.entity.number}
						</FileItem>
						{!isReadOnly && (
							<Close onClick={() => onRemovefile(index)}>
								<IconWrapper
									size={15}
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<Icon glyph={CloseIcon} />
								</IconWrapper>
							</Close>
						)}
					</FileUploadItem>
				</FileList>
			))
		)}
	</>
)

FileUploadedList.defaultProps = {
	documents: [],
	isReadOnly: false,
	showFiles: true,
	onRemovefile: () => {},
}

const FileUpload = ({
	type,
	name = '',
	label = '',
	size,
	hideError,
	width = '150px',
	error,
	hideLabel,
	required,
	currentUploads = [],
	onDocumentChange,
	onUploadFailure,
	outline,
	dropzone,
	fileUploadUrl,
	supportedFileType,
	onSingleApiUploadChange,
	showFiles = true,
	imageZoom = false,
	dropLabel = "Drag 'n' drop some files here",
	buttonLabel = 'Upload a File',
	modalTriggerBtnLabel = 'Upload',
	afterFileUploadText,
	disableUploadForOne = false,
	hideButtonOneUpload = false,
	disabled = false,
	hasFormatError = false,
	fileFormatErrorMsg = '',
	newAlignment = false, // max and type text removed, button and file name in display flex
	singleUploadApi = false, // for returning excel details
}: Props) => {
	const { t } = useTranslation()

	const defaultType = type || 'private'
	const fileID = `file-upload-${name || label}`
	const uploadUrl = fileUploadUrl || `${getIAMEndPoint()}files/${defaultType}`
	const [uploadedDocs, setupLoadedDocs] = useState(currentUploads)
	const ListComponent = fileUploadUrl ? FileUploadedList : FileLists

	const maxSize = size
	const [isLoading, setLoading] = useState()

	const onStatusChange = _isLoading => {
		setLoading(_isLoading)
	}

	useEffect(() => {
		setupLoadedDocs(currentUploads)
	}, [currentUploads])

	// We want this hook to send the changes only when the local state updates
	// including function will go in an infinite loop and not desired.
	// hence disabling the lint rule.
	useEffect(() => {
		onDocumentChange(uploadedDocs)
	}, [uploadedDocs]) // eslint-disable-line react-hooks/exhaustive-deps

	const onDrop = useCallback(
		acceptedFiles => {
			onStatusChange(true)
			setLoading(true)
			const callsToUpload = acceptedFiles.map(async file => {
				const formData = new FormData()
				formData.append('file', file)
				formData.append('name', file.name)

				// Using single api fosupportedFileTyper file  upload and send
				if (
					singleUploadApi &&
					(supportedFileType.includes('.xlsx') ||
						supportedFileType.includes('.csv'))
				) {
					const reader = new FileReader()
					const rABS = !!reader.readAsBinaryString // !! converts object to boolean
					const currentFile = {}
					const currentMeta = {}
					currentMeta.file = file
					currentMeta.name = file.name
					currentFile.meta = currentMeta
					reader.onload = e => {
						// file contents
						const binaryStr = reader.result
						const workbook = XLSX.read(binaryStr, {
							type: rABS ? 'binary' : 'array',
						})
						const sheet_name_list = workbook.SheetNames[0]
						const jsonFromExcel = XLSX.utils.sheet_to_json(
							workbook.Sheets[sheet_name_list],
							{
								raw: false,
								dateNF: 'MM-DD-YYYY',
								header: 1,
								defval: '',
							}
						)
						setupLoadedDocs([currentFile])
						onSingleApiUploadChange({
							jsonFromExcel,
							formData,
							currentFile,
						})
					}
					reader.readAsArrayBuffer(file)
				} else {
					// normal old implementation to upload file as private
					const options = {
						method: 'POST',
						body: formData,
						formData: true,
						headers: {
							'content-type': null,
						},
					}

					return await request(uploadUrl, options).then(success => {
						const response = getIn(success, ['data'])
						if (!response)
							throw new Error('Unable to upload the file')

						return response
					})
				}
			})

			Promise.all(callsToUpload)
				.then(files => {
					setupLoadedDocs(addLast(uploadedDocs, files))
					// onUploadSuccess(uploadedDocs)
				})
				.catch(err => {
					logger.log(err)
					onUploadFailure({ message: err.message })
				})
				.finally(() => onStatusChange(false))
		},
		[uploadedDocs, uploadUrl, onUploadFailure]
	)

	const removeFile = useCallback(
		index => {
			setupLoadedDocs(removeAt(uploadedDocs, index))
			onSingleApiUploadChange({
				jsonFromExcel: [],
				formData: {},
				currentFile: {},
			})
		},
		[uploadedDocs]
	)

	const {
		getRootProps,
		getInputProps,
		inputRef,
		isDragActive,
		open,
		isDragReject,
		isDragAccept,
	} = useDropzone({
		onDrop,
		accept: supportedFileType || '.jpeg,.png,.pdf,.jpg',
		minSize: 0,
		maxSize,
		noClick: true,
		noKeyboard: true,
		multiple: false,
	})

	return dropzone ? (
		<div {...getRootProps()}>
			<Container
				{...getRootProps({ isDragActive, isDragAccept, isDragReject })}
			>
				{isLoading && <TransparentBgSpinner />}
				{uploadedDocs.length > 0 ? (
					<ListComponent
						documents={uploadedDocs}
						onRemovefile={removeFile}
						showFiles={showFiles}
						imageZoom={imageZoom}
					/>
				) : (
					<>
						<input {...getInputProps()} />

						<IconWrapper size={120} style={{ marginRight: 10 }}>
							<Icon glyph={FileUploadIcon} />
						</IconWrapper>

						<p>{dropLabel}</p>
						<Spacer size={8} />
						<p>or</p>
						<Spacer size={12} />

						<Button outline label={buttonLabel} onClick={open} />
					</>
				)}
			</Container>
			{uploadedDocs.length > 0 && fileUploadUrl && showFiles && (
				<SmallText fontSize={14}>{afterFileUploadText}</SmallText>
			)}
		</div>
	) : (
		<Box overflow>
			{!hideLabel && label && (
				<>
					<Label
						for={fileID}
						required={required}
						style={{
							color: theme.color.accent2,
						}}
					>
						{label}
					</Label>
					<Spacer size={8} />
				</>
			)}
			{!!uploadedDocs.length && !newAlignment && (
				<Wrapper
					hideButtonOneUpload={hideButtonOneUpload}
					hasFormatError={hasFormatError}
				>
					<FileLists
						documents={uploadedDocs}
						onRemovefile={removeFile}
						imageZoom={imageZoom}
						hideButtonOneUpload={hideButtonOneUpload}
					/>
					<Spacer size={5} />
				</Wrapper>
			)}
			<div style={{ display: 'flex' }}>
				<Box overflow>
					<Box overflow width={width}>
						{/* eslint-disable-next-line */}
						{hideButtonOneUpload && uploadedDocs.length > 0 ? (
							''
						) : (
							// eslint-disable-next-line jsx-a11y/label-has-for
							<label htmlFor={fileID}>
								<HideWrapper />
								<Button
									label={modalTriggerBtnLabel}
									action
									rounded
									extendStyles={{
										width: newAlignment ? '250px' : '150px',
									}}
									primary={!outline}
									isLoading={isLoading}
									onClick={open}
									disabled={
										(disableUploadForOne &&
											uploadedDocs.length > 0) ||
										disabled
									}
									customIcon={
										!outline && (
											<IconWrapper size={20}>
												<Icon glyph={UploadIcon} />
											</IconWrapper>
										)
									}
								/>
								<Box>
									<span
										style={{
											fontSize: 10,
											color: theme.color.grey9,
											marginTop: '4px',
										}}
									>
										{t('common.fileType')} :{' '}
										{supportedFileType}
									</span>
									<span
										style={{
											fontSize: 10,
											color: theme.color.grey9,
											marginTop: '4px',
										}}
									>
										{t('common.maxFileSize')} :{' '}
										{(maxSize / (1024 * 1024)).toFixed(0)}{' '}
										MB
									</span>
								</Box>
							</label>
						)}
						<input
							ref={inputRef}
							id={fileID}
							{...getInputProps()}
						/>
					</Box>
					{!hideError && !hasFormatError && (
						<FieldText error show={!!error}>
							{error}
						</FieldText>
					)}
				</Box>
				{!!uploadedDocs.length && newAlignment && (
					<Wrapper>
						<FileLists
							documents={uploadedDocs}
							onRemovefile={removeFile}
							imageZoom={imageZoom}
						/>
						<Spacer size={5} />
					</Wrapper>
				)}
			</div>
			{/* for singleupload api format error message */}
			{hasFormatError && (
				<FieldText error show={hasFormatError}>
					{fileFormatErrorMsg}
				</FieldText>
			)}
		</Box>
	)
}

FileUpload.defaultProps = {
	currentUploads: [],
	onDocumentChange: () => {},
	onUploadFailure: () => {},
	onSingleApiUploadChange: () => {},
}

const FileUploadWithPreview = ({
	type,
	name = '',
	label = '',
	size,
	hideError,
	error,
	hideLabel,
	required,
	currentUploads = [],
	imageSrc,
	onDocumentChange,
	onUploadFailure,
	outline,
	fileUploadUrl,
	supportedFileType,
	pictureEditLabel = 'Change Picture',
}: Props) => {
	const defaultType = type || 'private'
	const fileID = `file-upload-${name || label}`
	const uploadUrl = fileUploadUrl || `${getIAMEndPoint()}files/${defaultType}`
	const [uploadedDocs, setupLoadedDocs] = useState(currentUploads)

	const ListComponent = fileUploadUrl ? FileUploadedList : FileLists

	const maxSize = size
	const [isLoading, setLoading] = useState()

	const onStatusChange = _isLoading => {
		setLoading(_isLoading)
	}

	useEffect(() => {
		setupLoadedDocs(currentUploads)
	}, [currentUploads])

	// We want this hook to send the changes only when the local state updates
	// including function will go in an infinite loop and not desired.
	// hence disabling the lint rule.
	useEffect(() => {
		onDocumentChange(uploadedDocs)
	}, [uploadedDocs]) // eslint-disable-line react-hooks/exhaustive-deps

	const onDrop = useCallback(
		acceptedFiles => {
			onStatusChange(true)
			setLoading(true)
			const callsToUpload = acceptedFiles.map(async file => {
				const formData = new FormData()
				formData.append('file', file)
				formData.append('name', file.name)
				const options = {
					method: 'POST',
					body: formData,
					formData: true,
					headers: {
						'content-type': null,
					},
				}

				return await request(uploadUrl, options).then(success => {
					const response = getIn(success, ['data'])
					if (!response) throw new Error('Unable to upload the file')

					return response
				})
			})

			Promise.all(callsToUpload)
				.then(files => {
					setupLoadedDocs(addLast(uploadedDocs, files))
					// onUploadSuccess(uploadedDocs)
				})
				.catch(err => {
					logger.log(err)
					onUploadFailure({ message: err.message })
				})
				.finally(() => onStatusChange(false))
		},
		[uploadedDocs, uploadUrl, onUploadFailure]
	)

	const removeFile = useCallback(
		index => {
			setupLoadedDocs(removeAt(uploadedDocs, index))
		},
		[uploadedDocs]
	)

	const {
		getRootProps,
		getInputProps,
		inputRef,
		isDragActive,
		open,
		isDragReject,
		isDragAccept,
	} = useDropzone({
		onDrop,
		accept: supportedFileType || '.jpeg,.png,.pdf,.jpg',
		minSize: 0,
		maxSize,
		noClick: true,
		noKeyboard: true,
		multiple: false,
	})

	return (
		<Box overflow>
			<Box overflow>
				<Box overflow width="150px">
					{/* eslint-disable-next-line */}
					<label htmlFor={fileID}>
						<HideWrapper />
						<Card
							style={{
								padding: '8px',
								position: 'relative',
								overflow: 'visible',
								boxShadow: ' 0px 6px 16px #00000014',
								width: '200px',
								height: '220px',
							}}
							textAlign="left"
						>
							<Box
								style={{
									backgroundColor: '#E4E8F0',
									borderRadius: '4px',
									width: '180px',
									height: '170px',
								}}
								justifyContent="center"
								alignItems="center"
							>
								{imageSrc ? (
									<img
										src={imageSrc}
										alt="logo"
										style={{
											width: '180px',
											height: '170px',
										}}
									/>
								) : (
									<p>No Logo</p>
								)}
							</Box>
							<Box center>
								<Button
									label={pictureEditLabel}
									action
									rounded
									primary={!outline}
									isLoading={isLoading}
									onClick={() => {
										if (inputRef.current)
											inputRef.current.click()
									}}
									extendStyles={{
										fontSize: '14px',
										backgroundColor: 'white',
										border: 'none',
										color: theme.color.blue7,
										padding: '0px',
										boxShadow: 'none',
									}}
								/>
							</Box>
						</Card>
					</label>
					<input ref={inputRef} id={fileID} {...getInputProps()} />
				</Box>
				{!hideError && (
					<FieldText error show={!!error}>
						{error}
					</FieldText>
				)}
			</Box>
		</Box>
	)
}

FileUploadWithPreview.defaultProps = {
	currentUploads: [],
	onDocumentChange: () => {},
	onUploadFailure: () => {},
}

const ShapeFileFileUpload = ({
	type,
	name = '',
	label = '',
	size,
	hideError,
	width = '150px',
	error,
	hideLabel,
	required,
	currentUploads = [],
	onDocumentChange,
	onUploadFailure,
	outline,
	dropzone,
	utilitiesUrl,
	supportedFileType,
	showFiles = true,
	imageZoom = false,
	dropLabel = "Drag 'n' drop some files here",
	buttonLabel = 'Upload a File',
	modalTriggerBtnLabel = 'Upload',
	afterFileUploadText,
	disableUploadForOne = false,
	hideButtonOneUpload = false,
	disabled = false,
}: Props) => {
	const { t } = useTranslation()

	const defaultType = type || 'private'
	const fileID = `file-upload-${name || label}`
	const uploadUrl = `${getIAMEndPoint()}files/${defaultType}`
	const [uploadedDocs, setupLoadedDocs] = useState(currentUploads)

	const maxSize = size
	const [isLoading, setLoading] = useState()

	const onStatusChange = _isLoading => {
		setLoading(_isLoading)
	}

	useEffect(() => {
		setupLoadedDocs(currentUploads)
	}, [currentUploads])

	// We want this hook to send the changes only when the local state updates
	// including function will go in an infinite loop and not desired.
	// hence disabling the lint rule.
	useEffect(() => {
		onDocumentChange(uploadedDocs)
	}, [uploadedDocs]) // eslint-disable-line react-hooks/exhaustive-deps

	const readGeoJsonFile = (blob: Blob) => {
		return new Promise(resolve => {
			const reader = new FileReader()
			reader.onloadend = () => resolve(JSON.parse(reader.result))
			reader.readAsText(blob)
		})
	}

	const convertShapeToGeoJSON = json => {
		const geoJson = {
			type: 'FeatureCollection',
			features: [],
		}
		json.forEach(d => {
			const { coordinates = [] } = d
			geoJson.features.push({
				type: 'Feature',
				properties: {},
				geometry: {
					type: 'Polygon',
					coordinates: [coordinates],
				},
			})
		})

		return geoJson
	}

	const onDrop = useCallback(
		acceptedFiles => {
			onStatusChange(true)
			setLoading(true)
			const callsToUpload = acceptedFiles.map(async file => {
				const formData = new FormData()
				formData.append('file', file)
				formData.append('name', file.name)
				const options = {
					method: 'POST',
					body: formData,
					formData: true,
					headers: {
						'content-type': null,
					},
				}
				let fileType = file.type
				if (!fileType) {
					if (file.name.includes('.geojson')) {
						fileType = 'application/geo+json'
					} else if (file.name.includes('.zip')) {
						fileType = 'application/zip'
					}
				}

				if (fileType === 'application/geo+json') {
					const geoJSON = await readGeoJsonFile(file)
					const json = {
						json: geoJSON,
						meta: {
							name: file.name,
						},
					}

					return json
				} else if (fileType === 'application/zip') {
					return await request(utilitiesUrl, options).then(
						success => {
							const response = getIn(success, ['data'])
							if (!response)
								throw new Error('Unable to upload the file')

							const json = {
								json: convertShapeToGeoJSON(response),
								meta: {
									name: file.name,
								},
							}

							return json
						}
					)
				} else {
					return await request(uploadUrl, options).then(success => {
						const response = getIn(success, ['data'])
						if (!response)
							throw new Error('Unable to upload the file')

						return response
					})
				}
			})

			Promise.all(callsToUpload)
				.then(files => {
					setupLoadedDocs(addLast(uploadedDocs, files))
					// onUploadSuccess(uploadedDocs)
				})
				.catch(err => {
					logger.log(err)
					onUploadFailure({ message: err.message })
				})
				.finally(() => onStatusChange(false))
		},
		[onUploadFailure, uploadUrl, uploadedDocs, utilitiesUrl]
	)

	const removeFile = useCallback(
		index => {
			setupLoadedDocs(removeAt(uploadedDocs, index))
		},
		[uploadedDocs]
	)

	const {
		getRootProps,
		getInputProps,
		inputRef,
		isDragActive,
		open,
		isDragReject,
		isDragAccept,
	} = useDropzone({
		onDrop,
		accept: supportedFileType || '.jpeg,.png,.pdf,.jpg',
		minSize: 0,
		maxSize,
		noClick: true,
		noKeyboard: true,
		multiple: false,
	})

	return (
		<Box overflow>
			{!hideLabel && label && (
				<>
					<Label
						for={fileID}
						required={required}
						style={{
							color: theme.color.accent2,
						}}
					>
						{label}
					</Label>
					<Spacer size={8} />
				</>
			)}
			{!!uploadedDocs.length && (
				<Wrapper hideButtonOneUpload={hideButtonOneUpload}>
					{uploadedDocs.map((file, index) => (
						<FileList key={`file-${file?.meta?.name}`}>
							<Item title={file?.meta?.name}>
								<IconWrapper
									size={10}
									style={{ marginRight: 10 }}
								>
									<Icon glyph={DocumentIcon} />
								</IconWrapper>
								<P>{file?.meta?.name}</P>

								<Close onClick={() => removeFile(index)}>
									<IconWrapper
										size={15}
										style={{
											display: 'flex',
											cursor: 'pointer',
											justifyContent: 'center',
											alignItems: 'center',
										}}
									>
										<Icon glyph={CloseIcon} />
									</IconWrapper>
								</Close>
							</Item>
						</FileList>
					))}

					<Spacer size={5} />
				</Wrapper>
			)}
			<div style={{ display: 'flex' }}>
				<Box overflow>
					<Box overflow width={width}>
						{/* eslint-disable-next-line */}
						{hideButtonOneUpload && uploadedDocs.length > 0 ? (
							''
						) : (
							// eslint-disable-next-line jsx-a11y/label-has-for
							<label htmlFor={fileID}>
								<HideWrapper />
								<Button
									label={modalTriggerBtnLabel}
									action
									rounded
									extendStyles={{
										width: '150px',
									}}
									primary={!outline}
									isLoading={isLoading}
									onClick={open}
									disabled={
										(disableUploadForOne &&
											uploadedDocs.length > 0) ||
										disabled
									}
									customIcon={
										!outline && (
											<IconWrapper size={20}>
												<Icon glyph={UploadIcon} />
											</IconWrapper>
										)
									}
								/>
								<Box>
									<span
										style={{
											fontSize: 10,
											color: theme.color.grey9,
											marginTop: '4px',
										}}
									>
										{t('common.fileType')} :{' '}
										{supportedFileType}
									</span>
									<span
										style={{
											fontSize: 10,
											color: theme.color.grey9,
											marginTop: '4px',
										}}
									>
										{t('common.maxFileSize')} :{' '}
										{(maxSize / (1024 * 1024)).toFixed(0)}{' '}
										MB
									</span>
								</Box>
							</label>
						)}
						<input
							accept=".geojson,application/geo+json"
							ref={inputRef}
							id={fileID}
							{...getInputProps()}
						/>
					</Box>
					{!hideError && (
						<FieldText error show={!!error}>
							{error}
						</FieldText>
					)}
				</Box>
			</div>
		</Box>
	)
}

ShapeFileFileUpload.defaultProps = {
	currentUploads: [],
	onDocumentChange: () => {},
	onUploadFailure: () => {},
}

export { FileUpload, FileUploadWithPreview, ShapeFileFileUpload }
