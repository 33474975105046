import {
	all,
	takeLatest,
	take,
	race,
	put,
	call,
	select,
} from 'redux-saga/effects'
import LogHelper from 'core-app/utils/logger'
import { getCoreEndPoint } from 'core-app/config'
import request from 'core-app/utils/request'
import {
	CallWithRefreshCheck,
	checkAppVersion,
	getOrgIDFromLoggedUser,
} from 'core-app/modules/Auth/AuthSaga'
import { getIn, omit, merge } from 'timm'
import { isEmptyObject, removeDuplicates } from 'core-app/utils/helpers'
import querySerializer from 'query-string'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { CookieDuc } from 'core-app/modules/App/cookieDuc'
import { USER_COOKIE_KEYS } from 'ui-lib/utils/config'
import {
	extractOrgIDsFromResponses,
	transformFilterStringsToBEQueries,
	extractFilterQueries,
	transformSortStringsToBEQueries,
	extractSortQueries,
	responseDocsMapper,
} from 'core-app/shared/helpers'

import { Toast } from 'ui-lib/components/Toast'
import { AppDuc } from './duc'

const responseDocsMapperList = (targetTypes, responses) =>
	targetTypes.reduce((agg, _type, index) => {
		const aggregator = agg

		aggregator[_type] = getIn(responses[index], ['data', 'list']) || []

		return aggregator
	}, {})

const logger = LogHelper('client:appSaga')

function* isUpdateAvailable() {
	try {
		const requestUrl = '/-/health.json'
		const data = yield call(request, requestUrl)

		const appVersion = getIn(data, ['release', 'version']) || ''

		yield put(
			CookieDuc.creators.getCookie({
				cookieName: USER_COOKIE_KEYS.APP_VERSION,
			})
		)
		const cookies = yield select(CookieDuc.selectors.cookies)

		const currentAppVersion = getIn(cookies, ['appVersion'])

		if (appVersion !== currentAppVersion) {
			yield put(AppDuc.creators.showUpdateModal(true))
		}
	} catch (err) {
		logger.log(err)
	}
}

function* DOList() {
	try {
		yield put(AppDuc.creators.showGlobalLoader('DO-in-App'))

		const orgID = yield getOrgIDFromLoggedUser()
		const requestUrl = `${getCoreEndPoint()}entities?type=delivery-order&initiatingPartyID=${orgID}`
		const { data } = yield call(request, requestUrl)

		yield put(AppDuc.creators.setOutgoingDOList(data))
	} catch (e) {
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('DO-in-App'))
	}
}

function* updateAppVersion() {
	try {
		const appVersion = yield checkAppVersion()

		yield put(
			CookieDuc.creators.setCookie({
				cookieName: USER_COOKIE_KEYS.APP_VERSION,
				cookieValue: appVersion,
			})
		)
	} catch (err) {
		logger.log(err)
	}
}

function* handleModal(action) {
	try {
		const { onAccept, onReject } = action || {}

		const { success } = yield race({
			success: take(AppDuc.creators.acceptedOnConfirmationModal().type),
			fail: take(AppDuc.creators.declinedOnConfirmationModal().type),
		})

		if (success && onAccept) {
			onAccept()
		} else {
			onReject()
		}
	} catch (e) {
		logger.log(e)
	}
}
function* saveUserFeedback(action) {
	try {
		const { feedback, module } = action
		const values = {
			module,
			message: feedback,
		}
		const requestUrl = `${getCoreEndPoint()}feedback`
		const options = {
			method: 'POST',
			body: JSON.stringify(values),
		}
		yield call(request, requestUrl, options)
		yield Toast({
			type: 'success',
			message: 'Feedback Sent',
		})
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('dashboard-listing'))
	}
}

export function* DocumentListing(
	requestUrl,
	locationState = {},
	PAGINATION_LIMIT = 20,
	meta = ''
) {
	try {
		const { query } = locationState
		const existingQueryFromUrl = query || {}
		let frontendTargetQuery = {} // this would appear in search bar
		let backendTargetQuery = {} // this would go to backend api call

		const paginationQuery = {
			activeIndex: existingQueryFromUrl.activeIndex
				? existingQueryFromUrl.activeIndex
				: 0,
			limit: Math.min(
				existingQueryFromUrl.limit || PAGINATION_LIMIT,
				PAGINATION_LIMIT
			),
			nextIndex: existingQueryFromUrl.nextIndex,
		}

		if (paginationQuery.limit) {
			backendTargetQuery.limit = paginationQuery.limit
		}

		if (paginationQuery.nextIndex) {
			backendTargetQuery.startID = paginationQuery.nextIndex
		}

		// prepare the filter query
		const filterQueries =
			omit(existingQueryFromUrl, [
				'sort',
				'q',
				'activeIndex',
				'limit',
				'nextIndex',
			]) || {}

		if (!isEmptyObject(filterQueries)) {
			// form the backend queries from the object
			backendTargetQuery = merge(
				backendTargetQuery,
				transformFilterStringsToBEQueries(filterQueries)
			)
		}
		const sortQuery = existingQueryFromUrl.sort || []
		const _sortKeys = Array.isArray(sortQuery) ? sortQuery : [sortQuery]
		let _sortQuery = _sortKeys && transformSortStringsToBEQueries(_sortKeys)
		_sortQuery = getIn(_sortQuery, ['delivery-order']) || ''
		const __query = {
			...backendTargetQuery,
		}

		const { data = {} } = yield CallWithRefreshCheck(
			`${requestUrl}?${querySerializer.stringify({
				...meta,
				...__query,
				...{ sort: _sortQuery },
			})}`
		)

		const serverPaginationQuery = {
			activeIndex: paginationQuery.activeIndex // &&
				? // We should have this check so the sequence of pagination is right.
				  // getIn(response, ['startID']) === backendTargetQuery.startID
				  paginationQuery.activeIndex
				: 0,
			limit: Math.min(getIn(data, ['pageSize']) || PAGINATION_LIMIT),
			total: getIn(data, ['total']),
			nextIndex: getIn(data, ['nextStartID']),
		}

		const sortQueriesFromBE = extractSortQueries(
			{ 'delivery-order': data },
			_sortKeys
		)

		// fetch org ids
		const orgIDs = extractOrgIDsFromResponses([data])
		if (orgIDs.length)
			yield put(
				AuthDuc.creators.fetchOrgDetails(removeDuplicates(orgIDs))
			)

		const { queryTree, stateTree } = extractFilterQueries(data)

		frontendTargetQuery = merge(frontendTargetQuery, queryTree)
		if (sortQueriesFromBE.length) {
			frontendTargetQuery.sort = sortQueriesFromBE
		}

		return (
			{ data, frontendTargetQuery, stateTree, serverPaginationQuery } ||
			{}
		)
	} catch (e) {
		logger.log(e)
	}
}

export function* MultipleDocumentListing(
	createPipeLineTypes,
	locationState = {},
	targetTypes = [],
	PAGINATION_LIMIT = 3
) {
	try {
		const { query, payload } = locationState
		const rootModule = getIn(payload, ['rooModule']) || ''
		const existingQueryFromUrl = query || {}
		const frontendTargetQuery = {} // this would appear in search bar
		let backendTargetQuery = {} // this would go to backend api call

		const paginationQuery = {
			activeIndex: existingQueryFromUrl.activeIndex
				? existingQueryFromUrl.activeIndex
				: 0,
			limit: Math.min(
				existingQueryFromUrl.limit || PAGINATION_LIMIT,
				PAGINATION_LIMIT
			),
			nextIndex: existingQueryFromUrl.nextIndex,
		}

		if (paginationQuery.limit) {
			backendTargetQuery.limit = paginationQuery.limit
		}

		if (paginationQuery.nextIndex) {
			backendTargetQuery.startID = paginationQuery.nextIndex
		}

		// prepare the filter query
		const filterQueries =
			omit(existingQueryFromUrl, [
				'sort',
				'q',
				'activeIndex',
				'limit',
				'nextIndex',
			]) || {}

		if (!isEmptyObject(filterQueries)) {
			// form the backend queries form the object
			backendTargetQuery = merge(
				backendTargetQuery,
				transformFilterStringsToBEQueries(filterQueries)
			)
		}
		const _recordQuery = getIn(existingQueryFromUrl, [rootModule]) || []
		const _sortKeys = Array.isArray(_recordQuery)
			? _recordQuery
			: [_recordQuery]
		const sortQuery =
			_sortKeys && transformSortStringsToBEQueries(_sortKeys)

		const __query = {
			...backendTargetQuery,
		}
		// eslint-disable-next-line array-callback-return
		const callsPipeLine = createPipeLineTypes.map(pipeline => {
			if (!isEmptyObject(pipeline)) {
				const _sortQuery = getIn(sortQuery, [pipeline, 'type']) || ''
				const requestUrl = getIn(pipeline, ['url']) || ''

				return CallWithRefreshCheck(
					`${requestUrl}&${querySerializer.stringify({
						...__query,
						...{ sort: _sortQuery },
					})}`
				)
			}
		})

		const origResponse = yield all(callsPipeLine)
		const _responses = responseDocsMapper(targetTypes, origResponse)
		const responses = responseDocsMapperList(targetTypes, origResponse)

		const orgIDs = yield extractOrgIDsFromResponses(origResponse, 'partner')
		if (orgIDs.length)
			yield put(
				AuthDuc.creators.fetchOrgDetails(removeDuplicates(orgIDs))
			)

		// update the queries as applied in backend to stay in sync
		const sortQueriesFromBE = extractSortQueries(_responses, _sortKeys)
		if (sortQueriesFromBE.length) {
			frontendTargetQuery.sort = sortQueriesFromBE
		}
		// eslint-disable-next-line array-callback-return
		const filterQueriesFromBE = Object.keys(_responses).map(key => {
			const { queryTree, stateTree } = extractFilterQueries(
				_responses[key]
			)
			const serverPaginationQuery = {
				activeIndex: paginationQuery.activeIndex // &&
					? // We should have this check so the sequence of pagination is right.
					  // getIn(response, ['startID']) === backendTargetQuery.startID
					  paginationQuery.activeIndex
					: 0,
				limit: Math.min(
					getIn(_responses[key], ['pageSize']) || PAGINATION_LIMIT
				),
				nextIndex: getIn(_responses[key], ['nextStartID']),
				total: getIn(_responses[key], ['total']),
				pageCount: getIn(_responses[key], ['pageCount']),
			}

			return {
				type: key,
				queryTree,
				stateTree,
				serverPaginationQuery,
			}
		})

		if (sortQueriesFromBE.length) {
			frontendTargetQuery.sort = sortQueriesFromBE
		}

		return { responses, frontendTargetQuery, filterQueriesFromBE } || {}
	} catch (e) {
		logger.log(e)
	}
}

export default function* AppSaga() {
	try {
		yield all([
			takeLatest(
				AppDuc.creators.showConfirmationModal({ meta: {} }).type,
				handleModal
			),
			takeLatest(
				AppDuc.creators.saveUserFeedback().type,
				saveUserFeedback
			),
			takeLatest(
				AppDuc.creators.isUpdateAvailable().type,
				isUpdateAvailable
			),
			takeLatest(AppDuc.creators.DOList().type, DOList),
			takeLatest(
				AppDuc.creators.updateAppVersion().type,
				updateAppVersion
			),
		])
	} catch (e) {
		logger.error(e)
	}
}
