import Duck from 'extensible-duck'
import { setIn, getIn } from 'timm'
import { createSelector } from 'reselect'

import { initialState, TRACE_GROUP_STATUS } from './config'

export const StorageCompanyDuc = new Duck({
	namespace: 'storageCompany',
	store: 'global',
	types: [
		'SET_ACTIVE_MODULE',
		'FETCH_INSIGHTS',
		'FETCH_INSIGHTS_SUCCESS',
		'SET_LOADING_TYPE',
		'FETCH_STORAGE_LISTING',
		'FETCH_STORAGE_LISTING_SUCCESS',
		'ASSIGN_TANK',
		'ASSIGN_TANK_VIEW',
		'ASSIGN_TANK_VIEW_SUCCESS',
		'MAP_OUTGOING_VIEW',
		'MAP_OUTGOING_VIEW_SUCCESS',
		'ASSIGN_TANK_CREATE',
		'ASSIGN_TANK_CREATE_SUCCESS',
		'FLUSH_DATA',
		'MAP_OUTGOING_CREATE',
		'CREATE_MAP_OUTPUT',
		'FETCH_DASHBOARD_LISTING',
		'FETCH_DASHBOARD_LISTING_LOADING',
		'FETCH_DASHBOARD_LISTING_SUCCESS',
		'SET_PAGINATION_ENTRIES',
		'SET_ACTIVE_LISTING_FILTERS',
		'SET_ACTIVE_LISTING_SORTS',
		'SET_STORAGE_TANKS',
	],
	initialState,
	reducer: (state, action, duck) => {
		switch (action.type) {
			case duck.types.SET_ACTIVE_MODULE: {
				return setIn(state, ['activeModule'], action.module)
			}
			case duck.types.FETCH_INSIGHTS_SUCCESS: {
				const { userInsight } = action

				return setIn(state, ['insightsData'], userInsight)
			}

			case duck.types.SET_LOADING_TYPE: {
				const { status } = action

				return setIn(state, ['loading'], status)
			}
			case duck.types.FETCH_STORAGE_LISTING_SUCCESS: {
				const { listing } = action

				return setIn(
					state,
					['modules', 'storage', 'activeDocuments'],
					listing
				)
			}

			case duck.types.ASSIGN_TANK_VIEW_SUCCESS: {
				const { details } = action

				return setIn(
					state,
					['modules', 'storage', 'assignTank'],
					details
				)
			}
			case duck.types.ASSIGN_TANK_CREATE_SUCCESS: {
				const { details } = action

				return setIn(
					state,
					['modules', 'storage', 'parentRef'],
					details
				)
			}
			case duck.types.MAP_OUTGOING_VIEW_SUCCESS: {
				const { details } = action

				return setIn(
					state,
					['modules', 'storage', 'outgoingBatch'],
					details
				)
			}

			case duck.types.FLUSH_DATA: {
				return setIn(
					state,
					['module', 'storage'],
					initialState.modules.storage
				)
			}
			case duck.types.SET_ACTIVE_LISTING_FILTERS: {
				const { filters = {} } = action

				return setIn(
					state,
					['modules', 'listing', 'activeFilters'],
					filters
				)
			}
			case duck.types.FETCH_DASHBOARD_LISTING_LOADING: {
				return setIn(
					state,
					['modules', 'listing', 'loading'],
					action.status || false
				)
			}

			case duck.types.FETCH_DASHBOARD_LISTING_SUCCESS: {
				const { activeDocuments } = action

				return setIn(
					state,
					['modules', 'listing', 'activeDocuments'],
					activeDocuments
				)
			}

			case duck.types.FLUSH_LISTING_DATA: {
				return setIn(
					state,
					['modules', 'listing', 'activeDocuments'],
					initialState.modules.listing.activeDocuments
				)
			}

			case duck.types.SET_PAGINATION_ENTRIES: {
				const {
					activeIndex,
					limit,
					total,
					nextCursor,
					currentCursor,
				} = action

				const root = ['modules', 'listing', 'pagination']
				let nextState = state

				nextState = setIn(
					nextState,
					[...root, 'activeIndex'],
					activeIndex || 0
				)

				if (limit)
					nextState = setIn(nextState, [...root, 'limit'], limit)

				if (total)
					nextState = setIn(nextState, [...root, 'total'], total)

				if (nextCursor)
					nextState = setIn(
						nextState,
						[...root, 'nextCursor'],
						nextCursor
					)

				if (currentCursor)
					nextState = setIn(
						nextState,
						[...root, 'currentCursor'],
						currentCursor
					)

				return nextState
			}

			case duck.types.SET_ACTIVE_LISTING_SORTS: {
				const { sortMap } = action

				let sorts = {}
				;(sortMap.sort || []).forEach(key => {
					const arr = key.split('|')
					arr.shift() // remove the first fragment since type is already decided
					const lastVal = arr.pop()

					sorts = setIn(sorts, arr, lastVal)
				})

				return setIn(
					state,
					['modules', 'listing', 'activeSorts'],
					sorts
				)
			}

			case duck.types.SET_STORAGE_TANKS: {
				return setIn(
					state,
					['modules', 'storage', 'storageTanks'],
					action.storageTanks
				)
			}

			default:
				return state
		}
	},
	helpers: {
		extractDocuments: documents =>
			[...TRACE_GROUP_STATUS].reduce((agg, key) => {
				const aggregator = agg
				aggregator[key] = getIn(documents, [key, 'list']) || []

				return aggregator
			}, {}),
	},
	selectors: {
		storageState: state =>
			getIn(state, ['storageCompany', 'modules', 'storage']),
		listingState: state =>
			getIn(state, ['storageCompany', 'modules', 'listing']) || {},
		auth: state => state.auth,
		location: state => state.location,
		activeModule: state =>
			getIn(state, ['storageCompany', 'activeModule']) || 'Error',

		getLoadingStatus: state =>
			getIn(state, ['storageCompany', 'loading']) || false,
		getActiveTabs: new Duck.Selector(selectors =>
			createSelector(selectors.storageState, storage =>
				getIn(storage, ['tabsConfig'])
			)
		),
		getActiveSorts: new Duck.Selector(selectors =>
			createSelector(
				selectors.storageState,
				storage => getIn(storage, ['activeSorts']) || {}
			)
		),
		getActiveDocuments: new Duck.Selector(selectors =>
			createSelector(selectors.storageState, storage => {
				const document = getIn(storage, ['activeDocuments']) || {}

				return StorageCompanyDuc.options.helpers.extractDocuments(
					document
				)
			})
		),
		getAssignedTank: new Duck.Selector(selectors =>
			createSelector(
				selectors.storageState,
				storage => getIn(storage, ['assignTank']) || []
			)
		),
		getAssignedTankCreate: new Duck.Selector(selectors =>
			createSelector(
				selectors.storageState,
				storage => getIn(storage, ['parentRef']) || {}
			)
		),
		getOutgoingBatch: new Duck.Selector(selectors =>
			createSelector(
				selectors.storageState,
				storage => getIn(storage, ['outgoingBatch']) || []
			)
		),
		getListingDocuments: new Duck.Selector(selectors =>
			createSelector(
				selectors.listingState,
				listing => getIn(listing, ['activeDocuments', 'list']) || []
			)
		),
		getListingLoadingStatus: new Duck.Selector(selectors =>
			createSelector(
				selectors.listingState,
				listing => listing.loading || false
			)
		),
		getListingActiveFilters: new Duck.Selector(selectors =>
			createSelector(
				selectors.listingState,
				listing => listing.activeFilters
			)
		),
		getListingPaginationEntries: new Duck.Selector(selectors =>
			createSelector(
				selectors.listingState,
				listing => listing.pagination
			)
		),
		getDocumentListingActiveSorts: new Duck.Selector(selectors =>
			createSelector(
				selectors.listingState,
				listing => getIn(listing, ['activeSorts']) || {}
			)
		),
		getInsightsData: state =>
			getIn(state, ['storageCompany', 'insightsData']) || {},
		getStorageTanksList: state =>
			getIn(state, [
				'storageCompany',
				'modules',
				'storage',
				'storageTanks',
			]) || [],
	},
	creators: duck => ({
		setActiveModule: module => ({
			type: duck.types.SET_ACTIVE_MODULE,
			module,
		}),
		fetchInsights: () => ({
			type: duck.types.FETCH_INSIGHTS,
		}),

		setLoadingStatus: status => ({
			type: duck.types.SET_LOADING_TYPE,
			status,
		}),
		fetchStorageListing: () => ({
			type: duck.types.FETCH_STORAGE_LISTING,
		}),
		fetchStorageListingSuccess: listing => ({
			type: duck.types.FETCH_STORAGE_LISTING_SUCCESS,
			listing,
		}),
		fetchDocumentListing: (
			docType,
			rootModules = [],
			locationState = {},
			skipGlobalLoader = false
		) => ({
			type: duck.types.FETCH_DASHBOARD_LISTING,
			rootModule: rootModules[0],
			submodule: docType,
			locationState,
			skipGlobalLoader,
		}),
		fetchDocumentLoading: status => ({
			type: duck.types.FETCH_DASHBOARD_LISTING_LOADING,
			status,
		}),
		fetchDocumentListingSuccess: activeDocuments => ({
			type: duck.types.FETCH_DASHBOARD_LISTING_SUCCESS,
			activeDocuments,
		}),
		setPaginationEntries: (
			activeIndex,
			limit,
			total,
			nextCursor,
			currentCursor
		) => ({
			type: duck.types.SET_PAGINATION_ENTRIES,
			activeIndex,
			limit,
			total,
			nextCursor,
			currentCursor,
		}),
		setActiveListingFilters: filters => ({
			type: duck.types.SET_ACTIVE_LISTING_FILTERS,
			filters,
		}),
		setActiveListingSorts: sortMap => ({
			type: duck.types.SET_ACTIVE_LISTING_SORTS,
			sortMap,
		}),
		assignTank: (values, traceID, successToast) => ({
			type: duck.types.ASSIGN_TANK,
			values,
			traceID,
			successToast,
		}),
		assignTankView: () => ({
			type: duck.types.ASSIGN_TANK_VIEW,
		}),
		assignTankViewSuccess: details => ({
			type: duck.types.ASSIGN_TANK_VIEW_SUCCESS,
			details,
		}),
		assignTankCreate: () => ({
			type: duck.types.ASSIGN_TANK_CREATE,
		}),
		assignTankCreateSuccess: details => ({
			type: duck.types.ASSIGN_TANK_CREATE_SUCCESS,
			details,
		}),
		mapOutgoingView: () => ({
			type: duck.types.MAP_OUTGOING_VIEW,
		}),
		mapOutgoingViewSuccess: details => ({
			type: duck.types.MAP_OUTGOING_VIEW_SUCCESS,
			details,
		}),
		fetchInsightsSuccess: userInsight => ({
			type: duck.types.FETCH_INSIGHTS_SUCCESS,
			userInsight,
		}),
		flushData: () => ({
			type: duck.types.FLUSH_DATA,
		}),
		mapOutgoingCreate: () => ({
			type: duck.types.MAP_OUTGOING_CREATE,
		}),
		createMapOutput: (successToast, documents) => ({
			type: duck.types.CREATE_MAP_OUTPUT,
			successToast,
			documents,
		}),
		setStorageTanks: storageTanks => ({
			type: duck.types.SET_STORAGE_TANKS,
			storageTanks,
		}),
	}),
})
