import React from 'react'
import { Box } from 'ui-lib/utils/Box'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MainRouteDuc } from 'core-app/routes/duc'
import { SettingsDuc } from 'core-app/modules/Settings/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { Card } from 'ui-lib/components/Card'
import { ButtonWithNoBorder } from 'ui-lib/components/Button'
import { SmallText } from 'ui-lib/components/Typography'
import { useDateByFormat } from 'core-app/utils/date'
import theme, { convertToRGBA } from 'ui-lib/utils/base-theme'

const NotificationComponent = ({ onClick, data }) => {
	return (
		<Card
			width="100%"
			textAlign="left"
			style={{
				maxWidth: '720px',
				margin: '0 auto 16px',
				padding: '16px',
				background: data.status === 'read' ? '#f5f5f5' : '#fff',
				border: `2px solid ${convertToRGBA(
					theme.color.themeDec_TradeMgr,
					'0.0'
				)}`,
				':hover': {
					border: `2px solid ${convertToRGBA(
						theme.color.themeDec_TradeMgr,
						'0.5'
					)} !important`,
				},
			}}
			border
			cursor="pointer"
			onClick={() => onClick(data)}
		>
			<Box
				style={{
					display: 'flex',
					alignItems: 'center',
					flexDirection: 'row',
				}}
			>
				<Box style={{ width: '90%' }}>
					<SmallText
						fontSize={theme.fontSize.m}
						style={{
							marginBottom: '8px',
							lineHeight: '16px',
							cursor: 'pointer',
						}}
					>
						{data.message}
					</SmallText>
					<SmallText
						fontSize={theme.fontSize.s}
						style={{
							color: 'rgba(0, 0, 0, 0.5)',
							lineHeight: '16px',
							cursor: 'pointer',
						}}
					>
						{useDateByFormat(data.createdAt, 'd LLL yyyy, hh:mm a')}
					</SmallText>
				</Box>
				<Box
					style={{
						width: '10%',
						marginLeft: '5px',
						padding: '5px',
					}}
				>
					<SmallText
						style={{
							border:
								data.status === 'read'
									? 'none'
									: `3px solid ${theme.color.grey3}`,
							backgroundColor:
								data.status === 'read'
									? '#ff'
									: `${theme.color.blue1}`,
							lineHeight: '16px',
							height: '14px',
							width: '14px',
							borderRadius: '50%',
						}}
					/>
				</Box>
			</Box>
		</Card>
	)
}

const Notifications = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const location = useSelector(SettingsDuc.selectors.location)
	const currentOrg = useSelector(AuthDuc.selectors.getCurrentOrganization)
	const notificationList = useSelector(SettingsDuc.selectors.getNotifications)
	const paginationConfig = useSelector(
		SettingsDuc.selectors.getPaginationConfig
	)
	const { total } = paginationConfig
	const disableShowMore = total === notificationList.length

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.notifications'),
			name: 'notifications',
			isActive: false,
		},
	]

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
	}

	const handleNotificationClick = notification => {
		const { meta, id, originOrgID } = notification
		const { component, componentRefID } = meta
		const entity = component.split('/')[1]

		if (component.includes('rfq')) {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.MARKETPLACE$ACTION,
					{
						rootModule:
							currentOrg.id === originOrgID ? 'buyer' : 'seller',
						action: 'view-rfq',
						documentReference: componentRefID,
					},
					{},
					{},
					true
				)
			)
		} else if (component.includes('quote')) {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.MARKETPLACE$ACTION,
					{
						rootModule:
							currentOrg.id === originOrgID ? 'seller' : 'buyer',
						action: 'view-quote',
						documentReference: componentRefID,
					},
					{},
					{},
					true
				)
			)
		} else if (component.includes('organizations/partners')) {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.PARTNER$WREQREFERENCE,
					{
						action: 'view',
						partnerRef: componentRefID,
					},
					{
						docType: 'notifications',
					}
				)
			)
		} else {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$VIEWWDOCREFERENCE,
					{
						rootModule: entity,
						documentReference: componentRefID,
					},
					{},
					{},
					true
				)
			)
		}

		dispatch(AuthDuc.creators.singleNotificationUpdateStatus(id, 'read'))
	}

	const handleShowMore = () => {
		const { nextCursor } = paginationConfig
		const locationState = {
			query: {
				...location.query,
				limit: 10,
				nextIndex: nextCursor,
			},
		}

		dispatch(SettingsDuc.creators.appendNotifications(locationState))
	}

	return (
		<Box padding={8} width="100%" height="100%">
			<Box style={{ padding: '0 5px' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			{notificationList.map(notification => {
				return (
					<NotificationComponent
						key={notification.id}
						data={notification}
						onClick={handleNotificationClick}
					/>
				)
			})}

			{notificationList.length ? null : (
				<Box center style={{ marginTop: '30vh' }}>
					{t('allNotifications.noNotifications')}
				</Box>
			)}

			{!disableShowMore && (
				<Box center padding={10}>
					<ButtonWithNoBorder
						label={t('allNotifications.showMore')}
						style={{
							fontSize: theme.fontSize.xl,
						}}
						onClick={handleShowMore}
					/>
				</Box>
			)}
		</Box>
	)
}

export { Notifications }
