import React from 'react'
import { useSelector } from 'react-redux'
import { NotFoundBlock } from 'core-app/modules/App/components/Error'
import { DealerDuc } from 'core-app/modules/Dealer/duc'
import { WorkListing } from 'core-app/modules/Dealer/containers/subModules/Work'

const modulesMap = {
	work: WorkListing,
}

const SubDashboard = () => {
	const location = useSelector(DealerDuc.selectors.location)
	const { payload = {} } = location
	const activeModule = payload.rootModule

	const Component = modulesMap[activeModule] || NotFoundBlock
	// if we are here, we have a submodule, route it to the module

	return <Component />
}

export default SubDashboard
