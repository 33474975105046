import React, { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn } from 'timm'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { AdminDuc } from 'core-app/modules/Admin/duc'
import { Title } from 'core-app/shared/components/Title'
import { currentPlatform } from 'ui-lib/utils/config'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { ButtonIconWrapper, Button } from 'ui-lib/components/Button'
import { Icon } from 'ui-lib/icons/components/Icon'
import { Box } from 'ui-lib/utils/Box'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import { Tabs } from 'ui-lib/components/Tabs'
import { Spacer } from 'ui-lib/utils/Spacer'
import Organisation from '../CompanyInformation/Organisation'
import Certification from '../CompanyInformation/Certification'
import Business from '../CompanyInformation/BusinessBlock'
import { WebTour } from '../../../WebTour'
import { WebTourDuc } from '../../../WebTour/duc'
import CollectionPoint from '../CollectionPoint/CollectionPoint'
import AreaDetails from '../AreaDetails/AreaDetails'
import OtherDocuments from '../OtherDocuments/OtherDocuments'
import { AddCarbonNumber } from '../CompanyInformation/AddCarbonNumber'

const getTabComponents = ({
	certificates,
	areaDetails,
	otherDocuments,
	details,
	business,
	carbonNumber,
	collectionPoints,
	t,
}) => ({ name }) => () => {
	return (
		<Box
			style={{
				backgroundColor: '#fff',
			}}
		>
			{name === 'organization' && (
				<Organisation orgDetails={details} t={t} />
			)}
			{name === 'business' && <Business business={business} t={t} />}
			{name === 'certifications' && (
				<Certification certificates={certificates} t={t} />
			)}
			{name === 'carbonNumber' && (
				<AddCarbonNumber carbonNumber={carbonNumber} />
			)}
			{name === 'collectionPoint' && (
				<CollectionPoint collectionPoints={collectionPoints} t={t} />
			)}
			{name === 'areaDetails' && (
				<AreaDetails areaDetails={areaDetails} t={t} />
			)}
			{name === 'otherDocuments' && (
				<OtherDocuments otherDocuments={otherDocuments} t={t} />
			)}
		</Box>
	)
}

const CompanyInformation = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const actor = useSelector(AuthDuc.selectors.getActor) || []
	const isDealer = actor.includes('palmoil_ffbdealer')
	const isSubDealer = actor.includes('palmoil_ffbsubdealer')
	const isPlantation = actor.includes('palmoil_plantation')
	const currentOrg = useSelector(AuthDuc.selectors.getCurrentOrganization)
	const collectionPoints =
		getIn(currentOrg, ['meta', 'childOrganization']) || []
	const certificates = useSelector(AdminDuc.selectors.getCertificateDetails)
	const areaDetails = useSelector(AdminDuc.selectors.getAreaDetails)
	const otherDocuments = useSelector(
		AdminDuc.selectors.getOtherDocumentsDetails
	)
	const business = useSelector(AdminDuc.selectors.getBusinessDetails)
	const companyInfo = useSelector(AdminDuc.selectors.getCompanyInfo)
	const carbonNumber = useSelector(AdminDuc.selectors.getCarbonNumber)
	const [activeTab, setActiveTab] = useState('organization')

	const activeTypes = [
		{
			code: 'organization',
			title: 'viewCompanyInfo.organisationInfo',
		},
		{
			code: 'business',
			title: 'viewCompanyInfo.businessInfo',
		},
		{
			code: 'certifications',
			title: 'viewCompanyInfo.certInfo',
		},
		...(currentPlatform() === 'palmoil'
			? [
					{
						code: 'carbonNumber',
						title: 'viewCompanyInfo.carbonNumber',
					},
			  ]
			: []),
		...(isDealer || isSubDealer
			? [
					{
						code: 'collectionPoint',
						title: 'viewCompanyInfo.manageCP',
					},
			  ]
			: []),
		...(isPlantation
			? [
					{
						code: 'areaDetails',
						title: 'viewCompanyInfo.areaDetails',
					},
			  ]
			: []),
		...(isPlantation
			? [
					{
						code: 'otherDocuments',
						title: 'viewCompanyInfo.otherDocuments',
					},
			  ]
			: []),
	]

	const getTabs = useCallback(() => {
		return activeTypes.map(({ code, title }, key) => ({
			name: code,
			title: t(title),
			key,
		}))
	}, [activeTypes, t])
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)

	useEffect(() => {
		if (tourModule === 'companyInfoHeader') {
			dispatch(
				WebTourDuc.creators.setActiveTourModule('companyInfoHeader2')
			)
		}
	}, [tourModule, dispatch])
	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.admin'),
			name: 'admin',
			isActive: true,
		},
		{
			label: t('breadcrumb.viewCompanyInfo'),
			name: 'view-company-info',
			isActive: false,
		},
	]

	/** Handlers */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'admin') {
			dispatch(MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN))
		}
	}

	return (
		<>
			<Box id="input-companyInfo" padding={8} width="100%" height="100%">
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
				<Box row justifyContent="space-between" alignItems="center">
					<Title title={t('viewCompanyInfo.title')} />
				</Box>
				<Spacer size={20} />

				<Tabs
					tabs={getTabs()}
					tabComponentsMap={getTabComponents({
						type: activeTab,
						details: companyInfo,
						certificates,
						areaDetails,
						otherDocuments,
						business,
						collectionPoints,
						carbonNumber,
						t,
					})}
					activeTabIndex={activeTypes.findIndex(
						type => type.code === activeTab
					)}
					onChange={({ name }) => {
						setActiveTab(name)
					}}
				/>
				<Spacer size={40} />
				{activeTab !== 'carbonNumber' && (
					<Box padding="25px 0" style={{ maxWidth: 150 }}>
						<Button
							label={t('common.back')}
							rounded
							customIcon={
								<ButtonIconWrapper lightBG>
									<Icon glyph={LeftArrowIcon} />
								</ButtonIconWrapper>
							}
							onClick={() =>
								dispatch(
									MainRouteDuc.creators.switchPage(
										MainRouteDuc.types.ADMIN
									)
								)
							}
						/>
					</Box>
				)}
			</Box>
			{tourModule === 'companyInfoHeader2' && <WebTour />}
		</>
	)
}

export { CompanyInformation }
