import React from 'react'
import { Box } from 'ui-lib/utils/Box'
import theme from 'ui-lib/utils/base-theme'

const SimplifiedTimeline = ({ data }) => {
	return (
		<Box style={{ background: '#fff', height: data.length * 100 }}>
			<div
				style={{
					fontSize: 24,
					fontWeight: 600,
					color: '#8D93A0',
					marginBottom: 50,
					textAlign: 'center',
				}}
			/>
			<div
				style={{
					width: 1,
					marginLeft: 300,
					position: 'relative',
					borderLeft: '1px solid rgb(100, 93, 183, 0.1)',
					height: data.length > 0 ? (data.length - 1) * 100 : 0,
				}}
			>
				{data.map((item, index) => (
					<>
						<div
							style={{
								top: index * 100 - 13,
								left: -150,
								zIndex: 10,
								width: 100,
								fontSize: 14,
								display: 'flex',
								color: theme.color.blue8,
								position: 'absolute',
								alignItems: 'center',
							}}
						>
							{item.key}
						</div>
						<div
							style={{
								top: index * 100,
								left: -6,
								width: 12,
								height: 12,
								zIndex: 10,
								borderRadius: 12,
								position: 'absolute',
								backgroundColor: '#645DB7',
							}}
						/>
						<div
							style={{
								top: index * 100 - 14,
								left: 100,
								zIndex: 10,
								width: 150,
								display: 'flex',
								color: '#3F57C6',
								position: 'absolute',
								alignItems: 'center',
							}}
						>
							{item.value}
						</div>
					</>
				))}
			</div>
		</Box>
	)
}

export { SimplifiedTimeline }
