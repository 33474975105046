import React from 'react'
import { useSelector } from 'react-redux'
import { NotFoundBlock } from 'core-app/modules/App/components/Error'
import { Assets } from 'core-app/modules/Admin/containers/subModules/Assets'
import { CompanyInformation } from 'core-app/modules/Admin/containers/subModules/CompanyInformation'
import { WebhookApiSettings } from 'core-app/modules/Admin/containers/subModules/WebhookApiSettings'
import { OtherSettings } from 'core-app/modules/Admin/containers/subModules/OtherSettings'
import { Employees } from 'core-app/modules/Admin/containers/subModules/Employees'
import { AdminDuc } from 'core-app/modules/Admin/duc'
import { CollectionPoint } from './CollectionPoint'

const modulesMap = {
	assets: Assets,
	'company-information': CompanyInformation,
	'employee-management': Employees,
	'collection-point': CollectionPoint,
	'other-settings': OtherSettings,
	'webhook-api-settings': WebhookApiSettings,
}

const SubDashboard = () => {
	const location = useSelector(AdminDuc.selectors.location)
	const { payload = {} } = location
	const activeModule = payload.rootModule

	const Component = modulesMap[activeModule] || NotFoundBlock
	// if we are here, we have a submodule, route it to the module

	return <Component />
}

export default SubDashboard
