/* @flow */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from 'ui-lib/components/Table'
import { ErrorBlock } from 'ui-lib/components/ErrorBlocks'
import { BoxShadowWrapper } from 'ui-lib/components/Card'
import { Box } from 'ui-lib/utils/Box'
import { TransparentBgSpinner } from 'ui-lib/components/Spinner/TransparentBgSpinner'

type Props = {
	isLoading: boolean,
	columnConfig: Array<any>,
	rowData: Array<Object>,
	rowTotal?: number,
	size?: string,
	maxHeight?: number,
	minHeight?: number,
	errorSize?: string,
	onClickRow: ({ datum: Array<any> }) => void,
	tableSize?: string,
	scroll?: boolean,
}

const BoxShadowTable = ({
	isLoading,
	scroll,
	columnConfig,
	rowData,
	rowTotal,
	size,
	tableSize,
	maxHeight,
	minHeight,
	errorSize = 'small',
	onClickRow,
	widthProp = false,
	isMobile = false,
}: Props) => {
	const { t } = useTranslation()

	return (
		<Box
			overflow
			style={{
				...(widthProp && { width: 'inherit' }),
			}}
		>
			<BoxShadowWrapper
				scroll={scroll}
				style={{
					position: 'relative',
					minHeight: maxHeight || 'auto',
				}}
			>
				{isLoading && <TransparentBgSpinner />}
				<Table
					size={size}
					step={rowTotal}
					columnConfig={columnConfig}
					rowData={rowData || []}
					onClickRow={onClickRow}
					tableSize={tableSize}
					noBorder
					mobileViewCSSOn={isMobile}
				/>
				{!isLoading && !(rowData || []).length && (
					<Box
						style={{
							backgroundColor: 'rgb(236, 238, 243)',
							height: minHeight,
						}}
					>
						<ErrorBlock
							hideButton
							small={errorSize === 'small'}
							status="empty"
							message={t('common.errorBlockMessage')}
						/>
					</Box>
				)}
			</BoxShadowWrapper>
		</Box>
	)
}

BoxShadowTable.defaultProps = {
	onClickRow: () => {},
	rowTotal: 200,
	scroll: true,
}

export { BoxShadowTable }
