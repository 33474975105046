export const initialState = {
	activeModule: '',
	productsListing: [],
	products: [],
	storageUnits: [],
	productsListingWithPagination: [],
	pagination: {},
	product: {
		category: '',
		subCategory: '',
		products: [],
	},
	subProduct: {
		name: '',
		description: '',
		skuCode: '',
		packingType: '',
		unitCapacity: 0,
		uom: '',
		colour: '',
		productAlias: '',
	},
	activeProduct: {},
}

export const TABLE_ALIAS = {
	productName: 'products.productName',
	productType: 'products.productType',
	hsn: 'products.hsn',
	availableQty: 'products.availableQty',
	productNameWithCode: 'products.productNameWithCode',
	rateofDuty: 'products.rateofDuty',
	qtyInHand: 'products.qtyInHand',
}
