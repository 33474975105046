import React from 'react'
import { useSelector } from 'react-redux'
import { NotFoundBlock } from 'core-app/modules/App/components/Error'
import { MillDuc } from '../duc'
import { Quality } from './subModules/Quality'
import { Production } from './subModules/Production'
import { ProductionWeekly } from './subModules/ProductionWeekly'

const modulesMap = {
	quality: Quality,
	production: ProductionWeekly,
	'production-v1': Production,
}

const SubDashboard = () => {
	const location = useSelector(MillDuc.selectors.location)
	const { payload = {} } = location
	const activeModule = payload.rootModule

	const Component = modulesMap[activeModule] || NotFoundBlock
	// if we are here, we have a submodule, route it to the module

	return <Component />
}

export { SubDashboard }
