import React, { useState, useRef, useEffect } from 'react'
import ReactMapGL, {
	// WebMercatorViewport,
	Marker,
	Popup,
	NavigationControl,
	// FullscreenControl,
	// ScaleControl,
	// GeolocateControl,
	// Source,
	// Layer,
} from 'react-map-gl'
import WebMercatorViewport from 'viewport-mercator-project'
import * as turf from '@turf/turf'
import distance from '@turf/distance'
import { getIn } from 'timm'
import { Box } from '../../utils/Box'
import { Label, CircleBox } from '../Typography'
import theme from '../../utils/base-theme'
import verifiedIcon from '../../icons/verified.svg'
import NotVerifiedIcon from '../../icons/not_verified.svg'
import { Icon, IconWrapper } from '../../icons/components/Icon'
import { getMapBoxAccessToken } from '../../config'
import { currentPlatform } from '../../utils/config'
import './styles.css'
import { getRandomNumber } from '../../utils/helpers'
import 'mapbox-gl'

const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`

const pinStyle = {
	cursor: 'pointer',
	position: 'absolute',
	left: 0,
	top: 0,
	zIndex: -1,
}

const pinBorderColor = {
	'palmoil-mill': '#D38900',
	'palmoil-smallholder': '#748E37',
	'palmoil-plantation': '#ffffff',
	'palmoil-ffbDealer': '#ffffff',
	'kerneloil-mill': '#ffffff',
	'palmoil-kernelMill': '#ffffff',
	'palmoil-refinery': '#ffffff',
	'palmoil-oleochemicals': '#ffffff',
	'palmoil-storage': '#0A6A48',
	'palmoil-trader': '#3E92DF',
	'palmoil-endManufacturer': '#ffffff',
}

const pinBorderColorForRice = {
	'rice-farmer': '#748E37',
	'rice-mill': '#D38900',
	'rice-serviceprovider': '#ffffff',
	'rice-dealer': '#ffffff',
	'rice-packingcompany': '#0A6A48',
	'rice-packer': '#ffffff',
	'rice-trader': '#3E92DF',
	'rice-wholesaler': '#ffffff',
	'rice-importer': '#ffffff',
	'rice-endbuyer': '#ffffff',
}

const pinColor = {
	'palmoil-mill': '#FFA602',
	'palmoil-smallholder': '#B2FF00',
	'palmoil-plantation': '#1B4239',
	'palmoil-ffbDealer': '#AD3B1B',
	'kerneloil-mill': '#393830',
	'palmoil-kernelMill': '#624515',
	'palmoil-oleochemicals': '#BA6826',
	'palmoil-refinery': '#BA6826',
	'palmoil-storage': '#66C3A2',
	'palmoil-trader': '#3E92DF',
	'palmoil-endManufacturer': '#233EC0',
}

const pinColorForRice = {
	'rice-farmer': '#B2FF00',
	'rice-mill': '#FFA602',
	'rice-serviceprovider': '#1B4239',
	'rice-dealer': '#AD3B1B',
	'rice-packingcompany': '#66C3A2',
	'rice-packer': '#BA6826',
	'rice-trader': '#3E92DF',
	'rice-wholesaler': '#BA6826',
	'rice-importer': '#624515',
	'rice-endbuyer': '#233EC0',
}

const Pins = ({ data, onHover, onClick }) => {
	const SIZE = 40

	return data.map((city, index) =>
		Number.isNaN(city.coordinates[0]) ||
		Number.isNaN(city.coordinates[1]) ||
		(city.coordinates[0] === 0 && city.coordinates[1] === 0) ? (
			<> </>
		) : (
			<div
			// onMouseEnter={() => onHover(city)}
			// onMouseLeave={() => onHover({})}
			>
				<Marker
					// eslint-disable-next-line react/no-array-index-key
					key={`marker-${index}`}
					longitude={city.coordinates[0]}
					latitude={city.coordinates[1]}
				>
					<svg
						height={SIZE}
						viewBox="0 0 24 24"
						fill={
							currentPlatform() === 'rice'
								? pinColorForRice[city.orgTypeID] || '#171fa9'
								: pinColor[city.orgTypeID] || '#171fa9'
						}
						strokeWidth={1}
						stroke={
							currentPlatform() === 'rice'
								? pinBorderColorForRice[city.orgTypeID]
								: pinBorderColor[city.orgTypeID]
						}
						style={{
							...pinStyle,
							transform: `translate(${-SIZE / 2}px,${-SIZE}px)`,
						}}
						onClick={() => {
							onClick(city)
							onHover(city)
						}}
					>
						<path d={ICON} />
					</svg>
				</Marker>
			</div>
		)
	)
}

// const dataLayer1 = {
// 	id: 'data',
// 	type: 'fill',
// 	paint: {
// 		'fill-color': {
// 			property: 'percentile',
// 			stops: [
// 				[0, '#3288bd'],
// 				[1, '#66c2a5'],
// 				[2, '#abdda4'],
// 				[3, '#e6f598'],
// 				[4, '#ffffbf'],
// 				[5, '#fee08b'],
// 				[6, '#fdae61'],
// 				[7, '#f46d43'],
// 				[8, '#d53e4f'],
// 			],
// 		},
// 		'fill-opacity': 0.8,
// 	},
// }

// const dataLayer = {
// 	id: 'earthquakes-heat',
// 	type: 'heatmap',
// 	source: 'earthquakes',
// 	maxzoom: 9,
// 	paint: {
// 		// Increase the heatmap weight based on frequency and property magnitude
// 		'heatmap-weight': [
// 			'interpolate',
// 			['linear'],
// 			['get', 'mag'],
// 			0,
// 			0,
// 			6,
// 			1,
// 		],
// 		// Increase the heatmap color weight weight by zoom level
// 		// heatmap-intensity is a multiplier on top of heatmap-weight
// 		'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 1, 9, 3],
// 		// Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
// 		// Begin color ramp at 0-stop with a 0-transparancy color
// 		// to create a blur-like effect.
// 		'heatmap-color': [
// 			'interpolate',
// 			['linear'],
// 			['heatmap-density'],
// 			0,
// 			'rgba(33,102,172,0)',
// 			0.2,
// 			'rgb(103,169,207)',
// 			0.4,
// 			'rgb(209,229,240)',
// 			0.6,
// 			'rgb(253,219,199)',
// 			0.8,
// 			'rgb(239,138,98)',
// 			1,
// 			'rgb(178,24,43)',
// 		],
// 		// Adjust the heatmap radius by zoom level
// 		'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 2, 9, 20],
// 		// Transition from heatmap to circle layer by zoom level
// 		'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 7, 1, 9, 0],
// 	},
// }

const getDistance = (coord1, coord2) => {
	const [long1, lat1] = coord1
	const [long2, lat2] = coord2

	// Haversine formula for distance between two coordinates
	const R = 6371 // Radius of the Earth in kilometers
	const dLat = (lat2 - lat1) * (Math.PI / 180)
	const dLong = (long2 - long1) * (Math.PI / 180)

	const a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.cos(lat1 * (Math.PI / 180)) *
			Math.cos(lat2 * (Math.PI / 180)) *
			Math.sin(dLong / 2) *
			Math.sin(dLong / 2)

	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
	const distance = R * c

	return distance // in kilometers
}

const findClusters = (coordinates, threshold = 100) => {
	// Threshold in kilometers
	const clusters = []

	if (coordinates?.length > 0) {
		coordinates.forEach(coord => {
			let foundCluster = false

			// Check each cluster to see if this coordinate belongs to any of them
			for (let cluster of clusters) {
				for (let clusteredCoord of cluster) {
					if (getDistance(coord, clusteredCoord) <= threshold) {
						cluster.push(coord)
						foundCluster = true
						break
					}
				}
				if (foundCluster) break
			}

			// If no cluster was found, create a new one
			if (!foundCluster) {
				clusters.push([coord])
			}
		})
	}
	// Find the largest cluster
	let largestCluster =
		clusters?.length > 0
			? clusters.reduce((maxCluster, cluster) => {
					return cluster.length > maxCluster.length
						? cluster
						: maxCluster
			  }, [])
			: []

	let totalLongitude = 0
	let totalLatitude = 0

	if (largestCluster?.length > 0) {
		largestCluster.forEach(coord => {
			totalLongitude += coord[0]
			totalLatitude += coord[1]
		})
	}

	const avgLongitude = totalLongitude / largestCluster.length
	const avgLatitude = totalLatitude / largestCluster.length

	const zoomedCoordinates = [avgLongitude, avgLatitude]

	return zoomedCoordinates
}

const TraceMaps = ({
	coordinatesets = [],
	onClick,
	eudrDetails,
	treeData,
	noOrgType,
	onShowDeforestation,
	t,
}) => {
	const coordinates = []
	const canShowDeforestation = ['palmoil-smallholder', 'palmoil-plantation']
	coordinatesets.forEach(coordinate => {
		if (!Number.isNaN(coordinate.coordinates[0])) {
			coordinates.push(coordinate.coordinates)
		}
	})
	const dimensions = {
		// eslint-disable-next-line no-restricted-globals
		width: screen.width,
		// eslint-disable-next-line no-restricted-globals
		height: screen.height,
	}
	const _viewport = new WebMercatorViewport(dimensions)
	const { longitude, latitude, zoom } = _viewport.fitBounds(coordinates, {
		padding: 20,
	})
	const [clickMarker, setClickMarker] = useState({})
	// const [layerData, setLayerData] = useState({})
	const cluster = findClusters(coordinates) // find the avg coordinates of most surrounded cluster
	const [viewport, setViewport] = useState({
		...dimensions,
		longitude: cluster?.length > 0 ? cluster[0] : '',
		latitude: cluster?.length > 0 ? cluster[1] : '',
		zoom: zoom > 5 ? 6 : zoom + 1, // zoom limited to 6
	})

	const checkEmpty =
		Object.keys(clickMarker).length === 0 &&
		clickMarker.constructor === Object

	const mapRef = useRef(null)

	const addMapLayer = (map, firstCoordinateData, secondCoordinateData) => {
		let firstCoordinate = []
		let secondCoordinate = []
		if (coordinatesets.length > 0) {
			firstCoordinate = coordinatesets.find(
				set => set.orgID === firstCoordinateData.id
			)
			secondCoordinate = coordinatesets.find(
				set => set.orgID === secondCoordinateData.id
			)
		}
		if (
			firstCoordinate.coordinates &&
			!(
				firstCoordinate.coordinates[0] === 0 &&
				firstCoordinate.coordinates[1] === 0
			) &&
			secondCoordinate.coordinates &&
			!(
				secondCoordinate.coordinates[0] === 0 &&
				secondCoordinate.coordinates[1] === 0
			)
		) {
			const routeJson = {
				type: 'Feature',
				properties: {},
				geometry: {
					type: 'LineString',
					coordinates: [
						firstCoordinate.coordinates,
						secondCoordinate.coordinates,
					],
				},
			}
			map.addLayer({
				id: `route-${firstCoordinateData.id}-${secondCoordinateData.id}`,
				type: 'line',
				source: {
					type: 'geojson',
					data: routeJson,
				},
				layout: {
					'line-cap': 'round',
					'line-join': 'round',
				},
				paint: {
					'line-width': 2,
					'line-color': '#FFFFFF',
					'line-dasharray': [3, 3],
				},
			})
			map.addLayer({
				id: `arrowLayer-${firstCoordinateData.id}-${secondCoordinateData.id}`,
				type: 'symbol',
				source: {
					type: 'geojson',
					data: routeJson,
				},
				layout: {
					'icon-size': 1,
					'symbol-spacing': 2,
					'symbol-placement': 'line',
					'icon-image': 'arrowIcon',
				},
			})
			map.addLayer({
				id: `distance-${firstCoordinateData.id}-${secondCoordinateData.id}`,
				type: 'symbol',
				source: { type: 'geojson', data: routeJson },
				layout: {
					'text-field': `${turf
						.length(routeJson)
						.toLocaleString()}km`,
					'text-size': 12,
					'text-offset': [0, 1],
					'text-justify': 'center',
					'symbol-placement': 'line',
					'text-letter-spacing': 0.05,
				},
				paint: {
					'text-color': '#FFFFFF',
				},
			})
		}
	}

	const addLayer = (map, id, fillColor = '#90EE90', lineColor = 'yellow') => {
		// Add a new layer to visualize the polygon.
		map.addLayer({
			id,
			type: 'fill',
			source: id, // reference the data source
			layout: {},
			paint: {
				'fill-color': fillColor, // color fill
				'fill-opacity': 0.5,
			},
		})
		// Add a outline around the polygon.
		map.addLayer({
			id: `${id}outline`,
			type: 'line',
			source: id,
			layout: {},
			paint: {
				'line-width': 1,
				'line-color': lineColor,
			},
		})
	}

	const addDeforestationLayer = (map, tree) => {
		const deforestationAlertData =
			getIn(tree, ['deforestationAlertData']) || []

		if (deforestationAlertData.length > 0) {
			const id = `polygon-${getRandomNumber()}`
			const eventGeometry = deforestationAlertData.map(item => {
				const features =
					getIn(item, ['meta', 'geometry', 'features', 0]) || {}

				return features
			})

			map.addSource(id, {
				type: 'geojson',
				data: {
					type: 'FeatureCollection',
					features: eventGeometry,
				},
			})

			addLayer(map, id, '#D9A679', 'black')
		}
	}

	const addPolygon = (map, tree) => {
		if (tree && tree.shape && Object.keys(tree.shape).length > 0) {
			const id = `polygon-${getRandomNumber()}`
			const { geoType, shape } = tree

			const getCircleData = () => {
				const options = {
					steps: 64,
					units: 'kilometers',
				}
				let radius = 0
				shape.coordinates.forEach(item => {
					const coorDistance = distance(shape.center, item)
					if (coorDistance > radius) {
						radius = coorDistance
					}
				})

				return turf.circle(shape.center, radius, options)
			}

			if (geoType === 'shapefile') {
				map.addLayer({
					id,
					type: 'fill',
					source: {
						type: 'vector',
						url: `mapbox://${shape.tilesetId}`, //  Mapbox tileset Map ID
					},
					'source-layer': shape.tilesetName, // name of tilesets
					paint: {
						'fill-color': '#90EE90',
						'fill-opacity': 0.5,
						'fill-outline-color': 'yellow',
					},
				})
			} else if (
				geoType === 'coordinates' &&
				(tree.orgTypeID === 'palmoil-smallholder' ||
					tree.orgTypeID === 'rice-farmer')
			) {
				map.addSource(id, {
					type: 'geojson',
					data: getCircleData(),
				})

				addLayer(map, id)
			} else if (geoType === 'coordinates') {
				map.addSource(id, {
					type: 'geojson',
					data: {
						type: 'FeatureCollection',
						features: [
							{
								type: 'Feature',
								properties: {},
								geometry: {
									type: 'Polygon',
									coordinates: [shape.coordinates],
								},
							},
						],
					},
				})

				addLayer(map, id)
			}
		}
	}

	const getChildren = (map, tree) => {
		const parent = tree
		addPolygon(map, tree)
		addDeforestationLayer(map, tree)
		if (tree.children && tree.children.length > 0) {
			tree.children.forEach(child => {
				addMapLayer(map, child, parent)
				getChildren(map, child)
			})
		}
	}

	const onMapLoad = React.useCallback(() => {
		const map = mapRef.current.getMap()
		const img = new Image(15, 15)
		img.onload = () => map.addImage('arrowIcon', img)
		img.src =
			"data:image/svg+xml,%3Csvg viewBox='0 0 1024 1024' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M529.6128 512L239.9232 222.4128 384.7168 77.5168 819.2 512 384.7168 946.4832 239.9232 801.5872z' p-id='9085' fill='%23FFFFFF'%3E%3C/path%3E%3C/svg%3E"
		if (treeData && treeData.length > 0) {
			treeData.forEach(tree => {
				getChildren(map, tree)
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [treeData])

	const getStatus = (certType, certificate) => {
		return certType.find(item => item.ctype === certificate.key)
			? certType.find(item => item.ctype === certificate.key).state
			: ''
	}

	const getCertUrl = (certType, certificate) => {
		return certType.find(item => item.ctype === certificate.key)
			? certType.find(item => item.ctype === certificate.key).certURL
			: ''
	}

	const getCertsList = certslist => {
		const listArray = []
		certslist.certificateTypes.forEach(certificate => {
			if (getStatus(certslist.certType, certificate) === 'Active') {
				listArray.push(
					<>
						<Label
							color={theme.color.themeGreen8}
							small
							style={{
								paddingLeft: 16,
								textTransform: 'capitalize',
								cursor:
									certificate.label !== 'NONE'
										? 'pointer'
										: 'default',
							}}
							onClick={() => {
								window.open(
									getCertUrl(certslist.certType, certificate),
									'_blank'
								)
							}}
						>
							{certificate.label}
						</Label>
					</>
				)
			}
		})

		return listArray
	}

	const getIcon = (item, clickMarker) => {
		let icon = clickMarker.eudrComplianceData[item]
			? verifiedIcon
			: NotVerifiedIcon
		if (item === 'deforestationAlert') {
			icon = clickMarker.eudrComplianceData[item]
				? NotVerifiedIcon
				: verifiedIcon
		}

		return icon
	}

	const getIconColor = (item, clickMarker) => {
		let iconColor = clickMarker.eudrComplianceData[item]
			? theme.color.themeGreen4
			: '#D05858'
		if (item === 'deforestationAlert') {
			iconColor = clickMarker.eudrComplianceData[item]
				? '#D05858'
				: theme.color.themeGreen4
		}

		return iconColor
	}

	const getEudrComplianceList = (clickMarker, eudrDetails) => {
		const detail = []
		const canShowDeforestation = [
			'palmoil-smallholder',
			'palmoil-plantation',
		]
		const showField = canShowDeforestation.includes(clickMarker.orgTypeID)

		eudrDetails.forEach(ele => {
			if (
				!(
					ele.id === 'deforestationAlert' || ele.id === 'geoJsonFlag'
				) ||
				showField
			) {
				detail.push(
					<Label
						color={theme.color.themeGreen7}
						small
						style={{
							paddingLeft: 16,
							textTransform: 'capitalize',
							borderLeft: '3px solid #e07616',
						}}
					>
						<div style={{ display: 'flex' }}>
							<div style={{ width: 200 }}>{ele.label}</div>
							<div
								style={{
									display: 'flex',
									maxWidth: '215px',
									flexWrap: 'wrap',
								}}
							>
								{
									<div>
										{ele.id === 'deforestationAlert' ? (
											<div
												style={{
													marginLeft: '8px',
													cursor:
														clickMarker?.deforestationData &&
														clickMarker
															?.deforestationData
															.length > 0
															? 'pointer'
															: 'default',
												}}
												onClick={() => {
													if (
														clickMarker?.deforestationData &&
														clickMarker
															?.deforestationData
															.length > 0
													) {
														onShowDeforestation(
															clickMarker.orgID
														)
													}
												}}
												title={t(
													'deforestationAlert.showDeforestationAlerts'
												)}
												role="button"
												tabIndex={0}
												onKeyDown={console.log('')}
											>
												{clickMarker?.deforestationData &&
												clickMarker?.deforestationData
													.length > 0 ? (
													<CircleBox
														color={
															theme.color.white
														}
														fontSize={10}
														fontWeight={600}
														width={17}
														height={17}
														backgroundColor={
															theme.color.pureRed
														}
													>
														{
															clickMarker
																?.deforestationData
																?.length
														}
													</CircleBox>
												) : (
													<CircleBox
														color={
															theme.color.white
														}
														fontSize={10}
														fontWeight={600}
														width={17}
														height={17}
														backgroundColor={
															theme.color
																.iconGreen
														}
													>
														0
													</CircleBox>
												)}
											</div>
										) : (
											<IconWrapper
												size={18}
												color={getIconColor(
													ele?.id,
													clickMarker
												)}
												style={{
													marginTop: '2px',
													marginLeft: '8px',
													marginBottom: '-3px',
												}}
											>
												<Icon
													glyph={getIcon(
														ele?.id,
														clickMarker
													)}
												/>
											</IconWrapper>
										)}
									</div>
								}
							</div>
						</div>
					</Label>
				)
			}
		})

		return detail
	}

	/** const addLines = useCallback(() => {
		console.log('hello')
		console.log(coordinatesets)
	}, [coordinatesets])
	*/

	// useEffect(() => {
	// 	async function getData() {
	// 		await setLayerData(_data)
	// 	}
	// 	getData()
	// }, [])
	// const addLines = useCallback(() => {
	// 	const map = mapRef.current.getMap()
	// 	coordinatesets.forEach(coordinates => {
	// 		map.addLayer({
	// 			id: 'route',
	// 			type: 'line',
	// 			source: {
	// 				type: 'geojson',
	// 				data: {
	// 					type: 'Feature',
	// 					properties: {},
	// 					geometry: {
	// 						type: 'LineString',
	// 						coordinates,
	// 					},
	// 				},
	// 			},
	// 			layout: {
	// 				'line-join': 'round',
	// 				'line-cap': 'round',
	// 			},
	// 			paint: {
	// 				'line-color': '#263b97',
	// 				'line-width': 5,
	// 			},
	// 		})
	// 	})
	// }, [coordinatesets])

	const uniqueOrgTypes =
		coordinatesets.length > 0
			? [...new Set(coordinatesets.map(item => item.orgTypeID))]
			: []

	const getCoordinateType = item => {
		return coordinatesets.find(coordinate => coordinate.orgTypeID === item)
			.orgType
	}

	const myRef = useRef(null)
	const executeScroll = () => myRef.current.scrollIntoView()

	useEffect(() => {
		executeScroll()
	}, [])

	return (
		<div ref={myRef} style={{ display: 'block' }}>
			{!noOrgType && (
				<Box style={{ display: 'block', padding: '0 0 30px 0' }}>
					{uniqueOrgTypes.map(item => {
						return (
							<div
								key={item}
								style={{
									marginTop: 10,
									marginRight: 10,
									alignItems: 'center',
									display: 'inline-flex',
								}}
							>
								<div
									style={{
										width: 30,
										height: 30,
										marginRight: 3,
										display: 'inline-block',
										background:
											currentPlatform() === 'rice'
												? pinColorForRice[item]
												: pinColor[item],
									}}
								/>
								{getCoordinateType(item)
									? getCoordinateType(item)
									: 'Small Holder'}
							</div>
						)
					})}
				</Box>
			)}
			<ReactMapGL
				width="100vw"
				height="82vh"
				ref={mapRef}
				onLoad={onMapLoad}
				// https://docs.mapbox.com/mapbox-gl-js/api/map/#map-parameters
				mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
				mapboxApiAccessToken={getMapBoxAccessToken()}
				{...viewport}
				onViewportChange={setViewport}
			>
				<div
					style={{
						width: '30px',
					}}
				>
					<NavigationControl
						showCompass={false}
						position="top-left"
					/>
				</div>
				{/* <Source type="geojson" data={_dataa}>
				<Layer {...dataLayer1} />
			</Source>
			<Source type="geojson" data={layerData}>
				<Layer {...dataLayer} />
			</Source> */}
				{/* <div
					style={{
						width: '30px',
					}}
				>
					<GeolocateControl />
					<FullscreenControl />
					<NavigationControl />
				</div> */}

				<Pins
					data={coordinatesets}
					onHover={data => {
						if (data.orgID && data.orgID === clickMarker.orgID) {
							setClickMarker({})
						} else {
							setClickMarker(data)
						}
					}}
					onClick={data => onClick(data)}
				/>

				{!checkEmpty && (
					<Popup
						key={clickMarker.orgID}
						tipSize={10}
						anchor="left"
						longitude={clickMarker.coordinates[0]}
						latitude={clickMarker.coordinates[1]}
						color="red"
						// closeOnClick={false}
						// onClose={() => setClickMarker({})}
					>
						<Box
							style={{
								maxWidth: 300,
							}}
						>
							<Label
								color="#fff"
								small
								style={{
									borderLeft: '3px solid #e07616',
									paddingLeft: 16,
								}}
							>
								{clickMarker.name}
							</Label>
							{!noOrgType && clickMarker.orgType && (
								<Label
									color={theme.color.grey3}
									small
									style={{
										fontSize: '12px',
										paddingLeft: 20,
										textTransform: 'capitalize',
										borderLeft: '3px solid #e07616',
									}}
								>
									{clickMarker.orgType}
								</Label>
							)}
							<Label
								color={theme.color.themeGreen7}
								small
								style={{
									paddingLeft: 16,
									paddingBottom: 10,
									textTransform: 'capitalize',
									borderLeft: '3px solid #e07616',
								}}
							>
								<div style={{ display: 'flex' }}>
									<div>CERT :</div>
									<div
										style={{
											display: 'flex',
											maxWidth: '215px',
											flexWrap: 'wrap',
										}}
									>
										{getCertsList(clickMarker)}
									</div>
								</div>
							</Label>
							{getEudrComplianceList(clickMarker, eudrDetails)}

							{clickMarker.universalMillID && (
								<Label
									color="#fff"
									small
									style={{
										paddingLeft: 16,
										textTransform: 'capitalize',
										borderLeft: '3px solid #e07616',
									}}
								>
									{`Universal Mill ID - ${clickMarker.universalMillID}`}
								</Label>
							)}

							{clickMarker.deforestationData.length > 0 && (
								<div>
									<br />
									<u>
										<Label
											color="#fff"
											small
											style={{
												paddingLeft: 16,
												display: 'block',
												textTransform: 'capitalize',
												textDecoration: 'underline',
											}}
										>
											Deforestation Data
										</Label>
									</u>
									{clickMarker.deforestationData.map(
										deforestationData => [
											<br />,
											<Label
												color="#fff"
												small
												key={
													deforestationData.universalID
												}
												style={{
													paddingLeft: 16,
													display: 'block',
													borderLeft:
														'3px solid #e07616',
													textTransform: 'capitalize',
												}}
											>
												{`${deforestationData.startPeriod ||
													''} - ${deforestationData.endPeriod ||
													''}: ${deforestationData.deforestArea ||
													''} ${
													deforestationData.deforestArea
														? 'ha'
														: ''
												}`}
											</Label>,
											<Label
												color="#fff"
												small
												key={`${deforestationData.universalID}-${deforestationData.deforestArea}`}
												style={{
													paddingLeft: 16,
													display: 'block',
													borderLeft:
														'3px solid #e07616',
													textTransform: 'capitalize',
												}}
											>
												{deforestationData.deforestScore
													? `Score - ${Number(
															deforestationData.deforestScore
													  ).toFixed(3)}`
													: ''}
											</Label>,
										]
									)}
									<br />
									<div>
										<Label
											small
											key="descartes"
											color="#1589FF"
											style={{
												paddingLeft: 16,
												cursor: 'inherit',
												textTransform: 'capitalize',
												borderLeft: '3px solid #e07616',
											}}
										>
											<a
												target="_blank"
												rel="noopener noreferrer"
												href="https://blog.descarteslabs.com/calculating-mill-level-deforestation-carbon-risk-scores-across-the-palm-supply-chain"
											>
												Data provided by Descartes Labs,
												USA
											</a>
										</Label>
									</div>
								</div>
							)}
						</Box>
					</Popup>
				)}
			</ReactMapGL>
		</div>
	)
}

TraceMaps.defaultProps = {
	data: [],
	onClick: () => {},
}

export { TraceMaps }
