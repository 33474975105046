import LogHelper from 'core-app/utils/logger'
import { StorageCompanyDuc } from 'core-app/modules/StorageCompany/duc'
import { getInsightsEndPoint, getCoreEndPoint } from 'core-app/config'
import { AppDuc } from 'core-app/modules/App/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import request from 'core-app/utils/request'
import { call, all, takeLatest, put, select } from 'redux-saga/effects'
import { MainRouteDuc } from 'core-app/routes/duc'
import { Toast } from 'ui-lib/components/Toast'
import querySerializer from 'query-string'
import { omit, merge, getIn } from 'timm'
import {
	CallWithRefreshCheck,
	fetchOrgDetailsCount,
	getOrgIDFromLoggedUser,
} from 'core-app/modules/Auth/AuthSaga'
import {
	isEmptyObject,
	transformDataForBarChart,
	transformDataforPieChart,
} from 'core-app/utils/helpers'
import {
	responseDocsMapper,
	transformSortStringsToBEQueries,
	transformFilterStringsToBEQueries,
	extractFilterQueries,
	extractSortQueries,
} from 'core-app/shared/helpers'
import { TRACE_GROUP_STATUS } from './config'

const logger = LogHelper('client:StorageCompanySaga')

function* fetchInsights() {
	try {
		yield put(
			AppDuc.creators.showGlobalLoader('fetch-storageCompany-insights')
		)
		yield fetchOrgDetailsCount()
		const requestUrl = `${getInsightsEndPoint()}dashboard/oil-storage/insights`
		const data = yield CallWithRefreshCheck(requestUrl)
		const userInsight = getIn(data, ['data']) || {}

		const bestSuppliers = yield transformDataforPieChart(
			userInsight.bestSuppliers,
			'best-suppliers'
		)

		const storageStatus = yield transformDataForBarChart(
			userInsight.storageStatus,
			'storage-status'
		)

		const certifiedSupply = yield transformDataForBarChart(
			userInsight.certifiedSupply,
			'certified-suppliers'
		)

		const supplierCategory = yield transformDataForBarChart(
			userInsight.supplierCategory,
			'supplier-categories'
		)

		const finaltransFormedInsight = {
			...userInsight,
			bestSuppliers,
			storageStatus,
			certifiedSupply,
			supplierCategory,
		}
		yield put(
			StorageCompanyDuc.creators.fetchInsightsSuccess(
				finaltransFormedInsight
			)
		)
	} catch (err) {
		const { message } = err
		logger.log(err)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(
			AppDuc.creators.hideGlobalLoader('fetch-storageCompany-insights')
		)
	}
}

const PAGINATION_LIMIT = 20

function* fetchDocumentListing(action) {
	try {
		const { submodule, locationState = {}, skipGlobalLoader } = action
		if (!skipGlobalLoader)
			yield put(AppDuc.creators.showGlobalLoader('document-listing'))
		const { type, payload, query } = locationState
		const existingQueryFromUrl = query || {}
		let frontendTargetQuery = {} // this would appear in search bar
		let backendTargetQuery = {} // this would go to backend api call
		yield put(StorageCompanyDuc.creators.fetchDocumentLoading(true))

		const orgID = yield getOrgIDFromLoggedUser()
		const initiatingParty = yield select(
			AuthDuc.selectors.getCurrentOrganization
		)
		const { id } = initiatingParty
		const paginationQuery = {
			activeIndex: existingQueryFromUrl.activeIndex
				? existingQueryFromUrl.activeIndex
				: 0,
			limit: Math.min(
				existingQueryFromUrl.limit || PAGINATION_LIMIT,
				PAGINATION_LIMIT
			),
			nextIndex: existingQueryFromUrl.nextIndex,
		}
		// prepare backend query based on the pagination factors
		if (paginationQuery.limit) {
			backendTargetQuery.limit = paginationQuery.limit
		}
		if (paginationQuery.activeIndex > 0 && paginationQuery.nextIndex) {
			backendTargetQuery.startID = paginationQuery.nextIndex
		}
		const searchQuery = existingQueryFromUrl.q || ''
		// prepare the filter query
		const filterQueries =
			omit(existingQueryFromUrl, [
				'sort',
				'q',
				'activeIndex',
				'limit',
				'nextIndex',
			]) || {}
		if (!isEmptyObject(filterQueries)) {
			// form the backend queries form the object
			backendTargetQuery = merge(
				backendTargetQuery,
				transformFilterStringsToBEQueries(filterQueries)
			)
		}
		const sortQuery = existingQueryFromUrl.sort || []
		const _sortKeys = Array.isArray(sortQuery) ? sortQuery : [sortQuery]
		let _sortQuery = _sortKeys && transformSortStringsToBEQueries(_sortKeys)
		_sortQuery = getIn(_sortQuery, [submodule]) || ''
		const __query = {
			...backendTargetQuery,
		}
		const url =
			submodule === 'map-incoming'
				? `${getCoreEndPoint()}entities/availableinventory/list?type=delivery-order&receivingPartyID=${orgID}&status=eq(state-%3Edelivered)&startID=`
				: `${getCoreEndPoint()}clients/organizations/${id}/tracegroups?status=storage`

		const requestUrl = `${url}
		&${querySerializer.stringify({
			...__query,
			...{ sort: _sortQuery },
			...(searchQuery && { search: searchQuery }),
		})}`
		const origResponse = yield CallWithRefreshCheck(requestUrl)
		const response = getIn(origResponse, ['data']) || {}
		const serverPaginationQuery = {
			activeIndex: paginationQuery.activeIndex // &&
				? // We should have this check so the sequence of pagination is right.
				  // getIn(response, ['startID']) === backendTargetQuery.startID
				  paginationQuery.activeIndex
				: 0,
			limit: Math.min(getIn(response, ['pageSize']) || PAGINATION_LIMIT),
			nextIndex: getIn(response, ['lastID']),
		}
		// extract pagination queries from response
		yield put(
			StorageCompanyDuc.creators.setPaginationEntries(
				serverPaginationQuery.activeIndex,
				getIn(response, ['pageSize']),
				getIn(response, ['total']),
				getIn(response, ['prevStartID']),
				getIn(response, ['nextStartID'])
			)
		)
		// extract filter queries
		const { queryTree, stateTree } = extractFilterQueries(response)
		yield put(StorageCompanyDuc.creators.setActiveListingFilters(stateTree))
		frontendTargetQuery = merge(
			frontendTargetQuery,
			queryTree,
			serverPaginationQuery
		)
		yield put(
			StorageCompanyDuc.creators.fetchDocumentListingSuccess(response)
		)
		const sortQueriesFromBE = extractSortQueries(response, _sortKeys)
		if (sortQueriesFromBE.length) {
			frontendTargetQuery.sort = sortQueriesFromBE
		}
		if (!isEmptyObject(frontendTargetQuery)) {
			yield put(
				StorageCompanyDuc.creators.setActiveListingSorts(
					frontendTargetQuery
				)
			)
			// we have some query, lets update the url to reflect that.
			yield put(
				MainRouteDuc.creators.redirect(
					type,
					payload,
					frontendTargetQuery,
					{
						skipRouteThunk: true,
					}
				)
			)
		}
		yield put(StorageCompanyDuc.creators.fetchDocumentLoading(false))
	} catch (err) {
		logger.log(err)
	} finally {
		yield put(StorageCompanyDuc.creators.fetchDocumentLoading(false))
		yield put(AppDuc.creators.hideGlobalLoader('document-listing'))
	}
}

function* fetchStorageListing() {
	try {
		yield put(AuthDuc.creators.fetchAllProducts())
		yield put(AuthDuc.creators.fetchAllStorageTank())
		yield put(StorageCompanyDuc.creators.setLoadingStatus(true))
		const targetTypes = TRACE_GROUP_STATUS
		const orgID = yield getOrgIDFromLoggedUser()
		const initiatingParty = yield select(
			AuthDuc.selectors.getCurrentOrganization
		)
		const { id } = initiatingParty
		const callsPipeLine = targetTypes.map(_type => {
			if (_type === 'mapIncoming') {
				return CallWithRefreshCheck(
					`${getCoreEndPoint()}entities/availableinventory/list?type=delivery-order&receivingPartyID=${orgID}&status=eq(state-%3Edelivered)&startID=`
				)
			}

			return CallWithRefreshCheck(
				`${getCoreEndPoint()}clients/organizations/${id}/tracegroups?status=storage`
			)
		})
		const origResponse = yield all(callsPipeLine)
		const responses = responseDocsMapper(targetTypes, origResponse)

		const storageUnitUrl = `${getCoreEndPoint()}assets/storageunits?type=neq(production-line)&state=active`
		const storageUnitsresponse = yield CallWithRefreshCheck(storageUnitUrl)
		const storageUnitList =
			getIn(storageUnitsresponse, ['data', 'list']) || []
		const storageTanks = storageUnitList.filter(
			storage => storage.type === 'tank'
		)
		yield put(StorageCompanyDuc.creators.setStorageTanks(storageTanks))
		yield put(
			StorageCompanyDuc.creators.fetchStorageListingSuccess(responses)
		)
		yield put(StorageCompanyDuc.creators.setLoadingStatus(false))
	} catch (err) {
		const { message } = err
		logger.log(err)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* assignTank(action) {
	try {
		const { values, traceID, successToast } = action
		const value = {
			storageUnits: values,
		}
		const requestUrl = `${getCoreEndPoint()}inventory/products/${traceID}/storage-units`

		const options = {
			method: 'PUT',
			body: JSON.stringify(value),
		}

		yield call(request, requestUrl, options)
		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)

		Toast({
			type: 'success',
			message: successToast,
			isMobile: isMobile || isTablet,
		})
		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.STORAGE_COMPANY$SUBROOT,
				{
					rootModule: 'assign-tank',
					action: 'view',
				},
				{
					traceID,
				}
			)
		)
	} catch (err) {
		const { message } = err
		logger.log(err)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* assignTankCreate() {
	try {
		yield put(AuthDuc.creators.fetchAllProducts())
		yield put(AuthDuc.creators.fetchAllStorageTank())

		yield put(StorageCompanyDuc.creators.setLoadingStatus(true))
		const locationState = yield select(StorageCompanyDuc.selectors.location)
		const { query = {} } = locationState

		const { parentRef } = query
		const requestUrl = `${getCoreEndPoint()}entities/${parentRef}/availableinventory`
		const { data = {} } = yield CallWithRefreshCheck(requestUrl)
		const productId =
			getIn(data, ['receiverTraceIDs', 0, 'productID']) || ''
		const { receiverTraceIDs } = data
		const availableQty = Number(
			receiverTraceIDs.length
				? receiverTraceIDs
						.reduce((accumulator, object) => {
							return accumulator + object.availableQty
						}, 0)
						.toString()
						.match(/^-?\d+(?:\.\d{0,3})?/)[0]
				: 0
		)
		const traceID = getIn(data, ['receiverTraceIDs', 0, 'traceID']) || ''

		const docRefData = {
			traceID,
			consignmentNumber: data.number,
			product: productId,
			quantity: availableQty,
		}
		yield put(
			StorageCompanyDuc.creators.assignTankCreateSuccess(docRefData)
		)
		yield put(StorageCompanyDuc.creators.setLoadingStatus(false))
	} catch (err) {
		const { message } = err
		logger.log(err)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* assignTankView() {
	try {
		yield put(StorageCompanyDuc.creators.flushData())

		yield put(AuthDuc.creators.fetchAllProducts())
		yield put(AuthDuc.creators.fetchAllStorageTank())
		const locationState = yield select(StorageCompanyDuc.selectors.location)
		const { query = {} } = locationState

		const { traceID } = query
		const requestUrl = `${getCoreEndPoint()}inventory/products/${traceID}`

		const { data } = yield CallWithRefreshCheck(requestUrl)
		const storageUnits = getIn(data, ['meta', 'storageUnits']) || []

		const assignedTank = storageUnits.map(storage => {
			return {
				storageUnit: storage.name,
				quantity: storage.quantity,
				createdAt: storage.date,
			}
		})
		const tankDetails = {
			productId: data.productID,
			entityNumber: data.meta.entityNumber,
			totalQty: data.totalQty,
			entityCreationData: data.createdAt,
			assignedTank,
		}
		yield put(StorageCompanyDuc.creators.assignTankViewSuccess(tankDetails))
		yield put(StorageCompanyDuc.creators.setLoadingStatus(false))
	} catch (err) {
		const { message } = err
		logger.log(err)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}
function* mapOutgoingView() {
	try {
		yield put(AuthDuc.creators.fetchAllProducts())
		yield put(AuthDuc.creators.fetchAllStorageTank())
		yield put(StorageCompanyDuc.creators.setLoadingStatus(true))
		const locationState = yield select(StorageCompanyDuc.selectors.location)
		const { query = {} } = locationState

		const { traceID } = query

		const requestUrl = `${getCoreEndPoint()}clients/organizations/_/tracegroups/${traceID}`

		const { data } = yield CallWithRefreshCheck(requestUrl)

		const traces = getIn(data, ['traces']) || []

		const outputTrace = traces.map(trace => {
			return {
				productID: trace.productID,
				quantity: trace.quantityUtilized,
				createdAt: trace.createdAt,
			}
		})
		const traceDetails = {
			batchReference: data.batchReference,
			outputTrace,
		}
		yield put(
			StorageCompanyDuc.creators.mapOutgoingViewSuccess(traceDetails)
		)
		yield put(StorageCompanyDuc.creators.setLoadingStatus(false))
	} catch (err) {
		const { message } = err
		logger.log(err)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* mapOutgoingCreate() {
	try {
		yield put(AuthDuc.creators.fetchAllProducts())
		yield put(AuthDuc.creators.fetchAllStorageTank())
	} catch (err) {
		const { message } = err
		logger.log(err)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* createMapOutput(action) {
	try {
		const { successToast, documents } = action
		const traces = documents.map(document => {
			return {
				productID: document.productID,
				quantityUtilized: document.quantity,
				meta: {
					storageUnits: [
						{
							storageUnitID: document.storageUnitID,
							quantity: document.quantity,
						},
					],
				},
			}
		})
		const values = {
			traceGroupStatus: 'storage',
			traces,
		}
		const initiatingParty = yield select(
			AuthDuc.selectors.getCurrentOrganization
		)
		const { id } = initiatingParty

		const requestUrl = `${getCoreEndPoint()}clients/organizations/${id}/products/-/plotinput`

		const options = {
			method: 'POST',
			body: JSON.stringify(values),
		}
		// eslint-disable-next-line no-unused-vars
		const { data } = yield call(request, requestUrl, options)
		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)

		Toast({
			type: 'success',
			message: successToast,
			isMobile: isMobile || isTablet,
		})
		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.STORAGE_COMPANY$SUBROOT,
				{
					rootModule: 'map-output',
					action: 'view',
				},
				{
					traceID: data.meta.traceGroup.id,
				}
			)
		)
	} catch (err) {
		const { message } = err
		logger.log(err)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

export default function* StorageCompanySaga() {
	try {
		yield all([
			takeLatest(
				StorageCompanyDuc.creators.fetchInsights().type,
				fetchInsights
			),
			takeLatest(
				StorageCompanyDuc.creators.fetchDocumentListing().type,
				fetchDocumentListing
			),
			takeLatest(
				StorageCompanyDuc.creators.fetchStorageListing().type,
				fetchStorageListing
			),
			takeLatest(
				StorageCompanyDuc.creators.assignTank().type,
				assignTank
			),
			takeLatest(
				StorageCompanyDuc.creators.assignTankView().type,
				assignTankView
			),
			takeLatest(
				StorageCompanyDuc.creators.assignTankCreate().type,
				assignTankCreate
			),
			takeLatest(
				StorageCompanyDuc.creators.mapOutgoingView().type,
				mapOutgoingView
			),
			takeLatest(
				StorageCompanyDuc.creators.mapOutgoingCreate().type,
				mapOutgoingCreate
			),
			takeLatest(
				StorageCompanyDuc.creators.createMapOutput().type,
				createMapOutput
			),
		])
	} catch (err) {
		logger.log(err)
	}
}
