import { all, takeLatest, put, call } from 'redux-saga/effects'
import LogHelper from 'core-app/utils/logger'
import { AppDuc } from 'core-app/modules/App/duc'
import { ProductsDuc } from 'core-app/modules/Products/duc'
import { MainRouteDuc } from 'core-app/routes/duc'
import request from 'core-app/utils/request'
import { Toast } from 'ui-lib/components/Toast'
import {
	getOrgIDFromLoggedUser,
	CallWithRefreshCheck,
} from 'core-app/modules/Auth/AuthSaga'
import { getIn } from 'timm'
import { getCoreEndPoint } from 'core-app/config'

const logger = LogHelper('client:ProductsSaga')

function* fetchProductsListing() {
	try {
		yield put(AppDuc.creators.showGlobalLoader('fetch-products-listing'))
		const requestUrl = `${getCoreEndPoint()}clients/organizations/products/stats/nopagination`
		const { data = {} } = yield CallWithRefreshCheck(requestUrl)
		yield put(ProductsDuc.creators.setProductsListing(data.list))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-products-listing'))
	}
}

function* viewProductDetails(action) {
	try {
		const { productID } = action
		yield put(AppDuc.creators.showGlobalLoader('view-product-details'))
		const orgID = yield getOrgIDFromLoggedUser()

		const requestUrl = `${getCoreEndPoint()}clients/organizations/${orgID}/products/${productID}`

		const { data = {} } = yield CallWithRefreshCheck(requestUrl)
		yield put(ProductsDuc.creators.viewProductDetailsSuccess(data))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('view-product-details'))
	}
}

function* addProduct(action) {
	try {
		const { productDetails, toastMessage } = action

		const payload = (productDetails || []).map(prod => {
			const { id, tradeAccess, productType } = prod

			return {
				product: {
					id,
				},
				meta: {
					tradeAccess,
					productType,
				},
			}
		})

		const orgID = yield getOrgIDFromLoggedUser()

		yield put(AppDuc.creators.showGlobalLoader('add-product-add'))

		const requestUrl = `${getCoreEndPoint()}clients/organizations/${orgID}/products`
		const options = {
			method: 'POST',
			body: JSON.stringify({ products: payload }),
		}

		yield call(request, requestUrl, options)

		yield Toast({
			type: 'success',
			message: toastMessage,
		})

		yield put(MainRouteDuc.creators.switchPage(MainRouteDuc.types.PRODUCTS))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('add-product-add'))
	}
}

function* editProduct(action) {
	try {
		const { details, prodID, toastMessage } = action

		yield put(AppDuc.creators.showGlobalLoader('edit-product-edit'))

		const orgID = yield getOrgIDFromLoggedUser()

		const requestUrl = `${getCoreEndPoint()}clients/organizations/${orgID}/products/${prodID}`

		const options = {
			method: 'PUT',
			body: JSON.stringify(details),
		}

		yield call(request, requestUrl, options)

		yield Toast({
			type: 'success',
			message: toastMessage,
		})

		yield put(MainRouteDuc.creators.switchPage(MainRouteDuc.types.PRODUCTS))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('edit-product-edit'))
	}
}

function* fetchProductionLine() {
	try {
		yield put(ProductsDuc.creators.setProductionLineLoading(true))
		yield put(AppDuc.creators.showGlobalLoader('fetch-production-line'))
		const requestUrl = `${getCoreEndPoint()}assets/productionlinelist?state=active`
		const { data = {} } = yield CallWithRefreshCheck(requestUrl)
		yield put(
			ProductsDuc.creators.setProductionLine(getIn(data, ['list']) || [])
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(ProductsDuc.creators.setProductionLineLoading(false))
		yield put(AppDuc.creators.hideGlobalLoader('fetch-production-line'))
	}
}

function* fetchProducts() {
	try {
		yield put(ProductsDuc.creators.setProductsLoading(true))
		yield put(AppDuc.creators.showGlobalLoader('fetch-products'))
		const requestUrl = `${getCoreEndPoint()}clients/organizations/products/nopagination`
		const { data = {} } = yield CallWithRefreshCheck(requestUrl)
		yield put(ProductsDuc.creators.setProducts(data?.list || []))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(ProductsDuc.creators.setProductsLoading(false))
		yield put(AppDuc.creators.hideGlobalLoader('fetch-products'))
	}
}

function* fetchIncomingProducts() {
	try {
		yield put(ProductsDuc.creators.setIncomingProductsLoading(true))
		yield put(AppDuc.creators.showGlobalLoader('fetch-incoming-products'))
		const requestUrl = `${getCoreEndPoint()}clients/organizations/products/nopagination?productType=incoming`
		const { data = {} } = yield CallWithRefreshCheck(requestUrl)
		yield put(ProductsDuc.creators.setIncomingProducts(data?.list || []))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(ProductsDuc.creators.setIncomingProductsLoading(false))
		yield put(AppDuc.creators.hideGlobalLoader('fetch-incoming-products'))
	}
}

function* fetchOutgoingProducts() {
	try {
		yield put(ProductsDuc.creators.setOutgoingProductsLoading(true))
		yield put(AppDuc.creators.showGlobalLoader('fetch-outgoing-products'))
		const requestUrl = `${getCoreEndPoint()}clients/organizations/products/nopagination?productType=outgoing`
		const { data = {} } = yield CallWithRefreshCheck(requestUrl)
		yield put(ProductsDuc.creators.setOutgoingProducts(data?.list || []))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(ProductsDuc.creators.setOutgoingProductsLoading(false))
		yield put(AppDuc.creators.hideGlobalLoader('fetch-outgoing-products'))
	}
}

function* fetchStorageUnits() {
	try {
		yield put(ProductsDuc.creators.setStorageUnitsLoading(true))
		yield put(AppDuc.creators.showGlobalLoader('fetch-storage-units'))
		const requestUrl = `${getCoreEndPoint()}assets/storageunits?state=active`
		const { data = {} } = yield CallWithRefreshCheck(requestUrl)
		yield put(ProductsDuc.creators.setStorageUnits(data?.list || []))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(ProductsDuc.creators.setStorageUnitsLoading(false))
		yield put(AppDuc.creators.hideGlobalLoader('fetch-storage-units'))
	}
}

function* fetchIncomingStorageUnits() {
	try {
		yield put(ProductsDuc.creators.setIncomingStorageUnitsLoading(true))
		yield put(
			AppDuc.creators.showGlobalLoader('fetch-incoming-storage-units')
		)
		const requestUrl = `${getCoreEndPoint()}assets/storageunits?state=active&meta=eq(inventoryType-%3Eincoming)`
		const { data = {} } = yield CallWithRefreshCheck(requestUrl)
		yield put(
			ProductsDuc.creators.setIncomingStorageUnits(data?.list || [])
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(ProductsDuc.creators.setIncomingStorageUnitsLoading(false))
		yield put(
			AppDuc.creators.hideGlobalLoader('fetch-incoming-storage-units')
		)
	}
}

function* fetchOutgoingStorageUnits(action) {
	const { storageType } = action
	try {
		yield put(ProductsDuc.creators.setOutgoingStorageUnitsLoading(true))
		yield put(
			AppDuc.creators.showGlobalLoader('fetch-outgoing-storage-units')
		)
		let requestUrl = `${getCoreEndPoint()}assets/storageunits?state=active&meta=eq(inventoryType-%3Eoutgoing)`
		if (storageType) requestUrl += `&type=${storageType}`
		const { data = {} } = yield CallWithRefreshCheck(requestUrl)
		yield put(
			ProductsDuc.creators.setOutgoingStorageUnits(data?.list || [])
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(ProductsDuc.creators.setOutgoingStorageUnitsLoading(false))
		yield put(
			AppDuc.creators.hideGlobalLoader('fetch-outgoing-storage-units')
		)
	}
}

function* fetchProductsListingWithPagination(action) {
	try {
		const { query = {}, limit = 100 } = action
		const { startID = '' } = query
		const orgID = yield getOrgIDFromLoggedUser()
		let requestUrl = `${getCoreEndPoint()}clients/organizations/${orgID}/products?limit=${limit}`
		if (startID) requestUrl += `&startID=${startID}`

		const { data = {} } = yield CallWithRefreshCheck(requestUrl)
		const pagination = {
			total: getIn(data, ['total']),
			nextIndex: getIn(data, ['nextStartID']),
		}
		yield put(
			ProductsDuc.creators.setProductsListWithPagination(data?.list)
		)
		yield put(ProductsDuc.creators.productsPaginationQuery(pagination))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

export default function* ProductsSaga() {
	try {
		yield all([
			takeLatest(
				ProductsDuc.creators.fetchProductsListing().type,
				fetchProductsListing
			),
			takeLatest(
				ProductsDuc.creators.viewProductDetails().type,
				viewProductDetails
			),
			takeLatest(ProductsDuc.creators.addProduct().type, addProduct),
			takeLatest(ProductsDuc.creators.editProduct().type, editProduct),
			takeLatest(
				ProductsDuc.creators.fetchProductionLine().type,
				fetchProductionLine
			),
			takeLatest(
				ProductsDuc.creators.fetchProducts().type,
				fetchProducts
			),
			takeLatest(
				ProductsDuc.creators.fetchIncomingProducts().type,
				fetchIncomingProducts
			),
			takeLatest(
				ProductsDuc.creators.fetchOutgoingProducts().type,
				fetchOutgoingProducts
			),
			takeLatest(
				ProductsDuc.creators.fetchStorageUnits().type,
				fetchStorageUnits
			),
			takeLatest(
				ProductsDuc.creators.fetchIncomingStorageUnits().type,
				fetchIncomingStorageUnits
			),
			takeLatest(
				ProductsDuc.creators.fetchOutgoingStorageUnits().type,
				fetchOutgoingStorageUnits
			),
			takeLatest(
				ProductsDuc.creators.fetchProductsListingWithPagination().type,
				fetchProductsListingWithPagination
			),
		])
	} catch (err) {
		logger.log(err)
	}
}
