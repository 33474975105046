import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { WeekHandlingComponent } from 'core-app/modules/Refinery/components/WeekHandlingComponent'
import { Title } from 'core-app/shared/components/Title'
import { MainRouteDuc } from 'core-app/routes/duc'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Button } from 'ui-lib/components/Button'
import Documents from 'ui-lib/icons/documents.svg'
import { Label } from 'ui-lib/components/Typography'
import { Box } from 'ui-lib/utils/Box'
import { SelectSearch } from 'ui-lib/components/Select'
import { Input } from 'ui-lib/components/Input'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import DeleteIcon from 'ui-lib/icons/deleteTrash.svg'
import moment from 'moment'
import { getRandomNumber } from 'ui-lib/utils/helpers'
import { TransparentBgSpinner } from 'ui-lib/components/Spinner/TransparentBgSpinner'
import { ProductsDuc } from 'core-app/modules/Products/duc'
import {
	getProductsList,
	getStorageUnitsList,
	getStorageCapacity,
} from 'core-app/utils/helpers'
import { MillDuc } from '../../duc'

const Inventory = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const outgoingInventory = useSelector(
		MillDuc.selectors.getOutgoingInventory
	)
	const outgoingProducts = useSelector(
		ProductsDuc.selectors.getOutgoingProducts
	)
	const outgoingStorageUnits = useSelector(
		ProductsDuc.selectors.getOutgoingStorageUnits
	)
	const millLoadingState = useSelector(MillDuc.selectors.getMillLoadingState)
	const productsLoadingState = useSelector(
		ProductsDuc.selectors.getProductsLoadingState
	)

	const currentDate = new Date()
	const initialDailyInventory = {
		monday: '',
		tuesday: '',
		wednesday: '',
		thursday: '',
		friday: '',
		saturday: '',
		sunday: '',
	}
	const initialData = {
		productID: '',
		storageID: '',
		dailyInventory: initialDailyInventory,
	}

	const initialOutgoingRows = Array(10)
		.fill()
		.map(() => initialData)

	const [weekNumber, setWeekNumber] = useState(0)
	const [outgoingID, setOutgoingID] = useState('')
	const [key, setKey] = useState(getRandomNumber())
	const [firstDayOfWeek, setFirstDayOfWeek] = useState('')
	const [lastDayOfWeek, setLastDayOfWeek] = useState('')
	const [outgoingRows, setOutgoingRows] = useState(initialOutgoingRows)
	const [currentOutgoingInventory, setCurrentOutgoingInventory] = useState({})

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.production'),
			name: 'production',
			isActive: true,
		},
		{
			label: t('production.inventory'),
			name: 'inventory',
			isActive: false,
		},
	]

	const getFilteredTanks = (productId, storageId, rows, storageUnits) => {
		const storageUnitsLists = getStorageUnitsList(storageUnits, true)
		const filtertedRows = rows.filter(
			item =>
				item.storageID !== '' &&
				item.storageID !== storageId &&
				item.productID === productId
		)
		const alreadySelectedStorageUnits =
			filtertedRows.length > 0
				? filtertedRows.map(row => {
						return row.storageID
				  })
				: []

		return storageUnitsLists.filter(
			item =>
				!alreadySelectedStorageUnits.includes(item.name) &&
				item.products.includes(productId)
		)
	}

	const daysOfWeek = [
		'monday',
		'tuesday',
		'wednesday',
		'thursday',
		'friday',
		'saturday',
		'sunday',
	]

	const daysOfWeekShort = [
		t('production.mon'),
		t('production.tue'),
		t('production.wed'),
		t('production.thur'),
		t('production.fri'),
		t('production.sat'),
		t('production.sun'),
	]

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'production') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.MILL$SUBROOT,
					{
						rootModule: 'production',
					}
				)
			)
		}
	}

	const handleOnChange = (currentFirstDayOfWeek, currentLastDayOfWeek) => {
		const weekNumber = moment(currentFirstDayOfWeek).week()
		const year = new Date(currentFirstDayOfWeek).getFullYear()
		setFirstDayOfWeek(currentFirstDayOfWeek)
		setLastDayOfWeek(currentLastDayOfWeek)
		setWeekNumber(moment(currentFirstDayOfWeek).week())
		setKey(getRandomNumber())
		setOutgoingRows(initialOutgoingRows)
		dispatch(MillDuc.creators.fetchOutgoingInventory(weekNumber, year))
	}

	const handleRowChange = (index, updatedRow) => {
		const updatedRows = [...outgoingRows]
		updatedRows[index] = updatedRow
		setOutgoingRows(updatedRows)
	}

	const handleDeleteRow = index => {
		const updatedRows = outgoingRows.filter((_, i) => i !== index)
		setOutgoingRows(updatedRows)
		setKey(getRandomNumber())
	}

	const getRows = (row, index) => {
		const filteredTanks = getFilteredTanks(
			row.productID,
			row.storageID,
			outgoingRows,
			outgoingStorageUnits
		)

		return (
			<Box row margin="8px 0" alignItems="center" key={`row-${index}`}>
				<Box width="20%" padding="0 5px">
					<SelectSearch
						hideError
						name="product"
						placeholder="Product"
						value={row.productID}
						key={`row-${index}-product`}
						options={getProductsList(outgoingProducts)}
						onChange={({ name }) =>
							handleRowChange(index, {
								...row,
								productID: name,
							})
						}
						returnOnlyValue
						labelKey="label"
						valueKey="name"
					/>
				</Box>
				<Box width="20%" padding="0 5px">
					<SelectSearch
						hideError
						name="tank"
						value={row.storageID}
						placeholder="Tank"
						disabled={!row.productID}
						key={`row-${index}-tank`}
						options={filteredTanks}
						onChange={({ name, capacity }) => {
							handleRowChange(index, {
								...row,
								storageID: name,
								capacity,
								dailyInventory: initialDailyInventory,
							})
							setKey(getRandomNumber())
						}}
						returnOnlyValue
						labelKey="label"
						valueKey="name"
					/>
				</Box>
				<Box width="60%" row>
					{daysOfWeek.map((day, daysIndex) => (
						<Box width="13.5%" key={day} center>
							<Input
								hideError
								type="number"
								disabled={
									!row.productID ||
									!row.storageID ||
									new Date(
										new Date(firstDayOfWeek).setDate(
											new Date(firstDayOfWeek).getDate() +
												daysIndex
										)
									).setHours(0, 0, 0, 0) >
										new Date(currentDate).setHours(
											0,
											0,
											0,
											0
										)
								}
								value={row.dailyInventory[day] || 0}
								key={`row-${index}-${day}`}
								name={`row--${index}-${day}`}
								onChange={e => {
									if (
										!row.capacity ||
										e.target.value <= row.capacity
									) {
										handleRowChange(index, {
											...row,
											dailyInventory: {
												...row.dailyInventory,
												[day]: e.target.value,
											},
										})
									} else {
										setKey(getRandomNumber())
									}
								}}
								width="90%"
							/>
						</Box>
					))}

					<Box
						row
						width="5.5%"
						style={{
							paddingTop: '8px',
						}}
						margin="0px 0px 0px 4px"
					>
						<IconWrapper
							hoverEffect
							width={30}
							height={30}
							onClick={() => {
								handleDeleteRow(index)
							}}
						>
							<Icon
								glyph={DeleteIcon}
								style={{
									cursor: 'pointer',
									width: 25,
									height: 25,
								}}
							/>
						</IconWrapper>
					</Box>
				</Box>
			</Box>
		)
	}

	useEffect(() => {
		if (
			JSON.stringify(currentOutgoingInventory) !==
			JSON.stringify(outgoingInventory)
		) {
			if (
				outgoingInventory &&
				outgoingInventory.weeklyInventory &&
				outgoingInventory.weeklyInventory.length > 0
			) {
				const currentOutgoingRows = outgoingInventory.weeklyInventory.map(
					item => {
						const { productID, storageID, dailyInventory } = item
						const capacity = getStorageCapacity(
							getStorageUnitsList(outgoingStorageUnits, true),
							storageID
						)
						const currentDailyInventory = {}
						daysOfWeek.forEach((item, index) => {
							if (dailyInventory[index]) {
								currentDailyInventory[item] =
									dailyInventory[index].quantity
							}
						})

						return {
							productID,
							storageID,
							capacity,
							dailyInventory: currentDailyInventory,
						}
					}
				)

				setOutgoingRows(currentOutgoingRows)
			} else {
				setOutgoingRows(initialOutgoingRows)
			}
			setKey(getRandomNumber())
			setOutgoingID(outgoingInventory?.id || '')
			setCurrentOutgoingInventory(outgoingInventory)
		}
	}, [
		currentOutgoingInventory,
		daysOfWeek,
		initialOutgoingRows,
		outgoingInventory,
		outgoingStorageUnits,
	])

	useEffect(() => {
		const first = currentDate.getDate() - currentDate.getDay() + 1
		setFirstDayOfWeek(new Date(new Date().setDate(first)))
		setWeekNumber(moment(new Date(new Date().setDate(first))).week())
		const last = first + 6
		setLastDayOfWeek(new Date(new Date().setDate(last)))

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Box key={key} padding={8} width="100%" height="100%">
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			{(millLoadingState || productsLoadingState) && (
				<TransparentBgSpinner />
			)}
			<Box row justifyContent="space-between" alignItems="baseline">
				<Box alignItems="flex-start">
					<Box row alignItems="center">
						<Title
							title={`${t('production.inventory')}`}
							icon={Documents}
						/>
					</Box>
				</Box>
			</Box>
			<Box>
				<WeekHandlingComponent
					weekNumber={weekNumber}
					firstDayOfWeek={firstDayOfWeek}
					lastDayOfWeek={lastDayOfWeek}
					currentDate={currentDate}
					onChange={handleOnChange}
				/>
			</Box>
			<>
				<Box
					row
					alignItems="center"
					justifyContent="space-between"
					style={{
						height: 40,
						marginTop: 20,
						backgroundColor: '#CBD2EF',
						color: 'rgb(63, 86, 196)',
						padding: 8,
						fontWeight: 'bold',
					}}
				>
					<Box
						width="20%"
						style={{
							textAlign: 'center',
						}}
					>
						{t('production.product')}
					</Box>
					<Box
						width="20%"
						style={{
							textAlign: 'center',
						}}
					>
						{t('production.tankOrStorageUnits')}
					</Box>
					<Box
						width="60%"
						style={{
							textAlign: 'center',
						}}
					>
						{t('production.levelInTons')}
					</Box>
				</Box>
				<Box row alignItems="center" style={{ marginTop: 6 }}>
					<Box width="20%" />
					<Box width="20%" />
					<Box row width="60%">
						{daysOfWeekShort.map(day => (
							<Box
								width="13.5%"
								key={day}
								style={{ textAlign: 'center' }}
							>
								<label htmlFor={day}>{day}</label>
							</Box>
						))}
						<Box width="5.5%" />
					</Box>
				</Box>
				{outgoingRows.map((row, index) => getRows(row, index))}
			</>
			<Box row justifyContent="space-between" margin="10px 0 0 0">
				<Box
					row
					alignItems="center"
					width="200px"
					onClick={() => {
						const newRows = [...outgoingRows]
						for (let i = 0; i < 5; i++) {
							newRows.push(initialData)
						}
						setOutgoingRows(newRows)
					}}
				>
					<div
						style={{
							width: 40,
							height: 40,
							borderRadius: '50%',
							backgroundColor: '#00DD00',
							color: '#fff',
							textAlign: 'center',
							lineHeight: '40px',
							fontSize: 32,
							marginRight: 12,
							cursor: 'pointer',
						}}
					>
						+
					</div>
					<Label color="inherit" bold small link as="a">
						{t('production.addMorePrimary')}
					</Label>
				</Box>
			</Box>
			<Box row style={{ padding: 2 }} justifyContent="flex-end">
				<Button
					label={t('common.save')}
					primary
					rounded
					extendStyles={{ width: 150 }}
					onClick={() => {
						const weeklyInventory = []
						const updatedRows = [...outgoingRows]
						updatedRows.forEach(item => {
							const {
								productID,
								storageID,
								dailyInventory,
							} = item
							if (productID && storageID) {
								const currentDailyInventory = daysOfWeek.map(
									(item, index) => {
										const date = new Date(firstDayOfWeek)
										date.setDate(date.getDate() + index)

										return {
											day:
												item.charAt(0).toUpperCase() +
												item.slice(1),
											quantity: dailyInventory[item]
												? parseFloat(
														dailyInventory[item]
												  )
												: 0,
											date: date.toISOString(),
										}
									}
								)
								weeklyInventory.push({
									index: weeklyInventory.length,
									productID,
									storageID,
									dailyInventory: currentDailyInventory,
								})
							}
						})
						const payload = {
							weekNumber,
							inventoryType: 'outgoing',
							year: firstDayOfWeek.getFullYear(),
							meta: {
								fromDate: firstDayOfWeek.toISOString(),
								toDate: lastDayOfWeek.toISOString(),
							},
							weeklyInventory,
						}
						if (outgoingID) {
							payload.id = outgoingID
						}
						dispatch(MillDuc.creators.updateInventory(payload))
					}}
				/>
			</Box>
		</Box>
	)
}

export { Inventory }
