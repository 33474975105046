import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from 'ui-lib/utils/Box'
import styled from 'styled-components'
import { MessagesModule } from 'ui-lib/components/MessagesModule'
import { useSelector, useDispatch } from 'react-redux'
import { getIn } from 'timm'
import { Grid } from 'grommet'
import { Button } from 'ui-lib/components/Button'
import { Modal } from 'ui-lib/components/Modal'
import { P } from 'ui-lib/components/Typography'
import theme from 'ui-lib/utils/base-theme'
import { MessagesDuc } from 'core-app/modules/Messages/duc'
import { AppDuc } from 'core-app/modules/App/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { MainRouteDuc } from 'core-app/routes/duc'
import backArrow from 'ui-lib/icons/arrow_back.svg'
import { MESSAGES_ORGANISATION_THRESHOLD } from 'core-app/utils/constants'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { getDateWithTimeByFormat } from 'core-app/utils/date'
import { ChatWindow } from 'core-app/modules/Messages/components/ChatWindow'
import { isIframeForTeamsApp } from 'ui-lib/utils/helpers'

const getLeftLength = () => {
	return isIframeForTeamsApp() ? '250px' : '500px'
}

const Header = styled.div`
	font-size: 24px;
	font-weight: 500;
	padding-left: 28px;
	color: ${theme.color.black3};
`

const TransactionIntermediate = styled.div``

const OrganisationCard = styled.div`
	overflow-y: auto;
`

const HeaderWrapper = styled.div`
	display: flex;
`

const TransactionTransition = styled.div`
	animation: ${props =>
		props.selectedEvent
			? ''
			: 'slideIn 0.25s forwards'}; //slideOut 0.05s backwards
	-moz-animation: ${props =>
		props.selectedEvent ? '' : 'slideIn 0.25s forwards'};
	-webkit-animation: ${props =>
		props.selectedEvent ? '' : 'slideIn 0.25s forwards'};
	left: ${props => (props.selectedEvent ? getLeftLength() : '0px')};
	position: ${props => (props.selectedEvent ? 'absolute' : 'relative')};
	z-index: 1;
`

const MessageTransition = styled.div`
	z-index: 2;
	left: ${isIframeForTeamsApp() ? '670px' : '700px'};
	width: ${props => (props.isIframeForTeamsApp ? '525px' : '650px')};
	position: unset;
	animation: slideInMsg 0.25s forwards;
	-moz-animation: slideInMsg 0.25s forwards;
	-webkit-animation: slideInMsg 0.25s forwards;
`

const Description = styled.div`
	font-size: 14px;
	padding-top: 9px;
	padding-left: 56px;
	padding-bottom: 38px;
	color: #6a6a6a;
`

const AggregatedMessages = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const [selectedOrganisation, setSelectedOrganisation] = useState(null)
	const [selectedEvent, setSelectedEvent] = useState(null)
	const [selectedTransaction, setSelectedTransaction] = useState(null)
	const [selectedTransactionkey, setSelectedTransactionKey] = useState(null)
	const [showSubList, setShowSubList] = useState(false)
	const [selectedSubMenu, setSelectedSubMenu] = useState(null)
	const [showNoData, setShowNoData] = useState(false)
	const [scrollPosition, setScrollPosition] = useState(650)
	const [totalTransactionMsgCount, setTotalTransactionMsgCount] = useState(0)
	const [totalOrgMsgCount, setTotalOrgMsgCount] = useState(0)
	const [eventListFull, setEventListFull] = useState(null)
	const [documentLink, setDocumentLink] = useState(null)
	const [currentDocId, setCurrentDocId] = useState('')
	const [allowAccess, setAllowAccess] = useState(true)
	const [showModal, setShowModal] = useState(false)
	const scrollIndex = 2

	const [
		organisationPaginatedListFull,
		setOrganisationPaginatedListFull,
	] = useState([])
	const transactionListFull = useSelector(
		MessagesDuc.selectors.getTransactionList
	)
	const organisationListFull = useSelector(
		MessagesDuc.selectors.getOrganisationList
	)
	const loggedInUser = useSelector(AuthDuc.selectors.getUserProfile)
	const { currentOrganization } = useSelector(AuthDuc.selectors.auth)
	const documentDetails = useSelector(
		MessagesDuc.selectors.getDocumentdetails
	)
	const { isMobile } = useSelector(AppDuc.selectors.detection)

	useEffect(() => {
		if (organisationListFull) {
			if (
				getIn(organisationListFull, ['data']) &&
				getIn(organisationListFull, ['data']).length > 0
			) {
				const currentList = []
				if (getIn(organisationListFull, ['data']).length > 20) {
					for (let i = 0; i < MESSAGES_ORGANISATION_THRESHOLD; i++) {
						currentList.push(
							getIn(organisationListFull, ['data'])[i]
						)
					}
					setOrganisationPaginatedListFull(currentList)
				} else {
					setOrganisationPaginatedListFull(
						getIn(organisationListFull, ['data'])
					)
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organisationListFull])

	useEffect(() => {
		if (
			selectedEvent &&
			getIn(selectedEvent, ['documentId']) &&
			(getIn(selectedEvent, ['docType']) === 'rfq' ||
				getIn(selectedEvent, ['docType']) === 'quote')
		) {
			dispatch(
				MessagesDuc.creators.viewDocumentFromMSG(
					getIn(selectedEvent, ['documentId']),
					getIn(selectedEvent, ['docType'])
				)
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedEvent])

	useEffect(() => {
		if (
			documentDetails &&
			loggedInUser &&
			(getIn(selectedEvent, ['docType']) === 'rfq' ||
				getIn(selectedEvent, ['docType']) === 'quote')
		) {
			const actor =
				getIn(documentDetails, ['id']) ===
				getIn(loggedInUser, ['organization', 'id'])
					? 'buyer'
					: 'seller'
			if (getIn(selectedEvent, ['docType']) === 'rfq') {
				setDocumentLink(
					`${
						window.location.origin
					}/marketPlace/${actor}/view-rfq/${getIn(selectedEvent, [
						'documentId',
					])}` // this will redirect to marketplace
				)
			} else {
				setDocumentLink(
					`${
						window.location.origin
					}/marketPlace/${actor}/view-quote/${getIn(selectedEvent, [
						'documentId',
					])}` // this will redirect to marketplace
				)
			}
		} else if (
			getIn(selectedEvent, ['docType']) === 'contract-buyer-seller' ||
			getIn(selectedEvent, ['docType']) === 'contract-buyer-broker' ||
			getIn(selectedEvent, ['docType']) === 'contract-broker-seller'
		) {
			let actor = 'buyer'
			if (
				(currentOrganization &&
					getIn(currentOrganization, ['categories', 0, 'id']) ===
						'palmoil-broker') ||
				getIn(selectedEvent, ['docType']) === 'contract-broker-seller'
			) {
				actor = 'seller'
			}
			setDocumentLink(
				`${
					window.location.origin
				}/marketPlace/${actor}/contract/view/${getIn(selectedEvent, [
					'documentId',
				])}` // this will redirect to marketplace
			)
		} else {
			setDocumentLink(
				`${
					window.location.origin
				}:444/trade-document-manager/${getIn(selectedEvent, [
					'docType',
				])}/${getIn(selectedEvent, ['documentId'])}`
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [documentDetails, loggedInUser, selectedEvent])

	useEffect(() => {
		if (
			organisationPaginatedListFull &&
			organisationPaginatedListFull.length > 0
		) {
			let count = 0
			organisationPaginatedListFull.forEach(item => {
				count += getIn(item, ['unReadCount']) || 0
			})
			setTotalOrgMsgCount(count)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organisationPaginatedListFull])

	useEffect(() => {
		if (
			transactionListFull &&
			getIn(transactionListFull, ['list']) &&
			getIn(transactionListFull, ['list']).length > 0
		) {
			const list = []
			let count = 0
			getIn(transactionListFull, ['list']).forEach(item => {
				list.push(getIn(item, ['masterTopic']))
				count += getIn(item, ['unReadCount']) || 0
			})
			setTotalTransactionMsgCount(count)
			setSelectedTransactionKey(list.toString())
			setAllowAccess(true)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [transactionListFull])

	const getLabel = item => {
		let label = ''
		if (getIn(item, ['docType']) === 'rfq') {
			label = `${getIn(item, ['docType']).toUpperCase()} (${getIn(item, [
				'documentMeta',
				'documentNumber',
			])})`
		} else if (
			getIn(item, ['docType']) === 'contract-buyer-seller' ||
			getIn(item, ['docType']) === 'contract-broker-seller' ||
			getIn(item, ['docType']) === 'contract-buyer-broker'
		) {
			label = `${t('messages.contract')} (${getIn(item, [
				'documentMeta',
				'documentNumber',
			])})`
		} else {
			label = `${getIn(item, ['docType'])
				.charAt(0)
				.toUpperCase() +
				getIn(item, ['docType']).slice(1)} (${getIn(item, [
				'documentMeta',
				'documentNumber',
			])})`
		}

		return label
	}
	useEffect(() => {
		const subMenuListFull = {}
		if (eventListFull && eventListFull.length > 0) {
			const subMenuList = []
			eventListFull.forEach(item => {
				subMenuList.push({
					label: getLabel(item),
					count: getIn(item, ['unReadCount']),
					docType: getIn(item, ['docType']),
					partnerId: getIn(selectedTransaction, ['partnerId']),
					senderOrgId: getIn(selectedTransaction, ['senderOrgId']),
					id: getIn(selectedTransaction, ['id']),
					labelId: getIn(item, [
						'partnerOrganizationMeta',
						[0],
						'id',
					]),
					chatThread: getIn(item, ['chatThread']),
					documentId: getIn(item, ['documentID']),
				})
			})
			subMenuListFull.data = subMenuList
		} else if (
			eventListFull &&
			!(
				getIn(eventListFull, ['data']) &&
				getIn(eventListFull, ['data']).length > 0
			)
		) {
			setShowNoData(true)
		}
		subMenuListFull.parentId = getIn(selectedTransaction, ['id'])

		setSelectedSubMenu(subMenuListFull)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [eventListFull, selectedTransaction])

	const handleTransactionLoadMore = async (
		scrollHeight,
		scrollTop,
		event
	) => {
		if (
			transactionListFull &&
			getIn(transactionListFull, ['list']) &&
			getIn(transactionListFull, ['list']).length > 0 &&
			getIn(transactionListFull, ['list']).length <
				getIn(transactionListFull, ['totalCount'])
		) {
			if (
				getIn(transactionListFull, ['list']).length <
					getIn(transactionListFull, ['totalCount']) &&
				scrollHeight - scrollTop <= scrollPosition &&
				allowAccess
			) {
				setAllowAccess(false)
				event.preventDefault()
				event.stopPropagation()
				const currentList = []
				await dispatch(
					MessagesDuc.creators.transactionListing(
						getIn(selectedOrganisation, ['id']),
						organisationListFull,
						getIn(transactionListFull, ['nextStartID']),
						getIn(transactionListFull, ['list'])
					)
				)
				const newList = organisationPaginatedListFull.concat(
					currentList
				)
				setOrganisationPaginatedListFull(newList)
				setScrollPosition(
					scrollHeight / scrollIndex > 1000
						? scrollHeight / scrollIndex
						: 1000
				)
			}
		}
	}

	const handleOrganisationLoadMore = (scrollHeight, scrollTop, event) => {
		if (organisationListFull) {
			if (
				organisationPaginatedListFull &&
				organisationPaginatedListFull.length > 0 &&
				organisationPaginatedListFull.length <
					getIn(organisationListFull, ['totalCount'])
			) {
				if (scrollHeight - scrollTop <= scrollPosition) {
					const currentCount =
						organisationPaginatedListFull.length +
							MESSAGES_ORGANISATION_THRESHOLD <
						getIn(organisationListFull, ['totalCount'])
							? organisationPaginatedListFull.length +
							  MESSAGES_ORGANISATION_THRESHOLD
							: getIn(organisationListFull, ['totalCount'])
					const currentList = []
					for (
						let count = organisationPaginatedListFull.length;
						count < currentCount;
						count++
					) {
						currentList.push(
							getIn(organisationListFull, ['data'])[count]
						)
					}
					const newList = organisationPaginatedListFull.concat(
						currentList
					)
					setOrganisationPaginatedListFull(newList)
					setScrollPosition(
						scrollHeight / scrollIndex > 1000
							? scrollHeight / scrollIndex
							: 1000
					)
					event.preventDefault()
					event.stopPropagation()
				}
			}
		}
	}

	const organisationData = () => {
		const organisationDataList = []
		if (
			organisationPaginatedListFull &&
			organisationPaginatedListFull.length > 0
		) {
			organisationPaginatedListFull.forEach(org => {
				organisationDataList.push({
					label: getIn(org, ['name']) || '',
					subLabel:
						getDateWithTimeByFormat(getIn(org, ['updatedAt'])) ||
						'',
					id: getIn(org, ['id']) || '',
					count: getIn(org, ['unReadCount']) || '',
				})
			})
		}

		return organisationDataList
	}

	const transactionData = () => {
		const selectedTransactionList = []
		if (selectedOrganisation) {
			if (
				transactionListFull &&
				getIn(transactionListFull, ['list']) &&
				getIn(transactionListFull, ['list']).length > 0
			) {
				getIn(transactionListFull, ['list']).forEach(items => {
					selectedTransactionList.push({
						label: `${getIn(items, [
							'documentMeta',
							'documentNumber',
						]) || ''} (${getIn(items, [
							'documentMeta',
							'productName',
						]) || ''})`,
						id: getIn(items, ['masterTopic']) || '',
						subLabel:
							getDateWithTimeByFormat(
								getIn(items, ['updatedAt'])
							) || '',
						count: getIn(items, ['unReadCount']),
						partnerId: getIn(items, [
							'partnerOrganizationMeta',
							[0],
							'id',
						]),
						senderOrgId: getIn(items, ['organizationMeta', 'id']),
						orgId: getIn(selectedOrganisation, ['id']),
						subMenu: getIn(items, ['subDocsChatMeta']) || '',
					})
				})
			}
		}

		return selectedTransactionList
	}

	const handleDataSelection = async data => {
		if (selectedOrganisation !== data) {
			await dispatch(
				MessagesDuc.creators.transactionListing(
					getIn(data, ['id']),
					organisationListFull
				)
			)
			setSelectedOrganisation(data)
			setSelectedEvent(null)
			setShowSubList(false)
			setSelectedSubMenu(null)
			setSelectedTransaction(null)
		}
	}

	const handleEventData = async (data, from) => {
		if (from === 'fromParent') {
			if (getIn(selectedTransaction, ['id']) !== getIn(data, ['id'])) {
				setEventListFull(getIn(data, ['subMenu']))
				setSelectedTransaction(data)
				setShowSubList(true)
				setSelectedSubMenu(null)
				setSelectedEvent(null)
			}
		} else {
			if (
				getIn(data, ['count']) > 0 &&
				currentDocId !== getIn(data, ['documentId'])
			) {
				await dispatch(
					MessagesDuc.creators.updateUnReadCount(
						data,
						organisationListFull,
						transactionListFull
					)
				)
				setCurrentDocId(getIn(data, ['documentId']))
			}
			setSelectedEvent(data)
		}
	}

	const chatList = () => {
		return (
			<ChatWindow
				partnerId={getIn(selectedEvent, ['partnerId'])}
				senderOrgId={getIn(selectedEvent, ['senderOrgId'])}
				tradeId={getIn(selectedEvent, ['id'])}
				docType={getIn(selectedEvent, ['docType'])}
				header={`${getIn(selectedTransaction, [
					'label',
				])} - ${getIn(selectedEvent, ['label'])}`}
				subHeader={getIn(selectedTransaction, ['subLabel'])}
				chatThreadId={getIn(selectedEvent, ['chatThread'])}
				isIframeForTeamsApp={isIframeForTeamsApp()}
			/>
		)
	}

	const handleSearch = value => {
		dispatch(MessagesDuc.creators.searchHandling(value))
	}

	return (
		<Box padding={8} width="100%" height="100%">
			<Modal
				closeable
				show={showModal}
				heading={t('topBar.logout')}
				body={
					<P large bold>
						{t('topBar.logoutMsg')}
					</P>
				}
				closelabel={t('common.no')}
				confirmlabel={t('common.yes')}
				onClose={() => {
					setShowModal(false)
				}}
				onConfirm={() => {
					dispatch(AuthDuc.creators.signOutUser(window.location.href))
				}}
				isMobile={isMobile}
			/>
			<Box justifyContent="space-between">
				{isIframeForTeamsApp() && (
					<Box
						style={{
							alignItems: isMobile ? 'start' : 'end',
						}}
					>
						<Button
							extendStyles={{
								width: '100px',
								borderRadius: '4px',
								fontSize: '16px',
								background: '#2A2364',
								padding: '6px',
								textAlign: 'center',
								textDecoration: 'solid',
							}}
							onClick={() => setShowModal(true)}
							label={t('topBar.logout')}
							primary
							plain
						/>
					</Box>
				)}
				{!isIframeForTeamsApp() && (
					<>
						<HeaderWrapper>
							<IconWrapper
								size={28}
								style={{
									color: theme.color.blue5,
									cursor: 'pointer',
								}}
								onClick={() => {
									dispatch(
										MainRouteDuc.creators.switchPage(
											MainRouteDuc.types.DASHBOARD
										)
									)
								}}
							>
								<Icon glyph={backArrow} />
							</IconWrapper>
							<Header>{t('messages.messages')}</Header>
						</HeaderWrapper>
						<Description>{t('messages.subHeading')}</Description>
					</>
				)}
				<Grid
					gap="medium"
					rows="small"
					columns={{ count: 'fit', size: 'small' }}
					style={{
						gridGap: 8,
						display: 'flex',
					}}
				>
					<OrganisationCard id="organisationCard">
						<MessagesModule
							dataList={organisationData()}
							header={t('messages.organisation')}
							subHeader={t('messages.organisationHelpText')}
							scrollEvent
							cardId="organisationDataBlock"
							onClick={target => handleDataSelection(target)}
							handleSearch={target => handleSearch(target)}
							blocksFwd
							width="390"
							showFooter
							bgColor={theme.color.blue10}
							totalUnreadCount={totalOrgMsgCount}
							offset={20}
							handleOnScroll={(scrollHeight, scrollTop, event) =>
								handleOrganisationLoadMore(
									scrollHeight,
									scrollTop,
									event
								)
							}
							closeMessageBox={() => {
								setSelectedEvent(null)
								setSelectedOrganisation(null)
							}}
							scrollMessage={t('messages.scroll')}
							noChatMessage={t('messages.noChat')}
						/>
					</OrganisationCard>
					{selectedOrganisation && (
						<TransactionTransition selectedEvent={selectedEvent}>
							{selectedTransactionkey !== null && (
								<TransactionIntermediate>
									<MessagesModule
										dataList={transactionData()}
										header={
											getIn(selectedOrganisation, [
												'label',
											]) || ''
										}
										subHeader={t('messages.transactions')}
										cardId="transactionDataBlock"
										onClick={(target, from) =>
											handleEventData(target, from)
										}
										blocksFwd // blocksFwd={false}
										width="390"
										showFooter
										scrollEvent
										bgColor={theme.color.blue11}
										fontColor={theme.color.blue12}
										countBg={theme.color.blue10}
										totalUnreadCount={
											totalTransactionMsgCount
										}
										offset={20}
										hasSubmenu
										selectedSubmenu={selectedSubMenu}
										showSubList={showSubList}
										showNoData={showNoData}
										handleOnScroll={(
											scrollHeight,
											scrollTop,
											event
										) =>
											handleTransactionLoadMore(
												scrollHeight,
												scrollTop,
												event
											)
										}
										closeMessageBox={() =>
											setSelectedEvent(null)
										}
										scrollMessage={t('messages.scroll')}
										noChatMessage={t('messages.noChat')}
									/>
								</TransactionIntermediate>
							)}
						</TransactionTransition>
					)}
					{selectedEvent ? (
						<MessageTransition
							isIframeForTeamsApp={isIframeForTeamsApp()}
						>
							<MessagesModule
								dataList={chatList()}
								header={
									getIn(selectedOrganisation, ['label']) || ''
								}
								width="650"
								subHeader={t('messages.messages')}
								cardId="messageDataBlock"
								showFooter={false}
								scrollEvent={false}
								bgColor={theme.color.black3}
								fontColor={theme.color.white}
								messageComponent
								documentLink={documentLink}
								onClick={() => setSelectedEvent(null)}
								scrollMessage={t('messages.scroll')}
								noChatMessage={t('messages.noChat')}
							/>
						</MessageTransition>
					) : (
						''
					)}
				</Grid>
			</Box>
		</Box>
	)
}

export { AggregatedMessages }
