import LogHelper from 'core-app/utils/logger'
import { getIn } from 'timm'
import { getInsightsEndPoint } from 'core-app/config'
import {
	CallWithRefreshCheck,
	fetchOrgDetailsCount,
} from 'core-app/modules/Auth/AuthSaga'
import { TraderDuc } from 'core-app/modules/Trader/duc'
import { AppDuc } from 'core-app/modules/App/duc'
import { all, takeLatest, put } from 'redux-saga/effects'
import {
	transformDataForBarChart,
	transformDataforPieChart,
} from 'core-app/utils/helpers'

const logger = LogHelper('client:TraderSaga')

function* fetchInsights() {
	try {
		yield put(AppDuc.creators.showGlobalLoader('fetch-trader-insights'))
		yield fetchOrgDetailsCount()

		const requestUrl = `${getInsightsEndPoint()}dashboard/oil-trader/insights`
		const data = yield CallWithRefreshCheck(requestUrl)
		const userInsight = getIn(data, ['data']) || {}

		const bestSuppliers = yield transformDataforPieChart(
			userInsight.bestSuppliers,
			'best-suppliers'
		)

		const topProducts = yield transformDataForBarChart(
			userInsight.topProducts,
			'products'
		)

		const certifiedSupply = yield transformDataForBarChart(
			userInsight.certifiedSupply,
			'certified-suppliers'
		)

		const supplierCategory = yield transformDataForBarChart(
			userInsight.supplierCategory,
			'supplier-categories'
		)

		const finaltransFormedInsight = {
			...userInsight,
			bestSuppliers,
			topProducts,
			certifiedSupply,
			supplierCategory,
		}

		yield put(
			TraderDuc.creators.fetchInsightsSuccess(finaltransFormedInsight)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-trader-insights'))
	}
}

export default function* TraderSaga() {
	try {
		yield all([
			takeLatest(TraderDuc.creators.fetchInsights().type, fetchInsights),
		])
	} catch (err) {
		logger.log(err)
	}
}
