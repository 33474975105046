import React from 'react'
import { PlantationDuc } from 'core-app/modules/Plantation/duc'
import { useSelector } from 'react-redux'
import { UnexpectedErrorBlock } from 'core-app/modules/App/components/Error'
import { PreviewBin } from 'core-app/modules/Plantation/containers/BinCreation/PreviewBin'
import { PreviewTicket } from 'core-app/modules/Plantation/containers/TicketCreation/PreviewTicket'
import { PreviewHarvester } from 'core-app/modules/Plantation/components/PreviewHarvester'
import { PreviewTrips } from 'core-app/modules/Plantation/components/PreviewTrips'

const Documents = () => {
	const { payload = {} } = useSelector(PlantationDuc.selectors.location)
	const { documentType } = payload
	if (documentType === 'bin') {
		return <PreviewBin />
	}

	if (documentType === 'ticket') {
		return <PreviewTicket />
	}

	if (documentType === 'harvester') {
		return <PreviewHarvester />
	}

	if (documentType === 'trips') {
		return <PreviewTrips />
	}

	return <UnexpectedErrorBlock status={401} />
}

export { Documents }
