import React, { useMemo } from 'react'
import { STATUS_COLOR } from 'core-app/shared/config'
import { Box } from 'ui-lib/utils/Box'
import { Label } from 'ui-lib/components/Typography'
import { useTranslation } from 'react-i18next'
import { ButtonWithDrop } from 'ui-lib/components/Button'
import {
	mapLabelsToStatusActions,
	getStatusLabel,
	getStateBasedTargetActions,
} from 'core-app/shared/helpers'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { useSelector } from 'react-redux'

const Status = ({
	docType,
	currentStatus,
	isDocumentOwner,
	editable,
	onChange,
	isMobile,
}) => {
	const { t } = useTranslation()
	const actor = useSelector(AuthDuc.selectors.getActor)[0] || []
	const isTraderOrEndManufacturer =
		actor.includes('palmoil_trader') ||
		actor.includes('palmoil_manufacturer')

	const LabelTag = (
		<Label
			as="span"
			style={{
				textTransform: 'uppercase',
				fontWeight: '700',
				fontSize: isMobile ? '9px' : '14px',
			}}
			small
			color={STATUS_COLOR[currentStatus]}
		>
			{t(getStatusLabel(currentStatus, isDocumentOwner))}
		</Label>
	)

	const activeActions = useMemo(() => {
		return mapLabelsToStatusActions(
			t,
			isDocumentOwner,
			getStateBasedTargetActions(
				docType,
				currentStatus,
				isDocumentOwner,
				isTraderOrEndManufacturer
			)
		)
	}, [docType, currentStatus, isDocumentOwner, isTraderOrEndManufacturer, t])

	if (editable && activeActions.length) {
		return (
			<Box center>
				<ButtonWithDrop
					padding="4px 8px"
					noBackground
					label={LabelTag}
					onChange={onChange}
					items={activeActions}
				/>
			</Box>
		)
	}

	return <Box>{LabelTag}</Box>
}

Status.defaultProps = {
	editable: false,
	isMobile: false,
	onChange: () => {},
}

export { Status }
