import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { removeLast } from 'timm'
import { MainRouteDuc } from 'core-app/routes/duc'
import { PlantationDuc } from 'core-app/modules/Plantation/duc'
import { AppDuc } from 'core-app/modules/App/duc'
import { KeyValueSegment } from 'core-app/shared/components/KeyValueSegment'
import { Title } from 'core-app/shared/components/Title'
import { getDateByFormat } from 'core-app/utils/date'
import { chunkArrayInGroups } from 'ui-lib/utils/helpers'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { BoxShadowWrapper } from 'ui-lib/components/Card'
import { Button, ButtonIconWrapper } from 'ui-lib/components/Button'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import VerifiedIcon from 'ui-lib/icons/verified.svg'

const getPairsAndOddOne = arr => {
	let targetArr = arr
	let lastOdd = null
	if (targetArr.length % 2 > 0) {
		lastOdd = targetArr[targetArr.length - 1]
		targetArr = removeLast(targetArr)
	}

	return { pairs: chunkArrayInGroups(targetArr, 5), lastOdd }
}

const ALIASES_KEYS = {
	date: 'plantationViewTicket.date',
	assigneeName: 'plantationViewTicket.harvesterName',
	harvesterID: 'plantationViewTicket.harvesterID',
	number: 'plantationViewTicket.ticketNo',
	product: 'plantationViewTicket.product',
	quantity: 'plantationViewTicket.quantity',
	treeNumber: 'plantationViewTicket.treeNumber',
	area: 'plantationViewTicket.area',
	phase: 'plantationViewTicket.phase',
	block: 'plantationViewTicket.block',
}

const { pairs, lastOdd } = getPairsAndOddOne(Object.keys(ALIASES_KEYS))

const HarvesterDetails = ({ values, isMobile, t }) => {
	const { isSynced } = values

	return (
		<>
			{isSynced && (
				<Box style={{ flex: 1 }} alignItems="flex-end">
					<IconWrapper size={25} color="#13835A">
						<Icon glyph={VerifiedIcon} />
					</IconWrapper>
				</Box>
			)}
			<Box justifyContent="space-between" row={!isMobile}>
				{pairs.map((pair, index) => (
					<>
						<Box>
							{pair.map(_key => (
								<KeyValueSegment
									key={_key}
									name={t(ALIASES_KEYS[_key])}
									description={
										_key === 'date'
											? getDateByFormat(values[_key])
											: values[_key]
									}
									userStatus={values.userStatus}
									t={t}
								/>
							))}
						</Box>
						{index % 2 === 0 && index !== pairs.length && (
							<Spacer size={16} horizontal />
						)}
					</>
				))}
			</Box>
			{lastOdd && (
				<Box>
					<KeyValueSegment
						name={t(ALIASES_KEYS[lastOdd])}
						description={values[lastOdd]}
					/>
				</Box>
			)}
		</>
	)
}

const PreviewHarvester = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const location = useSelector(PlantationDuc.selectors.location)

	const { payload = {} } = location
	const { rootModule } = payload

	const ticketInformation = useSelector(
		PlantationDuc.selectors.fetchTicketInformation
	)

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.records'),
			name: rootModule,
			isActive: true,
		},
		{
			label: t('breadcrumb.harvester'),
			name: 'home',
			isActive: false,
		},
	]

	/** Handlers */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === rootModule) {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.PLANTATION$SUBROOT,
					{ rootModule }
				)
			)
		}
	}

	return (
		<Box padding={8} width="100%" height="100%">
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Title title={t('plantationViewTicket.viewTicket')} />
			<BoxShadowWrapper>
				<Box
					padding="16px"
					style={{
						borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
					}}
				>
					<HarvesterDetails
						values={ticketInformation}
						isMobile={isMobile}
						t={t}
					/>
				</Box>
			</BoxShadowWrapper>
			<Box padding="25px 2px" style={{ maxWidth: 150 }}>
				<Button
					label={t('common.back')}
					rounded
					customIcon={
						<ButtonIconWrapper lightBG>
							<Icon glyph={LeftArrowIcon} />
						</ButtonIconWrapper>
					}
					onClick={() =>
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.PLANTATION$SUBROOT,
								{ rootModule, type: 'harvester' }
							)
						)
					}
				/>
			</Box>
		</Box>
	)
}

export { PreviewHarvester }
