import React, { useCallback, useMemo } from 'react'
import { getIn } from 'timm'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { TripsDuc } from 'core-app/modules/Trips/duc'
import { AppDuc } from 'core-app/modules/App/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { MainRouteDuc } from 'core-app/routes/duc'
import { Title } from 'core-app/shared/components/Title'
import { Button, ButtonIconWrapper } from 'ui-lib/components/Button'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import { Icon } from 'ui-lib/icons/components/Icon'
import { getDateByFormat } from 'core-app/utils/date'
import { Spacer } from 'ui-lib/utils/Spacer'
import { getDocumentViewColumnConfig } from 'core-app/modules/Trips/components/Columns'
import { BoxShadowTable } from 'core-app/shared/components/BoxShadowTable'
import { BoxShadowWrapper } from 'ui-lib/components/Card'
import { Label, P, H4 } from 'ui-lib/components/Typography'
import styled from 'styled-components'
import { applyMediaQueries } from 'ui-lib/utils/helpers'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Box } from 'ui-lib/utils/Box'

const StatusMap = {
	completed: 'tripsListing.completed',
	'in-progress': 'tripsListing.in-progress',
}
const StatusMapColor = {
	Completed: '#63AF5D',
	Ongoing: '#2680EB',
}

const BlockWrapper = styled(Box)(p => ({
	cursor: p.plain ? 'initial' : 'pointer',
	border: '1.5px solid transparent',
	borderRadius: 4,
	'&:hover': p.plain
		? {}
		: {
				border: `1.5px solid ${p.theme.color.accent}`,
		  },
}))

const SummaryWrapper = styled(Box)({
	paddingRight: 40,
	'&:last-child': {
		paddingRight: 'initial',
	},
	...applyMediaQueries(['mobile'], {
		paddingTop: 4,
		paddingBottom: 4,
	}),
})

const SummaryBlock = ({ name, detail }) => (
	<SummaryWrapper>
		<Label color="#7680A7" small>
			{name}
		</Label>
		<Box height={35} center>
			{typeof detail === 'string' ? (
				<P
					small
					bold
					color={name === 'Status' && StatusMapColor[detail]}
				>
					{detail}
				</P>
			) : (
				detail
			)}
		</Box>
	</SummaryWrapper>
)

export const DocumentView = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const userList = useSelector(AuthDuc.selectors.getOrgUsers)
	const partnerList = useSelector(AuthDuc.selectors.getTargetPartnersOfUser)
	const currentUserOrg = useSelector(AuthDuc.selectors.getCurrentOrganization)
	const document = useSelector(TripsDuc.selectors.documentInformation)
	const { data = {}, entityListing = {} } = document
	const { clientID } = data
	const driver = userList.filter(user => user.id === clientID)
	const driverName = getIn(driver, [0, 'fullName']) || '---'
	let totalQty = 0
	const entities = getIn(data, ['meta', 'entities']) || {}
	const entitiesList = Object.assign([], Object.values(entities))
	if (entitiesList.length > 0) {
		totalQty = entitiesList
			.map(entity => getIn(entity, ['products', 0, 'quantity']))
			.reduce((ttlQty = 0, entity = 0) => ttlQty + entity)
	}

	const getBreadcrumbTitle = currentTitle => {
		return `breadcrumb.${currentTitle}`
	}

	const getBreadCrumbsList = useCallback(
		title => [
			{
				label: t('breadcrumb.home'),
				name: 'home',
				isActive: true,
			},
			{
				label: t('breadcrumb.trips'),
				name: 'trips',
				isActive: true,
			},
			{
				label: `${t(getBreadcrumbTitle(title))} ${t(
					'breadcrumb.tripDeatils'
				)}`,
				name: 'trips details',
				isActive: false,
			},
		],
		[t]
	)
	const title = getIn(data, ['status']) || '---'
	const breadCrumbs = useMemo(() => getBreadCrumbsList(title), [
		title,
		getBreadCrumbsList,
	])

	/** Handlers */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'trips') {
			dispatch(MainRouteDuc.creators.switchPage(MainRouteDuc.types.TRIPS))
		}
	}

	const handleOnChange = useCallback(
		(actionType, meta = {}) => {
			if (actionType === 'open_document') {
				if (meta.row) {
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types
								.TRADE_DOCUMENT_MANAGER$VIEWWDOCREFERENCE,
							{
								rootModule: 'delivery-order',
								documentReference: meta.row.id,
							},
							{},
							{},
							true
						)
					)
				}
			}
		},
		[dispatch]
	)

	return (
		<Box padding={8} width="100%" height="100%">
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Box row justifyContent="space-between" alignItems="baseline">
				<Title title={t(`${t('tripsListing.trip')} ${data.number}`)} />
				<Box row justifyContent="flex-end">
					<Box style={{ padding: 2 }} width="200px">
						<Button
							label={t('common.back')}
							rounded
							customIcon={
								<ButtonIconWrapper lightBG>
									<Icon glyph={LeftArrowIcon} />
								</ButtonIconWrapper>
							}
							onClick={() =>
								dispatch(
									MainRouteDuc.creators.switchPage(
										MainRouteDuc.types.TRIPS
									)
								)
							}
						/>
					</Box>
				</Box>
			</Box>
			<Box overflow id="tripDO">
				<H4>{t('tripsListing.status')} </H4>
				<Spacer size={8} />
				<BoxShadowWrapper>
					<BlockWrapper
						padding={16}
						overflow="auto"
						width="100%"
						row
						justifyContent={isMobile ? 'flex-end' : 'space-between'}
						wrap
					>
						<Box
							row={!isMobile}
							alignItems="flex-start"
							width={isMobile ? '100%' : ''}
						>
							<Box row wrap>
								<SummaryBlock
									name={t('tripsListing.date')}
									detail={getDateByFormat(data.startTime)}
								/>
								<SummaryBlock
									name={t('tripsListing.driverName')}
									detail={driverName}
								/>

								<SummaryBlock
									name={t('tripsListing.tripsNumber')}
									detail={`${t('tripsListing.trip')} ${
										data.number
									}`}
								/>

								<SummaryBlock
									name={t('tripsListing.vehicleNumber')}
									detail={
										getIn(data, [
											'meta',
											'vehicle',
											'number',
										]) || '---'
									}
								/>
								<SummaryBlock
									name={t('tripsListing.product')}
									detail={
										getIn(entityListing, [
											0,
											'products',
											0,
											'name',
										]) || 'Fresh fruit bunch'
									}
								/>
								<SummaryBlock
									name={t('tripsListing.quantity')}
									detail={totalQty}
								/>
								<SummaryBlock
									name={t('tripsListing.status')}
									detail={t(StatusMap[data.status]) || '---'}
								/>
							</Box>
						</Box>
					</BlockWrapper>
				</BoxShadowWrapper>
			</Box>

			<Spacer size={32} />
			<H4>{t('tripsListing.sourcedForm')} </H4>
			<Spacer size={8} />
			<Box overflow id="viewTripDO">
				<BoxShadowWrapper>
					<BoxShadowTable
						size="large"
						columnConfig={getDocumentViewColumnConfig({
							onChange: handleOnChange,
							partnerList,
							currentUserOrg,
							t,
						})}
						rowData={entityListing}
					/>
				</BoxShadowWrapper>
			</Box>
		</Box>
	)
}
