import React, { useEffect } from 'react'
import { getIn } from 'timm'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { H3, P, Text } from 'ui-lib/components/Typography'
import { PieChart } from 'ui-lib/components/PieChart'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import Icon404 from 'ui-lib/icons/404.svg'
import theme from 'ui-lib/utils/base-theme'
import { AuthDuc } from '../Auth/duc'
import { CookieDuc } from '../App/cookieDuc'

export const Line = styled.div(p => ({
	borderBottom: '1px solid #c9c9c9',
	margin: p.margin,
}))

export const KeyValueSegment = ({
	label,
	value,
	labelWidth = '55%',
	title = '',
}) => {
	return (
		<Box
			row
			padding="4px 12px"
			justifyContent="space-between"
			alignItems="center"
		>
			<Box
				style={{
					width: labelWidth,
					overflow: 'hidden',
				}}
				title={title}
			>
				<Text
					style={{
						lineHeight: '1.5',
						fontSize: theme.fontSize.l,
						width: '100%',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						color: theme.color.accent2,
					}}
				>
					{label}
				</Text>
			</Box>
			<Box flex justifyContent="center" alignItems="flex-end">
				<H3 style={{ color: theme.color.accent2 }}>{value}</H3>
			</Box>
		</Box>
	)
}

export const PieChartLegends = ({ data, colors }) => {
	const chartColors = colors || [
		'#60A3FA',
		'#626AE3',
		'#8EDDF0',
		'#9177CE',
		'#D387F8',
	]

	return data.map((d, idx) => (
		<P
			style={{
				maxWidth: 'calc(100%)',
				textOverflow: 'ellipsis',
				overflow: 'hidden',
				whiteSpace: 'nowrap',
				marginBottom: '8px',
			}}
			key={d.label}
			title={d.label}
		>
			<span
				style={{
					height: '1em',
					width: '1em',
					lineHeight: '1em',
					display: 'inline-block',
					background: chartColors[idx] || '#ebebeb',
					marginRight: '8px',
					borderRadius: '4px',
				}}
			>
				{' '}
			</span>
			{d.value}% - {d.label}
		</P>
	))
}

const PieChartLegendforReport = ({ data, colors }) => {
	const chartColors = colors || [
		'#60A3FA',
		'#626AE3',
		'#8EDDF0',
		'#9177CE',
		'#D387F8',
	]

	return data.map((d, idx) => (
		<P
			style={{
				maxWidth: '100%',
				textOverflow: 'ellipsis',
				overflow: 'hidden',
				whiteSpace: 'nowrap',
			}}
			key={d.label}
			title={d.label}
		>
			<span
				style={{
					height: '1em',
					width: '1em',
					lineHeight: '1em',
					display: 'inline-block',
					background: chartColors[idx] || '#ebebeb',
					marginRight: '8px',
				}}
			>
				{' '}
			</span>
			{d.label} &nbsp;{d.value}
		</P>
	))
}

export const CustomPieChart = ({
	data,
	width,
	height,
	innerRadius,
	outerRadius,
	legendForReport,
	colors = ['#60A3FA', '#626AE3', '#8EDDF0', '#9177CE', '#D387F8'],
	errorLabel = 'common.noRecordsAvailable',
}) => {
	const { t } = useTranslation()

	const records = {}
	records.label = t(errorLabel)
	records.value = 100

	return (
		<div>
			{!legendForReport && (
				<div
					style={{
						display: 'inline-block',
						verticalAlign: 'middle',
						boxSizing: 'border-box',
						padding: '4px 8px',
						width: '55%',
					}}
				>
					{data.length ? (
						<PieChartLegends data={data} />
					) : (
						<PieChartLegends
							data={[records]}
							colors={['#F45E58']}
						/>
					)}
				</div>
			)}
			{legendForReport && (
				<div
					style={{
						display: 'inline-block',
						verticalAlign: 'middle',
						boxSizing: 'border-box',
						padding: '4px 8px',
						width: '45%',
					}}
				>
					{data.length ? (
						<PieChartLegendforReport data={data} />
					) : (
						<PieChartLegends
							data={[records]}
							colors={['#F45E58']}
						/>
					)}
				</div>
			)}
			<div
				style={{
					display: 'inline-block',
					verticalAlign: 'middle',
					boxSizing: 'border-box',
					padding: '4px 8px',
					width: '45%',
					textAlign: 'end',
				}}
			>
				{data.length ? (
					<PieChart
						values={data}
						width={width}
						height={height}
						innerRadius={innerRadius}
						outerRadius={outerRadius}
						colors={(() => {
							return i => colors[i % colors.length]
						})()}
					/>
				) : (
					<PieChart
						values={[records]}
						width="150"
						height="150"
						innerRadius="40"
						outerRadius="70"
						colors={(() => {
							const color = ['#F45E58']

							return i => color[i % color.length]
						})()}
					/>
				)}
			</div>
		</div>
	)
}

export const APIConfigError = () => {
	const { t } = useTranslation()

	return (
		<Box
			center
			style={{
				background: theme.color.grey0,
				width: '100vw',
				height: '100vh',
			}}
		>
			<IconWrapper style={{ width: 400, height: 400 }}>
				<Icon glyph={Icon404} />
			</IconWrapper>
			<Text
				style={{
					margin: '60px 0 16px',
					color: '#172B4D',
					fontSize: 32,
				}}
			>
				Uh Oh!
			</Text>
			<Text style={{ color: '#172B4D', fontSize: 32 }}>
				{t('common.apiConfigNotAvailable')}
			</Text>
		</Box>
	)
}

const Illustration = styled.img`
	width: 100%;
`

const Dashboard = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const verificationStatus =
		useSelector(AuthDuc.selectors.checkVerified) || 'verified'
	const virtualAccess = useSelector(AuthDuc.selectors.getVirtualAccess)
	const cookies = useSelector(CookieDuc.selectors.cookies)

	const skipInitialSetup = getIn(cookies, ['SKIP_INITIAL_SETUP'])

	useEffect(() => {
		if (
			verificationStatus === 'verified' &&
			virtualAccess === false &&
			!skipInitialSetup
		) {
			dispatch(AuthDuc.creators.checkIfInitialSetupIsRequired())
		}
	}, [dispatch, skipInitialSetup, verificationStatus, virtualAccess])

	return (
		<>
			{virtualAccess && (
				<div
					style={{
						margin: 0,
						opacity: 1,
						fontSize: 16,
						height: 'auto',
						borderRadius: 6,
						color: '#c97b12',
						marginBottom: 20,
						letterSpacing: 0,
						border: '1px solid #f09112',
						borderLeft: '10px solid #f09112',
						background: '#fff1dd 0% 0% no-repeat padding-box',
					}}
					title={t('setup.uploadCertificate')}
				>
					<Box
						row
						padding={16}
						alignContent="center"
						style={{
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						<Box
							style={{
								fontWeight: 500,
								fontSize: '16px',
								color: theme.color.blue5,
							}}
						>
							{t('setup.uploadCertificate')}
						</Box>
					</Box>
				</div>
			)}
			<Box row justifyContent="space-between">
				<Illustration src="/images/1.png" alt="otp_filled" />
			</Box>
			<Spacer size={40} />
			<Box>
				<Illustration src="/images/2.png" alt="otp_filled" />
			</Box>
		</>
	)
}

export default Dashboard
