/* @flow */
import React, { useState, useEffect } from 'react'
import {
	CheckBox as GrommCheckBox,
	CheckBoxGroup as GrommCheckBoxGroup,
} from 'grommet'
import { Label } from '../Label'
import { FieldText } from '../FieldText'

type Props = {
	onChange: (obj: { [string]: boolean }) => {},
	checked: boolean,
	label: string,
	reverse: boolean,
	name?: string,
	showToggle?: boolean,
	disabled?: boolean,
	required?: boolean,
	error?: string,
	hideError?: string,
	returnKeyValue?: boolean,
}

const CheckBox = ({
	onChange,
	checked: checkedProp,
	label,
	reverse,
	name,
	showToggle,
	disabled,
	required = false,
	error,
	hideError,
	returnKeyValue,
}: Props) => {
	const uniqueID = name || label
	const [checked, setChecked] = useState(!!checkedProp)
	const onChangeEvt = event => {
		const isChecked = event.target.checked
		setChecked(isChecked)
		onChange(returnKeyValue ? { [uniqueID]: isChecked } : event)
	}

	useEffect(() => {
		setChecked(!!checkedProp)
	}, [checkedProp])

	return (
		<>
			<GrommCheckBox
				name={name}
				id={`checkbox-${uniqueID}`}
				onChange={onChangeEvt}
				checked={checked}
				label={
					<Label htmlFor={uniqueID} required={required}>
						{label}
					</Label>
				}
				reverse={reverse}
				toggle={showToggle}
				disabled={disabled}
			/>
			{!hideError && (
				<FieldText customLeftMargin="auto" error show={!!error}>
					{error}
				</FieldText>
			)}
		</>
	)
}

type Options = {
	label?: string,
	value: string,
	name: string,
}

type CheckBoxGroupProps = {
	selected: string,
	options: Array<Options>,
	name: string,
	label: string,
	required: boolean,
	returnKeyValue: boolean,
	error: string,
	hideError?: boolean,
	singleSelect?: boolean,
	onChange: (param: string | {}) => {},
	key: string,
	direction?: string,
}

const CheckBoxGroup = ({
	selected,
	options,
	singleSelect,
	direction = 'column',
	onChange: onChangeProp,
}: CheckBoxGroupProps) => {
	const [value, setValue] = useState([])

	const onChange = event => {
		const { value: eventValue } = event
		if (singleSelect) {
			setValue([])
			const lastItem = eventValue[eventValue.length - 1]
			setValue([lastItem])
			onChangeProp([lastItem])
		} else {
			setValue(eventValue)
			onChangeProp(eventValue)
		}
	}
	useEffect(() => setValue(selected), [selected])

	return (
		<GrommCheckBoxGroup
			gap="xsmall"
			direction={direction}
			labelKey="label"
			valueKey="key"
			value={value}
			onChange={onChange}
			options={options}
		/>
	)
}

export { CheckBox, CheckBoxGroup }
