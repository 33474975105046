import React from 'react'
import styled from 'styled-components'
import { Box } from 'ui-lib/utils/Box'
import { P, Label } from 'ui-lib/components/Typography'
import { applyMediaQueries } from 'ui-lib/utils/helpers'

const NumberIndicator = styled.div(p => ({
	width: 20,
	height: 20,
	background: p.theme.color.grey7,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	borderRadius: '50%',
	padding: '16px',
	color: p.theme.color.white,
	border: `1px solid ${p.theme.color.grey7}`,
}))

const SearchWrapper = styled(Box)({
	marginTop: '32px',
	width: '50%',
	...applyMediaQueries(['mobile'], {
		width: '100%',
	}),
})

const Address = ({ address, isMobile = false }) => {
	const details = address || {}

	return (
		<Box>
			{details.line1 && <P as="span">{details.line1},</P>}
			{details.line2 && <P as="span">{details.line2},</P>}
			<Box row={!isMobile}>
				{details.city && <P as="span">{details.city},</P>}
				{details.state && <P as="span">{details.state},</P>}
				{details.country && <P as="span">{details.country}</P>}
			</Box>
			{details.postalCode && <P as="span">{details.postalCode}</P>}
			{details.email && (
				<Box row>
					<Label small as="span">
						Email:
					</Label>
					{details.email && <P as="span">{details.email},</P>}
				</Box>
			)}
		</Box>
	)
}

const FormHeader = styled(Box)(p => ({
	background: p.theme.color.grey2,
	padding: '24px',
}))
const FormBody = styled(Box)(p => ({
	background: p.theme.color.white,
	padding: '24px',
	paddingBottom: 0,
	overflowY: 'auto',
	maxHeight: '75vh',
	...applyMediaQueries(['mobile'], {
		maxHeight: '80vh',
	}),
}))
const FormFooter = styled(Box)(p => ({
	position: 'absolute',
	bottom: 0,
	height: 60,
	background: p.theme.color.grey2,
	padding: '4px 24px',
}))

export {
	NumberIndicator,
	SearchWrapper,
	Address,
	FormHeader,
	FormBody,
	FormFooter,
}
