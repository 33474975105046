import { KeyValueSegment } from 'core-app/modules/Admin/components/KeyValueSegment'
import { ORG_ALIASES_KEYS, areaKeys } from 'core-app/modules/Admin/config'
import { AdminDuc } from 'core-app/modules/Admin/duc'
import { AppDuc } from 'core-app/modules/App/duc'
import { TraceDuc } from 'core-app/modules/Trace/duc'
import { WebTourDuc } from 'core-app/modules/WebTour/duc'
import moment from 'moment'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getIn } from 'timm'
import { AreaMapPreview } from 'ui-lib/components/AreaMapPreview'
import {
	Button,
	ButtonIconWrapper,
	ButtonWithNoBorder,
} from 'ui-lib/components/Button'
import { Card } from 'ui-lib/components/Card'
import { ErrorBlock } from 'ui-lib/components/ErrorBlocks'
import { FileLists } from 'ui-lib/components/FileUpload'
import { Modal } from 'ui-lib/components/Modal'
import { TraceDeforestationEvents } from 'ui-lib/components/TraceDeforestationEvents'
import { P } from 'ui-lib/components/Typography'
import { Icon } from 'ui-lib/icons/components/Icon'
import EditIcon from 'ui-lib/icons/edit.svg'
import theme from 'ui-lib/utils/base-theme'
import { Box } from 'ui-lib/utils/Box'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { getDateByFormat } from 'core-app/utils/date'
import EditAreaDetails from './EditAreaDetails'

const AreaBlock = ({
	isMobile,
	t,
	areaDetail,
	index,
	currentOrg = [],
	activeArea = {},
}) => {
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [showDeforestationModal, setShowDeforestationModal] = useState(false)

	const yesterday = new Date()
	yesterday.setDate(yesterday.getDate() - 1)
	const dispatch = useDispatch()
	const deforestationEvents = useSelector(
		TraceDuc.selectors.getDeforestationEvents
	)

	const getValue = key => {
		if (key === 'updatedAt') {
			return areaDetail[key]
				? moment(areaDetail[key]).format('YYYY-MM-DD hh:mm A')
				: ''
		}

		return areaDetail[key]
	}

	return (
		<>
			<Modal
				forceCloseviaButton
				size="large"
				show={showDeleteModal}
				heading={t('viewCompanyInfo.deleteArea')}
				body={<div>{t('viewCompanyInfo.deleteAreaNote')}</div>}
				closelabel={t('common.cancel')}
				confirmlabel={t('common.yes')}
				onClose={() => setShowDeleteModal(false)}
				onConfirm={() => {
					setShowDeleteModal(false)
					dispatch(
						AdminDuc.creators.deleteSingleCertificate(
							areaDetail.id,
							t('toast.deleteSuccess')
						)
					)
				}}
			/>
			<Modal
				closeable
				heading={
					<P bold color={theme.color.primary} large>
						{t('viewCompanyInfo.deforestationReport')}
					</P>
				}
				forceCloseviaButton
				size="100%"
				show={showDeforestationModal}
				hideButtons
				full
				extendStylesBody={{
					display: 'flex',
					flex: 1,
					overflow: 'hidden',
				}}
				body={
					<TraceDeforestationEvents
						deforestationOrgCount={{}}
						treeData={activeArea}
						eventsArray={deforestationEvents}
						orgDetails={currentOrg}
						selectedDeforestOrg={currentOrg}
						t={t}
					/>
				}
				onClose={() => {
					setShowDeforestationModal(false)
				}}
			/>
			<Modal
				size="large"
				show={showModal}
				heading={t('viewCompanyInfo.updateArea')}
				body={
					<EditAreaDetails
						areaDetail={areaDetail}
						handleShowModal={value => {
							setShowModal(value)
						}}
					/>
				}
				hideButtons
				onConfirm={() => {
					setShowModal(false)
				}}
			/>
			<Box row={!isMobile} padding="20px">
				<Card
					style={{
						padding: '20px',
						position: 'relative',
						overflow: 'visible',
						boxShadow: ' 0px 6px 16px #00000014',
					}}
					textAlign="left"
				>
					<Box row style={{ width: '100%' }}>
						<Box style={{ width: '50%' }}>
							{areaKeys.map(key => {
								return (
									<KeyValueSegment
										key={key}
										labelWidth="50%"
										label={t(ORG_ALIASES_KEYS[key])}
										value={getValue(key)}
									/>
								)
							})}
							<KeyValueSegment
								key="Version"
								labelWidth="50%"
								label={t('viewCompanyInfo.version')}
								value={index}
							/>
						</Box>
						<Box
							style={{
								width: '50%',
								alignItems: 'center',
							}}
						>
							<Box>
								<Button
									label={`${t(
										'viewCompanyInfo.deforestationReport'
									)} - 29 Dec 2020`}
									primary
									onClick={() => {
										setShowDeforestationModal(true)
									}}
								/>
								<Box padding="25px 0px 25px 0px">
									<Button
										label={`${t(
											'viewCompanyInfo.deforestationReport'
										)} - ${getDateByFormat(yesterday)}`}
										primary
										onClick={() => {
											setShowDeforestationModal(true)
										}}
									/>
								</Box>
							</Box>
						</Box>
						<Box style={{ width: '50%' }}>
							<FileLists
								documents={areaDetail.files || []}
								isReadOnly
							/>
						</Box>
					</Box>

					{(getIn(areaDetail, ['shape', 'coordinates']) || [])
						.length > 0 && (
						<Box
							style={{
								height: '310px',
							}}
						>
							<AreaMapPreview
								geoJSON={{
									type: 'FeatureCollection',
									features: [
										{
											type: 'Feature',
											properties: {},
											geometry: {
												type: 'Polygon',
												coordinates: [
													getIn(areaDetail, [
														'shape',
														'coordinates',
													]),
												],
											},
										},
									],
								}}
							/>
						</Box>
					)}
				</Card>
			</Box>
		</>
	)
}
const AreaDetails = ({ areaDetails, t }) => {
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const [showModal, setShowModal] = useState(false)
	const areaDetail = {
		number: '',
		files: [],
		type: '',
	}
	const dispatch = useDispatch()
	const currentOrg = useSelector(AuthDuc.selectors.getCurrentOrganization)

	const parseData = data => {
		const label = getIn(data, ['name'], '')
		const value = getIn(data, ['id'], '')
		const orgTypeID = getIn(data, ['categories', 0, 'id'], '')
		const address = `
		  ${getIn(data, ['primaryAddress', 'line1'], '')} 
		  ${getIn(data, ['primaryAddress', 'line2'], '')}
		  ${getIn(data, ['primaryAddress', 'postalCode'], '')} - 
		  ${getIn(data, ['primaryAddress', 'city'], '')} 
		  ${getIn(data, ['primaryAddress', 'state'], '')} 
		  ${getIn(data, ['primaryAddress', 'country'], '')}
		`

		return [
			{
				label,
				value,
				orgTypeID,
				address,
			},
		]
	}

	const filterActiveRecord = () => {
		const activeAreas = (areaDetails || []).filter(
			record => getIn(record, ['status']) === 'active'
		)

		return (activeAreas || []).map(activeArea => ({
			id: getIn(activeArea, ['organizationID']),
			geoType: getIn(activeArea, ['type']),
			shape: getIn(activeArea, ['shape']),
		}))
	}

	return (
		<>
			<Modal
				forceCloseviaButton
				size="large"
				show={showModal}
				heading={t('viewCompanyInfo.addArea')}
				body={
					<EditAreaDetails
						areaDetail={areaDetail}
						handleShowModal={value => {
							setShowModal(value)
						}}
					/>
				}
				hideButtons
				onConfirm={() => {
					setShowModal(false)
				}}
			/>

			<Box row justifyContent="flex-end" padding="20px 20px 0px 0px">
				<ButtonWithNoBorder
					label={t('onboarding.addNew')}
					action
					rounded
					customIcon={
						<ButtonIconWrapper lightBG>
							<Icon glyph={EditIcon} />
						</ButtonIconWrapper>
					}
					onClick={() => {
						dispatch(WebTourDuc.creators.setActiveTourModule(''))
						setShowModal(true)
					}}
				/>
			</Box>

			{areaDetails.length ? (
				(areaDetails || []).map((areaDetailObject, index) => (
					<AreaBlock
						index={areaDetails.length - index}
						isMobile={isMobile}
						key={areaDetailObject.id}
						t={t}
						areaDetail={areaDetailObject}
						isOnlyOneCertificateAvailable={areaDetails.length === 1}
						currentOrg={parseData(currentOrg)}
						activeArea={filterActiveRecord()}
					/>
				))
			) : (
				<Box style={{ minHeight: 300 }}>
					<ErrorBlock
						hideButton
						status="empty"
						message={t('common.errorBlockMessage')}
					/>
				</Box>
			)}
		</>
	)
}

export default AreaDetails
