import Duck from 'extensible-duck'
import { setIn, getIn } from 'timm'
import { initialState } from './config'

export const TraderDuc = new Duck({
	namespace: 'trader',
	store: 'global',
	types: ['SET_ACTIVE_MODULE', 'FETCH_INSIGHTS', 'FETCH_INSIGHTS_SUCCESS'],
	initialState,
	reducer: (state, action, duck) => {
		switch (action.type) {
			case duck.types.SET_ACTIVE_MODULE: {
				return setIn(state, ['activeModule'], action.module)
			}

			case duck.types.FETCH_INSIGHTS_SUCCESS: {
				const { userInsight } = action

				return setIn(state, ['insightsData'], userInsight)
			}

			default:
				return state
		}
	},
	selectors: {
		auth: state => state.auth,
		location: state => state.location,
		activeModule: state =>
			getIn(state, ['trader', 'activeModule']) || 'Error',
		getInsightsData: state =>
			getIn(state, ['trader', 'insightsData']) || {},
	},
	creators: duck => ({
		setActiveModule: module => ({
			type: duck.types.SET_ACTIVE_MODULE,
			module,
		}),
		fetchInsights: () => ({
			type: duck.types.FETCH_INSIGHTS,
		}),
		fetchInsightsSuccess: userInsight => ({
			type: duck.types.FETCH_INSIGHTS_SUCCESS,
			userInsight,
		}),
	}),
})
