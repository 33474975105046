import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn } from 'timm'
import { AdminDuc } from 'core-app/modules/Admin/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { MainRouteDuc } from 'core-app/routes/duc'
import { KeyValueSegment } from 'core-app/modules/Admin/components/KeyValueSegment'
import { ASSETS_ALIAS, INVENTORY_TYPES } from 'core-app/modules/Admin/config'
import { getDateWithTimeByFormat } from 'core-app/utils/date'
import { Box } from 'ui-lib/utils/Box'
import { H3 } from 'ui-lib/components/Typography'
import { TextArea } from 'ui-lib/components/TextArea'
import { Button, ButtonWithNoBorder } from 'ui-lib/components/Button'
import theme from 'ui-lib/utils/base-theme'
import { Icon } from 'ui-lib/icons/components/Icon'
import LockIcon from 'ui-lib/icons/lock.svg'
import UnlockIcon from 'ui-lib/icons/unlock.svg'

export const supplyChainModels = {
	'identity-preserved': 'createAsset.identity-preserved',
	segregated: 'createAsset.segregated',
	'mass-balance': 'createAsset.mass-balance',
	none: 'createAsset.none',
}

const StorageDetails = ({ assetID, onClose }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const storageUnitDetails = useSelector(
		AdminDuc.selectors.getStorageUnitDetails
	)
	const actor = useSelector(AuthDuc.selectors.getActor)[0] || []
	const isPlantation = actor.includes('palmoil_plantation')

	const partners = useSelector(AuthDuc.selectors.getTargetPartnersOfUser)
	const allProducts = useSelector(AuthDuc.selectors.getProducts)
	const tankTimeline = useSelector(AdminDuc.selectors.getTankTimeline)

	const {
		name,
		type,
		meta = {},
		capacity = '',
		capacityUOM = '',
	} = storageUnitDetails
	const {
		storedProducts = [],
		status = 'unlocked',
		multiReservation = [],
	} = meta
	const productID = getIn(storedProducts, [0, 'productID'])
	const currentQuantity = getIn(storedProducts, [0, 'availableQty'])

	const [textareaStatus, setTextareaStatus] = useState(false)
	const [tankStatusMessage, setTankStatusMessage] = useState('')
	const [tankConfirmStatus, setTankConfirmStatus] = useState(false)
	const [timelineStatus, setTimelineStatus] = useState(false)

	const scModel = getIn(meta, ['supplyChainModel'])
	const reservedFor = getIn(meta, ['reservedFor'])
	const certificationType = getIn(meta, ['certificationType'])
	const inventoryType = getIn(meta, ['inventoryType'])

	const product = allProducts.filter(p => p.id === productID)
	const productName = getIn(product, [0, 'name'])

	const organisation = partners.filter(org => org.id === reservedFor)
	const orgName = getIn(organisation, [0, 'name']) || reservedFor || ''

	const capacityAndReservedFor = type !== 'area'

	return (
		<>
			<Box row justifyContent="flex-end">
				<ButtonWithNoBorder
					label={t('common.edit').toUpperCase()}
					onClick={() => {
						if (assetID) {
							dispatch(
								MainRouteDuc.creators.switchPage(
									MainRouteDuc.types
										.ADMIN$SUBMODULEWDOCREFERENCE,
									{
										rootModule: 'assets',
										subModule: 'storageunits',
										action: 'edit-asset',
										documentReference: assetID,
									}
								)
							)
						}
					}}
				/>
			</Box>

			<Box
				style={{
					border: `1px solid ${theme.color.grey12}`,
					background: theme.color.grey13,
					borderRadius: '4px',
				}}
			>
				<KeyValueSegment
					label={t('viewAsset.storageType')}
					value={t(ASSETS_ALIAS[type])}
				/>
				<KeyValueSegment
					label={`${t(ASSETS_ALIAS[type])}  ${t('viewAsset.name')}`}
					value={name}
				/>
				<KeyValueSegment
					label={t('assetsHome.inventoryType')}
					value={t(INVENTORY_TYPES[inventoryType])}
				/>
				{capacityAndReservedFor && (
					<KeyValueSegment
						label={t('viewAsset.capacity')}
						value={`${capacity} ${capacityUOM} (${capacity *
							1000} Kg)`}
					/>
				)}
				<KeyValueSegment
					label={t('viewAsset.currentQty')}
					value={`${currentQuantity} ${
						currentQuantity ? capacityUOM || 'mT' : ''
					} (${currentQuantity * 1000} Kg)`}
				/>
			</Box>
			{!isPlantation && (
				<>
					<Box
						style={{
							border: `1px solid ${theme.color.grey12}`,
							margin: '24px 0',
							borderRadius: '4px',
						}}
					>
						<KeyValueSegment
							label={t('viewAsset.scModel')}
							value={t(supplyChainModels[scModel])}
						/>
						<KeyValueSegment
							label={t('viewAsset.productStored')}
							value={productName}
						/>
						{capacityAndReservedFor &&
							!actor.includes('palmoil_storage') && (
								<KeyValueSegment
									label={t('viewAsset.reservedFor')}
									value={orgName}
								/>
							)}
						<KeyValueSegment
							label={t('assetsHome.certificationType')}
							value={certificationType}
						/>
					</Box>
					{type === 'tank' && (
						<Box
							style={{
								border: `1px solid ${theme.color.grey12}`,
								borderRadius: '4px',
								overflowY: 'scroll',
								height: timelineStatus ? '200px' : 'auto',
							}}
						>
							<Box row justifyContent="space-between">
								<Box width="60%">
									<KeyValueSegment
										label={t('assetsHome.tankStatus')}
										value={status.toUpperCase()}
										labelWidth="162px"
									/>
								</Box>
								<ButtonWithNoBorder
									label={
										timelineStatus
											? t('assetsHome.hideTimeline')
											: t('assetsHome.viewTimeline')
									}
									onClick={() => {
										setTimelineStatus(!timelineStatus)
									}}
								/>
								<Box
									center
									title={
										status === 'locked'
											? t('assetsHome.unlockTank')
											: t('assetsHome.lockTank')
									}
								>
									<Button
										rounded
										customIcon={
											<Icon
												glyph={
													status === 'locked'
														? UnlockIcon
														: LockIcon
												}
												style={{
													height: 24,
													width: 24,
												}}
											/>
										}
										onClick={() => {
											setTextareaStatus(true)
										}}
									/>
								</Box>
							</Box>
							{textareaStatus && (
								<Box padding="4px 12px" width="auto">
									<TextArea
										label={`${t(
											'assetsHome.provideReason'
										)} ${
											status === 'locked'
												? t('assetsHome.unlocking')
												: t('assetsHome.locking')
										} the tank`}
										placeholder={t('assetsHome.upto150')}
										size={150}
										maxLength={150}
										name="tankStatusMessage"
										value={tankStatusMessage}
										onChange={e => {
											setTankStatusMessage(e.target.value)
										}}
									/>
									<Box row justifyContent="flex-end">
										<Button
											outline
											label={t('common.cancel')}
											onClick={() => {
												setTankStatusMessage('')
												setTextareaStatus(false)
											}}
											extendStyles={{
												marginRight: 16,
												fontSize: theme.fontSize.l,
											}}
										/>
										<Button
											label={t('common.submit')}
											primary
											disabled={tankStatusMessage === ''}
											onClick={() => {
												setTextareaStatus(false)
												setTankConfirmStatus(true)
											}}
											extendStyles={{
												height: 40,
												width: 120,
											}}
										/>
									</Box>
								</Box>
							)}
							{tankStatusMessage && tankConfirmStatus && (
								<Box
									row
									justifyContent="center"
									alignItems="baseline"
									style={{
										background: '#fff',
										fontSize: 24,
										marginBottom: 8,
									}}
								>
									{t('assetsHome.confQues')}
									<Button
										extendStyles={{ marginLeft: 50 }}
										primary
										label={t('common.no')}
										onClick={() => {
											setTextareaStatus(false)
											setTankConfirmStatus(false)
											setTankStatusMessage('')
										}}
									/>
									<Button
										outline
										label={t('common.yes')}
										onClick={() => {
											onClose(
												status === 'locked'
													? 'unlocked'
													: 'locked',
												tankStatusMessage
											)
										}}
										extendStyles={{
											margin: '0 16px',
											fontSize: theme.fontSize.l,
										}}
									/>
								</Box>
							)}
							{timelineStatus &&
								tankTimeline.map(tank => {
									return (
										<>
											<KeyValueSegment
												label={`${t(
													'assetsHome.statusOn'
												)} ${getDateWithTimeByFormat(
													tank.createdAt
												)}`}
												value={tank.status}
												labelWidth="220px"
											/>
											<KeyValueSegment
												label={t('assetsHome.reason')}
												labelWidth="220px"
												value={tank.message}
												pointer
											/>
										</>
									)
								})}
						</Box>
					)}
					{actor.includes('palmoil_storage') &&
						multiReservation?.length > 0 && (
							<Box
								style={{
									border: `1px solid ${theme.color.grey12}`,
									borderRadius: '4px',
									overflowY: 'scroll',
									height: timelineStatus ? '200px' : 'auto',
									margin: '24px 0',
								}}
							>
								<H3
									bold
									color={theme.color.accent2}
									style={{
										padding: '0px 12px',
										margin: '12px 0px 0px',
									}}
								>
									Reserved For:
								</H3>
								{multiReservation.map(
									({ name: companyName, quantity }) => (
										<KeyValueSegment
											label={companyName}
											value={`${quantity} ${t(
												'createAsset.mT'
											)}`}
										/>
									)
								)}
							</Box>
						)}
				</>
			)}
		</>
	)
}

const StorageInventoryCard = ({ details }) => {
	const { t } = useTranslation()

	const partners = useSelector(AuthDuc.selectors.getTargetPartnersOfUser)
	const allProducts = useSelector(AuthDuc.selectors.getProducts)

	const { name, meta = {}, capacity, capacityUOM } = details
	const { storedProducts = [] } = meta
	const productID = getIn(storedProducts, [0, 'productID'])
	const currentQuantity = getIn(storedProducts, [0, 'availableQty'])
	const scModel = getIn(meta, ['supplyChainModel'])
	const reservedFor = getIn(meta, ['reservedFor'])

	const product = allProducts.filter(p => p.id === productID)
	const productName = getIn(product, [0, 'name'])

	const organisation = partners.filter(org => org.id === reservedFor)
	const orgName = getIn(organisation, [0, 'name'])
	const getCurrentQuantityInKg = capacityUOM
		? `(${currentQuantity * 1000} Kg)`
		: ''

	return (
		<Box
			style={{
				border: `1px solid ${theme.color.grey12}`,
				background: theme.color.grey13,
				borderRadius: '4px',
			}}
			width={330}
			margin="0 8px 8px 0"
		>
			<H3 style={{ padding: '4px 12px' }}>{name}</H3>
			{capacity && (
				<KeyValueSegment
					label={t('viewAsset.capacity')}
					value={`${capacity} ${capacityUOM} (${capacity * 1000} Kg)`}
					labelWidth="50%"
				/>
			)}
			<KeyValueSegment
				label={t('viewAsset.currentQty')}
				value={`${currentQuantity} ${capacityUOM ||
					''} ${getCurrentQuantityInKg}`}
				labelWidth="50%"
			/>
			<KeyValueSegment
				label={t('viewAsset.storageType')}
				value={t('products.tank')}
				labelWidth="50%"
			/>
			<KeyValueSegment
				label={t('viewAsset.scModel')}
				value={t(supplyChainModels[scModel])}
				labelWidth="50%"
				valueWidth="150px"
			/>
			<KeyValueSegment
				label={t('viewAsset.productStored')}
				value={productName}
				labelWidth="50%"
				valueWidth="150px"
			/>
			<KeyValueSegment
				label={t('viewAsset.reservedFor')}
				value={orgName}
				labelWidth="50%"
				valueWidth="150px"
			/>
		</Box>
	)
}

export { StorageDetails, StorageInventoryCard }
