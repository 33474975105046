import React from 'react'
import { DealerDuc } from 'core-app/modules/Dealer/duc'
import { useSelector } from 'react-redux'
import { UnexpectedErrorBlock } from 'core-app/modules/App/components/Error'
import { CreateWork } from 'core-app/modules/Dealer/containers/Work/CreateWork'
import { ViewWork } from 'core-app/modules/Dealer/containers/Work/ViewWork'

const WorkCreation = () => {
	const { payload = {} } = useSelector(DealerDuc.selectors.location)

	const { action } = payload

	if (action === 'create' || action === 'edit') {
		return <CreateWork />
	}

	if (action === 'view') {
		return <ViewWork />
	}

	return <UnexpectedErrorBlock status={401} />
}

export { WorkCreation }
