import React, { useCallback, useState, useEffect } from 'react'
import { Box } from 'ui-lib/utils/Box'
import { getIn } from 'timm'
import { useDispatch, useSelector } from 'react-redux'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AdminDuc } from 'core-app/modules/Admin/duc'
import { AppDuc } from 'core-app/modules/App/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { CookieDuc } from 'core-app/modules/App/cookieDuc'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Modal } from 'ui-lib/components/Modal'
import WorkOutline from 'ui-lib/icons/work-outline.svg'
import {
	ButtonWithDrop,
	ButtonIconWrapper,
	Button,
} from 'ui-lib/components/Button'
import { Table } from 'ui-lib/components/Table'
import { P } from 'ui-lib/components/Typography'
import { ErrorBlock } from 'ui-lib/components/ErrorBlocks'
import { Tabs, DefaultTabIndicator } from 'ui-lib/components/Tabs'
import { TransparentBgSpinner } from 'ui-lib/components/Spinner/TransparentBgSpinner'
import { Spacer } from 'ui-lib/utils/Spacer'
import { useTranslation } from 'react-i18next'
import { Title } from 'core-app/shared/components/Title'
import { Icon } from 'ui-lib/icons/components/Icon'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import { ShowMore } from 'core-app/modules/App/components/ShowMore'
import {
	getVehicleColumnConfig,
	getStorageUnitColumnConfig,
	getProductionLineColumnConfig,
	getWeighBridgesColumnConfig,
} from 'core-app/modules/Admin/components/Columns'
import { VehicleDetails } from 'core-app/modules/Admin/components/VehicleDetails'
import { ProductionLineDetails } from 'core-app/modules/Admin/components/ProductionLineDetails'
import { WeighbridgeDetails } from 'core-app/modules/Admin/components/WeighbridgeDetails'
import { StorageDetails } from 'core-app/modules/Admin/components/StorageDetails'
import theme from 'ui-lib/utils/base-theme'
import { WebTour } from '../../../WebTour'
import { WebTourDuc } from '../../../WebTour/duc'

const getTabComponents = ({
	type,
	rows,
	onChange,
	loadingStatus,
	t,
	allProducts,
	selectedCPID,
	assetPagination,
}) => ({ name }) => () => {
	let columns
	if (name === 'vehicles') {
		columns = getVehicleColumnConfig({
			type,
			docType: name,
			onChange,
			t,
			selectedCPID,
		})
	}
	if (name === 'production-line') {
		columns = getProductionLineColumnConfig({
			type,
			docType: name,
			onChange,
			t,
		})
	}
	if (name === 'storageunits') {
		columns = getStorageUnitColumnConfig({
			type,
			docType: name,
			allProducts,
			onChange,
			t,
		})
	}

	if (name === 'weighbridges') {
		columns = getWeighBridgesColumnConfig({
			type,
			docType: name,
			onChange,
			t,
		})
	}

	return (
		<Box style={{ overflow: 'auto', position: 'relative' }}>
			{loadingStatus && <TransparentBgSpinner />}
			<Table
				noRoundedBorder
				columnConfig={columns}
				rowData={rows[name] || []}
			/>
			<ShowMore
				documents={rows[name]}
				total={assetPagination[name] && assetPagination[name].total}
				handleChange={onChange}
				type={name}
			/>
			{!(rows[name] || []).length && (
				<Box style={{ minHeight: 300 }}>
					<ErrorBlock
						hideButton
						status="empty"
						message={t('common.errorBlockMessage')}
					/>
				</Box>
			)}
		</Box>
	)
}

const Assets = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const location = useSelector(AdminDuc.selectors.location)
	const actor = useSelector(AuthDuc.selectors.getActor)[0] || []
	const activeTypes = useSelector(AdminDuc.selectors.getAssetsActiveTabs)
	const activeDocuments = useSelector(AdminDuc.selectors.getAssetsListing)
	const allProducts = useSelector(AuthDuc.selectors.getProducts)
	const activeTabs = getIn(activeTypes, [actor]) || []
	const loading = useSelector(AdminDuc.selectors.getAssetsLoadingStatus)
	const selectedCPID = useSelector(CookieDuc.selectors.getSelectedCPID)
	const assetPagination = useSelector(
		AdminDuc.selectors.getAssetPaginationQueries
	)

	const assetsList = []
	activeTabs.forEach(({ code, title }) => {
		if (
			!(
				code === 'weighbridges' &&
				activeDocuments &&
				activeDocuments.weighbridges &&
				activeDocuments.weighbridges.length > 0
			)
		) {
			assetsList.push({ name: code, label: t(title) })
		}
	})

	const { payload = {} } = location
	const { rootModule, type = 'vehicles' } = payload
	const index = activeTabs.findIndex(tabs => tabs.code === type)
	const [showModal, setShowModal] = useState(false)
	const [assetID, setAssetID] = useState('')
	const [docType, setDocType] = useState('')
	const [vehicleModal, setVehicleModal] = useState(false)
	const [storageUnitModal, setStorageUnitModal] = useState(false)
	const [productionLineModal, setProductionLineModal] = useState(false)
	const [weighbridgeModal, setWeighbridgeModal] = useState(false)
	const [currentAsset, setCurrentAsset] = useState('vehicles')
	const getTabs = useCallback(() => {
		return activeTabs.map(({ code, title }) => ({
			name: code,
			title: t(title),
			IndicatorComponent:
				parseInt(code, 10) > 0 &&
				(({ isActive }) => (
					<DefaultTabIndicator active={isActive}>
						{parseInt(code, 10)}
					</DefaultTabIndicator>
				)),
		}))
	}, [activeTabs, t])

	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)

	useEffect(() => {
		if (tourModule === 'assetMgmtHeader') {
			dispatch(
				WebTourDuc.creators.setActiveTourModule('assetMgmtHeader2')
			)
		}
	}, [tourModule, dispatch])

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.admin'),
			name: 'admin',
			isActive: true,
		},
		{
			label: t('breadcrumb.assets'),
			name: 'assets',
			isActive: false,
		},
	]

	/** Handlers to react on actions */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'admin') {
			dispatch(MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN))
		}
	}

	const handleChange = useCallback(
		(actionType, meta = {}) => {
			function deleteStorageUnit() {
				dispatch(WebTourDuc.creators.setActiveTourModule(''))
				if (meta.row) {
					setDocType(meta.docType)
					setAssetID(meta.row.id)
					setShowModal(true)
				}
			}
			switch (actionType) {
				case 'vehicle_details': {
					dispatch(WebTourDuc.creators.setActiveTourModule(''))
					if (meta.row) {
						dispatch(
							AdminDuc.creators.viewVehicleDetails(meta.row.id)
						)
						setAssetID(meta.row.id)
					}
					setVehicleModal(true)
					break
				}

				case 'delete_vehicle': {
					dispatch(WebTourDuc.creators.setActiveTourModule(''))
					setDocType(meta.docType)
					setAssetID(meta.row.id)
					setShowModal(true)
					break
				}

				case 'storageunit_details': {
					dispatch(WebTourDuc.creators.setActiveTourModule(''))
					if (meta.row) {
						dispatch(
							AdminDuc.creators.viewStorageUnitDetails(
								meta.row.id
							)
						)
						setAssetID(meta.row.id)
					}
					setStorageUnitModal(true)

					break
				}

				case 'delete_storageunit': {
					deleteStorageUnit()
					break
				}

				case 'productionLine_details': {
					dispatch(WebTourDuc.creators.setActiveTourModule(''))
					if (meta.row) {
						dispatch(
							AdminDuc.creators.viewProductionLineDetails(
								meta.row.id
							)
						)
						setAssetID(meta.row.id)
					}
					setProductionLineModal(true)
					break
				}

				case 'delete_productionLine': {
					dispatch(WebTourDuc.creators.setActiveTourModule(''))
					if (meta.row) {
						setDocType(meta.docType)
						setAssetID(meta.row.id)
						setShowModal(true)
					}

					break
				}

				case 'weighbridge_details': {
					dispatch(WebTourDuc.creators.setActiveTourModule(''))
					if (meta.row) {
						dispatch(
							AdminDuc.creators.viewWeighbridgeDetails(
								meta.row.id
							)
						)
						setDocType(meta.docType)
						setAssetID(meta.row.id)
						setWeighbridgeModal(true)
					}

					break
				}

				case 'delete_weighbridge': {
					deleteStorageUnit()

					break
				}

				case 'vehicles':
				case 'storageunits':
				case 'production-line':
				case 'weighbridges': {
					dispatch(
						dispatch(
							AdminDuc.creators.fetchAssetsListing(actionType)
						)
					)
					break
				}

				default:
					break
			}
		},
		[dispatch]
	)

	return (
		<>
			<Box id="input-assetMgmt">
				<Box padding={8} width="100%" height="100%">
					<Modal
						show={vehicleModal}
						heading={t('assetsHome.vehicleModalHeader')}
						size="large"
						body={
							loading ? (
								<TransparentBgSpinner />
							) : (
								<VehicleDetails assetID={assetID} />
							)
						}
						closeable
						onClose={() => {
							setVehicleModal(false)
						}}
						hideButtons
					/>
					<Modal
						overflow="scroll"
						show={storageUnitModal}
						heading={t('assetsHome.storageUnitsModalHeader')}
						size="large"
						body={
							loading ? (
								<TransparentBgSpinner />
							) : (
								<StorageDetails
									assetID={assetID}
									onClose={(status, message) => {
										dispatch(
											AdminDuc.creators.updateTankLockStatus(
												status,
												assetID,
												message
											)
										)
										setStorageUnitModal(false)
									}}
								/>
							)
						}
						closeable
						onClose={() => {
							setStorageUnitModal(false)
						}}
						hideButtons
					/>
					<Modal
						show={productionLineModal}
						heading={t('assetsHome.productionLineModalHeader')}
						size="large"
						body={
							loading ? (
								<TransparentBgSpinner />
							) : (
								<ProductionLineDetails assetID={assetID} />
							)
						}
						closeable
						onClose={() => {
							setProductionLineModal(false)
						}}
						hideButtons
					/>
					<Modal
						extendStyles={{ overflowY: 'scroll' }}
						show={weighbridgeModal}
						heading={t('assetsHome.weighbridgeModalHeader')}
						size="large"
						body={
							loading ? (
								<TransparentBgSpinner />
							) : (
								<WeighbridgeDetails assetID={assetID} />
							)
						}
						closeable
						onClose={() => {
							setWeighbridgeModal(false)
						}}
						hideButtons
					/>
					<Modal
						forceCloseviaButton
						show={showModal}
						heading={t('assetsHome.confirm')}
						size="large"
						body={
							<P large>
								{docType === 'storageunits'
									? t('assetsHome.confMsgStorageUnit')
									: t('assetsHome.confMsg')}
							</P>
						}
						closelabel={t('common.back')}
						confirmlabel={t('common.proceed')}
						onClose={() => {
							setShowModal(false)
						}}
						onConfirm={() => {
							if (docType === 'vehicles') {
								dispatch(
									AdminDuc.creators.deleteVehicle(
										assetID,
										t('assetsHome.vehicleDeleteToast')
									)
								)
							} else if (docType === 'weighbridges') {
								dispatch(
									AdminDuc.creators.deleteWeighbridge(
										assetID,
										t('assetsHome.assetDeleted')
									)
								)
							} else if (docType === 'storageunits') {
								dispatch(
									AdminDuc.creators.deleteStorageUnit(
										assetID,
										t('assetsHome.assetDeleted')
									)
								)
							} else {
								dispatch(
									AdminDuc.creators.deleteProductionLine(
										assetID,
										t('assetsHome.assetDeleted')
									)
								)
							}
							setShowModal(false)
						}}
					/>
					<Box>
						<Breadcrumb
							links={breadCrumbs}
							onClick={target => handleBreadCrumbClick(target)}
						/>
					</Box>
					<>
						<Box
							row
							justifyContent="space-between"
							alignItems="center"
						>
							<Title
								title={t('assetsHome.assets')}
								icon={WorkOutline}
								note={t('assetsHome.note')}
							/>
							{!selectedCPID && (
								<Box
									justifyContent="flex-end"
									width={isMobile ? 120 : 200}
								>
									<Box style={{ marginBottom: '10px' }}>
										<Button
											label={`${t('common.upload')} ${t(
												'adminHome.assets'
											)}`.toUpperCase()}
											action
											primary
											rounded
											onClick={() =>
												dispatch(
													MainRouteDuc.creators.switchPage(
														MainRouteDuc.types
															.ADMIN$WACTION,
														{
															rootModule,
															action:
																'upload-assets',
														}
													)
												)
											}
											extendStyles={{
												background: theme.color.primary,
												height: '35px',
												borderRadius: '20px',
												paddingTop: '0px',
												fontSize: '14px',
												fontWeight: 500,
											}}
										/>
									</Box>
									<Box>
										<ButtonWithDrop
											label={t(
												'assetsHome.createAsset'
											).toUpperCase()}
											items={assetsList}
											onChange={active => {
												dispatch(
													MainRouteDuc.creators.switchPage(
														MainRouteDuc.types
															.ADMIN$SUBMODULEWACTION,
														{
															rootModule,
															subModule: active,
															action:
																'create-asset',
														}
													)
												)
											}}
											alignSelf="flex-end"
										/>
									</Box>
								</Box>
							)}
						</Box>
						<Spacer size={8} />
						<Tabs
							activeTabIndex={index}
							tabs={getTabs()}
							tabComponentsMap={getTabComponents({
								type: 'assets',
								rows: activeDocuments,
								allProducts,
								onChange: handleChange,
								loadingStatus: loading,
								t,
								selectedCPID,
								assetPagination,
							})}
							onChange={data => {
								setCurrentAsset(getIn(data, ['name']))
							}}
						/>
						<P
							style={{
								padding: '8px 4px',
								color: theme.color.grey6,
							}}
						>
							{currentAsset === 'storageunits'
								? t('assetsHome.tankdeleteWarning')
								: ''}
						</P>
					</>
					<Box padding="25px 0" style={{ maxWidth: 150 }}>
						<Button
							label={t('common.back')}
							rounded
							customIcon={
								<ButtonIconWrapper lightBG>
									<Icon glyph={LeftArrowIcon} />
								</ButtonIconWrapper>
							}
							onClick={() =>
								dispatch(
									MainRouteDuc.creators.switchPage(
										MainRouteDuc.types.ADMIN
									)
								)
							}
						/>
					</Box>
				</Box>
			</Box>
			{tourModule === 'assetMgmtHeader2' && <WebTour />}
		</>
	)
}

export { Assets }
