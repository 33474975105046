import React, { useMemo, useCallback } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { getIn } from 'timm'
import { AppDuc } from 'core-app/modules/App/duc'
import { MainRouteDuc } from 'core-app/routes/duc'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import { Box } from 'ui-lib/utils/Box'
import { useTranslation } from 'react-i18next'
import { Button, ButtonIconWrapper } from 'ui-lib/components/Button'
import { BoxShadowWrapper } from 'ui-lib/components/Card'
import { getDateWithTimeByFormat } from 'core-app/utils/date'
import { Icon } from 'ui-lib/icons/components/Icon'
import { Spacer } from 'ui-lib/utils/Spacer'
import { applyMediaQueries } from 'ui-lib/utils/helpers'
import { Label, P } from 'ui-lib/components/Typography'
import { BoxShadowTable } from 'core-app/shared/components/BoxShadowTable'
import { Title } from 'core-app/shared/components/Title'
import { getProducedBatchConfig } from 'core-app/modules/Mill/components/Columns'
import { SimplifiedTimeline } from 'core-app/modules/Mill/components/SimplifiedTimeline'
import { MillDuc, LabelsMap } from '../../duc'

const BlockWrapper = styled(Box)(p => ({
	cursor: p.plain ? 'initial' : 'pointer',
	border: '1.5px solid transparent',
	borderRadius: 4,
	'&:hover': p.plain
		? {}
		: {
				border: `1.5px solid ${p.theme.color.accent}`,
		  },
}))

const SummaryWrapper = styled(Box)({
	paddingRight: 40,
	'&:last-child': {
		paddingRight: 'initial',
	},
	...applyMediaQueries(['mobile'], {
		paddingTop: 4,
		paddingBottom: 4,
	}),
})

const SummaryBlock = ({ name, detail }) => (
	<SummaryWrapper>
		<Label color="#7680A7" small>
			{name}
		</Label>
		<Box height={35} center>
			{typeof detail === 'string' ? (
				<P small bold>
					{detail}
				</P>
			) : (
				detail
			)}
		</Box>
	</SummaryWrapper>
)

export function getUserName(userid, userData) {
	const user = userData.filter(usr => usr.id === userid)

	return getIn(user, [0, 'fullName'])
}

const PreviewPlot = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const locationState = useSelector(MillDuc.selectors.location)
	const activeRecords = useSelector(MillDuc.selectors.getActiveRecords)
	const allProducts = useSelector(AuthDuc.selectors.getProducts)
	const allTanks = useSelector(AuthDuc.selectors.getTanks)
	const userData = useSelector(AuthDuc.selectors.getUsersData)

	const productName = allProducts.filter(
		product => product.id === activeRecords.productName
	)
	const name = getIn(productName, [0, 'name']) || ''
	const uom = getIn(productName, [0, 'uom']) || ''

	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const { payload = {} } = locationState
	const { rootModule } = payload

	const getBreadCrumbsList = useCallback(
		label => [
			{
				label: t('breadcrumb.home'),
				name: 'home',
				isActive: true,
			},
			{
				label: t('breadcrumb.production'),
				name: 'production',
				isActive: true,
			},
			{
				label: `${t(label)}`,
				name: 'batch details',
				isActive: false,
			},
		],
		[t]
	)

	/** Handlers */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'production') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.MILL$SUBROOT,
					{
						rootModule: 'production',
					}
				)
			)
		}
	}

	const title = LabelsMap[rootModule]

	const breadCrumbs = useMemo(() => getBreadCrumbsList(title), [
		title,
		getBreadCrumbsList,
	])

	const heading = t('production.batchDetails')

	const transformedData = (
		getIn(activeRecords, ['statusChangeLog']) || []
	).map(d => {
		return {
			key: getDateWithTimeByFormat(d.createdAt),
			value: `${
				d.status === 'transforming' ? 'Created' : 'Completed'
			} by ${getUserName(d.createdBy, userData)}`,
		}
	})

	return (
		<Box padding={8} width="100%" height="100%">
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Box row justifyContent="space-between" alignItems="baseline">
				<Title title={heading} />
				<Box row justifyContent="flex-end">
					<Box style={{ padding: 2 }} width="200px">
						<Button
							label={t('common.back')}
							rounded
							customIcon={
								<ButtonIconWrapper lightBG>
									<Icon glyph={LeftArrowIcon} />
								</ButtonIconWrapper>
							}
							onClick={() =>
								dispatch(
									MainRouteDuc.creators.switchPage(
										MainRouteDuc.types.MILL$SUBROOT,
										{
											rootModule: 'production',
										}
									)
								)
							}
						/>
					</Box>
				</Box>
			</Box>
			<Box overflow>
				<BoxShadowWrapper>
					<Box
						padding="4px 15px"
						style={{
							borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
						}}
					>
						<P>{t('production.sourcedBatch')} </P>
					</Box>
					<BlockWrapper
						padding={16}
						overflow="auto"
						width="100%"
						row
						justifyContent={isMobile ? 'flex-end' : 'space-between'}
						wrap
					>
						<Box
							row={!isMobile}
							alignItems="flex-start"
							width={isMobile ? '100%' : ''}
						>
							<Box row wrap>
								<SummaryBlock
									name={t('production.inputMaterial')}
									detail={name || '---'}
								/>
								<SummaryBlock
									name={t(
										'production.quantityUnderProduction'
									)}
									detail={
										`${
											(activeRecords.quantity || 0)
												.toString()
												.match(
													/^-?\d+(?:\.\d{0,3})?/
												)[0]
										}${uom} (${activeRecords.quantity *
											1000} Kg)` || '---'
									}
								/>
								<SummaryBlock
									name={t('production.batchReference')}
									detail={
										activeRecords.batchReference || '---'
									}
								/>
							</Box>
						</Box>
					</BlockWrapper>
				</BoxShadowWrapper>
			</Box>
			<Spacer size={24} />

			{rootModule === 'produced-batch' && (
				<>
					<Title title={t('production.producedInventory')} />
					<BoxShadowTable
						size="large"
						columnConfig={getProducedBatchConfig({
							allProducts,
							createdAt: activeRecords.producedTracesCreatedAt,
							t,
							allTanks,
						})}
						rowData={activeRecords.producedDocumentTraces}
					/>
					<Spacer size={24} />
				</>
			)}
			<SimplifiedTimeline data={transformedData} />
		</Box>
	)
}

export { PreviewPlot }
