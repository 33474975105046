import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { AdminDuc } from 'core-app/modules/Admin/duc'
import { WebTourDuc } from 'core-app/modules/WebTour/duc'
import { UnexpectedErrorBlock } from 'core-app/modules/App/components/Error'
import { AddCollectionPoint } from 'core-app/modules/Onboard/containers/NewSignup/AddCollectionPoint'
import { Box } from 'ui-lib/utils/Box'
import { Title } from 'core-app/shared/components/Title'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { MainRouteDuc } from '../../../../routes/duc'

const CollectionPoint = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { payload = {} } = useSelector(AdminDuc.selectors.location)

	const { action } = payload
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.admin'),
			name: 'admin',
			isActive: true,
		},
		{
			label: t('viewCompanyInfo.addCollectionPoint'),
			name: 'view-company-info',
			isActive: false,
		},
	]

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'admin') {
			dispatch(MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN))
		}
	}

	if (tourModule !== '') {
		dispatch(WebTourDuc.creators.setActiveTourModule(''))
	}
	if (action === 'add-cp' || action === 'edit-cp') {
		return (
			<Box id="input-companyInfo" padding={8} width="100%" height="100%">
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
				<Box>
					<Title title={t('viewCompanyInfo.addCollectionPoints')} />
				</Box>
				<AddCollectionPoint />
			</Box>
		)
	}

	return <UnexpectedErrorBlock status={401} />
}

export { CollectionPoint }
