/* @flow */

import React from 'react'
// $FlowFixMe
import styled from 'styled-components'
import { Button as GrommetButton, Menu } from 'grommet'
import type { Node } from 'react'
import { Spinner } from '../Spinner'
import { Box } from '../../utils/Box'
import theme from '../../utils/base-theme'

type Props = {
	color?: string,
	type?: string,
	onClick?: () => void,
	label?: string | Node,
	disabled?: boolean,
	primary?: boolean,
	className?: string,
	isLoading?: boolean,
	customIcon?: ?Node,
	width?: string | number,
	plain?: boolean,
	extendStyles?: {},
	tabIndex?: number,
	reverse?: boolean,
}

const LoadingWrapper = styled.div(p => ({
	marginLeft: '-26px',
	marginRight: '6px',
	'& + div': {
		display: 'none',
	},
	...(p.customIcon && { position: 'relative', marginLeft: -4 }),
}))

const ButtonBox = styled(Box)`
	background: ${p =>
		p.noBackground ? p.theme.color.white : p.theme.color.primary};
	color: ${p =>
		p.noBackground ? p.theme.color.primary : p.theme.color.white};
	border: 1px solid ${p => p.theme.color.primary};
	font-weight: 500;
	border-radius: ${p => p.borderRadius};
	box-shadow: ${p =>
		p.noBackground ? 'initial' : 'rgba(0, 0, 0, 0.1) 2px 2px 3px'};
	& button {
		outline-color: initial;
	}
	& button > div:first-of-type {
		padding: ${p => p.customPadding || '6px'};

		& > div:first-of-type {
			display: none;
		}
	}
	& button > div:first-of-type > svg {
		fill: ${p =>
			p.noBackground ? p.theme.color.primary : p.theme.color.white};
		stroke: ${p =>
			p.noBackground ? p.theme.color.primary : p.theme.color.white};
	}
`
const Button = ({
	className,
	onClick,
	label,
	disabled,
	primary,
	isLoading,
	customIcon,
	type,
	plain,
	extendStyles,
	tabIndex,
	reverse,
}: Props) => (
	<GrommetButton
		overflow="auto"
		icon={
			isLoading ? (
				<LoadingWrapper customIcon={customIcon}>
					<Spinner relative color={primary ? '#fff' : ''} size={20} />
				</LoadingWrapper>
			) : (
				customIcon
			)
		}
		reverse={reverse}
		primary={primary}
		type={type}
		disabled={isLoading || disabled}
		label={label}
		onClick={onClick}
		className={className}
		tabIndex={tabIndex}
		plain={plain}
		style={{
			position: 'relative',
			...(primary && { boxShadow: '0px 6px 16px #172B4D2E' }),
			...(plain && {
				textDecoration: 'none',
				padding: '4px 22px',
				backgroundColor: 'white',
			}),
			backgroundColor: 'none',
			padding: 'none',
			// $FlowFixMe
			...(!plain && !primary && { backgroundColor: 'white' }),
			// $FlowFixMe
			...extendStyles,
		}}
	/>
)

const RoundedButton = styled(Button)`
	border-radius: 20px;
	color: ${p => (p.primary ? p.theme.color.white : p.theme.color.primary)};
`
const RoundedFeedbackButton = styled(Button)`
	border-radius: 150px;
	height: 56px;
	width: 56px;
	right: 16px;
	bottom: 16px;
	position: fixed !important;
	background: ${p => p.theme.color.blue1};
	box-shadow: 3px 3px 6px #00000029;
	color: ${p => (p.primary ? p.theme.color.white : p.theme.color.primary)};
`
const ButtonWithNoBorder = styled(Button)`
	color: ${p => p.color || p.theme.color.primary};
	text-decoration: ${p => p.textDecoration || 'underline'};
	background-color: initial !important;
	font-size: ${p => p.fontSize || p.theme.fontSize.m};
	border: none;
	padding: 5px;
	&:hover {
		box-shadow: none;
	}
`

type DropProps = {
	label: string,
	items: Array<{ name?: string, label: string }>,
	noBackground?: boolean,
	onChange: string => void,
	fontSize?: number,
	padding?: string,
	borderRadius?: string,
	disabled: boolean,
}

const ButtonWithDrop = ({
	label,
	items,
	noBackground,
	onChange,
	fontSize = theme.fontSize.s,
	padding,
	borderRadius = '100px',
	disabled,
}: DropProps) => (
	<ButtonBox
		center
		overflow
		noBackground={noBackground}
		customPadding={padding}
		borderRadius={borderRadius}
	>
		<Menu
			plain
			justifyContent="center"
			alignSelf="center"
			size="small"
			dropAlign={{ top: 'top', right: 'right' }}
			disabled={disabled}
			label={label}
			items={items.map(item => ({
				...item,
				onClick: () => {
					onChange(item.name || item.label)
				},
			}))}
			dropBackground="white"
			style={{
				fontSize,
				width: '100%',
			}}
		/>
	</ButtonBox>
)

const ButtonIconWrapper = styled.div(p => ({
	width: p.size ? p.size : 15,
	height: p.size ? p.size : 15,
	alignSelf: 'baseline',
	marginTop: p.marginTop ? p.marginTop : 1,
	color: p.lightBG ? p.theme.color.primary : p.theme.color.white,
	marginRight: p.marginRight ? p.marginRight : -5, // because of the way grommet does shit.
	...(p.color ? { color: p.color } : {}),
}))

const ButtonFeedbackIconWrapper = styled.div(p => ({
	width: 28,
	height: 28,
	alignSelf: 'baseline',
	marginTop: 1,
	marginLeft: 2,
	marginRight: -5, // because of the way grommet does shit.
	...(p.color ? { color: p.color } : {}),
}))

export {
	Button,
	RoundedButton,
	RoundedFeedbackButton,
	ButtonWithNoBorder,
	ButtonWithDrop,
	ButtonIconWrapper,
	ButtonFeedbackIconWrapper,
}
