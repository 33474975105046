import React from 'react'
import { useTranslation } from 'react-i18next'
import PartnerIcon from 'ui-lib/icons/partner.svg'
import { Box } from 'ui-lib/utils/Box'
import { UploadExcel } from 'core-app/modules/UploadContent/components/UploadExcel'

const UploadPartners = () => {
	const { t } = useTranslation()

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.partner'),
			name: 'partner',
			isActive: true,
		},
		{
			label: `${t('common.upload')} ${t('breadcrumb.partner')}`,
			name: 'upload-partners',
			isActive: false,
		},
	]

	return (
		<Box>
			<UploadExcel
				breadCrumbs={breadCrumbs}
				titleicon={PartnerIcon}
				title={`${t('common.upload')} ${t('breadcrumb.partner')}`}
				type="partners"
			/>
		</Box>
	)
}

export { UploadPartners }
