import React from 'react'
import { Input } from 'ui-lib/components/Input'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Label } from 'ui-lib/components/Label'

export const CustomInput = ({
	value,
	handleChange,
	handleBlur,
	touched,
	errors,
	label,
	name,
	type,
	regex,
	placeholder = '',
	required = false,
	disabled = false,
}) => {
	return (
		<Box style={{ width: '100%' }}>
			<Label
				required={required}
				style={{
					fontSize: '14px',
					color: '#555454',
				}}
			>
				{label}
			</Label>
			<Spacer size={8} />
			<Input
				disabled={disabled}
				value={value}
				name={name}
				type={type}
				regex={regex}
				placeholder={placeholder}
				onChange={handleChange}
				onBlur={handleBlur}
				error={touched && errors}
				extendStyles={{
					border: '1px solid #ACB7CD',
					borderRadius: '4px',
					opacity: 1,
					background: disabled ? '#ececec' : 'white',
				}}
			/>
		</Box>
	)
}
