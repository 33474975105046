import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { AppDuc } from 'core-app/modules/App/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { PlantationDuc } from 'core-app/modules/Plantation/duc'
import { Setup } from 'core-app/modules/App/containers/Setup'
import { Card } from 'ui-lib/components/Card'
import { H4, SmallText } from 'ui-lib/components/Typography'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Box } from 'ui-lib/utils/Box'
import theme from 'ui-lib/utils/base-theme'
import {
	KeyValueSegment,
	CustomPieChart,
	Line,
} from 'core-app/modules/Dashboard'

const binKeys = ['today', 'thisWeek', 'thisMonth', 'lastMonth']
const tripKeys = ['today', 'thisWeek', 'thisMonth', 'lastMonth']
const ALIASES_KEYS = {
	today: 'plantationDashboard.today',
	thisWeek: 'plantationDashboard.thisWeek',
	thisMonth: 'plantationDashboard.thisMonth',
	lastMonth: 'plantationDashboard.lastMonth',
}

const MainDashboard = () => {
	const { t } = useTranslation()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const insights = useSelector(PlantationDuc.selectors.getInsightsData) || {}
	const { partnersAvailable, employeesAvailable, languageSet } = useSelector(
		AuthDuc.selectors.getFirstTimeStats
	)
	const {
		sales = {},
		salesUOM,
		blocks = [],
		yieldCurrentMonth,
		yieldLastMonth,
		yieldUOM,
		harvest = [],
		messageExchanged,
		binsCount = {},
		binsUOM,
		tradeDetails = {},
		tradeDetailsUOM,
		tripTiming = {} || null,
		tripTimingUOM,
	} = insights

	const headingColor = theme.color.accent2
	const subHeadingColor = theme.color.grey10

	const getSalesSvg = () => {
		if (sales.currentMonth < sales.previousMonth) {
			return "url('/images/red-wave.svg')"
		}
		if (sales.currentMonth > sales.previousMonth) {
			return "url('/images/green-wave.svg')"
		}
		if (sales.currentMonth === sales.previousMonth) {
			return "url('/images/blue-wave.svg')"
		}
	}

	const getProductionSvg = () => {
		if (yieldCurrentMonth < yieldLastMonth) {
			return "url('/images/red-wave.svg')"
		}
		if (yieldCurrentMonth > yieldLastMonth) {
			return "url('/images/green-wave.svg')"
		}
		if (yieldCurrentMonth === yieldLastMonth) {
			return "url('/images/blue-wave.svg')"
		}
	}

	return (
		<>
			{(partnersAvailable === false ||
				employeesAvailable === false ||
				languageSet === false) && <Setup />}
			<Box row={!isMobile}>
				<Card
					style={{
						margin: '12px 0 0',
						backgroundImage: getSalesSvg(),
						backgroundSize: 'contain',
						backgroundPosition: 'bottom',
					}}
					textAlign="left"
					height="174px"
				>
					<Box>
						<H4
							style={{
								padding: '12px 12px 0px 12px',
								color: headingColor,
							}}
						>
							{t('millDashboard.salesHeading')}
						</H4>
						<SmallText
							style={{
								padding: '0 12px',
								color: subHeadingColor,
							}}
						>
							{t('plantationDashboard.salesSubheading')}
						</SmallText>
					</Box>
					<Box style={{ padding: '12px 0 ' }}>
						<KeyValueSegment
							label={t('millDashboard.currentMonthSales')}
							value={`${sales.currentMonth || 0} ${
								sales.currentMonth ? salesUOM : ''
							} (${sales.currentMonth * 1000} kg)`}
						/>
						<KeyValueSegment
							label={t('millDashboard.previousMonthSales')}
							value={`${sales.previousMonth || 0} ${
								sales.previousMonth ? salesUOM : ''
							} (${sales.previousMonth * 1000} kg)`}
						/>
					</Box>
				</Card>

				<Card
					style={{
						margin: isMobile ? '12px 0' : '12px 12px 0',
						backgroundImage: getProductionSvg(),
						backgroundSize: 'contain',
						backgroundPosition: 'bottom',
					}}
					textAlign="left"
					height="174px"
				>
					<Box>
						<H4
							style={{
								padding: '12px 12px 0px 12px',
								color: headingColor,
							}}
						>
							{t('plantationDashboard.productionHeading')}
						</H4>
						<SmallText
							style={{
								padding: '0 12px',
								color: subHeadingColor,
							}}
						>
							{t('plantationDashboard.productionSubheading')}
						</SmallText>
					</Box>
					<Box style={{ padding: '12px 0 ' }}>
						<KeyValueSegment
							label={t('plantationDashboard.currentMonth')}
							value={`${yieldCurrentMonth || 0} ${
								yieldCurrentMonth ? yieldUOM : ''
							}`}
						/>
						<KeyValueSegment
							label={t('plantationDashboard.previousMonth')}
							value={`${yieldLastMonth || 0} ${
								yieldLastMonth ? yieldUOM : ''
							}`}
						/>
					</Box>
				</Card>
			</Box>

			<Box row={!isMobile}>
				<Box width={isMobile ? 'auto' : '75%'}>
					<Box row={!isMobile}>
						<Card
							style={{ margin: isMobile ? '0' : '12px 0 0' }}
							textAlign="left"
							height={blocks.len || harvest.length ? '' : '236px'}
						>
							<Box>
								<H4
									style={{
										padding: '12px 12px 0px 12px',
										color: headingColor,
									}}
								>
									{t('plantationDashboard.bestBlocks')}
								</H4>
								<SmallText
									style={{
										padding: '0 12px',
										color: subHeadingColor,
									}}
								>
									{t('plantationDashboard.blocksSubheading')}
								</SmallText>
							</Box>
							<CustomPieChart
								data={blocks}
								width={isMobile ? '150' : '170'}
								height={isMobile ? '150' : '170'}
								innerRadius="55"
								outerRadius="70"
							/>
						</Card>

						<Card
							style={{
								margin: isMobile ? '12px 0' : '12px 12px 0',
							}}
							textAlign="left"
						>
							<Box>
								<H4
									style={{
										padding: '12px 12px 0px 12px',
										color: headingColor,
									}}
								>
									{t('plantationDashboard.bestHarvesters')}
								</H4>
								<SmallText
									style={{
										padding: '0 12px',
										color: subHeadingColor,
									}}
								>
									{t(
										'plantationDashboard.harvestersSubheading'
									)}
								</SmallText>
								{harvest.length ? (
									<Box style={{ padding: '12px 12px 0' }}>
										{harvest.map(({ name }, idx) => {
											return (
												<span
													key={name}
													style={{
														fontSize:
															theme.fontSize.l,
														padding: '4px',
														color:
															theme.color.accent2,
													}}
												>
													{idx + 1}. {name}
												</span>
											)
										})}
									</Box>
								) : (
									<Box
										height="150px"
										center
										style={{ color: '#08090C' }}
									>
										{t('common.errorBlockMessage')}
									</Box>
								)}
							</Box>
						</Card>
					</Box>

					<Box row={!isMobile}>
						<Card
							style={{ margin: isMobile ? '0' : '12px 0 0' }}
							textAlign="left"
						>
							<Box>
								<H4
									style={{
										padding: '12px 12px 0px 12px',
										color: headingColor,
									}}
								>
									{t('plantationDashboard.binsHeading')}
								</H4>
								<SmallText
									style={{
										padding: '0 12px',
										color: subHeadingColor,
									}}
								>
									{t('plantationDashboard.binSubheading')}
								</SmallText>
							</Box>
							<Box style={{ padding: '12px 0 12px' }}>
								{binKeys.map(key => {
									return (
										<KeyValueSegment
											key={key}
											label={t(ALIASES_KEYS[key])}
											value={`${
												binsCount[key]
													? binsCount[key]
													: ''
											} ${binsCount[key] ? binsUOM : 0}`}
										/>
									)
								})}
							</Box>
						</Card>

						<Card
							style={{
								margin: isMobile ? '12px 0 0' : '12px 12px 0',
							}}
							textAlign="left"
						>
							<Box>
								<H4
									style={{
										padding: '12px 12px 0px 12px',
										color: headingColor,
									}}
								>
									{t('plantationDashboard.tripsHeading')}
								</H4>
								<SmallText
									style={{
										padding: '0 12px',
										color: subHeadingColor,
									}}
								>
									{t('plantationDashboard.tripsSubheading')}
								</SmallText>
							</Box>
							<Box style={{ padding: '12px 0 12px' }}>
								{tripKeys.map(key => {
									return (
										<KeyValueSegment
											key={key}
											label={t(ALIASES_KEYS[key])}
											value={`${
												tripTiming[key]
													? tripTiming[key]
													: ''
											} ${
												tripTiming[key]
													? tripTimingUOM
													: 0
											}`}
										/>
									)
								})}
							</Box>
							<Box padding={12}>
								<SmallText
									style={{
										color: subHeadingColor,
									}}
								>
									{t('plantationDashboard.tripsFooter')}
								</SmallText>
							</Box>
						</Card>
					</Box>
				</Box>
				<Box width={isMobile ? 'auto' : '25%'}>
					<Card
						style={{
							margin: isMobile ? '12px 0' : '12px 12px 0 0',
							height: '100%',
							width: 'auto',
						}}
						textAlign="left"
					>
						<Box>
							<H4
								style={{
									padding: '12px 12px 0px 12px',
									color: headingColor,
								}}
							>
								{t('plantationDashboard.tradeDetails')}
							</H4>
							<SmallText
								style={{
									padding: '8px 12px 0px',
									color: subHeadingColor,
								}}
							>
								{t(
									'plantationDashboard.tradeDetailsSubHeading'
								)}
							</SmallText>
						</Box>

						<Box>
							<H4
								style={{
									padding: '12px',
								}}
							>
								{t('plantationDashboard.outgoingConsgmnt')}
							</H4>
							<KeyValueSegment
								label={t('plantationDashboard.thisMonth')}
								value={`${tradeDetails.thisMonth || 0} ${
									tradeDetails.thisMonth
										? tradeDetailsUOM
										: ''
								}`}
							/>
							<KeyValueSegment
								label={t('plantationDashboard.lastMonth')}
								value={`${tradeDetails.lastMonth || 0} ${
									tradeDetails.lastMonth
										? tradeDetailsUOM
										: ''
								}`}
							/>
						</Box>
						<Spacer size={50} />
						<Line />
						<Box>
							<H4
								style={{
									padding: '12px 12px 0px 12px',
									color: headingColor,
								}}
							>
								{t('plantationDashboard.msgsXchanged')}
							</H4>
							<SmallText
								style={{
									padding: '8px 12px 0px',
									color: subHeadingColor,
								}}
							>
								{t(
									'plantationDashboard.msgsXchangesSubheading'
								)}
							</SmallText>
						</Box>
						<Box style={{ padding: '10px 0' }}>
							<KeyValueSegment
								label={t('common.messages')}
								value={messageExchanged}
							/>
						</Box>
					</Card>
				</Box>
			</Box>
		</>
	)
}

export { MainDashboard }
