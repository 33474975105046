import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AdminDuc } from 'core-app/modules/Admin/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { CookieDuc } from 'core-app/modules/App/cookieDuc'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Modal } from 'ui-lib/components/Modal'
import { ButtonIconWrapper, Button } from 'ui-lib/components/Button'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { P } from 'ui-lib/components/Typography'
import DeleteIcon from 'ui-lib/icons/deleteTrash.svg'
import EditIcon from 'ui-lib/icons/edit.svg'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Title } from 'core-app/shared/components/Title'
import { TransparentBgSpinner } from 'ui-lib/components/Spinner/TransparentBgSpinner'
import { EmployeeDetailsSegment } from 'core-app/modules/Admin/components/EmployeeDetailsSegment'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'

const ViewEmployee = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const employee = useSelector(AdminDuc.selectors.getEmployeeDetails)
	const { orgRole: loggedinUserRole, id: loggedinUserID } = useSelector(
		AuthDuc.selectors.getUserProfile
	)

	const isLoading = useSelector(AdminDuc.selectors.getEmployeeLoadingStatus)

	const { payload } = useSelector(AdminDuc.selectors.location)
	const { rootModule, documentReference } = payload
	const selectedCPID = useSelector(CookieDuc.selectors.getSelectedCPID)
	const [showModal, setShowModal] = useState(false)
	const {
		firstName,
		orgRole: employeeRole,
		primaryContact = {},
		meta = {},
		id: employeeID,
	} = employee
	const { email, mobile } = primaryContact
	const { employeeData = {} } = meta

	let canDelete = false
	let canEdit = false

	if (loggedinUserRole === 'owner') {
		if (employeeRole === 'owner') {
			canEdit = true
		}
		if (
			employeeRole === 'administrator' ||
			employeeRole === 'office-management' ||
			employeeRole === 'checker' ||
			employeeRole === 'harvester' ||
			employeeRole === 'tractor-operator' ||
			employeeRole === 'weighbridgeClerk' ||
			employeeRole === 'driver' ||
			employeeRole === 'grader' ||
			employeeRole === 'purchase-officer' ||
			employeeRole === 'sales-marketing-officer'
		) {
			canDelete = true
			canEdit = true
		}
	}
	if (loggedinUserRole === 'administrator') {
		if (employeeRole === 'owner') {
			canDelete = false
			canEdit = false
		}
		if (employeeRole === 'administrator') {
			if (loggedinUserID !== employeeID) {
				canDelete = true
				canEdit = true
			} else {
				canDelete = false
				canEdit = true
			}
		}
		if (
			employeeRole === 'office-management' ||
			employeeRole === 'checker' ||
			employeeRole === 'harvester' ||
			employeeRole === 'tractor-operator' ||
			employeeRole === 'weighbridgeClerk' ||
			employeeRole === 'driver' ||
			employeeRole === 'grader' ||
			employeeRole === 'purchase-officer' ||
			employeeRole === 'sales-marketing-officer'
		) {
			canDelete = true
			canEdit = true
		}
	}
	if (loggedinUserRole === 'office-management') {
		if (
			employeeRole === 'owner' ||
			employeeRole === 'administrator' ||
			employeeRole === 'office-management' ||
			employeeRole === 'checker' ||
			employeeRole === 'harvester' ||
			employeeRole === 'tractor-operator' ||
			employeeRole === 'weighbridgeClerk' ||
			employeeRole === 'driver' ||
			employeeRole === 'grader'
		) {
			canDelete = false
			canEdit = false
		}
	}

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.empMgmt'),
			name: 'employee-management',
			isActive: true,
		},
		{
			label: t('breadcrumb.viewEmployee'),
			name: 'view-asset',
			isActive: false,
		},
	]

	/** Handlers */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'employee-management') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.ADMIN$SUBROOT,
					{
						rootModule: 'employee-management',
					}
				)
			)
		}
	}

	return (
		<Box padding={8} width="100%" height="100%">
			{isLoading && <TransparentBgSpinner />}
			<Modal
				forceCloseviaButton
				size="large"
				show={showModal}
				heading={t('viewEmployee.confirm')}
				body={
					<P large bold>
						{t('viewEmployee.confMessage')}
					</P>
				}
				closelabel={t('common.no')}
				confirmlabel={t('common.yes')}
				onClose={() => setShowModal(false)}
				onConfirm={() => {
					setShowModal(false)
					dispatch(
						AdminDuc.creators.deleteEmployee(
							documentReference,
							t('viewEmployee.empDeleteToast')
						)
					)
				}}
			/>
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Box
				row
				width="auto"
				alignItems="baseline"
				justifyContent="space-between"
			>
				<Title title={t('viewEmployee.empDetails')} />

				<Box row justifyContent="flex-end">
					{canEdit && !selectedCPID && (
						<Button
							label={t('common.edit')}
							extendStyles={{
								width: '150px',
							}}
							customIcon={
								<ButtonIconWrapper lightBG>
									<Icon glyph={EditIcon} />
								</ButtonIconWrapper>
							}
							rounded
							onClick={() => {
								dispatch(
									MainRouteDuc.creators.switchPage(
										MainRouteDuc.types.ADMIN$WDOCREFERENCE,
										{
											rootModule,
											action: 'edit-employee',
											documentReference,
										}
									)
								)
							}}
						/>
					)}
					<Spacer size={16} />
					{canDelete && !selectedCPID && (
						<Button
							label={t('common.delete')}
							customIcon={
								<IconWrapper lightBG size={25}>
									<Icon glyph={DeleteIcon} />
								</IconWrapper>
							}
							rounded
							onClick={() => {
								setShowModal(true)
							}}
						/>
					)}
				</Box>
			</Box>
			<EmployeeDetailsSegment
				firstName={firstName}
				designation={employeeData.designation}
				email={email}
				mobile={mobile}
				id={employeeData.id}
				orgRole={employeeRole}
			/>
			<Box row justifyContent="space-between" padding="8px 0">
				<Box style={{ maxWidth: 150 }}>
					<Button
						label={t('common.back')}
						rounded
						customIcon={
							<ButtonIconWrapper lightBG>
								<Icon glyph={LeftArrowIcon} />
							</ButtonIconWrapper>
						}
						onClick={() =>
							dispatch(
								MainRouteDuc.creators.switchPage(
									MainRouteDuc.types.ADMIN$SUBROOT,
									{
										rootModule: 'employee-management',
									}
								)
							)
						}
					/>
				</Box>
			</Box>
		</Box>
	)
}

export { ViewEmployee }
