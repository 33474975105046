/* @flow */
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
	Accordion as GrommetAccordion,
	AccordionPanel as GrommetAccordionPanel,
} from 'grommet'
import type { Node } from 'react'
import { BoxShadowWrapper } from '../Card'
import DownIcon from '../../icons/down.svg'
import SuccessIcon from '../../icons/success-round.svg'
import ErrorIcon from '../../icons/error-round.svg'
import { Icon } from '../../icons/components/Icon'
import { Box } from '../../utils/Box'
import { H3 } from '../Typography'

const IconWrapper = styled.div(p => ({
	width: 15,
	height: 15,
	'> svg': {
		transform: `${p.isExpanded ? 'rotateZ(180deg)' : 'rotateZ(0deg)'}`,
		transition: '0.7s transform',
	},
}))

const LeftIconWrapper = styled.div({
	width: 24,
	height: 24,
	marginRight: 10,
})

const Wrapper = styled.div(p => ({
	border: !p.noBorderColor ? '1px solid' : 'none',
	// eslint-disable-next-line no-nested-ternary
	borderColor: !p.noBorderColor
		? p.hasError
			? p.theme.color.warn
			: p.theme.color.success
		: 'none',
	borderRadius: 4,
	'& button[role=tab]': {
		outlineColor: 'inherit',
		borderColor: 'inherit',
		boxShadow: 'inherit',
		'& + div': {
			borderBottom: 'none',
		},
	},
	...(p.plain && {
		borderColor: p.theme.color.grey2,
	}),
}))

const Header = styled(Box)(p => ({
	padding: '8px 20px',
	...(p.isExpanded && {
		marginBottom: 2,
		borderBottom: '1px solid #E6E6E6',
		boxShadow: 'rgba(0, 0, 0, 0.1) 0 2px 6px',
	}),
}))

const InfoBar = styled.div(p => ({
	display: 'flex',
	alignItems: 'center',
	height: 32,
	width: 'inherit',
	padding: '0 20px',
	color: p.theme.color.white,
	fontSize: p.theme.fontSize.s,
	backgroundColor: p.hasError ? p.theme.color.warn : p.theme.color.success,
	...p.theme.fonts.regular,
}))

const ContentWrapper = styled.div(p => ({
	padding: '16px 20px',
	backgroundColor: p.theme.color.white,
	borderBottomLeftRadius: 4,
	borderBottomRightRadius: 4,
	margin: 1,
	display: p.show ? 'block' : 'none',
	boxShadow: 'rgba(0, 0, 0, 0.1) 0 2px 6px',
}))

type Props = {
	title: string,
	children: Node,
	body: Element<any> | string,
	expand?: boolean,
	onToggle: boolean => void,
	hasError?: boolean,
	message?: string,
	plain?: boolean,
	noBorderColor?: boolean,
}

export const CollapsableSegment = ({
	title,
	children,
	expand,
	body,
	onToggle,
	hasError,
	message,
	plain,
	noBorderColor = false,
}: Props) => {
	const [expanded, setExpanded] = useState(expand ? 0 : null)

	const handleToggle = ([_index]) => {
		const activeIndex = _index === 0 ? 0 : null
		setExpanded(activeIndex)
		onToggle(activeIndex === 0)
	}

	useEffect(() => setExpanded(expand ? 0 : null), [expand])

	let ActiveIcon = hasError ? ErrorIcon : SuccessIcon

	if (plain) ActiveIcon = null

	return (
		<BoxShadowWrapper>
			<Wrapper
				plain={plain}
				hasError={hasError}
				noBorderColor={noBorderColor}
			>
				<GrommetAccordion
					plain
					onActive={handleToggle}
					activeIndex={expanded}
				>
					<GrommetAccordionPanel
						plain
						header={
							<Header
								row
								alignItems="center"
								justifyContent="space-between"
								isExpanded={expanded === 0}
							>
								<Box row center>
									{!plain && !noBorderColor && (
										<LeftIconWrapper>
											<Icon glyph={ActiveIcon} />
										</LeftIconWrapper>
									)}
									<H3 bold primary>
										{title}
									</H3>
								</Box>
								<Box justifyContent="flex-end">{body}</Box>

								<IconWrapper isExpanded={expanded === 0}>
									<Icon glyph={DownIcon} />
								</IconWrapper>
							</Header>
						}
					>
						<ContentWrapper show={expanded === 0}>
							{children}
						</ContentWrapper>
					</GrommetAccordionPanel>
				</GrommetAccordion>
				{!plain && expanded === 0 && message && (
					<InfoBar hasError={hasError}>{message}</InfoBar>
				)}
			</Wrapper>
		</BoxShadowWrapper>
	)
}

CollapsableSegment.defaultProps = {
	onToggle: () => {},
}
