import React from 'react'
import { useDispatch } from 'react-redux'
import { MainRouteDuc } from 'core-app/routes/duc'
import { H2, SmallText } from 'ui-lib/components/Typography'
import { Button, ButtonIconWrapper } from 'ui-lib/components/Button'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import theme from 'ui-lib/utils/base-theme'
import SuccessIcon from 'ui-lib/icons/success-round.svg'
import LeftArrowIcon from 'ui-lib/icons/arrow_forward.svg'

const SuccessWithCP = () => {
	const dispatch = useDispatch()

	return (
		<Box
			style={{
				width: '100vw',
				height: '100vh',
				background: ' #1D285D',
				overflowY: 'scroll',
			}}
			center
			padding={8}
		>
			<Box center>
				<H2
					style={{
						fontSize: 40,
						color: theme.color.white,
						lineHeight: '52px',
						fontWeight: 'bold',
					}}
				>
					Excellent Work!
				</H2>
				<Spacer size={20} />
				<Box
					style={{
						width: '770px',
						height: 'fit-content',
						background: theme.color.white,
						borderRadius: '10px',
						boxShadow: '0px 3px 6px #00000029',
					}}
					padding={25}
					alignItems="center"
				>
					<IconWrapper color="#CE924B" size={70}>
						<Icon glyph={SuccessIcon} />
					</IconWrapper>
					<Spacer size={20} />
					<SmallText
						style={{
							color: '#2D2D2D',
							fontSize: '32px',
							lineHeight: '32px',
							width: '500px',
							fontWeight: 'bold',
							textAlign: 'center',
						}}
					>
						You have setup your collection points now
					</SmallText>
					<Spacer size={30} />
					<SmallText
						style={{
							color: '#555454',
							fontSize: '18px',

							textAlign: 'center',
						}}
					>
						Your organisation and collection points will receive
						account activation emails once accounts have been
						activated by DIBIZ. Until then you can manage all the
						trades from your organisation. DIBIZ takes maximum 2
						days for account activation. Thank you for your
						patience.
					</SmallText>
					<Spacer size={20} />

					<Spacer size={30} />
					<Box
						style={{
							width: '410px',
						}}
					>
						<Button
							action
							rounded
							label="Proceed to Homepage"
							customIcon={
								<ButtonIconWrapper color="white" lightBG>
									<Icon glyph={LeftArrowIcon} />
								</ButtonIconWrapper>
							}
							reverse
							onClick={() => {
								dispatch(
									MainRouteDuc.creators.switchPage(
										MainRouteDuc.types.DASHBOARD
									)
								)
							}}
							extendStyles={{
								background: '#3F56C4',
								color: theme.color.white,
							}}
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

export { SuccessWithCP }
