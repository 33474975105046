import React, { useState } from 'react'
import {
	ORG_ALIASES_KEYS,
	certKeys1,
	certKeys2,
	ORG_STATUS_TEXT,
} from 'core-app/modules/Admin/config'
import { MainRouteDuc } from 'core-app/routes/duc'
import { getIn } from 'timm'
import { Box } from 'ui-lib/utils/Box'
import { Card } from 'ui-lib/components/Card'
import { FileLists } from 'ui-lib/components/FileUpload'
import { ButtonWithNoBorder, Button } from 'ui-lib/components/Button'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Modal } from 'ui-lib/components/Modal'
import { AdminDuc } from 'core-app/modules/Admin/duc'
import { useDispatch, useSelector } from 'react-redux'
import { KeyValueSegment } from 'core-app/modules/Admin/components/KeyValueSegment'
import { supplyChainModels } from 'core-app/modules/Admin/components/StorageDetails'
import { SmallText, H3, P } from 'ui-lib/components/Typography'
import theme from 'ui-lib/utils/base-theme'
import RightArrow from 'ui-lib/icons/right-arrow.svg'
import styled from 'styled-components'
import {
	ORG_STATUS_BACKGROUND,
	ORG_STATUS_TEXT_COLOR,
} from 'core-app/utils/helpersFromMarketplace'

const Line = styled.div({
	borderBottom: '1px solid #CAD2DD',
	width: '100%',
})

const ChildOrgs = ({
	name,
	address,
	status,
	allDetails,
	setShowDetails,
	setSelectedOrg,
}) => {
	const {
		line1 = '',
		line2 = '',
		city = '',
		state = '',
		country = '',
	} = address

	return (
		<Box
			style={{
				background: '#FFFFFF 0% 0% no-repeat padding-box',
				border: '1px solid #CAD2DD',
				borderRadius: 10,
				opacity: 1,
				width: 960,
			}}
			padding="14px"
			margin="20px 0px 0px 0px"
			alignItems="center"
			justifyContent="space-between"
			row
		>
			<Box style={{ width: 580 }}>
				<Box alignItems="center" row>
					<P style={{ fontSize: 24, color: '#1D285D' }} bold>
						{name}
					</P>
					<P
						style={{
							marginLeft: '40px',
							backgroundColor: ORG_STATUS_BACKGROUND[status],
							fontSize: 16,
							color: ORG_STATUS_TEXT_COLOR[status],
							borderRadius: 5,
							padding: '5px 10px',
						}}
					>
						{ORG_STATUS_TEXT[status]}
					</P>
				</Box>
				<P style={{ color: '#555454', fontSize: theme.fontSize.m }}>
					{`${line1}, ${line2}, ${city}, ${state}, ${country} `}
				</P>
			</Box>
			<Button
				customIcon={
					<Box
						style={{
							height: 48,
							width: 48,
							borderRadius: '50%',
							background: '#F6F7F9',
							cursor: 'pointer',
						}}
						center
					>
						<IconWrapper>
							<Icon
								glyph={RightArrow}
								size={20}
								color="#CAD2DD"
							/>
						</IconWrapper>
					</Box>
				}
				extendStyles={{ marginLeft: 150 }}
				onClick={() => {
					setShowDetails(true)
					setSelectedOrg(allDetails)
				}}
			/>
		</Box>
	)
}

const NoCP = ({ t }) => {
	const dispatch = useDispatch()

	return (
		<Box
			style={{
				alignItems: 'center',
				justifyContent: 'center',
				padding: '20px',
			}}
		>
			<Spacer size={40} />
			<H3>{t('viewCompanyInfo.noCollectionPoints')}</H3>
			<Spacer size={20} />
			<P>{t('viewCompanyInfo.addNewCollectionPoint')}</P>
			<Spacer size={20} />
			<Box
				style={{
					width: '410px',
				}}
			>
				<Button
					action
					rounded
					label={t('viewCompanyInfo.addCollectionPoint')}
					reverse
					onClick={() => {
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.ADMIN$WACTION,
								{
									rootModule: 'collection-point',
									action: 'add-cp',
								}
							)
						)
					}}
					extendStyles={{
						background: '#3F56C4',
						color: theme.color.white,
					}}
				/>
			</Box>
			<Spacer size={40} />
		</Box>
	)
}

const CPlisting = ({ childOrgs, setShowDetails, setSelectedOrg, t }) => {
	const dispatch = useDispatch()

	return (
		<Box padding="20px">
			<Box row justifyContent="flex-end">
				<Spacer size={16} />

				<ButtonWithNoBorder
					label={`+ ${t('onboarding.addNew')}`}
					action
					rounded
					onClick={() => {
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.ADMIN$WACTION,
								{
									rootModule: 'collection-point',
									action: 'add-cp',
								}
							)
						)
					}}
				/>
			</Box>

			<Box style={{ width: '100%' }}>
				{childOrgs.map(d => (
					<ChildOrgs
						key={d.name}
						name={d?.name}
						address={d?.primaryAddress}
						status={d?.status?.state}
						allDetails={d}
						docs={d?.docs}
						setSelectedOrg={setSelectedOrg}
						setShowDetails={setShowDetails}
					/>
				))}
			</Box>
		</Box>
	)
}

const CertificationBlock = ({ certDetails, isMobile, t }) => {
	const scModel = getIn(certDetails, ['scmodel'])

	return (
		<Box row={!isMobile} padding="20px">
			<Card
				style={{
					padding: '20px',
					position: 'relative',
					overflow: 'visible',
					boxShadow: ' 0px 6px 16px #00000014',
				}}
				textAlign="left"
			>
				<SmallText
					style={{
						position: 'absolute',
						fontSize: theme.fontSize.m,
						color: theme.color.accent2,
						top: '-10px',
						left: '20px',
						display: 'inline-block',
						backgroundColor: theme.color.white,
						padding: '0 8px',
					}}
				>
					{getIn(certDetails, ['certOrg'])}
				</SmallText>

				<Box row style={{ width: '100%' }}>
					<Box style={{ width: '50%' }}>
						{certKeys1.map(key => {
							return (
								<KeyValueSegment
									key={key}
									labelWidth="50%"
									label={t(ORG_ALIASES_KEYS[key])}
									value={certDetails[key]}
								/>
							)
						})}
					</Box>
					<Box style={{ width: '50%' }}>
						{certKeys2.map(key => {
							return (
								<KeyValueSegment
									key={key}
									labelWidth="50%"
									label={t(ORG_ALIASES_KEYS[key])}
									value={
										key === 'scmodel'
											? t(supplyChainModels[scModel])
											: certDetails[key]
									}
								/>
							)
						})}
						<FileLists
							documents={certDetails.files || []}
							isReadOnly
						/>
					</Box>
				</Box>
			</Card>
		</Box>
	)
}

const CPViewDetails = ({
	isMobile,
	setShowDetails,
	selectedOrg,
	showEditModal,
	setShowEditModal,
	t,
}) => {
	const { name, primaryAddress, primaryContact, docs, status } = selectedOrg
	const {
		line1 = '',
		line2 = '',
		city = '',
		state = '',
		country = '',
	} = primaryAddress
	const { mobile, email } = primaryContact
	const { state: orgStatus } = status

	return (
		<Box padding="20px">
			<Modal
				hideButtons
				forceCloseviaButton
				size="500px"
				show={showEditModal}
				body={
					<Box alignItems="center" justifyContent="center">
						<SmallText
							style={{
								fontSize: 18,
								color: '#1F6584',
							}}
						>
							{t('viewCompanyInfo.info')}
						</SmallText>
						<H3>{t('viewCompanyInfo.upcomingFeature')}</H3>
						<SmallText
							style={{
								fontSize: 16,
								color: '#989EA9',
								padding: '10px',
							}}
						>
							{t('viewCompanyInfo.featureInformation')}
						</SmallText>

						<SmallText
							style={{
								fontSize: 16,
								color: '#555454',
							}}
						>
							{t('viewCompanyInfo.featInformationText')}
						</SmallText>
						<Button
							action
							rounded
							label={t('common.close')}
							reverse
							onClick={() => {
								setShowEditModal(false)
							}}
							extendStyles={{
								margin: '10px',
								background: '#3F56C4',
								color: theme.color.white,
							}}
						/>
					</Box>
				}
			/>
			<Box row justifyContent="space-between">
				<ButtonWithNoBorder
					label={t('common.back')}
					action
					rounded
					onClick={() => {
						setShowDetails(false)
					}}
				/>
				<Spacer size={16} />

				<ButtonWithNoBorder
					label={t('common.edit')}
					action
					rounded
					onClick={() => {
						setShowEditModal(true)
					}}
				/>
			</Box>
			<Card
				width={isMobile ? '320px' : '100%'}
				height="auto"
				textAlign="left"
			>
				<Box style={{ width: '100%' }}>
					<Box row padding="10px 20px" justifyContent="flex-end">
						<P
							style={{
								marginLeft: '40px',
								backgroundColor:
									ORG_STATUS_BACKGROUND[orgStatus],
								fontSize: 16,
								color: ORG_STATUS_TEXT_COLOR[orgStatus],
								borderRadius: 5,
								padding: '5px 10px',
							}}
						>
							{ORG_STATUS_TEXT[orgStatus]}
						</P>
					</Box>

					<KeyValueSegment
						labelWidth="50%"
						label={t('invitePartner.orgName')}
						value={name}
					/>
					<KeyValueSegment
						labelWidth="50%"
						label={t('auditReport.address')}
						value={`${line1}, ${line2}, ${city}, ${state}, ${country} `}
					/>
					<KeyValueSegment
						labelWidth="50%"
						label={t('invitePartner.phoneNo')}
						value={mobile}
					/>
					<KeyValueSegment
						labelWidth="50%"
						label={t('empMgmtHome.email')}
						value={email}
					/>
					<Box padding="0px 10px">
						<Line />
					</Box>
					{(docs || []).map(certificate => (
						<CertificationBlock
							key={certificate.id}
							isMobile={isMobile}
							t={t}
							certDetails={certificate}
						/>
					))}
				</Box>
			</Card>
		</Box>
	)
}

const CollectionPoint = ({ isMobile, t }) => {
	const [showDetails, setShowDetails] = useState(false)
	const [showEditModal, setShowEditModal] = useState(false)
	const [selectedOrg, setSelectedOrg] = useState({})
	const childOrgs = useSelector(AdminDuc.selectors.getChildOrgsDetails)

	if (childOrgs.length === 0) {
		return <NoCP t={t} />
	}
	if (childOrgs.length >= 0 && !showDetails) {
		return (
			<CPlisting
				childOrgs={childOrgs}
				setSelectedOrg={setSelectedOrg}
				setShowDetails={setShowDetails}
				t={t}
			/>
		)
	}
	if (childOrgs.length >= 0 && showDetails) {
		return (
			<CPViewDetails
				isMobile={isMobile}
				setShowDetails={setShowDetails}
				selectedOrg={selectedOrg}
				showEditModal={showEditModal}
				setShowEditModal={setShowEditModal}
				t={t}
			/>
		)
	}
}
export default CollectionPoint
