import React, { useMemo, useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { Box } from 'ui-lib/utils/Box'
import { Button, ButtonIconWrapper } from 'ui-lib/components/Button'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import { Icon } from 'ui-lib/icons/components/Icon'
import { CollapsableSegment } from 'ui-lib/components/CollapsableSegments'
import { BoxShadowTable } from 'core-app/shared/components/BoxShadowTable'
import { addLast, merge, getIn } from 'timm'
import { useTranslation } from 'react-i18next'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Label } from 'ui-lib/components/Typography'
import { InventoryHandler } from 'core-app/modules/Refinery/components/InventoryHandler'
import { Modal } from 'ui-lib/components/Modal'
import * as yup from 'yup'
import { useFormik } from 'formik'
import theme from 'ui-lib/utils/base-theme'
import { isEmptyObject } from 'core-app/utils/helpers'
import { RefineryDuc, NAME_ALIASES } from '../../duc'
import { getPlotInputColumnConfig } from '../../components/Columns'
import { WebTourDuc } from '../../../WebTour/duc'
import { WebTour } from '../../../WebTour'

const CTAWrapper = styled(Box)(p => ({
	width: 'fit-content',
	padding: 12,
	background: p.theme.color.white,
	color: p.theme.color.primary,
}))

const initialActiveBatch = activeIndex => {
	return {
		activeIndex,
		date: new Date().toISOString(),
		productID: '',
		quantity: '',
		productionLine: '',
		storageUnits: [],
	}
}

const validationSchema = () =>
	yup.object().shape({
		traces: yup
			.array()
			.of(
				yup.object().shape({
					productID: yup
						.string()
						.strict()
						.required(),
				})
			)
			.strict()
			.required(),
	})

const CreatePlotInput = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const storageList = useSelector(AuthDuc.selectors.getStorageUnits)
	const allProducts = useSelector(AuthDuc.selectors.getProducts)
	const locationState = useSelector(RefineryDuc.selectors.location)
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)
	const { productionLine = [] } = useSelector(AuthDuc.selectors.getOrgAssets)

	const { payload = {} } = locationState
	const { rootModule } = payload

	const activeProducts = allProducts.map(
		({ availableQty, name, id, uom: _uom }) => {
			return {
				id,
				label: `${name} - (${
					availableQty.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0]
				} ${_uom})`,
				availableQty,
			}
		}
	)

	const [trigger, setTrigger] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [storageTanks, setStorageTanks] = useState([])
	const [productDetails, setProductDetails] = useState({})
	const [lastUpdatedIndex, setLastUpdatedIndex] = useState(0)
	const [currentIndex, setCurrentIndex] = useState(0)
	const [activeDocuments, setActiveDocuments] = useState([
		initialActiveBatch(0),
	])

	useEffect(() => {
		if (tourModule === 'createIpBatch') {
			dispatch(WebTourDuc.creators.setActiveTourModule('createIpBatch2'))
		}
	}, [tourModule, dispatch])

	const getBreadCrumbsList = useCallback(
		() => [
			{
				label: t('breadcrumb.home'),
				name: 'home',
				isActive: true,
			},
			{
				label: t('breadcrumb.production'),
				name: 'production',
				isActive: true,
			},
			{
				label: t('breadcrumb.createPlotInput'),
				name: 'production-plot-input',
				isActive: false,
			},
		],
		[t]
	)

	/** Handlers */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'production') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.REFINERY$SUBROOT,
					{
						rootModule: 'production',
					}
				)
			)
		}
	}

	const { submitForm, setFieldValue, isSubmitting, errors } = useFormik({
		initialValues: {
			traces: [],
		},
		validationSchema: validationSchema(),
		enableReinitialize: true,
		onSubmit: (_values, { setSubmitting }) => {
			dispatch(
				RefineryDuc.creators.createPlotInput(
					_values,
					storageList,
					{
						setSubmitting,
					},
					t('production.plotInputSuccessToast')
				)
			)
		},
	})

	const storageTanksAssigned = activeDocuments.filter(
		doc => doc.storageUnits.length > 0
	)

	const isEveryProductAssignedWithStorageTank =
		activeDocuments.length === storageTanksAssigned.length

	const disableCTA =
		!isEmptyObject(errors) ||
		!trigger ||
		!isEveryProductAssignedWithStorageTank

	const handleChange = useCallback(
		(action, meta) => {
			const updatedActiveDocuments = [...activeDocuments]
			if (action === 'add_Values') {
				const { rowIndex, value, type } = meta

				if (type === 'date') {
					updatedActiveDocuments[rowIndex] = merge(
						updatedActiveDocuments[rowIndex],
						{
							date: value,
						}
					)
				}
				if (type === 'productID') {
					updatedActiveDocuments[rowIndex] = merge(
						updatedActiveDocuments[rowIndex],
						{
							storageUnits: [],
							productID: value,
						}
					)
				}
				if (type === 'productionLine') {
					updatedActiveDocuments[rowIndex] = merge(
						updatedActiveDocuments[rowIndex],
						{
							productionLine: value,
						}
					)
				}
				if (type === 'storageUnits') {
					updatedActiveDocuments[rowIndex] = merge(
						updatedActiveDocuments[rowIndex],
						{
							storageUnits: value,
						}
					)
					setTrigger(true)
				}
				setActiveDocuments(updatedActiveDocuments)
				setFieldValue('traces', updatedActiveDocuments)
				setLastUpdatedIndex(rowIndex)
			} else if (action === 'add_new_row') {
				const addRow = addLast(
					activeDocuments,
					initialActiveBatch(activeDocuments.length)
				)
				setActiveDocuments(addRow)
				setTrigger(false)
			}
		},
		[setFieldValue, activeDocuments, setLastUpdatedIndex]
	)
	const handleClose = () => {
		setShowModal(false)
	}
	const assignTank = (currentRow, productID) => {
		setCurrentIndex(currentRow)
		const tankDetails =
			getIn(activeDocuments, [currentRow, 'storageUnits']) || []
		setStorageTanks(tankDetails)
		setShowModal(!showModal)

		setProductDetails({
			productID,
		})
	}

	const title = NAME_ALIASES[rootModule]
	const breadCrumbs = useMemo(() => getBreadCrumbsList({ title }), [
		title,
		getBreadCrumbsList,
	])

	return (
		<>
			<Box padding={8} width="100%" height="100%">
				<Box style={{ padding: '0 5' }}>
					<Breadcrumb
						links={breadCrumbs}
						onClick={target => handleBreadCrumbClick(target)}
					/>
				</Box>
				<Spacer size={24} />
				<Box id="input-enterDataProdn">
					<CollapsableSegment
						expand
						title={t('production.enterProductionDetails')}
						hasError={disableCTA}
						open
						message={
							disableCTA
								? t('validation.collapsableSegmentWarning')
								: t('validation.collapsableSegmentSuccess')
						}
						onToggle={() => undefined}
					>
						<Box>
							<Spacer size={14} />
							<div
								style={{
									display: 'flex',
									justifyContent: 'end',
									color: theme.color.themeGreen2,
								}}
							>
								<div>
									{`* ${t(
										'production.productionMappingText'
									)}`}
								</div>
							</div>
							<Spacer size={10} />
							<BoxShadowTable
								size="large"
								key={`
									${activeDocuments[lastUpdatedIndex].productID}-${activeDocuments[lastUpdatedIndex].productionLine}
								`}
								columnConfig={getPlotInputColumnConfig({
									type: 'plot',
									docType: 'plot',
									handleChange,
									activeProducts,
									assignTank,
									productionLine,
									t,
									errors,
									activeDocuments,
								})}
								rowData={activeDocuments}
							/>
							<Modal
								noPadding
								show={showModal}
								size="large"
								body={
									<InventoryHandler
										activeTanks={storageTanks}
										storageList={storageList}
										onClose={handleClose}
										productDetails={productDetails}
										onChange={value => {
											handleChange('add_Values', {
												rowIndex: currentIndex,
												value,
												type: 'storageUnits',
											})
											setShowModal(false)
										}}
									/>
								}
								hideButtons
								onClose={handleClose}
							/>
							<Spacer size={8} />
							<CTAWrapper
								onClick={() => handleChange('add_new_row', {})}
							>
								<Label color="inherit" bold small link as="a">
									{t('production.addMore')}
								</Label>
							</CTAWrapper>
						</Box>
					</CollapsableSegment>
				</Box>
				<Spacer size={24} />
				<Box row justifyContent="space-between">
					<Box style={{ padding: 4 }} width="200px">
						<Button
							label={t('common.back')}
							rounded
							customIcon={
								<ButtonIconWrapper lightBG>
									<Icon glyph={LeftArrowIcon} />
								</ButtonIconWrapper>
							}
							onClick={() =>
								dispatch(
									MainRouteDuc.creators.switchPage(
										MainRouteDuc.types.REFINERY$SUBROOT,
										{
											rootModule: 'production',
										}
									)
								)
							}
						/>
					</Box>
					<Box
						id="createIpSubmit"
						style={{ padding: 2 }}
						width="200px"
					>
						<Button
							disabled={disableCTA}
							isLoading={isSubmitting}
							label={t('common.submit')}
							primary
							rounded
							onClick={() => {
								submitForm()
								dispatch(
									WebTourDuc.creators.setActiveTourModule('')
								)
							}}
						/>
					</Box>
				</Box>
			</Box>
			{tourModule === 'createIpBatch2' && <WebTour />}
		</>
	)
}

export { CreatePlotInput }
