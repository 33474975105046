import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ProductsDuc } from 'core-app/modules/Products/duc'
import { AdminDuc } from 'core-app/modules/Admin/duc'
import { ViewCarbonNumber } from 'core-app/modules/Admin/containers/CompanyInformation/ViewCarbonNumber'
import { CarbonNumberForm } from 'core-app/modules/Onboard/components/CarbonNumberForm'
import { Box } from 'ui-lib/utils/Box'

const AddCarbonNumber = ({ carbonNumber }) => {
	const dispatch = useDispatch()
	const products = useSelector(
		ProductsDuc.selectors.getProductsListWithPagination
	)
	const pagination = useSelector(ProductsDuc.selectors.getPaginationQuery)
	const carbonNumberEditFlag = useSelector(
		AdminDuc.selectors.getCarbonNumberEditFlag
	)

	const initialValues = {
		baseYear: carbonNumber?.baseYear || '',
		scope1: carbonNumber?.scope1 || '',
		scope2: carbonNumber?.scope2 || '',
		files: carbonNumber?.files || [],
		auditedBy: carbonNumber?.auditedBy || '',
		reportYear: carbonNumber?.reportYear || '',
		productLCVFile: carbonNumber?.productLCVFile || [],
		line1: carbonNumber?.auditorAddress?.line1 || '',
		line2: carbonNumber?.auditorAddress?.line2 || '',
		biogasCaptureDocumentProof: carbonNumber?.biogasCaptureDocumentProof,
		biogasCaptureFlag: carbonNumber?.biogasCaptureFlag,
	}

	const handleAddCarbonNumber = (
		payload,
		updatedProducts,
		setSubmitting,
		currentFormData = {},
		productLcvFile = false
	) => {
		dispatch(
			AdminDuc.creators.addCarbonNumber(
				payload,
				updatedProducts,
				setSubmitting,
				currentFormData,
				productLcvFile,
				carbonNumber.id ? carbonNumber.id : null, // update only
				!!carbonNumber.id // update key, update only
			)
		)
	}

	const handleProductsListingWithPagination = paginationParams => {
		dispatch(
			ProductsDuc.creators.fetchProductsListingWithPagination(
				paginationParams,
				20
			)
		)
	}
	const handleEdit = value => {
		dispatch(AdminDuc.creators.setCarbonNumberEditFlag(value))
	}

	return (
		<>
			{carbonNumber.id && !carbonNumberEditFlag ? (
				<ViewCarbonNumber
					carbonNumber={carbonNumber}
					setEdit={handleEdit}
				/>
			) : (
				<Box padding={50}>
					<CarbonNumberForm
						pagination={pagination}
						initialValues={initialValues}
						handleAddCarbonNumber={handleAddCarbonNumber}
						handleProductsListingWithPagination={
							handleProductsListingWithPagination
						}
						products={products}
						dispatch={dispatch}
					/>
				</Box>
			)}
		</>
	)
}

export { AddCarbonNumber }
