import React, { useState, useRef } from 'react'
import { Drop } from 'grommet'
import { Box } from 'ui-lib/utils/Box'
import { useTranslation } from 'react-i18next'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { useDateByFormat } from 'core-app/utils/date'
import { Label } from 'ui-lib/components/Label'
import { P } from 'ui-lib/components/Typography'
import styled from 'styled-components'
import theme from 'ui-lib/utils/base-theme'
import EditNoteSvg from 'ui-lib/icons/edit_note.svg'

const LinkBlock = styled.span(({ color }) => ({
	textAlign: 'right',
	color,
	fontSize: '12px',
	alignSelf: 'right',
	marginTop: '8px',
}))

const Message = ({
	message: {
		content: { message: msg },
		senderDisplayName,
		id,
		metadata: { senderID } = {},
		createdOn,
	},
	canEditDelete = true,
	senderOrgId,
	handleClick,
}) => {
	const { t } = useTranslation()
	const isSender = senderID === senderOrgId
	const bgColor = isSender ? '#E8E7E7' : '#3C6BD9'
	const textColor = isSender ? '#4A5255' : 'white'
	const dateColor = isSender ? '#8F9394' : '#BBCCF2'
	const [showMenu, setShowMenu] = useState(false)
	const ref = useRef()

	return (
		<Box key={id}>
			{isSender && showMenu && canEditDelete && (
				<Box
					style={{
						width: '44px',
					}}
				>
					<Drop
						stretch={false}
						onEsc={() => setShowMenu(false)}
						onClickOutside={() => setShowMenu(false)}
						target={ref.current}
						align={{
							top: 'bottom',
							right: 'right',
						}}
					>
						<Box
							row
							alignItems="center"
							padding={4}
							style={{
								borderBottom: '1px solid #D9DEE5',
							}}
						>
							<IconWrapper size={20}>
								<Icon
									color={theme.color.green}
									glyph={EditNoteSvg}
									size={10}
								/>
							</IconWrapper>
							<Label
								color="#fff"
								small
								key=",s"
								style={{
									padding: 8,
									cursor: 'pointer',
									color: theme.color.green,
								}}
								onClick={() => {
									setShowMenu(false)
									handleClick(id, msg)
								}}
							>
								{t('common.edit')}
							</Label>
						</Box>
					</Drop>
				</Box>
			)}
			<Box
				ref={ref}
				onClick={() => (!showMenu ? setShowMenu(true) : null)}
				style={{
					background: bgColor,
					borderRadius: isSender
						? '10px 0px 10px 10px'
						: '0px 10px 10px 10px',
				}}
				padding="10px"
				margin={isSender ? '0px 0px 10px 50px' : '0px 50px 10px 0px'}
			>
				<P
					style={{
						fontSize: '14px',
						fontWeight: '600',
					}}
					color={textColor}
				>
					{senderDisplayName}
				</P>
				<P
					style={{ marginTop: '8px', fontSize: '14px' }}
					color={textColor}
				>
					{msg}
				</P>
				<Box justifyContent="flex-end">
					<LinkBlock
						alignSelf="end"
						size="13px"
						textAlign="right"
						color={dateColor}
					>
						{useDateByFormat(createdOn, 'd LLL yyyy, hh:mm a')}
					</LinkBlock>
				</Box>
			</Box>
		</Box>
	)
}

export { Message }
