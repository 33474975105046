import React from 'react'
import { useTranslation } from 'react-i18next'
import { KeyValueSegment } from 'core-app/modules/Admin/components/KeyValueSegment'
import { ButtonWithNoBorder, ButtonIconWrapper } from 'ui-lib/components/Button'
import { FileLists } from 'ui-lib/components/FileUpload'
import EditIcon from 'ui-lib/icons/edit.svg'
import { Icon } from 'ui-lib/icons/components/Icon'
import { Box } from 'ui-lib/utils/Box'
import { Card } from 'ui-lib/components/Card'
import { Label } from 'ui-lib/components/Label'
import { SmallText } from 'ui-lib/components/Typography'
import theme from 'ui-lib/utils/base-theme'

export const carbonNumberKeys1 = ['scope1', 'baseYear', 'auditedBy']
export const carbonNumberKeys2 = ['scope2', 'reportYear', 'address']

export const CARBON_NUMBER_ALIAS = {
	scope1: 'onboarding.scope1',
	scope2: 'onboarding.scope2',
	auditedBy: 'onboarding.auditedBy',
	baseYear: 'onboarding.yearConsideredBase',
	reportYear: 'onboarding.yearOfReport',
	address: 'auditReport.address',
}

const ViewCarbonNumber = ({ carbonNumber, setEdit }) => {
	const { t } = useTranslation()

	return (
		<Box row padding="20px">
			<Card
				style={{
					padding: '20px',
					position: 'relative',
					overflow: 'visible',
					boxShadow: ' 0px 6px 16px #00000014',
				}}
				textAlign="left"
			>
				<SmallText
					style={{
						position: 'absolute',
						fontSize: theme.fontSize.m,
						color: theme.color.accent2,
						top: '-10px',
						left: '20px',
						display: 'inline-block',
						backgroundColor: theme.color.white,
						padding: '0 8px',
					}}
				>
					{t('viewCompanyInfo.carbonNumber')}
				</SmallText>
				<Box style={{ alignItems: 'end' }}>
					<ButtonWithNoBorder
						label={t('common.edit')}
						action
						rounded
						customIcon={
							<ButtonIconWrapper lightBG>
								<Icon glyph={EditIcon} />
							</ButtonIconWrapper>
						}
						onClick={() => {
							setEdit(true)
						}}
					/>
				</Box>
				<Box row style={{ width: '100%' }}>
					<Box style={{ width: '50%' }}>
						{carbonNumberKeys1.map(key => {
							return (
								<div style={{ height: '45px' }} key={key}>
									<KeyValueSegment
										labelWidth="40%"
										label={t(CARBON_NUMBER_ALIAS[key])}
										value={carbonNumber[key]}
									/>
								</div>
							)
						})}
						{carbonNumber?.productLCVFile?.length > 0 && (
							<Box padding={12}>
								<Label>{t('onboarding.productLCVFile')}</Label>
								<FileLists
									documents={
										carbonNumber?.productLCVFile || []
									}
									isReadOnly
								/>
							</Box>
						)}
					</Box>
					<Box style={{ width: '50%' }}>
						{carbonNumberKeys2.map(key => {
							return (
								<div style={{ height: '45px' }} key={key}>
									<KeyValueSegment
										labelWidth="40%"
										label={t(CARBON_NUMBER_ALIAS[key])}
										value={
											key === 'address' ? (
												<div>
													<div>
														{
															carbonNumber
																?.auditorAddress
																?.line1
														}
													</div>
													<div>
														{
															carbonNumber
																?.auditorAddress
																?.line2
														}
													</div>
												</div>
											) : (
												carbonNumber[key]
											)
										}
									/>
								</div>
							)
						})}
						{carbonNumber?.biogasCaptureDocumentProof?.length >
							0 && (
							<Box padding={12}>
								<Label>POME Gas Extraction Proof</Label>
								<FileLists
									documents={
										carbonNumber?.biogasCaptureDocumentProof ||
										[]
									}
									isReadOnly
								/>
							</Box>
						)}
						<Box padding={12}>
							<Label>{`ESG ${t('onboarding.report')}`}</Label>
							<FileLists
								documents={carbonNumber.files || []}
								isReadOnly
							/>
						</Box>
					</Box>
				</Box>
			</Card>
		</Box>
	)
}

export { ViewCarbonNumber }
