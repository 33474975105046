import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { AuthDuc } from 'core-app/modules/Auth/duc'

const VerifySocial = () => {
	const { query = {} } = useSelector(AuthDuc.selectors.location)

	useEffect(() => {
		if (window.opener !== null) {
			if (query.code && query.state) {
				localStorage.setItem('microsoft', query.code)
			}
			window.close()
		}
	})

	return null
}

export default VerifySocial
