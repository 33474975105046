import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import request from 'core-app/utils/request'
import { getCoreEndPoint } from 'core-app/config'
import { Box as GrommBox } from 'grommet'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { H2, P } from 'ui-lib/components/Typography'
import { CustomSelectSearch } from 'ui-lib/components/Select'
import theme from 'ui-lib/utils/base-theme'
import { Modal } from 'ui-lib/components/Modal'
import { useFormik } from 'formik'
import * as yup from 'yup'

const ProductsModal = ({
	show,
	onClose,
	onProductConfirm,
	productsToBeDisabled,
}) => {
	const { t } = useTranslation()
	const [globalProducts, setGlobalProducts] = useState([])

	useEffect(() => {
		async function fetchProduct() {
			const requestUrl = `${getCoreEndPoint()}products/all?masterCategory=palm-oil&subCategory=palm-oil`
			const { data = {} } = await request(requestUrl)
			setGlobalProducts(data.list)
		}

		fetchProduct()
	}, [setGlobalProducts])

	const validationSchema = yup.object().shape({
		products: yup.array(),
	})

	const {
		handleSubmit,
		handleBlur,
		errors,
		touched,
		setFieldValue,
		submitForm,
	} = useFormik({
		initialValues: {
			products: [],
		},
		enableReinitialize: true,
		validationSchema,
		onSubmit: _values => {
			onProductConfirm(_values)
		},
	})

	return (
		<Modal
			forceCloseviaButton
			show={show}
			size="600px"
			heading={
				<Box>
					<H2 style={{ color: '#4B4552', fontSize: 28 }}>
						{t('products.addNewProducts')}
					</H2>
					<P
						style={{
							color: theme.color.grey14,
							fontSize: theme.fontSize.l,
						}}
					>
						{t('products.addNewProductsHeading')}
					</P>
				</Box>
			}
			body={
				<div>
					<GrommBox pad="0px 48px" gap="small" width="600px" overflow>
						<Spacer size={20} />
						<form onSubmit={handleSubmit}>
							<Box width={540} margin="0 0 30px">
								<CustomSelectSearch
									disabledArray={productsToBeDisabled}
									name="products"
									placeholder={t('marketPlace.searchProduct')}
									options={globalProducts}
									onBlur={handleBlur}
									error={touched.products && errors.products}
									onChange={value => {
										setFieldValue('products', value)
									}}
									emptySearchMessage={t(
										'products.noProductsFound'
									)}
								/>
							</Box>
						</form>
					</GrommBox>
				</div>
			}
			closelabel={t('common.cancel')}
			confirmlabel={t('common.proceed')}
			onClose={onClose}
			onConfirm={() => submitForm()}
		/>
	)
}

export { ProductsModal }
