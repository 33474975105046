import { EndManufacturerDuc } from 'core-app/modules/EndManufacturer/duc'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import saga from 'core-app/modules/EndManufacturer/EndManufacturerSaga'

export default async (dispatch, getState, { action, extra }) => {
	const { type, customMeta = {} } = action

	/** if someone specifically wants to skip thunk fetch again, this check helps */
	const { skipRouteThunk } = customMeta
	if (skipRouteThunk) return

	// check if nothing changed in the route.

	// eslint-disable-next-line no-unused-vars

	// Inject the saga here. If it wasn't already
	extra.getSagaInjectors().injectSaga('endManufacturer', { saga })
	dispatch(AuthDuc.creators.fetchActor())
	const isMainDashboard = MainRouteDuc.types.END_MANUFACTURER === type

	let activeModule = ''

	if (isMainDashboard) {
		// load the dashboard of the core module
		activeModule = 'dashboard'
		dispatch(EndManufacturerDuc.creators.fetchInsights())
	}
	dispatch(EndManufacturerDuc.creators.setActiveModule(activeModule))
}
