import { Box as GrommBox } from 'grommet'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { ChatClient } from '@azure/communication-chat'
import { AzureCommunicationTokenCredential } from '@azure/communication-common'
import { getChatEndPoint } from 'core-app/config'
import { AppDuc } from 'core-app/modules/App/duc'
import { MessagesDuc } from 'core-app/modules/Messages/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { getIn } from 'timm'
import {
	ButtonIconWrapper,
	RoundedButton,
} from 'ui-lib/components/Button/index'
import { TransparentBgSpinner } from 'ui-lib/components/Spinner/TransparentBgSpinner'
import { getRandomNumber } from 'core-app/utils/helpers'
import { Input } from 'ui-lib/components/Input'
import { Icon } from 'ui-lib/icons/components/Icon'
import SendSvg from 'ui-lib/icons/send.svg'
import theme from 'ui-lib/utils/base-theme'
import { Box } from 'ui-lib/utils/Box'
import { MessageGroup } from './MessageGroup'

const SendButton = styled(RoundedButton)`
	border-radius: 150px;
	height: 48px;
	width: 48px;
	right: auto;
	bottom: auto;
	position: relative !important;
	background: ${p => p.theme.color.orange};
	box-shadow: none !important;
	color: ${p => (p.primary ? p.theme.color.white : p.theme.color.primary)};
`
const WindowHeader = styled.div`
	height: 47px;
	padding-left: 12px;
	border-bottom: 2px solid ${theme.color.grey4};
`
const HeaderText = styled.div`
	font-size: 14px;
	font-weight: 500;
	overflow: hidden;
	padding-bottom: 5px;
	white-space: nowrap;
	text-overflow: ellipsis;
	color: ${theme.color.blue8};
`
const SubHeaderText = styled.div`
	font-size: 12px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	color: ${theme.color.grey15};
`

const ChatWindow = ({
	tradeId,
	partnerId,
	senderOrgId,
	header = '',
	subHeader = '',
	chatThreadId = '',
	isIframeForTeamsApp = false,
}) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [chatText, setChatText] = useState('')
	const [editMsgIndex, setEditMsgIndex] = useState('')
	const [messages, setMessages] = useState([])
	const [disableButton, setDisableButton] = useState(false)
	const [chat, setChatClient] = useState(null)
	const [currentId, setCurrentId] = useState('')
	const [textBoxKey, setTextBoxKey] = useState(0)
	const token = useSelector(MessagesDuc.selectors.getChatToken)

	const { firstName, orgRole, id: userId, organization } = useSelector(
		AuthDuc.selectors.getUserProfile
	)

	useEffect(() => {
		setMessages([])
	}, [chatThreadId])

	useEffect(() => {
		dispatch(
			MessagesDuc.creators.sendMessageAction(chatThreadId, 'reset-unread')
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const loadMessageByID = async id => {
		try {
			if (!chatThreadId || !chat) {
				return
			}
			const chatThreadClient = chat.getChatThreadClient(chatThreadId)

			const message = await chatThreadClient.getMessage(id)
			if (currentId !== id) {
				setMessages(ms =>
					getIn(ms, [ms.length - 1, 'id']) !== id
						? [...ms, message]
						: ms
				)
				dispatch(
					MessagesDuc.creators.sendMessageAction(
						chatThreadId,
						'reset-unread'
					)
				)
			}
		} catch (err) {
			const { message } = err
			dispatch(
				AppDuc.creators.showToast({
					messageType: 'error',
					message,
				})
			)
		}
	}

	const loadMessages = useCallback(
		async (list = {}) => {
			try {
				const threadID = chatThreadId

				if (!threadID || !chat) {
					return
				}
				const chatThreadClient = await chat.getChatThreadClient(
					threadID
				)
				const msg = []
				for await (const chatMessage of chatThreadClient.listMessages()) {
					msg.push(chatMessage)
				}
				msg.sort((a, b) => a.sequenceId - b.sequenceId)
				if (list && getIn(list, ['id'])) {
					const message = await chatThreadClient.getMessage(
						getIn(list, ['id'])
					)
					const newState = msg.map(obj => {
						if (obj.id === getIn(list, ['id'])) {
							return message
						}

						return obj
					})
					setMessages(newState)
					dispatch(
						MessagesDuc.creators.sendMessageAction(
							chatThreadId,
							'reset-unread'
						)
					)
				} else {
					setMessages(msg)
				}
			} catch (err) {
				const { message } = err
				dispatch(
					AppDuc.creators.showToast({
						messageType: 'error',
						message,
					})
				)
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[chat, chatThreadId]
	)

	const startNotification = useCallback(async () => {
		try {
			if (!chat || chat === null) {
				return
			}
			if (!chat?.isRealtimeNotificationsStarted) {
				await chat.startRealtimeNotifications()
				chat.on('chatMessageReceived', ({ id, threadId, metadata }) => {
					if (
						threadId === chatThreadId &&
						currentId !== id &&
						getIn(organization, ['id']) ===
							getIn(metadata, ['receiverID'])
					) {
						loadMessageByID(id)
						setCurrentId(id)
					}
				})
				chat.on('chatMessageEdited', list => {
					if (
						getIn(list, ['threadId']) === chatThreadId &&
						getIn(organization, ['id']) ===
							getIn(list, ['metadata', 'receiverID'])
					) {
						loadMessages(list)
					}
				})
			}
		} catch (err) {
			const { message } = err
			dispatch(
				AppDuc.creators.showToast({
					messageType: 'error',
					message,
				})
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chat, chatThreadId])

	useEffect(() => {
		if (chat && chat !== null) {
			chat.stopRealtimeNotifications()
		}
		startNotification()

		loadMessages()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chatThreadId, token, chat])

	useEffect(() => {
		if (token && tradeId) {
			const chatClient = new ChatClient(
				getChatEndPoint(),
				new AzureCommunicationTokenCredential(token)
			)
			setChatClient(chatClient)
		}
	}, [token, tradeId])

	const loadToken = useCallback(async () => {
		try {
			await dispatch(MessagesDuc.creators.getChatToken(orgRole))
		} catch (err) {
			const { message } = err
			dispatch(
				AppDuc.creators.showToast({
					messageType: 'error',
					message,
				})
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orgRole])

	useEffect(() => {
		async function fetchChat() {
			await loadToken()
		}
		fetchChat()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const sendMessage = async () => {
		try {
			const threadID = chatThreadId
			if (!threadID) {
				return
			}
			const chatThreadClient = chat.getChatThreadClient(threadID)

			const sendMessageRequest = {
				content: chatText,
			}

			const sendMessageOptions = {
				senderDisplayName: firstName,
				metadata: {
					senderID: senderOrgId,
					receiverID: partnerId,
					clientID: userId,
				},
				type: 'text',
			}

			if (editMsgIndex !== '') {
				await chatThreadClient.updateMessage(editMsgIndex, {
					content: chatText,
				})
				const msg = await chatThreadClient.getMessage(editMsgIndex)
				const newState = messages.map(obj => {
					if (obj.id === editMsgIndex) {
						return msg
					}

					return obj
				})
				setMessages(newState)
			} else {
				const sendMessageResult = await chatThreadClient.sendMessage(
					sendMessageRequest,
					sendMessageOptions
				)
				await loadMessageByID(sendMessageResult.id)
			}
			dispatch(
				MessagesDuc.creators.sendMessageAction(
					chatThreadId,
					'sent-message'
				)
			)
		} catch (err) {
			const { message } = err
			dispatch(
				AppDuc.creators.showToast({
					messageType: 'error',
					message,
				})
			)
		} finally {
			setEditMsgIndex('')
			setChatText('')
			setTextBoxKey(getRandomNumber())
		}
	}

	const AllMessages = useMemo(() => {
		return messages && messages.length > 0 ? (
			<MessageGroup
				key={textBoxKey}
				msg={messages}
				senderOrgId={senderOrgId}
				handleClick={(id, message) => {
					setEditMsgIndex(id)
					setChatText(message)
				}}
			/>
		) : (
			<TransparentBgSpinner />
		)
	}, [messages, senderOrgId, textBoxKey])

	return (
		<GrommBox
			style={{
				flex: 0.5,
			}}
			overflow
			pad="10px 10px"
			margin="0px 10px"
			justify="between"
			width="inherit"
			height={isIframeForTeamsApp ? '505px' : '675px'}
			background="#fff"
		>
			<Box flex="1" width="100%" alignSelf="flex-start">
				<WindowHeader>
					<HeaderText title={header}>{header}</HeaderText>
					<SubHeaderText title={header}>{subHeader}</SubHeaderText>
				</WindowHeader>
				<>{AllMessages}</>
			</Box>
			<Box
				padding="14px 0px 0px 0px"
				row
				justifyContent="space-between"
				alignItems="flex-start"
				width="inherit"
			>
				<Box
					style={{
						flex: 1,
						marginRight: '20px',
					}}
				>
					<Input
						key={textBoxKey}
						value={chatText}
						name="name"
						type="text"
						placeholder={t('messages.typehere')}
						onChange={text => {
							setDisableButton(false)
							setChatText(text.target.value)
						}}
					/>
				</Box>
				<Box padding="0px 8px 0px 0px">
					<SendButton
						disabled={chatText.trim() === '' || disableButton}
						action
						primary
						rounded
						onClick={() => {
							sendMessage()
							setDisableButton(true)
						}}
						customIcon={
							<ButtonIconWrapper size="24px" width="24px">
								<Icon
									color={theme.color.white}
									glyph={SendSvg}
								/>
							</ButtonIconWrapper>
						}
					/>
				</Box>
			</Box>
		</GrommBox>
	)
}

export { ChatWindow }
