import React, { useState } from 'react'
import {
	ORG_ALIASES_KEYS,
	certKeys1,
	certKeys2,
} from 'core-app/modules/Admin/config'
import { getIn } from 'timm'
import moment from 'moment'
import { currentPlatform } from 'ui-lib/utils/config'
import { Box } from 'ui-lib/utils/Box'
import { Card } from 'ui-lib/components/Card'
import { FileLists } from 'ui-lib/components/FileUpload'
import { ButtonWithNoBorder, ButtonIconWrapper } from 'ui-lib/components/Button'
import { Icon } from 'ui-lib/icons/components/Icon'
import EditIcon from 'ui-lib/icons/edit.svg'
import DeleteIcon from 'ui-lib/icons/deleteTrash.svg'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Modal } from 'ui-lib/components/Modal'
import { ErrorBlock } from 'ui-lib/components/ErrorBlocks'
import { AdminDuc } from 'core-app/modules/Admin/duc'
import { WebTourDuc } from 'core-app/modules/WebTour/duc'
import { useDispatch, useSelector } from 'react-redux'
import { AppDuc } from 'core-app/modules/App/duc'
import { KeyValueSegment } from 'core-app/modules/Admin/components/KeyValueSegment'
import { supplyChainModels } from 'core-app/modules/Admin/components/StorageDetails'
import { SmallText } from 'ui-lib/components/Typography'
import theme from 'ui-lib/utils/base-theme'
import EditCertificate from './EditCertificate'

const CertificationBlock = ({
	isMobile,
	t,
	certDetails,
	isOnlyOneCertificateAvailable,
}) => {
	const scModel = getIn(certDetails, ['scmodel'])
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const dispatch = useDispatch()

	const getValue = key => {
		if (key === 'verificationStatus') {
			return getIn(certDetails, ['meta', 'verificationStatus'])
				? t(
						ORG_ALIASES_KEYS[
							getIn(certDetails, ['meta', 'verificationStatus'])
						]
				  )
				: ''
		}
		if (key === 'verifiedBy') {
			return getIn(certDetails, ['meta', 'verifiedBy']) || ''
		}
		if (key === 'verifiedOn') {
			return getIn(certDetails, ['meta', 'verifiedOn'])
				? moment(getIn(certDetails, ['meta', 'verifiedOn'])).format(
						'YYYY-MM-DD'
				  )
				: ''
		}
		if (key === 'createdAt') {
			return certDetails[key]
				? moment(certDetails[key]).format('YYYY-MM-DD hh:mm A')
				: ''
		}

		return certDetails[key]
	}

	return (
		<>
			<Modal
				forceCloseviaButton
				size="large"
				show={showDeleteModal}
				heading={t('onboarding.deleteCertificate')}
				body={<div>{t('onboarding.deleteCertificateNote')}</div>}
				closelabel={t('common.cancel')}
				confirmlabel={t('common.yes')}
				onClose={() => setShowDeleteModal(false)}
				onConfirm={() => {
					setShowDeleteModal(false)
					dispatch(
						AdminDuc.creators.deleteSingleCertificate(
							certDetails.id,
							t('toast.deleteSuccess')
						)
					)
				}}
			/>
			<Modal
				size="large"
				show={showModal}
				heading={t('onboarding.updateCertificate')}
				body={
					<EditCertificate
						certDetails={certDetails}
						handleShowModal={value => {
							setShowModal(value)
						}}
						edit
					/>
				}
				hideButtons
				onConfirm={() => {
					setShowModal(false)
				}}
			/>
			<Box row={!isMobile} padding="20px">
				<Card
					style={{
						padding: '20px',
						position: 'relative',
						overflow: 'visible',
						boxShadow: ' 0px 6px 16px #00000014',
					}}
					textAlign="left"
				>
					<SmallText
						style={{
							position: 'absolute',
							fontSize: theme.fontSize.m,
							color: theme.color.accent2,
							top: '-10px',
							left: '20px',
							display: 'inline-block',
							backgroundColor: theme.color.white,
							padding: '0 8px',
						}}
					>
						{getIn(certDetails, ['certOrg'])}
					</SmallText>
					<Box row justifyContent="flex-end">
						{!isOnlyOneCertificateAvailable && (
							<ButtonWithNoBorder
								label={t('common.delete')}
								action
								rounded
								customIcon={
									<ButtonIconWrapper lightBG>
										<Icon glyph={DeleteIcon} />
									</ButtonIconWrapper>
								}
								onClick={() => {
									dispatch(
										WebTourDuc.creators.setActiveTourModule(
											''
										)
									)
									setShowDeleteModal(true)
								}}
							/>
						)}
						<Spacer size={16} />

						<ButtonWithNoBorder
							label={t('common.edit')}
							action
							rounded
							customIcon={
								<ButtonIconWrapper lightBG>
									<Icon glyph={EditIcon} />
								</ButtonIconWrapper>
							}
							onClick={() => {
								dispatch(
									WebTourDuc.creators.setActiveTourModule('')
								)
								setShowModal(true)
							}}
						/>
					</Box>
					<Box row style={{ width: '100%' }}>
						<Box style={{ width: '50%' }}>
							{certKeys1.map(key => {
								return (
									<KeyValueSegment
										key={key}
										labelWidth="50%"
										label={t(ORG_ALIASES_KEYS[key])}
										value={certDetails[key]}
									/>
								)
							})}
						</Box>
						<Box style={{ width: '50%' }}>
							{certKeys2.map(key => {
								return !(
									['verifiedBy', 'verifiedOn'].includes(
										key
									) &&
									getIn(certDetails, [
										'meta',
										'verificationStatus',
									]) !== 'verified'
								) &&
									!(
										currentPlatform() === 'rice' &&
										key === 'scmodel'
									) ? (
									<KeyValueSegment
										key={key}
										labelWidth="50%"
										label={t(ORG_ALIASES_KEYS[key])}
										value={
											key === 'scmodel'
												? (scModel || []).map(
														sc =>
															`${t(
																supplyChainModels[
																	sc
																]
															)}, `
												  )
												: getValue(key)
										}
									/>
								) : null
							})}
							<FileLists
								documents={certDetails.files || []}
								isReadOnly
							/>
						</Box>
					</Box>
				</Card>
			</Box>
		</>
	)
}

const Certification = ({ certificates, t }) => {
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const [showModal, setShowModal] = useState(false)
	const certDetails = {
		certBody: '',
		certNum: '',
		certName: '',
		certOrg: '',
		certStartDate: '',
		issueDate: '',
		createdAt: '',
		scmodel: [],
		files: [],
	}
	const dispatch = useDispatch()

	return (
		<>
			<Modal
				forceCloseviaButton
				size="large"
				show={showModal}
				heading={t('onboarding.addNewCetificate')}
				body={
					<EditCertificate
						certDetails={certDetails}
						handleShowModal={value => {
							setShowModal(value)
						}}
					/>
				}
				hideButtons
				onConfirm={() => {
					setShowModal(false)
				}}
			/>
			<Box row justifyContent="flex-end" padding="20px 20px 0px 0px">
				<ButtonWithNoBorder
					label={t('onboarding.addNew')}
					action
					rounded
					customIcon={
						<ButtonIconWrapper lightBG>
							<Icon glyph={EditIcon} />
						</ButtonIconWrapper>
					}
					onClick={() => {
						dispatch(WebTourDuc.creators.setActiveTourModule(''))
						setShowModal(true)
					}}
				/>
			</Box>
			{certificates.length ? (
				(certificates || []).map(certificate => (
					<CertificationBlock
						isMobile={isMobile}
						key={certificate.id}
						t={t}
						certDetails={certificate}
						isOnlyOneCertificateAvailable={
							certificates.length === 1
						}
					/>
				))
			) : (
				<Box style={{ minHeight: 300 }}>
					<ErrorBlock
						hideButton
						status="empty"
						message={t('common.errorBlockMessage')}
					/>
				</Box>
			)}
		</>
	)
}

export default Certification
