/* eslint-disable no-nested-ternary */
/* @flow */
import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { merge, getIn } from 'timm'
import theme from '../../utils/base-theme'
import { Box } from '../../utils/Box'
import { Label } from '../Typography'

type Props = {
	id?: string,
	list: Array<{ name: string, label: string }>,
	onChange?: ({ name: string, label: string }) => void,
	statusList?: Array<{ label: string, count: number }>,
	isLoading?: boolean,
	showStatus?: boolean,
	activeIndex?: number,
	borderRadius?: number,
	fontSize?: number,
}

const Tab = styled.div(p => ({
	color: p.theme.color.grey8,
	transition: 'tranform 0.1s ease 0.1s',
	borderTop: '1px solid #ccc',
	borderBottom: '1px solid #ccc',
	borderRight: '1px solid #ccc',
	cursor: 'pointer',
	backgroundColor: p.theme.color.white,
	'&:first-of-type': {
		borderLeft: '1px solid #ccc',
		borderTopLeftRadius: p.borderRadius,
		borderBottomLeftRadius: p.borderRadius,
	},
	'&:last-of-type': {
		borderTopRightRadius: p.borderRadius,
		borderBottomRightRadius: p.borderRadius,
	},
	...(p.active && {
		backgroundColor: p.theme.color.primary,
		// transform: 'scale(1.07)',
	}),
	...(p.isMobile && {
		borderRadius: p.borderRadius,
	}),
}))

const TabButtons = ({
	id,
	list,
	onChange = () => {},
	statusList,
	isLoading,
	showStatus,
	activeIndex,
	borderRadius,
	fontSize,
	isMobile,
}: Props) => {
	const [_activeIndex, setActiveIndex] = useState(activeIndex)
	const _statusList = useMemo(() => {
		return statusList.length !== 0
			? merge(
					{
						Any: Object.values(statusList).reduce(
							(a, b) => a + b,
							0
						),
						Pending: statusList.Submitted || 0,
					},
					statusList
			  )
			: statusList
	}, [statusList])

	useEffect(() => {
		if (activeIndex) setActiveIndex(activeIndex)
	}, [activeIndex])

	return (
		<Box
			id={id || 'TabButtons'}
			row
			style={{
				height: isMobile ? 'auto' : 37,
				overflowX: 'hidden',
				borderRadius,
				fontSize,
				display: isMobile ? 'block' : 'flex',
			}}
		>
			{list.map(({ name, label }, _index) => (
				<Tab
					active={_activeIndex === _index}
					key={name || label}
					id={`${id || 'TabButtons'}-${name || label}`}
					style={{ padding: '4px 16px' }}
					onClick={() => {
						setActiveIndex(_index)
						onChange({ name, label })
					}}
					borderRadius={borderRadius}
					isMobile={isMobile}
				>
					<Label
						link
						color={_activeIndex === _index ? '#fff' : '#3F57C6'}
						as="span"
						style={{ fontSize }}
					>
						{showStatus
							? isLoading
								? `${label}(0)`
								: `${label}(${getIn(_statusList, [label]) ||
										0})`
							: `${label}`}
					</Label>
				</Tab>
			))}
		</Box>
	)
}

TabButtons.defaultProps = {
	onChange: () => {},
	showStatus: false,
	statusList: {},
	borderRadius: 4,
	activeIndex: 0,
	fontSize: theme.fontSize.s,
	isMobile: false,
}

export { TabButtons }
