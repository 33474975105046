import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn } from 'timm'
import { MainRouteDuc } from 'core-app/routes/duc'
import { PlantationDuc } from 'core-app/modules/Plantation/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { WebTour } from 'core-app/modules/WebTour'
import { WebTourDuc } from 'core-app/modules/WebTour/duc'
import { Title } from 'core-app/shared/components/Title'
import {
	getTractorColumnConfig,
	getCheckerColumnConfig,
	getHarvesterColumnConfig,
	getDriverColumnConfig,
	getSyncStatusColumnConfig,
} from 'core-app/modules/Plantation/components/Columns'
import { Table } from 'ui-lib/components/Table'
import { Label } from 'ui-lib/components/Typography'
import { DatePicker } from 'ui-lib/components/Datepicker'
import { SelectSearch } from 'ui-lib/components/Select'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Tabs, DefaultTabIndicator } from 'ui-lib/components/Tabs'
import {
	ButtonIconWrapper,
	Button,
	ButtonWithNoBorder,
} from 'ui-lib/components/Button'
import { ErrorBlock } from 'ui-lib/components/ErrorBlocks'
import { Box } from 'ui-lib/utils/Box'
import { Icon } from 'ui-lib/icons/components/Icon'
import theme from 'ui-lib/utils/base-theme'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import Records from 'ui-lib/icons/records.svg'

const getTabComponents = ({
	users,
	binList,
	rows,
	onChange,
	startDate,
	endDate,
	setStartDate,
	setEndDate,
	selectedActor,
	setSelectedActor,
	t,
	allPartners,
}) => ({ name }) => () => {
	let columns

	if (name === 'harvester') {
		columns = getHarvesterColumnConfig({
			onChange,
			users,
			t,
		})
	}
	if (name === 'checker') {
		columns = getCheckerColumnConfig({
			onChange,
			users,
			t,
		})
	}
	if (name === 'tractorOperator') {
		columns = getTractorColumnConfig({
			onChange,
			binList,
			users,
			t,
		})
	}
	if (name === 'driver') {
		columns = getDriverColumnConfig({
			onChange,
			binList,
			users,
			t,
			allPartners,
		})
	}
	if (name === 'syncStatus') {
		columns = getSyncStatusColumnConfig({
			onChange,
			users,
			t,
		})
	}

	const activeUsers = users
		.filter(user => getIn(user, ['status', 'state']) === 'active')
		.map(activeUser => {
			return {
				label: activeUser.fullName,
				id: activeUser.id,
				orgRole: activeUser.orgRole,
			}
		})

	const getUsers = type => {
		return activeUsers.filter(user => user.orgRole === type)
	}

	const usersList = {
		harvester: getUsers('harvester'),
		checker: getUsers('checker'),
		tractorOperator: getUsers('tractor-operator'),
		driver: getUsers('driver'),
	}

	const getPlaceholder = () => {
		if (name === 'driver')
			return `${t('tdmDocumentListing.select')} ${t(
				'plantationRecordsListing.driver'
			)}`

		if (name === 'tractorOperator')
			return `${t('tdmDocumentListing.select')} ${t(
				'plantationRecordsListing.tractorOperator'
			)}`

		if (name === 'checker')
			return `${t('tdmDocumentListing.select')} ${t(
				'plantationRecordsListing.checker'
			)}`

		if (name === 'harvester')
			return `${t('tdmDocumentListing.select')} ${t(
				'plantationRecordsListing.harvester'
			)}`
	}

	return (
		<Box style={{ overflow: 'auto', position: 'relative' }}>
			{name !== 'syncStatus' && (
				<Box style={{ background: '#fff' }} padding={8}>
					<Box row justifyContent="space-between">
						<Box margin="20px 0px 0px 12px">
							<SelectSearch
								name="employee"
								key={selectedActor}
								value={selectedActor}
								placeholder={getPlaceholder(name)}
								options={usersList[name]}
								onChange={value => {
									setSelectedActor(value.id)
									onChange('actor', { name, actor: value.id })
								}}
								labelKey="label"
								valueKey="id"
								defaultLabel={t('common.selectDefaultLabel')}
								style={{
									width: '350px',
									border: '1px solid #0b20aa',
									borderRadius: 4,
								}}
								returnOnlyValue
							/>
						</Box>
						<Box row>
							<Box
								alignItems="flex-start"
								width={180}
								margin="0px 5px"
							>
								<Label small>{t('auditReport.from')}</Label>

								<DatePicker
									name="startDate"
									placeholder={t('auditReport.choose')}
									value={startDate}
									maxDate={new Date()}
									onChange={value => {
										setStartDate(value)
										onChange('startDate', {
											name,
											date: value,
										})
									}}
								/>
							</Box>
							<Box
								alignItems="flex-start"
								width={180}
								margin="0px 5px"
							>
								<Label small>{t('auditReport.to')}</Label>

								<DatePicker
									name="toDate"
									value={endDate}
									toDate
									maxDate={new Date()}
									placeholder={t('auditReport.choose')}
									onChange={value => {
										setEndDate(value)
										onChange('endDate', {
											name,
											date: value,
										})
									}}
								/>
							</Box>
							<ButtonWithNoBorder
								label={t('auditReport.clear')}
								style={{
									fontSize: theme.fontSize.xl,
									margin: '0px 12px 0px 0px',
								}}
								onClick={() => {
									onChange('clearFilters', { name })
								}}
							/>
						</Box>
					</Box>
				</Box>
			)}
			<Table
				noRoundedBorder
				columnConfig={columns}
				rowData={rows[name] || []}
			/>
			{!(rows[name] || []).length && (
				<Box style={{ minHeight: 300 }}>
					<ErrorBlock
						hideButton
						status="empty"
						message={t('common.errorBlockMessage')}
					/>
				</Box>
			)}
		</Box>
	)
}

const Document = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const location = useSelector(PlantationDuc.selectors.location)

	const assets = useSelector(AuthDuc.selectors.getOrgAssets)
	const binList = getIn(assets, ['storageunits']) || []
	const users = useSelector(AuthDuc.selectors.getOrgUsers)
	const allPartners = useSelector(AuthDuc.selectors.getAllPartners)

	const documents = useSelector(PlantationDuc.selectors.fetchDocuments)

	const activeTypes = useSelector(
		PlantationDuc.selectors.getDocumentActiveTabs
	)

	const activeFilters = useSelector(PlantationDuc.selectors.getActiveFilters)

	const { payload = {} } = location
	const { type = 'harvester' } = payload
	const index = activeTypes.findIndex(tabs => tabs.code === type)

	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)

	const [startDate, setStartDate] = useState()
	const [endDate, setEndDate] = useState()
	const [selectedActor, setSelectedActor] = useState('')
	const [activeTab, setActiveTab] = useState('harvester')

	const pagination = useSelector(
		PlantationDuc.selectors.getRecordsPaginationQueries
	)

	// reset filters on tab change
	useEffect(() => {
		setStartDate('')
		setEndDate('')
		setSelectedActor('')
	}, [activeTab, dispatch])

	useEffect(() => {
		if (tourModule === 'records') {
			dispatch(WebTourDuc.creators.setRecordsVisit(true))
		}
	}, [tourModule, dispatch])

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.records'),
			name: 'record',
			isActive: false,
		},
	]

	/** Handlers to react on actions */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
	}

	const getTabs = useCallback(() => {
		return activeTypes.map(({ code, title }) => ({
			name: code,
			title: t(title),
			IndicatorComponent:
				parseInt(code, 10) > 0 &&
				(({ isActive }) => (
					<DefaultTabIndicator active={isActive}>
						{parseInt(code, 10)}
					</DefaultTabIndicator>
				)),
		}))
	}, [activeTypes, t])

	const handleOnChange = useCallback(
		(actionType, meta = {}) => {
			switch (actionType) {
				case 'open_document': {
					if (meta.row) {
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types
									.PLANTATION$DOCUMENTREFERENCETYPE,
								{
									rootModule: 'document',
									action: 'view',
									documentReference: meta.row.id,
									documentType: meta.docType,
								}
							)
						)
					}

					break
				}

				case 'startDate': {
					const { name, date } = meta

					let dateQuery = ''

					// If startDate or endDate exists, then date is a range. If not, date is startDate.
					if (endDate) {
						dateQuery = `createdAt=eq(${date}~${endDate})`
					} else {
						dateQuery = `createdAt=eq(${date})`
					}

					dispatch(PlantationDuc.creators.flushRecordsState(name))
					dispatch(
						PlantationDuc.creators.fetchDocuments(name, dateQuery)
					)

					break
				}

				case 'endDate': {
					const { name, date } = meta

					let dateQuery = ''

					// If startDate or endDate exists, then date is a range. If not, date is startDate.
					if (startDate) {
						dateQuery = `createdAt=eq(${startDate}~${date})`
					} else {
						dateQuery = `createdAt=eq(${date})`
					}

					dispatch(PlantationDuc.creators.flushRecordsState(name))
					dispatch(
						PlantationDuc.creators.fetchDocuments(name, dateQuery)
					)

					break
				}

				case 'actor': {
					const { name, actor } = meta

					let actorQuery = `createdBy=${actor}`

					// Check if name is 'checker' and update the query'
					if (name === 'checker') {
						actorQuery = `meta=eq(updatedBy->${actor})`
					} else if (name === 'driver') {
						actorQuery = `clientID=${actor}`
					}

					dispatch(PlantationDuc.creators.flushRecordsState(name))

					dispatch(
						PlantationDuc.creators.fetchDocuments(name, actorQuery)
					)

					break
				}

				case 'clearFilters': {
					const { name } = meta
					setStartDate('')
					setEndDate('')
					setSelectedActor('')

					dispatch(PlantationDuc.creators.fetchDocuments(name))
					break
				}

				default:
					break
			}
		},
		[dispatch, endDate, startDate]
	)

	return (
		<>
			<Box padding={8} width="100%" height="100%">
				<Box style={{ padding: '0 5' }}>
					<Breadcrumb
						links={breadCrumbs}
						onClick={target => handleBreadCrumbClick(target)}
					/>
				</Box>

				<Box
					row
					style={{ padding: '0 5' }}
					justifyContent="space-between"
				>
					<Title
						title={t('plantationRecordsListing.title')}
						icon={Records}
					/>
				</Box>

				<Tabs
					activeTabIndex={index}
					tabs={getTabs()}
					tabComponentsMap={getTabComponents({
						rows: documents,
						onChange: handleOnChange,
						binList,
						users,
						activeFilters,
						startDate,
						endDate,
						setStartDate,
						setEndDate,
						selectedActor,
						setSelectedActor,
						pagination,
						t,
						allPartners,
					})}
					onChange={({ name }) => {
						setActiveTab(name)
					}}
				/>
				<Box padding="25px 2px" style={{ maxWidth: 150 }}>
					<Button
						label={t('common.back')}
						rounded
						customIcon={
							<ButtonIconWrapper lightBG>
								<Icon glyph={LeftArrowIcon} />
							</ButtonIconWrapper>
						}
						onClick={() =>
							dispatch(
								MainRouteDuc.creators.switchPage(
									MainRouteDuc.types.DASHBOARD
								)
							)
						}
					/>
				</Box>
			</Box>
			{tourModule === 'records' && <WebTour />}
		</>
	)
}

export { Document }
