import Duck from 'extensible-duck'
import { setIn, getIn } from 'timm'
import { createSelector } from 'reselect'
import { initialState } from './config'

export const PartnerDuc = new Duck({
	namespace: 'partner',
	store: 'global',
	types: [
		'SET_ACTIVE_MODULE',
		'FETCH_PARTNER_LIST',
		'SET_PARTNER_LIST',
		'INITIATE_PARTNER_DETAILS',
		'FETCH_PARTNER_DETAILS',
		'SET_PARTNER_DETAILS',
		'UPDATE_REQUEST_STATUS',
		'UPDATE_REQUEST_STATUS_FROM_DETAILS_PAGE',
		'DELETE_PARTNER',
		'DELETE_PARTNER_SUCCESS_TOGGLE',
		'SEND_PARTNERSHIP_REQUEST',
		'SEND_PARTNER_REQUEST_SUCCESS_TOGGLE',
		'SET_CERTIFICATE_DETAILS',
		'INITIATE_SMALLHOLDER_INVITE',
		'INITIATE_PARTNER_INVITE',
		'INVITE_PARTNER_REQUEST_SUCCESS_TOGGLE',
		'PARTNER_ACCEPT_MODAL_TOGGLE',
		'VIEW_PARTNER_ACCEPT_MODAL_TOGGLE',
		'PARTNER_DECLINE_MODAL_TOGGLE',
		'VIEW_PARTNER_DECLINE_MODAL_TOGGLE',
		'SET_PARTNER_REQUEST_STATUS',
		'SET_ACTIVE_FILTER_ENTRIES',
		'SET_SUPPLYBASE_VALUES',
		'SET_REFERRAL_LIST',
		'SET_GLOBAL_PARTNERS_LIST',
		'BLACKLIST_OR_DEACTIVATE_PARTNER',
		'UPDATE_SUPPLYBASE_MEMBERS',
		'FETCH_PARTNERSHIP_TIMELINE',
		'SET_PARTNERSHIP_TIMELINE',
		'SET_FARM_CERTIFICATES',
	],
	initialState,
	reducer: (state, action, duck) => {
		switch (action.type) {
			case duck.types.SET_ACTIVE_MODULE: {
				return setIn(state, ['activeModule'], action.module)
			}

			case duck.types.SET_PARTNER_DETAILS: {
				return setIn(
					state,
					['partnerDetails', 'partnerInfo'],
					action.partnerInfo
				)
			}

			case duck.types.SET_PARTNER_LIST: {
				const { partnerList, docType } = action
				const _partnerList =
					docType === 'all'
						? partnerList
						: getIn(partnerList, [docType])
				const root = ['partnersListing']
				const modules =
					docType === 'all' ? [...root] : [...root, docType]

				return setIn(state, modules, _partnerList)
			}

			case duck.types.SET_CERTIFICATE_DETAILS: {
				return setIn(
					state,
					['partnerDetails', 'certificateDetails'],
					action.certificateDetails
				)
			}

			case duck.types.PARTNER_ACCEPT_MODAL_TOGGLE: {
				return setIn(
					state,
					['mainDashboardListing', 'partnerAcceptModalStatus'],
					action.status
				)
			}

			case duck.types.PARTNER_DECLINE_MODAL_TOGGLE: {
				return setIn(
					state,
					['mainDashboardListing', 'partnerDeclineModalStatus'],
					action.status
				)
			}

			case duck.types.VIEW_PARTNER_ACCEPT_MODAL_TOGGLE: {
				return setIn(
					state,
					['viewPartner', 'viewPageAcceptModalStatus'],
					action.status
				)
			}

			case duck.types.VIEW_PARTNER_DECLINE_MODAL_TOGGLE: {
				return setIn(
					state,
					['viewPartner', 'viewPageDeclineModalStatus'],
					action.status
				)
			}

			case duck.types.DELETE_PARTNER_SUCCESS_TOGGLE: {
				return setIn(
					state,
					['viewPartner', 'deletePartnerModalStatus'],
					action.status
				)
			}

			case duck.types.SEND_PARTNER_REQUEST_SUCCESS_TOGGLE: {
				return setIn(
					state,
					['viewPartner', 'sendPartnerRequestModalStatus'],
					action.status
				)
			}

			case duck.types.INVITE_PARTNER_REQUEST_SUCCESS_TOGGLE: {
				return setIn(
					state,
					['invitePartner', 'invitePartnerSuccessModalStatus'],
					action.status
				)
			}

			case duck.types.SET_PARTNER_REQUEST_STATUS: {
				return setIn(
					state,
					['viewPartner', 'partnerStatus'],
					action.status
				)
			}

			case duck.types.SET_ACTIVE_FILTER_ENTRIES: {
				const { filters, docType } = action
				const root = ['activeFilter']
				const _activeFilters =
					docType === 'all' ? filters : getIn(filters, [docType])

				const modules =
					docType === 'all' ? [...root] : [...root, docType]

				return setIn(state, modules, _activeFilters)
			}

			case duck.types.SET_SUPPLYBASE_VALUES: {
				return setIn(state, ['supplyBase'], action.values)
			}

			case duck.types.SET_REFERRAL_LIST: {
				return setIn(state, ['referralList'], action.list)
			}

			case duck.types.SET_GLOBAL_PARTNERS_LIST: {
				return setIn(state, ['globalPartnersList'], action.list)
			}

			case duck.types.SET_PARTNERSHIP_TIMELINE: {
				return setIn(state, ['partnershipTimeline'], action.details)
			}

			case duck.types.SET_FARM_CERTIFICATES: {
				return setIn(state, ['farmCertificates'], action.details)
			}

			default:
				return state
		}
	},
	selectors: {
		auth: state => state.auth,
		location: state => state.location,
		activeModule: state =>
			getIn(state, ['partner', 'activeModule']) || 'Error',
		partnerState: state => getIn(state, ['partner', 'partnerDetails']),
		getPartnerInfo: new Duck.Selector(selectors =>
			createSelector(
				selectors.partnerState,
				partner => partner.partnerInfo || {}
			)
		),
		getCertificateDetails: new Duck.Selector(selectors =>
			createSelector(
				selectors.partnerState,
				partner => partner.certificateDetails || []
			)
		),
		getPartnerList: state =>
			getIn(state, ['partner', 'partnersListing']) || {},
		getPartnerActiveTabs: state =>
			getIn(state, ['partner', 'tabsConfig']) || [],
		getPartnerAcceptModalStatus: state =>
			getIn(state, [
				'partner',
				'mainDashboardListing',
				'partnerAcceptModalStatus',
			]),
		getPartnerDeclineModalStatus: state =>
			getIn(state, [
				'partner',
				'mainDashboardListing',
				'partnerDeclineModalStatus',
			]),
		getViewPageAcceptModalStatus: state =>
			getIn(state, [
				'partner',
				'viewPartner',
				'viewPageAcceptModalStatus',
			]),
		getViewPageDeclineModalStatus: state =>
			getIn(state, [
				'partner',
				'viewPartner',
				'viewPageDeclineModalStatus',
			]),
		getDeletePartnerModalStatus: state =>
			getIn(state, [
				'partner',
				'viewPartner',
				'deletePartnerModalStatus',
			]),
		getSendPartnerRequestModalStatus: state =>
			getIn(state, [
				'partner',
				'viewPartner',
				'sendPartnerRequestModalStatus',
			]),
		getInvitePartnerSuccessModalStatus: state =>
			getIn(state, [
				'partner',
				'invitePartner',
				'invitePartnerSuccessModalStatus',
			]),
		getPartnerRequestStatus: state =>
			getIn(state, ['partner', 'viewPartner', 'partnerStatus']),
		getActiveFilters: state => getIn(state, ['partner', 'activeFilter']),
		getSupplyBaseValues: state =>
			getIn(state, ['partner', 'supplyBase']) || {},
		getReferralList: state =>
			getIn(state, ['partner', 'referralList']) || [],
		getGlobalPartnersList: state =>
			getIn(state, ['partner', 'globalPartnersList']) || [],
		getPartnershipTimeline: state =>
			getIn(state, ['partner', 'partnershipTimeline']) || [],
		getFarmCertificates: state =>
			getIn(state, ['partner', 'farmCertificates']) || [],
	},
	creators: duck => ({
		setActiveModule: module => ({
			type: duck.types.SET_ACTIVE_MODULE,
			module,
		}),
		fetchPartnerList: (
			docType,
			locationState,
			skipGlobalLoader = false,
			filterQuery = ''
		) => ({
			type: duck.types.FETCH_PARTNER_LIST,
			docType,
			locationState,
			skipGlobalLoader,
			filterQuery,
		}),
		setPartnerList: (docType, partnerList) => ({
			type: duck.types.SET_PARTNER_LIST,
			docType,
			partnerList,
		}),
		initiatePartnerDetails: partnerReqID => ({
			type: duck.types.INITIATE_PARTNER_DETAILS,
			partnerReqID,
		}),
		fetchPartnerDetails: orgID => ({
			type: duck.types.FETCH_PARTNER_DETAILS,
			orgID,
		}),
		setPartnerInfo: partnerInfo => ({
			type: duck.types.SET_PARTNER_DETAILS,
			partnerInfo,
		}),
		setCertificateDetails: certificateDetails => ({
			type: duck.types.SET_CERTIFICATE_DETAILS,
			certificateDetails,
		}),
		updateRequestStatus: (partnerReqID, status) => ({
			type: duck.types.UPDATE_REQUEST_STATUS,
			partnerReqID,
			status,
		}),
		updateRequestStatusFromDetailsPage: (partnerReqID, status) => ({
			type: duck.types.UPDATE_REQUEST_STATUS_FROM_DETAILS_PAGE,
			partnerReqID,
			status,
		}),
		partnerAcceptModalToggle: status => ({
			type: duck.types.PARTNER_ACCEPT_MODAL_TOGGLE,
			status,
		}),
		partnerDeclineModalToggle: status => ({
			type: duck.types.PARTNER_DECLINE_MODAL_TOGGLE,
			status,
		}),
		viewPageAcceptModalToggle: status => ({
			type: duck.types.VIEW_PARTNER_ACCEPT_MODAL_TOGGLE,
			status,
		}),
		viewPageDeclineModalToggle: status => ({
			type: duck.types.VIEW_PARTNER_DECLINE_MODAL_TOGGLE,
			status,
		}),
		deletePartner: partnerReqID => ({
			type: duck.types.DELETE_PARTNER,
			partnerReqID,
		}),
		deletePartnerSuccessToggle: status => ({
			type: duck.types.DELETE_PARTNER_SUCCESS_TOGGLE,
			status,
		}),
		sendPartnershipRequest: receiverID => ({
			type: duck.types.SEND_PARTNERSHIP_REQUEST,
			receiverID,
		}),
		sendPartnerReqSuccessToggle: status => ({
			type: duck.types.SEND_PARTNER_REQUEST_SUCCESS_TOGGLE,
			status,
		}),
		initiateSmallholderInvite: details => ({
			type: duck.types.INITIATE_SMALLHOLDER_INVITE,
			details,
		}),
		initiatePartnerInvite: details => ({
			type: duck.types.INITIATE_PARTNER_INVITE,
			details,
		}),
		invitePartnerReqSuccessToggle: status => ({
			type: duck.types.INVITE_PARTNER_REQUEST_SUCCESS_TOGGLE,
			status,
		}),
		setPartnerRequestStatus: status => ({
			type: duck.types.SET_PARTNER_REQUEST_STATUS,
			status,
		}),
		setActiveFilterEntries: (docType, filters) => ({
			type: duck.types.SET_ACTIVE_FILTER_ENTRIES,
			docType,
			filters,
		}),
		setSupplyBaseValues: values => ({
			type: duck.types.SET_SUPPLYBASE_VALUES,
			values,
		}),
		setReferralList: list => ({
			type: duck.types.SET_REFERRAL_LIST,
			list,
		}),
		setGlobalPartnersList: list => ({
			type: duck.types.SET_GLOBAL_PARTNERS_LIST,
			list,
		}),
		blackListOrDeactivatePartner: (partnerReqID, details) => ({
			type: duck.types.BLACKLIST_OR_DEACTIVATE_PARTNER,
			partnerReqID,
			details,
		}),
		updateSupplybaseMembers: (details, inviteSBMember) => ({
			type: duck.types.UPDATE_SUPPLYBASE_MEMBERS,
			details,
			inviteSBMember,
		}),
		fetchPartnershipTimeline: partnerReqID => ({
			type: duck.types.FETCH_PARTNERSHIP_TIMELINE,
			partnerReqID,
		}),
		setPartnershipTimeline: details => ({
			type: duck.types.SET_PARTNERSHIP_TIMELINE,
			details,
		}),
		setFarmCertificates: details => ({
			type: duck.types.SET_FARM_CERTIFICATES,
			details,
		}),
	}),
})
