import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { MainRouteDuc } from 'core-app/routes/duc'
import { Title } from 'core-app/shared/components/Title'
import { useDebouncedCallback } from 'core-app/utils/helpers'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { SuggestionsInput } from 'ui-lib/components/Input'
import { Card } from 'ui-lib/components/Card'
import { SmallText } from 'ui-lib/components/Typography'
import { ButtonIconWrapper, Button } from 'ui-lib/components/Button'
import theme from 'ui-lib/utils/base-theme'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import PartnerIcon from 'ui-lib/icons/partner.svg'
import AddPartnerIcon from 'ui-lib/icons/add.svg'
import { WebTour } from '../../../WebTour'
import { WebTourDuc } from '../../../WebTour/duc'

const AddPartner = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { results = [], message } = useSelector(
		AuthDuc.selectors.getOrgSearchResults
	)

	const virtualAccess = useSelector(AuthDuc.selectors.getVirtualAccess)

	const transformedData = Object.values(results).filter(
		activeDoc => activeDoc
	)

	const partnerList = transformedData.map(({ name, id }) => {
		return { label: name, value: id }
	})

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.partner'),
			name: 'partner',
			isActive: true,
		},
		{
			label: t('breadcrumb.addPartner'),
			name: 'addPartner',
			isActive: false,
		},
	]

	const handleSearch = useDebouncedCallback(value => {
		dispatch(AuthDuc.creators.searchOrganization(value, virtualAccess))
	}, 800)

	const handleSelect = selected => {
		const { value, label } = selected

		dispatch(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.PARTNER$WREQREFERENCE,
				{
					action: 'view',
					partnerRef: value,
				},
				{
					docType: 'partner-search',
					orgName: label,
				}
			)
		)
	}
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)

	useEffect(() => {
		if (tourModule === 'createPartner') {
			dispatch(WebTourDuc.creators.setActiveTourModule('createPartner2'))
		}
	}, [tourModule, dispatch])
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'partner') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER)
			)
		}
	}

	return (
		<>
			<Box padding={8} width="100%" height="100%">
				<Box style={{ padding: '0 5' }}>
					<Breadcrumb
						links={breadCrumbs}
						onClick={target => handleBreadCrumbClick(target)}
					/>
				</Box>
				<Box>
					<Title title={t('addPartner.title')} icon={PartnerIcon} />
				</Box>

				<Spacer size={20} />
				<Box row>
					<Box width="40%" id="input-addPartner">
						<SuggestionsInput
							value=""
							placeholder={t('addPartner.searchPlaceholder')}
							onChange={e => handleSearch(e.target.value)}
							suggestions={partnerList}
							onSelect={selected => handleSelect(selected)}
							error={message}
							extendStyles={{ borderRadius: '4px' }}
						/>
						<SmallText fontSize={theme.fontSize.m}>
							{t('addPartner.addPartnerText')}
						</SmallText>
					</Box>
					<Box
						id="input-addPartnerNotFound"
						style={{ marginLeft: 5 }}
					>
						<Box row justifyContent="center" alignItems="center">
							<IconWrapper
								size={50}
								style={{ cursor: 'pointer', marginTop: -3 }}
								onClick={() =>
									dispatch(
										MainRouteDuc.creators.switchPage(
											MainRouteDuc.types.PARTNER$ACTION,
											{
												action: 'invite-partner',
											}
										)
									)
								}
							>
								<Icon glyph={AddPartnerIcon} />
							</IconWrapper>
						</Box>
					</Box>
				</Box>

				<Spacer size={80} />

				<Card
					textAlign="left"
					style={{
						padding: '24px',
						border: `1px solid ${theme.color.grey7}`,
					}}
				>
					<SmallText bold fontSize={theme.fontSize.l}>
						{t('addPartner.information')}:
					</SmallText>
					<SmallText fontSize={theme.fontSize.m}>
						&nbsp;{t('addPartner.addPartnerInfo')}
					</SmallText>
				</Card>
				<Box padding="25px 2px" style={{ maxWidth: 150 }}>
					<Button
						label={t('common.back')}
						rounded
						customIcon={
							<ButtonIconWrapper lightBG>
								<Icon glyph={LeftArrowIcon} />
							</ButtonIconWrapper>
						}
						onClick={() =>
							dispatch(
								MainRouteDuc.creators.switchPage(
									MainRouteDuc.types.PARTNER
								)
							)
						}
					/>
				</Box>
			</Box>
			{tourModule === 'createPartner2' && <WebTour />}
		</>
	)
}

export { AddPartner }
