import React from 'react'
import { H3, Text } from 'ui-lib/components/Typography'
import { Box } from 'ui-lib/utils/Box'
import theme from 'ui-lib/utils/base-theme'

const KeyValueSegment = ({
	label,
	value,
	labelWidth,
	valueWidth = '340px',
	noMaxWidth = false,
	pointer = false,
}) => {
	return (
		<Box
			row
			padding="4px 12px"
			justifyContent="flex-start"
			alignItems="baseline"
		>
			<Box
				style={{
					width: labelWidth || '25%',
					overflow: 'hidden',
				}}
			>
				<H3
					style={{
						lineHeight: '1.5',
						fontSize: theme.fontSize.m,
						width: '100%',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						color: theme.color.accent2,
					}}
					title={label}
				>
					{label}
				</H3>
			</Box>
			:
			<Box
				padding={4}
				style={{
					maxWidth: noMaxWidth ? 'none' : valueWidth,
					marginLeft: '10px',
				}}
			>
				<Text
					title={value}
					style={{
						lineHeight: '1.5',
						width: '100%',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						color: theme.color.accent2,
						cursor: pointer && 'pointer',
					}}
				>
					{value}
				</Text>
			</Box>
		</Box>
	)
}

export { KeyValueSegment }
