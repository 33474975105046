import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { addLast, getIn, removeAt, updateIn } from 'timm'
import { getStates, getCountries } from 'country-state-picker'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AdminDuc } from 'core-app/modules/Admin/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { OnboardDuc } from 'core-app/modules/Onboard/duc'
import { Tooltip } from 'core-app/modules/Onboard/components/Tooltip'
import { isEmptyObject } from 'core-app/utils/helpers'
import { AppDuc } from 'core-app/modules/App/duc'
import { getSupplyChainModels } from 'core-app/modules/Admin/config'
import { Modal } from 'ui-lib/components/Modal'
import { SmallText, P } from 'ui-lib/components/Typography'
import { Label } from 'ui-lib/components/Label'
import { Button, ButtonIconWrapper } from 'ui-lib/components/Button'
import { CustomInput } from 'core-app/modules/Auth/components/CustomInput'
import { CollapsableSegment } from 'ui-lib/components/CollapsableSegments'
import { DatePicker } from 'ui-lib/components/Datepicker'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { Select, SelectSearch } from 'ui-lib/components/Select'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import theme from 'ui-lib/utils/base-theme'
import LeftArrowIcon from 'ui-lib/icons/arrow_forward.svg'
import DeleteIcon from 'ui-lib/icons/deleteTrash.svg'
import SuccessIcon from 'ui-lib/icons/success-round.svg'

const AddCollectionPoint = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { payload = {} } = useSelector(AdminDuc.selectors.location)
	const showCpSuccessModal = useSelector(
		AdminDuc.selectors.getShowCpSucessModel
	)

	const { action } = payload
	const isAddCP = action === 'add-cp'
	const formValues = {
		collectionPoints: [
			{
				email: '',
				orgName: '',
				organisationType: {
					name: 'Palmoil Collectionpoint',
					id: 'palmoil-collectionPoint',
				},
				addressLine1: '',
				addressLine2: '',
				state: '',
				country: '',
				postalCode: '',
				certType: '',
				certNumber: '',
				certBody: '',
				issueDate: new Date(),
				expiryDate: new Date(),
				certStartDate: new Date(),
			},
		],
	}
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const currentOrgDetails = useSelector(
		AuthDuc.selectors.getCurrentOrganization
	)
	const [initialValues, setInitialValues] = useState(formValues)

	const validationSchema = yup.object({
		collectionPoints: yup.array().of(
			yup.object().shape({
				email: yup
					.string()
					.email(t('validation.emailFormat'))
					.required(t('validation.emailRequired')),
				orgName: yup.string().required(t('validation.orgNameRequired')),
				addressLine1: yup
					.string()
					.required(t('validation.addressLine1required')),

				country: yup.string().required(t('validation.countryRequired')),
				state: yup.string().required(t('validation.stateRequired')),
				city: yup
					.string()
					.required(t('validation.villageCityRequired')),
				certType: yup
					.string()
					.required(t('validation.certTypeRequired')),
				certNumber: yup
					.string()
					.required(t('validation.certNumberRequired')),
			})
		),
	})
	const {
		handleSubmit,
		values,
		handleChange,
		handleBlur,
		touched,
		errors,
		setFieldValue,
		setValues,
	} = useFormik({
		initialValues,
		enableReinitialize: true,
		validationSchema,
		onSubmit: (_values, { setSubmitting }) => {
			if (isAddCP) {
				dispatch(
					AdminDuc.creators.addCollectionPoint(
						_values,
						currentOrgDetails,
						{
							setSubmitting,
						}
					)
				)
			} else {
				dispatch(
					OnboardDuc.creators.addCollectionPoint(
						_values,
						currentOrgDetails,
						{
							setSubmitting,
						}
					)
				)
			}
		},
	})
	const disableCTA = !isEmptyObject(errors)
	const countries = getCountries()

	const countryList = []
	// eslint-disable-next-line array-callback-return
	countries.map(value => {
		if (value.code !== 'gg') {
			countryList.push({
				name: value.code,
				label: value.name,
			})
		}
	})
	const [stateList, setStateList] = useState([])
	const getStateCode = code => {
		try {
			const _stateList = getStates(code)
			const stateDataList = _stateList.map(state => {
				return {
					name: state,
					label: state,
				}
			})
			setStateList(stateDataList)
		} catch (err) {
			// console.log(err)
		}
	}

	return (
		<Box
			flex
			style={{
				background: isAddCP ? 'white' : ' #1D285D',
			}}
		>
			<Modal
				hideButtons
				forceCloseviaButton
				show={showCpSuccessModal}
				size="large"
				body={
					<Box center>
						<Box
							style={{
								width: '770px',
								height: 'fit-content',
								background: theme.color.white,
								borderRadius: '10px',
								boxShadow: '0px 3px 6px #00000029',
							}}
							alignItems="center"
						>
							<IconWrapper color="#CE924B" size={70}>
								<Icon glyph={SuccessIcon} />
							</IconWrapper>
							<Spacer size={20} />
							<SmallText
								style={{
									color: '#2D2D2D',
									fontSize: '32px',
									lineHeight: '32px',
									width: '500px',
									fontWeight: 'bold',
									textAlign: 'center',
								}}
							>
								{t('onboard.collectionPt')}
							</SmallText>
							<Spacer size={30} />
							<SmallText
								style={{
									color: '#555454',
									fontSize: '18px',

									textAlign: 'center',
								}}
							>
								{t('onboard.collectionPt1')}
							</SmallText>
							<Spacer size={20} />

							<Spacer size={30} />
							<Box
								style={{
									width: '410px',
								}}
							>
								<Button
									action
									rounded
									label={t('common.proceed')}
									reverse
									onClick={() => {
										dispatch(
											AdminDuc.creators.showCpSuccessModal(
												false
											)
										)

										dispatch(
											MainRouteDuc.creators.switchPage(
												MainRouteDuc.types
													.ADMIN$SUBROOT,
												{
													rootModule:
														'company-information',
												}
											)
										)
									}}
									extendStyles={{
										background: '#3F56C4',
										color: theme.color.white,
									}}
								/>
							</Box>
						</Box>
					</Box>
				}
			/>
			<Box
				style={{
					height: '100%',
					padding: isAddCP ? '0px' : '20px 0px',
					overflowY: 'scroll',
				}}
				center
			>
				<Box
					style={{
						width: isAddCP ? '100%' : '1100px',
						background: theme.color.white,
						borderRadius: '10px',
						boxShadow: '0px 3px 6px #00000029',
					}}
					padding={25}
					alignItems="center"
				>
					{!isAddCP && (
						<>
							<SmallText
								style={{
									color: '#2D2D2D',
									fontSize: '32px',
									lineHeight: '32px',
									width: '500px',
									fontWeight: 'bold',
									textAlign: 'center',
								}}
							>
								{t('onboarding.collectionPt2')}
							</SmallText>
							<Spacer size={18} />
						</>
					)}
					<SmallText
						style={{
							color: '#555454',
							fontSize: '14px',
							lineHeight: '24px',
							textAlign: 'center',
						}}
					>
						{t('onboarding.collectionPt3')}
					</SmallText>
					<Spacer size={20} />
					<form
						style={{
							width: isMobile ? '100%' : '90%',
						}}
						onSubmit={handleSubmit}
					>
						{values.collectionPoints.map((d, index) => {
							return (
								<Box
									key={d.certStartDate}
									padding="10px 0px"
									alignItems="flex-start"
									row
								>
									<>
										<CollapsableSegment
											expand
											plain
											title={`${t(
												'dealerGeneralReports.collectionPt'
											)} -${index + 1}`}
										>
											<Box
												flex
												style={{
													overflowY: 'scroll',
												}}
											>
												<Box>
													<Box
														row={!isMobile}
														style={{
															width: '100%',
														}}
														justifyContent="space-between"
													>
														<Box
															style={{
																width: isMobile
																	? '100%'
																	: '47.5%',
															}}
														>
															<CustomInput
																value={
																	d.orgName
																}
																handleChange={
																	handleChange
																}
																handleBlur={
																	handleBlur
																}
																touched={getIn(
																	touched,
																	[
																		'collectionPoints',
																		index,
																		'orgName',
																	]
																)}
																errors={getIn(
																	errors,
																	[
																		'collectionPoints',
																		index,
																		'orgName',
																	]
																)}
																required
																label={t(
																	'onboarding.collectionPtName'
																)}
																name={`collectionPoints.${index}.orgName`}
															/>
														</Box>

														<Box
															style={{
																width: isMobile
																	? '100%'
																	: '47.5%',
															}}
														>
															<Box
																row
																justifyContent="space-between"
															>
																<Box row>
																	<Label
																		style={{
																			fontSize:
																				'14px',
																			color:
																				'#555454',
																		}}
																	>
																		{t(
																			'onboarding.cptEmailAddress'
																		)}
																	</Label>
																	<SmallText
																		style={{
																			color:
																				'#E02B2B',
																			marginLeft:
																				'8px',
																		}}
																	>
																		*
																	</SmallText>
																</Box>
																<Tooltip
																	heading={t(
																		'onboarding.workEmailReq'
																	)}
																	message={t(
																		'onboarding.workEmailSubText'
																	)}
																/>
															</Box>
															<CustomInput
																value={d.email}
																handleChange={
																	handleChange
																}
																handleBlur={
																	handleBlur
																}
																touched={getIn(
																	touched,
																	[
																		'collectionPoints',
																		index,
																		'email',
																	]
																)}
																errors={getIn(
																	errors,
																	[
																		'collectionPoints',
																		index,
																		'email',
																	]
																)}
																name={`collectionPoints.${index}.email`}
																type="email"
															/>
														</Box>
													</Box>
													<Box
														row={!isMobile}
														style={{
															width: '100%',
														}}
														justifyContent="space-between"
													>
														<Box
															style={{
																width: isMobile
																	? '100%'
																	: '47.5%',
															}}
														>
															<CustomInput
																value={
																	d.addressLine1
																}
																handleChange={
																	handleChange
																}
																handleBlur={
																	handleBlur
																}
																touched={getIn(
																	touched,
																	[
																		'collectionPoints',
																		index,
																		'addressLine1',
																	]
																)}
																errors={getIn(
																	errors,
																	[
																		'collectionPoints',
																		index,
																		'addressLine1',
																	]
																)}
																required
																label={t(
																	'tdmDetailsEntry.addressLine1'
																)}
																name={`collectionPoints.${index}.addressLine1`}
															/>
														</Box>
														<Box
															style={{
																width: isMobile
																	? '100%'
																	: '47.5%',
															}}
														>
															<Box row>
																<Label
																	style={{
																		fontSize:
																			'14px',
																		color:
																			'#555454',
																	}}
																>
																	{t(
																		'tdmDetailsEntry.addressLine2'
																	)}
																</Label>
																<SmallText
																	style={{
																		color:
																			'#CE924B',
																		marginLeft:
																			'8px',
																	}}
																>
																	(O)
																</SmallText>
															</Box>
															<CustomInput
																value={
																	d.addressLine2
																}
																handleChange={
																	handleChange
																}
																handleBlur={
																	handleBlur
																}
																touched={getIn(
																	touched,
																	[
																		'collectionPoints',
																		index,
																		'addressLine2',
																	]
																)}
																errors={getIn(
																	errors,
																	[
																		'collectionPoints',
																		index,
																		'addressLine2',
																	]
																)}
																name={`collectionPoints.${index}.addressLine2`}
															/>
														</Box>
													</Box>
													<Box
														row={!isMobile}
														style={{
															width: '100%',
														}}
														justifyContent="space-between"
													>
														<Box
															style={{
																width: isMobile
																	? '100%'
																	: '47.5%',
															}}
														>
															<Label
																required
																style={{
																	fontWeight:
																		'400px',
																	fontSize:
																		'16px',
																	color:
																		theme
																			.color
																			.accent2,
																}}
															>
																{t(
																	'onboarding.country'
																)}
															</Label>
															<SelectSearch
																required
																placeholder={t(
																	'onboarding.countryPlaceHolder'
																)}
																value={
																	d.country
																}
																options={
																	countryList
																}
																onChange={value => {
																	setFieldValue(
																		`collectionPoints.${index}.country`,
																		value.name
																	)
																	getStateCode(
																		value.name
																	)

																	setFieldValue(
																		`collectionPoints.${index}.state`,
																		''
																	)
																}}
																labelKey="label"
																valueKey="name"
																defaultLabel={t(
																	'common.selectDefaultLabel'
																)}
																returnOnlyValue
																onBlur={
																	handleBlur
																}
																error={getIn(
																	errors,
																	[
																		'collectionPoints',
																		index,
																		'country',
																	]
																)}
																style={{
																	background:
																		d.country ===
																		''
																			? `${theme.color.grey11} 0% 0% no-repeat padding-box`
																			: `${theme.color.white} 0% 0% no-repeat padding-box`,
																	boxShadow:
																		d.country ===
																		''
																			? '0px 6px 16px #05050707'
																			: `0px 2px 8px ${theme.color.blue6}`,
																	border:
																		d.country ===
																		''
																			? `1px solid ${theme.color.grey4}`
																			: `1px solid ${theme.color.blue5}`,
																	borderRadius:
																		'4px',
																	width:
																		'100%',
																	opacity: 1,
																	marginTop:
																		'8px',
																}}
															/>
														</Box>
														<Box
															style={{
																width: isMobile
																	? '100%'
																	: '47.5%',
															}}
														>
															<Box
																row
																alignItems="baseline"
															>
																<Label
																	required
																	style={{
																		fontWeight:
																			'400px',
																		fontSize:
																			'16px',
																		color:
																			theme
																				.color
																				.accent2,
																	}}
																>
																	{t(
																		'onboarding.state'
																	)}
																</Label>
																<Spacer
																	size={8}
																/>
																<P
																	style={{
																		fontSize:
																			'10px',
																		color:
																			'#7B8088',
																	}}
																>
																	(Please
																	select
																	country
																	first)
																</P>
															</Box>

															<SelectSearch
																placeholder={t(
																	'onboarding.statePlaceHolder'
																)}
																value={d.state}
																options={
																	stateList
																}
																onChange={value => {
																	setFieldValue(
																		`collectionPoints.${index}.state`,
																		value.name
																	)
																}}
																labelKey="name"
																valueKey="label"
																defaultLabel={t(
																	'common.selectDefaultLabel'
																)}
																returnOnlyValue
																onBlur={
																	handleBlur
																}
																error={getIn(
																	errors,
																	[
																		'collectionPoints',
																		index,
																		'state',
																	]
																)}
																style={{
																	background:
																		d.state ===
																		''
																			? `${theme.color.grey11} 0% 0% no-repeat padding-box`
																			: `${theme.color.white} 0% 0% no-repeat padding-box`,

																	boxShadow:
																		d.state ===
																		''
																			? '0px 6px 16px #05050707'
																			: `0px 2px 8px ${theme.color.blue6}`,
																	border:
																		d.state ===
																		''
																			? `1px solid ${theme.color.grey4}`
																			: `1px solid ${theme.color.blue5}`,
																	borderRadius:
																		'4px',
																	width:
																		'100%',
																	opacity: 1,
																	marginTop:
																		'8px',
																}}
															/>
														</Box>
													</Box>
													<Box
														row={!isMobile}
														style={{
															width: '100%',
														}}
														justifyContent="space-between"
													>
														<Box
															style={{
																width: isMobile
																	? '100%'
																	: '47.5%',
															}}
														>
															<CustomInput
																value={d.city}
																handleChange={
																	handleChange
																}
																handleBlur={
																	handleBlur
																}
																touched={getIn(
																	touched,
																	[
																		'collectionPoints',
																		index,
																		'city',
																	]
																)}
																errors={getIn(
																	errors,
																	[
																		'collectionPoints',
																		index,
																		'city',
																	]
																)}
																required
																label={t(
																	'onboarding.cityVillage'
																)}
																name={`collectionPoints.${index}.city`}
															/>
														</Box>
														<Box
															style={{
																width: isMobile
																	? '100%'
																	: '47.5%',
															}}
														>
															<Box row>
																<Label
																	style={{
																		fontSize:
																			'14px',
																		color:
																			'#555454',
																	}}
																>
																	{t(
																		'tdmDetailsEntry.postalCode'
																	)}
																</Label>
																<SmallText
																	style={{
																		color:
																			'#CE924B',
																		marginLeft:
																			'8px',
																	}}
																>
																	(O)
																</SmallText>
															</Box>

															<CustomInput
																value={
																	d.postalCode
																}
																handleChange={
																	handleChange
																}
																handleBlur={
																	handleBlur
																}
																touched={getIn(
																	touched,
																	[
																		'collectionPoints',
																		index,
																		'postalCode',
																	]
																)}
																errors={getIn(
																	errors,
																	[
																		'collectionPoints',
																		index,
																		'postalCode',
																	]
																)}
																name={`collectionPoints.${index}.postalCode`}
															/>
														</Box>
													</Box>

													<Box
														style={{
															padding: '0px 0px',
															position:
																'relative',
															overflow: 'visible',
															background:
																'#FAFBFF',
															border:
																'1px solid #D9DEE5',
															borderRadius: '6px',
															width: isMobile
																? 300
																: 'auto',
														}}
														textAlign="left"
													/>
													<Spacer size={16} />

													<Box row={!isMobile}>
														<Box row>
															<Box
																style={{
																	width: isMobile
																		? 100
																		: 174,
																}}
															>
																<Box row>
																	<Label
																		style={{
																			fontSize:
																				'14px',
																			color:
																				'#555454',
																		}}
																	>
																		{t(
																			'marketPlace.certificateType'
																		)}
																	</Label>
																	<SmallText
																		style={{
																			color:
																				'#E02B2B',
																			marginLeft:
																				'8px',
																		}}
																	>
																		*
																	</SmallText>
																</Box>
																<Select
																	required
																	name="certType"
																	options={[
																		'RSPO',
																		'MSPO',
																		'MPOB',
																		'ISCC',
																		'ISPO',
																	]}
																	returnOnlyValue
																	value={
																		d.certType
																	}
																	onChange={value => {
																		setFieldValue(
																			`collectionPoints.${index}.certType`,
																			value
																		)
																	}}
																/>
															</Box>
															<Box
																style={{
																	paddingLeft: 24,
																}}
															>
																<Box row>
																	<Label
																		style={{
																			fontSize:
																				'14px',
																			color:
																				'#555454',
																		}}
																	>
																		{t(
																			'auditReport.certificationNumber'
																		)}
																	</Label>
																	<SmallText
																		style={{
																			color:
																				'#E02B2B',
																			marginLeft:
																				'8px',
																		}}
																	>
																		*
																	</SmallText>
																</Box>
																<CustomInput
																	name={`collectionPoints.${index}.certNumber`}
																	value={
																		d.certNumber
																	}
																	handleChange={
																		handleChange
																	}
																	handleBlur={
																		handleBlur
																	}
																	touched={getIn(
																		touched,
																		[
																			'collectionPoints',
																			index,
																			'certNumber',
																		]
																	)}
																	errors={getIn(
																		errors,
																		[
																			'collectionPoints',
																			index,
																			'certNumber',
																		]
																	)}
																	extendStyles={{
																		border:
																			'1px solid #ACB7CD',
																		borderRadius:
																			'4px',
																		opacity: 1,
																		width:
																			'176px',
																	}}
																/>
															</Box>
															<Box
																style={{
																	paddingLeft: 24,
																}}
															>
																<Box row>
																	<Label
																		style={{
																			fontSize:
																				'14px',
																			color:
																				'#555454',
																		}}
																	>
																		{t(
																			'onboarding.certificateBody'
																		)}
																	</Label>
																	<SmallText
																		style={{
																			color:
																				'#CE924B',
																			marginLeft:
																				'8px',
																		}}
																	>
																		(O)
																	</SmallText>
																</Box>
																<CustomInput
																	name={`collectionPoints.${index}.certBody`}
																	value={
																		d.certBody
																	}
																	handleChange={
																		handleChange
																	}
																	handleBlur={
																		handleBlur
																	}
																	touched={getIn(
																		touched,
																		[
																			'collectionPoints',
																			index,
																			'certBody',
																		]
																	)}
																	errors={getIn(
																		errors,
																		[
																			'collectionPoints',
																			index,
																			'certBody',
																		]
																	)}
																	extendStyles={{
																		border:
																			'1px solid #ACB7CD',
																		borderRadius:
																			'4px',
																		opacity: 1,
																		width:
																			'176px',
																	}}
																/>
															</Box>
														</Box>
													</Box>
													<Box row={!isMobile}>
														<Box row>
															<Box
																style={{
																	width: isMobile
																		? 100
																		: 174,
																}}
															>
																<Box row>
																	<Label
																		style={{
																			fontSize:
																				'14px',
																			color:
																				'#555454',
																		}}
																	>
																		{t(
																			'tdmColumnHeaders.supplyChainModel'
																		)}
																	</Label>
																	<SmallText
																		style={{
																			color:
																				'#CE924B',
																			marginLeft:
																				'8px',
																		}}
																	>
																		(O)
																	</SmallText>
																</Box>
																<Select
																	labelKey="label"
																	valueKey="name"
																	required
																	name="supplychainModel"
																	options={getSupplyChainModels(
																		t
																	)}
																	returnOnlyValue
																	value={
																		d.supplychainModel
																	}
																	onChange={value => {
																		setFieldValue(
																			`collectionPoints.${index}.supplychainModel`,
																			value
																		)
																	}}
																/>
															</Box>
															<Box
																style={{
																	paddingLeft: 24,
																}}
																width="200px"
															>
																<Box row>
																	<Label
																		style={{
																			fontSize:
																				'14px',
																			color:
																				'#555454',
																		}}
																	>
																		{t(
																			'viewCPOQuality.issueDate'
																		)}
																	</Label>
																	<SmallText
																		style={{
																			color:
																				'#E02B2B',
																			marginLeft:
																				'8px',
																		}}
																	>
																		*
																	</SmallText>
																</Box>

																<DatePicker
																	required
																	name={`collectionPoints.${index}.issueDate`}
																	returnKeyValue
																	toDate
																	maxDate={
																		new Date()
																	}
																	value={
																		new Date(
																			d.issueDate
																		)
																	}
																	onChange={keyValue => {
																		setFieldValue(
																			`collectionPoints.${index}.issueDate`,
																			keyValue[
																				`collectionPoints.${index}.issueDate`
																			]
																		)
																	}}
																/>
															</Box>
															<Box
																style={{
																	paddingLeft: 24,
																}}
																width="200px"
															>
																<Box row>
																	<Label
																		style={{
																			fontSize:
																				'14px',
																			color:
																				'#555454',
																		}}
																	>
																		{t(
																			'viewPartner.startDate'
																		)}
																	</Label>
																	<SmallText
																		style={{
																			color:
																				'#E02B2B',
																			marginLeft:
																				'8px',
																		}}
																	>
																		*
																	</SmallText>
																</Box>

																<DatePicker
																	required
																	name={`collectionPoints.${index}.certStartDate`}
																	returnKeyValue
																	toDate
																	minDate={
																		new Date(
																			d.issueDate
																		)
																	}
																	maxDate={
																		new Date(
																			d.expiryDate
																		)
																	}
																	value={
																		new Date(
																			d.certStartDate
																		)
																	}
																	onChange={keyValue => {
																		setFieldValue(
																			`collectionPoints.${index}.certStartDate`,
																			keyValue[
																				`collectionPoints.${index}.certStartDate`
																			]
																		)
																	}}
																/>
															</Box>
															<Box
																style={{
																	paddingLeft: 24,
																}}
																width="200px"
															>
																<Box row>
																	<Label
																		style={{
																			fontSize:
																				'14px',
																			color:
																				'#555454',
																		}}
																	>
																		{t(
																			'advancedFilter.endDate'
																		)}
																	</Label>
																	<SmallText
																		style={{
																			color:
																				'#E02B2B',
																			marginLeft:
																				'8px',
																		}}
																	>
																		*
																	</SmallText>
																</Box>

																<DatePicker
																	required
																	name={`collectionPoints.${index}.expiryDate`}
																	returnKeyValue
																	toDate
																	value={
																		new Date(
																			d.expiryDate
																		)
																	}
																	minDate={
																		new Date(
																			d.certStartDate
																		)
																	}
																	onChange={keyValue => {
																		setFieldValue(
																			`collectionPoints.${index}.expiryDate`,
																			keyValue[
																				`collectionPoints.${index}.expiryDate`
																			]
																		)
																	}}
																/>
															</Box>
														</Box>
													</Box>
												</Box>
											</Box>
										</CollapsableSegment>

										{values.collectionPoints.length > 1 && (
											<IconWrapper
												size={28}
												style={{
													margin: '0px 0px 0px 12px',
													cursor: 'pointer',
												}}
												onClick={() => {
													const newValue = updateIn(
														values,
														['collectionPoints'],
														arr =>
															removeAt(arr, index)
													)
													setValues(newValue)
												}}
											>
												<Icon
													color="#DB6262"
													glyph={DeleteIcon}
												/>
											</IconWrapper>
										)}
									</>
								</Box>
							)
						})}
					</form>
					<Box row>
						<Button
							action
							label={t('common.back')}
							extendStyles={{
								borderWidth: 0,
							}}
							reverse
							onClick={() => {
								if (isAddCP) {
									dispatch(
										MainRouteDuc.creators.switchPage(
											MainRouteDuc.types.ADMIN$SUBROOT,
											{
												rootModule:
													'company-information',
											}
										)
									)
								} else {
									dispatch(
										MainRouteDuc.creators.switchPage(
											MainRouteDuc.types.ONBOARD$ACTION,
											{
												action: 'add-cp-menu',
											}
										)
									)
								}
							}}
						/>
						<Button
							action
							rounded
							label={t('onboarding.addAnotherCollectionPoint')}
							customIcon={
								<ButtonIconWrapper color="white" lightBG>
									<Icon glyph={LeftArrowIcon} />
								</ButtonIconWrapper>
							}
							reverse
							onClick={() => {
								const addRow = updateIn(
									values,
									['collectionPoints'],
									arr =>
										addLast(
											arr,
											formValues.collectionPoints
										)
								)
								setInitialValues(addRow)
							}}
						/>

						<Button
							action
							disabled={disableCTA}
							rounded
							label={t('common.proceed')}
							reverse
							onClick={() => {
								handleSubmit()
							}}
							extendStyles={{
								background: '#3F56C4',
								color: theme.color.white,
								marginLeft: '48px',
							}}
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

export { AddCollectionPoint }
