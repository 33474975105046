import React, { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AppDuc } from 'core-app/modules/App/duc'
import { merge, getIn, omit, setIn } from 'timm'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Title } from 'core-app/shared/components/Title'
import { Icon } from 'ui-lib/icons/components/Icon'
import {
	Button,
	ButtonIconWrapper,
	ButtonWithNoBorder,
} from 'ui-lib/components/Button'
import { Label } from 'ui-lib/components/Typography'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import {
	getColumnConfig,
	getOutgoingColumnConfig,
} from 'core-app/modules/StorageCompany/components/Columns'
import { initiateSort, getTargetFilterQueries } from 'core-app/shared/helpers'
import { getStartIndex, getEndIndex } from 'ui-lib/components/Paginator/utils'
import { BoxShadowTable } from 'core-app/shared/components/BoxShadowTable'
import { Paginator } from 'ui-lib/components/Paginator'
import { DatePicker } from 'ui-lib/components/Datepicker'
import theme from 'ui-lib/utils/base-theme'
import { useTranslation } from 'react-i18next'
import { StorageCompanyDuc } from 'core-app/modules/StorageCompany/duc'

const PaginatorBlock = React.memo(
	({ paginationConfig, onChange, documents, startDesc, toDesc, ofDesc }) => (
		<Box padding={8}>
			<Paginator
				onClick={direction => onChange('paginate', { direction })}
				startIndex={getStartIndex(
					paginationConfig.activeIndex,
					paginationConfig.limit
				)}
				endIndex={getEndIndex(
					paginationConfig.activeIndex,
					paginationConfig.limit,
					paginationConfig.total
				)}
				totalCount={paginationConfig.total}
				startDesc={startDesc}
				toDesc={toDesc}
				ofDesc={ofDesc}
				hidePageResults={(documents || []).length === 0}
			/>
		</Box>
	)
)

const getBreadCrumbsList = (t, subModuleType) => [
	{
		label: t('breadcrumb.home'),
		name: 'home',
		isActive: true,
	},
	{
		label: t('breadcrumb.storage'),
		name: 'storage',
		isActive: true,
	},
	{
		label: subModuleType,
		name: 'storageListing',
		isActive: false,
	},
]

export const StorageListing = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const location = useSelector(StorageCompanyDuc.selectors.location)
	const { payload = {} } = location
	const { rootModule, submodule: subModule } = payload

	const subModuleType =
		subModule === 'map-incoming'
			? t('common.receiveGoods')
			: t('common.deliverGoods')

	const activeFilters = useSelector(
		StorageCompanyDuc.selectors.getListingActiveFilters
	)
	const activeSorts = useSelector(
		StorageCompanyDuc.selectors.getDocumentListingActiveSorts
	)
	const [activeDateType, setActiveDateType] = useState('createdAt')
	const activeDateFilters = getIn(activeFilters, [activeDateType]) || {}
	let activeStartDate = getIn(activeDateFilters, ['gte', 0]) || null
	if (activeStartDate) activeStartDate = new Date(activeStartDate)
	let activeEndDate = getIn(activeDateFilters, ['lte', 0]) || null
	if (activeEndDate) activeEndDate = new Date(activeEndDate)

	const [startDate, setStartDate] = useState(activeStartDate)
	const [endDate, setEndDate] = useState(activeEndDate || new Date())
	const paginationConfig = useSelector(
		StorageCompanyDuc.selectors.getListingPaginationEntries
	)
	const documents = useSelector(
		StorageCompanyDuc.selectors.getListingDocuments
	)
	const orgDetails = useSelector(AuthDuc.selectors.getAvailableOrgs)
	const allProducts = useSelector(AuthDuc.selectors.getProducts)
	const isLoading = useSelector(
		StorageCompanyDuc.selectors.getListingLoadingStatus
	)

	/** Handlers */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.STORAGE_COMPANY
				)
			)
		}
		if (target === 'storage') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.STORAGE_COMPANY$LISTING
				)
			)
		}
	}
	const handleChange = useCallback(
		(actionType, meta = {}) => {
			switch (actionType) {
				case 'initiate_sort': {
					initiateSort(
						dispatch,
						meta,
						StorageCompanyDuc.creators.fetchDocumentListing,
						location,
						'sort'
					)
					break
				}

				case 'remove_filter': {
					setActiveDateType('createdAt')
					setStartDate('')
					setEndDate(new Date())
					const { docType, type, filterType } = meta

					const currentQuery = getIn(location, ['query']) || {}

					const segments = Array.isArray(filterType)
						? filterType
						: [filterType]

					const targetFilters = omit(currentQuery, segments)

					dispatch(
						StorageCompanyDuc.creators.fetchDocumentListing(
							docType,
							[type],
							setIn(location, ['query'], targetFilters),
							true
						)
					)

					break
				}

				case 'apply_filter': {
					const {
						docType,
						type,
						filterType, // the root filter key e.g status
						filterSegment, // optional when you need to clear multiple
						filterValue,
						prefix,
					} = meta

					const currentQuery = getIn(location, ['query']) || {}
					const filterQueries = omit(currentQuery, ['sort', 'q']) // exclude sort and search convention

					let targetQueries = getIn(filterQueries, [filterType]) || []
					// if there are multiple segments being invoked as part of one filter, map them one to one via index
					if (
						Array.isArray(filterSegment) &&
						Array.isArray(filterValue)
					) {
						targetQueries = filterSegment.reduce(
							(agg, _filterSegment, _index) => {
								const _filterValue = filterValue[_index]

								if (!_filterValue) return agg

								return getTargetFilterQueries(
									agg,
									_filterSegment,
									_filterValue,
									prefix
								)
							},
							[]
						)
					} else {
						targetQueries = getTargetFilterQueries(
							targetQueries,
							filterSegment,
							filterValue,
							prefix
						)
					}

					const targetFilters = merge(
						currentQuery,
						merge(filterQueries, { [filterType]: targetQueries })
					)

					dispatch(
						StorageCompanyDuc.creators.fetchDocumentListing(
							docType,
							[type],
							setIn(location, ['query'], targetFilters),
							true
						)
					)

					break
				}

				case 'paginate': {
					const { direction } = meta || {}

					const {
						activeIndex = 0,
						currentCursor,
						nextCursor,
					} = paginationConfig

					const targetIndex = Math.max(
						direction === 'next'
							? activeIndex + 1
							: activeIndex - 1,
						0
					)

					let nextIndex = null

					if (nextCursor && activeIndex > targetIndex) {
						nextIndex = nextCursor
					} else if (currentCursor && activeIndex < targetIndex) {
						nextIndex = currentCursor
					}

					// retrigger the current route so new data is fetched again.
					dispatch(
						MainRouteDuc.creators.redirect(
							location.type,
							location.payload,
							{
								...location.query,
								activeIndex: targetIndex,
								limit: location.query.limit || 5,
								nextIndex,
							}
						)
					)

					break
				}
				case 'open_document': {
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types
								.TRADE_DOCUMENT_MANAGER$VIEWWDOCREFERENCE,
							{
								rootModule: meta.row.type,
								documentReference: meta.row.id,
								documentStatus: null,
							},
							{},
							{},
							true
						)
					)

					break
				}

				case 'assign_tank': {
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.STORAGE_COMPANY$SUBROOT,
							{
								rootModule: 'assign-tank',
								action: 'create',
							},
							{
								parentRef: meta.rowId,
							}
						)
					)
					break
				}

				case 'view_tank': {
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.STORAGE_COMPANY$SUBROOT,
							{
								rootModule: 'assign-tank',
								action: 'view',
							},
							{
								parentRef: meta.treaceId,
							}
						)
					)
					break
				}

				case 'open_batch': {
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.STORAGE_COMPANY$SUBROOT,
							{
								rootModule: 'map-output',
								action: 'view',
							},

							{
								traceID: meta.row.id,
							}
						)
					)
					break
				}

				case 'generateDO': {
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$WACTION,
							{
								rootModule: 'delivery-order',
								action: 'details',
							},
							{
								parentDocRef: `storage~${meta.row.id}`,
							},
							{},
							true
						)
					)

					break
				}

				default:
					break
			}
		},
		[dispatch, location, paginationConfig]
	)

	const activeLocale = useSelector(AppDuc.selectors.activeLocale)

	return (
		<Box padding={8} width="100%" height="100%">
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={getBreadCrumbsList(t, subModuleType)}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>

			<Box row justifyContent="space-between" alignItems="baseline">
				<Title title={subModuleType} />

				<Box row>
					<Box alignItems="flex-start" width={125} margin="0 5px">
						<Label small>{t('tdmDocumentListing.from')}</Label>
						<div style={{ fontSize: theme.fontSize.s }}>
							<DatePicker
								name="startDate"
								placeholder={t('tdmDocumentListing.choose')}
								hideError
								value={startDate}
								maxDate={endDate}
								onChange={value => {
									setStartDate(value)
								}}
							/>
						</div>
					</Box>
					<Box alignItems="flex-start" width={125} margin="0 5px">
						<Label small>{t('tdmDocumentListing.to')}</Label>
						<div style={{ fontSize: theme.fontSize.s }}>
							<DatePicker
								name="toDate"
								placeholder={t('tdmDocumentListing.choose')}
								hideError
								toDate
								minDate={startDate}
								value={endDate}
								maxDate={endDate}
								onChange={value => {
									setEndDate(value)
								}}
							/>
						</div>
					</Box>
					<Spacer size={10} horizontal={!isMobile} />

					<Box alignItems="flex-end" width={150} margin="0 5px" row>
						<Button
							label={t('productTrace.apply')}
							type="submit"
							extendStyles={{
								padding: '2px 20px',
							}}
							primary
							rounded
							disabled={!startDate}
							onClick={() => {
								handleChange('apply_filter', {
									docType: subModule,
									type: rootModule,
									filterSegment: ['end_date', 'start_date'],
									filterType: activeDateType,
									filterValue: [
										new Date(startDate).toISOString(),
										new Date(endDate).toISOString(),
									],
								})
							}}
						/>
						<Spacer size={10} horizontal={!isMobile} />

						<ButtonWithNoBorder
							label={t('productTrace.clear')}
							style={{
								fontSize: theme.fontSize.xl,
							}}
							onClick={() => {
								handleChange('remove_filter', {
									docType: subModule,
									type: rootModule,
									filterType: ['createdAt', 'updatedAt'],
								})
							}}
						/>
					</Box>
				</Box>
			</Box>
			<PaginatorBlock
				paginationConfig={paginationConfig}
				onChange={handleChange}
				documents={documents}
				startDesc={t('common.showing')}
				toDesc={t('common.to')}
				ofDesc={t('common.of')}
			/>

			<BoxShadowTable
				isLoading={isLoading}
				size="large"
				columnConfig={
					subModule === 'map-incoming'
						? getColumnConfig({
								type: rootModule,
								docType: subModuleType,
								subModuleType: subModule,
								sortsMap: activeSorts,
								onChange: handleChange,
								orgDetails,
								activeLocale,
								allProducts,
								t,
						  })
						: getOutgoingColumnConfig({
								type: rootModule,
								docType: subModuleType,
								subModuleType: subModule,
								sortsMap: activeSorts,
								onChange: handleChange,
								orgDetails,
								activeLocale,
								allProducts,
								t,
						  })
				}
				rowData={documents}
			/>
			<PaginatorBlock
				paginationConfig={paginationConfig}
				onChange={handleChange}
				documents={documents}
				startDesc={t('common.showing')}
				toDesc={t('common.to')}
				ofDesc={t('common.of')}
			/>

			<Box padding="25px 2px" style={{ maxWidth: 150 }}>
				<Button
					label={t('common.back')}
					rounded
					customIcon={
						<ButtonIconWrapper lightBG>
							<Icon glyph={LeftArrowIcon} />
						</ButtonIconWrapper>
					}
					onClick={() =>
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.STORAGE_COMPANY$LISTING
							)
						)
					}
				/>
			</Box>
		</Box>
	)
}
