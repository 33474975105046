import * as turf from '@turf/turf'
import React, { useEffect, useRef, useState } from 'react'
import ReactMapGL, { NavigationControl } from 'react-map-gl'
import { getIn } from 'timm'
import { getMapBoxAccessToken } from '../../config'
import { getRandomNumber } from '../../utils/helpers'

const AreaMapPreview = ({
	geoJSON = {},
	treeData = [],
	selectedDeforestOrg = {},
}) => {
	const selectedOrgID = getIn(selectedDeforestOrg, [0, 'value']) || ''

	const [mapLoaded, setMapLoaded] = useState(false)
	const [viewport, setViewport] = useState({})

	const orgShapeMap = {}

	function extractOrgCoordinates(node) {
		if (node.id && node.geoType !== 'shapefile') {
			orgShapeMap[node.id] = {
				geoType: node.geoType,
				shape: node.shape,
			}
		}

		if (node.children && node.children.length > 0) {
			node.children.forEach(child => {
				extractOrgCoordinates(child)
			})
		}
	}

	treeData.forEach(item => {
		extractOrgCoordinates(item)
	})

	const mapRef = useRef(null)

	const addPolygon = async (map, tree) => {
		try {
			if (tree && tree.shape && Object.keys(tree.shape).length > 0) {
				const id = `polygon-${getRandomNumber()}`
				const { geoType, shape } = tree
				const getCircleData = () => {
					const options = {
						steps: 64,
						units: 'kilometers',
					}
					let radius = 0
					shape.coordinates.forEach(item => {
						const coorDistance = turf.distance(shape.center, item)
						if (coorDistance > radius) {
							radius = coorDistance
						}
					})

					return turf.circle(shape.center, radius, options)
				}

				if (geoType === 'shapefile') {
					map.addLayer({
						id,
						type: 'fill',
						source: {
							type: 'vector',
							url: `mapbox://${shape.tilesetId}`, //  Mapbox tileset Map ID
						},
						'source-layer': shape.tilesetName, // name of tilesets
						paint: {
							'fill-color': '#90EE90',
							'fill-opacity': 0.5,
							'fill-outline-color': 'yellow',
						},
					})
				}

				if (geoType === 'coordinates') {
					map.addSource(id, {
						type: 'geojson',
						data: shape.center
							? getCircleData()
							: {
									geometry: {
										coordinates: [shape.coordinates],
										type: 'Polygon',
									},
									properties: {},
									type: 'Feature',
							  },
					})

					// Add a new layer to visualize the polygon.
					map.addLayer({
						id,
						type: 'fill',
						source: id, // reference the data source
						layout: {},
						paint: {
							'fill-color': '#90EE90', // color fill
							'fill-opacity': 0.5,
						},
					})
					// Add a outline around the polygon.
					map.addLayer({
						id: `${id}-outline_org`,
						type: 'line',
						source: id,
						layout: {},
						paint: {
							'line-width': 1,
							'line-color': 'yellow',
						},
					})
				}
			}
		} catch (err) {
			console.log(err)
		}
	}

	const checkAndUpdateZoom = async (deforestJson, orgJson = {}) => {
		const { geoType, shape = {} } = orgJson
		const { coordinates: orgCoords = [] } = shape || {}
		let zoomCoords = deforestJson

		if (orgJson && geoType === 'coordinates') {
			const orgPolyGon = {
				geometry: {
					coordinates: [orgCoords],
					type: 'Polygon',
				},
				properties: {},
				type: 'Feature',
			}

			zoomCoords = {
				...deforestJson,
				features: [...deforestJson.features, orgPolyGon],
			}
		}

		const { features } = zoomCoords

		const turfFeatureCollection = turf.featureCollection(features)

		const bbox = turf.bbox(turfFeatureCollection)

		const bounds = [
			[bbox[0], bbox[1]],
			[bbox[2], bbox[3]],
		]

		const map = mapRef.current.getMap()
		map.fitBounds(bounds, {
			padding: 40,
			duration: 1000,
		})

		setTimeout(() => {
			map.once('moveend', () => {
				setViewport({
					latitude: map.getCenter().lat,
					longitude: map.getCenter().lng,
					zoom: map.getZoom(),
					width: viewport.width,
					height: viewport.height,
				})
			})
		}, 1000)
	}

	const addGeoLayer = async () => {
		try {
			const map = mapRef.current.getMap()
			const { features = [] } = geoJSON

			const sourceId = 'polygon-maine'
			if (map.getSource(sourceId)) {
				map.removeSource(sourceId)
			}

			if (
				mapLoaded &&
				features.length > 0 &&
				turf.featureCollection(features)
			) {
				map.addSource(sourceId, {
					type: 'geojson',
					data: geoJSON,
				})

				map.addLayer({
					id: 'polygon-geolayer1',
					type: 'fill',
					source: sourceId,
					layout: {},
					paint: {
						'fill-color': '#D9A679', // brown color fill
						'fill-opacity': 0.5,
					},
				})

				map.addLayer({
					id: 'polygon-outline',
					type: 'line',
					source: sourceId,
					layout: {},
					paint: {
						'line-color': '#000',
						'line-width': 3,
					},
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	const loadLayers = async () => {
		try {
			const map = mapRef.current.getMap()

			if (!mapLoaded) {
				return
			}
			const allLayers = map.getStyle().layers

			allLayers.forEach(layer => {
				const { id } = layer
				if (id.includes('outline')) {
					if (map.getLayer(id)) {
						map.removeLayer(id)
					}
				}
			})

			allLayers.forEach(layer => {
				const { id } = layer

				if (id.startsWith('polygon-')) {
					if (map.getLayer(id)) {
						map.removeLayer(id)
					}
					if (map.getSource(id)) {
						map.removeSource(id)
					}
				}
			})

			if (orgShapeMap[selectedOrgID]) {
				await addPolygon(map, orgShapeMap[selectedOrgID])
			}
			await addGeoLayer()

			await checkAndUpdateZoom(geoJSON, orgShapeMap[selectedOrgID])
		} catch (err) {
			console.log('Load Layer Error', err)
		}
	}

	useEffect(() => {
		loadLayers()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mapLoaded, geoJSON])

	const onMapLoad = React.useCallback(() => {
		setMapLoaded(true)
	}, [])

	const myRef = useRef(null)
	const executeScroll = () => myRef.current.scrollIntoView()

	useEffect(() => {
		executeScroll()
	}, [])

	return (
		<div ref={myRef} style={{ display: 'block', height: '100%' }}>
			<ReactMapGL
				width="100%"
				height="100%"
				ref={mapRef}
				onLoad={onMapLoad}
				mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
				mapboxApiAccessToken={getMapBoxAccessToken()}
				{...viewport}
				onViewportChange={setViewport}
			>
				<div
					style={{
						width: '30px',
					}}
				>
					<NavigationControl
						showCompass={false}
						position="top-left"
					/>
				</div>
			</ReactMapGL>
		</div>
	)
}

AreaMapPreview.defaultProps = {
	jeoJSON: [],
}

export { AreaMapPreview }
