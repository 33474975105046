/* eslint-disable radix */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { MainRouteDuc } from 'core-app/routes/duc'
import { DealerDuc } from 'core-app/modules/Dealer/duc'
import { AppDuc } from 'core-app/modules/App/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { Box } from 'ui-lib/utils/Box'
import { Button } from 'ui-lib/components/Button'
import { Input, SuggestionsInput } from 'ui-lib/components/Input'
import { Spacer } from 'ui-lib/utils/Spacer'
import { CollapsableSegment } from 'ui-lib/components/CollapsableSegments'
import { H2, P } from 'ui-lib/components/Typography'
import { DatePicker } from 'ui-lib/components/Datepicker'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { isEmptyObject, useDebouncedCallback } from 'core-app/utils/helpers'
import { Modal } from 'ui-lib/components/Modal'
import { WebTour } from '../../../WebTour'
import { WebTourDuc } from '../../../WebTour/duc'

const CreateWork = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { results = [], message } = useSelector(
		AuthDuc.selectors.getOrgSearchResults
	)
	const locationState = useSelector(DealerDuc.selectors.location)
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const { payload = {} } = locationState
	const { rootModule } = payload
	const initialValues = useSelector(DealerDuc.selectors.getInitialWorkDetails)
	const dealerParticipantID = useSelector(
		DealerDuc.selectors.getDealerParticipantID
	)
	const transformedData = Object.values(results).filter(
		activeDoc => activeDoc
	)

	const partnerList = transformedData.map(({ name, id }) => {
		return { label: name, value: id }
	})
	const [showModal, setShowModal] = useState(false)
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)
	useEffect(() => {
		if (tourModule === 'workCreate') {
			dispatch(WebTourDuc.creators.setActiveTourModule('workCreate2'))
		}
	}, [tourModule, dispatch])

	const validationSchema = yup
		.object()
		.shape({
			smallholderID: yup.string().required(),
			harvesterCount: yup
				.number()
				.required(t('validation.harvesterCountRequired')),
			ffbCount: yup
				.number()
				.min(0)
				.required(t('validation.ffbCountRequired')),
			ripeBunches: yup.number(),
			overripeBunches: yup.number(),
			underripeBunches: yup.number(),
			emptyBunches: yup.number(),
			wetBunches: yup.number(),
			unripe: yup.number(),
		})
		// eslint-disable-next-line func-names
		.test('sums', function(value) {
			if (!value) return true
			const {
				ripeBunches,
				emptyBunches,
				overripeBunches,
				underripeBunches,
				wetBunches,
				unripe,
				ffbCount,
			} = value
			let valid = false

			valid =
				(parseInt(ripeBunches) || 0) +
					(parseInt(emptyBunches) || 0) +
					(parseInt(overripeBunches) || 0) +
					(parseInt(underripeBunches) || 0) +
					(parseInt(wetBunches) || 0) +
					(parseInt(unripe) || 0) ===
				ffbCount

			return (
				valid ||
				this.createError({
					path: 'ffbCount',
					message: t('validation.ffbCountCheck'),
				})
			)
		})

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.workListing'),
			name: 'work-listing',
			isActive: true,
		},
		{
			label: t('breadcrumb.createWork'),
			name: 'create-work',
			isActive: false,
		},
	]

	/** Handlers */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'work-listing') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.DEALER$SUBROOT,
					{
						rootModule,
					}
				)
			)
		}
	}

	const handleSearch = useDebouncedCallback(value => {
		dispatch(AuthDuc.creators.searchPartnerOrganization(value))
	}, 800)

	const {
		handleSubmit,
		submitForm,
		values,
		handleChange,
		handleBlur,
		setFieldValue,
		touched,
		errors,
	} = useFormik({
		initialValues,
		enableReinitialize: true,
		validationSchema,
		onSubmit: () => {
			setShowModal(true)
		},
	})
	useEffect(() => {
		setFieldValue('smallholderID', dealerParticipantID)
	}, [dealerParticipantID, setFieldValue])

	const disableCTA =
		!isEmptyObject(errors) ||
		!(values.ffbCount > 0 || values.ffbCount === 0)

	return (
		<>
			<Box padding={8} width="100%" height="100%">
				<Modal
					forceCloseviaButton
					size="large"
					show={showModal}
					heading={t('viewEmployee.confirm')}
					body={
						<P large bold>
							{t('dealerListing.confMessage')}
						</P>
					}
					closelabel={t('common.no')}
					confirmlabel={t('common.yes')}
					onClose={() => setShowModal(false)}
					onConfirm={() => {
						setShowModal(false)
						dispatch(
							DealerDuc.creators.createWork(
								values,
								t('createWork.workCreationSuccessToast')
							)
						)
					}}
				/>
				<Box overflow="hidden">
					<Box style={{ padding: '0 5' }}>
						<Breadcrumb
							links={breadCrumbs}
							onClick={target => handleBreadCrumbClick(target)}
						/>
					</Box>
					<Spacer size={32} />

					<Box width={350} overflow>
						<DatePicker
							value={values.createdDate}
							onChange={value => {
								setFieldValue('createdDate', value)
							}}
							toDate
							placeholder={t('common.selectDate')}
						/>
					</Box>
					<Spacer size={8} />
					<CollapsableSegment
						expand
						title={t('createWork.workDetails')}
						hasError={disableCTA}
						open
						message={
							disableCTA
								? t('validation.collapsableSegmentWarning')
								: t('validation.collapsableSegmentSuccess')
						}
						onToggle={() => undefined}
					>
						<Box padding={isMobile ? '15px' : '25px'}>
							<form onSubmit={handleSubmit}>
								<Box row={!isMobile}>
									<Box width={300} id="choose-smallholder">
										<SuggestionsInput
											label={t(
												'createWork.chooseSmallholder'
											)}
											value={values.smallholderID}
											placeholder={t(
												'createWork.chooseSmallholder'
											)}
											onChange={e =>
												handleSearch(e.target.value)
											}
											suggestions={partnerList}
											onSelect={selected => {
												dispatch(
													DealerDuc.creators.setDealerParticipantID(
														selected.value
													)
												)
											}}
											required
											error={message}
										/>
									</Box>
									<Spacer size={32} />
									<Box id="input-FFBandHarvester">
										<Box width={300}>
											<Input
												label={t('createWork.ffbCount')}
												name="ffbCount"
												value={values.ffbCount}
												type="number"
												onChange={handleChange}
												onBlur={handleBlur}
												error={
													touched.ffbCount &&
													errors.ffbCount
												}
												required
											/>
										</Box>
										<Spacer size={32} />
										<Box width={300}>
											<Input
												label={t(
													'createWork.harvesterCount'
												)}
												name="harvesterCount"
												value={values.harvesterCount}
												type="number"
												onChange={handleChange}
												onBlur={handleBlur}
												error={
													touched.harvesterCount &&
													errors.harvesterCount
												}
												required
											/>
										</Box>
									</Box>
								</Box>
								<Box row={!isMobile}>
									<Box width={300}>
										<Input
											label={t('createWork.area')}
											name="area"
											value={values.area}
											type="text"
											onChange={handleChange}
											onBlur={handleBlur}
											error={touched.area && errors.area}
										/>
									</Box>
									<Spacer size={32} />
									<Box width={300}>
										<Input
											label={t('createWork.block')}
											name="block"
											value={values.block}
											type="text"
											onChange={handleChange}
											onBlur={handleBlur}
											error={
												touched.block && errors.block
											}
										/>
									</Box>
								</Box>

								<H2 style={{ padding: '32px 0 40px' }}>
									{t('createWork.qualityDetails')}
								</H2>
								<Box id="input-qualityDetails">
									<Box row={!isMobile}>
										<Box width={300}>
											<Input
												label={t('createWork.ripe')}
												name="ripeBunches"
												value={values.ripeBunches}
												type="number"
												onChange={handleChange}
												onBlur={handleBlur}
												error={
													touched.ripeBunches &&
													errors.ripeBunches
												}
											/>
										</Box>
										<Spacer size={32} />
										<Box width={300}>
											<Input
												label={t('createWork.overripe')}
												name="overripeBunches"
												value={values.overripeBunches}
												type="number"
												onChange={handleChange}
												onBlur={handleBlur}
												error={
													touched.overripeBunches &&
													errors.overripeBunches
												}
											/>
										</Box>
									</Box>
									<Box row={!isMobile}>
										<Box width={300}>
											<Input
												label={t(
													'createWork.underripe'
												)}
												name="underripeBunches"
												value={values.underripeBunches}
												type="number"
												onChange={handleChange}
												onBlur={handleBlur}
												error={
													touched.underripeBunches &&
													errors.underripeBunches
												}
											/>
										</Box>
										<Spacer size={32} />
										<Box width={300}>
											<Input
												label={t('createWork.unripe')}
												name="unripe"
												value={values.unripe}
												type="number"
												onChange={handleChange}
												onBlur={handleBlur}
												error={
													touched.unripe &&
													errors.unripe
												}
											/>
										</Box>
									</Box>
									<Box row={!isMobile}>
										<Box width={300}>
											<Input
												label={t('createWork.empty')}
												name="emptyBunches"
												value={values.emptyBunches}
												type="number"
												onChange={handleChange}
												onBlur={handleBlur}
												error={
													touched.emptyBunches &&
													errors.emptyBunches
												}
											/>
										</Box>
										<Spacer size={32} />
										<Box width={300}>
											<Input
												label={t('createWork.wet')}
												name="wetBunches"
												value={values.wetBunches}
												type="number"
												onChange={handleChange}
												onBlur={handleBlur}
												error={
													touched.wetBunches &&
													errors.wetBunches
												}
											/>
										</Box>
									</Box>
								</Box>
							</form>
						</Box>
					</CollapsableSegment>

					<Spacer size={16} />
					<Box row justifyContent="flex-end">
						<Box
							style={{ padding: 2 }}
							width="200px"
							id="update-work"
						>
							<Button
								disabled={disableCTA}
								type="submit"
								primary
								label={t('common.submit')}
								onClick={() => {
									submitForm()
									dispatch(
										WebTourDuc.creators.setActiveTourModule(
											''
										)
									)
								}}
							/>
						</Box>
					</Box>
				</Box>
			</Box>
			{tourModule === 'workCreate2' && <WebTour />}
		</>
	)
}

export { CreateWork }
