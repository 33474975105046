import { MainRouteDuc } from 'core-app/routes/duc'
import saga from 'core-app/modules/WebTour/WebTourSaga'
import { AppDuc } from 'core-app/modules/App/duc'

export default async (dispatch, getState, { action, extra }) => {
	const { type, customMeta = {} } = action

	/** if someone specifically wants to skip thunk fetch again, this check helps */
	const { skipRouteThunk } = customMeta
	if (skipRouteThunk) return

	// Inject the saga here. If it wasn't already
	extra.getSagaInjectors().injectSaga('WEBTOUR', { saga })

	const isMainDashboard = MainRouteDuc.types.STORAGE_COMPANY === type
	if (isMainDashboard) {
		dispatch(AppDuc.creators.fetchTourStatus(true, 'StorageCompany'))
	}
	// if the isTourOpen is true dispatch dashboard tour and also set activeModule to be dashboard
	// the dashboard tour will be popup wherein the activeModule is set to initialState
}
