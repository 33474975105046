import { WbTicketDuc } from 'core-app/modules/WbTicket/duc'
import { MainRouteDuc } from 'core-app/routes/duc'
import saga from 'core-app/modules/WbTicket/WbTicketSaga'
import { getIn } from 'timm'
import { AuthDuc } from 'core-app/modules/Auth/duc'

export default async (dispatch, getState, { action, extra }) => {
	const { type, customMeta = {} } = action
	const currentLocationFromAction = getIn(action, [
		'meta',
		'location',
		'current',
	])
	/** if someone specifically wants to skip thunk fetch again, this check helps */
	const { skipRouteThunk } = customMeta
	if (skipRouteThunk) return

	// check if nothing changed in the route.

	const state = getState()

	const locationState = currentLocationFromAction || state.location

	const { payload } = locationState

	const documentReference = getIn(payload, ['documentReference'])

	// Inject the saga here. If it wasn't already
	extra.getSagaInjectors().injectSaga('WBTICKETS', { saga })

	const isMainDashboard = MainRouteDuc.types.WBTICKET === type
	const isDocumentView = MainRouteDuc.types.WBTICKET$WDOCREFERENCE === type
	const isDocumentCreation = MainRouteDuc.types.WBTICKET$WACTION === type
	let activeModule = ''
	const isSkip = true
	if (isMainDashboard) {
		// load the dashboard of the core module
		activeModule = 'dashboard'
		dispatch(WbTicketDuc.creators.fetchDocuments(isSkip, locationState))
	} else if (isDocumentView) {
		activeModule = 'document'
		dispatch(WbTicketDuc.creators.viewDocument(documentReference))
	} else if (isDocumentCreation) {
		activeModule = 'upload'
		dispatch(AuthDuc.creators.fetchPartnerOrgs())
	}
	// load the dashboard of the core module
	dispatch(WbTicketDuc.creators.setActiveModule(activeModule))
}
