import React from 'react'
import { useTranslation } from 'react-i18next'
import WorkOutline from 'ui-lib/icons/work-outline.svg'
import { Box } from 'ui-lib/utils/Box'
import { UploadExcel } from 'core-app/modules/UploadContent/components/UploadExcel'

const UploadDocuments = () => {
	const { t } = useTranslation()

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.admin'),
			name: 'admin',
			isActive: true,
		},
		{
			label: t('breadcrumb.assets'),
			name: 'assets',
			isActive: true,
		},
		{
			label: `${t('common.upload')} ${t('adminHome.assets')}`,
			name: 'upload-asset',
			isActive: false,
		},
	]

	return (
		<Box>
			<UploadExcel
				breadCrumbs={breadCrumbs}
				titleicon={WorkOutline}
				title={`${t('common.upload')} ${t('adminHome.assets')}`}
				type="assets"
			/>
		</Box>
	)
}

export { UploadDocuments }
