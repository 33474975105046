import { MessagesDuc } from 'core-app/modules/Messages/duc'
import { MainRouteDuc } from 'core-app/routes/duc'

export default async (dispatch, getState, { action, extra }) => {
	const { type, customMeta = {} } = action

	/** if someone specifically wants to skip thunk fetch again, this check helps */
	const { skipRouteThunk } = customMeta
	if (skipRouteThunk) return

	let activeModule = ''

	const isMainDashboard = MainRouteDuc.types.MESSAGES === type
	if (isMainDashboard) {
		// load the dashboard of the core module
		activeModule = 'messages'
	}
	if (activeModule === 'messages') {
		dispatch(MessagesDuc.creators.organisationListing())
	}
	dispatch(MessagesDuc.creators.setActiveModule(activeModule))
}
