import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { Box } from 'ui-lib/utils/Box'
import { BoxShadowWrapper } from 'ui-lib/components/Card'
import { Label, P } from 'ui-lib/components/Typography'
import { applyMediaQueries } from 'ui-lib/utils/helpers'
import theme from 'ui-lib/utils/base-theme'
import { getInfoAddressLink } from 'core-app/config'

const SummaryWrapper = styled(Box)({
	paddingRight: 80,
	'&:last-child': {
		paddingRight: 'initial',
	},
	...applyMediaQueries(['mobile'], {
		paddingTop: 4,
		paddingBottom: 4,
	}),
})

const SummaryBlock = ({ name, detail, onClick, isLink }) => (
	<SummaryWrapper>
		<Label color="#263b97" small>
			{name}
		</Label>
		<Box height={35}>
			{typeof detail === 'string' ? (
				<P small bold link={isLink} onClick={onClick}>
					{detail}
				</P>
			) : (
				detail
			)}
		</Box>
	</SummaryWrapper>
)

const EmployeeDetailsSegment = ({
	firstName,
	designation,
	email,
	mobile,
	id,
	orgRole,
}) => {
	const { t } = useTranslation()
	const { orgRole: loggedinUserRole } = useSelector(
		AuthDuc.selectors.getUserProfile
	)

	return (
		<Box padding="8px 0" width="100%" height="100%">
			<Box overflow>
				<BoxShadowWrapper>
					<Box
						padding={8}
						style={{
							backgroundColor: '#D8DDEF',
						}}
					>
						<P bold color="#263b97">
							{t('viewEmployee.empDetails')}
						</P>
					</Box>
					<Box padding={8}>
						<Box
							width="100%"
							row
							justifyContent="space-between"
							style={{ paddingTop: '8px' }}
						>
							<Box row wrap>
								<SummaryBlock
									name={t('viewEmployee.empName')}
									detail={firstName || '---'}
								/>
								<SummaryBlock
									name={t('viewEmployee.designation')}
									detail={designation || '---'}
								/>
								<SummaryBlock
									name={t('viewEmployee.email')}
									detail={email || '---'}
								/>
								<SummaryBlock
									name={t('viewEmployee.phoneNo')}
									detail={mobile || '---'}
								/>
								<SummaryBlock
									name={t('viewEmployee.empID')}
									detail={id || '---'}
								/>
								<SummaryBlock
									name={t('viewEmployee.dibizRole')}
									detail={
										orgRole
											? t(`empMgmtHome.${orgRole}`)
											: '---'
									}
								/>
							</Box>
						</Box>
					</Box>
					{loggedinUserRole === 'owner' && orgRole === 'owner' && (
						<Box margin={8}>
							<Label color={theme.color.grey6}>
								{t('viewEmployee.ownerNote', {
									infoAddressLink: getInfoAddressLink(),
								})}
							</Label>
						</Box>
					)}
				</BoxShadowWrapper>
			</Box>
		</Box>
	)
}

export { EmployeeDetailsSegment }
