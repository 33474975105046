import React from 'react'
import { useSelector } from 'react-redux'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { Box } from 'ui-lib/utils/Box'
// eslint-disable-next-line import/extensions
import Loader from 'core/src/assets/loader.gif'

const QRRoutePage = () => {
	const location = useSelector(AuthDuc.selectors.location)
	const query = location?.query?.tag ? `tag=${location.query.tag}` : ''
	const webSignUpLink = `${window.location.origin}/signup?${query}`

	const isIOS =
		navigator.userAgent.match('iPad') ||
		navigator.userAgent.match('iPhone') ||
		navigator.userAgent.match('iPod')

	const isAndroid = navigator.userAgent.match('Android')

	if (isAndroid) {
		// open the app if the app exists if not play store will be opened
		window.location.replace(`dibizshapp://userRegister?${query}`)
		// eslint-disable-next-line func-names
		window.setTimeout(function() {
			window.location.replace(
				`https://play.google.com/store/apps/details?id=com.dibizmarketplace&${query}`
			)
		}, 3000)
	} else if (isIOS) {
		const isAppleBrowser = navigator.userAgent
			.toLowerCase()
			.match('/apple/')

		const isSafari =
			isAppleBrowser &&
			!navigator.userAgent.match(/crios/i) &&
			!navigator.userAgent.match(/fxios/i) &&
			!navigator.userAgent.match(/Opera|OPT\//)
		if (!isSafari) {
			window.location.replace(`dibizshapp://userRegister?${query}`)
			// open the app if the app exists if not play store will be opened
		}

		// eslint-disable-next-line func-names
		window.setTimeout(function() {
			window.location.replace(
				`https://itunes.apple.com/app/id1612610732?${query}`
			)
		}, 3000)
	} else {
		window.location.replace(webSignUpLink)
	}

	return (
		<Box>
			<Box
				style={{
					marginTop: 200,
					justifyContent: 'center',
				}}
				row
			>
				<div>
					<img src={Loader} alt="Loader" />
				</div>
			</Box>
		</Box>
	)
}

export { QRRoutePage }
