import React from 'react'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { BoxShadowWrapper } from 'ui-lib/components/Card'
import styled from 'styled-components'
import { getIn } from 'timm'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MainRouteDuc } from 'core-app/routes/duc'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import BackArrowIcon from 'ui-lib/icons/arrow-back.svg'
import { Title } from 'core-app/shared/components/Title'
import { Spacer } from 'ui-lib/utils/Spacer'
import { BoxShadowTable } from 'core-app/shared/components/BoxShadowTable'
import { Label, P } from 'ui-lib/components/Typography'
import { getDateByFormat } from 'core-app/utils/date'
import { getAssignTankPreview } from 'core-app/modules/StorageCompany/components/Columns'
import { Box } from 'ui-lib/utils/Box'
import { RefineryDuc } from '../../duc'

const Line = styled.div(p => ({
	width: p.size || 'inherit',
	borderBottom: '1px solid #c9c9c9',
}))

const KeyValueSegment = ({ name, detail }) => {
	return (
		<>
			<Box row padding="2px 0px">
				<Box width={150}>
					<Label>{name} : </Label>
				</Box>
				<Box style={{ marginLeft: 10 }} flex row>
					<P large>{detail}</P>
				</Box>
			</Box>
			<Spacer size={8} />
		</>
	)
}

const getBreadCrumbsList = t => [
	{
		label: t('breadcrumb.home'),
		name: 'home',
		isActive: true,
	},
	{
		label: t('breadcrumb.inputStorage'),
		name: 'inputStorage',
		isActive: true,
	},
	{
		label: t('breadcrumb.details'),
		name: 'mapOutput',
		isActive: false,
	},
]

export const AssignTankView = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const locationState = useSelector(RefineryDuc.selectors.location)

	const allProducts = useSelector(AuthDuc.selectors.getProducts)
	const storageList = useSelector(AuthDuc.selectors.getStorageUnits)

	const assignedTank = useSelector(RefineryDuc.selectors.getAssignedTank)

	const productName = allProducts.filter(
		product => product.id === assignedTank.productId
	)
	const name = getIn(productName, [0, 'name']) || ''

	const uom = getIn(productName, [0, 'uom']) || ''
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.REFINERY$SUBROOT,
					{ rootModule: 'production' }
				)
			)
		} else if (target === 'inputStorage') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.REFINERY$SUBROOT,
					{ rootModule: 'input-storage' }
				)
			)
		}
	}

	return (
		<Box padding={8} width="100%" height="100%">
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={getBreadCrumbsList(t)}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Box row justifyContent="space-between" alignItems="baseline">
				<Box row style={{ marginTop: '10px' }}>
					<Box>
						<IconWrapper
							size={30}
							onClick={() =>
								dispatch(
									MainRouteDuc.creators.switchPage(
										MainRouteDuc.types.REFINERY$SUBROOT,
										{ rootModule: 'input-storage' }
									)
								)
							}
						>
							<Icon glyph={BackArrowIcon} />
						</IconWrapper>
					</Box>
					<Spacer size={8} horizontal />
					<Title
						small
						style={{ marginTop: 0, marginBottom: 0 }}
						title={t('storage.assignTankDetails')}
						note={t('storage.assignTankCreateNote')}
					/>
				</Box>
			</Box>

			<Spacer size={24} />
			<BoxShadowWrapper>
				<Box padding={8}>
					<P bold color="#263b97">
						{t('storage.consignmentDetails')}
					</P>
				</Box>
				<Line />
				<Box padding={8}>
					<KeyValueSegment
						name={t('storage.consignmentNumber')}
						detail={locationState.query.consignmentNumber}
					/>
					<KeyValueSegment
						name={t('storage.dateIssued')}
						detail={getDateByFormat(locationState.query.issueDate)}
					/>
					<KeyValueSegment
						name={t('storage.product')}
						detail={name}
					/>
					<KeyValueSegment
						name={t('storage.quantity')}
						detail={`${assignedTank.totalQty} ${uom}`}
					/>
				</Box>
			</BoxShadowWrapper>
			<Spacer size={36} />

			<Box row>
				<Label>{t('storage.showingTankProduct')}</Label>
			</Box>
			<Spacer size={24} />

			<BoxShadowTable
				size="large"
				columnConfig={getAssignTankPreview({
					rootModule: 'storage-assignment',
					storageList,
					uom,
					t,
				})}
				rowData={assignedTank.assignedTank}
			/>
		</Box>
	)
}
