import Duck from 'extensible-duck'
import { setIn, getIn } from 'timm'
import { createSelector } from 'reselect'
import { initialState } from './config'

export const MODULES_LIST = ['dashboard', 'subDashboard']

export const NAME_ALIASES = {
	create: 'common.create',
	edit: 'common.update',
	view: 'common.view',
}

export const LabelsMap = {
	ticket: 'common.ticket',
	records: 'common.records',
	document: 'common.document',
	reports: 'common.reports',
	bin: 'common.bin',
}

export const PlantationDuc = new Duck({
	namespace: 'plantation',
	store: 'global',
	types: [
		'SET_ACTIVE_MODULE',
		'INITIATE_TICKET_CREATION',
		'SET_ACTIVE_PRODUCT_ID',
		'TOGGLE_PRODUCT_SELECTION_POPUP',
		'FETCH_TICKET_LISTING',
		'FETCH_TICKET_LISTING_LOADING',
		'FETCH_TICKET_LISTING_SUCCESS',
		'CREATE_TICKET',
		'SET_PAGINATION_ENTRIES',
		'SET_BIN_PAGINATION_ENTRIES',
		'SET_ACTIVE_LISTING_FILTERS',
		'SET_BIN_ACTIVE_LISTING_FILTERS',
		'SET_ACTIVE_SORTS',
		'FETCH_TICKET_INFORMATION',
		'FETCH_TICKET_INFORMATION_SUCCESS',
		'FETCH_TICKET_INITIAL_VALUES',
		'FETCH_TICKET_INITIAL_VALUES_SUCCESS',
		'SET_TICKET_LOADING_STATUS',
		'RESET_TICKET_LOADING_STATUS',
		'CREATE_BIN',
		'SET_BIN_INITIAL_VALUES_SUCCESS',
		'FETCH_BIN_LISTING',
		'FETCH_BIN_LISTING_SUCCESS',
		'SET_BIN_LOADING_STATUS',
		'RESET_BIN_LOADING_STATUS',
		'FETCH_BIN_INFORMATION',
		'FETCH_BIN_INFORMATION_SUCCESS',
		'FETCH_UNASSIGNED_TICKET_LISTING',
		'FETCH_UNASSIGNED_TICKET_LISTING_SUCCESS',
		'FETCH_USER_LIST_SUCCESS',
		'FETCH_BIN_INFORMATION_VALUES',
		'FETCH_BIN_INFORMATION_VALUES_SUCCESS',
		'SET_BIN_ACTIVE_SORTS',
		'MARKED_BIN_AS_COMPLETE',
		'FETCH_DOCUMENT_LISTING',
		'FETCH_DOCUMENT_LISTING_SUCCESS',
		'FETCH_INSIGHTS',
		'FETCH_INSIGHTS_SUCCESS',
		'FLUSH_TICKET_STATE',
		'FLUSH_BIN_STATE',
		'SET_ACTIVE_FILTER_ENTRIES',
		'FETCH_TRIP_INFORMATION',
		'FETCH_TRIP_INFORMATION_SUCCESS',
		'SET_RECORDS_PAGINATION_QUERIES',
		'FLUSH_RECORDS_STATE',
	],
	initialState,
	reducer: (state, action, duck) => {
		switch (action.type) {
			case duck.types.SET_ACTIVE_MODULE: {
				return setIn(state, ['activeModule'], action.module)
			}

			case duck.types.FETCH_TICKET_LISTING_SUCCESS: {
				const { activeDocuments } = action

				return setIn(
					state,
					['modules', 'ticket', 'activeDocuments'],
					activeDocuments
				)
			}

			case duck.types.FETCH_TICKET_LISTING_LOADING: {
				const rootPath = ['modules', 'ticket', 'loading']

				return setIn(state, [...rootPath], action.status)
			}

			case duck.types.SET_TICKET_LOADING_STATUS: {
				const { _modules } = action
				const rootPath = ['modules', 'ticket', 'loading']

				return setIn(state, [...rootPath], _modules)
			}

			case duck.types.RESET_TICKET_LOADING_STATUS: {
				const rootPath = ['modules', 'ticket', 'loading']

				return setIn(state, [...rootPath], false)
			}

			case duck.types.SET_PAGINATION_ENTRIES: {
				const {
					activeIndex,
					limit,
					total,
					nextCursor,
					currentCursor,
				} = action

				const root = ['modules', 'ticket', 'pagination']
				let nextState = state

				nextState = setIn(
					nextState,
					[...root, 'activeIndex'],
					activeIndex || 0
				)

				if (limit)
					nextState = setIn(nextState, [...root, 'limit'], limit)

				if (total)
					nextState = setIn(nextState, [...root, 'total'], total)

				if (nextCursor)
					nextState = setIn(
						nextState,
						[...root, 'nextCursor'],
						nextCursor
					)

				if (currentCursor)
					nextState = setIn(
						nextState,
						[...root, 'currentCursor'],
						currentCursor
					)

				return nextState
			}

			case duck.types.SET_ACTIVE_LISTING_FILTERS: {
				const { filters = {} } = action

				return setIn(
					state,
					['modules', 'ticket', 'activeFilters'],
					filters
				)
			}

			case duck.types.SET_BIN_PAGINATION_ENTRIES: {
				const {
					activeIndex,
					limit,
					total,
					nextCursor,
					currentCursor,
				} = action

				const root = ['modules', 'bin', 'pagination']
				let nextState = state

				nextState = setIn(
					nextState,
					[...root, 'activeIndex'],
					activeIndex || 0
				)

				if (limit)
					nextState = setIn(nextState, [...root, 'limit'], limit)

				if (total)
					nextState = setIn(nextState, [...root, 'total'], total)

				if (nextCursor)
					nextState = setIn(
						nextState,
						[...root, 'nextCursor'],
						nextCursor
					)

				if (currentCursor)
					nextState = setIn(
						nextState,
						[...root, 'currentCursor'],
						currentCursor
					)

				return nextState
			}

			case duck.types.SET_BIN_ACTIVE_LISTING_FILTERS: {
				const { filters = {} } = action

				return setIn(
					state,
					['modules', 'bin', 'activeFilters'],
					filters
				)
			}

			case duck.types.SET_ACTIVE_SORTS: {
				const { sortMap } = action
				let tickets = {}
				;(sortMap.ticket || []).forEach(key => {
					const arr = key.split('|')

					const lastVal = arr.pop()

					tickets = setIn(tickets, arr, lastVal)
				})

				return setIn(
					state,
					['modules', 'ticket', 'activeSorts'],
					tickets
				)
			}

			case duck.types.SET_BIN_ACTIVE_SORTS: {
				const { sortMap } = action
				let bins = {}
				;(sortMap.bin || []).forEach(key => {
					const arr = key.split('|')

					const lastVal = arr.pop()

					bins = setIn(bins, arr, lastVal)
				})

				return setIn(state, ['modules', 'bin', 'activeSorts'], bins)
			}

			case duck.types.FETCH_TICKET_INFORMATION_SUCCESS: {
				const { ticketInfo } = action

				return setIn(
					state,
					['modules', 'ticket', 'ticketInformation'],
					ticketInfo
				)
			}
			case duck.types.FETCH_TRIP_INFORMATION_SUCCESS: {
				const { tripInfo } = action

				return setIn(state, ['modules', 'trips', 'tripData'], tripInfo)
			}

			case duck.types.FETCH_TICKET_INITIAL_VALUES_SUCCESS: {
				const { ticketInitialValues } = action

				return setIn(
					state,
					['modules', 'ticket', 'initialValues'],
					ticketInitialValues
				)
			}

			case duck.types.FETCH_USER_LIST_SUCCESS: {
				const { userList } = action

				return setIn(state, ['modules', 'ticket', 'users'], userList)
			}

			case duck.types.FETCH_BIN_LISTING_SUCCESS: {
				const { activeDocuments } = action

				return setIn(
					state,
					['modules', 'bin', 'activeDocuments'],
					activeDocuments
				)
			}

			case duck.types.FETCH_BIN_INFORMATION_SUCCESS: {
				const { ticketInfo } = action

				return setIn(
					state,
					['modules', 'bin', 'binInformation'],
					ticketInfo
				)
			}

			case duck.types.FETCH_UNASSIGNED_TICKET_LISTING_SUCCESS: {
				const { activeDocuments } = action

				return setIn(
					state,
					['modules', 'bin', 'ticketList'],
					activeDocuments
				)
			}

			case duck.types.FETCH_BIN_INFORMATION_VALUES_SUCCESS: {
				const { response } = action

				return setIn(
					state,
					['modules', 'bin', 'initialValues'],
					response
				)
			}

			case duck.types.SET_BIN_INITIAL_VALUES_SUCCESS: {
				const { binInitialValues } = action

				return setIn(
					state,
					['modules', 'bin', 'initialValues'],
					binInitialValues
				)
			}

			case duck.types.FETCH_DOCUMENT_LISTING_SUCCESS: {
				const { activeDocuments, docType } = action

				if (docType === '') {
					return setIn(
						state,
						['modules', 'document', 'activeDocuments'],
						activeDocuments
					)
				}

				return setIn(
					state,
					['modules', 'document', 'activeDocuments', docType],
					activeDocuments[docType]
				)
			}

			case duck.types.FLUSH_RECORDS_STATE: {
				const { name } = action

				return setIn(
					state,
					['modules', 'document', 'activeDocuments', name],
					[]
				)
			}

			case duck.types.SET_RECORDS_PAGINATION_QUERIES: {
				const { pagination } = action

				return setIn(
					state,
					['modules', 'document', 'activePagination'],
					pagination
				)
			}

			case duck.types.FETCH_INSIGHTS_SUCCESS: {
				const { userInsight } = action

				return setIn(state, ['insightsData'], userInsight)
			}

			case duck.types.SET_ACTIVE_PRODUCT_ID: {
				const { activeProductID } = action

				return setIn(state, ['activeProductID'], activeProductID)
			}

			case duck.types.TOGGLE_PRODUCT_SELECTION_POPUP: {
				const { status } = action

				return setIn(state, ['prodSelectionModal'], status)
			}

			case duck.types.FLUSH_TICKET_STATE: {
				return setIn(
					state,
					['modules', 'ticket'],
					initialState.modules.ticket
				)
			}

			case duck.types.FLUSH_BIN_STATE: {
				return setIn(
					state,
					['modules', 'bin'],
					initialState.modules.bin
				)
			}

			case duck.types.SET_ACTIVE_FILTER_ENTRIES: {
				const { filters, docType } = action
				const root = ['modules', 'document', 'activeFilter']
				const _activeFilters =
					docType === 'all' ? filters : getIn(filters, [docType])

				const modules =
					docType === 'all' ? [...root] : [...root, docType]

				return setIn(state, modules, _activeFilters)
			}

			default:
				return state
		}
	},
	selectors: {
		auth: state => state.auth,
		location: state => state.location,
		activeModule: state =>
			getIn(state, ['plantation', 'activeModule']) || 'Error',
		getInsightsData: state =>
			getIn(state, ['plantation', 'insightsData']) || {},
		getActiveProductID: state =>
			getIn(state, ['plantation', 'activeProductID']),
		getProductSelectionModalStatus: state =>
			getIn(state, ['plantation', 'prodSelectionModal']),
		ticketState: state => getIn(state, ['plantation', 'modules', 'ticket']),
		binState: state => getIn(state, ['plantation', 'modules', 'bin']),
		tripState: state => getIn(state, ['plantation', 'modules', 'trips']),
		documentState: state =>
			getIn(state, ['plantation', 'modules', 'document']),

		getMainDashboardActiveSorts: new Duck.Selector(selectors =>
			createSelector(
				selectors.ticketState,
				ticket => getIn(ticket, ['activeSorts', 'ticket']) || {}
			)
		),
		getMainDashboardBinActiveSorts: new Duck.Selector(selectors =>
			createSelector(
				selectors.binState,
				bin => getIn(bin, ['activeSorts', 'bin']) || {}
			)
		),
		getListingPaginationEntries: new Duck.Selector(selectors =>
			createSelector(selectors.ticketState, ticket => ticket.pagination)
		),
		getBinListingPaginationEntries: new Duck.Selector(selectors =>
			createSelector(selectors.binState, bin => bin.pagination)
		),

		getListingActiveFilters: new Duck.Selector(selectors =>
			createSelector(
				selectors.ticketState,
				ticket => ticket.activeFilters
			)
		),

		getBinListingActiveFilters: new Duck.Selector(selectors =>
			createSelector(selectors.binState, bin => bin.activeFilters)
		),

		getActiveuserLists: new Duck.Selector(selectors =>
			createSelector(selectors.ticketState, ticket => ticket.users || [])
		),
		getListingLoadingStatus: new Duck.Selector(selectors =>
			createSelector(
				selectors.ticketState,
				ticket => ticket.loading || false
			)
		),
		getDocumentListingActiveSorts: new Duck.Selector(selectors =>
			createSelector(
				selectors.ticketState,
				ticket => getIn(ticket, ['activeSorts']) || {}
			)
		),
		getListingTickets: new Duck.Selector(selectors =>
			createSelector(
				selectors.ticketState,
				ticket => getIn(ticket, ['activeDocuments', 'list']) || []
			)
		),
		fetchTicketInformation: new Duck.Selector(selectors =>
			createSelector(
				selectors.ticketState,
				ticket => getIn(ticket, ['ticketInformation']) || []
			)
		),
		fetchTripInformation: new Duck.Selector(selectors =>
			createSelector(
				selectors.tripState,
				trip => getIn(trip, ['tripData']) || []
			)
		),
		fetchInitialValues: new Duck.Selector(selectors =>
			createSelector(
				selectors.ticketState,
				ticket => getIn(ticket, ['initialValues']) || {}
			)
		),

		// TRACTOR

		fetchBinInitialValues: new Duck.Selector(selectors =>
			createSelector(
				selectors.binState,
				bin => getIn(bin, ['initialValues']) || []
			)
		),
		getListingBins: new Duck.Selector(selectors =>
			createSelector(
				selectors.binState,
				bin => getIn(bin, ['activeDocuments', 'list']) || []
			)
		),
		fetchBinInformation: new Duck.Selector(selectors =>
			createSelector(
				selectors.binState,
				bin => getIn(bin, ['binInformation']) || []
			)
		),
		unAssignedTicketListing: new Duck.Selector(selectors =>
			createSelector(
				selectors.binState,
				bin => getIn(bin, ['ticketList']) || {}
			)
		),

		// DOCUMENT

		getDocumentActiveTabs: new Duck.Selector(selectors =>
			createSelector(
				selectors.documentState,
				document => getIn(document, ['tabsConfig']) || {}
			)
		),
		fetchDocuments: new Duck.Selector(selectors =>
			createSelector(
				selectors.documentState,
				document => getIn(document, ['activeDocuments']) || []
			)
		),
		getActiveFilters: new Duck.Selector(selectors =>
			createSelector(
				selectors.documentState,
				document => getIn(document, ['activeFilter']) || []
			)
		),
		getRecordsPaginationQueries: state =>
			getIn(state, [
				'plantation',
				'modules',
				'document',
				'activePagination',
			]) || [],
	},
	creators: duck => ({
		setActiveModule: module => ({
			type: duck.types.SET_ACTIVE_MODULE,
			module,
		}),
		setTicketLoading: (root, _modules) => ({
			type: duck.types.SET_TICKET_LOADING_STATUS,
			root,
			_modules,
		}),
		resetTicketLoading: () => ({
			type: duck.types.RESET_TICKET_LOADING_STATUS,
		}),
		fetchListingTickets: (
			docType,
			rootModules,
			locationState,
			skipGlobalLoader = false
		) => ({
			type: duck.types.FETCH_TICKET_LISTING,
			rootModules,
			docType,
			locationState,
			skipGlobalLoader,
		}),
		fetchDocumentLoading: status => ({
			type: duck.types.FETCH_TICKET_LISTING_LOADING,
			status,
		}),
		fetchListingTicketsSuccess: activeDocuments => ({
			type: duck.types.FETCH_TICKET_LISTING_SUCCESS,
			activeDocuments,
		}),
		createTicket: (values, createSuccessToast, updateSuccessToast) => ({
			type: duck.types.CREATE_TICKET,
			values,
			createSuccessToast,
			updateSuccessToast,
		}),
		setPaginationEntries: (
			activeIndex,
			limit,
			total,
			nextCursor,
			currentCursor
		) => ({
			type: duck.types.SET_PAGINATION_ENTRIES,
			activeIndex,
			limit,
			total,
			nextCursor,
			currentCursor,
		}),
		setBinPaginationEntries: (
			activeIndex,
			limit,
			total,
			nextCursor,
			currentCursor
		) => ({
			type: duck.types.SET_BIN_PAGINATION_ENTRIES,
			activeIndex,
			limit,
			total,
			nextCursor,
			currentCursor,
		}),
		setActiveListingFilters: filters => ({
			type: duck.types.SET_ACTIVE_LISTING_FILTERS,
			filters,
		}),
		setBinActiveListingFilters: filters => ({
			type: duck.types.SET_BIN_ACTIVE_LISTING_FILTERS,
			filters,
		}),
		setActiveSorts: sortMap => ({
			type: duck.types.SET_ACTIVE_SORTS,
			sortMap,
		}),
		setBinActiveSorts: sortMap => ({
			type: duck.types.SET_BIN_ACTIVE_SORTS,
			sortMap,
		}),
		getTicketInfo: ticketInfo => ({
			type: duck.types.FETCH_TICKET_INFORMATION_SUCCESS,
			ticketInfo,
		}),
		getTripInfo: tripInfo => ({
			type: duck.types.FETCH_TRIP_INFORMATION_SUCCESS,
			tripInfo,
		}),
		ticketPreview: (ticketID, documentType) => ({
			type: duck.types.FETCH_TICKET_INFORMATION,
			ticketID,
			documentType,
		}),
		fetchTicketInitialValues: ticketID => ({
			type: duck.types.FETCH_TICKET_INITIAL_VALUES,
			ticketID,
		}),
		setInitialValues: ticketInitialValues => ({
			type: duck.types.FETCH_TICKET_INITIAL_VALUES_SUCCESS,
			ticketInitialValues,
		}),
		setUerLists: userList => ({
			type: duck.types.FETCH_USER_LIST_SUCCESS,
			userList,
		}),
		// Tractor
		createBin: (values, successToast) => ({
			type: duck.types.CREATE_BIN,
			values,
			successToast,
		}),
		fetchListingBins: (
			docType,
			rootModules,
			locationState,
			skipGlobalLoader = false
		) => ({
			type: duck.types.FETCH_BIN_LISTING,
			rootModules,
			docType,
			locationState,
			skipGlobalLoader,
		}),
		fetchListingBinsSuccess: activeDocuments => ({
			type: duck.types.FETCH_BIN_LISTING_SUCCESS,
			activeDocuments,
		}),
		setBinLoading: (root, _modules) => ({
			type: duck.types.SET_BIN_LOADING_STATUS,
			root,
			_modules,
		}),
		resetBinLoading: () => ({
			type: duck.types.RESET_BIN_LOADING_STATUS,
		}),
		binPreview: documentReference => ({
			type: duck.types.FETCH_BIN_INFORMATION,
			documentReference,
		}),
		getBinInfo: ticketInfo => ({
			type: duck.types.FETCH_BIN_INFORMATION_SUCCESS,
			ticketInfo,
		}),
		fetchBinTickets: (
			docType,
			rootModules,
			locationState,
			binInitialValues,
			skipGlobalLoader = false
		) => ({
			type: duck.types.FETCH_UNASSIGNED_TICKET_LISTING,
			rootModules,
			docType,
			locationState,
			binInitialValues,
			skipGlobalLoader,
		}),
		fetchBinTicketsSuccess: activeDocuments => ({
			type: duck.types.FETCH_UNASSIGNED_TICKET_LISTING_SUCCESS,
			activeDocuments,
		}),
		setBinInitialValues: binInitialValues => ({
			type: duck.types.SET_BIN_INITIAL_VALUES_SUCCESS,
			binInitialValues,
		}),
		fetchBinInfo: documentReference => ({
			type: duck.types.FETCH_BIN_INFORMATION_VALUES,
			documentReference,
		}),
		fetchBinInfoSuccess: response => ({
			type: duck.types.FETCH_BIN_INFORMATION_VALUES_SUCCESS,
			response,
		}),
		markedBin: bin => ({
			type: duck.types.MARKED_BIN_AS_COMPLETE,
			bin,
		}),
		fetchDocuments: (docType = '', query = '') => ({
			type: duck.types.FETCH_DOCUMENT_LISTING,
			docType,
			query,
		}),
		fetchDocumentSuccess: (docType, activeDocuments) => ({
			type: duck.types.FETCH_DOCUMENT_LISTING_SUCCESS,
			docType,
			activeDocuments,
		}),
		fetchInsights: () => ({
			type: duck.types.FETCH_INSIGHTS,
		}),
		fetchInsightsSuccess: userInsight => ({
			type: duck.types.FETCH_INSIGHTS_SUCCESS,
			userInsight,
		}),
		initiateTicketCreation: () => ({
			type: duck.types.INITIATE_TICKET_CREATION,
		}),
		setActiveProductID: activeProductID => ({
			type: duck.types.SET_ACTIVE_PRODUCT_ID,
			activeProductID,
		}),
		toggleProductSelectionPopup: status => ({
			type: duck.types.TOGGLE_PRODUCT_SELECTION_POPUP,
			status,
		}),
		flushTicketState: () => ({
			type: duck.types.FLUSH_TICKET_STATE,
		}),
		flushBinState: () => ({
			type: duck.types.FLUSH_BIN_STATE,
		}),
		setActiveFilterEntries: (docType, filters) => ({
			type: duck.types.SET_ACTIVE_FILTER_ENTRIES,
			docType,
			filters,
		}),
		tripPreview: (tripID, documentType) => ({
			type: duck.types.FETCH_TRIP_INFORMATION,
			tripID,
			documentType,
		}),
		setRecordsPaginationQueries: pagination => ({
			type: duck.types.SET_RECORDS_PAGINATION_QUERIES,
			pagination,
		}),
		flushRecordsState: name => ({
			type: duck.types.FLUSH_RECORDS_STATE,
			name,
		}),
	}),
})
