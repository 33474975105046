import { DealerDuc } from 'core-app/modules/Dealer/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { MainRouteDuc } from 'core-app/routes/duc'
import { getIn } from 'timm'
import saga from 'core-app/modules/Dealer/DealerSaga'

export default async (dispatch, getState, { action, extra }) => {
	const { type, customMeta = {} } = action
	const currentLocationFromAction = getIn(action, [
		'meta',
		'location',
		'current',
	])
	/** if someone specifically wants to skip thunk fetch again, this check helps */
	const { skipRouteThunk } = customMeta
	if (skipRouteThunk) return

	// check if nothing changed in the route.

	const state = getState()

	const locationState = currentLocationFromAction || state.location

	const { payload } = locationState

	// Inject the saga here. If it wasn't already
	extra.getSagaInjectors().injectSaga('DEALER', { saga })
	dispatch(AuthDuc.creators.fetchActor())
	const isMainDashboard = MainRouteDuc.types.DEALER === type
	const isSubDashboard = MainRouteDuc.types.DEALER$SUBROOT === type
	const isDocumentCreation = type === MainRouteDuc.types.DEALER$WACTION
	const isDocumentUpdation = type === MainRouteDuc.types.DEALER$WDOCREFERENCE
	const isDocumentTypeControl = isDocumentCreation || isDocumentUpdation

	let activeModule = ''
	const isSkip = true

	if (isMainDashboard) {
		// load the dashboard of the core module
		activeModule = 'dashboard'
		dispatch(DealerDuc.creators.fetchInsights())
	} else if (isSubDashboard) {
		activeModule = 'subDashboard'
		dispatch(AuthDuc.creators.fetchPartnerOrgs(false, false))
		dispatch(DealerDuc.creators.fetchWorkListing(isSkip, locationState))
	} else if (isDocumentTypeControl) {
		activeModule = 'workCreation'
		if (payload.action === 'create' || payload.action === 'edit') {
			dispatch(AuthDuc.creators.fetchPartnerOrgs())
		}
		if (payload.action === 'edit') {
			dispatch(
				DealerDuc.creators.initiateWorkEdit(payload.documentReference)
			)
		}
		if (payload.action === 'view') {
			dispatch(
				DealerDuc.creators.viewWorkDetails(payload.documentReference)
			)
		}
	}

	// load the dashboard of the core module
	dispatch(DealerDuc.creators.setActiveModule(activeModule))

	// based on the payload or action, initiate saga's
}
