import React from 'react'
import { Group } from '@vx/group'
import { Bar } from '@vx/shape'
import { scaleLinear } from '@vx/scale'
import theme from '../../utils/base-theme'

// accessors return the label and value of that data item
const x = d => d.label
const y = d => d.value || 0

const BarChart = ({ data, width, height, UOM }) => {
	// bounds

	const yMax = height

	const yScale = scaleLinear({
		rangeRound: [0, yMax],
		domain: [Math.max(...data.map(y)), 0],
	})

	const chartColors = ['#2AA3DC', '#82CBAF', '#CECECE']

	return (
		<>
			<ul
				style={{
					listStyle: 'none',
					margin: '0px 12px 0px 0px',
					padding: 0,
					display: 'inline-block',
					verticalAlign: 'middle',
					height: `${height}px`,
				}}
			>
				{data.map((d, index) => {
					return (
						<li
							// eslint-disable-next-line react/no-array-index-key
							key={index}
							style={{
								lineHeight: '20px',
								height: '20px',
								marginBottom: '12px',
								width: '80px',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								fontSize: theme.fontSize.s,
							}}
							title={d.label}
						>
							{d.label}
						</li>
					)
				})}
			</ul>
			<div
				style={{
					display: 'inline-block',
					verticalAlign: 'middle',
				}}
			>
				<svg
					width={width}
					height={height}
					style={{ transform: 'rotate(90deg)' }}
				>
					<Group top={0} left={0}>
						{data.map((d, i) => {
							const label = x(d)
							const barWidth = 20
							const barHeight = yMax - yScale(y(d))
							const barX = i * 32
							const barY = yMax - barHeight

							return (
								<Bar
									key={`bar-${label}`}
									x={barX}
									y={barY}
									width={barWidth}
									height={barHeight}
									fill={chartColors[i] || '#ebebeb'}
								/>
							)
						})}
					</Group>
				</svg>
			</div>
			<ul
				style={{
					listStyle: 'none',
					margin: '0px 0px 0px 12px',
					padding: 0,
					display: 'inline-block',
					verticalAlign: 'middle',
					height: `${height}px`,
				}}
			>
				{data.map((d, index) => {
					return (
						<li
							// eslint-disable-next-line react/no-array-index-key
							key={index}
							style={{
								lineHeight: '20px',
								height: '20px',
								marginBottom: '12px',
								fontSize: theme.fontSize.s,
							}}
						>
							{d.value || 0} {UOM || ''}
						</li>
					)
				})}
			</ul>
		</>
	)
}
export { BarChart }
