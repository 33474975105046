import React, { useState, useRef, useLayoutEffect } from 'react'
import styled from 'styled-components'
import { merge, getIn } from 'timm'
import { getDotColorStatus, getTitle } from 'ui-lib/utils/helpers'
import { Label } from '../Typography'
import { Spacer } from '../../utils/Spacer'
import baseTheme from '../../utils/base-theme'
import { Box } from '../../utils/Box'

const statusColorMap = {
	bad: baseTheme.color.error,
	pending: baseTheme.color.warn,
	good: baseTheme.color.success,
}

const NodeContainer = styled(Box)(p => ({
	breakInside: 'avoid-page',
	paddingTop: p.paddingMeasure,
	alignItems: 'baseline',
}))
const SubLabelContainer = styled(Box)({
	fontSize: 12,
	marginBottom: '30px',
	pageBreakAfter: 'auto',
	breakInside: 'avoid-page',
})
const SubLabel = styled.div({
	display: 'flex',
	pageBreakAfter: 'avoid',
})
const SubLabelKey = styled.div({
	fontWeight: 500,
})
const SubLabelValue = styled.div({
	marginLeft: 8,
	fontWeight: 300,
	marginRight: 20,
	whiteSpace: 'normal',
})

const LabelBlock = styled(Label)(p => ({
	cursor: 'pointer',
	whiteSpace: 'nowrap',
	width: p.isPDF ? '500px' : '350px',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	padding: 6,
	marginLeft: 10,
	marginRight: 10,
	marginTop: 5,
	marginBottom: 5,
	color: p.theme.color.primary,
	...(p.active &&
		!p.isPDF && {
			borderRadius: 4,
			color: p.theme.color.white,
			backgroundColor: p.theme.color.primary,
		}),
	// ':hover': {
	// 	borderRadius: 4,
	// 	color: p.theme.color.white,
	// 	backgroundColor: p.theme.color.primary,
	// },
}))

const Dot = styled.div(p => ({
	width: 16,
	height: 16,
	borderRadius: '50%',
	fontSize: p.theme.fontSize.l,
	lineHeight: '16px',
	fontWeight: 'bold',
	zIndex: '2',
	backgroundColor: p.color || p.theme.color.grey8,
	textAlign: 'center',
	color: p.theme.color.white,
	...(p.hidden && { visibility: 'hidden' }),
}))

const Wrapper = styled.div(p => ({
	display: 'flex',
	alignItems: 'flex-start',
	flexDirection: 'column',
	position: 'relative',

	...(p.downLink && {
		':before': {
			content: '" "',
			width: '1px',
			height: `calc(100% - ${p.lastTrackHeight || 46}px)`,
			position: 'absolute',
			backgroundColor: p.downLinkColor,
			zIndex: '1',
			left: p.depth * 30 + 4,
			top: '22px',
		},
	}),
	...(p.leftLink && {
		':after': {
			content: '" "',
			top: 22,
			zIndex: '1',
			width: '30px',
			height: '1px',
			position: 'absolute',
			backgroundColor: p.color,
			left: (p.depth - 1) * 30 + 4,
		},
	}),
}))

const setIn = (obj, keys, value) => {
	const lastIdx = keys.length - 1
	let o = obj
	for (let i = 0; i < keys.length; i++) {
		const k = keys[i]
		if (o[k] === undefined) {
			o[k] = {}
		}
		if (lastIdx === i) {
			o[k] = value
			break
		}
		o = o[k]
	}

	return obj
}

// The following global variables are used to maintain state because TraceTree component is
// recursively called, and hence any state maintained within the component would be lost
// activeNode is used to store which all nodes are "expanded" or not
let activeNode = {}

// selectedNode is used to store the id of the node which is currently highlighted
// let selectedNode = ''

let lastSelectedNode = ''

const isBadNode = (nodePath, badNodePaths) => {
	for (const node of Object.keys(badNodePaths)) {
		if (node.includes(nodePath)) return true
	}

	return false
}

const hasBadChildNode = (nodePath, badNodePaths) => {
	for (const node of Object.keys(badNodePaths)) {
		if (node !== nodePath && node.includes(nodePath)) return true
	}

	return false
}

const TraceTree = ({
	track,
	depth = 0,
	getLastTrackHeight,
	onClick,
	expandDepth,
	noStatusCheck,
	certificatesSelected,
	supplyChainSelected,
	labelTexts,
	isPDF = false,
}) => {
	const [lastTrackHeight, setLastTrackHeight] = useState({})
	const [firstTime, setFirstTime] = useState(true)
	const [toggle, setToggle] = useState(false)

	const ref = useRef()
	// const [showToolTip, setShowTooltip] = useState(false)
	const lastTrack = useRef(null)

	useLayoutEffect(() => {
		if (lastTrack.current)
			getLastTrackHeight(lastTrack.current.getBoundingClientRect().height)
	}, [lastTrack.current]) // eslint-disable-line react-hooks/exhaustive-deps

	return track.nodes.map((obj, index) => {
		const {
			title,
			status,
			children,
			id,
			errorMsg,
			transaction,
			transforming,
			transformed,
			currentOrgDetail,
			massBalanceErrorMsg,
			certificationStatus,
			blockChainStatus,
			certType,
			supplyChainType,
			deforestationData,
			supplyBase,
			unKnownPercentage,
			virtualTraceData,
		} = obj

		const hasChildren = children && !!children.length
		let enabled = isPDF ? true : getIn(activeNode, [depth, index])
		if (depth <= expandDepth && enabled === undefined) {
			activeNode = setIn(activeNode, [depth, index], true)
			enabled = true
		}

		const dotColorStatus = getDotColorStatus({
			certType,
			supplyBase,
			supplyChainType,
			supplyChainSelected,
			certificatesSelected,
			currentOrgDetail,
		})

		const setClick = () => {
			onClick({
				id,
				depth,
				index,
				transforming,
				transformed,
				transaction,
				currentOrgDetail,
				title,
				status,
				errorMsg,
				massBalanceErrorMsg,
				certificationStatus,
				blockChainStatus,
				certType,
				supplyChainType,
				deforestationData,
				unKnownPercentage,
			})
			lastSelectedNode = obj.path
			setFirstTime(false)
		}

		if (index === 0 && depth === 0 && firstTime) {
			setClick()
		}

		const long = getIn(transaction, [0, 'location', 'long'])
		const lat = getIn(transaction, [0, 'location', 'lat'])

		return (
			<Wrapper
				key={id}
				depth={depth}
				downLink={hasChildren}
				leftLink={depth > 0}
				lastTrackHeight={lastTrackHeight[id]}
				{...(index === track.length - 1 ? { ref: lastTrack } : {})}
				color={
					isBadNode(obj.path, track.badNodePaths)
						? statusColorMap.bad
						: statusColorMap.good
				}
				downLinkColor={
					hasBadChildNode(obj.path, track.badNodePaths)
						? statusColorMap.bad
						: statusColorMap.good
				}
			>
				<NodeContainer
					row
					center
					isPDF={isPDF}
					paddingMeasure={depth > 2 ? depth : depth - 1}
				>
					<Spacer size={24 * depth} horizontal />
					<Dot
						color={
							noStatusCheck
								? statusColorMap.good
								: statusColorMap[dotColorStatus]
						}
						hidden={hasChildren && !status}
						onClick={() => {
							activeNode = setIn(
								activeNode,
								[depth, index],
								!enabled
							)
							setClick()
							setToggle(!toggle)
						}}
					>
						{hasChildren && (enabled ? '-' : '+')}
					</Dot>
					<LabelBlock
						onClick={() => {
							setClick()
						}}
						key={lastSelectedNode}
						active={obj.path === lastSelectedNode}
						// onMouseEnter={() => {
						// 	setShowTooltip(true)
						// }}
						// onMouseLeave={() => {
						// 	setShowTooltip(false)
						// }}
						ref={ref}
						isPDF={isPDF}
					>
						<Box>
							{getTitle(
								title,
								unKnownPercentage,
								virtualTraceData
							)}
						</Box>
						{isPDF && (
							<SubLabelContainer>
								<SubLabel>
									<SubLabelKey>{`${labelTexts.addressText} :`}</SubLabelKey>
									<SubLabelValue>
										{getIn(transaction, [
											0,
											'locationOfTrace',
										]) || ''}
									</SubLabelValue>
								</SubLabel>
								<SubLabel>
									<SubLabelKey>{`${labelTexts.locationText} :`}</SubLabelKey>
									<SubLabelValue>
										<a
											href={`https://www.google.com/maps/search/?api=1&query=${lat},${long}`}
											target="_blank"
											rel="noopener noreferrer"
										>
											{getIn(transaction, [
												0,
												'location',
												'long',
											])
												? `${getIn(transaction, [
														0,
														'location',
														'long',
												  ]).toFixed(
														3
												  )} , ${getIn(transaction, [
														0,
														'location',
														'lat',
												  ]).toFixed(3)}`
												: ''}
										</a>
									</SubLabelValue>
									<SubLabelKey>{`${labelTexts.certTypeText} :`}</SubLabelKey>
									<SubLabelValue>
										{getIn(transaction, [
											0,
											'certType',
											0,
											'ctype',
										]) || ''}
									</SubLabelValue>
								</SubLabel>
								<SubLabel>
									<SubLabelKey>{`${labelTexts.qtyText} :`}</SubLabelKey>
									<SubLabelValue>
										{getIn(transaction, [0, 'quantity']) ||
											''}
									</SubLabelValue>
								</SubLabel>
							</SubLabelContainer>
						)}
					</LabelBlock>
					{/* <Drop
						target={ref.current}
						style={{
							background: 'none',
							boxShadow: 'none',
							border: 'none',
						}}
					>
						<Box
							style={{
								background: '#333',
								width: '99%',
								padding: 16,
								maxWidth: 300,
								marginTop: 40,
							}}
						>
							<Label
								color="#fff"
								small
								key={title}
								style={{
									borderLeft: '3px solid #e07616',
									paddingLeft: 16,
									cursor: 'inherit',
								}}
							>
								{title}
							</Label>
						</Box>
					</Drop> */}
				</NodeContainer>
				{hasChildren && enabled && (
					<Box>
						<TraceTree
							onClick={onClick}
							track={{
								nodes: children,
								badNodePaths: track.badNodePaths,
							}}
							noStatusCheck={noStatusCheck}
							certificatesSelected={certificatesSelected}
							supplyChainSelected={supplyChainSelected}
							depth={depth + 1}
							expandDepth={expandDepth}
							getLastTrackHeight={_height => {
								setLastTrackHeight(
									merge(lastTrackHeight, {
										[id]: _height,
									})
								)
							}}
							isPDF={isPDF}
							labelTexts={labelTexts}
						/>
					</Box>
				)}
			</Wrapper>
		)
	})
}

TraceTree.defaultProps = {
	getLastTrackHeight: () => {},
	onClick: () => {},
}

export { TraceTree }
