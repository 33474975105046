import React from 'react'
import { Box } from 'ui-lib/utils/Box'
import { H3 } from 'ui-lib/components/Typography'
import { Spacer } from 'ui-lib/utils/Spacer'

const stepStyle = {
	color: '#F6F6F6',
	background: 'linear-gradient(45deg, #5a3a75 0%, #b374e9 60%)',
	backgroundColor: '#b374e9 ',
}
export const settingsPasswordSteps = t => {
	return [
		{
			selector: '#settingsPassword',
			content: () => (
				<Box margin="8px">{t('tourCommon.viewPasswdNote')}</Box>
			),

			style: stepStyle,
			position: 'right',
		},
	]
}

export const passwordUpdateSteps = t => {
	return [
		{
			selector: '#inputField-currentPassword',

			content: () => (
				<Box>
					<H3 style={{ color: '#F6F6F6', fontSize: '22px' }}>
						{`${t('tourCommon.stepNo')} 1`}
					</H3>
					<Spacer size={8} />
					<p>{t('tourAdmin.typeCurrentPsswd')}</p>
				</Box>
			),
			action: node => {
				node.focus()
			},
			style: stepStyle,
		},

		{
			selector: '#inputField-password',
			content: () => (
				<Box>
					<H3 style={{ color: '#F6F6F6', fontSize: '22px' }}>
						{`${t('tourCommon.stepNo')} 2`}
					</H3>
					<Spacer size={8} />
					<p>{t('tourAdmin.typeNewPsswd')}</p>
				</Box>
			),
			action: node => {
				node.focus()
			},
			style: stepStyle,
		},
		{
			selector: '#update-password',
			content: () => (
				<Box>
					<H3 style={{ color: '#F6F6F6', fontSize: '22px' }}>
						{`${t('tourCommon.stepNo')} 3`}
					</H3>
					<Spacer size={8} />
					<p>{t('tourAdmin.updatePsswd')}</p>
				</Box>
			),

			style: stepStyle,
		},
	]
}
export const settingsLangSteps = t => {
	return [
		{
			selector: '#settingsLanguage',
			content: () => (
				<Box margin="8px">{t('tourCommon.viewLangNote')}</Box>
			),

			style: stepStyle,
			position: 'right',
		},
	]
}
export const langUpdateSteps = t => {
	return [
		{
			selector: '#input-language',
			content: () => (
				<Box>
					<H3 style={{ color: '#F6F6F6', fontSize: '22px' }}>
						{`${t('tourCommon.stepNo')} 1`}
					</H3>
					<Spacer size={8} />
					<p>{t('tourAdmin.selectLang')}</p>
				</Box>
			),
			action: node => {
				node.focus()
			},
			style: stepStyle,
		},

		{
			selector: '#update-language',
			content: () => (
				<Box>
					<H3 style={{ color: '#F6F6F6', fontSize: '22px' }}>
						{`${t('tourCommon.stepNo')} 2`}
					</H3>
					<Spacer size={8} />
					<p>{t('tourAdmin.updateLang')}</p>
				</Box>
			),
			action: node => {
				node.focus()
			},
			style: stepStyle,
		},
	]
}

export const tdmImportSteps = t => {
	return [
		{
			selector: '#input-createWork',
			content: () => (
				<Box>
					<H3 style={{ color: '#F6F6F6', fontSize: '22px' }}>
						{`${t('tourCommon.stepNo')} 1`}
					</H3>
					<Spacer size={8} />
					<p>{t('tourCommon.importDONote')}</p>
				</Box>
			),
			action: node => {
				node.focus()
			},
			style: stepStyle,
		},
	]
}
export const dealerUploadWBTktSteps = t => {
	return [
		{
			selector: '#wbTicketUpload',
			content: () => (
				<Box margin="8px">{t('tourCommon.wbTicketsNote')}</Box>
			),
			style: stepStyle,
			position: 'right',
		},
	]
}
export const uploadWBTktSteps = t => {
	return [
		{
			selector: '#input-WBTktSM',
			content: () => (
				<Box>
					<H3 style={{ color: '#F6F6F6', fontSize: '22px' }}>
						{`${t('tourCommon.stepNo')} 1`}
					</H3>
					<Spacer size={8} />
					<p>{t('tourDealer.selectSmalholder')}</p>
				</Box>
			),

			action: node => {
				node.focus()
			},
			style: stepStyle,
		},

		{
			selector: '#input-WBTktFile ',
			content: () => (
				<Box>
					<H3 style={{ color: '#F6F6F6', fontSize: '22px' }}>
						{`${t('tourCommon.stepNo')}  2`}
					</H3>
					<Spacer size={8} />
					<p>{t('tourDealer.uploadFile')}</p>
				</Box>
			),

			action: node => {
				node.focus()
			},
			style: stepStyle,
		},

		{
			selector: '#input-saveTkt',
			content: () => (
				<Box>
					<H3 style={{ color: '#F6F6F6', fontSize: '22px' }}>
						{`${t('tourCommon.stepNo')}  3`}
					</H3>
					<Spacer size={8} />
					<p>{t('tourDealer.saveTkt')}</p>
				</Box>
			),
			style: stepStyle,
		},
	]
}
