import React, { useMemo, useState, useCallback } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { AppDuc } from 'core-app/modules/App/duc'
import { Box } from 'ui-lib/utils/Box'
import { Button, ButtonIconWrapper } from 'ui-lib/components/Button'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import { Icon } from 'ui-lib/icons/components/Icon'
import * as yup from 'yup'
import { Modal } from 'ui-lib/components/Modal'
import { CollapsableSegment } from 'ui-lib/components/CollapsableSegments'
import { BoxShadowTable } from 'core-app/shared/components/BoxShadowTable'
import { addLast, merge, getIn } from 'timm'
import { applyMediaQueries } from 'ui-lib/utils/helpers'
import { BoxShadowWrapper } from 'ui-lib/components/Card'
import { getDateByFormat } from 'core-app/utils/date'
import { Spacer } from 'ui-lib/utils/Spacer'
import { P, Label } from 'ui-lib/components/Typography'
import { useFormik } from 'formik'
import { RUBBER_INPUT_PRODUCT_RUBBER } from 'core-app/utils/constants'
import theme from 'ui-lib/utils/base-theme'
import { isEmptyObject } from 'core-app/utils/helpers'
import { AssignInventoryToTank } from 'core-app/modules/Refinery/components/AssignInventoryToTank'
import { MillDuc, NAME_ALIASES } from '../../duc'
import { getPlotOutputColumnConfig } from '../../components/Columns'
import { WebTourDuc } from '../../../WebTour/duc'

const BlockWrapper = styled(Box)(p => ({
	cursor: p.plain ? 'initial' : 'pointer',
	border: '1.5px solid transparent',
	borderRadius: 4,
	'&:hover': p.plain
		? {}
		: {
				border: `1.5px solid ${p.theme.color.accent}`,
		  },
}))

const CTAWrapper = styled(Box)(p => ({
	width: 'fit-content',
	padding: 12,
	background: p.theme.color.white,
	color: p.theme.color.primary,
}))

const SummaryWrapper = styled(Box)({
	paddingRight: 40,
	'&:last-child': {
		paddingRight: 'initial',
	},
	...applyMediaQueries(['mobile'], {
		paddingTop: 4,
		paddingBottom: 4,
	}),
})

const SummaryBlock = ({ name, detail, onClick, isLink }) => (
	<SummaryWrapper>
		<Label color="#7680A7" small>
			{name}
		</Label>
		<Box height={35} center>
			{typeof detail === 'string' ? (
				<P small bold link={isLink} onClick={onClick}>
					{detail}
				</P>
			) : (
				detail
			)}
		</Box>
	</SummaryWrapper>
)

const validationSchema = () =>
	yup.object().shape({
		traces: yup
			.array()
			.of(
				yup.object().shape({
					productID: yup
						.string()
						.strict()
						.required(),
				})
			)
			.strict()
			.required(),
	})

const CreatePlotOutput = () => {
	const dispatch = useDispatch()
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)
	if (tourModule !== '') {
		dispatch(WebTourDuc.creators.setActiveTourModule(''))
	}
	const { t } = useTranslation()
	const locationState = useSelector(MillDuc.selectors.location)
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const { payload = {}, query = {} } = locationState
	const [trigger, setTrigger] = useState(false)
	const storageList = useSelector(AuthDuc.selectors.getStorageUnits)
	const currentOrgdetails = useSelector(
		AuthDuc.selectors.getCurrentOrgDetails
	)

	const activeRecords = useSelector(MillDuc.selectors.getActiveRecords)
	const allProducts = useSelector(AuthDuc.selectors.getProducts)
	const productName = allProducts.filter(
		product => product.id === activeRecords.productName
	)
	const curentProductName = getIn(productName, [0, 'name']) || ''
	const uom = getIn(productName, [0, 'uom']) || ''

	const activeProducts = allProducts
		.filter(product => {
			return getIn(currentOrgdetails, ['name'])
				?.toLowerCase()
				?.includes('rubber')
				? product.code.includes(RUBBER_INPUT_PRODUCT_RUBBER)
				: product.id !== activeRecords.productName
		})
		.map(({ code: _code, name, id, uom: _uom }) => {
			return { id, label: `${name} (#${_code}) - ${_uom}` }
		})
	const { rootModule } = payload
	const { scModel, cert } = query
	const [activeDocuments, setActiveDocuments] = useState([
		{
			activeIndex: 0,
			date: new Date().toISOString(),
			productID: '',
			quantity: '',
			storageUnits: [],
		},
	])
	const [storageTanks, setStorageTanks] = useState([])
	const [productDetails, setProductDetails] = useState({})

	const [lastUpdatedIndex, setLastUpdatedIndex] = useState(0)
	const [currentIndex, setCurrentIndex] = useState(0)

	const [showModal, setShowModal] = useState(false)

	const getBreadCrumbsList = useCallback(
		() => [
			{
				label: t('breadcrumb.home'),
				name: 'home',
				isActive: true,
			},
			{
				label: t('breadcrumb.production'),
				name: 'production',
				isActive: true,
			},
			{
				label: t('production.createOutputBatch'),
				name: 'production-plot-output',
				isActive: false,
			},
		],
		[t]
	)

	const assignTank = (currentRow, productID) => {
		setCurrentIndex(currentRow)
		const tankDetails =
			getIn(activeDocuments, [currentRow, 'storageUnits']) || []
		setStorageTanks(tankDetails)
		setProductDetails({
			productID,
			product: curentProductName,
			quantity: `${activeRecords.quantity}${uom}`,
		})
		setShowModal(!showModal)
	}

	const handleClose = () => {
		setShowModal(false)
	}

	const storageTanksAssigned = activeDocuments.filter(
		doc => doc.storageUnits.length > 0
	)

	const isEveryProductAssignedWithStorageTank =
		activeDocuments.length === storageTanksAssigned.length

	/** Handlers */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'production') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.MILL$SUBROOT,
					{
						rootModule: 'production',
					}
				)
			)
		}
	}

	const { submitForm, setFieldValue, isSubmitting, errors } = useFormik({
		initialValues: {
			traceGroupID: getIn(activeRecords, ['traceGroupID']) || '',
			traces: [],
		},
		validationSchema: validationSchema(),
		enableReinitialize: true,
		onSubmit: (_values, { setSubmitting }) => {
			dispatch(
				MillDuc.creators.createPlotOutput(
					_values,
					t('common.successCreate'),
					{
						setSubmitting,
					}
				)
			)
		},
	})

	const disableCTA =
		!isEmptyObject(errors) ||
		!trigger ||
		!isEveryProductAssignedWithStorageTank

	const handleChange = useCallback(
		(action, meta) => {
			switch (action) {
				case 'add_new_row': {
					const initialActiveBatch = {
						activeIndex: activeDocuments.length || 0,
						date: new Date().toISOString(),
						productID: '',
						quantity: '',
						storageUnits: [],
					}
					const addRow = addLast(activeDocuments, initialActiveBatch)
					setFieldValue('traceGroupID', activeRecords.traceGroupID)
					setActiveDocuments(addRow)
					setTrigger(false)
					break
				}
				case 'modify_product': {
					const { row = {} } = meta
					const document = activeDocuments.filter(
						currentDoc => currentDoc.activeIndex !== row.activeIndex
					)
					setActiveDocuments(document)
					setFieldValue('traces', document)

					// eslint-disable-next-line no-unused-expressions
					activeDocuments.length > 1
						? setTrigger(true)
						: setTrigger(false)

					break
				}
				case 'add_Values': {
					const { rowIndex, value, type } = meta
					if (type === 'date') {
						activeDocuments[rowIndex] = merge(
							activeDocuments[rowIndex],
							{
								date: value,
							}
						)
					}
					if (type === 'productID') {
						activeDocuments[rowIndex] = merge(
							activeDocuments[rowIndex],
							{
								storageUnits: [],
								productID: value,
							}
						)
					}
					if (type === 'quantity') {
						activeDocuments[rowIndex] = merge(
							activeDocuments[rowIndex],
							{
								quantity: parseFloat(value, 4),
							}
						)
					}

					if (type === 'storageUnits') {
						activeDocuments[rowIndex] = merge(
							activeDocuments[rowIndex],
							{
								storageUnits: value,
							}
						)
						setTrigger(true)
					}

					setFieldValue('traces', activeDocuments)
					setLastUpdatedIndex(rowIndex)

					break
				}

				default:
					break
			}
		},
		[
			setActiveDocuments,
			activeDocuments,
			setFieldValue,
			setLastUpdatedIndex,
			activeRecords.traceGroupID,
		]
	)
	const title = NAME_ALIASES[rootModule]
	const breadCrumbs = useMemo(() => getBreadCrumbsList({ title }), [
		title,
		getBreadCrumbsList,
	])

	return (
		<Box padding={8} width="100%" height="100%">
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Spacer size={24} />

			<Box overflow>
				<BoxShadowWrapper>
					<Box
						padding="4px 15px"
						style={{
							borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
						}}
					>
						<P>{t('production.productionDetails')}</P>
					</Box>
					<BlockWrapper
						padding={16}
						overflow="auto"
						width="100%"
						row
						justifyContent={isMobile ? 'flex-end' : 'space-between'}
						wrap
						onClick={e => {
							e.stopPropagation()
						}}
					>
						<Box
							row={!isMobile}
							alignItems="flex-start"
							width={isMobile ? '100%' : ''}
						>
							<Box row wrap>
								<SummaryBlock
									name={t('production.inputMaterial')}
									detail={curentProductName || '---'}
								/>
								<SummaryBlock
									name={t(
										'production.quantityUnderProduction'
									)}
									detail={
										`${activeRecords.quantity}${uom}` ||
										'---'
									}
								/>
								<SummaryBlock
									name={t('production.generatedDate')}
									detail={
										getDateByFormat(
											activeRecords.producedTracesCreatedAt
										) || '---'
									}
								/>
								<SummaryBlock
									name={t('production.inputBatchReference')}
									detail={
										activeRecords.batchReference || '---'
									}
								/>
							</Box>
						</Box>
					</BlockWrapper>
				</BoxShadowWrapper>
			</Box>

			<Spacer size={24} />
			<CollapsableSegment
				expand
				title={t('production.enterProductionDetails')}
				hasError={disableCTA}
				open
				message={
					disableCTA
						? t('validation.collapsableSegmentWarning')
						: t('validation.collapsableSegmentSuccess')
				}
				onToggle={() => {}}
			>
				<Box>
					<Spacer size={14} />
					<div
						style={{
							display: 'flex',
							justifyContent: 'end',
							color: theme.color.themeGreen2,
						}}
					>
						<div>
							{`* ${t('production.productionMappingText')}`}
						</div>
					</div>
					<Spacer size={10} />
					<BoxShadowTable
						size="large"
						key={`${activeDocuments[lastUpdatedIndex] &&
							activeDocuments[lastUpdatedIndex].productID}`}
						columnConfig={getPlotOutputColumnConfig({
							type: 'plot',
							docType: 'plot',
							handleChange,
							assignTank,
							activeProducts,
							// activeRecords,
							t,
							activeDocuments,
						})}
						rowData={activeDocuments}
					/>
					<Modal
						noPadding
						show={showModal}
						size="large"
						body={
							<AssignInventoryToTank
								activeTanks={storageTanks}
								storageList={storageList}
								onClose={handleClose}
								productDetails={productDetails}
								inventorySCModel={scModel}
								certificationType={cert}
								onChange={values => {
									handleChange('add_Values', {
										rowIndex: currentIndex,
										value: values,
										type: 'storageUnits',
									})
									setShowModal(false)
								}}
							/>
						}
						hideButtons
						onClose={handleClose}
					/>
					<Spacer size={8} />
					<CTAWrapper onClick={() => handleChange('add_new_row', {})}>
						<Label color="inherit" bold small link as="a">
							{t('production.addMore')}
						</Label>
					</CTAWrapper>
				</Box>
			</CollapsableSegment>

			<Spacer size={24} />
			<Box row justifyContent="space-between">
				<Box style={{ padding: 4 }} width="200px">
					<Button
						label={t('common.back')}
						rounded
						customIcon={
							<ButtonIconWrapper lightBG>
								<Icon glyph={LeftArrowIcon} />
							</ButtonIconWrapper>
						}
						onClick={() =>
							dispatch(
								MainRouteDuc.creators.switchPage(
									MainRouteDuc.types.MILL$SUBROOT,
									{
										rootModule: 'production',
									}
								)
							)
						}
					/>
				</Box>
				<Box style={{ padding: 2 }} width="200px">
					<Button
						disabled={disableCTA}
						isLoading={isSubmitting}
						label={t('common.submit')}
						primary
						rounded
						onClick={() => {
							submitForm()
							dispatch(
								WebTourDuc.creators.setActiveTourModule('')
							)
						}}
					/>
				</Box>
			</Box>
		</Box>
	)
}

export { CreatePlotOutput }
