import { SettingsDuc } from 'core-app/modules/Settings/duc'
import { MainRouteDuc } from 'core-app/routes/duc'
import { getIn } from 'timm'
import { CookieDuc } from 'core-app/modules/App/cookieDuc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import saga from 'core-app/modules/Settings/SettingsSaga'

export default async (dispatch, getState, { action, extra }) => {
	const { type, customMeta = {} } = action
	const currentLocationFromAction = getIn(action, [
		'meta',
		'location',
		'current',
	])
	/** if someone specifically wants to skip thunk fetch again, this check helps */
	const { skipRouteThunk } = customMeta
	if (skipRouteThunk) return

	// check if nothing changed in the route.

	const state = getState()

	const locationState = currentLocationFromAction || state.location

	const { payload } = locationState

	// Inject the saga here. If it wasn't already
	extra.getSagaInjectors().injectSaga('SETTINGS', { saga })
	dispatch(AuthDuc.creators.fetchActor())
	const isMainDashboard = MainRouteDuc.types.SETTINGS === type
	const isSubDashboard = MainRouteDuc.types.SETTINGS$SUBROOT === type

	let activeModule = ''

	if (isMainDashboard) {
		// load the dashboard of the core module
		activeModule = 'dashboard'
	} else if (isSubDashboard) {
		activeModule = payload.rootModule
		if (payload.rootModule === 'change-language') {
			dispatch(
				CookieDuc.creators.getCookie({
					cookieName: 'I18N_LANGUAGE',
				})
			)
		}
		if (payload.rootModule === 'notifications') {
			dispatch(SettingsDuc.creators.fetchNotifications(locationState))
		}
		if (payload.rootModule === 'edit-profile') {
			dispatch(SettingsDuc.creators.setInitialValues())
		}
	}

	// load the dashboard of the core module
	dispatch(SettingsDuc.creators.setActiveModule(activeModule))

	// based on the payload or action, initiate saga's
}
