export const SortableKeys = ['date']

export const TABLE_ALIAS = {
	date: 'tripsListing.recievedDate',
	tripsNumber: 'tripsListing.tripsNumber',
	driverName: 'tripsListing.driverName',
	vehicleNumber: 'tripsListing.vehicleNumber',
	quantity: 'tripsListing.quantity',
	partnerName: 'tripsListing.partnerName',
	doNumber: 'tripsListing.doNumber',
	action: 'tripsListing.action',
}
