import React from 'react'
import { getIn } from 'timm'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { FileUpload } from 'ui-lib/components/FileUpload'
import { Label } from 'ui-lib/components/Label'
import theme from 'ui-lib/utils/base-theme'
import { Select } from 'ui-lib/components/Select'
import { useDispatch, useSelector } from 'react-redux'
import { AppDuc } from 'core-app/modules/App/duc'
import { Input } from 'ui-lib/components/Input'
import { DatePicker } from 'ui-lib/components/Datepicker'
import { useTranslation } from 'react-i18next'
import { WebTourDuc } from 'core-app/modules/WebTour/duc'
import { isEmptyObject } from 'core-app/utils/helpers'
import { Button, ButtonWithNoBorder } from 'ui-lib/components/Button'
import { AdminDuc } from 'core-app/modules/Admin/duc'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { AuthDuc } from 'core-app/modules/Auth/duc'

const EditDocument = ({ docDetails, handleShowModal, edit = false }) => {
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)
	const { fullName } = useSelector(AuthDuc.selectors.getUserProfile)
	const dispatch = useDispatch()
	if (tourModule !== '') {
		dispatch(WebTourDuc.creators.setActiveTourModule(''))
	}

	const { t } = useTranslation()

	const initialValues = {
		id: docDetails && docDetails.id ? docDetails.id : '',
		type: docDetails && docDetails.type ? docDetails.type : '',
		issuingBody: getIn(docDetails, [
			'meta',
			'certificate',
			'otherIssuingBody',
		])
			? 'others'
			: getIn(docDetails, ['meta', 'certificate', 'issuingBody']) || '',
		otherIssuingBody: getIn(docDetails, [
			'meta',
			'certificate',
			'otherIssuingBody',
		])
			? getIn(docDetails, ['meta', 'certificate', 'issuingBody'])
			: '',
		number: docDetails && docDetails.number ? docDetails.number : '',
		issueDate:
			docDetails && docDetails.issueDate ? docDetails.issueDate : '',
		files: docDetails && docDetails.files ? docDetails.files : [],
	}

	const validationSchema = yup.object().shape({
		type: yup.string().required(t('common.fieldRequired')),
		issuingBody: yup.string().required(t('common.fieldRequired')),
		number: yup.string().required(t('common.fieldRequired')),
		issueDate: yup.string().required(t('common.fieldRequired')),
		otherIssuingBody: yup.string().when('issuingBody', {
			is: 'others',
			then: yup.string().required(t('common.fieldRequired')),
		}),
	})

	const {
		values,
		setFieldValue,
		handleSubmit,
		handleBlur,
		isSubmitting,
		touched,
		errors,
	} = useFormik({
		initialValues,
		enableReinitialize: true,
		validationSchema,
		onSubmit: _values => {
			let { issuingBody } = _values
			if (_values.issuingBody === 'others') {
				issuingBody = _values.otherIssuingBody
			}
			const payload = {
				type: _values.type,
				number: _values.number,
				files: _values.files,
				meta: {
					updatedBy: fullName,
					certificate: {
						issuingBody,
						otherIssuingBody: _values.issuingBody === 'others',
					},
				},
			}

			if (_values.issueDate) {
				payload.issueDate = new Date(_values.issueDate)
			}

			if (edit) {
				dispatch(
					AdminDuc.creators.updateSingleCertificate(
						payload,
						values.id,
						t('common.docUpdateSuccess')
					)
				)
			} else {
				dispatch(
					AdminDuc.creators.addNewCertificate(
						payload,
						t('common.docCreateSuccess')
					)
				)
			}
		},
	})

	const disableCTA =
		!isEmptyObject(errors) || !(values.files && values.files.length)

	const getIssuingBody = () => {
		let issuingBody = []
		switch (values.type) {
			case 'land-legality':
				issuingBody = [
					{
						label: 'HGU (Hak Guna Usaha)',
						name: 'HGU',
					},
					{
						label: 'Others',
						name: 'others',
					},
				]
				break
			case 'agriculture-permit':
				issuingBody = [
					{
						label: 'IUP (Izin usaha Perkebunan Terintergrasi)',
						name: 'IUP',
					},
					{
						label: 'IUP – B (Izin Usaha Perkebunan Budidaya)',
						name: 'IUPB',
					},
				]
				break
			case 'environment-document':
				issuingBody = [
					{
						label: 'ANDAL (Analisis Dampak Lingkungan Hidup)',
						name: 'ANDAL',
					},
					{
						label:
							'UKL-UPL (Upaya Pengelolaan Lingkungan dan Upaya Pemantauan Lingkungan Hidup)',
						name: 'UKLUPL',
					},
				]
				break
			case 'environment-permit':
				issuingBody = [
					{
						label: 'Izin Lingkungan',
						name: 'IL',
					},
				]
				break
			default:
				break
		}

		return issuingBody
	}

	return (
		<Box
			style={{
				height: '310px',
				overflow: 'scroll',
			}}
		>
			<form onSubmit={handleSubmit}>
				<Box style={{ paddingRight: '6px' }}>
					<Box
						row
						justifyContent="space-between"
						style={{ width: '100%' }}
					>
						<Box style={{ width: '32%' }}>
							<Label
								required
								style={{
									fontWeight: '300px',
									fontSize: '16px',
									color: theme.color.accent2,
									width: '100%',
								}}
							>
								{t('viewCompanyInfo.selectDocType')}
							</Label>
							<Spacer size={8} />

							<Select
								placeholder={t('viewCompanyInfo.selectDocType')}
								name="type"
								options={[
									{
										label: t(
											'viewCompanyInfo.land-legality'
										),
										name: 'land-legality',
									},
									{
										label: t(
											'viewCompanyInfo.agriculture-permit'
										),
										name: 'agriculture-permit',
									},
									{
										label: t(
											'viewCompanyInfo.environment-document'
										),
										name: 'environment-document',
									},
									{
										label: t(
											'viewCompanyInfo.environment-permit'
										),
										name: 'environment-permit',
									},
								]}
								onBlur={handleBlur}
								error={touched.type && errors.type}
								value={values.type}
								onChange={({ name }) => {
									setFieldValue('type', name)
									setFieldValue('issuingBody', '')
								}}
								returnOnlyValue
								labelKey="label"
								valueKey="name"
							/>
						</Box>
					</Box>
					<Box row style={{ width: '100%' }}>
						<Box style={{ width: '32%' }}>
							<Label
								required
								style={{
									fontWeight: '300px',
									fontSize: '16px',
									color: theme.color.accent2,
									width: '100%',
								}}
							>
								{t('viewCompanyInfo.selectIssuingBody')}
							</Label>
							<Spacer size={8} />

							<Select
								placeholder={t(
									'viewCompanyInfo.selectIssuingBody'
								)}
								name="issuingBody"
								options={getIssuingBody()}
								onBlur={handleBlur}
								error={
									touched.issuingBody && errors.issuingBody
								}
								value={values.issuingBody}
								onChange={({ name }) => {
									setFieldValue('issuingBody', name)
								}}
								returnOnlyValue
								labelKey="label"
								valueKey="name"
							/>
						</Box>
						{values.issuingBody === 'others' && (
							<Box style={{ width: '32%', marginLeft: 15 }}>
								<Label
									required
									style={{
										fontWeight: '300px',
										fontSize: '16px',
										color: theme.color.accent2,
										width: '100%',
									}}
								>
									{t(
										'viewCompanyInfo.selectIssuingBodyOthers'
									)}
								</Label>
								<Spacer size={8} />
								<Box style={{ width: '100%' }}>
									<Input
										value={values.otherIssuingBody}
										name="otherIssuingBody"
										type="text"
										onChange={e => {
											setFieldValue(
												'otherIssuingBody',
												e.target.value
											)
										}}
										extendStyles={{
											background:
												values?.otherIssuingBody
													?.length === 0
													? `${theme.color.grey11} 0% 0% no-repeat padding-box`
													: `${theme.color.white} 0% 0% no-repeat padding-box`,
											boxShadow:
												values?.otherIssuingBody
													?.length === 0
													? '0px 6px 16px #05050707'
													: `0px 2px 8px ${theme.color.blue6}`,
											border:
												values?.otherIssuingBody
													?.length === 0
													? `1px solid ${theme.color.grey4}`
													: `1px solid ${theme.color.blue5}`,
											borderRadius: '4px',
											opacity: 1,
										}}
									/>
								</Box>
							</Box>
						)}
					</Box>
					<Box row overflow style={{ width: '100%' }}>
						<Box style={{ width: '32%' }}>
							<Label
								required
								style={{
									fontWeight: '300px',
									fontSize: '16px',
									color: theme.color.accent2,
									width: '100%',
								}}
							>
								{t('viewCompanyInfo.docNumber')}
							</Label>
							<Spacer size={8} />
							<Box style={{ width: '100%' }}>
								<Input
									value={values.number}
									name="number"
									type="text"
									onChange={e => {
										setFieldValue('number', e.target.value)
									}}
									onBlur={handleBlur}
									error={touched.number && errors.number}
									extendStyles={{
										background:
											values &&
											values.number &&
											values.number.length === 0
												? `${theme.color.grey11} 0% 0% no-repeat padding-box`
												: `${theme.color.white} 0% 0% no-repeat padding-box`,
										boxShadow:
											values &&
											values.number &&
											values.number.length === 0
												? '0px 6px 16px #05050707'
												: `0px 2px 8px ${theme.color.blue6}`,
										border:
											values &&
											values.number &&
											values.number.length === 0
												? `1px solid ${theme.color.grey4}`
												: `1px solid ${theme.color.blue5}`,
										borderRadius: '4px',
										opacity: 1,
									}}
								/>
							</Box>
						</Box>
						<Box
							style={{ width: 200, marginLeft: 15 }}
							width="200px"
						>
							<Label
								required
								style={{
									fontWeight: '300px',
									fontSize: '16px',
									color: theme.color.accent2,
								}}
							>
								{t('viewCompanyInfo.issueDate')}
							</Label>
							<Spacer size={8} />
							<DatePicker
								required
								name="issueDate"
								maxDate={new Date()}
								returnKeyValue
								value={values.issueDate}
								error={touched.issueDate && errors.issueDate}
								onChange={keyValue =>
									setFieldValue(
										'issueDate',
										keyValue.issueDate
									)
								}
							/>
						</Box>
					</Box>

					<FileUpload
						required
						type="public"
						name="file"
						size="5242880"
						modalTriggerBtnLabel={t('common.upload')}
						label={t('viewCompanyInfo.docCopy')}
						supportedFileType=".jpg,.png,.pdf"
						outline
						currentUploads={values.files}
						onDocumentChange={document =>
							setFieldValue('files', document)
						}
						onUploadFailure={({ message }) => {
							dispatch(
								AppDuc.creators.showToast({
									messageType: 'error',
									message,
								})
							)
						}}
					/>
					<Box
						style={{
							bottom: 0,
							right: '62px',
							position: 'absolute',
						}}
						row
						margin="20px 0"
					>
						<ButtonWithNoBorder
							label="Close"
							onClick={() => {
								handleShowModal(false)
							}}
							extendStyles={{
								marginRight: 10,
								color: theme.color.grey6,
							}}
						/>
						<Button
							primary
							type="submit"
							disabled={disableCTA}
							isLoading={isSubmitting}
							label={edit ? 'Update' : 'Add'}
							extendStyles={{
								width: 100,
							}}
						/>
					</Box>
				</Box>
			</form>
		</Box>
	)
}

export default EditDocument
