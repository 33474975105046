import React from 'react'
import { useSelector } from 'react-redux'
import { NotFoundBlock } from 'core-app/modules/App/components/Error'
import { RefineryDuc } from '../duc'
import { Production } from './subModules/Production'
import { ProductionDaily } from './subModules/ProductionDaily'

const modulesMap = {
	'production-v1': Production,
	production: ProductionDaily,
}

const SubDashboard = () => {
	const location = useSelector(RefineryDuc.selectors.location)
	const { payload = {} } = location
	const activeModule = payload.rootModule

	const Component = modulesMap[activeModule] || NotFoundBlock
	// if we are here, we have a submodule, route it to the module

	return <Component />
}

export { SubDashboard }
