import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
`

const handleSizeUnit = (size, unit) =>
	typeof size === 'number' ? `${size || 20}${unit}` : 'size'

const InfiniteSpinner = styled.div`
	animation: ${rotate} 1s linear infinite;
	background: ${p => p.theme.color.accent};
	border-radius: 50%;
	width: ${({ size, unit }) => handleSizeUnit(size, unit)};
	height: ${({ size, unit }) => handleSizeUnit(size, unit)};
	position: relative;

	&::before {
		border-radius: 50%;
		background: linear-gradient(
					0deg,
					hsla(0, 0%, 100%, 1) 50%,
					hsla(0, 0%, 100%, 0.9) 100%
				)
				0% 0%,
			linear-gradient(
					90deg,
					hsla(0, 0%, 100%, 0.9) 0%,
					hsla(0, 0%, 100%, 0.6) 100%
				)
				100% 0%,
			linear-gradient(
					180deg,
					hsla(0, 0%, 100%, 0.6) 0%,
					hsla(0, 0%, 100%, 0.3) 100%
				)
				100% 100%,
			linear-gradient(
					360deg,
					hsla(0, 0%, 100%, 0.3) 0%,
					hsla(0, 0%, 100%, 0) 100%
				)
				0% 100%;
		background-repeat: no-repeat;
		background-size: 50% 50%;
		top: -1px;
		bottom: -1px;
		left: -1px;
		right: -1px;
		content: '';
		position: absolute;
	}

	&::after {
		border-radius: 50%;
		background: ${p => p.theme.color.grey2};
		top: ${({ thickness = '10%' }) => thickness};
		bottom: ${({ thickness = '10%' }) => thickness};
		left: ${({ thickness = '10%' }) => thickness};
		right: ${({ thickness = '10%' }) => thickness};
		content: '';
		position: absolute;
	}
`

InfiniteSpinner.defaultProps = {
	unit: 'px',
}

export { InfiniteSpinner }
