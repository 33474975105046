import { all, takeLatest, put, select, call } from 'redux-saga/effects'
import LogHelper from 'core-app/utils/logger'
import { AppDuc } from 'core-app/modules/App/duc'
import { MainRouteDuc } from 'core-app/routes/duc'
import {
	isEmptyObject,
	transformDataForBarChart,
	transformDataforPieChart,
} from 'core-app/utils/helpers'
import querySerializer from 'query-string'
import {
	CallWithRefreshCheck,
	getOrgIDFromLoggedUser,
	fetchOrgDetailsCount,
	fetchOrgAssets,
	fetchCurrentOrgsDetail,
} from 'core-app/modules/Auth/AuthSaga'
import { getCoreEndPoint, getInsightsEndPoint } from 'core-app/config'
import request from 'core-app/utils/request'
import { omit, merge, getIn } from 'timm'
import { Toast } from 'ui-lib/components/Toast'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import {
	responseDocsMapper,
	transformSortStringsToBEQueries,
	transformFilterStringsToBEQueries,
	extractFilterQueries,
	extractSortQueries,
} from 'core-app/shared/helpers'
import { RefineryDuc } from './duc'
import { TRACE_GROUP_STATUS } from './config'

const logger = LogHelper('client:refinerySaga')

function* fetchInsights() {
	try {
		yield put(AppDuc.creators.showGlobalLoader('fetch-refinery-insights'))
		yield fetchOrgDetailsCount()
		const requestUrl = `${getInsightsEndPoint()}dashboard/oil-refinery/insights`
		const data = yield CallWithRefreshCheck(requestUrl)
		const userInsight = getIn(data, ['data']) || {}

		const bestSuppliers = yield transformDataforPieChart(
			userInsight.bestSuppliers,
			'best-suppliers'
		)

		const topProducts = yield transformDataForBarChart(
			userInsight.topProducts,
			'products'
		)

		const certifiedSupply = yield transformDataForBarChart(
			userInsight.certifiedSupply,
			'certified-suppliers'
		)

		const supplierCategory = yield transformDataForBarChart(
			userInsight.supplierCategory,
			'supplier-categories'
		)

		const finaltransFormedInsight = {
			...userInsight,
			bestSuppliers,
			topProducts,
			certifiedSupply,
			supplierCategory,
		}

		yield put(
			RefineryDuc.creators.fetchInsightsSuccess(finaltransFormedInsight)
		)
	} catch (err) {
		const { message } = err
		logger.log(err)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-refinery-insights'))
	}
}

function* fetchProductionDocuments(action) {
	try {
		const { rootModule, locationState = {} } = action

		yield put(RefineryDuc.creators.resetProductionLoading(true))

		yield put(AuthDuc.creators.fetchAllProducts())

		const initiatingParty = yield select(
			AuthDuc.selectors.getCurrentOrganization
		)
		const { id } = initiatingParty
		const orgID = yield getOrgIDFromLoggedUser()
		yield fetchCurrentOrgsDetail({
			orgID,
			returnResponse: true,
		})
		const targetTypes = TRACE_GROUP_STATUS
		const { query, payload = {} } = locationState
		const existingQueryFromUrl = query || {}
		const frontendTargetQuery = {} // this would appear in search bar
		const backendTargetQuery = {} // this would go to backend api call

		yield put(RefineryDuc.creators.setProductionLoading(targetTypes))

		const _recordQuery = existingQueryFromUrl.production || []
		const _sortKeys = Array.isArray(_recordQuery)
			? _recordQuery
			: [_recordQuery]

		const sortQuery =
			_sortKeys && transformSortStringsToBEQueries(_sortKeys)

		const __query = {
			...backendTargetQuery,
		}
		yield put(
			RefineryDuc.creators.setProductionActiveSorts(existingQueryFromUrl)
		)

		const callsPipeLine = targetTypes.map(_type => {
			const _sortQuery = getIn(sortQuery, [_type]) || ''
			let requestUrl

			if (payload.rootModule === 'production-v1') {
				requestUrl =
					_type === 'transformed'
						? `${getCoreEndPoint()}clients/organizations/_/produced-inventory?status=${_type}&${querySerializer.stringify(
								{
									...__query,
									...{ sort: _sortQuery },
								}
						  )}`
						: `${getCoreEndPoint()}clients/organizations/${id}/tracegroups?status=${_type}&${querySerializer.stringify(
								{
									...__query,
									...{ sort: _sortQuery },
								}
						  )}`
			} else if (payload.rootModule === 'production') {
				requestUrl = `${getCoreEndPoint()}clients/organizations/${id}/tracegroups?status=transformed&meta=eq(productionType->manual)&${querySerializer.stringify(
					{
						...__query,
						...{ sort: _sortQuery },
					}
				)}`
			}

			return CallWithRefreshCheck(requestUrl)
		})

		const origResponse = yield all(callsPipeLine)
		const responses = responseDocsMapper(targetTypes, origResponse)
		yield put(
			RefineryDuc.creators.fetchProductionDocumentsSuccess(responses)
		)
		const response = getIn(responses, ['data']) || []
		// update the queries as applied in backend to stay in sync
		const sortQueriesFromBE = extractSortQueries(
			{ [rootModule]: response },
			_sortKeys
		)
		if (sortQueriesFromBE.length) {
			frontendTargetQuery.production = sortQueriesFromBE
		}

		if (!isEmptyObject(frontendTargetQuery)) {
			yield put(
				RefineryDuc.creators.setProductionActiveSorts(
					frontendTargetQuery
				)
			)
		}
		const storageUnitUrl = `${getCoreEndPoint()}assets/storageunits?type=neq(production-line)&state=active`
		const storageUnitsresponse = yield CallWithRefreshCheck(storageUnitUrl)
		const storageUnitList =
			getIn(storageUnitsresponse, ['data', 'list']) || []
		const storageTanks = storageUnitList.filter(
			storage => storage.type === 'tank'
		)
		yield put(RefineryDuc.creators.setStorageTanks(storageTanks))
	} catch (e) {
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(RefineryDuc.creators.resetProductionLoading(false))
	}
}

function* fetchInitialPlotInput() {
	try {
		yield put(AuthDuc.creators.fetchAllProducts('all'))
		yield put(AuthDuc.creators.fetchAllStorageUnits())
		yield fetchOrgAssets()

		yield put(RefineryDuc.creators.productionLoadingStatus(false))
	} catch (err) {
		logger.log(err)
	} finally {
		yield put(RefineryDuc.creators.productionLoadingStatus(false))
	}
}

function* createPlotInput(value) {
	const {
		docValue,
		allTanks,
		helpers: { setSubmitting },
		toastMessage,
	} = value

	try {
		const productionLine = getIn(docValue, ['traces', 0, 'productionLine'])
		const traces = docValue.traces
			.map(v =>
				v.storageUnits.map(str => {
					const storageUnitDetails = allTanks.filter(
						alt => str.storageUnitID === alt.id
					)

					return {
						id: v.productID,
						storageUnitID: str.storageUnitID,
						quantity: str.quantity,
						supplyChainModel: getIn(storageUnitDetails, [
							0,
							'meta',
							'supplyChainModel',
						]),
						inventoryType: getIn(storageUnitDetails, [
							0,
							'meta',
							'inventoryType',
						]),
						certification: getIn(storageUnitDetails, [
							0,
							'meta',
							'certificationType',
						]),
					}
				})
			)
			.flat(1)

		const values = {
			traces,
			meta: {
				productionLine: {
					id: productionLine,
				},
				supplyChainFilter: true,
			},
		}

		const initiatingParty = yield select(
			AuthDuc.selectors.getCurrentOrganization
		)
		const { id } = initiatingParty

		const requestUrl = `${getCoreEndPoint()}clients/organizations/${id}/products/_/plotinput`
		const options = {
			method: 'POST',
			body: JSON.stringify(values),
		}

		yield call(request, requestUrl, options)
		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)

		Toast({
			customHeading: toastMessage,
			type: 'success',
			isMobile: isMobile || isTablet,
		})
		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.REFINERY$SUBROOT,
				{
					rootModule: 'production',
				}
			)
		)
	} catch (err) {
		logger.log(err)
		setSubmitting(false)
		const { message } = err
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)

		return null
	}
}

function* updateInventory(value) {
	const { payload, toastMessage } = value

	try {
		yield put(RefineryDuc.creators.setUpdateInventoryLoading(true))
		const requestUrl = `${getCoreEndPoint()}inventory/weekly-products`
		const options = {
			method: 'POST',
			body: JSON.stringify(payload),
		}
		yield call(request, requestUrl, options)
		yield put(
			RefineryDuc.creators.fetchIncomingInventory(
				payload.weekNumber,
				payload.year
			)
		)
		yield put(
			RefineryDuc.creators.fetchOutgoingInventory(
				payload.weekNumber,
				payload.year
			)
		)
		yield put(AppDuc.creators.showGlobalLoader('update-inventory'))
		Toast({
			type: 'success',
			message: toastMessage,
		})
	} finally {
		yield put(RefineryDuc.creators.setUpdateInventoryLoading(false))
		yield put(AppDuc.creators.hideGlobalLoader('update-inventory'))
	}
}

function* fetchIncomingInventory(value) {
	const { weekNumber, year } = value

	try {
		yield put(RefineryDuc.creators.setIncomingInventoryLoading(true))
		const requestUrl = `${getCoreEndPoint()}inventory/weekly-products/week/${weekNumber}/year/${year}/type/incoming`
		const { data } = yield CallWithRefreshCheck(requestUrl)
		yield put(RefineryDuc.creators.setIncomingInventory(data))
		yield put(AppDuc.creators.showGlobalLoader('fetch-incoming-inventory'))
	} finally {
		yield put(RefineryDuc.creators.setIncomingInventoryLoading(false))
		yield put(AppDuc.creators.hideGlobalLoader('fetch-incoming-inventory'))
	}
}

function* fetchOutgoingInventory(value) {
	const { weekNumber, year } = value

	try {
		yield put(RefineryDuc.creators.setOutgoingInventoryLoading(true))
		const requestUrl = `${getCoreEndPoint()}inventory/weekly-products/week/${weekNumber}/year/${year}/type/outgoing`
		const { data } = yield CallWithRefreshCheck(requestUrl)
		yield put(RefineryDuc.creators.setOutgoingInventory(data))
		yield put(AppDuc.creators.showGlobalLoader('fetch-outgoing-inventory'))
	} finally {
		yield put(RefineryDuc.creators.setOutgoingInventoryLoading(false))
		yield put(AppDuc.creators.hideGlobalLoader('fetch-outgoing-inventory'))
	}
}

const PAGINATION_LIMIT = 20

function* fetchDocumentListing(action) {
	try {
		const { submodule, locationState = {}, skipGlobalLoader } = action

		yield put(AuthDuc.creators.fetchAllProducts())
		yield put(RefineryDuc.creators.flushListingData())

		if (!skipGlobalLoader)
			yield put(AppDuc.creators.showGlobalLoader('dashboard-listing'))

		const { type, payload, query } = locationState
		const existingQueryFromUrl = query || {}
		let frontendTargetQuery = {} // this would appear in search bar
		let backendTargetQuery = {} // this would go to backend api call

		yield put(RefineryDuc.creators.fetchDocumentLoading(true))
		const orgID = yield getOrgIDFromLoggedUser()

		const paginationQuery = {
			activeIndex: existingQueryFromUrl.activeIndex
				? existingQueryFromUrl.activeIndex
				: 0,
			limit: Math.min(
				existingQueryFromUrl.limit || PAGINATION_LIMIT,
				PAGINATION_LIMIT
			),
			nextIndex: existingQueryFromUrl.nextIndex,
		}

		// prepare backend query based on the pagination factors
		if (paginationQuery.limit) {
			backendTargetQuery.limit = paginationQuery.limit
		}

		if (paginationQuery.activeIndex > 0 && paginationQuery.nextIndex) {
			backendTargetQuery.startID = paginationQuery.nextIndex
		}

		const searchQuery = existingQueryFromUrl.q || ''

		// prepare the filter query
		const filterQueries =
			omit(existingQueryFromUrl, [
				'sort',
				'q',
				'activeIndex',
				'limit',
				'nextIndex',
			]) || {}

		if (!isEmptyObject(filterQueries)) {
			// form the backend queries form the object
			backendTargetQuery = merge(
				backendTargetQuery,
				transformFilterStringsToBEQueries(filterQueries)
			)
		}
		const sortQuery = existingQueryFromUrl.sort || []
		const _sortKeys = Array.isArray(sortQuery) ? sortQuery : [sortQuery]
		let _sortQuery = _sortKeys && transformSortStringsToBEQueries(_sortKeys)

		_sortQuery = getIn(_sortQuery, [submodule]) || ''
		const __query = {
			...backendTargetQuery,
		}

		const url =
			submodule === 'sourced-batch'
				? `${getCoreEndPoint()}clients/organizations/${orgID}/tracegroups?status=transforming`
				: `${getCoreEndPoint()}clients/organizations/_/produced-inventory?`
		const requestUrl = `${url}
		&${querySerializer.stringify({
			...__query,
			...{ sort: _sortQuery },
			...(searchQuery && { search: searchQuery }),
		})}`
		const origResponse = yield CallWithRefreshCheck(requestUrl)

		const response = getIn(origResponse, ['data']) || {}
		const serverPaginationQuery = {
			activeIndex: paginationQuery.activeIndex // &&
				? // We should have this check so the sequence of pagination is right.
				  // getIn(response, ['startID']) === backendTargetQuery.startID
				  paginationQuery.activeIndex
				: 0,
			limit: Math.min(getIn(response, ['pageSize']) || PAGINATION_LIMIT),
			nextIndex: getIn(response, ['lastID']),
		}

		// extract pagination queries from response
		yield put(
			RefineryDuc.creators.setPaginationEntries(
				serverPaginationQuery.activeIndex,
				getIn(response, ['pageSize']),
				getIn(response, ['total']),
				getIn(response, ['prevStartID']),
				getIn(response, ['nextStartID'])
			)
		)
		// extract filter queries
		const { queryTree, stateTree } = extractFilterQueries(response)

		yield put(RefineryDuc.creators.setActiveListingFilters(stateTree))

		frontendTargetQuery = merge(
			frontendTargetQuery,
			queryTree,
			serverPaginationQuery
		)

		yield put(RefineryDuc.creators.fetchDocumentListingSuccess(response))
		const sortQueriesFromBE = extractSortQueries(response, _sortKeys)
		if (sortQueriesFromBE.length) {
			frontendTargetQuery.sort = sortQueriesFromBE
		}
		if (!isEmptyObject(frontendTargetQuery)) {
			yield put(
				RefineryDuc.creators.setActiveListingSorts(frontendTargetQuery)
			)
			// we have some query, lets update the url to reflect that.
			yield put(
				MainRouteDuc.creators.redirect(
					type,
					payload,
					frontendTargetQuery,
					{
						skipRouteThunk: true,
					}
				)
			)
		}
		yield put(RefineryDuc.creators.fetchDocumentLoading(false))
	} catch (err) {
		logger.log(err)
	} finally {
		yield put(RefineryDuc.creators.fetchDocumentLoading(false))
		yield put(AppDuc.creators.hideGlobalLoader('dashboard-listing'))
	}
}

function* fetchInitialPlotOutput() {
	try {
		yield put(AuthDuc.creators.fetchAllProducts())
		yield put(AuthDuc.creators.fetchAllStorageUnits())
		yield fetchOrgAssets()

		const locationState = yield select(RefineryDuc.selectors.location)

		const query = getIn(locationState, ['query']) || {}
		const { documentReference } = query
		const requestUrl = `${getCoreEndPoint()}clients/organizations/_/tracegroups/${documentReference}`
		const { data } = yield CallWithRefreshCheck(requestUrl)

		const totalQuantity =
			data && data.traces
				? data.traces
						.map(trace => trace.quantityUtilized)
						.reduce((ttl, trace) => trace + ttl)
				: 0
		const plotTraceGroup = {
			traceGroupID: data.id,
			productName: data.meta.productIDs ? data.meta.productIDs[0] : '',
			quantity: totalQuantity,
			createdAt: data.createdAt,
			batchReference: data.batchReference,
		}
		yield put(RefineryDuc.creators.viewPlotDocumentsSuccess(plotTraceGroup))
	} catch (err) {
		logger.log(err)
	}
}

function* createPlotOutput(action) {
	const {
		docValue,
		helpers: { setSubmitting },
		toastMessage,
	} = action
	try {
		const traces = docValue.traces
			.map(v =>
				v.storageUnits.map(str => {
					return {
						id: v.productID,
						storageUnitID: str.storageUnitID,
						quantity: str.quantity,
						supplyChainModel: str.supplyChainModel,
						inventoryType: str.inventoryType,
						certification: str.certification,
					}
				})
			)
			.flat(1)

		const values = {
			traceGroupID: docValue.traceGroupID,
			traces,
		}

		const initiatingParty = yield select(
			AuthDuc.selectors.getCurrentOrganization
		)
		const { id } = initiatingParty

		const requestUrl = `${getCoreEndPoint()}clients/organizations/${id}/products/plotoutput`
		const options = {
			method: 'POST',
			body: JSON.stringify(values),
		}

		yield call(request, requestUrl, options)
		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)

		Toast({
			customHeading: toastMessage,
			type: 'success',
			isMobile: isMobile || isTablet,
		})

		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.REFINERY$SUBROOT,
				{
					rootModule: 'production',
				}
			)
		)
	} catch (err) {
		logger.log(err)
		setSubmitting(false)
		const { message } = err
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)

		return null
	}
}

function* viewPlotDocuments(action) {
	try {
		const { traceGroupID } = action
		yield put(AuthDuc.creators.fetchAllProducts())
		yield put(AuthDuc.creators.fetchAllStorageUnits())
		yield fetchOrgAssets()

		const requestUrl = `${getCoreEndPoint()}clients/organizations/_/tracegroups/${traceGroupID}`
		const { data } = yield CallWithRefreshCheck(requestUrl)

		const documentTraces = getIn(data, ['traces']) || []
		const producedDocumentTraces = getIn(data, ['producedTraces']) || []

		const totalQuantity = data.traces
			.map(trace => trace.quantityUtilized)
			.reduce((ttl, trace) => trace + ttl)
		const plotTraceGroup = {
			productIDs: data.meta.productIDs,
			quantity: totalQuantity,
			batchReference: data.batchReference,
			consignmentLength: documentTraces.length,
			documentTraces,
			producedDocumentTraces,
			producedTracesCreatedAt: data.createdAt,
			statusChangeLog: data.statusChangeLog,
		}
		yield put(RefineryDuc.creators.viewPlotDocumentsSuccess(plotTraceGroup))
	} catch (err) {
		logger.log(err)
	}
}

function* fetchStorageListing(action) {
	try {
		const { paginationQuery } = action
		const { nextStartID } = paginationQuery
		yield put(AuthDuc.creators.fetchAllProducts())
		yield put(AuthDuc.creators.fetchAllStorageUnits())
		const orgID = yield getOrgIDFromLoggedUser()
		yield fetchCurrentOrgsDetail({
			orgID,
			returnResponse: true,
		})
		const requestUrl = `${getCoreEndPoint()}entities/availableinventory/list?type=delivery-order&receivingPartyID=${orgID}&status=eq(state-%3Edelivered)&startID=${nextStartID ||
			''}`
		const origResponse = yield CallWithRefreshCheck(requestUrl)
		const storageList = getIn(origResponse, ['data', 'list']) || []

		const pagination = {
			total: getIn(origResponse, ['data', 'total']),
			nextStartID: getIn(origResponse, ['data', 'nextStartID']),
		}
		yield put(RefineryDuc.creators.fetchStorageListingSuccess(storageList))
		yield put(
			RefineryDuc.creators.setStorageListingPaginationEntries(pagination)
		)
	} catch (err) {
		logger.log(err)
	}
}

function* appendStorageListing(action) {
	try {
		const { locationState = {} } = action

		yield put(AppDuc.creators.showGlobalLoader('load-more-consignments'))

		yield put(AuthDuc.creators.fetchAllProducts())
		yield put(AuthDuc.creators.fetchAllStorageTank())

		const { query } = locationState
		const existingQueryFromUrl = query || {}
		// eslint-disable-next-line prefer-const
		let backendTargetQuery = {} // this would go to backend api call
		const paginationQuery = {
			limit: Math.min(
				existingQueryFromUrl.limit || PAGINATION_LIMIT,
				PAGINATION_LIMIT
			),
			nextIndex: existingQueryFromUrl.nextIndex,
		}

		if (paginationQuery.limit) {
			backendTargetQuery.limit = paginationQuery.limit
		}

		if (paginationQuery.nextIndex) {
			backendTargetQuery.startID = paginationQuery.nextIndex
		}

		const filterQueries =
			omit(existingQueryFromUrl, ['limit', 'nextIndex', 'activeIndex']) ||
			{}

		// form the backend queries form the object
		backendTargetQuery = merge(
			backendTargetQuery,
			transformFilterStringsToBEQueries(filterQueries)
		)

		const __query = {
			...backendTargetQuery,
		}

		const requestUrl = `${getCoreEndPoint()}entities/entities-tracemeta/updated?status=eq(state->delivered)&${querySerializer.stringify(
			{
				...__query,
			}
		)}`

		const origResponse = yield CallWithRefreshCheck(requestUrl)
		const storageList = getIn(origResponse, ['data', 'list']) || []

		yield put(RefineryDuc.creators.fetchStorageListingSuccess(storageList))
		yield put(
			RefineryDuc.creators.setStorageListingPaginationEntries(
				0, // ActiveIndex
				getIn(origResponse, ['data', 'total']),
				getIn(origResponse, ['data', 'pageSize']),
				getIn(origResponse, ['data', 'nextStartID'])
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('load-more-consignments'))
	}
}

function* assignTank(action) {
	try {
		const {
			values,
			supplyChainModel,
			productID,
			entityID,
			toastMessage,
		} = action

		const { id } = yield select(AuthDuc.selectors.getCurrentOrganization)

		const productStorage = values.map(str => {
			return {
				id: productID,
				storageUnitID: str.storageUnitID,
				quantity: str.quantity,
				supplyChainModel,
				activeIndex: str.activeIndex,
			}
		})

		const payload = {
			tenantID: id,
			events: [
				{
					event: 'inventory-to-storage',
					data: {
						payload: {
							id: entityID,
							meta: {
								productStorage,
							},
						},
					},
				},
			],
		}

		const requestUrl = `${getCoreEndPoint()}entities/events`

		const options = {
			method: 'POST',
			body: JSON.stringify(payload),
		}

		yield call(request, requestUrl, options)
		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)

		Toast({
			type: 'success',
			message: toastMessage,
			isMobile: isMobile || isTablet,
		})
		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.REFINERY$SUBROOT,
				{
					rootModule: 'input-storage',
				}
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* assignTankView() {
	try {
		yield put(AppDuc.creators.showGlobalLoader('fetch-tank-details'))
		yield put(AuthDuc.creators.fetchAllProducts())
		yield put(AuthDuc.creators.fetchAllStorageUnits())
		const locationState = yield select(RefineryDuc.selectors.location)
		const { query = {} } = locationState

		const { parentRef } = query
		const traceID = decodeURI(parentRef)
		const requestUrl = `${getCoreEndPoint()}inventory/products?traceID=${traceID}`

		const { data } = yield CallWithRefreshCheck(requestUrl)

		let totalQty = 0
		const assignedTank = data.list.map(d => {
			totalQty += d.totalQty

			return {
				storageUnit: d.storageUnitID,
				quantity: d.totalQty,
				createdAt: d.createdAt,
			}
		})

		const tankDetails = {
			productId: data.list[0].productID,
			entityCreationData: data.createdAt,
			totalQty,
			assignedTank,
		}

		yield put(RefineryDuc.creators.assignTankViewSuccess(tankDetails))
	} catch (err) {
		logger.log(err)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-tank-details'))
	}
}

function* deleteTraceGroupID(action) {
	try {
		const { traceGrpID } = action

		const initiatingParty = yield select(
			AuthDuc.selectors.getCurrentOrganization
		)
		const { id } = initiatingParty

		const requestUrl = `${getCoreEndPoint()}clients/organizations/${id}/tracegroups/${traceGrpID}`
		const options = {
			method: 'DELETE',
		}
		yield call(request, requestUrl, options)

		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.REFINERY$SUBROOT,
				{
					rootModule: 'production',
				}
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* mapProduction(action) {
	try {
		const { productionEntries = [], toastMessage } = action
		yield put(AppDuc.creators.showGlobalLoader('map-production'))

		const requestUrl = `${getCoreEndPoint()}clients/organizations/-/bulk/plotio`

		const payload = {
			plotIOs: productionEntries,
			date: new Date().toISOString(),
		}

		const options = {
			method: 'POST',
			body: JSON.stringify(payload),
		}

		yield call(request, requestUrl, options)
		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)

		Toast({
			customHeading: toastMessage,
			type: 'success',
			isMobile: isMobile || isTablet,
		})
		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.REFINERY$SUBROOT,
				{
					rootModule: 'production',
				}
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.REFINERY$SUBROOT,
				{
					rootModule: 'production',
				}
			)
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('map-production'))
		window.location.reload()
	}
}

export default function* RefinerySaga() {
	try {
		yield all([
			takeLatest(
				RefineryDuc.creators.fetchInsights().type,
				fetchInsights
			),
			takeLatest(
				RefineryDuc.creators.fetchProductionDocuments().type,
				fetchProductionDocuments
			),
			takeLatest(
				RefineryDuc.creators.fetchInitialPlotInput().type,
				fetchInitialPlotInput
			),
			takeLatest(
				RefineryDuc.creators.createPlotInput().type,
				createPlotInput
			),
			takeLatest(
				RefineryDuc.creators.updateInventory().type,
				updateInventory
			),
			takeLatest(
				RefineryDuc.creators.fetchIncomingInventory().type,
				fetchIncomingInventory
			),
			takeLatest(
				RefineryDuc.creators.fetchOutgoingInventory().type,
				fetchOutgoingInventory
			),
			takeLatest(
				RefineryDuc.creators.fetchDocumentListing().type,
				fetchDocumentListing
			),
			takeLatest(
				RefineryDuc.creators.fetchInitialPlotOutput().type,
				fetchInitialPlotOutput
			),
			takeLatest(
				RefineryDuc.creators.createPlotOutput().type,
				createPlotOutput
			),
			takeLatest(
				RefineryDuc.creators.viewPlotDocuments().type,
				viewPlotDocuments
			),
			takeLatest(
				RefineryDuc.creators.fetchStorageListing().type,
				fetchStorageListing
			),
			takeLatest(
				RefineryDuc.creators.appendStorageListing().type,
				appendStorageListing
			),
			takeLatest(RefineryDuc.creators.assignTank().type, assignTank),
			takeLatest(
				RefineryDuc.creators.assignTankView().type,
				assignTankView
			),
			takeLatest(
				RefineryDuc.creators.deleteTraceGroupID().type,
				deleteTraceGroupID
			),
			takeLatest(
				RefineryDuc.creators.mapProduction().type,
				mapProduction
			),
		])
	} catch (e) {
		logger.error(e)
	}
}
